import React, { useCallback } from 'react';
import { useSmartLinkReload } from '@atlaskit/smart-card/hooks';
import {
	useIsEmbedPageIssueView,
	useIsFullPageIssueView,
	useIsModal,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { RelatedIssue } from '@atlassian/jira-issue-line-card/src/common/types.tsx';
import JiraIssueLineCard from '@atlassian/jira-issue-line-card/src/ui/index.tsx';
import type { IssueLineCardProps } from '@atlassian/jira-issue-line-card/src/ui/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';

const IssueLineCardWithHover = (props: IssueLineCardProps & { isDragging?: boolean }) => {
	const { onEdit, issueLink, isDragging = false, isRemote } = props;
	const isFullIssueView = useIsFullPageIssueView();
	const isEmbedView = useIsEmbedPageIssueView();
	const isModal = useIsModal();

	const normalizedIssueLink = issueLink?.startsWith('/')
		? // eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			`${window.location.protocol}//${window.location.hostname}${issueLink}`
		: issueLink;

	const reloadSmartLink = useSmartLinkReload({ url: normalizedIssueLink || '' });

	/**
	 * Hiding preview when dragging because it obscures other items.
	 */
	const shouldShowHoverPreview =
		!isDragging && !isRemote && (isFullIssueView || isModal || isEmbedView);

	const handleEdit = useCallback(
		(updatedFields: Partial<RelatedIssue>) => {
			if (onEdit) {
				onEdit(updatedFields);
			}

			if (shouldShowHoverPreview) {
				reloadSmartLink();
			}
		},
		[onEdit, reloadSmartLink, shouldShowHoverPreview],
	);

	return (
		<JiraIssueLineCard
			{...props}
			isLinkedIssuesRefreshEnabled={
				isVisualRefreshEnabled() && fg('redesign-linked-issues-issue-view')
			}
			onEdit={handleEdit}
			showHoverPreviewEnabled={shouldShowHoverPreview}
		/>
	);
};

export default IssueLineCardWithHover;
