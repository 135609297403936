import { useCallback, useState } from 'react';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';

// TODO : Add a prefix to identify each app and also make sure you update the same in test
const localStorageProvider = createLocalStorageProvider('');

type Tuple = [boolean, (value: boolean) => void];

export const getGroupLocalStorageName = (projectKey: string, id: string) =>
	`group.state.${projectKey}.${id}`;

export const getGroupLocalStorage = (keyName: string, defaultValue: boolean) => {
	try {
		let item;
		if (fg('jfp-vulcan-browser-storage-migration')) {
			item = localStorageProvider.get(keyName);
		} else if (typeof window !== 'undefined') {
			item = window.localStorage.getItem(keyName);
		}
		if (item === 'true') return true;
		if (item === 'false') return false;
		throw Error('Invalid group state value');
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (_: any) {
		return defaultValue;
	}
};

export const isGroupPanelOpen = (
	projectKey: string,
	group: string,
	panelId: string,
	defaultValue: boolean,
) => {
	const groupLocalStorageName = getGroupLocalStorageName(projectKey, group);

	return getGroupLocalStorage(`${groupLocalStorageName}-${panelId}`, defaultValue);
};

export const getGroupsLocalStorage = (keyNames: string[], initialOpened: boolean) => {
	const items: { [key: string]: boolean } = {};
	keyNames.forEach((key) => {
		items[key] = getGroupLocalStorage(key, initialOpened);
	});

	return items;
};

export const useGroupLocalStorage = (
	projectKey: string,
	id: string,
	initialOpened: boolean,
): Tuple => {
	const keyName = getGroupLocalStorageName(projectKey, id);
	const [storedValue, setStoredValue] = useState(() =>
		getGroupLocalStorage(keyName, initialOpened),
	);

	const setValue = useCallback(
		(value: boolean) => {
			try {
				setStoredValue(value);
				if (fg('jfp-vulcan-browser-storage-migration')) {
					localStorageProvider.set(keyName, String(value));
				} else if (typeof window !== 'undefined') {
					window.localStorage.setItem(keyName, String(value));
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (_: any) {
				// Not decided what we do when we reach here.
			}
		},
		[keyName],
	);

	return [storedValue, setValue];
};
