import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';

export const getQuery = () =>
	`query GetUnifiedProfile($accountId: ID) {
	growthUnifiedProfile_getUnifiedProfile(accountId: $accountId) {
		userProfile {
			teamType
		}
	}
}`;

const generateGraphqlUrl = (): string => '/gateway/api/graphql';

export const fetchUserTeamType = async (accountId: string): Promise<string> => {
	const response = await fetchJson(generateGraphqlUrl(), {
		method: 'POST',
		body: JSON.stringify({
			operationName: 'GetUnifiedProfile',
			query: getQuery(),
			variables: {
				accountId,
			},
		}),
	});
	return response?.data?.growthUnifiedProfile_getUnifiedProfile?.userProfile?.teamType;
};
