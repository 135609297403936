import {
	CORE_PROJECT,
	SERVICE_DESK_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	JSD_TEMPLATE,
	JCS_TEMPLATE,
	JWM_TEMPLATE,
	DEFAULT_TEMPLATE,
} from '@atlassian/jira-issue-view-layout-templates-constants/src/index.tsx';
import {
	useProjectType,
	useProjectKey,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { useIsJCSProjectType } from '@atlassian/jira-servicedesk-customer-service-common/src/utils/use-is-jcs/index.tsx';

export const useTemplateRendererKey = () => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);
	const isJCS = useIsJCSProjectType(projectType);

	// TODO: Move this check to the below switch statement
	// by directly checking for CUSTOMER_SERVICE_PROJECT
	// when cleaning up `jcs_project_type_m3`.
	if (isJCS) {
		return JCS_TEMPLATE;
	}

	switch (projectType) {
		case SERVICE_DESK_PROJECT:
			return JSD_TEMPLATE;
		case CORE_PROJECT:
			return JWM_TEMPLATE;
		default:
			return DEFAULT_TEMPLATE;
	}
};
