import React, { memo } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { PREMIUM_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import type { MainIssueAggQueryRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { ViewAutomationDiscoveryPanel } from './src/ui/index.tsx';

const AutomationDiscoveryContainerPanel = ({
	rootRelayFragment,
}: {
	rootRelayFragment: MainIssueAggQueryRelayFragment | null;
}) => {
	const appEditions = useAppEditions();
	if (rootRelayFragment == null || appEditions.serviceDesk !== PREMIUM_EDITION) {
		return null;
	}

	return (
		<JSErrorBoundary
			id="AsyncAutomationDiscoveryContainerPanel"
			packageName="jiraIssueViewBase"
			fallback="unmount"
		>
			<ViewAutomationDiscoveryPanel rootRelayFragment={rootRelayFragment} />
		</JSErrorBoundary>
	);
};

export default memo(AutomationDiscoveryContainerPanel);
