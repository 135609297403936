import { useMemo, useRef } from 'react';

import isEqual from 'lodash/isEqual';

import type { ScreenTabCommonProps } from './types';

const mapTabKnownProps = (tabs: readonly ScreenTabCommonProps[]) => tabs.map(({ id }) => ({ id }));

/**
 * Utility to memoize Screen Tabs using their known properties from `ScreenTabCommonProps`.
 *
 * The view may mutate unkown properties inside `tabs` at any time (such as JSX stored in tabs props) but we do not want these mutations to re-initialize tabs and associated UI Modifications.
 *
 * We only trigger such re-initialization when the shape of tabs or their identifiers changes.
 *
 * @param tabs screen tabs extending `ScreenTabCommonProps` as passed by the View
 * @returns memoized screen tabs
 */
export function useMemoizedTabs<ScreenTabProps extends ScreenTabCommonProps>(
	tabs: readonly ScreenTabProps[],
) {
	const memoizedTabsRef = useRef(tabs);

	return useMemo(() => {
		if (!isEqual(mapTabKnownProps(memoizedTabsRef.current), mapTabKnownProps(tabs))) {
			memoizedTabsRef.current = tabs;

			return tabs;
		}

		return memoizedTabsRef.current;
	}, [tabs]);
}
