/** @jsx jsx */
import React, {
	Component,
	type MouseEvent,
	type ReactNode,
	type SyntheticEvent,
	type PropsWithChildren,
	type FC,
	useRef,
	useEffect,
} from 'react';
import { styled, css, jsx } from '@compiled/react';
import { v4 as uuid } from 'uuid';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { Box, xcss } from '@atlaskit/primitives';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Avatar from '@atlaskit/avatar';
import AkComment, { CommentAction, CommentAuthor, CommentEdited } from '@atlaskit/comment';
import Lozenge from '@atlaskit/lozenge';
import { token } from '@atlaskit/tokens';
import AkToolTip from '@atlaskit/tooltip';
import { p, text, inlineCard, doc, mention, rule } from '@atlaskit/adf-utils/builders';
import type { onClickCreateIssuePayload } from '@atlassian/jira-create-issue-from-comment/src/controllers/use-open-gic-from-comment/types.tsx';
import { useIssueLayoutActivitySidePanel } from '@atlassian/jira-issue-view-layout/src/services/main.tsx';
import { useAcronymHighlighter } from '@atlassian/jira-highlight-actions/src/controllers/use-acronym-highlighter/index.tsx';
import { useOnHighlight } from '@atlassian/jira-highlight-actions/src/ui/utils.tsx';
import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';
import {
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
	CORE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import transformToISOString from '@atlassian/jira-common-date/src/utils/transform-to-iso-string/index.tsx';
import type { CreateAnalyticsEventType } from '@atlassian/jira-common-navigation/src/keyboard-shortcuts/analytics.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { type IntlShape, FormattedMessage } from '@atlassian/jira-intl';
import { useIsIssueOfIncidentsPractice } from '@atlassian/jira-issue-field-servicedesk-practices/src/services/use-practices-field-value/index.tsx';
import {
	COMMENT_VISIBILITY_TYPE_PUBLIC,
	type CommentVisibility,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/comments.tsx';
import type { User } from '@atlassian/jira-issue-shared-types/src/common/types/user-type.tsx';
import Timestamp from '@atlassian/jira-issue-timestamp/src/main.tsx';
import { COMMENT_VISIBILITY_PUBLIC } from '@atlassian/jira-issue-view-common-types/src/comment-type.tsx';
import { smoothScrollIntoViewIfNeeded } from '@atlassian/jira-issue-view-common-utils/src/scroll/index.tsx';
import { PermalinkButton } from '@atlassian/jira-issue-view-common/src/component/permalink-button/button/async.tsx';
import PermalinkProvider, {
	type Permalink,
} from '@atlassian/jira-issue-view-common/src/component/permalink-button/permalink-provider/index.tsx';
import draftMessages from '@atlassian/jira-issue-view-common/src/messages/drafts.tsx';
import WithProfileCard from '@atlassian/jira-issue-view-internal-profilecard-next/src/index.tsx';
import { NEW_COMMENT_ID } from '@atlassian/jira-issue-view-store/src/selectors/comment-constants.tsx';
import { isJsmLoomExperimentEnabled } from '@atlassian/jira-loom-jsm-agent-response/src/common/utils.tsx';
import { ModalContextProvider } from '@atlassian/jira-modal-context-provider/src/ModalContextProvider.tsx';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import { isShallowEqual } from '@atlassian/jira-platform-shallow-equal/src/index.tsx';
import {
	fireOperationalAnalytics,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { isOnlyWhitespaceAdf } from '@atlassian/jira-rich-content/src/common/adf-parsing-utils.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import { TenantContextSubscriber } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import type { MediaContext } from '@atlassian/jira-issue-view-common-types/src/media-context-type.tsx';
import type { UserMentionProvider } from '@atlassian/jira-mention-provider/src/services/user-mention-provider/index.tsx';
import type { ContextIdentifier } from '@atlassian/jira-rich-content/src/index.tsx';
import { useOpenGICFromComment } from '@atlassian/jira-create-issue-from-comment/src/controllers/use-open-gic-from-comment/index.tsx';
import { useIsTargetedForCreateIssueFromCommentExp } from '@atlassian/jira-create-issue-from-comment/src/controllers/is-targeted-for-create-issue-from-comment-exp/index.tsx';
import type { CreatedIssueWithIssueData } from '@atlassian/jira-issue-create-common-types/src/common/types/index.tsx';
import { createFullIssueUrl } from '@atlassian/jira-issue-view-store/src/selectors/breadcrumbs-selector.tsx';
import CommentAfterContent from './akcomment-resources/comment-after-content.tsx';
import CommentDeleted from './akcomment-resources/comment-deleted.tsx';
import { IssueCommentReactionsBoundary } from './comment-reactions/async.tsx';
import type IssueCommentReactionsType from './comment-reactions/index.tsx';
import IssueCommentReactionsSync from './comment-reactions/index.tsx';
import CommentVisibilitySelector from './comment-visibility/index.tsx';
import { areCommentVisibilitiesEqual } from './comment-visibility/view/utils.tsx';
import { deleteIssueLinkConfirmationEntryPoint } from './confirmation-modal/entrypoint.tsx';
import { IssueCommentEditorReadonly } from './content/async.tsx';
import CommentContent from './content/index.tsx';
import { EventOccurredAtSelector } from './event-occurred-at-selector/index.tsx';
import messages from './messages.tsx';
import { ActivityTimestampStylesControl, InternalCommentContainer } from './styled.tsx';
import type { OwnProps as CommentContentProps } from './content/view.tsx';
import { CommentFloatingMenu } from './comment-floating-menu/index.tsx';
import {
	getFloatingMenuItems,
	getFloatingMenuItemsForOptimisticComment,
} from './comment-floating-menu/utils.tsx';

type TimeoutId = ReturnType<typeof setTimeout>;
type CommentId = string;
type EditSaveData = {
	authorId: AccountId | null;
	createdDate: string;
	bodyAdf: ADF;
	bodyHtml: string;
	isNewComment: boolean;
	visibility: CommentVisibility;
	eventOccurredAt?: number | null;
	jsdIncidentActivityViewHidden?: boolean | null;
	fullIssueUrl?: string;
	triggerIncidentSaveCommentFlag?: boolean;
	parentId?: CommentId;
};

export type Props = {
	isOptimistic: boolean;
	isEditing: boolean;
	isInternal: boolean;
	isIssueOfIncidentsPractice: boolean;
	isHighlighted: boolean;
	hasDraft: boolean;
	hasSaveFailed: boolean;
	hasScrolledPermalink: boolean;
	shouldScrollAfterAsyncComponentsLoaded: boolean;
	shouldShowCommentVisibility: boolean;
	canEdit: boolean;
	canDelete: boolean;
	canAdd?: boolean;
	bodyAdf: ADF;
	/*
	 *  TODO : https://hello.atlassian.net/browse/GCS-1009 Make jsdAuthorCanSeeRequest mandatory when FF is cleaned
	 */
	jsdAuthorCanSeeRequest?: boolean; // JSM specific field for identifying whether user is request participant or not,
	bodyHtml: string;
	author?: User;
	updateAuthor?: User;
	createdDate: string;
	createAnalyticsEvent: CreateAnalyticsEventType;
	commentId: string;
	updatedDate?: string | null;
	edited: boolean;
	showReactions: boolean;
	visibility: CommentVisibility;
	visibilityEdited: CommentVisibility;
	fullIssueUrl: string;
	eventOccurredAt?: number | null;
	jsdIncidentActivityViewHidden?: boolean | null;
	projectType?: ProjectType | null;
	currentUserAaid?: string | null;
	isEditingInternal: boolean;
	setScrollStatus: (status: boolean) => void;
	getOnEditSave: (
		arg1: EditSaveData,
		arg2: UIAnalyticsEvent,
		commentSessionId?: string | null,
	) => void;

	getOnEditClick: (arg1: boolean, commentSessionId?: string | null) => void;
	showAddedCommentDescription?: boolean;
	showLozenge?: boolean;
	showSmallAvatar?: boolean;
	onSaveRetry: () => void;
	onSaveEdit: () => void;
	onSaveCancel: (parentId?: CommentId) => void;
	onEditCancel: () => void;
	onEditUpdate: (arg1: ADF) => void;
	onEditPaste: (arg1: string) => void;
	onBlur: () => void;
	onCopyClick: (e: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => void;
	onCommentVisibilityChange: (arg1: CommentVisibility) => void;
	intl: IntlShape;
	children?: ReactNode;
	isReplyingToComment?: boolean;
	onReplyClick?: (
		replyingToCommentId: string,
		authorId: string | null,
		authorDisplayName: string | null,
		visibility?: CommentVisibility,
		commentSessionId?: string | null,
		extraCommentContent?: ADF,
	) => void;
	isDeleted?: boolean;
	hasReplies?: boolean;
	parentId?: CommentId;
	commentSessionId?: string | null;
	latestCommentViewInteractedTime?: string | null;
	isRealTimeCommentUpdated?: boolean;
	isThreadedCommentsEnabled: boolean;
	onCommentInteractionUpdateCommentViewInteractionTimeMarker?: () => void;
	commentRef?: React.RefObject<HTMLDivElement>;
	topMostUnreadCommentId: string | null;
	commentScrollStatusForRealTimeUpdates?: boolean;
	isActivityFeedInViewPort?: boolean;
	setScrollStatusForRealTimeUpdate?: (arg1: boolean) => void;
	contextIdentifier?: ContextIdentifier | null;
	mentionProvider?: UserMentionProvider | null;
	mediaContext?: MediaContext | null;
	onClickCreateIssue?: (payload: onClickCreateIssuePayload) => void;
	currentUserAccountId?: string | null;
	canCreateWorkItemInComment?: boolean;
	hasFloatingMenu?: boolean;
};

const localStorageProvider = createLocalStorageProvider('atl-issue-view-comment');
const UNREAD_COMMENT_LOZENGE_VIEWED_KEY = 'unread-comment-lozenge-viewed';

// TODO reevaluate phase - forPaint is simply for initial parity
export const IssueCommentReactionsAsync = lazyForPaint<typeof IssueCommentReactionsType>(
	() => import(/* webpackChunkName: "async-comment-reactions" */ './comment-reactions'),
);

export const IssueCommentReactions = componentWithFG(
	'convert_issuecommentreactions_to_be_synchronous',
	IssueCommentReactionsSync,
	IssueCommentReactionsAsync,
);

// TODO reevaluate phase - forPaint is simply for initial parity
export const IssueCommentEditor = lazyForPaint<typeof CommentContent>(
	() => import(/* webpackChunkName: "async-editor-view" */ './content'),
);

const FIELD_TYPE_COMMENT = 'comment';

// eslint-disable-next-line jira/react/no-class-components
export class CommentBase extends Component<
	Props,
	{
		selectedEventOccurredAt: number | null | undefined;
		showNewUnreadCommentLozenge: boolean;
	}
> {
	static displayName = 'Comment';

	static defaultProps = {
		isEditing: false,
		isIssueOfIncidentsPractice: false,
		visibility: COMMENT_VISIBILITY_PUBLIC,
		updatedDate: null,
		edited: false,
		eventOccurredAt: null,
		jsdIncidentActivityViewHidden: null,
	};

	constructor(props: Props) {
		super(props);
		this.state = {
			selectedEventOccurredAt: props.eventOccurredAt,
			showNewUnreadCommentLozenge:
				!localStorageProvider.get(UNREAD_COMMENT_LOZENGE_VIEWED_KEY) &&
				props.commentId === props.topMostUnreadCommentId &&
				!props.isActivityFeedInViewPort,
		};
	}

	componentDidMount() {
		const {
			props: { isOptimistic, commentId, createAnalyticsEvent, isHighlighted },
			optimisticCommentContainerRef,
		} = this;
		if (isOptimistic && commentId.includes(NEW_COMMENT_ID) && optimisticCommentContainerRef) {
			smoothScrollIntoViewIfNeeded(optimisticCommentContainerRef, {
				scrollMode: 'always',
				behavior: 'smooth',
			});
		}

		if (fg('threaded_comments_old_checks')) {
			if (isHighlighted) {
				const analyticsEvent = createAnalyticsEvent({});
				fireOperationalAnalytics(analyticsEvent, 'focussed comment', {
					isParent: this.props?.parentId,
				});
			}
		}

		this.scrollCommentIntoView();
	}

	shouldComponentUpdate(nextProps: Props) {
		if (
			(!this.props.shouldScrollAfterAsyncComponentsLoaded &&
				nextProps.shouldScrollAfterAsyncComponentsLoaded) ||
			(!this.props.hasScrolledPermalink && nextProps.hasScrolledPermalink)
		) {
			const propsWithPermalinkUpdates = {
				...this.props,
				// make the props equal to preform shallow equal, a different
				// approach to using omit
				shouldScrollAfterAsyncComponentsLoaded: nextProps.shouldScrollAfterAsyncComponentsLoaded,
				hasScrolledPermalink: nextProps.hasScrolledPermalink,
			};

			// check to see if any other props has changed
			const propsShallowEqual = isShallowEqual(propsWithPermalinkUpdates, nextProps);
			if (propsShallowEqual) {
				// We only need to need to scroll to comments that are highlighted
				// and have not already scrolled. Which is true when the user
				// views the issue from a permalink. Most cases it will be false
				// so lets stop the re-rendering of comments that aren't highlighted.
				return nextProps.isHighlighted && !nextProps.hasScrolledPermalink;
			}
		}

		return true;
	}

	componentDidUpdate() {
		this.scrollCommentIntoView();
		if (
			(this.props.projectType === SOFTWARE_PROJECT || this.props.projectType === CORE_PROJECT) &&
			this.props.isActivityFeedInViewPort &&
			this.state.showNewUnreadCommentLozenge &&
			expVal('thor_issue_view_realtime_updates_experiment', 'isEnabled', false)
		) {
			localStorageProvider.set(UNREAD_COMMENT_LOZENGE_VIEWED_KEY, true);
			this.unreadCommentLozengeTimeoutId = setTimeout(() => {
				this.setState({ showNewUnreadCommentLozenge: false });
			}, 5000);
		}
		if (
			(this.props.projectType === SOFTWARE_PROJECT || this.props.projectType === CORE_PROJECT) &&
			this.props.commentId !== this.props.topMostUnreadCommentId &&
			this.state.showNewUnreadCommentLozenge &&
			expVal('thor_issue_view_realtime_updates_experiment', 'isEnabled', false)
		) {
			this.setState({
				showNewUnreadCommentLozenge: false,
			});
		}
	}

	componentWillUnmount() {
		if (this.unreadCommentLozengeTimeoutId) {
			clearTimeout(this.unreadCommentLozengeTimeoutId);
			this.unreadCommentLozengeTimeoutId = undefined;
		}
	}

	unreadCommentLozengeTimeoutId: TimeoutId | undefined = undefined;

	changeEventOccurredAt = (val: number | null) => {
		this.setState({
			selectedEventOccurredAt: val,
		});
	};

	unreadCommentRef: HTMLElement | null | undefined;

	updateUnreadCommentRef = (ref: HTMLElement) => {
		this.unreadCommentRef = ref;
	};

	isUnreadComment = () => {
		if (
			(this.props.projectType === SOFTWARE_PROJECT || this.props.projectType === CORE_PROJECT) &&
			expVal('thor_issue_view_realtime_updates_experiment', 'isEnabled', false)
		) {
			const { createdDate, latestCommentViewInteractedTime, author, currentUserAaid } = this.props;

			return (
				(author?.id !== currentUserAaid &&
					latestCommentViewInteractedTime &&
					new Date(latestCommentViewInteractedTime).getTime() < new Date(createdDate).getTime()) ||
				false
			);
		}
		return false;
	};

	onCommentInteraction = () => {
		if (
			(this.props.projectType === SOFTWARE_PROJECT || this.props.projectType === CORE_PROJECT) &&
			expVal('thor_issue_view_realtime_updates_experiment', 'isEnabled', false)
		) {
			const { onCommentInteractionUpdateCommentViewInteractionTimeMarker } = this.props;
			this.isUnreadComment() &&
				onCommentInteractionUpdateCommentViewInteractionTimeMarker &&
				onCommentInteractionUpdateCommentViewInteractionTimeMarker();
		}
	};

	// Function to add analytics when delete action item is clicked
	onDeleteClick = () => {
		const { parentId, createAnalyticsEvent, hasReplies } = this.props;
		fireUIAnalytics(
			createAnalyticsEvent({ action: 'clicked', actionSubject: 'button' }),
			'deleteCommentButton',
			{
				isRootComment: !!parentId,
				hasReplies,
			},
		);
	};

	onEditClick = (
		_: MouseEvent<HTMLElement>,
		analyticsEvent?: UIAnalyticsEvent,
		isSiteAdmin?: boolean,
	) => {
		const { getOnEditClick, isInternal, hasDraft, createAnalyticsEvent } = this.props;

		const commentSessionId = uuid();
		getOnEditClick(isInternal, commentSessionId);

		this.onCommentInteraction();

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		fireOperationalAnalytics(analyticsEvent as UIAnalyticsEvent, 'editCommentEditor expanded', {
			hasDraft,
		});
		fireUIAnalytics(createAnalyticsEvent({ actionSubject: 'commentEdit', action: 'started' }), {
			hasDraft,
			commentSessionId,
			...(this.jsmLoomExperiment?.enabled && {
				isSiteAdmin,
				loomJsmAgentExperimentCohort: this.jsmLoomExperiment?.cohort,
			}),
		});
	};

	onEditSave = (value: ADF): void => {
		const isValueEmpty = isOnlyWhitespaceAdf(value);
		if (!isValueEmpty) {
			const {
				author,
				commentId,
				createdDate,
				bodyHtml,
				shouldShowCommentVisibility,
				visibility,
				visibilityEdited,
				getOnEditSave,
				createAnalyticsEvent,
				jsdIncidentActivityViewHidden,
				fullIssueUrl,
				isIssueOfIncidentsPractice,
				isEditingInternal,
				projectType,
				parentId,
				commentSessionId,
				isRealTimeCommentUpdated,
			} = this.props;
			const authorId = author ? author.id : null;
			const analyticsEvent = createAnalyticsEvent({ action: 'updated' });

			this.state.selectedEventOccurredAt
				? analyticsEvent.update({ commentEditWithEventOccurredAt: true })
				: analyticsEvent.update({ commentEditWithEventOccurredAt: false });

			if (shouldShowCommentVisibility) {
				analyticsEvent.update({
					isVisibilityUpdated: !areCommentVisibilitiesEqual(visibility, visibilityEdited),
				});
			}

			if (fg('thor_send_realtime_attribute')) {
				analyticsEvent.update({
					isRealTimeCommentUpdated,
				});
			}

			getOnEditSave(
				{
					authorId,
					createdDate,
					bodyAdf: value,
					bodyHtml,
					isNewComment: commentId.includes(NEW_COMMENT_ID),
					visibility:
						projectType === SERVICE_DESK_PROJECT && !isEditingInternal
							? COMMENT_VISIBILITY_PUBLIC
							: visibilityEdited,
					...(isIssueOfIncidentsPractice && isEditingInternal && fg('jsm-timelines-phase-2')
						? {
								eventOccurredAt: this.state.selectedEventOccurredAt,
								jsdIncidentActivityViewHidden,
								fullIssueUrl,
								triggerIncidentSaveCommentFlag: isIssueOfIncidentsPractice,
							}
						: {}),
					...(fg('threaded_comments_old_checks') ? { parentId } : {}),
				},
				analyticsEvent,
				commentSessionId,
			);
		}
	};

	onHideSave = (): void => {
		const {
			author,
			commentId,
			createdDate,
			bodyHtml,
			bodyAdf,
			jsdIncidentActivityViewHidden,
			visibilityEdited,
			projectType,
			isEditingInternal,
			getOnEditSave,
			createAnalyticsEvent,
			eventOccurredAt,
			commentSessionId,
		} = this.props;

		const authorId = author ? author.id : null;
		const analyticsEvent = createAnalyticsEvent({ action: 'updated' });

		fireUIAnalytics(
			createAnalyticsEvent({}),
			jsdIncidentActivityViewHidden ? 'unhide clicked' : 'hide clicked',
		);

		getOnEditSave(
			{
				authorId,
				createdDate,
				bodyAdf,
				bodyHtml,
				isNewComment: commentId.includes(NEW_COMMENT_ID),
				visibility:
					projectType === SERVICE_DESK_PROJECT && !isEditingInternal
						? COMMENT_VISIBILITY_PUBLIC
						: visibilityEdited,
				eventOccurredAt,
				jsdIncidentActivityViewHidden: !jsdIncidentActivityViewHidden,
			},
			analyticsEvent,
			commentSessionId,
		);
	};

	getAuthorDisplayName = (author?: User) =>
		author ? author.displayName : this.props.intl.formatMessage(messages.anonymous);

	onReplyClick = () => {
		this.onReplyWithExtraCommentContent();
	};

	onReplyWithExtraCommentContent = (extraCommentContent?: ADF) => {
		const {
			onReplyClick,
			parentId,
			commentId,
			author,
			shouldShowCommentVisibility,
			visibility,
			createAnalyticsEvent,
			hasReplies,
		} = this.props;

		if (!onReplyClick) {
			return;
		}

		const replyingToCommentId = parentId || commentId;
		const authorId = author ? author.id : null;
		const authorDisplayName = this.getAuthorDisplayName(author);
		const replyVisibility = shouldShowCommentVisibility ? visibility : undefined;
		const commentSessionId = uuid();

		this.onCommentInteraction();

		onReplyClick(
			replyingToCommentId,
			authorId,
			authorDisplayName,
			replyVisibility,
			commentSessionId,
			extraCommentContent && expVal('issue_create_from_comment_experiment', 'hasIssueCreate', false)
				? extraCommentContent
				: undefined,
		);

		fireUIAnalytics(
			createAnalyticsEvent({ action: 'clicked', actionSubject: 'button' }),
			'replyButton',
			{
				isRootComment: !!parentId,
				hasReplies,
				commentSessionId,
			},
		);
	};

	onClickCreateIssueButton = () => {
		const { onClickCreateIssue } = this.props;
		if (!onClickCreateIssue) {
			return;
		}

		onClickCreateIssue({
			onIssueCreate: this.onIssueCreate,
		});
	};

	onIssueCreate = (data: CreatedIssueWithIssueData) => {
		const { author, currentUserAccountId, intl } = this.props;
		const issueUrl = createFullIssueUrl('', data.issueKey);
		const authorId = author ? author.id : null;
		const authorDisplayName = this.getAuthorDisplayName(author);

		const quotedCommentAndIssue = [
			p(inlineCard({ url: issueUrl })),
			rule(),
			...this.props.bodyAdf.content,
		];

		const extraCommentContent =
			currentUserAccountId === authorId
				? doc(
						p(text(this.props.intl.formatMessage(messages.createdIssueBySelf))),
						...quotedCommentAndIssue,
					)
				: doc(
						p(
							authorId
								? mention({
										id: authorId,
										text: `@${authorDisplayName}`,
									})
								: text(''),
							text(' '),
							text(intl.formatMessage(messages.createdIssue)),
						),

						...quotedCommentAndIssue,
					);

		this.onReplyWithExtraCommentContent(extraCommentContent);
	};

	getCommentReactions = () => (
		<ReactionsContainer
			data-testid="issue-comment-base.ui.comment.reactions-container"
			isOverflowReactionsFixEnabled={fg('jira_comment_reactions_overflow_ui')}
		>
			<IssueCommentReactionsBoundary packageName="issue" fallback={<></>}>
				<UFOSegment name="IssueCommentReactions">
					<IssueCommentReactions
						showQuickReactions={
							expVal(
								'thor_surface_reaction_comment_experiment',
								'isSurfaceReactionsEnabled',
								false,
							) && this.props.isHighlighted
						}
						onReactionSuccess={this.onCommentInteraction}
					/>
				</UFOSegment>
			</IssueCommentReactionsBoundary>
		</ReactionsContainer>
	);

	deleteConfirmModalProps = {
		width: 'small',
		testId: 'issue-comment-base.ui.comment.confirmation-modal.modal-dialog',
	};

	jsmLoomExperiment = { ...isJsmLoomExperimentEnabled() };

	getCommentActions: () => ReactNode[] = () =>
		[
			expVal('thor_surface_reaction_comment_experiment', 'isSurfaceReactionsEnabled', false)
				? this.props.showReactions && this.getCommentReactions()
				: undefined,
			this.props.canAdd && this.props.isThreadedCommentsEnabled && (
				<CommentAction key="reply" onClick={this.onReplyClick}>
					{this.props.intl.formatMessage(messages.reply)}
				</CommentAction>
			),
			this.props.canEdit &&
				!this.props.hasFloatingMenu &&
				(this.jsmLoomExperiment.enabled ? (
					<TenantContextSubscriber>
						{({ tenantContext: { isSiteAdmin } }) => {
							return (
								<CommentAction
									key="edit"
									onClick={(...args) => this.onEditClick(...args, isSiteAdmin)}
								>
									{this.props.intl.formatMessage(messages.edit)}
									{this.props.hasDraft
										? ` (${this.props.intl.formatMessage(draftMessages.draftIndicator)})`
										: ''}
								</CommentAction>
							);
						}}
					</TenantContextSubscriber>
				) : (
					<CommentAction key="edit" onClick={this.onEditClick}>
						{this.props.intl.formatMessage(messages.edit)}
						{this.props.hasDraft
							? ` (${this.props.intl.formatMessage(draftMessages.draftIndicator)})`
							: ''}
					</CommentAction>
				)),
			this.props.canDelete && !this.props.hasFloatingMenu && (
				<ModalEntryPointPressableTrigger
					entryPoint={deleteIssueLinkConfirmationEntryPoint}
					entryPointProps={{
						commentId: this.props.commentId,
						eventOccurredAt: this.props.eventOccurredAt,
						jsdIncidentActivityViewHidden: this.props.jsdIncidentActivityViewHidden,
						hasReplies: this.props.hasReplies,
						parentId: this.props.parentId,
						isRealTimeCommentUpdated: this.props.isRealTimeCommentUpdated,
					}}
					modalProps={this.deleteConfirmModalProps}
					useInternalModal
				>
					{({ ref }) => (
						<CommentAction
							key="delete"
							ref={ref}
							{...(fg('add-analytics-for-delete-comment-ken-1993') && {
								onClick: this.onDeleteClick,
							})}
						>
							{this.props.intl.formatMessage(messages.delete)}
						</CommentAction>
					)}
				</ModalEntryPointPressableTrigger>
			),
			this.props.canEdit &&
				this.props.isInternal &&
				this.props.isIssueOfIncidentsPractice &&
				fg('jsm-timelines-phase-2') && (
					<CommentAction key="hide" onClick={this.onHideSave}>
						{this.props.intl.formatMessage(
							this.props.jsdIncidentActivityViewHidden ? messages.unhide : messages.hide,
						)}
					</CommentAction>
				),
			expVal('thor_surface_reaction_comment_experiment', 'isSurfaceReactionsEnabled', false)
				? undefined
				: this.props.showReactions && this.getCommentReactions(),
		].filter((action) => !!action);

	onSaveCancelClick = () => {
		const { onSaveCancel, parentId } = this.props;
		onSaveCancel(parentId);
	};

	getCommentSaveFailedActions: () => ReactNode[] = () =>
		[
			<CommentAction key="retry" onClick={this.props.onSaveRetry}>
				{this.props.intl.formatMessage(messages.retrySave)}
			</CommentAction>,
			<CommentAction key="edit" onClick={this.props.onSaveEdit}>
				{this.props.intl.formatMessage(messages.edit)}
			</CommentAction>,
			<CommentAction key="cancel" onClick={this.onSaveCancelClick}>
				{this.props.intl.formatMessage(messages.cancelSave)}
			</CommentAction>,
		].filter((action) => !!action);

	getCommentSavedWithFloatingMenu: () => ReactNode[] = () =>
		[
			<CommentAction key="retry" onClick={this.props.onSaveRetry}>
				{this.props.intl.formatMessage(messages.retrySave)}
			</CommentAction>,
			<CommentAction key="cancel" onClick={this.onSaveCancelClick}>
				{this.props.intl.formatMessage(messages.cancelSave)}
			</CommentAction>,
		].filter((action) => !!action);

	renderAdfRendererFallback = () => <IssueCommentEditorReadonly bodyHtml={this.props.bodyHtml} />;

	getCommentContent = () => {
		const {
			bodyAdf,
			commentId,
			isEditing,
			onEditCancel,
			onEditUpdate,
			onEditPaste,
			onBlur,
			visibilityEdited,
			onCommentVisibilityChange,
			shouldShowCommentVisibility,
			eventOccurredAt,
			isEditingInternal,
			projectType,
			parentId,
		} = this.props;

		const shouldShowCommentVisibilitySelector = fg('threaded_comments_old_checks')
			? shouldShowCommentVisibility && !parentId // should not show for replies
			: shouldShowCommentVisibility;

		const jsmTimelineComponents =
			isEditingInternal && fg('jsm-timelines-phase-2')
				? [
						<EventOccurredAtSelector
							key="eventOccurredAtSelector"
							initialValue={eventOccurredAt}
							onChange={(newEventOccurredAt: number | null) => {
								this.changeEventOccurredAt(newEventOccurredAt);
							}}
						/>,
					]
				: [];

		let secondaryToolbarComponents = shouldShowCommentVisibilitySelector
			? [
					...jsmTimelineComponents,
					<CommentVisibilitySelector
						key="commentVisibilitySelector"
						initialValue={visibilityEdited}
						onChange={onCommentVisibilityChange}
					/>,
				]
			: jsmTimelineComponents;

		secondaryToolbarComponents =
			shouldShowCommentVisibilitySelector &&
			(projectType === SERVICE_DESK_PROJECT ? this.props.isEditingInternal : true)
				? [
						...jsmTimelineComponents,
						<CommentVisibilitySelector
							key="commentVisibilitySelector"
							initialValue={visibilityEdited}
							onChange={onCommentVisibilityChange}
						/>,
					]
				: jsmTimelineComponents;

		let commentContentProps: CommentContentProps = {
			commentId,
			isEditing,
			secondaryToolbarComponents,
			canEdit: this.props.canEdit,
			onCancel: onEditCancel,
			onChange: onEditUpdate,
			onSave: this.onEditSave,
			onPaste: onEditPaste,
			onBlur,
			onHideSave: this.onHideSave,
		};

		if (fg('change_comment_editorstate_props_to_propdrill')) {
			commentContentProps = {
				...commentContentProps,
				mentionProvider: this.props.mentionProvider,
				contextIdentifier: this.props.contextIdentifier,
				mediaContext: this.props.mediaContext,
			};
		}

		if (__SERVER__) {
			return <CommentContent {...commentContentProps} bodyAdf={bodyAdf} />;
		}

		return (
			<ErrorBoundary
				id="issue-comment-editor"
				packageName="issue"
				render={this.renderAdfRendererFallback}
			>
				<CommentContent {...commentContentProps} bodyAdf={bodyAdf} />
			</ErrorBoundary>
		);
	};

	getRestrictedTo = (restrictedTo: JSX.Element, restrictedToText: string) => {
		const { isOptimistic, isDeleted } = this.props;

		if (isDeleted && fg('threaded_comments_old_checks')) {
			return undefined;
		}

		return !isOptimistic ? restrictedTo : restrictedToText;
	};

	getCommentTime = () => {
		const {
			intl: { formatMessage },
			createdDate,
			showAddedCommentDescription,
			showLozenge,
			eventOccurredAt,
			isDeleted,
			commentId,
		} = this.props;

		if (isDeleted && fg('threaded_comments_old_checks')) {
			return undefined;
		}

		const value =
			eventOccurredAt && fg('jsm-timelines-phase-2')
				? transformToISOString(eventOccurredAt)
				: createdDate;
		return (
			<>
				{showAddedCommentDescription !== undefined && showAddedCommentDescription && (
					<DescriptionAction
						data-testid="issue-comment-base.ui.comment.action"
						dangerouslySetInnerHTML={{
							__html: formatMessage(
								expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false)
									? messages.addedCommentDescriptionLowercase
									: messages.addedCommentDescription,
								undefined,
								{
									ignoreTag: true,
								},
							),
						}}
					/>
				)}
				<Timestamp
					value={value}
					tooltipPosition="top"
					extraStyles={ActivityTimestampStylesControl}
				/>
				{showLozenge !== undefined && showLozenge && (
					<LozengeWrapper>
						<Lozenge>{formatMessage(messages.lozenge)}</Lozenge>
					</LozengeWrapper>
				)}
				{(this.props.projectType === SOFTWARE_PROJECT || this.props.projectType === CORE_PROJECT) &&
					this.isUnreadComment() &&
					expVal('thor_issue_view_realtime_updates_experiment', 'isEnabled', false) && (
						<Box
							as="span"
							ref={this.updateUnreadCommentRef}
							testId={`issue-comment-base.ui.comment.unread.${commentId}`}
							xcss={[unreadCss, this.state.showNewUnreadCommentLozenge && unreadNewBadgeCss]}
						>
							{this.state.showNewUnreadCommentLozenge && formatMessage(messages.new)}
						</Box>
					)}
			</>
		);
	};

	getCommentEditedToolTip = () => {
		const { intl } = this.props;

		const editTooltip = (
			<FormattedMessage
				{...messages.editedBy}
				values={{
					author: this.getAuthorDisplayName(this.props.updateAuthor),
					timestamp: <Timestamp value={this.props.updatedDate || ''} />,
				}}
			/>
		);

		return (
			<AkToolTip
				content={editTooltip}
				position="top"
				testId="issue-comment-base.ui.comment.ak-tool-tip"
			>
				<CommentEdited>{intl.formatMessage(messages.edited)}</CommentEdited>
			</AkToolTip>
		);
	};

	getFormattedGridContainer = (itemOne: ReactNode, itemTwo: ReactNode) =>
		isVisualRefreshEnabled() ? (
			<GridContentContainerVisualRefresh data-testid="issue-comment-base.ui.comment.grid-content-container">
				<GridContainer>{itemOne}</GridContainer>
				{itemTwo != null ? <GridContainer>{itemTwo}</GridContainer> : null}
			</GridContentContainerVisualRefresh>
		) : (
			<GridContentContainer data-testid="issue-comment-base.ui.comment.grid-content-container">
				<GridContainer>{itemOne}</GridContainer>
				{itemTwo != null ? <GridContainer>{itemTwo}</GridContainer> : null}
			</GridContentContainer>
		);

	getPermalinkIcon = (permalink: Permalink) => {
		const {
			commentId,
			fullIssueUrl,
			onCopyClick,
			intl: { formatMessage },
		} = this.props;

		return (
			<PermalinkButton
				id={commentId}
				fullIssueUrl={`${fullIssueUrl}?focusedCommentId=${commentId}`}
				label={formatMessage(messages.copy)}
				onCopyClick={onCopyClick}
				isVisible={permalink.isVisible}
			/>
		);
	};

	renderEditedOld = () => (this.props.edited ? this.getCommentEditedToolTip() : null);

	renderEditedOldWithOptimistic = (permalink: Permalink) => {
		const isDeletedComment = this.props.isDeleted && fg('threaded_comments_old_checks');

		if (this.props.hasFloatingMenu) {
			return (
				<>
					{this.renderEditedOld()}
					{this.getFloatingMenu(isDeletedComment, permalink)}
				</>
			);
		}
		return this.renderEditedOld();
	};

	getFloatingMenu = (isDeletedComment: boolean | undefined, permalink: Permalink) => {
		const {
			intl,
			fullIssueUrl,
			commentId,
			isOptimistic,
			isEditing,
			canDelete,
			canEdit,
			onCopyClick,
			onClickCreateIssue,
			hasSaveFailed,
			onSaveEdit,
		} = this.props;

		const entryPointProps = {
			commentId,
			eventOccurredAt: this.props.eventOccurredAt,
			jsdIncidentActivityViewHidden: this.props.jsdIncidentActivityViewHidden,
			hasReplies: this.props.hasReplies,
			parentId: this.props.parentId,
			isRealTimeCommentUpdated: this.props.isRealTimeCommentUpdated,
		};

		const shouldShowFloatingMenu = permalink.isVisible && !isDeletedComment && !isEditing;
		if (shouldShowFloatingMenu && isOptimistic) {
			return (
				<CommentFloatingMenu
					menuItems={getFloatingMenuItemsForOptimisticComment({
						formatMessage: intl.formatMessage,
						hasSaveFailed,
						onSaveEdit,
					})}
				/>
			);
		}
		if (shouldShowFloatingMenu && !isOptimistic) {
			return (
				<CommentFloatingMenu
					menuItems={getFloatingMenuItems({
						formatMessage: intl.formatMessage,
						fullIssueUrl,
						commentId,
						onClickCreateIssue: onClickCreateIssue ? this.onClickCreateIssueButton : undefined,
						onCopyClick,
						onEditClick: this.onEditClick,
						onDeleteClick: this.onDeleteClick,
						canDelete,
						canEdit,
						deleteEntryPointProps: entryPointProps,
						shouldShowCreateButton:
							this.props.canAdd &&
							this.props.isThreadedCommentsEnabled &&
							this.props.canCreateWorkItemInComment,
					})}
				/>
			);
		}
	};

	getEditingAndRestrictedTo = (restrictedToText: string, permalink: Permalink) => {
		const { isEditing, hasSaveFailed, isOptimistic, isDeleted, intl, hasFloatingMenu } = this.props;
		const isDeletedComment = isDeleted && fg('threaded_comments_old_checks');

		// if comment is deleted, show only deleted component in edited field
		if (isDeletedComment) {
			return {
				restrictedTo: null,
				edited: <CommentDeleted intl={intl} />,
			};
		}

		const copyIcon = !hasFloatingMenu ? this.getPermalinkIcon(permalink) : null;
		const hasRestrictedToText = restrictedToText.length > 0;

		// comment being saved and showing save label text
		// return null as otherwise would render the copy icon
		if (isOptimistic && !hasSaveFailed) return { restrictedTo: null, edited: null };

		const restrictedTo = hasRestrictedToText
			? this.getFormattedGridContainer(restrictedToText, copyIcon)
			: null;

		const editedCopyIcon = hasRestrictedToText ? null : copyIcon;

		const editedComment = this.getCommentEditedToolTip();

		// don't show copy icon while editing
		if (isEditing) {
			return {
				restrictedTo: null,
				edited: this.props.edited ? editedComment : null,
			};
		}
		// if there are 3 things to show (edited, restrictedTo, and copy icon) then the icon will need to be shown in the restrictedTo prop
		if (this.props.edited) {
			return {
				restrictedTo,
				edited: hasFloatingMenu ? (
					<>
						{this.getFormattedGridContainer(editedComment, editedCopyIcon)}
						{this.getFloatingMenu(isDeletedComment, permalink)}
					</>
				) : (
					this.getFormattedGridContainer(editedComment, editedCopyIcon)
				),
			};
		}

		const editedWithFloatingMenu = (
			<>
				{editedCopyIcon}
				{this.getFloatingMenu(isDeletedComment, permalink)}
			</>
		);

		// show copy icon if comment is not an internal comment.
		return {
			restrictedTo,
			edited: hasFloatingMenu ? editedWithFloatingMenu : editedCopyIcon,
		};
	};

	optimisticCommentContainerRef: HTMLElement | null | undefined;

	isRequestParticipant = () => this.props.jsdAuthorCanSeeRequest === false;

	getAuthorAdditionalInfo = () => {
		if (expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false)) {
			return this.isRequestParticipant()
				? ` ${this.props.intl.formatMessage(messages.notRequestParticipant)}`
				: '';
		}

		return this.isRequestParticipant()
			? this.props.intl.formatMessage(messages.notRequestParticipant)
			: '';
	};

	renderComment = (restrictedToText: string) => {
		const {
			author,
			isOptimistic,
			hasSaveFailed,
			isEditing,
			isHighlighted,
			isInternal,
			commentId,
			showSmallAvatar,
			children,
			isReplyingToComment,
			hasReplies,
			isDeleted,
			parentId,
			isThreadedCommentsEnabled,
			commentRef,
			hasFloatingMenu,
		} = this.props;

		const isDeletedComment = isDeleted && fg('threaded_comments_old_checks');

		return (
			<PermalinkProvider>
				{(permalink) => {
					// @ts-expect-error - TS2525 - Initializer provides no value for this binding element and the binding element has no default value. | TS7031 - Binding element 'restrictedTo' implicitly has an 'any' type. | TS2525 - Initializer provides no value for this binding element and the binding element has no default value. | TS7031 - Binding element 'edited' implicitly has an 'any' type.
					const { restrictedTo, edited } = !isOptimistic
						? this.getEditingAndRestrictedTo(restrictedToText, permalink)
						: {};

					const shouldHighlightComment = hasFloatingMenu
						? !isInternal && !isEditing && (isHighlighted || permalink.isVisible)
						: !isInternal && !isEditing && isHighlighted;

					const comment = (
						<AkComment
							avatar={
								<AvatarWrapper
									isThreadedComments={isThreadedCommentsEnabled}
									showLinesOnRoot={(hasReplies || isReplyingToComment) ?? false}
									isDeletedComment={isDeletedComment || false}
								>
									<WithProfileCard
										userAccount={isDeletedComment ? undefined : author}
										analyticsData={{ trigger: 'comment-avatar' }}
										testId={`issue-comment-base.ui.comment.${commentId}.avatar`}
										ariaHideProfileTrigger
										isChildComment={isThreadedCommentsEnabled && !!parentId}
									>
										<Avatar
											borderColor="transparent"
											src={(!isDeletedComment && author && author.avatarUrl) || undefined}
											size={showSmallAvatar ? 'small' : 'medium'}
										/>
									</WithProfileCard>
								</AvatarWrapper>
							}
							author={
								!isDeletedComment && (
									<WithProfileCard
										userAccount={author}
										analyticsData={{ trigger: 'comment-author' }}
									>
										<CommentAuthor>
											{this.getAuthorDisplayName(author)}
											{!expVal(
												'issue_view_activity_timeline',
												'isActivityTimelineEnabled',
												false,
											) && <>&nbsp;</>}
											{this.getAuthorAdditionalInfo()}
										</CommentAuthor>
									</WithProfileCard>
								)
							}
							time={this.getCommentTime()}
							edited={!isOptimistic ? edited : this.renderEditedOldWithOptimistic(permalink)}
							content={
								!isDeletedComment &&
								(!isEditing && fg('thor_ai_definitions_issue_comment') ? (
									<div ref={commentRef}>{this.getCommentContent()}</div>
								) : (
									<div>{this.getCommentContent()}</div>
								))
							}
							afterContent={
								// this is a workaround for an issue in @atlaskit/comment where it adds an extra padding even if "children" return null
								isThreadedCommentsEnabled &&
								!hasReplies &&
								!isReplyingToComment &&
								children && <CommentAfterContent isHighlighted={shouldHighlightComment} />
							}
							restrictedTo={this.getRestrictedTo(restrictedTo, restrictedToText)}
							actions={
								!isDeletedComment && !isOptimistic && !isEditing ? this.getCommentActions() : []
							}
							isError={hasSaveFailed && !isEditing}
							errorActions={
								hasFloatingMenu
									? this.getCommentSavedWithFloatingMenu()
									: this.getCommentSaveFailedActions()
							}
							isSaving={isOptimistic && !hasSaveFailed}
							savingText={this.props.intl.formatMessage(messages.saving)}
							highlighted={shouldHighlightComment}
							testId={`issue-comment-base.ui.comment.ak-comment.${commentId}`}
						>
							{isThreadedCommentsEnabled && children}
						</AkComment>
					);

					return !isOptimistic ? (
						<IssueCommentWrapper
							isRootComment={!this.props?.parentId}
							onMouseEnter={() =>
								fg('threaded_comments_old_checks') ? undefined : permalink.show()
							}
							onMouseLeave={() =>
								fg('threaded_comments_old_checks') ? undefined : permalink.hide()
							}
							onMouseOver={(e) =>
								fg('threaded_comments_old_checks') ? showPermalink(e, permalink) : undefined
							}
							onMouseOut={(e) =>
								fg('threaded_comments_old_checks') ? hidePermalink(e, permalink) : undefined
							}
							onFocus={(e) =>
								fg('threaded_comments_old_checks') ? showPermalink(e, permalink) : permalink.show()
							}
							onBlur={(e) =>
								fg('threaded_comments_old_checks') ? hidePermalink(e, permalink) : permalink.hide()
							}
						>
							<span
								data-testid={`comment-base-item-${commentId}`}
								ref={this.setCommentPermalinkContainer}
							>
								<ModalContextProvider>{comment}</ModalContextProvider>
							</span>
						</IssueCommentWrapper>
					) : (
						this.renderOptimisticComment(comment, permalink)
					);
				}}
			</PermalinkProvider>
		);
	};

	renderOptimisticComment = (comment: React.JSX.Element, permalink: Permalink) => {
		if (this.props.hasFloatingMenu && this.props.isThreadedCommentsEnabled) {
			return (
				<IssueCommentWrapper
					isRootComment={!this.props?.parentId}
					onMouseEnter={() => (fg('threaded_comments_old_checks') ? undefined : permalink.show())}
					onMouseLeave={() => (fg('threaded_comments_old_checks') ? undefined : permalink.hide())}
					onMouseOver={(e) =>
						fg('threaded_comments_old_checks') ? showPermalink(e, permalink) : undefined
					}
					onMouseOut={(e) =>
						fg('threaded_comments_old_checks') ? hidePermalink(e, permalink) : undefined
					}
					onFocus={(e) =>
						fg('threaded_comments_old_checks') ? showPermalink(e, permalink) : permalink.show()
					}
					onBlur={(e) =>
						fg('threaded_comments_old_checks') ? hidePermalink(e, permalink) : permalink.hide()
					}
				>
					<div ref={this.setOptimisticCommentPermalinkContainer}>{comment}</div>
				</IssueCommentWrapper>
			);
		}
		if (this.props.isThreadedCommentsEnabled) {
			return (
				<IssueCommentWrapper isRootComment={!this.props?.parentId}>
					<div ref={this.setOptimisticCommentPermalinkContainer}>{comment}</div>
				</IssueCommentWrapper>
			);
		}
		return fg('jira_comment_alignment_fix') ? (
			<Box
				xcss={optimisticNonThreadedCommentStyles}
				ref={this.setOptimisticCommentPermalinkContainer}
			>
				{comment}
			</Box>
		) : (
			<div ref={this.setOptimisticCommentPermalinkContainer}>{comment}</div>
		);
	};

	renderInternalComment = () => {
		const { visibility, intl, isEditing, isHighlighted } = this.props;
		const highlightColor = isHighlighted
			? token('color.background.accent.yellow.subtler')
			: token('color.background.accent.yellow.subtlest');
		const restrictedToText =
			visibility.type === COMMENT_VISIBILITY_TYPE_PUBLIC
				? `${intl.formatMessage(messages.internalNote)}`
				: `${visibility.value} • ${intl.formatMessage(messages.internalNote)}`;

		return (
			<InternalCommentContainer isEditing={isEditing} highlightColor={highlightColor}>
				{this.renderComment(restrictedToText)}
			</InternalCommentContainer>
		);
	};

	setCommentPermalinkContainer = (container: HTMLElement | null) => {
		this.commentPermalinkContainer = container;
	};

	setOptimisticCommentPermalinkContainer = (container: HTMLElement | null) => {
		this.optimisticCommentContainerRef = container;
	};

	commentPermalinkContainer: HTMLElement | null | undefined;

	scrollCommentIntoView = () => {
		const {
			hasScrolledPermalink,
			shouldScrollAfterAsyncComponentsLoaded,
			setScrollStatus,
			createAnalyticsEvent,
			commentId,
			topMostUnreadCommentId,
			commentScrollStatusForRealTimeUpdates,
			setScrollStatusForRealTimeUpdate,
		} = this.props;

		// hasScrolledPermalink ensures we only scroll once on initial page load.
		// ensure we don't scroll if we resize after initial load.
		// shouldScrollAfterAsyncComponentsLoaded used to ensure we only scroll when all async data is finished fetching.
		if (!hasScrolledPermalink && shouldScrollAfterAsyncComponentsLoaded) {
			smoothScrollIntoViewIfNeeded(this.commentPermalinkContainer, {
				scrollMode: 'always',
				behavior: 'smooth',
			});
			setScrollStatus(true);

			const analyticsEvent = createAnalyticsEvent({
				action: 'scrolled',
			});
			fireUIAnalytics(analyticsEvent, 'permalink comment', { name: 'comment' });
		}

		// scroll to unread comment during real time updates
		if (
			(this.props.projectType === SOFTWARE_PROJECT || this.props.projectType === CORE_PROJECT) &&
			commentScrollStatusForRealTimeUpdates &&
			commentId === topMostUnreadCommentId &&
			expVal('thor_issue_view_realtime_updates_experiment', 'isEnabled', false)
		) {
			smoothScrollIntoViewIfNeeded(this.unreadCommentRef, {
				scrollMode: 'always',
				behavior: 'smooth',
			});
			setScrollStatusForRealTimeUpdate?.(false);
		}
	};

	render() {
		const { visibility, isInternal } = this.props;

		if (isInternal) {
			return this.renderInternalComment();
		}
		return this.renderComment(visibility.value);
	}
}

const showPermalink = (e: SyntheticEvent, permalink: Permalink) => {
	e.stopPropagation();
	permalink.show();
};

const hidePermalink = (e: SyntheticEvent, permalink: Permalink) => {
	e.stopPropagation();
	permalink.hide();
};

const CommentWithIssueOfIncidentsPracticeAndAiDefinitions = (props: Props) => {
	const isIssueOfIncidentsPractice = useIsIssueOfIncidentsPractice();
	const commentRef = useRef(null);
	const { highlightAcronyms, resetAcronymsData } = useAcronymHighlighter(
		commentRef,
		FIELD_TYPE_COMMENT,
	);
	const { register, unregister } = useOnHighlight(commentRef, FIELD_TYPE_COMMENT);

	useEffect(() => {
		if (fg('thor_ai_definitions_issue_comment')) {
			if (commentRef.current && !props.isEditing) {
				highlightAcronyms(props.bodyAdf);
				return resetAcronymsData;
			}
		}
	}, [props.bodyAdf, highlightAcronyms, resetAcronymsData, props.isEditing]);

	useEffect(() => {
		if (fg('thor_ai_definitions_issue_comment')) {
			if (!props.isEditing) {
				register();
			}
			return unregister;
		}
	}, [unregister, register, props.isEditing]);

	return (
		<CommentBase
			{...props}
			commentRef={
				!props.isEditing && fg('thor_ai_definitions_issue_comment') ? commentRef : undefined
			}
			isIssueOfIncidentsPractice={fg('jsm-timelines-phase-2') ? isIssueOfIncidentsPractice : false}
		/>
	);
};

const CommentWithFeatureFlags = (props: Props) => {
	if (fg('thor_ai_definitions_issue_comment') || fg('jsm-timelines-phase-2')) {
		return <CommentWithIssueOfIncidentsPracticeAndAiDefinitions {...props} />;
	}
	return <CommentBase {...props} />;
};

const CommentswithCreateIssueFromComment = (props: Props) => {
	const { author, fullIssueUrl, bodyAdf } = props;
	const { onClickCreateIssue, currentUserAccountId } = useOpenGICFromComment({
		author,
		fullIssueUrl,
		bodyAdf,
	});

	return (
		<CommentWithFeatureFlags
			{...props}
			onClickCreateIssue={onClickCreateIssue}
			currentUserAccountId={currentUserAccountId}
		/>
	);
};

const CommentWithCreateIssueFromCommentFeatureFlags = (props: Props) => {
	const isTargetedForCreateIssueFromCommentExp = useIsTargetedForCreateIssueFromCommentExp();

	if (!isTargetedForCreateIssueFromCommentExp) {
		return <CommentWithFeatureFlags {...props} hasFloatingMenu={false} />;
	}

	return expVal('issue_create_from_comment_experiment', 'hasIssueCreate', false) ? (
		<CommentswithCreateIssueFromComment {...props} hasFloatingMenu />
	) : (
		<CommentWithFeatureFlags
			{...props}
			hasFloatingMenu={expVal('issue_create_from_comment_experiment', 'hasFloatingMenu', false)}
		/>
	);
};

export const Comment = CommentWithCreateIssueFromCommentFeatureFlags;
export default Comment;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GridContainer = styled.span({
	gridRow: 1,
	marginLeft: token('space.050'),
	whiteSpace: 'nowrap',
});

GridContainer.displayName = 'GridContainer';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GridContentContainer = styled.span({
	display: 'grid',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GridContentContainerVisualRefresh = styled.span({
	display: 'grid',
	alignItems: 'center',
});

GridContentContainer.displayName = 'GridContentContainer';
GridContentContainerVisualRefresh.displayName = 'GridContentContainer';

// TODO https://product-fabric.atlassian.net/browse/FS-4202
// Ideally we would style the Reactions component itself, but currently
// AK doesn't allow us to do it. So we're wrapping the component on this div
// and pulling it to the left as much as we can without covering the item separator (".")
// we're also giving it a specific height so the item separator will be properly aligned.

const AvatarWrapper: FC<
	PropsWithChildren<{
		showLinesOnRoot: boolean;
		isThreadedComments: boolean;
		isDeletedComment: boolean;
	}>
> = ({ showLinesOnRoot, children, isThreadedComments, isDeletedComment }) => {
	const { isActivityInVerticalSidePanel } = useIssueLayoutActivitySidePanel();
	if (isThreadedComments) {
		return (
			<div
				css={[
					profileCardWrapperStyles,
					showLinesOnRoot && rootCommentStyles,
					showLinesOnRoot &&
						isActivityInVerticalSidePanel &&
						rootCommentStylesOverrideForSmallAvatar,
					isDeletedComment && deletedCommentStyles,
				]}
			>
				{children}
			</div>
		);
	}
	return <>{children}</>;
};

const deletedCommentStyles = css({
	marginBottom: token('space.150'),
});

const profileCardWrapperStyles = css({
	position: 'relative',
	'--commentsLineWidth': '1px',
	'--commentLineColor': token('color.background.accent.gray.subtler'),
	height: '100%',
});

const rootCommentStyles = css({
	'&::before': {
		content: '',
		position: 'absolute',
		insetInlineStart: 'calc(50% - var(--commentsLineWidth)/2)',
		bottom: token('space.0'),
		width: 'var(--commentsLineWidth)',
		top: '46.5px', // From top = 2(padding) + 32(avatar) + 8(padding) + 4.5px
		backgroundColor: 'var(--commentLineColor)',
	},
});

const rootCommentStylesOverrideForSmallAvatar = css({
	'&::before': {
		top: '38.5px', // From top = 2(padding) + 24(avatar) + 8(padding) + 4.5px
		bottom: '-8px',
	},
});

const optimisticNonThreadedCommentStyles = xcss({
	marginLeft: 'space.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReactionsContainer = styled.div<{ isOverflowReactionsFixEnabled: boolean }>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	({ isOverflowReactionsFixEnabled }) =>
		isOverflowReactionsFixEnabled
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
				{ minHeight: `${gridSize * 2.5}px` }
			: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
				{ height: `${gridSize * 2.5}px` },

	{
		marginBottom: token('space.050'),
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentWrapper = styled.div({
	marginTop: token('space.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:first-child, &:first-of-type': {
		padding: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentWrapperVisualRefresh = styled.div<{ isRootComment: boolean }>(
	{ marginTop: token('space.100'), marginBottom: token('space.100') },

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	({ isRootComment }) =>
		isRootComment && {
			marginRight: token('space.100'),
			marginLeft: token('space.100'),
			'&:first-child, &:first-of-type': {
				padding: 0,
			},
		},
);

const IssueCommentWrapper = componentWithCondition(
	() => isVisualRefreshEnabled(),
	CommentWrapperVisualRefresh,
	CommentWrapper,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LozengeWrapper = styled.div({
	display: 'inline-flex',
	marginLeft: token('space.150'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DescriptionAction = styled.div({
	display: 'inline-block',
	/* so styling lines up with other feeds */
	marginLeft: token('space.negative.050'),
	marginRight: token('space.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> span': {
		fontWeight: token('font.weight.medium'),
	},
});

const unreadCss = xcss({
	height: token('space.075'),
	width: token('space.075'),
	borderRadius: 'border.radius.circle',
	backgroundColor: 'color.background.accent.blue.bolder',
	transition: 'all 0.2s ease-in-out',
});

const unreadNewBadgeCss = xcss({
	textAlign: 'center',
	font: 'font.body.small',
	color: 'color.text.inverse',
	width: token('space.500'),
	height: token('space.200'),
	borderRadius: 'border.radius.200',
});
