import React from 'react';
import type {
	IssueViewRelayFragment,
	MainIssueAggQueryRelayFragment,
} from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import {
	JSD_TEMPLATE,
	JWM_TEMPLATE,
	JCS_TEMPLATE,
} from '@atlassian/jira-issue-view-layout-templates-constants/src/index.tsx';
import JCSContentTemplate from '@atlassian/jira-issue-view-layout-templates-customer-service/src/ui/content/index.tsx';
import JCSContextTemplate from '@atlassian/jira-issue-view-layout-templates-customer-service/src/ui/context/index.tsx';
import JCSFoundationContentTemplate from '@atlassian/jira-issue-view-layout-templates-customer-service/src/ui/foundation-content/index.tsx';
import JCSFoundationContextTemplate from '@atlassian/jira-issue-view-layout-templates-customer-service/src/ui/foundation-context/index.tsx';
import ContentTemplate from '@atlassian/jira-issue-view-layout-templates-default/src/ui/content/index.tsx';
import ContextTemplate from '@atlassian/jira-issue-view-layout-templates-default/src/ui/context/index.tsx';
import FoundationContentTemplate from '@atlassian/jira-issue-view-layout-templates-default/src/ui/foundation-content/index.tsx';
import FoundationContextTemplate from '@atlassian/jira-issue-view-layout-templates-default/src/ui/foundation-context/index.tsx';
import JSDContentTemplate from '@atlassian/jira-issue-view-layout-templates-servicedesk/src/ui/content/index.tsx';
import JSDContextTemplate from '@atlassian/jira-issue-view-layout-templates-servicedesk/src/ui/context/index.tsx';
import JSDFoundationContentTemplate from '@atlassian/jira-issue-view-layout-templates-servicedesk/src/ui/foundation-content/index.tsx';
import JSDFoundationContextTemplate from '@atlassian/jira-issue-view-layout-templates-servicedesk/src/ui/foundation-context/index.tsx';
import { useTemplateRendererKey } from './services/index.tsx';

// All dependencies must be obtained internally and not prop injected except for Relay. We should be able to mount these templates anywhere.
export const FoundationContentTemplateRenderer = ({
	issueViewRelayFragment,
}: {
	// TODO Decomp JIV-12514 - add useFragment to this component and replace this prop with more specific fragment key
	issueViewRelayFragment?: IssueViewRelayFragment | null;
}) => {
	const templateKey = useTemplateRendererKey();

	switch (templateKey) {
		case JSD_TEMPLATE:
			return <JSDFoundationContentTemplate issueViewRelayFragment={issueViewRelayFragment} />;
		case JCS_TEMPLATE:
			return <JCSFoundationContentTemplate issueViewRelayFragment={issueViewRelayFragment} />;
		default:
			return <FoundationContentTemplate issueViewRelayFragment={issueViewRelayFragment} />;
	}
};

type FoundationContextTemplateRendererProps = {
	// TODO Decomp BENTO-12514 - add useFragment to this component and replace this prop with more specific fragment key
	issueViewRelayFragment?: IssueViewRelayFragment | null;
	isStatusOnLHS?: boolean;
	isStatusPositionExperimentEnabled?: boolean;
};
// All dependencies must be obtained internally and not prop injected except for Relay. We should be able to mount these templates anywhere.
export const FoundationContextTemplateRenderer = ({
	issueViewRelayFragment,
	isStatusPositionExperimentEnabled = false,
	isStatusOnLHS = false,
}: FoundationContextTemplateRendererProps) => {
	const templateKey = useTemplateRendererKey();
	let Template: React.FunctionComponent<FoundationContextTemplateRendererProps>;
	switch (templateKey) {
		case JSD_TEMPLATE:
		case JWM_TEMPLATE:
			Template = JSDFoundationContextTemplate;
			break;
		case JCS_TEMPLATE:
			Template = JCSFoundationContextTemplate;
			break;
		default:
			Template = FoundationContextTemplate;
	}

	return (
		<Template
			isStatusPositionExperimentEnabled={isStatusPositionExperimentEnabled}
			issueViewRelayFragment={issueViewRelayFragment}
			isStatusOnLHS={isStatusOnLHS}
		/>
	);
};

type ContentTemplateRendererProps = {
	// TODO Decomp BENTO-12514 - add useFragment to this component and replace this prop with more specific fragment key
	issueViewRelayFragment?: IssueViewRelayFragment | null;
	rootRelayFragment: MainIssueAggQueryRelayFragment | null;
};
// All dependencies must be obtained internally and not prop injected except for Relay. We should be able to mount these templates anywhere.
export const ContentTemplateRenderer = ({
	issueViewRelayFragment,
	rootRelayFragment,
}: ContentTemplateRendererProps) => {
	const templateKey = useTemplateRendererKey();

	switch (templateKey) {
		case JSD_TEMPLATE:
			return (
				<JSDContentTemplate
					issueViewRelayFragment={issueViewRelayFragment}
					rootRelayFragment={rootRelayFragment}
				/>
			);
		case JCS_TEMPLATE:
			return (
				<JCSContentTemplate
					issueViewRelayFragment={issueViewRelayFragment}
					rootRelayFragment={rootRelayFragment}
				/>
			);
		default:
			return (
				<ContentTemplate
					issueViewRelayFragment={issueViewRelayFragment}
					rootRelayFragment={rootRelayFragment}
				/>
			);
	}
};

type ContextTemplateRendererProps = {
	// TODO Decomp BENTO-12514 - add useFragment to this component and replace this prop with more specific fragment key
	issueViewRelayFragment?: IssueViewRelayFragment | null;
	containerElement?: HTMLElement | null;
	rootRelayFragment: MainIssueAggQueryRelayFragment | null;
};

// All dependencies must be obtained internally and not prop injected except for Relay. We should be able to mount these templates anywhere.
export const ContextTemplateRenderer = ({
	issueViewRelayFragment,
	rootRelayFragment,
	containerElement,
}: ContextTemplateRendererProps) => {
	const templateKey = useTemplateRendererKey();

	switch (templateKey) {
		case JSD_TEMPLATE:
			return (
				<JSDContextTemplate
					issueViewRelayFragment={issueViewRelayFragment}
					rootRelayFragment={rootRelayFragment}
				/>
			);
		case JCS_TEMPLATE:
			return (
				<JCSContextTemplate
					issueViewRelayFragment={issueViewRelayFragment}
					rootRelayFragment={rootRelayFragment}
				/>
			);
		default:
			return (
				<ContextTemplate
					issueViewRelayFragment={issueViewRelayFragment}
					rootRelayFragment={rootRelayFragment}
					containerElement={containerElement}
				/>
			);
	}
};
