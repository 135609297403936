import React, { useState } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import {
	SERVICEDESK_QUEUES_ISSUE,
	SERVICEDESK_REPORTS_ISSUE,
} from '@atlassian/jira-common-constants/src/analytics-sources.tsx';
import { ExperienceSuccessTracker as ViewExperienceSuccessTracker } from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracker-consumer/result-declared/index.tsx';
import { SubExperienceTrackingProvider as ViewSubExperienceTrackingProvider } from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracking-provider/index.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import {
	useAnalyticsSource,
	useIssueKey,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import type {
	IssueViewRelayFragment,
	MainIssueAggQueryRelayFragment,
} from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { CONTENT_REQUEST_AREA } from '@atlassian/jira-issue-layout-common-constants/src/index.tsx';
import { PROJECT_ARCHIVED } from '@atlassian/jira-issue-shared-types/src/common/types/project-type.tsx';
import { ItemList } from '@atlassian/jira-issue-view-layout-templates-item-list/src/index.tsx';
import {
	useProjectContext,
	useProjectKey,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { appBundleReadyMark } from '@atlassian/jira-providers-spa-apdex-analytics/src/marks.tsx';

import GlobalSpotlightTargetDeprecated from '@atlassian/jira-servicedesk-common/src/ui/global-spotlight-target/index.tsx';
import { JsmPageInteractiveEvent } from '@atlassian/jira-servicedesk-common/src/utils/browser-metric/index.tsx';
import { useRequestPanelMetadata } from '@atlassian/jira-servicedesk-request-details-panel-services/src/services/fetch-panel-metadata/index.tsx';
import {
	getMetricKey,
	getMetric,
} from '@atlassian/jira-servicedesk-request-details-panel-services/src/services/metric/index.tsx';
import { toItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/main.tsx';
import { PREMIUM_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { RouterSubscriber } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import { REPORTER_SPOTLIGHT } from './common/constants.tsx';
import { ContentSectionExpander } from './ui/expander/index.tsx';
import { GenericError } from './ui/generic-error/index.tsx';
import { HelpCenter } from './ui/help-center/index.tsx';
import { ReporterAndChannel } from './ui/reporter-and-channel/index.tsx';

export type StateProps = {
	isIssueViewLoading: boolean;
};
export type OwnProps = {
	// TODO Decomp JIV-12514 - Bento team will add useFragment to this component and replace this prop with more specific fragment key
	issueViewRelayFragment?: IssueViewRelayFragment | null;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	requestPanelItems: any[];
	rootRelayFragment: MainIssueAggQueryRelayFragment | null;
};
export type Props = OwnProps & StateProps;

const RequestDetailsPanel = ({
	requestPanelItems,
	isIssueViewLoading,
	issueViewRelayFragment,
	rootRelayFragment,
}: Props) => {
	const hasRequestPanelItems = requestPanelItems.length > 0;

	const [isExpanded, setExpanded] = useState(true);
	const [data] = useRequestPanelMetadata();
	// @ts-expect-error - TS2571 - Object is of type 'unknown'.
	const requestUrl = data?.requestUrl ?? '';

	const analyticsSource = useAnalyticsSource();

	const isInServiceDeskQueue =
		typeof analyticsSource === 'string' ? analyticsSource === SERVICEDESK_QUEUES_ISSUE : false;
	const isInServiceDeskReport =
		typeof analyticsSource === 'string' ? analyticsSource === SERVICEDESK_REPORTS_ISSUE : false;

	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectContext = useProjectContext(projectKey);

	const helpCenter = () =>
		projectContext?.projectStatus !== PROJECT_ARCHIVED && <HelpCenter link={requestUrl} />;

	const detailsHeader = (
		<>
			<ViewExperienceSuccessTracker location="panel-header-expand">
				<ExpanderWrapper>
					{fg('return_data_for_request_details_in_jsm') ? (
						/* @ts-expect-error - TS2571 - Object is of type 'unknown'. */
						<ReporterAndChannel channel={data.channel} />
					) : (
						/* @ts-expect-error - TS2571 - Object is of type 'unknown'. */
						<ReporterAndChannel channel={data?.channel} />
					)}
					{hasRequestPanelItems && (
						<ContentSectionExpander
							isContentExpanded={isExpanded}
							onExpand={() => setExpanded(true)}
							onCollapse={() => setExpanded(false)}
						/>
					)}
				</ExpanderWrapper>
			</ViewExperienceSuccessTracker>

			{((hasRequestPanelItems && isExpanded) || !hasRequestPanelItems) &&
				requestUrl &&
				helpCenter()}
		</>
	);

	return (
		<ViewSubExperienceTrackingProvider location="requestDetailsPanel">
			<RequestContentContainer isExpanded={isExpanded} hasContentItems={hasRequestPanelItems}>
				{isIssueViewLoading === false &&
					(isInServiceDeskQueue || isInServiceDeskReport) &&
					__SPA__ && (
						<RouterSubscriber>
							{({ match }) => (
								<JsmPageInteractiveEvent
									metricKey={getMetricKey(
										match && match.params && match.params.practiceType,
										analyticsSource,
									)}
									withMarks={[appBundleReadyMark]}
									metric={getMetric(analyticsSource)}
									extra={{
										category: match && match.params && toItsmPractice(match.params.practiceType),
									}}
								/>
							)}
						</RouterSubscriber>
					)}
				{/* NOTE: This GlobalSpotlightTarget (deprecated) can be used throughout Jira not just within
				 * the current package. They're often used for onboarding or changeboarding
				 * experiences and allow an Atlaskit spotlight to be attached to page elements.
				 * If you need to change this, make sure you check where the target ID is being used
				 * and contact the relevant team as necessary. This Sourcegraph link should help :)
				 * http://go/src/REPORTER_SPOTLIGHT|reporter-spotlight
				 */}
				<GlobalSpotlightTargetDeprecated id={REPORTER_SPOTLIGHT}>
					{detailsHeader}
				</GlobalSpotlightTargetDeprecated>
				{isExpanded && (
					<FieldsContainer hasContentItems={hasRequestPanelItems}>
						<ItemList
							items={requestPanelItems}
							area={CONTENT_REQUEST_AREA}
							layoutItemsDataFragment={issueViewRelayFragment ?? null}
							rootRelayFragment={rootRelayFragment}
						/>
					</FieldsContainer>
				)}
			</RequestContentContainer>
		</ViewSubExperienceTrackingProvider>
	);
};

const RequestDetailsPanelWithErrorBoundary = (props: Props) => {
	const { serviceDesk: serviceDeskEdition } = useAppEditions();

	return (
		<JSErrorBoundary
			id="requestDetailsPanel"
			packageName="jiraServicedeskRequestDetailsPanel"
			attributes={{ isJsmPremium: serviceDeskEdition === PREMIUM_EDITION }}
			fallback={() => (
				<RequestContentContainer isExpanded hasContentItems={false}>
					<GenericError />
				</RequestContentContainer>
			)}
		>
			<RequestDetailsPanel {...props} />
		</JSErrorBoundary>
	);
};

export default RequestDetailsPanelWithErrorBoundary;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RequestContentContainer = styled.div<{
	isExpanded: boolean;
	hasContentItems: boolean;
}>(
	{
		marginTop: token('space.100'),
		marginBottom: token('space.300'),
		display: 'flex',
		flexDirection: 'column',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		borderRadius: `${gridSize / 2}px`,
		borderWidth: '1px',
		borderStyle: 'solid',
		borderColor: `${token('color.border')}`,

		backgroundColor: token('elevation.surface'),
		paddingTop: token('space.200'),
		paddingRight: token('space.200'),
		paddingBottom: token('space.200'),
		paddingLeft: token('space.200'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isExpanded, hasContentItems }) =>
		isExpanded && hasContentItems ? { paddingBottom: 0 } : undefined,
);
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const FieldsContainer = styled.div<{ hasContentItems: boolean }>(({ hasContentItems }) =>
	hasContentItems ? { marginTop: token('space.200') } : undefined,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const visibleForTesting = { RequestContentContainer, FieldsContainer };

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ExpanderWrapper = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
});
