import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	flagIconLabel: {
		id: 'issue.issue-view-foundation.status.flagged-field-popup.flag-icon-label',
		defaultMessage: 'Flag',
		description: 'Flag icon label',
	},
	titleAddFlagComment: {
		id: 'issue.issue-view-foundation.status.flagged-field-popup.title-add-flag-comment',
		defaultMessage: 'Add a comment about the flag',
		description: 'Title for flag field popup, when adding a flag',
	},
	titleRemoveFlagComment: {
		id: 'issue.issue-view-foundation.status.flagged-field-popup.title-remove-flag-comment',
		defaultMessage: 'Why are you removing the flag?',
		description: 'Title for the flag popup when removing a flag',
	},
	placeholderAddFlagComment: {
		id: 'issue.issue-view-foundation.status.flagged-field-popup.placeholder-add-flag-comment',
		defaultMessage: 'Add a comment about the flag',
		description: 'Placeholder for flag field popup, when adding a flag',
	},
	placeholderRemoveFlagComment: {
		id: 'issue.issue-view-foundation.status.flagged-field-popup.placeholder-remove-flag-comment',
		defaultMessage: 'Was the flag solved? Does someone deserve a shout out?',
		description: 'Placeholder for the flag popup when removing a flag',
	},
	buttonCancel: {
		id: 'issue.issue-view-foundation.status.flagged-field-popup.button-cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel button text for the flag popup',
	},
	buttonConfirm: {
		id: 'issue.issue-view-foundation.status.flagged-field-popup.button-confirm',
		defaultMessage: 'Confirm',
		description: 'Confirm button text for the flag popup',
	},
	addFlagFailedTitle: {
		id: 'issue.issue-view-foundation.status.flagged-field-popup.add-flag-failed-title',
		defaultMessage: "We couldn't flag this issue",
		description:
			'Title for the error message when the there is an error adding a flag (impediment) to the issue',
	},
	addFlagFailedDescription: {
		id: 'issue.issue-view-foundation.status.flagged-field-popup.add-flag-failed-description',
		defaultMessage: 'Check your connection and try adding the flag again.',
		description:
			'Description for the error message when the there is an error adding a flag (impediment) to the issue',
	},
	removeFlagFailedTitle: {
		id: 'issue.issue-view-foundation.status.flagged-field-popup.remove-flag-failed-title',
		defaultMessage: "We couldn't remove the flag from this issue",
		description:
			'Title for the error message when the there is an error removing the flag (impediment) from the issue',
	},
	removeFlagFailedDescription: {
		id: 'issue.issue-view-foundation.status.flagged-field-popup.remove-flag-failed-description',
		defaultMessage: 'Check your connection and try removing the flag again.',
		description:
			'Description for the error message when the there is an error removing the flag (impediment) from the issue',
	},
	addFlagFailedTitleIssueTermRefresh: {
		id: 'issue.issue-view-foundation.status.flagged-field-popup.add-flag-failed-title-issue-term-refresh',
		defaultMessage: "We couldn't flag this work item",
		description:
			'Title for the error message when the there is an error adding a flag (impediment) to the issue',
	},
	removeFlagFailedTitleIssueTermRefresh: {
		id: 'issue.issue-view-foundation.status.flagged-field-popup.remove-flag-failed-title-issue-term-refresh',
		defaultMessage: "We couldn't remove the flag from this work item",
		description:
			'Title for the error message when the there is an error removing the flag (impediment) from the issue',
	},
});
