import { useCallback, useEffect, useState } from 'react';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import {
	getUserProperty,
	setUserProperties,
} from '@atlassian/jira-common-rest/src/api/latest/user-properties/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import {
	useIsOptoutAllMessagesSite,
	useIsOptoutAllMessagesSiteUser,
} from '@atlassian/jira-loom-utils/src/services/traits/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { useTraitsActions } from '@atlassian/jira-traits/src/TraitsStore.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createHook, type Action } from '@atlassian/react-sweet-state';

const localAdControlStore = createLocalStorageProvider('jira_loom_ad_controls');

interface State {
	adControlDismissed: {
		[adControlKey: string]: boolean;
	};
	loading: boolean;
}

const initialState: State = {
	loading: false, // loading when useLoomAdControls is rendered
	adControlDismissed: {},
};

interface InitLoomAdControlsProps {
	areProductSuggestionsEnabled: boolean;
	atlassianAccountId: string | null;
	adControlKeyWithCloudId: string;
	adControlKey: string;
}

const actions = {
	initLoomAdControls:
		({
			areProductSuggestionsEnabled,
			atlassianAccountId,
			adControlKeyWithCloudId,
			adControlKey,
		}: InitLoomAdControlsProps): Action<State> =>
		async ({ getState, setState }) => {
			const { adControlDismissed } = getState();
			if (!areProductSuggestionsEnabled || !atlassianAccountId) {
				setState({
					loading: false,
					adControlDismissed: {
						...adControlDismissed,
						[adControlKeyWithCloudId]: true,
					},
				});
				return;
			}
			// Async fetching of adcontrol settings via local storage or userProperties API
			const { loading } = getState();
			if (loading) {
				return;
			}
			setState({ loading: true });
			const adControlSetting = await getLoomAdControl(
				atlassianAccountId,
				adControlKeyWithCloudId,
				adControlKey,
			);
			setState({
				loading: false,
				adControlDismissed: {
					...adControlDismissed,
					[adControlKeyWithCloudId]: adControlSetting,
				},
			});
		},
	setLoomAdControl:
		(adControlKeyWithCloudId: string, dismissed: boolean): Action<State> =>
		({ getState, setState }) => {
			const { adControlDismissed } = getState();
			setState({
				adControlDismissed: {
					...adControlDismissed,
					[adControlKeyWithCloudId]: dismissed,
				},
			});
		},
} as const;

type Actions = typeof actions;

const store = createStore<State, Actions>({
	name: 'loom-utils.loom-ad-controls',
	initialState,
	actions,
});

const useLoomAdControlsStore = createHook(store);

const setLocalAdControl = (
	adControlKeyWithCloudId: string,
	dismissed: boolean,
	adControlKey: string,
) => {
	// Log failed instances of saving to local storage without altering users experience
	try {
		localAdControlStore.set(adControlKeyWithCloudId, dismissed);
	} catch (error) {
		// log error
		fireErrorAnalytics({
			error: error instanceof Error ? error : new Error('Failed to set local ad control'),
			meta: {
				id: 'setLocalAdControl',
				packageName: 'jiraLoomUtils',
				teamName: 'cross-flow-growth',
			},
			attributes: {
				adControlKey,
				adControlKeyWithCloudId,
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}
};

const getLoomAdControl = async (
	accountId: string,
	adControlKeyWithCloudId: string,
	adControlKey: string,
) => {
	try {
		const localResult = localAdControlStore.get(adControlKeyWithCloudId);
		if (localResult !== undefined) {
			return localResult;
		}
		const result = await getUserProperty<boolean>(accountId, adControlKeyWithCloudId);
		setLocalAdControl(adControlKeyWithCloudId, result, adControlKey);
		return result;
	} catch (error) {
		// If not found, then the user hasn't dismissed the ad.
		if (error instanceof FetchError && error.statusCode === 404) {
			setLocalAdControl(adControlKeyWithCloudId, false, adControlKey);
			return false;
		}
		fireErrorAnalytics({
			error: error instanceof Error ? error : new Error('Failed to get loom ad control'),
			meta: {
				id: 'getLoomAdControl',
				packageName: 'jiraLoomUtils',
				teamName: 'cross-flow-growth',
			},
			attributes: {
				adControlKey,
				adControlKeyWithCloudId,
			},
			sendToPrivacyUnsafeSplunk: true,
		});
		// Assume dismissed ad if there is an error
		return true;
	}
};

const dismissLoomAd = async (
	accountId: string,
	adControlKeyWithCloudId: string,
	adControlKey: string,
) => {
	try {
		setLocalAdControl(adControlKeyWithCloudId, true, adControlKey);
		await setUserProperties(accountId, adControlKeyWithCloudId, JSON.stringify(true));
	} catch (error) {
		fireErrorAnalytics({
			error: error instanceof Error ? error : new Error('Failed to dismiss loom ad'),
			meta: {
				id: 'dismissLoomAd',
				packageName: 'jiraLoomUtils',
				teamName: 'cross-flow-growth',
			},
			attributes: {
				adControlKey,
				adControlKeyWithCloudId,
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}
};

const useLoomTapTraitCheck = (cloudId: string, accountId: string | null) => {
	const { fetchTraits } = useTraitsActions();
	const [siteOptOut] = useIsOptoutAllMessagesSite();
	const [siteUserOptOut] = useIsOptoutAllMessagesSiteUser();

	useEffect(() => {
		fetchTraits(['SITE', 'SITE_USER'], { cloudId, accountId });
	}, [accountId, cloudId, fetchTraits]);

	return siteOptOut || siteUserOptOut;
};

export const useLoomAdControls = (adControlKey: string) => {
	const [userHasDismissedAd, setUserHasDismissedAd] = useState(true);
	const { atlassianAccountId, areProductSuggestionsEnabled, cloudId } = useTenantContext();
	const adControlKeyWithCloudId = `${adControlKey}-${cloudId}`;
	const [{ adControlDismissed }, { initLoomAdControls, setLoomAdControl }] =
		useLoomAdControlsStore();
	const tapOptOut = useLoomTapTraitCheck(cloudId, atlassianAccountId);

	useEffect(() => {
		if (adControlDismissed[adControlKeyWithCloudId] === undefined) {
			initLoomAdControls({
				atlassianAccountId,
				areProductSuggestionsEnabled,
				adControlKeyWithCloudId,
				adControlKey,
			});
		} else {
			setUserHasDismissedAd(adControlDismissed[adControlKeyWithCloudId] ?? true);
		}
	}, [
		atlassianAccountId,
		areProductSuggestionsEnabled,
		adControlDismissed,
		initLoomAdControls,
		adControlKeyWithCloudId,
		adControlKey,
	]);

	const dismissAd = useCallback(async () => {
		if (atlassianAccountId) {
			setUserHasDismissedAd(true);
			setLoomAdControl(adControlKeyWithCloudId, true);
			await dismissLoomAd(atlassianAccountId, adControlKeyWithCloudId, adControlKey);
		}
	}, [adControlKeyWithCloudId, atlassianAccountId, setLoomAdControl, adControlKey]);

	const adOptOut = tapOptOut || userHasDismissedAd;

	return { userHasDismissedAd: adOptOut, dismissAd };
};
