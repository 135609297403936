const DevOpsAppRecommendationContextProvider = importCond<
	typeof import('@atlassian/jira-dev-ops-app-recommendations-context-provider/src/index.tsx'),
	typeof import('./children-renderer.tsx')
>(
	'link_paste_recommendations',
	'@atlassian/jira-dev-ops-app-recommendations-context-provider/src/index.tsx',
	'./children-renderer.tsx',
);

export default DevOpsAppRecommendationContextProvider;
