import { expVal } from '@atlassian/jira-feature-experiments';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	type Action,
	createStore,
	createContainer,
	createStateHook,
	createActionsHook,
	createHook,
} from '@atlassian/react-sweet-state';

export type RelatedIssue = {
	id: string;
	issueKey: IssueKey;
	issueLink: string;
	issueTypeIconUrl: string;
	issueSummary: string;
};

export type ResourceSuggestion = {
	resource: RelatedIssue;
	errorMessage?: unknown;
	resourceStatus: 'DRAFT' | 'ACCEPTED' | 'DISCARDED';
};

/**
 * On proactive-jira-ai-similar-issues experiment cleanup, remove hidden step
 */
export type Step = 'HIDDEN' | 'FETCHING' | 'LOADED' | 'DISPLAY';
export const steps = {
	hidden: 'HIDDEN',
	fetching: 'FETCHING',
	display: 'DISPLAY',
	loaded: 'LOADED',
} as const;

export type InvokedFrom =
	| 'linkedWorkItemQuickAdd'
	| 'improveIssueDropdown'
	| 'issueLinksAddButton'
	| null;

// State of the Related issues context
type State = {
	currentStep: Step;
	resourceSuggestions?: ResourceSuggestion[];
	isLinkedIssuePanelButtonTriggered: boolean;
	errorMessage: unknown | null;
	displayLinkedIssuesPanelCallback?: (arg: boolean) => void;
	invokedFrom: InvokedFrom;
};

const initialState: State = {
	currentStep: steps.hidden,
	resourceSuggestions: undefined,
	isLinkedIssuePanelButtonTriggered: false,
	errorMessage: null,
	invokedFrom: null,
};

const setRelatedIssuesStep =
	(step: Step): Action<State> =>
	({ setState }) => {
		setState({ currentStep: step });
	};

const setInvokedFrom =
	(invokedFrom: InvokedFrom): Action<State> =>
	({ setState }) => {
		setState({ invokedFrom });
	};

const setRelatedIssuesFetchStep =
	(): Action<State> =>
	({ getState, setState }) => {
		const { displayLinkedIssuesPanelCallback } = getState();
		displayLinkedIssuesPanelCallback?.(true);
		setState({ currentStep: steps.fetching });
	};

const setRelatedIssuesDisplayStep =
	(): Action<State> =>
	({ setState }) => {
		setState({ currentStep: steps.display });
	};

const setRelatedIssuesLoadedStep =
	(): Action<State> =>
	({ setState }) => {
		setState({ currentStep: steps.loaded });
	};

const setIsLinkedIssuePanelButtonTriggered =
	(linkedIssuePanel: boolean): Action<State> =>
	({ setState }) => {
		setState({ isLinkedIssuePanelButtonTriggered: linkedIssuePanel });
	};

const isRelatedIssuesOpen =
	(): Action<State, void, Boolean> =>
	({ getState }) => {
		const { currentStep } = getState();
		return currentStep !== steps.hidden;
	};

const setResourceSuggestion =
	(resources: RelatedIssue[]): Action<State> =>
	({ getState, setState }) => {
		const { currentStep } = getState();
		const resourceSuggestions: ResourceSuggestion[] = resources.map((resource) => ({
			resource,
			resourceStatus: 'DRAFT',
		}));
		if (expVal('proactive-jira-ai-similar-issues', 'isEnabled', false)) {
			setState({ resourceSuggestions, currentStep: steps.loaded });
		} else if (currentStep === steps.fetching) {
			setState({ resourceSuggestions, currentStep: steps.display });
		}
	};

const setErrorMessage =
	(message: unknown): Action<State> =>
	({ getState, setState }) => {
		const { currentStep } = getState();
		if (expVal('proactive-jira-ai-similar-issues', 'isEnabled', false)) {
			setState({ errorMessage: message, currentStep: steps.loaded });
		} else if (currentStep === steps.fetching) {
			setState({ errorMessage: message, currentStep: steps.display });
		}
	};

const acceptSuggestion =
	(suggestion: RelatedIssue): Action<State> =>
	({ setState, getState }) => {
		const { resourceSuggestions } = getState();
		const updatedSuggestions: ResourceSuggestion[] | undefined = resourceSuggestions?.map(
			(resourceSuggestion) => {
				if (resourceSuggestion.resource.id === suggestion.id) {
					return {
						...resourceSuggestion,
						resourceStatus: 'ACCEPTED',
					};
				}
				return resourceSuggestion;
			},
		);

		setState({
			resourceSuggestions: updatedSuggestions,
		});
	};

const acceptAllSuggestions =
	(): Action<State> =>
	({ setState, getState }) => {
		const { resourceSuggestions, displayLinkedIssuesPanelCallback } = getState();
		const updatedSuggestions: ResourceSuggestion[] | undefined = resourceSuggestions?.map(
			(resourceSuggestion) => {
				return {
					...resourceSuggestion,
					resourceStatus: 'ACCEPTED',
				};
			},
		);
		displayLinkedIssuesPanelCallback?.(false);
		setState({
			resourceSuggestions: updatedSuggestions,
			currentStep: expVal('proactive-jira-ai-similar-issues', 'isEnabled', false)
				? steps.loaded
				: steps.hidden,
		});
	};

const discardSuggestion =
	(suggestion: RelatedIssue): Action<State> =>
	({ setState, getState }) => {
		const { resourceSuggestions } = getState();
		const updatedSuggestions: ResourceSuggestion[] | undefined = resourceSuggestions?.map(
			(resourceSuggestion) => {
				if (resourceSuggestion.resource.id === suggestion.id) {
					return {
						...resourceSuggestion,
						resourceStatus: 'DISCARDED',
					};
				}
				return resourceSuggestion;
			},
		);
		setState({
			resourceSuggestions: updatedSuggestions,
		});
	};

const discardAllSuggestions =
	(): Action<State> =>
	({ getState, setState }) => {
		const { displayLinkedIssuesPanelCallback } = getState();
		displayLinkedIssuesPanelCallback?.(false);
		setState({
			currentStep: expVal('proactive-jira-ai-similar-issues', 'isEnabled', false)
				? steps.loaded
				: steps.hidden,
			resourceSuggestions: undefined,
		});
	};

const resetState =
	(): Action<State> =>
	({ getState, setState }) => {
		const { displayLinkedIssuesPanelCallback } = getState();
		displayLinkedIssuesPanelCallback?.(false);
		setState(initialState);
	};

const setDisplayLinkedIssuesPanelCallback =
	(cb: (arg: boolean) => void): Action<State> =>
	({ setState }) => {
		setState({ displayLinkedIssuesPanelCallback: cb });
	};

const actions = {
	isRelatedIssuesOpen,
	acceptSuggestion,
	acceptAllSuggestions,
	discardSuggestion,
	discardAllSuggestions,
	setRelatedIssuesStep,
	setRelatedIssuesFetchStep,
	setRelatedIssuesLoadedStep,
	setIsLinkedIssuePanelButtonTriggered,
	resetState,
	setResourceSuggestion,
	setInvokedFrom,
	setErrorMessage,
	setRelatedIssuesDisplayStep,
	setDisplayLinkedIssuesPanelCallback,
};
type ActionsType = typeof actions;

export const JiraIssueRelatedIssuesContainer = createContainer();
export const Store = createStore<State, ActionsType>({
	initialState,
	actions,
	name: 'jira-ai-related-issues',
	containedBy: JiraIssueRelatedIssuesContainer,
});

/**
 * Specific container for mocking the state in storybook
 */
export const StorybookContainer = createContainer<State, ActionsType, State>(Store, {
	onInit:
		() =>
		({ setState }, mockedState) =>
			setState(mockedState),
});

export const useRelatedIssues = createHook(Store);

export const useRelatedIssuesState = createStateHook(Store);

export const useRelatedIssuesActions = createActionsHook(Store);
