import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { fg } from '@atlassian/jira-feature-gating';
import { useIsJCSProjectType } from '@atlassian/jira-servicedesk-customer-service-common/src/utils/use-is-jcs/index.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	useProjectKey,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import type { JsmDeveloperEscalationsButton } from './ui/jsm-developer-escalations-button/index.tsx';
import type {
	Props,
	JcsDeveloperEscalationsButton,
} from './ui/jcs-developer-escalations-button/index.tsx';

export const JsmDeveloperEscalationsButtonAsync = lazyForPaint<
	typeof JsmDeveloperEscalationsButton
>(() =>
	import(
		/* webpackChunkName: "async-developer-escalations-button" */ './ui/jsm-developer-escalations-button/index.tsx'
	).then((exportedModule) => exportedModule.JsmDeveloperEscalationsButton),
);

export const JcsDeveloperEscalationsButtonAsync = lazyForPaint<
	typeof JcsDeveloperEscalationsButton
>(() =>
	import(
		/* webpackChunkName: "async-jcs-developer-escalations-button" */ './ui/jcs-developer-escalations-button/index.tsx'
	).then((exportedModule) => exportedModule.JcsDeveloperEscalationsButton),
);

export const AsyncDeveloperEscalationsButton = (props: Props) => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);
	const isJCS = useIsJCSProjectType(projectType);

	if (!fg('jcs_developer_escalations') && isJCS) {
		return null;
	}

	if (fg('jcs_developer_escalations')) {
		return (
			<JSErrorBoundary
				id="DeveloperEscalationsButton"
				packageName="jiraServicedeskCustomerServiceEscalationsIssueViewButton"
				teamName="boysenberry"
				sendToPrivacyUnsafeSplunk
				fallback="unmount"
			>
				<Placeholder name="developer-escalations-button" fallback={null}>
					{isJCS ? (
						<JcsDeveloperEscalationsButtonAsync {...props} />
					) : (
						<JsmDeveloperEscalationsButtonAsync {...props} />
					)}
				</Placeholder>
			</JSErrorBoundary>
		);
	}

	return (
		<JSErrorBoundary
			id="DeveloperEscalationsButton"
			packageName="jiraServicedeskCustomerServiceEscalationsIssueViewButton"
			teamName="boysenberry"
			sendToPrivacyUnsafeSplunk
			fallback="unmount"
		>
			<Placeholder name="developer-escalations-button" fallback={null}>
				<JsmDeveloperEscalationsButtonAsync {...props} />
			</Placeholder>
		</JSErrorBoundary>
	);
};
