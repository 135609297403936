import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { PermalinkButton as PermalinkButtonSync, type Props } from './view.tsx';

export const PermalinkButton = (props: Props) => (
	<ErrorBoundary id="issue-view-lazy-permalink" packageName="jiraIssueView">
		<Placeholder name="issue-view-lazy-permalink" fallback={<EmptyState />}>
			<PermalinkButtonSync {...props} />
		</Placeholder>
	</ErrorBoundary>
);

PermalinkButton.displayName = 'PermalinkButton';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyState = styled.div({
	paddingTop: token('space.025'),
	display: 'inline-block',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 2}px`,
});
