import React from 'react';
import { Flex, Text, xcss } from '@atlaskit/primitives';

export const QuickActionTag = ({ text }: { text: string }) => {
	return (
		<Flex justifyContent="center" xcss={containerStyles}>
			<Text>{text}</Text>
		</Flex>
	);
};

const containerStyles = xcss({
	paddingInline: 'space.050',
	paddingBlock: 'space.025',
	backgroundColor: 'color.background.neutral',
	borderRadius: 'border.radius.100',
	minWidth: '36px',
});
