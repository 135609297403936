import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
	type Dispatch,
	type SetStateAction,
} from 'react';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { expVal } from '@atlassian/jira-feature-experiments';

interface TimingMetricsContextType {
	quickMenuClickedTimestamp: number | null;
	setQuickMenuClickedTimestamp: Dispatch<SetStateAction<number | null>>;
}

const TimingMetricsContext = createContext<TimingMetricsContextType>({
	quickMenuClickedTimestamp: null,
	setQuickMenuClickedTimestamp: () => null,
});

export const QuickActionTimingMetricsProvider = ({ children }: { children: React.JSX.Element }) => {
	const [quickMenuClickedTimestamp, setQuickMenuClickedTimestamp] = useState<number | null>(null);

	return (
		<TimingMetricsContext.Provider
			value={{ quickMenuClickedTimestamp, setQuickMenuClickedTimestamp }}
		>
			{children}
		</TimingMetricsContext.Provider>
	);
};

type EventAttributes = {
	name?: string;
	timeSinceMenuClick?: number;
	isM2Experiment?: boolean;
};

/**
 * This hook provides state and utilities to track the timing between quick menu click and the next action item click.
 * It triggers UI analytics for the same.
 */
export const useQuickActionTimingMetrics = () => {
	const { quickMenuClickedTimestamp, setQuickMenuClickedTimestamp } =
		useContext(TimingMetricsContext);
	const timestampRef = useRef(quickMenuClickedTimestamp);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		// storing this value in ref since updated value is not sent to event handlers due to closure
		timestampRef.current = quickMenuClickedTimestamp;
	}, [quickMenuClickedTimestamp]);

	const measureTimeOnClick = useCallback(
		(name = '') => {
			const eventAttributes: EventAttributes = {
				name,
			};

			let quickActionExperiment = 'ga';
			if (expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false)) {
				quickActionExperiment = 'm2_with_icon';
			}
			if (expVal('quick_actions_m2_experiment', 'variant_2_no_icon', false)) {
				quickActionExperiment = 'm2_without_icon';
			}

			if (timestampRef.current) {
				eventAttributes.timeSinceMenuClick = Date.now() - timestampRef.current;
			}
			fireUIAnalytics(
				createAnalyticsEvent({ action: 'clicked', actionSubject: 'button' }),
				'quickActionItem',
				{
					...eventAttributes,
					quickActionExperiment,
				},
			);
		},
		[createAnalyticsEvent],
	);

	return { quickMenuClickedTimestamp, setQuickMenuClickedTimestamp, measureTimeOnClick };
};
