/** @jsx jsx */
import { useState, memo, useEffect, useCallback } from 'react';
import { css, jsx } from '@compiled/react';
import InformationIcon from '@atlaskit/icon/core/migration/information--editor-panel';
import Popup from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { token } from '@atlaskit/tokens';
import { IconButton } from '@atlaskit/button/new';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import {
	fireScreenAnalytics,
	useAnalyticsEvents,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { useIntl } from '@atlassian/jira-intl';
import messages from '../messages.tsx';

export const PlaceholderTemplatePopup = memo(() => {
	const { formatMessage } = useIntl();

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		if (isOpen) {
			fireScreenAnalytics(createAnalyticsEvent({}));
		}
	}, [isOpen, createAnalyticsEvent]);

	const clickedIconBaseEvent = createAnalyticsEvent({
		action: 'clicked',
		actionSubject: 'icon',
	});

	const onClickIcon = useCallback(() => {
		setIsOpen(!isOpen);
		fireUIAnalytics(clickedIconBaseEvent, 'descriptionTemplatesInfoIcon');
	}, [isOpen, clickedIconBaseEvent]);

	return (
		<Popup
			zIndex={layers.modal}
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			placement="bottom-start"
			content={() => <div css={contentStyles}>{formatMessage(messages.popupText)}</div>}
			trigger={(triggerProps) => (
				<IconButton
					{...triggerProps}
					label={formatMessage(messages.popupIconLabel)}
					appearance="subtle"
					spacing="compact"
					isSelected={isOpen}
					onClick={onClickIcon}
					icon={InformationIcon}
				/>
			)}
		/>
	);
});

const contentStyles = css({
	paddingTop: token('space.200'),
	paddingRight: token('space.200'),
	paddingBottom: token('space.200'),
	paddingLeft: token('space.200'),
	width: '272px',
});
