import { useCallback } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useInlineFieldsActions } from '@atlassian/jira-forge-controllers-inline-fields-state/src/index.tsx';
import { useAnalyticsSource as useIssueViewAnalyticsSource } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIssueTransitionsOperationalAnalyticsEvent } from '@atlassian/jira-issue-transition-analytics/src/controllers/use-operational-analytics-events/index.tsx';
import { useIssueTransitionUIAnalyticsEvent } from '@atlassian/jira-issue-transition-analytics/src/controllers/use-ui-analytics-event/index.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useIssueTransitionChangeboardingPreferences } from '@atlassian/jira-router-resources-issue-transition-changeboarding-preferences/src/services/index.tsx';
import { useIssueTransitionLabsOptIn } from '@atlassian/jira-router-resources-issue-transition-labs-opt-in/src/services/index.tsx';
import type {
	CloseIssueTransitionModal,
	IssueTransitionModalPayload,
	OpenIssueTransitionModal,
	State,
	TriggerIssueTransitionModalActions,
} from '../../common/types.tsx';
import { usePublishOpenIssueTransitionModalPubSub } from '../../controllers/open-issue-transition-modal-pub-sub/use-publish-open-issue-transition-modal-pub-sub.tsx';
import {
	useTriggerIssueTransitionModalActions,
	useTriggerIssueTransitionModalState,
} from '../../controllers/trigger-issue-transition-modal/index.tsx';
import { openLegacyIssueTransitionModal } from '../../controllers/utils/index.tsx';
import { isNewTransitionScreenEnabled } from '../../common/utils/index.tsx';

export const useOpenIssueTransitionModal = (): OpenIssueTransitionModal => {
	const { openIssueTransitionModal: openIssueTransitionModalAction, openIssueTransitionModalNew } =
		useTriggerIssueTransitionModalActions();
	const { stateGet: { state: { isEnabled = true } = {} } = {} } = useIssueTransitionLabsOptIn();
	const publish = usePublishOpenIssueTransitionModalPubSub();
	const source = useIssueViewAnalyticsSource();
	const { fireUIEvent } = useIssueTransitionUIAnalyticsEvent();

	const {
		stateGet: { state },
	} = useIssueTransitionChangeboardingPreferences();

	const { fireOperationalAnalyticsTransitionScreenOpened } =
		useIssueTransitionsOperationalAnalyticsEvent();

	const benefitsModal = typeof state !== 'number' ? state?.benefitsModal : undefined;
	const { isDisabled, nextDateToShowBenefitsModal } = benefitsModal || {};
	return useCallback(
		(nextState?: IssueTransitionModalPayload) => {
			try {
				const { triggerPointKey } = nextState || {};

				const isNewITSEnabled = isNewTransitionScreenEnabled(isEnabled, triggerPointKey);

				if (fg('modern_issue_transition_enable_benefits_modal')) {
					fireOperationalAnalyticsTransitionScreenOpened({
						actionSubject: 'issueTransition triggered',
						isBenefitsModalFGEneabled: fg('modern_issue_transition_enable_benefits_modal'),
						preferNewIssueTransitionScreen: isEnabled,
						issueViewAnalyticsSource: source,
						isBenefitsModalDisabled: isDisabled,
						dateToShowBenefitsModal: nextDateToShowBenefitsModal || undefined,
					});
					openIssueTransitionModalNew({
						nextState,
						preferNewIssueTransitionScreen: isEnabled,
						issueViewAnalyticsSource: source,
						publishTriggerToOpenTransitions: publish,
						isBenefitsModalDisabled: isDisabled,
						dateToShowBenefitsModal: nextDateToShowBenefitsModal || undefined,
						fireUIEvent,
					});
					return;
				}
				if (fg('fix-connect-app-not-loading')) {
					if (isNewITSEnabled && source === 'connect-issue') {
						// fire UI event when issue transition is opened via connect API
						fireUIEvent({
							actionSubject: 'issueTransition',
							actionSubjectId: 'connectIssue',
							action: 'opened',
						});
						publish(nextState, isEnabled);
					} else {
						openIssueTransitionModalAction(nextState, isEnabled);
					}
				} else if (source === 'connect-issue') {
					// fire UI event when issue transition is opened via connect API
					fireUIEvent({
						actionSubject: 'issueTransition',
						actionSubjectId: 'connectIssue',
						action: 'opened',
					});
					publish(nextState, isEnabled);
				} else {
					openIssueTransitionModalAction(nextState, isEnabled);
				}

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				fireErrorAnalytics({
					meta: {
						id: 'transitionIssueTrigger',
						packageName: 'jiraIssueTransitionTrigger',
						teamName: 'gryffindor',
					},
					attributes: {
						transitionModalVersion: 'NEW',
					},
					error,
					sendToPrivacyUnsafeSplunk: true,
				});
				throw error;
			}
		},
		[
			fireOperationalAnalyticsTransitionScreenOpened,
			isEnabled,
			source,
			isDisabled,
			nextDateToShowBenefitsModal,
			openIssueTransitionModalNew,
			publish,
			fireUIEvent,
			openIssueTransitionModalAction,
		],
	);
};

export const useCloseIssueTransitionModal = (): CloseIssueTransitionModal => {
	const [, { resetState }] = useInlineFieldsActions();
	const { closeIssueTransitionModal: closeIssueTransitionModalAction } =
		useTriggerIssueTransitionModalActions();
	const {
		payload: { issueKey, transitionId },
		onDialogCancel,
		onDialogError,
		onDialogSuccess,
	} = useTriggerIssueTransitionModalState();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	return useCallback(
		(shouldOpenLegacyModal = false) => {
			closeIssueTransitionModalAction();
			resetState();
			if (shouldOpenLegacyModal) {
				const onOpenDialog = () => {
					fireTrackAnalytics(createAnalyticsEvent({}), 'legacyTransitionsScreen Switched');
				};
				openLegacyIssueTransitionModal({
					issueKey,
					isReopenScenario: true,
					transitionId,
					onDialogCancel,
					onDialogError,
					onDialogSuccess,
					onOpenDialog,
				});
			}
		},
		[
			closeIssueTransitionModalAction,
			createAnalyticsEvent,
			issueKey,
			onDialogCancel,
			onDialogError,
			onDialogSuccess,
			transitionId,
			resetState,
		],
	);
};

export const useTriggerIssueTransitionModal = (): [State, TriggerIssueTransitionModalActions] => {
	const state = useTriggerIssueTransitionModalState();
	const actions = useTriggerIssueTransitionModalActions();
	const { openIssueTransitionModalNew, ...restActions } = actions;
	const openIssueTransitionModal = useOpenIssueTransitionModal();

	return [state, { ...restActions, openIssueTransitionModal }];
};

export default useTriggerIssueTransitionModal;
