/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Chinese (Simplified)
export default {
  'fabric.elements.share.copied.to.clipboard.message': '链接已复制到剪贴板',
  'fabric.elements.share.copylink.button.text': '复制链接',
  'fabric.elements.share.copypubliclink.button.text': '复制公共链接',
  'fabric.elements.share.failure.icon.label': '错误图标',
  'fabric.elements.share.failure.message': '无法共享',
  'fabric.elements.share.form.comment.label': '消息（可选）',
  'fabric.elements.share.form.comment.placeholder': '对方是否应知道某些信息？',
  'fabric.elements.share.form.info.message.no.invite.confluence': '收件人将看到页面名称和您的消息',
  'fabric.elements.share.form.info.message.no.invite.jira': '收件人将看到事务名称和您的消息',
  'fabric.elements.share.form.info.message.no.invite.jira.issue-term-refresh': '收件人将看到工作项名称和您的消息',
  'fabric.elements.share.form.no-permissions': '您无法共享。',
  'fabric.elements.share.form.public.send': '发送公共链接',
  'fabric.elements.share.form.public.share': '分享公开链接',
  'fabric.elements.share.form.required-field.summary': '必填字段标有星号',
  'fabric.elements.share.form.retry': '重试',
  'fabric.elements.share.form.send': '发送',
  'fabric.elements.share.form.share': '共享',
  'fabric.elements.share.form.title': '共享',
  'fabric.elements.share.form.user-picker.add-more': '输入更多',
  'fabric.elements.share.form.user-picker.label.confluence': '姓名、团队、群组或电子邮件地址',
  'fabric.elements.share.form.user-picker.label.email-disabled-confluence': '姓名、团队或群组',
  'fabric.elements.share.form.user-picker.label.email-disabled-jira': '姓名或团队',
  'fabric.elements.share.form.user-picker.label.email-only': '电子邮件地址',
  'fabric.elements.share.form.user-picker.label.jira': '姓名、团队或电子邮件地址',
  'fabric.elements.share.form.user-picker.no-options.existingUserOnly': '我们找不到任何关于“{inputValue}”的结果。',
  'fabric.elements.share.form.user-picker.no-options.generic':
    '我们找不到任何关于“{inputValue}”的结果。请用电子邮件地址邀请他人。',
  'fabric.elements.share.form.user-picker.placeholder.confluence': '例如，玛利亚、橙队、第一组',
  'fabric.elements.share.form.user-picker.placeholder.email-disabled-jira': '例如，玛利亚、橙队',
  'fabric.elements.share.form.user-picker.placeholder.email-only': '例如，maria@company.com',
  'fabric.elements.share.form.user-picker.placeholder.jira': '例如，玛利亚、橙队、maria@company.com',
  'fabric.elements.share.form.user-picker.validation.required': '请至少选择一个用户、组、团队或电子邮件。',
  'fabric.elements.share.form.user-picker.validation.required-message.confluence':
    '请至少选择一个人员、团队、群组或电子邮件地址',
  'fabric.elements.share.form.user-picker.validation.required-message.email-disabled-confluence':
    '请至少选择一个人员、团队或群组',
  'fabric.elements.share.form.user-picker.validation.required-message.email-disabled-jira': '请至少选择一个人员或团队',
  'fabric.elements.share.form.user-picker.validation.required-message.email-only': '请至少选择一个电子邮件地址',
  'fabric.elements.share.form.user-picker.validation.required-message.jira': '请至少选择一个人员、团队或电子邮件地址',
  'fabric.elements.share.in.integration.button': '在 {integrationName} 中分享',
  'fabric.elements.share.invite.to.confluence': '邀请加入 Confluence',
  'fabric.elements.share.invite.to.jira': '邀请加入 Jira',
  'fabric.elements.share.main.tab.text.confluence': '分享页面',
  'fabric.elements.share.main.tab.text.jira': '共享事务',
  'fabric.elements.share.menu.email.label': '电子邮件',
  'fabric.elements.share.menu.slack.label': 'Slack',
  'fabric.elements.share.popup.label': '共享',
  'fabric.elements.share.success.message':
    '{object, select,blogpost {博客文章已共享}board {面板已共享}calendar {日历已共享}draft {草稿已共享}filter {筛选器已共享}issue {事务已共享}summary {摘要已共享}list {列表已共享}timeline {时间线已共享}form {表单已共享}media {媒体已共享}page {页面已共享}project {项目已共享}pullrequest {拉取请求已共享}question {问题已共享}report {报告已共享}repository {存储库已共享}request {请求已共享}roadmap {时间线已共享}site {站点已共享}space {空间已共享}other {链接已共享}}',
  'fabric.elements.share.to.integration.button': '分享到 {integrationName}',
  'fabric.elements.share.trigger.button.icon.label': '共享图标',
  'fabric.elements.share.trigger.button.invite.text': '邀请',
  'fabric.elements.share.trigger.button.text': '共享',
  'fabric.elements.share.trigger.button.tooltip.text': '共享',
};
