import type { ReactNode, ReactElement } from 'react';
import type { ExecutionResult } from '@apollo/react-common';
import type { OptionType } from '@atlaskit/select';
import type {
	AkSelectStyles,
	ActionMeta,
	Props as SelectProps,
} from '@atlassian/jira-common-components-picker/src/model.tsx';
import type {
	Group,
	Option,
} from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types.tsx';
import type { JiraFieldOptionIdsFilterInput } from '@atlassian/jira-relay/src/__generated__/selectableFieldSearchRefetchQuery.graphql';
import type {
	ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment$key as SelectableFieldFragment,
	ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment$data as SelectableFieldFragmentData,
} from '@atlassian/jira-relay/src/__generated__/ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment.graphql';
import type { JwmUpdateField } from '@atlassian/jira-business-inline-field-config-sidebar/src/services/update-field/__generated_apollo__/JwmUpdateField';

type Writeable<T> = { -readonly [P in keyof T]: T[P] };

type SelectableFieldFragmentDataNode = NonNullable<SelectableFieldFragmentData['node']>;
type SelectableValueOptions = NonNullable<
	SelectableFieldFragmentDataNode['selectableValueOptions']
>;
type SelectableValueOptionsEdges = NonNullable<SelectableValueOptions['edges']>;
export type SelectableValueOptionsNode = Writeable<NonNullable<SelectableValueOptionsEdges[0]>>;
export type SelectableValueOption = NonNullable<SelectableValueOptionsNode['node']>;

export type NullableOption = SelectableValueOption | null;

export type NullableOptions = SelectableValueOption[] | null;

export type OptionsInput = {
	color?: string | null;
	optionId?: string | number | null;
	value: string;
};

export const ACTION: Record<string, OperationType> = {
	ADD: 'add',
	DELETE: 'delete',
	UPDATE: 'update',
};

export type OperationType = 'add' | 'delete' | 'update';

/** Props for \<[SelectableFieldEditViewWithFieldOptionsFragment](./index.tsx) /> */
export interface SelectableFieldEditViewWithFieldOptionsFragmentProps {
	/**
	 * Whether the field should automatically receive focus on render or not (default should be false)
	 */
	autoFocus?: boolean;
	/** Cashed select options. If cache is expected for the field, the value is an empty array, otherwise undefined */
	cachedOptions?: SelectableValueOption[] | [];
	/** If provided, all inner components will be given a prefixed className attribute */
	classNamePrefix?: string;
	/** Close the select menu when the user selects an option */
	closeMenuOnSelect?: boolean;
	/** Id of the field in Jira */
	fieldId: string;
	/** This is the type of the field (com.*)  */
	fieldType?: string;
	/** Include/exclude options with their ID's (ARIs) */
	filterOptionsById?: JiraFieldOptionIdsFilterInput | null;
	/** Footer to create a new option */
	footer?: ReactNode | ((value: string) => ReactElement);
	/** Formats group labels in the menu as React components */
	formatGroupLabel?: SelectProps<Option | Group>['formatGroupLabel'];
	/** Has infinite scroll. True by default */
	hasInfiniteScroll?: boolean;
	/** Support multiple selected options. False by default */
	isMulti?: boolean;
	/** Is the field disabled? False by default */
	isDisabled?: boolean;
	/**
	 * Is the field invalid?
	 * If true, this will override the validationState to 'error'
	 * False by default
	 * */
	isInvalid?: boolean;
	/** Handler called on change */
	onChange?: (value: NullableOptions, actionMeta: ActionMeta<SelectableValueOption>) => void;
	/** handler called when the footer is selected */
	onFooterSelect?: (value: string) => void;
	/** Number of first fetched options */
	optionsFirstCount?: number;
	/** Number of options fetched on infinite scrolling  */
	optionsPageCount?: number;
	/** Is the field required? False by default */
	required?: boolean;
	/** Styling that will be applied to the AK Select component */
	styles?: AkSelectStyles;
	/** Height of the edit view select */
	spacing?: 'compact' | 'default';
	/** Validation state */
	validationState?: 'default' | 'error' | 'success';
	/** Current value of the field, accepts Relay `Node`s that implement `JiraSelectableValue` */
	value?: NullableOption | NullableOptions;
	/** is Select value clearable */
	isClearable?: boolean;
	/** used for linking field to label */
	inputId?: string;
	/** set dropdown menu position */
	menuPosition?: SelectProps<Option | Group>['menuPosition'];
	/**
	 * a callback to render a message when component is in a loading state
	 * */
	loadingMessage?: SelectProps<OptionType>['loadingMessage'];
	/**
	 * a callback to render a message when there are no options
	 * */
	noOptionsMessage?: SelectProps<OptionType>['noOptionsMessage'];
	/** Function that returns either a promise with the options to be used, or the options themselves. */
	loadOptions?: (
		options: SelectableValueOption[],
	) => SelectableValueOption[] | Promise<SelectableValueOption[]>;
	/** Function that returns sorted options  */
	sortOptions?: (options: SelectableValueOption[]) => SelectableValueOption[];
	/** Message displayed when there are no options selected. */
	placeholder?: string;
	/** Header label displayed to mark recently selected items. */
	recentOptionsLabel?: string;
	/** Header label displayed to mark all items. */
	allOptionsLabel?: string;
	/** ariaLabel which consumers can optionally pass */
	ariaLabel?: string;
	/** id of the label element which consumers can optionally pass */
	ariaLabelledBy?: string;
	/* The components will fetch their own suggestions onFocus, however this fragment is available
	 * for fetching the suggestions more eagerly using a higher level query.
	 * The Query containing this must have a fetchPolicy of 'store-only' to prevent this component
	 * from triggering suspense.
	 * So it is recommended to: for the containing query use a useLazyLoadQuery with
	 * `fetchPolicy: 'store-only'` and in order to trigger a network call, use something like
	 * `fetchQuery(...).toPromise().then(() => loadQuery(..., { fetchPolicy: 'store-only' }))`
	 * or equivalent with subscribers
	 */
	fieldOptionsFragmentRef: SelectableFieldFragment;
	onOptionChange?: (
		updatedOptionsInput: OptionsInput[],
		operationType: OperationType,
	) => Promise<ExecutionResult<JwmUpdateField>>;
}

export type SelectableFieldEditViewWithoutFragmentProps = Omit<
	SelectableFieldEditViewWithFieldOptionsFragmentProps,
	'fieldOptionsFragmentRef'
>;
