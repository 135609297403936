import React, { useMemo } from 'react';
import BitbucketCloneIcon from '@atlaskit/icon/glyph/bitbucket/clone';
import DuplicateIcon from '@atlaskit/icon-lab/core/duplicate';
import { getCommandPaletteIssueActionsPriority } from '@atlassian/jira-command-palette-common/src/common/utils/get-command-palette-issue-actions-priority/index.tsx';
import { getCommandPaletteIssueActionsHeader } from '@atlassian/jira-command-palette-common/src/common/utils/index.tsx';
import { useCommandKeywords } from '@atlassian/jira-command-palette-common/src/controllers/use-command-keywords/index.tsx';
import { COMMAND_PALETTE_REGISTRY_IDS } from '@atlassian/jira-command-palette-registry/src/common/constants/registry/index.tsx';
import { RegisterCommands } from '@atlassian/jira-command-palette-registry/src/ui/register-commands/index.tsx';
import {
	type Command,
	CommandActionType,
} from '@atlassian/jira-command-palette/src/common/types/commands/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { showCloneModal } from '@atlassian/jira-issue-view-store/src/actions/clone-modal-actions.tsx';
import { fetchSortedCommentsRequest } from '@atlassian/jira-issue-view-store/src/actions/comment-actions.tsx';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { useFlagIssueCommand } from './flag-issue-command/index.tsx';
import { messages } from './messages.tsx';
import type { CommandPaletteIssueActionsBaseProps } from './types.tsx';

const CommandPaletteIssueActionsBase = ({
	openCloneModal,
	onAddComment,
}: CommandPaletteIssueActionsBaseProps) => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const [{ canCloneIssue, canEditIssues }] = useProjectPermissions(projectKey);

	const { formatMessage } = useIntl();

	const { getKeywords } = useCommandKeywords();

	const commandFlagIssue = useFlagIssueCommand(issueKey, onAddComment);

	const commands: Command[] = useMemo(
		() => [
			...(canCloneIssue
				? [
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						{
							id: `issue-actions-clone-issue-${issueKey}`,
							name: formatMessage(
								fg('jira-issue-terminology-refresh-m3')
									? messages.cloneIssueIssueTermRefresh
									: messages.cloneIssue,
							),
							section: getCommandPaletteIssueActionsHeader(issueKey),
							keywords: getKeywords('cloneIssueSynonyms'),
							priority: getCommandPaletteIssueActionsPriority('ISSUE_HEADER_ITEMS'),
							components: {
								LeftIcon: () => (
									<DuplicateIcon
										label={formatMessage(
											fg('jira-issue-terminology-refresh-m3')
												? messages.cloneIssueIssueTermRefresh
												: messages.cloneIssue,
										)}
										LEGACY_fallbackIcon={BitbucketCloneIcon}
									/>
								),
							},
							primaryAction: {
								type: CommandActionType.PERFORM,
								perform: () => {
									openCloneModal();
								},
							},
							analytics: {
								action: 'cloneIssue',
							},
						} as Command,
					]
				: []),
			...(canEditIssues ? [commandFlagIssue] : []),
		],
		[
			canCloneIssue,
			issueKey,
			formatMessage,
			getKeywords,
			canEditIssues,
			commandFlagIssue,
			openCloneModal,
		],
	);

	return commands.length ? (
		<RegisterCommands
			registrationId={COMMAND_PALETTE_REGISTRY_IDS.ISSUE_ACTIONS}
			commands={commands}
			deps={commands}
		/>
	) : null;
};

export const CommandPaletteIssueActions = connect(null, (dispatch) => ({
	openCloneModal: () => {
		dispatch(showCloneModal());
	},
	onAddComment: () => {
		dispatch(fetchSortedCommentsRequest());
	},
}))(CommandPaletteIssueActionsBase);
