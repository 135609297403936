import { useMemo } from 'react';
import isNil from 'lodash/isNil';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { useForgeUiIssueData } from '@atlassian/jira-forge-controllers-extension-context/src/index.tsx';
import type {
	CustomFieldExtension,
	CustomFieldLatest,
	CustomFieldLegacy,
	CustomFieldTypeLatest,
	CustomFieldTypeLegacy,
} from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import { isExtensionVisible } from '@atlassian/jira-forge-ui-utils/src/utils/extension/index.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIssueFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import type { IssueConfiguration } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';
import {
	useForgeCustomField,
	useBlockedExtensions,
} from '@atlassian/jira-issue-view-forge-service/src/services/main.tsx';
import {
	useProjectConfiguration,
	useProjectKey,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { functionWithConditionAndDifferentArgs } from '@atlassian/jira-feature-flagging-utils/src/index.tsx';
import { transformExtension } from '@atlassian/jira-forge-custom-field-extension-transformer/src/index.tsx';
import { useIssueViewExtensionId } from './issue-view-extension-id/index.tsx';
import type { FetchIssueCustomFieldPayload, FetchIssueCustomFieldContext } from './types.tsx';

export const isForgeCustomFieldFilteredOut = (
	ecosystemEnabled: boolean,
	extension: CustomFieldExtension | null,
	extensionId: string,
	blockedExtensions: (CustomFieldExtension | null)[],
): boolean => {
	if (!ecosystemEnabled) {
		return false;
	}

	if (extension) {
		return !isExtensionVisible(extension);
	}

	const blockedCustomFieldExtensionIds = blockedExtensions.map((e) => e?.id);
	return blockedCustomFieldExtensionIds.includes(extensionId);
};

const shouldUseFormatterOld = (
	extension: CustomFieldLegacy | CustomFieldTypeLegacy | null,
): boolean => {
	if (!extension) {
		return false;
	}

	const hasRenderingFunction = !isNil(extension.properties.function);
	const hasNativeResource =
		!isNil(extension.properties.resource) && extension.properties.render === 'native';
	const hasFormatterDefined = !isNil(extension.properties.formatter);

	return !hasRenderingFunction && !hasNativeResource && hasFormatterDefined;
};

const shouldUseFormatterNew = (
	extension: CustomFieldLatest | CustomFieldTypeLatest | null,
): boolean => {
	if (!extension) {
		return false;
	}

	const view = extension.properties?.view;
	if (!view) {
		return false;
	}

	const hasRenderingFunction = 'function' in view && !isNil(view.function);
	const hasNativeResource =
		'resource' in view && !isNil(view.resource) && 'render' in view && view.render === 'native';
	const hasFormatterDefined = !isNil(view?.formatter);
	const supportedViewExperience =
		view && 'experience' in view && view.experience
			? view.experience.includes('issue-view')
			: false;
	return (
		!hasRenderingFunction && !(hasNativeResource && supportedViewExperience) && hasFormatterDefined
	);
};

export const shouldUseFormatter = functionWithConditionAndDifferentArgs<
	[CustomFieldLatest | CustomFieldTypeLatest | null],
	[CustomFieldLegacy | CustomFieldTypeLegacy | null],
	boolean
>(
	() => fg('ditto-fcf-support-new-manifest-on-frontend'),
	shouldUseFormatterNew,
	shouldUseFormatterOld,
);

export const doesFieldExistsInJira = (
	fieldId: string,
	fieldsConfiguration: IssueConfiguration,
): boolean => !!fieldsConfiguration[fieldId];

export const getFieldName = (fieldId: string, fieldsConfiguration: IssueConfiguration): string => {
	if (fieldsConfiguration[fieldId]?.title) {
		return fieldsConfiguration[fieldId]?.title;
	}

	log.safeWarnWithoutCustomerData('issue.field.selector', `${fieldId} does not have a field title`);
	return '';
};

export const useFetchIssueCustomFieldData = ({
	fieldId,
}: FetchIssueCustomFieldContext): FetchIssueCustomFieldPayload => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const { ecosystemEnabled = false } = useProjectConfiguration(projectKey);
	const { loading: extensionIdLoading, data: extensionId } = useIssueViewExtensionId(
		issueKey,
		fieldId,
	);
	const extensionData = useForgeUiIssueData();
	let extension = useForgeCustomField(extensionId || '') || null;
	const blockedExtensions = useBlockedExtensions();
	const [{ loading: fieldsConfigurationLoading, value: fieldsConfiguration }] =
		useIssueFieldConfig(issueKey);
	const blockedCustomFields = useMemo(
		() => [
			...(blockedExtensions ? blockedExtensions.customField : []),
			...(blockedExtensions ? blockedExtensions.customFieldType : []),
		],
		[blockedExtensions],
	);

	if (fg('ditto-fcf-support-new-manifest-on-frontend')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		extension = useMemo(() => transformExtension(extension), [extension]);
	}
	return useMemo(
		() => ({
			loading: extensionIdLoading || fieldsConfigurationLoading,
			data:
				extensionId && fieldsConfiguration && extensionData
					? {
							isFilteredOut: isForgeCustomFieldFilteredOut(
								ecosystemEnabled,
								extension,
								extensionId,
								blockedCustomFields,
							),
							shouldUseFormatter: shouldUseFormatter(extension),
							extension,
							extensionData,
							fieldExistsInJira: doesFieldExistsInJira(fieldId, fieldsConfiguration),
							fieldName: getFieldName(fieldId, fieldsConfiguration),
						}
					: undefined,
			error: undefined,
		}),
		[
			fieldId,
			ecosystemEnabled,
			extension,
			extensionIdLoading,
			extensionId,
			extensionData,
			fieldsConfigurationLoading,
			fieldsConfiguration,
			blockedCustomFields,
		],
	);
};
