export const NEWLINE_REGEX = /\r?\n/;
export const FOLD_LINES_REGEX = /\r?\n[ \t]/g; // match line breaks followed by a whitespace character (space or tab)

export const CRLF = 'CRLF' as const;
export const CRLF_VALUE = '\r\n' as const;
export const LF = 'LF' as const;
export const LF_VALUE = '\n' as const;

export const NewlineCharacters = { CRLF, LF } as const;
export const NewlineCharacterValues = {
	[CRLF]: CRLF_VALUE,
	[LF]: LF_VALUE,
} as const;

export const Frequencies = {
	SECONDLY: 'SECONDLY',
	MINUTELY: 'MINUTELY',
	HOURLY: 'HOURLY',
	DAILY: 'DAILY',
	WEEKLY: 'WEEKLY',
	MONTHLY: 'MONTHLY',
	YEARLY: 'YEARLY',
} as const;

export const Days = {
	MO: 'MO',
	TU: 'TU',
	WE: 'WE',
	TH: 'TH',
	FR: 'FR',
	SA: 'SA',
	SU: 'SU',
} as const;

// ICAL supported fields according to https://datatracker.ietf.org/doc/html/rfc5545
export const BEGIN = 'BEGIN';
export const END = 'END';
export const BEGIN_VEVENT = 'BEGIN:VEVENT';
export const END_VEVENT = 'END:VEVENT';
export const DTSTART = 'DTSTART';
export const RRULE = 'RRULE';
export const SUMMARY = 'SUMMARY';
export const LOCATION = 'LOCATION';
export const DESCRIPTION = 'DESCRIPTION';
export const UID = 'UID';
export const SEQUENCE = 'SEQUENCE';
export const STATUS = 'STATUS';
export const TRANSP = 'TRANSP';
export const PRIORITY = 'PRIORITY';
export const DTEND = 'DTEND';
export const DURATION = 'DURATION';
export const ATTACH = 'ATTACH';
export const ATTENDEE = 'ATTENDEE';
export const CATEGORIES = 'CATEGORIES';
export const COMMENT = 'COMMENT';
export const CONTACT = 'CONTACT';
export const EXDATE = 'EXDATE';
export const EXRULE = 'EXRULE';
export const RDATE = 'RDATE';
export const RELATED_TO = 'RELATED-TO';
export const RESOURCES = 'RESOURCES';
export const RECURRENCE_ID = 'RECURRENCE-ID';
export const URL = 'URL';
export const CREATED = 'CREATED';
export const DTSTAMP = 'DTSTAMP';
export const LAST_MODIFIED = 'LAST-MODIFIED';
export const ORGANIZER = 'ORGANIZER';
export const REQUEST_STATUS = 'REQUEST-STATUS';

export const ICalSpecificationFields = {
	BEGIN,
	END,
	BEGIN_VEVENT,
	END_VEVENT,
	SUMMARY,
	LOCATION,
	DESCRIPTION,
	UID,
	SEQUENCE,
	STATUS,
	TRANSP,
	PRIORITY,
	DTSTART,
	RRULE,
	DTEND,
	DURATION,
	ATTACH,
	ATTENDEE,
	CATEGORIES,
	COMMENT,
	CONTACT,
	EXDATE,
	EXRULE,
	RDATE,
	RELATED_TO,
	RESOURCES,
	RECURRENCE_ID,
	URL,
	CREATED,
	DTSTAMP,
	LAST_MODIFIED,
	ORGANIZER,
	REQUEST_STATUS,
} as const;

export const ICalSupportedFields = {
	DTSTART,
	RRULE,
} as const;

// DTSTART properties
export const TZID = 'TZID';

export const DTStartProperties = { DTSTART, TZID } as const;

// RRULE properties
export const FREQ = 'FREQ';
export const UNTIL = 'UNTIL';
export const INTERVAL = 'INTERVAL';
export const BYHOUR = 'BYHOUR';
export const BYMINUTE = 'BYMINUTE';
export const BYSECOND = 'BYSECOND';
export const BYDAY = 'BYDAY';
export const BYMONTH = 'BYMONTH';
export const BYMONTHDAY = 'BYMONTHDAY';

export const RRuleProperties = {
	FREQ,
	UNTIL,
	INTERVAL,
	BYHOUR,
	BYMINUTE,
	BYSECOND,
	BYDAY,
	BYMONTH,
	BYMONTHDAY,
} as const;

export const MISSING = 'MISSING' as const;
export const EMPTY = 'EMPTY' as const;
export const INVALID = 'INVALID' as const;
export const VALID = 'VALID' as const;
