/**
 * @generated SignedSource<<ecfea9c3d2d79ed9d6532814302b8476>>
 * @relayHash d2afa439142f4980fc6c84f100121f96
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 231213a856e1f8c582a11d589f269a949bb30a0b42d6beca347c3c975dbfd543

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type ui_dateFieldsMessage_DismissActionMutation$variables = {
  cloudId: string;
  issueKey: string;
};
export type ui_dateFieldsMessage_DismissActionMutation$data = {
  readonly jira: {
    readonly userPreferences: {
      readonly dismissDateFieldAssociationMessageByIssueKey: {
        readonly errors: ReadonlyArray<{
          readonly message: string | null | undefined;
        }> | null | undefined;
        readonly success: boolean;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type ui_dateFieldsMessage_DismissActionMutation$rawResponse = {
  readonly jira: {
    readonly userPreferences: {
      readonly dismissDateFieldAssociationMessageByIssueKey: {
        readonly errors: ReadonlyArray<{
          readonly message: string | null | undefined;
        }> | null | undefined;
        readonly success: boolean;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type ui_dateFieldsMessage_DismissActionMutation = {
  rawResponse: ui_dateFieldsMessage_DismissActionMutation$rawResponse;
  response: ui_dateFieldsMessage_DismissActionMutation$data;
  variables: ui_dateFieldsMessage_DismissActionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "issueKey"
  }
],
v1 = [
  {
    "concreteType": "JiraMutation",
    "kind": "LinkedField",
    "name": "jira",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "cloudId",
            "variableName": "cloudId"
          }
        ],
        "concreteType": "JiraUserPreferencesMutation",
        "kind": "LinkedField",
        "name": "userPreferences",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "issueKey",
                "variableName": "issueKey"
              }
            ],
            "concreteType": "JiraDateFieldAssociationMessageMutationPayload",
            "kind": "LinkedField",
            "name": "dismissDateFieldAssociationMessageByIssueKey",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "success"
              },
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "message"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "ui_dateFieldsMessage_DismissActionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ui_dateFieldsMessage_DismissActionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "231213a856e1f8c582a11d589f269a949bb30a0b42d6beca347c3c975dbfd543",
    "metadata": {},
    "name": "ui_dateFieldsMessage_DismissActionMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "1069f3009c4a423e5cf9fbbfe78a27dc";

export default node;
