import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { sendPendoTrackEvent as sendTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { isShallowEqual } from '@atlassian/jira-polaris-lib-equals/src/index.tsx';
import { runInBatch } from '@atlassian/jira-polaris-lib-run-in-batch/src/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { EVENTS } from '../../../../services/pendo/index.tsx';
import { createGetField, createGetFieldMapping } from '../../selectors/fields.tsx';
import { getRankedIssueIds } from '../../selectors/issue-ids.tsx';
import { createGetKeySelector } from '../../selectors/properties/index.tsx';
import type { State, Props, PropertyMaps } from '../../types.tsx';
import type { FieldMapping } from '../../utils/field-mapping/types.tsx';

type InvalidateFieldValueRequest = {
	fieldKey: FieldKey;
	invalidValue: unknown | undefined;
	performSideEffects: boolean;
};

const createIssueUpdateOperation = (
	localIssueId: LocalIssueId,
	fieldKey: FieldKey,
	fieldMapping: FieldMapping<unknown>,
	properties: PropertyMaps,
	state: State,
	props: Props,
): (() => Promise<void>) => {
	const issueKey = createGetKeySelector(localIssueId)(state);
	return () => {
		sendTrackEvent(EVENTS.IdeaFieldUpdated);
		return props.issuesRemote
			.updateIssueField({
				issueKey,
				fieldKey,
				value: fieldMapping.getFieldValueForJiraUpdate(
					fieldMapping.valueAccessor(state, props, localIssueId),
				),
			})
			.catch((error) => props.onIssueUpdateFailed(error));
	};
};

export const invalidateFieldValue =
	({ fieldKey, invalidValue, performSideEffects }: InvalidateFieldValueRequest) =>
	({ getState, setState }: StoreActionApi<State>, props: Props): Promise<void> => {
		const state = getState();
		const fieldMapping = createGetFieldMapping(fieldKey)(state, props);
		const ids = getRankedIssueIds(state);
		const field = createGetField(fieldKey)(state, props);

		if (field === undefined || fieldMapping === undefined) {
			return Promise.resolve(undefined);
		}

		const operation = ids.reduce(
			(
				result: {
					affectedIssues: LocalIssueId[];
					properties: PropertyMaps;
				},
				localIssueId: LocalIssueId,
			) => {
				if (fieldMapping.isMultiValueField) {
					const newMapping = fieldMapping.modifyImmutableIfMultiValueField(
						result.properties,
						localIssueId,
						undefined,
						invalidValue,
					);
					if (newMapping !== result.properties) {
						return {
							properties: newMapping,
							affectedIssues: [...result.affectedIssues, localIssueId],
						};
					}
				} else {
					const previousValue = fieldMapping.valueAccessor(
						{
							...getState(),
							properties: result.properties,
						},
						props,
						localIssueId,
					);
					if (isShallowEqual(previousValue, invalidValue)) {
						const newMapping = fieldMapping.setImmutable(state.properties, localIssueId, undefined);
						if (newMapping !== result.properties) {
							return {
								properties: newMapping,
								affectedIssues: [...result.affectedIssues, localIssueId],
							};
						}
					}
				}
				return result;
			},
			{
				properties: state.properties,
				affectedIssues: [],
			},
		);

		setState({
			properties: operation.properties,
		});

		if (performSideEffects) {
			if (field.type === FIELD_TYPES.STATUS) {
				// special handling for status fields. they are updated through a different endpoint
				// it is therefore required to use the update status action, and this becomes a noop
				throw new Error('Unable to update status field through invalidateFieldValue');
			} else {
				// generic update REST call to Jira
				const updateOperations = operation.affectedIssues.map((localIssueId) =>
					createIssueUpdateOperation(
						localIssueId,
						field.key,
						fieldMapping,
						operation.properties,
						state,
						props,
					),
				);

				return runInBatch(updateOperations, 4).then(() => undefined);
			}
		}

		return Promise.resolve(undefined);
	};
