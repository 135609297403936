import { defineMessages } from '@atlassian/jira-intl';

export const PlaybookPanelMessages = defineMessages({
	playbooks: {
		id: 'jira.playbooks.agent-view.playbooks-panel.playbooks',
		defaultMessage: 'Playbooks',
		description: 'Header for the playbooks section in issue view',
	},
	description: {
		id: 'jira.playbooks.agent-view.playbooks-panel.description',
		defaultMessage:
			'Guides that help resolve issues by consolidating relevant data, actions, and documents.',
		description: 'Description for the playbooks section in issue view',
	},
	descriptionIssueRefresh: {
		id: 'jira.playbooks.agent-view.playbooks-panel.description-issue-term-refresh',
		defaultMessage:
			'Guides that help resolve work items by consolidating relevant data, actions, and documents.',
		description: 'Description for the playbooks section in issue view',
	},
	new: {
		id: 'jira.playbooks.agent-view.playbooks-panel.new',
		defaultMessage: 'New',
		description: 'Lozenge to indicate the new feature',
	},
});
