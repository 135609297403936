/* eslint-disable jira/deprecations/no-base-url */ // baseUrl is used as a prop name here from confluenceAppLinks
import { v4 as uuid } from 'uuid';
import { invokeAIFeature } from '@atlassian/jira-ai-assistance-service-client/src/services/ai-features-client/index.tsx';
import type { ConfluencePage } from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import type { FailedRemoteLink } from '@atlassian/jira-issue-shared-types/src/common/types/remote-link-error-type.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { ConfluenceSiteAri } from '@atlassian/ari/confluence';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createActionsHook,
	createStateHook,
	type Action,
	createStore,
	createContainer,
	createHook,
} from '@atlassian/react-sweet-state';
import type { ConfluenceAppLink } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import {
	fireTrackAnalytics,
	type UIAnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import {
	RELATED_RESOURCES_SITE_SWITCHER_LOCAL_STORAGE_KEY,
	type SiteSwitcherSelection,
} from '../common/types.tsx';
import { createConfluencePageLinkRequest } from './link-confluence-page-api.tsx';

export type RelatedResource = {
	id: string;
	url: string;
	recommendationOrigin?: string[];
};
export type InvokedFrom = 'suggestConfluencePagesButton' | 'improveIssueDropdown' | 'quickadd';

export type ResourceSuggestion = {
	resource: RelatedResource;
	isLinking: boolean;
	errorMessage?: string;
	resourceStatus: 'DRAFT' | 'ACCEPTED' | 'DISCARDED';
};

export type Step = 'HIDDEN' | 'FETCHING' | 'DISPLAY';
export const steps = {
	hidden: 'HIDDEN',
	fetching: 'FETCHING',
	display: 'DISPLAY',
} as const;
export type RelatedResourcesAnalyticsAttributes = {
	singleInstrumentationId: string;
	crossSelectedConfluenceSite?: string | null;
};
// State of the Related resources context
type State = {
	confluenceSiteAri: string | null;
	currentStep: Step;
	resourceSuggestions?: ResourceSuggestion[];
	errorMessage: string | null;
	invokedFrom: InvokedFrom | null;
	confluenceAppLinks: ConfluenceAppLink[] | [];
	restController: AbortController | null;
	hostNamesFromApplinks: { [key: string]: string };
	analyticsAttributes: RelatedResourcesAnalyticsAttributes;
};
export const INITIAL_STATE: State = {
	confluenceSiteAri: null,
	currentStep: steps.hidden,
	resourceSuggestions: undefined,
	errorMessage: null,
	invokedFrom: null,
	confluenceAppLinks: [],
	restController: null,
	hostNamesFromApplinks: {},
	analyticsAttributes: {
		singleInstrumentationId: '',
	},
};

export type ConfluencePageSet = (ConfluencePage | FailedRemoteLink)[];
export type ContainerProps = {
	issueId: string | undefined;
	issueKey: string | undefined;
	onCloseAISuggestions: () => void;
	onCreateConfluencePageLinkSuccess: (confluencePageLink: ConfluencePage | FailedRemoteLink) => {
		type: string;
		payload: { confluencePageLink: ConfluencePage | FailedRemoteLink };
	};
	linkedConfluencePages?: ConfluencePageSet;
	mentionedConfluencePages?: ConfluencePageSet;
};

export type JiraIssueRelatedResourcesContainerProps = ContainerProps & {
	// This is always provided inside related-resources-container.tsx
	initialConfluenceSiteAri: string | null;
};
const storage = createLocalStorageProvider(RELATED_RESOURCES_SITE_SWITCHER_LOCAL_STORAGE_KEY);

function cleanUrl(url: string) {
	// Use a regular expression to match and replace the unwanted parts
	return url.replace(/^https:\/\/(.*?)\/wiki$/, '$1');
}

const fetchFromConvoAI = async (
	issueKey: string,
	confluenceCloudAri: string | null,
	signal?: AbortSignal,
): Promise<ResourceSuggestion[]> => {
	const result = await invokeAIFeature(
		'jira-issue-related-resources',
		{
			ai_feature_path: 'ai-feature/relatedconfluencepages',
			ai_feature_input: {
				issue_key: issueKey,
				...(confluenceCloudAri && fg('jira-ai-related-resources-site-switcher')
					? { confluence_cloud_ari: confluenceCloudAri }
					: {}),
			},
		},
		signal,
	);
	return (result?.ai_feature_output?.related_confluence_pages || []).map((suggestedResource) => {
		return { resource: suggestedResource, isLinking: false, resourceStatus: 'DRAFT' };
	});
};

const fetchSuggestions =
	(
		invokedFrom: InvokedFrom,
		analyticsEvent?: UIAnalyticsEvent,
	): Action<State, JiraIssueRelatedResourcesContainerProps> =>
	async ({ setState, getState, dispatch }, { issueKey, initialConfluenceSiteAri }) => {
		setState({
			...INITIAL_STATE,
			invokedFrom,
			...(fg('jira-ai-related-resources-site-switcher')
				? {
						confluenceSiteAri: getState().confluenceSiteAri,
						confluenceAppLinks: getState().confluenceAppLinks,
					}
				: {}),
			currentStep: steps.fetching,
			restController: new AbortController(),
			hostNamesFromApplinks: getState().hostNamesFromApplinks,
			...(fg('bugfix_related_resources_conf_site_initialisation')
				? {
						analyticsAttributes: {
							singleInstrumentationId: uuid(),
							crossSelectedConfluenceSite: dispatch(getConfluenceSiteARIWithFallback()),
						},
					}
				: {}),
		});
		const { restController } = getState();
		try {
			if (issueKey == null) {
				/**
				 * Throwing here will go into the catch block & trigger error state + analytics
				 */
				throw new Error('invalid issueKey');
			}
			/**
			 * We use the initialConfluenceSiteAri which is passed from related-resources-container.tsx on initialisation of the component only if the confluenceSiteAri inside sweet-state is null
			 * It is set to null when the user has not selected a site from the site-switcher
			 * It is set to the site selected by the user when the user has selected a site from the site-switcher & will use that site for all subsequent fetches
			 */
			let confluenceSiteAri: string | null = null;
			if (fg('bugfix_related_resources_conf_site_initialisation')) {
				confluenceSiteAri = dispatch(getConfluenceSiteARIWithFallback());
			} else {
				confluenceSiteAri = getState().confluenceSiteAri
					? getState().confluenceSiteAri
					: initialConfluenceSiteAri;
			}

			const resourceSuggestions = await fetchFromConvoAI(
				issueKey,
				confluenceSiteAri,
				restController?.signal,
			);

			setState({
				restController: null,
				resourceSuggestions,
				currentStep: steps.display,
				errorMessage: null,
			});
		} catch (error) {
			// Abort is not treated as an error
			if (error instanceof DOMException && error.name === 'AbortError') {
				setState({
					restController: null,
					currentStep: steps.hidden,
					errorMessage: null,
				});
			} else {
				setState({
					restController: null,
					currentStep: steps.display,
					errorMessage: 'Error occured while fetching suggestions',
				});
				if (analyticsEvent && fg('jira-ai-related-resources-site-switcher')) {
					fireTrackAnalytics(analyticsEvent, 'aiResult error');
				}

				const errorInEvent =
					error instanceof Error ? error : new Error('Failed to fetch related resources');
				fireErrorAnalytics({
					error: errorInEvent,
					meta: {
						id: 'jiraIssueRelatedResourcesFetchSuggestions',
						packageName: 'jiraaiRelatedResources',
						teamName: 'gravity-ai',
					},
					sendToPrivacyUnsafeSplunk: true,
				});
			}
		}
	};

const hasNoDrafts = (suggestions: ResourceSuggestion[] | undefined): boolean => {
	return (
		suggestions !== undefined &&
		suggestions.filter((suggestion) => suggestion.resourceStatus === 'DRAFT').length === 0
	);
};

const isPageError = (page: ConfluencePage | FailedRemoteLink): page is FailedRemoteLink =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	(page as FailedRemoteLink).error !== undefined;

const getlinkedConfluenceUrls = (
	linkedConfluencePages: ConfluencePageSet | undefined,
	mentionedConfluencePages: ConfluencePageSet | undefined,
): Set<String> => {
	const urlSet = new Set<string>();
	[...(linkedConfluencePages || []), ...(mentionedConfluencePages || [])].forEach((page) => {
		const url = isPageError(page) ? page?.link?.href : page.href;
		urlSet.add(url);
	});
	return urlSet;
};

const acceptSuggestions =
	(linkResourcesRequest: RelatedResource[]): Action<State, ContainerProps> =>
	async (
		{ getState, setState },
		{
			onCloseAISuggestions,
			issueId,
			onCreateConfluencePageLinkSuccess,
			linkedConfluencePages,
			mentionedConfluencePages,
		},
	) => {
		const linkedUrls = getlinkedConfluenceUrls(linkedConfluencePages, mentionedConfluencePages);

		// Set isLinking to True
		const idsToBeLinked = linkResourcesRequest.map((res) => res.id);
		const resourceSuggestionsLinking = getState().resourceSuggestions?.map((res) =>
			idsToBeLinked.includes(res.resource.id) ? { ...res, isLinking: true } : res,
		);
		setState({
			resourceSuggestions: resourceSuggestionsLinking,
		});

		// keep track of all links transformed in the same order of linkResourceRequest
		const linkStack: { index: number; createdLink?: ConfluencePage | FailedRemoteLink }[] = [];

		// Call api to create the links
		linkResourcesRequest.forEach(async (request, index) => {
			const isSuccess = await (async () => {
				try {
					if (linkedUrls.has(request.url)) {
						// the requested url is already linked to the issue
						// then consider linking to be successful so that it goes to accepted state
						linkStack.push({ index });
						return true;
					}
					const createdLink = await createConfluencePageLinkRequest({
						issueId: issueId || '',
						pageHref: request.url,
					});
					linkStack.push({ index, createdLink });
					return true;
				} catch (error) {
					linkStack.push({ index });
					const errorInEvent =
						error instanceof Error
							? error
							: new Error('Failed to link confluence page to work item');
					fireErrorAnalytics({
						error: errorInEvent,
						meta: {
							id: 'jiraIssueRelatedResourcesAcceptSuggestion',
							packageName: 'jiraaiRelatedResources',
							teamName: 'gravity-ai',
						},
						sendToPrivacyUnsafeSplunk: false,
					});

					return false;
				}
			})();

			// maintain the order of suggestions using index before saving
			if (linkStack.length === linkResourcesRequest.length) {
				const sortedLinks = linkStack.sort((a, b) => a.index - b.index);
				sortedLinks.map(
					(link) => link.createdLink && onCreateConfluencePageLinkSuccess(link.createdLink),
				);
			}

			const resourceSuggestionsAfterLinking: ResourceSuggestion[] | undefined =
				getState().resourceSuggestions?.map((res: ResourceSuggestion) => {
					if (request.id === res.resource.id) {
						return {
							...res,
							isLinking: false,
							resourceStatus: isSuccess ? 'ACCEPTED' : res.resourceStatus,
							errorMessage: !isSuccess
								? 'Error occured while linking resource to issue'
								: undefined,
						};
					}
					return res;
				});
			setState({
				resourceSuggestions: resourceSuggestionsAfterLinking,
			});
			if (hasNoDrafts(resourceSuggestionsAfterLinking)) {
				setState({
					currentStep: steps.hidden,
				});
				onCloseAISuggestions();
			}
		});
	};

const discardSuggestion =
	(resourceToBeDiscarded: RelatedResource): Action<State, ContainerProps> =>
	({ getState, setState }, { onCloseAISuggestions }) => {
		const resourceSuggestionsAfterDiscarding: ResourceSuggestion[] | undefined =
			getState().resourceSuggestions?.map((res: ResourceSuggestion) =>
				resourceToBeDiscarded.id === res.resource.id
					? { ...res, resourceStatus: 'DISCARDED' }
					: res,
			);
		setState({
			resourceSuggestions: resourceSuggestionsAfterDiscarding,
		});

		if (hasNoDrafts(resourceSuggestionsAfterDiscarding)) {
			setState({
				currentStep: steps.hidden,
			});
			onCloseAISuggestions();
		}
	};

const discardAllSuggestions =
	(): Action<State, ContainerProps> =>
	({ setState, getState }, { onCloseAISuggestions }) => {
		const { restController } = getState();
		if (isRelatedResourcesFetching()) {
			restController?.abort();
		}
		setState({
			currentStep: steps.hidden,
		});
		onCloseAISuggestions();
	};

const setRelatedResourcesStep =
	(step: Step): Action<State> =>
	({ setState }) => {
		setState({ currentStep: step });
	};

const setConfluenceSiteAriFromCloudId =
	(cloudId: string): Action<State> =>
	({ setState }) => {
		setState({ confluenceSiteAri: ConfluenceSiteAri.create({ siteId: cloudId }).toString() });
	};

const setHostNamesFromApplinks =
	(confluenceAppLinks: ConfluenceAppLink[]): Action<State> =>
	({ setState }) => {
		const hostNamesFromApplinks = (confluenceAppLinks ?? []).reduce(
			(acc: { [key: string]: string }, { baseUrl, name, requireCredentials }) => {
				if (!fg('related_resources_site_switcher_oath_link_enabled')) {
					if (requireCredentials) {
						return acc;
					}
				}

				const hostname = cleanUrl(baseUrl);
				acc[hostname] = name;
				return acc;
			},
			{},
		);
		setState({ hostNamesFromApplinks });
	};

const isRelatedResourcesOpen =
	(): Action<State, void, Boolean> =>
	({ getState }) => {
		const { currentStep } = getState();
		return currentStep !== steps.hidden;
	};

const isRelatedResourcesFetching =
	(): Action<State, void, boolean> =>
	({ getState }) => {
		const { currentStep } = getState();
		return currentStep === steps.fetching;
	};
const getConfluenceSiteARIWithFallback =
	(): Action<State, JiraIssueRelatedResourcesContainerProps, string | null> =>
	({ getState, setState }, { initialConfluenceSiteAri }) => {
		if (fg('bugfix_related_resources_conf_site_initialisation')) {
			const selectedSiteFromLocalStorage: SiteSwitcherSelection = storage.get(
				RELATED_RESOURCES_SITE_SWITCHER_LOCAL_STORAGE_KEY,
			);
			if (getState().confluenceSiteAri) {
				return getState().confluenceSiteAri;
			}
			if (selectedSiteFromLocalStorage && selectedSiteFromLocalStorage.value) {
				const newAri = ConfluenceSiteAri.create({
					siteId: selectedSiteFromLocalStorage.value,
				}).toString();
				if (!getState().confluenceSiteAri || getState().confluenceSiteAri !== newAri) {
					setState({ confluenceSiteAri: newAri });
				}
				return newAri;
			}
		}

		return getState().confluenceSiteAri ? getState().confluenceSiteAri : initialConfluenceSiteAri;
	};

export const actions = {
	acceptSuggestions,
	discardSuggestion,
	discardAllSuggestions,
	fetchSuggestions,
	setRelatedResourcesStep,
	isRelatedResourcesOpen,
	setConfluenceSiteAriFromCloudId,
	isRelatedResourcesFetching,
	setHostNamesFromApplinks,
	getConfluenceSiteARIWithFallback,
};

type ActionsType = typeof actions;

export const JiraIssueRelatedResourcesContainer =
	createContainer<JiraIssueRelatedResourcesContainerProps>({
		displayName: 'JiraIssueRelatedResourcesContainer',
	});

export const Store = createStore<State, ActionsType, JiraIssueRelatedResourcesContainerProps>({
	initialState: INITIAL_STATE,
	actions,
	name: 'jira-ai-related-resources',
	containedBy: JiraIssueRelatedResourcesContainer,
});
export const useRelatedResources = createHook(Store);
export const useRelatedResourcesActions = createActionsHook(Store);
export const useRelatedResourcesState = createStateHook(Store);
