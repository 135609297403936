import { styled } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css as cssSC } from 'styled-components';
import { token } from '@atlaskit/tokens';
import type { TimestampStyles } from '@atlassian/jira-issue-timestamp/src/index.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Emphasise = styled.span({
	fontWeight: token('font.weight.medium'),
});
Emphasise.displayName = 'Emphasise';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const GridContainer = styled.span({
	gridRow: 1,
	marginLeft: token('space.050'),
	whiteSpace: 'nowrap',
});

GridContainer.displayName = 'GridContainer';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FeedContainer = styled.div({
	marginTop: token('space.300'),
	marginRight: 0,
	marginBottom: token('space.300'),
	marginLeft: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TimestampContainer = styled.span({
	marginLeft: token('space.100'),
});

TimestampContainer.displayName = 'TimestampContainer';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AdditionalHeaderContentPermalinkContainer = styled.span({
	/*
        Required because AkComment shifts the content too far over
        Can be removed once a generic ActivityItem component is made in Ak
    */
	marginLeft: token('space.negative.050'),
	display: 'grid',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const GridContentContainer = styled.span({
	display: 'grid',
});

GridContentContainer.displayName = 'GridContentContainer';

// TODO APP-442 migrate to Compiled
// see comment https://stash.atlassian.com/projects/JIRACLOUD/repos/jira-frontend/pull-requests/69086/overview?commentId=4060638
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ActivityTimestampStyles: TimestampStyles = {
	// @ts-expect-error - TS2322 - Type 'InterpolationValue[]' is not assignable to type 'string'.
	always: cssSC({
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text.subtle'),
	}),
	// @ts-expect-error - TS2322 -Type 'InterpolationValue[]' is not assignable to type 'string'.
	whenDisplaySettingSwitchable: cssSC({
		'&:hover': {
			color: 'inherit',
		},
	}),
};
