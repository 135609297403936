/* eslint-disable jira/restricted/@atlassian/react-sweet-state */
import {
	type SetState,
	createStore,
	createHook,
	createContainer,
} from '@atlassian/react-sweet-state';
import type { CoverMedia } from '@atlassian/jira-card-cover-manager/src/common/cover-media.types.tsx';
import { type IssueCoverMedia, transformCoverMedia } from './utils.tsx';

type State = {
	coverMedia: IssueCoverMedia | null;
};

type Actions = typeof actions;

const initialState: State = {
	coverMedia: null,
};

const actions = {
	updateCoverMedia:
		(coverMedia: CoverMedia) =>
		({ setState }: { setState: SetState<State> }) => {
			setState({
				coverMedia: transformCoverMedia(coverMedia),
			});
		},
};

const Store = createStore<State, Actions>({
	initialState,
	actions,
});

export const useIssueViewCoverStore = createHook(Store);

export const IssueViewCoverContainer = createContainer(Store);
