import React from 'react';
// TODO: JFP-2824 | Suppressed to enable upgrade to ESLint v9 - please fix this if you can!
// eslint-disable-next-line jira/restricted/@atlaskit+analytics-next
import { AnalyticsContext } from '@atlaskit/analytics-next';
import { AIOpsUnifiedEventsContainer } from '@atlassian/eoc-aiops-instrumentation';
import { GlobalContextContainer } from '@atlassian/jira-eoc-global-context/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { AIContextContainer } from '@atlassian/jira-servicedesk-ai-context-common/src/ui/ai-context-container/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { useUFOComponentExperience } from '@atlassian/ufo';
import type { IncidentSuggestionResource } from '../common/types.tsx';
import { loadOpsContextPanelExperience } from '../experiences.tsx';
import { OpsPanelContent } from './content/index.tsx';
import messages from './messages.tsx';
import { SendUnifiedEvents } from './send-analytics/index.tsx';

type Props = {
	resource: IncidentSuggestionResource;
};

export const AIContextOpsPanel = ({ resource }: Props) => {
	const { formatMessage } = useIntl();
	const { loading, error, data } = resource;

	const isLoading = loading || (!data && !error);

	useUFOComponentExperience(loadOpsContextPanelExperience);

	return (
		<UFOSegment name="ai-context-ops-panel">
			<AnalyticsContext
				data={{
					source: 'AIOpsContextPanel',
				}}
			>
				<GlobalContextContainer
					packageName="jiraServicedeskAIContextOpsPanel"
					teamName="itsm-aiops-grogu"
				>
					<AIOpsUnifiedEventsContainer
						aiFeatureName="incidentContextPanel"
						scope="incidentContextPanel"
					>
						<AIContextContainer
							isLoading={isLoading}
							error={error}
							attribution={formatMessage(messages.attribution)}
							groupId="ai-context-ops"
							title={formatMessage(messages.contextTitle)}
						>
							{data && <OpsPanelContent resource={resource} />}
						</AIContextContainer>
						<SendUnifiedEvents error={error} />
					</AIOpsUnifiedEventsContainer>
				</GlobalContextContainer>
			</AnalyticsContext>
		</UFOSegment>
	);
};
