import React from 'react';
import type { Dispatch } from 'redux';
import flow from 'lodash/flow';
import noop from 'lodash/noop';
import { withAnalyticsEvents } from '@atlaskit/analytics-next';
import { fg } from '@atlassian/jira-feature-gating';
import type { Action } from '@atlassian/jira-issue-view-actions/src/index.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import withContainerWidth from '@atlassian/jira-issue-view-common-utils/src/with-container-width/index.tsx';
import withFieldValue from '@atlassian/jira-issue-view-common/src/component/with-field-value/view.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { attachmentPickerPopupOpenSuccess } from '@atlassian/jira-issue-view-store/src/actions/attachment-popup-actions.tsx';
import {
	childIssueQuickAddClicked,
	issueInEpicQuickAddClicked,
	subtaskQuickAddClicked,
	type QuickAddPayloadType,
} from '@atlassian/jira-issue-view-store/src/actions/child-panel-actions.tsx';
import { totalChildIssueCountSelector } from '@atlassian/jira-issue-view-store/src/selectors/child-issues-limit-selector.tsx';
import {
	createConfluencePageLinkRequest,
	linkConfluencePageClicked,
	linkConfluencePageClickedWithAISuggestions,
} from '@atlassian/jira-issue-view-store/src/actions/confluence-pages-actions.tsx';
import {
	addContentPanelRequest,
	contentPanelSelected,
} from '@atlassian/jira-issue-view-store/src/actions/ecosystem-content-panel-actions.tsx';
import { fetchConfluenceAppLinks } from '@atlassian/jira-issue-view-store/src/actions/fetch-confluence-app-links-actions.tsx';
import { investigateIncidentClicked } from '@atlassian/jira-issue-view-store/src/actions/investigate-incident-actions.tsx';
import {
	addIssueLinkClick,
	onAILinkedIssueSuggestion,
} from '@atlassian/jira-issue-view-store/src/actions/issue-links-actions.tsx';
import { showLinkIdeaInput } from '@atlassian/jira-issue-view-store/src/actions/link-idea-actions.tsx';
import { showInsertLoomLinkForm } from '@atlassian/jira-issue-view-store/src/actions/loom-actions.tsx';
import { showCreateWebLinkForm } from '@atlassian/jira-issue-view-store/src/actions/web-links-actions.tsx';
import {
	issueKeySelector,
	localeSelector,
	isAnonymousSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { projectTypeSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import {
	canInstallAddonsSelector,
	ecosystemAllPanelsSelector,
} from '@atlassian/jira-issue-view-store/src/ecosystem/ecosystem-extensions-selector.tsx';
import { quickAddItemsPermissionsSelector } from '@atlassian/jira-issue-view-store/src/selectors/issue-actions-group-permissions-selector.tsx';
import { SERVICEDESK_PRACTICES_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import { toHref } from '@atlassian/jira-shared-types/src/general.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { QuickActionTimingMetricsProvider } from '../timing-metrics/context.tsx';
import type { QuickAddItemAllDispatchProps, QuickAddItemAllProps } from '../types.tsx';
import { QuickAddItems as QuickAddItemsView } from './view.tsx';

export const actionDispatchToProps = (dispatch: Dispatch<Action>) => ({
	onAddAttachmentClick: () => {
		dispatch(attachmentPickerPopupOpenSuccess());
	},
	onCreateSubtaskClick: (payload?: QuickAddPayloadType) => {
		if (expVal('quick_actions_m3_experiment', 'hotKeysEnabled', false)) {
			dispatch(subtaskQuickAddClicked(payload));
		} else {
			dispatch(subtaskQuickAddClicked());
		}
	},
	onCreateIssueInEpicClick: (payload?: QuickAddPayloadType) => {
		if (expVal('quick_actions_m3_experiment', 'hotKeysEnabled', false)) {
			dispatch(issueInEpicQuickAddClicked(payload));
		} else {
			dispatch(issueInEpicQuickAddClicked());
		}
	},
	onAddIssueLinkClick: (payload?: QuickAddPayloadType) => {
		if (expVal('quick_actions_m3_experiment', 'hotKeysEnabled', false)) {
			dispatch(addIssueLinkClick(undefined, payload));
		} else {
			dispatch(addIssueLinkClick());
		}
	},
	onChildCreateClick: (payload?: QuickAddPayloadType) => {
		if (expVal('quick_actions_m3_experiment', 'hotKeysEnabled', false)) {
			dispatch(childIssueQuickAddClicked(payload));
		} else {
			dispatch(childIssueQuickAddClicked());
		}
	},
	onLinkConfluencePageClick: () => {
		dispatch(linkConfluencePageClicked());
	},
	onInvestigateIncidentClick: () => {
		dispatch(investigateIncidentClicked());
	},
	onAddWebLinkClick: () => {
		dispatch(showCreateWebLinkForm());
	},
	onLinkIdeaClick: () => {
		dispatch(showLinkIdeaInput());
	},
});

const QuickAddItemsWithTiming = (props: QuickAddItemAllProps) => {
	return (
		<QuickActionTimingMetricsProvider>
			<QuickAddItemsView {...props} setInvokedFrom={noop} />
		</QuickActionTimingMetricsProvider>
	);
};

export const QuickAddItems = componentWithFG(
	'quick_actions_click_timing_metrics',
	QuickAddItemsWithTiming,
	QuickAddItemsView,
);

const QuickAddItemswithContainerWidth = withContainerWidth(QuickAddItems);

const flowConfig = flow(
	withFieldValue({
		fieldKey: SERVICEDESK_PRACTICES_TYPE,
		propName: 'practices',
	}),
	withAnalyticsEvents(),
	connect(
		(state: State) => ({
			...quickAddItemsPermissionsSelector(state),
			locale: localeSelector(state),
			projectType: projectTypeSelector(state),
			issueKey: issueKeySelector(state),
			isAnonymousUser: isAnonymousSelector(state),
			...(fg('quick_actions_menu_ga')
				? {
						availableAddons: ecosystemAllPanelsSelector(state),
						canInstallAddons: canInstallAddonsSelector(state),
					}
				: {}),
			...(fg('new_attributes_for_child_issue_create_click')
				? {
						childCount: totalChildIssueCountSelector(state),
					}
				: {}),
		}),
		(dispatch: Dispatch<Action>): QuickAddItemAllDispatchProps => ({
			...actionDispatchToProps(dispatch),
			...(fg('jira_issue_view_expand_modeless_ep') && {
				onLinkPage: (link: string, analyticsEvent: UIAnalyticsEvent) =>
					dispatch(createConfluencePageLinkRequest(toHref(link), analyticsEvent)),
			}),
			onAddExtension: (extension: string) => {
				dispatch(addContentPanelRequest(extension));
			},
			onSelectContentPanel: (addonModuleKey: string) => {
				dispatch(contentPanelSelected(addonModuleKey));
			},
			fetchConfluenceAppLinks: () => {
				dispatch(fetchConfluenceAppLinks());
			},
			onInsertLoomLinkClick: () => {
				dispatch(showInsertLoomLinkForm());
			},
			...(fg('aiops_suggestion_panel_for_incident') && {
				onAddIssueLinkWithAISuggestionClick: () => {
					dispatch(addIssueLinkClick());
					dispatch(onAILinkedIssueSuggestion(true));
				},
				onLinkConfluencePageWithAISuggestionClick: () => {
					dispatch(linkConfluencePageClickedWithAISuggestions());
				},
			}),
			...(expVal('jira_suggest_confluence_pages_when_linking_a_page', 'isEnabled', false) && {
				onLinkConfluencePageWithAISuggestionClick: () => {
					dispatch(linkConfluencePageClickedWithAISuggestions());
				},
			}),
		}),
	),
);

export default flowConfig(QuickAddItemswithContainerWidth);
export const QuickAddItemsWithoutContainerWidth = flowConfig(QuickAddItems);

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as getItemList } from './item-list';
