import { di } from 'react-magnetic-di';
import { v4 as uuid } from 'uuid';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { JiraIssueAri } from '@atlassian/ari/jira';
import logger from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { ContainerProps, ProactiveResponse, State } from '../types.tsx';

const headers = {
	'Content-Type': 'application/json',
	'X-Product': 'jira',
	'X-Experience-Id': 'proactive-jira-comment-summary',
};

export const closeSummaryPanel =
	(): Action<State, ContainerProps> =>
	async ({ setState }) => {
		setState({ isPanelShown: false });
	};

export const stopLoading =
	(): Action<State, ContainerProps> =>
	async ({ setState }) => {
		setState({ isGenerating: false, error: undefined });
	};

export const loadSummaryStream =
	(): Action<State, ContainerProps> =>
	({ setState }) => {
		setState({
			isGenerating: true,
			isPanelShown: true,
			error: undefined,
			hasTriggeredNonProactive: true,
		});
	};

export const setHasScrolledLayoutContainer =
	(): Action<State, ContainerProps> =>
	({ setState, getState }) => {
		if (getState().hasScrolledLayoutContainer) {
			return;
		}

		setState({ hasScrolledLayoutContainer: true });
	};

export const setIsGenerating =
	(isGenerating: boolean): Action<State, ContainerProps> =>
	({ setState }) => {
		setState({ isGenerating });
	};

export const setTriggeredProactive =
	(hasTriggeredProactive: boolean): Action<State, ContainerProps> =>
	({ setState }) => {
		setState({ hasTriggeredProactive });
	};

export const fetchProactiveSummary =
	(issueId: string, cloudId: string): Action<State, ContainerProps> =>
	async ({ setState }) => {
		di(fetch);

		try {
			const id = uuid();
			const issueAri = JiraIssueAri.create({ issueId, siteId: cloudId }).toString();

			const response = await fetch('/gateway/api/assist/api/ai/v2/ai-feature/proactive/jira', {
				method: 'POST',
				credentials: 'include',
				headers,
				body: JSON.stringify({
					ai_feature_input: {
						triggers: [
							{
								trigger_id: id,
								trigger_type: 'ISSUE_WITH_COMMENTS_VIEWED',
								trigger_details: {
									issue_ari: issueAri,
								},
							},
						],
					},
				}),
			});

			if (!response.ok) {
				const errorResponse = await response.json();
				throw new Error(errorResponse.errorMessage);
			}

			const data: ProactiveResponse = await response.json();

			// hydration has failed fetching the context or unethical error/error from LLM
			if (data.ai_feature_output.triggers[0].errors != null) {
				const errorType = data.ai_feature_output.triggers[0].errors.error_type;
				throw new Error(errorType);
			}

			// didn't pass our internal conditions and thus didn't hit LLM gateway at all.
			if (data.ai_feature_output.triggers[0].recommendations.length === 0) {
				setState({
					isGenerating: false,
					hasTriggeredProactive: true,
					hasValidProactiveResponse: false,
				});
				return;
			}

			const recommendation = data.ai_feature_output.triggers[0].recommendations[0];
			const summary = recommendation.transformation_details.summary;
			const recommendationId = recommendation.recommendation_id;

			setState({
				isGenerating: false,
				content: { summary, recommendationId },
				error: undefined,
				hasTriggeredProactive: true,
				hasValidProactiveResponse: true,
			});
		} catch (error) {
			// todo follow up with firing error analytics
			const errorObj = error instanceof Error ? error : new Error('Unidentified error');
			setState({
				isGenerating: false,
				error: errorObj,
				hasTriggeredProactive: true,
				hasValidProactiveResponse: false,
			});
		}
	};

export const updateProactiveSummaryCache =
	(issueId: string | undefined, cloudId: string): Action<State, ContainerProps> =>
	async ({ setState, getState }) => {
		try {
			if (issueId === undefined) {
				logger.safeWarnWithoutCustomerData(
					'jira.proactive-comment-summary.dismiss',
					'Issue Id is null',
				);
				return;
			}
			// already triggered it from show more button, dont need to unnecessary call api
			if (getState().hasUpdatedProactiveCache) {
				return;
			}

			const issueAri = JiraIssueAri.create({ issueId, siteId: cloudId }).toString();
			const recommendationId = getState().content?.recommendationId;

			if (recommendationId === undefined) {
				throw new Error('No recommendation Id');
			}

			const response = await fetch(
				'/gateway/api/assist/api/ai/v2/ai-feature/proactive/jira/dismiss',
				{
					method: 'POST',
					credentials: 'include',
					headers,
					body: JSON.stringify({
						ai_feature_input: {
							trigger_details: {
								issue_ari: issueAri,
							},
							recommendation_ids: [recommendationId],
						},
					}),
				},
			);

			if (!response.ok) {
				throw new Error('Failed to dismiss proactive summary');
			}

			setState({ hasUpdatedProactiveCache: true });
		} catch (err) {
			const errorObj = err instanceof Error ? err : new Error('Unidentified error');

			logger.safeErrorWithoutCustomerData(
				'jira.proactive-comment-summary.dismiss',
				errorObj.message,
			);
			setState({ hasUpdatedProactiveCache: false });
		}
	};

export const setProactivePanelShown =
	(isShown: boolean): Action<State, ContainerProps> =>
	({ setState }) => {
		setState({ isProactivePanelShown: isShown });
	};
