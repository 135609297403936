/* eslint-disable react-hooks/rules-of-hooks */
import React, {
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
	type ReactNode,
} from 'react';
import noop from 'lodash/noop';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import type { EntryPointActions } from '@atlassian/jira-entry-point/src/controllers/utils/types.tsx';
import type { EntryPointReferenceSubject } from '@atlassian/jira-entry-point/src/controllers/utils/use-entry-point-load-manager/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import {
	useCanAdministerJira,
	useCanAdministerProject,
} from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { inlineFieldConfigEntryPoint } from './inlineFieldConfigEntryPoint.tsx';

export type AddFieldConfigState = 'closed' | 'adding' | 'edit';

export type EditFieldConfigState = {
	mode: AddFieldConfigState;
	fieldId: string | null;
};

type EditFieldConfigContextValue = {
	editFieldConfigState: EditFieldConfigState;
	setEditFieldConfigState: (args: EditFieldConfigState) => void;
	onEditFieldConfigClick: (fieldId: string) => void;
	inlineFieldConfigEntryPointReferenceSubject: EntryPointReferenceSubject<
		typeof inlineFieldConfigEntryPoint
	> | null;
	inlineFieldConfigEntryPointActions: EntryPointActions | null;
	hasPermissions: boolean;
};

export const EditFieldConfigContext = createContext<EditFieldConfigContextValue>({
	editFieldConfigState: {
		mode: 'closed',
		fieldId: null,
	},
	setEditFieldConfigState: noop,
	onEditFieldConfigClick: noop,
	inlineFieldConfigEntryPointReferenceSubject: null,
	inlineFieldConfigEntryPointActions: null,
	hasPermissions: false,
});

export const ConditionalEditFieldConfigContextProvider = ({
	children,
	issueKey,
}: {
	children: ReactNode;
	issueKey: string;
}) => {
	if (!fg('issue_view_field_config_edit')) {
		return <>{children}</>;
	}

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [editFieldConfigState, setEditFieldConfigState] = useState<{
		mode: AddFieldConfigState;
		fieldId: string | null;
	}>({
		mode: 'closed',
		fieldId: null,
	});

	const {
		entryPointReferenceSubject: inlineFieldConfigEntryPointReferenceSubject,
		entryPointActions: inlineFieldConfigEntryPointActions,
	} = useEntryPoint(
		inlineFieldConfigEntryPoint,
		useMemo(() => ({}), []),
	);

	const projectKey = useProjectKey(issueKey ?? '');
	const isJiraAdmin = useCanAdministerJira(projectKey);
	const isProjectAdmin = useCanAdministerProject(projectKey);
	const hasPermissions = isJiraAdmin || isProjectAdmin;

	const onEditFieldConfigClick = useCallback(
		(fieldId: string) => {
			inlineFieldConfigEntryPointActions.load();
			setEditFieldConfigState({
				mode: 'edit',
				fieldId,
			});
			fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'inlineEditCustomField');
		},
		[createAnalyticsEvent, inlineFieldConfigEntryPointActions],
	);

	const memoizedContextValue = useMemo(
		() => ({
			editFieldConfigState,
			setEditFieldConfigState,
			onEditFieldConfigClick,
			inlineFieldConfigEntryPointReferenceSubject,
			inlineFieldConfigEntryPointActions,
			hasPermissions,
		}),
		[
			editFieldConfigState,
			setEditFieldConfigState,
			onEditFieldConfigClick,
			inlineFieldConfigEntryPointReferenceSubject,
			inlineFieldConfigEntryPointActions,
			hasPermissions,
		],
	);

	return (
		<EditFieldConfigContext.Provider value={memoizedContextValue}>
			{children}
		</EditFieldConfigContext.Provider>
	);
};

export const useEditFieldConfigContext = () => useContext(EditFieldConfigContext);
