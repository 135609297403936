import type { ViewModeType } from '@atlassian/jira-issue-view-common-types/src/context-type.tsx';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';

// TODO : Add a prefix to identify each app and also make sure you update the same in test
const localStorageProvider = createLocalStorageProvider('');

const getViewMode = (): string => {
	if (fg('jfp-vulcan-browser-storage-migration')) {
		return localStorageProvider.get('gh.ghx-issue-fragment-view-mode') || 'default';
	}
	if (typeof window !== 'undefined') {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		return window?.localStorage?.getItem('gh.ghx-issue-fragment-view-mode') || 'default';
	}
	return 'default';
};

const mapPredefinedViewMode = (predefinedViewMode: ViewModeType | null) => {
	switch (predefinedViewMode) {
		case 'MODAL':
			return 'modal';
		case 'SIDEBAR':
			return 'sidebar';
		default:
			return null;
	}
};

export const getSafeViewMode = (predefinedViewMode: ViewModeType | null = null) =>
	mapPredefinedViewMode(predefinedViewMode) || getViewMode().replace(/"/g, '');
