import type { Template } from '../types.tsx';
import { template as itsmKnownErrorsTemplate } from './itsm-known-errors-template.tsx';
import { template as projectPlanTemplate } from './project-plan-template.tsx';
import { template as requirementsTemplate } from './requirements-template.tsx';

export const templates: Record<string, Template> = {
	'com.atlassian.confluence.plugins.confluence-software-blueprints:requirements-blueprint': {
		id: 'com.atlassian.confluence.plugins.confluence-software-blueprints:requirements-blueprint',
		template: requirementsTemplate,
		urlSlug: 'product-requirements',
		title: 'Product Requirements',
	},
	'com.atlassian.confluence.plugins.confluence-business-blueprints:projectplan-blueprint': {
		id: 'com.atlassian.confluence.plugins.confluence-business-blueprints:projectplan-blueprint',
		template: projectPlanTemplate,
		urlSlug: 'project-plan',
		title: 'Project Plan',
	},
	'com.atlassian.confluence.plugins.confluence-business-blueprints:itsm-known-errors-blueprint': {
		id: 'com.atlassian.confluence.plugins.confluence-business-blueprints:itsm-known-errors-blueprint',
		template: itsmKnownErrorsTemplate,
		urlSlug: 'itsm-known-errors',
		title: 'Known Errors',
	},
};
