import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	layoutPreferencesBtn: {
		id: 'issue.views.issue-base.foundation.layout-preferences.btn',
		defaultMessage: 'Layout preferences',
		description: 'Default text for the activity layout preferences dialog trigger button',
	},
	layoutTitle: {
		id: 'issue.views.issue-base.foundation.layout-preferences.container',
		defaultMessage: 'Activity Layout',
		description: 'Default text for the activity layout preferences dialog content',
	},
	verticalOption: {
		id: 'issue.views.issue-base.foundation.layout-preferences.vertical.option',
		defaultMessage: 'Vertical',
		description: 'Label for the layout switcher vertical option',
	},
	verticalOptionDescription: {
		id: 'issue.views.issue-base.foundation.layout-preferences.vertical.option.description',
		defaultMessage: 'Best for collaboration',
		description: 'Description for the layout switcher vertical option',
	},
	horizontalOption: {
		id: 'issue.views.issue-base.foundation.layout-preferences.horizontal.option',
		defaultMessage: 'Horizontal',
		description: 'Label for the layout switcher horizontal option',
	},
	horizontalOptionDescription: {
		id: 'issue.views.issue-base.foundation.layout-preferences.horizontal.option.description',
		defaultMessage: 'Best for updating fields',
		description: 'Description for the layout switcher Horizontal option',
	},
});
