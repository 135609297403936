import React, { useContext } from 'react';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { createUseEntryPointContext } from '@atlassian/jira-entry-point/src/controllers/utils/create-use-entry-point-context/index.tsx';
import { AIOpsLinkConfluenceSuggestionsEntrypoint } from '../../entrypoint.tsx';

export const AIOpsLinkConfluenceSuggestionsEntrypointContext =
	createUseEntryPointContext<typeof AIOpsLinkConfluenceSuggestionsEntrypoint>();

const EMPTY_ENTRY_POINT_PARAMS = {};

export const useAIOpsLinkConfluenceSuggestionsEntryPointContext = () =>
	useContext(AIOpsLinkConfluenceSuggestionsEntrypointContext);

export const AIOpsLinkConfluenceSuggestionsEntrypointContextProvider = ({
	children,
}: React.PropsWithChildren<{}>) => {
	const entryPoint = useEntryPoint(
		AIOpsLinkConfluenceSuggestionsEntrypoint,
		EMPTY_ENTRY_POINT_PARAMS,
	);
	return (
		<AIOpsLinkConfluenceSuggestionsEntrypointContext.Provider value={entryPoint}>
			{children}
		</AIOpsLinkConfluenceSuggestionsEntrypointContext.Provider>
	);
};
