import type { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import {
	type ConfluencePage,
	toConfluencePageType,
} from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import type { RecentlyVisitedConfluencePageFromServer } from '@atlassian/jira-issue-view-store/src/actions/fetch-recently-visited-confluence-pages-actions.tsx';
import {
	toRemoteLinkGlobalId,
	toConfluencePageId,
	toHref,
} from '@atlassian/jira-shared-types/src/general.tsx';

const LIMIT = 20;
const SUPPORTED_CONTENT_TYPES = ['blogpost', 'page'];

// if a cloudId is provided, load from stargate
const createRecentlyVisitedConfluencePagesUrl = (cloudId: string) =>
	`/gateway/api/ex/confluence/${cloudId}/wiki/rest/recentlyviewed/1.0/recent?limit=${LIMIT}`;

const fetch = (cloudId: string) => fetchJson$(createRecentlyVisitedConfluencePagesUrl(cloudId));

const prefixUrlWithHost = (url: string) => {
	if (!url.includes('http')) {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		if (window && window.location) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			return `${window.location.protocol}//${window.location.host}${url}`;
		}
	}
	return url;
};

const transform = (
	response: Observable<RecentlyVisitedConfluencePageFromServer[]>,
	baseUrl: string,
): Observable<ConfluencePage[]> =>
	response.map((pages: RecentlyVisitedConfluencePageFromServer[]) =>
		pages
			.filter((page) => SUPPORTED_CONTENT_TYPES.includes(page.type))
			.map(
				(page) =>
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					({
						id: toConfluencePageId(page.id.toString()),
						globalId: toRemoteLinkGlobalId(''),
						href: toHref(prefixUrlWithHost(`${baseUrl}/wiki${page.url}`)),
						title: page.title,
						type: toConfluencePageType(page.contentType),
						space: page.space,
						spaceKey: page.spaceKey || '',
						subtype: page.subtype || '',
					}) as ConfluencePage,
			),
	);

const fetchRecentlyVisitedConfluencePages = (
	baseUrl: string,
	cloudId: string,
): Observable<ConfluencePage[]> =>
	// @ts-expect-error - TS2345 - Argument of type 'Observable<unknown>' is not assignable to parameter of type 'Observable<RecentlyVisitedConfluencePageFromServer[]>'.
	transform(fetch(cloudId), baseUrl);

export default fetchRecentlyVisitedConfluencePages;
