/* eslint-disable jira/filename-case */

/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { ComponentSkeletonImage } from '../../common/ui/ComponentSkeletonImage.tsx';

import activityFeedSVG from './assets/issue-activity-feed-skeleton.tpl.svg';

import breadbrumbsSVG from './assets/issue-breadcrumbs-skeleton.tpl.svg';

import contentSVG from './assets/issue-content-skeleton.tpl.svg';
import contentCompactSVG from './assets/issue-content-compact-skeleton.tpl.svg';

import fieldsSVG from './assets/issue-fields-skeleton.tpl.svg';
import fieldsCompactSVG from './assets/issue-fields-compact-skeleton.tpl.svg';

import headerActionsSVG from './assets/issue-header-actions-skeleton.tpl.svg';
import headerActionsCompactSVG from './assets/issue-header-actions-compact-skeleton.tpl.svg';

import quickAddSVG from './assets/issue-quick-add-skeleton.tpl.svg';
import quickAddCompactSVG from './assets/issue-quick-add-compact-skeleton.tpl.svg';

import statusSVG from './assets/issue-status-skeleton.tpl.svg';

import summarySVG from './assets/issue-summary-skeleton.tpl.svg';
import summaryCompactSVG from './assets/issue-summary-compact-skeleton.tpl.svg';

export const IssueActivityFeedSkeleton = () => (
	<ComponentSkeletonImage
		name="issue-activity-skeleton"
		src={activityFeedSVG}
		containerStyles={{ maxHeight: '21em' }}
	/>
);

export const IssueBreadcrumbsSkeleton = () => (
	<ComponentSkeletonImage
		name="issue-breadcrumbs-skeleton"
		src={breadbrumbsSVG}
		containerStyles={{ maxHeight: '2em' }}
	/>
);

export const IssueContentSkeleton = () => (
	<ComponentSkeletonImage
		name="issue-content-skeleton"
		src={contentSVG}
		containerStyles={{ maxHeight: '26em' }}
	/>
);

export const IssueContentCompactSkeleton = () => (
	<ComponentSkeletonImage
		name="issue-content-skeleton"
		src={contentCompactSVG}
		containerStyles={{ maxHeight: '21.5em' }}
	/>
);

export const IssueFieldsSkeleton = () => (
	<ComponentSkeletonImage
		name="issue-fields-skeleton"
		src={fieldsSVG}
		containerStyles={{ maxHeight: '25em' }}
	/>
);

export const IssueFieldsCompactSkeleton = () => (
	<ComponentSkeletonImage
		name="issue-fields-compact-skeleton"
		src={fieldsCompactSVG}
		containerStyles={{ maxHeight: '25em' }}
	/>
);

export const IssueHeaderActionsSkeleton = () => (
	<ComponentSkeletonImage
		name="issue-header-actions-skeleton"
		src={headerActionsSVG}
		containerStyles={{ maxHeight: '3em' }}
	/>
);

export const IssueHeaderActionsCompactSkeleton = () => (
	<ComponentSkeletonImage
		name="issue-header-actions-compact-skeleton"
		src={headerActionsCompactSVG}
		containerStyles={{ maxHeight: '3.5em' }}
	/>
);

export const IssueQuickAddSkeleton = () => (
	<ComponentSkeletonImage
		name="issue-quick-add-skeleton"
		src={quickAddSVG}
		containerStyles={{ maxHeight: '4em' }}
	/>
);

export const IssueQuickAddCompactSkeleton = () => (
	<ComponentSkeletonImage
		name="issue-quick-add-compact-skeleton"
		src={quickAddCompactSVG}
		containerStyles={{ maxHeight: '4em' }}
	/>
);

export const IssueStatusSkeleton = () => (
	<ComponentSkeletonImage
		name="issue-status-skeleton"
		src={statusSVG}
		containerStyles={{ maxHeight: '3.5em' }}
	/>
);

export const IssueSummarySkeleton = () => (
	<ComponentSkeletonImage
		name="issue-summary-skeleton"
		src={summarySVG}
		containerStyles={{ maxHeight: '3.5em' }}
	/>
);

export const IssueSummaryCompactSkeleton = () => (
	<ComponentSkeletonImage
		name="issue-summary-compact-skeleton"
		src={summaryCompactSVG}
		containerStyles={{ maxHeight: '4.5em' }}
	/>
);

export const IssueFullContextSkeleton = () => {
	return (
		<>
			<IssueHeaderActionsSkeleton />
			<IssueStatusSkeleton />
			<IssueFieldsSkeleton />
		</>
	);
};

export const IssueFullContextCompactSkeleton = () => {
	return (
		<>
			<IssueFieldsCompactSkeleton />
		</>
	);
};

export const IssueFullContentSkeleton = () => {
	return (
		<>
			<IssueBreadcrumbsSkeleton />
			<IssueSummarySkeleton />
			<IssueQuickAddSkeleton />
			<IssueContentSkeleton />
		</>
	);
};

const issueFullContentHeaderCompactSkeletonStyles = css({
	display: 'flex',
	justifyContent: 'space-between',
});

export const IssueFullContentHeaderCompactSkeleton = () => {
	return (
		<div css={issueFullContentHeaderCompactSkeletonStyles}>
			<IssueBreadcrumbsSkeleton />
			<IssueHeaderActionsCompactSkeleton />
		</div>
	);
};

export const IssueFullContentCompactSkeleton = () => {
	return (
		<>
			<IssueSummaryCompactSkeleton />
			<IssueQuickAddCompactSkeleton />
			<IssueStatusSkeleton />
			<IssueContentCompactSkeleton />
		</>
	);
};
