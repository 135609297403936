import type { AssociatedIssuesContextActions } from '@atlassian/jira-associated-issues-context-service/src/actions.tsx';
import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types.tsx';
import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { CallbackPayload } from '@atlassian/jira-issue-create-extensibility/src/common/utils/lifecycle-events/types.tsx';
import type { Payload as GICPayload } from '@atlassian/jira-issue-create/src/common/types/index.tsx';
import type { FieldConfigServiceActions } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';
import type { FieldValueServiceActions } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { ServerAssociatedIssue } from '@atlassian/jira-issue-shared-types/src/common/types/associated-issue-type.tsx';
import type { ChildIssue } from '@atlassian/jira-issue-view-common-types/src/children-issues-type.tsx';
import type {
	FetchIssueTypesStatusState,
	FieldMeta,
} from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import type { TriggerPointKeyType } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/types.tsx';
import type { ApplicationEditions } from '@atlassian/jira-shared-types/src/edition.tsx';
import type {
	BaseUrl,
	IssueId,
	IssueKey,
	ProjectId,
	AccountId,
	IssueTypeId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import type { MainIssueAggQueryRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import type { AddChildEventHandler } from '../view/heading/add-child/types.tsx';
import type { OnChangeCallback } from './on-change-callback.tsx';

export type ChildIssueTypeWithFields = {
	id: string;
	name: string;
	iconUrl: string;
	fields?: {
		[key: string]: FieldMeta;
	};
};

export type OptimisticUiChildIssue = {
	id: string;
	issueSummary: string;
	issueTypeId: string;
	issueTypeIconUrl: string;
	issueTypeName: string;
	assigneeUrl: string | null;
	assigneeDisplayName: string | null;
};

export type ChildIssuePanelMessages = {
	title: MessageDescriptor;
	titleChildIssues?: MessageDescriptor;
	noValueText?: MessageDescriptor;
};

export const CLASSIC_SUBTASKS = 'classicSubtasks' as const;
export const CLASSIC_PROJECT_EPIC_CHILDREN = 'classicProjectEpicChildren' as const;
export const CLASSIC_PORTFOLIO_CHILDREN = 'portfolioChildren' as const;
// Formerly known as NEXTGEN_CHILDREN Panel - now used for all child issues apart from CMP subtasks
export const CHILDREN_ISSUES_PANEL = 'nextGenChildren' as const;

export type ChildIssuesPanelType =
	| typeof CLASSIC_SUBTASKS
	| typeof CLASSIC_PROJECT_EPIC_CHILDREN
	| typeof CLASSIC_PORTFOLIO_CHILDREN
	| typeof CHILDREN_ISSUES_PANEL;

export type FetchIssueTypesStatus = FetchIssueTypesStatusState;

export const SORT_OPTION_RANKING = 'ranking' as const;
export const SORT_OPTION_RANK = 'rank' as const;
export const SORT_OPTION_CREATED = 'created' as const;
export const SORT_OPTION_KEY = 'key' as const;
export const SORT_OPTION_PRIORITY = 'priority' as const;
export const SORT_OPTION_STATUS = 'status' as const;
export const SORT_OPTION_ASSIGNEE = 'assignee' as const;

export type SortingOption =
	| typeof SORT_OPTION_RANKING
	| typeof SORT_OPTION_CREATED
	| typeof SORT_OPTION_KEY
	| typeof SORT_OPTION_PRIORITY
	| typeof SORT_OPTION_STATUS
	| typeof SORT_OPTION_ASSIGNEE;

export type AppProps = {
	issueTypes: ChildIssueTypeWithFields[];
	// remove when isIssueKeyStrikethroughBasedOnResolutionEnabled is cleaned up
	issues: ChildIssue[];
	isIssueViewComplete: boolean;
	issueHierarchyLevel: number;
	// remove when isUseChildIssuesLimitEnabled is cleaned up
	hasExceededIssuesLimitAfterLoad?: boolean;
	childIssuesPanelType: ChildIssuesPanelType;
	supportsIssueCreation: boolean | undefined;
	baseUrl: BaseUrl;
	locale: Locale;
	user: AccountId | null;
	appEditions: ApplicationEditions;
	parentKey: IssueKey;
	customFieldIdRank: number | null;
	epicLinkFieldKey: string | null;
	parentId: IssueId | null;
	parentIssueTypeId: IssueTypeId | null;
	projectId: ProjectId | null;
	projectType: ProjectType | null;
	isSimplifiedProject: boolean;
	messages: ChildIssuePanelMessages;
	quickAddClickCount: number;
	triggeredViaHotKey?: boolean;
	fetchIssueTypesStatus: FetchIssueTypesStatus | undefined;
	analyticsSource: string;
	childIssuesLimitUrl: string;
	totalChildIssueCount: number;
	onNavigateToNewIssue: (arg1: {
		fromIssueKey: IssueKey;
		toIssueKey: IssueKey;
		meta?: {
			location: string;
		};
	}) => void;
	onChange: OnChangeCallback;
	onAddChildClick: AddChildEventHandler | undefined;
	onIssueBreakdownClick?: (() => void) | undefined;
	associatedIssuesContextActions?: AssociatedIssuesContextActions;
	fieldValuesActions?: FieldValueServiceActions;
	fieldConfigActions?: FieldConfigServiceActions;
	onEditAssociatedIssue: (issueKey?: IssueKey) => void;
	// TODO: Remove on jira-issue-view-child-issues-panel-merge-subtasks cleanup
	filterSubtasks: boolean | null;
	hasMultipleChildPanels?: boolean;
	issueCreateSessionId?: string;
	rootRelayFragment: MainIssueAggQueryRelayFragment | null;
};

export type OpenIssueCreateModalProps = {
	isSubTaskCreationOpen: boolean;
	payload: GICPayload | null;
	fieldsToAddInPublishedData: [string | null];
	triggerPointKey?: TriggerPointKeyType;
	sessionId?: string;
	onIssueCreate?: (data: { createdIssueDetails: ServerAssociatedIssue }) => void;
	/**
	 * Used to identify the origin of the GIC trigger as well as saving relevant data
	 * to be used in conjunction of GIC subscription events
	 */
	callbackPayload?: CallbackPayload;
	triggeredViaHotKey?: boolean;
};

export const DEFAULT_SORTING_OPTION: SortingOption = SORT_OPTION_RANKING;

export const SORTING_OPTIONS: SortingOption[] = [
	SORT_OPTION_RANKING,
	SORT_OPTION_CREATED,
	SORT_OPTION_KEY,
	SORT_OPTION_PRIORITY,
	SORT_OPTION_STATUS,
	SORT_OPTION_ASSIGNEE,
];
