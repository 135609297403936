import { useMemo } from 'react';
import omit from 'lodash/omit';
import { useMemoizedStateObject } from '@atlassian/jira-forge-ui-controllers/src/controllers/memoized-state-object/index.tsx';
import { useForgeStore, useForgeActionsStore } from './context.tsx';
import type { State } from './types.tsx';

type BlockedExtensionKey = keyof State['blockedExtensions'];
type BlockedExtensionValue = State['blockedExtensions'][BlockedExtensionKey];

export const useForgeGlances = () => {
	const [forge] = useForgeStore();
	return [forge.issueGlance] as const;
};

export const useForgeContextPanels = () => {
	const [forge] = useForgeStore();
	return [forge.issueContext] as const;
};

/**
 * The forgeDataComplete value determines whether the forge request has finished obtaining all the available
 * data from the BE. It is expected to be used in analytics.
 */
export const useForgeDataComplete = () => {
	const [forge] = useForgeStore();
	return [forge.isForgeDataComplete] as const;
};

export const useForgeCustomFields = () => {
	const [forge] = useForgeStore();
	return [[...forge.customField, ...forge.customFieldType]] as const;
};

/**
 * Every time issue refreshes the forge data is refetched.
 * On each forge data refetch the store is updated with a new data.
 * Because objects are compared by refs, every time such event occurs lots of components (may) rerender.
 * The reason for that are updated extensions.
 * We could be adding explicitly only needed props as dependencies, but it will result in lots of refactoring and fat list of dependencies.
 * The solution to it is to use previous data if the new one is identical.
 * So the extension in here will be always the same extension no matter how many times the issues is refreshed unless the app is redeployed.
 */
export const useForgeCustomField = (extensionId: string) => {
	const [customFieldExtensions] = useForgeCustomFields();
	return useMemoizedStateObject(
		useMemo(
			() => customFieldExtensions.find((ext) => ext.id === extensionId),
			[customFieldExtensions, extensionId],
		),
	);
};

export const useForgeActions = () => {
	const [, actions] = useForgeActionsStore();
	return [undefined, actions] as const;
};

export const useForgeLoadingFailed = () => {
	const [forge] = useForgeStore();
	return forge.isForgeLoadingFailed;
};

export const useForgeUimModules = () => {
	const [forge] = useForgeStore();
	return forge.uiModifications;
};

export const useHasBlockedExtensions = () => {
	const [forge] = useForgeStore();

	return useMemo(() => {
		if (!forge.blockedExtensions) return false;
		const toOmit: BlockedExtensionKey[] = ['issueViewBackgroundScript', 'uiModifications'] as const;
		return Object.values<BlockedExtensionValue>(omit(forge.blockedExtensions, toOmit)).some(
			(blockedExtensions) => blockedExtensions.length > 0,
		);
	}, [forge.blockedExtensions]);
};

export const useBlockedExtensions = () => {
	const [forge] = useForgeStore();
	return forge.blockedExtensions;
};

export const useIsForgeDataEmpty = () => {
	const [forge] = useForgeStore();

	const isExtensionsEmpty = Object.keys(forge).every((key) =>
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		Array.isArray(forge[key as keyof typeof forge])
			? // @ts-expect-error TS does not understand isArray method
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				forge[key as keyof typeof forge].length === 0
			: true,
	);

	const isBlockedExtensionsEmpty = Object.keys(forge.blockedExtensions).every(
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		(key) => forge.blockedExtensions[key as keyof typeof forge.blockedExtensions].length === 0,
	);

	return isExtensionsEmpty && isBlockedExtensionsEmpty;
};
