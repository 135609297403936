import head from 'lodash/head';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	fieldCanBeHidden,
	isGlobalSystemField,
} from '@atlassian/jira-polaris-domain-field/src/field/utils.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import type { FieldRemote } from '@atlassian/jira-polaris-remote-field/src/types.tsx';
import type { Props, State } from '../../types.tsx';
import { createGetFieldByKey } from '../../selectors/field.tsx';

const DUAL_WRITE_SUPPORTED_FIELD_TYPES = new Set<FieldType>([
	FIELD_TYPES.ATLAS_GOAL,
	FIELD_TYPES.PLATFORM_GOALS,
	FIELD_TYPES.ATLAS_PROJECT,
	FIELD_TYPES.ATLAS_PROJECT_STATUS,
	FIELD_TYPES.DELIVERY_PROGRESS,
	FIELD_TYPES.DELIVERY_STATUS,
	FIELD_TYPES.INSIGHTS,
	FIELD_TYPES.ISSUE_COMMENTS,
	FIELD_TYPES.LINKED_ISSUES,
]);

export const updateSystemFieldHidden =
	(fieldKey: FieldKey, hidden: boolean) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ onFieldUpdateFailed, fieldRemote, issueTypeIds, createAnalyticsEvent }: Props,
	): Promise<void> => {
		const field = createGetFieldByKey(fieldKey)(getState());
		if (!field || !fieldCanBeHidden(field.type) || field.configuration?.hidden === hidden) {
			return;
		}

		const issueTypeId = head(issueTypeIds);

		try {
			if (issueTypeId === undefined) {
				throw new Error('cannot update system field hidden for unknown issue type');
			}

			const configuration = {
				...field.configuration,
				hidden,
			};

			const fields = { ...getState().fields };
			setState({
				fields: {
					...fields,
					[fieldKey]: {
						...fields[fieldKey],
						configuration,
					},
				},
			});

			try {
				await fieldRemote.updateFieldConfiguration({
					oldConfiguration: field.configuration ?? {},
					configuration,
					fieldKey: field.key,
					issueTypeId,
					type: 'updateSystemFieldHidden',
					isGlobalSystemField: isGlobalSystemField(field),
				});
			} catch (error) {
				const currentState = getState();
				const currentFields = currentState.fields;

				setState({
					fields: {
						...currentFields,
						[fieldKey]: {
							...currentFields[fieldKey],
							configuration: {
								...currentFields[fieldKey].configuration,
								hidden: field.configuration?.hidden ?? !hidden,
							},
						},
					},
				});

				throw error;
			}

			fireTrackAnalytics(createAnalyticsEvent({}), 'issueFields updated', {
				issueFieldKey: fieldKey,
				issueFieldType: field.type,
				updatedItems: [{ name: 'hidden' }],
			});

			if (DUAL_WRITE_SUPPORTED_FIELD_TYPES.has(field.type)) {
				await dualWriteSystemFieldHiddenBasedOnFieldType({
					fieldKey,
					fieldType: field.type,
					issueTypeId,
					fieldRemote,
					hidden,
					getState,
					setState,
				});
			}
		} catch (error) {
			onFieldUpdateFailed(
				error instanceof Error ? error : new Error('failed to update system field hidden'),
			);
			throw error;
		}
	};

export const dualWriteSystemFieldHiddenBasedOnFieldType = async ({
	fieldKey,
	fieldType,
	issueTypeId,
	fieldRemote,
	hidden,
	getState,
	setState,
}: {
	fieldKey: FieldKey;
	fieldType: FieldType;
	issueTypeId: string;
	fieldRemote: FieldRemote;
	hidden: boolean;
	getState: StoreActionApi<State>['getState'];
	setState: StoreActionApi<State>['setState'];
}) => {
	const fields = getState().fields;

	const fieldTypesToMatch: FieldType[] =
		fieldType === FIELD_TYPES.ATLAS_GOAL || fieldType === FIELD_TYPES.PLATFORM_GOALS
			? [FIELD_TYPES.ATLAS_GOAL, FIELD_TYPES.PLATFORM_GOALS]
			: [fieldType];

	const otherField = Object.values(fields).find(
		(field) => fieldTypesToMatch.includes(field.type) && field.key !== fieldKey,
	);

	if (!otherField || otherField.configuration?.hidden === hidden) {
		return;
	}

	const configuration = {
		...otherField.configuration,
		hidden,
	};

	setState({
		fields: {
			...fields,
			[otherField.key]: {
				...fields[otherField.key],
				configuration,
			},
		},
	});

	try {
		await fieldRemote.updateFieldConfiguration({
			oldConfiguration: otherField.configuration ?? {},
			configuration,
			fieldKey: otherField.key,
			issueTypeId,
			type: 'updateSystemFieldHidden',
			isGlobalSystemField: isGlobalSystemField(otherField),
		});
	} catch (error) {
		const currentState = getState();
		const currentFields = currentState.fields;

		setState({
			fields: {
				...currentFields,
				[otherField.key]: {
					...currentFields[otherField.key],
					configuration: {
						...currentFields[otherField.key].configuration,
						hidden: otherField.configuration?.hidden ?? !hidden,
					},
				},
			},
		});

		throw error;
	}
};
