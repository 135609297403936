import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

const CascadingEditViewEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-priority-edit-view" */ './ui/cascading-select'),
	),
	getPreloadProps: () => ({}),
});

export default CascadingEditViewEntryPoint;
