import React, { type SyntheticEvent, type MouseEvent, forwardRef } from 'react';
import { IconButton, type IconButtonProps } from '@atlaskit/button/new';
import { Box, xcss } from '@atlaskit/primitives';
import DeleteIcon from '@atlaskit/icon/core/delete';
import WorkItemAddIcon from '@atlaskit/icon-lab/core/work-item-add';
import EditIcon from '@atlaskit/icon/core/edit';
import type { IntlShape as Intl } from '@atlassian/jira-intl';
import { PermalinkButton } from '@atlassian/jira-issue-view-common/src/component/permalink-button/button/async.tsx';
import type { UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import {
	ModalEntryPointPressableTrigger,
	type ModalEntryPointPressableTriggerProps,
} from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import { PermalinkType } from '@atlassian/jira-issue-fetch-services-common/src/common/utils/permalinks.tsx';
import { deleteIssueLinkConfirmationEntryPoint } from '../confirmation-modal/entrypoint.tsx';
import messages from './messages.tsx';

type PersistedCommentProps = {
	formatMessage: Intl['formatMessage'];
	fullIssueUrl: string;
	commentId: string;
	shouldShowCreateButton: boolean | undefined;
	canDelete: boolean;
	canEdit: boolean;
	onClickCreateIssue?: () => void;
	onCopyClick: (e: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => void;
	onEditClick: (
		_: MouseEvent<HTMLElement>,
		analyticsEvent?: UIAnalyticsEvent,
		isSiteAdmin?: boolean,
	) => void;
	onDeleteClick: () => void;
	deleteEntryPointProps: ModalEntryPointPressableTriggerProps<
		typeof deleteIssueLinkConfirmationEntryPoint
	>['entryPointProps'];
};

type OptimisticCommentProps = {
	formatMessage: Intl['formatMessage'];
	hasSaveFailed: boolean;
	onSaveEdit: () => void;
};

const BaseIconButton = forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => (
	<IconButton
		{...props}
		ref={ref}
		appearance="subtle"
		spacing="compact"
		isTooltipDisabled={false}
	/>
));

export const getFloatingMenuItems = ({
	formatMessage,
	fullIssueUrl,
	commentId,
	shouldShowCreateButton,
	canDelete,
	canEdit,
	onClickCreateIssue,
	onCopyClick,
	onEditClick,
	onDeleteClick,
	deleteEntryPointProps,
}: PersistedCommentProps) => {
	const deleteConfirmModalProps = {
		width: 'small',
		testId: 'issue-comment-base.ui.comment.confirmation-modal.modal-dialog',
	};
	const items = [];

	if (onClickCreateIssue && shouldShowCreateButton) {
		items.push(
			<BaseIconButton
				key="create-work-item"
				icon={WorkItemAddIcon}
				label={formatMessage(messages.createWorkItem)}
				onClick={onClickCreateIssue}
			/>,
		);
	}

	items.push(
		<Box xcss={copyLinkStyle} key="permalink">
			<PermalinkButton
				label={formatMessage(messages.copy)}
				onCopyClick={onCopyClick}
				isVisible
				id={commentId}
				fullIssueUrl={`${fullIssueUrl}?${PermalinkType.COMMENTS}=${commentId}`}
				disableTransition
			/>
		</Box>,
	);

	if (canEdit) {
		items.push(
			<BaseIconButton
				key="edit"
				icon={EditIcon}
				label={formatMessage(messages.edit)}
				onClick={onEditClick}
			/>,
		);
	}

	if (canDelete) {
		items.push(
			<ModalEntryPointPressableTrigger
				entryPoint={deleteIssueLinkConfirmationEntryPoint}
				entryPointProps={deleteEntryPointProps}
				useInternalModal
				modalProps={deleteConfirmModalProps}
				key="delete-entry-point"
			>
				{({ ref }) => (
					<BaseIconButton
						key="delete"
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						ref={ref as React.Ref<HTMLButtonElement>}
						icon={DeleteIcon}
						label={formatMessage(messages.delete)}
						onClick={onDeleteClick}
					/>
				)}
			</ModalEntryPointPressableTrigger>,
		);
	}

	return items;
};

const copyLinkStyle = xcss({
	paddingRight: 'space.050',
});

export const getFloatingMenuItemsForOptimisticComment = ({
	hasSaveFailed,
	onSaveEdit,
	formatMessage,
}: OptimisticCommentProps) => {
	if (hasSaveFailed) {
		return [
			<BaseIconButton
				key="edit"
				icon={EditIcon}
				label={formatMessage(messages.edit)}
				onClick={onSaveEdit}
			/>,
		];
	}
	return [];
};
