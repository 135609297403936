/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import Tabs, { Tab, TabList, TabPanel, type TabsProps } from '@atlaskit/tabs';
import Tooltip from '@atlaskit/tooltip';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { ConditionallyVisibleDiv } from '@atlassian/jira-issue-adjustments/src/ui.tsx';

type TabData = {
	id: string;
	label: string;
	content: JSX.Element;
	isVisible: boolean;
};

export const TAB_TEST_ID = 'issue-view-layout-templates-tab';

type StaticProps = {
	tabs: TabData[];
	selected?: number;
	onChange?: TabsProps['onChange'];
};

const getTab = ({ id, label, key }: { id: string; label: string; key?: string }) => {
	if (fg('fix-gryf-a11y-issues')) {
		return (
			<div css={tabStyles}>
				<Tab testId={`${TAB_TEST_ID}-${id}`} {...(key ? { key } : {})}>
					{label}
				</Tab>
			</div>
		);
	}

	return (
		<Tab testId={`${TAB_TEST_ID}-${id}`} {...(key ? { key } : {})}>
			{label}
		</Tab>
	);
};

/**
 * Tab layout items come from /gira, but hydrated Tab data comes from /graphql which is not available to SSR.
 * If we don't have the /graphql data, we short-circuit the rendering of the tabs in order to provide our
 * own custom skeleton components. Note this means we also can't do any smarts around empty tabs, so
 * we have to assume that all tabs are non-empty, but this will only create jank in very small edge cases.
 */
export const StaticTabs = ({ tabs, selected, onChange }: StaticProps) => (
	<Tabs
		testId="issue-view-layout-templates-tab-view.ui.static-tabs.tabs"
		selected={selected}
		onChange={onChange}
		id="issue-view-layout-templates-tabs"
		shouldUnmountTabPanelOnChange
	>
		<TabList>
			{tabs.map((tab) => (
				<ConditionallyVisibleDiv key={tab.id} isVisible={tab.isVisible}>
					{tabs.length > 2 ? (
						<Tooltip key={tab.id} content={tab.label} position="bottom">
							{getTab({ id: tab.id, label: tab.label })}
						</Tooltip>
					) : (
						getTab({ id: tab.id, label: tab.label, key: tab.id })
					)}
				</ConditionallyVisibleDiv>
			))}
		</TabList>
		{tabs.map((tab) => (
			<TabPanel key={tab.id}>{tab?.content}</TabPanel>
		))}
	</Tabs>
);

const tabStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'> div > span': {
		fontWeight: token('font.weight.medium'),
	},
});
