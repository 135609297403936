import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { fg } from '@atlassian/jira-feature-gating';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import { IssueViewAssigneeField } from '@atlassian/jira-issue-view-layout-assignee-field/src/ui/index.tsx';
import { IssueViewReporterField } from '@atlassian/jira-issue-view-layout-reporter-field/src/ui/index.tsx';
import { IssueViewUserField } from '@atlassian/jira-issue-view-layout-user-field/src/ui/index.tsx';
import {
	ASSIGNEE_TYPE,
	REPORTER_TYPE,
	USER_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { useIsJCSProjectType } from '@atlassian/jira-servicedesk-customer-service-common/src/utils/use-is-jcs/index.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	useProjectKey,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { JCSReporterField } from '@atlassian/jira-servicedesk-customer-service-custom-fields/src/ui/issue-view-reporter-field/index.tsx';
import type { ui_issueViewLayoutSingleSelectUserPickerField_IssueViewSingleSelectUserPickerField_rootRelayFragment$key as SingleSelectUserPickerFieldRootQueryFragment } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutSingleSelectUserPickerField_IssueViewSingleSelectUserPickerField_rootRelayFragment.graphql';
import type { ui_issueViewLayoutSingleSelectUserPickerField_IssueViewSingleSelectUserPickerField_fragmentKey$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutSingleSelectUserPickerField_IssueViewSingleSelectUserPickerField_fragmentKey.graphql';

export interface IssueViewSingleSelectUserPickerFieldProps {
	area?: Area;
	fragmentKey: ui_issueViewLayoutSingleSelectUserPickerField_IssueViewSingleSelectUserPickerField_fragmentKey$key;
	rootRelayFragment?: SingleSelectUserPickerFieldRootQueryFragment | null;
}

/**
 * If you implement USER_CF_TYPE remember to update
 * getRelayLayoutExclusions in jira/src/packages/issue/issue-view-layout-templates/components/layout-item/src/index.tsx
 */
export const IssueViewSingleSelectUserPickerField = ({
	area,
	fragmentKey,
	rootRelayFragment,
}: IssueViewSingleSelectUserPickerFieldProps) => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);
	const isJCS = useIsJCSProjectType(projectType);
	const data =
		useFragment<ui_issueViewLayoutSingleSelectUserPickerField_IssueViewSingleSelectUserPickerField_fragmentKey$key>(
			graphql`
				fragment ui_issueViewLayoutSingleSelectUserPickerField_IssueViewSingleSelectUserPickerField_fragmentKey on JiraSingleSelectUserPickerField
				@argumentDefinitions(
					issueViewRelayAssigneeFieldFlag: {
						type: "Boolean!"
						provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-assignee.relayprovider"
					}
					issueViewRelayReporterFieldFlag: {
						type: "Boolean!"
						provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-reporter.relayprovider"
					}
					issueViewRelayUserFieldFlag: {
						type: "Boolean!"
						provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-user.relayprovider"
					}
					jcsFlag: { type: "Boolean!", defaultValue: false }
				) {
					...ui_issueViewLayoutAssigneeField_IssueViewAssigneeField_fragmentKey
						@include(if: $issueViewRelayAssigneeFieldFlag)
					...ui_issueViewLayoutReporterField_IssueViewReporterField
						@include(if: $issueViewRelayReporterFieldFlag)
					...ui_issueViewLayoutUserField_IssueViewUserField
						@include(if: $issueViewRelayUserFieldFlag)
					...issueViewReporterField_servicedeskCustomerServiceCustomFields_JCSReporterFieldInner
						@include(if: $jcsFlag)
					type
				}
			`,
			fragmentKey,
		);

	const rootQueryData = useFragment<SingleSelectUserPickerFieldRootQueryFragment>(
		graphql`
			fragment ui_issueViewLayoutSingleSelectUserPickerField_IssueViewSingleSelectUserPickerField_rootRelayFragment on Query {
				...ui_issueViewLayoutAssigneeField_IssueViewAssigneeField_rootRelayFragment
			}
		`,
		rootRelayFragment && fg('assign-to-me-user-preloaded-in-issue-view') ? rootRelayFragment : null,
	);

	switch (data.type) {
		case ASSIGNEE_TYPE:
			if (fg('relay-migration-issue-fields-assignee-fg')) {
				return (
					<IssueViewAssigneeField
						area={area}
						fragmentKey={data}
						rootRelayFragment={rootQueryData}
					/>
				);
			}
			return null;

		case REPORTER_TYPE:
			if (fg('relay-migration-issue-fields-reporter')) {
				if (isJCS) {
					return <JCSReporterField fragmentKey={data} />;
				}
				return <IssueViewReporterField area={area} fragmentKey={data} />;
			}
			return null;

		case USER_CF_TYPE:
			if (fg('relay-migration-issue-fields-user-fg')) {
				return <IssueViewUserField area={area} fragmentKey={data} />;
			}
			return null;
		default:
			return null;
	}
};
