import React from 'react';

import type { ExtensionHandlers, ExtensionManifest } from '@atlaskit/editor-common/extensions';
import { SpotlightTarget } from '@atlaskit/onboarding';

import { RedactionMarker } from './ui/redaction-marker';

const EXTENSION_TYPE = 'com.atlassian.guard-premium';

export const getRedactionExtensionHandler: (
	getSpotlightKey?: () => string | null,
) => ExtensionHandlers = (getSpotlightKey) => ({
	[EXTENSION_TYPE]: () => {
		if (getSpotlightKey) {
			const key = getSpotlightKey();
			return (
				<SpotlightTarget name={`redaction-marker-${key}`}>
					<RedactionMarker />
				</SpotlightTarget>
			);
		}
		return <RedactionMarker />;
	},
});

export const getRedactionExtension: () => ExtensionManifest = () => {
	return {
		title: 'Redaction',
		type: EXTENSION_TYPE,
		key: 'redaction',
		description: 'Inserts redacted block.',
		icons: {
			'48': () => import('@atlaskit/icon/glyph/editor/addon'),
		},
		modules: {
			nodes: {
				default: {
					type: 'inlineExtension',
					render: async () => RedactionMarker,
				},
			},
		},
	};
};
