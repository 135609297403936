import { useCallback, useMemo } from 'react';
import { doc, mention, p, text, inlineCard, rule } from '@atlaskit/adf-utils/builders';
import { useIntl } from '@atlassian/jira-intl';
import { useOpenIssueCreateModal } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/main.tsx';
import { JIRA_ISSUE_COMMENT } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/constants.tsx';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { toProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { CreateIssueFromCommentPayload, onClickCreateIssuePayload } from './types.tsx';
import messages from './messages.tsx';

export const useOpenGICFromComment = ({
	author,
	fullIssueUrl,
	bodyAdf,
}: CreateIssueFromCommentPayload) => {
	const openIssueCreateModal = useOpenIssueCreateModal();
	const { data: projectData } = useProjectContext();
	const currentUserData = useCurrentUser();
	const currentUserAccountId = currentUserData?.data?.user?.accountId;
	const { formatMessage } = useIntl();

	const descriptionContent = useMemo(() => {
		if (author?.id && bodyAdf?.content && fullIssueUrl) {
			return doc(
				p(
					mention({ id: author?.id, text: `@${author?.displayName}` }),
					text(formatMessage(messages.commentOn)),
					inlineCard({ url: fullIssueUrl }),
				),
				rule(),
				...bodyAdf.content,
			);
		}
		return '';
	}, [author?.id, author?.displayName, bodyAdf?.content, fullIssueUrl, formatMessage]);

	const payload = useMemo(() => {
		return {
			...(projectData?.projectId && {
				project: {
					projectId: toProjectId(`${projectData?.projectId}`),
				},
			}),
			defaultValues: {
				...(descriptionContent && {
					richTextFields: [
						{
							fieldId: 'description',
							richText: { adfValue: descriptionContent },
						},
					],
				}),

				...(currentUserAccountId && {
					singleSelectUserPickerFields: [
						{
							fieldId: 'assignee',
							user: {
								accountId: currentUserAccountId,
							},
						},
					],
				}),
			},
		};
	}, [currentUserAccountId, descriptionContent, projectData?.projectId]);

	const onClickCreateIssue = useCallback(
		({ onIssueCreate }: onClickCreateIssuePayload) => {
			openIssueCreateModal({
				payload,
				onIssueCreate,
				triggerPointKey: JIRA_ISSUE_COMMENT,
				displayCreateAnotherIssueToggle: false,
				disableProjectDropdown: false,
				disableIssueTypeDropdown: false,
				disableCreationSuccessFlag: true,
			});
		},
		[openIssueCreateModal, payload],
	);

	return { onClickCreateIssue, currentUserAccountId };
};
