/**
 * Hook version of this is jira/src/packages/issue/fields/issue-view-layout/internal/experience-tracking/src/field-experience-tracking/index.tsx
 *
 * This file can be cleaned up once all critical slo fields have been migrated to relay (fieldsToTrack list)
 */

import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/ignoreElements';
import { type ActionsObservable, combineEpics } from 'redux-observable';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import type { Action } from '@atlassian/jira-issue-view-actions/src/index.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import {
	ISSUE_SUMMARY,
	DESCRIPTION,
	ASSIGNEE,
} from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import fieldErrorClassifier, {
	SERVER_ERROR,
} from '@atlassian/jira-issue-view-store/src/common/experience-tracking/field-error-classifier.tsx';
import {
	applicationSelector,
	applicationEditionSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/application-selector.tsx';
import { analyticsSourceSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { isFieldWaitingSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import { FIELD_EDIT_CANCEL } from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-actions.tsx';
import {
	FIELD_SAVE_FAILURE,
	FIELD_UPDATED,
	SWEET_STATE_FIELD_UPDATED,
	SWEET_STATE_FIELD_SAVE_FAILURE,
} from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-save-actions.tsx';

/**
 * We should not track the 'status' field in this epic.
 * Status edits are called transitions and are tracked by the Transition Issue Experience SLA.
 */
const fieldsToTrack = [ISSUE_SUMMARY, ASSIGNEE];
// @ts-expect-error - TS7031 - Binding element 'payload' implicitly has an 'any' type.
const shouldTrackField = ({ payload }) => fieldsToTrack.includes(payload.fieldId);

const fieldsToTrackOld = [ISSUE_SUMMARY, DESCRIPTION, ASSIGNEE];
// @ts-expect-error - TS7031 - Binding element 'payload' implicitly has an 'any' type.
const shouldTrackFieldOld = ({ payload }) => fieldsToTrackOld.includes(payload.fieldId);

const getAnalyticsSource = (state: State) => analyticsSourceSelector(state) || 'unknown';

type GetEditEventPayloadArg = {
	state: State;
	hasSuccess: boolean;
	fieldId: string;
	hasEdit: boolean;
	errorMessage?: string;
	traceId?: string;
	statusCode?: number;
};

const getEditEventPayload = ({
	state,
	hasSuccess,
	fieldId,
	hasEdit,
	errorMessage,
	traceId,
	statusCode,
}: GetEditEventPayloadArg) => {
	// Flow sadly cannot do multiple spreads in one object
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const additionalAttributes: Record<string, any> = {};
	if (errorMessage != null) {
		additionalAttributes.errorMessage = errorMessage;
	}

	if (traceId != null) {
		additionalAttributes.traceId = traceId;
	}

	if (statusCode && fg('thor_populate_missing_attributes_across_issue_view')) {
		additionalAttributes.statusCode = statusCode;
	}

	return {
		experience: 'editIssue',
		analyticsSource: getAnalyticsSource(state),
		application: applicationSelector(state),
		edition: applicationEditionSelector(state),
		wasExperienceSuccesful: hasSuccess,
		field: fieldId,
		hasEdit,
		additionalAttributes,
	};
};
// @ts-expect-error - TS7019 - Rest parameter 'args' implicitly has an 'any[]' type. | TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter.
const sendEditExperienceEvent = (...args) => sendExperienceAnalytics(getEditEventPayload(...args));

const onFieldEditCancel = (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(FIELD_EDIT_CANCEL)
		.filter(
			fg('relay-635_fix_relay_critical_field_analytics') ? shouldTrackField : shouldTrackFieldOld,
		)
		.filter(({ payload }) => {
			// This filters any "field cancel" actions that occured while the field is in a waiting state
			// which would indicate that it is being saved rather than cancelled.
			const state = store.getState();
			const isWaiting = isFieldWaitingSelector(payload.fieldId)(state);
			return !isWaiting;
		})
		.do((action) => {
			const state = store.getState();
			const { fieldId } = action.payload;
			return sendEditExperienceEvent({
				state,
				hasSuccess: true,
				fieldId,
				hasEdit: false,
			});
		})
		.ignoreElements();

const onFieldSaveSuccess = (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(FIELD_UPDATED, SWEET_STATE_FIELD_UPDATED)
		.filter(
			fg('relay-635_fix_relay_critical_field_analytics') ? shouldTrackField : shouldTrackFieldOld,
		)
		.do((action) => {
			const state = store.getState();
			const { fieldId } = action.payload;
			return sendEditExperienceEvent({
				state,
				hasSuccess: true,
				fieldId,
				hasEdit: true,
			});
		})
		.ignoreElements();

const onFieldSaveFailure = (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(FIELD_SAVE_FAILURE, SWEET_STATE_FIELD_SAVE_FAILURE)
		.filter(
			fg('relay-635_fix_relay_critical_field_analytics') ? shouldTrackField : shouldTrackFieldOld,
		)
		.filter(
			({ payload }) =>
				// This filters for any errors that are related to network connectivity
				// We don't care to send any success/failure event should this happen.
				!isClientFetchError(payload.error),
		)
		.do((action) => {
			const state = store.getState();
			const { fieldId, error } = action.payload;
			const errorType = fieldErrorClassifier(error);

			if (
				// Not using errorType here since ValidationError may not always be because of 4xx and 5xx
				error.statusCode >= 400 &&
				error.statusCode < 500
			) {
				fireErrorAnalytics({
					error,
					meta: {
						id: 'issue.issue-view.editIssue',
						teamName: 'bento',
					},
					attributes: {
						experience: 'editIssue',
						analyticsSource: getAnalyticsSource(state),
						application: applicationSelector(state),
						edition: applicationEditionSelector(state),
						wasExperienceSuccesful: errorType !== SERVER_ERROR,
						field: fieldId,
						hasEdit: true,
						traceId: error.traceId,
					},
				});
			}

			return sendEditExperienceEvent({
				state,
				hasSuccess: errorType !== SERVER_ERROR,
				fieldId,
				hasEdit: true,
				errorMessage: `${error.statusCode || errorType} - ${error.message}`,
				traceId: error.traceId,
				...(fg('thor_populate_missing_attributes_across_issue_view')
					? { statusCode: error.statusCode }
					: {}),
			});
		})
		.ignoreElements();

export default combineEpics(onFieldEditCancel, onFieldSaveSuccess, onFieldSaveFailure);
