import React, {
	Component,
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	type default as FetchError,
	ValidationError,
} from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { withFlagService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { getIsEditFromIssueView } from '@atlassian/jira-issue-analytics/src/utils/get-is-edit-from-issue-view/index.tsx';
import {
	FieldValueSubscriber,
	type FieldValueServiceActions,
} from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { TriggerIssueTransitionModalActions } from '@atlassian/jira-issue-transition-trigger/src/common/types.tsx';
import { useOpenIssueTransitionModal } from '@atlassian/jira-issue-transition-trigger/src/utils/use-trigger-issue-transition-modal/index.tsx';
import { useShowFlag } from '@atlassian/jira-issue-transition-use-show-flag/src/ui/use-show-flag/index.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import isValidExperienceError from '@atlassian/jira-issue-view-common-utils/src/utils/is-valid-experience-error.tsx';
import { useWorkflowTransitionsActions } from '@atlassian/jira-issue-workflow-transitions-services/src/main.tsx';
import type { WorkflowTransitionsActions } from '@atlassian/jira-issue-workflow-transitions-services/src/types.tsx';
import { createAri, getAriConfig, isAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import { STATUS_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import {
	fireTrackAnalytics,
	fireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { StatusDetails } from '@atlassian/jira-issue-shared-types/src/common/types/status-type.tsx';
import { useAnalyticsSource } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { STATUS_FIELD_POST_TRANSITION_KEY } from '../../common/constants.tsx';
import type { StatusValue, StatusTransition } from '../../common/types.tsx';
import { ISSUE_TRANSITION_STATUS_FIELD } from '../../constants.tsx';
import { transitionIssueExperienceDescription } from '../experience-descriptions/index.tsx';
import statusTransitionErrorFlag from '../flags/transition-failed-error/main.tsx';
import statusTransitionValidationErrorFlag from '../flags/validation-error/main.tsx';
import type { Props, State, API } from './types.tsx';

export const DIALOG_SUBMIT_BTN = '#issue-workflow-transition-submit';

export const safeUnbindDomEvent = (selector: string, event: string, handler: () => void) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const el = document.querySelector(selector);
	if (el) {
		el.removeEventListener(event, handler);
	}
};

export const safeBindDomEvent = (selector: string, event: string, handler: () => void) => {
	safeUnbindDomEvent(selector, event, handler);

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const el = document.querySelector(selector);
	if (el) {
		el.addEventListener(event, handler);
	}
};

// eslint-disable-next-line jira/react/no-class-components
class StatusServiceOld extends Component<Props, State> {
	static defaultProps = {
		onSubmit: noop,
		onSuccess: noop,
		onFailure: noop,
		onEditStart: noop,
		onEditCancel: noop,
	};

	/**
	 * Lets the consumer control the value of this component. If the consumer passes a `value` prop
	 * it will override the value in state, as long as the component is not in loading state. That's because the component
	 * will only let the consumer know about the new status value once the transition is complete (i.e. loading=false).
	 *
	 * This lifecycle method gets called before every invocation of render().
	 */
	static getDerivedStateFromProps(props: Props, state: State): Partial<State> | null {
		if (state.loading || !props.value) {
			return null;
		}

		return {
			value: props.value,
		};
	}

	constructor(props: Props) {
		super(props);

		const value = props.value || props.initialValue;
		if (value == null) {
			throw new Error('Either value or initialValue prop is required.');
		}

		this.state = {
			value,
			lastSavedValue: value,
			loading: false,
			error: null,
		};
	}

	componentDidMount() {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('legacyJiraDialogContentReady', this.onDialogContentReady);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('legacyJiraDialogBeforeHide', this.handleBeforeDialogHide);
	}

	componentDidUpdate = (prevProps: Props) => {
		if (fg('jira-edit-status-field-refresh-state')) {
			const isNewStatus =
				this.props.initialValue &&
				prevProps.initialValue &&
				prevProps.initialValue.id !== this.props.initialValue.id &&
				this.props.initialValue.id !== this.state.value.id;
			const hasNameChanged =
				this.props.initialValue &&
				prevProps.initialValue &&
				prevProps.initialValue.name !== this.props.initialValue.name;
			const hasCategoryChanged =
				this.props.initialValue &&
				prevProps.initialValue &&
				prevProps.initialValue.statusCategory.id !== this.props.initialValue.statusCategory.id;
			if (
				isNewStatus ||
				(this.props.checkRefreshedData && (hasNameChanged || hasCategoryChanged))
			) {
				this.setState({ loading: false, value: this.props.initialValue });
			}
		} else if (
			// If we receive a new prop that is different to the old one and it is different
			// to our state then trust it and update the state.
			this.props.initialValue &&
			prevProps.initialValue &&
			prevProps.initialValue.id !== this.props.initialValue.id &&
			this.props.initialValue.id !== this.state.value.id
		) {
			this.setState({ loading: false, value: this.props.initialValue });
		}
	};

	componentWillUnmount() {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.removeEventListener('legacyJiraDialogContentReady', this.onDialogContentReady);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.removeEventListener('legacyJiraDialogBeforeHide', this.handleBeforeDialogHide);

		this.unbindDialogFormHandlers();
		this.isDialogOpened = false;
	}

	isDialogOpened = false;

	isDialogConfirmPressed = false;

	onDialogContentReady = () => {
		// This event fired by a dialog before the dialog itself is rendered and visible to the user
		// That's why we need to postpone sending the response to the next moment after it became visible
		setTimeout(() => {
			this.bindDialogFormHandlers();
			this.isDialogOpened = true;
			this.isDialogConfirmPressed = false;
		});
	};

	bindDialogFormHandlers = () => {
		safeBindDomEvent(DIALOG_SUBMIT_BTN, 'click', this.onClickDialogConfirm);
	};

	unbindDialogFormHandlers = () => {
		safeUnbindDomEvent(DIALOG_SUBMIT_BTN, 'click', this.onClickDialogConfirm);
	};

	handleBeforeDialogHide = () => {
		this.unbindDialogFormHandlers();
		this.isDialogOpened = false;
		this.isDialogConfirmPressed = false;
	};

	onClickDialogConfirm = () => {
		// Dialog confirm button press will result in either successfully closing of dialog, or error and dialog re-rendering,
		// so we are starting both interactions
		this.isDialogConfirmPressed = true;
	};

	resetError = () =>
		this.setState({
			error: null,
		});

	submit = (transition: StatusTransition, event: UIAnalyticsEvent) => {
		const oldValue = this.state.value;
		const newValue = transition.to;
		if (fg('one_event_rules_them_all_fg')) {
			const isEditFromIssueView = getIsEditFromIssueView(event);
			const issueAri = this.props.issueId;

			const issueId =
				issueAri && isAri(issueAri) && fg('one_event_iteration_3')
					? getAriConfig(issueAri).resourceId
					: issueAri;

			getUpdateAnalyticsFlowHelper().fireAnalyticsEnd(STATUS_TYPE, {
				analytics: event,
				attributes: {
					fieldType: STATUS_TYPE,
					oldValId: oldValue.statusId ?? oldValue.id,
					newValId: newValue.id,
					oldStatusCategoryId: oldValue.statusCategory.id,
					newStatusCategoryId: newValue.statusCategory.id,
					isInlineEditing: !isEditFromIssueView,
					...(fg('one_event_improvements_1') && { issueId }),
				},
			});
		}
		this.setState({ loading: true, value: newValue });
		this.props.onSubmit(oldValue, newValue, { transition }, event);
	};

	success = ({
		status,
		event,
		viaDialog = false,
	}: {
		status: StatusValue;
		viaDialog?: boolean;
		event: UIAnalyticsEvent;
	}) => {
		this.setState({ loading: false, lastSavedValue: status });
		this.props.fieldValueActions.setFieldValue(this.props.issueKey, STATUS_TYPE, status);
		this.props.onSuccess(status, viaDialog, event);

		fireTrackAnalytics(event, 'statusField transitioned', 'statusField');

		sendExperienceAnalytics(
			transitionIssueExperienceDescription(
				true,
				'POST',
				fg('thor_resolve_transition_event_gate')
					? this.props.source ?? ''
					: 'Issue View Status Service',
				this.props.projectType,
			),
		);
	};

	failure = (error: FetchError, event: UIAnalyticsEvent) => {
		this.setState({
			error,
			loading: false,
			value: this.state.lastSavedValue || this.props.value || this.props.initialValue,
		});

		this.props.onFailure(error);

		const isValidationError = error instanceof ValidationError;
		if (isValidationError) {
			this.props.flagService.showFlag(statusTransitionValidationErrorFlag(error.message));
		} else if (fg('obsrve-2239-traceid-errorflag-issue-transition')) {
			this.props.flagService.showFlag(statusTransitionErrorFlag({ error, traceId: error.traceId }));
		} else {
			this.props.flagService.showFlag(statusTransitionErrorFlag());
		}

		fireOperationalAnalytics(event, 'statusField postTransitionFailed', {
			validationError: isValidationError,
			isClientFetchError: isClientFetchError(error),
		});

		if (isValidExperienceError(error) || fg('thor_resolve_transition_event_gate')) {
			sendExperienceAnalytics(
				transitionIssueExperienceDescription(
					false,
					'POST',
					fg('thor_resolve_transition_event_gate')
						? this.props.source ?? ''
						: 'Issue View Status Service',
					this.props.projectType,
					error.message,
					error.traceId,
					fg('thor_populate_missing_attributes_across_issue_view') ? error.statusCode : undefined,
				),
			);
		} else {
			sendExperienceAnalytics(
				transitionIssueExperienceDescription(
					true,
					'POST',
					fg('thor_resolve_transition_event_gate')
						? this.props.source ?? ''
						: 'Issue View Status Service',
					this.props.projectType,
				),
			);
		}
	};

	cancel = () => {
		// @ts-expect-error - TS2322 - Type 'Status | undefined' is not assignable to type 'Status'.
		this.setState({ loading: false, value: this.props.value || this.props.initialValue });
		this.props.onEditCancel();
		this.props.workflowTransitionsActions.setTransitionInProgress(false);
	};

	transitionStatus = async (transition: StatusTransition, event: UIAnalyticsEvent) => {
		const { appearance, issueKey, renderIssueTransitionModal, renderIssueTransitionSuccessFlag } =
			this.props;
		const newStatus = transition.to;

		this.submit(transition, event);

		try {
			if (transition.hasScreen) {
				const handleDialogSuccess = () => {
					fireTrackAnalytics(event, 'issueTransition success', {
						triggerPointKey: ISSUE_TRANSITION_STATUS_FIELD,
						isModalOpen: true,
					});

					fg('show-modernised-issue-transition-success-flag') &&
						renderIssueTransitionSuccessFlag?.(issueKey, newStatus.name);

					return this.success({ status: newStatus, event, viaDialog: true });
				};

				const handleDialogCancel = () => {
					fireTrackAnalytics(event, 'issueTransition cancel', {
						triggerPointKey: ISSUE_TRANSITION_STATUS_FIELD,
						isModalOpen: true,
					});
					return this.cancel();
				};

				const handleDialogError = () => {
					fireTrackAnalytics(event, 'issueTransition failed', {
						triggerPointKey: ISSUE_TRANSITION_STATUS_FIELD,
						isModalOpen: true,
					});
				};
				if (renderIssueTransitionModal) {
					const { issueId } = this.props;
					renderIssueTransitionModal({
						payload: {
							issueId,
							issueKey,
							transitionId: transition.id,
						},
						triggerPointKey:
							appearance === 'lozenge'
								? 'issue-transition-status-field-trigger'
								: 'issue-transition-issue-view',
						onDialogSuccess: handleDialogSuccess,
						onDialogCancel: handleDialogCancel,
						onDialogError: handleDialogError,
						analyticsEvent: event,
					});
				}
			} else {
				if (this.props.onUpdateStatusMutation && fg('relay-migration-issue-fields-status')) {
					await this.props.onUpdateStatusMutation?.({
						transitionId: transition.id,
						newStatus,
					});
				} else {
					await performPostRequest(`/rest/api/2/issue/${issueKey}/transitions`, {
						body: JSON.stringify({ transition }),
					});
				}
				fireTrackAnalytics(event, 'issueTransition success', {
					triggerPointKey: ISSUE_TRANSITION_STATUS_FIELD,
					isModalOpen: false,
				});

				fg('show-modernised-issue-transition-success-flag') &&
					renderIssueTransitionSuccessFlag?.(issueKey, newStatus.name);

				this.success({ status: newStatus, event });
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			log.safeErrorWithoutCustomerData(STATUS_FIELD_POST_TRANSITION_KEY, error);
			fireTrackAnalytics(event, 'issueTransition failed', {
				triggerPointKey: ISSUE_TRANSITION_STATUS_FIELD,
				isModalOpen: false,
			});
			this.failure(error, event);
		}
	};

	api: API = {
		transitionStatus: this.transitionStatus,
		resetError: this.resetError,
	};

	render() {
		return this.props.children({ ...this.state, ...this.api });
	}
}

const StatusServiceNew: React.FC<Props> = ({
	value: propValue,
	initialValue,
	onSubmit = noop,
	onSuccess = noop,
	onFailure = noop,
	onEditCancel = noop,
	children,
	...props
}) => {
	const [stateValue, setStateValue] = useState<StatusDetails>(() => {
		const value = propValue || initialValue;
		if (!value) {
			throw new Error('Either value or initialValue prop is required.');
		}
		return value;
	});
	const [lastSavedValue, setLastSavedValue] = useState<StatusValue>(stateValue);
	const [loading, setLoading] = useState(false);
	const [stateError, setError] = useState<FetchError | null>(null);

	// These are unused but we are keeping parity with the class component
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [isDialogOpened, setIsDialogOpened] = useState(false);
	// These are unused but we are keeping parity with the class component
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [isDialogConfirmPressed, setIsDialogConfirmPressed] = useState(false);

	/**
	 * Equivalent to getDerivedStateFromProps in class component version.
	 *
	 * Lets the consumer control the value of this component. If the consumer passes a `value` prop
	 * it will override the value in state, as long as the component is not in loading state. That's because the component
	 * will only let the consumer know about the new status value once the transition is complete (i.e. loading=false).
	 *
	 * This lifecycle method gets called before every invocation of render().
	 */
	const value = useMemo(() => {
		if (loading || !propValue) {
			return stateValue;
		}
		return propValue;
	}, [loading, propValue, stateValue]);

	// componentDidUpdate
	const prevInitialValueRef = useRef<StatusDetails | undefined>(initialValue);
	useLayoutEffect(() => {
		const prevInitialValue = prevInitialValueRef.current;
		const newInitialValue = initialValue;

		if (fg('jira-edit-status-field-refresh-state')) {
			const isNewStatus =
				newInitialValue &&
				prevInitialValue &&
				prevInitialValue.id !== newInitialValue.id &&
				newInitialValue.id !== value.id;
			const hasNameChanged =
				newInitialValue && prevInitialValue && prevInitialValue.name !== newInitialValue.name;
			const hasCategoryChanged =
				newInitialValue &&
				prevInitialValue &&
				prevInitialValue.statusCategory.id !== newInitialValue.statusCategory.id;
			if (isNewStatus || (props.checkRefreshedData && (hasNameChanged || hasCategoryChanged))) {
				setLoading(false);
				setStateValue(newInitialValue);
			}
		} else if (
			// If we receive a new prop that is different to the old one and it is different
			// to our state then trust it and update the state.
			newInitialValue &&
			prevInitialValue &&
			prevInitialValue.id !== newInitialValue.id &&
			newInitialValue.id !== value.id
		) {
			setLoading(false);
			setStateValue(newInitialValue);
		}
		prevInitialValueRef.current = newInitialValue;
	}, [initialValue, props.checkRefreshedData, value.id]);

	const onClickDialogConfirm = useCallback(() => {
		setIsDialogConfirmPressed(true);
	}, []);

	const bindDialogFormHandlers = useCallback(() => {
		safeBindDomEvent(DIALOG_SUBMIT_BTN, 'click', onClickDialogConfirm);
	}, [onClickDialogConfirm]);

	const unbindDialogFormHandlers = useCallback(() => {
		safeUnbindDomEvent(DIALOG_SUBMIT_BTN, 'click', onClickDialogConfirm);
	}, [onClickDialogConfirm]);

	useEffect(() => {
		// componentDidMount
		const onDialogContentReady = () => {
			// Using setTimeout as that was the class component version - this may need to be changed for concurrent

			// This event fired by a dialog before the dialog itself is rendered and visible to the user
			// That's why we need to postpone sending the response to the next moment after it became visible
			setTimeout(() => {
				bindDialogFormHandlers();
				setIsDialogOpened(true);
				setIsDialogConfirmPressed(false);
			});
		};

		const handleBeforeDialogHide = () => {
			unbindDialogFormHandlers();
			setIsDialogOpened(false);
			setIsDialogConfirmPressed(false);
		};

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('legacyJiraDialogContentReady', onDialogContentReady);
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('legacyJiraDialogBeforeHide', handleBeforeDialogHide);

		return () => {
			// componentWillUnmount
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.removeEventListener('legacyJiraDialogContentReady', onDialogContentReady);
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.removeEventListener('legacyJiraDialogBeforeHide', handleBeforeDialogHide);
			unbindDialogFormHandlers();
			// this.isDialogOpened = false; // we dont need to change the state since we are unmounting
		};
	}, [bindDialogFormHandlers, unbindDialogFormHandlers]);

	const resetError = useCallback(() => {
		setError(null);
	}, []);

	const submit = useCallback(
		(transition: StatusTransition, event: UIAnalyticsEvent) => {
			const oldValue = value;
			const newValue = transition.to;

			if (fg('one_event_rules_them_all_fg')) {
				const isEditFromIssueView = getIsEditFromIssueView(event);

				const issueAri = props.issueId;
				const issueId =
					issueAri && isAri(issueAri) && fg('one_event_iteration_3')
						? getAriConfig(issueAri).resourceId
						: issueAri;

				getUpdateAnalyticsFlowHelper().fireAnalyticsEnd(STATUS_TYPE, {
					analytics: event,
					attributes: {
						fieldType: STATUS_TYPE,
						// Disabling typechange to keep parity
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
						oldValId: (oldValue as any).statusId ?? oldValue.id,
						newValId: newValue.id,
						oldStatusCategoryId: oldValue.statusCategory.id,
						newStatusCategoryId: newValue.statusCategory.id,
						isInlineEditing: !isEditFromIssueView,
						...(fg('one_event_improvements_1') && { issueId }),
					},
				});
			}
			setLoading(true);
			setStateValue(newValue);
			onSubmit(oldValue, newValue, { transition }, event);
		},
		[value, onSubmit, props.issueId],
	);

	const success = useCallback(
		({
			status,
			event,
			viaDialog = false,
		}: {
			status: StatusValue;
			viaDialog?: boolean;
			event: UIAnalyticsEvent;
		}) => {
			setLoading(false);
			setLastSavedValue(status);
			props.fieldValueActions.setFieldValue(props.issueKey, STATUS_TYPE, status);
			onSuccess(status, viaDialog, event);

			fireTrackAnalytics(event, 'statusField transitioned', 'statusField');

			sendExperienceAnalytics(
				transitionIssueExperienceDescription(
					true,
					'POST',
					fg('thor_resolve_transition_event_gate')
						? props.source ?? ''
						: 'Issue View Status Service',
					props.projectType,
				),
			);
		},
		[onSuccess, props.fieldValueActions, props.issueKey, props.projectType, props.source],
	);

	const failure = useCallback(
		(error: FetchError, event: UIAnalyticsEvent) => {
			setError(error);
			setLoading(false);
			setStateValue(lastSavedValue || propValue || initialValue);
			onFailure(error);

			const isValidationError = error instanceof ValidationError;
			if (isValidationError) {
				props.flagService.showFlag(statusTransitionValidationErrorFlag(error.message));
			} else if (fg('obsrve-2239-traceid-errorflag-issue-transition')) {
				props.flagService.showFlag(statusTransitionErrorFlag({ error, traceId: error.traceId }));
			} else {
				props.flagService.showFlag(statusTransitionErrorFlag());
			}

			fireOperationalAnalytics(event, 'statusField postTransitionFailed', {
				validationError: isValidationError,
				isClientFetchError: isClientFetchError(error),
			});

			if (isValidExperienceError(error) || fg('thor_resolve_transition_event_gate')) {
				sendExperienceAnalytics(
					transitionIssueExperienceDescription(
						false,
						'POST',
						fg('thor_resolve_transition_event_gate')
							? props.source ?? ''
							: 'Issue View Status Service',
						props.projectType,
						error.message,
						error.traceId,
						fg('thor_populate_missing_attributes_across_issue_view') ? error.statusCode : undefined,
					),
				);
			} else {
				sendExperienceAnalytics(
					transitionIssueExperienceDescription(
						true,
						'POST',
						fg('thor_resolve_transition_event_gate')
							? props.source ?? ''
							: 'Issue View Status Service',
						props.projectType,
					),
				);
			}
		},
		[
			initialValue,
			lastSavedValue,
			onFailure,
			propValue,
			props.flagService,
			props.projectType,
			props.source,
		],
	);

	const cancel = useCallback(() => {
		setLoading(false);
		// @ts-expect-error - TS2322 - Type 'Status | undefined' is not assignable to type 'Status'.
		setStateValue(propValue || initialValue);
		onEditCancel();
		props.workflowTransitionsActions.setTransitionInProgress(false);
	}, [initialValue, onEditCancel, propValue, props.workflowTransitionsActions]);

	const transitionStatus = useCallback(
		async (transition: StatusTransition, event: UIAnalyticsEvent) => {
			const { appearance, issueKey, renderIssueTransitionModal, renderIssueTransitionSuccessFlag } =
				props;
			const newStatus = transition.to;

			submit(transition, event);

			try {
				if (transition.hasScreen) {
					const handleDialogSuccess = () => {
						fireTrackAnalytics(event, 'issueTransition success', {
							triggerPointKey: ISSUE_TRANSITION_STATUS_FIELD,
							isModalOpen: true,
						});

						fg('show-modernised-issue-transition-success-flag') &&
							renderIssueTransitionSuccessFlag?.(issueKey, newStatus.name);

						return success({ status: newStatus, event, viaDialog: true });
					};

					const handleDialogCancel = () => {
						fireTrackAnalytics(event, 'issueTransition cancel', {
							triggerPointKey: ISSUE_TRANSITION_STATUS_FIELD,
							isModalOpen: true,
						});
						return cancel();
					};

					const handleDialogError = () => {
						fireTrackAnalytics(event, 'issueTransition failed', {
							triggerPointKey: ISSUE_TRANSITION_STATUS_FIELD,
							isModalOpen: true,
						});
					};
					if (renderIssueTransitionModal) {
						const { issueId } = props;
						renderIssueTransitionModal({
							payload: {
								issueId,
								issueKey,
								transitionId: transition.id,
							},
							triggerPointKey:
								appearance === 'lozenge'
									? 'issue-transition-status-field-trigger'
									: 'issue-transition-issue-view',
							onDialogSuccess: handleDialogSuccess,
							onDialogCancel: handleDialogCancel,
							onDialogError: handleDialogError,
							analyticsEvent: event,
						});
					}
				} else {
					if (props.onUpdateStatusMutation && fg('relay-migration-issue-fields-status')) {
						await props.onUpdateStatusMutation?.({
							transitionId: transition.id,
							newStatus,
						});
					} else {
						await performPostRequest(`/rest/api/2/issue/${issueKey}/transitions`, {
							body: JSON.stringify({ transition }),
						});
					}
					fireTrackAnalytics(event, 'issueTransition success', {
						triggerPointKey: ISSUE_TRANSITION_STATUS_FIELD,
						isModalOpen: false,
					});

					fg('show-modernised-issue-transition-success-flag') &&
						renderIssueTransitionSuccessFlag?.(issueKey, newStatus.name);

					success({ status: newStatus, event });
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				log.safeErrorWithoutCustomerData(STATUS_FIELD_POST_TRANSITION_KEY, error);
				fireTrackAnalytics(event, 'issueTransition failed', {
					triggerPointKey: ISSUE_TRANSITION_STATUS_FIELD,
					isModalOpen: false,
				});
				failure(error, event);
			}
		},
		[cancel, failure, props, submit, success],
	);

	const state: State = useMemo(
		() => ({
			value,
			lastSavedValue,
			loading,
			error: stateError,
		}),
		[value, lastSavedValue, loading, stateError],
	);

	const api: API = useMemo(
		() => ({
			transitionStatus,
			resetError,
		}),
		[resetError, transitionStatus],
	);

	return children({ ...state, ...api });
};

const StatusService = componentWithFG(
	'jira-concurrent-status-field-fix',
	StatusServiceNew,
	StatusServiceOld,
);

const StatusServiceWithoutAriIdOld = (props: Props) => {
	const { issueId } = props;
	const cloudId = useCloudId();
	const ariIssueId = issueId
		? createAri({
				resourceOwner: 'jira',
				cloudId,
				resourceType: 'issue',
				resourceId: issueId,
			})
		: undefined;

	const renderIssueTransitionModal = useOpenIssueTransitionModal();

	return (
		<StatusService
			{...props}
			renderIssueTransitionModal={renderIssueTransitionModal}
			issueId={ariIssueId}
		/>
	);
};

const StatusServiceWithoutAriIdNew = (props: Props) => {
	const { issueId } = props;
	const cloudId = useCloudId();
	const ariIssueId = issueId
		? createAri({
				resourceOwner: 'jira',
				cloudId,
				resourceType: 'issue',
				resourceId: issueId,
			})
		: undefined;

	const renderIssueTransitionModal = useOpenIssueTransitionModal();
	const { showIssueTransitionSuccessFlag } = useShowFlag();

	return (
		<StatusService
			{...props}
			renderIssueTransitionModal={renderIssueTransitionModal}
			renderIssueTransitionSuccessFlag={showIssueTransitionSuccessFlag}
			issueId={ariIssueId}
		/>
	);
};

const StatusServiceWithoutAriId = componentWithFG(
	'show-modernised-issue-transition-success-flag',
	StatusServiceWithoutAriIdNew,
	StatusServiceWithoutAriIdOld,
);

const StatusServiceForMigrateIssueTransitionEnabledBase = (props: Props) => (
	<StatusServiceWithoutAriId {...props} />
);

const StatusServiceForMigrateIssueTransitionEnabledWithAnalyticsSource = (props: Props) => {
	const source = useAnalyticsSource();
	return <StatusServiceForMigrateIssueTransitionEnabledBase {...props} source={source} />;
};

const StatusServiceForMigrateIssueTransitionEnabled = componentWithFG(
	'thor_resolve_transition_event_gate',
	StatusServiceForMigrateIssueTransitionEnabledWithAnalyticsSource,
	StatusServiceForMigrateIssueTransitionEnabledBase,
);

const StatusWithValueService = (
	props: Flow.Diff<
		Props,
		{
			fieldValueActions: FieldValueServiceActions;
			workflowTransitionsActions: WorkflowTransitionsActions;
			renderIssueTransitionSuccessFlag?: (issueKey: string, status: string) => void;
			renderIssueTransitionModal?: TriggerIssueTransitionModalActions['openIssueTransitionModal'];
		}
	>,
) => {
	const [, workflowTransitionsActions] = useWorkflowTransitionsActions();
	return (
		<FieldValueSubscriber issueKey={props.issueKey} fieldKey={STATUS_TYPE}>
			{(value, fieldValueActions) => {
				const statusServiceProps = {
					// Overrides the value in the store if the consumer really wants to do it
					value,
					...props,
					fieldValueActions,
					workflowTransitionsActions,
				};

				return <StatusServiceForMigrateIssueTransitionEnabled {...statusServiceProps} />;
			}}
		</FieldValueSubscriber>
	);
};
const wrappedStatusService = withFlagService(StatusWithValueService);

export { wrappedStatusService as StatusService };
export { StatusService as BaseStatusService };
