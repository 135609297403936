import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const projectGlyph = (props: CustomGlyphProps) => (
	<svg
		{...props}
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
	>
		<g clipPath="url(#clip0_2381_27797)">
			<path
				d="M14.3492 1.9234C14.3492 1.77165 14.226 1.64863 14.0741 1.64863H10.9613C10.8884 1.64863 10.8184 1.67758 10.7668 1.72911L7.54583 4.94589L2.99068 4.9459C2.91771 4.94589 2.84773 4.97484 2.79613 5.02637L2.33456 5.48735L3.69638 6.8474C4.2336 7.38393 4.2336 8.25381 3.69638 8.79033L1.16728 11.3161L0 10.1504L2.33456 7.81887L0.972732 6.45882C0.435507 5.92229 0.435507 5.05241 0.972732 4.51588L1.62886 3.86062C1.99003 3.49991 2.4799 3.29726 2.99068 3.29726L6.86205 3.29726L9.59952 0.563352C9.9607 0.202644 10.4506 0 10.9613 0H14.0741C15.1377 -5.24086e-07 16 0.861136 16 1.9234L16 5.03209C16 5.54221 15.7971 6.03144 15.4359 6.39214L12.6822 9.14223V13.0086C12.6822 13.5187 12.4793 14.0079 12.1182 14.3686L11.4782 15.0077C10.941 15.5442 10.07 15.5442 9.53277 15.0077L8.17094 13.6477L5.81554 16L4.64826 14.8342L7.19821 12.2876C7.73544 11.7511 8.60645 11.7511 9.14367 12.2876L10.5055 13.6477L10.9509 13.2029C11.0025 13.1513 11.0315 13.0814 11.0315 13.0086V8.45935L14.2686 5.22639C14.3202 5.17486 14.3492 5.10497 14.3492 5.03209V1.9234Z"
				fill="currentColor"
			/>
			<path
				d="M12.84 4.71017C12.4103 5.13939 11.7134 5.13939 11.2837 4.71017C10.8539 4.28095 10.8539 3.58505 11.2837 3.15583C11.7134 2.72661 12.4103 2.72661 12.84 3.15583C13.2698 3.58505 13.2698 4.28095 12.84 4.71017Z"
				fill="currentColor"
			/>
			<path
				d="M5.83642 8.98468L0.267066 14.5468L1.43434 15.7125L7.0037 10.1504L5.83642 8.98468Z"
				fill="currentColor"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2381_27797">
				<rect width="16" height="16" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
