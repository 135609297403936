/** @jsx jsx */
import React from 'react';
import { css, jsx, type CSSProps } from '@compiled/react';
// eslint-disable-next-line jira/import-whitelist
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
// eslint-disable-next-line jira/import-whitelist
import { isChrome } from '@atlassian/jira-common-util-browser/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';

import messages from './messages.tsx';

const skeletonImageStyles = css({
	display: 'block',
	boxSizing: 'border-box',
	width: '100%',
});

type Props = {
	name: string;
	src: string;
	className?: string;
	/**
	 * Inline styles to apply to the image container, for example, to set a fixed container height for component
	 * skeletons.
	 */
	containerStyles?: Pick<CSSProps<unknown>, 'maxHeight'>;
};

const baseContainerStyles = css({
	overflow: 'hidden',
	width: '100%',
	height: '100%',
});

export const ComponentSkeletonImage = ({ name, src, className, containerStyles = {} }: Props) => {
	const { formatMessage } = useIntl();

	// Passing containerStyles to `css` causes the "Variable could not be found" error to be thrown...
	// Using the spread operator against containerStyles causes the same error...
	// Passing containerStyles to the `css()` function also causes the same error...
	// Passing induvidual keys to the css function works fine...
	const { maxHeight } = containerStyles;

	return (
		// eslint-disable-next-line jira/ufo/valid-labels -- The name is based on the component, so dynamic
		<UFOLoadHold name={name}>
			<div
				// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- We need dynamic styles here?
				css={[baseContainerStyles, css({ maxHeight })]}
				data-testid={`component-skeleton-image.${name}`}
			>
				{process.env.NODE_ENV !== 'production' && isChrome() ? (
					/* during dev use <object> instead of <img> to easily debug */
					<object
						data={src}
						type="image/svg+xml"
						css={skeletonImageStyles}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={className}
						aria-label={`${formatMessage(messages.loading)}...`}
					>
						{formatMessage(messages.loading)}...
					</object>
				) : (
					<img
						src={src}
						alt={`${formatMessage(messages.loading)}...`}
						css={skeletonImageStyles}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={className}
					/>
				)}
			</div>
		</UFOLoadHold>
	);
};
