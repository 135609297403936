import { createSelector } from 'reselect';
import {
	SERVICE_DESK_PROJECT,
	projectTypeToCanonicalId,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import type { FieldsState } from '@atlassian/jira-issue-shared-types/src/common/types/field-type.tsx';
import type { User } from '@atlassian/jira-issue-shared-types/src/common/types/user-type.tsx';
import type {
	EntitiesState,
	State,
	UiState,
	AgileState,
	GraphQlIssueState,
} from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import {
	COMPLETE,
	LOADING,
	PREVIEW,
} from '@atlassian/jira-issue-view-common-types/src/loading-stage-type.tsx';
import { EPIC_ISSUES } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import {
	type AccountId,
	type CloudId,
	type IssueId,
	toIssueId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import { cloudIdSelector, accountIdloggedInUserSelector } from './context-selector.tsx';
import { fieldsSelector } from './field-selector.tsx';

export const entitiesSelector = (state: State): EntitiesState => state.entities;

export const projectSelector = createSelector(entitiesSelector, (entities) =>
	entities ? entities.project : null,
);
export const uiSelector: (arg1: State) => UiState = (state) => state.ui;
export const agileSelector: (arg1: State) => AgileState = (state) => state.agile;

export const entitiesIssueSelector = createSelector(
	entitiesSelector,
	(entities: EntitiesState): GraphQlIssueState | null => entities.issue,
);

export const entitiesRefreshedOnSelector = createSelector(
	entitiesSelector,
	(entities) => entities.refreshedOn,
);

export const flagsSelector = createSelector(uiSelector, (ui) => ui.flags);

// We have to explicitly define the function type here as it is called with props by a composed selector in
// comments-selector.js. Without explicit types flow will throw an error as we expect props to be void (undefined),
export const projectIdSelector = createSelector(projectSelector, (project) =>
	project && project.projectId ? project.projectId : null,
);

export const projectIdsSelector = createSelector(projectSelector, (project) =>
	project && project.projectId ? [project.projectId] : [],
);

export const projectKeySelector = createSelector(projectSelector, (project) =>
	project ? project.projectKey : null,
);

export const projectTypeSelector = createSelector(projectSelector, (project) =>
	project && project.projectType ? project.projectType : null,
);

export const isSimplifiedProjectSelector = createSelector(
	projectSelector,
	(project) => !!project && project.isSimplified,
);

export const loggedInUserDetailsSelector = createSelector(
	entitiesSelector,
	accountIdloggedInUserSelector,
	(entities, loggedInAccountId) => (loggedInAccountId ? entities.users[loggedInAccountId] : null),
);

export const loggedInUserAvatarUrlSelector = createSelector(
	entitiesSelector,
	accountIdloggedInUserSelector,
	(entities, loggedInAccountId) => {
		const loggedInUser = loggedInAccountId ? entities.users[loggedInAccountId] : null;
		return loggedInUser ? loggedInUser.avatarUrl : null;
	},
);

export const loggedInUserDisplayNameSelector = createSelector(
	entitiesSelector,
	accountIdloggedInUserSelector,
	(entities, loggedInAccountId) => {
		const loggedInUser = loggedInAccountId ? entities.users[loggedInAccountId] : null;
		return loggedInUser ? loggedInUser.displayName : null;
	},
);

export const parentIssueEntitySelector = createSelector(
	entitiesSelector,
	(entities) => entities.parentIssue,
);

export const loggedInUserTimeZoneSelector = createSelector(
	loggedInUserDetailsSelector,
	(loggedInUser) => (loggedInUser ? loggedInUser.timeZone : undefined),
);

export const idSelector = createSelector(
	entitiesIssueSelector,
	(issue: GraphQlIssueState | null): IssueId | null => issue && toIssueId(issue.id),
);

export const isClassicProjectSelector = createSelector(
	isSimplifiedProjectSelector,
	(isSimplified) => !isSimplified,
);

export const isLoadingSelector = createSelector(uiSelector, (ui) => ui.loadingStage === LOADING);
export const doNotUseIsLoadingPromiseSelector = createSelector(
	uiSelector,
	(ui: UiState): (() => Promise<unknown> | null) => ui.doNotUseIsLoadingPromise,
);

export const isPreviewSelector = createSelector(uiSelector, (ui) => ui.loadingStage === PREVIEW);

export const isCompletedLoadingSelector = createSelector(
	uiSelector,
	(ui: UiState): boolean => ui.loadingStage === COMPLETE,
);

export const loadingStageSelector = createSelector(uiSelector, (ui) => ui.loadingStage);

export const isDeleteIssueModalOpenSelector = createSelector(
	uiSelector,
	(ui) => !!(ui.issueDeletion && ui.issueDeletion.isDeleteIssueModalOpen),
);

export const isDeletingIssueSelector = createSelector(uiSelector, (ui) =>
	ui.issueDeletion ? ui.issueDeletion.isDeletingIssue : false,
);

export const didIssueDeleteFailSelector = createSelector(uiSelector, (ui) =>
	ui.issueDeletion ? ui.issueDeletion.didIssueDeleteFail : false,
);

export const errorSelector = createSelector(uiSelector, (ui) => ui.error);

export const errorMessageSelector = createSelector(uiSelector, (ui) => ui.errorMessage);

export type UserByIdSelector = (userId: AccountId | null | undefined) => User | undefined;

export const getUserByIdSelector = createSelector(
	entitiesSelector,
	(entities) => (userId?: AccountId | null) => (userId ? entities.users[userId] : undefined),
);

export const parentLevelIssuesSelector = createSelector(
	entitiesSelector,
	(entities) => entities.parentLevelIssues,
);

export const resourceOwnerSelector = () => 'jira';

export const canonicalIdSelector = createSelector(projectTypeSelector, projectTypeToCanonicalId);

export const baseAriSelector = createSelector(
	resourceOwnerSelector,
	cloudIdSelector,
	(resourceOwner: string, cloudId: CloudId) =>
		cloudId ? `ari:cloud:${resourceOwner}:${cloudId}` : null,
);

export const issueAriSelector = createSelector(baseAriSelector, idSelector, (baseAri, issueId) => {
	if (!baseAri || !issueId) {
		return null;
	}

	return `${baseAri}:issue/${issueId}`;
});

export const epicLinkFieldKeySelector = createSelector(
	agileSelector,
	(agile) => (agile && agile.epic && agile.epic.key) || null,
);

export const isEpic = (fields: FieldsState): boolean =>
	({}).hasOwnProperty.call(fields, EPIC_ISSUES);

export const isEpicSelector = createSelector(fieldsSelector, (fields) => isEpic(fields));

// GALILEO-404 START
// This checks the hierarchy level property of the fields object for the name of the issue level
// instead of checking for the EPIC_ISSUES property which is only returned on classic issues.
// Epics only have epics issue types at the same hierarchy, so we can check this. There is another
// issuetype field we can check, but it is not language agnostic, it will get translated depending on locale.
export const isEpicIssueType = (fields: FieldsState): boolean => {
	if ({}.hasOwnProperty.call(fields, 'hierarchy-level-same-level')) {
		return fields['hierarchy-level-same-level'].value.name === 'Epic';
	}

	return false;
};

export const isEpicIssueTypeSelector = createSelector(fieldsSelector, (fields) =>
	isEpicIssueType(fields),
);

export const getIssueStatusCategoryId = (fields: FieldsState): number | undefined => {
	if ({}.hasOwnProperty.call(fields, 'status')) {
		return fields.status.value?.statusCategory?.id;
	}
};

export const getIssueStatusCategoryIdSelector = createSelector(fieldsSelector, (fields) =>
	getIssueStatusCategoryId(fields),
);

export const getIssueSummarySelector = createSelector(fieldsSelector, (fields) =>
	getIssueSummary(fields),
);

export const getIssueSummary = (fields: FieldsState): string | undefined => {
	if ({}.hasOwnProperty.call(fields, 'summary')) {
		return fields.summary.value;
	}
};
// GALILEO-404 END

export const isServiceDeskSelector = createSelector(
	projectTypeSelector,
	(projectType) => projectType === SERVICE_DESK_PROJECT,
);
