import React from 'react';

import type { GlyphProps, NewCoreIconProps } from '@atlaskit/icon';
import AutomationIcon from '@atlaskit/icon/core/automation';
import AddIcon from '@atlaskit/icon/core/migration//add';
import EditorDoneIcon from '@atlaskit/icon/core/migration/check-mark--editor-done';
import CommentIcon from '@atlaskit/icon/core/migration/comment';
import EmailIcon from '@atlaskit/icon/core/migration/email';
import NotificationIcon from '@atlaskit/icon/core/migration/notification';
import {
	AnsibleIcon,
	AwsIcon,
	AzureIcon,
	MsTeamsIcon,
	RelicIcon as NewRelicIcon,
	SlackIcon,
} from '@atlaskit/legacy-custom-icons';
import { token } from '@atlaskit/tokens';

import * as messages from './messages.tsx';
import {
	type ALERT_MAP_KEYS,
	type ISSUE_MAP_KEYS,
	type MappedType,
	RuleParts,
	type MappedTypeResponse,
} from './types.tsx';

export const ISSUE_MAP: MappedType<keyof typeof ISSUE_MAP_KEYS, MappedTypeResponse> = {
	itsm_template_18: {
		info: {
			footer: messages.itsmTemplate18.footerText,
			footerNonAdmin: messages.itsmTemplate18.footerTextNonAdmin,
			learnMoreLink:
				'https://support.atlassian.com/cloud-automation/docs/use-atlassian-automation-with-ansible/',
			learnMoreText: messages.itsmTemplate18.learnMoreText,
			savedMinutes: 1500,
		},
		components: [
			{
				type: RuleParts.trigger,
				text: messages.itsmTemplate18.trigger,
				icon: (props: NewCoreIconProps) => (
					<AddIcon {...props} color={token('color.icon.accent.blue')} />
				),
			},
			{
				type: RuleParts.action,
				text: messages.itsmTemplate18.action,
				icon: (props: GlyphProps) => (
					<AnsibleIcon {...props} primaryColor={token('color.icon.accent.gray')} />
				),
			},
			{
				type: RuleParts.action,
				text: messages.itsmTemplate18.actionNext,
				icon: (props: NewCoreIconProps) => (
					<CommentIcon {...props} color={token('color.icon.accent.green')} />
				),
			},
		],
	},
	itsm_template_20: {
		info: {
			footer: messages.itsmTemplate20.footerText,
			footerNonAdmin: messages.itsmTemplate20.footerTextNonAdmin,
			learnMoreLink:
				'https://support.atlassian.com/cloud-automation/docs/jira-automation-actions/#Create-Slack-channel-for-incident',
			learnMoreText: messages.itsmTemplate20.learnMoreText,
			savedMinutes: 3000,
		},
		components: [
			{
				type: RuleParts.trigger,
				text: messages.itsmTemplate20.trigger,
				icon: (props: NewCoreIconProps) => (
					<AddIcon {...props} color={token('color.icon.accent.green')} />
				),
			},
			{
				type: RuleParts.action,
				text: messages.itsmTemplate20.action,
				icon: (props: GlyphProps) => (
					<SlackIcon {...props} primaryColor={token('color.icon.accent.blue')} />
				),
			},
		],
	},
	itsm_template_21: {
		info: {
			footer: messages.itsmTemplate21.footerText,
			footerNonAdmin: messages.itsmTemplate21.footerTextNonAdmin,
			learnMoreLink:
				'https://support.atlassian.com/cloud-automation/docs/jira-automation-actions/#Create-Microsoft-Teams-chat-or-meeting-for-incident',
			learnMoreText: messages.itsmTemplate21.learnMoreText,
			savedMinutes: 3000,
		},
		components: [
			{
				type: RuleParts.trigger,
				text: messages.itsmTemplate21.trigger,
				icon: (props: NewCoreIconProps) => (
					<AddIcon {...props} color={token('color.icon.accent.green')} />
				),
			},
			{
				type: RuleParts.action,
				text: messages.itsmTemplate21.action,
				icon: (props: GlyphProps) => (
					<MsTeamsIcon {...props} primaryColor={token('color.icon.accent.blue')} />
				),
			},
		],
	},
	itsm_template_24: {
		info: {
			footer: messages.itsmTemplate24.footerText,
			footerNonAdmin: messages.itsmTemplate24.footerTextNonAdmin,
			learnMoreLink:
				'https://support.atlassian.com/cloud-automation/docs/use-jira-automation-with-aws/',
			learnMoreText: messages.itsmTemplate24.learnMoreText,
			savedMinutes: 3000,
		},
		components: [
			{
				type: RuleParts.trigger,
				text: messages.itsmTemplate24.trigger,
				icon: (props: NewCoreIconProps) => (
					<AddIcon {...props} color={token('color.icon.accent.blue')} />
				),
			},
			{
				type: RuleParts.action,
				text: messages.itsmTemplate24.action,
				icon: (props: GlyphProps) => (
					<NewRelicIcon {...props} primaryColor={token('color.icon.accent.gray')} />
				),
			},
			{
				type: RuleParts.action,
				text: messages.itsmTemplate24.actionNext,
				icon: (props: GlyphProps) => (
					<AwsIcon {...props} primaryColor={token('color.icon.accent.gray')} />
				),
			},
			{
				type: RuleParts.action,
				text: messages.itsmTemplate24.actionNext2,
				icon: (props: NewCoreIconProps) => (
					<EditorDoneIcon {...props} color={token('color.icon.accent.blue')} />
				),
			},
		],
	},
	itsm_template_25: {
		info: {
			footer: messages.itsmTemplate25.footerText,
			footerNonAdmin: messages.itsmTemplate25.footerTextNonAdmin,
			learnMoreLink:
				'https://support.atlassian.com/cloud-automation/docs/use-jira-automation-with-jira-edge-connector-jec/',
			learnMoreText: messages.itsmTemplate25.learnMoreText,
			savedMinutes: 5800,
		},
		components: [
			{
				type: RuleParts.trigger,
				text: messages.itsmTemplate25.trigger,
				icon: (props: NewCoreIconProps) => (
					<AddIcon {...props} color={token('color.icon.accent.blue')} />
				),
			},
			{
				type: RuleParts.action,
				text: messages.itsmTemplate25.action,
				icon: (props: GlyphProps) => (
					<NewRelicIcon {...props} primaryColor={token('color.icon.accent.gray')} />
				),
			},
			{
				type: RuleParts.action,
				text: messages.itsmTemplate25.actionNext,
				icon: (props: GlyphProps) => (
					<AutomationIcon {...props} color={token('color.icon.accent.blue')} />
				),
			},
		],
	},
	itsm_template_26: {
		info: {
			footer: messages.itsmTemplate26.footerText,
			footerNonAdmin: messages.itsmTemplate26.footerTextNonAdmin,
			learnMoreLink:
				'https://support.atlassian.com/cloud-automation/docs/use-jira-automation-with-microsoft-azure/',
			learnMoreText: messages.itsmTemplate26.learnMoreText,
			savedMinutes: 3600,
		},
		components: [
			{
				type: RuleParts.trigger,
				text: messages.itsmTemplate26.trigger,
				icon: (props: NewCoreIconProps) => (
					<AddIcon {...props} color={token('color.icon.accent.blue')} />
				),
			},
			{
				type: RuleParts.action,
				text: messages.itsmTemplate26.action,
				icon: (props: GlyphProps) => (
					<NewRelicIcon {...props} primaryColor={token('color.icon.accent.gray')} />
				),
			},
			{
				type: RuleParts.action,
				text: messages.itsmTemplate26.actionNext,
				icon: (props: GlyphProps) => (
					<AzureIcon {...props} primaryColor={token('color.icon.accent.blue')} />
				),
			},
			{
				type: RuleParts.action,
				text: messages.itsmTemplate26.actionNext2,
				icon: (props: NewCoreIconProps) => (
					<AzureIcon {...props} primaryColor={token('color.icon.accent.blue')} />
				),
			},
		],
	},
	default: {
		id: 'itsm_template_15',
		info: {
			footer: messages.itsmTemplate15.footerText,
			footerNonAdmin: messages.itsmTemplate15.footerTextNonAdmin,
			learnMoreLink:
				'https://support.atlassian.com/cloud-automation/docs/use-automation-with-slack/',
			learnMoreText: messages.itsmTemplate15.learnMoreText,
			savedMinutes: 3000,
		},
		components: [
			{
				type: RuleParts.trigger,
				text: messages.itsmTemplate15.trigger,
				icon: (props: NewCoreIconProps) => (
					<AddIcon {...props} color={token('color.icon.accent.green')} />
				),
			},
			{
				type: RuleParts.action,
				text: messages.itsmTemplate15.action,
				icon: (props: NewCoreIconProps) => (
					<EmailIcon {...props} color={token('color.icon.accent.blue')} />
				),
			},
			{
				type: RuleParts.action,
				text: messages.itsmTemplate15.actionNext,
				icon: (props: GlyphProps) => (
					<SlackIcon {...props} primaryColor={token('color.icon.accent.blue')} />
				),
			},
		],
	},
};

export const ALERT_MAP: MappedType<keyof typeof ALERT_MAP_KEYS, MappedTypeResponse> = {
	itsm_template_19: {
		info: {
			footer: messages.itsmTemplate19.footerTextAws,
			footerNonAdmin: messages.itsmTemplate19.footerTextAwsNonAdmin,
			learnMoreLink:
				'https://support.atlassian.com/cloud-automation/docs/use-jira-automation-with-aws/',
			learnMoreText: messages.itsmTemplate19.learnMoreTextAws,
			savedMinutes: 3000,
		},
		components: [
			{
				type: RuleParts.trigger,
				text: messages.itsmTemplate19.trigger,
				icon: (props: NewCoreIconProps) => (
					<NotificationIcon {...props} color={token('color.icon.accent.green')} />
				),
			},
			{
				type: RuleParts.action,
				text: messages.itsmTemplate19.action,
				icon: (props: GlyphProps) => (
					<NewRelicIcon {...props} primaryColor={token('color.icon.accent.gray')} />
				),
			},
			{
				type: RuleParts.action,
				text: messages.itsmTemplate19.actionNext,
				icon: (props: GlyphProps) => (
					<AwsIcon {...props} primaryColor={token('color.icon.accent.gray')} />
				),
			},
			{
				type: RuleParts.action,
				text: messages.itsmTemplate19.actionNext2,
				icon: (props: NewCoreIconProps) => (
					<EditorDoneIcon {...props} color={token('color.icon.accent.blue')} />
				),
			},
		],
	},
	itsm_template_19_newrelic: {
		id: 'itsm_template_19',
		info: {
			footer: messages.itsmTemplate19.footerTextNr,
			footerNonAdmin: messages.itsmTemplate19.footerTextNrNonAdmin,
			learnMoreLink:
				'https://support.atlassian.com/cloud-automation/docs/use-jira-automation-with-new-relic/',
			learnMoreText: messages.itsmTemplate19.learnMoreTextNr,
			savedMinutes: 4200,
		},
		components: [
			{
				type: RuleParts.trigger,
				text: messages.itsmTemplate19.trigger,
				icon: (props: NewCoreIconProps) => (
					<NotificationIcon {...props} color={token('color.icon.accent.green')} />
				),
			},
			{
				type: RuleParts.action,
				text: messages.itsmTemplate19.action,
				icon: (props: GlyphProps) => (
					<NewRelicIcon {...props} primaryColor={token('color.icon.accent.gray')} />
				),
			},
			{
				type: RuleParts.action,
				text: messages.itsmTemplate19.actionNext,
				icon: (props: GlyphProps) => (
					<AwsIcon {...props} primaryColor={token('color.icon.accent.gray')} />
				),
			},
			{
				type: RuleParts.action,
				text: messages.itsmTemplate19.actionNext2,
				icon: (props: NewCoreIconProps) => (
					<EditorDoneIcon {...props} color={token('color.icon.accent.blue')} />
				),
			},
		],
	},
	itsm_template_23: {
		info: {
			footer: messages.itsmTemplate23.footerText,
			footerNonAdmin: messages.itsmTemplate23.footerTextNonAdmin,
			learnMoreLink:
				'https://support.atlassian.com/cloud-automation/docs/use-jira-automation-with-microsoft-azure/',
			learnMoreText: messages.itsmTemplate23.learnMoreText,
			savedMinutes: 3600,
		},
		components: [
			{
				type: RuleParts.trigger,
				text: messages.itsmTemplate23.trigger,
				icon: (props: NewCoreIconProps) => (
					<NotificationIcon {...props} color={token('color.icon.accent.green')} />
				),
			},
			{
				type: RuleParts.action,
				text: messages.itsmTemplate23.action,
				icon: (props: GlyphProps) => (
					<NewRelicIcon {...props} primaryColor={token('color.icon.accent.gray')} />
				),
			},
			{
				type: RuleParts.action,
				text: messages.itsmTemplate23.actionNext,
				icon: (props: GlyphProps) => (
					<AzureIcon {...props} primaryColor={token('color.icon.accent.blue')} />
				),
			},
			{
				type: RuleParts.action,
				text: messages.itsmTemplate23.actionNext2,
				icon: (props: NewCoreIconProps) => (
					<AzureIcon {...props} primaryColor={token('color.icon.accent.blue')} />
				),
			},
		],
	},
	itsm_template_27: {
		info: {
			footer: messages.itsmTemplate27.footerText,
			footerNonAdmin: messages.itsmTemplate27.footerTextNonAdmin,
			learnMoreLink:
				'https://support.atlassian.com/cloud-automation/docs/use-atlassian-automation-with-ansible/',
			learnMoreText: messages.itsmTemplate27.learnMoreText,
			savedMinutes: 1500,
		},
		components: [
			{
				type: RuleParts.trigger,
				text: messages.itsmTemplate27.trigger,
				icon: (props: NewCoreIconProps) => (
					<NotificationIcon {...props} color={token('color.icon.accent.green')} />
				),
			},
			{
				type: RuleParts.action,
				text: messages.itsmTemplate27.action,
				icon: (props: GlyphProps) => (
					<AnsibleIcon {...props} primaryColor={token('color.icon.accent.gray')} />
				),
			},
			{
				type: RuleParts.action,
				text: messages.itsmTemplate27.actionNext,
				icon: (props: NewCoreIconProps) => (
					<CommentIcon {...props} color={token('color.icon.accent.green')} />
				),
			},
		],
	},
	itsm_template_29: {
		info: {
			footer: messages.itsmTemplate29.footerText,
			footerNonAdmin: messages.itsmTemplate29.footerTextNonAdmin,
			learnMoreLink:
				'https://support.atlassian.com/cloud-automation/docs/use-jira-automation-with-jira-edge-connector-jec/',
			learnMoreText: messages.itsmTemplate29.learnMoreText,
			savedMinutes: 5800,
		},
		components: [
			{
				type: RuleParts.trigger,
				text: messages.itsmTemplate29.trigger,
				icon: (props: NewCoreIconProps) => (
					<NotificationIcon {...props} color={token('color.icon.accent.green')} />
				),
			},
			{
				type: RuleParts.action,
				text: messages.itsmTemplate29.action,
				icon: (props: GlyphProps) => (
					<AutomationIcon {...props} color={token('color.icon.accent.blue')} />
				),
			},
			{
				type: RuleParts.action,
				text: messages.itsmTemplate29.actionNext,
				icon: (props: NewCoreIconProps) => (
					<EditorDoneIcon {...props} color={token('color.icon.accent.blue')} />
				),
			},
		],
	},
	default: {
		id: 'itsm_template_19',
		info: {
			footer: messages.itsmTemplate19.footerTextAws,
			footerNonAdmin: messages.itsmTemplate19.footerTextAwsNonAdmin,
			learnMoreLink:
				'https://support.atlassian.com/cloud-automation/docs/use-jira-automation-with-aws/',
			learnMoreText: messages.itsmTemplate19.learnMoreTextAws,
			savedMinutes: 3000,
		},
		components: [
			{
				type: RuleParts.trigger,
				text: messages.itsmTemplate19.trigger,
				icon: (props: NewCoreIconProps) => (
					<NotificationIcon {...props} color={token('color.icon.accent.green')} />
				),
			},
			{
				type: RuleParts.action,
				text: messages.itsmTemplate19.action,
				icon: (props: GlyphProps) => (
					<NewRelicIcon {...props} primaryColor={token('color.icon.accent.gray')} />
				),
			},
			{
				type: RuleParts.action,
				text: messages.itsmTemplate19.actionNext,
				icon: (props: GlyphProps) => (
					<AwsIcon {...props} primaryColor={token('color.icon.accent.gray')} />
				),
			},
			{
				type: RuleParts.action,
				text: messages.itsmTemplate19.actionNext2,
				icon: (props: NewCoreIconProps) => (
					<EditorDoneIcon {...props} color={token('color.icon.accent.blue')} />
				),
			},
		],
	},
};

export const PROPERTY_KEY = 'jira.user.issue.automation-discoverability.panel.state';
