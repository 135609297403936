import { expVal } from '@atlassian/jira-feature-experiments';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	getSupportsIssueCreation,
	isCompatibleSortingPanel,
} from '../../state/context/selectors.tsx';
import { getAllIssues } from '../../state/entities/selectors.tsx';
import type { State } from '../../state/types.tsx';
import AddChild from './add-child/index.tsx';
import Meatballs from './meatballs/index.tsx';
import SortByMenu from './sort-by-menu/index.tsx';
import Title from './title/index.tsx';
import Heading from './view.tsx';

export default flowWithSafeComponent(
	connect(
		(state: State) => ({
			Title,
			Meatballs,
			AddChild,
			SortByMenu,
			allIssues: getAllIssues(state),
			supportsIssueCreation: getSupportsIssueCreation(state),
			isSortingAvailable:
				!expVal('jira-issue-view-chin-ga', 'isChildIssueEnabled', false) &&
				isCompatibleSortingPanel(state),
		}),
		{},
	),
)(Heading);
