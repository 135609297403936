import React, { memo, useCallback } from 'react';
import { styled } from '@compiled/react';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useDateField } from '@atlassian/jira-issue-field-date/src/services/use-date-field/index.tsx';
import { DateField } from '@atlassian/jira-issue-field-date/src/ui/main.tsx';
import { FieldDescription } from '@atlassian/jira-issue-field-description/src/ui/index.tsx';
import { FieldHeading } from '@atlassian/jira-issue-field-heading/src/index.tsx';
import {
	FieldHeadingTitle,
	SideBySideField,
	FieldWrapper,
	FieldHeadingIconsContainer,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin/src/index.tsx';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import { FieldEditIcon } from '@atlassian/jira-issue-field-edit-icon/src/FieldEditIcon.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { main_issueViewBase_Date$key } from '@atlassian/jira-relay/src/__generated__/main_issueViewBase_Date.graphql';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import type { Props } from './types.tsx';

const Date = ({ fieldId: fieldKey, label, area, onError, onConfirm, fragmentKey }: Props) => {
	const { formatMessage } = useIntl();
	const issueKey = useIssueKey();
	const showPinButton = getShowPinButton(area);
	const [{ value, fieldConfig }] = useDateField({ issueKey, fieldKey });
	const fieldLabel = fieldConfig?.title || label;

	const onErrorCallback = useCallback(() => {
		onError?.(value);
	}, [onError, value]);

	// eslint-disable-next-line @atlassian/relay/query-restriction
	const data = useFragment<main_issueViewBase_Date$key>(
		graphql`
			fragment main_issueViewBase_Date on JiraIssueField {
				...FieldEditIcon
			}
		`,
		fragmentKey,
	);

	return (
		<DateFieldWrapper data-testid={`issue.views.field.date-inline-edit.${fieldKey}`}>
			<FieldHeading fieldId={fieldKey}>
				<FieldHeadingTitle>{fieldLabel}</FieldHeadingTitle>
				<FieldHeadingIconsContainer
					isCustomFieldConfigurationEnabled={fg('issue_view_field_config_edit')}
				>
					{issueKey !== undefined && fieldKey !== undefined && (
						<FieldDescription issueKey={issueKey} fieldKey={fieldKey} label={fieldLabel} />
					)}
					{showPinButton === true && <FieldPin fieldId={fieldKey} label={fieldLabel} />}
					{fg('issue_view_field_config_edit') && (
						<UFOSegment name="issue-view-field-icon-edit">
							<FieldEditIcon fieldId={fieldKey} fragmentKey={data} />
						</UFOSegment>
					)}
				</FieldHeadingIconsContainer>
			</FieldHeading>
			<SideBySideField>
				<DateField
					isFitContainerWidthReadView
					issueKey={issueKey}
					fieldKey={fieldKey}
					noValueText={formatMessage(genericMessages.noValue)}
					onConfirm={onConfirm}
					onError={onErrorCallback}
				/>
			</SideBySideField>
		</DateFieldWrapper>
	);
};

export default memo<Props>(Date);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DateFieldWrapper = styled(FieldWrapper)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div > div > div > div > div > div > div': {
		width: '100%',
	},
});
