import type MarkdownIt from 'markdown-it';
import type StateCore from 'markdown-it/lib/rules_core/state_core.mjs';
import type Token from 'markdown-it/lib/token.mjs';

import { isSupportedByLinkingPlatform } from '../../linking-platform';
import { type RegexStorage } from '../../linking-platform/utils';

const linkPatternStart = '\\bhttps?:\\/\\/(?:www\\.)?';
const linkPatternEnd = '[^\\s\\]\\)]*';

// If we want to re-introduce canva support, please use the regex from this
// PR which was made redundant: https://stash.atlassian.com/projects/ATLASSIAN/repos/atlassian-frontend-monorepo/pull-requests/135407/commits/0fcf626f035396cfcb87153a4d8d4721c844b07a#platform%2Fpackages%2Fai-mate%2Fconversation-assistant%2Fsrc%2Fcommon%2Futils%2Fcontent-transformer%2Fmd-tokenizer%2Fmd-plugin-smartlink%2Findex.ts
// Right now the support is dropped in favour of supporting what the Canva Agent needs.
// It's likely first-class Enhanced Smart links  (e.g `(my canva link "smartCard-embed")[http://some.canva.link]`
// will be how we support this in the future
const linkRegex = {
	youtube: `${linkPatternStart}youtube\\.com${linkPatternEnd}`,
	youtubeShortUrl: `${linkPatternStart}youtu\\.be${linkPatternEnd}`,
	loomVideo: `${linkPatternStart}loom\\.com\\/share\\/${linkPatternEnd}`,
	figmaFile: `${linkPatternStart}figma\\.com\\/file\\/${linkPatternEnd}`,
	confluenceWhiteboard: `${linkPatternStart}.*\\/wiki\\/spaces\\/.*\\/whiteboard\\/${linkPatternEnd}`,
	googlePresentationAndSpreadsheet: `${linkPatternStart}docs\\.google\\.com\\/(presentation|spreadsheets)\\/d\\/${linkPatternEnd}`,
	googleDoc: `${linkPatternStart}docs\\.google\\.com\\/(document)\\/d\\/edit${linkPatternEnd}`,
	googleForm: `${linkPatternStart}docs\\.google\\.com\\/forms\\/d\\/${linkPatternEnd}`,
	microsoftForm: `${linkPatternStart}forms\\.office\\.com\\/Pages\\/ResponsePage\\.aspx\\?id\\=${linkPatternEnd}`,
	analyticsChart: `\\bhttps://[^.]+\\.(atlassian\\.net|jira-dev\\.com)/analytics/[^/]+/chart-${linkPatternEnd}`,
};
const supportedEmbedProviders = Object.values(linkRegex).join('|');
const embedRegex = new RegExp(supportedEmbedProviders, 'g');

const embedInlineRegex = [
	// avpRovoSkillsChart
	`${linkPatternStart}avp-rovo-skills\\.[-\\w]+.frontend.public.atl-paas.net/v1/chart[^\\s\\)]+${linkPatternEnd}`,
];

const createSmartLinks =
	({ regexStorage }: { regexStorage: RegexStorage }) =>
	(state: StateCore) => {
		const tokens: Token[] = [];
		const embedTokens: Token[] = [];
		let embedMatches: string[] = [];
		let filteredTokens: Token[] = [];

		for (const token of state.tokens) {
			let processedTokenChildren: Array<Token> = [];

			embedMatches = [...embedMatches, ...new Set(token.content?.trim().match(embedRegex))];

			// Any block or embed Smart Card links will be in an "inline" token. If
			// the current token is anything other than "inline", or there's no child tokens
			// to process, then we add it to the list of tokens to keep and move forward
			if (token.type !== 'inline' || !token.children) {
				tokens.push(token);
				continue;
			}

			var outputToken = new state.Token('inline', '', 0);
			let tokensToAppend: Token[] = [outputToken];

			if (token.children) {
				let discardUntilIndex = -1;

				token.children.forEach((childToken, i) => {
					const discardedToken =
						i <= discardUntilIndex && ['link_close', 'text'].includes(childToken.type);

					if (discardedToken) {
						return;
					}

					const url = childToken.attrGet('href') ?? '';
					const isInlineLink = url === token.children?.[i + 1]?.content;

					if (
						childToken?.type === 'link_open' &&
						(isSupportedByLinkingPlatform(url, regexStorage) || isInlineLink)
					) {
						// Discard the next two tokens. We're now processing the opening node for a URL
						// that will result in a new node that replaces it, therefore the subsequent link content and
						// closing node are no longer needed
						discardUntilIndex = i + 2;

						if (token.level <= 1 && embedInlineRegex.some((r) => url.match(r))) {
							outputToken.children = processedTokenChildren;
							tokensToAppend.push(new state.Token('paragraph_close', '', 0));

							processedTokenChildren = [];

							let embedToken = new state.Token('embedCard', '', 0);
							embedToken.content = '';
							embedToken.block = true;
							embedToken.attrSet('url', url);
							embedToken.attrSet('layout', 'center');
							tokensToAppend.push(embedToken);

							tokensToAppend.push(new state.Token('paragraph_open', '', 0));

							outputToken = new state.Token('inline', '', 0);
							tokensToAppend.push(outputToken);

							return;
						}

						let cardToken = new state.Token('inlineCard', '', 0);
						cardToken.content = '';
						cardToken.block = false;
						cardToken.level = childToken.level;
						cardToken.attrSet('url', url);
						processedTokenChildren.push(cardToken);

						return;
					}

					processedTokenChildren.push(childToken);
				});
			}

			outputToken.children = processedTokenChildren;

			const blockTokensWithContent = tokensToAppend.filter(
				(t) => t.type !== 'inline' || t.children?.length,
			);
			tokens.push(...blockTokensWithContent);
		}

		for (let i = 0; i < tokens.length; i++) {
			if (
				i < tokens.length - 1 &&
				tokens[i].type === 'paragraph_open' &&
				tokens[i + 1].type === 'paragraph_close'
			) {
				i = i + 1;
			} else {
				filteredTokens.push(tokens[i]);
			}
		}

		if (embedMatches?.length) {
			[...new Set(embedMatches)].forEach((match) => {
				let embedToken = new state.Token('embedCard', '', 0);
				embedToken.content = '';
				embedToken.block = true;
				embedToken.attrSet('url', match);
				embedToken.attrSet('layout', 'center');
				embedTokens.push(embedToken);
			});
		}
		state.tokens = [...filteredTokens, ...embedTokens];

		return;
	};

export const createSmartLinksPlugin =
	(params: Parameters<typeof createSmartLinks>[0]) => (md: MarkdownIt) =>
		md.core.ruler.push('inlineCard', createSmartLinks(params));
