// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createActionsHook } from '@atlassian/react-sweet-state';
import { ArchiveIssueModalActions, type Actions } from './actions.tsx';
import { initialState } from './constants.tsx';
import type { State } from './types.tsx';

export const ArchiveIssueModalStore = createStore<State, Actions>({
	initialState,
	actions: ArchiveIssueModalActions,
	name: 'archiveIssueModalStore',
});

export const useArchiveIssueModalActions = createActionsHook<State, Actions>(
	ArchiveIssueModalStore,
);
