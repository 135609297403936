// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addAttachmentTooltip: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.add-attachment-label',
		defaultMessage: 'Add attachment',
		description:
			'The tooltip to be used on the quick add button for adding an attachment to an issue',
	},
	createSubtaskTooltip: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.create-subtask-label',
		defaultMessage: 'Create subtask',
		description: 'The tooltip to be used on the quick add button for adding a subtask for an issue',
	},
	addIssueLinkTooltip: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.add-issue-link-label',
		defaultMessage: 'Link issue',
		description: 'The tooltip to be used on the quick add button for creating an issue link',
	},
	createChildTooltip: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.create-child-label',
		defaultMessage: 'Add a child issue',
		description:
			'The tooltip to be used on the quick add button for creating a child in an agility issue',
	},
	linkConfluencePageTooltip: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.link-confluence-page-label',
		defaultMessage: 'Link a Confluence page',
		description: 'The tooltip to be used on the quick add button for linking a Confluence page',
	},
	linkConfluenceLiveDocTooltip: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.link-confluence-live-doc-label',
		defaultMessage: 'Link a Confluence live doc',
		description: 'The tooltip to be used on the quick add button for linking a Confluence live doc',
	},
	linkConfluenceContentTooltip: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.link-confluence-content-label',
		defaultMessage: 'Link a Confluence content',
		description: 'The tooltip to be used on the quick add button for linking Confluence content',
	},
	addAttachmentLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.add-attachment-button-label',
		defaultMessage: 'Attach',
		description:
			'The label to be used on the quick add button for adding an attachment to an issue',
	},
	attachmentLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.attachment-button-label',
		defaultMessage: 'Attachment',
		description:
			'The label to be used on the quick add button for adding an attachment to an issue',
	},
	createSubtaskLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.create-subtask-button-label',
		defaultMessage: 'Create subtask',
		description: 'The label to be used on the quick add button for adding a subtask for an issue',
	},
	subtaskLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.subtask-button-label',
		defaultMessage: 'Subtask',
		description: 'The label to be used on the quick add button for adding a subtask for an issue',
	},
	addIssueLinkLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.add-issue-link-button-label',
		defaultMessage: 'Link issue',
		description: 'The label to be used on the quick add button for creating an issue link',
	},
	addLinkedIssueLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.add-linked-issue-button-label',
		defaultMessage: 'Linked issue',
		description: 'The label to be used on the quick add button for creating an issue link',
	},
	createChildLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.create-child-button-label',
		defaultMessage: 'Add a child issue',
		description:
			'The label to be used on the quick add button for creating a child in an agility issue',
	},
	childIssueLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.child-issue-button-label',
		defaultMessage: 'Child issue',
		description:
			'The label to be used on the quick add button for creating a child in an agility issue',
	},
	linkConfluencePageLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.link-confluence-page-button-label',
		defaultMessage: 'Link Confluence content',
		description: 'The label to be used on the quick add button for linking Confluence content',
	},
	existingConfluencePageLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.existing-confluence-page-button-label',
		defaultMessage: 'Existing Confluence content',
		description: 'The label to be used on the quick add button for linking Confluence content',
	},
	linkConfluencePageNestedLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.link-confluence-page-nested-label',
		defaultMessage: 'Link page',
		description:
			'The label to be used on the quick add button for linking a Confluence page in nested menu',
	},
	linkConfluencePageNestedLabelSearch: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.link-confluence-page-nested-label-search',
		defaultMessage: 'Link page in confluence',
		description:
			'The label to be used for search on the quick add button for linking a Confluence page in nested menu',
	},
	linkConfluenceLiveDocNestedLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.link-confluence-live-doc-nested-label',
		defaultMessage: 'Link live doc',
		description:
			'The label to be used on the quick add button for linking a Confluence live doc in nested menu',
	},
	linkConfluenceLiveDocNestedLabelSearch: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.link-confluence-live-doc-nested-label-search',
		defaultMessage: 'Link live doc in confluence',
		description:
			'The label to be used for search on the quick add button for linking a Confluence live doc in nested menu',
	},
	confluencePageNestedParentLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.confluence-page-nested-parent-label',
		defaultMessage: 'Create or link confluence page',
		description:
			'The label to be used on the quick add menu for creating or linking a Confluence content in nested menu',
	},
	confluencePageNestedParentSearchLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.confluence-page-nested-parent-search-label',
		defaultMessage: 'Create page or link page in confluence',
		description:
			'The label to be used for search on the quick add menu for creating or linking a Confluence page in nested menu',
	},
	confluenceLiveDocNestedParentSearchLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.confluence-live-doc-nested-parent-search-label',
		defaultMessage: 'Create live doc or link page in confluence',
		description:
			'The label to be used for search on the quick add menu for creating or linking a Confluence live doc in nested menu',
	},
	confluencePageNestedParentCrossFlowSearchLabel: {
		id: 'issue-view-foundation.quick-add.quick-add-items.confluence-page-nested-parent-cross-flow-search-label',
		defaultMessage: 'Confluence create page',
		description:
			'The label to be used for Crossflow search on the quick add menu for creating or linking a Confluence page in nested menu',
	},
	confluencePageTryCrossFlowConfluence: {
		id: 'issue-view-foundation.quick-add.quick-add-items.confluence-page-try-cross-flow-confluence',
		defaultMessage: 'Try confluence page',
		description:
			'The label to be used for Crossflow search on the quick add menu for creating or linking a Confluence page in nested menu',
	},
	confluenceLiveDocTryCrossFlowConfluence: {
		id: 'issue-view-foundation.quick-add.quick-add-items.confluence-live-doc-try-cross-flow-confluence',
		defaultMessage: 'Try confluence live doc',
		description:
			'The label to be used for Crossflow search on the quick add menu for creating or linking a Confluence live doc in nested menu',
	},
	linkConfluenceWhiteboardNestedLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.link-confluence-whiteboard-nested-label',
		defaultMessage: 'Link whiteboard',
		description:
			'The label to be used on the quick add button for linking a Confluence whiteboard in nested menu',
	},
	linkConfluenceWhiteboardNestedLabelSearch: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.link-confluence-whiteboard-nested-label-search',
		defaultMessage: 'Link whiteboard in confluence',
		description:
			'The label to be used for search on the quick add button for linking a Confluence whiteboard in nested menu',
	},
	confluenceWhiteboardNestedParentLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.confluence-whiteboard-nested-parent-label',
		defaultMessage: 'Create or link confluence whiteboard',
		description:
			'The label to be used on the quick add menu for creating or linking a Confluence whiteboard in nested menu',
	},
	confluenceWhiteboardNestedParentSearchLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.confluence-whiteboard-nested-parent-search-label',
		defaultMessage: 'Create whiteboard or link whiteboard in confluence',
		description:
			'The label to be used for search on the quick add menu for creating or linking a Confluence whiteboard in nested menu',
	},
	confluenceWhiteboardNestedParentCrossFlowSearchLabel: {
		id: 'issue-view-foundation.quick-add.quick-add-items.confluence-whiteboard-nested-parent-cross-flow-search-label',
		defaultMessage: 'Confluence create whiteboard',
		description:
			'The label to be used for crossflow search on the quick add menu for creating or linking a Confluence whiteboard in nested menu',
	},
	confluenceWhiteboardTryCrossFlowConfluence: {
		id: 'issue-view-foundation.quick-add.quick-add-items.confluence-whiteboard-try-cross-flow-confluence',
		defaultMessage: 'Try confluence whiteboard',
		description:
			'The label to be used for Crossflow search on the quick add menu for creating or linking a Confluence whiteboard in nested menu',
	},
	loomTryCrossFlowConfluence: {
		id: 'issue-view-foundation.quick-add.quick-add-items.loom-try-cross-flow-confluence',
		defaultMessage: 'Try Loom video',
		description:
			'The label to be used for Crossflow search on the quick add menu for creating or linking a Loom video',
	},
	linkConfluenceContentLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.link-confluence-content-button-label',
		defaultMessage: 'Link Confluence content',
		description: 'The label to be used on the quick add button for linking Confluence content',
	},
	linkAlertsLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.link-alerts-label',
		defaultMessage: 'Link alerts',
		description:
			'The label to be used on the quick add button for linking Opsgenie alerts with JSM incidents',
	},
	linkAlertsTooltip: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.link-alerts-tooltip',
		defaultMessage: 'Link alerts',
		description:
			'The tooltip to be used on the quick add button for linking Opsgenie alerts with JSM incidents',
	},
	investigateIncidentLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.investigate-incident-label',
		defaultMessage: 'Investigate',
		description:
			'The label to be used on the quick add button for investigating incidents with JSM requests',
	},
	investigateIncidentTooltip: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.investigate-incident-tooltip',
		defaultMessage: 'Investigate deployments and add them as potential causes to this incident',
		description:
			'The tooltip to be used on the quick add button for investigating incidents with JSM requests',
	},
	addWebLinkLabel: {
		id: 'issue.foundation.quick-add.quick-add-items.add-web-link-label',
		defaultMessage: 'Add web link',
		description: 'The tooltip to be used on the quick add button for adding web link',
	},
	webLinkLabel: {
		id: 'issue.foundation.quick-add.quick-add-items.web-link-label',
		defaultMessage: 'Web link',
		description: 'The tooltip to be used on the quick add button for adding web link',
	},
	addWebLinkTooltip: {
		id: 'issue.foundation.quick-add.quick-add-items.add-web-link-tooltip',
		defaultMessage: 'Link web pages and more',
		description: 'The tooltip to be used on the quick add button for adding web link',
	},
	linkDropdownButtonLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.web-link-dropdown-label',
		defaultMessage: 'Link web pages and more',
		description: 'The label to be used on the dropdown button for showing link options',
	},
	linkDropdownButtonCompactLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.web-link-dropdown-compact-label',
		defaultMessage: 'Link issues, web pages, and more',
		description:
			'The label to be used on the dropdown button for showing link options in compact mode',
	},
	linkIdeaLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.link-idea-label',
		defaultMessage: 'Link idea',
		description: 'The label to be used on the dropdown button for linking an idea',
	},
	ideaLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.idea-label',
		defaultMessage: 'Idea',
		description: 'The label to be used on the dropdown button for linking an idea',
	},
	linkIdeaTooltip: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items..link-idea-tooltip',
		defaultMessage: 'Link product idea to your issue',
		description: 'The tooltip to be used on the dropdown button for linking an idea',
	},
	addDesignSearchLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.add-design-search-label',
		defaultMessage: 'Add design',
		description: 'Search label to be used for add design action',
	},
	createChildIssueDropdownLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.create-child-issue-dropdown-label',
		defaultMessage: 'Create child issue',
		description:
			'The label to be used on the quick add button for creating a child issue in quick add dropdown',
	},
	createSubtaskDropdownLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.create-subtask-dropdown-label',
		defaultMessage: 'Create subtask',
		description:
			'The label to be used on the quick add button for creating a subtask in quick add dropdown',
	},
	linkIssueDropdownLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.link-issue-dropdown-label',
		defaultMessage: 'Link issue',
		description:
			'The label to be used on the quick add button for creating an issue link in quick add dropdown',
	},
	addAttachmentDropdownLabel: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.add-attachment-dropdown-label',
		defaultMessage: 'Add attachment',
		description:
			'The label to be used on the quick add button for adding an attachment to an issue in quick add dropdown',
	},
	addIssueLinkTooltipIssueTermRefresh: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.add-issue-link-label-issue-term-refresh',
		defaultMessage: 'Link work item',
		description: 'The tooltip to be used on the quick add button for creating an work item link',
	},
	createChildTooltipIssueTermRefresh: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.create-child-label-issue-term-refresh',
		defaultMessage: 'Add a child work item',
		description:
			'The tooltip to be used on the quick add button for creating a child in an agility work item',
	},
	addIssueLinkLabelIssueTermRefresh: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.add-issue-link-button-label-issue-term-refresh',
		defaultMessage: 'Link work item',
		description: 'The label to be used on the quick add button for creating a work item link',
	},
	addLinkedIssueLabelIssueTermRefresh: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.add-linked-issue-button-label-issue-term-refresh',
		defaultMessage: 'Linked work item',
		description: 'The label to be used on the quick add button for creating an work item link',
	},
	createChildLabelIssueTermRefresh: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.create-child-button-label-issue-term-refresh',
		defaultMessage: 'Add a child work item',
		description:
			'The label to be used on the quick add button for creating a child in an agility work item',
	},
	childIssueLabelIssueTermRefresh: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.child-issue-button-label-issue-term-refresh',
		defaultMessage: 'Child work item',
		description:
			'The label to be used on the quick add button for creating a child in an agility work item',
	},
	linkIdeaTooltipIssueTermRefresh: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items..link-idea-tooltip-issue-term-refresh',
		defaultMessage: 'Link product idea to your work item',
		description: 'The tooltip to be used on the quick add button for creating an issue link',
	},
	linkDropdownButtonCompactLabelIssueTermRefresh: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.web-link-dropdown-compact-label-issue-term-refresh',
		defaultMessage: 'Link work items, web pages, and more',
		description:
			'The label to be used on the dropdown button for showing link options in compact mode',
	},
	createChildIssueDropdownLabelIssueTermRefresh: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.create-child-issue-dropdown-label-issue-term-refresh',
		defaultMessage: 'Create child work item',
		description:
			'The label to be used on the quick add button for creating a child work item in quick add dropdown',
	},
	linkIssueDropdownLabelIssueTermRefresh: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.link-issue-dropdown-label-issue-term-refresh',
		defaultMessage: 'Link work item',
		description:
			'The label to be used on the quick add button for creating a work item link in quick add dropdown',
	},
	spinnerLoading: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.spinner-loading',
		defaultMessage: 'Loading',
		description: 'The message to be displayed when the spinner is loading',
	},
	tryLozenge: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.try-lozenge',
		defaultMessage: 'Try',
		description: 'The message to be displayed on the try lozenge for cross flow',
	},
	addLozenge: {
		id: 'issue.issue-base.foundation.quick-add.quick-add-items.add-lozenge',
		defaultMessage: 'Add',
		description: 'The message to be displayed on the add lozenge for cross flow',
	},
});
