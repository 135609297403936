/** @jsx jsx */
import React, { memo, type HTMLAttributes } from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import IssueStatusField from '@atlassian/jira-issue-field-status/src/ui/main.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { preventDefault } from '../../common/utils.tsx';
import { PaddedFlexContainer } from '../../common/ui/styled.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const statusContainerStyle = css({
	width: '124px',
	maxWidth: '124px',
	display: 'flex',
	alignItems: 'center',
	marginLeft: token('space.200'),
});

const StatusContainer = ({ children, ...rest }: HTMLAttributes<HTMLDivElement>) => (
	<div css={statusContainerStyle} {...rest}>
		{children}
	</div>
);

export const Status = memo<Props>(
	({
		issueKey,
		statusName,
		statusId,
		statusCategoryId,
		isRemote,
		isInteractive,
		hideTooltipOnMouseDown,
		projectType,
		onEditStatus,
		isLinkedIssuesRefreshEnabled = false,
	}: Props) => {
		const intl = useIntl();
		const tooltipContent =
			isRemote === true && isInteractive !== true
				? intl.formatMessage(messages.remoteIssueUneditableStatusTooltip)
				: null;

		if (isLinkedIssuesRefreshEnabled) {
			return (
				<StatusContainer
					css={statusContainerStyle}
					onClick={preventDefault}
					data-testid="issue-line-card.ui.status.status-field-container"
				>
					<Tooltip
						content={tooltipContent}
						hideTooltipOnMouseDown={hideTooltipOnMouseDown}
						position="bottom"
					>
						<ContextualAnalyticsData sourceName="childIssuesPanel">
							<IssueStatusField
								isEditable={isInteractive}
								appearance="lozenge"
								issueKey={issueKey}
								projectType={projectType}
								initialValue={{
									id: statusId,
									name: statusName,
									statusCategory: { id: statusCategoryId },
								}}
								onSuccess={onEditStatus}
							/>
						</ContextualAnalyticsData>
					</Tooltip>
				</StatusContainer>
			);
		}

		return (
			<PaddedFlexContainer
				onClick={preventDefault}
				data-testid="issue-line-card.ui.status.status-field-container"
			>
				<Tooltip
					content={tooltipContent}
					hideTooltipOnMouseDown={hideTooltipOnMouseDown}
					position="bottom"
				>
					<ContextualAnalyticsData sourceName="childIssuesPanel">
						<IssueStatusField
							isEditable={isInteractive}
							appearance="lozenge"
							issueKey={issueKey}
							projectType={projectType}
							initialValue={{
								id: statusId,
								name: statusName,
								statusCategory: { id: statusCategoryId },
							}}
							onSuccess={onEditStatus}
						/>
					</ContextualAnalyticsData>
				</Tooltip>
			</PaddedFlexContainer>
		);
	},
);
