import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { ResourceManager } from '@atlassian/jira-issue-view-common-utils/src/utils/prefetched-resources/prefetched-resource-manager/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createHook, type StoreActionApi } from '@atlassian/react-sweet-state';
import { fetchNonCriticalGiraDataWithRetries } from '../../services/index.tsx';
import type { State } from './types.tsx';

const initialState: State = {};

const fetch =
	(issueKey: IssueKey, prefetchedResourceManager?: ResourceManager) =>
	async ({ setState, getState }: StoreActionApi<State>) => {
		if (getState()?.[issueKey]?.isFetching) {
			return;
		}
		setState({
			[issueKey]: {
				...getState()[issueKey],
				isFetching: true,
			},
		});

		try {
			const data = await fetchNonCriticalGiraDataWithRetries(issueKey, prefetchedResourceManager);
			setState({
				[issueKey]: {
					value: data,
					isFetching: false,
					isReady: true,
				},
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			log.safeErrorWithoutCustomerData(
				'issue.fetch.gira-non-critical',
				'Failed to fetch non-critical Gira data',
				error,
			);
			setState({
				[issueKey]: {
					...getState()[issueKey],
					isFetching: false,
					isReady: true,
				},
			});
		}
	};

export const actions = {
	fetch,
};

const Store = createStore<State, typeof actions>({
	initialState,
	actions,
	name: 'IssueViewNonCriticalData',
});

export const useAllIssueViewAsyncData = createHook<State, typeof actions>(Store);
