import React from 'react';

import CrossCircleIcon from '@atlaskit/icon/core/migration/cross-circle';
import SuccessCheckCircleIcon from '@atlaskit/icon/core/migration/success--check-circle';
import WarningIcon from '@atlaskit/icon/core/warning'; // TODO: find the correct icon GLIDE-152

import { token } from '@atlaskit/tokens';

import Spinner from '@atlaskit/spinner';

import { Box, xcss } from '@atlaskit/primitives';

import { defineMessages, useIntl } from '@atlassian/jira-intl';
import NoActionsCircle from './no-actions-circle/index.tsx';

const CATEGORIES = [
	'SUCCESS',
	'THROTTLED',
	'LOOP',
	'SOME_ERRORS',
	'ABORTED',
	'FAILURE',
	'NO_ACTIONS_PERFORMED',
	'QUEUED_FOR_RETRY',
] as const;
type Category = (typeof CATEGORIES)[number];

const isCategory = (category: string): category is Category =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	CATEGORIES.includes(category as Category);

const messages = defineMessages<Category>({
	// Disabling eslint here just for the key of messages (on the left side), the message id follows the eslint convention
	// eslint-disable-next-line jira/i18n/id-named-by-package
	SUCCESS: {
		id: 'automation-issue-audit-log-panel.recent-rule-runs.rule-display.rule-display-icon.success',
		defaultMessage: 'SUCCESS',
		description:
			'This is a category of an automation rule run. It indicates that the rule ran successfully.',
	},
	// eslint-disable-next-line jira/i18n/id-named-by-package
	THROTTLED: {
		id: 'automation-issue-audit-log-panel.recent-rule-runs.rule-display.rule-display-icon.throttled',
		defaultMessage: 'THROTTLED',
		description:
			'This is a category of an automation rule run. It indicates that the rule was throttled.',
	},
	// eslint-disable-next-line jira/i18n/id-named-by-package
	LOOP: {
		id: 'automation-issue-audit-log-panel.recent-rule-runs.rule-display.rule-display-icon.loop',
		defaultMessage: 'LOOP',
		description:
			'This is a category of an automation rule run. It indicates that the rule was in a loop.',
	},
	// eslint-disable-next-line jira/i18n/id-named-by-package
	SOME_ERRORS: {
		id: 'automation-issue-audit-log-panel.recent-rule-runs.rule-display.rule-display-icon.some-errors',
		defaultMessage: 'SOME ERRORS',
		description:
			'This is a category of an automation rule run. It indicates that the rule had some errors.',
	},
	// eslint-disable-next-line jira/i18n/id-named-by-package
	ABORTED: {
		id: 'automation-issue-audit-log-panel.recent-rule-runs.rule-display.rule-display-icon.aborted',
		defaultMessage: 'ABORTED',
		description:
			'This is a category of an automation rule run. It indicates that the rule was aborted.',
	},
	// eslint-disable-next-line jira/i18n/id-named-by-package
	FAILURE: {
		id: 'automation-issue-audit-log-panel.recent-rule-runs.rule-display.rule-display-icon.failure',
		defaultMessage: 'FAILURE',
		description: 'This is a category of an automation rule run. It indicates that the rule failed.',
	},
	// eslint-disable-next-line jira/i18n/id-named-by-package
	NO_ACTIONS_PERFORMED: {
		id: 'automation-issue-audit-log-panel.recent-rule-runs.rule-display.rule-display-icon.no-actions-performed',
		defaultMessage: 'NO ACTIONS PERFORMED',
		description:
			'This is a category of an automation rule run. It indicates that no actions were performed.',
	},
	// eslint-disable-next-line jira/i18n/id-named-by-package
	QUEUED_FOR_RETRY: {
		id: 'automation-issue-audit-log-panel.recent-rule-runs.rule-display.rule-display-icon.queued',
		defaultMessage: 'QUEUED FOR RETRY',
		description:
			'This is a category of an automation rule run. It indicates that the rule was queued for retry.',
	},
});

const AuditItemIconMapping = {
	SUCCESS: () => (
		<SuccessCheckCircleIcon label="" LEGACY_size="small" color={token('color.text.success')} />
	),
	THROTTLED: () => <WarningIcon label="" LEGACY_size="small" color={token('color.icon.warning')} />,
	LOOP: () => <WarningIcon label="" LEGACY_size="small" color={token('color.icon.warning')} />,
	SOME_ERRORS: () => (
		<WarningIcon label="" LEGACY_size="small" color={token('color.icon.warning')} />
	),
	ABORTED: () => (
		<CrossCircleIcon label="" LEGACY_size="small" color={token('color.icon.danger')} />
	),
	FAILURE: () => (
		<CrossCircleIcon label="" LEGACY_size="small" color={token('color.icon.danger')} />
	),
	NO_ACTIONS_PERFORMED: () => (
		<NoActionsCircle label="" size="small" primaryColor={token('color.icon')} />
	),
	QUEUED_FOR_RETRY: () => <Spinner size="small" />,
} as const;

const RuleDisplayIcon: React.FC<{ category: string }> = ({ category }) => {
	const { formatMessage } = useIntl();

	if (!isCategory(category)) {
		return null;
	}
	const icon = AuditItemIconMapping[category];
	const title = formatMessage(messages[category]);

	const Icon = icon;

	return (
		<Box
			title={title}
			xcss={category === 'QUEUED_FOR_RETRY' ? queuedIconBackgroundStyle : iconBackgroundStyle}
		>
			<Icon />
		</Box>
	);
};

const iconBackgroundStyle = xcss({
	height: '16px',
	width: '16px',
	borderRadius: '3px',
	verticalAlign: 'middle',
	gridArea: 'icon',
	alignSelf: 'center',
});

const queuedIconBackgroundStyle = xcss({
	height: '16px',
	width: '16px',
	borderRadius: '3px',
	verticalAlign: 'middle',
	gridArea: 'icon',
	alignSelf: 'start',
});

export default RuleDisplayIcon;
