/** @jsx jsx */
import React, { useCallback, useEffect, useState, type PropsWithChildren } from 'react';
import { styled, jsx, css } from '@compiled/react';
import Button from '@atlaskit/button';
import { IconButton, type IconButtonProps } from '@atlaskit/button/new';
import Icon from '@atlaskit/icon';
import AutomationIcon from '@atlaskit/icon/core/automation';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import { Section } from '@atlaskit/menu';
import type { Placement } from '@atlaskit/popper';
import type { TriggerProps } from '@atlaskit/popup';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import { AutomationGlyph } from '@atlassian/jira-automation-menu/src/common/assets/automation.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import Footer from '@atlassian/jira-automation-platform/src/ui/footer/main.tsx';
import { CORE_PROJECT, SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/index.tsx';
import { gridSize, layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { RecurringIssueLinkItem } from '@atlassian/jira-recurring-issue-action/src/ui/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { asyncRecurWorkFormEntryPoint } from '@atlassian/jira-recur-work-item/entrypoint.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { RecurWorkItemContainer as RecurWorkItemContainerMain } from '@atlassian/jira-recur-work-item/src/controllers/index.tsx';
import type { ContainerProps as RecurWorkItemContainerProps } from '@atlassian/jira-recur-work-item/src/controllers/types.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { RecurWorkComponent } from '@atlassian/jira-issue-field-recur-work-item-trigger/src/ui/index.tsx';
import { MENU_ITEM } from '@atlassian/jira-issue-field-recur-work-item-trigger/src/ui/constants.tsx';
import { AutomationSubMenu } from './automation-submenu/index.tsx';
import messages from './messages.tsx';
import { TransitionsSubMenu } from './transitions-submenu/index.tsx';
import type { Props } from './types.tsx';
import { RecurWorkContainer } from './recur-work-container/index.tsx';
import { POPUP_KEYS, type PopupKeys } from './constants.tsx';
import { RecurWorkMenuItem } from './recur-work-menu-item/index.tsx';

// Component is responsible for triggering the ManualRulesContainer HOC to fetch the rules
// when this mounts. This is how we ensure lazy load behaviour of the menu.
const FetchRulesContainer = (props: {
	triggerFetch: () => Promise<void>;
	children: React.ReactNode;
}) => {
	const { triggerFetch, children } = props;
	useEffect(() => {
		triggerFetch();
		// Ignore exhaustive dep here to ensure no call to useEffect on re-render
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return <>{children}</>;
};

const tooltipOptions: IconButtonProps['tooltip'] = {
	position: 'top',
	hideTooltipOnClick: true,
};

const customModifiers = [
	{
		name: 'preventOverflow',
		enabled: true,
		options: {
			padding: { top: 65 },
		},
	},
];

const RecurWorkItemContainerEmpty = ({
	children,
}: PropsWithChildren<RecurWorkItemContainerProps>) => children;

export const useIsOpen = () => useState(false);

export const ActionsMenu = (props: Props) => {
	const {
		transitions,
		onSelect,
		rules,
		executeRule,
		executingRule,
		triggerFetch,
		initialised,
		issueKey,
		issueId,
		projectType,
		baseAutomationUrl,
		canManageAutomations,
	} = props;

	const [activePopupKey, setActivePopupKey] = useState<PopupKeys | null>(null);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isOpen, setIsOpen] = useIsOpen();
	const { formatMessage } = useIntl();

	const {
		entryPointReferenceSubject: recurringWorkEntryPointRef,
		entryPointActions: recurringWorkEntryPointActions,
	} = useEntryPoint(asyncRecurWorkFormEntryPoint, {});
	const recurringWorkTriggerRef = useEntryPointButtonTrigger(recurringWorkEntryPointActions);

	const canSeeRecurringWorkToggle =
		canManageAutomations &&
		(projectType === SOFTWARE_PROJECT || projectType === CORE_PROJECT) &&
		expVal('jira_recurring_work_oncomplete_admin_experiment', 'showRecurringWorkToggle', false) &&
		!expVal('jira-recur-single-work-item-on-schedule', 'showRecurringWorkPopup', false);

	const canSeeRecurringWorkPopup =
		(projectType === SOFTWARE_PROJECT || projectType === CORE_PROJECT) &&
		expVal('jira-recur-single-work-item-on-schedule', 'showRecurringWorkPopup', false);

	const canSeeRecurringWorkPopupWithNewTrigger =
		canSeeRecurringWorkPopup && fg('jira-recur-single-work-item-on-sched-due-date-gate');

	const onTriggerClick = useCallback(() => {
		if (!isOpen) {
			const analyticsEvent = createAnalyticsEvent({
				actionSubject: 'menu',
				action: 'opened',
			});
			fireUIAnalytics(analyticsEvent, 'manualRulesActionsMenu', {
				userType: canManageAutomations ? 'admin' : 'endUser',
			});
		}

		if (isOpen) setActivePopupKey(null);
		setIsOpen(!isOpen);
	}, [canManageAutomations, createAnalyticsEvent, isOpen, setIsOpen]);

	const onTriggerClickOld = useCallback(() => {
		if (fg('jira_adds_isjiraglobaladmin_event_attribute')) {
			if (!isOpen) {
				const analyticsEvent = createAnalyticsEvent({
					actionSubject: 'menu',
					action: 'opened',
				});
				fireUIAnalytics(analyticsEvent, 'manualRulesActionsMenu', {
					userType: canManageAutomations ? 'admin' : 'endUser',
				});
			}
		}

		if (isOpen) setActivePopupKey(null);
		setIsOpen(!isOpen);

		if (!fg('jira_adds_isjiraglobaladmin_event_attribute')) {
			if (isOpen) {
				const analyticsEvent = createAnalyticsEvent({
					actionSubject: 'menu',
					action: 'opened',
				});
				fireUIAnalytics(analyticsEvent, 'manualRulesActionsMenu', {
					userType: canManageAutomations ? 'admin' : 'endUser',
				});
			}
		}
	}, [canManageAutomations, createAnalyticsEvent, isOpen, setIsOpen]);

	// fallback placements added as per atlaskit dropdown-menu
	const fallbackPlacements: Placement[] = [
		'bottom',
		'bottom-end',
		'top-start',
		'top',
		'top-end',
		'auto',
	];

	const onClose = useCallback(() => {
		setIsOpen(false);
		setActivePopupKey(null);
	}, [setIsOpen, setActivePopupKey]);

	const renderActivePopup = useCallback(() => {
		if (activePopupKey === POPUP_KEYS.recurWork) {
			return (
				<RecurWorkContainer
					entryPointRef={recurringWorkEntryPointRef}
					issueKey={issueKey}
					onBack={() => setActivePopupKey(null)}
					onClose={onClose}
				/>
			);
		}
	}, [activePopupKey, recurringWorkEntryPointRef, issueKey, onClose]);

	const handleOpenRecurWorkPopup = useCallback(() => {
		setActivePopupKey(POPUP_KEYS.recurWork);
	}, []);

	const RecurWorkItemContainer =
		canSeeRecurringWorkPopup || canSeeRecurringWorkPopupWithNewTrigger
			? RecurWorkItemContainerMain
			: RecurWorkItemContainerEmpty;

	return (
		<RecurWorkItemContainer issueKey={issueKey}>
			<Popup
				messageId="issue-field-status.ui.status-view.actions.actions-menu.popup"
				messageType="transactional"
				isOpen={isOpen}
				placement="bottom-start"
				fallbackPlacements={fallbackPlacements}
				trigger={(triggerProps: TriggerProps) => {
					const trigger = isVisualRefreshEnabled() ? (
						<IconButton
							{...triggerProps}
							isSelected={isOpen}
							onClick={
								fg('jira_manualrulesactionsmenu_analytics_bugfix')
									? onTriggerClick
									: onTriggerClickOld
							}
							icon={AutomationIcon}
							isTooltipDisabled={false}
							tooltip={tooltipOptions}
							label={formatMessage(messages.automation)}
							interactionName="jira-issue-field-status.ui.status-view.actions.actions-menu.button"
						/>
					) : (
						<Button
							{...triggerProps}
							appearance="subtle"
							isSelected={isOpen}
							onClick={
								fg('jira_manualrulesactionsmenu_analytics_bugfix')
									? onTriggerClick
									: onTriggerClickOld
							}
							iconAfter={<ChevronDownIcon label="" />}
							iconBefore={
								<Icon size="medium" glyph={AutomationGlyph} label="" secondaryColor="none" />
							}
							interactionName="jira-issue-field-status.ui.status-view.actions.actions-menu.button"
						>
							{formatMessage(messages.actions)}
						</Button>
					);

					return trigger;
				}}
				onClose={onClose}
				content={() =>
					activePopupKey && (canSeeRecurringWorkPopup || canSeeRecurringWorkPopupWithNewTrigger) ? (
						renderActivePopup()
					) : (
						<div css={menuContainerStyles} role="menu">
							<MenuItemContainer>
								<FetchRulesContainer triggerFetch={triggerFetch}>
									<TransitionsSubMenu
										initialised={initialised}
										onSelect={onSelect}
										transitions={transitions}
										canManageAutomations={canManageAutomations}
									/>
									<AutomationSubMenu
										initialised={initialised}
										rules={rules}
										transitions={transitions}
										executeRule={executeRule}
										issueKey={issueKey}
										issueId={issueId}
										executingRule={executingRule}
										baseAutomationUrl={baseAutomationUrl}
										canManageAutomations={canManageAutomations}
									/>
								</FetchRulesContainer>
							</MenuItemContainer>
							{canSeeRecurringWorkPopupWithNewTrigger && (
								<RecurWorkComponent
									triggerType={MENU_ITEM}
									testId="issue-field-status.ui.status-view.actions.actions-menu"
									buttonRef={recurringWorkTriggerRef}
									onClick={handleOpenRecurWorkPopup}
									issueKey={issueKey}
									recurWorkFormEntryPoint="actionsMenu"
								/>
							)}
							{!canSeeRecurringWorkPopupWithNewTrigger && canSeeRecurringWorkPopup && (
								<RecurWorkMenuItem
									buttonRef={recurringWorkTriggerRef}
									onClick={handleOpenRecurWorkPopup}
									issueKey={issueKey}
									recurWorkFormEntryPoint="actionsMenu"
								/>
							)}
							{canSeeRecurringWorkToggle && <RecurringIssueLinkItem issueKey={issueKey} />}
							{canManageAutomations && <Footer baseAutomationUrl={baseAutomationUrl} />}
						</div>
					)
				}
				zIndex={layers.modal}
				shouldRenderToParent
				modifiers={customModifiers}
				testId="issue-field-status.ui.status-view.actions.actions-menu.popup"
			/>
		</RecurWorkItemContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- To migrate as part of go/ui-styling-standard
export const MenuItemContainer = styled(Section)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minWidth: `${gridSize * 40}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxHeight: `${gridSize * 62}px`,
	overflow: 'auto',
});

const menuContainerStyles = css({
	textWrap: 'initial',
	// maxHeight = total height - top navigation height(56px) - top and bottom space (20px)
	maxHeight: 'calc(100vh - 76px)',
	overflow: 'auto',
});
