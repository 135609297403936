import React, { useCallback, useEffect } from 'react';
import { graphql, useFragment } from 'react-relay';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { useIntl } from '@atlassian/jira-intl';
import {
	fireOperationalAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useRouterActions } from '@atlassian/react-resource-router';
import type { view_issueViewFoundation_ConfigureIssueFF$key } from '@atlassian/jira-relay/src/__generated__/view_issueViewFoundation_ConfigureIssueFF.graphql';
import { getHrefWithBackLink } from '@atlassian/jira-project-settings-apps-common/src/backlink.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import getConfigurationHref from '../../../utils/get-configuration-href.tsx';
import messages from './messages.tsx';

type Props = {
	issueViewRelayFragment: view_issueViewFoundation_ConfigureIssueFF$key | null;
};

const ConfigureIssueFF = ({ issueViewRelayFragment }: Props) => {
	// We're in a non-tangerine folder structure, I can confirm this is a UI component and it isn't sharing state outside itself
	// eslint-disable-next-line @atlassian/relay/query-restriction
	const data = useFragment<view_issueViewFoundation_ConfigureIssueFF$key>(
		graphql`
			fragment view_issueViewFoundation_ConfigureIssueFF on JiraIssue {
				key
				configurationUrl
			}
		`,
		issueViewRelayFragment,
	);

	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { push } = useRouterActions();
	const oldHref = getConfigurationHref(formatMessage);
	const newHref = getHrefWithBackLink(
		data?.configurationUrl,
		formatMessage(messages.swiftBacklink, { issueKey: data?.key }),
	);
	const href = fg('jiv-replace-configuration-button-use-datafetcher') ? newHref : oldHref;

	useEffect(() => {
		if (oldHref !== newHref) {
			fireOperationalAnalytics(
				createAnalyticsEvent({}),
				'consistencyCheck failed',
				'configureIssue',
				{
					oldHref,
					newHref,
				},
			);
		}
	}, [oldHref, newHref, createAnalyticsEvent]);

	const onClick = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'dropdownItem',
		});
		fireUIAnalytics(analyticsEvent, 'configureIssue');
		href && push(href);
	}, [createAnalyticsEvent, push, href]);

	if (!href) {
		return null;
	}

	return (
		<DropdownItem href={href} key="configure" onClick={onClick} UNSAFE_shouldDisableRouterLink>
			{formatMessage(messages.configure)}
		</DropdownItem>
	);
};

export default ConfigureIssueFF;
