// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addParent: {
		id: 'issue.details.add-parent',
		defaultMessage: 'Add {target}',
		description: 'Label text for add parent breadcrumb in team-managed projects',
	},
	addParentLabel: {
		id: 'issue.details.add-parent-label',
		defaultMessage: 'Add parent',
		description: 'Label text for add parent breadcrumb in company-managed projects',
	},
	copyLink: {
		id: 'issue.details.copy-link-to-clipboard',
		defaultMessage: 'Copy link',
		description: 'Tooltip shown on hover',
	},
	rootProjectsLink: {
		id: 'issue.details.root-projects-link',
		defaultMessage: 'Projects',
		description: 'Link to the Browse Projects JIRA page',
	},
	breadcrumbNavLabel: {
		id: 'issue.details.breadcrumb-nav-label',
		defaultMessage: 'Issue breadcrumbs',
		description: 'Accessible label for the breadcrumb navigation',
	},
	copyIssueTermRefresh: {
		id: 'issue.details.copy-to-clipboard-issue-term-refresh',
		defaultMessage: 'Copy link to work item',
		description: 'Tooltip shown on hover(with issue term)',
	},
	breadcrumbNavLabelIssueTermRefresh: {
		id: 'issue.details.breadcrumb-nav-label-issue-term-refresh',
		defaultMessage: 'Work item breadcrumbs',
		description: 'Accessible label for the breadcrumb navigation',
	},
});
