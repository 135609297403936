import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	commentActions: {
		id: 'issue-comment-base.comment.comment-floating-menu.comment-actions',
		defaultMessage: 'Comment actions',
		description: 'Label for the comment actions menu',
	},
	createWorkItem: {
		id: 'issue-comment-base.comment.comment-floating-menu.create-work-item',
		defaultMessage: 'Create work item',
		description: 'Tooltip for the create work item button',
	},
	copy: {
		id: 'issue-comment-base.comment.comment-floating-menu.copy',
		defaultMessage: 'Copy link to comment',
		description: 'Tooltip for the copy button',
	},
	edit: {
		id: 'issue-comment-base.comment.comment-floating-menu.edit',
		defaultMessage: 'Edit',
		description: 'Tooltip for the edit button',
	},
	delete: {
		id: 'issue-comment-base.comment.comment-floating-menu.delete',
		defaultMessage: 'Delete',
		description: 'Tooltip for the delete button',
	},
});
