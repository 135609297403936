import React from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import CheckIcon from '@atlaskit/icon/core/migration/check-mark--check';
import { PopupSelect } from '@atlaskit/select';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';
import type { SecurityLevel } from '../../../common/types.tsx';
import messages from './messages.tsx';
import { RemoveItem } from './remove-item/index.tsx';
import type { Props } from './types.tsx';

const ItemSelectedIcon = () => (
	<ItemSelectedIconContainer>
		<CheckIcon spacing="spacious" label="" />
	</ItemSelectedIconContainer>
);

const formatGroupLabel = ({ label }: { label: string }) => (
	<Header data-testid="issue-field.security-level.ui.security-level-view.dropdown.test">
		{label}
	</Header>
);

const DESCRIPTION_MAX_LENGTH = 210;
const TITLE_MAX_LENGTH = 100;
const SECURITY_LEVEL_ITEM = 'item';
const SECURITY_LEVEL_REMOVE = 'remove';
const LARGE_SEARCH_THRESHOLD_TO_FORCE_HIDE_SEARCH_INPUT = Number.MAX_SAFE_INTEGER;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const limitText = (text: any, limit: number) => {
	const textLength = text.length;

	if (textLength > limit) {
		const smallText = text.substring(0, limit);

		return (
			<Tooltip content={text}>
				<span data-testid="issue-field.security-level.ui.security-level-view.dropdown.option">{`${smallText}...`}</span>
			</Tooltip>
		);
	}

	return text;
};

// @ts-expect-error - TS7031 - Binding element 'context' implicitly has an 'any' type.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const formatOptionLabel = (option: any, { context }) => {
	if (context === 'menu') {
		if (option.type === SECURITY_LEVEL_REMOVE) {
			return <RemoveItem />;
		}
		return (
			<ItemContainer data-testid="issue-field.security-level.ui.security-level-view.dropdown.item-container">
				<div>
					<ItemTitle>{limitText(option.item.name, TITLE_MAX_LENGTH)}</ItemTitle>
					{option.item.description ? (
						<ItemDescription>
							{limitText(option.item.description, DESCRIPTION_MAX_LENGTH)}
						</ItemDescription>
					) : null}
				</div>
				{option.selected ? <ItemSelectedIcon /> : null}
			</ItemContainer>
		);
	}
	return option.value;
};

// @ts-expect-error - TS7031 - Binding element 'hasValue' implicitly has an 'any' type.
const getCustomStyles = ({ hasValue }) => ({
	// @ts-expect-error - TS7006 - Parameter 'provided' implicitly has an 'any' type.
	container: (provided) => ({
		...provided,
		width: '240px',
		paddingBottom: '0',

		marginBottom: hasValue ? '46px' : '0',
	}),
	// @ts-expect-error - TS7006 - Parameter 'provided' implicitly has an 'any' type.
	menuList: (provided) => ({
		...provided,
		paddingBottom: hasValue ? '0' : token('space.100'),
	}),
	// @ts-expect-error - TS7006 - Parameter 'provided' implicitly has an 'any' type.
	group: (provided) => ({
		...provided,

		paddingBottom: '1px',
	}),
	// @ts-expect-error - TS7006 - Parameter 'provided' implicitly has an 'any' type.
	groupHeading: (provided) => ({
		...provided,
		font: token('font.body.UNSAFE_small'),

		marginBottom: '10px',
	}),
	// @ts-expect-error - TS7006 - Parameter 'provided' implicitly has an 'any' type. | TS7006 - Parameter 'state' implicitly has an 'any' type.
	option: (provided, state) => {
		if (state.data.selected) {
			return {
				...provided,
				backgroundColor: token('color.background.selected'),
			};
		}

		if (state.data.type === SECURITY_LEVEL_REMOVE) {
			return {
				'> *': {
					backgroundColor: state.isFocused
						? token('color.background.neutral.subtle.hovered')
						: token('color.background.neutral.subtle'),
				},
				height: '0',
				padding: '0',
			};
		}

		if (state.isFocused) {
			return {
				...provided,
				backgroundColor: token('color.background.neutral.subtle.hovered'),
			};
		}

		return provided;
	},
});

type Option = {
	item: SecurityLevel;
	type: 'item' | 'remove';
	selected?: boolean;
};

type Options = {
	options: Option[];
	label: string;
};

// @ts-expect-error - TS7031 - Binding element 'items' implicitly has an 'any' type. | TS7031 - Binding element 'value' implicitly has an 'any' type. | TS7031 - Binding element 'formatMessage' implicitly has an 'any' type.
const getOptions = ({ items, value, formatMessage }): Options[] => {
	const showRemove = !!value;
	const options: Option[] = [];

	// @ts-expect-error - TS7006 - Parameter 'item' implicitly has an 'any' type.
	items.forEach((item) => {
		const option: Option = {
			item,
			type: SECURITY_LEVEL_ITEM,
			selected: value && item ? item.id === value.id : false,
		};
		if (option.selected === true) {
			options.unshift(option);
		} else {
			options.push(option);
		}
	});

	if (showRemove) {
		options.push({
			item: null,
			type: SECURITY_LEVEL_REMOVE,
		});
	}
	return [
		{
			label: formatMessage(messages.setSecurityLevel),
			options,
		},
	];
};

const PopupSelectWithAnalyticsNew = AnalyticsEventToProps('dropdown', {
	onOpen: 'opened',
	onChange: 'clicked',
})(PopupSelect);

export const Dropdown = ({
	trigger,
	items,
	value,
	onChange,
	onChangeOpenState,
	isDropdownDefaultOpen = false,
}: Props) => {
	const intl = useIntl();
	const open = (analyticsEvent: UIAnalyticsEvent) => {
		onChangeOpenState(true, analyticsEvent);
	};
	// @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
	const close = () => onChangeOpenState(false);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onChangeHandler = (item: Option, _: any, analyticsEvent: UIAnalyticsEvent) => {
		onChange(item.item, null, analyticsEvent);
	};

	const options = getOptions({ items, value, formatMessage: intl.formatMessage });

	return (
		<PopupSelectWithAnalyticsNew
			testId="issue-field.security-level.ui.security-level-view.dropdown"
			formatGroupLabel={formatGroupLabel}
			formatOptionLabel={formatOptionLabel}
			popperProps={{
				placement: 'bottom-end',
				strategy: 'fixed',
			}}
			onChange={onChangeHandler}
			options={options}
			// @ts-expect-error - TS7031 - Binding element 'ref' implicitly has an 'any' type.
			target={({ ref }) => <div ref={ref}>{trigger}</div>}
			styles={getCustomStyles({ hasValue: !!value })}
			onClose={close}
			onOpen={open}
			isSearchable={false}
			searchThreshold={LARGE_SEARCH_THRESHOLD_TO_FORCE_HIDE_SEARCH_INPUT}
			defaultIsOpen={isDropdownDefaultOpen}
			label={options && options.length > 0 && options[0].label ? options[0].label : ''}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ItemContainer = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ItemTitle = styled.div({
	fontWeight: token('font.weight.medium'),
	whiteSpace: 'normal',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ItemDescription = styled.div({
	font: token('font.body.UNSAFE_small'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest'),
	marginTop: token('space.050'),
	marginRight: 0,
	marginBottom: token('space.050'),
	marginLeft: 0,
	maxWidth: '200px',
	whiteSpace: 'normal',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ItemSelectedIconContainer = styled.div({
	flexShrink: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.brand'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Header = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle'),
	font: token('font.body.small'),
	fontWeight: token('font.weight.semibold'),
});
