import React, { useEffect, useState, type PropsWithChildren } from 'react';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	createConfluencePageLinkSuccess,
	linkConfluencePageAISuggestionsClosed,
} from '@atlassian/jira-issue-view-store/src/actions/confluence-pages-actions.tsx';
import {
	issueIdSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import {
	linkedConfluencePagesPagesSelector,
	mentionedConfluencePagesPagesSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/confluence-content-selector.tsx';
import { confluenceAppLinksSelector } from '@atlassian/jira-issue-view-store/src/selectors/confluence-app-links-selector.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { ConfluenceSiteAri } from '@atlassian/ari/confluence';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import type { SiteSwitcherSelection } from '../common/types.tsx';
import {
	JiraIssueRelatedResourcesContainer,
	type ContainerProps,
} from './related-resources-context.tsx';

const RELATED_RESOURCES_SITE_SWITCHER_LOCAL_STORAGE_KEY = 'related-resources-site-switcher';

const storage = createLocalStorageProvider(RELATED_RESOURCES_SITE_SWITCHER_LOCAL_STORAGE_KEY);

const RelatedResourcesInternalContainer = ({
	children,
	...containerProps
}: PropsWithChildren<ContainerProps>) => {
	const [confluenceSiteAri, setConfluenceSiteAri] = useState<string | null>(
		storage.get(RELATED_RESOURCES_SITE_SWITCHER_LOCAL_STORAGE_KEY),
	);

	useEffect(() => {
		if (fg('jira-ai-related-resources-site-switcher')) {
			const storedValue: SiteSwitcherSelection = storage.get(
				RELATED_RESOURCES_SITE_SWITCHER_LOCAL_STORAGE_KEY,
			);
			if (storedValue && storedValue.value) {
				const newAri = ConfluenceSiteAri.create({ siteId: storedValue.value }).toString();
				if (!confluenceSiteAri || confluenceSiteAri !== newAri) {
					setConfluenceSiteAri(newAri);
				}
			}
		}
	}, [confluenceSiteAri]);

	return (
		<JiraIssueRelatedResourcesContainer
			scope={`related-resources-${containerProps.issueKey}`}
			{...containerProps}
			initialConfluenceSiteAri={confluenceSiteAri}
		>
			{children}
		</JiraIssueRelatedResourcesContainer>
	);
};

export const RelatedResourcesContainer = connect(
	(state) => ({
		issueId: issueIdSelector(state),
		issueKey: issueKeySelector(state),
		linkedConfluencePages: linkedConfluencePagesPagesSelector(state),
		mentionedConfluencePagesPages: mentionedConfluencePagesPagesSelector(state),
		confluenceAppLinks: confluenceAppLinksSelector(state),
	}),
	{
		onCloseAISuggestions: linkConfluencePageAISuggestionsClosed,
		onCreateConfluencePageLinkSuccess: createConfluencePageLinkSuccess,
	},
)(RelatedResourcesInternalContainer);
