// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createContainer,
	createHook,
	type Action,
} from '@atlassian/react-sweet-state';

export type GICPositioningEnabledState = {
	disableGICPositioning: boolean;
};

const initialState: GICPositioningEnabledState = {
	disableGICPositioning: false,
};

const actions = {
	setDisableGICPositioning:
		(disableGICPositioning: boolean): Action<GICPositioningEnabledState> =>
		({ setState }) => {
			setState({
				disableGICPositioning,
			});
		},
} as const;

type GICPositioningEnabledActions = typeof actions;

const Store = createStore<GICPositioningEnabledState, GICPositioningEnabledActions>({
	initialState,
	actions,
	name: 'GICPositioningEnabledController',
});

export const GICPositioningEnabledContainer = createContainer<
	GICPositioningEnabledState,
	GICPositioningEnabledActions,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Record<any, any>
>(Store);

export const useGICPositioningEnabledController = createHook(Store);
