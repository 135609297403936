import isEqual from 'lodash/isEqual';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { SharingSettings } from '@atlassian/jira-polaris-domain-view/src/sharing/settings/index.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import {
	isNotFoundError,
	isClientClosedError,
	isPermissionError,
} from '@atlassian/jira-polaris-lib-errors/src/controllers/utils.tsx';
import type { ViewRemote } from '@atlassian/jira-polaris-remote-view/src/types.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import {
	getCurrentViewSharingSettings,
	getIsCurrentViewSharingSettingLoading,
	getIsCurrentViewSharingSettingsInitialLoading,
	getPublicSharingControls,
} from '../../selectors/index.tsx';
import type { State, Props } from '../../types.tsx';
import { setSharingSettingsForView } from '../utils.tsx';

// this logic is needed when custom description field was removed,
// in such case we don't want to send this value to jpd-views-service to avoid 400 error
const sanitizeSettings = (settings: SharingSettings, fields: Field[]): Partial<SharingSettings> => {
	if (settings.ideaDescription.fieldKey) {
		const descriptionFieldExists = fields.some(
			({ key }) => key === settings.ideaDescription.fieldKey,
		);

		if (!descriptionFieldExists) {
			const { ideaDescription, ...rest } = settings;
			return rest;
		}
	}

	return settings;
};

// Rationale for this helper:
// We use a single endpoint for fetching the settings but two distinct ones
// to update them. Since updateSharingSettings action processes combined
// settings object we need to figure out which services to call based on
// the settings object properties diff.
const callSharingSettingsServices = async (
	viewRemote: ViewRemote,
	viewUUID: string,
	newSettings: Partial<SharingSettings>,
	oldSettings?: Partial<SharingSettings>,
) => {
	const { publicSharingEnabled: newValueOfPublicSharingEnabled, ...incomingSettings } = newSettings;
	const { publicSharingEnabled: oldValueOfPublicSharingEnabled, ...existingSettings } =
		oldSettings ?? {};

	const requestPromises = [];
	const result = {
		publicAccessChanged: false,
		publishedChanged: false,
	};

	if (
		newValueOfPublicSharingEnabled !== undefined &&
		newValueOfPublicSharingEnabled !== oldValueOfPublicSharingEnabled
	) {
		requestPromises.push(
			viewRemote.updatePublicSharingSettings(viewUUID, {
				enabled: newValueOfPublicSharingEnabled,
			}),
		);
		result.publicAccessChanged = true;
	}

	if (!isEqual(incomingSettings, existingSettings)) {
		requestPromises.push(viewRemote.updateSharingSettings(viewUUID, newSettings));
		result.publishedChanged = true;
	}

	await Promise.all(requestPromises);

	return result;
};

type SettingsUpdateOutcome =
	| 'PUBLIC_SHARING_DISABLED'
	| 'PUBLIC_SHARING_ENABLED'
	| 'PUBLIC_SHARING_UNCHANGED';

/**
 * Since public sharing is not an atomic action (we need to check 2 independent settings to establish if the view
 * is being shared publicly with the link) this function single reponsibility is to compare previous and next settings
 * on update and see if the view is becoming public, stops being public or the settings change does not impact
 * the view public sharing status.
 */
const getSettingsUpdateOutcome = async (
	prevSettings: SharingSettings | undefined,
	newSettings: Partial<SharingSettings>,
): Promise<SettingsUpdateOutcome> => {
	const wasSharedPublicly =
		prevSettings?.publicSharingEnabled === true && prevSettings?.sharingLinkEnabled === true;

	if (wasSharedPublicly) {
		const willStopBeingShared =
			newSettings.publicSharingEnabled === false || newSettings?.sharingLinkEnabled === false;

		if (willStopBeingShared) {
			return 'PUBLIC_SHARING_DISABLED';
		}
	} else {
		const combinedPublicSharingSettings = {
			publicSharingEnabled:
				newSettings.publicSharingEnabled ?? prevSettings?.publicSharingEnabled ?? false,
			sharingLinkEnabled:
				newSettings.sharingLinkEnabled ?? prevSettings?.sharingLinkEnabled ?? false,
		};
		const willBecomeSharedPublicly =
			combinedPublicSharingSettings.publicSharingEnabled === true &&
			combinedPublicSharingSettings.sharingLinkEnabled === true;

		if (willBecomeSharedPublicly) {
			return 'PUBLIC_SHARING_ENABLED';
		}
	}

	return 'PUBLIC_SHARING_UNCHANGED';
};

export const updateSharingSettings =
	(
		viewUUID: string,
		settings: SharingSettings,
	): Action<State, Props, Promise<{ publicSharingStatus: SettingsUpdateOutcome } | undefined>> =>
	async (
		{ dispatch, getState },
		{ viewRemote, onActionFailed, fields = [], createAnalyticsEvent },
	) => {
		const prevSettings = getCurrentViewSharingSettings(getState());
		const newSettings = sanitizeSettings(settings, fields);

		try {
			experience.sharing.updateSettings.start();

			// Optimistic update
			dispatch(setSharingSettingsForView(viewUUID, { settings }));

			const { publicAccessChanged, publishedChanged } = await callSharingSettingsServices(
				viewRemote,
				viewUUID,
				newSettings,
				prevSettings,
			);
			const isPublic = newSettings.publicSharingEnabled === true;
			const isPublished = newSettings.sharingLinkEnabled === true;

			if (publicAccessChanged) {
				const eventName = isPublic ? 'view becomesPublic' : 'view becomesNotPublic';
				fireTrackAnalytics(createAnalyticsEvent({}), eventName, { isPublished });
				sendPendoTrackEvent({ actionSubjectAndAction: eventName, attributes: { isPublished } });
			}

			if (publishedChanged) {
				const eventName = isPublished ? 'view published' : 'view unpublished';
				fireTrackAnalytics(createAnalyticsEvent({}), eventName, { isPublic });
				sendPendoTrackEvent({ actionSubjectAndAction: eventName, attributes: { isPublic } });
			}

			experience.sharing.updateSettings.success();
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			// Bringing back the old settings
			dispatch(setSharingSettingsForView(viewUUID, { settings: prevSettings }));

			if (isClientFetchError(error) || isPermissionError(error)) {
				experience.sharing.updateSettings.abort(error);
			} else {
				experience.sharing.updateSettings.failure(error);
			}

			fireErrorAnalytics(
				createErrorAnalytics('polaris.view-sharing.update-settings-action', error),
			);

			onActionFailed(error);
		}

		const publicSharingControls = getPublicSharingControls(getState());

		const isPublicSharingDisabledByGlobalConfig =
			publicSharingControls.status === 'disabled' &&
			publicSharingControls.reason === 'global-configuration';

		if (!isPublicSharingDisabledByGlobalConfig) {
			return { publicSharingStatus: await getSettingsUpdateOutcome(prevSettings, settings) };
		}
	};

export const loadSharingSettings =
	(viewUUID: string): Action<State, Props> =>
	async (
		{ getState, dispatch },
		{ isSharedView, onLoadFailed, onLoadViewComments, viewRemote },
	) => {
		const isSharingSettingsInitialLoading =
			getIsCurrentViewSharingSettingsInitialLoading(getState());
		const isSharingSettingsLoading = getIsCurrentViewSharingSettingLoading(getState());

		if (!isSharingSettingsInitialLoading && isSharingSettingsLoading) {
			return;
		}

		dispatch(setSharingSettingsForView(viewUUID, { isLoading: true }));

		try {
			experience.sharing.loadSettings.start();
			const settings = await viewRemote.fetchSharingSettings(viewUUID);
			dispatch(setSharingSettingsForView(viewUUID, { settings }));
			experience.sharing.loadSettings.success();

			if (isSharedView && settings.showViewComments) {
				onLoadViewComments?.();
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			if (
				isNotFoundError(error) ||
				isClientClosedError(error) ||
				isClientFetchError(error) ||
				isPermissionError(error)
			) {
				experience.sharing.loadSettings.abort(error);
			} else {
				experience.sharing.loadSettings.failure(error);
			}

			fireErrorAnalytics(createErrorAnalytics('polaris.view-sharing.load-settings-action', error));

			dispatch(
				setSharingSettingsForView(viewUUID, {
					settings: undefined,
					error,
				}),
			);

			if (isSharedView) {
				onLoadFailed(error);
			}
		}
	};
