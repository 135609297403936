import indexOf from 'lodash/indexOf';
import type {
	Filter,
	IntervalFieldFilter,
} from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { createGetIntervalFilter, getCurrentViewFilter } from '../../../selectors/filters.tsx';
import { isCurrentViewAutosaveEnabled } from '../../../selectors/view/autosave/index.tsx';
import type { Props, State } from '../../../types.tsx';
import { saveViewWithAutoSave } from '../../save/index.tsx';
import { updateViewState } from '../../utils/state/index.tsx';
import { currentViewFilter } from '../../utils/views/index.tsx';

const createNewFilters = (
	newFilter: IntervalFieldFilter,
	oldFilter: IntervalFieldFilter | undefined,
	viewFilters: Filter[],
): Filter[] => {
	const index = indexOf(viewFilters, oldFilter);
	if (index === -1) {
		return [...viewFilters, newFilter];
	}
	const newFilters = [...viewFilters];
	newFilters.splice(index, 1, newFilter);
	return newFilters;
};

export const updateIntervalFilter =
	(
		filter: IntervalFieldFilter,
		onSuccess?: () => void,
		onError?: (error: Error) => void,
	): Action<State, Props> =>
	async ({ getState, setState, dispatch }, props) => {
		const state = getState();
		const getIntervalFilter = createGetIntervalFilter(filter.field);
		const currentFilter = getCurrentViewFilter(state, props);

		const isAutosaveEnabled = isCurrentViewAutosaveEnabled(state, props);
		const newFilters = createNewFilters(filter, getIntervalFilter(state, props), currentFilter);

		const viewMutation = (view: View): View => {
			return {
				...view,
				filter: isAutosaveEnabled ? newFilters : view.filter,
				modified: isAutosaveEnabled,
				draft: {
					...view.draft,
					filter: newFilters,
				},
			};
		};

		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(props.currentViewSlug),
			viewMutation,
		);

		if (changedView) {
			setState({ viewSets });

			dispatch(
				saveViewWithAutoSave(changedView.id, (view: View | undefined) => {
					if (view && view.saveError) {
						onError?.(view.saveError);
					} else if (view && !view.saveError) {
						onSuccess?.();
					}
				}),
			);
		}
	};
