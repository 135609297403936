import type { IssueTypeId, ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createStateHook,
	createActionsHook,
	createSelector,
} from '@atlassian/react-sweet-state';
import type { IssueTypeFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/issue-type/types.tsx';
import { makeAvatarUrlFromId } from '../common/utils/make-avatar-url-from-id.tsx';
import { actions } from './actions/index.tsx';
import type { IssueType, State } from './types.tsx';

const EMPTY: IssueTypeId[] = [];

const initialState: State = {
	issueTypesById: {},
	issueTypesByProjectId: {},
};

const Store = createStore({
	initialState,
	actions,
	name: 'PolarisIssueTypesStore',
});

export const useIssueTypesActions = createActionsHook(Store);

const getAllIssueTypesById = (state: State) => state.issueTypesById;
const getAllIssueTypeIds = (state: State) => Object.keys(state.issueTypesById);
const getAllIssueTypeIdsByProjectId = (state: State) => state.issueTypesByProjectId;

export const useAllIssueTypesById = createStateHook(Store, {
	selector: getAllIssueTypesById,
});

const getProjectIds = createSelector(getAllIssueTypeIdsByProjectId, (issueTypesByProjectId) =>
	Object.keys(issueTypesByProjectId),
);

export const useIssueTypesProjectIds = createStateHook(Store, {
	selector: getProjectIds,
});

export const useAllIssueTypeIds = createStateHook(Store, {
	selector: getAllIssueTypeIds,
});

export const useAllIssueTypeIdsByProjectId = createStateHook(Store, {
	selector: getAllIssueTypeIdsByProjectId,
});

const getAllIssueTypesByProjectId = createSelector(
	getAllIssueTypeIdsByProjectId,
	getAllIssueTypesById,
	(issueTypesByProjectId, issueTypesById) => {
		return Object.keys(issueTypesByProjectId).reduce<Record<string, IssueType[] | undefined>>(
			(acc, projectId) => {
				acc[projectId] = issueTypesByProjectId[projectId]
					?.map((issueTypeId) => issueTypesById[issueTypeId])
					.filter(Boolean);
				return acc;
			},
			{},
		);
	},
);

export const useAllIssueTypesByProjectId = createStateHook(Store, {
	selector: getAllIssueTypesByProjectId,
});

const getIssueTypeIdsForProject = (
	state: State,
	{ projectId }: { projectId: ProjectId | undefined },
): IssueTypeId[] => {
	if (projectId === undefined) {
		return EMPTY;
	}
	return state.issueTypesByProjectId[projectId] || EMPTY;
};

const getIssueTypeIdsForProjectIds = (
	state: State,
	{ projectIds }: { projectIds: ProjectId[] },
): IssueTypeId[] => {
	return projectIds.flatMap((projectId) => state.issueTypesByProjectId[projectId] || EMPTY);
};

export const useIssueTypeIdsForProject = createStateHook(Store, {
	selector: getIssueTypeIdsForProject,
});

const getIssueTypesForProject = createSelector(
	getAllIssueTypesById,
	getIssueTypeIdsForProject,
	(issueTypesById, issueTypeIds) =>
		issueTypeIds.map((issueTypeId) => issueTypesById[issueTypeId]).filter(Boolean),
);

export const useIssueTypesForProject = createStateHook(Store, {
	selector: getIssueTypesForProject,
});

const getIssueTypesForProjectIds = createSelector(
	getAllIssueTypesById,
	getIssueTypeIdsForProjectIds,
	(issueTypesById, issueTypeIds) =>
		issueTypeIds.map((issueTypeId) => issueTypesById[issueTypeId]).filter(Boolean),
);

export const useIssueTypesForProjectIds = createStateHook(Store, {
	selector: getIssueTypesForProjectIds,
});

/**
 * @deprecated Use useIssueTypeIdsForProject instead, we can no longer
 * guarantee a single issue type per project
 */
export const useIssueTypeIdForProject = ({
	projectId,
}: {
	projectId: ProjectId | undefined;
}): IssueTypeId | undefined => {
	const issueTypeIds = useIssueTypeIdsForProject({ projectId });
	return issueTypeIds.length > 0 ? issueTypeIds[0] : undefined;
};

export const useIssueTypeExists = createStateHook(Store, {
	selector: (state, { issueTypeId }: { issueTypeId: IssueTypeId | undefined }) =>
		issueTypeId ? !!state.issueTypesById[issueTypeId] : false,
});

export const useIssueTypeName = createStateHook(Store, {
	selector: (state, { issueTypeId }: { issueTypeId: IssueTypeId }) =>
		state.issueTypesById[issueTypeId]?.name,
});

export const useIssueTypeDescription = createStateHook(Store, {
	selector: (state, { issueTypeId }: { issueTypeId: IssueTypeId }) =>
		state.issueTypesById[issueTypeId]?.description,
});

export const useIssueTypeAvatarId = createStateHook(Store, {
	selector: (state, { issueTypeId }: { issueTypeId: IssueTypeId }) =>
		state.issueTypesById[issueTypeId]?.avatarId,
});

export const useIsIssueTypeDeletable = createStateHook(Store, {
	selector: (state, { issueTypeId }: { issueTypeId: IssueTypeId }) =>
		state.issueTypesById[issueTypeId]?.operation.deletable === true,
});

export const useIsIssueTypeEditable = createStateHook(Store, {
	selector: (state, { issueTypeId }: { issueTypeId: IssueTypeId }) =>
		state.issueTypesById[issueTypeId]?.operation.editable === true,
});

const transformToFieldValue = ({ id, name, avatarId }: IssueType): IssueTypeFieldValue => ({
	id,
	name,
	iconUrl: makeAvatarUrlFromId(avatarId),
});

const getIssueTypeValuesForProject = createSelector(
	getIssueTypesForProject,
	(issueTypes): IssueTypeFieldValue[] => issueTypes.map(transformToFieldValue),
);

export const useIssueTypeValuesForProject = createStateHook(Store, {
	selector: getIssueTypeValuesForProject,
});

const getIssueTypeValuesForProjectIds = createSelector(
	getIssueTypesForProjectIds,
	(issueTypes): IssueTypeFieldValue[] => issueTypes.map(transformToFieldValue),
);

export const useIssueTypeValuesForProjectIds = createStateHook(Store, {
	selector: getIssueTypeValuesForProjectIds,
});

const getAllIssueTypeValues = createSelector(getAllIssueTypesById, (allIssueTypes) => {
	const issueTypeMap = new Map<string, IssueTypeFieldValue>();
	Object.values(allIssueTypes).forEach((issueType) => {
		issueType && issueTypeMap.set(issueType.id, transformToFieldValue(issueType));
	});
	return issueTypeMap;
});

export const useAllIssueTypeValues = createStateHook(Store, {
	selector: getAllIssueTypeValues,
});

const hasProjectCustomIssueTypes = createSelector(
	getIssueTypeIdsForProject,
	(issueTypeIds) => issueTypeIds.length > 1,
);

export const useHasProjectCustomIssueTypes = createStateHook(Store, {
	selector: hasProjectCustomIssueTypes,
});

const getIssueTypeByNameAndProjectIds = (
	state: State,
	{ name, projectIds }: { name?: string; projectIds?: ProjectId[] },
) => {
	if (!name || !projectIds) {
		return;
	}

	for (const projectId of projectIds) {
		const issueTypeId = state.issueTypesByProjectId[projectId]?.find(
			(id) => state.issueTypesById[id]?.name === name,
		);

		if (issueTypeId) {
			return state.issueTypesById[issueTypeId];
		}
	}
};

export const useIssueTypeByNameAndProjectIds = createStateHook(Store, {
	selector: getIssueTypeByNameAndProjectIds,
});
