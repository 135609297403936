import React, { useState, useMemo, useCallback } from 'react';
import { styled } from '@compiled/react';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import Link from '@atlaskit/link';
import Popup from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { SelectValueShape } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types.tsx';
import SingleSelectInlineEditView, {
	type Props as SingleSelectInlineEditViewProps,
} from '@atlassian/jira-issue-internal-field-select/src/single-select-inline-edit/index.tsx';
import type { IssueType } from '@atlassian/jira-issue-view-store/src/selectors/breadcrumbs-selector.tsx';
import {
	ContextualAnalyticsData,
	DROPDOWN,
	FireScreenAnalytics,
	fireTrackAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import SingleSelectFieldReadView, {
	singleSelectFieldRenderReadView,
} from '../../common/single-select-field-read-view/main.tsx';
import messages from './messages.tsx';

const ErrorIconWithPopup = () => {
	const { formatMessage } = useIntl();
	const [showPopup, setShowPopup] = useState(false);

	const learnMoreAboutIncompatibleRequestTypeLink =
		'https://support.atlassian.com/jira-service-management-cloud/docs/how-do-workflows-issue-types-and-request-types-connect/';

	const popupContent = (
		<InlinePopupContentControl>
			{formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.incompatibleRequestTypeWithHelpLinkIssueTermRefresh
					: messages.incompatibleRequestTypeWithHelpLink,
				{
					learnMore: (
						<Link rel="noreferrer" href={learnMoreAboutIncompatibleRequestTypeLink} target="_blank">
							{formatMessage(
								fg('jira-issue-terminology-refresh-m3')
									? messages.learnMoreAboutIncompatibleRequestTypeIssueTermRefresh
									: messages.learnMoreAboutIncompatibleRequestType,
							)}
						</Link>
					),
				},
			)}
		</InlinePopupContentControl>
	);
	return (
		<Popup
			isOpen={showPopup}
			onClose={() => setShowPopup(false)}
			placement="bottom-start"
			role="dialog"
			label={formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.incompatibleRequestTypeErrorIconLabelIssueTermRefresh
					: messages.incompatibleRequestTypeErrorIconLabel,
			)}
			content={() => popupContent}
			trigger={(triggerProps) => (
				<ErrorIconContainer
					{...triggerProps}
					role="button"
					onMouseEnter={() => {
						setShowPopup(!showPopup);
					}}
				>
					<ErrorIcon
						color={token('color.icon.danger')}
						LEGACY_size="medium"
						spacing="spacious"
						label={formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.incompatibleRequestTypeErrorIconLabelIssueTermRefresh
								: messages.incompatibleRequestTypeErrorIconLabel,
						)}
					/>
				</ErrorIconContainer>
			)}
		/>
	);
};

const singleSelectFieldRenderErrorView = ({ iconUrl, content }: SelectValueShape) => {
	if (!iconUrl) {
		return undefined;
	}

	return () => (
		<ErrorWrapper>
			<SingleSelectFieldReadView iconUrl={iconUrl} content={content} />
			<ErrorIconWithPopup />
		</ErrorWrapper>
	);
};

type Props = {
	issueType: IssueType;
} & SingleSelectInlineEditViewProps;

export const RequestTypeField = (props: Props) => {
	const { issueType, ...selectProps } = props;
	const { value, onChange } = props;

	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const isRequestTypeIncompatible = value != null && value && issueType.id !== value.issueTypeId;

	const valueForRequestType = useMemo(() => {
		if (!value) {
			return value;
		}
		return {
			...value,
			isInvalid: isRequestTypeIncompatible,
			noOptionsMessage: () =>
				formatMessage(
					fg('jira-issue-terminology-refresh-m3')
						? messages.noOptionsIssueTermRefresh
						: messages.noOptions,
					{
						issueType: issueType.name,
					},
				),
		};
	}, [isRequestTypeIncompatible, value, issueType.name, formatMessage]);

	const onChangeWithAnalytics = useCallback(
		(...args: Parameters<typeof onChange>) => {
			isRequestTypeIncompatible &&
				fireTrackAnalytics(createAnalyticsEvent({}), 'incompatibleRequestType updated');

			onChange(...args);
		},
		[createAnalyticsEvent, isRequestTypeIncompatible, onChange],
	);

	if (value?.hasError === true) {
		return null;
	}

	const convertedSelectProps: SingleSelectInlineEditViewProps = {
		...selectProps,
		value: valueForRequestType,
		onChange: onChangeWithAnalytics,
	};

	return (
		<>
			<SingleSelectInlineEditView
				{...convertedSelectProps}
				renderReadView={
					valueForRequestType?.isInvalid
						? singleSelectFieldRenderErrorView(valueForRequestType)
						: singleSelectFieldRenderReadView(valueForRequestType)
				}
			/>
			{valueForRequestType?.isInvalid && (
				<ContextualAnalyticsData sourceName="incompatibleRequestType" sourceType={DROPDOWN}>
					<FireScreenAnalytics />
				</ContextualAnalyticsData>
			)}
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorWrapper = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.danger'),
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorIconContainer = styled.div({
	marginLeft: token('space.100'),
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlinePopupContentControl = styled.p({
	font: token('font.body'),
	maxWidth: '300px',
	paddingTop: token('space.200'),
	paddingRight: token('space.200'),
	paddingBottom: token('space.200'),
	paddingLeft: token('space.200'),
});
