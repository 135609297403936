import type {
	ClipboardComponentMeta,
	ClipboardComponentType,
	ClipboardComponentValue,
	ClipboardParser,
} from './types.tsx';
import { ClipboardValidationError } from './clipboard-validation-error.tsx';

const isComponentType = <Type extends ClipboardComponentType>(
	meta: ClipboardComponentMeta,
	type: Type,
): meta is ClipboardComponentMeta<Type> => meta.componentType === type;
/**
 * Parser that returns the value prop from component metadata extracted from the Clipboard API if it matches the
 * `expectedComponentType`. If the type doesn't match then a `ClipboardValidationError` is thrown.
 */
export const getDefaultClipboardParser = <Type extends ClipboardComponentType>(
	expectedComponentType: Type,
): ClipboardParser<ClipboardComponentValue<Type>> => {
	return (meta: ClipboardComponentMeta): ClipboardComponentValue<Type> => {
		if (isComponentType(meta, expectedComponentType)) {
			return meta.value;
		}
		throw ClipboardValidationError.pasteUnexpectedComponentType(
			meta.componentType,
			expectedComponentType,
		);
	};
};
