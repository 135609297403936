/**
 * @generated SignedSource<<9f1fe88efcdd689af795eea1702d2986>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type relayLayoutItems_issueViewLayoutTemplatesLayoutItem_RelayLayoutItemFragmentContainer_fragmentKey$data = {
  readonly " $fragmentSpreads": FragmentRefs<"issueViewEntitlementField_servicedeskCustomerServiceCustomFields_EntitlementFieldProductSelector_fragmentKey" | "issueViewEntitlementField_servicedeskCustomerServiceEntitlementsField_EntitlementsField" | "issueViewOrganizationField_servicedeskCustomerServiceCustomFields_JCSOrganizationFieldInner" | "ui_businessGoalsIssueViewField_GoalsInlineEditViewField" | "ui_issueViewLayoutCascadingSelectField_IssueViewCascadingSelectField" | "ui_issueViewLayoutCheckboxSelectField_IssueViewCheckboxSelectField" | "ui_issueViewLayoutComponentsField_IssueViewComponentsField" | "ui_issueViewLayoutDateField_IssueViewDateField" | "ui_issueViewLayoutDateTimeField_IssueViewDateTimeField" | "ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField" | "ui_issueViewLayoutLabelsSystemField_IssueViewLabelsSystemField" | "ui_issueViewLayoutMultiGroupPicker_IssueViewMultiGroupPicker" | "ui_issueViewLayoutMultiUser_IssueViewMultiUser" | "ui_issueViewLayoutNumberField_IssueViewNumberField" | "ui_issueViewLayoutOriginalEstimateField_IssueViewOriginalEstimateField" | "ui_issueViewLayoutParentField_IssueViewParentField" | "ui_issueViewLayoutPeople_IssueViewPeople" | "ui_issueViewLayoutPriorityField_IssueViewPriorityField" | "ui_issueViewLayoutProjectField_IssueViewProjectField" | "ui_issueViewLayoutRadioSelectField_IssueViewRadioSelectField" | "ui_issueViewLayoutRichTextField_IssueViewRichTextField_fragmentKey" | "ui_issueViewLayoutSentimentField_IssueViewSentimentField" | "ui_issueViewLayoutSingleLineText_IssueViewSingleLineText" | "ui_issueViewLayoutSingleSelectField_IssueViewSingleSelectField" | "ui_issueViewLayoutSingleSelectUserPickerField_IssueViewSingleSelectUserPickerField_fragmentKey" | "ui_issueViewLayoutSprintField_IssueViewSprintField" | "ui_issueViewLayoutTeamField_IssueViewTeamField" | "ui_issueViewLayoutTimeTrackingField_IssueViewTimeTrackingField" | "ui_issueViewLayoutUrlField_IssueViewUrlField">;
  readonly " $fragmentType": "relayLayoutItems_issueViewLayoutTemplatesLayoutItem_RelayLayoutItemFragmentContainer_fragmentKey";
};
export type relayLayoutItems_issueViewLayoutTemplatesLayoutItem_RelayLayoutItemFragmentContainer_fragmentKey$key = {
  readonly " $data"?: relayLayoutItems_issueViewLayoutTemplatesLayoutItem_RelayLayoutItemFragmentContainer_fragmentKey$data;
  readonly " $fragmentSpreads": FragmentRefs<"relayLayoutItems_issueViewLayoutTemplatesLayoutItem_RelayLayoutItemFragmentContainer_fragmentKey">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscheckboxselectrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscomponentsrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsgrouppickerrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultigrouppickerrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultiuserparticipantsrequestparticipantsrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsoriginalestimaterelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsparent"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsradioselectrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsrichtextrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsteamrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldstimetrackingrelayprovider"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayAssigneeFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayDateFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayDateTimeFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayNumberFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayPeopleAndApproversFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayPriorityFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayProjectFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelaySingleLineTextFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelaySprintFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayUrlFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "jcsFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "jsmSentimentCustomFieldFlag"
    }
  ],
  "kind": "Fragment",
  "name": "relayLayoutItems_issueViewLayoutTemplatesLayoutItem_RelayLayoutItemFragmentContainer_fragmentKey",
  "selections": [
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsradioselectrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutRadioSelectField_IssueViewRadioSelectField"
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsrichtextrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutRichTextField_IssueViewRichTextField_fragmentKey"
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsteamrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutTeamField_IssueViewTeamField"
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultigrouppickerrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutMultiGroupPicker_IssueViewMultiGroupPicker"
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsgrouppickerrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField"
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultiuserparticipantsrequestparticipantsrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutMultiUser_IssueViewMultiUser"
        }
      ]
    },
    {
      "condition": "issueViewRelayPeopleAndApproversFieldFlag",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutPeople_IssueViewPeople"
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldstimetrackingrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutTimeTrackingField_IssueViewTimeTrackingField"
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscheckboxselectrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutCheckboxSelectField_IssueViewCheckboxSelectField"
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscomponentsrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutComponentsField_IssueViewComponentsField"
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsoriginalestimaterelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutOriginalEstimateField_IssueViewOriginalEstimateField"
        }
      ]
    },
    {
      "condition": "issueViewRelayProjectFieldFlag",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutProjectField_IssueViewProjectField"
        }
      ]
    },
    {
      "condition": "issueViewRelayUrlFieldFlag",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutUrlField_IssueViewUrlField"
        }
      ]
    },
    {
      "condition": "issueViewRelaySingleLineTextFieldFlag",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutSingleLineText_IssueViewSingleLineText"
        }
      ]
    },
    {
      "condition": "issueViewRelayAssigneeFieldFlag",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "jcsFlag",
              "variableName": "jcsFlag"
            }
          ],
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutSingleSelectUserPickerField_IssueViewSingleSelectUserPickerField_fragmentKey"
        }
      ]
    },
    {
      "condition": "issueViewRelayDateFieldFlag",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutDateField_IssueViewDateField"
        }
      ]
    },
    {
      "condition": "issueViewRelayDateTimeFieldFlag",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutDateTimeField_IssueViewDateTimeField"
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutLabelsSystemField_IssueViewLabelsSystemField"
    },
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutSingleSelectField_IssueViewSingleSelectField"
    },
    {
      "condition": "jsmSentimentCustomFieldFlag",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutSentimentField_IssueViewSentimentField"
        }
      ]
    },
    {
      "condition": "issueViewRelayNumberFieldFlag",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutNumberField_IssueViewNumberField"
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutCascadingSelectField_IssueViewCascadingSelectField"
    },
    {
      "kind": "FragmentSpread",
      "name": "issueViewEntitlementField_servicedeskCustomerServiceEntitlementsField_EntitlementsField"
    },
    {
      "kind": "FragmentSpread",
      "name": "ui_businessGoalsIssueViewField_GoalsInlineEditViewField"
    },
    {
      "condition": "issueViewRelayPriorityFieldFlag",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutPriorityField_IssueViewPriorityField"
        }
      ]
    },
    {
      "condition": "issueViewRelaySprintFieldFlag",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutSprintField_IssueViewSprintField"
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsparent",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutParentField_IssueViewParentField"
        }
      ]
    },
    {
      "condition": "jcsFlag",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "issueViewEntitlementField_servicedeskCustomerServiceCustomFields_EntitlementFieldProductSelector_fragmentKey"
        },
        {
          "kind": "FragmentSpread",
          "name": "issueViewOrganizationField_servicedeskCustomerServiceCustomFields_JCSOrganizationFieldInner"
        }
      ]
    }
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};

(node as any).hash = "147afcc4c497c835228b0d2c255e0843";

export default node;
