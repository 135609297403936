import React, { useCallback, useMemo } from 'react';
import { useFragment, graphql } from 'react-relay';
import { Box } from '@atlaskit/primitives';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import type { field_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelFieldView$key } from '@atlassian/jira-relay/src/__generated__/field_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelFieldView.graphql';
import type { AggJiraSecurityLevel } from '../../common/types.tsx';
import { SecurityLevelInlineEdit } from './inline-edit/index.tsx';

const testId = 'issue.views.issue-base.foundation.header.header-actions.security-level-icon';

export interface IssueViewSecurityLevelFieldProps {
	securityLevelField: field_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelFieldView$key;
}

export const IssueViewSecurityLevelFieldView = ({
	securityLevelField,
}: IssueViewSecurityLevelFieldProps) => {
	const issueId = useIssueId();
	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();

	const data =
		useFragment<field_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelFieldView$key>(
			graphql`
				fragment field_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelFieldView on JiraIssue {
					securityLevelField {
						...inlineEdit_issueViewLayoutSecurityLevelField_SecurityLevelInlineEdit
						fieldId
						type
						__typename
					}
				}
			`,
			securityLevelField,
		);

	const fieldData = useMemo(
		() =>
			data?.securityLevelField?.__typename === 'JiraSecurityLevelField'
				? data?.securityLevelField
				: // won't ever get here but adding stuff to ease TS
					({
						fieldId: '',
						type: '',
						__typename: 'JiraSecurityLevelField',
					} as const),
		[data?.securityLevelField],
	);

	const onSubmit = useCallback(
		(value: AggJiraSecurityLevel) => {
			issueId &&
				fieldChangeRequested(issueId, fieldData?.fieldId, value, undefined, {
					type: fieldData?.type,
					__typename: fieldData?.__typename,
				});
		},
		[fieldData?.__typename, fieldData?.fieldId, fieldData?.type, fieldChangeRequested, issueId],
	);

	const onSubmitSucceeded = useCallback(
		(value: AggJiraSecurityLevel) => {
			issueId &&
				fieldChanged(issueId, fieldData?.fieldId, value, {
					type: fieldData?.type,
					__typename: fieldData?.__typename,
				});
		},
		[fieldData?.__typename, fieldData?.fieldId, fieldData?.type, fieldChanged, issueId],
	);

	const onSubmitFailed = useCallback(
		() => issueId && fieldChangeFailed(issueId, fieldData?.fieldId),
		[fieldData?.fieldId, fieldChangeFailed, issueId],
	);

	if (data?.securityLevelField?.__typename !== 'JiraSecurityLevelField') {
		return null;
	}
	return (
		<Box testId={testId}>
			<SecurityLevelInlineEdit
				securityLevelField={data.securityLevelField}
				onSubmit={onSubmit}
				onSubmitSucceeded={onSubmitSucceeded}
				onSubmitFailed={onSubmitFailed}
			/>
		</Box>
	);
};
