import React, { useState, useEffect, useCallback, useRef } from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import isNil from 'lodash/isNil';
import { v4 as uuid } from 'uuid';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { ErrorFlag } from '@atlassian/jira-issue-error-flag/src/index.tsx';
import { useCreateTeamDialog } from '@atlassian/jira-issue-field-create-team-dialog/src/ui/use-create-team-dialog/index.tsx';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import { TeamEditViewNew } from '@atlassian/jira-issue-field-team-editview-full/src/ui/team/index.tsx';
import type { TeamEditViewProps } from '@atlassian/jira-issue-field-team-editview-full/src/ui/team/types.tsx';
import {
	fireUIAnalytics,
	fireOperationalAnalytics,
	fireTrackAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type { TeamValue } from '../common/types.tsx';
import messages from '../messages.tsx';
import { useTeamField as defaultUseTeamField } from '../services/index.tsx';
import { TeamPickerEdit } from './edit-mode/index.tsx';
import type { Props } from './types.tsx';
import { TeamPickerView } from './view-mode/index.tsx';

export const actionSubject = 'teamInlineEdit';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: Props) => {
	const {
		fieldKey,
		issueKey,
		issueId,
		onCancel,
		onEdit,
		onEscape,
		onConfirm,
		onEnter,
		onUpdate,
		readView,
		editView,
		// We must overwrite the default placeholder from the user picker to have a consistent message for read and edit mode
		noValueText = 'DEFAULT PLACEHOLDER MESSAGE', // TODO: need to intl
		useTeamField = defaultUseTeamField,
		isCompact,
		menuPortalTarget,
		fixedMarginTop,
		...rest
	} = props;

	const tenantContext = useTenantContext();
	const siteId = tenantContext.cloudId;
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isAssigningNewlyCreatedTeam, setIsAssigningNewlyCreatedTeam] = useState(false);

	const onFailure = useCallback(
		(err: Error) => {
			fireOperationalAnalytics(createAnalyticsEvent({}), 'field updateFailed', 'teamIssueField', {
				isAssigningNewlyCreatedTeam,
				error: err,
			});
			setIsAssigningNewlyCreatedTeam(false);
		},
		[createAnalyticsEvent, isAssigningNewlyCreatedTeam],
	);

	const onSuccess = useCallback(
		(newValue: TeamValue) => {
			setIsAssigningNewlyCreatedTeam(false);
			if (onUpdate) {
				onUpdate(newValue);
			}
		},
		[onUpdate],
	);

	const [{ value, error, fieldConfig }, { saveValue, resetError }] = useTeamField({
		issueKey: toIssueKey(issueKey),
		fieldKey,
		onSuccess,
		onFailure,
	});

	const [isEditing, setIsEditing] = useState<boolean>(false);
	const updatedValue = useRef<TeamValue>(value);

	const isFieldEditable = fieldConfig?.isEditable ?? false;
	const isValueEmpty = isNil(updatedValue.current);

	const sessionId = useRef<string>(uuid());

	const onCreate = useCallback(
		(team: TeamValue) => {
			if (team && team?.id !== value?.id) {
				fireTrackAnalytics(
					createAnalyticsEvent({}),
					'createTeamInline succeeded',
					'teamIssueField',
					{
						memberCount: team.memberCount,
					},
				);
				// required wait for new created team to be available
				setTimeout(() => {
					setIsAssigningNewlyCreatedTeam(true);
					saveValue(
						team,
						null,
						createAnalyticsEvent({
							action: 'succeeded',
							actionSubject: 'createTeamInline',
							attributes: {
								memberCount: team.memberCount,
							},
						}),
					);
					setIsEditing(false);
				}, 1000);
			}
		},
		[createAnalyticsEvent, saveValue, value?.id],
	);

	const [createTeamDialog, createTeamDialogTrigger] = useCreateTeamDialog({
		onCreate,
	});

	useEffect(() => {
		if (error) {
			setIsEditing(true);
		}
		if (!isEditing) {
			updatedValue.current = value;
		}
	}, [error, value, isEditing]);

	const save = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			// Needed when assigning team failed, otherwise it would stuck in a loop and cannot be closed.
			resetError();
			if (updatedValue.current?.id !== value?.id) {
				saveValue(updatedValue.current, null, analyticsEvent);
			}
			setIsEditing(false);
			fireUIAnalytics(analyticsEvent);
		},
		[resetError, saveValue, value?.id],
	);

	const cancel = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			resetError();
			setIsEditing(false);
			updatedValue.current = value;
			fireUIAnalytics(analyticsEvent);
		},
		[resetError, value],
	);

	const onCancelRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			cancel(analyticsEvent);
			onCancel && onCancel(analyticsEvent);
		},
		[cancel, onCancel],
	);

	const onConfirmRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			save(analyticsEvent);
			onConfirm && onConfirm(analyticsEvent);
		},
		[onConfirm, save],
	);

	const onEditRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			setIsEditing(true);
			fireUIAnalytics(analyticsEvent);
			onEdit && onEdit(analyticsEvent);
		},
		[onEdit],
	);

	const onEscapeRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			cancel(analyticsEvent);
			onEscape && onEscape(analyticsEvent);
		},
		[cancel, onEscape],
	);

	const onChange = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'val' implicitly has an 'any' type.
		(val) => {
			resetError();
			updatedValue.current = val;
		},
		[resetError],
	);

	const getEditViewProps = (): TeamEditViewProps => ({
		fieldId: fieldKey,
		autoFocus: true,
		value: value || null,
		onChange,
		isDropdownMenuFixedAndLayered: false,
		menuPosition: 'absolute',
		sessionId: sessionId?.current,
		appearance: isCompact ? 'compact' : 'normal',
		menuPortalTarget,
	});

	const renderDefaultEditView = () =>
		fg('relay-migration-issue-fields-team-field-next') ? (
			<TeamEditViewNew {...getEditViewProps()} />
		) : (
			<TeamPickerEdit
				defaultValue={updatedValue.current}
				siteId={siteId}
				onChange={onChange}
				autoFocus
				noValueText={noValueText}
				createTeamDialogTrigger={createTeamDialogTrigger}
			/>
		);

	const renderEditView = () => (editView !== undefined ? editView : renderDefaultEditView());

	const renderReadView = () =>
		readView !== undefined ? (
			readView
		) : (
			<TeamPickerView
				defaultValue={updatedValue.current}
				placeholder={noValueText}
				isEditable={isFieldEditable}
			/>
		);

	const renderErrorFlag = () => {
		if (!error) return null;

		return (
			<ErrorFlag error={error} title={messages.errorTitle} description={messages.errorMessage} />
		);
	};

	return (
		<>
			{error && renderErrorFlag()}
			<InlineEditWrapper
				isFieldEditable={isFieldEditable}
				isEditing={isEditing}
				fixedHeight={false}
				fixedMarginTop={fixedMarginTop}
				isEmpty={readView === undefined && isValueEmpty}
			>
				<FieldInlineEditStateLess
					testId="issue-field-team.ui.view"
					fieldId={fg('one_event_rules_them_all_fg') ? fieldKey : undefined}
					isEditable={isFieldEditable}
					isEditing={isEditing}
					areActionButtonsHidden
					isFitContainerWidthReadView
					readView={renderReadView()}
					editView={isFieldEditable ? renderEditView() : null}
					onEdit={onEditRequest}
					shouldConfirmOnEnter
					actionSubject={actionSubject}
					onCancel={onCancelRequest}
					onConfirm={onConfirmRequest}
					onEscape={onEscapeRequest}
					{...rest}
				/>
			</InlineEditWrapper>
			<ErrorBoundary id="issue-field-team-create-dialog">{createTeamDialog}</ErrorBoundary>
		</>
	);
};

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line  @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InlineEditWrapper = styled.div<{
	isFieldEditable: boolean;
	isEmpty: boolean;
	isEditing: boolean;
	fixedHeight: boolean;
	fixedMarginTop?: boolean;
}>`
	margin-left: ${token('space.negative.100')};
	margin-right: ${token('space.100')};
	margin-top: ${
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(props: any) => {
			if (props.isFieldEditable || props.fixedMarginTop) {
				return token('space.negative.100');
			}
			if (props.isEmpty) {
				return '-13px';
			}
			return token('space.negative.150');
		}
	};
	${
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(props: any) => (!props.isEditing && props.fixedHeight ? `height: ${gridSize * 4}px` : '')
	};
`;
