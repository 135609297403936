import { useCallback } from 'react';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import { getBlankSpaceBlueprintId } from '../services/confluence-blueprint.tsx';
import { createConfluenceDraft } from '../services/confluence-create-draft.tsx';
import {
	createSpace,
	generateSpaceKey,
	hasSpaceWithProjectName,
} from '../services/confluence-spaces.tsx';
import type { ConfluenceTemplate } from './confluence-templates.tsx';

export const useSpaceCreation = (
	cloudId: string,
	projectName: string,
	template: ConfluenceTemplate | undefined,
) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	// this needs to be a hook due to the useAnalyticsEvents
	return useCallback(async () => {
		if (!template) return;

		const space = await createSpaceForProjectName(projectName || '');
		const spaceKey = space?.key;
		if (!spaceKey) throw new Error('No space key available');

		if (space?.spaceCreated) {
			fireTrackAnalytics(createAnalyticsEvent({}), 'spaceWithProjectName created', {
				flagKey: 'grw1117_jsw_pt_crossjoin',
			});
		}

		const result = await createConfluenceDraft(
			cloudId,
			spaceKey,
			template.blueprintModuleCompleteKey,
		);

		if (!result) throw new Error('Failed to create draft');

		const pageUrl = `${globalThis.window.location.origin}/wiki/spaces/${spaceKey}/pages/edit-v2/${result.contentId}/?draftShareId=${result.draftShareId}`;

		return pageUrl;
	}, [cloudId, projectName, template, createAnalyticsEvent]);
};

export const createSpaceForProjectName = async (projectName: string) => {
	if (!projectName) return;

	const key = await hasSpaceWithProjectName(projectName);

	if (key) {
		return { key, spaceCreated: false };
	}

	const spaceKey = spaceNameToSpaceKey(projectName);

	if (!spaceKey) throw new Error('Failed to find space key');

	const [blankSpaceBlueprintId, generatedSpaceKey] = await Promise.all([
		getBlankSpaceBlueprintId(),
		generateSpaceKey(spaceKey),
	]);

	if (!generatedSpaceKey || !blankSpaceBlueprintId) throw new Error('Failed to generate space key');

	const data = await createSpace({
		spaceName: projectName,
		spaceKey: generatedSpaceKey,
		spaceBlueprintId: blankSpaceBlueprintId,
	});

	const updatedData = {
		...data,
		spaceCreated: true,
	};

	return updatedData;
};

// 'Knowledge base' becomes 'KB', and 'Legal' becomes 'LEGAL', etc., non-alphanumeric strings will have a random key generated
export function spaceNameToSpaceKey(name?: string | null): string | null {
	if (!name) return null;
	const words = name
		.split(' ')
		.map((word) => word.replace(/[^a-zA-Z0-9]+/g, ''))
		.filter((word) => word);

	let key;
	if (words.length > 1) {
		const initials = words.map((word) => word[0]);
		key = initials.join('').toUpperCase();
	} else {
		key = words[0];
	}

	if (!key) {
		if (fg('placeholder_template_i18n_killswitch')) {
			const RANDOM_KEY_CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
			const generateRandomKey = () => {
				return Array.from({ length: 10 })
					.map(() => RANDOM_KEY_CHARS.charAt(Math.floor(Math.random() * RANDOM_KEY_CHARS.length)))
					.join('');
			};

			key = generateRandomKey();
		} else {
			return null;
		}
	}

	return key.length > 10 ? key.substring(0, 10) : key;
}
