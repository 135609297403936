import React, { type PropsWithChildren, useRef, useState, type ReactElement } from 'react';
import { styled } from '@compiled/react';
import { SpotlightPulse, SpotlightCard } from '@atlaskit/onboarding';
import { token } from '@atlaskit/tokens';
import { borderRadius, layers } from '@atlassian/jira-common-styles/src/main.tsx';
import CoordinationClient from '@atlassian/jira-engagement/src/ui/coordination-client/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { smoothScrollIntoViewIfNeeded } from '@atlassian/jira-issue-view-common-utils/src/scroll/index.tsx';
import messages from './messages.tsx';

export const CONTEXT_GROUP_CHANGE_BOARDING_MESSAGE_ID = 'context-group-forge-apps-changeboarding';

type StopFunction = () => void;
type CoordinationStopProviderProps = {
	stop?: StopFunction;
	children: (stop: StopFunction | undefined) => ReactElement;
};
export const CoordinationStopProvider = ({ stop, children }: CoordinationStopProviderProps) =>
	children(stop);

export const ChangeBoarding = ({ children }: PropsWithChildren) => {
	const { formatMessage } = useIntl();
	const spotlightPulseRef = useRef<HTMLDivElement>(null);
	const [showSpotlightCard, setShowSpotlightCard] = useState<boolean>(true);
	const [showSpotlightPulse, setShowSpotlightPulse] = useState<boolean>(false);
	const onSpotlightPulseClick = () => {
		setShowSpotlightPulse(false);
	};

	const renderSpotlightCard = (stop?: StopFunction) => {
		const hideSpotlight = () => {
			setShowSpotlightCard(false);
			stop?.();
		};
		const onShowMeClick = () => {
			hideSpotlight();
			setShowSpotlightPulse(true);
			smoothScrollIntoViewIfNeeded(spotlightPulseRef.current, {
				scrollMode: 'always',
				behavior: 'smooth',
			});
		};

		return (
			<SpotlightCardContainer>
				<SpotlightCard
					heading={formatMessage(messages.heading)}
					actions={[
						{ text: formatMessage(messages.showMe), onClick: onShowMeClick },
						{
							text: formatMessage(messages.close),
							onClick: hideSpotlight,
							appearance: 'subtle',
						},
					]}
					width={275}
				>
					{formatMessage(messages.body)}
				</SpotlightCard>
			</SpotlightCardContainer>
		);
	};

	return (
		<div ref={spotlightPulseRef}>
			<SpotlightPulse
				radius={borderRadius}
				pulse={showSpotlightPulse}
				onClick={onSpotlightPulseClick}
			>
				{children}
			</SpotlightPulse>
			{showSpotlightCard && (
				<CoordinationClient messageId={CONTEXT_GROUP_CHANGE_BOARDING_MESSAGE_ID}>
					<CoordinationStopProvider>{renderSpotlightCard}</CoordinationStopProvider>
				</CoordinationClient>
			)}
		</div>
	);
};

export default ChangeBoarding;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const SpotlightCardContainer = styled.div({
	position: 'absolute',
	right: token('space.400'),
	top: token('space.300'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: layers.dialog,
});
