import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { Label } from '../label/index.tsx';
import type { LayoutProps } from '../types.tsx';
import { ValueContainer } from '../value-container/index.tsx';
import { WithPopup } from '../with-popup/index.tsx';

/**
 * DefaultLayout refers to issue view layout
 */
export const DefaultLayout = ({ id, label, isInline, children, popup, testId }: LayoutProps) => (
	<Box xcss={wrapperStyles} testId={testId}>
		<Box
			xcss={[labelStyles, fg('jcs_master_flag') && labelStylesNew, isInline && inlineLabelStyles]}
		>
			<Label htmlFor={id}>{label}</Label>
		</Box>
		<Box xcss={[fieldStyles, isInline && inlineFieldStyles]}>
			<WithPopup popup={popup}>
				{children({
					getValueContainer: () => ValueContainer,
				})}
			</WithPopup>
		</Box>
	</Box>
);

const wrapperStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	flexWrap: 'wrap',
});

const labelStyles = xcss({
	paddingTop: 'space.100',
	width: '37%',
	minWidth: '90pt',
	paddingInlineEnd: 'space.150',
	flexGrow: 1,
});

// Replace width in labelStyles once FG is merged
const labelStylesNew = xcss({
	width: '40%',
});

const inlineLabelStyles = xcss({
	maxWidth: '170px',
	paddingBottom: 'space.100',
});

const fieldStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body'),
	paddingTop: 'space.0',
	paddingBottom: 'space.0',
	paddingLeft: 'space.0',
	paddingRight: 'space.0',
	flexGrow: 1,
	width: 'calc(60% + 16px)',
	marginInline: 'space.negative.100', // 6px padding + 2px border
});

const inlineFieldStyles = xcss({
	paddingTop: 'space.025',
	paddingBottom: 'space.025',
	paddingRight: 'space.100',
});
