import type { Environment } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import type { Product } from '../../utils/index.tsx';
import {
	RECAPTCHA_SITE_KEY_DEV,
	RECAPTCHA_SITE_KEY_PROD,
	RECAPTCHA_SITE_KEY_STAGING,
} from './constants.tsx';
import type { BulkInviteSuccessResponse, InviteApiSuccessResponse } from './types.tsx';

export const getRecaptchaSiteKey = (environment: Environment) => {
	switch (environment) {
		case 'prod':
			return RECAPTCHA_SITE_KEY_PROD;
		case 'staging':
			return RECAPTCHA_SITE_KEY_STAGING;
		default:
			return RECAPTCHA_SITE_KEY_DEV;
	}
};

export const handleInviteUsersSuccessResponse = (
	response: InviteApiSuccessResponse[],
): BulkInviteSuccessResponse =>
	response.reduce<BulkInviteSuccessResponse>(
		(accumulator, userResponse) => {
			const user = {
				id: userResponse.id,
				email: userResponse.email,
			};

			Object.keys(userResponse.results).forEach((resource) => {
				switch (userResponse.results[resource]) {
					case 'INVITED':
					case 'USER_EXISTS':
						accumulator.invited.push({
							...user,
							results: userResponse.results,
						});
						break;
					case 'INVITED_PENDING_APPROVAL':
					case 'PENDING_INVITE_EXISTS':
						accumulator.accessRequested.push({
							...user,
							results: userResponse.results,
						});
						break;
					case 'NOT_INVITED':
					case 'ERROR':
						accumulator.error.push({
							...user,
							error: userResponse.errorReasons[resource],
						});
						break;
					default:
						throw new Error('Unexpected return type from invite user response');
				}
			});
			return accumulator;
		},
		{
			invited: [],
			accessRequested: [],
			error: [],
		},
	);

export const getOriginProduct = (product: Product) => {
	switch (product) {
		default:
			return product;
	}
};
