import React, { useCallback, useMemo, useState, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { JiraInlineDialog as InlineDialog } from '@atlassian/jira-inline-dialog/src/ui/jira-inline-dialog.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { Link } from '@atlassian/react-resource-router';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const ClosedSprintsView = (props: Props) => {
	const { shouldHideLinks = false, closedSprints } = props;

	const { formatMessage } = useIntl();
	const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

	const handleButtonClick = useCallback(
		(e: SyntheticEvent<HTMLElement>) => {
			setIsDialogOpen(!isDialogOpen);
			e.stopPropagation();
		},
		[isDialogOpen],
	);

	const handleDialogClose = useCallback((data: { isOpen: boolean; event: Event }) => {
		setIsDialogOpen(data.isOpen);
	}, []);

	const handleDialogClick = useCallback((e: SyntheticEvent<HTMLDivElement>) => {
		e.stopPropagation();
	}, []);

	const handleDialogMouseDown = useCallback((e: SyntheticEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);

	const closedSprintsLabel = useMemo(() => {
		const numberOfClosedSprints = closedSprints.length;
		return numberOfClosedSprints > 0
			? formatMessage(messages.closedSprintsLabel, {
					count: numberOfClosedSprints,
				})
			: null;
	}, [closedSprints, formatMessage]);

	const renderInlineDialogContent = () => (
		<div data-testid="issue-field-sprint-readview-full.ui.sprint.closed-sprints.view-closed-sprint-inline-dialog-content-container">
			{isVisualRefreshEnabled() ? (
				<Text weight="medium">{closedSprintsLabel}</Text>
			) : (
				<Title>{closedSprintsLabel}</Title>
			)}
			{closedSprints.map((sprint) => (
				<ItemContainer
					key={sprint.name}
					data-testid={`issue-field-sprint-readview-full.ui.sprint.closed-sprints.closed-sprint-item-${sprint.id}`}
				>
					{shouldHideLinks ? (
						<NameSpan>{sprint.name}</NameSpan>
					) : (
						<NameLinkContainer>
							<Link href={sprint.href}>{sprint.name}</Link>
						</NameLinkContainer>
					)}
					<NoWrapDate>{sprint.endDate}</NoWrapDate>
				</ItemContainer>
			))}
		</div>
	);

	return (
		<Container onClick={handleDialogClick} onMouseDown={handleDialogMouseDown}>
			<InlineDialog
				content={renderInlineDialogContent()}
				isOpen={isDialogOpen}
				onClose={handleDialogClose}
				placement="bottom"
				messageId="issue-field-sprint-readview-full.ui.sprint.closed-sprints.inline-dialog"
				messageType="transactional"
			>
				<Button
					onClick={handleButtonClick}
					spacing="none"
					isSelected={isDialogOpen}
					testId="issue-field-sprint-readview-full.ui.sprint.closed-sprints.button"
					aria-expanded={isDialogOpen}
				>
					<ClosedSprintsCount>{`+${closedSprints.length}`}</ClosedSprintsCount>
				</Button>
			</InlineDialog>
		</Container>
	);
};

export default ClosedSprintsView;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const Container = styled.div({
	maxWidth: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ClosedSprintsCount = styled.span({
	paddingTop: token('space.050'),
	paddingRight: token('space.050'),
	paddingBottom: token('space.050'),
	paddingLeft: token('space.050'),
	fontWeight: token('font.weight.regular'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ItemContainer = styled.div({
	marginTop: token('space.100'),
	display: 'flex',
	justifyContent: 'space-between',
	maxWidth: '300px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Title = styled.span({
	fontWeight: token('font.weight.medium'),
});

const nameStyles = `
    margin-right: ${token('space.300')};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const NameLinkContainer = styled.div(nameStyles);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const NameSpan = styled.span(nameStyles);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NoWrapDate = styled.div({
	whiteSpace: 'nowrap',
});
