import React from 'react';
import type { Dispatch } from 'redux';
import memoizeOne from 'memoize-one';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import componentWithCondition from '@atlassian/jira-common-util-component-with-condition/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import CommentVisibilitySelect from '@atlassian/jira-issue-comment-base/src/ui/comment/comment-visibility/index.tsx';
import { EventOccurredAtSelector } from '@atlassian/jira-issue-comment-base/src/ui/comment/event-occurred-at-selector/index.tsx';
import {
	useIsAgentOrProjectAdmin,
	useIsIssueOfIncidentsPractice,
} from '@atlassian/jira-issue-field-servicedesk-practices/src/services/use-practices-field-value/index.tsx';
import {
	COMMENT_VISIBILITY_TYPE_PUBLIC,
	type CommentVisibility,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/comments.tsx';
import { COMMENT_VISIBILITY_PUBLIC } from '@atlassian/jira-issue-view-common-types/src/comment-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import withContainerWidth from '@atlassian/jira-issue-view-common-utils/src/with-container-width/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	addCommentFormCancel,
	addCommentFormCollapse,
	addCommentFormExpand,
	editCommentUpdate,
	editCommentVisibility,
	pastedIntoCommentForm,
	saveCommentRequest,
	type CommentSaveRequest,
	editCommentEventOccurredAt,
	type EditCommentVisibilityAction,
	updateCommentViewInteractionTimeMarker,
} from '@atlassian/jira-issue-view-store/src/actions/comment-actions.tsx';
import {
	accountIdloggedInUserSelector,
	isServiceDeskSelector,
	isMobileSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { isRichTextFieldEditingSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import {
	loggedInUserAvatarUrlSelector,
	loggedInUserDisplayNameSelector,
	projectTypeSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import {
	canUseServiceDeskAgentFeaturesSelector,
	canAdministerProjectPermissionsSelector,
	canAdministerJiraPermissionsSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/permissions-selector.tsx';
import { createCommentDraftKey } from '@atlassian/jira-issue-view-store/src/drafts/draft-utils.tsx';
import { getSelectedActivitySortOrder } from '@atlassian/jira-issue-view-store/src/selectors/activity-feed-selector.tsx';
import { fullIssueUrlSelector } from '@atlassian/jira-issue-view-store/src/selectors/breadcrumbs-selector.tsx';
import { NEW_COMMENT_ID } from '@atlassian/jira-issue-view-store/src/selectors/comment-constants.tsx';
import {
	commentEditingBodySelector,
	commentEditingOrViewBodyAdfSelector,
	commentEditingVisibilitySelector,
	isAddCommentEditorExpandedSelector,
	isCommentAttachmentInProgressSelector,
	isCommentVisibilityRestrictionSupportedSelector,
	isEditingAnyCommentSelector,
	commentEditingEventOccurredAtSelector,
	commentEditSessionContainsAttachmentsUsageSelector,
	isEditingInternalCommentSelector,
	addCommentEditorForceFocusSelector,
	commentSessionIdSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/comment-selector.tsx';
import { fieldHasDraftSelector } from '@atlassian/jira-issue-view-store/src/selectors/drafts/selectors.tsx';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { mainIssueAggQuery$data } from '@atlassian/jira-relay/src/__generated__/mainIssueAggQuery.graphql.ts';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useRealTimeComments } from '@atlassian/jira-realtime-comments-controller/src/controllers/index.tsx';
import { useCannedCommentAnalyticsAttributes } from '@atlassian/jira-canned-comments/src/controllers/use-canned-comments-analytics-attributes/index.tsx';
import { AddCommentForm } from './view.tsx';

const isCannedResponseEnabled = (state: State) => {
	const isServiceDesk = isServiceDeskSelector(state);
	const isMobile = isMobileSelector(state);
	const isAdminOrAgent =
		canAdministerJiraPermissionsSelector(state) ||
		canAdministerProjectPermissionsSelector(state) ||
		canUseServiceDeskAgentFeaturesSelector(state);
	return isServiceDesk && !isMobile && isAdminOrAgent;
};

const isSmartRepliesEnabled = (state: State) => {
	if (fg('smart_replies_m1_kill_switch')) {
		const projectType = projectTypeSelector(state);
		return projectType === 'software' || projectType === 'business';
	}
	return false;
};

const isQuickRepliesEnabled = (state: State) => {
	if (fg('smart_reply_on_issue_updates_events_gate')) {
		const projectType = projectTypeSelector(state);
		return projectType === 'software' || projectType === 'business';
	}
	return false;
};

const mapStateToProps = (state: State) => ({
	authorId: accountIdloggedInUserSelector(state),
	avatarUrl: loggedInUserAvatarUrlSelector(state),
	displayName: loggedInUserDisplayNameSelector(state),
	newCommentDraft: commentEditingOrViewBodyAdfSelector(NEW_COMMENT_ID)(state),
	hasDraft: fieldHasDraftSelector(createCommentDraftKey(NEW_COMMENT_ID))(state),
	// We should expand if we have explicitly requested expansion
	// or if we have currently have a draft
	isExpanded:
		isAddCommentEditorExpandedSelector(state) ||
		commentEditingBodySelector(NEW_COMMENT_ID)(state) != null,
	forceFocus: addCommentEditorForceFocusSelector(state),
	isServiceDesk: isServiceDeskSelector(state),
	isEditorFocused: isRichTextFieldEditingSelector(state) || isEditingAnyCommentSelector(state),
	isLoading: isServiceDeskSelector(state) && isCommentAttachmentInProgressSelector(state),
	shouldShowCommentVisibilitySelect: isCommentVisibilityRestrictionSupportedSelector(state),
	visibility: commentEditingVisibilitySelector(NEW_COMMENT_ID)(state) || COMMENT_VISIBILITY_PUBLIC,
	sortOrder: getSelectedActivitySortOrder(state),
	eventOccurredAt: commentEditingEventOccurredAtSelector(NEW_COMMENT_ID)(state) || null,
	isEditingInternal: isEditingInternalCommentSelector(NEW_COMMENT_ID)(state),
	editSessionContainsAttachmentsUsage:
		commentEditSessionContainsAttachmentsUsageSelector(NEW_COMMENT_ID)(state),
	fullIssueUrl: fullIssueUrlSelector(state),
	isCannedResponseEnabled: isCannedResponseEnabled(state),
	isSmartRepliesEnabled: isSmartRepliesEnabled(state),
	isQuickRepliesEnabled: isQuickRepliesEnabled(state),
	commentSessionId: commentSessionIdSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	onExpand: () => dispatch(addCommentFormExpand()),
	onEdit: (value: ADF) => dispatch(editCommentUpdate(NEW_COMMENT_ID, value)),
	onVisibilityChange: (visibility: CommentVisibility) =>
		dispatch(editCommentVisibility(NEW_COMMENT_ID, visibility)),
	onPaste: (pastedContent: string) =>
		dispatch(pastedIntoCommentForm(NEW_COMMENT_ID, pastedContent)),
	onBlur: () => dispatch(addCommentFormCollapse()),
	onCancel: () => dispatch(addCommentFormCancel()),
	onEventOccurredAtChange: (eventOccurredAt: number | null) => {
		dispatch(editCommentEventOccurredAt(NEW_COMMENT_ID, eventOccurredAt));
	},
	getOnSave: (
		eventData: Omit<CommentSaveRequest, 'id' | 'createdDate'>,
		analyticsEvent: UIAnalyticsEvent,
		commentSessionId?: string | null,
	) =>
		dispatch(
			saveCommentRequest(
				{
					id: `${NEW_COMMENT_ID}_${new Date().getTime()}`,
					createdDate: new Date().toISOString(),
					isNewComment: true,
					...eventData,
				},
				analyticsEvent,
				commentSessionId,
			),
		),
	onCommentInteractionUpdateCommentViewInteractionTimeMarker: () =>
		// Update interaction time marker with the current time when user interacted with new comments
		dispatch(updateCommentViewInteractionTimeMarker(new Date().toISOString())),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type OwnProps = {
	isIssueOfIncidentsPractice?: boolean;
	isUserJSMAgentOrProjectAdmin?: boolean;
	rootRelayFragment: mainIssueAggQuery$data | null;
	isRealTimeCommentUpdated?: boolean;
	getCannedCommentAnalyticsAttributes?: (doc: ADF) => {
		cannedCommentUsed: boolean | undefined;
		cannedCommentExactMatch: boolean | undefined;
	};
};
const editorSecondaryToolbarComponentsFactory = memoizeOne(
	(
		shouldShowCommentVisibility: boolean,
		visibility: CommentVisibility,
		onVisibilityChange: (visibility: CommentVisibility) => EditCommentVisibilityAction,
		jsmTimelineComponents: React.JSX.Element[],
	) =>
		shouldShowCommentVisibility
			? [
					...jsmTimelineComponents,
					<CommentVisibilitySelect
						key="commentVisibilitySelect"
						initialValue={visibility}
						onChange={onVisibilityChange}
					/>,
				]
			: jsmTimelineComponents,
);

const onSaveFactory = memoizeOne(
	(
		eventOccurredAt: StateProps['eventOccurredAt'],
		shouldShowCommentVisibilitySelect: StateProps['shouldShowCommentVisibilitySelect'],
		visibility: CommentVisibility,
		getOnSave: DispatchProps['getOnSave'],
		authorId: StateProps['authorId'],
		isIssueOfIncidentsPractice: OwnProps['isIssueOfIncidentsPractice'],
		isUserJSMAgentOrProjectAdmin: OwnProps['isUserJSMAgentOrProjectAdmin'],
		isEditingInternal: StateProps['isEditingInternal'],
		fullIssueUrl: StateProps['fullIssueUrl'],
		commentSessionId: StateProps['commentSessionId'],
		editSessionContainsAttachmentsUsage: StateProps['editSessionContainsAttachmentsUsage'],
		isRealTimeCommentUpdated: OwnProps['isRealTimeCommentUpdated'],
		getCannedCommentAnalyticsAttributes: OwnProps['getCannedCommentAnalyticsAttributes'],
	) =>
		(value: ADF, analyticsEvent: UIAnalyticsEvent) => {
			eventOccurredAt
				? analyticsEvent.update({
						isEventOccurredAtSelected: true,
					})
				: analyticsEvent.update({
						isEventOccurredAtSelected: false,
					});
			if (shouldShowCommentVisibilitySelect) {
				analyticsEvent.update({
					isCommentRestricted: visibility.type !== COMMENT_VISIBILITY_TYPE_PUBLIC,
				});
			}

			if (fg('thor_send_realtime_attribute')) {
				analyticsEvent.update({
					isRealTimeCommentUpdated,
				});
			}

			if (getCannedCommentAnalyticsAttributes && fg('smart-replies-remove-event-comment-saved')) {
				const { cannedCommentUsed, cannedCommentExactMatch } =
					getCannedCommentAnalyticsAttributes(value);

				analyticsEvent.update({
					cannedCommentUsed,
					cannedCommentExactMatch,
				});
			}

			getOnSave(
				{
					authorId,
					bodyAdf: value,
					bodyHtml: undefined,
					richContent: undefined,
					visibility,
					...(isIssueOfIncidentsPractice &&
					isUserJSMAgentOrProjectAdmin &&
					isEditingInternal &&
					fg('jsm-timelines-phase-2')
						? {
								eventOccurredAt,
								jsdIncidentActivityViewHidden: false,
								fullIssueUrl,
								triggerIncidentSaveCommentFlag: Boolean(
									isIssueOfIncidentsPractice && isUserJSMAgentOrProjectAdmin && eventOccurredAt,
								),
							}
						: {}),
					editSessionContainsAttachmentsUsage,
				},
				analyticsEvent,
				commentSessionId,
			);
		},
);

const onEditorExpandedWithAnalyticsFactory = memoizeOne(
	(onExpand: DispatchProps['onExpand'], hasDraft: StateProps['hasDraft']) =>
		(analyticsEvent: UIAnalyticsEvent) => {
			onExpand();
			fireOperationalAnalytics(
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				analyticsEvent as UIAnalyticsEvent,
				'addCommentEditor expanded',
				{
					hasDraft,
				},
			);
		},
);

const jsmTimelineComponentsFactory = memoizeOne(
	(
		isJsmTimelineEnabledFF: boolean,
		isEditingInternal: StateProps['isEditingInternal'],
		statePropsEventOccurredAt: StateProps['eventOccurredAt'],
		onEventOccurredAtChange: DispatchProps['onEventOccurredAtChange'],
	) =>
		isJsmTimelineEnabledFF && isEditingInternal
			? [
					<EventOccurredAtSelector
						key="eventOccurredAtSelector"
						initialValue={statePropsEventOccurredAt}
						onChange={(eventOccurredAt: number | null) => {
							onEventOccurredAtChange(eventOccurredAt);
						}}
					/>,
				]
			: [],
);
const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: OwnProps) => {
	const jsmTimelineComponents = jsmTimelineComponentsFactory(
		fg('jsm-timelines-phase-2'),
		stateProps.isEditingInternal,
		stateProps.eventOccurredAt,
		dispatchProps.onEventOccurredAtChange,
	);

	const shouldShowCommentVisibility =
		stateProps.shouldShowCommentVisibilitySelect && stateProps.isEditingInternal;

	return {
		...ownProps,
		...stateProps,
		...dispatchProps,
		editorSecondaryToolbarComponents: editorSecondaryToolbarComponentsFactory(
			shouldShowCommentVisibility,
			stateProps.visibility,
			dispatchProps.onVisibilityChange,
			jsmTimelineComponents,
		),
		onSave: onSaveFactory(
			stateProps.eventOccurredAt,
			stateProps.shouldShowCommentVisibilitySelect,
			stateProps.visibility,
			dispatchProps.getOnSave,
			stateProps.authorId,
			ownProps.isIssueOfIncidentsPractice,
			ownProps.isUserJSMAgentOrProjectAdmin,
			stateProps.isEditingInternal,
			stateProps.fullIssueUrl,
			stateProps.commentSessionId,
			stateProps.editSessionContainsAttachmentsUsage,
			ownProps.isRealTimeCommentUpdated,
			ownProps.getCannedCommentAnalyticsAttributes,
		),
		onEditorExpandedWithAnalytics: onEditorExpandedWithAnalyticsFactory(
			dispatchProps.onExpand,
			stateProps.hasDraft,
		),
	};
};

type ConnectedAddCommentFormProps = OwnProps & {
	rootRelayFragment: mainIssueAggQuery$data | null;
};

const ConnectedAddCommentFormBaseWithoutSmartRepliesAnalytics = flowWithSafeComponent(
	withContainerWidth,
	ComponentWithAnalytics('issueFields', {
		onSave: 'created',
		onEditorExpandedWithAnalytics: 'commentExpanded',
	}),
	// @ts-expect-error - Argument of type 'InferableComponentEnhancerWithProps<{ editorSecondaryToolbarComponents: Element[]; onSave: (value: DocNode, analyticsEvent: UIAnalyticsEvent) => void; onEditorExpandedWithAnalytics: (analyticsEvent: UIAnalyticsEvent) => void; ... 25 more ...; isIssueOfIncidentsPractice?: boolean | undefined; }, { ...; }>' is not assignable to parameter of type 'FlowStep<FC<Omit<{ [K in keyof PropsWithRef<Omit<P, "containerWidth">>]: K extends "onSave" | "onEditorExpandedWithAnalytics" ? (...args: [...Parameters<PropsWithRef<Omit<P, "containerWidth">>[K]>, UIAnalyticsEvent]) => ReturnType<...> : PropsWithRef<...>[K]; }, keyof WithAnalyticsEventsProps>>, ConnectedComponent<....'.
	connect(mapStateToProps, mapDispatchToProps, mergeProps),
)(AddCommentForm);

const ConnectedAddCommentFormBaseWithSmartRepliesAnalytics = (props: OwnProps) => {
	const { getCannedCommentAnalyticsAttributes } = useCannedCommentAnalyticsAttributes();
	return (
		<ConnectedAddCommentFormBaseWithoutSmartRepliesAnalytics
			{...props}
			getCannedCommentAnalyticsAttributes={getCannedCommentAnalyticsAttributes}
		/>
	);
};

export const ConnectedAddCommentFormWithoutRealtime = componentWithFG(
	'smart-replies-remove-event-comment-saved',
	ConnectedAddCommentFormBaseWithSmartRepliesAnalytics,
	ConnectedAddCommentFormBaseWithoutSmartRepliesAnalytics,
);

const ConnectedAddCommentFormWithRealtime = (props: OwnProps) => {
	const [{ isRealTimeCommentUpdated }] = useRealTimeComments();
	return (
		<ConnectedAddCommentFormWithoutRealtime
			{...props}
			isRealTimeCommentUpdated={isRealTimeCommentUpdated}
		/>
	);
};

export const ConnectedAddCommentForm = componentWithFG(
	'thor_send_realtime_attribute',
	ConnectedAddCommentFormWithRealtime,
	ConnectedAddCommentFormWithoutRealtime,
);

export const ConnectedAddCommentFormWithIssueOfIncidentsPractice = ({
	rootRelayFragment,
}: ConnectedAddCommentFormProps) => {
	const isIssueOfIncidentsPractice = useIsIssueOfIncidentsPractice();
	const isUserJSMAgentOrProjectAdmin = useIsAgentOrProjectAdmin();

	return (
		<ConnectedAddCommentForm
			isIssueOfIncidentsPractice={isIssueOfIncidentsPractice}
			isUserJSMAgentOrProjectAdmin={isUserJSMAgentOrProjectAdmin}
			rootRelayFragment={rootRelayFragment}
		/>
	);
};

export default componentWithCondition<OwnProps>(
	() => fg('jsm-timelines-phase-2'),
	ConnectedAddCommentFormWithIssueOfIncidentsPractice,
	ConnectedAddCommentForm,
);
