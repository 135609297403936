import { useCallback, useState } from 'react';
import { useMutation, graphql } from 'react-relay';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useFlagService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import { useIssueId, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldsValuesActions } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import type {
	useUpdateIssueViewOrganizationField_Mutation,
	useUpdateIssueViewOrganizationField_Mutation$data,
} from '@atlassian/jira-relay/src/__generated__/useUpdateIssueViewOrganizationField_Mutation.graphql';
import { setTraceId } from '@atlassian/jira-servicedesk-customer-service-common/src/utils/trace-id/index.tsx';
import { useActivationId } from '@atlassian/jira-tenant-context-controller/src/components/activation-id/index.tsx';
import type { OrganizationOption } from '../../common/types.tsx';
import { editOrganizationFieldIssueViewExperience } from '../../experiences.tsx';
import messages from './messages.tsx';
import type { UseUpdateIssueViewOrganizationFieldArg } from './types.tsx';

export const useUpdateIssueViewOrganizationField = ({
	id,
	fieldId,
	fieldName,
	setEditing,
}: UseUpdateIssueViewOrganizationFieldArg) => {
	const { formatMessage } = useIntl();
	const { showFlag, dismissFlag } = useFlagService();
	const activationId = useActivationId();

	const issueId = useIssueId();
	const issueKey = useIssueKey();
	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();
	const [, { setFieldValue }] = useFieldsValuesActions();
	const [isInvalid, setIsInvalid] = useState<boolean>(false);

	const [commit] = useMutation<useUpdateIssueViewOrganizationField_Mutation>(graphql`
		mutation useUpdateIssueViewOrganizationField_Mutation(
			$input: JiraCustomerServiceUpdateOrganizationFieldInput!
		) {
			jira {
				updateOrganizationField(input: $input) @optIn(to: "JiraIssueFieldMutations") {
					success
					errors {
						message
					}
					field {
						selectedOrganization {
							id
							organizationId
							organizationName
							selectableLabel
						}
					}
				}
			}
		}
	`);

	const onChange = useCallback(
		(newValue: OrganizationOption | null) => {
			const traceId = setTraceId();

			setIsInvalid(false);
			setEditing(false);

			issueId && fieldChangeRequested(issueId, fieldId, {});

			const onError = (error: Error) => {
				setIsInvalid(true);
				issueId && fieldChangeFailed(issueId, fieldId);
				editOrganizationFieldIssueViewExperience.failure({ metadata: { traceId } });

				const flagId = showFlag({
					messageId:
						'servicedesk-customer-service-custom-fields.controllers.use-update-issue-view-organization-field.show-flag.error',
					messageType: 'transactional',
					type: 'error',
					title: formatMessage(messages.title, { fieldName }),
					description: formatMessage(messages.description),
					isAutoDismiss: true,
					actions: [
						{
							content: messages.tryAgain,
							onClick: () => {
								setEditing(true);
								flagId && dismissFlag({ id: flagId, command: 'DISMISS' });
							},
						},
					],
				});

				fireErrorAnalytics({
					meta: {
						id: 'fetchOrganizations',
						packageName: 'jiraServicedeskCustomerServiceOrganizationField',
						teamName: 'boysenberry',
					},
					error,
					sendToPrivacyUnsafeSplunk: true,
					attributes: { traceId },
				});
			};

			const onCompleted = ({ jira }: useUpdateIssueViewOrganizationField_Mutation$data) => {
				const success = jira?.updateOrganizationField?.success;
				const selectedOrganization = jira?.updateOrganizationField?.field?.selectedOrganization;

				if (success) {
					issueId && fieldChanged(issueId, fieldId, selectedOrganization);
					setFieldValue(issueKey, fieldId, selectedOrganization);
					editOrganizationFieldIssueViewExperience.success({ metadata: { traceId } });
					return;
				}

				onError(new Error(JSON.stringify(jira?.updateOrganizationField?.errors || 'not provided')));
			};

			commit({
				variables: {
					input: {
						id,
						operation: {
							operation: 'SET',
							organizationId: `ari:cloud:jira-servicedesk::organization/activation/${activationId}${newValue ? `/${newValue.value}` : ''}`,
						},
					},
				},
				onCompleted,
				onError,
				optimisticResponse: {
					jira: {
						updateOrganizationField: {
							success: true,
							errors: null,
							field: {
								id,
								selectedOrganization: newValue && {
									id: newValue.value,
									organizationId: newValue.value,
									organizationName: newValue.label,
									selectableLabel: newValue.label,
								},
							},
						},
					},
				},
			});
		},
		[
			commit,
			setEditing,
			setIsInvalid,
			id,
			fieldId,
			// error handling
			showFlag,
			dismissFlag,
			formatMessage,
			fieldName,
			// sweet state
			issueKey,
			setFieldValue,
			// sweet state events
			issueId,
			fieldChanged,
			fieldChangeFailed,
			fieldChangeRequested,
			activationId,
		],
	);

	return {
		onChange,
		isInvalid,
	};
};
