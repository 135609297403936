import React, { useMemo } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useProjectKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import { useIsSimplifiedProject } from '@atlassian/jira-project-context-service/src/main.tsx';
import type { ShortcutOption } from '@atlassian/jira-shortcuts-dialog/src/common/types.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import type { view_issueViewFoundation_ConfigureIssueFF$key } from '@atlassian/jira-relay/src/__generated__/view_issueViewFoundation_ConfigureIssueFF.graphql';
import FindField from '../admin-actions-dropdown-group/find-field/index.tsx';
import messages from '../admin-actions-dropdown-group/messages.tsx';
import ConfigureClassic from '../issue-configuration-dropdown-group/configure-issue/main.tsx';

export const issueAdminActionsDropdownGroup = (
	shouldShowConfiguration: boolean,
	actions: ShortcutOption[],
	onClick: (
		itemKey: string,
		event?: Event,
		actionAttributes?: Attributes,
	) => Promise<undefined> | undefined,
	issueViewRelayFragment?: view_issueViewFoundation_ConfigureIssueFF$key | null,
) => {
	let items = actions.reduce<React.ReactNode[]>((itemsArray, { Component, label, key }) => {
		if (Component) {
			itemsArray.push(<Component label={label} key={key} itemKey={key} onClick={onClick} />);
		}
		return itemsArray;
	}, []);
	items = shouldShowConfiguration
		? [
				...items,
				<ConfigureClassic
					key="configureClassic"
					issueViewRelayFragment={issueViewRelayFragment ?? null}
				/>,
			]
		: items;

	return {
		name: '',
		key: 'IssueAdminActionsGroup',

		items,
	};
};

export const useAdminActions = (): ShortcutOption[] => {
	const projectKey = useProjectKey();
	const isSimplifiedProject = useIsSimplifiedProject(projectKey);
	const { formatMessage } = useIntl();
	const isAdmin = useIsAdmin();
	const items = useMemo(
		() => [
			...(!isSimplifiedProject
				? [
						{
							Component: FindField,
							label: formatMessage(messages.findField),
							key: 'findField',
						},
					]
				: []),
		],
		[formatMessage, isSimplifiedProject],
	);

	return isAdmin ? items : [];
};

export default issueAdminActionsDropdownGroup;
