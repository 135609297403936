/** @jsx jsx */
// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps, type ReactNode } from 'react';
import { styled, jsx } from '@compiled/react';
import Heading from '@atlaskit/heading';
import { token } from '@atlaskit/tokens';
import { css } from '@atlaskit/css';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { FieldHeadingIconsVisibility } from '@atlassian/jira-issue-field-heading/src/styled.tsx';

const headingWithDraftStyles = css({
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
	maxWidth: '100%',
	gap: token('space.100'),
});

const HeadingWithDraftNew = ({ children, ...props }: { children: ReactNode }) => (
	<FieldHeadingIconsVisibility css={headingWithDraftStyles} {...props}>
		{children}
	</FieldHeadingIconsVisibility>
);

export const DraftIndicator = (props: ComponentProps<typeof DraftIndicatorComponent>) => (
	<Heading size="xxsmall" as="div">
		<DraftIndicatorComponent {...props} />
	</Heading>
);
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SectionHeading = styled(FieldHeadingIconsVisibility)<{
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	leftAlign?: any;
}>({
	display: 'flex',
	alignItems: 'center',
	flexWrap: 'nowrap',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	justifyContent: (props) => (props.leftAlign ? 'normal' : 'space-between'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const HeadingWithDraftOld = styled(FieldHeadingIconsVisibility)({
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
	maxWidth: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HeadingWithDraft = componentWithFG(
	'issue_view_field_config_edit',
	HeadingWithDraftNew,
	HeadingWithDraftOld,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SectionHeadingTitle = styled.h2({
	font: token('font.heading.small'),
	color: token('color.text'),
	overflowWrap: 'break-word',
	minWidth: 0,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any,  @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SectionHeadingIcons = styled.div<{ leftAlign?: any }>(
	{
		display: 'flex',
		alignItems: 'center',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	(props) => (props.leftAlign ? `margin-left: ${gridSize}px` : ''),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DraftIndicatorComponent = styled.div({
	marginLeft: token('space.100'),
	marginTop: 0,
	flexShrink: 0,
});
