import React, { useMemo } from 'react';
import { IssueTaskDecisionProvider } from '@atlassian/jira-issue-task-decision-provider/src/controllers/index.tsx';
import { useCommentTasksUpdater } from '@atlassian/jira-issue-task-decision-provider/src/controllers/use-comment-tasks-updater/index.tsx';
import Renderer from '@atlassian/jira-issue-view-media-renderer/src/index.tsx';
import { useEditorState } from '../../../../state/context.tsx';
import type { PropsOld, Props } from './types.tsx';

export const ConnectedRendererOld = (props: PropsOld) => {
	const { adf, canEdit, commentId } = props;

	const [{ mentionProvider, contextIdentifier, mediaContext }] = useEditorState();

	const toggleTask = useCommentTasksUpdater(commentId);
	const taskDecisionProvider = useMemo(
		() => new IssueTaskDecisionProvider(toggleTask),
		[toggleTask],
	);

	if (!mentionProvider || !contextIdentifier || !mediaContext) {
		return null;
	}

	return (
		<Renderer
			adf={adf}
			disableActions={!canEdit}
			taskDecisionProvider={taskDecisionProvider}
			mentionProvider={mentionProvider}
			contextIdentifier={contextIdentifier}
			mediaContext={mediaContext}
		/>
	);
};

export const ConnectedRendererNew = (props: Props) => {
	const { adf, canEdit, commentId } = props;

	const toggleTask = useCommentTasksUpdater(commentId);
	const taskDecisionProvider = useMemo(
		() => new IssueTaskDecisionProvider(toggleTask),
		[toggleTask],
	);

	if (!props.mentionProvider || !props.contextIdentifier || !props.mediaContext) {
		return null;
	}

	return (
		<Renderer
			adf={adf}
			disableActions={!canEdit}
			taskDecisionProvider={taskDecisionProvider}
			mentionProvider={props.mentionProvider}
			contextIdentifier={props.contextIdentifier}
			mediaContext={props.mediaContext}
		/>
	);
};
