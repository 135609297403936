import React, {
	useCallback,
	useMemo,
	useReducer,
	useEffect,
	type ReactElement,
	type ReactNode,
	type ComponentType,
	type Dispatch,
} from 'react';
import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { usePerformanceAnalyticsActions } from '@atlassian/jira-forge-ui-analytics/src/controllers/performance-analytics/main.tsx';
import {
	fireTrackEditedEvent,
	fireUiCanceledEvent,
	fireUiTriggerClickEvent,
} from '@atlassian/jira-forge-ui-analytics/src/services/custom-field-edit/index.tsx';
import { AsyncCustomFieldInlineEdit } from '@atlassian/jira-forge-ui-async/src/ui/custom-fields/custom-field-inline-edit/async.tsx';
import type { ForgeCustomFieldValue } from '@atlassian/jira-forge-ui-types/src/common/types/contexts/custom-field.tsx';
import type {
	CustomFieldExtensionData,
	ForgeUiIssueData,
} from '@atlassian/jira-forge-ui-types/src/common/types/extension-data.tsx';
import type { CustomFieldExtension } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import customFieldGenericError from '@atlassian/jira-forge-ui/src/common/ui/flags/custom-field/edit-failed/generic-error/index.tsx';
import { useCustomFieldService } from '@atlassian/jira-forge-ui/src/services/custom-fields/save-field/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { EditAnalyticsWrapper } from '@atlassian/jira-forge-ui-analytics/src/ui/custom-field/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import type { ForgeUiAnalyticsAttributes } from '@atlassian/jira-forge-ui-types/src/common/types/analytics.tsx';
import { useAnalyticsAttributesContext } from '../analytics/atrributes-context/index.tsx';
import type { CustomFieldProps } from '../types.tsx';
import {
	forgeCustomFieldEditInitialization,
	forgeCustomFieldEditCancel,
	forgeCustomFieldEditSetValue,
	forgeCustomFieldEditRendered,
	forgeCustomFieldEditFailed,
	type Action,
} from './actions.tsx';
import EditReadView from './edit-read-view/index.tsx';
import editReducer from './state.tsx';

type EditViewContentProps = {
	issueKey: IssueKey;
	fieldId: string;
	extension: CustomFieldExtension;
	extensionData: Omit<CustomFieldExtensionData, 'type'>;
	extensionPayload: {
		fieldValue: ForgeCustomFieldValue;
	};
	analyticsAttributes: ForgeUiAnalyticsAttributes;
	onSave: ((value: ForgeCustomFieldValue) => void) | undefined;
	onCancel: () => void;
	dispatch: Dispatch<Action>;
};

export const EditViewContent = ({
	issueKey,
	fieldId,
	extension,
	extensionData,
	extensionPayload,
	analyticsAttributes,
	onSave,
	onCancel,
	dispatch,
}: EditViewContentProps) => {
	const { showFlag } = useFlagsService();
	const performanceAnalyticsActions = usePerformanceAnalyticsActions();

	const onEditConfirm = useCallback(
		(newValue: ForgeCustomFieldValue) => {
			// to update redux store with new value
			onSave && onSave(newValue);
			dispatch(forgeCustomFieldEditSetValue(newValue));
			fireTrackEditedEvent(analyticsAttributes.source, analyticsAttributes);
		},
		[onSave, analyticsAttributes, dispatch],
	);
	const onEditCancel = useCallback(() => {
		onCancel();
		dispatch(forgeCustomFieldEditCancel());
		fireUiCanceledEvent(analyticsAttributes.source, analyticsAttributes);
	}, [onCancel, analyticsAttributes, dispatch]);

	const onEditRender = useCallback(() => {
		dispatch(forgeCustomFieldEditRendered());
		performanceAnalyticsActions.finishUserInteraction();
	}, [performanceAnalyticsActions, dispatch]);

	const onEditFail = useCallback(() => {
		showFlag(customFieldGenericError());
		dispatch(forgeCustomFieldEditFailed());
	}, [showFlag, dispatch]);

	const { save } = useCustomFieldService({
		issueKey,
		fieldId,
		fieldType: extension.id,
		source: analyticsAttributes.source,
	});

	return (
		<AsyncCustomFieldInlineEdit
			extension={extension}
			extensionData={extensionData}
			extensionPayload={extensionPayload}
			analyticsAttributes={analyticsAttributes}
			onConfirm={onEditConfirm}
			onRender={onEditRender}
			onCancel={onEditCancel}
			onError={onEditFail}
			onSave={save}
		/>
	);
};

export type Props = {
	issueKey: IssueKey;
	extension: CustomFieldExtension;
	extensionData: ForgeUiIssueData | null;
	fieldId: string;
	fieldName: string;
	value: ForgeCustomFieldValue;
	viewProps: CustomFieldProps;
	onCancel: () => void;
	onSave?: (value: ForgeCustomFieldValue) => void;
	ForgeField: ComponentType<CustomFieldProps>;
	shouldRenderCustomReadView: boolean;
	renderCustomReadView: (newValue: ForgeCustomFieldValue) => ReactElement;
};

const ForgeCustomFieldEditViewNew = ({
	issueKey,
	extensionData,
	extension,
	fieldId,
	fieldName,
	value,
	onCancel,
	onSave,
	shouldRenderCustomReadView,
	renderCustomReadView,
	viewProps,
	ForgeField,
}: Props) => {
	const [{ isEditing, isLoading, editValue }, dispatch] = useReducer(editReducer, {
		isEditing: false,
		isLoading: false,
		editValue: value,
	});
	const analyticsAttributes = useAnalyticsAttributesContext();
	const performanceAnalyticsActions = usePerformanceAnalyticsActions();

	if (fg('prevent_from_updating_custom_field_in_edit_mode')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			if (!isEditing) {
				dispatch(forgeCustomFieldEditSetValue(value));
			}
		}, [value, isEditing]);
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			dispatch(forgeCustomFieldEditSetValue(value));
		}, [value]);
	}

	// switchToEditMode visual feedback
	useEffect(() => {
		if (isEditing && isLoading) {
			performanceAnalyticsActions.markUserInteractionFeedback();
		}
	}, [isEditing, isLoading, performanceAnalyticsActions]);

	const memoizedExtensionData = useMemo(
		() => ({
			...extensionData,
			fieldId,
			fieldName,
			fieldType: extension.id,
			renderContext: 'issue-view',
			...(fg('ditto-fcf-support-new-manifest-on-frontend') && {
				experience: 'issue-view' as const,
			}),
		}),
		[extension, extensionData, fieldId, fieldName],
	);

	const memoizedExtensionPayload = useMemo(
		() => ({
			fieldValue: editValue,
		}),
		[editValue],
	);

	const switchToEditMode = useCallback(() => {
		fireUiTriggerClickEvent(extension.id, analyticsAttributes.source, analyticsAttributes);
		performanceAnalyticsActions.startUserInteraction();
		dispatch(forgeCustomFieldEditInitialization());
	}, [extension.id, analyticsAttributes, performanceAnalyticsActions]);

	const readView = useCallback(
		() => <EditReadView isLoading={isLoading}>{renderCustomReadView(editValue)}</EditReadView>,
		[editValue, isLoading, renderCustomReadView],
	);

	const fieldContentWrapper = useCallback(
		({ topMargin, children }: { topMargin?: number | undefined; children: ReactNode }) => (
			<EditReadView
				isCustomReadView={shouldRenderCustomReadView}
				topMargin={topMargin}
				isLoading={isLoading}
			>
				{children}
			</EditReadView>
		),
		[isLoading, shouldRenderCustomReadView],
	);

	const editView = useCallback(() => {
		return (
			<EditAnalyticsWrapper attributes={analyticsAttributes}>
				<EditViewContent
					issueKey={issueKey}
					fieldId={fieldId}
					extension={extension}
					extensionData={memoizedExtensionData}
					extensionPayload={memoizedExtensionPayload}
					analyticsAttributes={analyticsAttributes}
					onSave={onSave}
					onCancel={onCancel}
					dispatch={dispatch}
				/>
			</EditAnalyticsWrapper>
		);
	}, [
		fieldId,
		issueKey,
		extension,
		memoizedExtensionData,
		memoizedExtensionPayload,
		analyticsAttributes,
		dispatch,
		onSave,
		onCancel,
	]);

	const editViewProps = useMemo(
		() => ({
			...viewProps,
			isEditing,
			onEditRequest: switchToEditMode,
			// the logic behind displaying action buttons is handled by AsyncCustomFieldInlineEdit
			// component. We need to pass this prop here so it's not added by default by base
			// inline edit components for custom fields
			hideActionButtons: true,
			renderAppEditView: editView,
			value: editValue,
			...(shouldRenderCustomReadView ? { customReadView: readView } : { fieldContentWrapper }),
		}),
		[
			viewProps,
			isEditing,
			switchToEditMode,
			editView,
			editValue,
			shouldRenderCustomReadView,
			readView,
			fieldContentWrapper,
		],
	);

	return <ForgeField {...editViewProps} />;
};

const ForgeCustomFieldEditViewOld = ({
	issueKey,
	extensionData,
	extension,
	fieldId,
	fieldName,
	value,
	onCancel,
	onSave,
	shouldRenderCustomReadView,
	renderCustomReadView,
	viewProps,
	ForgeField,
}: Props) => {
	const [{ isEditing, isLoading, editValue }, dispatch] = useReducer(editReducer, {
		isEditing: false,
		isLoading: false,
		editValue: value,
	});
	const analyticsAttributes = useAnalyticsAttributesContext();
	const performanceAnalyticsActions = usePerformanceAnalyticsActions();

	if (fg('prevent_from_updating_custom_field_in_edit_mode')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			if (!isEditing) {
				dispatch(forgeCustomFieldEditSetValue(value));
			}
		}, [value, isEditing]);
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			dispatch(forgeCustomFieldEditSetValue(value));
		}, [value]);
	}

	const attributes = useMemo(
		() => ({ ...analyticsAttributes, displayType: 'modal' }),
		[analyticsAttributes],
	);
	const { showFlag } = useFlagsService();

	const onEditConfirm = useCallback(
		(newValue: ForgeCustomFieldValue) => {
			// to update redux store with new value
			onSave && onSave(newValue);
			dispatch(forgeCustomFieldEditSetValue(newValue));
			fireTrackEditedEvent(attributes.source, attributes);
		},
		[onSave, attributes],
	);
	const onEditCancel = useCallback(() => {
		onCancel();
		dispatch(forgeCustomFieldEditCancel());
		fireUiCanceledEvent(attributes.source, attributes);
	}, [onCancel, attributes]);

	const onEditRender = useCallback(() => {
		dispatch(forgeCustomFieldEditRendered());
		performanceAnalyticsActions.finishUserInteraction();
	}, [performanceAnalyticsActions]);

	const onEditFail = useCallback(() => {
		showFlag(customFieldGenericError());
		dispatch(forgeCustomFieldEditFailed());
	}, [showFlag]);

	const switchToEditMode = useCallback(() => {
		fireUiTriggerClickEvent(extension.id, attributes.source, attributes);
		performanceAnalyticsActions.startUserInteraction();
		dispatch(forgeCustomFieldEditInitialization());
	}, [extension.id, attributes, performanceAnalyticsActions]);

	// switchToEditMode visual feedback
	useEffect(() => {
		if (isEditing && isLoading) {
			performanceAnalyticsActions.markUserInteractionFeedback();
		}
	}, [isEditing, isLoading, performanceAnalyticsActions]);

	const { save } = useCustomFieldService({
		issueKey,
		fieldId,
		fieldType: extension.id,
		source: attributes.source,
	});

	const memoizedExtensionData = useMemo(
		() => ({
			...extensionData,
			fieldId,
			fieldName,
			fieldType: extension.id,
			renderContext: 'issue-view',
			...(fg('ditto-fcf-support-new-manifest-on-frontend') && {
				experience: 'issue-view' as const,
			}),
		}),
		[extension, extensionData, fieldId, fieldName],
	);

	const memoizedExtensionPayload = useMemo(
		() => ({
			fieldValue: editValue,
		}),
		[editValue],
	);

	const readView = useCallback(
		() => <EditReadView isLoading={isLoading}>{renderCustomReadView(editValue)}</EditReadView>,
		[editValue, isLoading, renderCustomReadView],
	);

	const fieldContentWrapper = useCallback(
		({ topMargin, children }: { topMargin?: number | undefined; children: ReactNode }) => (
			<EditReadView
				isCustomReadView={shouldRenderCustomReadView}
				topMargin={topMargin}
				isLoading={isLoading}
			>
				{children}
			</EditReadView>
		),
		[isLoading, shouldRenderCustomReadView],
	);

	const editView = useCallback(() => {
		return (
			<AsyncCustomFieldInlineEdit
				extension={extension}
				extensionData={memoizedExtensionData}
				extensionPayload={memoizedExtensionPayload}
				analyticsAttributes={analyticsAttributes}
				onConfirm={onEditConfirm}
				onRender={onEditRender}
				onCancel={onEditCancel}
				onError={onEditFail}
				onSave={save}
			/>
		);
	}, [
		extension,
		memoizedExtensionData,
		memoizedExtensionPayload,
		analyticsAttributes,
		onEditConfirm,
		onEditRender,
		onEditCancel,
		onEditFail,
		save,
	]);

	const editViewProps = useMemo(
		() => ({
			...viewProps,
			isEditing,
			onEditRequest: switchToEditMode,
			// the logic behind displaying action buttons is handled by AsyncCustomFieldInlineEdit
			// component. We need to pass this prop here so it's not added by default by base
			// inline edit components for custom fields
			hideActionButtons: true,
			renderAppEditView: editView,
			value: editValue,
			...(shouldRenderCustomReadView ? { customReadView: readView } : { fieldContentWrapper }),
		}),
		[
			viewProps,
			isEditing,
			switchToEditMode,
			editView,
			editValue,
			shouldRenderCustomReadView,
			readView,
			fieldContentWrapper,
		],
	);

	return <ForgeField {...editViewProps} />;
};

export const ForgeCustomFieldEditView = componentWithFG(
	'custom_fields_screen_events_fix',
	ForgeCustomFieldEditViewNew,
	ForgeCustomFieldEditViewOld,
);
