/**
 * @generated SignedSource<<3d6599c336b4112b7bb324682ad068c7>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutTemplates_UserPreferences$data = {
  readonly userPreferences?: {
    readonly __id: string;
    readonly showDateFieldAssociationMessageByIssueKey: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ui_issueViewLayoutTemplates_UserPreferences";
};
export type ui_issueViewLayoutTemplates_UserPreferences$key = {
  readonly " $data"?: ui_issueViewLayoutTemplates_UserPreferences$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutTemplates_UserPreferences">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isInTimelineOrCalendarView"
    },
    {
      "kind": "RootArgument",
      "name": "issueKey"
    }
  ],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutTemplates_UserPreferences",
  "selections": [
    {
      "condition": "isInTimelineOrCalendarView",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "cloudId",
              "variableName": "cloudId"
            }
          ],
          "concreteType": "JiraUserPreferences",
          "kind": "LinkedField",
          "name": "userPreferences",
          "plural": false,
          "selections": [
            {
              "args": [
                {
                  "kind": "Variable",
                  "name": "issueKey",
                  "variableName": "issueKey"
                }
              ],
              "kind": "ScalarField",
              "name": "showDateFieldAssociationMessageByIssueKey"
            },
            {
              "kind": "ClientExtension",
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "__id"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraQuery"
};

(node as any).hash = "2bd07a8cd51cd8ae623fa12b3360a3f3";

export default node;
