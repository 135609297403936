import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/merge';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import { type ActionsObservable, combineEpics } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators/catchError';
import { mergeMap } from 'rxjs/operators/mergeMap';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';
import { ofTypeOrBatchType } from '@atlassian/jira-issue-view-common-utils/src/rx/of-type-or-batch-type.tsx';
import {
	editCommentUpdate,
	FETCH_MORE_COMMENTS_SUCCESS,
	EDIT_COMMENT_BEGIN,
	FETCH_MORE_CHILD_COMMENTS_SUCCESS,
} from '@atlassian/jira-issue-view-store/src/actions/comment-actions.tsx';
import {
	FETCH_ISSUE_SUCCESS,
	REFRESH_ISSUE_SUCCESS,
} from '@atlassian/jira-issue-view-store/src/common/actions/issue-fetch-actions.tsx';
import {
	issueKeySelector,
	accountIdloggedInUserSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { loadDraftRequest } from '@atlassian/jira-issue-view-store/src/drafts/draft-actions.tsx';
import {
	getDraftKey,
	createCommentDraftKey,
} from '@atlassian/jira-issue-view-store/src/drafts/draft-utils.tsx';
import {
	commentBodyHtmlSelector,
	commentIdsSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/comment-selector.tsx';

// @ts-expect-error - TS2304 - Cannot find name 'CommentId'.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getDraftHandler = (useDraftHandler: boolean, commentId: CommentId, action: any) =>
	useDraftHandler && commentId === action.payload.id ? editCommentUpdate : null;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const loadCommentDrafts = (action: any, state: State, useDraftHandler: boolean) => {
	const loggedInAccountId = accountIdloggedInUserSelector(state);
	const issueKey = issueKeySelector(state);
	const commentIds = commentIdsSelector(state);

	return commentIds.map((commentId) => {
		const id = createCommentDraftKey(commentId);
		const draftKey = getDraftKey(loggedInAccountId, issueKey, id);
		const commentContent = commentBodyHtmlSelector(commentId)(state);
		const commentContentNotNull = commentContent != null ? commentContent : undefined;

		return Observable.of(
			loadDraftRequest(
				commentId,
				draftKey,
				getDraftHandler(useDraftHandler, commentId, action),
				commentContentNotNull,
			),
		);
	});
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const errorHandler = (error: any) => {
	trackOrLogClientError('issue.comment-load-draft', error.message, error);
	return Observable.empty<never>();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const commentEpicWithDraftHander = (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(EDIT_COMMENT_BEGIN)
		.mergeMap((action) => Observable.merge(...loadCommentDrafts(action, store.getState(), true)))
		.catch((error) => errorHandler(error));

const commentEpicWithoutDraftHandler = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	action$: ActionsObservable<any>,
	store: MiddlewareAPI<State>,
) =>
	action$.pipe(
		ofTypeOrBatchType(
			FETCH_ISSUE_SUCCESS,
			REFRESH_ISSUE_SUCCESS,
			FETCH_MORE_COMMENTS_SUCCESS,
			FETCH_MORE_CHILD_COMMENTS_SUCCESS,
		),
		mergeMap((action) =>
			// @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2339 - Property 'comments' does not exist on type 'Record<any, any> | Record<any, any>[]'.
			!action.payload.comments
				? Observable.empty<never>()
				: Observable.merge(...loadCommentDrafts(action, store.getState(), false)),
		),
		catchError((error) => errorHandler(error)),
	);

export default combineEpics(commentEpicWithDraftHander, commentEpicWithoutDraftHandler);
