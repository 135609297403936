import React from 'react';
import { styled, keyframes } from '@compiled/react';
import { token } from '@atlaskit/tokens';

const inProgressColor = token('color.icon.information');
const backgroundColor = token('color.icon.inverse');

const InProgressIcon = () => (
	<SpinningSvg
		xmlns="http://www.w3.org/2000/svg"
		width="100%"
		height="100%"
		fill="none"
		viewBox="0 0 16 16"
	>
		<circle r="7" cx="8" cy="8" fill={backgroundColor} />

		<path
			fill={inProgressColor}
			d="M8 0a8 8 0 100 16A8 8 0 008 0zm0 12.444A4.445 4.445 0 013.556 8a.889.889 0 111.777 0A2.667 2.667 0 008 10.667a.888.888 0 110 1.777zm3.556-3.555a.889.889 0 01-.89-.889A2.667 2.667 0 008 5.333a.889.889 0 010-1.777A4.445 4.445 0 0112.444 8a.888.888 0 01-.888.889z"
		/>
	</SpinningSvg>
);

InProgressIcon.displayName = 'InProgressIcon';
export default InProgressIcon;

const spin = keyframes({
	'0%': {
		transform: 'rotate(0deg)',
	},
	'100%': {
		transform: 'rotate(360deg)',
	},
});

// 1.5s matches the same animation on bitbucket for builds
const spinAnimation = `${spin} 1.5s linear infinite`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinningSvg = styled.svg({
	animation: spinAnimation,
});
