/**
 * @generated SignedSource<<84333e1a98617d626d007b6444ca744d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type mentionedConfluenceLinksSection_issueViewBase$data = {
  readonly confluenceMentionedLinks: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly href: string | null | undefined;
        readonly id: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
    };
    readonly totalCount: number | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "mentionedConfluenceLinksSection_issueViewBase";
};
export type mentionedConfluenceLinksSection_issueViewBase$key = {
  readonly " $data"?: mentionedConfluenceLinksSection_issueViewBase$data;
  readonly " $fragmentSpreads": FragmentRefs<"mentionedConfluenceLinksSection_issueViewBase">;
};

import IssueConfluenceMentionedLinksQuery_graphql from './IssueConfluenceMentionedLinksQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "confluenceMentionedLinks"
],
v1 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": IssueConfluenceMentionedLinksQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "mentionedConfluenceLinksSection_issueViewBase",
  "selections": [
    {
      "alias": "confluenceMentionedLinks",
      "concreteType": "JiraConfluenceRemoteIssueLinkConnection",
      "kind": "LinkedField",
      "name": "__IssueConfluenceContent__confluenceMentionedLinks_connection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "totalCount"
        },
        {
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "hasNextPage"
            },
            {
              "kind": "ScalarField",
              "name": "endCursor"
            }
          ]
        },
        {
          "concreteType": "JiraConfluenceRemoteIssueLinkEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraConfluenceRemoteIssueLink",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "href"
                },
                {
                  "kind": "ScalarField",
                  "name": "__typename"
                }
              ]
            },
            {
              "kind": "ScalarField",
              "name": "cursor"
            }
          ]
        }
      ]
    },
    (v1/*: any*/)
  ],
  "type": "JiraIssue"
};
})();

(node as any).hash = "e750cf3257597badfc45552a400c57da";

export default node;
