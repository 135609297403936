import React, { useMemo, useCallback } from 'react';
import { styled } from '@compiled/react';
import { ForgeScreenEvent } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import { userConsentErrorRegex } from '@atlassian/jira-forge-ui-analytics/src/common/utils/get-error-type/index.tsx';
import { Skeleton } from '@atlassian/jira-forge-ui-async/src/common/ui/skeleton/main.tsx';
import { AsyncCustomField } from '@atlassian/jira-forge-ui-async/src/ui/custom-fields/custom-field/async.tsx';
import { ErrorPanel } from '@atlassian/jira-forge-ui-renderers/src/common/ui/error-panel/index.tsx';
import type { ForgeCustomFieldValue } from '@atlassian/jira-forge-ui-types/src/common/types/contexts/custom-field.tsx';
import type { ForgeUiIssueData } from '@atlassian/jira-forge-ui-types/src/common/types/extension-data.tsx';
import type {
	CollectionType,
	CustomFieldExtension,
	FieldType,
} from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useAnalyticsAttributesContext } from '../analytics/atrributes-context/index.tsx';
import { toForgeFieldValue } from '../value-transformer/index.tsx';
import { FormattedValueContainer } from './styled.tsx';

const skeletonConfig = [{ width: 100 }];

type CustomReadViewProps = {
	extension: CustomFieldExtension;
	extensionData: ForgeUiIssueData | null;
	type: FieldType;
	fieldId: string;
	fieldName: string;
	collection?: CollectionType;
	shouldUseFormatter: boolean;
	onUserConsentGranted?: () => void;
	formatterValue: string | null;
	newValue: ForgeCustomFieldValue;
	error: Error | null;
	isLoadingValueFunction: boolean;
	isLoadingFormatter: boolean;
	onRequestExecuteValueFunction: () => void;
};

export const useRenderCustomReadView = (options: Omit<CustomReadViewProps, 'newValue'>) => {
	const {
		extension,
		extensionData,
		type,
		fieldId,
		fieldName,
		collection,
		shouldUseFormatter,
		onUserConsentGranted,
		formatterValue,
		error,
		isLoadingFormatter,
		isLoadingValueFunction,
		onRequestExecuteValueFunction,
	} = options;

	return useCallback(
		(newValue: ForgeCustomFieldValue) => (
			<CustomReadView
				newValue={newValue}
				extension={extension}
				extensionData={extensionData}
				type={type}
				fieldId={fieldId}
				fieldName={fieldName}
				collection={collection}
				shouldUseFormatter={shouldUseFormatter}
				onUserConsentGranted={onUserConsentGranted}
				formatterValue={formatterValue}
				error={error}
				isLoadingValueFunction={isLoadingValueFunction}
				isLoadingFormatter={isLoadingFormatter}
				onRequestExecuteValueFunction={onRequestExecuteValueFunction}
			/>
		),
		[
			collection,
			error,
			extension,
			extensionData,
			fieldId,
			fieldName,
			formatterValue,
			isLoadingFormatter,
			isLoadingValueFunction,
			onRequestExecuteValueFunction,
			onUserConsentGranted,
			shouldUseFormatter,
			type,
		],
	);
};

const CustomReadView = (props: CustomReadViewProps) => {
	const {
		extension,
		extensionData,
		fieldId,
		fieldName,
		formatterValue,
		shouldUseFormatter,
		type,
		onUserConsentGranted,
		collection,
		newValue,
		isLoadingValueFunction,
		error,
		isLoadingFormatter,
		onRequestExecuteValueFunction,
	} = props;

	const extensionDataOverrides = useMemo(
		() => ({
			...extensionData,
			fieldId,
			fieldType: extension.id,
			fieldName,
			renderContext: 'issue-view',
			...(fg('ditto-fcf-support-new-manifest-on-frontend') && {
				experience: 'issue-view' as const,
			}),
		}),
		[extensionData, fieldId, extension.id, fieldName],
	);

	const extensionPayload = useMemo(
		() => ({
			fieldValue: toForgeFieldValue({
				type,
				// @ts-expect-error - TS2322 - Type 'CollectionType' is not assignable to type '"none" | "list"'.
				collection,
				// @ts-expect-error - TS2322 - Type 'ForgeCustomFieldValue' is not assignable to type 'string | number | string[] | UserOption | Group | StateUser[] | Group[] | { [key: string]: unknown; } | null'.
				value: newValue,
			}),
		}),
		[collection, newValue, type],
	);

	const analyticsAttributes = useAnalyticsAttributesContext();

	const analyticsAttributesOverridesCustom = useMemo(
		() => ({ ...analyticsAttributes, renderMode: 'custom' }),
		[analyticsAttributes],
	);
	const customFieldDataId = 'CustomFieldContentContainer';

	if (error !== null && !userConsentErrorRegex.test(error.message)) {
		return <ErrorPanel error={error} onRetry={onRequestExecuteValueFunction} />;
	}
	if (isLoadingValueFunction || isLoadingFormatter) {
		return <Skeleton skeletonConfig={skeletonConfig} />;
	}

	return (
		<CustomFieldContentContainer data-id={customFieldDataId}>
			{shouldUseFormatter ? (
				<>
					<FormattedValueContainer dangerouslySetInnerHTML={{ __html: formatterValue || '' }} />
					<ForgeScreenEvent attributes={{ ...analyticsAttributes, renderMode: 'formatter' }} />
				</>
			) : (
				<AsyncCustomField
					extension={extension}
					extensionData={extensionDataOverrides}
					extensionPayload={extensionPayload}
					analyticsAttributes={analyticsAttributesOverridesCustom}
					onUserConsentGranted={onUserConsentGranted}
				/>
			)}
		</CustomFieldContentContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CustomFieldContentContainer = styled.div({
	width: '100%',
	'&:focus': {
		outline: 'none',
	},
});
