import isEqual from 'lodash/isEqual';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { getPolarisQuerySelector } from '../../selectors/index.tsx';
import type { State, PolarisQuery } from '../../types.tsx';
import debouncedRouterActions from '../../utils/debounced-router-actions.tsx';

export const setQuery =
	(newQuery: PolarisQuery, updateMethod = 'push') =>
	({ getState }: StoreActionApi<State>) => {
		const currentQuery = getPolarisQuerySelector(getState());
		if (isEqual(omitBy(currentQuery, isUndefined), omitBy(newQuery, isUndefined))) {
			return;
		}
		const updatedQuery = newQuery === undefined ? {} : { ...currentQuery, ...newQuery };
		if (updateMethod === 'push') debouncedRouterActions.push(null, updatedQuery);
		else if (updateMethod === 'replace') debouncedRouterActions.replace(null, updatedQuery);
	};
