/**
 * @generated SignedSource<<60e0568950d3b66874e3b86822c4ac43>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueRestriction_issueViewFoundation_IssueRestrictionWrapper$data = {
  readonly issueRestrictionField: {
    readonly selectedRolesConnection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly roleId: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly issueTypeField: {
    readonly issueType: {
      readonly hierarchy: {
        readonly level: number | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly key: string;
  readonly projectField: {
    readonly project: {
      readonly canSetIssueRestriction: boolean | null | undefined;
      readonly key: string;
    };
  };
  readonly " $fragmentType": "issueRestriction_issueViewFoundation_IssueRestrictionWrapper";
} | null | undefined;
export type issueRestriction_issueViewFoundation_IssueRestrictionWrapper$key = {
  readonly " $data"?: issueRestriction_issueViewFoundation_IssueRestrictionWrapper$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueRestriction_issueViewFoundation_IssueRestrictionWrapper">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "key"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "issueRestriction_issueViewFoundation_IssueRestrictionWrapper",
  "selections": [
    (v0/*: any*/),
    {
      "concreteType": "JiraIssueTypeField",
      "kind": "LinkedField",
      "name": "issueTypeField",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraIssueType",
          "kind": "LinkedField",
          "name": "issueType",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraIssueTypeHierarchyLevel",
              "kind": "LinkedField",
              "name": "hierarchy",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "level"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraProjectField",
        "kind": "LinkedField",
        "name": "projectField",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "concreteType": "JiraProject",
              "kind": "LinkedField",
              "name": "project",
              "plural": false,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": (v0/*: any*/),
                  "action": "LOG",
                  "path": "projectField.project.key"
                },
                {
                  "kind": "ScalarField",
                  "name": "canSetIssueRestriction"
                }
              ]
            },
            "action": "LOG",
            "path": "projectField.project"
          }
        ]
      },
      "action": "LOG",
      "path": "projectField"
    },
    {
      "concreteType": "JiraIssueRestrictionField",
      "kind": "LinkedField",
      "name": "issueRestrictionField",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraRoleConnection",
          "kind": "LinkedField",
          "name": "selectedRolesConnection",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraRoleEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "concreteType": "JiraRole",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "roleId"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssue"
};
})();

(node as any).hash = "f7e11ac144ea52ae68bd893a9b449e6e";

export default node;
