import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/from';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import type { AnalyticsEventPayload, UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/index.tsx';
import { JIRA_SOFTWARE } from '@atlassian/jira-shared-types/src/application.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { OptimisticUiChildIssue } from '../../model/types.tsx';
import createIssue from '../../services/create-issue/index.tsx';
import {
	getBaseUrl,
	getProjectId,
	getParentId,
	getIsClassicSubtaskPanel,
	getIsClassicProjectEpicPanel,
	getIsChildrenIssuesPanel,
} from '../../state/context/selectors.tsx';
import {
	type CreateChildRequestAction,
	CREATE_CHILD_REQUEST,
	createChildSuccess,
	createChildFailure,
} from '../../state/entities/actions.tsx';
import type { State } from '../../state/types.tsx';

const createChildIssue = (
	state: State,
	optimisticUiChildIssue: OptimisticUiChildIssue,
	analyticsEvent: UIAnalyticsEvent,
	additionalAttributes: AnalyticsEventPayload['attributes'] = {},
) => {
	const baseUrl = getBaseUrl(state);
	const projectId = getProjectId(state);
	const parentIssueId = getParentId(state);
	const isClassicSubtaskPanel = getIsClassicSubtaskPanel(state);
	const isChildrenIssuesPanel = getIsChildrenIssuesPanel(state);
	const isClassicProjectEpicPanel = getIsClassicProjectEpicPanel(state);

	if (!projectId || !parentIssueId) {
		throw new Error('Attempted to create a new child issue without a project or parent issue ID');
	}

	const shouldIncludeParentId =
		isClassicSubtaskPanel || isChildrenIssuesPanel || isClassicProjectEpicPanel;
	const createPayload = {
		fields: {
			project: { id: projectId },
			issuetype: { id: optimisticUiChildIssue.issueTypeId },
			summary: optimisticUiChildIssue.issueSummary,
			...(shouldIncludeParentId ? { parent: { id: parentIssueId } } : null),
		},
	};

	return Observable.from(createIssue(baseUrl, createPayload, optimisticUiChildIssue))
		.mergeMap((createdChild) =>
			Observable.of(
				createChildSuccess(
					optimisticUiChildIssue.id,
					createdChild,
					analyticsEvent,
					false,
					additionalAttributes,
				),
			),
		)
		.catch((error) => {
			if (fg('child_issue_creation_failure_analytics')) {
				sendExperienceAnalytics({
					experience: 'issueViewChildIssuesPanel-createIssue',
					wasExperienceSuccesful: false,
					analyticsSource: 'jiraIssueViewChildIssuesPanel',
					application: JIRA_SOFTWARE,
					edition: null,
					additionalAttributes: {
						statusCode: error?.statusCode || 0,
						errorMessage: error?.message || 'UNKNOWN_ERROR',
						traceId: error?.traceId || '',
					},
				});
			}
			return Observable.of(createChildFailure(optimisticUiChildIssue.id, error));
		});
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	action$: ActionsObservable<CreateChildRequestAction>,
	store: MiddlewareAPI<State>,
) =>
	action$.ofType(CREATE_CHILD_REQUEST).mergeMap(({ payload, meta }) => {
		const state = store.getState();
		const { optimisticUiChildIssue } = payload;
		return createChildIssue(
			state,
			optimisticUiChildIssue,
			meta.analyticsEvent,
			meta.additionalAttributes,
		);
	});
