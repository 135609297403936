/** @jsx jsx */
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { css, jsx } from '@compiled/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled
import styled, { keyframes } from 'styled-components';
// TODO: JFP-2824 | Suppressed to enable upgrade to ESLint v9 - please fix this if you can!
// eslint-disable-next-line jira/restricted/@atlaskit+analytics-next
import { AnalyticsContext } from '@atlaskit/analytics-next';
import { useSmartLinkLifecycleAnalytics } from '@atlaskit/link-analytics';
import { SmartCardProvider } from '@atlaskit/link-provider';
import {
	type ActionItem,
	ActionName,
	Card,
	CardAction,
	TitleBlock,
	ElementName,
	SmartLinkPosition,
	SmartLinkSize,
} from '@atlaskit/smart-card';
import { noFocusRing } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { EMBEDDED_CONFLUENCE_MODE } from '@atlassian/embedded-confluence';
import {
	EmbeddedConfluenceSidepanelContainer,
	useEmbeddedConfluenceSidePanel,
} from '@atlassian/jira-confluence-integration-controls/src/controllers/use-embedded-confluence-side-panel/index.tsx';
import { ConfluenceContentTypes } from '@atlassian/jira-confluence-integration-controls/src/controllers/use-embedded-confluence-side-panel/types.tsx';
import { useUserHasConfluenceAccess } from '@atlassian/jira-confluence-integration-controls/src/controllers/use-user-has-confluence-access/index.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { type FlagId, type FlagMessage, toFlagId, useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import type { ShowFlag } from '@atlassian/jira-flags/src/services/types'; // ignore-for-ENGHEALTH-17759
import {
	useIsEmbedPageIssueView,
	useIsFullPageIssueView,
	useIsModal,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import { JIRA_ISSUE_LINKED_MENTIONED_PAGES } from '@atlassian/jira-issue-create-confluence-content/src/common/constants/embedded-confluence-source.tsx';
import { useEmbeddedPageModalEntryPoint } from '@atlassian/jira-issue-create-confluence-content/src/controllers/use-embedded-page-modal-entry-point/index.tsx';
import { useEmbeddedWhiteboardModalEntryPoint } from '@atlassian/jira-issue-create-confluence-content/src/controllers/use-embedded-whiteboard-modal-entry-point/index.tsx';
import { useParentProduct } from '@atlassian/jira-issue-create-confluence-content/src/controllers/use-parent-product/index.tsx';
import type {
	ConfluencePage,
	ConfluenceWhiteboard,
} from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import type { FailedRemoteLink } from '@atlassian/jira-issue-shared-types/src/common/types/remote-link-error-type.tsx';
import {
	useSmartCardProductType,
	useSmartCardIsAIEnabled,
} from '@atlassian/jira-linking-platform-utils/src/index.tsx';
import { generateNanoId } from '@atlassian/jira-uuid/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import messages from '../web-link-line-card-group/line-card/error-message/messages.tsx';
import { smartLinksAttributes } from './smart-link-analytics.tsx';
import type { DispatchProps, Props, StateProps } from './types.tsx';
import uiOptions from './ui-options.tsx';

const getErrorFlagConfiguration = (
	id: FlagId,
	title: FlagMessage,
	onRetry: () => void,
	onCancel: () => void,
): ShowFlag => ({
	messageId: 'issue-view-common-views.smart-link-content.flag.warning',
	messageType: 'transactional',
	id,
	title,
	description: messages.errorBody,
	type: 'warning',
	isAutoDismiss: true,
	actions: [
		{
			content: messages.retryAction,
			onClick: onRetry,
		},
		{
			content: messages.cancelAction,
			onClick: onCancel,
		},
	],
});

const styleOverrides = css({
	height: '40px',
	gap: token('space.100'),
	cursor: 'pointer',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'[data-smart-element-avatar-group]': {
		height: '24px',
		minHeight: '24px',
		maxHeight: '24px',
		width: '24px',
		minWidth: '24px',
		maxWidth: '24px',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'div, span, img, svg': {
			height: '24px',
			minHeight: '24px',
			maxHeight: '24px',
			width: '24px',
			minWidth: '24px',
			maxWidth: '24px',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		span: {
			margin: 0,
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'[data-smart-element-link]': {
		boxShadow: 'none',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
		outline: 'none !important',
		outlineColor: 'inherit',
		color: 'inherit',
		WebkitTapHighlightColor: 'transparent',
		WebkitTouchCallout: 'none',
		WebkitUserSelect: 'none',
		MozUserSelect: 'none',
		MsUserSelect: 'none',
		userSelect: 'none',
	},
});

const SmartLinkContent = (props: Props & DispatchProps & StateProps) => {
	const {
		analyticsLocation,
		canDelete,
		deleteFailed,
		linkId,
		smartLinkId,
		text,
		url,
		onClick,
		onDeleteClick,
		onDeleteWebLinkRetry,
		onDeleteWebLinkCancel,
		loadConfluenceInNewTab,
		page,
		whiteboard,
		hasActivatedConfluenceWithinFourteenDays,
		entryPointWrapper,
	} = props;

	const { entryPointActions: pageEntryPointActions, entryPoint: pageEntrypoint } =
		useEmbeddedPageModalEntryPoint(page, JIRA_ISSUE_LINKED_MENTIONED_PAGES);
	const { entryPointActions: whiteboardEntryPointActions, entryPoint: whiteboardEntryPoint } =
		useEmbeddedWhiteboardModalEntryPoint(whiteboard, JIRA_ISSUE_LINKED_MENTIONED_PAGES);

	const entryPoint = pageEntrypoint || whiteboardEntryPoint;
	const entryPointActions = pageEntryPointActions || whiteboardEntryPointActions;
	const { showFlag, dismissFlag } = useFlagsService();
	const linkAnalytics = useSmartLinkLifecycleAnalytics();
	const deleteFailedOld = useRef(false);

	const isFullIssueView = useIsFullPageIssueView();

	const isModalView = useIsModal();

	const isEmbedView = useIsEmbedPageIssueView();

	const parentProduct = useParentProduct();

	const { hasAccess: hasConfluenceAccess } = useUserHasConfluenceAccess(
		JIRA_ISSUE_LINKED_MENTIONED_PAGES,
	);

	const shouldShowHoverPreview = isFullIssueView || isModalView || isEmbedView;

	const analyticsData = {
		attributes: {
			location: analyticsLocation,
		},
	};

	const showErrorFlag = useCallback(
		(title: FlagMessage, onRetry: () => void, onCancel: () => void) => {
			const flagId = toFlagId(generateNanoId());
			const dismissAndRetry = () => {
				dismissFlag(flagId);
				onRetry();
			};
			const dismissAndCancel = () => {
				dismissFlag(flagId);
				onCancel();
			};

			showFlag(getErrorFlagConfiguration(flagId, title, dismissAndRetry, dismissAndCancel));
		},
		[showFlag, dismissFlag],
	);

	const showDeleteErrorFlag = useCallback(
		() => showErrorFlag(messages.deleteErrorHeading, onDeleteWebLinkRetry, onDeleteWebLinkCancel),
		[onDeleteWebLinkRetry, onDeleteWebLinkCancel, showErrorFlag],
	);

	useEffect(() => {
		if (!deleteFailedOld.current && deleteFailed) {
			showDeleteErrorFlag();
			deleteFailedOld.current = true;
		}
	}, [deleteFailed, showDeleteErrorFlag]);

	/* Initializing Experiment for SidePanel EP */
	const isEligibleForSidePanelEPExp = useMemo(
		() =>
			hasConfluenceAccess &&
			isFullIssueView &&
			!hasActivatedConfluenceWithinFourteenDays &&
			parentProduct !== 'JSM',
		[hasConfluenceAccess, isFullIssueView, hasActivatedConfluenceWithinFourteenDays, parentProduct],
	);

	const [sideBySideEPExperimentConfig, sidePanelEPExperimentfireExposureEvent] =
		UNSAFE_noExposureExp('jira_issue_view_side_by_side_modeless_ep_exp');

	const [, embeddedConfluenceSidePanelActions] = useEmbeddedConfluenceSidePanel(
		JIRA_ISSUE_LINKED_MENTIONED_PAGES,
	);

	const sendDeletedEvent = useCallback(() => {
		linkAnalytics?.linkDeleted(
			{
				url,
				smartLinkId: linkId,
			},
			null,
			{
				...smartLinksAttributes,
				location: analyticsLocation,
			},
		);
	}, [url, linkId, analyticsLocation, linkAnalytics]);

	const cardOnclick = useCallback(
		(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			const isFailedRemoteLink =
				(!page && !whiteboard) ||
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				(page && !!(page as FailedRemoteLink).error) ||
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				(whiteboard && !!(whiteboard as FailedRemoteLink).error);

			const openInModal = () => {
				entryPointActions?.load();
				onClick?.(event);
			};

			if (!loadConfluenceInNewTab && !isFailedRemoteLink && (page || whiteboard)) {
				event.preventDefault();
				if (isEligibleForSidePanelEPExp) {
					sidePanelEPExperimentfireExposureEvent();
					if (sideBySideEPExperimentConfig.get('cohort', 'not-enrolled') === 'experiment') {
						if (page) {
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							const confluencePage = page as ConfluencePage;
							embeddedConfluenceSidePanelActions.openSidePanel({
								contentType: ConfluenceContentTypes.PAGE,
								mode: EMBEDDED_CONFLUENCE_MODE.VIEW_MODE,
								hostname: confluencePage?.hostname,
								url: confluencePage?.href,
								title: confluencePage?.title,
								openInModal,
							});
							onClick?.(event);
							return;
						}
						if (whiteboard && fg('jira_issue_view_modeless_whiteboard')) {
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							const confluenceWhiteboard = whiteboard as ConfluenceWhiteboard;
							if (confluenceWhiteboard?.title) {
								embeddedConfluenceSidePanelActions.openSidePanel({
									contentType: ConfluenceContentTypes.WHITEBOARD,
									hostname: confluenceWhiteboard?.hostname,
									url: confluenceWhiteboard?.href,
									title: confluenceWhiteboard?.title,
									contentId: confluenceWhiteboard?.whiteboardId ?? '',
									openInModal,
								});
								onClick?.(event);
								return;
							}
						}
					}
				}
				openInModal();
			} else {
				onClick?.(event);
			}
		},
		[
			entryPointActions,
			loadConfluenceInNewTab,
			onClick,
			page,
			whiteboard,
			isEligibleForSidePanelEPExp,
			sidePanelEPExperimentfireExposureEvent,
			sideBySideEPExperimentConfig,
			embeddedConfluenceSidePanelActions,
		],
	);

	const onDeleteCallback = useCallback(() => {
		onDeleteClick && onDeleteClick(linkId);
		sendDeletedEvent();
	}, [linkId, onDeleteClick, sendDeletedEvent]);

	const actions: ActionItem[] = useMemo(
		() =>
			canDelete
				? [
						{
							name: ActionName.DeleteAction,
							onClick: onDeleteCallback,
							hideContent: true,
							entryPointWrapper,
						},
					]
				: // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					([] as ActionItem[]),
		[canDelete, onDeleteCallback, entryPointWrapper],
	);

	const smartLink = useMemo(
		() => (
			<Card
				id={linkId}
				key={smartLinkId}
				appearance="block"
				url={url}
				ui={{
					...uiOptions,
					size:
						isVisualRefreshEnabled() && fg('visual-refresh_drop_4')
							? SmartLinkSize.Large
							: SmartLinkSize.Medium,
				}}
				onClick={cardOnclick}
				placeholder=""
				showHoverPreview={shouldShowHoverPreview}
				actionOptions={{
					hide: false,
					exclude: [CardAction.ChangeStatusAction],
				}}
			>
				<TitleBlock
					position={SmartLinkPosition.Center}
					metadata={[
						{
							name: ElementName.ModifiedOn,
						},
						{
							name: ElementName.State,
						},
						{
							name: ElementName.AuthorGroup,
							size: SmartLinkSize.Large,
						},
					]}
					// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides
					css={styleOverrides}
					text={text}
					actions={actions}
					showActionOnHover
					maxLines={1}
					anchorTarget="_blank"
					hideTitleTooltip={shouldShowHoverPreview}
				/>
			</Card>
		),
		[linkId, smartLinkId, url, cardOnclick, shouldShowHoverPreview, text, actions],
	);
	return (
		<AnalyticsContext data={analyticsData}>
			<CardWrapper>{smartLink}</CardWrapper>
			{entryPoint}
		</AnalyticsContext>
	);
};

const ConfluenceContentWrapper = ({ children }: { children: React.ReactNode }) =>
	fg('jira_issue_view_modeless_ep_container') ? (
		<EmbeddedConfluenceSidepanelContainer>{children}</EmbeddedConfluenceSidepanelContainer>
	) : (
		<>{children}</>
	);

const SmartLinkContentWithSmartCardProvider = (props: Props & DispatchProps & StateProps) => {
	const smartCardProviderProduct = useSmartCardProductType();
	const isAIFeatureEnabled = useSmartCardIsAIEnabled();

	return (
		<SmartCardProvider product={smartCardProviderProduct} isAdminHubAIEnabled={isAIFeatureEnabled}>
			<ConfluenceContentWrapper>
				<SmartLinkContent {...props} />
			</ConfluenceContentWrapper>
		</SmartCardProvider>
	);
};

export default SmartLinkContentWithSmartCardProvider;

const animationNameStyles = keyframes({
	'0%': {
		backgroundPosition: '-300px 0',
	},
	'100%': {
		backgroundPosition: '1000px 0',
	},
});

const loadingShimmer = `
  animation-duration: 1.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${animationNameStyles};
  animation-timing-function: linear;
  background-color: ${token('color.background.neutral')};
  background-image: linear-gradient(
      to right,
      ${token('color.background.neutral')} 10%,
      ${token('color.background.neutral.subtle')} 30%,
      ${token('color.background.neutral')} 50%
  );
  background-repeat: no-repeat;
  border-radius: 4px;
`;

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardWrapper = styled.li`
	list-style: none;

	&:last-child {
		${noFocusRing};
		border-bottom-color: transparent;
	}

	&:hover {
		background: ${token('color.background.neutral.subtle.hovered')};
		text-decoration: none;
	}

	border-bottom: 1px solid ${token('color.border')};
	box-shadow: none;
	background: ${token('color.background.neutral.subtle')};
	padding: 0 ${token('space.075')};
	margin: 0 ${token('space.025')};
	border-radius: 3px;

	.smart-link-loading-placeholder {
		margin-left: -0.5rem;
		height: 20px;
		border-bottom: solid ${token('color.background.neutral.subtle')} 10px;
		border-top: solid ${token('color.background.neutral.subtle')} 10px;
		border-left: solid ${token('color.background.neutral.subtle')} 14px;
		border-right: solid ${token('color.background.neutral.subtle')} 14px;
		width: calc(100% + -1rem);
		display: block;
		${loadingShimmer}
	}
`;
