import React from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import ThreeAvatars from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/other/components/three-avatars/index.tsx';

type Props = {
	text: string;
};

export const List = (props: Props) => {
	const { text } = props;

	return (
		<EmptyListContainer>
			<Box xcss={imageWrapperStyles}>
				<ThreeAvatars alt="" width={128} height={55} />
			</Box>

			{text}
		</EmptyListContainer>
	);
};

export default List;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyListContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	marginTop: token('space.250'),
	marginRight: 0,
	marginBottom: token('space.250'),
	marginLeft: 0,
});

const imageWrapperStyles = xcss({
	marginBottom: 'space.150',
});
