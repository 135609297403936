import type { RemoteJiraIssue } from '@atlassian/jira-polaris-remote-issue/src/controllers/crud/types.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { ServerTeam } from '@atlassian/jira-issue-field-team/src/common/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';

const fetchTeamAvatarUrl = async (teamId: string, cloudId: string): Promise<string | undefined> => {
	try {
		return fetchJson<ServerTeam>(`/gateway/api/v4/teams/${teamId}?siteId=${cloudId}`).then(
			(response) => response.smallAvatarImageUrl,
		);
	} catch (error) {
		return undefined;
	}
};

/** Temporary workaround until the backend is updated to return `avatarUrl` for new issues
 * POL-14612 is the ticket for BE changes, POL-14630 is the one for the clean-up
 */
export const getTeamAvatarUrlForIssue = async (
	newIssue: RemoteJiraIssue,
	teamFieldKey?: string,
	cloudId?: string,
) => {
	if (!fg('polaris_team_field_integration') || !teamFieldKey || !cloudId) {
		return;
	}

	const teamValue = newIssue.fields[teamFieldKey];
	if (!teamValue || !Object.hasOwn(teamValue, 'id') || teamValue.avatarUrl) {
		return;
	}

	return fetchTeamAvatarUrl(teamValue.id, cloudId);
};
