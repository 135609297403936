import React from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';

import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import GenericErrorComponent from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/error/components/generic-error/index.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import messages from './messages.tsx';

const ErrorImage = () => {
	const intl = useIntl();
	return (
		<DialogContainer>
			<Content>
				<Box xcss={imageWrapperStyles}>
					<GenericErrorComponent alt="" width={50} height={40} />
				</Box>

				<ErrorMessageStyling>{intl.formatMessage(messages.errorMessage)}</ErrorMessageStyling>
			</Content>
		</DialogContainer>
	);
};

export default ErrorImage;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DialogContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 25}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Content = styled.div({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorMessageStyling = styled.p({
	color: token('color.text.subtlest'),
	textAlign: 'center',
});

const imageWrapperStyles = xcss({
	marginTop: 'space.150',
	marginBottom: 'space.150',
});
