/**
 * @generated SignedSource<<296ea03a4a64bb88d748e099fc822493>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutTimeTrackingAddModal_IssueViewTimeTrackingAddModalInner$data = {
  readonly __typename: "JiraTimeTrackingField";
  readonly __id: string;
  readonly fieldId: string;
  readonly id: string;
  readonly issue: {
    readonly id: string;
    readonly issueId?: string;
  } | null | undefined;
  readonly originalEstimate: {
    readonly timeInSeconds: AGG$Long | null | undefined;
  } | null | undefined;
  readonly remainingEstimate: {
    readonly timeInSeconds: AGG$Long | null | undefined;
  } | null | undefined;
  readonly timeSpent: {
    readonly timeInSeconds: AGG$Long | null | undefined;
  } | null | undefined;
  readonly timeTrackingSettings: {
    readonly workingDaysPerWeek: number | null | undefined;
    readonly workingHoursPerDay: number | null | undefined;
  } | null | undefined;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutTimeTrackingUtils_IssueViewTimeTrackingController">;
  readonly " $fragmentType": "ui_issueViewLayoutTimeTrackingAddModal_IssueViewTimeTrackingAddModalInner";
};
export type ui_issueViewLayoutTimeTrackingAddModal_IssueViewTimeTrackingAddModalInner$key = {
  readonly " $data"?: ui_issueViewLayoutTimeTrackingAddModal_IssueViewTimeTrackingAddModalInner$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutTimeTrackingAddModal_IssueViewTimeTrackingAddModalInner">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
},
v1 = [
  {
    "kind": "ScalarField",
    "name": "timeInSeconds"
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrconeeventimprovementsenabledrelayprovider"
    }
  ],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutTimeTrackingAddModal_IssueViewTimeTrackingAddModalInner",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutTimeTrackingUtils_IssueViewTimeTrackingController"
    },
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "issue",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrconeeventimprovementsenabledrelayprovider",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "issueId"
            }
          ]
        }
      ]
    },
    {
      "concreteType": "JiraEstimate",
      "kind": "LinkedField",
      "name": "originalEstimate",
      "plural": false,
      "selections": (v1/*: any*/)
    },
    {
      "concreteType": "JiraEstimate",
      "kind": "LinkedField",
      "name": "timeSpent",
      "plural": false,
      "selections": (v1/*: any*/)
    },
    {
      "concreteType": "JiraEstimate",
      "kind": "LinkedField",
      "name": "remainingEstimate",
      "plural": false,
      "selections": (v1/*: any*/)
    },
    {
      "concreteType": "JiraTimeTrackingSettings",
      "kind": "LinkedField",
      "name": "timeTrackingSettings",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "workingDaysPerWeek"
        },
        {
          "kind": "ScalarField",
          "name": "workingHoursPerDay"
        }
      ]
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__id"
        }
      ]
    }
  ],
  "type": "JiraTimeTrackingField"
};
})();

(node as any).hash = "0f52ca8e0ff0d7170f0bfce94182e3d2";

export default node;
