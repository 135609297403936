/**
 * @generated SignedSource<<690000fc6d0a3c570cd5f81c44e0c58a>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewCover_rootRelayFragment$data = {
  readonly mediaClientId: string | null | undefined;
  readonly mediaExternalEndpointUrl: string | null | undefined;
  readonly " $fragmentType": "ui_issueViewCover_rootRelayFragment";
};
export type ui_issueViewCover_rootRelayFragment$key = {
  readonly " $data"?: ui_issueViewCover_rootRelayFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewCover_rootRelayFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cloudId"
    }
  ],
  "kind": "Fragment",
  "name": "ui_issueViewCover_rootRelayFragment",
  "selections": [
    {
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "mediaClientId"
    },
    {
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "mediaExternalEndpointUrl"
    }
  ],
  "type": "JiraQuery"
};
})();

(node as any).hash = "739d0457193ec9e2293319aa0f58d570";

export default node;
