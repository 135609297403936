import React from 'react';
import { xcss, Pressable } from '@atlaskit/primitives';

export const PressableCTA = ({
	children,
	...restProps
}: { children: React.ReactNode } & React.ComponentProps<typeof Pressable>) => {
	return (
		<Pressable xcss={pressableCTA} {...restProps}>
			{children}
		</Pressable>
	);
};

const pressableCTA = xcss({
	background: 'none',
	padding: '0',
	color: 'color.text.subtle',
	fontSize: 'font.body.small',
	fontWeight: 'font.weight.medium',
});
