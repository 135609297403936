import type { ClipboardTextParser } from './types.tsx';
import { ClipboardValidationError } from './clipboard-validation-error.tsx';

/** Text parser for component types that do not support parsing from a string value. */
export const defaultClipboardTextParser: ClipboardTextParser<never> = () => {
	throw ClipboardValidationError.pasteUnsupportedFormat('text/plain');
};

/** Simple text parser for component types that support a primitive string value. */
export const stringClipboardTextParser: ClipboardTextParser<string> = (plainText) => plainText;
