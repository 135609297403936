import React, { useRef } from 'react';

import { v4 as uuid } from 'uuid';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { Box, xcss } from '@atlaskit/primitives';
import { getEmptyADF } from '@atlaskit/adf-utils/empty-adf';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useTriggerIssueViewModal } from '@atlassian/jira-open-issue-view-modal-controller/src/index.tsx';
import { useDescriptionField } from '@atlassian/jira-issue-field-rich-text/src/services/index.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { getADFCharacterCount } from '@atlassian/jira-issue-view-common/src/utils/adf-utils/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useGlobalRefStoreActions } from '@atlassian/jira-refs-store/src/index.tsx';
import SquareAddIconButton from '../../../../button/square-add-icon-button.tsx';
import type { AddChildProps } from './types.tsx';
import messages from './messages.tsx';

const AddChildOld = ({ isClassicSubtaskPanel = false, onClick }: AddChildProps) => {
	const { formatMessage } = useIntl();
	const label = isClassicSubtaskPanel ? messages.createSubtaskLabel : messages.createChildLabel;
	const [, { setIssueCreateSessionId }] = useTriggerIssueViewModal();
	const [, { register }] = useGlobalRefStoreActions();
	const addChildRef = useRef(null);

	const handleOnClick = (
		e: React.MouseEvent<HTMLElement>,
		analyticsEvent: UIAnalyticsEvent,
	): void => {
		const sessionId = uuid();
		setIssueCreateSessionId(sessionId);
		onClick(e, analyticsEvent, sessionId);
		fg('jfp-a11y-team-issue-view-child-issue-panel-focus') &&
			register('quick-add-items.add-child-subtask', addChildRef.current);
	};

	return (
		<ErrorBoundary prefixOverride="issue" id="Safe(AddChild)">
			<Box xcss={buttonWrapperStyles}>
				<SquareAddIconButton
					onClick={handleOnClick}
					label={formatMessage(label)}
					{...(fg('jfp-a11y-team-issue-view-child-issue-panel-focus') && { ref: addChildRef })}
				/>
			</Box>
		</ErrorBoundary>
	);
};

const AddChildNew = ({ isClassicSubtaskPanel = false, onClick }: AddChildProps) => {
	const issueKey = useIssueKey();
	const [{ value }] = useDescriptionField({ issueKey });
	const { formatMessage } = useIntl();
	const label = isClassicSubtaskPanel ? messages.createSubtaskLabel : messages.createChildLabel;
	const [, { setIssueCreateSessionId }] = useTriggerIssueViewModal();
	const [, { register }] = useGlobalRefStoreActions();
	const addChildRef = useRef(null);

	const handleOnClick = (
		e: React.MouseEvent<HTMLElement>,
		analyticsEvent: UIAnalyticsEvent,
	): void => {
		const sessionId = uuid();
		setIssueCreateSessionId(sessionId);
		const hasDescription = value != null && JSON.stringify(value) !== JSON.stringify(getEmptyADF());
		fg('jfp-a11y-team-issue-view-child-issue-panel-focus') &&
			register('quick-add-items.add-child-subtask', addChildRef.current);
		if (fg('add_description_range_to_add_child_click')) {
			const descriptionLength = value ? getADFCharacterCount(value) : 0;
			onClick(e, analyticsEvent, sessionId, hasDescription, descriptionLength);
			return;
		}
		onClick(e, analyticsEvent, sessionId, hasDescription);
	};

	return (
		<ErrorBoundary prefixOverride="issue" id="Safe(AddChild)">
			<Box xcss={buttonWrapperStyles}>
				<SquareAddIconButton
					onClick={handleOnClick}
					label={formatMessage(label)}
					{...(fg('jfp-a11y-team-issue-view-child-issue-panel-focus') && { ref: addChildRef })}
				/>
			</Box>
		</ErrorBoundary>
	);
};

export const AddChild = componentWithFG(
	// NOTE: add_description_range_to_add_child_click is another FG used within AddChildNew, factor it when cleaning new_attributes_for_child_issue_create_click
	'new_attributes_for_child_issue_create_click',
	AddChildNew,
	AddChildOld,
);

const buttonWrapperStyles = xcss({
	marginLeft: 'space.075',
});
