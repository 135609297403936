import head from 'lodash/head';
import indexOf from 'lodash/indexOf';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	type FieldValueFilter,
	type NumericFieldFilter,
	type Filter,
	CONNECTION_FIELD_FILTER,
	NUMERIC_FIELD_FILTER,
	INTERVAL_FIELD_FILTER,
	GENERIC_FIELD_FILTER,
	TEXT_FIELD_FILTER,
} from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import {
	createGetFieldFilter,
	createGetNumericFilter,
	getQuickSearchFilter,
	getCurrentViewFilter,
} from '../../selectors/filters.tsx';
import { isCurrentViewAutosaveEnabled } from '../../selectors/view/autosave/index.tsx';
import type { State, Props } from '../../types.tsx';
import { saveViewWithAutoSave } from '../save/index.tsx';
import { fireViewUpdatedEvent } from '../utils/analytics.tsx';
import { updateViewState } from '../utils/state/index.tsx';
import { currentViewFilter } from '../utils/views/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { updateIntervalFilter } from './interval';

export const clearAllFilters =
	(onSuccess?: () => void, onError?: (error: Error) => void): Action<State, Props> =>
	async ({ getState, setState, dispatch }, props) => {
		const state = getState();
		const { currentViewSlug, createAnalyticsEvent } = props;
		const viewMutation = (view: View): View => {
			const isAutosaveEnabled = isCurrentViewAutosaveEnabled(state, props);

			return {
				...view,
				filter: isAutosaveEnabled ? [] : view.filter,
				modified: isAutosaveEnabled,
				draft: {
					...view.draft,
					filter: [],
				},
			};
		};

		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(currentViewSlug),
			viewMutation,
		);

		if (changedView) {
			setState({ viewSets });
			dispatch(
				saveViewWithAutoSave(changedView.id, (view: View | undefined) => {
					if (view && view.saveError) {
						onError?.(view.saveError);
					} else if (view && !view.saveError) {
						onSuccess?.();
					}
				}),
			);

			fireViewUpdatedEvent(createAnalyticsEvent, changedView, {
				updatedItems: [{ name: 'filter' }],
			});
		}
	};

export const updateQuickSearchFilter =
	(
		values: {
			stringValue: string | undefined;
		}[],
	): Action<State, Props> =>
	async ({ getState, setState }, props) => {
		const state = getState();
		const isAutosaveEnabled = isCurrentViewAutosaveEnabled(state, props);

		const viewMutation = (view: View): View => {
			const quickSearchFilter = getQuickSearchFilter(state, props);
			const currentFilter = getCurrentViewFilter(state, props);
			const index = indexOf(currentFilter, quickSearchFilter);

			const newValue = head(values.map(({ stringValue }) => stringValue)) ?? '';
			const currentValue =
				head(quickSearchFilter.values.map(({ stringValue }) => stringValue)) ?? '';

			if (currentValue === newValue) {
				return view;
			}

			if (index === -1) {
				const newFilters = [...currentFilter, { ...quickSearchFilter, values }];
				return {
					...view,
					filter: isAutosaveEnabled ? newFilters : view.filter,
					draft: {
						...view.draft,
						filter: newFilters,
					},
				};
			}
			const newFilters = [...currentFilter];
			if (newValue !== '') {
				newFilters.splice(index, 1, { ...quickSearchFilter, values });
			} else {
				newFilters.splice(index, 1);
			}

			return {
				...view,
				filter: isAutosaveEnabled ? newFilters : view.filter,
				draft: {
					...view.draft,
					filter: newFilters,
				},
			};
		};

		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(props.currentViewSlug),
			viewMutation,
		);

		if (changedView && viewSets !== getState().viewSets) {
			setState({ viewSets });
		}
	};

export const updateFieldFilter =
	(
		filter: FieldValueFilter,
		onSuccess?: () => void,
		onError?: (error: Error) => void,
	): Action<State, Props> =>
	async ({ getState, setState, dispatch }, props) => {
		const state = getState();
		const isAutosaveEnabled = isCurrentViewAutosaveEnabled(state, props);

		const getFieldFilter = createGetFieldFilter(filter.field);
		const fieldFilter = getFieldFilter(state, props);
		const currentFilter = getCurrentViewFilter(state, props);

		const newFilters =
			fieldFilter === undefined
				? [...currentFilter, filter]
				: currentFilter.map((f) =>
						f.type === GENERIC_FIELD_FILTER && f.field === filter.field ? filter : f,
					);

		const viewMutation = (view: View): View => {
			return {
				...view,
				filter: isAutosaveEnabled ? newFilters : view.filter,
				modified: isAutosaveEnabled,
				draft: {
					...view.draft,
					filter: newFilters,
				},
			};
		};

		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(props.currentViewSlug),
			viewMutation,
		);

		if (changedView) {
			setState({ viewSets });

			dispatch(
				saveViewWithAutoSave(changedView.id, (view: View | undefined) => {
					if (view && view.saveError) {
						onError?.(view.saveError);
					} else if (view && !view.saveError) {
						onSuccess?.();
					}
				}),
			);

			fireViewUpdatedEvent(props.createAnalyticsEvent, changedView, {
				updatedItems: [{ name: 'filter' }],
			});
		}
	};

export const clearFieldFilter =
	(
		field: FieldKey,
		onSuccess?: () => void,
		onError?: (error: Error) => void,
	): Action<State, Props> =>
	async ({ getState, setState, dispatch }, props) => {
		const state = getState();
		const isAutosaveEnabled = isCurrentViewAutosaveEnabled(state, props);

		const getFieldFilter = createGetFieldFilter(field);
		const fieldFilter = getFieldFilter(state, props);

		if (fieldFilter === undefined) {
			return;
		}

		const currentFilter = getCurrentViewFilter(state, props);
		const newFilters = currentFilter.filter(
			(f) => f.type !== GENERIC_FIELD_FILTER || f.field !== field,
		);

		const viewMutation = (view: View): View => {
			return {
				...view,
				filter: isAutosaveEnabled ? newFilters : view.filter,
				modified: isAutosaveEnabled,
				draft: {
					...view.draft,
					filter: newFilters,
				},
			};
		};

		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(props.currentViewSlug),
			viewMutation,
		);

		if (changedView) {
			setState({ viewSets });

			dispatch(
				saveViewWithAutoSave(changedView.id, (view: View | undefined) => {
					if (view && view.saveError) {
						onError?.(view.saveError);
					} else if (view && !view.saveError) {
						onSuccess?.();
					}
				}),
			);

			fireViewUpdatedEvent(props.createAnalyticsEvent, changedView, {
				updatedItems: [{ name: 'filter' }],
			});
		}
	};

export const clearFilter =
	(
		field: FieldKey,
		onSuccess?: () => void,
		onError?: (error: Error) => void,
	): Action<State, Props> =>
	async ({ getState, setState, dispatch }, props) => {
		const state = getState();
		const isAutosaveEnabled = isCurrentViewAutosaveEnabled(state, props);

		const currentFilter = getCurrentViewFilter(state, props);
		const newFilters = currentFilter.filter((f) =>
			fg('jpd_issues_relationships')
				? (f.type !== GENERIC_FIELD_FILTER &&
						f.type !== NUMERIC_FIELD_FILTER &&
						f.type !== INTERVAL_FIELD_FILTER &&
						f.type !== CONNECTION_FIELD_FILTER) ||
					f.field !== field
				: (f.type !== GENERIC_FIELD_FILTER &&
						f.type !== NUMERIC_FIELD_FILTER &&
						f.type !== INTERVAL_FIELD_FILTER) ||
					f.field !== field,
		);

		const viewMutation = (view: View): View => {
			return {
				...view,
				filter: isAutosaveEnabled ? newFilters : view.filter,
				modified: isAutosaveEnabled,
				draft: {
					...view.draft,
					filter: newFilters,
				},
			};
		};

		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(props.currentViewSlug),
			viewMutation,
		);

		if (changedView) {
			setState({ viewSets });

			dispatch(
				saveViewWithAutoSave(changedView.id, (view: View | undefined) => {
					if (view && view.saveError) {
						onError?.(view.saveError);
					} else if (view && !view.saveError) {
						onSuccess?.();
					}
				}),
			);

			fireViewUpdatedEvent(props.createAnalyticsEvent, changedView, {
				updatedItems: [{ name: 'filter' }],
			});
		}
	};

export const updateNumericFilter =
	(
		filter: NumericFieldFilter,
		onSuccess?: () => void,
		onError?: (error: Error) => void,
	): Action<State, Props> =>
	async ({ getState, setState, dispatch }, props) => {
		const state = getState();
		const isAutosaveEnabled = isCurrentViewAutosaveEnabled(state, props);

		const getNumericFilter = createGetNumericFilter(filter.field);
		const numericFilter = getNumericFilter(state, props);
		const currentFilter = getCurrentViewFilter(state, props);
		const index = indexOf(currentFilter, numericFilter);

		const newFilters = [...currentFilter];
		if (index === -1) {
			newFilters.push(filter);
		} else {
			newFilters.splice(index, 1, filter);
		}

		const viewMutation = (view: View): View => {
			return {
				...view,
				filter: isAutosaveEnabled ? newFilters : view.filter,
				modified: isAutosaveEnabled,
				draft: {
					...view.draft,
					filter: newFilters,
				},
			};
		};

		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(props.currentViewSlug),
			viewMutation,
		);

		if (changedView) {
			setState({ viewSets });

			dispatch(
				saveViewWithAutoSave(changedView.id, (view: View | undefined) => {
					if (view && view.saveError) {
						onError?.(view.saveError);
					} else if (view && !view.saveError) {
						onSuccess?.();
					}
				}),
			);
		}
	};

export const updateFilters =
	(
		filters: Filter[],
		onSuccess?: () => void,
		onError?: (error: Error) => void,
	): Action<State, Props> =>
	async ({ getState, setState, dispatch }, props) => {
		const state = getState();
		const isAutosaveEnabled = isCurrentViewAutosaveEnabled(state, props);

		const currentFilter = getCurrentViewFilter(state, props);
		const newFilters = [...currentFilter];

		filters.forEach((updatedFilter) => {
			const found = currentFilter.find(
				(f) =>
					(f.type === updatedFilter.type && f.type === TEXT_FIELD_FILTER) ||
					(f.type !== TEXT_FIELD_FILTER &&
						f.type === updatedFilter.type &&
						f.field === updatedFilter.field),
			);
			const index = indexOf(currentFilter, found);

			if (index === -1) {
				newFilters.push(updatedFilter);
			}
			newFilters.splice(index, 1, updatedFilter);
		});

		const viewMutation = (view: View): View => {
			return {
				...view,
				filter: isAutosaveEnabled ? newFilters : view.filter,
				modified: isAutosaveEnabled,
				draft: {
					...view.draft,
					filter: newFilters,
				},
			};
		};

		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(props.currentViewSlug),
			viewMutation,
		);

		if (changedView) {
			setState({ viewSets });

			dispatch(
				saveViewWithAutoSave(changedView.id, (view: View | undefined) => {
					if (view && view.saveError) {
						onError?.(view.saveError);
					} else if (view && !view.saveError) {
						onSuccess?.();
					}
				}),
			);
		}
	};
