/** @jsx jsx */
import React, {
	type ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
	Fragment,
	type KeyboardEvent,
} from 'react';
import { jsx } from '@compiled/react';
import merge from 'lodash/merge';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import traceUFOPress from '@atlaskit/react-ufo/trace-press';
import { Box, Flex, Pressable, xcss } from '@atlaskit/primitives';
import Select, {
	PopupSelect,
	components,
	type MenuListComponentProps,
	type OptionProps,
	type OptionType,
} from '@atlaskit/select';
import { token } from '@atlaskit/tokens';
import Blanket from '@atlassian/jira-common-blanket/src/view.tsx';
import EnterEscapeHandler from '@atlassian/jira-common-components-enter-escape-handler/src/index.tsx';
import type { ActionMeta } from '@atlassian/jira-common-components-picker/src/model.tsx';
import { statusCategoryForId } from '@atlassian/jira-common-constants/src/status-categories.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { AnalyticsEventToProps, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { GroupComponentWithStickyFooter } from '@atlassian/jira-select-with-footer-options/src/GroupComponentWithStickyFooter.tsx';
import type { FooterOption } from '@atlassian/jira-select-with-footer-options/src/utils.tsx';
import {
	AtlaskitSelectInlineTabActivator,
	type AtlaskitSelectInlineTabActivatorProps,
} from '@atlassian/jira-inline-config-buttons-for-select/src/AtlaskitSelectInlineTabActivator.tsx';
import { OptionWithButtonStyle } from '@atlassian/jira-select-with-footer-options/src/OptionWithButtonStyle.tsx';
import {
	type StatusTransition,
	type StatusTransitionOption,
	type ModifierExtended,
	CREATE_STATUS_OPTION_TYPE,
	MANAGE_WORKFLOW_OPTION_TYPE,
} from '../../common/types.tsx';
import { WrappedStatusLozenge } from '../../common/ui/status-lozenge/index.tsx';
import {
	getFilteredOrderedTransitions,
	isOnlyGlobalTransitions,
	transformOptions,
} from '../../common/utils.tsx';
import { CommandPaletteStatusView } from './command-palette/index.tsx';
import { CreateStatusButton } from './configure-status/CreateStatusButton.tsx';
import { EditStatusButton } from './configure-status/EditStatusButton.tsx';
import messages from './messages.tsx';
import { LoadingSpinner } from './spinner/index.tsx';
import { StatusButton } from './status-button/index.tsx';
import { Transition } from './transition/index.tsx';
import type { Props, SelectTarget, SelectTargetRender } from './types.tsx';
import { OptionComponent, useOptionsWithFooterActions } from './utils.tsx';
import {
	OptionWithInlineConfigButtons,
	type SelectOption,
} from './configure-status/OptionWithInlineConfigButtons.tsx';
import { CreateStatusOption } from './configure-status/CreateStatusOption.tsx';

const defaultPopperProps = {
	modifiers: [
		{
			name: 'offset',
			options: {
				offset: [0, 8],
			},
		},
		{
			name: 'preventOverflow',
			options: {
				padding: 5,
				rootBoundary: 'document',
			},
		},
	],
	// makes it work inside modal-dialog
	strategy: 'fixed',
};

export const PopupSelectWithAnalytics = AnalyticsEventToProps('statusField', {
	onOpen: 'openDropdown',
	onChange: 'transitionSelected',
})(PopupSelect<OptionType, false, ModifierExtended>);

export const SelectWithAnalytics = AnalyticsEventToProps('statusField', {
	onOpen: 'openDropdown',
	onChange: 'transitionSelected',
})(Select);

const isOptionDisabled = (option: StatusTransitionOption | FooterOption) =>
	'transition' in option && option.transition.isDisabled;

export const StatusView = ({
	forceNoLabels = false,
	isEditable = true,
	isSaving = false,
	isLoading,
	error,
	value,
	transitions,
	appearance,
	footer,
	isDefaultSelect = false,
	selectedTransition,
	onChange = noop,
	onOpen = noop,
	onClose = noop,
	onSuccess,
	customNoOptionsMessage,
	statusButtonRef,
	popperProps,
	registerInCommandPalette,
	onFetch,
	fieldId,
	showConfigureStatusButtons,
	projectId,
	projectKey,
	projectType,
	issueTypeId,
	// for testing
	defaultOpen = false,
	shouldFitContainer = true,
	onUpdateStatusMutation,
	onStatusCreatedOrUpdated,
	onOpenManageWorkflow,
	configureStatusSource,
	autofocus = false,
}: Props) => {
	const [isOpen, setIsOpen] = useState(false);
	// TODO: (JGFENG-910) Use existing isOpen state
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const lastKey = useRef<string | null>(null);

	const childWrapperRef = useRef<unknown>(null);
	const selectRef = useRef<HTMLElement | null>(null);
	const wasChanged = useRef<boolean>(false);

	const { formatMessage } = useIntl();

	const previousIsSaving = usePrevious(isSaving);
	const previousTransitions = usePrevious(transitions);
	const previousIsOpen = usePrevious(isOpen);

	const wasFetchSuccessful = transitions != null;
	const filteredAndOrderedTransitions = getFilteredOrderedTransitions(transitions, value.id);
	const isGlobalOnly = isOnlyGlobalTransitions(filteredAndOrderedTransitions || []);
	const showLabel = !(forceNoLabels || isGlobalOnly);

	const [inlineStatusConfigExp] = UNSAFE_noExposureExp('inline_status_add_and_rename_in_gic');
	const isInlineStatusExperimentEnabled = inlineStatusConfigExp.get(
		'isInlineStatusConfigEnabled',
		false,
	);
	const isConfigureStatusAvailable =
		isInlineStatusExperimentEnabled && showConfigureStatusButtons && projectType && projectKey;

	const createStatusButtonRef = useRef<HTMLButtonElement>(null);
	const menuListRef = useRef<HTMLDivElement>(null);

	const [isConfigureStatusModalOpen, setIsConfigureStatusModalOpen] = useState(false);

	useEffect(() => {
		if (autofocus && fg('focus_status_select_after_status_creation_gic')) {
			selectRef.current?.focus?.();
		}
	}, [autofocus]);

	const formatTransitionOptionLabel = useCallback(
		(
			option: Pick<StatusTransitionOption, 'transition'>,
			transitionProps?: {
				addTransitionArrowSpacing?: boolean;
			},
		) => {
			if (
				!fg('inline_config_a11y_improvements') &&
				showConfigureStatusButtons &&
				projectId &&
				projectKey
			) {
				const status = {
					id: option.transition.to.id,
					statusName: option.transition.to.name,
					statusCategory: statusCategoryForId(option.transition.to.statusCategory.id),
				};
				return (
					<Transition
						transition={option.transition}
						hasLabel={showLabel}
						addTransitionArrowSpacing={transitionProps?.addTransitionArrowSpacing}
						contentAfter={
							option.transition.id !== selectedTransition?.id && (
								<EditStatusButton
									status={status}
									projectId={projectId}
									// issueTypeId is not immediately available on first render
									// temporary empty string will trigger a loading state within the edit status modal
									issueTypeId={issueTypeId ?? ''}
									projectKey={projectKey}
									statusNames={transitions?.map((transition) => transition.to.name)}
									onClick={() => setIsConfigureStatusModalOpen?.(true)}
									onClose={() => setIsConfigureStatusModalOpen?.(false)}
									onStatusUpdated={onStatusCreatedOrUpdated}
									source={configureStatusSource}
								/>
							)
						}
						testId="issue-field-status.ui.status-view.transition"
					/>
				);
			}
			return (
				<Transition
					transition={option.transition}
					hasLabel={showLabel}
					addTransitionArrowSpacing={transitionProps?.addTransitionArrowSpacing}
					testId="issue-field-status.ui.status-view.transition"
				/>
			);
		},
		[
			showConfigureStatusButtons,
			projectId,
			projectKey,
			showLabel,
			selectedTransition,
			issueTypeId,
			transitions,
			onStatusCreatedOrUpdated,
			configureStatusSource,
		],
	);

	const formatTransitionOptionLabelWithCreateStatus = useCallback(
		(
			option: StatusTransitionOption | FooterOption,
			transitionProps?: {
				addTransitionArrowSpacing?: boolean;
			},
		) => {
			if (isConfigureStatusAvailable && projectId && issueTypeId && 'value' in option) {
				switch (option.value) {
					case CREATE_STATUS_OPTION_TYPE:
						return fg('inline_config_a11y_improvements') ? (
							<CreateStatusOption
								projectId={projectId}
								projectKey={projectKey}
								projectType={projectType}
								issueTypeId={issueTypeId}
								statusNames={transitions?.map((transition) => transition?.to?.name)}
								onClick={() => setIsConfigureStatusModalOpen(true)}
								onClose={() => setIsConfigureStatusModalOpen(false)}
								onStatusCreated={onStatusCreatedOrUpdated}
								buttonRef={createStatusButtonRef}
								source={configureStatusSource}
							/>
						) : (
							<CreateStatusButton
								projectId={projectId}
								projectKey={projectKey}
								projectType={projectType}
								issueTypeId={issueTypeId}
								statusNames={transitions?.map((transition) => transition?.to?.name)}
								onClick={() => setIsConfigureStatusModalOpen(true)}
								onClose={() => setIsConfigureStatusModalOpen(false)}
								onStatusCreated={onStatusCreatedOrUpdated}
								isPressable
								buttonRef={createStatusButtonRef}
								source={configureStatusSource}
							/>
						);
					case MANAGE_WORKFLOW_OPTION_TYPE:
						return fg('inline_config_a11y_improvements') ? (
							<Box xcss={manageWorkflowStyles}>{option.label}</Box>
						) : (
							<Pressable xcss={manageWorkflowStyles}>{option.label}</Pressable>
						);
					default:
						break;
				}
			}
			return 'transition' in option && formatTransitionOptionLabel(option, transitionProps);
		},
		[
			isConfigureStatusAvailable,
			projectId,
			issueTypeId,
			formatTransitionOptionLabel,
			projectKey,
			projectType,
			transitions,
			onStatusCreatedOrUpdated,
			configureStatusSource,
		],
	);

	// specifically for screen readers
	const getTransitionOptionLabel = (option: StatusTransitionOption | FooterOption) => {
		if ('transition' in option) {
			return option.transition.isGlobal
				? formatMessage(messages.transitionTo, { statusName: option.label })
				: `${option.transition.name} → ${option.transition.to.name}`;
		}
	};

	const mergedPopperProps = useMemo(() => merge(defaultPopperProps, popperProps), [popperProps]);

	// componentWillUpdate
	useEffect(() => {
		if (previousIsSaving === true && isSaving === false) {
			childWrapperRef.current &&
				// @ts-expect-error - TS2571 - Object is of type 'unknown'.
				childWrapperRef.current.children.length > 0 &&
				// @ts-expect-error - TS2571 - Object is of type 'unknown'.
				childWrapperRef.current.children[0].focus();
		}
	}, [isOpen, isSaving, previousIsOpen, previousIsSaving, previousTransitions, transitions]);

	const handleChangeStatus = useCallback(
		(transition: StatusTransition, analyticsEvent: UIAnalyticsEvent) => {
			const { hasScreen } = transition;
			wasChanged.current = true;

			fireUIAnalytics(analyticsEvent, 'transitionSelectedStatusField', {
				hasScreen,
			});

			if (fg('obsrve-2239-traceid-errorflag-issue-transition')) {
				traceUFOPress('issue.fields.status.popup.submit');
			}

			onChange?.(transition, analyticsEvent);
		},
		[onChange],
	);

	const handleChange = useCallback(
		(
			option: StatusTransitionOption,
			action: ActionMeta<StatusTransitionOption>,
			analyticsEvent: UIAnalyticsEvent,
		) => {
			if (
				isConfigureStatusAvailable &&
				(option.value === CREATE_STATUS_OPTION_TYPE || option.value === MANAGE_WORKFLOW_OPTION_TYPE)
			) {
				switch (option.value) {
					case CREATE_STATUS_OPTION_TYPE:
						createStatusButtonRef?.current?.click();
						setIsConfigureStatusModalOpen(true);
						break;
					case MANAGE_WORKFLOW_OPTION_TYPE:
						onOpenManageWorkflow && onOpenManageWorkflow();
						break;
					default:
						break;
				}
				return;
			}

			if (fg('inline_config_a11y_improvements')) {
				setIsMenuOpen(false);
			}

			handleChangeStatus(option.transition, analyticsEvent);
		},
		[handleChangeStatus, isConfigureStatusAvailable, onOpenManageWorkflow],
	);

	const handleOpen = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'openDropdownStatusField');

			onOpen?.(analyticsEvent);
			setIsOpen(true);
		},
		[onOpen],
	);

	const handleClose = useCallback(() => {
		wasChanged.current = false;
		onClose?.();
		setIsOpen(false);
	}, [onClose]);

	const handleKeyDownForSelect = useCallback(
		(e: KeyboardEvent<HTMLElement>) => {
			lastKey.current = e.key;
		},
		[lastKey],
	);

	const handleCloseforSelect = useCallback(() => {
		// Prevents closing the menu when tabbing to edit status buttons
		// Preserves ability for Esc key to close menu
		if (lastKey.current !== 'Tab') {
			setIsMenuOpen(false);
		}
	}, [lastKey, setIsMenuOpen]);

	const renderLozenge = useCallback(
		({
			isEditable: editable,
			isSaving: saving,
			error: err,
			targetRef,
			contentRef,
		}: SelectTargetRender) => {
			if (saving) {
				return (
					<Blanket border={false}>
						<WrappedStatusLozenge
							targetRef={targetRef}
							status={value}
							error={error}
							contentRef={contentRef}
						/>
					</Blanket>
				);
			}

			return (
				<WrappedStatusLozenge
					ariaLabel={`${value.name} - ${formatMessage(messages.changeStatus)}`}
					error={err}
					hasHoverState
					isEditable={editable}
					isOpen={isOpen}
					status={value}
					targetRef={targetRef}
					contentRef={contentRef}
				/>
			);
		},
		[error, formatMessage, isOpen, value],
	);

	const renderButton = useCallback(
		({ isEditable: editable, isSaving: saving, targetRef }: SelectTargetRender) => {
			const statusButton = (
				<StatusButton
					ariaLabel={`${value.name} - ${formatMessage(messages.changeStatus)}`}
					isEditable={editable}
					isOpen={isOpen}
					status={value}
					targetRef={targetRef}
					statusButtonRef={statusButtonRef}
					shouldFitContainer={shouldFitContainer}
				/>
			);
			return saving ? <Blanket>{statusButton}</Blanket> : statusButton;
		},
		[formatMessage, isOpen, shouldFitContainer, statusButtonRef, value],
	);

	const renderCommandPalette = useCallback(
		() => (
			<CommandPaletteStatusView
				transitions={filteredAndOrderedTransitions}
				currentStatus={value}
				onSuccess={onSuccess}
				wasFetchSuccessful={wasFetchSuccessful}
				renderTransition={formatTransitionOptionLabel}
				onFetch={onFetch}
				isLoading={isLoading}
				onUpdateStatusMutation={onUpdateStatusMutation}
			/>
		),
		[
			filteredAndOrderedTransitions,
			value,
			onSuccess,
			wasFetchSuccessful,
			formatTransitionOptionLabel,
			onFetch,
			isLoading,
			onUpdateStatusMutation,
		],
	);

	const renderWithFocusWrapper = useCallback(
		// @ts-expect-error - TS2322 - Type 'MutableRefObject<unknown>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'.
		(children: ReactNode) => <div ref={childWrapperRef}>{children}</div>,
		[],
	);

	const renderTarget = useCallback(
		({ isEditable: editable, isSaving: saving, error: err, targetRef }: SelectTargetRender) => {
			if (value) {
				if (appearance === 'lozenge') {
					return renderWithFocusWrapper(
						renderLozenge({
							isEditable: editable,
							isSaving: saving,
							error: err,
							targetRef,
							contentRef: statusButtonRef,
						}),
					);
				}
				if (appearance === 'button') {
					return (
						<>
							{renderWithFocusWrapper(
								renderButton({ isEditable: editable, isSaving: saving, targetRef }),
							)}
							{registerInCommandPalette && renderCommandPalette()}
						</>
					);
				}
			}
			return <div />;
		},
		[
			appearance,
			registerInCommandPalette,
			renderButton,
			renderCommandPalette,
			renderLozenge,
			renderWithFocusWrapper,
			statusButtonRef,
			value,
		],
	);

	const optionsWithFooterAction =
		isInlineStatusExperimentEnabled &&
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useOptionsWithFooterActions(transformOptions(filteredAndOrderedTransitions || []));

	const selectOptions = useMemo(() => {
		if (isInlineStatusExperimentEnabled && showConfigureStatusButtons) {
			return optionsWithFooterAction;
		}
		return transformOptions(filteredAndOrderedTransitions || []);
	}, [
		filteredAndOrderedTransitions,
		isInlineStatusExperimentEnabled,
		optionsWithFooterAction,
		showConfigureStatusButtons,
	]);

	const renderOptionWithButton = useCallback(
		({ children, ...optionProps }: OptionProps<SelectOption>) => {
			if (
				optionProps.data.type === CREATE_STATUS_OPTION_TYPE ||
				optionProps.data.type === MANAGE_WORKFLOW_OPTION_TYPE
			) {
				return (
					<OptionWithButtonStyle
						{...optionProps}
						innerProps={{
							...optionProps.innerProps,
							role: 'button',
						}}
					>
						{children}
					</OptionWithButtonStyle>
				);
			}

			if (!projectId || !projectKey)
				return <OptionComponent {...optionProps}>{children}</OptionComponent>;

			return (
				<OptionWithInlineConfigButtons
					showEditButton={Boolean(showConfigureStatusButtons)}
					projectId={projectId}
					projectKey={projectKey}
					// issueTypeId is not immediately available on first render
					// temporary empty string will trigger a loading state within the edit status modal
					issueTypeId={issueTypeId ?? ''}
					statusNames={transitions?.map((transition) => transition.to.name) ?? []}
					menuListRef={menuListRef}
					onStatusUpdated={onStatusCreatedOrUpdated}
					source={configureStatusSource}
					onOpenEditModal={() => setIsConfigureStatusModalOpen(true)}
					onCloseEditModal={() => setIsConfigureStatusModalOpen(false)}
					{...optionProps}
				>
					{children}
				</OptionWithInlineConfigButtons>
			);
		},
		[
			showConfigureStatusButtons,
			projectId,
			projectKey,
			issueTypeId,
			transitions,
			menuListRef,
			onStatusCreatedOrUpdated,
			configureStatusSource,
			setIsConfigureStatusModalOpen,
		],
	);

	/* This prevents the dropdown from being accessible while saving/not editable,
	 * because for the underlying PopupSelect, the `isDisabled` prop only prevents
	 * the actual dropdown items from being selectable - the dropdown itself still
	 * pops up/down.
	 */
	if (isSaving || !isEditable) {
		return renderTarget({ isSaving, isEditable, error });
	}

	const getNoOptionsMessage = () => {
		if (customNoOptionsMessage) {
			return customNoOptionsMessage;
		}
		const noPermissionMessage = fg('jira-issue-terminology-refresh-m3')
			? messages.noPermissionIssueTermRefresh
			: messages.noPermission;
		return formatMessage(wasFetchSuccessful ? noPermissionMessage : messages.cannotLoadTransitions);
	};

	const renderFooter = () => {
		const showCreateStatusButton =
			showConfigureStatusButtons && projectKey && projectType && projectId;

		return footer || showCreateStatusButton ? (
			<Flex direction="column" xcss={statusViewFooterStyles}>
				{showCreateStatusButton && (
					<CreateStatusButton
						projectId={projectId}
						projectKey={projectKey}
						projectType={projectType}
						// issueTypeId is not immediately available on first render
						// temporary empty string will trigger a loading state within the create status modal
						issueTypeId={issueTypeId ?? ''}
						statusNames={transitions?.map((transition) => transition.to.name)}
						onClick={() => setIsConfigureStatusModalOpen(true)}
						onClose={() => setIsConfigureStatusModalOpen(false)}
						onStatusCreated={onStatusCreatedOrUpdated}
						source={configureStatusSource}
					/>
				)}
				{footer}
			</Flex>
		) : null;
	};

	const SelectWrapper = fg('inline_config_a11y_improvements')
		? AtlaskitSelectInlineTabActivator
		: Fragment;

	const selectWrapperProps = fg('inline_config_a11y_improvements')
		? {
				setIsMenuOpen,
				isMenuOpen,
			}
		: // Temporary until inline_config_a11y_improvements is cleaned up
			// Needed for VR tests to pass as props cannot be added to Fragment
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			({} as AtlaskitSelectInlineTabActivatorProps);

	const statusPopup =
		isDefaultSelect && selectedTransition ? (
			<SelectWrapper {...selectWrapperProps}>
				<SelectWithAnalytics
					label={formatMessage(messages.selectStatus)}
					inputId={fieldId}
					noOptionsMessage={getNoOptionsMessage}
					formatOptionLabel={formatTransitionOptionLabelWithCreateStatus}
					getOptionLabel={getTransitionOptionLabel}
					options={selectOptions}
					isLoading={isLoading}
					isDisabled={!isEditable}
					isOptionDisabled={isOptionDisabled}
					onChange={handleChange}
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					value={transformOptions([selectedTransition!])[0]}
					isSearchable={false}
					// TODO: (JGFENG-910) Fix handlers - onOpen and onClose are invalid props for Select
					onOpen={handleOpen}
					onClose={handleClose}
					closeMenuOnSelect
					{...(fg('inline_config_a11y_improvements')
						? {
								onKeyDown: handleKeyDownForSelect,
								onMenuClose: handleCloseforSelect,
							}
						: {})}
					components={{
						LoadingMessage: () => <LoadingSpinner />,
						...(isInlineStatusExperimentEnabled &&
							showConfigureStatusButtons && {
								Group: GroupComponentWithStickyFooter,
								Option: fg('inline_config_a11y_improvements')
									? (props: OptionProps<SelectOption>) => renderOptionWithButton(props)
									: OptionComponent,
							}),
						...(fg('inline_config_a11y_improvements') && {
							MenuList: ({ children, ...props }: MenuListComponentProps<SelectOption, false>) => (
								<components.MenuList {...props} innerRef={menuListRef}>
									{children}
								</components.MenuList>
							),
						}),
					}}
					menuIsOpen={
						// Remove eslint-disable-next-line when inline_config_a11y_improvements is cleaned up
						// eslint-disable-next-line no-nested-ternary
						isConfigureStatusModalOpen
							? false
							: fg('inline_config_a11y_improvements')
								? isMenuOpen
								: undefined
					}
					popperProps={mergedPopperProps}
					// TODO: (JGFENG-910) Remove footer prop as it is not a valid prop for Select
					footer={renderFooter()}
					ref={selectRef}
				/>
			</SelectWrapper>
		) : (
			<PopupSelectWithAnalytics
				inputId={fieldId}
				noOptionsMessage={getNoOptionsMessage}
				formatOptionLabel={formatTransitionOptionLabel}
				getOptionLabel={getTransitionOptionLabel}
				options={transformOptions(filteredAndOrderedTransitions || [])}
				target={({ ref }: SelectTarget) =>
					renderTarget({ isEditable, isSaving, error, targetRef: ref })
				}
				isLoading={isLoading}
				isDisabled={!isEditable}
				isOptionDisabled={isOptionDisabled}
				searchThreshold={99999}
				onChange={handleChange}
				onOpen={handleOpen}
				handleClick={handleOpen}
				onClose={handleClose}
				closeMenuOnSelect
				components={{
					LoadingMessage: () => <LoadingSpinner />,
					...(showConfigureStatusButtons &&
						fg('inline_config_a11y_improvements') && {
							Group: GroupComponentWithStickyFooter,
							Option: (props: OptionProps<SelectOption>) => renderOptionWithButton(props),
						}),
					...(fg('inline_config_a11y_improvements') && {
						MenuList: ({ children, ...props }: MenuListComponentProps<SelectOption, false>) => (
							<components.MenuList {...props} innerRef={menuListRef}>
								{children}
							</components.MenuList>
						),
					}),
				}}
				isOpen={isConfigureStatusModalOpen ? false : undefined}
				popperProps={mergedPopperProps}
				footer={renderFooter()}
				defaultIsOpen={defaultOpen}
				label={formatMessage(messages.selectStatus)}
				shouldCloseMenuOnTab={false}
			/>
		);

	return <EnterEscapeHandler onEscape={noop}>{statusPopup}</EnterEscapeHandler>;
};

const statusViewFooterStyles = xcss({
	borderTop: `2px solid ${token('color.border')}`,
});

const manageWorkflowStyles = xcss({
	height: '100%',
	width: '100%',
	backgroundColor: 'color.background.neutral.subtle',
	alignItems: 'center',
	textAlign: 'left',
	fontWeight: 'font.weight.medium',
});

export default StatusView;
