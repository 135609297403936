import { createSelector } from 'reselect';
import find from 'lodash/find';
import has from 'lodash/has';
import head from 'lodash/head';
import some from 'lodash/some';
import trim from 'lodash/trim';
import { fg } from '@atlassian/jira-feature-gating';
import type { FieldsByKey } from '@atlassian/jira-polaris-domain-field/src/collections/types.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	type FieldValueFilter,
	type Filter,
	type NumericFieldFilter,
	type TextFilter,
	type IntervalFieldFilter,
	type ConnectionFieldFilter,
	CONNECTION_FIELD_FILTER,
	GENERIC_FIELD_FILTER,
	NUMERIC_FIELD_FILTER,
	INTERVAL_FIELD_FILTER,
	TEXT_FIELD_FILTER,
} from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import type { IssueType } from '@atlassian/jira-polaris-component-issue-types/src/controllers/types.tsx';
import type { Props } from '../types.tsx';
import { getFields } from './fields.tsx';
import { getCurrentViewDraft, isCurrentViewAutosaveEnabled } from './view/autosave/index.tsx';
import { getCurrentView } from './view/current/index.tsx';
import { getCurrentViewGroupBy, getCurrentViewVerticalGroupBy } from './view/index.tsx';

type FilterValue = {
	stringValue?: string;
};

const doesFilterValueIdExistsInFieldOptions = functionWithCondition(
	() => fg('jpd_issue_types_ga'),
	(field: Field | undefined, { stringValue: optionId }: FilterValue, issueTypes: IssueType[]) => {
		// undefined (=no value)
		if (optionId === undefined) {
			return true;
		}

		if (field === undefined) {
			return true;
		}

		if (field.type === FIELD_TYPES.ISSUE_TYPE) {
			if (fg('jpd_view_config_using_issue_type_name')) {
				const issueTypeId = issueTypes.find(({ name }) => name === optionId)?.id;

				return issueTypeId !== undefined && field.issueTypes.includes(issueTypeId);
			}

			return field.issueTypes.includes(optionId);
		}

		if (field.options === undefined) {
			return true;
		}

		// check optionId in field options
		return field.options.some(({ jiraOptionId }) => jiraOptionId === optionId);
	},
	(field: Field, { stringValue: optionId }: FilterValue, _issueTypes: IssueType[]) =>
		// undefined (=no value) OR check optionId in field options
		optionId === undefined ||
		!field?.options ||
		field?.options?.some(({ jiraOptionId }) => jiraOptionId === optionId),
);

const getCurrentViewPermanentFilterProperty = createSelector(
	getCurrentView,
	(currentView) => currentView?.filter,
);

const getCurrentViewPermanentFilterObj = createSelector(
	getCurrentViewPermanentFilterProperty,
	(filter) => (filter || []).filter((f) => f.type !== TEXT_FIELD_FILTER || f.localId === 'quick'),
);

const getCurrentViewDraftFilterProperty = createSelector(
	getCurrentViewDraft,
	(draft) => draft?.filter || [],
);

export const getCurrentViewFilter = createSelector(
	getCurrentViewPermanentFilterObj,
	getCurrentViewDraftFilterProperty,
	getFields,
	isCurrentViewAutosaveEnabled,
	(state, props) => props?.issueTypes,
	(viewFilterObj, draftFilter, fieldsByKey, isAutosaveEnabled, issueTypes = []) => {
		const filterData: Filter[] = isAutosaveEnabled ? viewFilterObj : draftFilter;

		return filterData.map((filter) =>
			filter.type === GENERIC_FIELD_FILTER
				? {
						...filter,
						values: filter.values.filter((value) =>
							doesFilterValueIdExistsInFieldOptions(fieldsByKey[filter.field], value, issueTypes),
						),
					}
				: filter,
		);
	},
);

export const getCurrentViewInitialFilter = createSelector(
	getCurrentView,
	(view) => view?.initialFilter,
);

export const hasSharedViewNoValueFilter = (field: Field) =>
	createSelector(
		getCurrentViewInitialFilter,
		(_, props: Props | undefined) => props,
		(initialFilter, props) => {
			if (!props?.isSharedView) return true;

			const fieldFilter = initialFilter?.find((f) => 'field' in f && f.field === field.key);

			if (!fieldFilter || fieldFilter.type !== GENERIC_FIELD_FILTER) {
				return true;
			}

			return !!fieldFilter?.values?.find(({ stringValue }) => !stringValue);
		},
	);

export const getCurrentViewFilterForShared = createSelector(
	getCurrentViewFilter,
	getCurrentViewInitialFilter,
	getCurrentViewGroupBy,
	getCurrentViewVerticalGroupBy,
	(filters, initialFilters, groupBy, verticalGroupBy) => {
		const getGroupFilter = (f: Filter) => 'field' in f && f.field === groupBy?.key;
		const getVerticalGroupFilter = (f: Filter) => 'field' in f && f.field === verticalGroupBy?.key;

		const groupFilter = initialFilters?.find(getGroupFilter);
		const verticalGroupByFilter = initialFilters?.find(getVerticalGroupFilter);
		const localGroupFilter = filters.find(getGroupFilter);
		const localVerticalGroupFilter = filters.find(getVerticalGroupFilter);
		const mergedFilters = [...filters];

		// use group filter configured in normal view until user overrides this locally in shared view
		if (groupFilter && !localGroupFilter) {
			mergedFilters.push(groupFilter);
		}

		if (verticalGroupByFilter && !localVerticalGroupFilter) {
			mergedFilters.push(verticalGroupByFilter);
		}

		return mergedFilters;
	},
);

export const createGetFieldFilter = (field: FieldKey) =>
	createSelector(getCurrentViewFilter, (filters) => {
		const retVal = find(
			filters,
			(f): f is FieldValueFilter => f.type === GENERIC_FIELD_FILTER && f.field === field,
		);

		return retVal;
	});

export const createGetConnectionFieldFilter = (field: FieldKey) =>
	createSelector(getCurrentViewFilter, (filters) => {
		return filters.find(
			(f): f is ConnectionFieldFilter => f.type === CONNECTION_FIELD_FILTER && f.field === field,
		);
	});

export const createGetNumericFilter = (field: FieldKey) =>
	createSelector(getCurrentViewFilter, (filters) => {
		const retVal = find(
			filters,
			(f): f is NumericFieldFilter => f.type === NUMERIC_FIELD_FILTER && f.field === field,
		);
		return retVal;
	});

export const createGetIntervalFilter = (field: FieldKey) =>
	createSelector(getCurrentViewFilter, (filters) =>
		find(
			filters,
			(filter): filter is IntervalFieldFilter =>
				filter.type === INTERVAL_FIELD_FILTER && filter.field === field,
		),
	);

export const createGetFieldFilterOrEmptyFilter = (fieldKey: FieldKey) => {
	const getFieldFilter = createGetFieldFilter(fieldKey);
	return createSelector(
		getFieldFilter,
		getFields,
		(fieldFilter, fields): FieldValueFilter =>
			fieldFilter || {
				type: GENERIC_FIELD_FILTER,
				field: fieldKey,
				values: [],
				fieldType: fields[fieldKey]?.type,
			},
	);
};

export const createGetNumericFilterOrEmptyFilter = (fieldKey: FieldKey) => {
	const getFieldFilter = createGetNumericFilter(fieldKey);
	return createSelector(
		getFieldFilter,
		getFields,
		(fieldFilter, fields): NumericFieldFilter =>
			fieldFilter || {
				type: NUMERIC_FIELD_FILTER,
				field: fieldKey,
				fieldType: fields[fieldKey].type,
				values: [],
			},
	);
};

export const createGeIntervalFilterOrEmptyFilter = (fieldKey: FieldKey) => {
	const getFieldFilter = createGetIntervalFilter(fieldKey);
	const emptyIntervalFilter: IntervalFieldFilter = {
		type: INTERVAL_FIELD_FILTER,
		field: fieldKey,
		values: [],
	};
	return createSelector(
		getFieldFilter,
		(fieldFilter: IntervalFieldFilter | undefined): IntervalFieldFilter =>
			fieldFilter || emptyIntervalFilter,
	);
};

export const getQuickSearchFilter = createSelector(
	getCurrentViewFilter,
	(filters): TextFilter =>
		head(
			filters.filter((f): f is TextFilter => f.type === TEXT_FIELD_FILTER && f.localId === 'quick'),
		) || {
			type: TEXT_FIELD_FILTER,
			localId: 'quick',
			values: [],
		},
);

/**
 * Filters as represented in the state might be empty or referencing fields
 * that are no longer available on the prroject. this filter ensures that only
 * filters that actually can modify the issue set are used for counting active filters.
 */
const isApplicableFilter = (filter: Filter, fields: FieldsByKey): boolean => {
	if (filter.type === TEXT_FIELD_FILTER) {
		return filter.values.length > 0 && trim(filter.values[0].stringValue) !== '';
	}
	return filter.values.length > 0 && has(fields, filter.field);
};

export const isQuickSearchFilterDefined = createSelector(getQuickSearchFilter, (textFilter) =>
	isApplicableFilter(textFilter, {}),
);

export const getActiveFiltersCount = createSelector(
	getCurrentViewFilter,
	getFields,
	(filters, fields) => filters.filter((filter) => isApplicableFilter(filter, fields)).length,
);

// Fields for active filters
export const getActivePermanentFiltersFields = createSelector(
	getCurrentViewFilter,
	getFields,
	(filters: Filter[], fields: FieldsByKey = {}): Field[] => {
		const activeFiltersFields = filters
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			.reduce<Record<string, any>>((acc, filter) => {
				if (fg('jpd_issue_types_ga')) {
					if (
						filter.type === GENERIC_FIELD_FILTER ||
						filter.type === NUMERIC_FIELD_FILTER ||
						filter.type === INTERVAL_FIELD_FILTER ||
						(filter.type === CONNECTION_FIELD_FILTER && fg('jpd_issues_relationships'))
					) {
						if (filter.values.length > 0 && has(fields, filter.field)) {
							acc[filter.field] = fields[filter.field];
						}
					}

					return acc;
				}

				if (
					(filter.type === GENERIC_FIELD_FILTER &&
						// Check for ISSUE_TYPE added in case we will want to rollback the FG: jpd_issues_relationships
						filter.fieldType !== FIELD_TYPES.ISSUE_TYPE) ||
					filter.type === NUMERIC_FIELD_FILTER ||
					filter.type === INTERVAL_FIELD_FILTER
				) {
					if (filter.values.length > 0 && has(fields, filter.field)) {
						acc[filter.field] = fields[filter.field];
					}
				}
				return acc;
			}, {});

		return Object.values(activeFiltersFields);
	},
);

export const getAllowedFiltersFields = createSelector(
	getFields,
	(fields: FieldsByKey = {}): Field[] => {
		// Allowed field types to filter on
		const allowedFieldTypes = [
			FIELD_TYPES.NUMBER,
			FIELD_TYPES.FORMULA,
			FIELD_TYPES.LINKED_ISSUES,
			FIELD_TYPES.RATING,
			FIELD_TYPES.CHECKBOX,
			FIELD_TYPES.SLIDER,
			FIELD_TYPES.INSIGHTS,
			FIELD_TYPES.ASSIGNEE,
			FIELD_TYPES.REPORTER,
			FIELD_TYPES.CREATOR,
			FIELD_TYPES.PEOPLE,
			FIELD_TYPES.JSW_PEOPLE,
			FIELD_TYPES.SINGLE_SELECT,
			FIELD_TYPES.MULTI_SELECT,
			FIELD_TYPES.JSW_MULTI_SELECT,
			FIELD_TYPES.LABELS,
			FIELD_TYPES.CUSTOM_LABELS,
			FIELD_TYPES.STATUS,
			FIELD_TYPES.ISSUE_ID,
			FIELD_TYPES.ATLAS_GOAL,
			FIELD_TYPES.ATLAS_PROJECT,
			FIELD_TYPES.ATLAS_PROJECT_STATUS,
			FIELD_TYPES.INTERVAL,
			FIELD_TYPES.DELIVERY_STATUS,
			FIELD_TYPES.DELIVERY_PROGRESS,
			FIELD_TYPES.REACTIONS,
			FIELD_TYPES.PROJECT,
			FIELD_TYPES.CONNECTION,
			...(fg('polaris_team_field_integration') ? [FIELD_TYPES.TEAM] : []),
			...(fg('jpd_issue_types_ga') ? [FIELD_TYPES.ISSUE_TYPE] : []),
			...(fg('jpd_platform_goals_field_support') ? [FIELD_TYPES.PLATFORM_GOALS] : []),
		];

		return Object.keys(fields)
			.map((key) => fields[key])
			.filter(({ type }) => allowedFieldTypes.some((t) => t === type));
	},
);

// Available fields for filters
export const getAvailableFiltersFields = createSelector(
	getAllowedFiltersFields,
	getActivePermanentFiltersFields,
	(allowedFields: Field[], activeFiltersFields: Field[]): Field[] =>
		allowedFields.filter((key) => !some(activeFiltersFields, { key })),
);
