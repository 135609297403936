import { useCrossFlow } from '@atlassiansox/cross-flow-api-internals';
import { useLicensedProducts } from '@atlassian/jira-tenant-context-controller/src/components/licensed-products/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { expVal, expValEquals } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import type { Sources } from '../common.tsx';
import { useHasConfluenceActivatedWithinFourteenDays } from './confluence-activated-within-fourteen-days.tsx';
import { useUserHasAddProductPermissions } from './user-product-permissions.tsx';
import { isSupportedIssueType } from './confluence-templates.tsx';
import { useUserHasEligibleTeamType } from './user-team-type.tsx';

export type PlaceholderTargeting =
	| 'RequestTrial'
	| 'CrossJoin'
	| 'CoUse'
	| 'CrossFlow-User'
	| 'CrossFlow-Admin'
	| undefined;

export const useTargeting = (
	projectType: string | undefined,
	issueTypeEnglish: string | undefined,
	hasConfluenceAccess: boolean,
	source: Sources,
	isConfluenceProvisioningStarted?: boolean,
	hasActivatedConfluenceWithinFourteenDays?: boolean,
	hasActivatedConfluenceWithinSixtythreeDays?: boolean,
): PlaceholderTargeting => {
	const licensedProducts = useLicensedProducts();
	const crossFlow = useCrossFlow();
	const isSiteAdmin = useIsSiteAdmin();

	// This hook checks if the site has activated confluence within the last 14 days.
	// If that value is already passed to the hook then the check is skipped.
	const isConfluenceActivatedWithinFourteenDays = useHasConfluenceActivatedWithinFourteenDays(
		hasActivatedConfluenceWithinFourteenDays,
	);

	// areProductSuggestionsEnabled does not have a specific hook and we are required to use the deprecated interface
	const { areProductSuggestionsEnabled } = useTenantContext();
	const tenantHasConfluence = licensedProducts?.confluence === true;

	// checking if end user is TEL eligible
	const { canProvisionConfluenceEndUser } = useUserHasAddProductPermissions({
		skip:
			isSiteAdmin ||
			tenantHasConfluence ||
			!areProductSuggestionsEnabled ||
			projectType !== SOFTWARE_PROJECT ||
			!isSupportedIssueType(issueTypeEnglish),
	});

	// check for admin's team type
	const hasEligibleTeamType = useUserHasEligibleTeamType({
		skip:
			!isSiteAdmin ||
			!areProductSuggestionsEnabled ||
			projectType !== SOFTWARE_PROJECT ||
			!isSupportedIssueType(issueTypeEnglish),
	});

	if (source === 'issueView' && projectType !== SOFTWARE_PROJECT) return undefined;

	if (source === 'issueView' && !isSupportedIssueType(issueTypeEnglish)) return undefined;

	// if provisioning has started but wasn't completed, we don't want to show cross-flow
	if (!tenantHasConfluence && !isConfluenceProvisioningStarted) {
		if (!crossFlow.isEnabled || !areProductSuggestionsEnabled) return undefined;

		// canProvisionConfluence wraps the admin check
		if (isSiteAdmin) {
			if (hasEligibleTeamType) {
				// For actual A/B we likely return new PlaceholderTargeting value b/c the templates will be different
				expVal('aa_grw-1544_whiteboards_as_placeholder_template', 'cohort', 'not-enrolled');
			}

			return 'CrossFlow-Admin';
		}

		// end-users TEL
		if (canProvisionConfluenceEndUser) return 'CrossFlow-User';

		return 'RequestTrial';
	}

	if (
		hasConfluenceAccess &&
		isConfluenceActivatedWithin(
			source,
			isConfluenceActivatedWithinFourteenDays,
			hasActivatedConfluenceWithinSixtythreeDays,
		)
	) {
		if (isSiteAdmin && hasEligibleTeamType) {
			// For actual A/B we likely return new PlaceholderTargeting value b/c the templates will be different
			expVal('aa_grw-1544_whiteboards_as_placeholder_template', 'cohort', 'not-enrolled');
		}
		return 'CoUse';
	}

	return undefined;
};

const isConfluenceActivatedWithin = (
	source: Sources,
	fourteenDays: boolean,
	sixtythreeDays = false,
) => {
	if (source === 'issueView' && fg('grw-1589_extend_pt_onboarding_gate')) {
		if (expValEquals('grw-1589_extending_pt_onboarding', 'cohort', 'variation'))
			return sixtythreeDays;
	}
	return fourteenDays;
};
