import React, { useMemo, type RefObject, type ReactNode, useCallback } from 'react';
import type { OptionProps, OptionType } from '@atlaskit/select';
import { Box, xcss } from '@atlaskit/primitives';
import type {
	CREATE_STATUS_OPTION_TYPE,
	MANAGE_WORKFLOW_OPTION_TYPE,
	StatusTransition,
} from '@atlassian/jira-issue-field-status/src/common/types.tsx';
import { OptionWithAutoKeyboardScroll } from '@atlassian/jira-select-with-footer-options/src/OptionWithAutoKeyboardScroll.tsx';
import { InlineConfigButtons } from '@atlassian/jira-inline-config-buttons-for-select/src/index.tsx';
import {
	statusCategoryForId,
	type StatusCategory,
} from '@atlassian/jira-common-constants/src/status-categories.tsx';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import { asyncInlineEditStatusModal } from '@atlassian/jira-inline-edit-status-modal/entrypoint.tsx';
import {
	ISSUE_VIEW_SOURCE,
	type ConfigureSource,
} from '@atlassian/jira-status-config-common/src/types.tsx';
import { defaultModalProps } from '@atlassian/jira-status-config-common/src/constants.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { editStatusExperience } from '../../../experiences.tsx';
import { messages } from './messages.tsx';

type StatusTransitionOption = {
	transition?: StatusTransition;
} & OptionType;

type CreateStatusOption = {
	label: string;
	value: string;
	type: typeof CREATE_STATUS_OPTION_TYPE;
	groupType: string;
};

type ManageWorkflowOption = {
	label: string;
	value: string;
	type: typeof MANAGE_WORKFLOW_OPTION_TYPE;
	groupType: string;
};

export type SelectOption = StatusTransitionOption | CreateStatusOption | ManageWorkflowOption;

export type OptionWithInlineConfigButtonsProps = {
	children: React.ReactNode;
	projectId: string;
	projectKey: string;
	issueTypeId: string;
	statusNames: string[];
	showEditButton: boolean;
	menuListRef: RefObject<HTMLDivElement>;
	source?: ConfigureSource;
	onOpenEditModal: () => void;
	onCloseEditModal: () => void;
	onStatusUpdated?: () => Promise<void>;
} & OptionProps<SelectOption>;

export type EditButtonWrapperProps = {
	children: ReactNode;
	queryParams: {
		status: {
			id: string;
			statusName: string;
			statusCategory: StatusCategory;
		};
		projectId: string;
		projectKey: string;
		issueTypeId: string;
		statusNames: string[];
		source?: ConfigureSource;
		onClose: () => void;
		onStatusUpdated?: () => Promise<void>;
	};
};

export const EditButtonWrapper = ({ children, queryParams }: EditButtonWrapperProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const {
		status,
		projectId,
		projectKey,
		issueTypeId,
		statusNames,
		source,
		onClose,
		onStatusUpdated,
	} = queryParams;

	const onCloseEditModal = useCallback(() => {
		onClose();
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: 'inlineEditStatusModal',
				action: 'closed',
			}),
		);
	}, [createAnalyticsEvent, onClose]);

	const entryPointProps = useMemo(
		() => ({
			onClose: onCloseEditModal,
			status: {
				id: status.id,
				statusName: status.statusName,
				statusCategory: status.statusCategory,
			},
			issueTypeId,
			projectId,
			projectKey,
			editStatusExperience,
			statusNames,
			source: source || ISSUE_VIEW_SOURCE,
			onStatusUpdated,
		}),
		[
			onCloseEditModal,
			status.id,
			status.statusName,
			status.statusCategory,
			issueTypeId,
			projectId,
			projectKey,
			statusNames,
			source,
			onStatusUpdated,
		],
	);

	return (
		<ModalEntryPointPressableTrigger
			entryPoint={asyncInlineEditStatusModal}
			entryPointProps={entryPointProps}
			interactionName="edit-status-modal"
			modalProps={defaultModalProps}
			useInternalModal
		>
			{({ ref }) => (
				<Box
					role="button"
					xcss={inlineButtonStyles}
					ref={ref}
					aria-label={formatMessage(messages.statusNameButtonLabel, {
						statusName: status.statusName,
					})}
					testId="issue-field-status.ui.status-view.configure-status.edit-status-button"
				>
					{children}
				</Box>
			)}
		</ModalEntryPointPressableTrigger>
	);
};

export const OptionWithInlineConfigButtons = ({
	children,
	projectId,
	projectKey,
	issueTypeId,
	statusNames,
	showEditButton,
	menuListRef,
	source,
	onOpenEditModal,
	onCloseEditModal,
	onStatusUpdated,
	...props
}: OptionWithInlineConfigButtonsProps) => {
	const { formatMessage } = useIntl();
	const selectOption: StatusTransitionOption = props.data;

	return (
		<OptionWithAutoKeyboardScroll {...props} containerRef={menuListRef}>
			<InlineConfigButtons
				editActionSubjectId="editStatusButton"
				editLabelText={formatMessage(messages.editStatusTooltip)}
				isFocused={props.isFocused}
				showEditButton={Boolean(selectOption.transition && showEditButton)}
				shouldStopPropagationOnClick
				queryParams={{
					status: {
						id: selectOption.transition?.to?.id ?? '',
						statusName: selectOption.transition?.to?.name ?? '',
						statusCategory:
							statusCategoryForId(selectOption.transition?.to?.statusCategory?.id) ?? '',
					},
					projectId,
					projectKey,
					issueTypeId,
					statusNames,
					source,
					onClose: onCloseEditModal,
					onStatusUpdated,
				}}
				EditButtonWrapper={EditButtonWrapper}
				onEditButtonClick={() => {
					onOpenEditModal();
				}}
			>
				{children}
			</InlineConfigButtons>
		</OptionWithAutoKeyboardScroll>
	);
};

const inlineButtonStyles = xcss({
	marginBlock: 'space.negative.025',
});
