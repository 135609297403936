/**
 * @generated SignedSource<<24bc40758d0d9aea76ed90455a95776d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type header_issueViewFoundation_HeaderItemWithoutCompactMode$data = {
  readonly " $fragmentSpreads": FragmentRefs<"headerActions_issueViewFoundation_HeaderActionsView_headerActions" | "headerBreadcrumbs_issueViewFoundation_HeaderBreadcrumbsView">;
  readonly " $fragmentType": "header_issueViewFoundation_HeaderItemWithoutCompactMode";
};
export type header_issueViewFoundation_HeaderItemWithoutCompactMode$key = {
  readonly " $data"?: header_issueViewFoundation_HeaderItemWithoutCompactMode$data;
  readonly " $fragmentSpreads": FragmentRefs<"header_issueViewFoundation_HeaderItemWithoutCompactMode">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsheaderactionsrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsparent"
    }
  ],
  "kind": "Fragment",
  "name": "header_issueViewFoundation_HeaderItemWithoutCompactMode",
  "selections": [
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsheaderactionsrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "headerActions_issueViewFoundation_HeaderActionsView_headerActions"
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsparent",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "headerBreadcrumbs_issueViewFoundation_HeaderBreadcrumbsView"
        }
      ]
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "26bb4504c03f194a21c6c8742a591243";

export default node;
