import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';

const CONFLUENCE_FREE_OFFERING_KEY = '7547cfd6-a804-4e42-8bed-e656d49ea09e';

export const fetchEndUserAddProductPermissions = async (
	cloudId: string,
	isBillingSystemCcp: boolean,
) => {
	const response = await fetchJson(
		'/gateway/api/bxp/signup/product/end-user-activate-eligible-check',
		{
			method: 'POST',
			body: JSON.stringify({
				cloudId,
				isCCP: isBillingSystemCcp,
				offerings: [
					{
						offeringId: CONFLUENCE_FREE_OFFERING_KEY,
						chargeElement: 'user',
						pricingType: 'FREE',
					},
				],
			}),
		},
	);

	return response;
};
