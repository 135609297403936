import type { FlagConfiguration } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	title: {
		id: 'issue-field-status.view-workflow.classic.flags.errors.load-modal-error.title',
		defaultMessage: "We couldn't load the workflow",
		description:
			'Error is shown when View workflow modal code cannot be loaded, or modal itself cannot be rendered',
	},
	description: {
		id: 'issue-field-status.view-workflow.classic.flags.errors.load-modal-error.description',
		defaultMessage: 'Check your connection, then try reloading the page.',
		description:
			'Error is shown when View workflow modal code cannot be loaded, or modal itself cannot be rendered',
	},
});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (): FlagConfiguration => ({
	type: 'error',
	title: messages.title,
	description: messages.description,
	messageId: 'issue-field-status.ui.view-workflow.classic.flags.errors.load-modal-error.flag.error',
	messageType: 'transactional',
});
