/**
 * @generated SignedSource<<5a3b9a4a4f05cf3149c6874c72032756>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type plainTextField_issueViewLayoutRichTextField$data = {
  readonly __typename: "JiraRichTextField";
  readonly __id: string;
  readonly description: string | null | undefined;
  readonly fieldConfig: {
    readonly isEditable: boolean | null | undefined;
  } | null | undefined;
  readonly fieldId: string;
  readonly issue: {
    readonly issueId: string;
    readonly key: string;
  } | null | undefined;
  readonly name: string;
  readonly renderer: string | null | undefined;
  readonly richText: {
    readonly plainText: string | null | undefined;
  } | null | undefined;
  readonly type: string;
  readonly " $fragmentType": "plainTextField_issueViewLayoutRichTextField";
};
export type plainTextField_issueViewLayoutRichTextField$key = {
  readonly " $data"?: plainTextField_issueViewLayoutRichTextField$data;
  readonly " $fragmentSpreads": FragmentRefs<"plainTextField_issueViewLayoutRichTextField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "plainTextField_issueViewLayoutRichTextField",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "description"
    },
    {
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "issue",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "issueId"
        },
        {
          "kind": "ScalarField",
          "name": "key"
        }
      ]
    },
    {
      "kind": "ScalarField",
      "name": "renderer"
    },
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        }
      ]
    },
    {
      "concreteType": "JiraRichText",
      "kind": "LinkedField",
      "name": "richText",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "plainText"
        }
      ]
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__id"
        }
      ]
    }
  ],
  "type": "JiraRichTextField"
};

(node as any).hash = "aa8261d220b488895ae19cd4e5a2bc6e";

export default node;
