import React, { type FC, useState, useEffect } from 'react';
import { styled } from '@compiled/react';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { ConnectIframe } from '@atlassian/connect-module-core';

import { useDefaultConnectIframeProvider } from '@atlassian/jira-common-ecosystem/src/index.tsx';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { EcosystemTimeoutViewAsync } from '@atlassian/jira-connect-utils-async/src/async.tsx';
import {
	getConnectHost,
	withConnectHost,
} from '@atlassian/jira-connect-utils/src/common/utils/ecosystem-connect-host.tsx';
import type { ACJSHost } from '@atlassian/jira-connect/src/index.tsx';
import { useIssueId, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useOnDemandIssueRefreshTime } from '@atlassian/jira-issue-refresh-service/src/services/main.tsx';
import type { ContextPanel } from '@atlassian/jira-issue-shared-types/src/common/types/context-panel-type.tsx';
import { useIsEcosystemDataLoadedFor } from '@atlassian/jira-issue-view-ecosystem-service/src/services/main.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

type StateProps = {
	refreshedOn?: number;
};

export type Props = StateProps & ContextPanel;

const Loading = () => (
	<StyledLoading>
		<Spinner size={5 * gridSize} />
	</StyledLoading>
);

export const ConnectContextPanel: FC<Props> = ({ name, options, appKey, moduleKey }) => {
	const [connectHost, setConnectHost] = useState<ACJSHost | null>(() => getConnectHost());
	const refreshedOn = useOnDemandIssueRefreshTime();
	const issueId = useIssueId();
	const issueKey = useIssueKey();
	const connectIframeProvider = useDefaultConnectIframeProvider({
		isAutoresizeBugfixEnabled: true,
	});
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { url } = options || {};

	useEffect(() => {
		if (!connectHost) {
			log.safeInfoWithoutCustomerData(
				'issue.ecosystem.context.panel',
				'Delayed initialization of ecosystem context panel',
			);
			withConnectHost((host) => {
				setConnectHost(host);
			});
		}
	}, [connectHost]);

	// If url contains the JWT, valid the JWT before loading the Iframe
	const isJwtExpired =
		url && connectHost && connectHost.hasJwt?.(url) && connectHost.isJwtExpired?.(url);

	useEffect(() => {
		if (isJwtExpired) {
			fireTrackAnalytics(createAnalyticsEvent({}), 'ConnectIframe expired', {
				location: 'ContextPanel',
			});
		}
	}, [isJwtExpired, createAnalyticsEvent]);

	// there is a situation when during SPA transition issueId is yet undefined
	const hasCorrectIssueInformation = useIsEcosystemDataLoadedFor(useIssueKey());

	const connectKeyIssueId = `connect-iFrame-${options?.origin}-${issueId}-${refreshedOn}`;
	const connectKeyIssueKey = `connect-iFrame-${options?.origin}-${issueKey}-${refreshedOn}`;

	useEffect(() => {
		fireTrackAnalytics(createAnalyticsEvent({}), 'ConnectContextPanel loaded');
	}, [createAnalyticsEvent]);

	useEffect(() => {
		fireTrackAnalytics(createAnalyticsEvent({}), 'ConnectContextPanel updated');
	}, [connectKeyIssueKey, createAnalyticsEvent]);

	// there is a situation when during SPA transition:
	// - issueId is yet undefined
	// - contentPanel is different between states, including not yet being present
	const renderUnblocked = connectHost && Boolean(issueId) && hasCorrectIssueInformation;

	if (!renderUnblocked || !url) {
		return <Loading />;
	}

	return (
		<ConnectIframe
			// @TODO JIV-17989 replace connectKeyIssueId with connectKeyIssueKey
			key={connectKeyIssueId}
			connectHost={connectHost}
			appKey={appKey}
			moduleKey={moduleKey}
			url={url}
			height="100%"
			width="100%"
			connectIframeProvider={connectIframeProvider}
			options={connectIframeProvider?.convertConnectOptions(options)}
			// @ts-expect-error - TS2322 - Type '() => JSX.Element' is not assignable to type 'new () => PureComponent<{ failedCallback: Function; }, {}, any>'.
			timeoutIndicator={() => <EcosystemTimeoutViewAsync appName={name} appKey={appKey} />}
		/>
	);
};

export default ConnectContextPanel;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledLoading = styled.div({
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	marginTop: token('space.300'),
	marginRight: '0px',
	marginBottom: token('space.300'),
	marginLeft: '0px',
});
