/**
 * @generated SignedSource<<17ee63debd47430f20858f9327b6754e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type headerActions_issueViewFoundation_HeaderActionsView_root$data = {
  readonly " $fragmentSpreads": FragmentRefs<"issueActions_issueViewFoundation_IssueActionsMenuWithRelay_root">;
  readonly " $fragmentType": "headerActions_issueViewFoundation_HeaderActionsView_root";
};
export type headerActions_issueViewFoundation_HeaderActionsView_root$key = {
  readonly " $data"?: headerActions_issueViewFoundation_HeaderActionsView_root$data;
  readonly " $fragmentSpreads": FragmentRefs<"headerActions_issueViewFoundation_HeaderActionsView_root">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationconfigurationurlrelayprovider"
    }
  ],
  "kind": "Fragment",
  "name": "headerActions_issueViewFoundation_HeaderActionsView_root",
  "selections": [
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationconfigurationurlrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "issueActions_issueViewFoundation_IssueActionsMenuWithRelay_root"
        }
      ]
    }
  ],
  "type": "JiraQuery"
};

(node as any).hash = "dcf546f90f7ca0e5cfcc9e0408120362";

export default node;
