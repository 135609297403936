import { usePageContext as usePageContextDI } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/page-context/main.tsx';
import { toProjectId, toBaseUrl, toProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { CommonPageContext } from './types.tsx';

export const useCommonPageContext = (
	usePageContext: typeof usePageContextDI = usePageContextDI,
): CommonPageContext => {
	const [{ data: pageContextData, error }] = usePageContext();
	const { tenantContext, projectContext } = pageContextData || {};
	const { projectKey, projectName, projectId, projectType, isProjectSimplified } =
		projectContext || {};
	const { baseUrl, locale } = tenantContext || {};

	return {
		baseUrl: baseUrl !== undefined ? toBaseUrl(baseUrl) : baseUrl,
		error,
		locale,
		projectKey: projectKey !== undefined ? toProjectKey(projectKey) : projectKey,
		projectName,
		projectId: projectId != null ? toProjectId(projectId.toString()) : projectId,
		projectType: fg('jcs_project_type_m3') ? projectType : undefined,
		isProjectSimplified,
	};
};
