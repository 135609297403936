import React, { useCallback, useState } from 'react';
import { getATLContextUrl } from '@atlassian/atl-context';
import { fg } from '@atlassian/jira-feature-gating';
import { BaseBlockedAppsInfoBanner } from '@atlassian/jira-common-components-blocked-apps-info-banner/src/main.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useHasBlockedExtensions } from '@atlassian/jira-issue-view-forge-service/src/services/main.tsx';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import messages from './messages.tsx';
import { useHasBlockedConnectApps } from './services/connect-addon/index.tsx';

const useHasBlockedExtensionsWithDefaultToFalse = () => {
	const forge = useHasBlockedExtensions() || false;
	const connect = useHasBlockedConnectApps() || false;
	return forge || connect;
};

// TODO : JFP-2294 Add a prefix to identify each app
const localStorageProvider = createLocalStorageProvider('');

const useViewBlockedAppsLink = () => `${getATLContextUrl('id')}/manage-profile/apps`;

const useLocalStorage = () => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);

	const [notShowAgain, setNotShowAgain] = useState(() => {
		if (fg('jfp-vulcan-browser-storage-migration')) {
			return Boolean(localStorageProvider.get(`showBlockedAppsBanner-${projectKey}`));
		}
		if (__SERVER__ && fg('issue-view-ssr-perf-m1')) {
			// the Local Storage Web API does not exist in SSR
			// so we hide the banner in all cases in SSR
			return true;
		}
		return Boolean(localStorage.getItem(`showBlockedAppsBanner-${projectKey}`));
	});

	const handleDoNotShowAgain = useCallback(() => {
		fg('jfp-vulcan-browser-storage-migration')
			? localStorageProvider.set(`showBlockedAppsBanner-${projectKey}`, true)
			: localStorage.setItem(`showBlockedAppsBanner-${projectKey}`, 'true');
		setNotShowAgain(true);
	}, [projectKey]);

	return [notShowAgain, handleDoNotShowAgain] as const;
};

export const BlockedAppsIssueInfoBanner = () => (
	<BaseBlockedAppsInfoBanner
		getHasBlockedExtensions={useHasBlockedExtensionsWithDefaultToFalse}
		getViewBlockedAppsLink={useViewBlockedAppsLink}
		getMainMessage={() => messages.appsHaveBeenBlockedByYourAdmin}
		dismissalHandler={useLocalStorage}
	/>
);
