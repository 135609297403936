import { useCallback } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useHasCurrentUserOpsgenieAccount } from '@atlassian/jira-servicedesk-incident-management-common/src/services/has-opsgenie-account/index.tsx';
import type { CloudId, FormatMessage } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createContainer,
	createStore,
	createHook,
	createSubscriber,
} from '@atlassian/react-sweet-state';
import { CREATED_BY_ME, SHARED_WITH_ME, PREDEFINED } from '../common/constants.tsx';
import type { SavedSearchCategory, LinkedAlert, SavedSearch } from '../common/types.tsx';
import {
	PREDEFINED_FILTER_ALL,
	predefinedIdToNameMap,
} from './actions/fetch-saved-searches/constants.tsx';
import { type Actions, actions } from './actions/index.tsx';
import type { State, LinkModalFilter } from './types.tsx';
import { getSelectableAlertIds } from './utils/alert.tsx';

export const DEFAULT_SELECTED_SAVED_SEARCH_ID = PREDEFINED_FILTER_ALL;

// Consider updating resetState when adding more properties to this react-sweet-state, as state values will not be reset automatically on modal close
export const initialState: State = {
	isViewModalOpen: false,
	isLinkModalOpen: false,
	linkedAlerts: {
		forIssueId: undefined,
		isLoading: false,
		error: undefined,
		data: undefined,
		startIndex: null,
	},
	linkModalAlerts: {
		startIndex: 0,
		isLoading: true,
		error: undefined,
		data: undefined,
	},
	linkModalFilter: {
		selectedSavedSearchId: DEFAULT_SELECTED_SAVED_SEARCH_ID,
		savedSearchOptions: {
			isLoading: false,
			error: undefined,
			data: {
				[PREDEFINED]: [],
				[CREATED_BY_ME]: [],
				[SHARED_WITH_ME]: [],
			},
		},
		textSearch: undefined,
	},
	selectedAlertsToLinkIds: [],
};

export const resetState = (state: State, setState: (arg1: Partial<State>) => void) => {
	setState({
		...initialState,
		isViewModalOpen: state.isViewModalOpen,
		isLinkModalOpen: state.isLinkModalOpen,
		linkedAlerts: {
			...initialState.linkedAlerts,
			forIssueId: state.linkedAlerts.forIssueId,
			data: state.linkedAlerts.data,
			startIndex: state.linkedAlerts.startIndex,
		}, // need to preserve this to show count of linked alerts on Issue View page
		linkModalFilter: state.linkModalFilter,
	});
};

const incidentManagementLinkedAlertsStore = createStore<State, Actions>({
	name: 'JSDIncidentManagementLinkedAlertsModal',
	initialState,
	actions,
});

export type PropType = {
	cloudId: CloudId;
};

const _IncidentManagementLinkedAlertsContainerImpl = createContainer<State, Actions, PropType>(
	incidentManagementLinkedAlertsStore,
);

export const useLinkedAlertsViewModalState = createHook(incidentManagementLinkedAlertsStore, {
	selector: ({ isViewModalOpen }) => isViewModalOpen,
});

const useLinkedAlertsImpl = createHook(incidentManagementLinkedAlertsStore, {
	selector: ({ linkedAlerts }) => linkedAlerts,
});

const useAlertsSelectionStateInternal = createHook(incidentManagementLinkedAlertsStore, {
	selector: ({ linkModalAlerts: { data }, selectedAlertsToLinkIds }) => {
		if (data?.results == null) {
			return {
				selectedAlertIds: [],
				selectableAlertIds: [],
			};
		}

		const selectableAlertIds = getSelectableAlertIds(data.results);

		return {
			selectedAlertIds: selectedAlertsToLinkIds,
			selectableAlertIds,
		};
	},
});

/**
 * Need to use useAlertsSelectionStateInternal and recreate result here, because passing useAlertsSelectionStateInternal directly as useAlertsSelectionState causes Flow error.
 * Fixing would require defining all actions' types in common/alerts-table, even those it does not use. And this is not possible because common/ should not import from services
 */
export const useAlertsSelectionState = () => {
	const [state, { onSelectAllChange }] = useAlertsSelectionStateInternal();
	return [state, onSelectAllChange] as const;
};

// NOTE: use this when you need only store actions (not state data) - not to recalc components where this hook is used (when state changes)
export const useLinkAlertsModalActions = createHook(incidentManagementLinkedAlertsStore, {
	selector: null,
});

const useSelectedLinkModalAlertIdsInternal = createHook(incidentManagementLinkedAlertsStore, {
	selector: ({ selectedAlertsToLinkIds }) => selectedAlertsToLinkIds,
});

export const useSelectedLinkModalAlertIds = () => {
	const [state, { onAlertCheckboxChanged }] = useSelectedLinkModalAlertIdsInternal();
	return [state, onAlertCheckboxChanged] as const;
};

export const useLinkedAlerts = () => {
	const issueId = useIssueId();
	const [hasOpsgenieAccount] = useHasCurrentUserOpsgenieAccount();
	const [linkedAlerts, { fetchLinkedAlerts }] = useLinkedAlertsImpl();

	return [
		linkedAlerts,
		{
			fetchLinkedAlerts: useCallback(
				(cloudId: CloudId, analyticsEvent: UIAnalyticsEvent, startIndex: number | null = null) => {
					fetchLinkedAlerts({
						issueId,
						cloudId,
						hasOpsgenieAccount,
						analyticsEvent,
						startIndex,
					});
				},
				[fetchLinkedAlerts, issueId, hasOpsgenieAccount],
			),
		},
	] as const;
};

export const useLinkedAlertsItems = () => {
	const [{ data }] = useLinkedAlerts();

	return data;
};

export const useLinkAlertsModal = createHook(incidentManagementLinkedAlertsStore, {
	selector: ({ isLinkModalOpen }) => isLinkModalOpen,
});

export const LinkAlertsModalSubscriber = createSubscriber<State, Actions>(
	incidentManagementLinkedAlertsStore,
);

export const useLinkModalAlerts = createHook(incidentManagementLinkedAlertsStore, {
	selector: ({ linkModalAlerts }) => linkModalAlerts,
});

export const useLinkModalAlertsItems = (): LinkedAlert[] => {
	const [{ data }] = useLinkModalAlerts();

	return data?.results || [];
};

export const useLinkModalFilter = createHook(incidentManagementLinkedAlertsStore, {
	selector: ({ linkModalFilter }) => linkModalFilter,
});

export const getSelectedSavedSearch = (
	linkModalFilter: LinkModalFilter,
): SavedSearch | undefined => {
	let result;

	const { data } = linkModalFilter.savedSearchOptions;
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const dataKeys: SavedSearchCategory[] = Object.keys(data) as Array<keyof typeof data>;

	dataKeys.some((groupKey: SavedSearchCategory) => {
		// .some( is used instead of .forEach( to be able to break out of the loop. Usage of for ... of is prohibited by (an obsolete) eslint rule ("iterators/generators require regenerator-runtime" rule in `no-restricted-syntax` one).
		result = linkModalFilter.savedSearchOptions.data[groupKey].find(
			(savedSearch) => savedSearch.id === linkModalFilter.selectedSavedSearchId,
		);
		return !!result; // exit loop early
	});

	return result;
};

export const useSelectedSavedSearch = createHook(incidentManagementLinkedAlertsStore, {
	selector: (state) => getSelectedSavedSearch(state.linkModalFilter),
});

export const getSelectedSavedSearchText = (
	linkModalFilter: LinkModalFilter,
	formatMessage: FormatMessage,
): string => {
	// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly "preDefined--ALL": MessageDescriptorV2; readonly "preDefined--Open": MessageDescriptorV2; readonly "preDefined--Closed": MessageDescriptorV2; readonly "preDefined--Un'Acked": MessageDescriptorV2; readonly "preDefined--Not Seen": MessageDescriptorV2; readonly "preDefined--Assigned to me": MessageDescriptor...'.
	const predefinedSearchText = predefinedIdToNameMap[linkModalFilter.selectedSavedSearchId];
	if (predefinedSearchText) return formatMessage(predefinedSearchText);

	const selectedSavedSearch = getSelectedSavedSearch(linkModalFilter);
	return selectedSavedSearch?.name || '';
};
