import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import AsyncIcon from '@atlassian/jira-common-components-async-icon/src/view.tsx';
import type { SelectValueShape } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types.tsx';

type Props = {
	iconUrl: string;
	content: string;
};

export const SingleSelectFieldReadView = ({ iconUrl, content }: Props) => (
	<Wrapper>
		<AsyncIconWrapper>
			<AsyncIcon url={iconUrl} />
		</AsyncIconWrapper>
		<Text>{content}</Text>
	</Wrapper>
);

export const singleSelectFieldRenderReadView = (value?: SelectValueShape | null) => {
	if (!value?.iconUrl) {
		return undefined;
	}
	const { iconUrl, content } = value;

	return () => <SingleSelectFieldReadView iconUrl={iconUrl} content={content} />;
};

export default SingleSelectFieldReadView;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Text = styled.span({
	marginLeft: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AsyncIconWrapper = styled.div({
	display: 'flex',
	paddingLeft: token('space.075'),
	paddingRight: token('space.075'),
});
