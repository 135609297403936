// eslint-disable-next-line jira/restricted/react-suspense
import React, { type PropsWithChildren, Suspense, Fragment, useCallback } from 'react';

import { graphql, useFragment } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import { useIssueId, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { AssigneeInlineEditView } from '@atlassian/jira-issue-field-assignee-inline-edit-full/src/ui/assignee/index.tsx';
import type { AggUser } from '@atlassian/jira-issue-user-picker-edit-view/src/common/types.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import { useFieldExperienceTracking } from '@atlassian/jira-issue-view-layout-experience-tracking/src/field-experience-tracking/index.tsx';
import { IssueViewFieldHeading } from '@atlassian/jira-issue-view-layout-field-heading/src/ui/index.tsx';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { ui_issueViewLayoutAssigneeField_IssueViewAssigneeField_rootRelayFragment$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutAssigneeField_IssueViewAssigneeField_rootRelayFragment.graphql';
import type { ui_issueViewLayoutAssigneeField_IssueViewAssigneeField_fragmentKey$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutAssigneeField_IssueViewAssigneeField_fragmentKey.graphql';
import { AssignToMeInlineEdit as AssignToMeInlineEditOld } from './assign-to-me-inline-edit/index.tsx';
import { AssignToMeInlineEdit } from './assign-to-me-inline-edit/AssignToMeInlineEdit.tsx';
import { CommandPaletteAssignee as CommandPaletteAssigneeOld } from './command-palette-assignee/index.tsx';
import { CommandPaletteAssignee } from './command-palette-assignee/CommandPaletteAssignee.tsx';

export interface IssueViewAssigneeFieldProps {
	area?: Area;
	fragmentKey: ui_issueViewLayoutAssigneeField_IssueViewAssigneeField_fragmentKey$key;
	rootRelayFragment: ui_issueViewLayoutAssigneeField_IssueViewAssigneeField_rootRelayFragment$key | null;
}

const MayBeSuspense = componentWithFG(
	'jfp_magma_jira-suspensed-assign-me',
	({ children }: PropsWithChildren) => <Suspense fallback={null}>{children}</Suspense>,
	Fragment,
);

export const IssueViewAssigneeField = ({
	area,
	fragmentKey,
	rootRelayFragment,
}: IssueViewAssigneeFieldProps) => {
	const issueId = useIssueId();
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();

	const data = useFragment<ui_issueViewLayoutAssigneeField_IssueViewAssigneeField_fragmentKey$key>(
		graphql`
			fragment ui_issueViewLayoutAssigneeField_IssueViewAssigneeField_fragmentKey on JiraSingleSelectUserPickerField {
				...ui_issueViewLayoutFieldHeading_IssueViewFieldHeading
				...assignee_issueFieldAssigneeInlineEditFull_AssigneeInlineEditView_fragmentRef
				...assignToMeInlineEdit_issueViewLayoutAssigneeField_fragmentKey
				...AssignToMeInlineEdit_fragmentKey
				...commandPaletteAssignee_issueViewLayoutAssigneeField
				...CommandPaletteAssignee
				fieldId
				type
				__typename
			}
		`,
		fragmentKey,
	);

	const rootQueryData =
		useFragment<ui_issueViewLayoutAssigneeField_IssueViewAssigneeField_rootRelayFragment$key>(
			graphql`
				fragment ui_issueViewLayoutAssigneeField_IssueViewAssigneeField_rootRelayFragment on Query {
					...AssignToMeInlineEdit
					me {
						user {
							...CommandPaletteAssignee_me_user
						}
					}
				}
			`,
			rootRelayFragment && fg('assign-to-me-user-preloaded-in-issue-view')
				? rootRelayFragment
				: null,
		);

	const {
		sendSubmitSucceededExperienceEvent,
		sendSubmitFailedExperienceEvent,
		sendEditCancelExperienceEvent,
	} = useFieldExperienceTracking({ fieldId: data.fieldId });

	const onSubmit = useCallback(
		(value: AggUser | null) => {
			issueId &&
				fieldChangeRequested(issueId, data.fieldId, value, undefined, {
					type: data.type,
					__typename: data.__typename,
				});
		},
		[data.__typename, data.fieldId, data.type, fieldChangeRequested, issueId],
	);

	const onSubmitSucceeded = useCallback(
		(value: AggUser | null) => {
			issueId &&
				fieldChanged(issueId, data.fieldId, value, {
					type: data.type,
					__typename: data.__typename,
				});
			sendSubmitSucceededExperienceEvent();
		},
		[
			data.__typename,
			data.fieldId,
			data.type,
			fieldChanged,
			issueId,
			sendSubmitSucceededExperienceEvent,
		],
	);

	const onSubmitFailed = useCallback(
		(error?: Error) => {
			issueId && fieldChangeFailed(issueId, data.fieldId);
			sendSubmitFailedExperienceEvent(error);
		},
		[data.fieldId, fieldChangeFailed, issueId, sendSubmitFailedExperienceEvent],
	);

	const onCancel = useCallback(() => {
		sendEditCancelExperienceEvent();
	}, [sendEditCancelExperienceEvent]);

	return (
		<UFOSegment name="issue-field-assignee">
			<IssueViewFieldHeading
				area={area}
				fragmentKey={data}
				testId={`issue.issue-view-layout.issue-view-assignee-field.${data.fieldId}`}
			>
				<Box
					xcss={fieldWrapperStyles}
					// eslint-disable-next-line jira/integration/test-id-by-folder-structure
					testId="issue.views.field.user.assignee"
				>
					<AssigneeInlineEditView
						fragmentRef={data}
						onSubmit={onSubmit}
						onSubmitSucceeded={onSubmitSucceeded}
						onSubmitFailed={onSubmitFailed}
						onCancel={onCancel}
						projectKey={projectKey}
					/>
					<Box
						xcss={assignToMeWrapperStyles}
						testId="issue-view-layout-assignee-field.ui.assign-to-me"
					>
						<MayBeSuspense>
							{fg('assign-to-me-user-preloaded-in-issue-view') ? (
								<AssignToMeInlineEdit
									fragmentKey={data}
									rootRelayFragment={rootQueryData}
									projectKey={projectKey}
									onSubmit={onSubmit}
									onSubmitSucceeded={onSubmitSucceeded}
									onSubmitFailed={onSubmitFailed}
								/>
							) : (
								<AssignToMeInlineEditOld
									fragmentKey={data}
									projectKey={projectKey}
									onSubmit={onSubmit}
									onSubmitSucceeded={onSubmitSucceeded}
									onSubmitFailed={onSubmitFailed}
								/>
							)}
						</MayBeSuspense>
					</Box>
				</Box>
			</IssueViewFieldHeading>
			<MayBeSuspense>
				{fg('assign-to-me-user-preloaded-in-issue-view') ? (
					<CommandPaletteAssignee
						fragmentKey={data}
						userFragment={rootQueryData?.me?.user ?? null}
					/>
				) : (
					<CommandPaletteAssigneeOld fragmentKey={data} />
				)}
			</MayBeSuspense>
		</UFOSegment>
	);
};

const fieldWrapperStyles = xcss({
	marginLeft: 'space.negative.100',
	marginRight: 'space.100',
});

const assignToMeWrapperStyles = xcss({
	marginLeft: 'space.100',
});
