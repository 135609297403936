import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';

const PERSONALIZATION_BASE_URL = '/gateway/api/tap-delivery/api/v3/personalization';
const SITE_QUERY_PARAM = '/site/';
const LOG_LOCATION =
	'confluence-content-placeholder-template-fetch-personalization-site-user-traits';

export type TapTrait = {
	name: string;
	value: boolean | string | number;
};

export type PersonalizationAPIResponse = {
	attributes: TapTrait[];
};

function getUserPersonalizationUrl(cloudId: string) {
	return `${PERSONALIZATION_BASE_URL}${SITE_QUERY_PARAM}${cloudId}`;
}

export const fetchPersonalizationSiteTraits = async (cloudId: string) => {
	try {
		const response = await fetchJson<PersonalizationAPIResponse>(
			getUserPersonalizationUrl(cloudId),
		);

		return response.attributes;
	} catch (error) {
		fireErrorAnalytics({
			meta: {
				id: LOG_LOCATION,
				packageName: '@atlassian/jira-issue-content-placeholder-template',
				teamName: 'starburst',
			},
			attributes: { errorCode: error instanceof FetchError ? error.statusCode : null },
			error: {
				name: 'error',
				message: `Failed to fetch TAP traits via the personalization api for cloudId : ${cloudId}`,
			},
		});
		return null;
	}
};
