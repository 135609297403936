import { parseISO, isValid } from 'date-fns';
import {
	BYDAY,
	BYHOUR,
	BYMINUTE,
	BYSECOND,
	FREQ,
	INTERVAL,
	UNTIL,
	BYMONTHDAY,
	BYMONTH,
	DTSTART,
	TZID,
	MISSING,
	EMPTY,
	NEWLINE_REGEX,
	RRULE,
	INVALID,
	FOLD_LINES_REGEX,
} from './constants.tsx';

import type {
	ByDay,
	ValidationResult,
	RRuleProperty,
	DTStartProperty,
	ICalField,
} from './types.tsx';

import {
	extractTZID,
	isValidHour,
	isValidMonthDay,
	isValidMonth,
	isValidSecond,
	isValidMinute,
	isValidByDayValue,
	isValidInterval,
	isValidFrequency,
	toRuleProperty,
	toDTStartProperty,
	toICalSupportedField,
	isICalField,
} from './utils.tsx';

type ValidationRule = (value: string) => boolean | ((value: ByDay) => boolean);

type RuleValidator = Record<RRuleProperty, ValidationRule>;
type DateTimeValidator = Record<DTStartProperty, ValidationRule>;

const isValidMultiValue = (value: string, validator: (value: string) => boolean): boolean => {
	return value.split(',').every(validator);
};

const getValidator = <T extends string>(
	fieldKey: string,
	toProperty: (key: string) => T | undefined,
	validators: Record<T, ValidationRule>,
): ValidationRule | undefined => {
	const validatorKey = toProperty(fieldKey?.toUpperCase());
	return validatorKey ? validators[validatorKey] : undefined;
};

const ruleValidators: RuleValidator = {
	[FREQ]: (value: string): boolean => isValidFrequency(value),
	[INTERVAL]: (value: string): boolean => isValidInterval(value),
	[BYHOUR]: (value: string): boolean => isValidMultiValue(value, isValidHour),
	[BYMINUTE]: (value: string): boolean => isValidMultiValue(value, isValidMinute),
	[BYSECOND]: (value: string): boolean => isValidMultiValue(value, isValidSecond),
	[BYDAY]: (value: string): boolean => isValidMultiValue(value, isValidByDayValue),
	[BYMONTHDAY]: (value: string): boolean => isValidMultiValue(value, isValidMonthDay),
	[BYMONTH]: (value: string): boolean => isValidMultiValue(value, isValidMonth),
	[UNTIL]: (value: string): boolean => {
		const date = parseISO(value);
		return isValid(date);
	},
};
const getRuleValidators = (fieldKey: string): ValidationRule | undefined =>
	getValidator(fieldKey, toRuleProperty, ruleValidators);

const datetimeValidators: DateTimeValidator = {
	[DTSTART]: (value: string): boolean => {
		const date = parseISO(value);
		return isValid(date);
	},
	[TZID]: (value: string): boolean => /^[A-Za-z_]+\/[A-Za-z_]+$/.test(value),
};
const getDateTimeValidator = (fieldKey: string): ValidationRule | undefined =>
	getValidator(fieldKey, toDTStartProperty, datetimeValidators);

function validateDateTime(
	fieldKey: string,
	dtString: string,
): {
	isValid: boolean;
	errors: { [key: string]: string }[];
} {
	const { dateTimeString, timezone } = extractTZID(dtString);
	const invalidProperties: { [key: string]: string }[] = [];

	const datetime = dateTimeString.trim();
	const datetimeValidator = getDateTimeValidator(fieldKey);
	if (!datetimeValidator || !datetimeValidator(datetime)) {
		invalidProperties.push({ [fieldKey]: dateTimeString });
	}

	if (timezone !== undefined) {
		const timezoneValidator = datetimeValidators[TZID];
		if (!timezone || !timezoneValidator(timezone)) {
			invalidProperties.push({ TZID: timezone || '' });
		}
	}

	return {
		isValid: invalidProperties.length === 0,
		errors: invalidProperties,
	};
}

/**
 * Validates the DTSTART property of an iCalendar string.
 *
 * @param {string} dtstartString - The DTSTART string to validate.
 * @returns {{ isValid: boolean, properties: { [key: string]: string }[] }} The validation result.
 */
export function validateDTStart(dtstartString: string): ValidationResult {
	return validateDateTime(DTSTART, dtstartString);
}

/**
 * Validates the RRULE property of an iCalendar string.
 *
 * @param {string} rruleString - The RRULE string to validate.
 * @returns {{ isValid: boolean, properties: { [key: string]: string }[] }} The validation result.
 */
export function validateRRule(rruleString: string): ValidationResult {
	if (!rruleString?.toUpperCase().startsWith(`${RRULE}:`)) {
		return { isValid: false, errors: [{ RRULE: rruleString }] };
	}

	const ruleBody = rruleString.split(':')?.[1];
	if (!ruleBody) {
		return { isValid: false, errors: [{ RRULE: rruleString }] };
	}

	const rules = ruleBody.split(';');
	let hasFrequency = false;
	const invalidProperties: { [key: string]: string }[] = [];

	const isValidRecurrenceRule = rules.every((rule) => {
		const [key, value] = rule.split('=');
		const upperCaseKey = key?.toUpperCase();
		if (!key || !value) {
			invalidProperties.push({ [key || 'invalidKey']: value || 'invalidValue' });
			return false;
		}

		const validator = getRuleValidators(upperCaseKey);
		if (!validator || !validator(value)) {
			invalidProperties.push({ [key]: value });
			return false;
		}

		if (upperCaseKey === FREQ) {
			hasFrequency = true;
		}

		return true;
	});

	if (!hasFrequency && invalidProperties.length === 0) {
		invalidProperties.push({ FREQ: MISSING });
	}

	return {
		isValid: isValidRecurrenceRule && hasFrequency,
		errors: invalidProperties,
	};
}

type EventValidator = (line: string) => ValidationResult;

const eventSupportedFieldValidators: Partial<Record<ICalField, EventValidator>> = {
	[DTSTART]: validateDTStart,
	[RRULE]: validateRRule,
};

const getICalSupportedFieldValidator = (fieldKey: string): EventValidator | undefined => {
	const validatorKey = toICalSupportedField(fieldKey?.toUpperCase());
	return validatorKey ? eventSupportedFieldValidators[validatorKey] : undefined;
};

/**
 * Validates an iCalendar string.
 *
 * @param {string} iCalString - The iCalendar string to validate.
 * @returns {{ isValid: boolean, errors: { [key: string]: string }[] }} The validation result.
 */
export function validate(iCalString: string | undefined | null): ValidationResult {
	if (!iCalString || iCalString.trim() === '') {
		return { isValid: false, errors: [{ ICAL_STRING: EMPTY }] };
	}

	const errors =
		iCalString
			?.replace(FOLD_LINES_REGEX, '')
			?.split(NEWLINE_REGEX)
			?.map((line) => line.trim())
			?.filter(Boolean)
			?.flatMap((line) => {
				const [property] = line?.split(':')?.[0]?.split(';');
				const upperCaseProperty = property?.toUpperCase();
				const validator = getICalSupportedFieldValidator(upperCaseProperty);

				if (validator) {
					const validationResult = validator(line);
					return validationResult.isValid ? [] : validationResult.errors;
				}

				return isICalField(upperCaseProperty) ? [] : [{ ICAL_STRING: INVALID }];
			}) ?? [];

	return {
		isValid: errors.length === 0,
		errors,
	};
}
