import { expVal } from '@atlassian/jira-feature-experiments';
import { useIsArchived } from '@atlassian/jira-issue-context-service/src/index.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { useProjectKey, useEdition } from '@atlassian/jira-project-context-service/src/main.tsx';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { PREMIUM_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';

export const useIsDeleteIssueExperimentEnabled = () => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const edition = useEdition(projectKey, true);
	const isPremium = edition === PREMIUM_EDITION;

	const [permissions] = useProjectPermissions(projectKey);

	const isArchived = useIsArchived();

	const [issueType] = useFieldValue({ issueKey, fieldKey: 'issuetype' });
	const isSubtask = issueType?.subtask;

	return (
		!isSubtask &&
		!isArchived &&
		permissions.canArchiveIssue &&
		isPremium &&
		expVal('issue_deletion_a_two_step_process_ga', 'isDeleteTwoStepGaEnabled', false)
	);
};
