// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	productRequirementsTemplateName: {
		id: 'confluence-placeholder-template.product-requirements-template-name',
		defaultMessage: 'Product requirements',
		description: 'Name of the Product Requirements template',
	},
	projectPlanTemplateName: {
		id: 'confluence-placeholder-template.project-plan-template-name',
		defaultMessage: 'Project plan',
		description: 'Name of the Project Plan template',
	},
	knownErrorsTemplateName: {
		id: 'confluence-placeholder-template.known-errors-template-name',
		defaultMessage: 'Known errors',
		description: 'Name of the Known Errors template',
	},
	privacyPolicy: {
		id: 'confluence-placeholder-template.generic-templates.privacy-policy',
		defaultMessage: 'Learn more about your data privacy with Atlassian',
		description: "Link to Atlassian's privacy policy",
	},
	dismissFlagTitle: {
		id: 'confluence-placeholder-template.dismiss-flag-title',
		defaultMessage: 'Thanks for letting us know',
		description: 'Title of the flag that shows up when a user dismisses the placeholder template',
	},
	dismissFlagDescription: {
		id: 'confluence-placeholder-template.dismiss-flag-description',
		defaultMessage: "You won't be seeing this here anymore",
		description:
			'Description of the flag that shows up when a user dismisses the placeholder template',
	},
	reasonsModalTitle: {
		id: 'confluence-placeholder-template.reasons-modal-title',
		defaultMessage: 'Why am I seeing this?',
		description: 'Title of the reasons modal',
	},
	reasonsModalExplanation: {
		id: 'confluence-placeholder-template.reasons-modal-explanation',
		defaultMessage:
			"We think <strong>Confluence</strong> will improve your team's productivity. The recommendation is based on:",
		description: 'Explanation as to why the placeholder template is being shown',
	},
	reasonsModalBulletOne: {
		id: 'confluence-placeholder-template.reasons-modal-bullet-one',
		defaultMessage: 'the size of your team',
		description: 'First bullet point of the reasons modal',
	},
	reasonsModalBulletTwo: {
		id: 'confluence-placeholder-template.reasons-modal-bullet-two',
		defaultMessage: 'your existing Atlassian products',
		description: 'Second bullet point of the reasons modal',
	},
	moreLabel: {
		id: 'confluence-placeholder-template.reasons-menu-label',
		defaultMessage: 'More',
		description: 'Dropdown button label for actions button in Confluence placeholder template',
	},
	popupText: {
		id: 'issue.details.confluence-content.placeholder-template-popup',
		defaultMessage: "This template is powered by Confluence, Atlassian's documentation tool.",
		description:
			'Popup text that is displayed when the user clicks the placehodler template info icon.',
	},
	popupIconLabel: {
		id: 'issue.details.confluence-content.placeholder-template-popup-icon-label',
		defaultMessage: 'Details about Confluence templates.',
		description:
			'Accessible label for the information icon in the header for Confluence placeholder templates',
	},
	showMore: {
		id: 'issue.details.confluence-content.show-more',
		defaultMessage: 'Show more',
		description: 'Label for the show more button in the confluence content',
	},
	mentionedPages: {
		id: 'issue.details.confluence-pages.mentioned-on',
		defaultMessage: 'mentioned on',
		description: 'Heading for the mentioned pages section in the confluence content',
	},
});
