import { useCallback, useEffect, useMemo, useState } from 'react';
import memoizeOne from 'memoize-one';
import { createSessionStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/session-storage/index.tsx';
import { useTenantHasConfluence } from '@atlassian/jira-business-confluence-pages/src/utils/use-tenant-has-confluence/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import {
	CONFLUENCE_ACCESS_STORAGE_KEY,
	CONFLUENCE_ACCESS_STORAGE_PROVIDER_KEY,
} from '../../constants.tsx';
import { fetchConfluenceUserPermissions } from '../../services/permissions/index.tsx';
import {
	getSessionStorageData,
	setSessionStorageData,
	isStorageKeyExpired,
} from '../../services/session-storage/index.tsx';
import { useEmbeddedPageTracking } from '../use-embedded-page-tracking/index.tsx';

const TIME_TO_LIVE = 1800000; // 30 minutes

const storage = createSessionStorageProvider(CONFLUENCE_ACCESS_STORAGE_PROVIDER_KEY);

// useUserHasConfluenceAccess is being called in mulitple locations on page render
// The cache has not been updated in time for the other calls to read from it
// This leads to multiple /permissions/permitted requests being made as caching is not being utilised

// Memoizing this function is one way to solve this
// TODO: MemoizeOne is not intended to support promises
// It will also cache errors when the promise rejects
export const fetchConfluenceUserPermissionsMemoized = memoizeOne(fetchConfluenceUserPermissions);

export const useUserHasConfluenceAccess = (
	embeddedConfluenceSource?: string,
	skip = false,
): { hasAccess: boolean; refetchHasAccess: () => Promise<void> } => {
	const cloudId = useCloudId();
	const fireAnalyticsEvent = useEmbeddedPageTracking();
	const tenantHasConfluence = useTenantHasConfluence();
	const [hasAccess, setHasAccess] = useState(false);
	const permitted = useMemo(
		() => getSessionStorageData(CONFLUENCE_ACCESS_STORAGE_KEY, storage)?.permitted,
		[],
	);

	const getConfluencePermissions = useCallback(async () => {
		try {
			if (!cloudId) return;
			const response = await fetchConfluenceUserPermissionsMemoized(cloudId);

			fireAnalyticsEvent({
				action: 'success',
				eventName: 'userConfluenceAccess',
				embeddedConfluenceSource,
			});
			setSessionStorageData({
				key: CONFLUENCE_ACCESS_STORAGE_KEY,
				value: response,
				ttl: TIME_TO_LIVE,
				storage,
			});
			setHasAccess(response?.permitted);
		} catch (error) {
			fireErrorAnalytics({
				meta: {
					id: 'userConfluenceAccess',
					teamName: 'confluence-better-together',
				},
				attributes: { embeddedConfluenceSource },
				error: new Error(`Failed to get user confluence access status: ${error}`),
			});
		}
	}, [cloudId, embeddedConfluenceSource, fireAnalyticsEvent]);

	const refetchHasAccess = useCallback(async () => {
		setSessionStorageData({
			key: CONFLUENCE_ACCESS_STORAGE_KEY,
			value: {},
			ttl: TIME_TO_LIVE,
			storage,
		});
		fetchConfluenceUserPermissionsMemoized.clear();
		await getConfluencePermissions();
	}, [getConfluencePermissions]);

	useEffect(() => {
		if (skip) {
			return;
		}

		if (!getSessionStorageData(CONFLUENCE_ACCESS_STORAGE_KEY, storage)) {
			setSessionStorageData({
				key: CONFLUENCE_ACCESS_STORAGE_KEY,
				value: {},
				ttl: TIME_TO_LIVE,
				storage,
			});
		}

		if (!isStorageKeyExpired(CONFLUENCE_ACCESS_STORAGE_KEY, storage) && permitted !== undefined) {
			setHasAccess(permitted);
			return;
		}

		if (!tenantHasConfluence) {
			setSessionStorageData({
				key: CONFLUENCE_ACCESS_STORAGE_KEY,
				value: { permitted: false },
				ttl: TIME_TO_LIVE,
				storage,
			});
			setHasAccess(false);
			return;
		}

		getConfluencePermissions();
	}, [
		embeddedConfluenceSource,
		fireAnalyticsEvent,
		getConfluencePermissions,
		permitted,
		skip,
		tenantHasConfluence,
	]);
	return { hasAccess, refetchHasAccess };
};
