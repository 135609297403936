import React, { useCallback, useState, useMemo } from 'react';
import { useFragment, graphql, useMutation } from 'react-relay';
import { fg } from '@atlassian/jira-feature-gating';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import { useFieldInlineEditActions } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/index.tsx';
import type { OnSubmitCallbacks } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/types.tsx';
import type { ValidationFieldProps } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/field-inline-edit-lite/types.tsx';
import { FieldInlineEditLiteWithEntryPoint } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/index.tsx';
import type { ParentFieldOption } from '@atlassian/jira-issue-field-parent-editview-full/src/common/types.tsx';
import ParentEditViewEntryPoint from '@atlassian/jira-issue-field-parent-editview-full/src/entrypoint.tsx';
import type {
	NullableParentOption,
	ParentEditViewProps,
} from '@atlassian/jira-issue-field-parent-editview-full/src/ui/parent/types.tsx';
import { transformParentNode } from '@atlassian/jira-issue-field-parent-editview-full/src/utils/transform-data/index.tsx';
import { ParentReadView } from '@atlassian/jira-issue-field-parent-readview-full/src/ui/parent/index.tsx';
import type { parent_issueFieldParent_ParentField_Mutation as ParentMutation } from '@atlassian/jira-relay/src/__generated__/parent_issueFieldParent_ParentField_Mutation.graphql';
import type { parent_issueFieldParentInlineEditFull_ParentInlineEditView_fragmentRef$key as ParentFragment } from '@atlassian/jira-relay/src/__generated__/parent_issueFieldParentInlineEditFull_ParentInlineEditView_fragmentRef.graphql';
import type { parent_issueFieldParentInlineEditFull_ParentInlineEditViewIsEditable_fragmentRef$key as ParentWithIsEditableFragment } from '@atlassian/jira-relay/src/__generated__/parent_issueFieldParentInlineEditFull_ParentInlineEditViewIsEditable_fragmentRef.graphql';
import type {
	ParentInlineEditViewProps,
	ParentInlineEditViewWithIsEditableProps,
} from './types.tsx';
import { transformOptionToAgg, transformOptionToOptimisticFieldResponse } from './utils.tsx';

const isEqualParent = (a: NullableParentOption, b: NullableParentOption) => a?.value === b?.value;

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components. This variant allows
 * consumers to define their own value to determine whether the field is editable.
 *
 * In most cases consumers should use `ParentInlineEditView` which will enforce that the user has permission to
 * edit the field within the issue view. However, this component can be used for experiences that have differing
 * permissions or want finer control for how this data is retrieved, e.g. lazy loading editability.
 *
 * @param props [ParentInlineEditViewWithIsEditableProps](./types.tsx)
 */
export const ParentInlineEditViewIsEditable = ({
	attributes,
	editViewPopup,
	editViewPopupAlignBlock,
	fragmentRef,
	menuPosition,
	menuPortalTarget,
	onSubmit,
	onSubmitFailed,
	onSubmitSucceeded,
	isEditable,
	readViewFitContainerHeight,
	isTruncated,
	spacing = 'compact',
	emptyText,
	isSingleLineRowHeightEnabled,
	renderHoverPopover,
}: ParentInlineEditViewWithIsEditableProps) => {
	// #region Relay
	const data = useFragment<ParentWithIsEditableFragment>(
		graphql`
			fragment parent_issueFieldParentInlineEditFull_ParentInlineEditViewIsEditable_fragmentRef on JiraParentIssueField {
				...parent_issueFieldParentReadviewFull_ParentReadView
				id
				fieldId
				type
				name
				parentIssue {
					id
					# eslint-disable-next-line @atlassian/relay/unused-fields used in transformParentNode
					issueId
					# eslint-disable-next-line @atlassian/relay/unused-fields used in transformParentNode
					webUrl
					# eslint-disable-next-line @atlassian/relay/unused-fields used in transformParentNode
					key
					# eslint-disable-next-line @atlassian/relay/unused-fields used in transformParentNode
					issueColorField {
						color {
							colorKey
						}
					}
					# eslint-disable-next-line @atlassian/relay/unused-fields used in transformParentNode
					fieldsById(ids: ["summary", "issuetype", "status"]) {
						edges {
							node {
								... on JiraSingleLineTextField {
									fieldId
									text
								}
								... on JiraIssueTypeField {
									fieldId
									issueType {
										issueTypeId
										name
										avatar {
											xsmall
										}
										hierarchy {
											level
										}
									}
								}
								... on JiraStatusField {
									fieldId
									status {
										statusCategory {
											statusCategoryId
										}
									}
								}
							}
						}
					}
				}
			}
		`,
		fragmentRef,
	);
	const { overriding } = useInlineEditFieldInjections();
	const { id: uniqueFieldId, fieldId, type, name } = data;

	const fieldName = overriding.overrideLabel(name);

	const isFieldEditable = overriding?.overrideIsEditable(isEditable);

	const [commit] = useMutation<ParentMutation>(graphql`
		mutation parent_issueFieldParent_ParentField_Mutation($input: JiraUpdateParentFieldInput!) {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateParentField(input: $input) {
					success
					errors {
						message
					}
					field {
						...parent_issueFieldParentInlineEditFull_ParentInlineEditViewIsEditable_fragmentRef
					}
				}
			}
		}
	`);
	// #endregion
	const initialValue = useMemo(() => {
		if (data?.parentIssue) {
			return transformParentNode(data.parentIssue);
		}
		return null;
	}, [data.parentIssue]);

	// #region Common state
	const [updatedValue, setUpdatedValue] = useState<NullableParentOption>(initialValue);

	const handleSubmit = useCallback(
		(option: NullableParentOption, { onSuccess, onFail }: OnSubmitCallbacks) => {
			onSubmit?.(transformOptionToAgg(option));
			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operation: {
							operation: 'SET',
							id: option?.value?.toString() || null,
						},
					},
				},
				onCompleted: (mutationData) => {
					if (mutationData.jira?.updateParentField?.success) {
						onSuccess();
					} else {
						onFail();
					}
				},
				onError(error) {
					onFail(error);
				},
				optimisticResponse: {
					jira: {
						updateParentField: {
							success: true,
							errors: [],
							field: transformOptionToOptimisticFieldResponse(data, option?.aggValue),
						},
					},
				},
			});
		},
		[onSubmit, commit, uniqueFieldId, data],
	);

	const handleSubmitSucceeded = useCallback(
		(option: NullableParentOption) => {
			onSubmitSucceeded?.(transformOptionToAgg(option));
		},
		[onSubmitSucceeded],
	);
	const setAnalyticsAttributes = fg('one_event_rules_them_all_fg')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useCallback(
				(value: ParentFieldOption | null) => {
					if (initialValue !== value) {
						const oldValId = initialValue?.issueId;
						const newValId = value?.issueId || null;

						getUpdateAnalyticsFlowHelper().setActionTakenAttributes(fieldId, oldValId, newValId);
					}
				},
				[fieldId, initialValue],
			)
		: undefined;

	const {
		hasServerValidationError,
		handleCancel,
		handleEdit,
		handleConfirm,
		handleChangeAndConfirm,
		invalidMessage,
		isEditing,
	} = useFieldInlineEditActions({
		attributes,
		fieldId,
		fieldName,
		fieldType: type,
		initialValue,
		isValueEqual: isEqualParent,
		onSubmit: handleSubmit,
		onSubmitFailed,
		onSubmitSucceeded: handleSubmitSucceeded,
		onUpdateValue: setUpdatedValue,
		updatedValue,
		...(fg('one_event_rules_them_all_fg') && { setAnalyticsAttributes }),
	});

	const renderReadView = useCallback(
		() => (
			<ParentReadView
				fragmentRef={data}
				isTruncated={isTruncated}
				emptyText={emptyText}
				isSingleLineRowHeightEnabled={isSingleLineRowHeightEnabled}
				renderHoverPopover={renderHoverPopover}
			/>
		),
		[data, isTruncated, emptyText, isSingleLineRowHeightEnabled, renderHoverPopover],
	);

	const getEditViewProps = useCallback(
		(fieldProps: ValidationFieldProps): ParentEditViewProps => ({
			...fieldProps,
			fieldId: uniqueFieldId,
			fieldName,
			autoFocus: true,
			value: updatedValue,
			onChange: handleChangeAndConfirm,
			menuPosition,
			menuPortalTarget,
			menuIsOpen: fg('jsc_inline_editing_field_refactor') ? true : undefined,
			spacing: fg('jsc_inline_editing_field_refactor') ? spacing : undefined,
		}),
		[
			fieldName,
			handleChangeAndConfirm,
			menuPosition,
			menuPortalTarget,
			uniqueFieldId,
			updatedValue,
			spacing,
		],
	);

	return (
		<FieldInlineEditLiteWithEntryPoint
			editViewPopup={editViewPopup}
			editViewPopupAlignBlock={editViewPopupAlignBlock}
			editViewPopupMinWidth="small"
			editViewEntryPoint={ParentEditViewEntryPoint}
			editViewEntryPointParams={{}}
			getEditViewProps={getEditViewProps}
			fieldName={fieldName}
			hasUnsubmittedChanges={hasServerValidationError}
			invalidMessage={invalidMessage}
			isEditing={isEditing}
			isEditable={isFieldEditable}
			onCancel={handleCancel}
			onConfirm={handleConfirm}
			onEdit={handleEdit}
			readViewFitContainerHeight={readViewFitContainerHeight}
			readView={renderReadView}
			hideActionButtons
		/>
	);
};

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components.
 *
 * @param props [ParentInlineEditViewProps](./types.tsx)
 */

export const ParentInlineEditView = ({ fragmentRef, ...props }: ParentInlineEditViewProps) => {
	const data = useFragment<ParentFragment>(
		graphql`
			fragment parent_issueFieldParentInlineEditFull_ParentInlineEditView_fragmentRef on JiraParentIssueField {
				...parent_issueFieldParentInlineEditFull_ParentInlineEditViewIsEditable_fragmentRef
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
	);

	return (
		<ParentInlineEditViewIsEditable
			{...props}
			fragmentRef={data}
			isEditable={data.fieldConfig?.isEditable ?? false}
		/>
	);
};
