import React, { type ReactNode } from 'react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import CheckIcon from '@atlaskit/icon/core/migration/check-mark--check';
import { token } from '@atlaskit/tokens';

import Tooltip from '@atlaskit/tooltip';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { ResolutionValue } from '../../common/types.tsx';
import messages from './messages.tsx';

type Props = {
	isLabelClickEnabled: boolean;
	value: ResolutionValue;
	hideIcon?: boolean;
};

export const ResolutionReadView = ({ value, isLabelClickEnabled, hideIcon = false }: Props) => {
	const { formatMessage } = useIntl();
	const hasResolution = value;
	const resolutionName = value?.name || formatMessage(messages.unresolvedResolution);

	const icon =
		hasResolution && !hideIcon ? (
			<CheckIcon
				LEGACY_size="medium"
				spacing="spacious"
				label={fg('deployment_issue_sideview_icon_a11y') ? '' : resolutionName}
				testId="issue-field-resolution.ui.read.check-icon"
				color={token('color.icon.success')}
				aria-hidden={!!fg('deployment_issue_sideview_icon_a11y')}
			/>
		) : null;

	const renderResolutionLabel = (labelName: string) => {
		const resolutionStatusLabel = (
			<ResolutionStatusLabel
				data-testid="issue-field-resolution.ui.read.resolution-status-label"
				isDisabled={!isLabelClickEnabled}
				isDarkText={isLabelClickEnabled || !!hasResolution}
			>
				{labelName}
			</ResolutionStatusLabel>
		);

		return !isLabelClickEnabled ? (
			<Tooltip content={formatMessage(messages.readViewMessage)} position="top">
				{resolutionStatusLabel}
			</Tooltip>
		) : (
			resolutionStatusLabel
		);
	};

	return (
		<Container addPadding={!isLabelClickEnabled} data-testid="issue-field-resolution.ui.read.label">
			{icon}
			{renderResolutionLabel(resolutionName)}
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{
	children?: ReactNode;
	addPadding: boolean;
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
}>(({ addPadding }) => ({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	padding: addPadding ? `0 ${gridSize * 2}px 0 12px` : 0,
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 4}px`,
}));

const itemTruncateWidth: number = gridSize * 25;

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ResolutionStatusLabel = styled.span<{
	children?: ReactNode;
	isDarkText: boolean;
	isDisabled: boolean;
}>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) => ({
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		color: props.isDarkText
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.text.subtle')
			: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.text.subtlest'),
		display: 'block',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		maxWidth: `${itemTruncateWidth}px`,
	}),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) => (props.isDisabled ? ':hover { cursor: not-allowed; }' : ''),
);
