/**
 * @generated SignedSource<<6b527f1453afbcf1cc2003dfded756cc>>
 * @relayHash 9e0332fe6fd7b277aaf9638cdd8da6f9
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d87c5d3dc5a14f126210d697c9ce9909cfff3a9a6ce0d111bded722d9b2e5fe0

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type ui_dateFieldsMessage_EnableActionMutation$variables = {
  cloudId: string;
  fieldId: string;
  projectId: AGG$Long;
};
export type ui_dateFieldsMessage_EnableActionMutation$data = {
  readonly jira: {
    readonly jwmAssociateField: {
      readonly associatedIssueTypeIds: ReadonlyArray<AGG$Long | null | undefined> | null | undefined;
      readonly errors: ReadonlyArray<{
        readonly extensions: {
          readonly errorType: string | null | undefined;
          readonly statusCode: number | null | undefined;
        } | null | undefined;
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type ui_dateFieldsMessage_EnableActionMutation$rawResponse = {
  readonly jira: {
    readonly jwmAssociateField: {
      readonly associatedIssueTypeIds: ReadonlyArray<AGG$Long | null | undefined> | null | undefined;
      readonly errors: ReadonlyArray<{
        readonly extensions: {
          readonly __typename: "BulkMutationErrorExtension";
          readonly errorType: string | null | undefined;
          readonly id: string;
          readonly statusCode: number | null | undefined;
        } | {
          readonly __typename: string;
          readonly errorType: string | null | undefined;
          readonly statusCode: number | null | undefined;
        } | null | undefined;
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type ui_dateFieldsMessage_EnableActionMutation = {
  rawResponse: ui_dateFieldsMessage_EnableActionMutation$rawResponse;
  response: ui_dateFieldsMessage_EnableActionMutation$data;
  variables: ui_dateFieldsMessage_EnableActionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "fieldId",
        "variableName": "fieldId"
      },
      {
        "kind": "Variable",
        "name": "projectId",
        "variableName": "projectId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v4 = {
  "kind": "ScalarField",
  "name": "success"
},
v5 = {
  "kind": "ScalarField",
  "name": "errorType"
},
v6 = {
  "kind": "ScalarField",
  "name": "statusCode"
},
v7 = {
  "kind": "ScalarField",
  "name": "message"
},
v8 = {
  "kind": "ScalarField",
  "name": "associatedIssueTypeIds"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "ui_dateFieldsMessage_EnableActionMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "concreteType": "JiraWorkManagementAssociateFieldPayload",
            "kind": "LinkedField",
            "name": "jwmAssociateField",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ]
                  },
                  (v7/*: any*/)
                ]
              },
              (v8/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ui_dateFieldsMessage_EnableActionMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "concreteType": "JiraWorkManagementAssociateFieldPayload",
            "kind": "LinkedField",
            "name": "jwmAssociateField",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "__typename"
                      },
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "id"
                          }
                        ],
                        "type": "BulkMutationErrorExtension"
                      }
                    ]
                  },
                  (v7/*: any*/)
                ]
              },
              (v8/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "d87c5d3dc5a14f126210d697c9ce9909cfff3a9a6ce0d111bded722d9b2e5fe0",
    "metadata": {},
    "name": "ui_dateFieldsMessage_EnableActionMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "e8e87e241742f1eaaa33746a8a8dfcce";

export default node;
