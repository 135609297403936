/**
 * @generated SignedSource<<ce0df5f4b972213480c6068e1a3451c4>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_ViewAutomationDiscoveryPanel_features$data = {
  readonly isAutomationDiscoverabilityEnabled: boolean | null | undefined;
  readonly " $fragmentType": "ui_ViewAutomationDiscoveryPanel_features";
};
export type ui_ViewAutomationDiscoveryPanel_features$key = {
  readonly " $data"?: ui_ViewAutomationDiscoveryPanel_features$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_ViewAutomationDiscoveryPanel_features">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_ViewAutomationDiscoveryPanel_features",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isAutomationDiscoverabilityEnabled"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "94d326cf499746106a3e60c6dc1229c3";

export default node;
