/** @jsx jsx */
import React, { memo, type ComponentType } from 'react';

import { css, styled, jsx } from '@compiled/react';
import type { EntryPointProps } from 'react-relay';
import { SmartCardProvider } from '@atlaskit/link-provider';
import { CardSSR } from '@atlaskit/smart-card/ssr';
import {
	Card as SmartCard,
	TitleBlock,
	ElementName,
	CardAction,
	type CardProps,
} from '@atlaskit/smart-card';
import { token } from '@atlaskit/tokens';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import type { SingleLineSmartLinkProps } from './types.tsx';

type InternalProps<TCard> = {
	Card: ComponentType<TCard>;
	cardProps: TCard;
} & Pick<SingleLineSmartLinkProps, 'anchorTarget' | 'text'>;

const COMMON_CARD_PROPS: CardProps = {
	appearance: 'inline',
	ui: {
		hidePadding: true,
		clickableContainer: true,
	},
	frameStyle: 'hide',
	showHoverPreview: true,
	actionOptions: {
		hide: false,
		exclude: [CardAction.ChangeStatusAction],
	},
	onClick: (event) => event.stopPropagation(),
};

const smartLinkStyles = css({
	paddingTop: token('space.025'),
	paddingRight: token('space.050'),
	paddingBottom: token('space.025'),
	paddingLeft: token('space.050'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors,@atlaskit/ui-styling-standard/no-unsafe-selectors
	'& > div:nth-child(2)': {
		flex: '0 1 auto',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		a: {
			wordBreak: 'break-all',
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors,@atlaskit/ui-styling-standard/no-unsafe-selectors
	'& > div:nth-child(2) ~ div': {
		flex: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors,@atlaskit/ui-styling-standard/no-unsafe-selectors
	'& > div:last-child': {
		minWidth: 0,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& > div': {
		alignSelf: 'center',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors,@atlaskit/ui-styling-standard/no-unsafe-selectors
	'& > span + div:nth-of-type(2) span': {
		textAlign: 'left',
	},
});

const SingleLineSmartLinkInternal = <TCard,>({
	Card,
	cardProps,
	anchorTarget,
	text,
}: InternalProps<TCard>) => {
	return (
		<SmartCardProvider>
			<LinkContainer>
				<Card {...cardProps}>
					<TitleBlock
						hideTitleTooltip
						maxLines={1}
						metadata={[
							{
								name: ElementName.State,
							},
						]}
						css={smartLinkStyles}
						anchorTarget={anchorTarget ?? '_self'}
						text={text}
					/>
				</Card>
			</LinkContainer>
		</SmartCardProvider>
	);
};

export const SingleLineSmartLinkNew = memo(
	({
		props: { fieldType = 'url', url, ...rest },
	}: EntryPointProps<{}, {}, SingleLineSmartLinkProps, {}>) => (
		<SingleLineSmartLinkInternal
			Card={SmartCard}
			cardProps={{
				...COMMON_CARD_PROPS,
				url,
				analyticsContext: {
					attributes: {
						fieldType,
					},
				},
			}}
			{...rest}
		/>
	),
);

export const SingleLineSmartLinkOld = memo(
	({
		props: { url, anchorTarget, text, fieldType = 'url' },
	}: EntryPointProps<{}, {}, SingleLineSmartLinkProps, {}>) => (
		<SmartCardProvider>
			<LinkContainer>
				<SmartCard
					appearance="inline"
					url={url}
					ui={{
						hidePadding: true,
						clickableContainer: true,
					}}
					frameStyle="hide"
					showHoverPreview
					actionOptions={{
						hide: false,
						exclude: [CardAction.ChangeStatusAction],
					}}
					analyticsContext={{
						attributes: {
							fieldType,
						},
					}}
					onClick={(event) => event.stopPropagation()}
				>
					<TitleBlock
						hideTitleTooltip
						maxLines={1}
						metadata={[
							{
								name: ElementName.State,
							},
						]}
						css={smartLinkStyles}
						anchorTarget={anchorTarget ?? '_self'}
						text={text}
					/>
				</SmartCard>
			</LinkContainer>
		</SmartCardProvider>
	),
);

export const SingleLineSmartLink = componentWithFG(
	'jsc_inline_editing_field_refactor',
	SingleLineSmartLinkNew,
	SingleLineSmartLinkOld,
);

export const SingleLineSmartLinkSSR = memo(({ url, ...rest }: SingleLineSmartLinkProps) => (
	<SingleLineSmartLinkInternal Card={CardSSR} cardProps={{ ...COMMON_CARD_PROPS, url }} {...rest} />
));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const LinkContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& div[data-smart-link-container="true"]': {
		// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "8px" and fallback "4px" do not match and can't be replaced automatically.
		borderRadius: token('border.radius.200', '4px'),
		whiteSpace: 'normal',
		margin: 0,
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& div[data-smart-block]': {
		gap: token('space.050'),
	},
});
