import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	copyErrorTitle: {
		id: 'issue-field-copy-paste.controller.copy-error-title',
		defaultMessage: 'Unable to copy',
		description: 'Heading of the error when copying',
	},
	copyValidationError: {
		id: 'issue-field-copy-paste.controller.copy-validation-error',
		defaultMessage: "{name} can't be copied.",
		description:
			'Validation error when copying is not supported for the current field. {name} is the field name',
	},
	cutErrorTitle: {
		id: 'issue-field-cut-paste.controller.cut-error-title',
		defaultMessage: 'Unable to cut',
		description: 'Heading of the error when cutting',
	},
	cutValidationError: {
		id: 'issue-field-cut-paste.controller.cut-validation-error',
		defaultMessage: "{name} can't be cut.",
		description:
			'Validation error when cutting is not supported for the current field. {name} is the field name',
	},
	cutNonEditableError: {
		id: 'business-list.controllers.clipboard.cut-non-editable-error',
		defaultMessage: '{name} is copied to your clipboard instead.',
		description:
			'Validation error when cutting is not supported for the current field. {name} is the field name',
	},
	pasteErrorTitle: {
		id: 'issue-field-copy-paste.controller.paste-error-title',
		defaultMessage: 'Unable to paste',
		description: 'Heading of the error when pasting',
	},
	pasteUnsupportedError: {
		id: 'issue-field-copy-paste.controller.paste-unsupported-error',
		defaultMessage: "This can't be pasted on {name}.",
		description:
			'Validation error when pasting is not supported for the current field. {name} is the field name',
	},
});
