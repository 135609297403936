import React, {
	type ReactNode,
	useCallback,
	type MouseEvent,
	useRef,
	useEffect,
	useMemo,
	useState,
} from 'react';
import noop from 'lodash/noop';
import { graphql, useFragment } from 'react-relay';
import Button from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import DropdownMenu, {
	DropdownItemGroup,
	type CustomTriggerProps,
	type OnOpenChangeArgs,
} from '@atlaskit/dropdown-menu';
import AddIcon from '@atlaskit/icon/core/migration/add';
import AppIcon from '@atlaskit/icon/core/migration/app--addon';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import { SpotlightPulse } from '@atlaskit/onboarding';
import { Box, Inline, Text, xcss } from '@atlaskit/primitives';
import AkToolTip from '@atlaskit/tooltip';
import TextField from '@atlaskit/textfield';
import SearchIcon from '@atlaskit/icon/core/migration/search';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { EcosystemContentPanel } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/ecosystem.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import { useSelector } from '@atlassian/jira-react-redux/src/index.tsx';
import { FORGE_ENTITY_TYPE } from '@atlassian/jira-issue-view-common-constants/src/ecosystem-constants.tsx';
import { forgeUserCanSaveIssuePanelSelector } from '@atlassian/jira-issue-view-store/src/ecosystem/forge/forge-extensions-selector.tsx';
import type { MainIssueAggQueryRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import type { quickAddItemsCompact$key } from '@atlassian/jira-relay/src/__generated__/quickAddItemsCompact.graphql';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useShowSpotlight } from '../quick-actions-changeboarding/use-show-spotlight/index.tsx';
import { AdControlSection } from '../ad-control-section/view.tsx';
import type { QuickActionComponent } from '../types.tsx';
import {
	setCrossflowPreferences,
	useCrossflowAdControls,
} from '../ad-control-section/use-crossflow-ad-controls/index.tsx';
import { useQuickActionTimingMetrics } from '../timing-metrics/context.tsx';
import { isMatch } from '../quick-add-items/item-list/utils.tsx';
import { messages } from './messages.tsx';
import useFocusTrapSearch from './use-focus-trap-search.tsx';

type Props = {
	actions: ReactNode[];
	apps: ReactNode;
	availableAddons?: EcosystemContentPanel[];
	setMenuOpen: (menuName: string, isOpen: boolean) => void;
	openMenu: string | null;
	canInstallAddons?: boolean;
	crossflow?: {
		actions: QuickActionComponent[];
	};
	onSearch: (query: string) => void;
	searchQuery?: string;
	groupCounts?: number[];
	addButtonRef?: React.RefObject<HTMLButtonElement>;
	rootRelayFragment?: MainIssueAggQueryRelayFragment;
};

type PropsWithData = Props & {
	userHasDismissedAd: boolean;
	dismissAd: () => void;
};

export const QuickAddItemsCompact = (props: PropsWithData) => {
	const {
		actions,
		apps,
		crossflow,
		availableAddons,
		setMenuOpen,
		openMenu,
		canInstallAddons,
		onSearch,
		searchQuery,
		groupCounts,
		userHasDismissedAd,
		dismissAd,
	} = props;
	const { formatMessage } = useIntl();
	const add = formatMessage(messages.add);
	const appsMessage = formatMessage(messages.apps);
	const spotlightRef = useRef<HTMLElement>(null);
	const searchRef = useRef<HTMLInputElement | null>(null);
	const actionsMenuRef = useRef<HTMLDivElement>(null);
	const { setQuickMenuClickedTimestamp } = useQuickActionTimingMetrics();

	const { shouldShowSpotlight, updateShouldShowSpotlight } = fg('quick_actions_menu_ga')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useShowSpotlight()
		: { shouldShowSpotlight: false, updateShouldShowSpotlight: noop };

	const canSaveForgeIssuePanel = fg('issue-view-quick-add-button-without-permission')
		? useSelector(forgeUserCanSaveIssuePanelSelector) // eslint-disable-line react-hooks/rules-of-hooks
		: true;

	const filteredAddons =
		availableAddons &&
		availableAddons.filter((addon) => {
			if (fg('issue-view-quick-add-button-without-permission')) {
				return (
					!(
						addon.moduleKey.includes('linked-goals-content-panel') &&
						expVal('native_goals_on_issue_view', 'isGoalsFieldEnabled', false)
					) && (addon.type === FORGE_ENTITY_TYPE ? canSaveForgeIssuePanel : true)
				);
			}

			return !(
				addon.moduleKey.includes('linked-goals-content-panel') &&
				expVal('native_goals_on_issue_view', 'isGoalsFieldEnabled', false)
			);
		});

	const availableApps = filteredAddons && filteredAddons.length >= 1;

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClickWithAnalytics = useCallback(
		(originalOnClick?: (event: MouseEvent<HTMLElement>) => void, buttonState?: string) =>
			(event: MouseEvent<HTMLElement>) => {
				if (originalOnClick) {
					originalOnClick(event);
				}

				if (buttonState) {
					fireUIAnalytics(
						createAnalyticsEvent({}),
						'button clicked',
						`quickActionButtonRevamp${buttonState}`,
					);
				}
			},
		[createAnalyticsEvent],
	);

	const crossFlowActions = useMemo(() => {
		if (!searchQuery) return crossflow?.actions.map(({ component }) => component);

		return crossflow?.actions
			?.filter((action) => isMatch(searchQuery, action?.label))
			.map(({ component }) => component);
	}, [crossflow?.actions, searchQuery]);

	const showCrossFlow = useMemo(() => {
		return !!crossFlowActions?.length && !userHasDismissedAd;
	}, [crossFlowActions, userHasDismissedAd]);

	const toggleMenuOpen: (menuName: string) => (params: OnOpenChangeArgs) => void = useCallback(
		(menuName: string) =>
			({ isOpen, event }: OnOpenChangeArgs) => {
				// eslint-disable-next-line jira/ff/no-preconditioning
				if (fg('quick_actions_menu_ga') && menuName === 'addButton' && shouldShowSpotlight) {
					if (
						spotlightRef.current &&
						event?.target &&
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						spotlightRef.current.contains(event.target as Node)
					) {
						return;
					}

					if (!isOpen) {
						updateShouldShowSpotlight();
					}
				}

				if (isOpen && fg('quick_actions_click_timing_metrics')) {
					setQuickMenuClickedTimestamp(Date.now());
				}

				setMenuOpen(menuName, isOpen);
			},
		[shouldShowSpotlight, setMenuOpen, updateShouldShowSpotlight, setQuickMenuClickedTimestamp],
	);

	const renderDropdownTrigger = ({ triggerRef, onClick, ...rest }: CustomTriggerProps) => {
		const triggerButton = expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false) ? (
			<IconButton
				{...rest}
				icon={() => <AddIcon color="currentColor" spacing="none" label="" LEGACY_size="small" />}
				ref={props.addButtonRef ? mergeRefs(triggerRef, props.addButtonRef) : triggerRef}
				label={formatMessage(
					fg('jira-issue-terminology-refresh-m3')
						? messages.buttonAddCreateWorkIssueTermRefresh
						: messages.buttonAddCreateWork,
				)}
				onClick={onClickWithAnalytics(onClick, '+')}
			/>
		) : (
			<Button
				{...rest}
				label={formatMessage(messages.buttonAddContent)}
				ref={props.addButtonRef ? mergeRefs(triggerRef, props.addButtonRef) : triggerRef}
				onClick={onClickWithAnalytics(onClick, 'Add')}
				iconBefore={<AddIcon color="currentColor" spacing="none" label="" LEGACY_size="small" />}
			>
				{add}
			</Button>
		);

		if (fg('quick_actions_menu_ga')) {
			return (
				<AkToolTip
					content={
						expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false)
							? formatMessage(messages.buttonAddCreateWorkTooltipMessage)
							: formatMessage(messages.buttonAddContent)
					}
				>
					<SpotlightPulse radius={3} pulse={shouldShowSpotlight}>
						{triggerButton}
					</SpotlightPulse>
				</AkToolTip>
			);
		}

		return triggerButton;
	};

	useEffect(() => {
		if (searchRef.current) {
			searchRef.current.style.padding = '4px 4px 4px 8px';
			if (openMenu === 'addButton') {
				searchRef.current.focus();
			}
		}
	}, [searchQuery, openMenu]);

	useFocusTrapSearch({
		actions,
		crossflow,
		actionsMenuRef,
		searchRef,
		searchQuery,
	});

	return (
		<>
			<Inline space="space.100">
				<UFOSegment name="issue-quick-add-button">
					<Inline ref={actionsMenuRef}>
						<DropdownMenu
							trigger={renderDropdownTrigger}
							testId="issue-view-foundation.quick-add.quick-add-items-compact.add-button-dropdown"
							shouldRenderToParent
							onOpenChange={toggleMenuOpen('addButton')}
							isOpen={openMenu === 'addButton'}
							strategy="absolute"
						>
							{expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false) && (
								<Box xcss={searchBoxStyles}>
									<TextField
										testId="issue-view-foundation.quick-add.quick-add-items-compact.search-input"
										aria-label={formatMessage(messages.findMenuItem)}
										aria-autocomplete="list"
										elemBeforeInput={
											<Inline xcss={searchIconStyles}>
												<SearchIcon
													label={formatMessage(messages.findMenuItem)}
													LEGACY_size="small"
												/>
											</Inline>
										}
										onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
											onSearch(event.target.value)
										}
										onFocus={() => searchRef.current?.select()}
										placeholder={formatMessage(messages.findMenuItem)}
										value={searchQuery}
										ref={searchRef}
									/>
								</Box>
							)}

							{expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false) ? (
								<>
									{(Array.isArray(actions) &&
										actions.some(
											(actionGroup) =>
												Array.isArray(actionGroup) &&
												groupCounts?.reduce((acc, val) => acc + val, 0) !== 0,
										)) ||
									showCrossFlow ? (
										actions.map((actionGroup, index) => {
											return (
												Array.isArray(actionGroup) &&
												actionGroup.length > 0 && (
													<Box
														key={`group${index}`}
														xcss={groupCounts?.[index] ? null : noItemGroupStyles}
													>
														<DropdownItemGroup
															hasSeparator={
																index !== 0 &&
																groupCounts?.[index] !== 0 &&
																groupCounts?.slice(0, index).reduce((acc, val) => acc + val, 0) !==
																	0
															}
														>
															{actionGroup}
														</DropdownItemGroup>
													</Box>
												)
											);
										})
									) : (
										<DropdownItemGroup>
											<Box xcss={noResultsContainerStyles}>
												<Text as="strong" weight="bold">
													{formatMessage(messages.searchResultsEmptyMessageHeading)}
												</Text>
												<div>{formatMessage(messages.searchResultsEmptyMessageDescription)}</div>
											</Box>
										</DropdownItemGroup>
									)}
									{showCrossFlow ? (
										<AdControlSection
											key="ad-control-section"
											actions={crossFlowActions}
											onDismiss={dismissAd}
											searchQuery={searchQuery}
										/>
									) : null}
								</>
							) : (
								<DropdownItemGroup>{actions}</DropdownItemGroup>
							)}
						</DropdownMenu>
					</Inline>
				</UFOSegment>
				{(availableApps || canInstallAddons) && (
					<UFOSegment name="issue-quick-apps-button">
						<DropdownMenu
							trigger={({ triggerRef, onClick, ...rest }) => {
								const appsButton = expVal(
									'quick_actions_m2_experiment',
									'isM2ExperimentEnabled',
									false,
								) ? (
									<IconButton
										{...rest}
										ref={triggerRef}
										label={formatMessage(messages.buttonViewAppActions)}
										onClick={onClickWithAnalytics(onClick, '...')}
										icon={() => (
											<AppIcon color="currentColor" label="" spacing="none" LEGACY_size="small" />
										)}
									/>
								) : (
									<Button
										{...rest}
										label={formatMessage(messages.buttonAddApps)}
										ref={triggerRef}
										onClick={onClickWithAnalytics(onClick, 'Apps')}
										iconBefore={
											<AppIcon color="currentColor" label="" spacing="none" LEGACY_size="small" />
										}
									>
										{appsMessage}
									</Button>
								);

								const appsTooltipMessage = expVal(
									'quick_actions_m2_experiment',
									'isM2ExperimentEnabled',
									false,
								)
									? formatMessage(messages.buttonViewAppActions)
									: formatMessage(messages.buttonAddApps);

								return (
									<AkToolTip
										content={
											availableApps
												? appsTooltipMessage
												: formatMessage(messages.buttonAddAppsNoApps)
										}
									>
										{availableApps || fg('quick_actions_menu_ga') ? (
											appsButton
										) : (
											<IconButton
												{...rest}
												ref={triggerRef}
												label={formatMessage(messages.buttonAddAppsNoApps)}
												onClick={onClickWithAnalytics(onClick, '...')}
												icon={() => (
													<ShowMoreHorizontalIcon
														color="currentColor"
														spacing="spacious"
														label=""
														LEGACY_size="small"
													/>
												)}
											/>
										)}
									</AkToolTip>
								);
							}}
							testId="issue-view-foundation.quick-add.quick-add-items-compact.apps-button-dropdown"
							onOpenChange={toggleMenuOpen('appsButton')}
							isOpen={openMenu === 'appsButton'}
							shouldRenderToParent
						>
							{apps}
						</DropdownMenu>
					</UFOSegment>
				)}
			</Inline>
		</>
	);
};

const TemporaryDataWrapperRest = (props: Props) => {
	const { userHasDismissedAd, dismissAd } = useCrossflowAdControls();
	return (
		<QuickAddItemsCompact
			{...props}
			userHasDismissedAd={userHasDismissedAd}
			dismissAd={dismissAd}
		/>
	);
};

const TemporaryDataWrapperRelay = (props: Props) => {
	const isIssueViewCrossFlowBannerDismissed =
		// eslint-disable-next-line @atlassian/relay/query-restriction
		useFragment<quickAddItemsCompact$key>(
			graphql`
				fragment quickAddItemsCompact on JiraQuery {
					userPreferences(cloudId: $cloudId) {
						isIssueViewCrossFlowBannerDismissed
					}
				}
			`,
			props.rootRelayFragment?.jira || null,
		)?.userPreferences?.isIssueViewCrossFlowBannerDismissed || false;

	const [userHasDismissedAd, setUserHasDismissedAd] = useState(isIssueViewCrossFlowBannerDismissed);

	const dismissAd = useCallback(() => {
		setUserHasDismissedAd(true);
		setCrossflowPreferences(true);
	}, []);

	return (
		<QuickAddItemsCompact
			{...props}
			userHasDismissedAd={userHasDismissedAd}
			dismissAd={dismissAd}
		/>
	);
};

export default componentWithFG(
	'jiv-19897-move-user-prefs-to-mainissueagg',
	TemporaryDataWrapperRelay,
	TemporaryDataWrapperRest,
);

const searchIconStyles = xcss({
	marginInlineStart: 'space.100',
});

const searchBoxStyles = xcss({
	marginBlockStart: 'space.100',
	marginInlineStart: 'space.100',
	marginInlineEnd: 'space.100',
	width: '250px',
});

const noResultsContainerStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	textAlign: 'center',
	minHeight: '50px',
	width: '100%',
	boxSizing: 'border-box',
});

const noItemGroupStyles = xcss({
	height: '0',
});
