import React from 'react';

import Icon from '@atlaskit/icon';
import type { CustomGlyphProps, IconProps } from '@atlaskit/icon/types';

const MsTeamsIconGlyph = (props: CustomGlyphProps) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		data-testid={props['data-testid']}
		aria-label={props['aria-label']}
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		className={props.className}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17 6C17 5.46957 17.2107 4.96086 17.5858 4.58579C17.9609 4.21071 18.4696 4 19 4C19.5304 4 20.0391 4.21071 20.4142 4.58579C20.7893 4.96086 21 5.46957 21 6C21 6.53043 20.7893 7.03914 20.4142 7.41421C20.0391 7.78929 19.5304 8 19 8C18.4696 8 17.9609 7.78929 17.5858 7.41421C17.2107 7.03914 17 6.53043 17 6ZM19 5C18.7348 5 18.4804 5.10536 18.2929 5.29289C18.1054 5.48043 18 5.73478 18 6C18 6.26522 18.1054 6.51957 18.2929 6.70711C18.4804 6.89464 18.7348 7 19 7C19.2652 7 19.5196 6.89464 19.7071 6.70711C19.8946 6.51957 20 6.26522 20 6C20 5.73478 19.8946 5.48043 19.7071 5.29289C19.5196 5.10536 19.2652 5 19 5Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 7.82843C12.3182 7.94094 12.6561 8 13 8C13.7956 8 14.5587 7.68393 15.1213 7.12132C15.6839 6.55871 16 5.79565 16 5C16 4.20435 15.6839 3.44129 15.1213 2.87868C14.5587 2.31607 13.7956 2 13 2C12.2044 2 11.4413 2.31607 10.8787 2.87868C10.3161 3.44129 10 4.20435 10 5C10 5.34394 10.0591 5.6818 10.1716 6H2.833C2.373 6 2 6.373 2 6.833V15.167C2 15.3879 2.08776 15.5998 2.24398 15.756C2.4002 15.9122 2.61207 16 2.833 16H8C8 17.3261 8.52678 18.5979 9.46447 19.5355C10.4021 20.4732 11.6739 21 13 21C14.3261 21 15.5979 20.4732 16.5355 19.5355C17.0103 19.0608 17.3797 18.5003 17.6288 17.8906C17.9809 17.981 18.3463 18.0165 18.7119 17.9943C19.3013 17.9586 19.8721 17.7743 20.3711 17.4586C20.8702 17.1429 21.2812 16.706 21.566 16.1888C21.8508 15.6715 22.0001 15.0905 22 14.5V10C22 9.73478 21.8946 9.48043 21.7071 9.29289C21.5196 9.10536 21.2652 9 21 9H12V7.82843ZM13 3C12.4696 3 11.9609 3.21071 11.5858 3.58579C11.2107 3.96086 11 4.46957 11 5C11 5.35625 11.095 5.70269 11.2711 6.00543C11.3011 6.0092 11.3307 6.01489 11.3596 6.0224C11.6937 6.10151 11.95 6.38183 11.9935 6.72827C12.2965 6.90473 12.6433 7 13 7C13.5304 7 14.0391 6.78929 14.4142 6.41421C14.7893 6.03914 15 5.53043 15 5C15 4.46957 14.7893 3.96086 14.4142 3.58579C14.0391 3.21071 13.5304 3 13 3ZM18 10V16C18 16.3144 17.9704 16.6257 17.9127 16.9302C18.0184 16.9558 18.1259 16.9745 18.2347 16.9861C18.6826 17.034 19.1351 16.96 19.5443 16.7719C19.9536 16.5838 20.3044 16.2886 20.5598 15.9176C20.8151 15.5466 20.9655 15.1134 20.995 14.664L21 14.5V10H18ZM17 10H12V15.167C12 15.627 11.627 16 11.167 16H9C9 17.0609 9.42143 18.0783 10.1716 18.8284C10.9217 19.5786 11.9391 20 13 20C14.0609 20 15.0783 19.5786 15.8284 18.8284C16.5786 18.0783 17 17.0609 17 16V10ZM5 8H9V8.975H7.481V14H6.512V8.975H5V8Z"
			fill="currentColor"
		/>
	</svg>
);

/**
 * __MsTeamsIcon__
 */
const MsTeamsIcon = ({ label, primaryColor, secondaryColor, size, testId }: IconProps) => (
	<Icon
		label={label}
		primaryColor={primaryColor}
		secondaryColor={secondaryColor}
		size={size}
		testId={testId}
		glyph={MsTeamsIconGlyph}
	/>
);

export default MsTeamsIcon;
