import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import { fg } from '@atlassian/jira-feature-gating';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import SquareAddIconButton from '@atlassian/jira-issue-view-common-views/src/button/square-add-icon-button.tsx';
import { useAiRelatedIssuesEntryPointActions } from '@atlassian/jira-ai-related-issues/src/controllers/context-provider.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { useRelatedIssuesActions } from '@atlassian/jira-ai-related-issues/src/controllers/context.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import messages from './messages.tsx';

type Props = {
	onClick: () => void;
	intl: IntlShape;
};

const AddIssueLinkButtonWithInvokedFrom = ({ onClick, intl }: Props) => {
	/**
	 * Similar Issues required variables to trigger the entry point in jira/src/packages/issue/issue-view-content-issue-links/src/add/view.tsx
	 */
	const entryPointActions = useAiRelatedIssuesEntryPointActions();
	const relatedIssuesEntrypointRef = useEntryPointButtonTrigger(entryPointActions, true);
	const { setInvokedFrom } = useRelatedIssuesActions();

	const handleClick = useCallback(() => {
		setInvokedFrom('issueLinksAddButton');
		onClick();
	}, [onClick, setInvokedFrom]);

	return (
		<RightSideContainer
			// eslint-disable-next-line jira/integration/test-id-by-folder-structure
			data-testid="issue.views.issue-base.content.issue-links.add.add-button.right-side-container"
		>
			<SquareAddIconButton
				label={intl.formatMessage(
					fg('jira-issue-terminology-refresh-m3') ? messages.labelIssueTermRefresh : messages.label,
				)}
				onClick={handleClick}
				ref={relatedIssuesEntrypointRef}
			/>
		</RightSideContainer>
	);
};

export function AddIssueLinkButtonWithoutRelatedIssuesExperiment(props: Props) {
	const { onClick, intl } = props;

	return (
		<RightSideContainer
			// eslint-disable-next-line jira/integration/test-id-by-folder-structure
			data-testid="issue.views.issue-base.content.issue-links.add.add-button.right-side-container"
		>
			<SquareAddIconButton
				label={intl.formatMessage(
					fg('jira-issue-terminology-refresh-m3') ? messages.labelIssueTermRefresh : messages.label,
				)}
				onClick={onClick}
			/>
		</RightSideContainer>
	);
}

export const AddIssueLinkButton = componentWithCondition(
	() => expValEquals('proactive-jira-ai-similar-issues', 'isEnabled', true),
	AddIssueLinkButtonWithInvokedFrom,
	AddIssueLinkButtonWithoutRelatedIssuesExperiment,
);

AddIssueLinkButton.defaultProps = {
	onClick: noop,
};

export default injectIntl(AddIssueLinkButton);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RightSideContainer = styled.span({
	marginLeft: 'auto',
});
