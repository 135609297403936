import React, { useEffect } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useSearchCustomFieldKeys } from '@atlassian/jira-issue-field-base/src/services/custom-field-key-service/index.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import {
	REPORTER_TYPE,
	ORGANIZATIONS_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import JiraRelayEnvironmentProvider from '@atlassian/jira-relay-environment-provider/src/index.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { useIsAgentOrProjectAdmin } from '../common/utils.tsx';
import { Customer } from './customer/index.tsx';
import { Entitlement } from './entitlement/index.tsx';
import { Organizations } from './organizations/index.tsx';

export const CustomerServiceIssueViewPanel = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const issueKey = useIssueKey();
	const isJiraAdmin = useIsAdmin();
	const isAgentOrProjectAdmin = useIsAgentOrProjectAdmin();

	const [reporterFieldValue] = useFieldValue({
		issueKey,
		fieldKey: REPORTER_TYPE,
	});

	const [organizationsFieldId] = useSearchCustomFieldKeys(issueKey, ORGANIZATIONS_CF_TYPE);
	const [organizationsFieldValue] = useFieldValue({
		issueKey,
		fieldKey: organizationsFieldId,
	});

	const orgCount = organizationsFieldValue ? organizationsFieldValue.length : 0;
	useEffect(() => {
		fireTrackAnalytics(createAnalyticsEvent({}), 'issueViewCustomerContext loaded', {
			hasReporter: !!reporterFieldValue,
			orgCount,
			isAgentOrProjectAdmin,
			isJiraAdmin,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<JiraRelayEnvironmentProvider>
			<Container>
				<Customer fieldValue={reporterFieldValue} orgCount={orgCount} />
				<Organizations fieldValue={organizationsFieldValue} />
				<Entitlement />
			</Container>
		</JiraRelayEnvironmentProvider>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	paddingTop: token('space.025'),
	paddingRight: 0,
	paddingBottom: token('space.050'),
	paddingLeft: 0,
	gap: token('space.025'),
});
