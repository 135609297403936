import React, { useCallback, useEffect, useState } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { CONFLUENCE } from '@atlassian/jira-shared-types/src/application-key.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { UNSAFE_noExposureExp, expValEquals } from '@atlassian/jira-feature-experiments';
import { useInvitees } from '../../services/get-invitees/index.tsx';
import { useInviteCapability } from '../../services/invite-capability/index.tsx';
import { useInviteUsers } from '../../services/invite/index.tsx';
import { useOptOut } from '../../services/opt-out/index.tsx';
import { InviteCapabilities } from '../../services/invite-capability/types.tsx';
import type { Invitees } from '../../common/types.tsx';
import {
	usePersistentInvitesCache,
	PersistentInviteCacheKeys,
} from '../../services/caching/index.tsx';
import { getUsersData } from '../../services/get-invitees/get-users-data/index.tsx';
import { useInviteErrorFlag } from '../invite-error-flag/index.tsx';
import { useInviteSuccessFlag } from '../invite-success-flag/index.tsx';
import type { Pages, PersistentInviteExperiment } from './types.tsx';
import { InviteBanner } from './invite-banner.tsx';
import { getNewLinks, isCurrentSiteLink, getPageHref } from './utils.tsx';

export const usePersistentInviteEligibility = () => {
	const tenantId = useCloudId();
	const getInviteCapability = useInviteCapability();
	const getInvitees = useInvitees();
	const inviteUsers = useInviteUsers();
	const showSuccessFlag = useInviteSuccessFlag();
	const showErrorFlag = useInviteErrorFlag();
	const { getOptOutStatus, setOptOutStatus } = useOptOut(
		'persistent-invite-prompt-on-link-insert-opt-out',
	);
	const { getPersistentInvitesCache, setPersistentInvitesCache, removePersistentInvitesCache } =
		usePersistentInvitesCache();

	return useCallback(async (): Promise<PersistentInviteExperiment> => {
		try {
			let isDismissed = getPersistentInvitesCache(PersistentInviteCacheKeys.DISMISSED);
			if (isDismissed === undefined) {
				isDismissed = await getOptOutStatus();
				setPersistentInvitesCache(PersistentInviteCacheKeys.DISMISSED, isDismissed);
			}
			if (isDismissed) {
				setPersistentInvitesCache(PersistentInviteCacheKeys.ELIGIBILITY, false);
				return { isEligible: false };
			}

			let inviteCapability = getPersistentInvitesCache(PersistentInviteCacheKeys.INVITE_CAPABILITY);
			if (inviteCapability === undefined) {
				inviteCapability = await getInviteCapability();
				setPersistentInvitesCache(PersistentInviteCacheKeys.INVITE_CAPABILITY, inviteCapability);
			}
			if (inviteCapability === InviteCapabilities.NONE) {
				setPersistentInvitesCache(PersistentInviteCacheKeys.ELIGIBILITY, false);
				return { isEligible: false };
			}

			let users: Invitees = [];
			let userIds = getPersistentInvitesCache(PersistentInviteCacheKeys.INVITEES);
			if (userIds === undefined) {
				users = await getInvitees(tenantId, CONFLUENCE, 10);
				userIds = users.map(({ id }) => id);
				setPersistentInvitesCache(PersistentInviteCacheKeys.INVITEES, userIds);
			}

			if (userIds.length === 0) {
				setPersistentInvitesCache(PersistentInviteCacheKeys.ELIGIBILITY, false);
				return { isEligible: false };
			}

			const getUsers = async () => {
				if (users.length > 0) return users;
				return getUsersData(userIds);
			};

			const onSubmit = async (continueUrl: string) => {
				try {
					const response = await inviteUsers({
						continueUrl,
						userIds,
						tenantId,
						product: CONFLUENCE,
						integration: 'persistentInvitePromptOnLinkInsert',
					});

					removePersistentInvitesCache(PersistentInviteCacheKeys.ELIGIBILITY);
					removePersistentInvitesCache(PersistentInviteCacheKeys.INVITEES);

					showSuccessFlag({
						userIds,
						product: CONFLUENCE,
						requireAdminApproval: response.accessRequested.length > 0,
					});
					return true;
				} catch (error) {
					showErrorFlag();
					return false;
				}
			};

			const onDismiss = async () => {
				await setOptOutStatus();
				removePersistentInvitesCache(PersistentInviteCacheKeys.ELIGIBILITY);
				setPersistentInvitesCache(PersistentInviteCacheKeys.DISMISSED, true);
			};

			setPersistentInvitesCache(PersistentInviteCacheKeys.ELIGIBILITY, true);
			return {
				isEligible: true,
				inviteCapability,
				userIds,
				getUsers,
				onSubmit,
				onDismiss,
			};
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireErrorAnalytics({
				meta: {
					id: 'usePersistentInviteExperiment',
					packageName: 'jiraInvitePromptOnLinkInsert',
				},
				error,
			});
			return { isEligible: false };
		}
	}, [
		getPersistentInvitesCache,
		setPersistentInvitesCache,
		setOptOutStatus,
		getOptOutStatus,
		getInviteCapability,
		getInvitees,
		tenantId,
		inviteUsers,
		removePersistentInvitesCache,
		showSuccessFlag,
		showErrorFlag,
	]);
};

export const PersistentInviteBanner = ({ pages }: { pages: Pages }) => {
	const getPersistentInviteEligibility = usePersistentInviteEligibility();
	const [experiment, setExperiment] = useState<PersistentInviteExperiment>();
	const persistentInviteCache = usePersistentInvitesCache();
	const { getPersistentInvitesCache } = persistentInviteCache;
	const [prevPages, setPrevPages] = useState(pages);
	const [continueUrl, setContinueUrl] = useState<string>();

	const defaultContinueUrl = pages?.map(getPageHref).find(isCurrentSiteLink);
	const currentContinueUrl = continueUrl || defaultContinueUrl;

	const fetchPersistentInviteExperiment = useCallback(async () => {
		// eslint-disable-next-line jira/ff/unsafe-no-exposure
		const [config] = UNSAFE_noExposureExp('persistent_invite_on_link_insert');
		if (!config.get('cohort', null)) return;
		const data = await getPersistentInviteEligibility();
		setExperiment(data);
	}, [getPersistentInviteEligibility]);

	useEffect(() => {
		if (pages !== prevPages) {
			setPrevPages(pages);
			if (!pages || pages.length === 0) return;
			const newCurrentSiteLink = getNewLinks(pages, prevPages).find(isCurrentSiteLink);
			if (newCurrentSiteLink) {
				setContinueUrl(newCurrentSiteLink);
				fetchPersistentInviteExperiment();
			}
		}
	}, [pages, prevPages, fetchPersistentInviteExperiment]);

	useEffect(() => {
		if (getPersistentInvitesCache(PersistentInviteCacheKeys.ELIGIBILITY)) {
			fetchPersistentInviteExperiment();
		}
	}, [fetchPersistentInviteExperiment, getPersistentInvitesCache]);

	return experiment?.isEligible &&
		currentContinueUrl &&
		expValEquals('persistent_invite_on_link_insert', 'cohort', 'variation') ? (
		<InviteBanner
			collaboratorIds={experiment.userIds}
			getCollaboratorData={experiment.getUsers}
			inviteCapability={experiment.inviteCapability}
			isFirstImpression={!!continueUrl}
			onSubmit={async () => {
				const success = await experiment.onSubmit(currentContinueUrl);
				if (success) setExperiment(undefined);
			}}
			onDismiss={() => {
				experiment.onDismiss();
				setExperiment(undefined);
			}}
			persistentInviteCache={persistentInviteCache}
		/>
	) : null;
};
