import React, { useCallback, useEffect, useMemo, useState, type ReactNode } from 'react';
import { Targets, Journeys, useCrossFlow } from '@atlassiansox/cross-flow-support';
import DropdownMenu, { DropdownItem } from '@atlaskit/dropdown-menu';
import Lozenge from '@atlaskit/lozenge';
import ChevronRightIcon from '@atlaskit/icon/utility/migration/chevron-right';
import { isFedRamp } from '@atlaskit/atlassian-context';
import { token } from '@atlaskit/tokens';
import VideoIcon from '@atlaskit/icon/core/video';
import Spinner from '@atlaskit/spinner';
import { AsyncSuggestResourcesQuickAction } from '@atlassian/jira-aiops-suggested-resources/src/ui/quick-action/async.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	usePracticesFieldValue,
	useIsIssueOfIncidentsPractice,
} from '@atlassian/jira-issue-field-servicedesk-practices/src/services/use-practices-field-value/index.tsx';
import { SCHEDULE_CHANGE_ID } from '@atlassian/jira-issue-view-common-constants/src/quick-add-constants.tsx';
import { LOOM_QUICK_ADD_AD_CONTROL_DISMISS_KEY } from '@atlassian/jira-issue-view-common/src/component/loom-videos/constants.tsx';
import { useLoomTouchpointVariantWithAdControls } from '@atlassian/jira-issue-view-common/src/component/loom-videos/use-loom-touchpoint-variant-with-ad-controls/index.tsx';
import {
	LoomTouchpointVariant,
	type LoomTouchpointVariantType,
} from '@atlassian/jira-loom-utils/src/controllers/use-loom-touchpoint-variant/types.tsx';
import { useForms } from '@atlassian/jira-proforma-panel-in-issue-view-services/src/services/forms-status-service/index.tsx';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { AsyncDeveloperEscalationsButton } from '@atlassian/jira-servicedesk-customer-service-escalations-issue-view-button/src/async.tsx';
import { useIsDeveloperEscalationsEnabled } from '@atlassian/jira-servicedesk-customer-service-escalations-issue-view-button/src/controllers/use-is-developer-escalations-enabled/index.tsx';
import { AsyncReviewButton } from '@atlassian/jira-servicedesk-post-incident-review/src/ui/review-button/async.tsx';
import { CHANGES } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import type { UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import { useIntl } from '@atlassian/jira-intl';
import { useUserHasConfluenceAccess } from '@atlassian/jira-confluence-integration-controls/src/controllers/use-user-has-confluence-access/index.tsx';
import { JIRA_ISSUE_QUICK_ACTIONS } from '@atlassian/jira-issue-create-confluence-content/src/common/constants/embedded-confluence-source.tsx';
import { expVal, expValEquals } from '@atlassian/jira-feature-experiments';
import { AddDesignItem } from '../../add-design-item/index.tsx';
import { AddFormButton } from '../../add-form-button/add-form-button-view/index.tsx';
import addFormButtonMessages from '../../add-form-button/add-form-button-view/messages.tsx';
import { CreateConfluenceContentMenu } from '../../create-confluence-content-menu/index.tsx';
import { LoomInsertLinkButton } from '../../loom-insert-link-item-button/view.tsx';
import loomInsertLinkButtonMessages from '../../loom-insert-link-item-button/messages.tsx';
import { LoomQuickAddItemView } from '../../loom-record-item-button/index.tsx';
import loomNewVideoMessages from '../../loom-record-item-button/ui/messages.tsx';
import { ScheduleChangeButton } from '../../schedule-change-button/async.tsx';
import type { QuickActionComponent, QuickAddItemToRender } from '../../types.tsx';
import {
	useDynamicQuickActionListItems,
	type QuickActionDropdownItemProps,
} from '../use-dynamic-quick-action-list-items/index.tsx';
import quickAddItemsMessages from '../messages.tsx';
import { isMatch } from '../item-list/utils.tsx';
import { useQuickActionTimingMetrics } from '../../timing-metrics/context.tsx';

type ExternalQuickActionsPropTypes = {
	isAnonymousUser: boolean;
	onInsertLoomLinkClick: () => void;
	shouldBeCompact: boolean;
	forceUpdate: () => void;
	projectKey: string;
	issueKey: string;
	isQuickActionsListView: boolean;
	canCreateIssueLink: boolean;
	onAddIssueLinkClick: () => void;
	createItemView: (itemToRender: QuickAddItemToRender) => React.JSX.Element;
	staticComponents?: { [key: string]: ReactNode };
	onLinkPage: ((link: string, analyticsEvent: UIAnalyticsEvent) => void) | undefined;
	searchQuery: string;
	closeMenu?: () => void;
};

type ExternalQuickActionsReturnTypes = {
	components: QuickActionComponent[][];
	actions: {
		setQuickActionListItem: (
			category: string,
			name: string,
			newValues: QuickActionDropdownItemProps | null,
		) => void;
	};
	crossflow: {
		actions: QuickActionComponent[];
	};
};

const useExternalQuickActionComponents = ({
	isAnonymousUser,
	onInsertLoomLinkClick,
	shouldBeCompact,
	forceUpdate,
	projectKey,
	issueKey,
	isQuickActionsListView,
	canCreateIssueLink,
	onAddIssueLinkClick,
	createItemView,
	onLinkPage,
	searchQuery,
	staticComponents,
	closeMenu,
}: ExternalQuickActionsPropTypes): ExternalQuickActionsReturnTypes => {
	const { formatMessage } = useIntl();
	const hasConfluenceAccess = useUserHasConfluenceAccess(JIRA_ISSUE_QUICK_ACTIONS).hasAccess;
	const { measureTimeOnClick } = useQuickActionTimingMetrics();
	const [{ canEditIssues }] = useProjectPermissions(projectKey);
	const [practices] = usePracticesFieldValue({ issueKey });
	const shouldShowScheduleChangeButton: boolean =
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		canEditIssues && (practices?.some((practice: any) => practice === CHANGES) ?? false);
	const isIssueOfIncidentsPractice = useIsIssueOfIncidentsPractice();
	const isDeveloperEscalationsEnabled = useIsDeveloperEscalationsEnabled();
	const [isLoomInitialising, setIsLoomInitialising] = useState<boolean>(false);
	const crossFlow = useCrossFlow();
	const isFedRampInstance = isFedRamp();

	const { dynamicQuickActionListItems, setQuickActionListItem } = useDynamicQuickActionListItems(
		fg('quick_actions_menu_ga')
			? [
					{
						category: 'confluence',
						name: 'new-live-doc',
						linkComponent: 'confluenceLiveDoc',
						parentLabelMessage: formatMessage(
							!hasConfluenceAccess
								? quickAddItemsMessages.confluenceLiveDocTryCrossFlowConfluence
								: quickAddItemsMessages.confluenceLiveDocNestedParentSearchLabel,
						),
						isDisabled: true,
						itemKey: 'confluence-quick-actions-new-live-doc',
					},
					{
						category: 'confluence',
						name: 'new-page',
						linkComponent: 'confluencePage',
						parentLabelMessage: formatMessage(
							!hasConfluenceAccess
								? quickAddItemsMessages.confluencePageTryCrossFlowConfluence
								: quickAddItemsMessages.confluencePageNestedParentSearchLabel,
						),
						isDisabled: true,
						itemKey: 'confluence-quick-actions-new-page',
					},
					{
						category: 'confluence',
						name: 'new-whiteboard',
						linkComponent: 'confluenceWhiteboard',
						parentLabelMessage: formatMessage(
							!hasConfluenceAccess
								? quickAddItemsMessages.confluenceWhiteboardTryCrossFlowConfluence
								: quickAddItemsMessages.confluenceWhiteboardNestedParentSearchLabel,
						),
						isDisabled: true,
						itemKey: 'confluence-quick-actions-new-whiteboard',
					},
				]
			: [],
	);

	useEffect(() => {
		if (
			isQuickActionsListView &&
			expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false)
		) {
			setQuickActionListItem('confluence', 'new-live-doc', {
				parentLabelMessage: formatMessage(
					!hasConfluenceAccess
						? quickAddItemsMessages.confluenceLiveDocTryCrossFlowConfluence
						: quickAddItemsMessages.confluenceLiveDocNestedParentSearchLabel,
				),
			});
			setQuickActionListItem('confluence', 'new-page', {
				parentLabelMessage: formatMessage(
					!hasConfluenceAccess
						? quickAddItemsMessages.confluencePageTryCrossFlowConfluence
						: quickAddItemsMessages.confluencePageNestedParentSearchLabel,
				),
			});
			setQuickActionListItem('confluence', 'new-whiteboard', {
				parentLabelMessage: formatMessage(
					!hasConfluenceAccess
						? quickAddItemsMessages.confluenceWhiteboardTryCrossFlowConfluence
						: quickAddItemsMessages.confluenceWhiteboardNestedParentSearchLabel,
				),
			});
		}
	}, [formatMessage, hasConfluenceAccess, setQuickActionListItem, isQuickActionsListView]);

	const getQuickActionListComponentsByCategory = useCallback(
		(category: string) =>
			dynamicQuickActionListItems
				.filter((quickActionListItem) => quickActionListItem.category === category)
				.map((quickActionListItem) => {
					let dropdownItem: ReactNode = null;
					const confluenceSearchLabel = expVal(
						'quick_actions_m2_experiment',
						'isM2ExperimentEnabled',
						false,
					)
						? quickActionListItem.parentLabelMessage
						: quickActionListItem.label;
					const isListItemVisible = isMatch(searchQuery, confluenceSearchLabel);

					if (
						!hasConfluenceAccess &&
						expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false)
					) {
						dropdownItem = crossFlow.isEnabled ? (
							<DropdownItem
								elemBefore={
									expVal('quick_actions_m2_experiment', 'variant_2_no_icon', false)
										? null
										: quickActionListItem.iconComponent
								}
								isDisabled={!isListItemVisible}
								elemAfter={
									<Lozenge appearance="new">
										{expValEquals('jira_issue_view_loom_add_lozenge', 'cohort', 'variant')
											? formatMessage(quickAddItemsMessages.addLozenge)
											: formatMessage(quickAddItemsMessages.tryLozenge)}
									</Lozenge>
								}
								onClick={() => {
									measureTimeOnClick(String(quickActionListItem.itemKey));
									crossFlow.api.open({
										journey: Journeys.DECIDE,
										targetProduct: Targets.CONFLUENCE,
										sourceComponent: 'confluencePlaceholderTemplateScreen',
										sourceContext: 'confluencePlaceholderTemplateLink',
									});
								}}
							>
								{isListItemVisible ? quickActionListItem.parentLabel : null}
							</DropdownItem>
						) : null;
					} else {
						dropdownItem = expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false) ? (
							<DropdownMenu
								key={`nested-dropdown-${category}`}
								placement="right-start"
								shouldRenderToParent
								trigger={({ triggerRef, ...triggerProps }) => (
									<DropdownItem
										{...triggerProps}
										ref={triggerRef}
										isDisabled={!isListItemVisible}
										elemBefore={
											expVal('quick_actions_m2_experiment', 'variant_2_no_icon', false)
												? null
												: quickActionListItem.iconComponent
										}
										elemAfter={
											<ChevronRightIcon
												spacing="spacious"
												color={token('color.icon.subtle')}
												label=""
											/>
										}
									>
										{isListItemVisible ? quickActionListItem.parentLabel : null}
									</DropdownItem>
								)}
							>
								{quickActionListItem.linkComponent
									? staticComponents?.[quickActionListItem.linkComponent]
									: null}
								{isMatch(searchQuery, quickActionListItem.searchLabel) ? (
									<DropdownItem
										key={quickActionListItem.itemKey}
										onClick={(...params) => {
											if (fg('quick_actions_click_timing_metrics')) {
												measureTimeOnClick(String(quickActionListItem.itemKey));
											}
											quickActionListItem.onClick?.(...params);
											closeMenu?.();
										}}
										elemAfter={quickActionListItem.itemBadgeComponent}
										{...quickActionListItem.additionalDropdownProps}
									>
										{quickActionListItem.labelComponent}
									</DropdownItem>
								) : null}
							</DropdownMenu>
						) : (
							<DropdownItem
								key={quickActionListItem.itemKey}
								onClick={(...params) => {
									if (fg('quick_actions_click_timing_metrics')) {
										measureTimeOnClick(String(quickActionListItem.itemKey));
									}
									quickActionListItem.onClick?.(...params);
								}}
								elemBefore={quickActionListItem.iconComponent}
								elemAfter={quickActionListItem.itemBadgeComponent}
								{...quickActionListItem.additionalDropdownProps}
							>
								{quickActionListItem.labelComponent}
							</DropdownItem>
						);
					}

					const component = fg('add_error_boundary_for_quick_actions_add_button') ? (
						<UFOSegment key={quickActionListItem.itemKey} name="quick-actions-confluence-buttons">
							<JSErrorBoundary
								id={quickActionListItem.name || 'quick-actions-confluence-buttons'}
								packageName="jiraQuickActions"
								teamName="bento"
								fallback="unmount"
							>
								{dropdownItem}
							</JSErrorBoundary>
						</UFOSegment>
					) : (
						dropdownItem
					);

					return {
						component,
						label: confluenceSearchLabel,
					};
				}),
		[
			closeMenu,
			crossFlow,
			dynamicQuickActionListItems,
			hasConfluenceAccess,
			measureTimeOnClick,
			searchQuery,
			staticComponents,
			formatMessage,
		],
	);

	const [
		{
			value: { hasIssueForms, hasProjectForms },
		},
		{ onClickAddFormButton },
	] = useForms({
		issueKey: toIssueKey(issueKey),
	});

	const suggestResourcesAction = useMemo(
		() =>
			isIssueOfIncidentsPractice && fg('incident_suggested_resources')
				? [
						{
							component: (
								<AsyncSuggestResourcesQuickAction
									isCompactMode={shouldBeCompact}
									isQuickActionsListView={isQuickActionsListView}
									key="suggest-resources-action"
								/>
							),
						},
					]
				: [],
		[isIssueOfIncidentsPractice, isQuickActionsListView, shouldBeCompact],
	);

	const reviewButton = useMemo(
		() =>
			isIssueOfIncidentsPractice && canCreateIssueLink
				? [
						{
							component: (
								<AsyncReviewButton
									onAddIssueLinkClick={onAddIssueLinkClick}
									compactMode={shouldBeCompact}
									isQuickActionsListView={isQuickActionsListView}
									key="async-review-button"
								/>
							),
						},
					]
				: [],
		[
			canCreateIssueLink,
			isIssueOfIncidentsPractice,
			isQuickActionsListView,
			onAddIssueLinkClick,
			shouldBeCompact,
		],
	);

	const changeManagementActions = useMemo(
		() =>
			[
				shouldShowScheduleChangeButton && {
					component: (
						<ScheduleChangeButton key={SCHEDULE_CHANGE_ID} createItemView={createItemView} />
					),
				},
			].filter(Boolean),
		[createItemView, shouldShowScheduleChangeButton],
	);

	const developerEscalationsAction = useMemo(
		() =>
			isDeveloperEscalationsEnabled
				? [
						{
							component: (
								<AsyncDeveloperEscalationsButton
									key="async-developer-escalations-button"
									createItemView={createItemView}
								/>
							),
						},
					]
				: [],
		[createItemView, isDeveloperEscalationsEnabled],
	);

	const formsActions = useMemo(
		() =>
			hasProjectForms
				? [
						{
							component: (
								<AddFormButton
									key="add-form-button"
									createItemView={createItemView}
									onClick={(...params) => {
										if (fg('quick_actions_click_timing_metrics')) {
											measureTimeOnClick('add-form-button');
										}
										onClickAddFormButton(...params);
									}}
									hasIssueForms={hasIssueForms}
									isQuickActionsListView={isQuickActionsListView}
								/>
							),
							label: formatMessage(addFormButtonMessages.label),
						},
					]
				: [],
		[
			hasProjectForms,
			createItemView,
			hasIssueForms,
			isQuickActionsListView,
			formatMessage,
			onClickAddFormButton,
			measureTimeOnClick,
		],
	);

	const designActions = useMemo(
		() =>
			!isAnonymousUser && canEditIssues
				? [
						{
							component: (
								<AddDesignItem
									key="add-design-button"
									createItemView={createItemView}
									onMount={forceUpdate}
									isQuickActionsListView={isQuickActionsListView}
								/>
							),
							label: formatMessage(quickAddItemsMessages.addDesignSearchLabel),
						},
					]
				: [],
		[
			canEditIssues,
			createItemView,
			forceUpdate,
			isAnonymousUser,
			isQuickActionsListView,
			formatMessage,
		],
	);

	const asyncConfluenceContentMenu = useMemo(
		() => [
			{
				component: (
					<CreateConfluenceContentMenu
						key="create-confluence-content-menu"
						appearance={shouldBeCompact ? 'compact' : ''}
						isQuickActionsListView={isQuickActionsListView}
						onLinkPage={onLinkPage}
					/>
				),
			},
		],
		[isQuickActionsListView, shouldBeCompact, onLinkPage],
	);

	let loomTouchpointVariant: LoomTouchpointVariantType = LoomTouchpointVariant.NONE;
	let dismissAd: () => Promise<void> = useCallback(() => Promise.resolve(), []);

	if (fg('loom_crossflow_enablement_in_jira')) {
		/**
		 * Adding this because of removal of file level eslint disable for
		 * rules of hooks (PIR-21595). To be cleaned up when above FG is removed.
		 */
		/* eslint-disable-next-line react-hooks/rules-of-hooks */
		const touchPointVariants = useLoomTouchpointVariantWithAdControls(
			LOOM_QUICK_ADD_AD_CONTROL_DISMISS_KEY,
		);
		loomTouchpointVariant = touchPointVariants.loomTouchpointVariant;
		dismissAd = touchPointVariants.dismissAd;
	}

	const showLoomInCrossFlow =
		(loomTouchpointVariant === LoomTouchpointVariant.CROSSFLOW ||
			loomTouchpointVariant === LoomTouchpointVariant.CROSSJOIN) &&
		!isFedRampInstance;

	let loomVideoButtons: QuickActionComponent[] = useMemo(() => {
		let loomButtons: QuickActionComponent[] = [];
		if (fg('loom_crossflow_enablement_in_jira')) {
			loomButtons = [
				// record Loom button could have crossflow, crossjoin or co-use touchpoints, so the logic is handled in the LoomQuickAddItemView
				{
					component: (
						<LoomQuickAddItemView
							key="loom-record-video"
							loomTouchpointVariant={loomTouchpointVariant}
							onDismissAd={dismissAd}
							onRecordClick={() => closeMenu?.()}
							setIsLoomInitialising={setIsLoomInitialising}
						/>
					),
					label: showLoomInCrossFlow
						? formatMessage(quickAddItemsMessages.loomTryCrossFlowConfluence)
						: formatMessage(loomNewVideoMessages.recordVideoButtonLabelSearch),
				},
			];

			if (loomTouchpointVariant === LoomTouchpointVariant.COUSE) {
				// insert existing Loom video button should only render when we are in the co-use scenario
				loomButtons = [
					...loomButtons,
					{
						component: (
							<LoomInsertLinkButton
								key="loom-insert-existing-video"
								onInsertLoomLinkClick={() => {
									onInsertLoomLinkClick();
									closeMenu?.();
								}}
							/>
						),
						label: expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false)
							? formatMessage(loomInsertLinkButtonMessages.linkVideoButtonLabelSearch)
							: formatMessage(loomInsertLinkButtonMessages.loomExistingVideoButtonLabel),
					},
				];
			}
		}

		return loomButtons;
	}, [
		loomTouchpointVariant,
		dismissAd,
		showLoomInCrossFlow,
		formatMessage,
		closeMenu,
		onInsertLoomLinkClick,
	]);

	let confluenceButtons: QuickActionComponent[] = useMemo(() => {
		return fg('quick_actions_menu_ga') ? getQuickActionListComponentsByCategory('confluence') : [];
	}, [getQuickActionListComponentsByCategory]);

	const crossflowButtons = [];

	if (expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false)) {
		if (loomTouchpointVariant === LoomTouchpointVariant.COUSE && loomVideoButtons.length) {
			const loomIcon = isLoomInitialising ? (
				<Spinner size="medium" label={formatMessage(quickAddItemsMessages.spinnerLoading)} />
			) : (
				<VideoIcon
					label={formatMessage(loomInsertLinkButtonMessages.videoParentNestedMenuLabel)}
					color="currentColor"
					spacing="spacious"
				/>
			);
			const revLoomVideoButtons = [...loomVideoButtons].reverse();
			const loomSearchLabel = formatMessage(
				loomInsertLinkButtonMessages.videoParentNestedMenuLabelTextForRecordSearch,
			);
			const isLoomItemVisible = isMatch(searchQuery, loomSearchLabel);

			loomVideoButtons = [
				{
					component: (
						<DropdownMenu
							key="nested-dropdown-loom"
							placement="right-start"
							shouldRenderToParent
							trigger={({ triggerRef, ...triggerProps }) => (
								<DropdownItem
									{...triggerProps}
									ref={triggerRef}
									isDisabled={!isLoomItemVisible}
									elemBefore={
										expVal('quick_actions_m2_experiment', 'variant_2_no_icon', false)
											? null
											: loomIcon
									}
									elemAfter={
										<ChevronRightIcon
											spacing="spacious"
											color={token('color.icon.subtle')}
											label=""
										/>
									}
								>
									{isLoomItemVisible
										? formatMessage(loomInsertLinkButtonMessages.videoParentNestedMenuLabel)
										: null}
								</DropdownItem>
							)}
						>
							{revLoomVideoButtons
								.filter((item) => isMatch(searchQuery, item.label))
								.map((item) => item.component)}
						</DropdownMenu>
					),
					label: loomSearchLabel,
				},
			];
		}

		/**
		 * Logic: If the user doesn't have access to loom or confluence, we want to show these buttons in the crossflow section
		 * instead of the regular dropdown.
		 */
		if (!hasConfluenceAccess) {
			crossflowButtons.push(...confluenceButtons);
			confluenceButtons = [];
		}

		if (showLoomInCrossFlow) {
			crossflowButtons.push(...loomVideoButtons);
			loomVideoButtons = [];
		}
	}

	return {
		components: [
			designActions,
			formsActions,
			developerEscalationsAction,
			changeManagementActions,
			reviewButton,
			suggestResourcesAction,
			asyncConfluenceContentMenu,
			loomVideoButtons,
			confluenceButtons,
		],
		actions: {
			setQuickActionListItem,
		},
		crossflow: {
			actions: [...crossflowButtons],
		},
	};
};

export default useExternalQuickActionComponents;
