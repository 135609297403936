import React from 'react';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useProjectId, useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useStableIssueId } from '../../controllers/use-stable-issue-id/index.tsx';
import { AIContextOpsPanelWithPrefetchedResource } from './ai-context-ops-panel-with-prefetched-resource/index.tsx';
import { AIContextOpsPanelWithPrefetchedCommonResource } from './ai-context-ops-panel-with-prefetched-common-resource/index.tsx';

export const AIContextOpsPanelLoader = () => {
	const issueId = useStableIssueId();
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectId = useProjectId(projectKey);

	if (!projectId || !issueId) {
		return null;
	}

	return fg('rrr_for_context_panel_data') ? (
		<AIContextOpsPanelWithPrefetchedCommonResource
			issueId={issueId}
			projectId={projectId}
			issueKey={issueKey}
		/>
	) : (
		<AIContextOpsPanelWithPrefetchedResource issueId={issueId} projectId={projectId} />
	);
};
