/**
 * @generated SignedSource<<6bf8a6a83753a85c8a93cbdb3e0ec6f2>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutOriginalEstimateField_IssueViewOriginalEstimateField$data = {
  readonly __typename: "JiraOriginalTimeEstimateField";
  readonly fieldId: string;
  readonly issue: {
    readonly timeTrackingField: {
      readonly __typename: "JiraTimeTrackingField";
      readonly fieldId: string;
      readonly type: string;
      readonly " $fragmentSpreads": FragmentRefs<"lite_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditView_fragmentRef" | "originalEstimate_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewOld">;
    } | null | undefined;
  } | null | undefined;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutFieldHeading_IssueViewFieldHeading">;
  readonly " $fragmentType": "ui_issueViewLayoutOriginalEstimateField_IssueViewOriginalEstimateField";
};
export type ui_issueViewLayoutOriginalEstimateField_IssueViewOriginalEstimateField$key = {
  readonly " $data"?: ui_issueViewLayoutOriginalEstimateField_IssueViewOriginalEstimateField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutOriginalEstimateField_IssueViewOriginalEstimateField">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v1 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v2 = {
  "kind": "ScalarField",
  "name": "type"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutOriginalEstimateField_IssueViewOriginalEstimateField",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading"
    },
    {
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "issue",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraTimeTrackingField",
          "kind": "LinkedField",
          "name": "timeTrackingField",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "kind": "FragmentSpread",
              "name": "originalEstimate_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewOld"
            },
            {
              "kind": "FragmentSpread",
              "name": "lite_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditView_fragmentRef"
            }
          ]
        }
      ]
    },
    (v1/*: any*/),
    (v2/*: any*/),
    (v0/*: any*/)
  ],
  "type": "JiraOriginalTimeEstimateField"
};
})();

(node as any).hash = "8001c54af61dfe44fd3e0bc99b9fdbc5";

export default node;
