import React, { memo } from 'react';
import Link from '@atlaskit/link';
import { useIntl } from '@atlassian/jira-intl';
import messages from '../messages.tsx';

export const ContactLabel = memo(() => {
	const { formatMessage } = useIntl();

	return (
		// eslint-disable-next-line @atlaskit/design-system/use-primitives-text
		<p>
			{formatMessage(messages.canContactPrompt)}{' '}
			<Link
				href="https://www.atlassian.com/legal/privacy-policy"
				target="_blank"
				rel="noopener noreferrer"
			>
				{formatMessage(messages.canContactPromptLink)}
			</Link>
		</p>
	);
});
