import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	sectionHeading: {
		id: 'issue-design-section.panel.heading.section-heading',
		defaultMessage: 'Designs {count, plural, =0 {} other {(#)}}',
		description:
			'Heading for the designs field displayed on the issue view which displays UI/UX designs that have been linked to an issue',
	},
	sectionHeadingNoCount: {
		id: 'issue-design-section.panel.heading.section-heading-no-count',
		defaultMessage: 'Designs',
		description:
			'Heading for the designs field displayed on the issue view which displays UI/UX designs that have been linked to an issue',
	},
	expandButtonLabel: {
		id: 'issue-design-section.panel.heading.expand-button-label',
		defaultMessage: 'Expand',
		description: 'Button to expand the designs panel',
	},
	expandTooltip: {
		id: 'issue-design-section.panel.heading.expand-tooltip',
		defaultMessage: 'Expand',
		description: 'Tooltip that displays on hover of the expand button',
	},
	collapseTooltip: {
		id: 'issue-design-section.panel.heading.collapse-tooltip',
		defaultMessage: 'Hide',
		description: 'Tooltip that displays on hover of the collapse button',
	},
	noDesignsCantEditTooltip: {
		id: 'issue-design-section.panel.heading.cant-edit-tooltip',
		defaultMessage: 'There are no designs attached to this issue',
		description: 'Tooltip that displays on hover of the disabled expand button',
	},
	noDesignsCantEditTooltipIssueTermRefresh: {
		id: 'issue-design-section.panel.heading.cant-edit-tooltip-issue-term-refresh',
		defaultMessage: 'There are no designs attached to this work item',
		description: 'Tooltip that displays on hover of the disabled expand button',
	},
	collapseButtonLabel: {
		id: 'issue-design-section.panel.heading.collapse-button-label',
		defaultMessage: 'Collapse',
		description: 'Button to collapse the designs panel',
	},
	addDesignPopupButtonLabel: {
		id: 'issue-design-section.panel.heading.add-design-popup-button-label',
		defaultMessage: 'Add a design',
		description:
			'Button to trigger a popup that allows the user to paste another design link to add to the Jira issue',
	},
	getDesignUpdatesButton: {
		id: 'issue-design-section.panel.heading.get-design-updates-button',
		defaultMessage: 'Request design status updates',
		description: 'Button to request admin to enable receiving design updates',
	},
	getDesignUpdatesButtonForAdmin: {
		id: 'issue-design-section.panel.heading.get-design-updates-button-for-admin',
		defaultMessage: 'Get design status updates',
		description: 'Button to enable receiving design updates',
	},
});
