// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	archive: {
		id: 'issue.archive.confirm',
		defaultMessage: 'Archive',
		description: 'Label for the issue actions item to archive the issue',
	},
	delete: {
		id: 'issue.delete.confirm',
		defaultMessage: 'Delete',
		description: 'Label for the issue actions item to delete the issue',
	},
	deleteHeading: {
		id: 'issue.delete-heading',
		defaultMessage: 'Delete {issueKey}?',
		description: 'Heading for the delete modal to delete the issue',
	},
	deleteOrArchiveHeading: {
		id: 'issue.delete-or-archive-heading',
		defaultMessage: 'Delete or archive {issueKey}?',
		description: 'Heading for the new delete modal to delete the issue',
	},
	deleteWithSubtasksHeading: {
		id: 'issue.delete-with-subtasks-heading',
		defaultMessage: 'Delete {issueKey} and its subtasks?',
		description: 'Heading for the delete modal to delete the issue when it has subtasks',
	},
	deleteWithChildrenHeading: {
		id: 'issue.delete-with-children-heading',
		defaultMessage: 'Delete {issueKey} and its child issues?',
		description: 'Heading for the delete modal to delete the issue when it has child issues',
	},
	cancel: {
		id: 'issue.delete.cancel',
		defaultMessage: 'Cancel',
		description: 'Label for the button to cancel deleting the issue',
	},
	deleteWarning: {
		id: 'issue.delete-warning',
		defaultMessage:
			"You're about to permanently delete this issue, its comments and attachments, and all of its data.",
		description: '',
	},
	deleteOrArchiveWarning: {
		id: 'issue.delete-or-archive-warning',
		defaultMessage:
			'You can choose to delete or archive this issue. Deleting is irreversible. It permanently removes the issue, comments and attachments.',
		description: 'Warning message for delete modal',
	},
	deleteOrArchiveWarningIssueTermRefresh: {
		id: 'issue.delete-or-archive-warning-issue-term-refresh',
		defaultMessage:
			'You can choose to delete or archive this work item. Deleting is irreversible. It permanently removes the work item, comments and attachments.',
		description: 'Warning message for delete modal',
	},
	deleteWithSubtasksWarning: {
		id: 'issue.delete-with-subtasks-warning',
		defaultMessage:
			"You're about to permanently delete this issue and all its subtasks, its comments and attachments, and all of its data. You should move any subtasks you don't want to delete to another parent issue.",
		description: '',
	},
	deleteWithChildrenWarning: {
		id: 'issue.delete-with-children-warning',
		defaultMessage:
			"You're about to permanently delete this issue and all its child issues, its comments and attachments, and all of its data. You should move any child issues you don't want to delete to another parent issue.",
		description: '',
	},
	deleteRecommendation: {
		id: 'issue.delete-recommendation',
		defaultMessage: "If you're not sure, you can resolve or close this issue instead.",
		description: '',
	},
	deleteWithChildrenHeadingIssueTermRefresh: {
		id: 'issue.delete-with-children-heading-issue-term-refresh',
		defaultMessage: 'Delete {issueKey} and its child work items?',
		description: 'Heading for the delete modal to delete the issue when it has child issues',
	},
	deleteWarningIssueTermRefresh: {
		id: 'issue.delete-warning-issue-term-refresh',
		defaultMessage:
			"You're about to permanently delete this work item, its comments and attachments, and all of its data.",
		description: '',
	},
	deleteWithSubtasksWarningIssueTermRefresh: {
		id: 'issue.delete-with-subtasks-warning-issue-term-refresh',
		defaultMessage:
			"You're about to permanently delete this work item and all its subtasks, comments and attachments, and data. You should move any subtasks you don't want to delete to another work item.",
		description: '',
	},
	deleteOrArchiveWithSubtasksWarning: {
		id: 'issue.delete-or-archive-with-subtasks-warning',
		defaultMessage:
			'You can choose to delete or archive this issue and all its subtasks. Deleting is irreversible. It permanently removes the issue, subtasks, comments and attachments. To keep subtasks move them to a different parent.',
		description: 'warning for delete issue modal with subtasks',
	},
	deleteOrArchiveWithSubtasksWarningIssueTermRefresh: {
		id: 'issue.delete-or-archive-with-subtasks-warning-issue-term-refresh',
		defaultMessage:
			'You can choose to delete or archive this work item and all its subtasks. Deleting is irreversible. It permanently removes the work item, subtasks, comments and attachments. To keep subtasks move them to a different parent.',
		description: 'warning for delete issue modal with subtasks',
	},
	deleteWithChildrenWarningIssueTermRefresh: {
		id: 'issue.delete-with-children-warning-issue-term-refresh',
		defaultMessage:
			"You're about to permanently delete this work item and all its child work items, comments and attachments, and data. You should move any child work items you don't want to delete to another work items.",
		description: '',
	},
	deleteOrArchiveWithChildrenWarning: {
		id: 'issue.delete-or-archive-with-children-warning',
		defaultMessage:
			'You can choose to delete or archive this issue and all its child issues. Deleting is irreversible. It permanently removes the issue, child issues, comments and attachments. To keep child issues move them to a different parent.',
		description: 'warning for delete issue modal with child issues',
	},
	deleteOrArchiveWithChildrenWarningIssueTermRefresh: {
		id: 'issue.delete-or-archive-with-children-warning-issue-term-refresh',
		defaultMessage:
			'You can choose to delete or archive this work item and all its child work items. Deleting is irreversible. It permanently removes the work item, child work items, comments and attachments. To keep child work items move them to a different parent.',
		description: 'warning for delete issue modal with child issues',
	},
	deleteRecommendationIssueTermRefresh: {
		id: 'issue.delete-recommendation-issue-term-refresh',
		defaultMessage: "If you're not sure, you can resolve or close this work item instead.",
		description: '',
	},
	deleteErrorMessage: {
		id: 'issue.delete-error-message',
		defaultMessage: 'The value entered does not match',
		description:
			'Error message that is displayed when the user enters a different value in textbox',
	},
	deleteTextFieldLabelMessage: {
		id: 'issue.delete-textfield-label-message',
		defaultMessage: 'Type <strong>delete</strong> to continue',
		description: 'Label message that is displayed above the textfield',
	},
	deleteLabelMessage: {
		id: 'issue.delete-label-message',
		defaultMessage: 'Type delete to continue',
		description: 'Label for textfield',
	},
	iconButtonLabel: {
		id: 'issue.icon-button-label',
		defaultMessage: 'Close Modal',
		description: 'Label for icon button',
	},
	issueArchivalSuccessFlagTitle: {
		id: 'issue-archival-modal.issue-archival-success-flag-title',
		defaultMessage: "We've archived the issue {issueKey}.",
		description: 'Header content of the issue archival success flag',
	},
	issueArchivalSuccessFlagTitleIssueTermRefresh: {
		id: 'issue-archival-modal.issue-archival-success-flag-title-issue-term-refresh',
		defaultMessage: "We've archived the work item {issueKey}.",
		description: 'Header content of the issue archival success flag',
	},
	issueArchivalSuccessFlagActionViewIssue: {
		id: 'issue-archival-modal.issue-archival-success-flag-action-view-issue',
		defaultMessage: 'View issue',
		description: 'Text to show the link of the archived issue page',
	},
	issueArchivalSuccessFlagActionViewIssueIssueTermRefresh: {
		id: 'issue-archival-modal.issue-archival-success-flag-action-view-issue-issue-term-refresh',
		defaultMessage: 'View work item',
		description: 'Text to show the link of the archived issue page',
	},
	issueArchivalSuccessFlagActionViewInArchive: {
		id: 'issue-archival-modal.issue-archival-success-flag-action-view-in-archive',
		defaultMessage: 'View all archived issues',
		description: 'Text to show the link of the issue archives page',
	},
	issueArchivalSuccessFlagActionViewInArchiveIssueTermRefresh: {
		id: 'issue-archival-modal.issue-archival-success-flag-action-view-in-archive-issue-term-refresh',
		defaultMessage: 'View all archived work items',
		description: 'Text to show the link of the issue archives page',
	},
	issueArchivalErrorFlagTitle: {
		id: 'issue-archival-modal.issue-archival-error-flag-title',
		defaultMessage: 'We couldn’t archive the issue {issueKey}. Wait a few moments, then try again.',
		description: 'Header content of the issue archival error flag',
	},
	issueArchivalErrorFlagTitleIssueTermRefresh: {
		id: 'issue-archival-modal.issue-archival-error-flag-title-issue-term-refresh',
		defaultMessage:
			'We couldn’t archive the work item {issueKey}. Wait a few moments, then try again.',
		description: 'Header content of the issue archival error flag',
	},
	deleteTextToEquateForTextbox: {
		id: 'issue-delete-modal.issue-delete-text-to-equate-for-textbox',
		defaultMessage: 'delete',
		description: 'Text for textbox',
	},
});
