/** @jsx jsx */
import React, { memo } from 'react';
import { styled, jsx } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import Tooltip from '@atlaskit/tooltip';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { IconContainer, PaddedFlexContainer } from '../../../common/ui/styled.tsx';
import type { Props } from './types.tsx';

export const ReadOnlyAssignee = memo<Props>(
	({
		assigneeUrl,
		assigneeDisplayName,
		hideTooltipOnMouseDown,
		isLinkedIssuesRefreshEnabled = false,
	}: Props) => {
		if (isLinkedIssuesRefreshEnabled) {
			return (
				// We are disabling tooltip in AK Avatar, to use this instance
				// of AKTooltip since it is a stateful component.
				<Tooltip
					content={assigneeDisplayName || ''}
					hideTooltipOnMouseDown={hideTooltipOnMouseDown}
					position="bottom"
				>
					<IconContainer>
						<Avatar
							name={assigneeDisplayName || ''}
							src={assigneeUrl || ''}
							size="small"
							testId="issue-line-card.ui.assignee.read-only-assignee"
						/>
					</IconContainer>
				</Tooltip>
			);
		}

		return (
			<PaddedFlexContainer>
				<AvatarWrapper>
					<Tooltip
						content={assigneeDisplayName || ''}
						hideTooltipOnMouseDown={hideTooltipOnMouseDown}
						position="bottom"
					>
						<Avatar
							name={assigneeDisplayName || ''}
							src={assigneeUrl || ''}
							size="small"
							testId="issue-line-card.ui.assignee.read-only-assignee"
						/>
					</Tooltip>
				</AvatarWrapper>
			</PaddedFlexContainer>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AvatarWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 3.5}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 3.5}px`,
	padding: 0,
	boxSizing: 'border-box',
	cursor: 'default',
});
