import React from 'react';
import { token } from '@atlaskit/tokens';

type Props = {
	isModal: boolean;
};

const Avatar = ({ isModal }: Props) => (
	<svg width="40" height="40" viewBox="0 0 40 40" focusable={false}>
		<mask id="circle-cutout">
			<rect x="0" y="0" width="40" height="40" fill="white" />
			{}
			<circle cx="18" cy="18" r="16" fill="black" />
		</mask>
		<rect
			x="0"
			y="0"
			width="40"
			height="40"
			mask="url(#circle-cutout)"
			fill={isModal ? token('elevation.surface.overlay') : token('elevation.surface')}
		/>
	</svg>
);

export default Avatar;
