import React, { useState, useRef, useEffect, type MouseEvent } from 'react';
import { Box } from '@atlaskit/primitives';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import type { IssueType } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { InlineConfigButtons } from '@atlassian/jira-inline-config-buttons-for-select/src/index.tsx';
import { EditIssueTypeModalEntrypointTrigger } from '@atlassian/jira-edit-issue-type-modal-trigger/src/EditIssueTypeModalEntrypointTrigger.tsx';
import AsyncIcon from '@atlassian/jira-common-components-async-icon/src/view.tsx';
import {
	ISSUE_VIEW_SOURCE,
	EDIT_ISSUE_TYPE_ACTION_SUBJECT_ID,
} from '@atlassian/jira-issue-type-config-common/src/types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { RefObject } from '@atlassian/jira-shared-types/src/general.tsx';
import { scrollIntoView } from '@atlassian/jira-select-with-footer-options/src/utils.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { editIssueTypeExperience } from '../experiences.tsx';
import messages from './messages.tsx';

export const DropdownItemWithInlineConfigButtons = ({
	issueType,
	projectId,
	isIssueTypeConfigEnabled,
	projectKey,
	handleItemClick,
	onEditModalClose,
	onIssueTypeEdited,
	stickyFooterRef,
	onEditButtonClick,
}: {
	issueType: IssueType;
	projectId: string | undefined;
	isIssueTypeConfigEnabled: boolean;
	projectKey: string;
	handleItemClick: (issueType: IssueType) => void;
	onEditModalClose: () => void;
	onIssueTypeEdited: () => void;
	stickyFooterRef?: RefObject<HTMLElement>;
	onEditButtonClick: (ev: MouseEvent<HTMLElement>) => void;
}) => {
	const { formatMessage } = useIntl();
	const [isFocused, setIsFocused] = useState(false);
	const focusedOptionRef = useRef<HTMLElement>(null);

	useEffect(() => {
		if (isFocused) {
			// Only auto-scroll for focus with keyboard, not for focus with mouse hover
			if (
				!focusedOptionRef.current?.parentElement?.parentElement?.parentElement ||
				!stickyFooterRef?.current ||
				focusedOptionRef.current?.matches(':hover')
			) {
				return;
			}
			scrollIntoView(
				focusedOptionRef.current.parentElement.parentElement.parentElement, // DropdownMenu
				focusedOptionRef.current,
				stickyFooterRef.current.clientHeight,
			);
		}
	}, [isFocused, projectId, stickyFooterRef]);

	return (
		<Box
			onMouseEnter={() => setIsFocused(true)}
			onMouseLeave={() => {
				setIsFocused(false);
			}}
			onFocus={() => {
				setIsFocused(true);
			}}
			onBlur={() => {
				setIsFocused(false);
			}}
		>
			<DropdownItem
				testId={`issue.views.issue-base.foundation.change-issue-type.item.${issueType.id}`}
				elemBefore={<AsyncIcon url={issueType.iconUrl} alt="" />}
				onClick={() => handleItemClick(issueType)}
				ref={focusedOptionRef}
			>
				{projectId && isIssueTypeConfigEnabled ? (
					<InlineConfigButtons
						showEditButton
						queryParams={{
							projectKey,
							projectId: Number(projectId),
							issueTypeId: String(issueType.id),
							calledFrom: ISSUE_VIEW_SOURCE,
							onRefetchIssueTypesWhenIssueTypeUpdated: onIssueTypeEdited,
							onEditModalClose,
							ufoExperience: editIssueTypeExperience,
						}}
						shouldStopPropagationOnClick={fg('inline_config_a11y_improvements')}
						EditButtonWrapper={EditIssueTypeModalEntrypointTrigger}
						editActionSubjectId={EDIT_ISSUE_TYPE_ACTION_SUBJECT_ID}
						editLabelText={formatMessage(messages.editTypeLabel)}
						onEditButtonClick={onEditButtonClick}
						isFocused={isFocused}
					>
						{issueType.name}
					</InlineConfigButtons>
				) : (
					issueType.name
				)}
			</DropdownItem>
		</Box>
	);
};
