import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
	// eslint-disable-next-line jira/restricted/react-component-props
	type ComponentProps,
	type RefObject,
} from 'react';
import { styled } from '@compiled/react';
import { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';

import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { getAnalyticsAttributesFromExtension } from '@atlassian/jira-forge-ui-analytics/src/common/utils/get-analytics-attributes-from-extension/index.tsx';
import {
	fireUiActionClickedEvent,
	fireUiRemoveClickedEvent,
	fireUiCollapseExpandClickedEvent,
} from '@atlassian/jira-forge-issue-panel/src/analytics/services/index.tsx';
import { ViewAnalyticsWrapper } from '@atlassian/jira-forge-issue-panel/src/analytics/ui/ViewAnalyticsWrapper.tsx';
import { AsyncIssuePanel } from '@atlassian/jira-forge-issue-panel/src/ui/async/AsyncIssuePanel.tsx';
import { ExtensionTitle } from '@atlassian/jira-forge-ui-extension-title/src/ui/index.tsx';
import type {
	CustomAction,
	IssuePanel,
} from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useOnDemandIssueRefreshTime } from '@atlassian/jira-issue-refresh-service/src/services/main.tsx';
import { CollapsedSectionWrapper } from '@atlassian/jira-issue-view-common-styles/src/issue-layout.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { smoothScrollIntoCenterIfNeeded } from '@atlassian/jira-issue-view-common-utils/src/scroll/index.tsx';
import {
	SectionHeading,
	SectionHeadingTitle,
} from '@atlassian/jira-issue-view-common/src/component/section-heading/section-heading-view.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	contentPanelUnselect,
	removeContentPanelRequest,
} from '@atlassian/jira-issue-view-store/src/actions/ecosystem-content-panel-actions.tsx';
import {
	forgeIssuePanelUpdateRequest,
	forgeNewIssuePanelUntrack,
	type IssuePanelUpdateRequestPayload,
} from '@atlassian/jira-issue-view-store/src/actions/forge-actions.tsx';
import {
	analyticsSourceSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { entitiesRefreshedOnSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import {
	forgeIsCollapsedPanelSelector,
	forgeJustAddedPanelSelector,
	forgeUserCanSaveIssuePanelSelector,
	forgeIssueDataSelector,
	isForgePanelNewToIssueSelector,
} from '@atlassian/jira-issue-view-store/src/ecosystem/forge/forge-extensions-selector.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { MeatBallMenu } from '../common/meatball-menu-view.tsx';
import messages from './messages.tsx';

type IssuePanelConfig = JSX.LibraryManagedAttributes<
	typeof AsyncIssuePanel,
	ComponentProps<typeof AsyncIssuePanel>
>;
type ExtensionData = Omit<IssuePanelConfig['extensionData'], 'isNewToIssue'>;
type ExtendedExtensionData = ExtensionData & { isNewToIssue: boolean };

type OwnProps = {
	isCompactMode: boolean;
	name: string;
	extension: IssuePanel;
	externalId?: string;
	appKey: string;
	moduleKey: string;
	panelKey: string;
};

type StateProps = {
	isJustAdded: boolean;
	isPanelCollapsed: boolean;
	issueKey: IssueKey;
	refreshedOn: number;
	extensionData: ExtensionData;
	isNewToIssue: boolean;
	source: string | undefined;
	userCanSaveIssuePanel: boolean;
};

type DispatchProps = {
	// eslint-disable-next-line jira/react/handler-naming
	removeFromJustAdded: (panelKey: string) => void;
	// eslint-disable-next-line jira/react/handler-naming
	removeFromNewPanels: (panelKey: string) => void;
	// eslint-disable-next-line jira/react/handler-naming
	updatePanel: (payload: IssuePanelUpdateRequestPayload) => void;
	onRemove: (panelKey: string) => void;
};

export type ForgeContentPanelViewProps = OwnProps & StateProps & DispatchProps;

type ActionsProps = {
	actions: CustomAction[];
	moreActionsButtonRef?: RefObject<HTMLButtonElement>;
};

type HeaderProps = ActionsProps & {
	name: string;
	extension: IssuePanel;
	moreActionsButtonRef?: RefObject<HTMLButtonElement>;
};

export const Actions = ({ actions, moreActionsButtonRef }: ActionsProps) => {
	const [isLoading, setIsLoading] = useState(false);
	const { formatMessage } = useIntl();

	if (actions.length === 0) {
		return null;
	}

	const actionsWithLoading: CustomAction[] = actions.map((action) => ({
		...action,
		onClick: () => {
			setIsLoading(true);
			return action.onClick().finally(() => {
				setIsLoading(false);
			});
		},
	}));

	return (
		<MeatBallMenu
			testId="issue-view-ecosystem.forge.meat-ball-menu"
			isLoading={isLoading}
			ref={moreActionsButtonRef}
			label={formatMessage(messages.moreActionLabel, { appName: 'system-app-tester' })}
		>
			<DropdownItemGroup title={formatMessage(messages.title)}>
				{actionsWithLoading.map((action) => (
					<DropdownItem onClick={action.onClick} key={action.key}>
						{action.text}
					</DropdownItem>
				))}
			</DropdownItemGroup>
		</MeatBallMenu>
	);
};

export const ForgeContentPanelHeader = ({
	name,
	extension: { environmentType, environmentKey },
	actions,
	moreActionsButtonRef,
}: HeaderProps) => (
	<SectionHeading>
		<LeftAligned>
			<SectionHeadingTitle>
				<ExtensionTitle
					extensionName={name}
					extensionEnvType={environmentType}
					extensionEnvKey={environmentKey}
				/>
			</SectionHeadingTitle>
		</LeftAligned>
		<RightAligned>
			<Actions actions={actions} moreActionsButtonRef={moreActionsButtonRef} />
		</RightAligned>
	</SectionHeading>
);

export const ForgeContentPanelView = ({
	extensionData,
	source,
	onRemove,
	appKey,
	moduleKey,
	issueKey,
	isNewToIssue,
	name,
	isPanelCollapsed,
	...restProps
}: ForgeContentPanelViewProps) => {
	const {
		extension,
		panelKey,
		isJustAdded,
		isCompactMode,
		removeFromJustAdded,
		removeFromNewPanels,
		updatePanel,
		userCanSaveIssuePanel,
	} = restProps;
	const wrapperRef = useRef<HTMLDivElement>(null);
	const moreActionsButtonRef = useRef<HTMLButtonElement | null>(null);
	const { formatMessage } = useIntl();

	useEffect(() => {
		if (isJustAdded && wrapperRef && wrapperRef.current) {
			smoothScrollIntoCenterIfNeeded(wrapperRef.current);
			// Moving focus to the container while scrolling the container to achieve focus order
			// Added 500ms timeout because smoothScroll need to be completed before moving the focus
			setTimeout(() => moreActionsButtonRef.current?.focus(), 500);
			removeFromJustAdded(panelKey);
		}
	}, [isJustAdded, panelKey, removeFromJustAdded]);

	useEffect(
		() => () => {
			if (isNewToIssue) {
				removeFromNewPanels(panelKey);
			}
		},
		[isNewToIssue, panelKey, removeFromNewPanels],
	);

	const analyticsAttributes = useMemo(() => ({ source, isNewToIssue }), [isNewToIssue, source]);

	const [customActions, setCustomActions] = useState<CustomAction[]>([]);

	const removeAction: CustomAction = useMemo(
		() => ({
			onClick: () => {
				fireUiRemoveClickedEvent(source);

				onRemove(panelKey);
				return Promise.resolve();
			},
			text: formatMessage(messages.remove),
			key: 'internal__remove-action',
		}),
		[formatMessage, onRemove, panelKey, source],
	);

	const setPanelCollapse = useCallback(
		(collapsed: boolean) => {
			fireUiCollapseExpandClickedEvent(source, { collapsed });

			if (collapsed) {
				setCustomActions([]);
			}

			updatePanel({
				issueKey,
				panelKey,
				collapsed,
			});

			return Promise.resolve();
		},
		[issueKey, panelKey, source, updatePanel],
	);

	const expandAction = useMemo(
		() => ({
			onClick: () => setPanelCollapse(false),
			text: formatMessage(messages.expand),
			key: 'internal__expand-action',
		}),
		[formatMessage, setPanelCollapse],
	);

	const collapseAction = useMemo(
		() => ({
			onClick: () => setPanelCollapse(true),
			text: formatMessage(messages.collapse),
			key: 'internal__collapse-action',
		}),
		[formatMessage, setPanelCollapse],
	);

	const changeCollapseAction = isPanelCollapsed ? expandAction : collapseAction;

	const actions: CustomAction[] = [
		// based on docs we show only up to 5 custom actions
		...customActions.slice(0, 5).map((action) => ({
			...action,
			onClick: () => {
				fireUiActionClickedEvent(source);

				return action.onClick();
			},
		})),
		...(!userCanSaveIssuePanel ? [] : [changeCollapseAction, removeAction]),
	];

	const extendedExtensionData: ExtendedExtensionData = useMemo(
		() => ({
			...extensionData,
			isNewToIssue,
		}),
		[isNewToIssue, extensionData],
	);

	return (
		<div ref={wrapperRef} data-testid="issue-view-ecosystem.forge.issue-panel">
			<CollapsedSectionWrapper
				isCompactMode={isCompactMode}
				isPanelCollapsed={isPanelCollapsed && userCanSaveIssuePanel}
			>
				<ForgeContentPanelHeader
					actions={actions}
					extension={extension}
					name={name}
					moreActionsButtonRef={moreActionsButtonRef}
				/>
				{(!isPanelCollapsed || !userCanSaveIssuePanel) && (
					<div role="status" aria-live="polite" aria-atomic="true">
						<AsyncIssuePanel
							extensionData={extendedExtensionData}
							extension={extension}
							localId={panelKey}
							setCustomActions={setCustomActions}
							analyticsAttributes={analyticsAttributes}
						/>
					</div>
				)}
			</CollapsedSectionWrapper>
		</div>
	);
};

export const ForgeContentPanelViewWithAnalytics = (props: ForgeContentPanelViewProps) => {
	const refreshedOn = useOnDemandIssueRefreshTime();

	return (
		<ViewAnalyticsWrapper
			key={refreshedOn}
			attributes={{
				source: props.source,
				isNewToIssue: props.isNewToIssue,
				isPanelCollapsed: props.isPanelCollapsed,
				userCanSaveIssuePanel: props.userCanSaveIssuePanel,
				...getAnalyticsAttributesFromExtension(props.extension),
			}}
		>
			<ForgeContentPanelView {...props} />
		</ViewAnalyticsWrapper>
	);
};

export default connect(
	(state: State, ownProps: { panelKey: string }): StateProps => ({
		isJustAdded: forgeJustAddedPanelSelector(ownProps.panelKey)(state),
		issueKey: issueKeySelector(state),
		refreshedOn: entitiesRefreshedOnSelector(state),
		extensionData: forgeIssueDataSelector(state),
		isNewToIssue: isForgePanelNewToIssueSelector(state, ownProps.panelKey),
		source: analyticsSourceSelector(state),
		isPanelCollapsed: forgeIsCollapsedPanelSelector(state, ownProps.panelKey),
		userCanSaveIssuePanel: forgeUserCanSaveIssuePanelSelector(state),
	}),
	(dispatch): DispatchProps => ({
		removeFromJustAdded: (key) => {
			dispatch(contentPanelUnselect(key));
		},
		removeFromNewPanels: (key) => {
			dispatch(forgeNewIssuePanelUntrack(key));
		},
		onRemove: (key) => {
			dispatch(removeContentPanelRequest(key));
		},
		updatePanel: (payload: IssuePanelUpdateRequestPayload) => {
			dispatch(forgeIssuePanelUpdateRequest(payload));
		},
	}),
)(ForgeContentPanelViewWithAnalytics);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LeftAligned = styled.div<{ clickable?: boolean }>({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 4}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: (props) => (props.clickable ? 'pointer' : 'auto'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RightAligned = styled.div({
	alignItems: 'center',
	display: 'flex',
	flexFlow: 'row-reverse wrap',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > * + *': {
		marginLeft: token('space.100'),
	},
});
