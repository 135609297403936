import React, { useMemo } from 'react';
import { MOBILE_ISSUE } from '@atlassian/jira-common-constants/src/analytics-sources.tsx';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	useAnalyticsSource,
	useProjectKey,
	useIssueKey,
	useIsClassifyEditEnabled,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useSearchCustomFieldKeys } from '@atlassian/jira-issue-field-base/src/services/custom-field-key-service/index.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { useFlaggedField } from '@atlassian/jira-issue-field-flagged/src/services/main.tsx';
import useConnectOperations from '@atlassian/jira-issue-non-critical-gira-service/src/controllers/use-connect-operations/index.tsx';
import type { Action as ForgeAction } from '@atlassian/jira-issue-view-common-types/src/forge-types.tsx';
import { FLAG_ACTIONS } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import type { EcosystemOperation } from '@atlassian/jira-issue-view-ecosystem-service/src/services/types.tsx';
import {
	FLAGGED_CF_TYPE,
	TIME_TRACKING_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import {
	useProjectType,
	useProjectConfiguration,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { useTimeTrackingConfiguration } from '@atlassian/jira-settings-service/src/main.tsx';
import type {
	ShortcutComponentProps,
	ShortcutOption,
} from '@atlassian/jira-shortcuts-dialog/src/common/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { main_redactions_issueActionMenu_user$key } from '@atlassian/jira-relay/src/__generated__/main_redactions_issueActionMenu_user.graphql';
import { ClassifyIssueButtonItem } from './classify-issue/button-item.tsx';
import { classifyIssueLabel, classifyRequestLabel } from './classify-issue/index.tsx';
import { ClassifyIssue } from './classify-issue/main.tsx';
import { getEcosystemItems } from './ecosystem-dropdown-items/index.tsx';
import FlagButtonItem from './flag/button-item.tsx';
import { addFlagLabel, removeFlagLabel } from './flag/index.tsx';
import FlagIssueActionView from './flag/main.tsx';
import { LogWork, LogWorkButtonItem, label as logWorkLabel } from './log-work/index.tsx';
import { Redactions } from './redactions/main.tsx';
import { RedactionsButtonItem } from './redactions/button-item.tsx';
import { redactionsLabel } from './redactions/index.tsx';

export const addToIssueDropdownGroup = (
	actions: ShortcutOption[],
	onClick: (
		itemKey: string,
		event?: Event,
		actionAttributes?: Attributes,
	) => Promise<undefined> | undefined,
) => ({
	name: '',
	key: 'AddToIssueDropdownGroup',
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	items: actions.reduce<Array<any>>((itemsArray, { Component, label, key }) => {
		if (Component) {
			itemsArray.push(<Component label={label} key={key} itemKey={key} onClick={onClick} />);
		}
		return itemsArray;
	}, []),
});

export const useAddToIssueActions = (
	forgeIssueActions: (EcosystemOperation | ForgeAction)[],
	rootRelayFragment?: main_redactions_issueActionMenu_user$key | null,
	redactionCount = 0,
): ShortcutOption[] => {
	const { formatMessage } = useIntl();
	const source = useAnalyticsSource();
	const issueKey = useIssueKey();
	const projectKey = useProjectKey();
	const projectType = useProjectType(projectKey);
	const { issueActions } = useProjectConfiguration(projectKey);
	const [permissions] = useProjectPermissions(projectKey);
	const [timeTrackingFieldConfigData] = useFieldConfig(issueKey, TIME_TRACKING_TYPE);
	const timeTrackingFieldConfig = timeTrackingFieldConfigData.value;
	const [timeTrackingConfig] = useTimeTrackingConfiguration();
	const [fieldKey] = useSearchCustomFieldKeys(issueKey, FLAGGED_CF_TYPE);
	const [{ value: isFlagged }] = useFlaggedField({ issueKey, fieldKey: fieldKey || '' });
	const [{ value: connectOperations }] = useConnectOperations(issueKey);

	const shouldShowFlag = useMemo(
		() => permissions.canEditIssues && !!issueActions?.includes(FLAG_ACTIONS),
		[permissions, issueActions],
	);

	const showRedactions = useMemo(
		() =>
			permissions.canEditIssues &&
			redactionCount > 0 &&
			fg('enable_redaction_support_in_jira_issue_view'),
		[permissions.canEditIssues, redactionCount],
	);

	const canLogTime = useMemo(
		() =>
			source !== MOBILE_ISSUE &&
			permissions.canLogWork &&
			!!timeTrackingFieldConfig &&
			timeTrackingConfig.isTimeTrackingEnabled,
		[source, permissions, timeTrackingFieldConfig, timeTrackingConfig],
	);

	const canClassifyIssue = useIsClassifyEditEnabled();
	const items = useMemo(
		() => [
			...(canLogTime
				? [
						{
							Component: LogWork,
							DialogComponent: LogWorkButtonItem,
							label: formatMessage(logWorkLabel),
							key: 'logWork',
						},
					]
				: []),
			...(shouldShowFlag
				? [
						{
							Component: FlagIssueActionView,
							DialogComponent: FlagButtonItem,
							label: formatMessage(isFlagged ? removeFlagLabel : addFlagLabel),
							key: 'flagIssue',
						},
					]
				: []),
			...(canClassifyIssue
				? [
						{
							Component: ClassifyIssue,
							DialogComponent: ClassifyIssueButtonItem,
							label:
								projectType === SERVICE_DESK_PROJECT
									? formatMessage(classifyRequestLabel)
									: formatMessage(classifyIssueLabel),
							key: 'classifyIssue',
						},
					]
				: []),
			...(showRedactions
				? [
						{
							Component: (props: ShortcutComponentProps) => (
								<Redactions
									rootRelayFragment={rootRelayFragment}
									redactionCount={redactionCount}
									{...props}
								/>
							),
							DialogComponent: RedactionsButtonItem,
							label: formatMessage(redactionsLabel),
							key: 'redactions',
						},
					]
				: []),
			...getEcosystemItems({
				ecosystemActions: [...forgeIssueActions, ...connectOperations],
			}),
		],
		[
			canLogTime,
			formatMessage,
			shouldShowFlag,
			isFlagged,
			canClassifyIssue,
			projectType,
			showRedactions,
			forgeIssueActions,
			connectOperations,
			rootRelayFragment,
			redactionCount,
		],
	);

	return items;
};
