import React, { useEffect, useMemo, useState } from 'react';
import type { PublicPluginAPI } from '@atlaskit/editor-common/types';
import type { MentionsPlugin } from '@atlaskit/editor-plugin-mentions';
import usePressTracing from '@atlaskit/react-ufo/use-press-tracing';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useLoomVideoEditorInserter } from '@atlassian/jira-loom-utils/src/controllers/use-loom-video-editor-inserter/index.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { UNSAFE_noExposureExp, expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { MentionsContainer } from '@atlassian/jira-editor-wrapper/src/mention-reccommendations/index.tsx';
import {
	useProjectKey,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { useIssueTypeField } from '@atlassian/jira-issue-field-issue-type/src/services/index.tsx';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/index.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { messages as loomBannerMessages } from '../loom-videos/messages.tsx';
import type { OwnProps } from './index.tsx';
import { issueEditorEntrypoint } from './entrypoint.tsx';

type EditorMentionsAPI = PublicPluginAPI<[MentionsPlugin]>;

const entryPointParams = {};

const IssueEditor = (props: OwnProps) => {
	const [editorMentionsAPI, setEditorMentionsAPI] = useState<EditorMentionsAPI | undefined>(
		undefined,
	);

	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);
	const [{ value: issueType }] = useIssueTypeField({ issueKey });
	const { formatMessage } = useIntl();

	const isMentionExperimentEligible = useMemo(() => {
		return (
			projectType === SOFTWARE_PROJECT &&
			issueType?.name !== formatMessage(loomBannerMessages.bugIssueTypeName)
		);
	}, [projectType, issueType, formatMessage]);

	const contentComponents = useMemo(
		() =>
			isMentionExperimentEligible &&
			expVal('recommend_user_mentions_in_comments', 'isMentionsPanelVisible', false)
				? {
						before: <></>,
						after: <MentionsContainer api={editorMentionsAPI} />,
					}
				: undefined,
		[editorMentionsAPI, isMentionExperimentEligible],
	);

	const runtimeProps = useMemo(() => {
		return {
			...props,
			...(props.isAddComment &&
				isMentionExperimentEligible &&
				expVal('recommend_user_mentions_in_comments', 'isMentionsPanelVisible', false) && {
					contentComponents,
					editorMentionsAPI,
					setEditorMentionsAPI,
				}),
		};
	}, [
		props,
		contentComponents,
		editorMentionsAPI,
		setEditorMentionsAPI,
		isMentionExperimentEligible,
	]);

	return <IssueEditorInternal runtimeProps={runtimeProps} />;
};

const IssueEditorWithLoomEmbedder = (props: OwnProps) => {
	const { editorApiRef } = useLoomVideoEditorInserter({
		entryPointLocation: 'jsmIssueView_customerReply_crossflow',
	});

	const [editorMentionsAPI, setEditorMentionsAPI] = useState<EditorMentionsAPI | undefined>(
		undefined,
	);

	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);
	const [{ value: issueType }] = useIssueTypeField({ issueKey });
	const { formatMessage } = useIntl();

	const isMentionExperimentEligible = useMemo(() => {
		return (
			projectType === SOFTWARE_PROJECT &&
			issueType?.name !== formatMessage(loomBannerMessages.bugIssueTypeName)
		);
	}, [projectType, issueType, formatMessage]);

	const contentComponents = useMemo(
		() =>
			isMentionExperimentEligible &&
			expVal('recommend_user_mentions_in_comments', 'isMentionsPanelVisible', false)
				? {
						before: <></>,
						after: <MentionsContainer api={editorMentionsAPI} />,
					}
				: undefined,
		[editorMentionsAPI, isMentionExperimentEligible],
	);

	const runtimeProps = useMemo(() => {
		return {
			...props,
			editorApiRef,
			...(props.isAddComment &&
				isMentionExperimentEligible &&
				expVal('recommend_user_mentions_in_comments', 'isMentionsPanelVisible', false) && {
					contentComponents,
					editorMentionsAPI,
					setEditorMentionsAPI,
				}),
		};
	}, [
		editorApiRef,
		props,
		contentComponents,
		editorMentionsAPI,
		setEditorMentionsAPI,
		isMentionExperimentEligible,
	]);

	return <IssueEditorInternal runtimeProps={runtimeProps} />;
};

const IssueEditorInternal = ({ runtimeProps }: { runtimeProps: unknown }) => {
	const pressTracing = usePressTracing('issue-editor-load');
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		issueEditorEntrypoint,
		entryPointParams,
	);

	// Tracking the editor loading should start at the point of user interaction, as should loading the entrypoint.
	// Everything in this useEffect should actually be moved out of this component and closer to whatever
	// user interaction triggers the editor to appear. (e.g., pressing 'm' to start a new issue comment)
	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		fg('jiv-20205-more-ufo-tracing') && pressTracing();
		entryPointActions.load();
	}, [pressTracing, entryPointActions]);

	return (
		<JiraEntryPointContainer
			entryPointReferenceSubject={entryPointReferenceSubject}
			id="lazyIssueEditor"
			packageName="issueViewCommon"
			errorFallback="unmount"
			teamName="team-bento"
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore -  error TS2322: Type are incompatible
			runtimeProps={runtimeProps}
		/>
	);
};

export const LazyIssueEditor = componentWithCondition(
	() => {
		const [experimentConfigJSM] = UNSAFE_noExposureExp('loom_jsm_agent_response_touchpoint');
		return (
			fg('loom_jsm_experiment_controller') &&
			experimentConfigJSM.get('cohort', 'not-enrolled') === 'variation'
		);
	},
	IssueEditorWithLoomEmbedder,
	IssueEditor,
);
