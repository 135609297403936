import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	editButtonText: {
		id: 'inline-status-and-issue-types.inline-config-buttons-for-select.edit-button-text',
		defaultMessage: 'Edit',
		description: 'Label text of inline edit icon button to edit a config inlinely',
	},
	deleteButtonText: {
		id: 'inline-status-and-issue-types.inline-config-buttons-for-select.delete-button-text',
		defaultMessage: 'Delete',
		description: 'Label text of delete icon button to delete a config inlinely',
	},
});
