import React, { type ReactNode, useEffect } from 'react';
import type { AllowedValues } from '@atlassian/jira-issue-field-injections/src/controllers/injections-context/types.tsx';
import type { FieldOptionsFilter } from '@atlassian/jira-issue-field-injections/src/controllers/types.tsx';
import { FieldMessage } from '../../common/ui/field-message/index.tsx';
import { useFieldContext } from '../../controllers/injections-provider/index.tsx';

type Props<T> = {
	label: string;
	description: string | null | undefined;
	fieldProps: {
		value: T;
		onChange: (value: T | React.FormEvent<HTMLInputElement>) => void;
		onBlur: (value: T | React.FormEvent<HTMLInputElement>) => void;
		isDisabled: boolean;
		isRequired: boolean;
		id: string;
	};
	error?: string;
	allowedValues?: AllowedValues<T>;
	shouldShowFieldMessage?: boolean;
	children: (args: {
		label: string;
		value: T;
		onChange: (...args: unknown[]) => void;
		onBlur: (...args: unknown[]) => void;
		isDisabled: boolean;
		isRequired: boolean;
		error?: string;
		isInvalid: boolean;
		allowedValues?: AllowedValues<T>;
		fieldOptionsFilter?: FieldOptionsFilter;
		id: string;
		fetchSuggestionsOnMount: boolean;
		ariaLabelledBy?: string;
	}) => ReactNode;
	fieldHelpTextUrl?: string;
};

export const Field = <T,>({
	children,
	fieldProps: { value, isDisabled, onChange, onBlur, id, isRequired },
	error,
	label,
	description,
	allowedValues,
	fieldHelpTextUrl,
	shouldShowFieldMessage = true,
}: Props<T>) => {
	const {
		capturing,
		overriding: {
			overrideAllowedValues,
			overrideDescription,
			overrideFieldOptionsFilter,
			overrideOnBlur,
			overrideOnChange,
		},
	} = useFieldContext();

	// Since `allowedValues` and `description` are provided to the field only initially
	// we don't want to run their capturing logic with the same frequency as we do with `value`
	useEffect(() => {
		capturing.captureAllowedValues(allowedValues);
		capturing.captureDescription(description);
	}, [allowedValues, description, capturing]);

	return (
		<>
			{children({
				value,
				error,
				isInvalid: !!error,
				isDisabled,
				isRequired,
				label,
				// Those properties use overrides provided by injections context.
				// If injections provider is not present they fall back to identity functions.
				allowedValues: overrideAllowedValues(allowedValues),
				// fieldOptionsFilter isn't captured from component
				fieldOptionsFilter: overrideFieldOptionsFilter(undefined),
				onChange: overrideOnChange(onChange),
				onBlur: overrideOnBlur(onBlur),
				id,
				fetchSuggestionsOnMount: false,
				ariaLabelledBy: `${id}-label`,
			})}
			{shouldShowFieldMessage && (
				<FieldMessage
					error={error}
					fieldName={label}
					description={overrideDescription(description)}
					fieldHelpTextUrl={fieldHelpTextUrl}
				/>
			)}
		</>
	);
};
