/**
 * @generated SignedSource<<0beaacf0f46814e54750166e1f237c8f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type confluenceContent_issueViewBase_ConfluenceContentWithFragment$data = {
  readonly confluenceMentionedLinks: {
    readonly totalCount: number | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"mentionedConfluenceLinksSection_issueViewBase">;
  readonly " $fragmentType": "confluenceContent_issueViewBase_ConfluenceContentWithFragment";
};
export type confluenceContent_issueViewBase_ConfluenceContentWithFragment$key = {
  readonly " $data"?: confluenceContent_issueViewBase_ConfluenceContentWithFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"confluenceContent_issueViewBase_ConfluenceContentWithFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "name": "confluenceContent_issueViewBase_ConfluenceContentWithFragment",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "mentionedConfluenceLinksSection_issueViewBase"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "after"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        }
      ],
      "concreteType": "JiraConfluenceRemoteIssueLinkConnection",
      "kind": "LinkedField",
      "name": "confluenceMentionedLinks",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "totalCount"
        }
      ]
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "051358a70f307b56edfa140804665af7";

export default node;
