import { createSelector } from 'reselect';
import equals from 'lodash/fp/equals';
import isEqual from 'lodash/isEqual';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { ConnectionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/connection/types.tsx';
import {
	CONNECTION_FIELD_FILTER,
	CONNECTION_FIELD_FILTER_BOARD_COLUMN,
	CONNECTION_FIELD_FILTER_VIEW_GROUP,
	type ConnectionFieldFilter,
} from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import type { Props, State } from '../types.tsx';
import { isMatchingConnectionFieldFilter } from '../utils/connection-field-filters.tsx';
import { fieldMapping } from '../utils/field-mapping/index.tsx';
import { createGetField, getAllFieldsByKey, getFields } from './fields.tsx';
import { getIssueIdsConsideringArchived } from './filters.tsx';
import {
	getJiraIdToLocalIssueId,
	getIssuesWithBasicPropertiesMap,
	getConnectionIssueIds,
} from './issue-ids.tsx';
import {
	createGetIssueType,
	createGetSummary,
	getConnectionProperties,
	getSelectedIssueLocalIssueId,
} from './properties/index.tsx';
import {
	createCombinedComparator,
	createIdeaComparatorForFieldComparator,
	type IdeaComparator,
} from './sort.tsx';

export const createGetAllIssueIdsMatchingConnectionFieldFilters = (
	localIssueId: LocalIssueId,
	fieldKey: FieldKey,
) =>
	createSelector(
		getIssueIdsConsideringArchived,
		createGetField(fieldKey),
		(state, props) => ({ state, props }),
		(issueIds, connectionField, { state, props }) => {
			const projectIssuesIds = issueIds.filter((issueId) => {
				const issueType = createGetIssueType(issueId)(state, props);

				return (
					issueId !== localIssueId &&
					isMatchingConnectionFieldFilter({
						filters: connectionField?.configuration?.issueTypeFilters || [],
						issueTypeId: issueType?.id,
						issueTypeName: issueType?.name,
					})
				);
			});
			return projectIssuesIds;
		},
	);

export const createIsIssueMatchingConnectionFieldFilter = (
	localIssueId: LocalIssueId = '',
	fieldKey: FieldKey,
) =>
	createSelector(
		createGetField(fieldKey),
		(state: State, props?: Props) => ({ state, props }),
		(connectionField, { state, props }) => {
			const issueType = createGetIssueType(localIssueId)(state, props);

			return isMatchingConnectionFieldFilter({
				filters: connectionField?.configuration?.issueTypeFilters || [],
				issueTypeId: issueType?.id,
				issueTypeName: issueType?.name,
			});
		},
	);

const filterArchivedConnectionFieldIssueIds = (
	connections: ConnectionFieldValue[] = [],
	issuesWithBasicPropertiesMap: ReturnType<typeof getIssuesWithBasicPropertiesMap>,
	connectionIssueIds: ReturnType<typeof getConnectionIssueIds>,
	jiraIdToLocalIssueId: Record<number, string>,
) =>
	connections.filter(({ id }) => {
		const localId = jiraIdToLocalIssueId[parseInt(id, 10)];

		return (
			(issuesWithBasicPropertiesMap[localId] &&
				!issuesWithBasicPropertiesMap[localId]?.isArchived) ||
			connectionIssueIds.includes(localId)
		);
	});

const EMPTY_CONNECTIONS: ConnectionFieldValue[] = [];

export const createGetConnectionFieldIssueIds = (fieldKey: FieldKey, localIssueId?: LocalIssueId) =>
	createSelector(
		getConnectionProperties,
		getIssuesWithBasicPropertiesMap,
		getConnectionIssueIds,
		getJiraIdToLocalIssueId,
		(
			connectionProperties,
			issuesWithBasicPropertiesMap,
			connectionIssueIds,
			jiraIdToLocalIssueId,
		) => {
			if (!localIssueId) {
				return EMPTY_CONNECTIONS;
			}

			const result = filterArchivedConnectionFieldIssueIds(
				connectionProperties[fieldKey]?.[localIssueId],
				issuesWithBasicPropertiesMap,
				connectionIssueIds,
				jiraIdToLocalIssueId,
			);
			const sortedResult = [...result].sort(
				({ id: id1 }, { id: id2 }) => Number(id1) - Number(id2),
			);
			return sortedResult;
		},
	);

type ConnectionFieldOptions = {
	column?: {
		fieldKey: string;
		fieldValue: unknown;
	};
	group?: {
		fieldKey: string;
		fieldValue: unknown;
	};
};

const getFilters = ({ containerProps }: State) => containerProps?.filter;

const createGetConnectionFieldIssueIdsWithOptions = (
	fieldKey: FieldKey,
	localIssueId: LocalIssueId,
	{ column, group }: ConnectionFieldOptions,
) => {
	const getConnectionFieldIssueIds = createGetConnectionFieldIssueIds(fieldKey, localIssueId);

	const getMappingColumn = createSelector(
		({ containerProps }) => containerProps?.issuesRemote,
		getFields,
		getAllFieldsByKey,
		(issuesRemote, fields, fieldsByKey) =>
			column && issuesRemote && fieldMapping(issuesRemote, fields, fieldsByKey[column.fieldKey]),
	);

	const getMappingGroup = createSelector(
		({ containerProps }) => containerProps?.issuesRemote,
		getFields,
		getAllFieldsByKey,
		(issuesRemote, fields, fieldsByKey) =>
			group && issuesRemote && fieldMapping(issuesRemote, fields, fieldsByKey[group.fieldKey]),
	);

	const getConnectionFieldFilters = createSelector(getFilters, (filters) => {
		return (
			filters?.filter((filter): filter is ConnectionFieldFilter => {
				return filter.type === CONNECTION_FIELD_FILTER && filter.field === fieldKey;
			}) ?? []
		);
	});

	const getShouldMatchColumn = createSelector(getConnectionFieldFilters, (connectionFieldFilters) =>
		connectionFieldFilters.some((filter) =>
			filter.values.some((value) => value.enumValue === CONNECTION_FIELD_FILTER_BOARD_COLUMN),
		),
	);

	const getShouldMatchGroup = createSelector(getConnectionFieldFilters, (connectionFieldFilters) =>
		connectionFieldFilters.some((filter) =>
			filter.values.some((value) => value.enumValue === CONNECTION_FIELD_FILTER_VIEW_GROUP),
		),
	);

	// For memoized result
	let lastResult: ConnectionFieldValue[] = [];

	return createSelector(
		getConnectionFieldIssueIds,
		getJiraIdToLocalIssueId,
		getMappingColumn,
		getMappingGroup,
		getShouldMatchColumn,
		getShouldMatchGroup,
		(state, props) => ({ state, props }),
		(
			issueIds,
			jiraIdToLocalIssueId,
			mappingColumn,
			mappingGroup,
			shouldMatchColumn,
			shouldMatchGroup,
			{ state, props },
		) => {
			const columnMatching = (v: ConnectionFieldValue) => {
				if (shouldMatchColumn === false || mappingColumn === undefined || column === undefined) {
					return true;
				}

				const localId = jiraIdToLocalIssueId[parseInt(v.id, 10)];
				const fieldValue = mappingColumn.valueAccessor(state, props, localId);
				return mappingColumn.comparator(column.fieldValue, fieldValue, 'ASC') === 0;
			};

			const groupMatching = (v: ConnectionFieldValue) => {
				if (shouldMatchGroup === false || mappingGroup === undefined || group === undefined) {
					return true;
				}

				const localId = jiraIdToLocalIssueId[parseInt(v.id, 10)];
				const fieldValue = mappingGroup.valueAccessor(state, props, localId);
				return mappingGroup.comparator(group.fieldValue, fieldValue, 'ASC') === 0;
			};

			const result = issueIds.filter((v) => columnMatching(v) && groupMatching(v));

			if (equals(result, lastResult)) {
				// So that we don't send new array to React. This should prevent extra renders.
				return lastResult;
			}
			// Update the memoized value
			lastResult = result;
			return result;
		},
	);
};

const createGetSelectedIssueConnectionFieldIssueIds = (connectionFieldKey: FieldKey) =>
	createSelector(
		getSelectedIssueLocalIssueId,
		getConnectionProperties,
		getIssuesWithBasicPropertiesMap,
		getConnectionIssueIds,
		getJiraIdToLocalIssueId,
		(
			localIssueId,
			connectionProperties,
			issuesWithBasicPropertiesMap,
			connectionIssueIds,
			jiraIdToLocalIssueId,
		) => {
			if (!localIssueId) {
				return EMPTY_CONNECTIONS;
			}

			const result = filterArchivedConnectionFieldIssueIds(
				connectionProperties[connectionFieldKey]?.[localIssueId],
				issuesWithBasicPropertiesMap,
				connectionIssueIds,
				jiraIdToLocalIssueId,
			);
			const sortedResult = [...result].sort(
				({ id: id1 }, { id: id2 }) => Number(id1) - Number(id2),
			);
			return sortedResult;
		},
	);

export const createGetColumnAndGroupAwareConnectionFieldIssueIdsSorted = (
	connectionFieldKey: FieldKey,
	localIssueId: LocalIssueId,
	options: ConnectionFieldOptions,
) =>
	createSelector(
		createGetConnectionFieldIssueIdsWithOptions(connectionFieldKey, localIssueId, options),
		createGetField(connectionFieldKey),
		getAllFieldsByKey,
		getFields,
		getJiraIdToLocalIssueId,
		({ containerProps }) => containerProps?.issuesRemote,
		(state, props) => ({ state, props }),
		(
			ids,
			connectionField,
			fieldsByKey,
			fields,
			jiraIdToLocalIssueId,
			issuesRemote,
			{ state, props },
		) => {
			if (!connectionField || !issuesRemote) {
				return [];
			}

			const sortBy = connectionField.configuration?.issueViewLayout?.sort || [];

			const configuredComparators = sortBy.reduce<Array<IdeaComparator>>((comparators, sort) => {
				const mapping = fieldMapping(issuesRemote, fields, fieldsByKey[sort.fieldKey]);

				const comparator = createIdeaComparatorForFieldComparator(
					state,
					props,
					mapping.valueAccessor,
					mapping.comparator,
					mapping.comparatorWithMapping,
					sort.order === 'ASC',
				);

				comparators.push(comparator);

				return comparators;
			}, []);

			const combinedComparator = createCombinedComparator(configuredComparators);

			const idsCopy = [...ids];

			idsCopy.sort((a, b) =>
				combinedComparator(
					jiraIdToLocalIssueId[parseInt(a.id, 10)],
					jiraIdToLocalIssueId[parseInt(b.id, 10)],
				),
			);

			return idsCopy;
		},
	);

type ConnectionsMap = Map<
	LocalIssueId,
	Array<{
		field: Field;
		key: FieldKey;
		value: {
			connections: Array<{ summary: string; highlightedFieldValue: unknown }>;
			highlightedField?: Field;
		};
	}>
>;

type ColumnAndGroupIssue = {
	id: string;
	localIssueId: LocalIssueId;
	column: {
		fieldKey: FieldKey;
		fieldValue: unknown;
	};
	group?: {
		fieldKey: FieldKey;
		fieldValue: unknown;
	};
};

const EMPTY_CONNECTIONS_MAP: ConnectionsMap = new Map();

export const createGetColumnAndGroupAwareConnections = (
	issues: ColumnAndGroupIssue[],
	connectionFieldKeys: FieldKey[],
) => {
	let lastResult: ConnectionsMap;

	return createSelector(
		getAllFieldsByKey,
		getFields,
		getJiraIdToLocalIssueId,
		({ containerProps }) => containerProps?.issuesRemote,
		(state, props) => ({ state, props }),
		(fieldsByKey, fields, jiraIdToLocalIssueId, issueRemote, { state, props }) => {
			if (!issueRemote) {
				return EMPTY_CONNECTIONS_MAP;
			}

			const connectionsMap: ConnectionsMap = new Map();

			issues.forEach(({ id, localIssueId, group, column }) => {
				if (!connectionsMap.has(id)) {
					connectionsMap.set(id, []);
				}

				const issueConnectionFields = connectionsMap.get(id);

				connectionFieldKeys.forEach((fieldKey) => {
					const field = fieldsByKey[fieldKey];

					if (!field) {
						return;
					}

					const filteredConnections = createGetConnectionFieldIssueIdsWithOptions(
						field.key,
						localIssueId,
						{ column, group },
					)(state, props);
					const highlightedField = field.configuration?.issueViewLayout?.fields[0];
					const highlightedFieldMapping = highlightedField
						? fieldMapping(issueRemote, fields, fieldsByKey[highlightedField])
						: undefined;
					const connections = filteredConnections.map((connection) => {
						const connectionLocalIssueId = jiraIdToLocalIssueId[parseInt(connection.id, 10)];

						return {
							summary: createGetSummary(connectionLocalIssueId)(state, props),
							highlightedFieldValue: highlightedFieldMapping?.valueAccessor(
								state,
								props,
								connectionLocalIssueId,
							),
						};
					});

					if (!connections.length) {
						return;
					}

					issueConnectionFields?.push({
						field,
						key: field.key,
						value: {
							highlightedField: highlightedField ? fieldsByKey[highlightedField] : undefined,
							connections,
						},
					});
				});
			});

			if (isEqual(lastResult, connectionsMap)) {
				return lastResult;
			}

			lastResult = connectionsMap;
			return connectionsMap;
		},
	);
};

export const createGetConnectionFieldSortedIssueIds = (
	connectionFieldKey: FieldKey,
	localIssueId?: LocalIssueId,
) =>
	createSelector(
		createGetConnectionFieldIssueIds(connectionFieldKey, localIssueId),
		createGetField(connectionFieldKey),
		getAllFieldsByKey,
		getFields,
		getJiraIdToLocalIssueId,
		({ containerProps }) => containerProps?.issuesRemote,
		(state, props) => ({ state, props }),
		(
			ids,
			connectionField,
			fieldsByKey,
			fields,
			jiraIdToLocalIssueId,
			issuesRemote,
			{ state, props },
		) => {
			if (!connectionField || !issuesRemote) {
				return [];
			}

			const sortBy = connectionField.configuration?.issueViewLayout?.sort || [];

			const configuredComparators = sortBy.reduce<Array<IdeaComparator>>((comparators, sort) => {
				const mapping = fieldMapping(issuesRemote, fields, fieldsByKey[sort.fieldKey]);

				const comparator = createIdeaComparatorForFieldComparator(
					state,
					props,
					mapping.valueAccessor,
					mapping.comparator,
					mapping.comparatorWithMapping,
					sort.order === 'ASC',
				);

				comparators.push(comparator);

				return comparators;
			}, []);

			const combinedComparator = createCombinedComparator(configuredComparators);

			const idsCopy = [...ids];

			idsCopy.sort((a, b) =>
				combinedComparator(
					jiraIdToLocalIssueId[parseInt(a.id, 10)],
					jiraIdToLocalIssueId[parseInt(b.id, 10)],
				),
			);

			return idsCopy;
		},
	);

export const createGetSelectedIssuesConnectionFieldIssuesCount = (fieldKey: FieldKey) =>
	createSelector(createGetSelectedIssueConnectionFieldIssueIds(fieldKey), (ids) => ids.length);

export const getSelectedIssueConfiguredConnectionFields = createSelector(
	getSelectedIssueLocalIssueId,
	getFields,
	(state, props) => ({ state, props }),
	(localIssueId, fields, { state, props }) => {
		const issueType = createGetIssueType(localIssueId)(state, props);

		return fields.reduce<Field[]>((res, field) => {
			if (
				field.type === FIELD_TYPES.CONNECTION &&
				field.configuration?.issueTypeFilters &&
				field.configuration.issueTypeFilters.length > 0 &&
				!isMatchingConnectionFieldFilter({
					filters: field.configuration.issueTypeFilters,
					issueTypeId: issueType?.id,
					issueTypeName: issueType?.name,
				})
			) {
				res.push(field);
			}
			return res;
		}, []);
	},
);

export const getSelectedIssueConfiguredConnectionFieldsCount = createSelector(
	getSelectedIssueConfiguredConnectionFields,
	(fields) => fields.length,
);
