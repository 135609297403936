// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	remoteLinkAuthText: {
		id: 'issues.issue-links.app-link-option.auth-required-nonfinal',
		defaultMessage: 'Authenticate to search',
		description:
			'Text for application links that require authentication before we can link to their resources',
	},
	remoteLinkAuthTextNew: {
		id: 'issues.issue-links.app-link-option.auth-required',
		defaultMessage: 'Enable access to link work items for this site',
		description:
			'Text for application links that require authentication before we can link to their resources',
	},
	remoteLinkAuthLabelText: {
		id: 'issues.issue-links.app-link-option.auth-required-label',
		defaultMessage: 'Enable access to link work items for this site, opens in new window',
		description: 'Label for application links that require authentication',
	},
});
