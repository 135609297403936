import React from 'react';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { createUseEntryPointContext } from '@atlassian/jira-entry-point/src/controllers/utils/create-use-entry-point-context/index.tsx';
import { issueSmartRequestSummaryEntryPoint } from '@atlassian/jira-issue-smart-request-summary/entrypoint.tsx';
import { jsmSmartRequestSummaryEntryPoint } from '@atlassian/jira-servicedesk-smart-request-summary/entrypoint.tsx';
import { jsmSmartRequestPreloadedSummaryEntryPoint } from '@atlassian/jira-servicedesk-smart-request-summary/src/graphql/entrypoint.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';

export const JsmSmartRequestSummaryEntryPointContext =
	createUseEntryPointContext<typeof jsmSmartRequestSummaryEntryPoint>();

export const JsmSmartRequestSummaryEntryPointContextProvider = ({
	children,
}: React.PropsWithChildren<{}>) => {
	const entryPoint = useEntryPoint(jsmSmartRequestSummaryEntryPoint, {});
	const preloadedSummaryEntryPoint = useEntryPoint(jsmSmartRequestPreloadedSummaryEntryPoint, {
		cloudId: useCloudId(),
		issueId: useIssueId() ?? '',
	});

	return (
		<JsmSmartRequestSummaryEntryPointContext.Provider
			value={fg('smart-summary-using-graphql') ? preloadedSummaryEntryPoint : entryPoint}
		>
			{children}
		</JsmSmartRequestSummaryEntryPointContext.Provider>
	);
};

export const IssueSmartRequestSummaryEntryPointContext =
	createUseEntryPointContext<typeof issueSmartRequestSummaryEntryPoint>();

const EMPTY_ENTRY_POINT_PARAMS = {};

export const IssueSmartRequestSummaryEntryPointContextProvider = ({
	children,
}: React.PropsWithChildren<{}>) => {
	const entryPoint = useEntryPoint(issueSmartRequestSummaryEntryPoint, EMPTY_ENTRY_POINT_PARAMS);

	return (
		<IssueSmartRequestSummaryEntryPointContext.Provider value={entryPoint}>
			{children}
		</IssueSmartRequestSummaryEntryPointContext.Provider>
	);
};
