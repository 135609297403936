import { SUMMARY_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import { VIEW_KIND_TIMELINE } from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import { type View, ViewLayoutType } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { getCurrentViewFieldKeys } from '../../selectors/view/index.tsx';
import { getCurrentViewSummaryCardField } from '../../selectors/view/timeline/index.tsx';
import type { State, Props } from '../../types.tsx';
import { saveViewWithAutoSave } from '../save/index.tsx';
import { updateSummaryCardFieldInView } from '../timeline/index.tsx';
import { updateViewState } from '../utils/state/index.tsx';
import { currentViewFilter } from '../utils/views/index.tsx';

export const setLayoutType =
	(layoutType: ViewLayoutType): Action<State, Props> =>
	async ({ getState, setState, dispatch }, props) => {
		const viewMutation = (view: View): View => {
			const newView = {
				...view,
				layoutType: view.isAutosaveEnabled ? layoutType : view.layoutType,
				modified: view.isAutosaveEnabled,
				draft: {
					...view.draft,
					layoutType,
				},
			};
			const summaryCardField = getCurrentViewSummaryCardField(getState(), props);

			if (
				view.kind === VIEW_KIND_TIMELINE &&
				layoutType === ViewLayoutType.SUMMARY &&
				!summaryCardField
			) {
				const firstFieldKey = getCurrentViewFieldKeys(getState(), props).find(
					(key) => key !== SUMMARY_FIELDKEY,
				);

				if (firstFieldKey) {
					return updateSummaryCardFieldInView(newView, props.fields[firstFieldKey]);
				}
			}

			return newView;
		};
		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(props.currentViewSlug),
			viewMutation,
		);

		if (changedView) {
			setState({ viewSets });
			dispatch(saveViewWithAutoSave(changedView.id));
		}
	};
