import React, { useEffect, useMemo, type ReactNode } from 'react';
import noop from 'lodash/noop';
import { ExperienceTracker as ViewExperienceTracker } from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracker-consumer/tracker-base/index.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import {
	MultilineFieldHeading,
	MultilineFieldHeadingTitle,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin/src/index.tsx';
import {
	CONTENT,
	CONTEXT,
} from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import {
	SectionHeading,
	SectionHeadingTitle,
	DraftIndicator,
	HeadingWithDraft,
} from '@atlassian/jira-issue-view-common/src/component/section-heading/section-heading-view.tsx';
import draftMessages from '@atlassian/jira-issue-view-common/src/messages/drafts.tsx';
import { DESCRIPTION } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	useIssueLayout,
	MIN_EDITOR_SIDEBAR_CONTAINER_WIDTH,
} from '@atlassian/jira-issue-view-services/src/issue-layout-service/context.tsx';
import {
	fieldEditingAdfValueSelector,
	isFieldEditingSelector,
	isFieldWaitingSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import { fieldHasDraftSelector } from '@atlassian/jira-issue-view-store/src/selectors/drafts/selectors.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';

import { TextareaWrapperWithoutMargin } from '../switching-multiline-field/view.tsx';
import type { DescriptionProps, DescriptionWrapperProps, InjectedProps } from './types.tsx';
import RichDescription from './view.tsx';

export const Description = (props: DescriptionProps) => {
	const {
		area = CONTEXT,
		fieldId,
		hasDraft,
		isEditing,
		isWaiting,
		onExperienceSuccess = noop,
		onSaveRef,
		label: labelFromProps,
		adfValue: adfValueServer,
		adfValueDraft,
		...rest
	} = props;
	const adfValue = useMemo(() => {
		if (fg('relay-migration-issue-fields-multi-line-text-fg')) {
			return hasDraft && isEditing ? adfValueDraft : adfValueServer;
		}
		return adfValueServer;
	}, [adfValueDraft, adfValueServer, hasDraft, isEditing]);

	const issueKey = useIssueKey();

	const [{ value }] = useFieldConfig(issueKey, DESCRIPTION);
	const label: string =
		labelFromProps !== undefined && fg('relay-migration-issue-fields-multi-line-text-fg')
			? labelFromProps || ''
			: value?.title || '';

	const intl = useIntl();
	const HeadingWrapper = area === CONTENT ? SectionHeading : MultilineFieldHeading;
	const HeadingTitle = area === CONTENT ? SectionHeadingTitle : MultilineFieldHeadingTitle;
	const showPinButton = getShowPinButton(area);
	const [, { updateSidebarMinWidth, resetSidebarMinWidth }] = useIssueLayout();

	useEffect(() => {
		if (area === CONTEXT) {
			if (isEditing === true) {
				updateSidebarMinWidth(MIN_EDITOR_SIDEBAR_CONTAINER_WIDTH);
				return;
			}
			resetSidebarMinWidth();
		}
	}, [isEditing, area, updateSidebarMinWidth, resetSidebarMinWidth]);

	useEffect(() => {
		onExperienceSuccess();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<TextareaWrapperWithoutMargin>
			<HeadingWrapper data-testid="issue-view-base.common.description.heading-wrapper">
				<HeadingWithDraft data-testid="issue-view-base.common.description.heading-with-draft">
					<HeadingTitle
						/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
						data-testid="issue.views.issue-base.common.description.label"
					>
						{label}
					</HeadingTitle>
					{hasDraft && !isEditing && !isWaiting ? (
						<DraftIndicator
							/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
							data-testid="issue.views.issue-base.common.description.draft-indicator"
							aria-label={intl.formatMessage(draftMessages.draftIndicator)}
						>
							{`• ${intl.formatMessage(draftMessages.draftIndicator)}`}
						</DraftIndicator>
					) : null}
					{showPinButton === true && <FieldPin fieldId={fieldId} label={label} />}
				</HeadingWithDraft>
			</HeadingWrapper>
			<RichDescription
				{...(fg('relay-migration-issue-fields-multi-line-text-fg')
					? { saveFieldOverride: onSaveRef, label: labelFromProps, adfValue, ...rest }
					: {})}
			/>
		</TextareaWrapperWithoutMargin>
	);
};
const ContextualAnalytics = ({ children }: { children?: ReactNode }) => {
	if (fg('adding-more-context-for-drop-events')) {
		return (
			<ContextualAnalyticsData sourceType={SCREEN} sourceName="description-field">
				{children}
			</ContextualAnalyticsData>
		);
	}
	return children;
};

const DescriptionWrapper = (props: DescriptionWrapperProps) => (
	<UFOSegment name="issue-description">
		<ErrorBoundary prefixOverride="issue" id="Description">
			<ContextualAnalytics>
				<ViewExperienceTracker location="description-container">
					{({ onExperienceSuccess }) => (
						<Description onExperienceSuccess={onExperienceSuccess} {...props} />
					)}
				</ViewExperienceTracker>
			</ContextualAnalytics>
		</ErrorBoundary>
	</UFOSegment>
);

export default connect(
	(state): InjectedProps => ({
		hasDraft: fieldHasDraftSelector(DESCRIPTION)(state),
		isEditing: isFieldEditingSelector(DESCRIPTION)(state),
		isWaiting: isFieldWaitingSelector(DESCRIPTION)(state),
		...(fg('relay-migration-issue-fields-multi-line-text-fg')
			? {
					/**
					 * This is technically the redux value, not just draft value
					 * It will return essentially draft ?? realValue
					 * So we use hasDraft and isEditing to determine if we should use draft or real value in the component
					 */
					adfValueDraft: fieldEditingAdfValueSelector(DESCRIPTION)(state),
				}
			: {}),
	}),
	{},
)(DescriptionWrapper);
