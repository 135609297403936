import { expVal } from '@atlassian/jira-feature-experiments';
import type { IncidentManagementField } from './types.tsx';

export const isMatch = (searchQuery?: string, label?: string) => {
	if (!searchQuery) return true;
	const trimmedSearchQuery = searchQuery.trim();
	if (
		!trimmedSearchQuery ||
		!expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false)
	) {
		return true;
	}

	return label?.toLowerCase().includes(trimmedSearchQuery.toLowerCase());
};

export const shouldLinkAlertsItemRender = (
	incidentManagementField?: IncidentManagementField,
): boolean => {
	if (!incidentManagementField) {
		return false;
	}
	return incidentManagementField.alertLinking.enabled;
};
