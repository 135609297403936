import React, { useCallback, useMemo } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import VideoPlayIcon from '@atlaskit/icon/core/video-play';
import AttachmentIcon from '@atlaskit/icon/core/migration/attachment';
import { Flex } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useQuickActionTimingMetrics } from '../timing-metrics/context.tsx';
import messages from './messages.tsx';

export const LoomInsertLinkButton = ({
	onInsertLoomLinkClick,
}: {
	onInsertLoomLinkClick: () => void;
}) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const analyticsEvent = createAnalyticsEvent({
		action: 'clicked',
		actionSubject: 'dropdownItem',
	});
	const { measureTimeOnClick } = useQuickActionTimingMetrics();

	const label = useMemo(() => {
		let _label = messages.loomInsertExistingVideoButtonLabel;
		if (fg('quick_actions_menu_ga')) {
			_label = expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false)
				? messages.linkVideoButtonLabel
				: messages.loomExistingVideoButtonLabel;
		}
		return _label;
	}, []);

	const handleInsertLoomLink = useCallback(() => {
		fireUIAnalytics(analyticsEvent, 'add-text-button-dropdown-insert-existing-loom-video');
		if (fg('quick_actions_click_timing_metrics')) {
			measureTimeOnClick('insert-existing-loom-video');
		}
		onInsertLoomLinkClick();
	}, [analyticsEvent, measureTimeOnClick, onInsertLoomLinkClick]);

	const videoIcon = fg('quick_actions_menu_ga') ? (
		<VideoPlayIcon color="currentColor" label="" spacing="spacious" />
	) : (
		<AttachmentIcon LEGACY_size="medium" spacing="spacious" label="" />
	);

	return (
		<DropdownItem
			key="loom-provision-button"
			elemBefore={
				!expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false) ? videoIcon : null
			}
			onClick={handleInsertLoomLink}
			testId="issue-view-foundation.quick-add.loom-insert-link-item-button.provision-button"
		>
			<Flex alignItems="center" gap="space.050">
				{formatMessage(label)}
			</Flex>
		</DropdownItem>
	);
};
