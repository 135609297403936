import React, { memo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { PlaybooksPanel } from '@atlassian/jira-playbooks-agent-view/src/ui/playbook-panel/PlaybooksPanel.tsx';
import type { MainIssueAggQueryRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import type { ViewPlaybooks$key } from '@atlassian/jira-relay/src/__generated__/ViewPlaybooks.graphql.ts';
import type { CloudId, ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';

export const ViewPlaybooks = ({
	rootRelayFragment,
	issueId,
	cloudId,
	projectKey,
}: {
	rootRelayFragment: MainIssueAggQueryRelayFragment;
	issueId: string;
	cloudId: CloudId;
	projectKey: ProjectKey;
}) => {
	const data = useFragment<ViewPlaybooks$key>(
		graphql`
			fragment ViewPlaybooks on JiraProject {
				isPlaybooksEnabled
			}
		`,
		rootRelayFragment?.jira?.issueByKey?.projectField?.project || null,
	);

	if (data?.isPlaybooksEnabled !== true) {
		return null;
	}

	return <PlaybooksPanel issueId={issueId} cloudId={cloudId} projectKey={projectKey} />;
};

export default memo(ViewPlaybooks);
