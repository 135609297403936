import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	enableDateFieldsTitle: {
		id: 'issue-date-fields-message.enable-date-fields-title',
		defaultMessage: 'Enable date fields',
		description:
			'Title of the section message in issue view telling the user to enable start and due date fields',
	},
	enableDateFieldsDescriptionAdmin: {
		id: 'issue-date-fields-message.enable-date-fields-description-admin',
		defaultMessage:
			'Enable start and due date fields so you can schedule work from the issue view.',
		description:
			'Description of the section message in issue view telling the user to enable start and due date fields if the user is not an admin',
	},
	enableDateFieldsDescriptionNonAdmin: {
		id: 'issue-date-fields-message.enable-date-fields-description-non-admin',
		defaultMessage:
			'Ask your admin to enable start and due date fields so you can schedule work from the issue view.',
		description:
			'Description of the section message in issue view telling the user to enable start and due date fields if the user is an admin',
	},
	configureFields: {
		id: 'issue-date-fields-message.configure-fields',
		defaultMessage: 'Configure fields',
		description: 'Link to the configure fields screen in project settings for this issue type',
	},
	enable: {
		id: 'issue-date-fields-message.enable',
		defaultMessage: 'Enable',
		description: 'Link to enable start and due date fields for TMP projects',
	},
	dismiss: {
		id: 'issue-date-fields-message.dismiss',
		defaultMessage: 'Dismiss',
		description: 'Link to dismiss the message telling the user to enable start and due date fields',
	},
	enableDateFieldsSuccessTitle: {
		id: 'issue-date-fields-message.enable-date-fields-success-title',
		defaultMessage: 'Success',
		description: 'Title of success flag when enabling date fields succeeds',
	},
	enableDateFieldsSuccessDescription: {
		id: 'issue-date-fields-message.enabling-date-fields-success-description',
		defaultMessage: "You've enabled start and due date fields in the issue view.",
		description: 'Description of success flag when enabling date fields succeeds',
	},
	enablingDateFieldsSpinnerLabel: {
		id: 'issue-date-fields-message.enabling-date-fields-spinner-label',
		defaultMessage: 'Loading',
		description: 'Label for spinner while enabling date fields',
	},
	enableDateFieldsFailedTitle: {
		id: 'issue-date-fields-message.enable-date-fields-failed-title',
		defaultMessage: "We couldn't enable date fields",
		description: 'Title of error flag when enabling date fields fails',
	},
	enableDateFieldsFailedDescription: {
		id: 'issue-date-fields-message.enable-date-fields-failed-description',
		defaultMessage: 'Refresh the page and try again.',
		description: 'Description of error flag when enabling date fields fails',
	},
	enableDateFieldsPartiallyFailedTitle: {
		id: 'issue-date-fields-message.enable-date-fields-partially-failed-title',
		defaultMessage: "We couldn't enable both fields",
		description:
			'Title of error flag when enabling date fields worked for one date field but not the other',
	},
	enableDateFieldsPartiallyFailedDescription: {
		id: 'issue-date-fields-message.enable-date-fields-partially-failed-description',
		defaultMessage:
			"The {successfulField} field was enabled, but the {failedField} field wasn't.\nContinue with the single field, or try again.",
		description:
			'Description of error flag when enabling date fields worked for one date field but not the other',
	},
	enableDateFieldsPartiallyFailedAction: {
		id: 'issue-date-fields-message.enable-date-fields-partially-failed-action',
		defaultMessage: 'Try again',
		description:
			'Action button to try associating the date field that failed to be associated on the previous try',
	},
	dismissMessageFailedTitle: {
		id: 'issue-date-fields-message.dismiss-message-failed-title',
		defaultMessage: "We couldn't save your changes",
		description: 'Title of error flag when dismissing the message fails',
	},
	dismissMessageFailedDescription: {
		id: 'issue-date-fields-message.dismiss-message-failed-description',
		defaultMessage: 'Refresh the page and try again.',
		description: 'Description of error flag when dismissing the message fails',
	},
});
