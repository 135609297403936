import type { IssueTypeId, ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import { performUpdateIssueType } from '../../services/jira/update-issue-type.tsx';
import type { IssueTypeModifiableProps, State } from '../types.tsx';

export const updateIssueType =
	(issueTypeId: IssueTypeId, projectId: ProjectId, update: IssueTypeModifiableProps) =>
	async ({ setState, getState }: StoreActionApi<State>) => {
		const state = getState();
		const currentIssueType = state.issueTypesById[issueTypeId];

		if (!currentIssueType) {
			return;
		}

		setState({
			issueTypesById: {
				...state.issueTypesById,
				[issueTypeId]: {
					...currentIssueType,
					...update,
				},
			},
		});

		try {
			await performUpdateIssueType(issueTypeId, projectId, {
				name: update.name,
				description: update.description,
				avatarId: Number(update.avatarId),
			});
		} catch (error) {
			logSafeErrorWithoutCustomerDataWrapper(
				'polaris.error.controllers.issue-types.actions.update-issue-type',
				'Failed to update issue type',
				error instanceof Error ? error : new Error('Error while updating issue type'),
			);

			setState(state);
			throw error;
		}
	};
