/* eslint-disable jira/integration/test-id-by-folder-structure */
import React from 'react';
import { styled } from '@compiled/react';
import isNil from 'lodash/isNil';
import FocusRing from '@atlaskit/focus-ring';
import { VerifiedTeamIcon } from '@atlaskit/people-teams-ui-public/verified-team-icon';
import { Text, Inline } from '@atlaskit/primitives';
// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
import { gridSize } from '@atlaskit/theme/constants';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import AsyncTeamProfileCardNext from '@atlassian/jira-profilecard-next/src/ui/team-profilecard/async.tsx';
import type { Team, TeamValue } from '../../common/types.tsx';
import messages from '../../messages.tsx';

export type Props = {
	defaultValue: TeamValue;
	placeholder: string;
	isEditable?: boolean;
	noPaddingLeft?: boolean;
};

const ErrorView = () => {
	const { formatMessage } = useIntl();

	return (
		<Tooltip content={formatMessage(messages.teamErrorViewTooltip)}>
			<Wrapper>
				<Text_ isDisabled data-testid="issue-field-team.ui.view-error-value-text">
					{formatMessage(messages.teamErrorViewText)}
				</Text_>
			</Wrapper>
		</Tooltip>
	);
};

export const TeamPickerView = (props: Props) => {
	const { defaultValue, isEditable, placeholder, noPaddingLeft } = props;
	if (isNil(defaultValue)) {
		return (
			<ReadViewContainerEmpty>
				<Text testId="issue-field-team.ui.view-empty-value-text" color="color.text.subtle">
					{placeholder}
				</Text>
			</ReadViewContainerEmpty>
		);
	}

	if (defaultValue.isVisible === false) {
		return <ErrorView />;
	}

	const team: Team = defaultValue;

	return (
		<Wrapper isEditable={isEditable} noPaddingLeft={noPaddingLeft}>
			<FocusRing isInset>
				<AsyncTeamProfileCardNext teamId={team.id} trigger="hover-click" position="left-start">
					<Inline alignBlock="center">
						<Text_ data-testid="issue-field-team.ui.view-team-name">{team.name}</Text_>
						{team.isVerified && fg('verified_team_in_team_picker_in_jira') && <VerifiedTeamIcon />}
					</Inline>
				</AsyncTeamProfileCardNext>
			</FocusRing>
		</Wrapper>
	);
};

export default TeamPickerView;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const ReadViewContainerEmpty = styled.div({
	display: 'flex',
	font: token('font.body'),
	maxWidth: '100%',
	paddingTop: token('space.075'),
	paddingRight: token('space.075'),
	paddingBottom: token('space.075'),
	paddingLeft: token('space.075'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minHeight: `${gridSize() * 2}px`,
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles
const Text_ = styled.span<{ isDisabled?: boolean }>((props) => ({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '20px',
	textAlign: 'left',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: props.isDisabled ? token('color.text.disabled') : token('color.text'),
	alignItems: 'center',
	display: 'flex',
	padding: `${token('space.025')} 0`,
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const Wrapper = styled.div<{ isEditable?: boolean; noPaddingLeft?: boolean }>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	(props) => ({
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		cursor: props.isEditable ? 'pointer' : 'default',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		paddingLeft: props.noPaddingLeft ? undefined : token('space.075'),
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'> a': {
			display: 'flex',
			alignItems: 'center',
		},
	}),
);
