import { useEffect, useState } from 'react';
import { useUserHasConfluenceAccess } from '@atlassian/jira-confluence-integration-controls/src/controllers/use-user-has-confluence-access/index.tsx';

// upon successful crossflow, the window is reloaded with href should have spaceKey as a query param
export const useConfluenceAccess = () => {
	const [isConfluenceRecentlyProvisioned, setIsConfluenceRecentlyProvisioned] = useState(false);
	const [isConfluenceProvisioningStarted, setIsConfluenceProvisioningStarted] = useState(false);

	const { refetchHasAccess, hasAccess } = useUserHasConfluenceAccess();

	const onProvisioningStarted = () => setIsConfluenceProvisioningStarted(true);

	useEffect(() => {
		const url = new URL(globalThis.window.location.href);
		const spaceKey = url.searchParams.get('spaceKey');

		if (spaceKey) {
			url.searchParams.delete('spaceKey');
			globalThis.window.history.replaceState({}, '', url);
			refetchHasAccess();
			setIsConfluenceRecentlyProvisioned(true);
		}
	}, [refetchHasAccess]);

	return {
		isConfluenceRecentlyProvisioned,
		isConfluenceProvisioningStarted,
		hasConfluenceAccess: hasAccess,
		onProvisioningStarted,
	};
};
