import React, { type ComponentType, useEffect, useCallback, useState } from 'react';
import { useChildPanelRenderTrigger } from '@atlassian/jira-issue-view-common-views/src/child-issues-panel/hooks/child-panel-render-trigger/index.tsx';
import {
	CLASSIC_PORTFOLIO_CHILDREN,
	CLASSIC_SUBTASKS,
	CHILDREN_ISSUES_PANEL,
} from '@atlassian/jira-issue-view-common-views/src/child-issues-panel/model/types.tsx';
import { useTriggerIssueCreateModal } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/main.tsx';
import type { Props } from '../types.tsx';
import type { RenderChildComponentType } from './types.tsx';

/**
 * Add GIC properties into content-items-view in order to manage rendered components
 */
export const withGICHook =
	(ContentItemsComponent: ComponentType<Props> | React.FC<Props>) => (props: Props) => {
		const [{ isModalOpen, callbackPayload }] = useTriggerIssueCreateModal();

		const { childIssuesPanelType } = callbackPayload?.data || {};

		const getRenderComponentValue = useCallback(() => {
			if (!isModalOpen) return false;
			switch (childIssuesPanelType) {
				case CHILDREN_ISSUES_PANEL:
				case CLASSIC_SUBTASKS:
				case CLASSIC_PORTFOLIO_CHILDREN:
					return childIssuesPanelType;
				default:
					return false;
			}
		}, [childIssuesPanelType, isModalOpen]);

		const [renderChildComponent, setRenderChildComponent] =
			useState<RenderChildComponentType>(getRenderComponentValue());

		useEffect(() => {
			const newRenderChildValue = getRenderComponentValue();
			if (newRenderChildValue !== false) {
				setRenderChildComponent(newRenderChildValue);
			}
		}, [getRenderComponentValue]);

		const [{ whichChildPanelRenderTriggered }] = useChildPanelRenderTrigger();

		return (
			<ContentItemsComponent
				{...props}
				shouldRenderSubtasks={
					props.shouldRenderSubtasks ||
					renderChildComponent === CLASSIC_SUBTASKS ||
					whichChildPanelRenderTriggered === CLASSIC_SUBTASKS
				}
				shouldRenderChildrenIssues={
					props.shouldRenderChildrenIssues ||
					renderChildComponent === CHILDREN_ISSUES_PANEL ||
					whichChildPanelRenderTriggered === CHILDREN_ISSUES_PANEL
				}
				shouldRenderPortfolioChildrenIssues={
					props.shouldRenderPortfolioChildrenIssues ||
					renderChildComponent === CLASSIC_PORTFOLIO_CHILDREN
				}
			/>
		);
	};
