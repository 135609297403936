import React from 'react';
import { graphql, useFragment } from 'react-relay';
import type { AssignToMeProps } from '@atlassian/jira-issue-field-assignee-assign-to-me/src/ui/assign-to-me/types.tsx';
import type { AssigneeInlineEditViewBaseProps } from '@atlassian/jira-issue-field-assignee-inline-edit-full/src/ui/assignee/types.tsx';
import type { AssignToMeInlineEdit$key } from '@atlassian/jira-relay/src/__generated__/AssignToMeInlineEdit.graphql';
import type { AssignToMeInlineEdit_fragmentKey$key } from '@atlassian/jira-relay/src/__generated__/AssignToMeInlineEdit_fragmentKey.graphql';
import { AssignToMeInlineEditInner } from './AssignToMeInlineEditInner.tsx';

export type AssignToMeInlineEditBase = Omit<AssignToMeProps, 'onClick'> &
	Pick<AssigneeInlineEditViewBaseProps, 'onSubmit' | 'onSubmitFailed' | 'onSubmitSucceeded'>;

export type AssignToMeInlineEditProps = AssignToMeInlineEditBase & {
	fragmentKey: AssignToMeInlineEdit_fragmentKey$key;
	rootRelayFragment: AssignToMeInlineEdit$key | null;
};

export const AssignToMeInlineEdit = ({
	fragmentKey,
	rootRelayFragment,
	...props
}: AssignToMeInlineEditProps) => {
	const data = useFragment<AssignToMeInlineEdit$key>(
		graphql`
			fragment AssignToMeInlineEdit on Query {
				...AssignToMeInlineEditInner_rootFragmentKey
			}
		`,
		rootRelayFragment,
	);

	const fieldData = useFragment<AssignToMeInlineEdit_fragmentKey$key>(
		graphql`
			fragment AssignToMeInlineEdit_fragmentKey on JiraSingleSelectUserPickerField {
				...AssignToMeInlineEditInner
			}
		`,
		fragmentKey,
	);

	return <AssignToMeInlineEditInner {...props} fragmentKey={fieldData} rootFragmentKey={data} />;
};
