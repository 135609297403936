import { createSelector } from 'reselect';
import { fg } from '@atlassian/jira-feature-gating';
import { CHILDREN_ISSUES, SUBTASKS } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import {
	hasChildrenIssuesSelector,
	hasChildrenIssueValuesSelector,
	hasSubtasksSelector,
	hasSubtasksValuesSelector,
	hasPortfolioChildIssuesSelector,
	hasPortfolioChildIssueValuesSelector,
	childIssuesLimitUrlSelector,
} from '../common/state/selectors/field-selector.tsx';
import { uiSelector } from '../common/state/selectors/issue-selector.tsx';
import { isIssueBaseLevel } from '../issue-field/state/selectors/hierarchy-level-selector.tsx';

const childPanelSelector = createSelector(uiSelector, (ui) => ui.childPanel);

export const subtaskQuickAddClickCountSelector = createSelector(
	childPanelSelector,
	(childPanel) => childPanel.subtaskQuickAddClickCount,
);

export const issueInEpicQuickAddClickCountSelector = createSelector(
	childPanelSelector,
	(childPanel) => childPanel.issueInEpicQuickAddClickCount,
);

export const childIssueQuickAddClickCountSelector = createSelector(
	childPanelSelector,
	(childPanel) => childPanel.childIssueQuickAddClickCount,
);

export const triggeredViaHotKeySelector = createSelector(
	childPanelSelector,
	(childPanel) => childPanel.triggeredViaHotKey,
);

/**
 * We should render the particular create issues UI if there are already some sort of sub-issues (i.e. the field is non-empty) or the field is empty but the user has clicked create issue
 * If childIssueLimitUrl for the respective panel is present, we will also render the panel even though the values in the field will be empty, since we want to show the new child issue limit panel
 */
const renderChildIssuesPanelFunc = (
	doesFieldExistOnIssue: boolean,
	doesFieldHaveValues: boolean,
	createIssueClickCount: number,
	childIssueLimitUrl: (arg1: string) => string,
): boolean =>
	Boolean(childIssueLimitUrl(CHILDREN_ISSUES)) ||
	doesFieldHaveValues ||
	(doesFieldExistOnIssue && createIssueClickCount > 0);

/**
 * We should render the particular create issues UI if there are already some sort of sub-issues (i.e. the field is non-empty) or the field is empty but the user has clicked create issue
 * If childIssueLimitUrl for the respective panel is present, we will also render the panel even though the values in the field will be empty, since we want to show the new child issue limit panel
 */
const renderSubtasksPanelFunc = (
	doesFieldExistOnIssue: boolean,
	doesFieldHaveValues: boolean,
	createIssueClickCount: number,
	childIssueLimitUrl: (arg1: string) => string,
	isBaseLevel?: boolean,
): boolean => {
	// Display subtasks only under base-level work items
	// See https://hello.atlassian.net/wiki/spaces/JIE/pages/4950810256
	if (isBaseLevel === false && fg('jira-issue-view-child-issues-panel-merge-subtasks')) {
		return false;
	}

	return (
		Boolean(childIssueLimitUrl(SUBTASKS)) ||
		doesFieldHaveValues ||
		(doesFieldExistOnIssue && createIssueClickCount > 0)
	);
};

export const shouldRenderChildPanelSelector = createSelector(
	hasChildrenIssuesSelector,
	hasChildrenIssueValuesSelector,
	childIssueQuickAddClickCountSelector,
	childIssuesLimitUrlSelector,
	renderChildIssuesPanelFunc,
);

export const shouldRenderSubtaskPanelSelector = createSelector(
	hasSubtasksSelector,
	hasSubtasksValuesSelector,
	subtaskQuickAddClickCountSelector,
	childIssuesLimitUrlSelector,
	isIssueBaseLevel,
	renderSubtasksPanelFunc,
);

// portfolio child panel uses the shouldRenderCreateSubtasksSelector
// because when its over the child issue limit (100), the field will be
// switched to subtasks field. Anything under or equal the limit, will still
// use the portfolio issue field
export const shouldRenderPortfolioChildPanelSelector = createSelector(
	hasPortfolioChildIssuesSelector,
	hasPortfolioChildIssueValuesSelector,
	childIssueQuickAddClickCountSelector,
	childIssuesLimitUrlSelector,
	renderSubtasksPanelFunc,
);

const renderHasMultiplePanelFunc = (
	shouldRenderSubtaskPanel: boolean,
	shouldRenderChildPanel: boolean,
	shouldRenderPortfolioChildPanel: boolean,
): boolean =>
	shouldRenderSubtaskPanel && (shouldRenderChildPanel || shouldRenderPortfolioChildPanel);

export const hasMultipleChildPanelsSelector = createSelector(
	shouldRenderSubtaskPanelSelector,
	shouldRenderChildPanelSelector,
	shouldRenderPortfolioChildPanelSelector,
	renderHasMultiplePanelFunc,
);
