import { styled } from '@compiled/react';
import { SkeletonCompiled } from './styled.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Fill = styled(SkeletonCompiled)({
	width: '100%',
	height: '100%',
	display: 'inline-block',
	'&::after': {
		content: "'\\a0'",
	},
});

export default Fill;
