import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import type { DocNode } from '@atlaskit/adf-schema';
import { defaultSchema } from '@atlaskit/adf-schema/schema-default';
import { ReactRenderer } from '@atlaskit/renderer';
import { token } from '@atlaskit/tokens';
import { getUnsupportedContentLevelsTracking } from '@atlassian/jira-common-util-unsupported-content/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	SEVERITY_DEGRADED_THRESHOLD,
	SEVERITY_NORMAL_THRESHOLD,
} from '@atlassian/jira-issue-view-common-constants/src/index.tsx';
import {
	onLinkClick,
	smartLinksDefault,
} from '@atlassian/jira-linking-platform-utils/src/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';
import { getADF } from './utils.tsx';

const MessageView = (props: Props) => {
	const { formatMessage } = useIntl();
	const { value } = props;
	const adfDocument = useMemo(() => value !== null && value !== '' && getADF(value), [value]);

	// handle empty message
	if (value === '' || value === null) {
		return <EmptyValue>{formatMessage(messages.emptyMessage)}</EmptyValue>;
	}

	return (
		<ReactRenderer
			allowWrapCodeBlock
			allowCopyToClipboard
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			document={adfDocument as DocNode}
			schema={defaultSchema}
			disableHeadingIDs
			analyticsEventSeverityTracking={{
				enabled: true,
				severityDegradedThreshold: SEVERITY_DEGRADED_THRESHOLD,
				severityNormalThreshold: SEVERITY_NORMAL_THRESHOLD,
			}}
			unsupportedContentLevelsTracking={getUnsupportedContentLevelsTracking()}
			useSpecBasedValidator
			featureFlags={{
				codeBidiWarnings: true,
				'code-bidi-warnings': true,
				// enables sending analytics event with renderer specific tti measurement+
				// please do not clean up, this feature flag is meant to be rolled out permanently just for a fraction of users
				'renderer-tti-tracking': fg('renderer_tti_tracking_jira'),
			}}
			eventHandlers={{
				smartCard: { onClick: onLinkClick },
				link: { onClick: onLinkClick },
			}}
			smartLinks={smartLinksDefault}
		/>
	);
};

export default MessageView;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyValue = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest'),
});
