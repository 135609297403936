// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook, createStore } from '@atlassian/react-sweet-state';
import { actions, type Actions } from './actions/index.tsx';
import { getSignature } from './selectors/index.tsx';
import type { State } from './types.tsx';

const initialState: State = {
	signature: undefined,
};

export const store = createStore<State, Actions>({
	initialState,
	actions,
	name: 'CannedResponseCommonStore',
});

export const useSignature = createHook(store, {
	selector: getSignature,
});
