import { v4 as uuid } from 'uuid';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { createTemplate, setIssueProperties } from '../../services/set-recurring.tsx';
import { SOFTWARE_END_USER_TEMPLATE_2 } from '../../common/constants.tsx';
import type { RecurOnScheduleConfig, RuleConfig } from '../../common/types.tsx';
import type { StoreContainerProps, State } from '../types.tsx';
import { callServiceWithAnalytics } from '../../common/utils.tsx';

export type SetAsRecurringIssueParams = {
	recurOnScheduleConfig: RecurOnScheduleConfig;
};

export const setAsRecurringIssue =
	({
		recurOnScheduleConfig: { rRule, recurCondition, dueDateOffsetSmartValue },
	}: SetAsRecurringIssueParams): Action<State, StoreContainerProps> =>
	async (
		{ setState, getState },
		{ createAnalyticsEvent, issueKey, environment, cloudId, projectId },
	) => {
		const { errors, isUpdatingRule } = getState();
		setState({
			isCreatingRule: true,
		});

		const recurrenceToken = uuid();
		const templateId = SOFTWARE_END_USER_TEMPLATE_2;
		const analyticsEvent = createAnalyticsEvent({});

		try {
			const createTemplateResponse = await callServiceWithAnalytics(
				analyticsEvent,
				createTemplate({
					environment,
					cloudId,
					projectId,
					recurrenceToken,
					recurCondition,
					rRule,
					dueDateOffsetSmartValue,
				}),
				'createTemplate',
			);
			if (createTemplateResponse?.ruleUuid) {
				await callServiceWithAnalytics(
					analyticsEvent,
					setIssueProperties({
						issueKey,
						recurrenceToken,
						ruleUuid: createTemplateResponse.ruleUuid,
						recurCondition,
						rRule,
						templateId,
						dueDateOffsetSmartValue,
					}),
					'setIssueProperties',
				);

				fireTrackAnalytics(analyticsEvent, 'recurringRule created', {
					// TODO: add ruleId attribute
					issueKey,
					ruleUuid: createTemplateResponse.ruleUuid,
					templateId,
					recurCondition,
					isUpdateForExistingRule: isUpdatingRule,
				});

				const issueProperties: RuleConfig = {
					automationRuleUuid: createTemplateResponse.ruleUuid,
					token: recurrenceToken,
					templateId,
					recurOnScheduleConfig: {
						rRule,
						dueDateOffsetSmartValue,
						recurCondition,
					},
				};

				setState({
					isCreatingRule: false,
					issueProperties,
					isRuleOwner: true,
				});
			} else {
				throw new Error('New rule failed to be created.');
			}
		} catch (error: unknown) {
			setState({
				isCreatingRule: false,
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				errors: [...(errors ?? []), error as Error],
			});
			throw error;
		}
	};
