import React, { Component, type ComponentType, createRef, type SyntheticEvent } from 'react';
import { styled as styled2 } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { ModalBody } from '@atlaskit/modal-dialog';
import Tabs, { useTabPanel } from '@atlaskit/tabs';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import {
	StopMetric,
	CancelMetric,
} from '@atlassian/jira-development-common/src/performance-analytics/view.tsx';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import { JiraModal as ModalDialog } from '@atlassian/jira-modal/src/ui/jira-modal.tsx';
import type { Locale } from '@atlassian/jira-platform-utils-date-fns/src/types.tsx';
import {
	fireUIAnalytics,
	FireScreenAnalytics,
	ContextualAnalyticsData,
	MODAL,
	FireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import { devDetailsTimeToLoad } from '../../common/analytics/performance-analytics.tsx';
import type { PanelDataTypes, HeaderProps, Tab } from '../model/types.tsx';
import { RepoPromptForGithubBannerContainer } from '../state/selectors/repo-prompt-for-github-banner/index.tsx';

type Props = {
	readonly issueId: string;
	readonly issueKey: string;
	isError: boolean;
	isLoading: boolean;
} & Intl & {
		readonly baseUrl: string;
		readonly Header: ComponentType<HeaderProps>;
		readonly locale: Locale;
		readonly activeTab: PanelDataTypes;
		tabs: Tab[];
		readonly onCloseDialog: () => void;
		readonly onChangeTab: (type: PanelDataTypes) => void;
	};

export const ModalBodyTabPanel = ({ children }: { children: React.ReactNode }) => {
	const tabPanelAttributes = useTabPanel();

	return (
		<TabPanel {...tabPanelAttributes}>
			<ModalBody>{children}</ModalBody>
		</TabPanel>
	);
};
// eslint-disable-next-line jira/react/no-class-components
export class Details extends Component<Props> {
	componentDidUpdate(prevProps: Props) {
		const { activeTab: prevActiveTab } = prevProps;
		const { activeTab } = this.props;
		if (this.bodyRef.current && activeTab !== prevActiveTab) {
			this.bodyRef.current.scrollTop = 0;
		}
	}

	bodyRef = createRef<HTMLDivElement>();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onClose = (event: SyntheticEvent<any>, analyticsEvent: UIAnalyticsEvent) => {
		const { onCloseDialog } = this.props;
		onCloseDialog();
		fireUIAnalytics(analyticsEvent, 'closeDevPanelDetailsDialog');

		devDetailsTimeToLoad.cancel();
	};

	renderHeader = () => {
		const { intl, issueKey, Header, tabs, activeTab, onChangeTab, issueId } = this.props;
		return (
			<Header
				intl={intl}
				issueId={issueId}
				issueKey={issueKey}
				tabs={tabs}
				activeTab={activeTab}
				onChangeTab={onChangeTab}
				onClose={this.onClose}
			/>
		);
	};

	renderTabContent() {
		const { issueId, issueKey, baseUrl, locale, tabs } = this.props;
		return tabs.map(({ TabContent, ...tab }) => (
			<ModalBodyTabPanel key={tab.type}>
				<TabContent issueId={issueId} issueKey={issueKey} baseUrl={baseUrl} locale={locale} />
			</ModalBodyTabPanel>
		));
	}

	renderMetricsElements() {
		if (this.props.isLoading) {
			return [];
		}
		if (this.props.isError) {
			return (
				<>
					<FireOperationalAnalytics
						eventName="developmentDetailsDialog failed"
						attributes={{
							errorType: 'serverError',
						}}
					/>
					<CancelMetric metric={devDetailsTimeToLoad} />
				</>
			);
		}

		return (
			<>
				<FireOperationalAnalytics eventName="developmentDetailsDialog rendered" />
				<StopMetric metric={devDetailsTimeToLoad} />
			</>
		);
	}

	onChangeTab = (selectedIndex: number) => {
		const { activeTab, tabs } = this.props;
		const currentIndex = tabs.findIndex((tab) => tab.type === activeTab);
		if (currentIndex !== selectedIndex) {
			const { type } = tabs[selectedIndex];
			this.props.onChangeTab(type);
		}
	};

	render() {
		const { activeTab, tabs } = this.props;

		return (
			<ContextualAnalyticsData sourceName="devPanelDetailsDialog" sourceType={MODAL}>
				<ShortcutScope>
					<RepoPromptForGithubBannerContainer>
						<ModalDialog
							// @ts-expect-error - TS2769 - No overload matches this call.
							onClose={this.onClose}
							width="x-large"
							height="800px"
							testId="development-details.main.modal-dialog"
							autoFocus={false}
							messageId="development-details.main.modal-dialog"
							messageType="transactional"
						>
							{/**
							 * Its critical the first child in the Tabs object contains the Tab options
							 * and the subsequent children each represent panels at the same indexed order to the tab items
							 * <Tabs>
							 *   <TabRow>
							 *     <Tab>A</Tab>
							 *     <Tab>B</Tab>
							 *   </TabRow>
							 *   <TabPanel>A</TabPanel>
							 *   <TabPanel>B</TabPanel>
							 */}
							<Tabs
								id="dev-details-dialog-tab"
								selected={tabs.findIndex((tab) => tab.type === activeTab)}
								onChange={this.onChangeTab}
								// we only need this if things currently are doing things on mount.
								shouldUnmountTabPanelOnChange
							>
								{this.renderHeader()}
								{this.renderTabContent()}
							</Tabs>
							{this.renderMetricsElements()}
							<FireScreenAnalytics attributes={{ activeTab }} />
						</ModalDialog>
					</RepoPromptForGithubBannerContainer>
				</ShortcutScope>
			</ContextualAnalyticsData>
		);
	}
}

export default injectIntl(Details);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TabPanel = styled2.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&&': {
		padding: '0px',
	},
	flexDirection: 'column',
});
