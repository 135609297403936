import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import type { FooterProps } from '../../../types/commands/index.tsx';
import { FOOTER_TEST_ID } from './constants.tsx';

export const Footer = ({ defaultFooterContent, children }: FooterProps) => {
	const footerChildren = children || defaultFooterContent;

	if (!footerChildren) return null;

	return <ModalFooter data-testid={FOOTER_TEST_ID}>{footerChildren}</ModalFooter>;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ModalFooter = styled.div({
	borderBottomLeftRadius: 'inherit',
	borderBottomRightRadius: 'inherit',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderTop: `1px solid ${token('color.border')}`,
	paddingTop: token('space.150'),
	paddingRight: token('space.200'),
	paddingBottom: token('space.150'),
	paddingLeft: token('space.200'),
});
