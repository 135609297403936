import React, { type ReactNode, useMemo, useCallback, type SyntheticEvent } from 'react';
import isNil from 'lodash/isNil';
import EditorCloseIcon from '@atlaskit/icon/core/migration/close--editor-close';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import ShortcutIcon from '@atlaskit/icon/core/migration/link-external--shortcut';
import LockFilledIcon from '@atlaskit/icon/utility/migration/lock-locked--lock-filled';
import { token } from '@atlaskit/tokens';
import AKTooltip from '@atlaskit/tooltip';
import AsyncIcon from '@atlassian/jira-common-components-async-icon/src/view.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	APPLINK_REQ_AUTH,
	APPLINK_MISSING,
} from '@atlassian/jira-issue-view-common-constants/src/remote-link-error-type.tsx';
import { FormattedReactMessage } from '@atlassian/jira-project-settings-apps-common/src/i18n/formatted-react-message.tsx';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { LineCardHiddenButton } from '../../../line-card-hidden-button/index.tsx';
import messages from './messages.tsx';
import {
	ErrorIconContainer,
	Key,
	ErrorText,
	Card,
	ShortCutIconContainer,
	FlexContainer,
	AuthActionText,
	AuthErrorTextInner,
} from './styled.tsx';
import type { Props } from './type.tsx';

const authSummaryLinkStartTag = '{tagStart}';
const authSummaryLinkEndTag = '{tagEnd}';

export const RemoteIssueLineCardError = ({
	issueKey,
	isHovering,
	isFocused,
	issueLink,
	issueTypeIconUrl,
	issueTypeName,
	canRemove,
	globalId,
	remoteServerHostname,
	error,
	handleUserClickAnalytics,
	onDelete,
}: Props) => {
	const { formatMessage } = useIntl();

	const renderedIcon = useMemo(() => {
		if (error === APPLINK_REQ_AUTH) {
			const title = isNil(issueTypeName)
				? issueTypeName
				: formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.issueTypeTitleIssueTermRefresh
							: messages.issueTypeTitle,
						{ issueTypeName },
					);
			return (
				<AKTooltip content={issueTypeName || ''} position="bottom">
					<FlexContainer>
						<AsyncIcon alt={title} url={issueTypeIconUrl} />
					</FlexContainer>
				</AKTooltip>
			);
		}
		return (
			<ErrorIconContainer>
				<ErrorIcon label="Issue link loading error" color={token('color.icon.danger')} />
			</ErrorIconContainer>
		);
	}, [error, formatMessage, issueTypeIconUrl, issueTypeName]);

	const renderedText = useMemo(() => {
		switch (error) {
			case APPLINK_REQ_AUTH:
				return (
					// @ts-expect-error - TS2741 - Property 'color' is missing in type '{ children: Element; }' but required in type 'Readonly<ThemedOuterStyledProps<ClassAttributes<HTMLDivElement> & HTMLAttributes<HTMLDivElement> & { color: string; }, any>>'.
					<ErrorText>
						<AKTooltip
							content={formatMessage(messages.remoteLinkAuthTooltip, {
								remoteServerHostname,
							})}
							position="bottom"
						>
							<AuthErrorTextInner>
								<LockFilledIcon label="" LEGACY_size="small" color={token('color.icon.brand')} />
								<FormattedReactMessage
									message={formatMessage(messages.remoteLinkAuthSummary, {
										tagStart: authSummaryLinkStartTag,
										tagEnd: authSummaryLinkEndTag,
									})}
									openingTag={authSummaryLinkStartTag}
									closingTag={authSummaryLinkEndTag}
									Component={(linkProps: { children: ReactNode }) => (
										<AuthActionText
											onClick={() => {
												handleUserClickAnalytics('errorCard.authenticationLink');
											}}
										>
											{linkProps.children}
										</AuthActionText>
									)}
								/>
							</AuthErrorTextInner>
						</AKTooltip>
					</ErrorText>
				);
			case APPLINK_MISSING:
				return (
					// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
					<ErrorText color="red">
						{formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.remoteLinkMissingApplinkIssueTermRefresh
								: messages.remoteLinkMissingApplink,
						)}
					</ErrorText>
				);
			default:
				return (
					// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
					<ErrorText color="red">
						{formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.remoteLinkGeneralErrorIssueTermRefresh
								: messages.remoteLinkGeneralError,
						)}
					</ErrorText>
				);
		}
	}, [error, formatMessage, handleUserClickAnalytics, remoteServerHostname]);

	const openIssueLink = useCallback(
		(event: SyntheticEvent) => {
			if (issueLink !== undefined) {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.open(issueLink, '_blank');
			}
			handleUserClickAnalytics('errorCard.linkIcon');
			event.stopPropagation();
		},
		[handleUserClickAnalytics, issueLink],
	);

	const renderedLinkButton = useMemo(() => {
		const buttonIcon = (
			<AKTooltip content={formatMessage(messages.remoteLinkOpenLinkTooltip)} position="bottom">
				<ShortCutIconContainer>
					<ShortcutIcon color="currentColor" label="Open remote issue" LEGACY_size="small" />
				</ShortCutIconContainer>
			</AKTooltip>
		);
		return (
			<LineCardHiddenButton
				show={isHovering || isFocused}
				icon={buttonIcon}
				onClick={openIssueLink}
			/>
		);
	}, [formatMessage, isFocused, isHovering, openIssueLink]);

	const handleDelete = useCallback(
		(event: SyntheticEvent) => {
			// Check issueKey or globalId for flowType
			if (issueKey != null && globalId != null) {
				onDelete && onDelete(toIssueKey(issueKey), globalId);
			}
			event.stopPropagation();
		},
		[globalId, issueKey, onDelete],
	);

	const renderedDeleteButton = useMemo(() => {
		if (!canRemove || !onDelete) {
			return null;
		}

		return (
			<LineCardHiddenButton
				show={isHovering || isFocused}
				icon={
					<AKTooltip
						content={formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.remoteLinkDeleteLinkTooltipIssueTermRefresh
								: messages.remoteLinkDeleteLinkTooltip,
						)}
						position="bottom"
					>
						<EditorCloseIcon color="currentColor" label="Remove issue link" />
					</AKTooltip>
				}
				onClick={handleDelete}
			/>
		);
	}, [canRemove, formatMessage, handleDelete, isFocused, isHovering, onDelete]);

	const isAuthenticationError = error === APPLINK_REQ_AUTH || error === APPLINK_MISSING;
	return (
		<Card>
			{renderedIcon}
			{/* renderKey translated to inline component  */}
			{!isAuthenticationError && (
				<Key onClick={() => handleUserClickAnalytics('errorCard.issueKey')}>{issueKey}</Key>
			)}
			{renderedText}
			{!isAuthenticationError && renderedLinkButton}
			{!isAuthenticationError && renderedDeleteButton}
		</Card>
	);
};

export default RemoteIssueLineCardError;
