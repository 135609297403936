import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { type ColorValues, type DisabledColorConfig } from '../../common/constants';

type ContainerProps = {
	isIconOnly: boolean;
	isPopupDisabled: boolean;
	colorsConfig: ColorValues;
	disabledColorConfig: DisabledColorConfig;
	spacing: 'default' | 'expanded';
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const ContainerButton = styled.button<ContainerProps>((props) => ({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: props.isIconOnly ? undefined : props.spacing === 'expanded' ? '24px' : '20px',
	display: 'inline-flex',
	maxWidth: '100%',
	whiteSpace: 'nowrap',
	width: 'auto',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	padding: token('space.050', '4px'),
	gap: token('space.050', '4px'),
	border: 'none',
	borderRadius: token('border.radius', '3px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: props.colorsConfig.fontColor,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	background: props.colorsConfig.bgColor,
	cursor: 'pointer',
	outline: 0,
	transition: 'background 0.1s ease-out 0s',
	'&:focus-visible': {
		outline: `1px solid ${token('color.border.focused', '#388BFF')}`,
		outlineOffset: token('space.025', '2px'),
		background:
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			props.isPopupDisabled || props.isIconOnly
				? props.colorsConfig.bgColor
				: props.colorsConfig.bgColorFocused,
	},
	'&:hover': {
		background:
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			props.isPopupDisabled || props.isIconOnly
				? props.colorsConfig.bgColor
				: props.colorsConfig.bgColorHovered,
	},
	'&:active': {
		background:
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			props.isPopupDisabled || props.isIconOnly
				? props.colorsConfig.bgColor
				: props.colorsConfig.bgColorPressed,
	},
	'&:disabled': {
		cursor: 'default',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		color: props.disabledColorConfig.fontColor,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		background: props.disabledColorConfig.bgColor,
	},
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const ContainerSpan = styled.span<ContainerProps>((props) => ({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: props.isIconOnly ? undefined : props.spacing === 'expanded' ? '24px' : '20px',
	display: 'inline-flex',
	maxWidth: '100%',
	whiteSpace: 'nowrap',
	width: 'auto',
	alignItems: 'center',
	padding: token('space.050', '4px'),
	gap: token('space.050', '4px'),
	borderRadius: token('border.radius', '3px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: props.colorsConfig.fontColor,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	background: props.colorsConfig.bgColor,
	userSelect: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&.disabled': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		color: props.disabledColorConfig.fontColor,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		background: props.disabledColorConfig.bgColor,
	},
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Name = styled.span({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography -- Ignored via go/DSP-18766
	fontSize: '12px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography -- Ignored via go/DSP-18766
	lineHeight: '12px',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});
