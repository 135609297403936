/**
 * @generated SignedSource<<f5de00b761b647da740afbd181b70435>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type quickAddItemsCompact$data = {
  readonly userPreferences: {
    readonly isIssueViewCrossFlowBannerDismissed: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "quickAddItemsCompact";
};
export type quickAddItemsCompact$key = {
  readonly " $data"?: quickAddItemsCompact$data;
  readonly " $fragmentSpreads": FragmentRefs<"quickAddItemsCompact">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    }
  ],
  "kind": "Fragment",
  "name": "quickAddItemsCompact",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "cloudId",
          "variableName": "cloudId"
        }
      ],
      "concreteType": "JiraUserPreferences",
      "kind": "LinkedField",
      "name": "userPreferences",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isIssueViewCrossFlowBannerDismissed"
        }
      ]
    }
  ],
  "type": "JiraQuery"
};

(node as any).hash = "c0e21f0e06ed0f57b523c6143c039b93";

export default node;
