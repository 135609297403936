import isEqual from 'lodash/isEqual';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { State } from '../../types.tsx';
import type { SetFieldCacheProps } from './types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { SetFieldCacheProps } from './types';

export const setFieldCache =
	({ fieldKey, projectKey, prefetched, recentlySelected }: SetFieldCacheProps): Action<State> =>
	({ setState, getState }) => {
		const fieldVal = getState()[fieldKey];
		const projectVal = fieldVal[projectKey];

		const newFieldVal = {
			...fieldVal,
			[projectKey]: {
				...projectVal,
				...(prefetched ? { prefetched } : {}),
				...(recentlySelected ? { recentlySelected } : {}),
			},
		};

		if (isEqual(fieldVal, newFieldVal)) {
			return;
		}

		setState({ [fieldKey]: newFieldVal });
	};
