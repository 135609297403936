/** @jsx jsx */

import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { css, jsx } from '@compiled/react';
import noop from 'lodash/noop';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/utility/migration/chevron-up';
import { token } from '@atlaskit/tokens';
import { Inline, xcss, Box } from '@atlaskit/primitives';
import { useStickyHeaderRegistration } from '@atlassian/jira-issue-header-tracker-service/src/services/sticky-header/index.tsx';
import { issueViewLayers as layers } from '@atlassian/jira-issue-view-layers/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { HeaderComponentProps, Props, RequiredComponents } from '../../types.tsx';
import BaseGroup from '../base-group/index.tsx';
import { defaultComponents } from '../styled.tsx';

const HEADER_TITLE_DATA_TEST_ID =
	'issue-view-layout-group.common.ui.collapsible-group-factory.title';

const HeaderComponent = ({
	id,
	isOpen,
	subTitle,
	title,
	onToggle,
	renderHeaderActions,
	stickyHeaderPosition,
	componentsMerged,
	innerRef,
	enableVisualRefreshForTitle,
	containerElement,
}: HeaderComponentProps) => {
	const { Title, SubTitle, HeaderRightAligned } = componentsMerged;
	const testId =
		id === null || id === undefined
			? undefined
			: `issue-view-layout-group.common.ui.collapsible-group-factory.${id}`;

	const headerActions = useMemo(() => renderHeaderActions?.(), [renderHeaderActions]);
	const [hasScrolled, setHasScrolled] = useState(false);
	const currentStickyHeaderPosition = useMemo(() => {
		if (!hasScrolled) {
			return 0;
		}
		return stickyHeaderPosition ? `${stickyHeaderPosition}px` : 0;
	}, [hasScrolled, stickyHeaderPosition]);

	const handleScroll = useCallback(() => {
		// hasScrolled only needs to be set once after initial load
		if (!hasScrolled) {
			setHasScrolled(true);
		}

		// remove event listener immediately on first run
		containerElement?.removeEventListener('scroll', noop);
	}, [hasScrolled, containerElement]);

	if (fg('sticky-css-iv-llc-context-headings')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			// if containerElement prop is not passed down, revert to default behaviour of dynamically setting top offset immediately
			if (containerElement === undefined) {
				handleScroll();
			}

			if (typeof document !== 'undefined') {
				// if containerElement exists, conditionally set top offset only when scrolled
				if (containerElement) {
					containerElement.addEventListener('scroll', handleScroll);
				}
			}
			return () => {
				containerElement?.removeEventListener('scroll', handleScroll);
			};
		}, [containerElement, handleScroll]);
	}

	return (
		<summary
			data-testid={testId}
			css={[summaryHeaderStyles]}
			// eslint-disable-next-line jira/react/no-style-attribute
			style={{
				// temporarily using ternary statement to show old/new code path
				// eslint-disable-next-line no-nested-ternary
				top: fg('sticky-css-iv-llc-context-headings')
					? currentStickyHeaderPosition
					: stickyHeaderPosition
						? `${stickyHeaderPosition}px`
						: 0,
			}}
			ref={innerRef}
			tabIndex={0}
			onClick={(e) => {
				e.preventDefault();
				onToggle(e);
			}}
		>
			<Box
				aria-label={typeof title === 'string' ? title : undefined}
				xcss={[
					headerClickableStyles,
					!isOpen && headerClickableClosedStyles,
					!headerActions && !isOpen && headerClickableWithoutActionsClosedStyles,
					!headerActions && isOpen && headerClickableWithoutActionsOpenStyles,
				]}
			>
				<Inline
					alignBlock="center"
					spread="space-between"
					grow="fill"
					xcss={headerInnerWrapperStyles}
				>
					<Inline alignBlock="center" grow="fill" xcss={titleWrapperStyles}>
						<Title
							data-testid={HEADER_TITLE_DATA_TEST_ID}
							enableVisualRefreshForTitle={enableVisualRefreshForTitle}
						>
							{title}
						</Title>
						{subTitle !== undefined && !isOpen && (
							<SubTitle aria-hidden="true">{subTitle}</SubTitle>
						)}
					</Inline>
					<Inline xcss={alignRightStyles}>
						<HeaderRightAligned isOpen={isOpen} />
						{isOpen ? (
							<ChevronUpIcon spacing="spacious" label="" color={token('color.icon.subtle')} />
						) : (
							<ChevronDownIcon spacing="spacious" label="" color={token('color.icon.subtle')} />
						)}
					</Inline>
				</Inline>
			</Box>
			{!!headerActions && <Box xcss={headerActionsStyles}>{headerActions}</Box>}
		</summary>
	);
};

const summaryHeaderStyles = css({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	backgroundColor: token('elevation.surface.overlay'),
	borderStyle: 'solid',
	borderWidth: '1px',
	borderColor: token('color.border'),
	borderRadius: '4px',
	position: 'sticky',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	zIndex: layers.groupStickyHeader,
	height: '48px',

	'&::-webkit-details-marker': {
		display: 'none',
		content: '',
		width: 0,
		height: 0,
	},
});

const headerClickableStyles = xcss({
	height: '100%',
	width: '100%',
	background: 'transparent',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	borderTopLeftRadius: '2px',
	backgroundColor: 'elevation.surface.overlay',
	paddingLeft: 'space.100',
	paddingRight: 'space.100',
	cursor: 'pointer',
	alignItems: 'center',
	display: 'flex',
	':hover': {
		backgroundColor: 'elevation.surface.overlay.hovered',
	},
	':focus': {
		outline: `2px solid ${token('color.border.accent.blue')}`,
		// @ts-expect-error Atlaskit appears broken for this style, need to do manually
		outlineOffset: '-1px',
		backgroundColor: 'elevation.surface.overlay.hovered',
	},
	':focus-within': {
		outline: `2px solid ${token('color.border.accent.blue')}`,
		// @ts-expect-error Atlaskit appears broken for this style, need to do manually
		outlineOffset: '-1px',
		backgroundColor: 'elevation.surface.overlay.hovered',
	},
	':active': {
		outline: `2px solid ${token('color.border.accent.blue')}`,
		// @ts-expect-error Atlaskit appears broken for this style, need to do manually
		outlineOffset: '-1px',
		backgroundColor: 'elevation.surface.overlay.hovered',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
	':focus:not(:focus-visible)': {
		outline: `2px solid ${token('color.border.accent.blue')}`,
	},
});

const headerClickableClosedStyles = xcss({
	borderBottomLeftRadius: '2px',
});

const headerClickableWithoutActionsClosedStyles = xcss({
	borderRadius: '2px',
});

const headerClickableWithoutActionsOpenStyles = xcss({
	borderTopRightRadius: '2px',
});

const headerActionsStyles = xcss({
	borderLeftStyle: 'solid',
	borderLeftWidth: '1px',
	borderLeftColor: 'color.border',
	padding: 'space.100',
});

const alignRightStyles = xcss({
	position: 'relative',
	right: '0px',
	flexShrink: 0,
	alignItems: 'center',
});

const titleWrapperStyles = xcss({
	maxWidth: '90%',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

const headerInnerWrapperStyles = xcss({ overflow: 'hidden' });

/**
 * The default Composable Component group, that acts
 * as a skeleton, and can be re-used for different purpose or
 * design.
 */
export const CollapsibleGroupFactory = forwardRef<HTMLDivElement, Props>((props, ref) => {
	const {
		children,
		components,
		id,
		isOpen,
		onHeaderClick,
		renderHeaderActions,
		stickyHeaderPosition,
		subTitle,
		title,
		enableVisualRefreshForTitle = false,
		containerElement,
	} = props;
	const componentsMerged: RequiredComponents = { ...defaultComponents, ...components };
	const { Group, Body } = componentsMerged;
	const didOpenAtLeastOnceRef = useRef(false);

	const onToggle = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			// Using `useRef` here instead of `useState` prevents an extra re-render
			// because `useState`'s updater functionc causes a re-render when being
			// updated.
			// See: https://stash.atlassian.com/projects/JIRACLOUD/repos/jira-frontend/pull-requests/60341/overview?commentId=3695533
			didOpenAtLeastOnceRef.current = true;
			event.preventDefault();
			onHeaderClick?.(event);
		},
		// `onHeaderClick` should be a dependency as this is passed from a prop and
		// would otherwise trigger un-necessary re-renders (especially as this is not
		// memoized in the parent component `CollapsibleGroup`).
		[onHeaderClick],
	);

	const headerRef = useRef(null);

	const { registerSticky, deregisterSticky } = useStickyHeaderRegistration();

	useEffect(() => {
		const STICKY_NAME = Symbol('group-header');
		registerSticky(STICKY_NAME, headerRef);
		return () => deregisterSticky(STICKY_NAME);
	}, [registerSticky, deregisterSticky, stickyHeaderPosition]);

	return (
		<BaseGroup
			headerNode={
				<HeaderComponent
					isOpen={isOpen}
					componentsMerged={componentsMerged}
					id={id}
					onToggle={onToggle}
					renderHeaderActions={renderHeaderActions}
					stickyHeaderPosition={stickyHeaderPosition}
					subTitle={subTitle}
					title={title}
					innerRef={headerRef}
					enableVisualRefreshForTitle={enableVisualRefreshForTitle}
					containerElement={containerElement}
				/>
			}
			Group={Group}
			isOpen={isOpen}
			ref={ref}
			enableVisualRefreshForTitle={enableVisualRefreshForTitle}
		>
			{(!didOpenAtLeastOnceRef.current && !isOpen) || children === undefined ? null : (
				<Body isOpen={isOpen}>{children}</Body>
			)}
		</BaseGroup>
	);
});

export default CollapsibleGroupFactory;
