import { styled, css } from '@compiled/react';
import { token } from '@atlaskit/tokens';

export const ellipsis = {
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
} as const;

const FALLBACK_ROWS = 2;

export const transparentScrollTrack = (target: string): ReturnType<typeof css> =>
	css({
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		[`${/* sc-selector */ target}::-webkit-scrollbar`]: {
			width: '8px',
			background: 'transparent',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		[`${/* sc-selector */ target}::-webkit-scrollbar-thumb`]: {
			// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
			background: '#c1c1c1',
			borderRadius: '10px',
		},
	});

const getTextAreaCss = {
	font: token('font.body.large'),
	marginTop: token('space.025'),
	marginBottom: 0,
	overflowY: 'auto',
	overflowX: 'hidden',
	width: '100%',
	height: 'auto',
	whiteSpace: 'pre-line',
} as const;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
export const TextContentArea = styled.div(getTextAreaCss);

// eslint-disable-next-line @typescript-eslint/no-explicit-any,  @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
export const ScrollableArea = styled.div<{ maxRows?: any }>(getTextAreaCss, {
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxHeight: (props) => `${(props.maxRows ? props.maxRows : FALLBACK_ROWS) * 2.5}ex`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&::-webkit-scrollbar': {
		width: '8px',
		background: 'transparent',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&::-webkit-scrollbar-thumb': {
		// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
		background: '#c1c1c1',
		borderRadius: '10px',
	},
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any,  @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
export const Ellipsis = styled.div(css<Record<any, any>>(ellipsis));
