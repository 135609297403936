import sortBy from 'lodash/sortBy';
import { v4 as uuid } from 'uuid';
import { fg } from '@atlassian/jira-feature-gating';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { getAriConfig } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { FieldsByKey } from '@atlassian/jira-polaris-domain-field/src/collections/types.tsx';
import {
	KEY_FIELDKEY,
	SUMMARY_FIELDKEY,
	PROJECT_FIELDKEY,
} from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { ViewSet } from '@atlassian/jira-polaris-domain-view/src/view-set/types.tsx';
import { VIEW_KIND_TIMELINE } from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import {
	type RemoteView,
	type View,
	type ViewKind,
	type ViewDraft,
	ViewLayoutType,
} from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { generateLocalViewId } from '@atlassian/jira-polaris-domain-view/src/view/utils.tsx';
import { getAutosaveAffectedViewConfigurations } from '../autosave.tsx';
import { logViewError } from '../errors.tsx';
import { generateJQL, jqlToSortBy } from '../jql/index.tsx';

const ARCHIVED_VIEW_SLUG = 'archived';

export const currentViewFilter = (currentViewSlug?: string) => (view: View) => {
	if (getWillShowNav4() && currentViewSlug === ARCHIVED_VIEW_SLUG && view.containsArchived) {
		return true;
	}
	return view.slug !== undefined && currentViewSlug !== undefined && view.slug === currentViewSlug;
};

// there is no longer a 1->1 from view to slug; a view will have *two* slugs,
// one for its legacy xid and one for its uuid
export const getViewSlug = (view: View | RemoteView): string | undefined => {
	if (!view.viewId) {
		return undefined;
	}

	try {
		const parsed = getAriConfig(view.viewId);
		if (parsed) {
			return parsed.resourceId;
		}
		throw new Error('Unable to parse ari');
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		logViewError('utils.getViewSlug', Object.assign(error, { viewId: view.viewId }));
	}

	return undefined;
};

export const doesViewMatchSlug = (view: View | RemoteView, slug: string): boolean => {
	const viewSlug = getViewSlug(view);
	if (viewSlug !== undefined && slug !== undefined && viewSlug === slug) {
		return true;
	}

	if (view.uuid !== undefined && slug !== undefined && view.uuid === slug) {
		return true;
	}

	if (getWillShowNav4() && view.containsArchived && slug === ARCHIVED_VIEW_SLUG) {
		return true;
	}

	const xid = parseInt(slug, 10);
	if (!Number.isNaN(xid)) {
		if (view.viewLegacyId !== undefined && xid === view.viewLegacyId) {
			return true;
		}
	}

	return false;
};

export const internRemoteView = (
	fields: FieldsByKey,
	view: View,
	isSharedView?: boolean,
	isCollectionView?: boolean,
): View => {
	const remoteView = isSharedView
		? {
				...view,
				filter: [],
				initialFilter: view.filter,
			}
		: { ...view };

	let slug = isCollectionView ? remoteView.uuid : getViewSlug(remoteView);
	if (getWillShowNav4() && fg('jpd-sidebar-v4-for-roadmaps')) {
		slug = getViewSlug(remoteView);
	}

	return {
		...remoteView,
		id: remoteView.id || generateLocalViewId(),
		slug,
		saving: false,
		modified: false,
		isEditingTitle: remoteView.isEditingTitle ?? false,
		// use the remote sort order directly if available, otherwise parse
		// it from the JQL for legacy sort reasons (i.e., for when the new
		// code is loading an old view)
		// TODO: drop the above comment on polaris_drop_unused_jql_sort_for_views FG cleanup
		...(fg('polaris_drop_unused_jql_sort_for_views')
			? undefined
			: { sortBy: remoteView.sortBy || jqlToSortBy(fields, generateJQL('')) }),
		selectedIssues: remoteView.selectedIssues || {},
		collapsedSwimlanes: remoteView.collapsedSwimlanes || {},
		draft: getAutosaveAffectedViewConfigurations(remoteView),
		bulkEditHoveredFieldKey: undefined,
	};
};

export const viewComparator = (a: View, b: View) => a.rank - b.rank;

// return new array with the views sorted by `viewComparator`
export const sortViews = (views: View[]): View[] => {
	const newViews = [...views];
	newViews.sort(viewComparator);
	return newViews;
};

export const findView = (
	viewSets: ViewSet[],
	predicate: (view: View) => boolean,
): View | undefined => {
	for (const viewSet of viewSets) {
		const view = viewSet.views.find(predicate);
		if (view) {
			return view;
		}
		for (const vSet of viewSet.viewSets || []) {
			const viewSetView = vSet.views.find(predicate);
			if (viewSetView) {
				return viewSetView;
			}
		}
	}
	return undefined;
};

export const findViewWithSlug = (viewSets: ViewSet[], slug?: string) =>
	slug === undefined ? undefined : findView(viewSets, (view) => doesViewMatchSlug(view, slug));

export const findViewSet = (
	viewSets: ViewSet[],
	predicate: (viewSet: ViewSet) => boolean,
): ViewSet | undefined => {
	for (const viewSet of viewSets) {
		const result = viewSet.viewSets?.find(predicate);
		if (result) {
			return result;
		}
	}
	return viewSets.find(predicate);
};

export const checkIsViewCollapsed = (views: View[], currentViewSlug: string | undefined) =>
	currentViewSlug !== undefined &&
	views.findIndex((v) => doesViewMatchSlug(v, currentViewSlug)) !== -1;

const findFirstNotEmptySection = (viewSets: ViewSet[]) => {
	const sections = viewSets.filter((v) => v.type === 'SECTION' && v.views.length > 0);
	return sortBy(sections, (s) => s.rank)[0];
};

export const getFirstAvailableViewIdInViewSet =
	(viewSets: ViewSet[]) =>
	(viewSetType: string): { view: View; sectionId?: string } | undefined => {
		const viewSet = findViewSet(viewSets, (v) => v.type === viewSetType);

		if (viewSet === undefined) {
			return undefined;
		}

		if (viewSet.views.length > 0) {
			return {
				view: viewSet.views[0],
			};
		}

		const section = findFirstNotEmptySection(viewSet.viewSets ?? []);

		if (section === undefined) {
			return undefined;
		}

		return {
			view: section.views[0],
			sectionId: section.id,
		};
	};

const getNewViewFields = (viewKind: ViewKind, isCollectionView: boolean): FieldKey[] => {
	if (isCollectionView) return [SUMMARY_FIELDKEY, PROJECT_FIELDKEY];
	if (viewKind === VIEW_KIND_TIMELINE) return [SUMMARY_FIELDKEY];
	return [KEY_FIELDKEY, SUMMARY_FIELDKEY];
};

export const createNewViewEntity = (
	viewSetId: string,
	kind: ViewKind,
	title: string,
	rank: number,
	isCollectionView: boolean,
) => {
	const view: View = {
		kind,
		viewSetId,
		collapsedSwimlanes: {},
		comments: [],
		markers: [],
		containsArchived: false,
		description: undefined,
		editable: true,
		fields: getNewViewFields(kind, !!isCollectionView),
		filter: [],
		groupBy: undefined,
		groupValues: [],
		fieldRollups: [],
		hidden: [],
		id: uuid(),
		isEditingTitle: true, // create new view in edit title mode
		lastCommentsViewedTimestamp: undefined,
		modified: true, // Ensure this gets saved
		rank,
		saveError: undefined,
		saving: false,
		selectedIssues: {},
		sortBy: undefined,
		title,
		emoji: undefined,
		verticalGroupValues: [],
		viewId: undefined, // this is a new view; make sure we don't steal the server's ID of the old view
		tableColumnSizes: [],
		bulkEditHoveredFieldKey: undefined,
		hideEmptyGroups: false,
		hideEmptyColumns: false,
		isAutosaveEnabled: !isCollectionView,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		draft: {} as ViewDraft,
		layoutType: ViewLayoutType.DETAILED,
		createdAtTimestamp: new Date().toISOString(),
		updatedAtTimestamp: null,
		commentsLoaded: true,
		configError: undefined,
	};
	return view;
};
