import { useEffect } from 'react';
import { useRelayEnvironment } from 'react-relay';
import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';
import {
	CORE_PROJECT,
	PRODUCT_DISCOVERY_PROJECT,
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import {
	useIssueContextActions,
	useIssueKey,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	fetchAiOptInData,
	JiraProjectTypeEnum,
} from '@atlassian/jira-issue-fetch-services/src/services/ai-optin-data/index.tsx';
import type { JiraProjectType } from '@atlassian/jira-issue-fetch-services/src/types.tsx';
import {
	useProjectKey,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';

export const mapToJiraProjectType = (
	projectType: ProjectType | undefined,
): JiraProjectType | null => {
	switch (projectType) {
		case SOFTWARE_PROJECT:
			return JiraProjectTypeEnum.SOFTWARE;
		case SERVICE_DESK_PROJECT:
			return JiraProjectTypeEnum.SERVICE_DESK;
		case CORE_PROJECT:
			return JiraProjectTypeEnum.BUSINESS;
		case PRODUCT_DISCOVERY_PROJECT:
			return JiraProjectTypeEnum.PRODUCT_DISCOVERY;
		default:
			return null;
	}
};

export const AiOptInService = () => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);
	const cloudId = useCloudId();
	const environment = useRelayEnvironment();
	const [, { mergeIssueContext }] = useIssueContextActions();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const isAiOptInEnabled = await fetchAiOptInData(
					mapToJiraProjectType(projectType),
					cloudId,
					environment,
				);
				mergeIssueContext({ isAiOptInEnabled });
			} catch (error: unknown) {
				log.safeErrorWithoutCustomerData(
					'issue.fetch.services.ai.optin.service',
					'Error fetching ai opt in value',
					error instanceof Error ? error : undefined,
				);
				mergeIssueContext({ isAiOptInEnabled: false });
			}
		};

		fetchData();
	}, [cloudId, environment, mergeIssueContext, projectType, issueKey]);

	return null;
};
