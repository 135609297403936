// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createHook,
	type Action,
	createContainer,
} from '@atlassian/react-sweet-state';

export type State = {
	isOpen: boolean;
};

export const initialState: State = {
	isOpen: false,
};

export const actions = {
	openFindFieldDialog:
		(): Action<State> =>
		({ setState }) => {
			setState({
				isOpen: true,
			});
		},
	closeFindFieldDialog:
		(): Action<State> =>
		({ setState }) => {
			setState({
				isOpen: false,
			});
		},
} as const;

export type Actions = typeof actions;

const store = createStore<State, Actions>({
	name: 'issue-view.issue-actions.find-field',
	initialState,
	actions,
});

export const useFindFieldDialog = createHook(store);
export const FindFieldDialogContainer = createContainer(store);
