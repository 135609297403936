import noop from 'lodash/noop';
import type { FieldIdMap } from '@atlassian/jira-polaris-component-field-id-mapping/src/controllers/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createStateHook, createActionsHook } from '@atlassian/react-sweet-state';
import type { FieldKeyToAriMap, State } from './types.tsx';

const createPromiseState = () => {
	let resolve: (value: FieldIdMap) => void = noop;
	let reject: () => void = noop;

	const promise = new Promise<FieldIdMap>((pr, rj) => {
		resolve = pr;
		reject = rj;
	});

	return { promise, resolve, reject };
};

const initialState: State = {
	// ensure loading state until we have a proper loading state (e.g. for cases where projectId is initially undefined)
	isDependencyLoading: true,
	fieldIdMap: undefined,
	cloudId: undefined,
	promise: createPromiseState(),
};

/**
 * Visible for testing
 */
export const Store = createStore({
	initialState,
	actions: {
		updateMapping:
			(cloudId: string, isLoading: boolean, fieldIdMap: FieldIdMap) =>
			({ getState, setState }) => {
				setState({
					fieldIdMap,
					cloudId,
					isDependencyLoading: isLoading,
				});

				if (!isLoading && fieldIdMap !== undefined) {
					getState().promise.resolve(fieldIdMap);
				}
			},
	},
	name: 'polaris-field-ari-store',
});

export const useActions = createActionsHook(Store);

const getFieldAris = (
	state: State,
	fieldKeys: FieldKey[] | undefined,
): FieldKeyToAriMap | undefined => {
	const map: FieldKeyToAriMap = {};

	if (fieldKeys === undefined || state.fieldIdMap === undefined) {
		return undefined;
	}

	fieldKeys.forEach((fieldKey) => {
		const legacyFieldId = state.fieldIdMap?.[fieldKey];

		map[fieldKey] = legacyFieldId || `ari:cloud:jira:${state.cloudId}:jira-field/${fieldKey}`;
	});

	return map;
};

const getFieldAri = (state: State, fieldKey: FieldKey | undefined) => {
	if (fieldKey === undefined || !state.cloudId) {
		return undefined;
	}

	return getFieldAris(state, [fieldKey])?.[fieldKey];
};

export const useFieldAri = createStateHook(Store, { selector: getFieldAri });
export const useFieldKeyToAriMapAsync = createStateHook(Store, {
	selector: (
		state,
		{ fieldKeys }: { fieldKeys: FieldKey[] | undefined },
	): Promise<FieldKeyToAriMap> =>
		state.promise.promise.then(() => getFieldAris(state, fieldKeys) || {}),
});
