import React, { memo, useCallback } from 'react';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import type {
	IssueViewRelayFragment,
	MainIssueAggQueryRelayFragment,
} from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import {
	ALWAYS_PRIMARY_AND_VISIBLE,
	DEFAULT_TEMPLATE,
} from '@atlassian/jira-issue-view-layout-templates-constants/src/index.tsx';
import { useVisibleHiddenItems } from '@atlassian/jira-issue-view-layout-templates-services/src/services/context/visible-hidden/visible-hidden-items/index.tsx';
import VisibleHiddenContextSectionView from '@atlassian/jira-issue-view-layout-templates-views/src/ui/context/visible-hidden/ui/index.tsx';

type Props = {
	// TODO Decomp BENTO-12514 - add useFragment to this component and replace this prop with more specific fragment key
	issueViewRelayFragment?: IssueViewRelayFragment | null;
	rootRelayFragment: MainIssueAggQueryRelayFragment | null;
	containerElement?: HTMLElement | null;
};

const ContextItems = ({ issueViewRelayFragment, rootRelayFragment, containerElement }: Props) => {
	const [items, updateHiddenSecondaryFields] = useVisibleHiddenItems(
		ALWAYS_PRIMARY_AND_VISIBLE[DEFAULT_TEMPLATE],
	);

	const onToggleExpanded = useCallback(() => {
		updateHiddenSecondaryFields();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items.hiddenItems]);

	return (
		<>
			<VisibleHiddenContextSectionView
				items={items}
				onToggleExpanded={onToggleExpanded}
				issueViewRelayFragment={issueViewRelayFragment}
				rootRelayFragment={rootRelayFragment}
				containerElement={containerElement}
			/>
		</>
	);
};

export default componentWithFG(
	'issue_view_improve_context_layout',
	memo(ContextItems),
	ContextItems,
);
