import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	coverImageAlt: {
		id: 'issue-view-cover.cover-image-alt',
		defaultMessage: 'Issue view cover',
		description: 'Alt text for the issue cover image',
	},
	editCoverButtonLabel: {
		id: 'issue-view-cover.edit-cover-button-label',
		defaultMessage: 'Edit cover',
		description: 'Edit cover button label',
	},
});
