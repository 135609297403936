import type { GetProjectSettingsUrl } from './types.tsx';

export const getProjectSettingsIssueTypesUrl = ({
	projectKey,
	projectType,
}: GetProjectSettingsUrl) => {
	switch (projectType?.toLowerCase()) {
		case 'customer_service':
		case 'service_desk':
			return `/plugins/servlet/project-config/${projectKey}/issueTypes`;
		case 'business':
			return `/jira/core/projects/${projectKey}/settings/issuetypes`;
		default:
			return `/jira/software/projects/${projectKey}/settings/issuetypes`;
	}
};
