import React, { useCallback, type RefObject } from 'react';
import Button from '@atlaskit/button/new';
import { Box, xcss } from '@atlaskit/primitives';
import { defineMessages, useIntl } from '@atlassian/jira-intl';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { confluencePageAISuggestionIsOpenSelector } from '@atlassian/jira-issue-view-store/src/selectors/confluence-content-selector.tsx';
import { linkConfluencePageClickedWithAISuggestions } from '@atlassian/jira-issue-view-store/src/actions/confluence-pages-actions.tsx';
import type { IncidentPanelExternalHandlers } from '@atlassian/jira-servicedesk-aiops-suggestions-common/src/ui/index.tsx';
import { useAIOpsLinkConfluenceSuggestionsEntryPointContext } from '@atlassian/jira-servicedesk-aiops-link-confluence-suggestions/src/controllers/entrypoint-context-provider.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { fireUIAnalytics, type UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';

const messages = defineMessages({
	findRelatedContentButton: {
		id: 'issue.details.confluence-content.find-related-content-button',
		defaultMessage: 'Find related content',
		description: 'Clicking on the button will open "Find related content" suggestion panel',
	},
});

type Props = {
	isPanelVisible?: boolean;
	suggestionPanelRef: RefObject<IncidentPanelExternalHandlers>;
	onShowPanel: () => void;
};

const FindRelatedContentButton = ({
	isPanelVisible = false,
	suggestionPanelRef,
	onShowPanel,
}: Props) => {
	const { formatMessage } = useIntl();
	const { entryPointActions } = useAIOpsLinkConfluenceSuggestionsEntryPointContext();
	const triggerRef = useEntryPointButtonTrigger(entryPointActions);

	const onClick = useCallback(
		(_: unknown, event: UIAnalyticsEvent) => {
			onShowPanel();
			suggestionPanelRef.current?.scrollIntoView();
			fireUIAnalytics(event, 'findRelatedContent');
		},
		[onShowPanel, suggestionPanelRef],
	);

	return (
		<Box xcss={buttonStyles}>
			<Button ref={triggerRef} spacing="compact" isSelected={isPanelVisible} onClick={onClick}>
				{formatMessage(messages.findRelatedContentButton)}
			</Button>
		</Box>
	);
};

export const FindRelatedContentButtonContainer = connect(
	(state: State) => ({
		isPanelVisible: confluencePageAISuggestionIsOpenSelector(state),
	}),
	{
		onShowPanel: linkConfluencePageClickedWithAISuggestions,
	},
)(FindRelatedContentButton);

const buttonStyles = xcss({
	marginInlineStart: 'space.075',
});
