import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noFocusedCommentFoundTitle: {
		id: 'remove-permalink-query.no-focused-comment-found-title',
		defaultMessage: 'We’re unable to load the comment',
		description: 'Title for success message when custom field context is deleted',
	},
	noFocusedCommentFoundDescription: {
		id: 'remove-permalink-query.no-focused-comment-found-description',
		defaultMessage: 'Looks like that comments doesn’t exist or it’s been deleted.',
		description:
			'Description of the flag shown when the request failed while restoring custom field.',
	},
	noFocusedCommentFoundAction: {
		id: 'remove-permalink-query.no-focused-comment-found-action',
		defaultMessage: 'Refresh',
		description: 'Action of the flag to reload the page',
	},
});
