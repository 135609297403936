import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { IssueLink } from './types.tsx';

export const getUserHasPermissionToLink = (error: FetchError | undefined): Boolean =>
	error?.statusCode !== 401;

export const getIsAnyPrioritySetInAnyLinkedIssue = (issueLinks: IssueLink | null): boolean => {
	let isSet = false;

	if (!issueLinks) {
		return isSet;
	}

	for (const issueLinkGroup of Object.values(issueLinks)) {
		for (const linkedIssueType of issueLinkGroup) {
			if (linkedIssueType.linkedIssue.issuePriorityName) {
				isSet = true;
				break;
			}
		}
		if (isSet) break;
	}

	return isSet;
};
