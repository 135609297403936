import React, { useCallback } from 'react';
import { graphql, useFragment, useMutation } from 'react-relay';
import { AssignToMe } from '@atlassian/jira-issue-field-assignee-assign-to-me/src/ui/assign-to-me/index.tsx';
import type { AssignToMeProps } from '@atlassian/jira-issue-field-assignee-assign-to-me/src/ui/assign-to-me/types.tsx';
import type { AssigneeInlineEditViewBaseProps } from '@atlassian/jira-issue-field-assignee-inline-edit-full/src/ui/assignee/types.tsx';
import { useFieldIsEditingActions } from '@atlassian/jira-issue-field-editing-store/src/index.tsx';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import { useServerValidationHandler } from '@atlassian/jira-issue-field-validation-handler/src/controllers/server-validation-handler/index.tsx';
import type { AggUser } from '@atlassian/jira-issue-user-picker-edit-view/src/common/types.tsx';
import { useAnalyticsEvents, fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { AssignToMeInlineEditInner$key } from '@atlassian/jira-relay/src/__generated__/AssignToMeInlineEditInner.graphql';
import type { AssignToMeInlineEditInner_rootFragmentKey$key } from '@atlassian/jira-relay/src/__generated__/AssignToMeInlineEditInner_rootFragmentKey.graphql';
import type { AssignToMeInlineEditInner_new_Mutation } from '@atlassian/jira-relay/src/__generated__/AssignToMeInlineEditInner_new_Mutation.graphql';
import { fg } from '@atlassian/jira-feature-gating';

export type AssignToMeInlineEditBase = Omit<AssignToMeProps, 'onClick'> &
	Pick<AssigneeInlineEditViewBaseProps, 'onSubmit' | 'onSubmitFailed' | 'onSubmitSucceeded'>;

type AssignToMeInlineEditInnerProps = AssignToMeInlineEditBase & {
	fragmentKey: AssignToMeInlineEditInner$key;
	rootFragmentKey: AssignToMeInlineEditInner_rootFragmentKey$key | null;
};

export const AssignToMeInlineEditInner = ({
	fragmentKey,
	rootFragmentKey,
	projectKey,
	onSubmit,
	onSubmitFailed,
	onSubmitSucceeded,
	...rest
}: AssignToMeInlineEditInnerProps) => {
	const { overriding } = useInlineEditFieldInjections();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { setFieldEditingState } = useFieldIsEditingActions();
	const field = useFragment<AssignToMeInlineEditInner$key>(
		graphql`
			fragment AssignToMeInlineEditInner on JiraSingleSelectUserPickerField {
				id
				name
				fieldId
				...assignToMe_issueFieldAssigneeAssignToMe_fragmentRef
			}
		`,
		fragmentKey,
	);

	const root = useFragment<AssignToMeInlineEditInner_rootFragmentKey$key>(
		graphql`
			fragment AssignToMeInlineEditInner_rootFragmentKey on Query {
				...assignToMe_issueFieldAssigneeAssignToMe_fragmentRefRoot
			}
		`,
		rootFragmentKey || null,
	);
	const setIsEditing = useCallback(() => {
		setFieldEditingState(field.fieldId, true);
	}, [field.fieldId, setFieldEditingState]);
	const [, { handleSubmitFailed, clearServerValidation }] = useServerValidationHandler(
		overriding.overrideLabel(field.name),
		setIsEditing,
	);

	const [commit] = useMutation<AssignToMeInlineEditInner_new_Mutation>(graphql`
		mutation AssignToMeInlineEditInner_new_Mutation(
			$input: JiraUpdateSingleSelectUserPickerFieldInput!
		) @raw_response_type {
			jira {
				updateSingleSelectUserPickerField(input: $input) @optIn(to: "JiraIssueFieldMutations") {
					success
					errors {
						message
					}
					field {
						user {
							accountId
							name
							picture
							accountStatus
							id
						}
					}
				}
			}
		}
	`);
	// #endregion

	const handleSubmit = useCallback(
		(newUser: AggUser | null) => {
			fg('relay-635_fix_relay_critical_field_analytics') && clearServerValidation();
			onSubmit?.(newUser);
			commit({
				variables: {
					input: {
						id: field.id,
						operation: {
							operation: 'SET',
							id: newUser?.id ?? null,
						},
					},
				},
				onCompleted: (mutationData) => {
					if (mutationData.jira?.updateSingleSelectUserPickerField?.success) {
						onSubmitSucceeded?.(newUser);
						// Field updated event is a core action used to track MCU & collaboration metrics
						// as defined in https://hello.atlassian.net/wiki/spaces/ANALYTICS/pages/3767029088/Monthly+Core+User+MCU+Definition
						// and https://hello.atlassian.net/wiki/spaces/Spork/pages/4098606074/Warepil+-+Collaboration+Statsig+Metric?focusedCommentId=4269736439
						fireTrackAnalytics(createAnalyticsEvent({}), 'field updated', {
							editOperationType: 'set',
							fieldKey: field.fieldId,
							fieldType: 'user',
						});
					} else {
						onSubmitFailed?.();
						fg('relay-635_fix_relay_critical_field_analytics') && handleSubmitFailed();
					}
				},
				onError() {
					onSubmitFailed?.();
					handleSubmitFailed();
				},
				optimisticResponse: {
					jira: {
						updateSingleSelectUserPickerField: {
							success: true,
							errors: null,
							field: {
								id: field.id,
								user: newUser
									? {
											id: newUser.id,
											accountId: newUser.accountId,
											accountStatus: newUser.accountStatus,
											name: newUser.name,
											picture: newUser.picture,
											__typename: 'AtlassianAccountUser',
										}
									: null,
							},
						},
					},
				},
			});
		},
		[
			clearServerValidation,
			onSubmit,
			commit,
			field.id,
			field.fieldId,
			onSubmitSucceeded,
			createAnalyticsEvent,
			onSubmitFailed,
			handleSubmitFailed,
		],
	);

	// if root is null, we are in the old code path
	return (
		root != null && (
			<AssignToMe
				onClick={handleSubmit}
				fragmentRefRoot={root}
				fragmentRef={field}
				projectKey={projectKey}
				{...rest}
			/>
		)
	);
};
