/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { useCallback, useEffect, useMemo, useRef } from 'react';
import Button, { IconButton } from '@atlaskit/button/new';
import { cssMap, jsx } from '@atlaskit/css';
import TeamsIcon from '@atlaskit/icon/core/teams';
import CloseIcon from '@atlaskit/icon/core/close';
import LegacyTeamsIcon from '@atlaskit/icon/glyph/teams';
import { SpotlightPulse } from '@atlaskit/onboarding';
import { Box, Inline } from '@atlaskit/primitives/compiled';
import { token } from '@atlaskit/tokens';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { PersistentInviteCacheKeys } from '../../services/caching/index.tsx';
import { InviteCapabilities } from '../../services/invite-capability/types.tsx';
import { AvatarGroup } from './lazy-load-avatars.tsx';
import { messages } from './messages.tsx';
import type { InviteBannerProps } from './types.tsx';

const styles = cssMap({
	wrapper: {
		height: '44px',
		backgroundColor: token('color.background.neutral.subtle'),
		color: token('color.text.subtle'),
		borderBottom: `${token('border.width')} solid ${token('color.border')}`,
	},
	iconWrapper: {
		display: 'flex',
		width: '24px',
		height: '24px',
	},
	leftContent: {
		paddingLeft: token('space.100'),
	},
	rightContent: {
		paddingRight: token('space.100'),
	},
});

export const InviteBanner = ({
	collaboratorIds,
	getCollaboratorData,
	inviteCapability,
	isFirstImpression,
	onSubmit,
	onDismiss,
	persistentInviteCache,
}: InviteBannerProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const { getPersistentInvitesCache, setPersistentInvitesCache } = persistentInviteCache;
	const viewCountCache = useRef<number>(
		(getPersistentInvitesCache(PersistentInviteCacheKeys.VIEW_COUNT) || 0) + 1,
	);
	const viewCount = viewCountCache.current;
	const numberOfCollaborators = collaboratorIds.length;

	const buttonMessage =
		inviteCapability === InviteCapabilities.DIRECT_INVITE
			? messages.shareAccessButtonTitle
			: messages.requestAccessButtonTitle;

	const attributes = useMemo(
		() => ({
			collaborators: collaboratorIds,
			numberOfCollaborators,
			inviteCapability,
			isFirstImpression,
			viewCount,
		}),
		[collaboratorIds, numberOfCollaborators, inviteCapability, isFirstImpression, viewCount],
	);

	const onAccessButtonClick = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({}),
			'button clicked',
			'submitPersistentInviteBanner',
			attributes,
		);
		onSubmit();
	}, [attributes, createAnalyticsEvent, onSubmit]);

	const onCloseButtonClick = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({}),
			'button clicked',
			'dismissPersistentInviteBanner',
			attributes,
		);
		onDismiss();
	}, [attributes, createAnalyticsEvent, onDismiss]);

	useEffect(() => {
		fireUIAnalytics(createAnalyticsEvent({}), 'persistentInviteBanner rendered', attributes);
	}, [attributes, createAnalyticsEvent]);

	useEffect(() => {
		setPersistentInvitesCache(PersistentInviteCacheKeys.VIEW_COUNT, viewCount);
	}, [setPersistentInvitesCache, viewCount]);

	return (
		<SpotlightPulse radius={1} pulse={isFirstImpression}>
			<Inline
				grow="fill"
				spread="space-between"
				alignBlock="center"
				xcss={styles.wrapper}
				testId="invite-prompt-on-link-insert.ui.confluence-content-invite-banner.test-id-invite-banner-wrapper"
			>
				<Inline space="space.100" alignBlock="center" xcss={styles.leftContent}>
					<Inline alignBlock="center" alignInline="center" xcss={styles.iconWrapper}>
						<TeamsIcon
							label={formatMessage(messages.teamIconLabel)}
							LEGACY_fallbackIcon={LegacyTeamsIcon}
							LEGACY_size="medium"
						/>
					</Inline>
					<Box>
						<FormattedMessage
							{...messages.inviteBannerTitle}
							values={{ numOfAssignees: numberOfCollaborators }}
						/>
					</Box>
				</Inline>
				<Inline space="space.100" alignBlock="center" xcss={styles.rightContent}>
					<Button
						appearance="default"
						spacing="compact"
						onClick={onAccessButtonClick}
						testId="invite-prompt-on-link-insert.ui.confluence-content-invite-banner.submit-button"
					>
						<FormattedMessage {...buttonMessage} />
					</Button>
					<AvatarGroup
						getData={getCollaboratorData}
						numberOfCollaborators={numberOfCollaborators}
					/>
					<Inline alignBlock="center">
						<IconButton
							appearance="subtle"
							icon={CloseIcon}
							label={formatMessage(messages.closeIconLabel)}
							onClick={onCloseButtonClick}
							spacing="compact"
							testId="invite-prompt-on-link-insert.ui.confluence-content-invite-banner.close-button"
						/>
					</Inline>
				</Inline>
			</Inline>
		</SpotlightPulse>
	);
};
