import React, { useMemo } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import type { Props } from '@atlassian/jira-servicedesk-aiops-link-incident-suggestions/src/ui/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useAIOpsLinkIncidentSuggestionsEntryPointContext } from '@atlassian/jira-servicedesk-aiops-link-incident-suggestions/src/controllers/entrypoint-context-provider.tsx';

export const IncidentSuggestionPanel = ({
	suggestionPanelRef,
	isPanelVisible,
	onHidePanel,
	onLinkIssue,
}: Props) => {
	const { entryPointReferenceSubject } = useAIOpsLinkIncidentSuggestionsEntryPointContext();

	const runtimeProps = useMemo(
		() => ({
			isPanelVisible,
			onHidePanel,
			onLinkIssue,
			suggestionPanelRef,
		}),
		[isPanelVisible, onHidePanel, onLinkIssue, suggestionPanelRef],
	);
	return (
		<Box xcss={relatedIncidentWrapperStyles}>
			<JiraEntryPointContainer
				entryPointReferenceSubject={entryPointReferenceSubject}
				id="aiops-link-incident-suggestion-panel"
				teamName="itsm-aiops-grogu"
				packageName="jiraServicedeskAiopsLinkIncidentSuggestions"
				fallback={null}
				runtimeProps={runtimeProps}
			/>
		</Box>
	);
};

const relatedIncidentWrapperStyles = xcss({
	marginTop: 'space.200',
});
