import { traverse } from '@atlaskit/adf-utils/traverse';
import type { ADFEntity } from '@atlaskit/adf-utils/types';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';

export const getADFCharacterCount = (doc?: ADFEntity | null) => {
	let characterCount = 0;
	try {
		const clonedDoc = JSON.parse(JSON.stringify(doc));
		if (!clonedDoc) {
			return 0;
		}
		traverse(clonedDoc, {
			text: (node) => {
				const isLinkOnly = node?.marks?.some(
					(mark) => mark.type === 'link' && mark.attrs?.href === node.text,
				);
				if (!isLinkOnly) {
					characterCount += node?.text?.length ?? 0;
				}
			},
			link: (node) => {
				characterCount -= node?.attrs?.href?.length ?? 0;
			},
		});
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			error,
			meta: {
				id: 'adfCharacterCount',
				packageName: 'quickAddItem',
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}
	return characterCount;
};
