import indexOf from 'lodash/indexOf';
import { fireAnalyticsEventForIssueUpdate } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { getLocalIssueIdsByJiraIssueId } from '../../selectors/issue-ids.tsx';
import { createGetIssueAnalyticsAttributes } from '../../selectors/properties/index.tsx';
import type { State, Props } from '../../types.tsx';

export const adaptExternalIssueRank =
	(
		id: IssueId,
		destinationBeforeId: IssueId | undefined,
		destinationAfterId: IssueId | undefined,
		sortedIds: IssueId[],
	) =>
	({ getState, setState }: StoreActionApi<State>, props: Props) => {
		const localIdMap = getLocalIssueIdsByJiraIssueId(getState(), props);
		const newSortedIds = [...sortedIds];
		const itemIndex = indexOf(newSortedIds, id);
		newSortedIds.splice(itemIndex, 1);
		const toIndex =
			destinationBeforeId !== undefined
				? indexOf(newSortedIds, destinationBeforeId)
				: indexOf(newSortedIds, destinationAfterId) + 1;
		newSortedIds.splice(toIndex, 0, id);
		setState({
			externalIssueRanking: newSortedIds,
		});

		fireAnalyticsEventForIssueUpdate(props.createAnalyticsEvent({}), localIdMap[id], {
			updatedItems: [{ name: 'viewRank' }],
			...createGetIssueAnalyticsAttributes(id)(getState()),
		});
	};
