/**
 * @generated SignedSource<<c7f8a679e750021423160785825e959e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraTimeFormat = "DAYS" | "HOURS" | "PRETTY" | "%future added value";
export type JiraTimeUnit = "DAY" | "HOUR" | "MINUTE" | "WEEK" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditViewOld_timeTrackingSettingsfragmentRef$data = {
  readonly defaultFormat: JiraTimeFormat | null | undefined;
  readonly defaultUnit: JiraTimeUnit | null | undefined;
  readonly workingDaysPerWeek: number | null | undefined;
  readonly workingHoursPerDay: number | null | undefined;
  readonly " $fragmentType": "originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditViewOld_timeTrackingSettingsfragmentRef";
};
export type originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditViewOld_timeTrackingSettingsfragmentRef$key = {
  readonly " $data"?: originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditViewOld_timeTrackingSettingsfragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditViewOld_timeTrackingSettingsfragmentRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditViewOld_timeTrackingSettingsfragmentRef",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "workingHoursPerDay"
    },
    {
      "kind": "ScalarField",
      "name": "workingDaysPerWeek"
    },
    {
      "kind": "ScalarField",
      "name": "defaultFormat"
    },
    {
      "kind": "ScalarField",
      "name": "defaultUnit"
    }
  ],
  "type": "JiraTimeTrackingSettings"
};

(node as any).hash = "94152ce82ac3669d51e2f08afc01fd0e";

export default node;
