import { createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	COUNT_ROLLUP,
	EMPTY_ROLLUP,
	FILLED_ROLLUP,
} from '@atlassian/jira-polaris-domain-field/src/rollup/constants.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import {
	GENERIC_FIELD_FILTER,
	type Filter,
} from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import type { TeamValue } from '@atlassian/jira-issue-field-team/src/common/types.tsx';
import { jiraTeamMapping } from '../../../../field/mapping/team/index.tsx';
import type { PropertyMaps, State } from '../../../types.tsx';
import { nullSafeComparator, stringComparator } from '../../comparators/index.tsx';
import { removePropertyValue } from '../common/remove-utils.tsx';
import type { FieldMapping } from '../types.tsx';
import { createStringValueContainsFilter } from '../common/filter-utils.tsx';

export const teamMapping = (issuesRemote: IssuesRemote, field: Field): FieldMapping<TeamValue> => {
	const valueAccessor: FieldMapping<TeamValue>['valueAccessor'] = (state, _props, issueId) =>
		state.properties.team[field.key]?.[issueId];

	const valueAccessorToExport: FieldMapping<string>['valueAccessorToExport'] = (
		state,
		props,
		issueId,
	) => {
		const team = valueAccessor(state, props, issueId);
		return team?.name || '';
	};

	return {
		...jiraTeamMapping(issuesRemote, field),
		setMutable: (maps: PropertyMaps, issueId: LocalIssueId, value?: TeamValue) =>
			set(maps.team, [field.key, issueId], value),
		setImmutable: (maps: PropertyMaps, issueId: LocalIssueId, value?: TeamValue) => {
			if (maps.team[field.key] && isEqual(maps.team[field.key][issueId], value)) {
				return maps;
			}
			return {
				...maps,
				team: {
					...maps.team,
					[field.key]: {
						...maps.team[field.key],
						[issueId]: value,
					},
				},
			};
		},
		remove: (maps: PropertyMaps, issueId: LocalIssueId) =>
			removePropertyValue(field.key, maps, issueId, 'team'),
		modifyImmutableIfMultiValueField: (maps: PropertyMaps) => maps,
		comparator: nullSafeComparator<TeamValue>((a, b, direction) =>
			stringComparator(a?.name, b?.name, direction),
		),
		valueAccessor,
		valueAccessorToExport,
		getAllValues: (state) => state.properties.team[field.key] ?? {},
		getGroupIdentitiesSelector: (fieldKey, issueIdsSelector) =>
			createSelector(
				issueIdsSelector,
				(state: State) => state.properties.team[fieldKey],
				(issueIds, team) =>
					issueIds.reduce(
						(result, issueId) =>
							Object.assign(result, {
								[issueId]: team?.[issueId]
									? [
											{
												groupIdentity: team[issueId]?.id ?? '',
												value: team[issueId],
											},
										]
									: [],
							}),
						{},
					),
			),
		getGroupIdentities: (state, _props, issueId) =>
			state.properties.team[field.key] !== undefined &&
			state.properties.team[field.key][issueId] !== undefined
				? [
						{
							groupIdentity: state.properties.team[field.key][issueId]?.id ?? '',
							value: state.properties.team[field.key][issueId],
						},
					]
				: [],
		getRollupOperations: () => [COUNT_ROLLUP, EMPTY_ROLLUP, FILLED_ROLLUP],
		allowEmptyGroup: true,
		getLabel: (_groupIdentity, value) => value?.name ?? '',
		getFilter: (filter: Filter) => {
			if (filter.type === GENERIC_FIELD_FILTER && filter.field === field.key) {
				const stringValueContainsFilter = createStringValueContainsFilter(filter);
				if (stringValueContainsFilter === undefined) {
					return undefined;
				}

				return (value: TeamValue | undefined, state, props, localIssueId) =>
					stringValueContainsFilter(value?.id, state, props, localIssueId);
			}
			return undefined;
		},
	};
};
