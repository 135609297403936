import React, { useState, type ReactNode, type MouseEvent, useCallback } from 'react';
import { Box, Pressable, xcss } from '@atlaskit/primitives';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import DropdownMenu, {
	DropdownItemGroup,
	type CustomTriggerProps,
	DropdownItem,
} from '@atlaskit/dropdown-menu';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import { token } from '@atlaskit/tokens';
import { FORGE_ENTITY_TYPE } from '@atlassian/jira-issue-view-common-constants/src/ecosystem-constants.tsx';
import type { ActivityItem } from '@atlassian/jira-issue-view-common-types/src/activity-item-type.tsx';
import { ForgeIssueActivityItem } from '@atlassian/jira-issue-view-forge-issue-activity/src/index.tsx';
import {
	AnalyticsEventToProps,
	fireScreenAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
	MountEvent,
} from '@atlassian/jira-product-analytics-bridge';
import { useIntl } from '@atlassian/jira-intl';
import { COMMENTS } from '@atlassian/jira-issue-view-common-constants/src/activity-items.tsx';
import { messages } from '../../messages.tsx';
import type { SelectActivityItem } from '../../common/ui/types.tsx';
import { UnreadCommentsBadge } from '../unread-comments-badge/index.tsx';

type DropdownProps = {
	items: ActivityItem[];
	selectedItem: ActivityItem;
	onSelectActivityItem: SelectActivityItem;
	label: string;
	unreadCommentsCount?: number;
	showDetails?: boolean;
	setShowDetails?: (showDetails: boolean) => void;
};

const DropdownItemWithAnalytics = AnalyticsEventToProps('dropdownItem', {
	onClick: 'clicked',
})(DropdownItem);

const DropdownItemComponent = ({
	children,
	onClick,
}: {
	children: ReactNode;
	onClick: (event: MouseEvent, analyticsEvent: UIAnalyticsEvent) => void;
}) => (
	<DropdownItemWithAnalytics onClick={onClick}>
		<Box xcss={[dropdownItemWrapper]}>{children}</Box>
	</DropdownItemWithAnalytics>
);

// visible for testing
export const useIsOpen = () => useState(false);

export const ActivityFeedDropdown = ({
	items,
	selectedItem,
	onSelectActivityItem,
	showDetails,
	setShowDetails,
	unreadCommentsCount,
}: DropdownProps) => {
	const [isDropdownOpen, setIsDropdownOpen] = useIsOpen();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();

	const dropdownTrigger = useCallback(
		({ triggerRef, ...triggerProps }: CustomTriggerProps) => {
			return (
				<Pressable
					xcss={[baseStyles, isDropdownOpen && selectedStyles]}
					ref={triggerRef}
					{...triggerProps}
				>
					{formatMessage(messages.more)}
					{selectedItem.type !== COMMENTS && (
						<UnreadCommentsBadge unreadCommentsCount={unreadCommentsCount} />
					)}
					<ChevronDownIcon color="currentColor" label="" />
				</Pressable>
			);
		},
		[formatMessage, isDropdownOpen, selectedItem.type, unreadCommentsCount],
	);

	return (
		<>
			<MountEvent onMount={fireScreenAnalytics} />
			<DropdownMenu
				isOpen={isDropdownOpen}
				testId="issue-activity-feed.ui.buttons-with-dropdown.dropdown-menu-stateless"
				placement="bottom-start"
				trigger={dropdownTrigger}
				onOpenChange={({ isOpen }) => {
					setIsDropdownOpen(isOpen);
					fireUIAnalytics(
						createAnalyticsEvent({
							action: 'toggled',
							actionSubject: 'dropdownMenu',
						}),
						{ isOpen },
					);
				}}
			>
				<DropdownItemGroup>
					{items
						.filter((item) => item.key !== selectedItem.key)
						.map((item) => {
							const props = {
								key: item.key,
								onClick: (e: MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
									onSelectActivityItem(item, analyticsEvent, 'dropdownItemSelected', e.timeStamp);
									if (showDetails) {
										setShowDetails?.(false);
									}
								},
							};

							return item.type === FORGE_ENTITY_TYPE ? (
								<ForgeIssueActivityItem {...props} component={DropdownItemComponent} item={item} />
							) : (
								<DropdownItemComponent {...props}>
									{item.name}
									{item.type === COMMENTS && (
										<UnreadCommentsBadge
											unreadCommentsCount={unreadCommentsCount}
											isWithinDropdown
										/>
									)}
								</DropdownItemComponent>
							);
						})}
				</DropdownItemGroup>
			</DropdownMenu>
		</>
	);
};
const baseStyles = xcss({
	background: token('color.background.neutral.subtle'),
	border: '1px solid transparent',
	borderRadius: token('border.radius.050'),
	color: 'color.text.subtle',
	height: '26px',
	fontWeight: 'font.weight.medium',
	paddingLeft: 'space.150',
	paddingRight: 'space.150',
	display: 'flex',
	gap: 'space.075',
	alignItems: 'center',
	':hover': {
		background: token('color.background.neutral.subtle.hovered'),
		color: 'color.text.subtle',
	},
	':active': {
		background: token('color.background.neutral.subtle.pressed'),
		color: 'color.text.subtle',
	},
	':focus': {
		outlineOffset: 'space.0',
	},
	':disabled': {
		background: token('color.background.disabled'),
		color: 'color.text.disabled',
	},
});

const selectedStyles = xcss({
	background: token('color.background.selected'),
	border: `1px solid ${token('color.border.selected')}`,
	color: 'color.text.selected',
	cursor: 'default',
	':hover': {
		background: token('color.background.selected'),
		color: 'color.text.selected',
	},
});

const dropdownItemWrapper = xcss({
	minWidth: '128px',
});
