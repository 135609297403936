import React, { useCallback, useRef, type ReactNode } from 'react';
import { v4 as uuid } from 'uuid';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { ButtonGroup } from '@atlaskit/button';
import Button from '@atlaskit/button/new';
import {
	AIEventsInstrumentationProvider,
	useAIEventsInstrumentation,
} from '@atlassian/ai-analytics';
import type { SuggestedCommentReplyAgentCommentType } from '@atlassian/jira-ai-assistance-service-client/src/services/invoke-agent/suggested-comment-reply-agent/types.tsx';
import AIContainer from '@atlassian/jira-atlassian-intelligence/src/common/ui/ai-container/index.tsx';
import {
	CORE_PROJECT,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { DESCRIPTION, ASSIGNEE } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { fieldSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import { totalCommentsSelector } from '@atlassian/jira-issue-view-store/src/selectors/comment-selector.tsx';
import {
	ContextualAnalyticsData,
	fireTrackAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import {
	useProjectKey,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { AI_COMMENTS_FEATURE } from '../../common/constants.tsx';
import { CannedCommentsPlaceholder } from '../../common/ui/canned-comment-placeholder/index.tsx';
import {
	useAICommentsStates,
	JiraIssueAICommentsContainer,
} from '../../controllers/use-ai-comments-context/index.tsx';
import { hasSuggestedComment, useAIComments } from '../../services/use-ai-comments/index.tsx';
import AIGeneratedComment from './ai-generated-comment/index.tsx';

type CommentEditorWithAIProps = {
	onChange?: (value: ADF) => void;
	onExpanded?: () => void;
	placeholder?: string;
};

export const CommentEditorWithAI = ({
	onChange,
	onExpanded,
	placeholder,
}: CommentEditorWithAIProps) => {
	const { formatMessage } = useIntl();
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);
	const { fetchSuggestedComments } = useAIComments();
	const [
		{
			availableCommentTypes,
			suggestedComments,
			availableCommentTypeOptions,
			currentCommentType,
			showGeneratedComment,
			isLoading,
		},
		{
			setSuggestedComments,
			setCurrentCommentType,
			acceptSuggestedComment,
			setShowGeneratedComment,
			setIsLoading,
		},
	] = useAICommentsStates();

	const {
		trackAIInteractionInit,
		trackAIResultAction,
		trackAIInteractionDismiss,
		trackAIResultError,
	} = useAIEventsInstrumentation();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onFail = useCallback(
		(type: SuggestedCommentReplyAgentCommentType) => (error: Error) => {
			setShowGeneratedComment(false);

			trackAIResultError(
				{
					aiErrorMessage: error?.message,
				},
				{
					attributes: {
						aiExperienceName: type,
					},
				},
			);
		},
		[setShowGeneratedComment, trackAIResultError],
	);

	const onClick = useCallback(
		(type: SuggestedCommentReplyAgentCommentType) => {
			trackAIInteractionInit({
				attributes: {
					aiExperienceName: type,
				},
			});
			setCurrentCommentType(type);
			setShowGeneratedComment(true);
			fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'aiCannedComment', {
				commentType: type,
			});

			if (
				!hasSuggestedComment(type, suggestedComments) &&
				!isLoading &&
				(projectType === CORE_PROJECT || projectType === SOFTWARE_PROJECT)
			) {
				setIsLoading(true);
				fetchSuggestedComments({
					issueKey,
					projectType,
					selectedType: type,
					commentTypes: availableCommentTypes,
					onFail: onFail(type),
				}).then((data) => {
					setSuggestedComments(data);
					setIsLoading(false);
				});
			}
		},
		[
			availableCommentTypes,
			createAnalyticsEvent,
			fetchSuggestedComments,
			isLoading,
			issueKey,
			onFail,
			projectType,
			setCurrentCommentType,
			setIsLoading,
			setShowGeneratedComment,
			setSuggestedComments,
			suggestedComments,
			trackAIInteractionInit,
		],
	);

	const onDismiss = useCallback(
		(isDiscard: boolean) => {
			trackAIInteractionDismiss({
				attributes: {
					aiExperienceName: currentCommentType ?? undefined,
					dismissedAction: isDiscard ? 'discardButtonClicked' : 'cancelButtonClicked',
				},
			});
			setShowGeneratedComment(false);
			setCurrentCommentType(null);
			onExpanded?.();
		},
		[
			trackAIInteractionDismiss,
			currentCommentType,
			setShowGeneratedComment,
			setCurrentCommentType,
			onExpanded,
		],
	);

	const onInsertComment = useCallback(
		(comment: ADF) => {
			trackAIResultAction('insert', {
				attributes: {
					aiExperienceName: currentCommentType ?? undefined,
				},
			});
			setShowGeneratedComment(false);
			currentCommentType && acceptSuggestedComment(currentCommentType);
			onExpanded?.();
			onChange?.(comment);
		},
		[
			trackAIResultAction,
			currentCommentType,
			setShowGeneratedComment,
			acceptSuggestedComment,
			onExpanded,
			onChange,
		],
	);

	return (
		<AIContainer
			shouldFitContainer
			spacing="none"
			testId="canned-comments.ui.ai-comments.ai-container"
			isLoading={isLoading && showGeneratedComment}
			hasNewBorderExperience
		>
			{showGeneratedComment && (
				<AIGeneratedComment
					isLoading={isLoading}
					onDismiss={onDismiss}
					onInsertComment={onInsertComment}
				/>
			)}
			{!showGeneratedComment && (
				<CannedCommentsPlaceholder
					onClick={onExpanded}
					onFocus={onExpanded}
					placeholder={placeholder}
					hiddenBorder
					renderCannedCommentOptions={() => (
						<ButtonGroup label="">
							{availableCommentTypeOptions.map((option) => (
								<Button spacing="compact" onClick={() => onClick(option.value)} key={option.value}>
									{formatMessage(option.message)}
								</Button>
							))}
						</ButtonGroup>
					)}
				/>
			)}
		</AIContainer>
	);
};

export const CommentEditorWithAIContext = ({ children }: { children: ReactNode }) => {
	const singleInstrumentationId = useRef(uuid());
	const attributes = {
		aiFeatureName: AI_COMMENTS_FEATURE,
	};
	const issueKey = useIssueKey();
	return (
		<ReduxConnectedJiraIssueAICommentsContainer scope={issueKey}>
			<ContextualAnalyticsData attributes={attributes}>
				<AIEventsInstrumentationProvider
					config={{
						...attributes,
						product: 'jira',
						subproduct: 'jira',
						proactiveGeneratedAI: 0,
						userGeneratedAI: 1,
						isAIFeature: 1,
						singleInstrumentationID: singleInstrumentationId.current,
						customAnalyticsFire: (payload, event) => fireTrackAnalytics(event, payload),
					}}
				>
					{children}
				</AIEventsInstrumentationProvider>
			</ContextualAnalyticsData>
		</ReduxConnectedJiraIssueAICommentsContainer>
	);
};

const ReduxConnectedJiraIssueAICommentsContainer = connect(
	(state) => ({
		totalOfComments: totalCommentsSelector(state),
		hasMissingAssignee: fieldSelector(ASSIGNEE)(state)?.value === null,
		hasMissingDescription: fieldSelector(DESCRIPTION)(state)?.adfValue?.content.length === 0,
	}),
	{},
)(JiraIssueAICommentsContainer);
