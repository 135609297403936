import { userPreferenceAPI } from '@atlassian/jira-user-preferences-services/src/utils/index.tsx';
import { useService } from '@atlassian/jira-common-services/src/use-service/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { PROPERTY_KEY } from '../../common/constants.tsx';

const deleteRequest = async () => {
	try {
		return await userPreferenceAPI.remove(PROPERTY_KEY);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			error,
			meta: {
				id: 'deleteDismissData',
				teamName: 'ITOps Phantom',
				packageName: 'jiraAutomationDiscoverability',
			},
		});
	}
};
export const useDeleteDismissDataService = () => {
	const { loading, error, data, fetch } = useService(deleteRequest);

	return { loading, error, data, removeDismissData: fetch };
};
