import { useCallback } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { toFlagId, useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import {
	fireOperationalAnalytics,
	fireTrackAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { postAssignInvitedUsers } from '../../services/assign-invited-users/index.tsx';
import { messages } from './messages.tsx';
import type { AssignInvitedUserRequest } from './types.tsx';

export const ASSIGN_INVITED_USER_ERROR_FLAG_ID = toFlagId('ASSIGN_INVITED_USER_ERROR');

export const useAssignInvitedUser = ({ analyticsSource }: { analyticsSource: string }) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagsService();

	return useCallback(
		async ({ issueKey, userId }: AssignInvitedUserRequest) => {
			try {
				await postAssignInvitedUsers({ issueKey, invitedUserId: userId });

				const analyticsEvent = createAnalyticsEvent({});
				const attributes = {
					fieldKey: 'assignee',
					fieldType: 'user',
					assignInvitedUserSource: analyticsSource,
					isAssignInvitedUser: true,
				};
				// intentionally firing the event even if BE call fails to update the field
				fireTrackAnalytics(analyticsEvent, 'field updated', attributes);
				fireOperationalAnalytics(analyticsEvent, 'field updateSucceeded', attributes);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				fireErrorAnalytics({
					meta: {
						id: 'useAssignInvitedUser',
						packageName: 'jiraInviteAndAssign',
						teamName: 'jlove-makkuro',
					},
					error,
					attributes: {
						analyticsSource,
					},
				});

				showFlag({
					id: ASSIGN_INVITED_USER_ERROR_FLAG_ID,
					type: 'error',
					title: formatMessage(
						expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.errorFlagTitleIssueTermRefresh
							: messages.errorFlagTitle,
					),
					description: formatMessage(messages.errorFlagDescription),
					isAutoDismiss: true,
					messageId: 'invite-and-assign.controllers.assign-invited-user.show-flag.error',
					messageType: 'transactional',
				});
			}
		},
		[createAnalyticsEvent, formatMessage, showFlag, analyticsSource],
	);
};
