import { useCallback } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useFlagsService, toFlagId, type FlagConfiguration } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';
import {
	ClipboardValidationError,
	type ClipboardValidationErrorType,
} from './clipboard-validation-error.tsx';

const uniqueFlagId = toFlagId('JSC_COPY_PASTE_FLAG');

export const assertUnreachable = (_: never): never => {
	throw new Error('Unreachable code reached');
};

const useGetErrorFlag = (fieldName: string) => {
	const { formatMessage } = useIntl();
	return useCallback(
		(errorType: ClipboardValidationErrorType): Omit<FlagConfiguration, 'id'> => {
			switch (errorType) {
				case 'copyOperationUnsupported':
					return {
						type: 'error',
						title: formatMessage(messages.copyErrorTitle),
						description: formatMessage(messages.copyValidationError, { name: fieldName }),
						messageId: 'issue-field-copy-paste.controller.flag.error.copy-validation-error',
						messageType: 'transactional',
					};
				case 'cutOperationUnsupported':
					return {
						type: 'error',
						title: formatMessage(messages.cutErrorTitle),
						description: formatMessage(messages.cutValidationError, { name: fieldName }),
						messageId: 'issue-field-copy-paste.controller.flag.error.cut-validation-error',
						messageType: 'transactional',
					};
				case 'cutNonEditableError':
					return {
						type: 'error',
						title: formatMessage(messages.cutErrorTitle),
						description: formatMessage(messages.cutNonEditableError, { name: fieldName }),
						messageId: 'issue-field-copy-paste.controller.flag.error.cut-non-editable-error',
						messageType: 'transactional',
					};
				case 'pasteOperationUnsupported':
				case 'pasteUnexpectedComponentType':
				case 'pasteUnsupportedFormat':
					return {
						type: 'error',
						title: formatMessage(messages.pasteErrorTitle),
						description: formatMessage(messages.pasteUnsupportedError, { name: fieldName }),
						messageId: 'issue-field-copy-paste.controller.flag.error.paste-unsupported-error',
						messageType: 'transactional',
					};
				default:
					return assertUnreachable(errorType);
			}
		},
		[fieldName, formatMessage],
	);
};

export const useClipboardErrorReporting = (fieldName: string, fieldType: string) => {
	const { dismissFlag, showFlag } = useFlagsService();
	const getErrorFlag = useGetErrorFlag(fieldName);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	return useCallback(
		(error?: Error) => {
			if (error instanceof ClipboardValidationError) {
				fireTrackAnalytics(createAnalyticsEvent({}), 'inlineEdit clipboardValidationError', {
					clipboardValidationErrorType: error.type,
					fieldType,
				});
				dismissFlag(uniqueFlagId);
				showFlag({
					...getErrorFlag(error.type),
					id: uniqueFlagId,
				});
			} else {
				const ugcSafeMessage = `Failed to perform clipboard operation for field of type ${fieldType}`;

				fireErrorAnalytics({
					meta: {
						id: 'clipboardOperationError',
						packageName: 'jiraIssueFieldCopyPaste',
						teamName: 'empanada',
					},
					attributes: {
						message: ugcSafeMessage,
						fieldType,
					},
					error,
					sendToPrivacyUnsafeSplunk: true,
				});
			}
		},
		[createAnalyticsEvent, dismissFlag, fieldType, getErrorFlag, showFlag],
	);
};
