import React, { memo, useCallback } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueAttachments } from '@atlassian/jira-issue-attachments-base/src/services/attachments-service/main.tsx';
import { isAttachmentNumberExceedLimit } from '@atlassian/jira-issue-attachments-limit/src/common/utils.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { ATTACHMENTS_VIEW } from '@atlassian/jira-issue-shared-types/src/common/types/user-preferences-type.tsx';
import { useUserPreferencesValue } from '@atlassian/jira-issue-user-preference-services/src/main.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { expVal } from '@atlassian/jira-feature-experiments';
import { LIST_VIEW, STRIP_VIEW, GRID_VIEW } from '../../../constants.tsx';
import messages from './messages.tsx';
import type { Props, SwitchToNewViewType } from './types.tsx';

const SwitchFromListView = memo(({ switchToNewView }: { switchToNewView: SwitchToNewViewType }) => {
	const { formatMessage } = useIntl();
	return (
		<>
			<DropdownItem onClick={switchToNewView(STRIP_VIEW, LIST_VIEW)}>
				{formatMessage(messages.switchToStripView)}
			</DropdownItem>
			<DropdownItem onClick={switchToNewView(GRID_VIEW, LIST_VIEW)}>
				{formatMessage(messages.switchToGridView)}
			</DropdownItem>
		</>
	);
});

const SwitchFromStripView = memo(
	({ switchToNewView }: { switchToNewView: SwitchToNewViewType }) => {
		const { formatMessage } = useIntl();
		return (
			<>
				<DropdownItem onClick={switchToNewView(LIST_VIEW, STRIP_VIEW)}>
					{formatMessage(messages.switchToListView)}
				</DropdownItem>
				<DropdownItem onClick={switchToNewView(GRID_VIEW, STRIP_VIEW)}>
					{formatMessage(messages.switchToGridView)}
				</DropdownItem>
			</>
		);
	},
);

const SwitchFromGridView = memo(({ switchToNewView }: { switchToNewView: SwitchToNewViewType }) => {
	const { formatMessage } = useIntl();
	return (
		<>
			<DropdownItem onClick={switchToNewView(LIST_VIEW, GRID_VIEW)}>
				{formatMessage(messages.switchToListView)}
			</DropdownItem>
			<DropdownItem onClick={switchToNewView(STRIP_VIEW, GRID_VIEW)}>
				{formatMessage(messages.switchToStripView)}
			</DropdownItem>
		</>
	);
});

export const SwitchViewItem = memo<Props>(({ refreshAttachments }: Props) => {
	const issueKey = useIssueKey();
	const { formatMessage } = useIntl();
	const [value, { setValue }] = useUserPreferencesValue(ATTACHMENTS_VIEW);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const switchToNewView = useCallback(
		(view: string, fromView?: string) => () => {
			setValue(view);
			refreshAttachments(issueKey);
			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'dropdownItem',
			});

			// once we have 3 views we will start storing the fromView to identify the source of the switch
			if (fromView) {
				fireUIAnalytics(analyticsEvent, 'switchAttachmentsView', { view, fromView });
			} else {
				fireUIAnalytics(analyticsEvent, 'switchAttachmentsView', { view });
			}
		},
		[createAnalyticsEvent, issueKey, refreshAttachments, setValue],
	);

	const [
		{
			value: { totalCount },
		},
	] = useIssueAttachments(issueKey);

	/**
	 * As per JIV-14783 we wish to hide the switch to strip view button
	 * When attachments exceed the limit
	 */
	if (isAttachmentNumberExceedLimit(totalCount)) {
		return null;
	}

	const withGridView = () => {
		switch (value) {
			case LIST_VIEW:
				return <SwitchFromListView switchToNewView={switchToNewView} />;
			case STRIP_VIEW:
				return <SwitchFromStripView switchToNewView={switchToNewView} />;
			case GRID_VIEW:
			default:
				return <SwitchFromGridView switchToNewView={switchToNewView} />;
		}
	};

	const withoutGridView = () => {
		switch (value) {
			case LIST_VIEW: {
				return (
					<DropdownItem onClick={switchToNewView(STRIP_VIEW)}>
						{formatMessage(messages.switchToStripView)}
					</DropdownItem>
				);
			}
			case STRIP_VIEW:
			default:
				return (
					<DropdownItem onClick={switchToNewView(LIST_VIEW)}>
						{formatMessage(messages.switchToListView)}
					</DropdownItem>
				);
		}
	};

	return expVal('jira_attachments_grid_view_exp', 'isEnabled', false)
		? withGridView()
		: withoutGridView();
});

export default SwitchViewItem;
