export const MAX_COLUMN_SIZE = 10;
export const DEFAULT_COLUMN_SIZE = 5;
export const MIN_COLUMN_SIZE = 2;
export const MAX_ROW_SIZE = 3;
export const MIN_ROW_SIZE = 1;
export const MAX_PAGE_SIZE = MAX_COLUMN_SIZE * MAX_ROW_SIZE;
export const DEFAULT_PAGE_SIZE = DEFAULT_COLUMN_SIZE * MAX_ROW_SIZE;

export const GRID_ITEM_DIMENSIONS = {
	minWidth: 132,
	height: 125,
	spacing: 8,
};

export const DEBOUNCE_DURATION = 300;
