import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const AIOpsLinkIncidentSuggestionsEntrypoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(/* webpackChunkName: "async-aiops-link-incident-suggestions" */ './src/ui/index.tsx'),
	),
	getPreloadProps: () => ({}),
});
