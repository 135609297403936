import React, { type FC } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import Badge from '@atlaskit/badge';
import { expVal } from '@atlassian/jira-feature-experiments';
import {
	useIsBusinessIssue,
	useIsSoftwareIssue,
} from '@atlassian/jira-issue-util-hooks/src/index.tsx';

type Props = {
	unreadCommentsCount?: number;
	isWithinDropdown?: boolean;
};

const MAX_UNREAD_COUNT = 4;

export const UnreadCommentsBadge: FC<Props> = (props: Props) => {
	const { unreadCommentsCount, isWithinDropdown } = props;
	const isJSW = useIsSoftwareIssue();
	const isJWM = useIsBusinessIssue();
	if (
		(!isJSW && !isJWM) ||
		!unreadCommentsCount ||
		!expVal('thor_issue_view_realtime_updates_experiment', 'isEnabled', false)
	) {
		return null;
	}

	return (
		<Box as="span" xcss={[badgeStyles, isWithinDropdown && dropdownBadgeStyles]}>
			<Badge max={MAX_UNREAD_COUNT} appearance="primary">
				{unreadCommentsCount}
			</Badge>
		</Box>
	);
};

const badgeStyles = xcss({
	marginLeft: 'space.075',
});

const dropdownBadgeStyles = xcss({ marginLeft: 'space.150' });
