import keyBy from 'lodash/keyBy';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import { wrapPromiseWithFetchInteraction } from '@atlassian/jira-polaris-lib-analytics/src/common/utils/interaction/index.tsx';
import {
	performGetIssueTypesForProject,
	performGetIssueTypesForProjects,
} from '../../services/jira/fetch-issue-types.tsx';
import type { State } from '../types.tsx';
import { transformIssueTypeResponse } from './utils.tsx';

export const fetchIssueTypesForProject =
	(projectId: ProjectId) =>
	async ({ setState, getState }: StoreActionApi<State>) => {
		try {
			const issueTypes = await performGetIssueTypesForProject(projectId);

			const issueTypesById = keyBy(issueTypes.map(transformIssueTypeResponse), ({ id }) => id);
			setState({
				issueTypesById: {
					...getState().issueTypesById,
					...issueTypesById,
				},
				issueTypesByProjectId: {
					...getState().issueTypesByProjectId,
					[projectId]: Object.keys(issueTypesById),
				},
			});
		} catch (error) {
			logSafeErrorWithoutCustomerDataWrapper(
				'polaris.error.controllers.issue-types.actions.fetch-issue-types-for-project',
				'Failed to fetch issue types for project',
				error instanceof Error ? error : new Error('Error while fetching issue types for project'),
			);

			throw error;
		}
	};

export const fetchIssueTypesForProjects =
	(projectIds: ProjectId[]) =>
	async ({ setState, getState }: StoreActionApi<State>) => {
		const prevState = getState();

		try {
			setState({
				issueTypesByProjectId: {
					...prevState.issueTypesByProjectId,
					...projectIds.reduce<Record<ProjectId, string[]>>((acc, projectId) => {
						acc[projectId] = [];
						return acc;
					}, {}),
				},
			});

			const { projects } = await wrapPromiseWithFetchInteraction(
				performGetIssueTypesForProjects(projectIds),
				'getIssueTypesForProjects',
			);

			const { issueTypesById, issueTypesByProjectId } = projects.reduce<
				Pick<State, 'issueTypesById' | 'issueTypesByProjectId'>
			>(
				(acc, { projectId, issueTypes }) => ({
					issueTypesById: {
						...acc.issueTypesById,
						...keyBy(issueTypes.map(transformIssueTypeResponse), ({ id }) => id),
					},
					issueTypesByProjectId: {
						...acc.issueTypesByProjectId,
						[projectId]: issueTypes.map(({ id }) => String(id)),
					},
				}),
				{
					issueTypesById: {},
					issueTypesByProjectId: {},
				},
			);

			setState({
				issueTypesById: {
					...getState().issueTypesById,
					...issueTypesById,
				},
				issueTypesByProjectId: {
					...getState().issueTypesByProjectId,
					...issueTypesByProjectId,
				},
			});
		} catch (error) {
			logSafeErrorWithoutCustomerDataWrapper(
				'polaris.error.controllers.issue-types.actions.fetch-issue-types-for-projects',
				'Failed to fetch issue types for projects',
				error instanceof Error ? error : new Error('Error while fetching issue types for projects'),
			);

			throw error;
		}
	};
