import React, { forwardRef, type Ref } from 'react';
import { styled } from '@compiled/react';
import type { EntryPointProps } from 'react-relay';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import TextField from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { NUMBER_WARNING_ICON_TEST_ID } from '../../common/constants.tsx';
import type { NumberEditViewProps } from './types.tsx';

export const NumberEditView = forwardRef(
	(props: NumberEditViewProps, ref: Ref<HTMLInputElement>) => {
		const {
			defaultValue,
			isInvalid = false,
			autoFocus = true,
			isDisabled = false,
			onWheel,
			onChange,
			onBlur,
			ariaLabelledBy,
			label,
			spacing,
		} = props;

		const value = defaultValue == null || defaultValue === '' ? '' : Number(defaultValue);

		return (
			<Container>
				<TextField
					ref={ref}
					aria-labelledby={ariaLabelledBy}
					aria-label={label}
					autoFocus={autoFocus}
					isCompact={spacing === 'compact'}
					isDisabled={isDisabled}
					isInvalid={isInvalid}
					step="any"
					type="number"
					value={value}
					onWheel={onWheel}
					elemAfterInput={
						isInvalid === true ? (
							<WarningWrapper data-testid={NUMBER_WARNING_ICON_TEST_ID}>
								<WarningIcon
									label=""
									color={token('color.icon.warning')}
									LEGACY_size="medium"
									spacing="spacious"
								/>
							</WarningWrapper>
						) : null
					}
					onChange={onChange}
					onBlur={onBlur}
				/>
			</Container>
		);
	},
);

const NumberEditViewEntryPoint = ({ props }: EntryPointProps<{}, {}, NumberEditViewProps, {}>) => (
	<NumberEditView {...props} />
);

export default NumberEditViewEntryPoint;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Container = styled.div({
	width: '100%',
	cursor: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const WarningWrapper = styled.div({
	marginLeft: token('space.negative.050'),
	marginRight: token('space.050'),
});
