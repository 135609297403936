import { createJpdContainer } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/hooks/index.tsx';
import { createPolarisStore } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/store/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { throttledFetchSnippetProviders } from './actions/fetch-snippet-providers.tsx';
import actions from './actions/index.tsx';
import { loadFields } from './actions/load-fields.tsx';
import type { Props, State } from './types.tsx';
import { syncFieldsIssueTypes } from './actions/sync-fields-issue-types/index.tsx';

export type Actions = typeof actions;

const initialState: State = {
	meta: {
		initialized: false,
		loading: false,
		loadingSnippetProviders: false,
		error: undefined,
		loadingProps: undefined,
		connectionsLoadingFieldsAssociations: [],
	},
	fields: {},
	fieldValueDecorations: {},
	snippetProviders: undefined,
	highlightedFields: [],
	containerProps: undefined,
	throttledFetchSnippetProviders: undefined,
	newlyAddedGlobalFields: [],
	connectionsFieldsAssociations: {},
};

export const FieldStore = createPolarisStore<State, Actions>({
	initialState,
	actions,
	name: 'PolarisFieldStore',
});

const { Container, useActions, createHook, createHigherLevelHook } = createJpdContainer<
	Props,
	State,
	Actions
>(FieldStore, {
	onInit:
		() =>
		({ dispatch }) => {
			dispatch(loadFields());
			dispatch(throttledFetchSnippetProviders());
		},
	onUpdate:
		() =>
		({ dispatch, getState }, props) => {
			if (fg('jpd_issue_types_ga')) {
				const { prevContainerProps } = getState();

				if (
					prevContainerProps?.issueTypeIds &&
					props.issueTypeIds !== prevContainerProps.issueTypeIds
				) {
					dispatch(syncFieldsIssueTypes());
				}
			}

			dispatch(loadFields());
			dispatch(throttledFetchSnippetProviders());
		},
});

export const useFieldActions = useActions;
export const createFieldHook = createHook;
export const createHigherLevelFieldHook = createHigherLevelHook;
export const FieldContainer = Container;
