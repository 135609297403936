import { useEffect, useState } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import { useFetchEarliestOnboardedProject } from './services/get-earliest-onboarded-project-timestamp/index.tsx';

export const JPD_TEMPLATE_DEFAULT = 'v20';
export const JPD_TEMPLATE_V21 = 'v21';

export const JPD_ONBOARDING_TEMPLATES = [JPD_TEMPLATE_DEFAULT, JPD_TEMPLATE_V21];

type TemplateVersionMap = {
	[version: string]: {
		default: string;
	};
};

const DEFAULT_TEMPLATE_TO_VERSION: TemplateVersionMap = {
	v20: {
		default: JPD_TEMPLATE_DEFAULT,
	},
	v21: {
		default: JPD_TEMPLATE_V21,
	},
};

export const getDefaultProjectTemplateVersionVariant = () => {
	const templateVersion = fg('polaris-issue-terminology-refresh') ? 'v21' : 'v20';
	return DEFAULT_TEMPLATE_TO_VERSION[templateVersion].default;
};

// Returns null in case of loading status
export const useShouldShowOnboardingExperience = (projectAri: Ari | null): boolean | null => {
	const [fetchEarliestOnboardedProjectTimeStamp] = useFetchEarliestOnboardedProject();
	const [earliestOnboardedProjectTemplate, setEarliestOnboardedProjectTemplate] = useState<
		undefined | null | string
	>();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (!projectAri) {
			return;
		}

		fetchEarliestOnboardedProjectTimeStamp(projectAri)
			.then(({ template }) => setEarliestOnboardedProjectTemplate(template))
			.catch((error) => {
				logSafeErrorWithoutCustomerDataWrapper(
					'polaris.onboarding.experiment-check.error',
					'Failed to fetch earliest onboarded project for current cloud id',
					error,
				);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [fetchEarliestOnboardedProjectTimeStamp, projectAri]);

	if (isLoading) {
		return null;
	}

	return (
		earliestOnboardedProjectTemplate === null ||
		JPD_ONBOARDING_TEMPLATES.includes(earliestOnboardedProjectTemplate ?? '')
	);
};
