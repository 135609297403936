import type { IssueRemoteData } from '@atlassian/jira-issue-view-common-types/src/issue-remote-data-type.tsx';
import {
	type FetchIssueRemoteDataSuccess,
	type FetchIssueRemoteDataFailure,
	type FetchIssueRemoteDataRequest,
	type FetchIssueRemoteDataRequestLoadingComplete,
	FETCH_ISSUE_REMOTE_DATA_SUCCESS,
	FETCH_ISSUE_REMOTE_DATA_FAILURE,
	FETCH_ISSUE_REMOTE_DATA_REQUEST,
	FETCH_ISSUE_REMOTE_DATA_REQUEST_LOADING_COMPLETE,
} from '../../actions/issue-remote-data-actions.tsx';

type Action =
	| FetchIssueRemoteDataSuccess
	| FetchIssueRemoteDataFailure
	| FetchIssueRemoteDataRequest
	| FetchIssueRemoteDataRequestLoadingComplete;

export const initialState = {
	isLoadingComplete: false,
	isLoading: false,
} as const;

// reducer that contains information for issue-remote-data (web-links and confluence page links)
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: IssueRemoteData = initialState, action: Action) => {
	switch (action.type) {
		case FETCH_ISSUE_REMOTE_DATA_REQUEST_LOADING_COMPLETE:
		case FETCH_ISSUE_REMOTE_DATA_SUCCESS:
		case FETCH_ISSUE_REMOTE_DATA_FAILURE: {
			return {
				...state,
				isLoadingComplete: true,
				isLoading: false,
			};
		}

		case FETCH_ISSUE_REMOTE_DATA_REQUEST: {
			return {
				...state,
				isLoading: true,
			};
		}

		default:
			return state;
	}
};
