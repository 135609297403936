import React, { useMemo, useCallback } from 'react';
import { graphql, usePaginationFragment } from 'react-relay';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { mentionedConfluenceLinksSection_issueViewBase$key } from '@atlassian/jira-relay/src/__generated__/mentionedConfluenceLinksSection_issueViewBase.graphql';
import type { IssueConfluenceMentionedLinksQuery } from '@atlassian/jira-relay/src/__generated__/IssueConfluenceMentionedLinksQuery.graphql';
import { CONFLUENCE_PAGE } from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import {
	ConfluenceLinksSection,
	type ConfluenceLinksSectionBaseProps,
} from '../confluence-links-section/index.tsx';
import messages from '../messages.tsx';

export const MentionedConfluenceLinksSection = ({
	issueFragment,
	...props
}: ConfluenceLinksSectionBaseProps & {
	issueFragment: mentionedConfluenceLinksSection_issueViewBase$key;
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { data, loadNext } = usePaginationFragment<
		IssueConfluenceMentionedLinksQuery,
		mentionedConfluenceLinksSection_issueViewBase$key
	>(
		graphql`
			fragment mentionedConfluenceLinksSection_issueViewBase on JiraIssue
			@refetchable(queryName: "IssueConfluenceMentionedLinksQuery")
			@argumentDefinitions(first: { type: "Int", defaultValue: 10 }, after: { type: "String" }) {
				confluenceMentionedLinks(first: $first, after: $after)
					@connection(key: "IssueConfluenceContent__confluenceMentionedLinks")
					@optIn(to: "JiraIssueConfluenceMentionedLinks") {
					totalCount
					pageInfo {
						hasNextPage
					}
					edges {
						node {
							id
							href
						}
					}
				}
			}
		`,
		issueFragment,
	);

	const onShowMore = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'button',
		});

		const total = data?.confluenceMentionedLinks?.totalCount ?? 0;

		fireUIAnalytics(analyticsEvent, 'mentionedConfluencePagesShowMore', {
			totalCount: total,
		});

		loadNext(total);
	}, [createAnalyticsEvent, data, loadNext]);

	const mentionedPages = useMemo(
		() =>
			data?.confluenceMentionedLinks?.edges
				?.map((edge) => {
					const node = edge?.node;
					if (!node) {
						return null;
					}

					const href = node.href ?? '';

					return {
						id: node.id,
						href,
						// The globalID is used to delete the page link. However, it's not currently avaliable as a field in the GraphQL API.
						// The temporary solution is to use the "internal" ID and it is obtained by extracting it from the ARI.
						// The globalId will be available as part of the JIV-20161
						title: href,
						globalId: node.id,
						type: CONFLUENCE_PAGE,
					};
				})
				.filter(Boolean) ?? [],
		[data],
	);

	return (
		<ConfluenceLinksSection
			{...props}
			pages={mentionedPages}
			onShowMore={onShowMore}
			hasNextMentionedPage={data?.confluenceMentionedLinks?.pageInfo?.hasNextPage ?? false}
			headingDescriptor={messages.mentionedPages}
			showMoreButtonDescriptor={messages.showMore}
		/>
	);
};
