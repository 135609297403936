/**
 * @generated SignedSource<<ddab01559df7b2cff06ff714db553ea0>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type AssignToMeInlineEdit$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AssignToMeInlineEditInner_rootFragmentKey">;
  readonly " $fragmentType": "AssignToMeInlineEdit";
};
export type AssignToMeInlineEdit$key = {
  readonly " $data"?: AssignToMeInlineEdit$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssignToMeInlineEdit">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "AssignToMeInlineEdit",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "AssignToMeInlineEditInner_rootFragmentKey"
    }
  ],
  "type": "Query"
};

(node as any).hash = "fb9f18be477b973d40f087abb090b822";

export default node;
