import React, { type FC } from 'react';
import { Box, Pressable, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import type { ActivityItem } from '@atlassian/jira-issue-view-common-types/src/activity-item-type.tsx';
import {
	ContextualAnalyticsData,
	fireScreenAnalyticsDeferred,
	MountEvent,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import { useIntl } from '@atlassian/jira-intl';
import { COMMENTS } from '@atlassian/jira-issue-view-common-constants/src/activity-items.tsx';
import type { SelectActivityItem } from '../../common/ui/types.tsx';
import { messages } from '../../messages.tsx';
import { UnreadCommentsBadge } from '../unread-comments-badge/index.tsx';
import { ActivityFeedDropdown } from './dropdown.tsx';

type BaseProps = {
	items: ActivityItem[];
	selectedItem: ActivityItem;
	onSelectActivityItem: SelectActivityItem;
	unreadCommentsCount?: number;
};

type Props =
	| (BaseProps & { showDetailsTabButton: false })
	| (BaseProps & {
			showDetailsTabButton: true;
			showDetails: boolean;
			setShowDetails: (showDetails: boolean) => void;
	  });

export const ActivityFeedButtonsWithDropdown: FC<Props> = (props: Props) => {
	const { formatMessage } = useIntl();
	const isCompact = false;

	return (
		<ContextualAnalyticsData sourceType={SCREEN} sourceName="activityFeedButtons">
			<MountEvent onMount={(event) => fireScreenAnalyticsDeferred(event)} />
			<Box xcss={containerStyles}>
				{props.showDetailsTabButton && (
					<Pressable
						xcss={[baseStyles, !isCompact && wideStyles, props.showDetails && selectedStyles]}
						onClick={(_event, _analyticsEvent) => props.setShowDetails(true)}
					>
						{formatMessage(messages.details)}
					</Pressable>
				)}
				<Pressable
					xcss={[
						baseStyles,
						!isCompact && wideStyles,
						!(props.showDetailsTabButton && props.showDetails) && selectedStyles,
					]}
					onClick={(_event, _analyticsEvent) => {
						if (props.showDetailsTabButton && props.showDetails) {
							props.setShowDetails(false);
						}
					}}
				>
					{props.selectedItem.name}
					{props.selectedItem.type === COMMENTS && (
						<UnreadCommentsBadge unreadCommentsCount={props.unreadCommentsCount} />
					)}
				</Pressable>
				<ActivityFeedDropdown
					items={props.items}
					selectedItem={props.selectedItem}
					onSelectActivityItem={props.onSelectActivityItem}
					label={formatMessage(messages.filterBy)}
					unreadCommentsCount={props.unreadCommentsCount}
					{...(props.showDetailsTabButton && {
						showDetails: props.showDetails,
						setShowDetails: props.setShowDetails,
					})}
				/>
			</Box>
		</ContextualAnalyticsData>
	);
};

const containerStyles = xcss({
	display: 'inline-flex',
	gap: 'space.050',
	alignItems: 'center',
});

const baseStyles = xcss({
	background: token('color.background.neutral.subtle'),
	border: '1px solid transparent',
	borderRadius: token('border.radius.050'),
	color: 'color.text.subtle',
	height: '26px',
	fontWeight: 'font.weight.medium',
	padding: 'space.0',
	':hover': {
		background: token('color.background.neutral.subtle.hovered'),
		color: 'color.text.subtle',
	},
	':active': {
		background: token('color.background.neutral.subtle.pressed'),
		color: 'color.text.subtle',
	},
	':focus': {
		outlineOffset: 'space.0',
	},
	':disabled': {
		background: token('color.background.disabled'),
		color: 'color.text.disabled',
	},
});

const selectedStyles = xcss({
	background: token('color.background.selected'),
	border: `1px solid ${token('color.border.selected')}`,
	color: 'color.text.selected',
	cursor: 'default',
	':hover': {
		background: token('color.background.selected'),
		color: 'color.text.selected',
	},
});

const wideStyles = xcss({
	paddingLeft: 'space.150',
	paddingRight: 'space.150',
});
