/**
 * @generated SignedSource<<5e4c3cea2a734edf187e57b05df6a87b>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutAssigneeField_IssueViewAssigneeField_fragmentKey$data = {
  readonly __typename: "JiraSingleSelectUserPickerField";
  readonly fieldId: string;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"AssignToMeInlineEdit_fragmentKey" | "CommandPaletteAssignee" | "assignToMeInlineEdit_issueViewLayoutAssigneeField_fragmentKey" | "assignee_issueFieldAssigneeInlineEditFull_AssigneeInlineEditView_fragmentRef" | "commandPaletteAssignee_issueViewLayoutAssigneeField" | "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading">;
  readonly " $fragmentType": "ui_issueViewLayoutAssigneeField_IssueViewAssigneeField_fragmentKey";
};
export type ui_issueViewLayoutAssigneeField_IssueViewAssigneeField_fragmentKey$key = {
  readonly " $data"?: ui_issueViewLayoutAssigneeField_IssueViewAssigneeField_fragmentKey$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutAssigneeField_IssueViewAssigneeField_fragmentKey">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutAssigneeField_IssueViewAssigneeField_fragmentKey",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading"
    },
    {
      "kind": "FragmentSpread",
      "name": "assignee_issueFieldAssigneeInlineEditFull_AssigneeInlineEditView_fragmentRef"
    },
    {
      "kind": "FragmentSpread",
      "name": "assignToMeInlineEdit_issueViewLayoutAssigneeField_fragmentKey"
    },
    {
      "kind": "FragmentSpread",
      "name": "AssignToMeInlineEdit_fragmentKey"
    },
    {
      "kind": "FragmentSpread",
      "name": "commandPaletteAssignee_issueViewLayoutAssigneeField"
    },
    {
      "kind": "FragmentSpread",
      "name": "CommandPaletteAssignee"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "__typename"
    }
  ],
  "type": "JiraSingleSelectUserPickerField"
};

(node as any).hash = "6a8114e39a51e4c391a1ce9ded0afa94";

export default node;
