import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import { createGetTransitionForStatus } from '../../../workflow/selectors/transitions.tsx';
import { createGetField, getIssuesSimiliarStatusIdsByStatusId } from '../../selectors/fields.tsx';
import { createGetIssueType, createGetStatus } from '../../selectors/properties/index.tsx';
import type { State, Props } from '../../types.tsx';
import { updateFieldValue } from '../update-field-value/index.tsx';
import { updateStatus } from '../update-status/index.tsx';
import type { Status } from '../../../workflow/types.tsx';

export const updateField =
	<TFieldValueType,>(
		fieldKey: FieldKey,
		appendMultiValues: boolean,
		issueId: LocalIssueId,
		value?: TFieldValueType,
		removeValue?: TFieldValueType,
		// Note: the purpose of this parameter is to allow this function to be called in callbacks with the actual state.
		// If not provided
		overrideState?: State,
		onSuccess?: () => void,
		onError?: (arg1: Error) => void,
	) =>
	(
		{ getState, dispatch }: StoreActionApi<State>,
		props: Props,
	): {
		/**
		 * whether or not the updated issue will be filtered out after the update,
		 * or undefined if unknown
		 */
		filtered: boolean | undefined;
	} => {
		const field = createGetField(fieldKey)(getState(), props);

		if (field === undefined) {
			return { filtered: undefined };
		}

		if (field.type === FIELD_TYPES.STATUS) {
			if (value === undefined) {
				return { filtered: undefined };
			}

			const issueTypeSelector = createGetIssueType(issueId);
			const issueType = issueTypeSelector(overrideState || getState(), props);

			const getStatus = createGetStatus(issueId);
			const status = getStatus(overrideState || getState(), props);

			if (issueType === undefined || status === undefined) {
				return { filtered: undefined };
			}

			const getTransitionForStatusOLD = createGetTransitionForStatus(
				issueType.id,
				status,
				// @ts-expect-error - TS2345 - Argument of type 'TFieldValueType' is not assignable to parameter of type 'Status'.
				value,
			);

			let transition = getTransitionForStatusOLD({ transitions: props.workflowTransitions });

			if (fg('jpd-aurora-roadmap-inline-edit')) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const statusValue = value as Status | null;

				// In a multi projects context (like roadmap) all statuses from the projects
				// are merged into a list with only dictinct values, in order to avoid having
				// x amount of "Done" statuses even if they wont have the same id.
				// So only the first occurence of a specific status, considering its name and status category,
				// will be kept in the list.
				// Then when we update status here, the status contained in "value" is not garantee to be
				// from current issue workflow. That's why we get all existing similar statuses
				// and try to get a transition from current status to all of them. Only a transition for a status
				// of the same workflow is possible, so if it exists, that's the one we're keeping.
				const similarStatusIds =
					statusValue !== null
						? getIssuesSimiliarStatusIdsByStatusId(getState(), props)[statusValue.id]
						: undefined;

				const allStatuses = props.statusesById ?? {};

				transition = similarStatusIds
					?.map((statusId) => {
						const getTransitionForStatus = createGetTransitionForStatus(
							issueType.id,
							status,
							allStatuses[statusId],
						);

						return getTransitionForStatus({ transitions: props.workflowTransitions });
					})
					.find((t) => !!t);
			}

			if (transition !== undefined) {
				return dispatch(updateStatus(fieldKey, issueId, transition, onSuccess, onError));
			}
			return { filtered: undefined };
		}

		if (field.type === FIELD_TYPES.REPORTER) {
			if (value === undefined) {
				return { filtered: undefined };
			}
		}

		return dispatch(
			updateFieldValue({
				fieldKey,
				localIssueIds: [issueId],
				newValue: value,
				removeValue,
				appendMultiValues,
			}),
		);
	};
