import type { AvatarProps } from '@atlaskit/avatar-group';
import { getHostnameFromUrl } from '@atlassian/jira-issue-create-confluence-content/src/controllers/utils/getHostnameFromUrl.tsx';
import type { FailedRemoteLink } from '@atlassian/jira-issue-shared-types/src/common/types/remote-link-error-type.tsx';
import type { Page, Pages } from './types.tsx';

export const getPlaceholderAvatars = (numberOfPlaceholders: number): AvatarProps[] => {
	return Array(numberOfPlaceholders).fill({ name: '' });
};

const isPageError = (page: Page): page is FailedRemoteLink =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	(page as FailedRemoteLink).error !== undefined;

export const getPageHref = (page: Page) => (isPageError(page) ? page.link.href : page.href);

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
export const getCurrentHostname = () => window?.location?.hostname || '';

export const isCurrentSiteLink = (link: string) => {
	const hostname = getHostnameFromUrl(link);
	return hostname === getCurrentHostname();
};

export const getNewLinks = (pages: Pages, prevPages: Pages) => {
	const links = pages?.map(getPageHref) ?? [];
	const prevLinks = prevPages?.map(getPageHref) ?? [];
	return links.filter((link) => !prevLinks.includes(link));
};
