import React, { type ReactNode, useMemo } from 'react';
import { getAnalyticsAttributesFromExtension } from '@atlassian/jira-forge-ui-analytics/src/common/utils/get-analytics-attributes-from-extension/index.tsx';
import {
	PerformanceAnalyticsContainer,
	usePerformanceAnalytics,
} from '@atlassian/jira-forge-ui-analytics/src/controllers/performance-analytics/main.tsx';
import type { PerformanceAnalyticsContainerProps } from '@atlassian/jira-forge-ui-analytics/src/controllers/performance-analytics/types.tsx';
import { ViewAnalyticsWrapper } from '@atlassian/jira-forge-ui-analytics/src/ui/custom-field/index.tsx';
import type { ForgeUiAnalyticsAttributes } from '@atlassian/jira-forge-ui-types/src/common/types/analytics.tsx';
import type {
	CustomFieldLatest,
	CustomFieldTypeLatest,
} from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import {
	useAnalyticsSource,
	useIssueKey,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useSpaState } from '@atlassian/jira-spa-state-controller/src/common/index.tsx';
import { AnalyticsAttributesContextProvider } from '../atrributes-context/index.tsx';
import {
	getAdditionalPageSegmentLoadTimings,
	getPerformanceCustomData,
} from '../performance-analytics/index.tsx';

export const ISSUE_VIEW_RENDER_CONTEXT = 'issue-view';

export type CustomFieldAnalyticsWrapperProps = {
	extension: CustomFieldLatest | CustomFieldTypeLatest;
	children: ReactNode;
};

export const CustomFieldAnalyticsWrapper = ({
	extension,
	children,
}: CustomFieldAnalyticsWrapperProps) => {
	const source = useAnalyticsSource();
	const issueKey = useIssueKey();

	const analyticsAttributes: ForgeUiAnalyticsAttributes = useMemo(
		() => ({
			source,
			renderContext: ISSUE_VIEW_RENDER_CONTEXT,
			module: extension.type,
			fieldType: extension.properties.type,
			...(extension && getAnalyticsAttributesFromExtension(extension)),
		}),
		[extension, source],
	);

	const [{ currentPage: currentPageTiming }] = useSpaState();
	const [{ extensionsMeasuredStartTime }, { updateExtensionMeasuredStartTime }] =
		usePerformanceAnalytics();
	const performanceAnalyticsProps: PerformanceAnalyticsContainerProps = useMemo(
		() => ({
			issueKey,
			currentPageTiming,
			extensionsMeasuredStartTime,
			updateExtensionMeasuredStartTime,
			performanceAttributes: {
				extensionId: extension.id,
				module: extension.type,
				source,
				renderContext: ISSUE_VIEW_RENDER_CONTEXT,
				customData: source
					? getPerformanceCustomData(source, ISSUE_VIEW_RENDER_CONTEXT, extension)
					: undefined,
			},
			additionalTimings: {
				pageSegmentLoad: getAdditionalPageSegmentLoadTimings(),
			},
		}),
		[
			issueKey,
			currentPageTiming,
			extensionsMeasuredStartTime,
			updateExtensionMeasuredStartTime,
			extension,
			source,
		],
	);

	return (
		<AnalyticsAttributesContextProvider value={analyticsAttributes}>
			<ViewAnalyticsWrapper attributes={analyticsAttributes}>
				<PerformanceAnalyticsContainer {...performanceAnalyticsProps}>
					{children}
				</PerformanceAnalyticsContainer>
			</ViewAnalyticsWrapper>
		</AnalyticsAttributesContextProvider>
	);
};
