import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { isShallowEqual } from '@atlassian/jira-polaris-lib-equals/src/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { getCurrentViewMatrixConfig } from '../../../selectors/view/matrix/index.tsx';
import type { Props, State } from '../../../types.tsx';
import { saveViewWithAutoSave } from '../../save/index.tsx';
import { updateViewState } from '../../utils/state/index.tsx';
import { currentViewFilter } from '../../utils/views/index.tsx';

export const switchMatrixXAndYAxis =
	(): Action<State, Props> =>
	async ({ getState, setState, dispatch }, props) => {
		const state = getState();

		const viewMutation = (view: View): View => {
			const matrixConfig = getCurrentViewMatrixConfig(state, props);
			if (matrixConfig === undefined) {
				return view;
			}
			const newAxes = matrixConfig.axes.map((axis) => {
				if (axis.dimension === 'x') {
					return { ...axis, dimension: 'y' };
				}
				if (axis.dimension === 'y') {
					return { ...axis, dimension: 'x' };
				}
				return axis;
			});
			if (isShallowEqual(newAxes, matrixConfig.axes)) {
				return view;
			}

			const newMatrixConfig = {
				...matrixConfig,
				axes: newAxes,
			};

			return {
				...view,
				matrixConfig: view.isAutosaveEnabled ? newMatrixConfig : view.matrixConfig,
				modified: view.isAutosaveEnabled,
				draft: {
					...view.draft,
					matrixConfig: newMatrixConfig,
				},
			};
		};

		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(props.currentViewSlug),
			viewMutation,
		);

		if (changedView) {
			setState({ viewSets });
			dispatch(saveViewWithAutoSave(changedView.id));
		}
	};
