import React, { useCallback, useMemo } from 'react';
import {
	endOfQuarter,
	startOfQuarter,
	formatISO,
	differenceInDays,
	isValid,
	startOfMonth,
	endOfMonth,
} from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { type IntlShape, useIntl } from '@atlassian/jira-intl';

import { formatTimezonedWithLocale } from '@atlassian/jira-platform-utils-date-fns/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { messages } from './messages.tsx';
import type { IntervalValue } from './types.tsx';

import { getADGTimestampDisplaySetting, RELATIVE, getRelativeValueAndUnit } from './utils.tsx';

// TODO: these constant strings were floating around different places so i pulled them all here
// however, a fixed date format for all locales seems very wrong to me. potentially an i18n bug
export const DATETIME_FORMAT = 'LLL d, yyyy hh:mm a';
export const DATE_FORMAT = 'LLL d, yyyy';

export const DATE_FORMAT_DFNS = 'MMM D, YYYY';

export const DIFF_YEAR_FORMAT = 'MMM d, yyyy';
export const MONTH_ONLY_FORMAT_SHORT = 'MMM';
export const MONTH_ONLY_FORMAT_DIFF_YEAR = 'MMM, yyyy';
export const MONTH_WITH_DIFF_YEAR_FORMAT = 'MMM yyyy';

// this will only work for Dates that are in UTC. It won't work with DateTimes as those are timezone aware
const formatWithLocale = (date: Date, dateFormat: string, locale?: string) => {
	return formatTimezonedWithLocale(date, dateFormat, locale || 'en_US', 'UTC');
};

export const formatNiceDate = (date: Date, locale?: string) =>
	formatWithLocale(date, DIFF_YEAR_FORMAT, locale);

export const formatNiceMonthDate = (date: Date, locale?: string) =>
	formatWithLocale(date, MONTH_ONLY_FORMAT_DIFF_YEAR, locale);

export const formatMonthAndYearDate = (date: Date, locale?: string) =>
	formatWithLocale(date, MONTH_WITH_DIFF_YEAR_FORMAT, locale);

export const formatQuarterAndYearDate = (date: Date, locale?: string) => {
	const startOfQuarterDate = zonedTimeToUtc(startOfQuarter(date), 'UTC');
	const endOfQuarterDate = zonedTimeToUtc(endOfQuarter(date), 'UTC');

	return `${formatWithLocale(startOfQuarterDate, MONTH_ONLY_FORMAT_SHORT)}-${formatWithLocale(
		endOfQuarterDate,
		MONTH_WITH_DIFF_YEAR_FORMAT,
		locale,
	)}`;
};

export const formatCustomQuarterDate = (
	date: IntervalValue,
	formatMessage: IntlShape['formatMessage'],
	formatDate: IntlShape['formatDate'],
) => {
	if (!date || !date.start || !date.end) {
		return '';
	}
	const quarterStart = startOfMonth(new Date(date.start));
	const quarterEnd = endOfMonth(new Date(date.end));
	const year = formatDate(quarterStart, {
		year: 'numeric',
	});
	return formatMessage(messages.quarterDisplay, {
		startMonth: formatDate(quarterStart, {
			month: 'short',
		}),
		endMonth: formatDate(quarterEnd, {
			month: 'short',
		}),
		year,
	});
};

export const formatNiceQuarterDate = (date: Date, locale?: string) => {
	const startOfQuarterDate = zonedTimeToUtc(startOfQuarter(date), 'UTC');
	const endOfQuarterDate = zonedTimeToUtc(endOfQuarter(date), 'UTC');

	return `${formatWithLocale(startOfQuarterDate, MONTH_ONLY_FORMAT_SHORT)}-${formatWithLocale(
		endOfQuarterDate,
		MONTH_ONLY_FORMAT_DIFF_YEAR,
		locale,
	)}`;
};

export const formatIsoLocalDate = (date: Date) => formatISO(date, { representation: 'date' });

// TODO: go/restrict-enums
// eslint-disable-next-line no-restricted-syntax
export enum FuzzyScale {
	DAY = 'day',
	MONTH = 'month',
	QUARTER = 'quarter',
}

const MONTH_THRESHOLD = 31;
const DAY_THRESHOLD = 1;

export const getFuzzyScale = (dateObj?: IntervalValue) => {
	if (dateObj) {
		const dateDiff = differenceInDays(new Date(dateObj.end), new Date(dateObj.start));
		if (dateDiff < DAY_THRESHOLD) {
			return FuzzyScale.DAY;
		}
		if (dateDiff <= MONTH_THRESHOLD) {
			return FuzzyScale.MONTH;
		}
		return FuzzyScale.QUARTER;
	}
	// If date is somehow undefined, we'll show quarters picker by default
	return FuzzyScale.QUARTER;
};

export const renderDateString = (
	dateString?: IntervalValue,
	placeholder?: string,
	formatMessage?: IntlShape['formatMessage'],
	formatDate?: IntlShape['formatDate'],
) => {
	if (!dateString) {
		return placeholder;
	}

	const endDate = zonedTimeToUtc(dateString.end.slice(0, 10), 'UTC');

	if (!isValid(endDate)) {
		return placeholder;
	}

	switch (getFuzzyScale(dateString)) {
		case FuzzyScale.DAY:
			return formatNiceDate(endDate);
		case FuzzyScale.QUARTER:
			return formatMessage && formatDate && fg('polaris_pol-14239_custom_quarters')
				? formatCustomQuarterDate(dateString, formatMessage, formatDate)
				: formatNiceQuarterDate(endDate);
		default:
		case FuzzyScale.MONTH:
			return formatNiceMonthDate(endDate);
	}
};

export const useAdgCompliantRelativeDate = (date?: string | number | null): string | undefined => {
	const { formatDate, formatRelativeTime, formatMessage } = useIntl();
	return useMemo(() => {
		if (date === null || date === undefined) {
			return undefined;
		}
		if (getADGTimestampDisplaySetting(date) === RELATIVE) {
			const [value, unit] = getRelativeValueAndUnit(date);
			if (unit === 'second' && value < 60 && value > -60) {
				return formatMessage(messages.justNow);
			}
			return formatRelativeTime(value, unit);
		}
		return formatDate(date, {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		});
	}, [date, formatDate, formatRelativeTime, formatMessage]);
};

type FormattedDateProps = {
	date: string | number;
};

export const FormattedDate = ({ date }: FormattedDateProps) => {
	const formatted = useAdgCompliantRelativeDate(date);
	return <>{formatted}</>;
};

export const useRenderDateString = () => {
	const { formatDate, formatMessage } = useIntl();

	const formattedRenderDateString = useCallback(
		(dateString?: IntervalValue, placeholder?: string) => {
			return renderDateString(dateString, placeholder, formatMessage, formatDate);
		},
		[formatDate, formatMessage],
	);

	return formattedRenderDateString;
};
