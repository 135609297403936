import { createSelector } from 'reselect';
import filter from 'lodash/filter';
import findKey from 'lodash/findKey';
import flatten from 'lodash/flatten';
import flattenDeep from 'lodash/flattenDeep';
import has from 'lodash/has';
import head from 'lodash/head';
import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import size from 'lodash/size';
import uniq from 'lodash/uniq';
import without from 'lodash/without';
import { differenceInMilliseconds, parseISO } from 'date-fns';
import { statusCategoryForId } from '@atlassian/jira-common-constants/src/status-categories.tsx';
import { timestampAggDateToInterval } from '@atlassian/jira-polaris-domain-delivery/src/dates/index.tsx';
import type { FieldOption } from '@atlassian/jira-polaris-domain-field/src/field-option/types.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { issuesProgressFromAggregate } from '@atlassian/jira-polaris-domain-field/src/field-types/progress/index.tsx';
import type { StatusFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/status/types.tsx';
import type { UserFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/user/types.tsx';
import {
	ASSIGNEE_FIELDKEY,
	CREATED_FIELDKEY,
	ISSUEID_FIELDKEY,
	ISSUETYPE_FIELDKEY,
	REPORTER_FIELDKEY,
	STATUS_FIELDKEY,
	UPDATED_FIELDKEY,
} from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import {
	type IntervalFieldSource,
	INTERVAL_FIELD_SOURCES,
} from '@atlassian/jira-polaris-domain-field/src/field/interval/index.tsx';
import type { ProjectFieldValue } from '@atlassian/jira-polaris-domain-field/src/field/project/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type {
	LocalIssueId,
	OptionProperty,
} from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { IntervalValue } from '@atlassian/jira-polaris-lib-date-time/src/types.tsx';
import { isShallowEqual } from '@atlassian/jira-polaris-lib-equals/src/index.tsx';
import { JPD_ONBOARDING_TEMPLATES } from '@atlassian/jira-polaris-lib-onboarding/src/utils.tsx';
import { cacheSelectorCreator } from '@atlassian/jira-polaris-lib-selector-creator-cache/src/index.tsx';
import {
	type IssueId,
	type IssueKey,
	toIssueId,
	type IssueTypeId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import type { TeamValue } from '@atlassian/jira-issue-field-team/src/common/types.tsx';
import { isGlobalSystemField } from '@atlassian/jira-polaris-domain-field/src/field/utils.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { User } from '../../../../common/types/user/index.tsx';
import { getIssueNumberFromIssueKey } from '../../../../common/utils/issue-key/index.tsx';
import {
	IssueCreateStatusInCreation,
	IssueCreateStatusInTransition,
	type PropertyMaps,
	type PropertyMap,
	type Props,
	type State,
	type IssueCreatedProperty,
} from '../../types.tsx';
import type { Value } from '../../utils/field-mapping/external-reference-property/index.tsx';
import type { FieldMapping } from '../../utils/field-mapping/types.tsx';
import {
	createGetFieldOptions,
	createGetFieldOptionsWithAriResolvedById,
	getAllFieldsByKey,
	getArchivedFieldMappings,
	getFieldMappings,
	createGetFieldOfType,
	getIssuesSimiliarStatusIdsByStatusId,
	getNumberFieldMappings,
	createGetField,
} from '../fields.tsx';
import { getAggregatedDeliveryDates } from './linked-issues/index.tsx';
import { createGetCommentsCount } from './comments/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { getExternalIssueData } from './linked-issues';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { getInsights } from './insights';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { createGetLinkedIssueData } from './linked-issues';

export const getProperties = (state: State): PropertyMaps => state.properties;

export const getStringProperties = createSelector(getProperties, (properties) => properties.string);

export const getKeyProperties = createSelector(
	getStringProperties,
	(stringProperties) => stringProperties.key,
);

export const getDocumentProperties = createSelector(
	getProperties,
	(properties) => properties.document,
);

export const getStatusProperties = createSelector(getProperties, (properties) => properties.status);

export const getNumberProperties = createSelector(getProperties, (properties) => properties.number);
export const getJiraIssueIdProperties = (state: State): Record<LocalIssueId, number> =>
	getNumberProperties(state).issueid || {};

export const getStringListProperties = createSelector(
	getProperties,
	(properties) => properties.stringList,
);

export const getUserProperties = createSelector(getProperties, (properties) => properties.user);

export const getTeamProperties = createSelector(getProperties, (properties) => properties.team);

export const getPeopleProperties = createSelector(getProperties, (properties) => properties.people);

export const getexternalReferenceEntitiesProperties = createSelector(
	getProperties,
	(properties) => properties.externalReferenceEntities,
);

export const getPlatformGoalsProperties = createSelector(
	getProperties,
	(properties) => properties.goals,
);

export const getAllGoalsAri = createSelector(getPlatformGoalsProperties, (goalsProperties) => {
	const allGoals = Object.values(goalsProperties)
		.flatMap((goalsByIssueId) => Object.values(goalsByIssueId))
		.flat();
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return uniq(allGoals.filter(Boolean)) as string[];
});

export const getExternalReferencesProperties = createSelector(
	getProperties,
	(properties) => properties.externalReference,
);

export const getExternalReferenceEntitiesProperties = createSelector(
	getProperties,
	(properties) => properties.externalReferenceEntities,
);

export const getAllExternalReferencesPropertiesByProvider = createSelector(
	getExternalReferencesProperties,
	(state: State) => state.containerProps?.fields,
	(state: State) => state.ids,
	(state: State) => state.connectionIssueIds,
	(externalReferenceProperties, fields, ids, connectionIssueIds) => {
		const filteredFields = fields?.filter((field) => field.configuration?.provider);
		const providers: {
			[key: string]: string[];
		} =
			filteredFields?.reduce((result, field) => {
				if (field.configuration?.provider) {
					return Object.assign(result, {
						[field.configuration?.provider]: [],
					});
				}

				return result;
			}, {}) || {};

		Object.keys(providers).forEach((key) => {
			const mapId = (id: string) =>
				filteredFields
					?.filter((field) => field.configuration?.provider === key)
					.map((field) => externalReferenceProperties?.[field.key]?.[id])
					.filter(Boolean) || [];

			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			providers[key] = Array.from(
				new Set([
					...flattenDeep(ids.map(mapId)),
					...(fg('jpd_cross_project_connecting') ? flattenDeep(connectionIssueIds.map(mapId)) : []),
				]),
			) as string[];
		});
		return providers;
	},
);

export const getSingleOptionProperties = createSelector(
	getProperties,
	(properties) => properties.singleSelect,
);

export const getMultiOptionProperties = createSelector(
	getProperties,
	(properties) => properties.multiSelect,
);

export const getCreatedProperties = createSelector(
	getProperties,
	(properties) => properties.created,
);

export const getConnectionProperties = createSelector(
	getProperties,
	(properties) => properties.connection,
);

export const getIdeasInCreationCount = createSelector(getCreatedProperties, (props) =>
	size(
		filter(
			props,
			({ status }) =>
				status === IssueCreateStatusInCreation || status === IssueCreateStatusInTransition,
		),
	),
);

export const getIdeasInCreationGrouped = createSelector(
	getCreatedProperties,
	(createdProperties) => {
		const result: IssueCreatedProperty = {};

		Object.keys(createdProperties).forEach((key) => {
			const createdProperty = createdProperties[key];

			if (
				createdProperty.status === IssueCreateStatusInCreation ||
				createdProperty.status === IssueCreateStatusInTransition
			) {
				result[key] = createdProperty;
			}
		});

		return result;
	},
);

export const getIdeasInCreation = createSelector(getIdeasInCreationGrouped, (ideas) =>
	Object.keys(ideas),
);

export const getIssueTypeProperties = createSelector(
	getProperties,
	(properties) => properties.issueType,
);

const getInsightsProperties = createSelector(getProperties, (properties) => properties.insights);

const getAggregatedDeliveryProgressProperties = createSelector(
	getProperties,
	(properties) => properties.aggregatedDeliveryProgress,
);

export const getCreatedIssueIds = createSelector(getCreatedProperties, (createdPoperties) => {
	if (createdPoperties !== undefined) {
		return Object.keys(createdPoperties);
	}
	return [];
});

export const getIssueIdsInCreation = createSelector(getCreatedProperties, (createdProperties) => {
	if (createdProperties !== undefined) {
		const issueIdsInCreation = pickBy(
			createdProperties,
			(o) => o.status === IssueCreateStatusInCreation,
		);
		return Object.keys(issueIdsInCreation);
	}
	return [];
});

export const getPropjectProperties = createSelector(
	getProperties,
	(properties) => properties.projects.project,
);

export const getSelectedIssue = (state: State): IssueKey | undefined => state.selectedIssue;

export const getSelectedIssueLocalIssueId = createSelector<
	State,
	IssueKey | undefined,
	Record<LocalIssueId, string>,
	LocalIssueId | undefined
>(getSelectedIssue, getKeyProperties, (selectedIssue, keyProperties) =>
	findKey(keyProperties, (issueKey) => selectedIssue === issueKey),
);

export const hasSelectedLocalIssueId = createSelector(
	getSelectedIssueLocalIssueId,
	(issueId) => issueId !== undefined,
);

export const getSelectedIssueJiraId = createSelector(
	getSelectedIssueLocalIssueId,
	getJiraIssueIdProperties,
	(selectedIssueLocalId, jiraIdProperty) =>
		selectedIssueLocalId ? jiraIdProperty[selectedIssueLocalId] : undefined,
);

export const getIssueJiraId = (id: LocalIssueId) =>
	createSelector(getJiraIssueIdProperties, (jiraIdProperty) => jiraIdProperty[id]);

export const createGetKeySelector = (id: LocalIssueId) =>
	createSelector<State, PropertyMap<string>, IssueKey>(
		getStringProperties,
		(stringProperties) => stringProperties.key[id],
	);

export const createGetIdSelector = (id: LocalIssueId) =>
	createSelector(getNumberProperties, (numberProperties): number => numberProperties.issueid[id]);

export const createSafeGetKeySelector = (id?: LocalIssueId) =>
	createSelector(getKeyProperties, (keyProperties): IssueKey | undefined =>
		id === undefined ? undefined : keyProperties?.[id],
	);

export const createSafeGetJiraIdSelector = (id?: LocalIssueId) =>
	createSelector(getNumberProperties, (numberProperties): IssueId | undefined =>
		id === undefined ||
		numberProperties.issueid === undefined ||
		numberProperties.issueid[id] === undefined
			? undefined
			: toIssueId(String(numberProperties.issueid[id])),
	);

export const createGetProjectSelector = (id: LocalIssueId) =>
	createSelector(
		getPropjectProperties,
		(projectProperties): ProjectFieldValue | undefined => projectProperties?.[id],
	);

export const createGetSummary = (id: LocalIssueId) =>
	createSelector(getAllFieldsByKey, getStringProperties, (fields, stringProperties) => {
		const { key: fieldKey } = fields.summary;
		return stringProperties[fieldKey][id];
	});

export const createGetDescription = (id?: LocalIssueId) =>
	createSelector(getAllFieldsByKey, getDocumentProperties, (fields, documentProperties) => {
		if (id === undefined || fields?.description === undefined) {
			return undefined;
		}
		const { key: fieldKey } = fields.description;

		return documentProperties[fieldKey][id];
	});

export const createGetIssueType = (id?: LocalIssueId) =>
	createSelector(getAllFieldsByKey, getIssueTypeProperties, (fields, issueTypeProperties) => {
		if (id === undefined || !has(fields, ISSUETYPE_FIELDKEY)) {
			return undefined;
		}
		const { key: fieldKey } = fields[ISSUETYPE_FIELDKEY];

		return issueTypeProperties[fieldKey][id];
	});

export const createGetStatus = (id?: LocalIssueId) =>
	createSelector(getAllFieldsByKey, getStatusProperties, (fields, statusProperties) => {
		if (id === undefined || !has(fields, STATUS_FIELDKEY)) {
			return undefined;
		}
		const { key: fieldKey } = fields[STATUS_FIELDKEY];

		return statusProperties[fieldKey] !== undefined ? statusProperties[fieldKey][id] : undefined;
	});

export const createGetStatusesForIssues = (ids: LocalIssueId[]) =>
	createSelector(
		getAllFieldsByKey,
		getStatusProperties,
		getIssuesSimiliarStatusIdsByStatusId,
		(state: State, props?: Props) => props?.isCollectionView,
		(fields, statusProperties, similarStatusesIdsBystatusId, isCollectionView) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const issueStatuses: Record<string, any> = {};

			ids.forEach((id) => {
				if (id !== undefined && has(fields, STATUS_FIELDKEY)) {
					const { key: fieldKey } = fields[STATUS_FIELDKEY];
					const status = statusProperties[fieldKey][id];
					if (status) {
						if (isCollectionView) {
							issueStatuses[id] = similarStatusesIdsBystatusId[status.id][0];
						} else {
							issueStatuses[id] = status.id;
						}
					}
				}
			});
			return issueStatuses;
		},
	);

export const createGetIssueCreationStateProperties = (id: LocalIssueId) =>
	createSelector(getCreatedProperties, (createdProperties) => createdProperties[id]);

export const createGetStringValueSelector = (fieldKey: FieldKey, id?: LocalIssueId) =>
	createSelector(getStringProperties, (stringProperties): string | undefined =>
		id ? stringProperties[fieldKey]?.[id] : undefined,
	);

export const createGetExternalReferencesValueSelector = (
	fieldKey: FieldKey | undefined,
	id: LocalIssueId,
) =>
	createSelector(
		getFieldMappings,
		(state: State) => state,
		(_: State, props?: Props) => props,
		(mappings, state, props): string | string[] | undefined =>
			fieldKey ? mappings[fieldKey].valueAccessor(state, props, id) : undefined,
	);

export const createGetPlatformGoalsValueSelector = (
	fieldKey: FieldKey | undefined,
	id: LocalIssueId,
) =>
	createSelector(
		getFieldMappings,
		(state: State) => state,
		(_: State, props?: Props) => props,
		(mappings, state, props): string[] | undefined =>
			fieldKey ? mappings[fieldKey].valueAccessor(state, props, id) : undefined,
	);

export const createGetExternalReferencePropertyValueSelector = (
	fieldKey: FieldKey,
	id: LocalIssueId,
) =>
	createSelector(
		getFieldMappings,
		(state: State) => state,
		(_: State, props?: Props) => props,
		(mappings, state, props): Value | undefined =>
			mappings[fieldKey].valueAccessor(state, props, id),
	);

export const createGetStringValuesSelector = (
	fieldKey: FieldKey | undefined,
	ids: LocalIssueId[],
) =>
	createSelector(getStringProperties, (stringProperties) => {
		const result: Record<string, string> = {};

		if (!fieldKey) {
			return result;
		}

		ids.forEach((id) => {
			if (stringProperties[fieldKey]?.[id] !== undefined) {
				result[id] = stringProperties[fieldKey]?.[id];
			}
		});
		return result;
	});

export const createGetIntervalDateValuesSelector = (
	fieldKey: FieldKey | undefined,
	ids: LocalIssueId[],
) =>
	createSelector(
		createGetStringValuesSelector(fieldKey, ids),
		getexternalReferenceEntitiesProperties,
		getFieldMappings,
		createGetFieldOfType(FIELD_TYPES.ATLAS_PROJECT),
		getAggregatedDeliveryDates,
		(state: State) => state,
		(_: State, props?: Props) => props,
		(
			intervalStringValues,
			externalReferenceEntities,
			mappings,
			atlasProjectField,
			aggregatedDeliveryDates,
			state,
			props,
		) => {
			const result: Record<
				string,
				{
					field: { dataSource: IntervalFieldSource; fieldKey: FieldKey };
					isExternal: boolean;
					value: IntervalValue;
				}
			> = {};
			if (!fieldKey) {
				return result;
			}

			const atlasProjectFieldMapping = atlasProjectField
				? mappings[atlasProjectField.key]
				: undefined;
			const source =
				mappings[fieldKey]?.field?.configuration?.source || INTERVAL_FIELD_SOURCES.MANUAL;

			ids.forEach((id) => {
				if (atlasProjectFieldMapping) {
					switch (source) {
						case INTERVAL_FIELD_SOURCES.ATLAS_PROJECT_TARGET_DATE: {
							const value = atlasProjectFieldMapping.valueAccessor(state, props, id);

							const dateRange = externalReferenceEntities[value]?.dueDate?.dateRange;

							if (
								value &&
								dateRange?.start &&
								dateRange?.end &&
								externalReferenceEntities[value]?.state?.value !== 'pending'
							) {
								result[id] = {
									isExternal: true,
									field: {
										dataSource: source,
										fieldKey,
									},
									value: {
										start: dateRange.start,
										end: dateRange.end,
									},
								};
								return;
							}
							break;
						}
						default:
						// no nothing
					}
				}

				if (source === INTERVAL_FIELD_SOURCES.DELIVERY_DATE) {
					const customFieldKey = mappings[fieldKey]?.field?.configuration?.customfieldKey;
					const aggregationType = mappings[fieldKey]?.field?.configuration?.aggregationType;
					const value = aggregatedDeliveryDates[id]?.find(
						(v) =>
							v.fieldKey === customFieldKey &&
							v.aggregationType.toLowerCase() === aggregationType?.toLowerCase(),
					);

					const intervalValue = timestampAggDateToInterval(value, props?.locale, props?.timezone);

					if (intervalValue) {
						result[id] = {
							isExternal: true,
							field: {
								dataSource: source,
								fieldKey,
							},
							value: intervalValue,
						};
						return;
					}
				}

				try {
					result[id] = {
						isExternal: false,
						field: {
							dataSource: source,
							fieldKey,
						},
						value: JSON.parse(intervalStringValues[id]),
					};
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (err: any) {
					// do nothing
				}
			});
			return result;
		},
	);

export const createGetIsArchivedSelector =
	(id: LocalIssueId) =>
	(state: State, props?: Props): boolean => {
		const archivedMappings = getArchivedFieldMappings(state, props);
		if (props?.archivedFieldsConfig?.archivedField?.key === undefined) {
			return false;
		}
		const fieldMapping = archivedMappings[props.archivedFieldsConfig.archivedField.key];
		if (fieldMapping === undefined) {
			return false;
		}
		return (
			props.archivedFieldsConfig.archivedField.archivedOption?.jiraOptionId !== undefined &&
			fieldMapping.valueAccessor(state, props, id)?.id ===
				props?.archivedFieldsConfig?.archivedField?.archivedOption?.jiraOptionId
		);
	};

export const createGetNumberValueSelector =
	(fieldKey: FieldKey, id: LocalIssueId, dynamicFieldInitialized: boolean) =>
	(state: State, props?: Props) => {
		const numberMappings = getNumberFieldMappings(state, props);
		const fieldMapping = numberMappings[fieldKey];

		if (fieldMapping === undefined) {
			return undefined;
		}

		if (
			fieldMapping.field?.formula &&
			dynamicFieldInitialized !== true &&
			!(state.meta?.isSingleIssueLoaded === true && state.meta?.initialized !== true)
		) {
			return undefined;
		}

		return fieldMapping.valueAccessor(state, props, id);
	};

export const createGetFieldNumberValuesSelector =
	(fieldKey: FieldKey | undefined, ids: LocalIssueId[]) => (state: State, props?: Props) => {
		if (fieldKey === undefined) {
			return {};
		}
		const numberMappings = getNumberFieldMappings(state, props);
		const fieldMapping = numberMappings[fieldKey];

		if (fieldMapping === undefined) {
			return {};
		}

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const result: Record<string, any> = {};
		ids.forEach((id) => {
			result[id] = fieldMapping.valueAccessor(state, props, id);
		});
		return result;
	};

export const createGetStringListValueSelector = (fieldKey: FieldKey, id: LocalIssueId) =>
	createSelector(
		getStringListProperties,
		(stringListProperties): string[] =>
			stringListProperties[fieldKey] && stringListProperties[fieldKey][id],
	);

export const createGetSingleOptionSelector = (fieldKey: FieldKey, localIssueId: LocalIssueId) =>
	createSelector(
		getSingleOptionProperties,
		createGetFieldOptionsWithAriResolvedById(fieldKey),
		(singleOptionProperties, fieldOptions): OptionProperty[] => {
			const optionProps = singleOptionProperties?.[fieldKey]?.[localIssueId];
			if (fieldOptions && optionProps) {
				const { id } = optionProps;
				const fieldOption = fieldOptions[optionProps.id];
				if (fieldOption === undefined) {
					return [];
				}
				return [
					{
						id,
						value: fieldOption.value,
						weight: fieldOption.weight || 0,
					},
				];
			}
			return [];
		},
	);

export const createGetFieldSingleOptionSelector = (
	fieldKey: FieldKey | undefined,
	ids: LocalIssueId[],
	useWeights = false,
) =>
	createSelector(
		getSingleOptionProperties,
		createGetFieldOptionsWithAriResolvedById(fieldKey),
		(singleOptionProperties, fieldOptions) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const result: Record<string, any> = {};
			ids.forEach((localIssueId) => {
				if (
					fieldKey !== undefined &&
					fieldOptions &&
					singleOptionProperties[fieldKey] &&
					singleOptionProperties[fieldKey][localIssueId]
				) {
					const { id } = singleOptionProperties[fieldKey][localIssueId];
					if (fieldOptions[id] !== undefined) {
						result[localIssueId] =
							useWeights === true ? fieldOptions[id].weight : fieldOptions[id].id;
					}
				}
			});
			return result;
		},
	);

export const createGetMultiOptionSelector = (fieldKey: FieldKey, localIssueId: LocalIssueId) =>
	createSelector(
		getMultiOptionProperties,
		createGetFieldOptionsWithAriResolvedById(fieldKey),
		createGetFieldOptions(fieldKey),
		(multiOptionProperties, fieldOptions, sortedFieldOptions?: FieldOption[]): OptionProperty[] => {
			const options: OptionProperty[] = [];
			const optionProps = multiOptionProperties?.[fieldKey]?.[localIssueId];
			if (fieldOptions && optionProps && sortedFieldOptions) {
				sortedFieldOptions.forEach(({ jiraOptionId }) => {
					if (optionProps.find((issueOption) => issueOption.id === jiraOptionId)) {
						options.push(fieldOptions[jiraOptionId]);
					}
				});
				return options;
			}
			return options;
		},
	);

export const createGetFieldMultiOptionsSelector = (
	fieldKey: FieldKey | undefined,
	ids: LocalIssueId[],
) =>
	createSelector(
		getMultiOptionProperties,
		createGetFieldOptionsWithAriResolvedById(fieldKey),
		(optionProperties, fieldOptions) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const result: Record<string, any> = {};
			ids.forEach((localIssueId) => {
				const optionProps = fieldKey && optionProperties?.[fieldKey]?.[localIssueId];
				if (fieldOptions && optionProps) {
					const weightOption = optionProps.map(({ id }) => {
						if (fieldOptions[id] !== undefined) {
							return fieldOptions[id].weight || 0;
						}
						return 0;
					});
					result[localIssueId] = weightOption.reduce(
						(prevValue, currValue) => prevValue + currValue,
						0,
					);
				}
			});
			return result;
		},
	);

export const createGetUserSelector = (fieldKey: FieldKey, id: LocalIssueId) =>
	createSelector(
		getUserProperties,
		(userProperties): UserFieldValue | undefined =>
			userProperties[fieldKey] && userProperties[fieldKey][id],
	);

export const createGetTeamSelector = (fieldKey: FieldKey, id: LocalIssueId) =>
	createSelector(
		getTeamProperties,
		(teamProperties): TeamValue | undefined => teamProperties[fieldKey]?.[id],
	);

export const createGetUserByAccountIdSelector = (fieldKey: FieldKey, accountId: string) =>
	createSelector(getUserProperties, (userProperties) => {
		if (userProperties[fieldKey]) {
			const userInfo = Object.values(userProperties[fieldKey]).find(
				(user) => user?.accountId === accountId,
			);
			return userInfo;
		}
		return undefined;
	});

export const createGetFieldUserSelector = (fieldKey: FieldKey | undefined, ids: LocalIssueId[]) =>
	createSelector(getUserProperties, (userProperties) => {
		const result: Record<string, string> = {};
		ids.forEach((localId) => {
			if (fieldKey !== undefined && userProperties[fieldKey] && userProperties[fieldKey][localId]) {
				const userId = userProperties[fieldKey][localId]?.accountId;
				result[localId] = userId;
			}
		});
		return result;
	});

export const createGetPeopleSelector = (fieldKey: FieldKey, id: LocalIssueId) =>
	createSelector(
		getPeopleProperties,
		(peopleProperties): UserFieldValue[] | undefined =>
			peopleProperties[fieldKey] && peopleProperties[fieldKey][id],
	);

export const createGetPeopleByAccountIdSelector = (fieldKey: FieldKey, accountId: string) =>
	createSelector(getPeopleProperties, (peopleProperties): User | undefined => {
		if (peopleProperties[fieldKey]) {
			const userInfo = Object.values(peopleProperties[fieldKey])
				.flat()
				.find((user) => user?.accountId === accountId);
			const transformedUserInfo = userInfo && {
				id: userInfo.accountId,
				name: userInfo.displayName ?? '',
				avatarUrl: userInfo.avatarUrls?.['16x16'] ?? '',
			};
			return transformedUserInfo;
		}
		return undefined;
	});

export const createGetPeopleTransformedSelector = (fieldKey: FieldKey, id: LocalIssueId) =>
	createSelector(
		createGetPeopleSelector(fieldKey, id),
		(people: UserFieldValue[] | undefined): (User & { src: string })[] =>
			people !== undefined && people !== null
				? people.map((prop) => ({
						id: prop.accountId,
						name: prop.displayName || '',
						avatarUrl: prop.avatarUrls?.['48x48'] || '',
						src: prop.avatarUrls?.['48x48'] || '',
					}))
				: [],
	);

export const createGetSingularStatusSelectorForSelectedIssueHook = (
	fieldKey: FieldKey,
	id: LocalIssueId,
) =>
	createSelector(
		getStatusProperties,
		(statusProperties): StatusFieldValue =>
			// @ts-expect-error - TS2538 - Type 'undefined' cannot be used as an index type.
			statusProperties[head(keys(statusProperties))][id],
	);

/**
 * helper function to create a selector that only returns a new tuple of state/props
 * if a relevant property on one of the field mappings changes
 */
export const createSpecificallyMemoizedDataSelector = (
	mappingsSelector: (arg1: State, arg2: Props | undefined) => FieldMapping<unknown>[],
) => {
	// memoize relevant object for comparison
	let previousRelevantObjects: unknown[];
	// memoize previous properties to return when no relevant change has occurred
	let previousData: [State, Props | undefined];

	return createSelector(
		mappingsSelector,
		(state) => state,
		(state, props) => props,
		(mappings, state, props) => {
			const accessors = uniq(
				flatten(
					mappings.map(({ getAllValues, getDependencyValues }) =>
						getDependencyValues ? [getAllValues, getDependencyValues] : getAllValues,
					),
				),
			);
			const relevantObjects = without(
				uniq(flatten(accessors.map((accessor) => accessor(state, props)))),
				null,
				undefined,
			);

			if (
				previousData === undefined ||
				previousRelevantObjects === undefined ||
				!isShallowEqual(previousRelevantObjects, relevantObjects)
			) {
				previousRelevantObjects = relevantObjects;
				previousData = [state, props];
			}

			return previousData;
		},
	);
};

const ISSUE_MODIFIED_TIME_THRESHOLD = 2000;
const notModifiedSelector = () => false;
const createGetIsIssueModified = cacheSelectorCreator((id?: LocalIssueId) =>
	id
		? createSelector(
				createGetStringValueSelector(CREATED_FIELDKEY, id),
				createGetStringValueSelector(UPDATED_FIELDKEY, id),
				(created, updated): boolean =>
					created !== undefined &&
					updated !== undefined &&
					// Since create/updated are not always equal, we treat issue as modified only if the diff is more than ISSUE_MODIFIED_TIME_THRESHOLD milliseconds
					Math.abs(differenceInMilliseconds(parseISO(created), parseISO(updated))) >
						ISSUE_MODIFIED_TIME_THRESHOLD,
			)
		: notModifiedSelector,
);

export const createIsOnboardingExperimentIssue = (id?: LocalIssueId) => {
	const keySelector = createSafeGetKeySelector(id);
	return createSelector(
		keySelector,
		(_, props?: Props) => props?.projectTemplateVersion,
		(issueKey, projectTemplateVersion) => {
			const issueKeyNumber = getIssueNumberFromIssueKey(issueKey);
			// In onboarding experiment we track first 3 ideas separately, so we mark them here
			return (
				JPD_ONBOARDING_TEMPLATES.includes(projectTemplateVersion ?? '') &&
				issueKeyNumber !== undefined &&
				[1, 2, 3].includes(issueKeyNumber)
			);
		},
	);
};

const createIsOnboardingExperimentIssueModified = (id?: LocalIssueId) => {
	const isOnboardingExperimentIssueSelector = createIsOnboardingExperimentIssue(id);
	const isModifiedSelector = createGetIsIssueModified(id);
	return createSelector(
		isOnboardingExperimentIssueSelector,
		isModifiedSelector,
		(isOnboardingExperimentIssue, isModified) =>
			isOnboardingExperimentIssue ? isModified : undefined,
	);
};

const creategGetIdeaDeliveryTicketCount = (id?: LocalIssueId) =>
	createSelector(getAggregatedDeliveryProgressProperties, (aggregatedDeliveryProgressProperties) =>
		id && aggregatedDeliveryProgressProperties[id]
			? issuesProgressFromAggregate(aggregatedDeliveryProgressProperties[id]).total
			: 0,
	);

const creategGetIdeaInsightCount = (id?: LocalIssueId) =>
	createSelector(getInsightsProperties, (insightsProperties) =>
		id ? insightsProperties?.[id]?.length : 0,
	);

export const createGetIssueAnalyticsAttributes = (id?: LocalIssueId) => {
	const keySelector = createSafeGetKeySelector(id);
	const isOnboardingExperimentIssueSelector = createIsOnboardingExperimentIssue(id);
	const isOnboardingExperimentIssueModifiedSelector = createIsOnboardingExperimentIssueModified(id);
	return createSelector(
		getNumberProperties,
		getStatusProperties,
		getUserProperties,
		getIssueTypeProperties,
		creategGetIdeaInsightCount(id),
		createGetCommentsCount(id || ''),
		creategGetIdeaDeliveryTicketCount(id),
		keySelector,
		isOnboardingExperimentIssueSelector,
		isOnboardingExperimentIssueModifiedSelector,
		(_, props?: Props) => props?.projectId,
		(
			numberProperties,
			statusProperties,
			userProperties,
			issueTypeProperties,
			ideaInsightCount,
			ideaCommentCount,
			ideaDeliveryTicketCount,
			issueKey,
			isOnboardingExperimentIssue,
			isOnboardingExperimentIssueModified,
			projectId,
		) => {
			if (id === undefined) {
				return undefined;
			}

			const issueKeyNumber = getIssueNumberFromIssueKey(issueKey);

			return {
				// Schema https://hello.atlassian.net/wiki/spaces/DQ/pages/875384754/Jira+Product+entities#issue-schema
				issueType: issueTypeProperties[ISSUETYPE_FIELDKEY]?.[id]?.name?.toLowerCase(),
				issueId: numberProperties[ISSUEID_FIELDKEY]?.[id]?.toString(),
				assigneeId: userProperties[ASSIGNEE_FIELDKEY]?.[id]?.accountId,
				issueTypeId: issueTypeProperties[ISSUETYPE_FIELDKEY]?.[id]?.id,
				issueHierarchyLevel: 0,
				issueReporter: userProperties[REPORTER_FIELDKEY]?.[id]?.accountId,
				issueStatusId: statusProperties[STATUS_FIELDKEY]?.[id]?.id,
				statusCategory: statusCategoryForId(
					statusProperties[STATUS_FIELDKEY]?.[id]?.statusCategory?.id,
				),
				statusCategoryId: statusProperties[STATUS_FIELDKEY]?.[id]?.statusCategory?.id,
				// JPD specific attributes
				ideaInsightCount,
				ideaDeliveryTicketCount,
				ideaCommentCount,
				projectId,
				onboardingExperimentIssue: isOnboardingExperimentIssue ? issueKeyNumber : undefined,
				onboardingExperimentIssueModified: isOnboardingExperimentIssueModified,
			};
		},
	);
};

export const createIsFieldAssociatedToIssue = (
	fieldKey: FieldKey,
	localIssueId: LocalIssueId,
	connectionsFieldsAssociations?: Record<FieldKey, IssueTypeId[]>,
) => {
	const getField = createGetField(fieldKey);
	const getIssueType = createGetIssueType(localIssueId);

	return createSelector(getField, getIssueType, (field, issueType) => {
		if (!field || !issueType) return false;

		// global system fields and project system fields are always associated
		if (isGlobalSystemField(field) || !field.custom) {
			return true;
		}

		return (
			field.issueTypes.includes(issueType.id) ||
			!!connectionsFieldsAssociations?.[field.key]?.includes(issueType.id)
		);
	});
};
