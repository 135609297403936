import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { SkeletonCompiled } from './styled.tsx';

// This data is sourced from the Atlassian Design typography guidelines.
// See: https://atlassian.design/guidelines/product/foundations/typography
// There is a PlainText export for regular text, a Paragraph export for grouping multiple
// lines of regular text, and various exports for headings:
//
// * Hx00 sets height equal to font size and margins to fill out the line height for the
//   "Text size x00" typography.  It doesn't account for the prescribed top-margin.
// * Hx00M adds the top margin specified for that typography.
// * Hx00L uses the "long form" line height and top margin for that font size

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const baseCompiled = styled(SkeletonCompiled)({
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PlainTextCompiled = styled(baseCompiled)({
	height: token('space.150'),
	marginBottom: token('space.075'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ParagraphCompiled = styled.div({
	width: '100%',
	marginTop: token('space.150'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H100Compiled = styled(baseCompiled)({
	height: token('space.150'),
	marginTop: token('space.025'),
	marginBottom: token('space.025'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H100MCompiled = styled(H100Compiled)({
	marginTop: token('space.250'),
});

export const H100LCompiled = H100MCompiled;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H200Compiled = styled(baseCompiled)({
	height: token('space.150'),
	marginTop: token('space.025'),
	marginBottom: token('space.025'),
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H200MCompiled = styled(H200Compiled)({
	marginTop: token('space.250'),
});
export const H200LCompiled = H200MCompiled;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H300Compiled = styled(baseCompiled)({
	height: token('space.150'),
	marginTop: token('space.025'),
	marginBottom: token('space.025'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H300MCompiled = styled(H300Compiled)({
	marginTop: token('space.250'),
});

export const H300LCompiled = H300MCompiled;
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H400Compiled = styled(baseCompiled)({
	height: token('space.150'),
	marginTop: token('border.width'),
	marginBottom: token('border.width'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H400MCompiled = styled(H400Compiled)({
	marginTop: token('space.200'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H400LCompiled = styled(H400MCompiled)({
	marginTop: '27px',
	marginBottom: token('space.025'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H500Compiled = styled(baseCompiled)({
	height: token('space.250'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H500MCompiled = styled(H500Compiled)({
	marginTop: token('space.300'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H500LCompiled = styled(H500MCompiled)({
	marginTop: '36px',
	marginBottom: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H600Compiled = styled(baseCompiled)({
	height: token('space.250'),
	marginTop: token('space.025'),
	marginBottom: token('space.025'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H600MCompiled = styled(H600Compiled)({
	marginTop: token('space.400'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H600LCompiled = styled(H600MCompiled)({
	marginTop: token('space.500'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H700Compiled = styled(baseCompiled)({
	height: token('space.300'),
	marginTop: token('space.025'),
	marginBottom: token('space.025'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H700MCompiled = styled(H700Compiled)({
	marginTop: token('space.500'),
});
export const H700LCompiled = H700MCompiled;
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H800Compiled = styled(baseCompiled)({
	height: token('space.400'),
	marginTop: token('border.width'),
	marginBottom: token('space.025'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H800MCompiled = styled(H800Compiled)({
	marginTop: token('space.500'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H800LCompiled = styled(H800MCompiled)({
	marginTop: token('space.600'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H900Compiled = styled(baseCompiled)({
	height: '35px',
	marginTop: token('space.025'),
	marginBottom: token('space.025'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const H900MCompiled = styled(H900Compiled)({
	marginTop: '54px',
});

export const H900LCompiled = H900MCompiled;

// With Feature Gating

export const PlainText = PlainTextCompiled;

export const Paragraph = ParagraphCompiled;

export const H300 = H300Compiled;

export const H500 = H500Compiled;

export const H700 = H700Compiled;
