import { ApolloError } from 'apollo-client';
import { JiraIssueAri } from '@atlassian/ari/jira/issue';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { IssueErrorConstructor } from '@atlassian/jira-polaris-lib-errors/src/common/utils/issue-error/index.tsx';
import type { JiraBulkOperationType } from '@atlassian/jira-polaris-remote-issue/src/services/jira/update-ideas-bulk/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import {
	createGetFieldMapping,
	getConfiguredConnectionFieldsKeys,
} from '../../../selectors/fields.tsx';
import { createGetIdSelector } from '../../../selectors/properties/index.tsx';
import type { State, Props } from '../../../types.tsx';
import { incrementOpenUpdateCounter } from '../../real-time/index.tsx';
import { refreshConnectionFieldsValues } from '../../refresh-connection-field-values/index.tsx';
import { hasIssueTypeField } from '../utils.tsx';
import { mergeObjects } from './utils.tsx';

const getMainApolloError = (error: ApolloError): Error | null => {
	const UNKNOWN_ERROR = 'Unknown error';

	const graphQLErrors = error?.graphQLErrors;
	if (!graphQLErrors?.length) {
		return null;
	}

	// Find the error with the highest status code and use it as the main error
	const mainError = graphQLErrors.reduce<{ statusCode: number | null; message: string }>(
		(acc, currError) => {
			const statusCode = currError.extensions?.statusCode ?? null;
			if (!statusCode || statusCode <= (acc.statusCode ?? 0)) {
				return acc;
			}
			return { statusCode, message: currError.message ?? UNKNOWN_ERROR };
		},
		{ statusCode: null, message: UNKNOWN_ERROR },
	);

	return mainError.statusCode
		? new IssueErrorConstructor(
				mainError.message,
				[
					{
						error: mainError.message,
					},
				],
				mainError.statusCode,
			)
		: null;
};

export const updateFieldValuesOnBackendBulk = (
	state: State,
	props: Props,
	fieldKeys: FieldKey[],
	localIssueIds: LocalIssueId[],
	dispatch: StoreActionApi<State>['dispatch'],
	updateIssuesSuccess: (isBulkEditingOperation: boolean) => void,
	updateIssuesError: (err: Error, isBulkEditingOperation: boolean) => void,
) => {
	dispatch(incrementOpenUpdateCounter(localIssueIds));

	const selectedIssueAris = localIssueIds.map((localIssueId) => {
		const issueId = String(createGetIdSelector(localIssueId)(state));
		return JiraIssueAri.create({
			siteId: props.cloudId,
			issueId,
		}).toString();
	});

	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
	let editedFieldsInput = {} as any;
	fieldKeys.forEach((fieldKey) => {
		const fieldMapping = createGetFieldMapping(fieldKey)(state, props);
		const getFieldValueFn = fieldMapping?.getFieldValueForJiraBulkUpdate;

		if (!getFieldValueFn) {
			return Promise.reject(new Error('Bulk update not supported for this field'));
		}

		const editedFieldInput = getFieldValueFn(
			// since we are bulk editing, we can get the value from the first issue
			fieldMapping.valueAccessor(state, props, localIssueIds[0]),
		);

		if (editedFieldInput) {
			editedFieldsInput = {
				...mergeObjects([editedFieldsInput, editedFieldInput]),
			};
		}
	});

	return props.issuesRemote
		.updateIssueFieldsBulk({
			input: {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				bulkOperationType: 'BULK_EDIT' as JiraBulkOperationType,
				bulkOperationInput: {
					bulkEditInput: {
						selectedActions: fieldKeys,
						selectedIssueIds: selectedIssueAris,
						editedFieldsInput,
						sendBulkNotification: true,
					},
				},
			},
		})
		.then((progress) => {
			if (!progress?.taskId) {
				throw new Error('Invalid progress response');
			}
			props.onIssueBulkUpdate({
				taskId: progress.taskId,
				getUpdateIssueFieldsBulkProgress: props.issuesRemote.getUpdateIssueFieldsBulkProgress,
				onFinished: () => {
					if (fg('jpd_issues_relationships')) {
						// In case fieldKeys include fields of ISSUE_TYPE type, then we need to refresh all connection fields for all issues
						// after the bulk update is done. This is because the issue type change is affecting the connection fields.
						if (hasIssueTypeField(fieldKeys, state, props)) {
							dispatch(
								refreshConnectionFieldsValues(getConfiguredConnectionFieldsKeys(state, props)),
							);
						}
					}
				},
			});
			updateIssuesSuccess && updateIssuesSuccess(true);
			return progress;
		})
		.catch((err) => {
			let error: Error = err;
			if (err instanceof ApolloError) {
				const mainApolloError = getMainApolloError(err);
				if (mainApolloError) {
					error = mainApolloError;
				}
			}
			props.onIssueUpdateFailed(error);
			updateIssuesError && updateIssuesError(error, true);
			return error;
		});
};
