// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook } from '@atlassian/react-sweet-state';
import { store } from './index.tsx';
import type { ScrollTarget, State } from './types.tsx';

const getScrollTarget = (state: State): ScrollTarget => state.scrollTarget;

export const useScrollStoreScrollTarget = createHook(store, {
	selector: getScrollTarget,
});

export const useScrollStoreScrollTargetActions = createHook(store, {
	selector: null,
});
