import React, { memo, useMemo } from 'react';
import { styled } from '@compiled/react';
import type { DocNode } from '@atlaskit/adf-schema';
import { defaultSchema } from '@atlaskit/adf-schema/schema-default';
import { ErrorMessage } from '@atlaskit/form';
import Link from '@atlaskit/link';
import { ReactRenderer } from '@atlaskit/renderer';
import { token } from '@atlaskit/tokens';
import { useEmojiProvider } from '@atlassian/jira-emoji-provider/src/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	onLinkClick,
	smartLinksDefault,
} from '@atlassian/jira-linking-platform-utils/src/index.tsx';
import type { LinkClickEvent, Props } from './types.tsx';
import { adfDocument, getErrorMessage, getLearnMoreMessage } from './utils.tsx';

const featureFlags = {
	codeBidiWarnings: true,
	'code-bidi-warnings': true,
	'renderer-tti-tracking': true,
	'allow-windowed-code-block': true,
};

const FieldHelpLink = memo(
	({
		fieldHelpTextUrl,
		onLinkClickEvent,
	}: {
		fieldHelpTextUrl: string;
		onLinkClickEvent: LinkClickEvent;
	}) => {
		const learnMoreMessage = getLearnMoreMessage(fieldHelpTextUrl);
		const { formatMessage } = useIntl();
		return (
			<Link
				href={fieldHelpTextUrl}
				target="_blank"
				onClick={(e) => onLinkClickEvent(e, fieldHelpTextUrl)}
			>
				{formatMessage(learnMoreMessage)}
			</Link>
		);
	},
);

export const FieldMessage = (props: Props) => {
	const emojiProvider = useEmojiProvider();

	const { error, fieldName, description = '', fieldHelpTextUrl = '', onLinkClickEvent } = props;

	const { formatMessage, formatNumber } = useIntl();
	const eventHandlers = useMemo(
		() => ({
			smartCard: { onClick: onLinkClick },
			link: {
				onClick:
					onLinkClickEvent ||
					((event, url) => {
						const eventTarget = event.target;
						if (
							getLearnMoreMessage(fieldHelpTextUrl) &&
							url === fieldHelpTextUrl &&
							eventTarget instanceof HTMLAnchorElement
						) {
							eventTarget.target = '_blank';
							return;
						}
						onLinkClick(event, url);
					}),
			},
		}),
		[fieldHelpTextUrl, onLinkClickEvent],
	);

	const document: DocNode | null | undefined = useMemo(() => {
		if (error) {
			return null;
		}
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return adfDocument({ description }) as DocNode;
	}, [description, error]);

	if (error) {
		return (
			<ErrorMessage>{getErrorMessage(error, fieldName, formatMessage, formatNumber)}</ErrorMessage>
		);
	}

	if (!document && !fieldHelpTextUrl) {
		return null;
	}

	return (
		<Description key={`${fieldName}-field-message`}>
			{document && (
				<ReactRenderer
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					document={document!}
					dataProviders={emojiProvider}
					schema={defaultSchema}
					disableHeadingIDs
					featureFlags={featureFlags}
					truncated={false}
					eventHandlers={eventHandlers}
					smartLinks={smartLinksDefault}
				/>
			)}
			{fieldHelpTextUrl && (
				<FieldHelpLink
					fieldHelpTextUrl={fieldHelpTextUrl}
					onLinkClickEvent={eventHandlers.link.onClick}
				/>
			)}
		</Description>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Description = styled.div<{}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.ak-renderer-document': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		font: token('font.body.UNSAFE_small'),
		color: token('color.text.subtlest'),
		marginTop: token('space.050'),
	},
});
