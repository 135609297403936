// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	changeType: {
		id: 'issue.details.change-issue-type',
		defaultMessage: '{issueType} - Change issue type',
		description: '',
	},
	changeTypeGroupName: {
		id: 'issue.details.change-issue-type-groupname',
		defaultMessage: 'Change Issue Type',
		description: '',
	},
	changeTypeIssueTermRefresh: {
		id: 'issue.details.change-issue-type-issue-term-refresh',
		defaultMessage: '{issueType} - Change work type',
		description: '',
	},
	changeTypeGroupNameIssueTermRefresh: {
		id: 'issue.details.change-issue-type-groupname-issue-term-refresh',
		defaultMessage: 'Change Work Type',
		description: '',
	},
	createWorkTypeButton: {
		id: 'issue.details.create-work-type-button',
		defaultMessage: 'Create work type',
		description: 'Name of button used to create a new work type when clicked',
	},
	createIssueTypeButton: {
		id: 'issue.details.create-issue-type-button',
		defaultMessage: 'Create issue type',
		description: 'Name of button used to create a new issue type when clicked',
	},
	editTypeLabel: {
		id: 'issue.details.edit-type-label',
		defaultMessage: 'Edit type',
		description: 'Name of the tooltip label used to edit an issue type when clicked',
	},
	manageButton: {
		id: 'issue.details.manage-button',
		defaultMessage: 'Manage',
		description: 'Name of button used to manage issue types via project settings when clicked',
	},
});
