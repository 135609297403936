import { DateOnly } from '@atlassian/jira-platform-utils-date-only/src/index.tsx';
/**
 * Normalizes a given date by removing the time component.
 *
 * @param {Date} date - The date to be normalized.
 * @returns {Date} - The normalized date without the time component.
 */
const normalizeDate = (date: Date): Date =>
	new Date(date.getFullYear(), date.getMonth(), date.getDate());

/**
 * Adds a specified number of days to a given date.
 *
 * @param date - The date to which the days should be added.
 * @param days - The number of days to add.
 * @returns The new date after adding the specified number of days.
 */
const datePlusXDays = (date: Date, days: number): Date =>
	normalizeDate(new Date(date.getTime() + days * 24 * 60 * 60 * 1000));

/**
 * Determines if a given date is in the past or due soon. Ignores the time component in comparison
 * Disclaimer: Use this function only for dates in local timezone / non-UTC format as it does not factor them.
 * Details - https://hello.atlassian.net/wiki/spaces/JBus/blog/2024/01/15/3264652858/Why+the+date+is+getting+set+to+the+day+prior
 *
 * @param dateString - The date string to check.
 * @param dueDaysOffset - The number of days to consider as "due soon". Default is 7.
 * @returns An object with two boolean properties: `isDueDatePast` and `isDueSoon`.
 */
export const getIsDatePastOrDueSoon = (dateString: string | null, dueDaysOffset = 7) => {
	if (!dateString) {
		return { isDueDatePast: false, isDueSoon: false };
	}
	const date = normalizeDate(new Date(dateString));
	if (Number.isNaN(date.getTime())) {
		return { isDueDatePast: false, isDueSoon: false };
	}

	const TODAY = normalizeDate(new Date());
	const TODAY_PLUS_DUE_DAYS_OFFSET = datePlusXDays(TODAY, dueDaysOffset);
	const isDueDatePast = date.getTime() === TODAY.getTime() || date < TODAY;
	const isDueSoon = !isDueDatePast && date < TODAY_PLUS_DUE_DAYS_OFFSET;
	return { isDueDatePast, isDueSoon };
};

/**
 * Determines if a given UTC date is past or due soon. Returns false if the date string is invalid / non UTC fomrat.
 *
 * @param {string | null} dateString - The date string in ISO format(yyyy-mm-dd).
 * @param {number} [dueDaysOffset=7] - The number of days to consider for "due soon". Defaults to 7 days.
 * @returns {{ isDueDatePast: boolean, isDueSoon: boolean }} An object indicating if the date is past or due soon.
 */
export const getIsUTCDatePastOrDueSoon = (dateString: string | null, dueDaysOffset = 7) => {
	if (!dateString) {
		return { isDueDatePast: false, isDueSoon: false };
	}
	let inputDateInISO;
	try {
		inputDateInISO = DateOnly.fromISODateString(dateString).toLocalDate();
	} catch (e) {
		return { isDueDatePast: false, isDueSoon: false };
	}
	const TODAY = DateOnly.fromDateAccordingToLocalTime(new Date()).toLocalDate();
	const TODAY_PLUS_DUE_DAYS_OFFSET = datePlusXDays(TODAY, dueDaysOffset);
	const isDueDatePast = inputDateInISO.getTime() === TODAY.getTime() || inputDateInISO < TODAY;
	const isDueSoon = !isDueDatePast && inputDateInISO < TODAY_PLUS_DUE_DAYS_OFFSET;
	return { isDueDatePast, isDueSoon };
};

/**
 * Determines if a given UTC date is due today or within 7 days overdue. Returns null if the date string is invalid / non UTC format.
 *
 * @param {string | null} dateString - The date string in ISO format (yyyy-mm-dd).
 * @returns {{ isDueToday: boolean, isWithin7DaysOverdue: boolean }} An object indicating if the date is due today or within 7 days overdue.
 */
export const getIsUTCDateDueTodayOrWithin7DaysOverdue = (dateString: string | null) => {
	if (!dateString) {
		return { isDueToday: null, isWithin7DaysOverdue: null };
	}
	let inputDateInISO;
	try {
		inputDateInISO = DateOnly.fromISODateString(dateString).toLocalDate();
	} catch (e) {
		return { isDueToday: null, isWithin7DaysOverdue: null };
	}
	const TODAY = DateOnly.fromDateAccordingToLocalTime(new Date()).toLocalDate();
	const TODAY_MINUS_7_DAYS = datePlusXDays(TODAY, -7);
	const isDueToday = inputDateInISO.toDateString() === TODAY.toDateString();
	const isWithin7DaysOverdue =
		!isDueToday && inputDateInISO >= TODAY_MINUS_7_DAYS && inputDateInISO < TODAY;
	return { isDueToday, isWithin7DaysOverdue };
};

/**
 * Calculates the number of days since the given UTC date string.
 *
 * @param {string | null} dateString - The date string in ISO format(YYYY-MM-DD) to calculate the days since.
 * @returns {number | null} The number of days since the given date, or null if the date string is invalid.
 */
export const getNoOfDaysSinceUTCDateString = (dateString: string | null) => {
	if (!dateString) {
		return null;
	}
	let inputDateInISO;
	try {
		inputDateInISO = DateOnly.fromISODateString(dateString).toLocalDate();
	} catch (e) {
		return null;
	}
	const TODAY = DateOnly.fromDateAccordingToLocalTime(new Date()).toLocalDate();
	const daysBetween = Math.floor(
		(TODAY.getTime() - inputDateInISO.getTime()) / (1000 * 60 * 60 * 24),
	);
	return daysBetween;
};
