/**
 * @generated SignedSource<<c5935cf9be3db8627bb9c49811bbe213>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueViewFoundation_VoteButtonRelay$data = {
  readonly __typename: "JiraVotesField";
  readonly fieldId: string;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"voteFieldServiceRelay_issueFieldVoters_UseVoteFieldRelayGenerator">;
  readonly " $fragmentType": "main_issueViewFoundation_VoteButtonRelay";
};
export type main_issueViewFoundation_VoteButtonRelay$key = {
  readonly " $data"?: main_issueViewFoundation_VoteButtonRelay$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueViewFoundation_VoteButtonRelay">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueViewFoundation_VoteButtonRelay",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "FragmentSpread",
      "name": "voteFieldServiceRelay_issueFieldVoters_UseVoteFieldRelayGenerator"
    }
  ],
  "type": "JiraVotesField"
};

(node as any).hash = "4565cbba7cbdab05beee50b983a5c6c8";

export default node;
