import React from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import ProgressBar from '@atlassian/jira-common-components-progress-bar/src/main.tsx';

import ProgressElement from '@atlassian/jira-common-components-progress-bar/src/progress-element/index.tsx';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage } from '@atlassian/jira-intl';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import messages from './messages.tsx';
import {
	DONE_SEGMENT_COLOR,
	IN_PROGRESS_SEGMENT_COLOR_HIGHER_CONTRAST,
	TODO_SEGMENT_COLOR_HIGHER_CONTRAST,
} from './styled.tsx';

const CLASSIC_SUBTASK_PANEL_TOOLTIP = {
	remainingTooltip: messages.todoSubtasksSegmentTooltipText,
	completedTooltip: messages.completedSubtasksSegmentTooltipText,
	inProgressTooltip: messages.inProgressSubtasksSegmentTooltipText,
} as const;

const CHILD_ISSUE_PANEL_TOOLTIP_ISSUE_TERM_REFRESH = {
	remainingTooltip: messages.todoIssuesSegmentTooltipTextIssueTermRefresh,
	completedTooltip: messages.completedIssuesSegmentTooltipTextIssueTermRefresh,
	inProgressTooltip: messages.inProgressIssuesSegmentTooltipTextIssueTermRefresh,
} as const;

const CHILD_ISSUE_PANEL_TOOLTIP = {
	remainingTooltip: messages.todoIssuesSegmentTooltipText,
	completedTooltip: messages.completedIssuesSegmentTooltipText,
	inProgressTooltip: messages.inProgressIssuesSegmentTooltipText,
} as const;

export type Props = {
	isClassicSubtaskPanel: boolean;
	totalIssueCount: number;
	inProgressIssueCount: number;
	completedIssueCount: number;
};

export const ProgressSummaryView = (props: Props) => {
	const { formatMessage } = useIntl();
	const { isClassicSubtaskPanel, totalIssueCount, inProgressIssueCount, completedIssueCount } =
		props;

	// Note: on rare occasions, it is possible for todoIssueCount to include issues with UNDEFINED status category.
	const todoIssueCount = totalIssueCount - inProgressIssueCount - completedIssueCount;

	// Math.floor instead of Math.round so we don't incorrectly show 100% Done when we're only at 99.5%.
	const completionPercentage = Math.floor((completedIssueCount / totalIssueCount) * 100);
	const CHILD_ISSUE_PANEL_TOOLTIP_MESSAGE = fg('jira-issue-terminology-refresh-m3')
		? CHILD_ISSUE_PANEL_TOOLTIP_ISSUE_TERM_REFRESH
		: CHILD_ISSUE_PANEL_TOOLTIP;

	const { remainingTooltip, completedTooltip, inProgressTooltip } = isClassicSubtaskPanel
		? CLASSIC_SUBTASK_PANEL_TOOLTIP
		: CHILD_ISSUE_PANEL_TOOLTIP_MESSAGE;

	const progressElementCompleted = (
		<ProgressElement
			value={completedIssueCount}
			color={DONE_SEGMENT_COLOR}
			tooltipContent={formatMessage(completedTooltip, {
				completedIssueCount,
				totalIssueCount,
			})}
		/>
	);

	const progressElementInProgress = (
		<ProgressElement
			value={inProgressIssueCount}
			color={IN_PROGRESS_SEGMENT_COLOR_HIGHER_CONTRAST}
			tooltipContent={formatMessage(inProgressTooltip, {
				inProgressIssueCount,
				totalIssueCount,
			})}
		/>
	);

	const childWorkProgressSummaryLabel = fg('jira-issue-terminology-refresh-m3')
		? messages.childWorkProgressSummaryLabelRefresh
		: messages.childWorkProgressSummaryLabel;

	// Extra the common content from newProgressBarContainer and oldProgressBarContainer
	const progressBarContent = (
		<ProgressBar
			sum={totalIssueCount}
			backgroundColor={TODO_SEGMENT_COLOR_HIGHER_CONTRAST}
			remainingTooltipContent={formatMessage(remainingTooltip, {
				todoIssueCount,
				totalIssueCount,
			})}
			height={8}
			aria-label={formatMessage(childWorkProgressSummaryLabel)}
		>
			{progressElementCompleted}
			{progressElementInProgress}
		</ProgressBar>
	);

	if (expVal('jira-issue-view-chin-ga', 'isChildIssueEnabled', false)) {
		return (
			<ProgressSummaryContainer>
				<ProgressBarContainer>{progressBarContent}</ProgressBarContainer>
				<CompletionPercentageContainer
					{...(fg('jfp_a11y_fix_announce_updated_progress')
						? {
								'aria-live': 'polite',
								'aria-atomic': 'true',
							}
						: {
								'aria-hidden': 'true',
							})}
					data-testid="issue-view-common-views.child-issues-panel.progress-summary.progress-percentage-container"
				>
					<FormattedMessage
						{...messages.completionPercentageText}
						values={{
							completionPercentage: (
								<PercentageValueContainer>{completionPercentage}</PercentageValueContainer>
							),
						}}
					/>
				</CompletionPercentageContainer>
			</ProgressSummaryContainer>
		);
	}
	return (
		<OldProgressSummaryContainer>
			<OldProgressBarContainer>{progressBarContent}</OldProgressBarContainer>
			<OldCompletionPercentageContainer
				{...(fg('jfp_a11y_fix_announce_updated_progress')
					? {
							'aria-live': 'polite',
							'aria-atomic': 'true',
						}
					: {
							'aria-hidden': 'true',
						})}
				data-testid="issue-view-common-views.child-issues-panel.progress-summary.progress-percentage-container"
			>
				<FormattedMessage
					{...messages.completionPercentageText}
					values={{
						completionPercentage: (
							<OldPercentageValueContainer>{completionPercentage}</OldPercentageValueContainer>
						),
					}}
				/>
			</OldCompletionPercentageContainer>
		</OldProgressSummaryContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OldProgressSummaryContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	flexGrow: 2,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProgressSummaryContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	alignSelf: 'center',
	flexGrow: 2,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OldProgressBarContainer = styled.div({
	flexGrow: 1,
	marginTop: token('space.100'),
	marginBottom: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProgressBarContainer = styled.div({
	flexGrow: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OldCompletionPercentageContainer = styled.div({
	marginLeft: token('space.200'),
	marginRight: token('space.025'),
	flexShrink: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CompletionPercentageContainer = styled.div({
	marginLeft: token('space.200'),
	marginRight: token('space.200'),
	flexShrink: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest'),
});

// Fixed width span is used to contain the actual completion percentage value. This will ensure the progress bar width
// remains constant no matter how many digits there are in the percentage value.

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OldPercentageValueContainer = styled.span({
	display: 'inline-block',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 3}px`,
	textAlign: 'right',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PercentageValueContainer = styled.span({
	display: 'inline-block',
});
