import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import ConfirmIcon from '@atlaskit/icon/core/migration/check-mark--check';
import CancelIcon from '@atlaskit/icon/core/migration/close--cross';
import { token } from '@atlaskit/tokens';

interface ButtonsProp {
	confirmButtonLabel: string;
	cancelButtonLabel: string;
	onMouseDown?: () => void;
	onCancelClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const Buttons = ({
	confirmButtonLabel,
	cancelButtonLabel,
	onMouseDown,
	onCancelClick,
}: ButtonsProp) => (
	<ButtonsContainer>
		<ButtonWrapper>
			<Button
				aria-label={confirmButtonLabel}
				type="submit"
				iconBefore={<ConfirmIcon label={confirmButtonLabel} LEGACY_size="small" />}
				shouldFitContainer
				onMouseDown={onMouseDown}
			/>
		</ButtonWrapper>
		<ButtonWrapper>
			<Button
				aria-label={cancelButtonLabel}
				iconBefore={<CancelIcon label={cancelButtonLabel} LEGACY_size="small" />}
				onClick={onCancelClick}
				shouldFitContainer
				onMouseDown={onMouseDown}
			/>
		</ButtonWrapper>
	</ButtonsContainer>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonsContainer = styled.div({
	display: 'flex',
	marginBlockStart: token('space.075'),
	position: 'absolute',
	insetBlockStart: '100%',
	insetInlineEnd: 0,
	flexShrink: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonWrapper = styled.div({
	boxSizing: 'border-box',
	width: token('space.400'),
	zIndex: 200,
	backgroundColor: token('elevation.surface.overlay'),
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "4px" and fallback "3px" do not match and can't be replaced automatically.
	borderRadius: token('border.radius', '3px'),
	font: token('font.body'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:last-child': {
		marginInlineStart: token('space.050'),
	},
	boxShadow: token('elevation.shadow.overlay'),
	// These styles are copied from @atlaskit/inline-edit hence the unsafe nested selectors. Once AK adopts a platform
	// friendly solution, e.g. xcss, that should be adopted here as well.

	// These buttons are floating, so they need an override to overlay interaction states
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& > button': {
		backgroundColor: token('elevation.surface.overlay'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& > button:hover': {
		backgroundColor: token('elevation.surface.overlay.hovered'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& > button:active': {
		backgroundColor: token('elevation.surface.overlay.pressed'),
		color: token('color.text'),
	},
});

export default Buttons;
