// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook } from '@atlassian/react-sweet-state';
import type { EntityLimitType, EntityLimits } from '../../../common/types.tsx';
import { EntityLimitsStore, type Actions } from '../main.tsx';
import type { State } from '../types.tsx';
import { getEntityLimits } from './entity-limits.tsx';

export const useEntityLimits = createHook<State, Actions, EntityLimits | null>(EntityLimitsStore, {
	selector: getEntityLimits,
});

// Get the limit for a specific entity type
export const useEntityLimitsByType = (limitType: EntityLimitType) => {
	const [entityLimits] = useEntityLimits();
	return entityLimits?.[limitType] ?? null;
};
