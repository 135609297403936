import React, { useCallback, useContext, useState } from 'react';
import type { Dispatch } from 'redux';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { Box, Flex, xcss, Text } from '@atlaskit/primitives';
import AkToolTip from '@atlaskit/tooltip';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import { eventHub, findEditorId } from '@atlassian/editor-plugin-ai/EventHub';
import {
	useRelatedIssuesActions,
	steps as relatedIssuesSteps,
	JiraIssueRelatedIssuesContainer,
} from '@atlassian/jira-ai-related-issues/src/controllers/context.tsx';
import { useHasRelatesToIssueLinkType } from '@atlassian/jira-ai-related-issues/src/utils/index.tsx';
import { RelatedResourcesContainer } from '@atlassian/jira-ai-related-resources/src/controllers/related-resources-container.tsx';
import { useRelatedResourcesActions } from '@atlassian/jira-ai-related-resources/src/controllers/related-resources-context.tsx';
import {
	useAiWorkBreakDownAnalytics,
	IMPROVE_ISSUE_DROPDOWN_ID,
} from '@atlassian/jira-ai-work-breakdown/src/common/analytics/index.tsx';
import { ISSUE_VIEW_CONSUMER } from '@atlassian/jira-ai-work-breakdown/src/constants.tsx';
import { AiIssueBreakdownEntryPointContext } from '@atlassian/jira-ai-work-breakdown/src/controllers/context-provider.tsx';
import { useAiRelatedIssuesEntryPointActions } from '@atlassian/jira-ai-related-issues/src/controllers/context-provider.tsx';
import {
	useIssueBreakdown,
	steps,
	IssueBreakdownAiContainer,
} from '@atlassian/jira-ai-work-breakdown/src/controllers/context.tsx';
import { AiIcon } from '@atlassian/jira-atlassian-intelligence/src/common/ui/ai-icon/index.tsx';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import type { EntryPointActions } from '@atlassian/jira-entry-point/src/controllers/utils/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useSmartSummarizeCommentsAnalytics } from '@atlassian/jira-issue-smart-request-summary-state/src/common/analytics/index.tsx';
import { useActions as useSmartSummaryActions } from '@atlassian/jira-issue-smart-request-summary-state/src/controllers/state/index.tsx';
import { SummaryStateContainer } from '@atlassian/jira-issue-smart-request-summary-state/src/ui/index.tsx';
import type { Action } from '@atlassian/jira-issue-view-actions/src/index.tsx';
import { ISSUE_DESCRIPTION_EDITOR_ID } from '@atlassian/jira-issue-view-common-constants/src/index.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { useChildPanelRenderTrigger } from '@atlassian/jira-issue-view-common-views/src/child-issues-panel/hooks/child-panel-render-trigger/index.tsx';
import {
	CLASSIC_SUBTASKS,
	CHILDREN_ISSUES_PANEL,
	CLASSIC_PROJECT_EPIC_CHILDREN,
} from '@atlassian/jira-issue-view-common-views/src/child-issues-panel/model/types.tsx';
import { IssueSmartRequestSummaryEntryPointContext } from '@atlassian/jira-issue-view-smart-request-summary-entrypoint/src/index.tsx';
import { linkConfluencePageClickedWithAISuggestions } from '@atlassian/jira-issue-view-store/src/actions/confluence-pages-actions.tsx';
import {
	addIssueLinkClick,
	onAILinkedIssueSuggestion,
} from '@atlassian/jira-issue-view-store/src/actions/issue-links-actions.tsx';
import { improveIssueDropdownPermissionsSelector } from '@atlassian/jira-issue-view-store/src/selectors/improve-issue-dropdown-selector.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	useProjectKey,
	useApplication,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { JIRA_CORE, JIRA_SOFTWARE } from '@atlassian/jira-shared-types/src/application.tsx';
import { useRelatedConfluencePagesTriggerRef } from '@atlassian/jira-ai-related-resources/src/controllers/entrypoint-params-store.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { getAiActions } from './get-ai-actions/index.tsx';
import { messages } from './messages.tsx';
import { useImproveIssueStore } from './store.tsx';
import type { AiActionReturn, Props, ChildPanel } from './types.tsx';

/*
	Escape hatch for testing purpose
*/
export const defaultPopupState = () => false;

export const ItemView = (itemToRender: AiActionReturn) => {
	const { id, label, icon, onClick, testId, ref, shouldRender, disabledMessage } = itemToRender;
	const [isPopupOpen, setIsPopupOpen] = useState(defaultPopupState());

	return shouldRender ? (
		<DropdownItem key={id} onClick={onClick} ref={ref} testId={testId} elemBefore={icon}>
			{label}
		</DropdownItem>
	) : (
		<Popup
			key={id}
			zIndex={layers.modal}
			isOpen={isPopupOpen}
			onClose={() => setIsPopupOpen(false)}
			placement="right-start"
			messageId="issue-improve-issue-dropdown.popup"
			messageType="transactional"
			content={() => <Box xcss={popupContentStyles}>{disabledMessage ?? ''}</Box>}
			trigger={(triggerProps) => (
				<DropdownItem
					{...triggerProps}
					onClick={() => setIsPopupOpen(!isPopupOpen)}
					testId={testId}
					elemBefore={icon}
				>
					{label}
				</DropdownItem>
			)}
		/>
	);
};

export const useAiIssueBreakdownEntryPointActions = (): EntryPointActions =>
	useContext(AiIssueBreakdownEntryPointContext).entryPointActions;

export const useAiSmartSummaryEntryPointActions = (): EntryPointActions =>
	useContext(IssueSmartRequestSummaryEntryPointContext).entryPointActions;

const editorSelector = `[data-editor-container-id="${ISSUE_DESCRIPTION_EDITOR_ID}"]`;

export const triggerEditorStreamHubEvent = () => {
	const targetEditorId = findEditorId(editorSelector);
	if (targetEditorId) {
		eventHub.publishToEditor({
			event: 'start prompt',
			data: {
				prompt: 'issue reformatter',
				targetEditorId,
				analyticSourceId: 'jiraIssueReformatterExternalButton',
			},
		});
		// Indicates that the event was published successfully
		return true;
	}
	// Indicates that the event couldn't be published
	return false;
};

export const ImproveIssueQuickAddItem = ({
	supportsChildCreation,
	shouldShowCreateSubtaskButton,
	shouldShowIssueInEpicButton,
	onLinkConfluencePageClickWithAISuggestions,
	scope,
	visibleCommentIds,
	hasIssueDescription,
	canLinkConfluencePage,
	canAddIssueLinks,
	onLinkedIssueSuggestion,
	onSimilarItemsTrigger,
}: Props) => {
	const [open, setOpen] = useState(false);
	const { formatMessage } = useIntl();
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const application = useApplication(projectKey, true);
	const [{ canEditIssues }] = useProjectPermissions(projectKey);
	const [{ onEditAction }, { setOnEditorReady, resetOnEditorReady }] = useImproveIssueStore();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const hasRelatesToLinkType = useHasRelatesToIssueLinkType();
	const relatedConfluencePagesRef = useRelatedConfluencePagesTriggerRef();

	const toggleMenuOpen = useCallback(({ isOpen }: { isOpen: boolean }) => {
		setOpen(isOpen);
	}, []);

	const [
		{ channelId },
		{
			setIssueBreakdownStep,
			updateStreamingStatus,
			isIssueBreakdownOpen,
			incrementIssueBreakDownButtonClickCount,
		},
	] = useIssueBreakdown();

	const { fireTrack: fireAiWorkBreakdownAnalytics } = useAiWorkBreakDownAnalytics({
		channelId,
		consumer: ISSUE_VIEW_CONSUMER,
	});
	const [_, { setWhichChildPanelRenderTriggered }] = useChildPanelRenderTrigger();
	const issueBreakdownEntryPointActions = useAiIssueBreakdownEntryPointActions();
	const issueBreakdownRef = useEntryPointButtonTrigger(issueBreakdownEntryPointActions);

	const { loadSummaryStream } = useSmartSummaryActions();
	const { fireTrack: fireSmartSummaryAnalytics } = useSmartSummarizeCommentsAnalytics();
	const smartSummaryEntrypointActions = useAiSmartSummaryEntryPointActions();
	const smartSummaryRef = useEntryPointButtonTrigger(smartSummaryEntrypointActions);

	const relatedIssuesEntryPointActions = useAiRelatedIssuesEntryPointActions();
	const relatedIssuesRef = useEntryPointButtonTrigger(relatedIssuesEntryPointActions);

	const onIssueBreakdownClick = useCallback(() => {
		if (!isIssueBreakdownOpen()) {
			fg('proactive_ai_suggestions_for_child_items_targeting') &&
				incrementIssueBreakDownButtonClickCount();
			setWhichChildPanelRenderTriggered(scope);
			setIssueBreakdownStep(steps.draftListStep);
			updateStreamingStatus(true);
			fireAiWorkBreakdownAnalytics('aiInteraction initiated', 'issueBreakdownButtonClicked', {
				invokedFrom: IMPROVE_ISSUE_DROPDOWN_ID,
			});
		}
	}, [
		isIssueBreakdownOpen,
		setWhichChildPanelRenderTriggered,
		scope,
		setIssueBreakdownStep,
		updateStreamingStatus,
		fireAiWorkBreakdownAnalytics,
		incrementIssueBreakDownButtonClickCount,
	]);

	/**
	 * Related Work Items handlers
	 */
	const { setRelatedIssuesStep, setInvokedFrom } = useRelatedIssuesActions();
	/**
	 * For proactive mode, we trigger the similar items trigger which is the same experience as clicking "Linked work item" or the "+" button on the Linked Issues panel
	 */
	const onRelatedIssuesClick = useCallback(() => {
		if (expVal('proactive-jira-ai-similar-issues', 'isEnabled', false)) {
			setInvokedFrom('improveIssueDropdown');
			onSimilarItemsTrigger();
		} else {
			onLinkedIssueSuggestion?.(true);
			setRelatedIssuesStep(relatedIssuesSteps.fetching);
		}
	}, [onLinkedIssueSuggestion, setRelatedIssuesStep, setInvokedFrom, onSimilarItemsTrigger]);

	/**
	 * Smart Summary handlers
	 */
	const onSmartSummaryClick = useCallback(() => {
		loadSummaryStream();
		fireSmartSummaryAnalytics('aiInteraction initiated', 'jiraSmartSummarizeComments', {
			invokedFrom: IMPROVE_ISSUE_DROPDOWN_ID,
		});
	}, [loadSummaryStream, fireSmartSummaryAnalytics]);

	/*
		The order of events is important. Edit action should be triggered before
		stream hub event to set the right focus
	*/
	const onImproveDescriptionClick = useCallback(() => {
		onEditAction?.();
		const isPublished = triggerEditorStreamHubEvent();
		if (!isPublished) {
			// Event isn't published. The editor wasn't ready. Hence we schedule an event instead
			setOnEditorReady(() => {
				triggerEditorStreamHubEvent();
				// Reset the onEditorReady callback to ensure it's not called again
				resetOnEditorReady();
			});
		}
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: 'editorPluginAI',
				action: 'prompt',
			}),
			'userFlow',
			{
				source: 'improveIssueDropdown',
				experienceName: 'Add structure',
				invokedFrom: 'improveIssueDropdown',
			},
		);
	}, [onEditAction, createAnalyticsEvent, setOnEditorReady, resetOnEditorReady]);

	/**
	 * Related Confluence Pages handlers
	 * aiInteraction initiated are currently sent directly from the Related Resources component
	 * on mount, via a useEffect - exploration to pass through an `invokedFrom` prop is ongoing
	 * src/packages/issue/ai-improve-issue/ai-related-resources/src/ui/index.tsx
	 */
	const { fetchSuggestions } = fg('jira_ai_powered_issue_related_confluence_resources')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useRelatedResourcesActions()
		: { fetchSuggestions: noop };
	const onRelatedResourcesClick = useCallback(() => {
		onLinkConfluencePageClickWithAISuggestions?.();
		fetchSuggestions('improveIssueDropdown');
	}, [fetchSuggestions, onLinkConfluencePageClickWithAISuggestions]);

	/**
	 * The following checks are used to determine conditions of the AI actions
	 */
	const isIssueBreakdownEnabled =
		supportsChildCreation || shouldShowCreateSubtaskButton || shouldShowIssueInEpicButton;

	const isSmartSummaryEnabled = Number(visibleCommentIds?.length) > 0;

	/**
	 * Business decision to only enable for JSW and not JWM
	 */
	const isImproveDescriptionEnabled = fg('platform_gravityai-1853_issue_reformatter_ga')
		? hasIssueDescription && canEditIssues
		: hasIssueDescription && canEditIssues && application === JIRA_SOFTWARE;

	const isRelatedConfluencePagesEnabled =
		canLinkConfluencePage && fg('jira_ai_powered_issue_related_confluence_resources');

	const isRelatedIssuesEnabled =
		canAddIssueLinks && hasRelatesToLinkType && fg('jira_ai_related_issue_enabled');

	/**
	 * The multiple ternary will be refactored into one conditional, when jira-issue-terminology-refresh-m3 is shipped
	 */
	const relatedIssuesDisabledMessage = (() => {
		if (!canAddIssueLinks) {
			if (fg('aix-967-similar-issues-content-review')) {
				return formatMessage(messages.aiSuggestRelatedIssuesDisabledMessageReviewed);
			}
			if (fg('jira-issue-terminology-refresh-m3')) {
				return formatMessage(messages.aiSuggestRelatedIssuesDisabledMessageIssueTermRefresh, {
					b: (text: React.ReactNode) => <b>{text}</b>,
				});
			}
			return formatMessage(messages.aiSuggestRelatedIssuesDisabledMessage, {
				b: (text: React.ReactNode) => <b>{text}</b>,
			});
		}

		if (fg('aix-967-similar-issues-content-review')) {
			return formatMessage(messages.aiSuggestRelatedIssueLinkTypeDisabledMessageReviewed, {
				b: (text: React.ReactNode) => <b>{text}</b>,
			});
		}

		if (fg('jira-issue-terminology-refresh-m3')) {
			return formatMessage(
				messages.aiSuggestRelatedIssuesIssueLinkTypeDisabledMessageIssueTermRefresh,
				{
					b: (text: React.ReactNode) => <b>{text}</b>,
				},
			);
		}
		return formatMessage(messages.aiSuggestRelatedIssuesIssueLinkTypeDisabledMessage, {
			b: (text: React.ReactNode) => <b>{text}</b>,
		});
	})();

	const aiActions = getAiActions({
		application,
		formatMessage,
		issueBreakdownRef,
		onIssueBreakdownClick,
		isIssueBreakdownEnabled,
		onSmartSummaryClick,
		smartSummaryRef,
		onRelatedResourcesClick,
		isSmartSummaryEnabled,
		isImproveDescriptionEnabled,
		onImproveDescriptionClick,
		isRelatedConfluencePagesEnabled,
		isRelatedIssuesEnabled,
		relatedIssuesRef,
		onRelatedIssuesClick,
		relatedIssuesDisabledMessage,
		relatedConfluencePagesRef,
	});

	const createItemView = useCallback(ItemView, []);

	const mappedAiActions = aiActions.map(createItemView);

	const onDropdownClick = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'improveIssueDropdown', {
				isIssueBreakdownEnabled,
				isSmartSummaryEnabled,
				isImproveDescriptionEnabled,
				isRelatedConfluencePagesEnabled,
				isRelatedIssuesEnabled,
			});
		},
		[
			isImproveDescriptionEnabled,
			isIssueBreakdownEnabled,
			isRelatedConfluencePagesEnabled,
			isSmartSummaryEnabled,
			isRelatedIssuesEnabled,
		],
	);

	return (
		<Flex xcss={flexContainerStyles} testId="issue-improve-issue-dropdown.wrapper">
			<DropdownMenu
				trigger={({ triggerRef, onClick, ...rest }) => (
					<AkToolTip
						content={formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.buttonImproveIssueIssueTermRefresh
								: messages.buttonImproveIssue,
						)}
					>
						<Button
							{...rest}
							label={formatMessage(
								fg('jira-issue-terminology-refresh-m3')
									? messages.buttonImproveIssueIssueTermRefresh
									: messages.buttonImproveIssue,
							)}
							ref={triggerRef}
							iconBefore={<AiIcon label="" size="small" isDisabled={!open} />}
							onClick={(
								event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
								analyticsEvent: UIAnalyticsEvent,
							) => {
								onDropdownClick(analyticsEvent);
								onClick && onClick(event);
							}}
						>
							{formatMessage(
								fg('jira-issue-terminology-refresh-m3')
									? messages.buttonImproveIssueIssueTermRefresh
									: messages.buttonImproveIssue,
							)}
						</Button>
					</AkToolTip>
				)}
				testId="issue-improve-issue-dropdown.improve-issue-dropdown"
				shouldRenderToParent
				onOpenChange={toggleMenuOpen}
				isOpen={open}
			>
				<Box paddingInline="space.150" paddingBlockStart="space.200">
					<Text size="small" weight="semibold" color="color.text.subtlest">
						{formatMessage(messages.atlassianIntelligence).toLocaleUpperCase()}
					</Text>
				</Box>
				<DropdownItemGroup>{mappedAiActions}</DropdownItemGroup>
			</DropdownMenu>
		</Flex>
	);
};

/**
 * This scopeSelector function is a business decision to determine the correct childPanelType to use
 * as the scope for the ImproveIssueQuickAddItem component.
 * The decision is that the default button on an issue that adds child issues should open the same panel
 * as the AIWB button on the imrpove issue dropdown
 */
const scopeSelector = ({
	supportsChildCreation,
	shouldShowCreateSubtaskButton,
	shouldShowIssueInEpicButton,
}: ChildPanel) => {
	if (supportsChildCreation) {
		return CHILDREN_ISSUES_PANEL;
	}
	if (shouldShowCreateSubtaskButton) {
		return CLASSIC_SUBTASKS;
	}
	if (shouldShowIssueInEpicButton) {
		return CLASSIC_PROJECT_EPIC_CHILDREN;
	}
	return CHILDREN_ISSUES_PANEL;
};

export const ImproveIssueQuickAddItemWithStores = (props: Omit<Props, 'scope'>) => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const application = useApplication(projectKey, true);

	const { supportsChildCreation, shouldShowCreateSubtaskButton, shouldShowIssueInEpicButton } =
		props;

	const scope = scopeSelector({
		supportsChildCreation,
		shouldShowCreateSubtaskButton,
		shouldShowIssueInEpicButton,
	});

	if (application !== JIRA_CORE && application !== JIRA_SOFTWARE) {
		return null;
	}

	return (
		<SummaryStateContainer>
			<JiraIssueRelatedIssuesContainer scope={`related-issues-${issueKey}`}>
				<IssueBreakdownAiContainer scope={`${issueKey}-${scope}-issue-breakdown`}>
					{fg('jira_ai_powered_issue_related_confluence_resources') ? (
						<RelatedResourcesContainer>
							<ImproveIssueQuickAddItem {...props} scope={scope} />
						</RelatedResourcesContainer>
					) : (
						<ImproveIssueQuickAddItem {...props} scope={scope} />
					)}
				</IssueBreakdownAiContainer>
			</JiraIssueRelatedIssuesContainer>
		</SummaryStateContainer>
	);
};

export const ConnectedImproveIssueQuickAddItemWithStores = connect(
	(state: State) => ({
		...improveIssueDropdownPermissionsSelector(state),
	}),
	(
		dispatch: Dispatch<Action>,
	): {
		onLinkConfluencePageClickWithAISuggestions: () => void;
		onLinkedIssueSuggestion: (arg: boolean) => void;
		onSimilarItemsTrigger: () => void;
	} => ({
		onLinkConfluencePageClickWithAISuggestions: () => {
			dispatch(linkConfluencePageClickedWithAISuggestions());
		},
		onLinkedIssueSuggestion: () => {
			dispatch(onAILinkedIssueSuggestion(true));
		},
		onSimilarItemsTrigger: () => dispatch(addIssueLinkClick()),
	}),
)(ImproveIssueQuickAddItemWithStores);

const flexContainerStyles = xcss({
	marginRight: 'space.100',
});

const popupContentStyles = xcss({
	maxWidth: '300px',
	paddingInline: 'space.300',
	paddingBlock: 'space.200',
});
