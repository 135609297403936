import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	useIsIssueOfIncidentsPractice,
	useIsIssueOfServiceRequestPractice,
} from '@atlassian/jira-issue-field-servicedesk-practices/src/services/use-practices-field-value/index.tsx';
import { usePrefetchableResource } from '@atlassian/jira-resource-with-custom-router-context/src/controllers/use-init-fetch-resource/index.tsx';
import {
	JSM_AI_CONTEXT,
	ENABLED,
} from '@atlassian/jira-servicedesk-common/src/service-project-features/constants.tsx';
import useServiceProjectFeatures from '@atlassian/jira-servicedesk-features-store/src/index.tsx';
import { AIContextOpsPanelLoader } from './ai-context-ops-panel-loader/index.tsx';
import { AIContextServicePanelLoader } from './ai-context-service-panel-loader/index.tsx';

export const AIContextPanel = () => {
	const features = useServiceProjectFeatures();
	const isIncident = useIsIssueOfIncidentsPractice();
	const isServiceRequest = useIsIssueOfServiceRequestPractice();

	usePrefetchableResource(features, true);

	if (features?.data?.[JSM_AI_CONTEXT]?.status !== ENABLED) {
		return null;
	}

	if (isIncident && fg('jsm_issue_view_ai_context_ops')) {
		return (
			<JSErrorBoundary
				id="async-servicedesk-ai-context-ops-panel"
				fallback="unmount"
				teamName="itsm-aiops-grogu"
			>
				<AIContextOpsPanelLoader />
			</JSErrorBoundary>
		);
	}

	if (isServiceRequest) {
		return (
			<JSErrorBoundary
				id="async-servicedesk-ai-context-service-panel"
				fallback="unmount"
				teamName="JSM Agent AI"
			>
				<AIContextServicePanelLoader />
			</JSErrorBoundary>
		);
	}

	return null;
};
