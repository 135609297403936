import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import { AvatarContent } from '@atlaskit/avatar';
import AvatarGroup, { type AvatarGroupProps } from '@atlaskit/avatar-group';
import { token } from '@atlaskit/tokens';

const MAX_AVATARS_FEATURED = 5;
const MAX_SMALL_AVATARS_FEATURED = 2;
const MAX_OVERFLOW_USER_COUNT_DISPLAYED = 99;
const OVERFLOW_USER_MARKER = '$$$overflow-user-marker';

type Props = Omit<AvatarGroupProps, 'onMoreClick'> & { useSmallAvatars?: boolean };

/*
 * The original AkAvatarGroup cannot be used inside an AkFlag, because the More button (e.g. `+2`)
 * opens a popup whose z-index is too low, and gets displayed below the flag.
 */
export const FlagCompatibleAvatarGroup = ({ data, useSmallAvatars, ...props }: Props) => {
	const maxAvatarCount = useSmallAvatars ? MAX_SMALL_AVATARS_FEATURED : MAX_AVATARS_FEATURED;
	const { data: customData, overrides: customOverrides } = useMemo(
		() => getCustomAvatarGroupProps({ data, useSmallAvatars }),
		[data, useSmallAvatars],
	);

	const avatarGroup = (
		<AvatarGroup
			maxCount={maxAvatarCount}
			data={customData}
			overrides={customOverrides}
			boundariesElement="scrollParent"
			tooltipPosition="top"
			onMoreClick={noop} // Prevents the More popup. XXX: The popup has the wrong zIndex and displays under the flag
			size={useSmallAvatars ? 'small' : 'medium'}
			{...props}
		/>
	);

	return avatarGroup;
};

export const getCustomAvatarGroupProps = ({
	data: users,
	useSmallAvatars,
}: Pick<AvatarGroupProps, 'data'> & { useSmallAvatars?: boolean }) => {
	const maxAvatarCount = useSmallAvatars ? MAX_SMALL_AVATARS_FEATURED : MAX_AVATARS_FEATURED;
	const overflows = users.length > maxAvatarCount;
	const lastFeaturedUser = maxAvatarCount - 1;
	const featuredUsers = users.slice(0, lastFeaturedUser);
	const overflowUsers = users.slice(lastFeaturedUser);
	const overflowMarkerDummyUser = getOverflowMarkerDummyUser(overflowUsers);
	const processedUsers = overflows ? [...featuredUsers, overflowMarkerDummyUser] : users;
	const overrides: AvatarGroupProps['overrides'] = {
		Avatar: {
			render: (AvatarComponent, avatarProps) => {
				if (
					avatarProps.name === overflowMarkerDummyUser.name &&
					avatarProps.key === overflowMarkerDummyUser.key
				) {
					return renderAvatarWithOverflowCount(
						AvatarComponent,
						avatarProps,
						overflowUsers.length,
						useSmallAvatars,
					);
				}
				return <AvatarComponent {...avatarProps} />;
			},
		},
	};
	return { overrides, data: processedUsers };
};

export const getOverflowMarkerDummyUser = (overflowUsers: AvatarGroupProps['data']) => ({
	name: overflowUsers.map((u) => u.name).join(', '),
	key: OVERFLOW_USER_MARKER,
});

type AvatarRender = NonNullable<
	NonNullable<NonNullable<AvatarGroupProps['overrides']>['Avatar']>['render']
>;
type AvatarRenderArgs = Parameters<AvatarRender>;

const renderAvatarWithOverflowCount = (
	AvatarComponent: AvatarRenderArgs[0],
	avatarProps: AvatarRenderArgs[1],
	overflowCount: number,
	useSmallAvatars?: boolean,
): ReturnType<AvatarRender> => {
	const Container = useSmallAvatars
		? SmallAvatarOverflowCountContainer
		: AvatarOverflowCountContainer;
	return (
		<AvatarComponent
			name={avatarProps.name}
			appearance={avatarProps.appearance}
			size={avatarProps.size}
			borderColor={avatarProps.borderColor}
			stackIndex={0}
		>
			<AvatarContent>
				<Container>{`+${Math.min(overflowCount, MAX_OVERFLOW_USER_COUNT_DISPLAYED)}`}</Container>
			</AvatarContent>
		</AvatarComponent>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const AvatarOverflowCountContainer = styled.span<{ useSmallAvatars?: boolean }>({
	width: '100%',
	height: '100%',
	display: 'grid',
	placeItems: 'center',
	backgroundColor: token('color.background.neutral'),
	color: token('color.text'),
	textAlign: 'center',
	// eslint-disable-next-line @compiled/shorthand-property-sorting
	fontWeight: token('font.weight.medium'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const SmallAvatarOverflowCountContainer = styled(AvatarOverflowCountContainer)({
	// eslint-disable-next-line @compiled/shorthand-property-sorting
	font: token('font.body.small'),
});
