import React from 'react';
import { useResourceWithCustomRouterContext } from '@atlassian/jira-resource-with-custom-router-context/src/controllers/use-resource-with-custom-router-context/index.tsx';
import { aiContextPanelResource } from '@atlassian/jira-servicedesk-ai-context-panel-resources/src/services/index.tsx';
import { AIContextOpsPanel } from '@atlassian/jira-servicedesk-ai-context-ops-panel/src/ui/index.tsx';

import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import { INCIDENT_MANAGEMENT_FEATURE } from '@atlassian/jira-servicedesk-common/src/service-project-features/constants.tsx';
import type { UseResourceHookResponse } from '@atlassian/react-resource-router';
import type { IncidentSuggestion } from '@atlassian/jira-servicedesk-ai-context-panel-resources/src/services/ops/common/types.tsx';
import { useRefreshOnIssueIdChange } from '../../../controllers/use-refresh-on-issue-id-change/index.tsx';
import type { DummyRoute, DummyRouteMatchParams } from './types.tsx';

type Props = {
	issueId: string;
	issueKey: string;
	projectId: string;
};

// Check the comments in the implementation of useResourceWithCustomRouterContext hook for more detail about this dummy route
const DUMMY_ROUTE_WITH_ISSUE_ID: DummyRoute = {
	name: 'SERVICEDESK_AI_CONTEXT_OPS_PANEL_DUMMY_ROUTE_NAME',
	path: '/servicedesk-ai-context-panel-dummy-route/:projectId/:issueId/:locale/:issueKey/:practice/:skipChecks',
};

export const AIContextOpsPanelWithPrefetchedCommonResource = ({
	issueId,
	issueKey,
	projectId,
}: Props) => {
	const locale = useLocale();
	const resource = useResourceWithCustomRouterContext(
		aiContextPanelResource,
		{
			route: DUMMY_ROUTE_WITH_ISSUE_ID,
			matchParams: {
				projectId,
				issueId,
				issueKey,
				locale,
				practice: INCIDENT_MANAGEMENT_FEATURE,
				skipChecks: 1,
			} satisfies DummyRouteMatchParams,
		},
		{
			shouldPrefetch: true,
		},
	);

	useRefreshOnIssueIdChange({ issueId, refreshResource: resource.refresh });

	if (resource.data && resource.data.type !== 'OpsPanelSuggestions') {
		return null;
	}

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return <AIContextOpsPanel resource={resource as UseResourceHookResponse<IncidentSuggestion>} />;
};
