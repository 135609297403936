import React, { type ReactNode, useCallback, useMemo } from 'react';
import { styled } from '@compiled/react';
import { Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import {
	dismissFlag as createDismissFlag,
	type FlagComponentProps,
	SuccessFlag,
	useFlagService,
} from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { type Product, generateId, getProductDisplayName } from '../../utils/index.tsx';
import { messages } from './messages.tsx';

export const useInviteSuccessFlag = () => {
	const { showFlag, dismissFlag } = useFlagService();
	return useCallback(
		({
			userIds,
			product,
			requireAdminApproval,
		}: {
			userIds: string[];
			product: Product;
			requireAdminApproval: boolean;
		}) => {
			const id = `success-${generateId()}`;
			const dismiss = () => dismissFlag(createDismissFlag(id));

			showFlag({
				id,
				render: (props) => (
					<InviteSuccessFlag
						{...props}
						id={id}
						userIds={userIds}
						onDismiss={dismiss}
						product={product}
						requireAdminApproval={requireAdminApproval}
					/>
				),
			});
		},
		[dismissFlag, showFlag],
	);
};

export type Props = {
	id: FlagComponentProps['id'];
	onDismiss: FlagComponentProps['onDismissed'];
	userIds: string[];
	product: Product;
	requireAdminApproval?: boolean;
};

export const InviteSuccessFlag = ({
	userIds,
	onDismiss,
	product,
	requireAdminApproval,
	...props
}: Props) => {
	const { formatMessage } = useIntl();
	const productDisplayName = getProductDisplayName(product);

	type SuccessFlagProps = React.ComponentProps<typeof SuccessFlag>;

	const customFlagFooterProps = useMemo(
		(): Pick<SuccessFlagProps, 'actions' | 'linkComponent'> => ({
			actions: [
				{
					content: '',
					// Replace with lodash/noop
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					onClick: () => {},
				},
			],
			linkComponent: () =>
				requireAdminApproval && (
					<FooterParagraph>
						{formatMessage(messages.additionalInfoGeneric, {
							strong: (...chunks: ReactNode[]) => <Text as="strong">{chunks.join('')}</Text>,
							productDisplayName,
						})}
					</FooterParagraph>
				),
		}),
		[formatMessage, requireAdminApproval, productDisplayName],
	);

	return (
		<SuccessFlag
			{...props}
			{...customFlagFooterProps}
			onDismissed={onDismiss}
			title={formatMessage(messages.title, {
				inviteeCount: userIds.length,
			})}
			description={
				// eslint-disable-next-line @atlaskit/design-system/use-primitives-text
				<p>
					{formatMessage(messages.descriptionGeneric, {
						strong: (...chunks: ReactNode[]) => <Text as="strong">{chunks.join('')}</Text>,
						inviteeCount: userIds.length,
						productDisplayName,
					})}
				</p>
			}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FooterParagraph = styled.p({
	color: token('color.text.subtle'),
	transform: 'translateX(2px)',
});
