import React from 'react';
import { styled } from '@compiled/react';
import { ExitingPersistence, SlideIn } from '@atlaskit/motion';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import UploadImageIllustration from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/software/components/upload/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';

const DropzoneOverlay = () => {
	const { formatMessage } = useIntl();
	return (
		<ExitingPersistence appear>
			<SlideIn enterFrom="bottom" fade="inout" duration="large">
				{(animateProps) => (
					<IssueBodyContainerDropzonePopup {...animateProps}>
						<Box xcss={imageWrapperStyles}>
							<UploadImageIllustration alt={formatMessage(messages.uploadImage)} fill />
						</Box>
						<TextContainer>
							{formatMessage(
								fg('jira-issue-terminology-refresh-m3')
									? messages.uploadIssueTermRefresh
									: messages.upload,
							)}
						</TextContainer>
					</IssueBodyContainerDropzonePopup>
				)}
			</SlideIn>
		</ExitingPersistence>
	);
};

export default DropzoneOverlay;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueBodyContainerDropzonePopup = styled.div({
	display: 'flex',
	alignItems: 'center',
	position: 'absolute',
	width: '290px',
	height: '64px',
	bottom: token('space.800'),
	zIndex: 101,
	backgroundColor: token('elevation.surface.raised'),
	boxShadow: token('elevation.shadow.raised'),
	borderRadius: '3px',
	right: 0,
	left: 0,
	marginLeft: 'auto',
	marginRight: 'auto',
	// https://bitbucket.org/atlassian/atlassian-frontend/src/17086749154fbc64e237d814f8ecab9e13daa875/packages/design-system/motion/src/utils/accessibility.tsx#lines-57
	'@media (prefers-reduced-motion: reduce)': {
		animation: 'none',
		transition: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextContainer = styled.div({
	width: '184px',
	font: token('font.heading.small'),
	// MUST OCCUR AFTER `font`
	fontWeight: token('font.weight.medium'),
	color: token('color.text'),
});
const imageWrapperStyles = xcss({
	height: '51px',
	paddingLeft: 'space.250',
	position: 'relative',
	paddingRight: 'space.250',
	width: '111px',
});
