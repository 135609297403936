/* eslint-disable jira/react/no-empty-divs */
import React from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';

export type Props = {
	avatarUrl: string;
	label: string;
	email?: string;
	context: 'menu' | 'value';
};

const UserOptionView = ({ avatarUrl, label, email, context }: Props) => {
	const avatarSize = context === 'value' ? 'xsmall' : 'medium';
	const isMenu = context === 'menu';
	return (
		<Wrapper>
			<Avatar
				testId="issue-internal-field-select.common.custom-format.user-option.avatar"
				{...(isVisualRefreshEnabled() ? { borderColor: 'transparent' } : {})}
				size={avatarSize}
				src={avatarUrl}
			/>
			<Content>
				<div>
					<Text>{label}</Text>
				</div>
				{/* eslint-disable-next-line no-nested-ternary */}
				{fg('relay-migration-issue-fields-multi-user-fg') ? (
					isMenu ? (
						<div>
							<Text secondary>{email}</Text>
						</div>
					) : null
				) : (
					<div>
						<Text secondary>{email}</Text>
					</div>
				)}
			</Content>
		</Wrapper>
	);
};

export default UserOptionView;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.span({
	alignItems: 'center',
	boxSizing: 'border-box',
	display: 'flex',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: 1,
	outline: 'none',
	margin: 0,
	width: '100%',
	cursor: 'pointer',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Content = styled.div({
	maxWidth: '100%',
	minWidth: 0,
	flex: '1 1 100%',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '1.4',
	paddingLeft: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Text = styled.span<{ secondary?: boolean }>(
	{
		margin: 0,
		color: token('color.text'),
		overflowX: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		props.secondary && {
			color: token('color.text.subtlest'),
			font: token('font.body'),
		},
);
