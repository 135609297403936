// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	type StoreActionApi,
	createContainer,
	createStore,
	createActionsHook,
} from '@atlassian/react-sweet-state';

type State = Record<never, never>;

type Props = {
	embedded?: boolean;
	isSharedView?: boolean;
	isCollectionView?: boolean;
};

const initialState: State = {};

const actions = {
	/**
	 * this action has no side effects. It is a helper accessor to get the container
	 * props in through the hook API and forwarded to the reselect selectors and hooks
	 */
	getContainerProps: () => (ops: StoreActionApi<State>, props: Props) => props,
};

export const PolarisEnvironmentStore = createStore({
	initialState,
	actions,
	name: 'PolarisEnvironmentStore',
});

export const PolarisEnvironmentContainer = createContainer<State, typeof actions, Props>(
	PolarisEnvironmentStore,
);

const useActions = createActionsHook(PolarisEnvironmentStore);

const useProps = (): Props => {
	const { getContainerProps } = useActions();
	return getContainerProps();
};

export const useIsEmbedded = (): boolean => {
	const { embedded } = useProps();
	return embedded === true;
};

export const useIsSharedView = (): boolean => {
	const { isSharedView } = useProps();
	return isSharedView === true;
};

export const useIsCollectionView = (): boolean => {
	const { isCollectionView } = useProps();
	return isCollectionView === true;
};
