import React from 'react';
import { styled } from '@compiled/react';
import LinkIcon from '@atlaskit/icon/core/migration/link--link-filled';
import { token } from '@atlaskit/tokens';
import { Ellipsis } from '@atlassian/jira-issue-view-common-styles/src/issue-value.tsx';
import type { Href } from '@atlassian/jira-shared-types/src/general.tsx';

type Props = {
	href: Href;
	onSelect?: (href: Href) => void;
};

export default function LinkItem(props: Props) {
	const { href, onSelect } = props;
	return (
		<PageItemContainer onClick={() => onSelect && onSelect(href)}>
			<IconContainer>
				<LinkIcon
					label=""
					spacing="spacious"
					color={token('color.text.brand')}
					LEGACY_size="medium"
				/>
			</IconContainer>
			<ContentContainer>
				<TitleWrapper>
					<Ellipsis>{href}</Ellipsis>
				</TitleWrapper>
			</ContentContainer>
		</PageItemContainer>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PageItemContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	height: '34px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconContainer = styled.div({
	marginRight: token('space.200'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleWrapper = styled.div({
	font: token('font.body'),
	fontWeight: token('font.weight.medium'),
});
