/** @jsx jsx */
import React, { forwardRef } from 'react';
import { css, jsx } from '@compiled/react';
import Heading from '@atlaskit/heading';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import type { BaseGroupProps } from '../../types.tsx';
import { GroupStyled } from '../styled.tsx';

/**
 * Base Group Component
 */
export const BaseGroup = forwardRef<HTMLDivElement, BaseGroupProps>(
	(
		{ Group = GroupStyled, Header, headerNode, children, isOpen, enableVisualRefreshForTitle },
		ref,
	) => {
		if (fg('fix-gryf-a11y-issues')) {
			return (
				<div css={headingStyles}>
					<Heading size="medium" as="h1">
						<Group
							// @ts-expect-error - TS2322: Type 'ForwardedRef<HTMLDivElement>' is not assignable to type '(instance: HTMLDivElement | null) => void)
							ref={ref}
							open={isOpen}
							enableVisualRefreshForTitle={enableVisualRefreshForTitle}
						>
							{headerNode !== undefined ? headerNode : Header !== undefined && <Header />}
							{children}
						</Group>
					</Heading>
				</div>
			);
		}
		return (
			<Group
				// @ts-expect-error - TS2322: Type 'ForwardedRef<HTMLDivElement>' is not assignable to type '(instance: HTMLDivElement | null) => void)
				ref={ref}
				open={isOpen}
				enableVisualRefreshForTitle={enableVisualRefreshForTitle}
			>
				{headerNode !== undefined ? headerNode : Header !== undefined && <Header />}
				{children}
			</Group>
		);
	},
);

const headingStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	h1: {
		all: 'unset',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'> h1 > details > summary > div > div > div > h2': {
		fontWeight: token('font.weight.medium'),
	},
});

export default BaseGroup;
