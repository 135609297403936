/* eslint-disable @atlassian/relay/unused-fields */
import React, { useCallback, type ComponentPropsWithoutRef } from 'react';
import { useFragment, graphql, useMutation, fetchQuery, useRelayEnvironment } from 'react-relay';
import type { AggGroup } from '@atlassian/jira-issue-field-multi-group-picker-editview-full/src/ui/multi-group-picker/types.tsx';
import type {
	SelectValueShape,
	ServerSuggestions,
} from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types.tsx';
import MultiSelectInlineEditView from '@atlassian/jira-issue-internal-field-select/src/multi-select-inline-edit/index.tsx';
import type { UserGroup } from '@atlassian/jira-issue-view-base/src/context/group-picker/model/types.tsx';
import messages from '@atlassian/jira-issue-view-base/src/context/group-picker/multi/messages.tsx';
import { transformFromStateValue } from '@atlassian/jira-issue-view-base/src/context/group-picker/multi/transformer.tsx';
import { transformSuggestions } from '@atlassian/jira-issue-view-base/src/context/group-picker/rest/fetch.tsx';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import { getDisplayName } from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field.tsx';
import {
	useConnectRelayField,
	type ConnectedLayoutProps,
	type PropsCallback,
} from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-relay-field.tsx';
import { ConnectedRelayFieldWrapper } from '@atlassian/jira-issue-view-common-views/src/connect-field/relay-field/field-wrapper.tsx';
import { mapNodes } from '@atlassian/jira-relay-utils/src/utils/map-nodes/index.tsx';
import type {
	ui_issueViewLayoutMultiGroupPickerField_IssueViewMultiGroupPickerField$key as ComponentsInlineEditFragment,
	ui_issueViewLayoutMultiGroupPickerField_IssueViewMultiGroupPickerField$data as ComponentsInlineEditFragmentData,
} from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutMultiGroupPickerField_IssueViewMultiGroupPickerField.graphql';
import type { ui_issueViewLayoutMultiGroupPickerField_IssueViewMultiGroupPickerField_Mutation } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutMultiGroupPickerField_IssueViewMultiGroupPickerField_Mutation.graphql';
import type { uiMultiGroupPickerFieldSearchRefetchQuery as uiMultiGroupPickerFieldSearchRefetchQueryType } from '@atlassian/jira-relay/src/__generated__/uiMultiGroupPickerFieldSearchRefetchQuery.graphql';

type ComponentValueShape = SelectValueShape[];
type AggValueShape = Pick<
	ComponentsInlineEditFragmentData,
	'selectedGroupsConnection'
>['selectedGroupsConnection'];

export type IssueViewMultiGroupPickerFieldProps =
	ConnectedLayoutProps<ComponentsInlineEditFragment>;

export const toComponentValueShape = (fieldData: AggGroup[] | null): UserGroup[] | null => {
	if (!fieldData) {
		return null;
	}

	return fieldData.map((item) => ({
		name: item.name,
		id: item.id,
		groupId: item.groupId,
	}));
};

export const transformComponentValueToAggShape = (componentValue: ComponentValueShape) => {
	return {
		selectedGroupsConnection: {
			edges:
				componentValue?.map((item) => ({
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					node: {
						id: item?.id,
						name: item?.value,
						groupId: item?.groupKey,
					} as AggGroup,
				})) ?? [],
		},
	};
};

type AdditionalProps = Pick<
	ComponentPropsWithoutRef<typeof MultiSelectInlineEditView>,
	'fetchSuggestions' | 'placeholder' | 'noValueText' | 'showPinButton' | 'validate'
>;

export function IssueViewMultiGroupPickerField(props: IssueViewMultiGroupPickerFieldProps) {
	const environment = useRelayEnvironment();
	const data = useFragment<ComponentsInlineEditFragment>(
		graphql`
			fragment ui_issueViewLayoutMultiGroupPickerField_IssueViewMultiGroupPickerField on JiraMultipleGroupPickerField {
				id
				fieldId
				type
				name
				description
				__typename

				fieldConfig {
					isEditable
					isRequired
				}
				issue {
					key
				}
				selectedGroupsConnection {
					edges {
						node {
							id
							name
							groupId
						}
					}
				}
			}
		`,
		props.fragmentKey,
	);

	const [commit] =
		useMutation<ui_issueViewLayoutMultiGroupPickerField_IssueViewMultiGroupPickerField_Mutation>(
			graphql`
				mutation ui_issueViewLayoutMultiGroupPickerField_IssueViewMultiGroupPickerField_Mutation(
					$input: JiraUpdateMultipleGroupPickerFieldInput!
				) @raw_response_type {
					jira @optIn(to: "JiraIssueFieldMutations") {
						updateMultipleGroupPickerField(input: $input) {
							success
							errors {
								message
							}
							field {
								id
								selectedGroupsConnection {
									edges {
										node {
											id
											name
											groupId
										}
									}
								}
							}
						}
					}
				}
			`,
		);

	const getComponentProps = useCallback<
		PropsCallback<
			ComponentsInlineEditFragment,
			ComponentsInlineEditFragmentData,
			ComponentValueShape,
			AggValueShape,
			AdditionalProps
		>
	>(
		({ intl, softRefreshCallbacks }) => {
			const fetchSuggestions = async (query: string): Promise<ServerSuggestions> => {
				const queryData = await fetchQuery<uiMultiGroupPickerFieldSearchRefetchQueryType>(
					environment,
					graphql`
						query uiMultiGroupPickerFieldSearchRefetchQuery(
							$id: ID!
							$searchBy: String!
							$first: Int!
							$after: String
						) {
							node(id: $id) {
								... on JiraMultipleGroupPickerField {
									groups(searchBy: $searchBy, first: $first, after: $after)
										@connection(key: "multiGroupPicker_issueFieldMultiGroupPicker_groups") {
										edges {
											node {
												id
												name
												groupId
											}
										}
									}
								}
							}
						}
					`,
					{
						id: data.id,
						searchBy: query,
						first: 50,
						after: null,
					},
				).toPromise();

				return transformSuggestions({
					groups: mapNodes(queryData?.node?.groups),
				});
			};

			return {
				jiraIssueField: data,

				value: transformFromStateValue(
					toComponentValueShape(mapNodes<AggGroup>(data.selectedGroupsConnection ?? null)),
				),

				onValueConfirm(componentValue) {
					const newAggValue = transformComponentValueToAggShape(componentValue);

					softRefreshCallbacks.onSubmit(newAggValue.selectedGroupsConnection);

					const newIds = mapNodes<AggGroup>(newAggValue.selectedGroupsConnection).map(
						(value) => value.id,
					);

					commit({
						variables: {
							input: {
								id: data.id,
								operations: [
									{
										ids: newIds,
										operation: 'SET',
									},
								],
							},
						},
						onCompleted: (mutationData) => {
							if (mutationData.jira?.updateMultipleGroupPickerField?.success) {
								softRefreshCallbacks.onSubmitSucceeded(newAggValue.selectedGroupsConnection);
							} else {
								softRefreshCallbacks.onSubmitFailed();
							}
						},
						onError() {
							softRefreshCallbacks.onSubmitFailed();
						},
						optimisticResponse: {
							jira: {
								updateMultipleGroupPickerField: {
									success: true,
									errors: [],
									field: {
										id: data.id,
										selectedGroupsConnection: {
											...newAggValue.selectedGroupsConnection,
										},
									},
								},
							},
						},
					});
				},

				additionalProps: {
					fetchSuggestions,
					placeholder: intl.formatMessage(messages.placeholder),
					noValueText: intl.formatMessage(genericMessages.noValue),
					showPinButton: getShowPinButton(props.area),
					validate: () => null,
				},
			};
		},
		[commit, data, props.area, environment],
	);

	const connectField = useConnectRelayField(props, data, getComponentProps);
	const componentName = getDisplayName(MultiSelectInlineEditView);

	return (
		<ConnectedRelayFieldWrapper componentName={componentName}>
			<MultiSelectInlineEditView {...connectField.componentProps} />
		</ConnectedRelayFieldWrapper>
	);
}
