import React from 'react';

export const atlasProjectGlyph = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13.5 3.00001C13.5 2.72386 13.2761 2.50001 13 2.50001L10.5178 2.5C10.3852 2.5 10.258 2.55268 10.1642 2.64645L7.78033 5.03034C7.63968 5.17099 7.44891 5.25001 7.25 5.25001L3.01777 5.25001C2.88516 5.25001 2.75798 5.30269 2.66421 5.39646L2.31066 5.75001L4.03033 7.46968C4.32322 7.76257 4.32322 8.23745 4.03033 8.53034L1.78033 10.7803L0.719669 9.71968L2.43934 8.00001L0.71967 6.28034C0.426777 5.98745 0.426777 5.51257 0.71967 5.21968L1.60355 4.3358C1.97863 3.96072 2.48733 3.75001 3.01777 3.75001L6.93934 3.75001L9.10355 1.58579C9.47863 1.21071 9.98734 0.999999 10.5178 1L13 1.00001C14.1046 1.00001 15 1.89544 15 3L15 5.48225C15 6.01268 14.7893 6.52139 14.4142 6.89646L12.25 9.06068L12.25 12.9823C12.25 13.5127 12.0393 14.0214 11.6642 14.3965L10.7803 15.2803C10.6397 15.421 10.4489 15.5 10.25 15.5C10.0511 15.5 9.86032 15.421 9.71967 15.2803L8 13.5607L6.28033 15.2803L5.21967 14.2197L7.46967 11.9697C7.61032 11.829 7.80109 11.75 8 11.75C8.19891 11.75 8.38968 11.829 8.53033 11.9697L10.25 13.6894L10.6036 13.3358C10.6973 13.242 10.75 13.1149 10.75 12.9823L10.75 8.75002C10.75 8.5511 10.829 8.36034 10.9697 8.21969L13.3536 5.8358C13.4473 5.74204 13.5 5.61486 13.5 5.48225L13.5 3.00001ZM6.78033 10.2803L1.78033 15.2803L0.71967 14.2197L5.71967 9.21968L6.78033 10.2803Z"
			fill="currentColor"
		/>
		<path
			d="M12.5 4.62501C12.5 5.24633 11.9963 5.75001 11.375 5.75001C10.7537 5.75001 10.25 5.24633 10.25 4.62501C10.25 4.00369 10.7537 3.50001 11.375 3.50001C11.9963 3.50001 12.5 4.00369 12.5 4.62501Z"
			fill="currentColor"
		/>
	</svg>
);

export const atlasProjectStatusGlyph = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.5 2.625C3.5 1.17525 4.67525 0 6.125 0H9.875C11.3247 0 12.5 1.17525 12.5 2.625V11.375C12.5 12.8247 11.3247 14 9.875 14H8.75V16H7.25L7.25 14H6.125C4.67525 14 3.5 12.8247 3.5 11.375V2.625ZM6.125 1.5C5.50368 1.5 5 2.00368 5 2.625V11.375C5 11.9963 5.50368 12.5 6.125 12.5H9.875C10.4963 12.5 11 11.9963 11 11.375V2.625C11 2.00368 10.4963 1.5 9.875 1.5H6.125ZM6.5 4.75C6.5 3.92157 7.17157 3.25 8 3.25C8.82843 3.25 9.5 3.92157 9.5 4.75C9.5 5.57843 8.82843 6.25 8 6.25C7.17157 6.25 6.5 5.57843 6.5 4.75ZM6.5 9.25C6.5 8.42157 7.17157 7.75 8 7.75C8.82843 7.75 9.5 8.42157 9.5 9.25C9.5 10.0784 8.82843 10.75 8 10.75C7.17157 10.75 6.5 10.0784 6.5 9.25Z"
			fill="currentColor"
		/>
	</svg>
);

export const atlasGoalGlyph = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8ZM8 5.5C6.61929 5.5 5.5 6.61929 5.5 8C5.5 9.38071 6.61929 10.5 8 10.5C9.38071 10.5 10.5 9.38071 10.5 8C10.5 6.61929 9.38071 5.5 8 5.5ZM4 8C4 5.79086 5.79086 4 8 4C10.2091 4 12 5.79086 12 8C12 10.2091 10.2091 12 8 12C5.79086 12 4 10.2091 4 8Z"
			fill="currentColor"
		/>
	</svg>
);
