import { token } from '@atlaskit/tokens';

export const STATUS_THEME_DEFAULT = 'default';
export const STATUS_THEME_SUCCESS = 'success';
export const STATUS_THEME_IN_PROGRESS = 'inprogress';

export const statusCategoryToThemeMap: { [key: string]: string } = {
	'1': STATUS_THEME_DEFAULT,
	'2': STATUS_THEME_DEFAULT,
	'3': STATUS_THEME_SUCCESS,
	'4': STATUS_THEME_IN_PROGRESS,
};

export const bgColor: { [key: string]: string } = {
	[STATUS_THEME_DEFAULT]: token('color.background.neutral'),
	[STATUS_THEME_SUCCESS]: token('color.background.success'),
	[STATUS_THEME_IN_PROGRESS]: token('color.background.information'),
};

export const textColor: { [key: string]: string } = {
	[STATUS_THEME_DEFAULT]: token('color.text.subtle'),
	[STATUS_THEME_SUCCESS]: token('color.text.success'),
	[STATUS_THEME_IN_PROGRESS]: token('color.text.information'),
};

export const textColorOld: { [key: string]: string } = {
	[STATUS_THEME_DEFAULT]: token('color.text'),
	[STATUS_THEME_SUCCESS]: token('color.text.success'),
	[STATUS_THEME_IN_PROGRESS]: token('color.text.information'),
};

export const bgHoverColor: { [key: string]: string } = {
	[STATUS_THEME_DEFAULT]: token('color.background.neutral.hovered'),
	[STATUS_THEME_SUCCESS]: token('color.background.success.hovered'),
	[STATUS_THEME_IN_PROGRESS]: token('color.background.information.hovered'),
};

export const textHoverColor: { [key: string]: string } = {
	[STATUS_THEME_DEFAULT]: token('color.text'),
	[STATUS_THEME_SUCCESS]: token('color.text.success'),
	[STATUS_THEME_IN_PROGRESS]: token('color.text.information'),
};

export const disabledColor: { [key: string]: string } = {
	bg: token('color.background.neutral'),
	text: token('color.text.disabled'),
};
