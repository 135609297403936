import type { ViewUUID } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { UserData } from '../../../../domain/types.tsx';
import { getUsersInBulk } from '../../../../services/jira/bulk-get-users/index.tsx';
import type { State } from '../../types.tsx';

export const fetchUserData =
	(viewUUID: ViewUUID, accountIds: AccountId[]) => (api: StoreActionApi<State>) => {
		const { setState, getState } = api;

		const userDataInState: Record<AccountId, UserData> | undefined = getState().users[viewUUID];
		const usersToFetch = accountIds.filter((accountId) => {
			const isAlreadyHandled =
				userDataInState !== undefined &&
				userDataInState[accountId] !== undefined &&
				(userDataInState[accountId].data !== undefined ||
					userDataInState[accountId].error !== undefined ||
					userDataInState[accountId].loading);
			return !isAlreadyHandled;
		});

		const loadingStates: Record<AccountId, UserData> = {};
		usersToFetch.forEach((accountId) => {
			loadingStates[accountId] = {
				loading: true,
			};
		});

		setState({
			users: {
				...getState().users,
				[viewUUID]: {
					...(getState().users[viewUUID] || {}),
					...loadingStates,
				},
			},
		});

		getUsersInBulk(usersToFetch)
			.then((users) => {
				const userMap: Record<AccountId, UserData> = {};
				users.forEach((user) => {
					if (user === null) {
						return;
						// early exit if user is not found
					}
					userMap[user.accountId] = {
						data: user,
						loading: false,
					};
				});

				setState({
					users: {
						...getState().users,
						[viewUUID]: {
							...(getState().users[viewUUID] || {}),
							...userMap,
						},
					},
				});
			})
			.catch((error) => {
				const userMap: Record<AccountId, UserData> = {};
				usersToFetch.forEach((accountId) => {
					userMap[accountId] = {
						error,
						loading: false,
					};
				});

				setState({
					users: {
						...getState().users,
						[viewUUID]: {
							...(getState().users[viewUUID] || {}),
							...userMap,
						},
					},
				});
			});
	};
