import React, { useEffect, type FC, useState, useCallback } from 'react';
import { graphql, useFragment, useMutation } from 'react-relay';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import { Flex, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import DataClassification, { type Color } from '@atlassian/data-classification-level';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useFlagsService, toFlagId } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useOptionallyControlledEditingState } from '@atlassian/jira-issue-field-optional-editing-state-manager/src/index.tsx';
import { DATA_CLASSIFICATION_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import {
	fireTrackAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import {
	useProjectType,
	useProjectKey,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import type { ui_issueClassificationBadge_IssueClassificationBadge$key as ClassificationLevelFieldFragmentType } from '@atlassian/jira-relay/src/__generated__/ui_issueClassificationBadge_IssueClassificationBadge.graphql';
import { ClassifyButton } from './classify-button/index.tsx';
import { ClassificationEditViewPopup } from './edit-view-popup/index.tsx';
import type { SetShowUpdateSpinnerType } from './edit-view-popup/types.tsx';
import { messages } from './messages.tsx';
import { ClassificationReadViewPopup } from './read-view-popup/index.tsx';
import type { IssueClassificationBadgeProps, ClassificationSelectOption } from './types.tsx';

export const DATA_CLASSIFICATION_UPDATE_SUCCESS_FLAG_ID = toFlagId(
	'data-classification-update-sucess-flag',
);
export const DATA_CLASSIFICATION_UPDATE_FAILURE_FLAG_ID = toFlagId(
	'data-classification-update-failure-flag',
);
export const DATA_CLASSIFICATION_CANNOT_CLASSIFY_ISSUE_FLAG_ID = toFlagId(
	'data-classification-cannot-classify-issue-flag',
);

const ErrorFetchingClassification = () => {
	const { formatMessage } = useIntl();
	return (
		<Flex alignItems="center">
			<ErrorIcon
				LEGACY_size="medium"
				spacing="spacious"
				label=""
				color={token('color.icon.danger')}
			/>
			<Text as="span">{formatMessage(messages.couldNotLoadClassificationLevel)}</Text>
		</Flex>
	);
};

/**
 * General purpose DataClassification component with Relay data wired in.
 */
export const IssueClassificationBadge: FC<IssueClassificationBadgeProps> = ({
	dataClassificationFragment,
	popupZIndex,
}: IssueClassificationBadgeProps) => {
	let getPopupState: (() => boolean) | undefined;
	let setPopupState: ((state: boolean) => void) | undefined;
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagsService();
	const [readView, setReadView] = useState(true);
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);

	/**
	 * Todo: Replace the constant `DATA_CLASSIFICATION_EDITING_STATE_KEY` with the destructured value from relay response
	 * You can refer the file jira/src/packages/issue/fields/platform/date/inline-edit-full/src/ui/date/index.tsx
	 * for the implementation of the same. There `uniqueFieldId` is destructured from the relay response.
	 */
	const [isIssueBeingClassified, setIsIssueBeingClassified] = useOptionallyControlledEditingState(
		false,
		DATA_CLASSIFICATION_TYPE,
	);

	const data = useFragment<ClassificationLevelFieldFragmentType>(
		graphql`
			fragment ui_issueClassificationBadge_IssueClassificationBadge on JiraDataClassificationField {
				...editViewPopup_issueClassificationBadge_ClassificationEditViewPopup
				id
				fieldConfig {
					isEditable
				}
				classificationLevel {
					name
					guidelines
					color {
						colorKey
					}
				}
			}
		`,
		dataClassificationFragment,
	);

	const [commit] = useMutation(graphql`
		mutation ui_updateDataClassificationFieldMutation(
			$input: JiraUpdateDataClassificationFieldInput!
		) @raw_response_type {
			jira {
				updateDataClassificationField(input: $input) @optIn(to: "JiraIssueFieldMutations") {
					success
					errors {
						message
					}
					field {
						id
						fieldId
						name
						type
						classificationLevelSource
						classificationLevel {
							id
							name
							color {
								id
								colorKey
							}
							guideline: guidelines
							definition
						}
					}
				}
			}
		}
	`);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const displayClassificationUpdateFlag = useCallback(
		(success: boolean, classificationLevel: ClassificationSelectOption | null = null) => {
			if (success) {
				fireTrackAnalytics(createAnalyticsEvent({}), 'issueClassificationSave updatedSuccess');

				if (!classificationLevel) {
					showFlag({
						messageId: 'issue-classification-badge.ui.show-flag.success.no-classification-name',
						messageType: 'transactional',
						id: DATA_CLASSIFICATION_UPDATE_SUCCESS_FLAG_ID,
						title: formatMessage(messages.noClassficationName),
						description: formatMessage(messages.noClassficationDescription, {
							issueKey,
						}),
						type: 'success',
						isAutoDismiss: true,
						testId: 'issue-classification-badge.ui.data-classification-success-flag-dismiss',
					});
				} else {
					showFlag({
						messageId: 'issue-classification-badge.ui.show-flag.success',
						messageType: 'transactional',
						id: DATA_CLASSIFICATION_UPDATE_SUCCESS_FLAG_ID,
						title: formatMessage(messages.sucessFlagTitle),
						description: formatMessage(messages.successFlagDescription, {
							name: classificationLevel?.name,
							issueKey,
						}),
						type: 'success',
						isAutoDismiss: true,
						testId: 'issue-classification-badge.ui.data-classification-success-flag-dismiss',
					});
				}
			} else {
				fireTrackAnalytics(createAnalyticsEvent({}), 'issueClassificationSave updatedFailed');

				showFlag({
					messageId: 'issue-classification-badge.ui.show-flag.error',
					messageType: 'transactional',
					id: DATA_CLASSIFICATION_UPDATE_FAILURE_FLAG_ID,
					title: formatMessage(messages.errorFlagTitle),
					description: formatMessage(messages.errorFlagDescription),
					type: 'error',
					isAutoDismiss: true,
					testId: 'issue-classification-badge.ui.data-classification-failure-flag-dismiss',
				});
			}
		},
		[createAnalyticsEvent, formatMessage, issueKey, showFlag],
	);

	const onCommitCompleted = useCallback(
		// @ts-expect-error: TS7006 Parameter 'response' implicitly has an 'any' type.
		(response) => {
			if (!response.jira?.updateDataClassificationField) {
				displayClassificationUpdateFlag(false);
				return;
			}

			const { success, field } = response.jira.updateDataClassificationField;
			const { classificationLevel } = field || {};

			if (!success) {
				displayClassificationUpdateFlag(false);
				return;
			}

			displayClassificationUpdateFlag(true, classificationLevel);
			setIsIssueBeingClassified(false);
		},
		[displayClassificationUpdateFlag, setIsIssueBeingClassified],
	);

	/* TODO in https://hello.jira.atlassian.cloud/browse/KEN-112:
	 *  - add optimistic response
	 *  - Update content in Error Flag
	 *  - Add VR and unit tests to edit and read view popup
	 * */
	const handleSubmit = useCallback(
		(
			classificationDirty: ClassificationSelectOption,
			closePopup: () => void,
			setShowUpdateSpinner: SetShowUpdateSpinnerType,
		) => {
			commit({
				variables: {
					input: {
						id: data?.id,
						operation: {
							operation: 'SET',
							classificationLevel: classificationDirty.id,
						},
					},
				},
				onCompleted(response) {
					onCommitCompleted(response);
					setShowUpdateSpinner(false);
					setReadView(true);
					closePopup();
				},
				onError() {
					displayClassificationUpdateFlag(false);
					setShowUpdateSpinner(false);
					setReadView(true);
					closePopup();
				},
			});
		},
		[commit, displayClassificationUpdateFlag, data?.id, onCommitCompleted],
	);

	useEffect(() => {
		const action = 'viewed';
		const actionSubject = 'classificationField';

		fireTrackAnalytics(createAnalyticsEvent({}), `${actionSubject} ${action}`);
	}, [createAnalyticsEvent]);

	const { classificationLevel } = data;

	useEffect(() => {
		if (
			isIssueBeingClassified &&
			classificationLevel &&
			Object.keys(classificationLevel).length === 0
		) {
			/**
			 * The above condition is used to handle the scenario when `classificationLevel`
			 * is an empty object and user has clicked on Classify issue option. In such case
			 * we need to show a flag to inform user that issue cannot be classified.
			 */
			showFlag({
				messageId: 'issue-classification-badge.ui.show-flag.warning.cannot-classify-issue',
				messageType: 'transactional',
				id: DATA_CLASSIFICATION_CANNOT_CLASSIFY_ISSUE_FLAG_ID,
				title: formatMessage(messages.cannotClassifyIssueTitle),
				description: formatMessage(messages.cannotClassifyIssueDescription),
				type: 'warning',
				isAutoDismiss: true,
				actions: [
					{
						content: messages.refresh,

						// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
						onClick: () => window.location.reload(),
					},
				],
			});
			setIsIssueBeingClassified(false);
		} else if (isIssueBeingClassified) {
			/**
			 * Setting the popup state to true always because this is the case when
			 * user clicks on Classify issue and there will not be any toggle scenario.
			 * Also, if because of any of the dependencies if this code runs twice
			 * then popup will again close because of the old toggling condition.
			 */
			if (getPopupState && setPopupState) {
				setPopupState(true);
			}
			setReadView(false);
		} else if (setPopupState) {
			setPopupState(false);
		}
	}, [
		isIssueBeingClassified,
		classificationLevel,
		showFlag,
		formatMessage,
		getPopupState,
		setPopupState,
		setIsIssueBeingClassified,
	]);

	const { color, name, guidelines } = classificationLevel || {};

	const { isEditable } = data?.fieldConfig || {};

	const renderPopupComponent = useCallback(
		(closePopup: () => void) => (
			<ErrorBoundary
				id="popup-error-boundary"
				packageName="atlassian/jira-issue-classification-badge"
			>
				{readView ? (
					<ClassificationReadViewPopup
						guideline={guidelines || undefined}
						name={name || undefined}
						onChangeLevelClick={
							isEditable
								? () => {
										setReadView(false);
										fireUIAnalytics(
											createAnalyticsEvent({}),
											'classificationReadViewPopupOnChangeButton clicked',
										);
									}
								: undefined
						}
					/>
				) : (
					<ClassificationEditViewPopup
						customHeading={
							projectType === SERVICE_DESK_PROJECT
								? formatMessage(messages.classifyRequest)
								: formatMessage(messages.classifyIssue)
						}
						onEditCancel={() => {
							setReadView(true);
							fireUIAnalytics(
								createAnalyticsEvent({}),
								'classificationEditViewPopupCancelButton clicked',
							);
							/**
							 * This is the case when issue currently does NOT have classfication.
							 * Then user clicks on Classify issue option in the meatball menu.
							 * So EditView popup opens and then user clicks on cancel button.
							 * In that case the popup should be closed instead of showing the readview popup.
							 */

							if (isIssueBeingClassified && !classificationLevel) {
								closePopup();
								setIsIssueBeingClassified(false);
							}
						}}
						// @ts-expect-error ClassificationSelectOption argument types do not match but this will be fixed when we can import the types from @atlassian/data-classification-level after https://stash.atlassian.com/projects/ATLASSIAN/repos/atlassian-frontend-monorepo/pull-requests/74374/overview
						onEditSubmit={(
							classificationDirty: ClassificationSelectOption,
							setShowUpdateSpinner: SetShowUpdateSpinnerType,
						) => {
							fireUIAnalytics(
								createAnalyticsEvent({}),
								'classificationEditViewPopupUpdateButton clicked',
							);
							handleSubmit(classificationDirty, closePopup, setShowUpdateSpinner);
						}}
						editViewPopupFragment={data}
					/>
				)}
			</ErrorBoundary>
		),
		[
			readView,
			guidelines,
			name,
			isEditable,
			projectType,
			formatMessage,
			data,
			createAnalyticsEvent,
			isIssueBeingClassified,
			classificationLevel,
			setIsIssueBeingClassified,
			handleSubmit,
		],
	);

	/**
	 * This is for removing the data classification badge when user selects
	 * No classification and user has not clicked on Classify issue option.
	 */
	if (!isIssueBeingClassified && !classificationLevel) {
		return <></>;
	}

	/**
	 * If classificationLevel is an empty object then it's an error scenario.
	 */
	if (classificationLevel && Object.keys(classificationLevel).length === 0) {
		fireErrorAnalytics({
			meta: {
				id: 'classificationLevelEmptyObjectError',
				packageName: 'jiraIssueClassificationBadge',
			},
			error: new Error('classificationLevel is an empty object'),
		});
		return <ErrorFetchingClassification />;
	}

	/**
	 * Modify the props based on whether meatball menu was clicked and issue is being explicitly classified(isIssueBeingClassified).
	 * @returns {Object} currentName, currentColor, currentTestId, currentTooltip
	 */
	const getCurrentProps = (): {
		currentName: string | undefined;
		currentColor: Color;
		currentTestId: string;
		currentTooltip: string | undefined;
	} => {
		let currentName = name || undefined;
		// TODO: update the return type of color in the fragment
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		let currentColor = color?.colorKey as Color;
		let currentTestId = 'issue-classification-badge.ui.data-classification';
		let currentTooltip: string | undefined = formatMessage(messages.classificationLevel, {
			name,
		});

		// If there's no existing classification and issue is being classified from meatball menu
		if (isIssueBeingClassified && !classificationLevel) {
			currentName =
				projectType === SERVICE_DESK_PROJECT
					? formatMessage(messages.classifyRequest)
					: formatMessage(messages.classifyIssue);
			currentColor = 'BLUE';
			currentTestId = 'issue-classification-badge.ui.no-classification';
			currentTooltip = undefined;
		}

		return {
			currentName,
			currentColor,
			currentTestId,
			currentTooltip,
		};
	};
	const { currentName, currentColor, currentTestId, currentTooltip } = getCurrentProps();

	/**
	 * This is the initial Classify issue button which is rendered
	 * when user clicks on Classify issue in the meat ball menu
	 */
	if (isIssueBeingClassified && !classificationLevel) {
		return <ClassifyButton currentName={currentName} renderPopupComponent={renderPopupComponent} />;
	}

	return (
		<DataClassification
			testId={currentTestId}
			name={currentName}
			color={currentColor}
			guideline={guidelines || undefined}
			popupZIndex={popupZIndex}
			tooltip={currentTooltip}
			onClick={() => fireTrackAnalytics(createAnalyticsEvent({}), 'classificationField clicked')}
			onPopupShown={() =>
				fireTrackAnalytics(createAnalyticsEvent({}), 'classificationReadViewPopup viewed')
			}
			onPopupClose={() => {
				setReadView(true);
				setIsIssueBeingClassified(false);
			}}
			renderPopupComponent={renderPopupComponent}
			getPopupStateControllers={(isPopupOpen, setPopupOpen) => {
				getPopupState = isPopupOpen;
				setPopupState = setPopupOpen;
			}}
		/>
	);
};
