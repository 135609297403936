import React from 'react';
import FlagFilledIcon from '@atlaskit/icon/core/flag-filled';
import FlagFilledIconOld from '@atlaskit/icon/glyph/flag-filled';
import FlagIcon from '@atlaskit/icon/core/flag';
import FlagIconOld from '@atlaskit/icon/glyph/emoji/flags';
import { token } from '@atlaskit/tokens';

import { IconButton, type IconButtonProps } from '@atlaskit/button/new';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';
import { useFlaggedFieldPopupStore } from './state.tsx';

const tooltipOptions: IconButtonProps['tooltip'] = {
	position: 'top',
	hideTooltipOnClick: true,
};

type Props = {
	isIssueFlagged: boolean;
};

const FlaggedFieldPopup = ({ isIssueFlagged }: Props) => {
	const { formatMessage } = useIntl();
	const [{ isFlaggedFieldPopupOpen }, { openFlaggedFieldPopup, closeFlaggedFieldPopup }] =
		useFlaggedFieldPopupStore();

	return (
		<Box xcss={flagFieldWrapperStyles}>
			<Popup
				isOpen={isFlaggedFieldPopupOpen}
				onClose={closeFlaggedFieldPopup}
				placement="bottom-end"
				content={(_contentProps) => (
					<Box testId="issue-view-foundation.status.flagged-field-popup.content">
						{formatMessage(messages.flagIconLabel)}
					</Box>
				)}
				trigger={(triggerProps: TriggerProps) => (
					<IconButton
						{...triggerProps}
						isSelected={isFlaggedFieldPopupOpen}
						onClick={isFlaggedFieldPopupOpen ? closeFlaggedFieldPopup : openFlaggedFieldPopup}
						icon={(iconProps) =>
							isIssueFlagged ? (
								<FlagFilledIcon
									{...iconProps}
									spacing="spacious"
									LEGACY_fallbackIcon={FlagFilledIconOld}
									LEGACY_size="medium"
									LEGACY_primaryColor={token('color.icon.danger')}
									color={token('color.icon.danger')}
									testId="issue-view-foundation.status.flagged-field-popup.flag-icon-solid"
								/>
							) : (
								<FlagIcon
									{...iconProps}
									spacing="spacious"
									LEGACY_fallbackIcon={FlagIconOld}
									LEGACY_size="medium"
									LEGACY_primaryColor={token('color.icon.danger')}
									color={token('color.icon.danger')}
									testId="issue-view-foundation.status.flagged-field-popup.flag-icon-outline"
								/>
							)
						}
						isTooltipDisabled={false}
						tooltip={tooltipOptions}
						label={formatMessage(messages.flagIconLabel)}
						interactionName="issue.views.issue-base.foundation.status.flagged-field-wrapper.button"
						testId="issue-view-foundation.status.flagged-field-popup.trigger"
					/>
				)}
			/>
		</Box>
	);
};

const flagFieldWrapperStyles = xcss({
	display: 'inline-flex',
	maxWidth: '100%',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});

export default FlaggedFieldPopup;
