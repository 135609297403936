import React, { useState, useMemo, useCallback } from 'react';

import { Box, xcss } from '@atlaskit/primitives';
import { FireScreenAnalytics } from '@atlassian/jira-product-analytics-bridge';

import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import type { MappedTypeResponseWithHeader, DismissData } from '../../../common/types.tsx';

import { ruleViewerEntryPoint } from '../rule-viewer/entrypoint.tsx';
import { ALERT_MAP, ISSUE_MAP } from './constants.tsx';

import { AutomationDiscoveryPanel } from './panel/index.tsx';
import type { PanelContainerProps } from './types.tsx';

export const getPanelVisibility = (templateId: string, data?: DismissData): boolean => {
	if (data !== undefined) {
		if (data === null) {
			return true; // No property set so should be visible
		}
		if (
			data.some(
				(preference) =>
					preference.templateId === templateId &&
					new Date(preference.dismissUntilDateTime) > new Date(),
			)
		) {
			return false; // Property set but in future so shouldn't be visible
		}
		return true;
	}
	return false; // Data not yet loaded from user api so hide until visible
};

export const PanelController = ({
	onRedirectToRuleBuilder,
	isRuleExpanded,
	onClose,
	onExpandRule,
	isUserAuthorizedToCreateRule,
	templateId,
	type,
	getAutomationTemplateLink,
	dismissData,
	setDismissData,
}: PanelContainerProps) => {
	const data: MappedTypeResponseWithHeader = useMemo(() => {
		if (type === 'issue') {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return ISSUE_MAP[templateId as keyof typeof ISSUE_MAP];
		}
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return ALERT_MAP[templateId as keyof typeof ALERT_MAP];
	}, [templateId, type]);

	const [isAnimationFinished, setIsAnimationFinished] = useState(false);

	const { info, id } = data;
	const idUsedForRedirection = id || templateId;

	const onEditRule = useCallback(() => {
		onRedirectToRuleBuilder(idUsedForRedirection);
	}, [onRedirectToRuleBuilder, idUsedForRedirection]);

	const onAnimationFinish = useCallback(() => {
		setIsAnimationFinished(true);
	}, []);

	const runTimeProps = useMemo(
		() => ({
			onEditRule,
			isRuleExpanded,
			isUserAuthorizedToCreateRule,
			templateId,
			getAutomationTemplateLink,
			onAnimationFinish,
			type,
			header: info.header,
		}),
		[
			onEditRule,
			isRuleExpanded,
			isUserAuthorizedToCreateRule,
			templateId,
			getAutomationTemplateLink,
			onAnimationFinish,
			type,
			info.header,
		],
	);

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(ruleViewerEntryPoint, {});

	const triggerRef = useEntryPointButtonTrigger(entryPointActions);

	const onClosePanel = () => {
		onClose();
		setIsAnimationFinished(false);
	};

	const isAutomationPanelVisible = useMemo(
		() => getPanelVisibility(templateId, dismissData),
		[templateId, dismissData],
	);

	if (isAutomationPanelVisible) {
		return (
			<Box
				testId="automation-discoverability.ui.automation-discovery.panel-controller.automation-discovery-container"
				xcss={containerStyles}
				data-vc={`automation-discoverability-banner-${type}`}
			>
				<Box testId="automation-discoverability.ui.automation-discovery.panel-controller.automation-discovery-panel-container">
					<FireScreenAnalytics />
					<AutomationDiscoveryPanel
						templateId={templateId}
						onClose={onClosePanel}
						onExpandRule={onExpandRule}
						isRuleExpanded={isRuleExpanded}
						isAnimationFinished={isAnimationFinished}
						header={info.header}
						isUserAuthorizedToCreateRule={isUserAuthorizedToCreateRule}
						triggerRef={triggerRef}
						dismissData={dismissData}
						setDismissData={setDismissData}
					/>
					<JiraEntryPointContainer
						entryPointReferenceSubject={entryPointReferenceSubject}
						fallback={<Box />}
						id="automation-discoverability-rule-viewer"
						packageName="@atlassian/jira-automation-discoverability"
						runtimeProps={runTimeProps}
					/>
				</Box>
			</Box>
		);
	}
	return null;
};

const containerStyles = xcss({
	borderColor: 'color.border',
	borderWidth: 'border.width',
	borderStyle: 'solid',
	borderRadius: 'border.radius.100',
});
