import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { ConfluenceAccess } from '../session-storage/index.tsx';

export const fetchConfluenceUserPermissions = async (
	cloudId: string,
): Promise<ConfluenceAccess> => {
	const response = fetchJson('/gateway/api/permissions/permitted', {
		method: 'POST',
		body: JSON.stringify({
			resourceId: `ari:cloud:confluence::site/${cloudId}`,
			permissionId: 'write',
		}),
		headers: {
			Accept: 'application/json',
		},
	});
	return response;
};
