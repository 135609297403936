import React, { type FC, useRef, useState, useCallback, useEffect } from 'react';
import AtlassianIntelligenceIcon from '@atlaskit/icon/core/atlassian-intelligence';
import InfoIcon from '@atlaskit/icon/core/migration/information--info';
import Lozenge from '@atlaskit/lozenge';
import { xcss, Inline, type Space, Flex } from '@atlaskit/primitives';
import Anchor from '@atlaskit/primitives/anchor';
import { token } from '@atlaskit/tokens';
import VisuallyHidden from '@atlaskit/visually-hidden';
import { useIntl, type MessageDescriptor } from '@atlassian/jira-intl';
import { useResizeObserver } from '@atlassian/jira-react-use-resize-observer/src/index.tsx';
import messages from './messages.tsx';

const ATLASSIAN_INTELLIGENCE_LINK =
	'https://support.atlassian.com/organization-administration/docs/understand-atlassian-intelligence-features-in-products/';

export type Props = {
	testId?: string;
	attribution?: string;
	disclaimer?: string;
	footerSpace?: Space;
	isBeta?: boolean;
	showDisclaimer?: boolean;
	showAttributionLink?: boolean;
};

const AIFooterThreshold = {
	SMALL: 280,
	MEDIUM: 328,
	LARGE: 624,
};

export const AiFooter: FC<Props> = ({ testId, isBeta = true, showDisclaimer = true }) => {
	const { formatMessage } = useIntl();
	const footerContainer = useRef(null);
	const onResize = useCallback(() => {
		if (footerContainer.current) {
			const { offsetWidth } = footerContainer.current;
			if (showDisclaimer) {
				if (offsetWidth > AIFooterThreshold.LARGE) {
					setDisplayedAttribution(messages.longAttributionText);
					setDisplayedDisclaimer(messages.longDisclaimerText);
				} else if (offsetWidth > AIFooterThreshold.MEDIUM) {
					setDisplayedAttribution(messages.shortAttributionText);
					setDisplayedDisclaimer(messages.disclaimerText);
				} else {
					setDisplayedAttribution(null);
					setDisplayedDisclaimer(messages.disclaimerText);
				}
			} else if (offsetWidth > AIFooterThreshold.SMALL) {
				setDisplayedAttribution(messages.longAttributionText);
			} else {
				setDisplayedAttribution(messages.shortAttributionText);
			}
		}
	}, [showDisclaimer]);

	const [displayedAttribution, setDisplayedAttribution] = useState<MessageDescriptor | null>(null);
	const [displayedDisclaimer, setDisplayedDisclaimer] = useState<MessageDescriptor | null>(null);

	useEffect(() => {
		onResize();
	}, [onResize]);

	useResizeObserver({
		ref: footerContainer,
		onResize,
	});

	return (
		<Inline
			testId={testId}
			xcss={footerStyle}
			spread="space-between"
			alignBlock="center"
			ref={footerContainer}
		>
			<Flex alignItems="center" gap="space.050">
				{isBeta && (
					<Lozenge
						testId="atlassian-intelligence.common.ui.ai-footer.beta-lozenge"
						appearance="default"
					>
						{formatMessage(messages.betaLozenge)}
					</Lozenge>
				)}
				{displayedDisclaimer && (
					<>
						<InfoIcon
							label={formatMessage(messages.infoIconLabel)}
							LEGACY_size="small"
							color={token('color.icon.subtle')}
							testId="atlassian-intelligence.common.ui.ai-footer.info-icon"
						/>
						<Anchor
							xcss={linkStyle}
							href="https://www.atlassian.com/trust/atlassian-intelligence"
							rel="noopener"
							target="_blank"
							testId="atlassian-intelligence.common.ui.ai-footer.disclaimer-text"
						>
							{formatMessage(displayedDisclaimer)}
						</Anchor>
					</>
				)}
			</Flex>
			<Flex alignItems="center" gap="space.050">
				<Anchor
					xcss={linkStyle}
					href={ATLASSIAN_INTELLIGENCE_LINK}
					rel="noopener"
					target="_blank"
					testId="atlassian-intelligence.common.ui.ai-footer.attribution-link"
				>
					{displayedAttribution ? (
						formatMessage(displayedAttribution)
					) : (
						<VisuallyHidden>{formatMessage(messages.longAttributionText)}</VisuallyHidden>
					)}
				</Anchor>
				<AtlassianIntelligenceIcon label={formatMessage(messages.aiIconLabel)} spacing="spacious" />
			</Flex>
		</Inline>
	);
};

const footerStyle = xcss({
	borderTop: `1px solid ${token('color.border')}`,
	paddingInline: 'space.150',
	paddingBlock: 'space.050',
});

const linkStyle = xcss({
	textDecoration: 'none',
	color: 'color.text.subtlest',
	font: token('font.body.small'),
});
