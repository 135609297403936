export const downloadText = (text: string, filename: string, mimeType: string) => {
	const blob = new Blob([text], { type: mimeType });
	downloadBlob(blob, filename);
};

export const downloadBlob = (blob: Blob, filename: string) => {
	const url = URL.createObjectURL(blob);

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const link = document.createElement('a');
	link.setAttribute('href', url);
	link.setAttribute('download', filename);
	link.style.visibility = 'hidden';

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	document.body.appendChild(link);
	link.click();

	setTimeout(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.body.removeChild(link);
		URL.revokeObjectURL(url);
	}, 30000);
};
