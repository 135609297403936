import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	automationIcon: {
		id: 'automation-discovery.automation-icon',
		defaultMessage: 'Automation Icon',
		description: 'Automation Icon label',
	},
	buttonGroupLabel: {
		id: 'automation-discovery.button-group-label',
		defaultMessage: 'Button Groups label ',
		description: 'Button Groups containing',
	},
	buildThisRule: {
		id: 'automation-discovery.build-this-rule',
		defaultMessage: 'Create rule',
		description: 'Button label having make this rule',
	},
	viewThisRule: {
		id: 'automation-discovery.create-this-rule',
		defaultMessage: 'View rule',
		description: 'Button label having view this rule',
	},
	turnOnRule: {
		id: 'automation-discovery.turn-on-rule',
		defaultMessage: 'Turn on rule',
		description: 'Button label having turn on rule',
	},

	chevronUp: {
		id: 'automation-discovery.chevron-up',
		defaultMessage: 'Close the panel',
		description: 'close panel',
	},
	new: {
		id: 'automation-discovery.new',
		defaultMessage: 'New',
		description: 'New panel',
	},
	dismiss: {
		id: 'automation-discovery.dismiss',
		defaultMessage: 'Dismiss',
		description: 'Dismiss Button',
	},
});
