import React from 'react';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useStableIssueId } from '../../controllers/use-stable-issue-id/index.tsx';
import { AIContextServicePanelWithPrefetchedResource } from './ai-context-service-panel-with-prefetched-resource/index.tsx';
import { AIContextServicePanelWithPrefetchedCommonResource } from './ai-context-service-panel-with-prefetched-common-resource/index.tsx';

export const AIContextServicePanelLoader = () => {
	const issueId = useStableIssueId();
	const issueKey = useIssueKey();

	if (!issueId) {
		return null;
	}

	return fg('rrr_for_context_panel_data') ? (
		<AIContextServicePanelWithPrefetchedCommonResource issueId={issueId} issueKey={issueKey} />
	) : (
		<AIContextServicePanelWithPrefetchedResource issueId={issueId} />
	);
};
