import React, { type SyntheticEvent } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import Button from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/utility/migration/chevron-up';
import { fireUIAnalytics, type UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';

type Props = {
	fieldName: string;
	isOpen: boolean;
	onToggle: () => void;
	expandAriaLabel: string;
	collapseAriaLabel: string;
};

export const ExpandButton = ({
	fieldName,
	isOpen,
	onToggle,
	expandAriaLabel,
	collapseAriaLabel,
}: Props) => {
	const controlContentId = `${fieldName}-custom-field-expand-panel`;
	const openedOrClosed = isOpen ? 'closed' : 'opened';

	return fg('jcs-issue-view-truncate-reporter') ? (
		<Box xcss={buttonStyles}>
			<IconButton
				testId={controlContentId}
				onClick={(e: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
					fireUIAnalytics(analyticsEvent, 'issueViewCustomerContextFieldExpand', {
						context: fieldName,
						openedOrClosed,
					});
					onToggle();
				}}
				appearance="subtle"
				aria-expanded={isOpen}
				aria-controls={controlContentId}
				label={isOpen ? collapseAriaLabel : expandAriaLabel}
				icon={isOpen ? ChevronUpIcon : ChevronDownIcon}
			/>
		</Box>
	) : (
		<Button
			testId={controlContentId}
			onClick={(e: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
				fireUIAnalytics(analyticsEvent, 'issueViewCustomerContextFieldExpand', {
					context: fieldName,
					openedOrClosed,
				});
				onToggle();
			}}
			appearance="subtle"
			aria-expanded={isOpen}
			aria-controls={controlContentId}
			aria-label={isOpen ? collapseAriaLabel : expandAriaLabel}
			iconBefore={
				isOpen ? (
					<ChevronUpIcon spacing="spacious" label="" />
				) : (
					<ChevronDownIcon spacing="spacious" label="" />
				)
			}
		/>
	);
};

const buttonStyles = xcss({ flexShrink: '0' });
