import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	redactions: {
		id: 'issue.action-menu.redactions',
		defaultMessage: 'Redactions',
		description: 'Label for the redactions actions item which opens redaction table',
	},
	spotlightMessage: {
		id: 'issue.action-menu.redaction-spotlight-message',
		defaultMessage:
			'Find out what type of data was redacted, who initiated the redaction, and when it occurred.',
		description: 'Redaction spotlight message on redactions in action menu',
	},
	readMore: {
		id: 'issue.action-menu.redaction-read-more',
		defaultMessage: 'Read more',
		description: 'Redaction spotlight message on redactions in action menu',
	},
	ok: {
		id: 'issue.action-menu.redaction-spotlight-ok',
		defaultMessage: 'Ok',
		description: 'Redaction spotlight message on redactions in action menu',
	},
});
