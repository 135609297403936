import { getAutomationStack } from '@atlassian/jira-automation-platform/src/common/utils.tsx';
import {
	performDeleteRequest,
	performPatchRequest,
} from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { Environment } from '@atlassian/jira-shared-types/src/tenant-context.tsx';

type DeleteRuleParams = {
	environment: Environment | null;
	cloudId: string;
	projectId: string;
	ruleUuid: string;
};
export type DeleteRuleResult = {};

export const deleteRule = async ({
	environment,
	cloudId,
	projectId,
	ruleUuid,
}: DeleteRuleParams): Promise<DeleteRuleResult> => {
	const automationStack = await getAutomationStack(environment, cloudId);
	const DELETE_RULE_URL = `/gateway/api/automation/internal-api/jira/${cloudId}/${automationStack}/rest/${projectId}/rule/${ruleUuid}`;

	return performDeleteRequest(DELETE_RULE_URL);
};

type DeleteIssuePropertiesParams = {
	issueKey: string;
};
export type DeleteIssuePropertiesResult = {};

export const deleteIssueProperties = async ({
	issueKey,
}: DeleteIssuePropertiesParams): Promise<DeleteIssuePropertiesResult> => {
	const ruleConfigEndpoint = `/rest/api/2/issue/${issueKey}/properties/recurrence-config`;
	return performDeleteRequest(ruleConfigEndpoint);
};

type DisableRuleParams = {
	environment: Environment | null;
	cloudId: string;
	projectId: string;
	ruleUuid: string;
};
export type DisableRuleResult = {};

export const disableRule = async ({
	environment,
	cloudId,
	projectId,
	ruleUuid,
}: DisableRuleParams): Promise<DisableRuleResult> => {
	const automationStack = await getAutomationStack(environment, cloudId);

	const disableRuleEndpoint = `/gateway/api/automation/internal-api/jira/${cloudId}/${automationStack}/rest/${projectId}/rule/${ruleUuid}/disable`;
	return performPatchRequest(disableRuleEndpoint);
};
