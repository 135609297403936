import React, { createContext, useCallback, useContext, useMemo, useRef, memo } from 'react';
import { v4 as uuid } from 'uuid';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import {
	type AI_EVENTS_TYPE,
	type AIAnalyticsContextType,
	type ProviderProps,
	type AttributesType,
	isConfigEqual,
} from './common.tsx';

const AIAnalyticsContext = createContext<AIAnalyticsContextType | null>(null);
/**
 * Although we are checking for config changes and using memo, it is better to pass a constant config.
 * This is because the config object is passed to the fireTrackAI function, which is a dependency of the useCallback hook.
 */
export const AIAnalyticsProvider = memo(({ config, children }: ProviderProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const singleInstrumentationID = useMemo(() => uuid(), []);
	const traceIdsRef = useRef<Set<string>>(new Set());

	const addTraceId = useCallback((traceId: string) => {
		traceIdsRef.current.add(traceId);
	}, []);

	const fireTrackAI = useCallback(
		(eventName: AI_EVENTS_TYPE, attr: Omit<AttributesType, 'singleInstrumentationID'> = {}) => {
			const event = createAnalyticsEvent({});
			// use this console log to verify your events
			// console.log('... here', { eventName, ...attr, traceIds: traceIdsRef.current });
			fireTrackAnalytics(event, eventName, {
				singleInstrumentationID,
				traceIds: [...traceIdsRef.current].join(','),
				...config,
				...attr,
			});
		},
		[createAnalyticsEvent, config, singleInstrumentationID],
	);

	const contextValue = useMemo(() => ({ addTraceId, fireTrackAI }), [addTraceId, fireTrackAI]);

	return <AIAnalyticsContext.Provider value={contextValue}>{children}</AIAnalyticsContext.Provider>;
}, isConfigEqual);

export const useAIAnalyticsContext = () => {
	const context = useContext(AIAnalyticsContext);
	if (!context) {
		throw new Error('useAIAnalytics must be used within a AIAnalyticsProvider');
	}
	return context;
};
