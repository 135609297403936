import isEmpty from 'lodash/isEmpty';
import type {
	CustomFieldLatest,
	CustomFieldTypeLatest,
	CustomFieldProperties,
	CustomFieldTypeProperties,
	CustomFieldExtension,
} from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';

function isCustomFieldSchemaNew(obj: unknown): obj is CustomFieldLatest | CustomFieldTypeLatest {
	if (
		typeof obj === 'object' &&
		obj !== null &&
		'properties' in obj &&
		typeof obj.properties === 'object' &&
		obj.properties !== null
	) {
		return (
			'view' in obj.properties ||
			('edit' in obj.properties &&
				typeof obj.properties.edit === 'object' &&
				obj.properties.edit !== null &&
				('validation' in obj.properties.edit || 'experience' in obj.properties.edit))
		);
	}
	return false;
}

export function transformExtension(
	extension: CustomFieldExtension | null,
): CustomFieldLatest | CustomFieldTypeLatest | null {
	if (!extension) {
		return null;
	}
	if (isCustomFieldSchemaNew(extension)) {
		return extension;
	}

	const {
		key,
		type,
		name,
		description,
		readOnly,
		collection,
		resourceUploadId,
		formatter,
		validation,
		edit,
		value,
		render,
		resource,
		function: viewFunction,
	} = extension.properties;

	let newEdit: CustomFieldTypeLatest['properties']['edit'];
	if (edit) {
		if ('resource' in edit) {
			const { resource: editResource, render: editRender, isInline } = edit;
			newEdit = {
				resource: editResource,
				...(editRender && { render: editRender }),
				...(validation && { validation }),
				...(isInline && { isInline }),
			};
			newEdit.experience = ['issue-create', 'issue-view', 'issue-transition'];
		} else {
			const { function: editFunction } = edit;
			newEdit = {
				...(editFunction && { function: editFunction }),
				...(validation && { validation }),
			};
		}
	} else {
		newEdit = {
			...(validation && { validation }),
		};
	}

	let newView: CustomFieldTypeLatest['properties']['view'];
	if (viewFunction) {
		newView = {
			function: viewFunction,
			...(formatter && { formatter }),
			...(value && { value }),
		};
	} else {
		newView = {
			...(formatter && { formatter }),
			...(value && { value }),
			...(render && { render }),
			...(resource && { resource }),
		};
		if (newView.resource) {
			newView.experience = ['issue-view'];
		}
	}

	const transformed: CustomFieldProperties | CustomFieldTypeProperties = {
		key,
		type,
		name,
		description,
		...(resourceUploadId && { resourceUploadId }),
		...(readOnly && { readOnly }),
		...(collection && { collection }),
		...(!isEmpty(newEdit) && { edit: newEdit }),
		...(!isEmpty(newView) && { view: newView }),
	};

	const newExtension: CustomFieldLatest | CustomFieldTypeLatest = {
		...extension,
		properties: transformed,
	};

	return newExtension;
}
