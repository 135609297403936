import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import {
	isNotFoundError,
	isPermissionError,
} from '@atlassian/jira-polaris-lib-errors/src/controllers/utils.tsx';
import { fetchSiteSharingConfig } from '@atlassian/jira-polaris-sharing-config-service/src/services/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { isClientFetchError } from '@atlassian/jira-fetch/src/index.tsx';
import type { State, Props } from '../../types.tsx';

export const loadSharingSiteConfig =
	(): Action<State, Props> =>
	async ({ setState }, { isSharedView }) => {
		if (isSharedView) {
			setState({
				siteConfig: {
					isEnabled: true,
					isLoading: false,
					error: undefined,
				},
			});
			return;
		}

		setState({
			siteConfig: {
				isEnabled: false,
				isLoading: true,
				error: undefined,
			},
		});

		try {
			experience.sharing.loadSiteConfig.start();

			const { enabled } = await fetchSiteSharingConfig();
			setState({
				siteConfig: {
					isEnabled: enabled,
					isLoading: false,
					error: undefined,
				},
			});

			experience.sharing.loadSiteConfig.success();
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState({
				siteConfig: {
					isEnabled: false,
					isLoading: false,
					error,
				},
			});

			if (isPermissionError(error) || isNotFoundError(error) || isClientFetchError(error)) {
				experience.sharing.loadSiteConfig.abort(error);
			} else {
				experience.sharing.loadSiteConfig.failure(error);
			}

			fireErrorAnalytics(
				createErrorAnalytics('polaris.view-sharing.load-sharing-site-config-action', error),
			);
		}
	};
