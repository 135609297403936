import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addGoal: {
		id: 'business-goal-picker.goals-cell-content.add-goal',
		defaultMessage: 'Add goal',
		description:
			'The text shown to indicate to add a new goal when the user hovers over the cell & there are no existing goals linked.',
	},
	noGoals: {
		id: 'business-goal-picker.goals-cell-content.no-goals',
		defaultMessage: 'None',
		description: 'The text shown to indicate no goal has been linked to the issue.',
	},
	linkedGoals: {
		id: 'business-goal-picker.goals-cell-content.goals-linked',
		defaultMessage: '{goalCount, plural, other {# goals} one {# goal}} linked',
		description: 'The text shown to indicate the number of goals linked to the issue.',
	},
});
