import React from 'react';
import { useFragment, graphql } from 'react-relay';
import { expVal } from '@atlassian/jira-feature-experiments';
import { EmptyFieldText } from '@atlassian/jira-issue-empty-field-text/src/ui/index.tsx';
import { OptionTag } from '@atlassian/jira-option-color-picker/src/option-tag/index.tsx';
import type { singleSelect_issueFieldSingleSelectReadviewFull_SingleSelectReadView$key as SingleSelectReadViewFragment } from '@atlassian/jira-relay/src/__generated__/singleSelect_issueFieldSingleSelectReadviewFull_SingleSelectReadView.graphql';
import { FRAGMENT_SELECTABLE_FIELD_OPTIONS_FIRST } from '@atlassian/jira-issue-field-constants/src/index.tsx';
import { CATEGORY_TYPE, SELECT_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';

import { fg } from '@atlassian/jira-feature-gating';
import type { SingleSelectReadViewProps } from './types.tsx';
/**
 * The SingleSelectReadView will show a read only view of SingleSelect field.
 * @param props {@link SingleSelectReadViewProps}
 */
export const SingleSelectReadView = (props: SingleSelectReadViewProps) => {
	const { fragmentRef, fieldType } = props;
	const data = useFragment<SingleSelectReadViewFragment>(
		graphql`
			fragment singleSelect_issueFieldSingleSelectReadviewFull_SingleSelectReadView on JiraSingleSelectField
			@argumentDefinitions(
				includeSingleSelectColorField: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/thor-colorful-single-select-milestone2-experiment.relayprovider"
				}
				isJscInlineEditRefactorEnabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/jsc-inline-editing-field-refactor.relayprovider"
				}
			) {
				fieldOptions {
					totalCount
				}
				fieldOption {
					value
					color @include(if: $isJscInlineEditRefactorEnabled) {
						colorKey
					}
					color @include(if: $includeSingleSelectColorField) {
						colorKey
					}
				}
			}
		`,
		fragmentRef,
	);
	if (!data.fieldOption?.value) {
		return <EmptyFieldText />;
	}
	const totalOptionsCount = data?.fieldOptions?.totalCount ?? 0;

	const isCategoryAndListExperimentON =
		props.fieldType === CATEGORY_TYPE && fg('jsc_inline_editing_field_refactor');
	// Separate checks to assure exposure
	const isSingleSelectAndColourfulExperimentOn =
		fieldType === SELECT_CF_TYPE &&
		totalOptionsCount <= FRAGMENT_SELECTABLE_FIELD_OPTIONS_FIRST &&
		expVal(
			'thor_colourful_single_select_milestone2_experiment',
			'isColorfulSingleSelectEnabled',
			false,
		);

	if (isSingleSelectAndColourfulExperimentOn || isCategoryAndListExperimentON) {
		return (
			<OptionTag name={data.fieldOption.value} color={data?.fieldOption?.color?.colorKey ?? null} />
		);
	}

	return <>{data.fieldOption.value}</>;
};
