import React, { useEffect } from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import type LinkedAlertsLinkModal from './ui/link-modal/index.tsx';
import type LinkedAlertsField from './ui/linked-alerts-field/main.tsx';
import { linkedAlertsViewModalEntryPoint } from './ui/view-modal/entrypoint.tsx';
import type { Props } from './ui/linked-alerts-field/types.tsx';
import type LinkedAlertsViewModal from './ui/view-modal/index.tsx';
import { linkedAlertsModalEntrypoint } from './ui/link-modal/entrypoint.tsx';
import { useLinkAlertsModal, useLinkedAlertsViewModalState } from './services/main.tsx';

const runtimeProps = {};
const entryPointParams = {};
const LazyAsyncLinkedAlertsField = lazyAfterPaint<typeof LinkedAlertsField>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-incident-management-linked-alerts-field" */ './ui/linked-alerts-field/main'
		),
);

export const AsyncLinkedAlertsField = (props: Props) => (
	<ErrorBoundary
		id="jira-servicedesk.incident-management.linked-alerts-field.async"
		packageName="AsyncLinkedAlertsField"
	>
		<Placeholder name="servicedesk-incident-management-linked-alerts-field" fallback={null}>
			<LazyAsyncLinkedAlertsField {...props} />
		</Placeholder>
	</ErrorBoundary>
);

const LazyAsyncLinkedAlertsViewModal = lazyAfterPaint<typeof LinkedAlertsLinkModal>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-incident-management-view-modal" */ './ui/view-modal/index'
		),
);

const AsyncLinkedAlertsViewModalOld = () => (
	<ErrorBoundary
		id="jira-servicedesk.incident-management.linked-alerts-view-modal.async"
		packageName="AsyncLinkedAlertsViewModal"
	>
		<Placeholder name="servicedesk-incident-management-view-modal" fallback={null}>
			<LazyAsyncLinkedAlertsViewModal />
		</Placeholder>
	</ErrorBoundary>
);

const LinkedAlertsViewModalNew = () => {
	const [isViewModalOpen] = useLinkedAlertsViewModalState();
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		linkedAlertsViewModalEntryPoint,
		entryPointParams,
	);

	// Violating react-entrypoint/no-load-in-hooks so we can experiment faster: https://hello.atlassian.net/wiki/spaces/JIE/pages/4772823699/LDR+Entrypoint+Load+Via+Hooks+For+Faster+Experimentation
	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		// only load entrypoint when the modal is opened
		if (isViewModalOpen) {
			entryPointActions.load();
		}
	}, [entryPointActions, isViewModalOpen]);

	return (
		<JiraEntryPointContainer
			id="jira-servicedesk.incident-management.linked-alerts-view-modal.async"
			packageName="AsyncLinkedAlertsViewModal"
			placeholderName="servicedesk-incident-management-view-modal"
			entryPointReferenceSubject={entryPointReferenceSubject}
			fallback={null}
			runtimeProps={runtimeProps}
		/>
	);
};

export const AsyncLinkedAlertsViewModal = componentWithFG(
	'iv-llc-unnecessary-bundles-view-alerts-modal',
	LinkedAlertsViewModalNew,
	AsyncLinkedAlertsViewModalOld,
);

const LazyAsyncLinkedAlertsLinkModal = lazyAfterPaint<typeof LinkedAlertsViewModal>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-incident-management-link-modal" */ './ui/link-modal/index'
		),
);

const AsyncLinkedAlertsLinkModalNew = () => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		linkedAlertsModalEntrypoint,
		entryPointParams,
	);

	const [isLinkModalOpen] = useLinkAlertsModal();

	// Violating react-entrypoint/no-load-in-hooks so we can experiment faster: https://hello.atlassian.net/wiki/spaces/JIE/pages/4772823699/LDR+Entrypoint+Load+Via+Hooks+For+Faster+Experimentation
	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		if (isLinkModalOpen) {
			entryPointActions.load();
		}
	}, [entryPointActions, isLinkModalOpen]);

	return (
		<JiraEntryPointContainer
			id="jira-linked-alerts-async"
			packageName="jira-linked-alerts"
			entryPointReferenceSubject={entryPointReferenceSubject}
			runtimeProps={runtimeProps}
			errorFallback="unmount"
		/>
	);
};

const AsyncLinkedAlertsLinkModalOld = () => (
	<ErrorBoundary
		id="jira-servicedesk.incident-management.linked-alerts-link-modal.async"
		packageName="AsyncLinkedAlertsLinkModal"
	>
		<Placeholder name="servicedesk-incident-management-link-modal" fallback={null}>
			<LazyAsyncLinkedAlertsLinkModal />
		</Placeholder>
	</ErrorBoundary>
);

export const AsyncLinkedAlertsLinkModal = componentWithFG(
	'alerts_link_iv_llc_unnecessary_bundles',
	AsyncLinkedAlertsLinkModalNew,
	AsyncLinkedAlertsLinkModalOld,
);
