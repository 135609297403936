import React, { type FC, useCallback, useMemo, useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import isUndefined from 'lodash/isUndefined';
import { forgeModulesConcurrentLoadTime } from '@atlassian/jira-forge-ui-analytics/src/common/utils/performance-analytics/main.tsx';
import {
	StickyHeaderTrackerContainer,
	useStickyHeader,
} from '@atlassian/jira-issue-header-tracker-service/src/services/sticky-header/index.tsx';
import { smoothScrollIntoViewIfNeeded } from '@atlassian/jira-issue-view-common-utils/src/scroll/index.tsx';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import {
	fireUIAnalytics,
	useAnalyticsEvents,
	type Attributes,
} from '@atlassian/jira-product-analytics-bridge';
import { GROUP_COLLAPSIBLE_UI_EVENT } from '../common/constants.tsx';
import type {
	BaseGroupWithIconProps,
	CollapsibleGroupProps,
	GeneralProps,
} from '../common/types.tsx';
import CollapsibleGroupFactory from '../common/ui/collapsible-group-factory/index.tsx';
import { useGroupLocalStorage } from '../services/local-storage/index.tsx';
import { Status } from './status/index.tsx';
import { Subtitle } from './subtitle/index.tsx';

/**
 * Default Grouping component used for Issue View
 * @param {*} param0
 */
export const CollapsibleGroup = ({
	children,
	title,
	subTitle,
	onChange,
	components,
	projectKey,
	id,
	actionButton,
	initialOpened,
	additionalEventAttributes,
	enableVisualRefreshForTitle,
	containerElement,
}: CollapsibleGroupProps) => {
	const startTime = useRef(performance.now());
	const CollapsibleGroupRef = useRef<unknown>(null);
	const [isOpen, setIsOpen] = useGroupLocalStorage(projectKey, id, !!initialOpened);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [stickyHeaderPosition, stickyHeaderEnabled] = useStickyHeader();
	const forgeExtensionId = useMemo(
		() =>
			id.includes('ari:cloud:ecosystem::extension')
				? id.replace(/(.*)(ari:cloud:ecosystem::extension)/, '$2')
				: null,
		[id],
	);

	// Ideally this code should be run inside the Forge component, but the `isOpen` is being initialized here,
	// after the children are already rendered
	useEffect(
		() => {
			if (isOpen && forgeExtensionId) {
				forgeModulesConcurrentLoadTime(forgeExtensionId).start({ startTime: startTime.current });
			}
		},
		// We want to run this code only once on mount
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	if (stickyHeaderEnabled === true) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const previousValue = usePrevious(isOpen);
		const hasClosed = !isUndefined(previousValue) && !isOpen && !isEqual(isOpen, previousValue);

		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			if (hasClosed) {
				// Wait until group has collapsed
				setTimeout(() => {
					const refElement = CollapsibleGroupRef.current;

					// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'HTMLElement | null | undefined'.
					smoothScrollIntoViewIfNeeded(refElement, {
						scrollMode: 'if-needed',
						behavior: (actions) =>
							actions.forEach(({ el, top }) => {
								// eslint-disable-next-line no-param-reassign
								el.scrollTop = top - (stickyHeaderPosition || 0);
							}),
						block: 'start',
					});
				});
			}
		}, [hasClosed, stickyHeaderPosition]);
	}

	const onHeaderClick = useCallback(() => {
		setIsOpen(!isOpen);

		const openedOrClosed = isOpen ? 'closed' : 'opened';
		const analyticsEvent = createAnalyticsEvent({
			eventName: `bentoCollapsibleGroup ${openedOrClosed}`,
			action: 'clicked',
			actionSubject: 'button',
			actionSubjectId: 'bentoCollapsibleGroup',
		});
		const uiAnalytics: Attributes = {
			groupTitle: title,
			groupState: openedOrClosed,
			...additionalEventAttributes,
		};
		fireUIAnalytics(analyticsEvent, GROUP_COLLAPSIBLE_UI_EVENT, uiAnalytics);

		if (forgeExtensionId && !isOpen) {
			forgeModulesConcurrentLoadTime(forgeExtensionId).start();
		}

		if (typeof onChange === 'function') onChange(!isOpen);
	}, [
		additionalEventAttributes,
		createAnalyticsEvent,
		forgeExtensionId,
		isOpen,
		onChange,
		setIsOpen,
		title,
	]);

	const renderHeaderActions = useMemo(() => {
		if (actionButton) {
			return () => actionButton;
		}

		return undefined;
	}, [actionButton]);

	return (
		<StickyHeaderTrackerContainer scope={`group-${id}`}>
			<CollapsibleGroupFactory
				id={id}
				title={title}
				subTitle={subTitle}
				isOpen={isOpen}
				onHeaderClick={onHeaderClick}
				components={components}
				renderHeaderActions={renderHeaderActions}
				stickyHeaderPosition={stickyHeaderPosition}
				// @ts-expect-error - TS2322 - Type 'MutableRefObject<unknown> | null' is not assignable to type 'Ref<Props> | undefined'.
				ref={stickyHeaderEnabled === true ? CollapsibleGroupRef : null}
				enableVisualRefreshForTitle={enableVisualRefreshForTitle}
				containerElement={containerElement}
			>
				{children}
			</CollapsibleGroupFactory>
		</StickyHeaderTrackerContainer>
	);
};

/**
 * Group with header Icon and a Badge
 */
export const CollapsibleGroupWithHeaderIcon: FC<BaseGroupWithIconProps> = ({
	icon,
	title,
	subtitle,
	status,
	extension,
	...rest
}) => {
	const components = {
		HeaderRightAligned: useCallback<FC<GeneralProps>>(
			({ isOpen }) => {
				if (!isOpen) {
					return <Status status={status} extension={extension} />;
				}
				return null;
			},
			[status, extension],
		),
	};
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const name = title as string;
	return (
		<CollapsibleGroup
			{...rest}
			title={title}
			components={components}
			subTitle={<Subtitle icon={icon} label={subtitle} name={name} />}
		/>
	);
};
