import { createHook } from 'react-sweet-state';

import { type Actions, store } from '../../main';
import type { IssueAdjustmentsState } from '../../types';

const selectTriggerFieldInitializationKey = (state: IssueAdjustmentsState) =>
	state.triggerFieldInitializationKey;

export const useTriggerFieldInitialization = createHook<IssueAdjustmentsState, Actions, number>(
	store,
	{
		selector: selectTriggerFieldInitializationKey,
	},
);
