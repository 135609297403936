import sortBy from 'lodash/sortBy';
import type { PolarisComment } from '@atlassian/jira-polaris-domain-comment/src/index.tsx';
import type { IssueLink } from '../../services/jira/get-issue/types.tsx';
import type { RemoteIssue, RemoteIssueMeta } from '../crud/types.tsx';

export const getCommentsMeta = (comments: PolarisComment[]) => {
	const lastCommentByCreatedDate = sortBy(comments, (comment) => comment.created).pop();
	return {
		count: comments.length,
		lastCreated: comments.length > 0 ? lastCommentByCreatedDate?.created ?? undefined : undefined,
		lastCreatedAuthor:
			comments.length > 0 ? lastCommentByCreatedDate?.author?.accountId ?? undefined : undefined,
	};
};

export const getMetaFromJiraSearchIssue = (
	response: RemoteIssue,
	polarisIssueLinkType: string | undefined,
	hiddenIssueLinkTypes: string[],
): RemoteIssueMeta => {
	if ('meta' in response) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return response.meta as RemoteIssueMeta;
	}
	const issueLinksCount =
		response.fields.issuelinks?.filter((issueLink: IssueLink) => {
			const linkedIssueKey = issueLink?.inwardIssue?.key || issueLink?.outwardIssue?.key;
			if (!linkedIssueKey) {
				return false;
			}

			if (
				issueLink.type.id !== polarisIssueLinkType &&
				!hiddenIssueLinkTypes.includes(issueLink.type.id)
			) {
				return true;
			}
			return false;
		})?.length || 0;

	return {
		issueLinks: {
			count: issueLinksCount,
		},
		comments: getCommentsMeta(response.fields.comment?.comments || []),
	};
};
