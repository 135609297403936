import type { ComponentPropsWithRef, RefAttributes } from 'react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled, { type StyledComponentClass } from 'styled-components';
import Button from '@atlaskit/button/standard-button';
import { token } from '@atlaskit/tokens';

type ButtonProps = ComponentPropsWithRef<typeof Button>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any,  @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledButton: StyledComponentClass<ButtonProps & RefAttributes<HTMLElement>, any> =
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
	styled(Button)({
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'&&': {
			padding: `0 ${token('space.050')}`,
			alignItems: 'center',
		},
	});
