import { createSelector } from 'reselect';
import type { IssueRemoteData } from '@atlassian/jira-issue-view-common-types/src/issue-remote-data-type.tsx';
import type { EntitiesState } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { entitiesSelector } from '../common/state/selectors/issue-selector.tsx';

const issueRemoteDataSelector = createSelector(
	entitiesSelector,
	(entities: EntitiesState): IssueRemoteData => entities.issueRemoteData,
);

export const isIssueRemoteDataLoadingCompleteSelector = createSelector(
	issueRemoteDataSelector,
	(issueRemoteData: IssueRemoteData): boolean => issueRemoteData.isLoadingComplete,
);

export const isIssueRemoteDataLoadingSelector = createSelector(
	issueRemoteDataSelector,
	(issueRemoteData: IssueRemoteData): boolean => issueRemoteData.isLoading,
);
