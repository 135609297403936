/**
 * @generated SignedSource<<fa6f54fcc828d3b7323de3e5dc523338>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueActionsView_issueViewFoundation_IssueActionsMeatballMenuInternal$data = {
  readonly redactions?: {
    readonly totalCount: AGG$Long | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"view_issueViewFoundation_ConfigureIssueFF">;
  readonly " $fragmentType": "issueActionsView_issueViewFoundation_IssueActionsMeatballMenuInternal";
};
export type issueActionsView_issueViewFoundation_IssueActionsMeatballMenuInternal$key = {
  readonly " $data"?: issueActionsView_issueViewFoundation_IssueActionsMeatballMenuInternal$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueActionsView_issueViewFoundation_IssueActionsMeatballMenuInternal">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjiraissueviewredactionexperiencerelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationconfigurationurlrelayprovider"
    }
  ],
  "kind": "Fragment",
  "name": "issueActionsView_issueViewFoundation_IssueActionsMeatballMenuInternal",
  "selections": [
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjiraissueviewredactionexperiencerelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "concreteType": "JiraRedactionConnection",
          "kind": "LinkedField",
          "name": "redactions",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "totalCount"
            }
          ]
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationconfigurationurlrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "view_issueViewFoundation_ConfigureIssueFF"
        }
      ]
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "66ef9eec0a10882375e05ea73494f4b8";

export default node;
