/**
 * @generated SignedSource<<86c1b45ff4dd20dbb7b4b6e059423196>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type view_issueViewBase_People$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FieldEditIcon">;
  readonly " $fragmentType": "view_issueViewBase_People";
};
export type view_issueViewBase_People$key = {
  readonly " $data"?: view_issueViewBase_People$data;
  readonly " $fragmentSpreads": FragmentRefs<"view_issueViewBase_People">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "view_issueViewBase_People",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "FieldEditIcon"
    }
  ],
  "type": "JiraIssueField",
  "abstractKey": "__isJiraIssueField"
};

(node as any).hash = "22131074753e5f1dae73d01ce954b22a";

export default node;
