import React, { type ComponentType, type ReactElement } from 'react';
import { Provider as ReduxProvider } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	issueViewStore,
	type ActionsType,
} from '@atlassian/jira-issue-view-react-sweet-state/src/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createActionsHook,
	createHook,
	createContainer,
	type HookActionsFunction,
} from '@atlassian/react-sweet-state';

// eslint-disable-next-line camelcase
const useStore_DO_NOT_USE = createHook(issueViewStore);

/* eslint-disable-next-line camelcase */ // @ts-expect-error - TS2322 - Type 'HookActionsFunction<BoundActions<State, { readonly setStore: (store: any) => Action<State, void, void | Promise<void>>; }>>' is not assignable to type 'HookActionsFunction<{ readonly setStore: (store: any) => Action<State, void, void | Promise<void>>; }>'.
export const useStoreActionsForIssueViewShortcutActions_DO_NOT_USE: HookActionsFunction<ActionsType> =
	createActionsHook(issueViewStore);

export const StoreForIssueViewContainer = createContainer(issueViewStore);

// eslint-disable-next-line camelcase
export const withStoreForIssueViewShortcutActions_DO_NOT_USE =
	<Props,>(WrappedComponent: ComponentType<Props>): ((props: Props) => ReactElement) =>
	// @ts-expect-error - TS2322 - Type '(props: Props) => JSX.Element | null' is not assignable to type '(props: Props) => ReactElement<any, string | JSXElementConstructor<any>>'.
	(props: Props) => {
		const [{ store }] = useStore_DO_NOT_USE();

		return store !== null ? (
			<ReduxProvider store={store}>
				{/* @ts-expect-error - TS2322 - Type 'Props' is not assignable to type 'IntrinsicAttributes & Props & { children?: ReactNode; }'. */}
				<WrappedComponent {...props} />
			</ReduxProvider>
		) : null;
	};
