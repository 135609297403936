/* eslint-disable @atlassian/relay/unused-fields */
import React, { useEffect } from 'react';
import { styled } from '@compiled/react';
import OriginTracer from '@atlassiansox/origin-tracing';
import { graphql, useFragment } from 'react-relay';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import type { MainIssueAggQueryRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { extractCanUpgradeAndPayDetails } from '@atlassian/jira-billing/src/controllers/can-upgrade-and-pay/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIsJiraSoftwareFree } from '@atlassian/jira-jsw-feature-gates/src/ui/index.tsx';
import { useIsProductUsingCcp } from '@atlassian/jira-billing/src/controllers/billing-system/use-is-product-using-ccp.tsx';
import {
	fireUIAnalytics,
	FireUiAnalytics,
	useAnalyticsEvents,
	ContextualAnalyticsData,
} from '@atlassian/jira-product-analytics-bridge';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import type { ui_storageLimitsBanner_StorageLimitsBannerUsingMainIssueAggQuery$key } from '@atlassian/jira-relay/src/__generated__/ui_storageLimitsBanner_StorageLimitsBannerUsingMainIssueAggQuery.graphql';
import { ERROR_BOUNDARY_ID, PACKAGE_NAME, STORAGE_RATIO_TO_SHOW_BANNER } from './constants.tsx';
import messages from './messages.tsx';
import { useCheckRequisitesAndFetchUpgradeLink, useGetUsedStorageData } from './utils.tsx';

export type StorageLimitsBannerProps = {
	isFullWidth?: boolean;
};

export type IssueViewStorageLimitsBannerProps = {
	isFullWidth?: boolean;
	rootRelayFragment: MainIssueAggQueryRelayFragment | null;
};

const FREE_PLAN_STORAGE_LIMIT = 2;
const STANDARD_PLAN_STORAGE_LIMIT = 250;

function BannerContent({
	isFullWidth,
	upgradeLinkWithOrigin,
	openBillingPage,
	isUsingCcp,
	storageLimit,
	usedStorageRatio,
}: {
	isFullWidth: boolean;
	upgradeLinkWithOrigin: string | undefined;
	openBillingPage: () => void;
	isUsingCcp: boolean;
	storageLimit: number;
	usedStorageRatio: number;
}) {
	const { formatMessage } = useIntl();

	const bannerText =
		usedStorageRatio < 1
			? formatMessage(messages.storageLimits90PercentBannerText, {
					freePlanStorageLimit: storageLimit,
				})
			: formatMessage(messages.storageLimitsBannerText, {
					freePlanStorageLimit: storageLimit,
				});

	return (
		<ContextualAnalyticsData
			attributes={{
				billingSourceSystem: isUsingCcp ? 'CCP' : 'HAMS',
				usedStorage: storageLimit * usedStorageRatio,
			}}
		>
			<Box xcss={isFullWidth ? fullWidthBannerWrapperStyles : partialWidthBannerWrapperStyles}>
				<FireUiAnalytics
					action="viewed"
					actionSubject="banner"
					actionSubjectId="storageLimitsBanner"
				/>
				<BannerContainer data-testid="storage-limits-banner.ui.storage-limits-banner">
					<WarningIcon
						spacing="spacious"
						label=""
						color={token('color.icon.warning')}
						LEGACY_secondaryColor={token('color.background.warning')}
					/>
					<BannerText
						data-testid="storage-limits-banner.ui.storage-limits-banner-text"
						title={bannerText}
					>
						{bannerText}
					</BannerText>

					<BannerLink
						onClick={openBillingPage}
						href={upgradeLinkWithOrigin}
						target="_blank"
						data-testid="storage-limits-banner.ui.storage-limits-banner-link"
					>
						{formatMessage(messages.hamsLinkText, {
							standardPlanStorageLimit: STANDARD_PLAN_STORAGE_LIMIT,
						})}
					</BannerLink>
				</BannerContainer>
			</Box>
		</ContextualAnalyticsData>
	);
}

function StorageLimitsBannerComponent(props: StorageLimitsBannerProps) {
	const { isFullWidth = false } = props;
	const isUsingCcp = useIsProductUsingCcp(SOFTWARE_PROJECT);
	const {
		canView,
		upgradeLink,
		isLoading,
		error: upgradeAndPayError,
	} = useCheckRequisitesAndFetchUpgradeLink();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const origin = new OriginTracer({ product: 'jira' });
	const upgradeLinkWithOrigin = upgradeLink ? origin.addToUrl(upgradeLink) : undefined;

	const usedStorageData = useGetUsedStorageData({ skip: !canView });
	const storageLimit =
		usedStorageData?.storageLimit !== undefined
			? usedStorageData.storageLimit
			: FREE_PLAN_STORAGE_LIMIT;
	const usedStorageRatio = usedStorageData?.storageRatio;
	const enoughStorageUsed =
		usedStorageRatio !== undefined && usedStorageRatio >= STORAGE_RATIO_TO_SHOW_BANNER;

	useEffect(() => {
		if (upgradeAndPayError) {
			fireErrorAnalytics({
				error: upgradeAndPayError,
				meta: {
					id: 'canUpgradeAndPay',
					packageName: PACKAGE_NAME,
					teamName: 'growth-tako',
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		}
	}, [upgradeAndPayError]);

	if (
		!canView ||
		!enoughStorageUsed ||
		isLoading ||
		upgradeAndPayError ||
		usedStorageData?.edition !== 'free'
	) {
		return null;
	}

	const openBillingPage = () => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'link',
			}),
			'storageLimitsBannerLink',
		);
	};

	return (
		<BannerContent
			isFullWidth={isFullWidth}
			upgradeLinkWithOrigin={upgradeLinkWithOrigin}
			openBillingPage={openBillingPage}
			isUsingCcp={isUsingCcp}
			storageLimit={storageLimit}
			usedStorageRatio={usedStorageRatio}
		/>
	);
}

export function StorageLimitsBannerUsingMainIssueAggQuery({
	isFullWidth = false,
	rootRelayFragment,
}: IssueViewStorageLimitsBannerProps) {
	const isUsingCcp = useIsProductUsingCcp(SOFTWARE_PROJECT);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const isJswFree = useIsJiraSoftwareFree();
	if (!isJswFree) {
		return null;
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const data = useFragment<ui_storageLimitsBanner_StorageLimitsBannerUsingMainIssueAggQuery$key>(
		graphql`
			fragment ui_storageLimitsBanner_StorageLimitsBannerUsingMainIssueAggQuery on Query {
				tenantContexts(cloudIds: [$cloudId]) {
					entitlementInfo(hamsProductKey: $productKey) {
						entitlement {
							transactionAccount {
								experienceCapabilities {
									addPaymentMethod {
										isAvailableToUser
									}
								}
							}
							subscription {
								accountDetails {
									invoiceGroup {
										experienceCapabilities {
											configurePayment {
												isAvailableToUser
											}
										}
									}
								}
							}
							experienceCapabilities {
								changeOffering(offeringKey: $offeringKey) {
									experienceUrl
									isAvailableToUser
								}
							}
						}
					}
				}
			}
		`,
		rootRelayFragment,
	);

	if (data == null) {
		return null;
	}

	const { canUpgradeAndPay, upgradeLink } = extractCanUpgradeAndPayDetails(data);

	const origin = new OriginTracer({ product: 'jira' });
	const upgradeLinkWithOrigin = upgradeLink ? origin.addToUrl(upgradeLink) : undefined;

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const usedStorageData = useGetUsedStorageData({ skip: !canUpgradeAndPay });
	const storageLimit =
		usedStorageData?.storageLimit !== undefined
			? usedStorageData.storageLimit
			: FREE_PLAN_STORAGE_LIMIT;
	const usedStorageRatio = usedStorageData?.storageRatio;
	const enoughStorageUsed =
		usedStorageRatio !== undefined && usedStorageRatio >= STORAGE_RATIO_TO_SHOW_BANNER;

	if (!canUpgradeAndPay || !enoughStorageUsed || usedStorageData?.edition !== 'free') {
		return null;
	}

	const openBillingPage = () => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'link',
			}),
			'storageLimitsBannerLink',
		);
	};

	return (
		<JSErrorBoundary
			id={ERROR_BOUNDARY_ID}
			packageName={PACKAGE_NAME}
			fallback="unmount"
			teamName="growth-tako"
		>
			<BannerContent
				isFullWidth={isFullWidth}
				upgradeLinkWithOrigin={upgradeLinkWithOrigin}
				openBillingPage={openBillingPage}
				isUsingCcp={isUsingCcp}
				storageLimit={storageLimit}
				usedStorageRatio={usedStorageRatio}
			/>
		</JSErrorBoundary>
	);
}

export default function StorageLimitsBanner(props: StorageLimitsBannerProps) {
	return (
		<JSErrorBoundary
			id={ERROR_BOUNDARY_ID}
			packageName={PACKAGE_NAME}
			fallback="unmount"
			teamName="growth-tako"
		>
			<StorageLimitsBannerComponent {...props} />
		</JSErrorBoundary>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BannerContainer = styled.div({
	backgroundColor: token('color.background.warning'),
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	fontWeight: token('font.weight.medium'),
	color: token('color.text.subtle'),
	minHeight: token('space.300'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	maxHeight: `${token('space.300')} !important`,
	gap: token('space.050'),
	whiteSpace: 'nowrap',
	paddingTop: token('space.050'),
	paddingRight: token('space.300'),
	paddingBottom: token('space.050'),
	paddingLeft: token('space.300'),
});

const fullWidthBannerWrapperStyles = xcss({
	paddingTop: 'space.150',
	paddingBottom: 'space.150',
	paddingRight: 'space.0',
	paddingLeft: 'space.0',
});

const partialWidthBannerWrapperStyles = xcss({
	paddingTop: 'space.150',
	paddingBottom: 'space.150',
	paddingRight: 'space.500',
	paddingLeft: 'space.500',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BannerLink = styled.a({
	color: token('color.text.subtle'),
	textDecoration: 'underline',
	cursor: 'pointer',
	'&:hover': {
		color: token('color.text.subtle'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BannerText = styled.div({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});
