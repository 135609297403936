import type { IssueViewRealtimeEventType } from '@atlassian/jira-issue-shared-types/src/common/types/realtime-support.tsx';
import type { IssueId, ProjectId, AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';

export type RealtimeRefreshEventDetails = {
	updateType: IssueViewRealtimeEventType;
	atlassianId: AccountId;
	issueId: IssueId;
	projectId: ProjectId;
};

export const Reason = {
	/**
	 * Aka hard refresh, used by Ecosystem apps to refresh issue view on demand
	 * 1) Full issue view refresh
	 * 2) Hard Ecosystem apps refresh (causes remounting of the apps)
	 */
	OnDemand: 'ON_DEMAND',
	/**
	 * Used by Jira internally to update issue view on demand without forcing Ecosystem apps to rerender
	 * 1) Full issue view refresh only
	 * 2) Ecosystem app aren't hard refreshed, however, if field's value has changed, Forge custom field will rerender
	 */
	SoftRefresh: 'SOFT_REFRESH',
	/**
	 * Issue view refresh caused by Realtime service (Jirt)
	 */
	Realtime: 'REALTIME',
} as const;

export type Reason = (typeof Reason)[keyof typeof Reason];

export type SetEventPayload =
	| { reason: typeof Reason.OnDemand; refreshed: boolean }
	| { reason: typeof Reason.SoftRefresh; refreshed: boolean }
	| { reason: typeof Reason.Realtime; refreshed: boolean; details: RealtimeRefreshEventDetails };

export interface RefreshEvent {
	timestamp: number;
	refreshed: boolean;
	reported: boolean;
}

export interface RealtimeRefreshEvent extends RefreshEvent {
	details: RealtimeRefreshEventDetails;
}

export type State = {
	events: {
		[Reason.OnDemand]?: RefreshEvent;
		[Reason.SoftRefresh]?: RefreshEvent;
		[Reason.Realtime]?: RealtimeRefreshEvent;
	};
};

export type StoreApi = StoreActionApi<State>;

export type IssueRefreshServiceActions = {
	setEvent: (payload: SetEventPayload) => void;
};
