import { toRankCustomFieldId } from '@atlassian/jira-polaris-domain-field/src/field-types/rank/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { getIssueIdsConsideringArchived } from '../../selectors/filters.tsx';
import {
	getSortedUnfilteredIssueIds,
	getSortedUnfilteredIssueKeys,
} from '../../selectors/sort.tsx';
import type { State, Props } from '../../types.tsx';

const updateRankOnBackend = ({ getState }: StoreActionApi<State>, props: Props) => {
	const keys = getSortedUnfilteredIssueKeys(getState(), props);
	if (keys.length <= 1) {
		return;
	}
	props.issuesRemote
		.rankIssues({
			issueKeys: keys,
			rankBeforeIssueKey: keys[0],
			rankAfterIssueKey: undefined,
			rankCustomFieldId:
				props.rankField !== undefined ? toRankCustomFieldId(props.rankField) : undefined,
		})
		.catch(props.onIssueUpdateFailed);
};

const updateStateRanking = ({ setState, getState }: StoreActionApi<State>, props: Props) => {
	const sortedIssueIds = getSortedUnfilteredIssueIds(getState(), props);
	const issueIdsConsideringArchived = getIssueIdsConsideringArchived(getState(), {
		...props,
		containsArchived: !props.containsArchived,
	});

	if (sortedIssueIds.length <= 1) {
		return;
	}
	setState({
		ids: [...sortedIssueIds, ...issueIdsConsideringArchived],
	});
};

export const rankIdeasWithCurrentSorting =
	() => (storeActionApi: StoreActionApi<State>, props: Props) => {
		const { isRankingEnabled } = props;

		if (!isRankingEnabled) {
			return;
		}

		updateRankOnBackend(storeActionApi, props);
		updateStateRanking(storeActionApi, props);
	};
