import { useCallback, useMemo, type ReactNode } from 'react';
import { useFragment, graphql } from 'react-relay';
import { defaultTimeTrackingConfiguration } from '@atlassian/jira-common-constants/src/jira-settings.tsx';
import { isAggJiraProjectClassic } from '@atlassian/jira-issue-agg-field-transformers/src/common/utils/project-field-transformer.tsx';
import type {
	DefaultUnit,
	Format,
} from '@atlassian/jira-issue-shared-types/src/common/types/jira-settings-type.tsx';
import type { TimeTrackingState } from '@atlassian/jira-issue-shared-types/src/common/types/time-tracking-type.tsx';
import { addTimeTrackingValues } from '@atlassian/jira-issue-view-store/src/common/state/selectors/time-tracking-selector.tsx';
import type {
	ui_issueViewLayoutTimeTrackingUtils_IssueViewTimeTrackingController$key,
	ui_issueViewLayoutTimeTrackingUtils_IssueViewTimeTrackingController$data,
} from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutTimeTrackingUtils_IssueViewTimeTrackingController.graphql';

export function getTimeTrackingConfiguration(
	data: ui_issueViewLayoutTimeTrackingUtils_IssueViewTimeTrackingController$data,
) {
	return {
		isTimeTrackingEnabled:
			data?.timeTrackingSettings?.isJiraConfiguredTimeTrackingEnabled ??
			defaultTimeTrackingConfiguration.isTimeTrackingEnabled,
		hoursPerDay:
			data?.timeTrackingSettings?.workingHoursPerDay ??
			defaultTimeTrackingConfiguration.hoursPerDay,
		daysPerWeek:
			data?.timeTrackingSettings?.workingDaysPerWeek ??
			defaultTimeTrackingConfiguration.daysPerWeek,
		format: data?.timeTrackingSettings?.defaultFormat
			? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				(data?.timeTrackingSettings?.defaultFormat.toLowerCase() as Format)
			: defaultTimeTrackingConfiguration.format, // NOTE: The original gira transformer returns undefined here
		defaultUnit: data?.timeTrackingSettings?.defaultUnit
			? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				(data?.timeTrackingSettings?.defaultUnit.toLowerCase() as DefaultUnit)
			: defaultTimeTrackingConfiguration.defaultUnit,
	};
}

type IssueViewTimeTrackingControllerChildrenProps = {
	config: ReturnType<typeof getTimeTrackingConfiguration>;
	isDone: boolean;
	isClassicProject: boolean;
	shouldDisplayRollUpDataControl: boolean;
	getTimeTrackingValue: (isRollingUpData?: boolean) => TimeTrackingState;
	rolledUpTimeTrackingValue: TimeTrackingState;
};

type IssueViewTimeTrackingControllerProps = {
	fragmentKey: ui_issueViewLayoutTimeTrackingUtils_IssueViewTimeTrackingController$key;
	children: (props: IssueViewTimeTrackingControllerChildrenProps) => ReactNode;
};

export function IssueViewTimeTrackingController(props: IssueViewTimeTrackingControllerProps) {
	const data = useFragment<ui_issueViewLayoutTimeTrackingUtils_IssueViewTimeTrackingController$key>(
		graphql`
			fragment ui_issueViewLayoutTimeTrackingUtils_IssueViewTimeTrackingController on JiraTimeTrackingField {
				issue @required(action: NONE) {
					statusField {
						status {
							statusCategory {
								statusCategoryId
							}
						}
					}

					projectField {
						project {
							projectStyle
						}
					}

					childIssues {
						... on JiraChildIssuesExceedingLimit {
							search
						}
						... on JiraChildIssuesWithinLimit {
							issues {
								edges {
									node {
										timeTrackingField {
											originalEstimate {
												timeInSeconds
											}
											timeSpent {
												timeInSeconds
											}
											remainingEstimate {
												timeInSeconds
											}
										}
									}
								}
							}
						}
					}
				}

				originalEstimate {
					timeInSeconds
				}
				timeSpent {
					timeInSeconds
				}
				remainingEstimate {
					timeInSeconds
				}
				timeTrackingSettings {
					isJiraConfiguredTimeTrackingEnabled
					workingDaysPerWeek
					workingHoursPerDay
					defaultUnit
					defaultFormat
				}
			}
		`,
		props.fragmentKey,
	);

	const childIssues = useMemo(
		() => data?.issue?.childIssues?.issues?.edges ?? [],
		[data?.issue?.childIssues?.issues?.edges],
	);
	const hasChildLimitUrl = useMemo(
		() => Boolean(data?.issue.childIssues?.search),
		[data?.issue.childIssues?.search],
	);
	const hasChildIssues = useMemo(
		() => childIssues.length > 0 || hasChildLimitUrl,
		[childIssues.length, hasChildLimitUrl],
	);

	const isClassicProject = isAggJiraProjectClassic({
		projectStyle: data?.issue?.projectField?.project?.projectStyle,
	});

	const timeTrackingWithSubtasksOrChildrenRollup = useCallback(
		(isRollingUpData?: boolean) => {
			const currentIssueTimeTrackingData: TimeTrackingState = {
				originalEstimateSeconds: data?.originalEstimate?.timeInSeconds ?? undefined,
				timeSpentSeconds: data?.timeSpent?.timeInSeconds ?? undefined,
				remainingEstimateSeconds: data?.remainingEstimate?.timeInSeconds ?? undefined,
			};

			// base case: we return just the current issue's time tracking data whenever the issue
			// has no subtasks or the respective UI option is unchecked
			if (!childIssues || childIssues.length === 0 || !isRollingUpData) {
				return currentIssueTimeTrackingData || {};
			}

			return childIssues
				.map((subIssue) => subIssue?.node?.timeTrackingField)
				.filter(Boolean)
				.reduce<TimeTrackingState>(
					(accumulator, current) => ({
						originalEstimateSeconds: addTimeTrackingValues(
							accumulator.originalEstimateSeconds,
							current?.originalEstimate?.timeInSeconds ?? undefined,
						),
						timeSpentSeconds: addTimeTrackingValues(
							accumulator.timeSpentSeconds,
							current?.timeSpent?.timeInSeconds ?? undefined,
						),
						remainingEstimateSeconds: addTimeTrackingValues(
							accumulator.remainingEstimateSeconds,
							current?.remainingEstimate?.timeInSeconds ?? undefined,
						),
					}),
					currentIssueTimeTrackingData || {},
				);
		},
		[
			childIssues,
			data?.originalEstimate?.timeInSeconds,
			data?.remainingEstimate?.timeInSeconds,
			data?.timeSpent?.timeInSeconds,
		],
	);

	const rolledUpTimeTrackingValue = useMemo(
		() => timeTrackingWithSubtasksOrChildrenRollup(true),
		[timeTrackingWithSubtasksOrChildrenRollup],
	);

	const doChildrenHaveTimeTrackingConfigured = useMemo(() => {
		// Time tracking data is always available on classic subtasks even if the Time tracking field isn't configured
		if (isClassicProject) {
			return true;
		}

		if (hasChildLimitUrl) {
			return true;
		}

		if (!childIssues || childIssues.length === 0) {
			return false;
		}

		const childIssuesTimeTrackingValues = childIssues
			.map((subIssue) => subIssue?.node?.timeTrackingField)
			.filter(Boolean);

		return childIssuesTimeTrackingValues.length > 0;
	}, [childIssues, hasChildLimitUrl, isClassicProject]);

	const shouldDisplayRollUpDataControl = useMemo(() => {
		return hasChildIssues && doChildrenHaveTimeTrackingConfigured;
	}, [doChildrenHaveTimeTrackingConfigured, hasChildIssues]);

	return props.children({
		config: getTimeTrackingConfiguration(data),
		isDone: data?.issue?.statusField?.status?.statusCategory?.statusCategoryId === '3',
		getTimeTrackingValue: timeTrackingWithSubtasksOrChildrenRollup,
		rolledUpTimeTrackingValue,
		isClassicProject,
		shouldDisplayRollUpDataControl,
	});
}
