import { Journeys, Targets, type CrossFlowContextType } from '@atlassiansox/cross-flow-support';

export const openCffeForConfluence = async (
	crossFlow: CrossFlowContextType,
	sourceComponent: string,
	sourceContext: string,
	experimentalOptions: {},
) => {
	if (crossFlow.isEnabled) {
		await crossFlow.api.open({
			targetProduct: Targets.CONFLUENCE,
			journey: Journeys.GET_STARTED,
			sourceComponent,
			sourceContext,
			experimentalOptions,
		});
	}
};
