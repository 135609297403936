/* eslint-disable @atlassian/relay/graphql-naming */
import { useCallback, useMemo } from 'react';
import { useMutation } from 'react-relay';
import { graphql, readInlineData } from 'relay-runtime';
import { ISSUE_TYPE_HIERARCHY_LEVEL } from '@atlassian/jira-issue-agg-field-transformers/src/common/constants.tsx';
import type { Color } from '@atlassian/jira-issue-epic-color-types/src/types.tsx';
import type { AggJiraParent } from '@atlassian/jira-issue-field-parent-inline-edit-full/src/ui/parent/types.tsx';
import type { ParentFieldOptimisticResponse } from '@atlassian/jira-issue-field-parent-inline-edit-full/src/ui/parent/utils.tsx';
import type { IssueParent } from '@atlassian/jira-issue-parent-services/src/services/types.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/index.tsx';
import type {
	issueParentLegacyAndAgg_inlineData$key,
	issueParentLegacyAndAgg_inlineData$data,
} from '@atlassian/jira-relay/src/__generated__/issueParentLegacyAndAgg_inlineData.graphql';
import type { issueParentLegacyAndAgg_inlineData_Mutation as ParentMutation } from '@atlassian/jira-relay/src/__generated__/issueParentLegacyAndAgg_inlineData_Mutation.graphql';

type Parents =
	| {
			readonly edges:
				| readonly (
						| {
								readonly node: issueParentLegacyAndAgg_inlineData$key | null | undefined;
						  }
						| null
						| undefined
				  )[]
				| null
				| undefined;
	  }
	| null
	| undefined;

export const parentCandidatesForExistingIssueLegacyAndAgg = (parents: Parents) => {
	return (
		parents?.edges
			?.filter(
				(edge): edge is { node: NonNullable<NonNullable<typeof edge>['node']> } =>
					edge !== null && edge?.node !== null && edge?.node !== undefined,
			)
			?.map((edge) => ({ node: parentCandidatesForExistingIssueNodeLegacyAndAgg(edge.node) })) || []
	);
};

export const parentCandidatesForExistingIssueNodeLegacyAndAgg = (
	parentIssueField: issueParentLegacyAndAgg_inlineData$key,
) => {
	const node = readInlineData<issueParentLegacyAndAgg_inlineData$key>(
		graphql`
			fragment issueParentLegacyAndAgg_inlineData on JiraIssue @inline {
				id
				issueId
				key
				webUrl
				summaryField {
					id
					text
				}
				statusField {
					status {
						statusCategory {
							statusCategoryId
						}
					}
				}
				issueColorField {
					id
					color {
						id
						colorKey
					}
				}
				issueTypeField {
					id
					issueType {
						id
						description
						issueTypeId
						name
						hierarchy {
							level
						}
						avatar {
							medium
							xsmall
						}
					}
				}
			}
		`,
		parentIssueField,
	);

	const legacy: IssueParent = {
		id: node?.issueId || '',
		key: node?.key || '',
		summary: node?.summaryField?.text || '',
		issueType: {
			id: node?.issueTypeField?.issueType?.issueTypeId || '',
			iconUrl: node?.issueTypeField?.issueType?.avatar?.medium || '',
			name: node?.issueTypeField?.issueType?.name,
			description: node?.issueTypeField?.issueType?.description || '',
			hierarchyLevel: node?.issueTypeField?.issueType?.hierarchy?.level || undefined,
			subtask:
				node?.issueTypeField?.issueType?.hierarchy?.level ===
				ISSUE_TYPE_HIERARCHY_LEVEL.SUBTASK_LEVEL,
		},
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		color: (node?.issueColorField?.color?.colorKey as Color) || undefined,
		isDone: node?.statusField?.status?.statusCategory?.statusCategoryId === '3',
		statusCategoryId: node?.statusField?.status?.statusCategory?.statusCategoryId,
	};

	return {
		agg: node,
		legacy,
	};
};

type AggParent = issueParentLegacyAndAgg_inlineData$data;

type ParentField = {
	id: string;
	fieldId: string;
	type: string;
	name: string;
};

export const transformParentCandidateToOptimisticResponse = (
	parentField: ParentField,
	newParent: AggParent | null | undefined,
): ParentFieldOptimisticResponse => {
	return {
		id: parentField?.id,
		fieldId: parentField?.fieldId,
		type: parentField?.type,
		name: parentField?.name,
		parentIssue: newParent
			? {
					id: newParent?.id,
					issueId: newParent?.issueId,
					webUrl: newParent?.webUrl,
					key: newParent?.key,
					issueColorField: newParent?.issueColorField,
					summaryField: {
						id: newParent?.summaryField?.id || '',
						text: newParent?.summaryField?.text || '',
					},
					issueTypeField: {
						id: newParent?.issueTypeField?.id || '',
						issueType: {
							id: newParent?.issueTypeField?.issueType?.id || '',
							avatar: {
								xsmall: newParent?.issueTypeField?.issueType?.avatar?.xsmall || '',
							},
							name: newParent?.issueTypeField?.issueType?.name || '',
						},
					},
					fieldsById: {
						edges: [],
					},
				}
			: null,
	};
};

export const useParentFieldUpdate = () => {
	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();
	const onSubmit = useCallback(
		(issueId: string, parentField: ParentField, value: AggJiraParent | null) => {
			fieldChangeRequested(issueId, parentField.fieldId, value, undefined, {
				type: parentField.type,
				__typename: 'JiraParentIssueField',
			});
		},
		[fieldChangeRequested],
	);

	const onSubmitSucceeded = useCallback(
		(issueId: string, parentField: ParentField, value: AggJiraParent | null) => {
			issueId &&
				parentField &&
				fieldChanged(issueId, parentField.fieldId, value, {
					type: parentField.type,
					__typename: 'JiraParentIssueField',
				});
		},
		[fieldChanged],
	);

	const onSubmitFailed = useCallback(
		(issueId: string, parentField: ParentField) =>
			issueId && parentField && fieldChangeFailed(issueId, parentField.fieldId),
		[fieldChangeFailed],
	);
	const [commit] = useMutation<ParentMutation>(graphql`
		mutation issueParentLegacyAndAgg_inlineData_Mutation($input: JiraUpdateParentFieldInput!) {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateParentField(input: $input) {
					success
					errors {
						message
					}
					field {
						...parent_issueFieldParentInlineEditFull_ParentInlineEditViewIsEditable_fragmentRef
					}
				}
			}
		}
	`);
	const onUpdate = useCallback(
		async (issueId: string, parentField: ParentField, newParent: AggParent | null) => {
			if (!parentField?.id) {
				return;
			}
			onSubmit(issueId, parentField, newParent);
			return commit({
				variables: {
					input: {
						id: parentField.id,
						operation: {
							operation: 'SET',
							id: newParent?.id || null,
						},
					},
				},
				onCompleted: (mutationData) => {
					if (mutationData.jira?.updateParentField?.success) {
						onSubmitSucceeded(issueId, parentField, newParent);
					} else {
						onSubmitFailed(issueId, parentField);
					}
				},
				onError() {
					onSubmitFailed(issueId, parentField);
				},
				optimisticResponse: {
					jira: {
						updateParentField: {
							success: true,
							errors: [],
							field: transformParentCandidateToOptimisticResponse(
								{
									id: parentField.id,
									fieldId: parentField.fieldId,
									type: parentField.type,
									name: parentField.name,
								},
								newParent,
							),
						},
					},
				},
			});
		},
		[commit, onSubmit, onSubmitFailed, onSubmitSucceeded],
	);

	return useMemo(() => ({ onUpdate }), [onUpdate]);
};
