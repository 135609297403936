import React, { type ReactNode } from 'react';

import { styled } from '@compiled/react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import { useIssueAttachments } from '@atlassian/jira-issue-attachments-base/src/services/attachments-service/main.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import AttachmentsContent from '../content/index.tsx';
import AttachmentsHeading from '../heading/index.tsx';
import ExceedWarning from './exceed-warning/index.tsx';

const ContextualAnalytics = ({ children }: { children?: ReactNode }) => {
	if (fg('adding-more-context-for-drop-events')) {
		return (
			<ContextualAnalyticsData sourceType={SCREEN} sourceName="attachments-field">
				{children}
			</ContextualAnalyticsData>
		);
	}
	return children;
};

export const Attachments = () => {
	const issueKey = useIssueKey();
	const [
		{
			error,
			value: { totalCount },
		},
		{ refreshAttachments },
	] = useIssueAttachments(issueKey);
	const showAttachmentPanel = totalCount > 0;

	if (!showAttachmentPanel) {
		return null;
	}

	return (
		<ContextualAnalytics>
			<UFOSegment name="issue-attachments">
				<PanelWrapper
					/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
					data-testid="issue.views.issue-base.content.attachment.panel"
				>
					<AttachmentsHeading totalCount={totalCount} refreshAttachments={refreshAttachments} />
					<ExceedWarning totalCount={totalCount} />
					<AttachmentsContent
						attachmentError={error}
						totalCount={totalCount}
						refreshAttachments={refreshAttachments}
					/>
				</PanelWrapper>
			</UFOSegment>
		</ContextualAnalytics>
	);
};

const AttachmentsWithMonitoring = () => (
	<ErrorBoundary id="issue.base.content.attachments-panel">
		<ReportErrors
			id="base.content.attachments-panel"
			packageName="jiraIssueView"
			sendToPrivacyUnsafeSplunk
		>
			<Attachments />
		</ReportErrors>
	</ErrorBoundary>
);

export default AttachmentsWithMonitoring;

// Max-width has been added to avoid broken images in the carousel for clients with a huge widescreen
// 1900px was set based on the maximum number of fetched items - 12 items.

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PanelWrapper = styled.div({
	maxWidth: '1900px',
});
