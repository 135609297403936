import React, {
	type ReactNode,
	type MouseEvent,
	type ComponentType,
	type RefObject,
	type KeyboardEvent,
} from 'react';
import { IconButton } from '@atlaskit/button/new';
import DeleteIcon from '@atlaskit/icon/core/delete';
import EditIcon from '@atlaskit/icon/core/edit';
import { Flex, Inline, xcss, Box } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import messages from './messages.tsx';

export type InlineConfigButtonsProps<QueryParams> = {
	children: ReactNode;
	source?: string;
	editActionSubjectId?: string;
	deleteActionSubjectId?: string;
	showEditButton?: boolean;
	showDeleteButton?: boolean;
	isFocused: boolean;
	editLabelText?: string;
	deleteLabelText?: string;
	queryParams: QueryParams;
	onEditButtonClick?: (ev: MouseEvent<HTMLElement>) => void;
	onDeleteButtonClick?: (ev: MouseEvent<HTMLElement>) => void;
	// Remove this prop when cleaning up inline_config_a11y_improvements
	shouldStopPropagationOnClick?: boolean;
	// Remove this prop when cleaning up inline_config_a11y_improvements
	buttonRef?: RefObject<HTMLElement> | null;
	EditButtonWrapper?: ComponentType<{
		children: ReactNode;
		queryParams: QueryParams;
		onClick?: (ev: MouseEvent<HTMLElement>) => void;
	}>;
	DeleteButtonWrapper?: ComponentType<{
		children: ReactNode;
		queryParams: QueryParams;
	}>;
};

export const InlineConfigButtons = <QueryParams extends {}>({
	children,
	source,
	editActionSubjectId,
	deleteActionSubjectId,
	showEditButton,
	showDeleteButton,
	isFocused,
	EditButtonWrapper,
	DeleteButtonWrapper,
	queryParams,
	editLabelText,
	deleteLabelText,
	onEditButtonClick,
	onDeleteButtonClick,
	shouldStopPropagationOnClick = false,
	buttonRef = null,
}: InlineConfigButtonsProps<QueryParams>) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onFireRequiredAnalyticsForEditButtonClick = () => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			}),
			editActionSubjectId ?? '',
			{
				source,
			},
		);
	};

	const onFireRequiredAnalyticsForDeleteButtonClick = () => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			}),
			deleteActionSubjectId ?? '',
			{
				source,
			},
		);
	};

	const handleOnEditButtonMouseDown = (e: MouseEvent | React.MouseEvent) => {
		// fire event on left click only
		if (e.button === 0) {
			onFireRequiredAnalyticsForEditButtonClick();
		}
	};

	const handleOnDeleteButtonMouseDown = (e: MouseEvent | React.MouseEvent) => {
		// fire event on left click only
		if (e.button === 0) {
			onFireRequiredAnalyticsForDeleteButtonClick();
		}
	};

	const handleOnEditButtonKeyDown = (e: KeyboardEvent | React.KeyboardEvent) => {
		if (e.code === 'Enter' || e.code === 'Space') {
			// Always stop propagation when cleaning up inline_config_a11y_improvements
			shouldStopPropagationOnClick && e.stopPropagation();
			// Remove this line when cleaning up inline_config_a11y_improvements
			buttonRef?.current?.click();
			onFireRequiredAnalyticsForEditButtonClick();
		}
	};

	const handleOnDeleteButtonKeyDown = (e: KeyboardEvent | React.KeyboardEvent) => {
		if (e.code === 'Enter' || e.code === 'Space') {
			shouldStopPropagationOnClick && e.stopPropagation();
			onFireRequiredAnalyticsForDeleteButtonClick();
		}
	};

	const handleOnEditButtonClick = (e: MouseEvent<HTMLElement>) => {
		shouldStopPropagationOnClick && e.stopPropagation();
		onEditButtonClick?.(e);
	};

	const handleOnDeleteButtonClick = (e: MouseEvent<HTMLElement>) => {
		shouldStopPropagationOnClick && e.stopPropagation();
		onDeleteButtonClick?.(e);
	};

	const renderButtons = isFocused || fg('jira_inline_config_buttons_styling_update');

	const renderEditButton = () => {
		const iconButton = (
			<IconButton
				tabIndex={isFocused ? 0 : -1}
				isDisabled={!isFocused}
				appearance="subtle"
				onClick={handleOnEditButtonClick}
				// onClick is not working well with Select component and ModalEntryPointPressableTrigger
				// Therefore, we need to handle event analytics using mouseDown, touchEnd and keyDown
				onMouseDown={handleOnEditButtonMouseDown}
				onTouchEnd={onFireRequiredAnalyticsForEditButtonClick}
				onKeyDown={handleOnEditButtonKeyDown}
				icon={EditIcon}
				label={editLabelText ?? formatMessage(messages.editButtonText)}
				spacing={fg('inline_config_edit_button_styling_change') ? 'compact' : undefined}
			/>
		);
		if (fg('inline_config_edit_button_styling_change')) {
			return <Box xcss={additionalMarginStyles}>{iconButton}</Box>;
		}
		return iconButton;
	};

	return (
		<Flex direction="row" columnGap="space.050">
			<Inline
				xcss={
					fg('jira_inline_config_buttons_styling_update') ? leftColumnStyleNew : leftColumnStyleOld
				}
			>
				{children}
			</Inline>
			{renderButtons && (
				<Inline
					xcss={
						// eslint-disable-next-line no-nested-ternary
						fg('jira_inline_config_buttons_styling_update')
							? isFocused
								? rightColumnStyleVisible
								: rightColumnStyleHidden
							: rightColumnStyleOld
					}
				>
					<Flex direction="row" columnGap="space.050">
						{showEditButton && EditButtonWrapper && (
							<EditButtonWrapper queryParams={queryParams}>
								<Tooltip content={editLabelText ?? formatMessage(messages.editButtonText)}>
									{renderEditButton()}
								</Tooltip>
							</EditButtonWrapper>
						)}
						{showDeleteButton && DeleteButtonWrapper && (
							<DeleteButtonWrapper queryParams={queryParams}>
								<Tooltip content={deleteLabelText ?? formatMessage(messages.deleteButtonText)}>
									<IconButton
										isDisabled={!isFocused}
										tabIndex={isFocused ? 0 : -1}
										appearance="subtle"
										onClick={handleOnDeleteButtonClick}
										// onClick is not working well with Select component and ModalEntryPointPressableTrigger
										// Therefore, we need to handle event analytics using mouseDown, touchEnd and keyDown
										onMouseDown={handleOnDeleteButtonMouseDown}
										onTouchEnd={onFireRequiredAnalyticsForDeleteButtonClick}
										onKeyDown={handleOnDeleteButtonKeyDown}
										icon={DeleteIcon}
										label={deleteLabelText ?? formatMessage(messages.deleteButtonText)}
									/>
								</Tooltip>
							</DeleteButtonWrapper>
						)}
					</Flex>
				</Inline>
			)}
		</Flex>
	);
};

const leftColumnStyleOld = xcss({
	wordBreak: 'break-word',
	flex: 1,
});

const leftColumnStyleNew = xcss({
	wordBreak: 'break-word',
	flex: 1,
	alignItems: 'center',
});

const rightColumnStyleOld = xcss({
	width: 'auto',
	height: 'auto',
	marginTop: 'space.negative.075',
	marginBottom: 'space.negative.075',
	marginRight: 'space.negative.075',
});

const rightColumnStyleVisible = xcss({
	width: 'auto',
	height: 'auto',
});

const rightColumnStyleHidden = xcss({
	width: 'auto',
	height: 'auto',
	opacity: 0,
});

const additionalMarginStyles = xcss({
	margin: 'space.050',
});
