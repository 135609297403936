import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	beta: {
		id: 'ai-related-resources.beta',
		defaultMessage: 'BETA',
		description: 'Lozenge to indicate that feature is in beta phase',
	},
	suggestConfluencePages: {
		id: 'ai-related-resources.suggest-confluence-pages',
		defaultMessage: 'Suggest Confluence pages',
		description: 'Prompt indicating that the AI is suggesting confluence pages',
	},
	suggestConfluenceContent: {
		id: 'ai-related-resources.suggest-confluence-content',
		defaultMessage: 'Suggest Confluence content',
		description: 'Prompt indicating that the AI is suggesting confluence content',
	},
	discardSuggestions: {
		id: 'ai-related-resources.discard-suggestions',
		defaultMessage: 'Discard and close',
		description: 'Message to indicate that the button can initiate an action to descard suggestion',
	},
	relatedResourcesHeading: {
		id: 'ai-related-resources.related-resources-heading',
		defaultMessage: 'Related Resources',
		description: 'Title for Related resources',
	},
	generatingPageSuggestions: {
		id: 'ai-related-resources.generating-page-suggestions',
		defaultMessage: 'Generating page suggestions',
		description:
			'Text shown when AI is generating suggestions for pages related to the jira work item',
	},
	generatingContentSuggestions: {
		id: 'ai-related-resources.generating-content-suggestions',
		defaultMessage: 'Generating content suggestions',
		description:
			'Text shown when AI is generating suggestions for content related to the jira work item',
	},
	pageSuggestionsEmptyHeader: {
		id: 'ai-related-resources.page-suggestions-empty-header-non-final',
		defaultMessage: 'We can’t make suggestions for this work item',
		description: 'Message header shown when AI cannot find pages relevant to the work item',
	},
	pageSuggestionsEmptyInfo: {
		id: 'ai-related-resources.page-suggestions-empty-non-final',
		defaultMessage: 'Try rewriting the work item description, then try again.',
		description: 'Message info shown when AI cannot find pages relevant to the work item',
	},
	pageSuggestionsCouldNotBeGenerated: {
		id: 'ai-related-resources.page-suggestions-could-not-be-generated-non-final',
		defaultMessage:
			'Atlassian Intelligence isn’t responding. Try again later or <anchor>check the status of Atlassian Intelligence.</anchor>',
		description: 'Message shown when AI cannot suggest pages relevant to the work item',
	},
	findingRelatedConfluenceContent: {
		id: 'ai-related-resources.finding-related-confluence-content',
		defaultMessage: 'Finding related Confluence content',
		description: 'Message shown when AI is generating suggestions for related Confluence content',
	},
	linkConfluenceContent: {
		id: 'ai-related-resources.link-confluence-content',
		defaultMessage: 'Link Confluence content',
		description: 'Message shown when AI is suggesting Confluence content to the work item',
	},
	atlassianIntelligenceNotResponding: {
		id: 'ai-related-resources.atlassian-intelligence-not-responding',
		defaultMessage: 'Atlassian Intelligence (AI) isn’t responding. Try again later.',
		description: 'Message shown when AI is not responding',
	},
	pageSuggestionsEmptyHeaderReviewed: {
		id: 'ai-related-resources.page-suggestions-empty-header-reviewed',
		defaultMessage: 'We can’t find related Confluence content',
		description: 'Message header shown when AI cannot find confluence relevant to the work item',
	},
	pageSuggestionsEmptyInfoReviewed: {
		id: 'ai-related-resources.page-suggestions-empty-info-reviewed',
		defaultMessage: 'Try rewriting the issue description, then try again.',
		description: 'Message info shown when AI cannot find confluence relevant to the work item',
	},
});
