import React, { Component, type ReactNode, type ChangeEvent } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import { ErrorMessage, Label } from '@atlaskit/form';
import Popup from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { Box, Text, xcss } from '@atlaskit/primitives';
import TextField from '@atlaskit/textfield';

import { token } from '@atlaskit/tokens';
import Pressable from '@atlaskit/primitives/pressable';
import ErrorIcon from '@atlaskit/icon/utility/migration/error';
import { fg } from '@atlassian/jira-feature-gating';
import { layers, gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import InfoIcon from '@atlassian/jira-issue-field-description/src/ui/assets/info-icon.tsx';
import {
	FieldWrapper,
	CustomFieldHeadingWithBottomMargin,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';

type Props = {
	isInvalid: boolean;
	isDisabled: boolean;
	isRequired: boolean;
	value: string;
	autoFocus: boolean;
	label: string;
	triggerBtnAriaLabel?: string;
	name: string;
	description?: string | null;
	invalidMessage: ReactNode;
	onChange: (arg1: string) => void;
	onBlur: () => void;
};

const informationIconStyles = xcss({
	background: 'none',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: 'inherit',
	padding: '0',
	marginLeft: 'space.050',
	marginBottom: 'space.050',
	verticalAlign: 'baseline',
});

const labelStyles = xcss({
	fontWeight: token('font.weight.medium'),
	verticalAlign: 'bottom',
	marginBottom: '0',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '1.3333333333333333',
});

// eslint-disable-next-line jira/react/no-class-components
export default class TimeLogTextField extends Component<Props> {
	static defaultProps = {
		isInvalid: false,
		isRequired: false,
		isDisabled: false,
		autoFocus: false,
		name: 'text-field',
		onBlur: noop,
	};

	state = {
		shouldShowDetails: false,
	};

	onToggle = () =>
		this.setState({
			shouldShowDetails: !this.state.shouldShowDetails,
		});

	onChange = (event: ChangeEvent<HTMLInputElement>) => {
		this.props.onChange(event.target.value);
	};

	render() {
		const TextfieldAriaDescribedby = this.props.isInvalid
			? 'timelog-textfield-error-message time-tracking-formatting-text'
			: 'time-tracking-formatting-text';

		return (
			<FieldWrapper>
				<CustomFieldHeadingWithBottomMargin>
					<Box as="span" xcss={labelStyles}>
						<Label htmlFor={`timelog-textfield-${this.props.label}`}>{this.props.label}</Label>
					</Box>
					{this.props.description && (
						<Popup
							testId="issue.component.log-time-modal.modal.time-log-text-field.ak-popup"
							zIndex={layers.modal}
							onClose={() => {
								this.setState({ shouldShowDetails: false });
							}}
							placement="right-start"
							content={() => (
								<Container>
									<Text as="p">{this.props.description}</Text>
								</Container>
							)}
							isOpen={this.state.shouldShowDetails}
							shouldRenderToParent={fg('jfp-a11y-team_fix_logwork_modal_a11y_issues')}
							trigger={(triggerProps) => (
								<Pressable
									{...triggerProps}
									onClick={this.onToggle}
									aria-pressed={this.state.shouldShowDetails}
									xcss={informationIconStyles}
									aria-label={this.props.triggerBtnAriaLabel}
								>
									<InfoIcon />
								</Pressable>
							)}
						/>
					)}
				</CustomFieldHeadingWithBottomMargin>
				{fg('jfp-a11y-team-issue-log-work-modal-error-message') ? (
					<>
						<TextField
							autoFocus={this.props.autoFocus}
							isInvalid={this.props.isInvalid}
							isDisabled={this.props.isDisabled}
							isRequired={this.props.isRequired}
							onChange={this.onChange}
							value={this.props.value}
							onBlur={this.props.onBlur}
							aria-label={this.props.label}
							aria-describedby={TextfieldAriaDescribedby}
							id={`timelog-textfield-${this.props.label}`}
							data-testId={`timelog-textfield-${this.props.label}`}
						/>
						{this.props.isInvalid && (
							<Box id="timelog-textfield-error-message" role="alert" xcss={messageStyles}>
								<Box as="span" xcss={iconWrapperStyles}>
									<ErrorIcon
										LEGACY_margin="0 -2px 0 0"
										color="currentColor"
										LEGACY_size="small"
										label=""
									/>
								</Box>
								{this.props.invalidMessage}
							</Box>
						)}
					</>
				) : (
					<>
						<TextField
							autoFocus={this.props.autoFocus}
							isInvalid={this.props.isInvalid}
							isDisabled={this.props.isDisabled}
							isRequired={this.props.isRequired}
							onChange={this.onChange}
							value={this.props.value}
							onBlur={this.props.onBlur}
							aria-label={this.props.label}
							aria-describedby="time-tracking-formatting-text"
							id={`timelog-textfield-${this.props.label}`}
							data-testId={`timelog-textfield-${this.props.label}`}
						/>
						{this.props.isInvalid && <ErrorMessage>{this.props.invalidMessage}</ErrorMessage>}
					</>
				)}
			</FieldWrapper>
		);
	}
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 32}px`,
	marginLeft: token('space.025'),
	width: 'auto',
	paddingTop: token('space.200'),
	paddingRight: token('space.200'),
	paddingBottom: token('space.200'),
	paddingLeft: token('space.300'),
	overflow: 'auto',
	backgroundColor: token('elevation.surface.raised'),
	boxShadow: token('elevation.shadow.raised'),
});

const messageStyles = xcss({
	display: 'flex',
	justifyContent: 'baseline',
	gap: 'space.075',
	font: 'font.body.UNSAFE_small',
	marginBlockStart: 'space.050',
	color: 'color.text.danger',
});

const iconWrapperStyles = xcss({
	display: 'flex',
	height: 'space.200',
	alignItems: 'center',
});
