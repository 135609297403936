import React, { type FC } from 'react';
import Button from '@atlaskit/button/new';
import { Box, xcss } from '@atlaskit/primitives';
import AtlassianIntelligenceIcon from '@atlaskit/icon/core/atlassian-intelligence';
import { AiIcon } from '@atlassian/jira-atlassian-intelligence/src/common/ui/ai-icon/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useRelatedResourcesActions } from '../../controllers/related-resources-context.tsx';
import messages from '../messages.tsx';
import { useRelatedConfluencePagesTriggerRef } from '../../controllers/entrypoint-params-store.tsx';

type Props = {
	onShowAISuggestionsClicked: () => void;
};

export const SuggestRelatedResourcesButton: FC<Props> = ({ onShowAISuggestionsClicked }) => {
	const { formatMessage } = useIntl();
	const { fetchSuggestions, isRelatedResourcesOpen, isRelatedResourcesFetching } =
		useRelatedResourcesActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const triggerRef = useRelatedConfluencePagesTriggerRef();

	const iconLabel = fg('gravityai-2578-related-resources-copy-updates')
		? formatMessage(messages.linkConfluenceContent)
		: formatMessage(
				fg('confluence_live_pages_updates_jira_issue_view')
					? messages.suggestConfluenceContent
					: messages.suggestConfluencePages,
			);

	return (
		<Box xcss={buttonStyles}>
			<Button
				spacing="compact"
				ref={triggerRef}
				isDisabled={isRelatedResourcesFetching()}
				onClick={() => {
					onShowAISuggestionsClicked();
					fetchSuggestions('suggestConfluencePagesButton', createAnalyticsEvent({}));
				}}
				iconBefore={(iconProps) => {
					return fg('update-work-item-icons-to-common-component') ? (
						<AtlassianIntelligenceIcon color="currentColor" label={iconLabel} />
					) : (
						<AiIcon {...iconProps} label={iconLabel} size="small" />
					);
				}}
				isSelected={!!isRelatedResourcesOpen()}
			>
				{fg('gravityai-2578-related-resources-copy-updates')
					? formatMessage(messages.linkConfluenceContent)
					: formatMessage(
							fg('confluence_live_pages_updates_jira_issue_view')
								? messages.suggestConfluenceContent
								: messages.suggestConfluencePages,
						)}
			</Button>
		</Box>
	);
};

const buttonStyles = xcss({
	marginInlineStart: 'space.075',
});
