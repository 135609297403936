// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore } from '@atlassian/react-sweet-state';
import * as actions from './actions/index.tsx';
import { DEFAULT_ORDER_DIRECTION, DEFAULT_ORDER_FIELD } from './common/constants.tsx';
import type { State } from './types.tsx';

export type Actions = typeof actions;

export const issueAttachmentsStore = createStore<State, Actions>({
	name: 'issue-attachments',
	initialState: {
		issueAttachments: {},
		meta: {
			orderDirection: DEFAULT_ORDER_DIRECTION,
			orderField: DEFAULT_ORDER_FIELD,
			startAt: 0,
		},
	},
	actions,
});
