import { createSelector } from 'reselect';
import pickBy from 'lodash/pickBy';
import find from 'lodash/find';
import { fg } from '@atlassian/jira-feature-gating';
import type { FieldsByKey } from '@atlassian/jira-polaris-domain-field/src/collections/types.tsx';
import {
	FIELD_TYPES_CATEGORIES,
	FIELD_TYPES,
} from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	hasSystemFields,
	isGlobalSystemField,
	isProjectSystemField,
} from '@atlassian/jira-polaris-domain-field/src/field/utils.tsx';
import type { Props, State } from '../types.tsx';

const EMPTY_FIELDS: FieldsByKey = Object.freeze({});

const getAllFields = createSelector(
	(_: State, props?: Props) => props?.fields,
	(fields) => {
		if (!fields) {
			return EMPTY_FIELDS;
		}

		const { hasGlobalSystemFields, hasProjectSystemFields } = hasSystemFields(fields);

		return pickBy(fields, (field) => {
			if (!fg('jpd_platform_goals_field_support') && field.type === FIELD_TYPES.PLATFORM_GOALS) {
				return false;
			}

			if (!fg('jpd_issue_types_ga') && field.type === FIELD_TYPES.ISSUE_TYPE) {
				return false;
			}

			if (hasGlobalSystemFields && hasProjectSystemFields) {
				const isGlobal = isGlobalSystemField(field);
				const isProject = isProjectSystemField(field);

				if (isGlobal && !fg('polaris_show-global-system-fields')) {
					return false;
				}

				if (isProject && fg('polaris_show-global-system-fields')) {
					return false;
				}
			}

			return true;
		});
	},
);

export const getFields = createSelector(getAllFields, (fields) =>
	pickBy(fields, (field) => {
		const isHidden = field.configuration?.hidden;
		const isRestricted = field.hasRestrictedContext;
		return !isHidden && !isRestricted;
	}),
);

const UNSUPPORTED_FIELD_TYPES_OLD: FieldType[] = [
	...FIELD_TYPES_CATEGORIES.ARCHIVED,
	FIELD_TYPES.DESCRIPTION,
	FIELD_TYPES.ISSUE_TYPE,
	FIELD_TYPES.ISSUE_ID,
];

const UNSUPPORTED_FIELD_TYPES: FieldType[] = [
	...FIELD_TYPES_CATEGORIES.ARCHIVED,
	FIELD_TYPES.DESCRIPTION,
	FIELD_TYPES.ISSUE_ID,
];

const getSupportedFields = (fields: FieldsByKey) =>
	pickBy(fields, ({ type }) => {
		const unsupportedTypes = fg('jpd_issue_types_ga')
			? UNSUPPORTED_FIELD_TYPES
			: UNSUPPORTED_FIELD_TYPES_OLD;

		return !unsupportedTypes.some((t) => t === type);
	});

export const getFieldsForViewControls = createSelector(getFields, getSupportedFields);

export const getAllFieldsForViewControls = createSelector(getAllFields, getSupportedFields);

// Get the count of standard and votes fields
export const getAllFieldsForViewControlsCountByType = createSelector<
	State,
	Props | undefined,
	FieldsByKey,
	{ totalFieldsCount: number; votesFieldsCount: number }
>(getAllFieldsForViewControls, (fields) => {
	const count = {
		totalFieldsCount: Object.values(fields).length,
		votesFieldsCount: 0,
	};

	for (const field of Object.values(fields)) {
		if (field.type === FIELD_TYPES.VOTES) {
			count.votesFieldsCount++;
		}
	}

	return count;
});

export const createGetFieldByType = (fieldType: string) =>
	createSelector(getAllFields, (fields) => find(fields, (field) => field.type === fieldType));

export const createGetFieldForFieldKey = (fieldKey?: FieldKey) =>
	createSelector(getFields, (fields) => (fieldKey === undefined ? undefined : fields[fieldKey]));
