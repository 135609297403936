/** @jsx jsx */
import React, { type HTMLAttributes, forwardRef } from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';

const iconContainerStyle = css({
	width: '40px',
	height: '40px',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

const flexStyles = css({
	display: 'flex',
	alignItems: 'center',
});
const paddingLeftStyles = css({
	paddingLeft: token('space.050'),
});
const cardStyles = css({
	width: '100%',
	height: '100%',
	position: 'relative',
	justifyContent: 'space-between',
});
const multiLineCardStyles = css({
	flexDirection: 'column',
	alignItems: 'flex-start',
});
const itemWrapperStyles = css({
	paddingRight: token('space.050'),
});

export const IconContainer = ({ children, ...rest }: HTMLAttributes<HTMLDivElement>) => {
	return (
		<div css={iconContainerStyle} {...rest}>
			{children}
		</div>
	);
};

export const FlexContainer = ({ children, ...rest }: HTMLAttributes<HTMLDivElement>) => {
	return (
		<div css={flexStyles} {...rest}>
			{children}
		</div>
	);
};

export const PaddedFlexContainer = ({ children, ...rest }: HTMLAttributes<HTMLDivElement>) => (
	<div css={[flexStyles, paddingLeftStyles]} {...rest}>
		{children}
	</div>
);

export const Card = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
	({ children, ...rest }, ref) => (
		<div ref={ref} css={[flexStyles, cardStyles]} {...rest}>
			{children}
		</div>
	),
);

export const MultiLineCardContainer = ({ children, ...rest }: HTMLAttributes<HTMLDivElement>) => (
	<div css={[flexStyles, cardStyles, multiLineCardStyles]} {...rest}>
		{children}
	</div>
);

export const AdditionalItemWrapper = ({ children, ...rest }: HTMLAttributes<HTMLDivElement>) => (
	<div css={[flexStyles, itemWrapperStyles]} {...rest}>
		{children}
	</div>
);
