import React from 'react';
import ErrorIcon from '@atlaskit/icon/core/error';
import { xcss, Text, Inline } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { defineMessages, useIntl } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';

export const PlaybooksPanelErrorView = () => {
	const { formatMessage } = useIntl();

	return (
		<Inline space="space.100" xcss={errorViewStyles} alignBlock="center">
			<ErrorIcon label="" color={token('color.icon.accent.red')} />
			<Text color="color.text.danger">
				{formatMessage(
					fg('itsm-issue-renaming')
						? PlaybooksPanelErrorMessages.descriptionIssueRefresh
						: PlaybooksPanelErrorMessages.description,
				)}
			</Text>
		</Inline>
	);
};

const errorViewStyles = xcss({
	paddingTop: 'space.100',
});

const PlaybooksPanelErrorMessages = defineMessages({
	description: {
		id: 'jira.playbooks.agent-view.playbooks-panel.error-view-description',
		defaultMessage:
			'There was an issue retrieving the playbooks for this issue. Refresh and try again',
		description: 'Description for error view',
	},
	descriptionIssueRefresh: {
		id: 'jira.playbooks.agent-view.playbooks-panel.error-view-description-issue-term-refresh',
		defaultMessage:
			'There was an issue retrieving the playbooks for this work item. Refresh and try again',
		description: 'Description for error view',
	},
});
