import React from 'react';
import Reactions from '@atlassian/jira-issue-view-common/src/component/reactions/reactions-view.tsx';

export type StateProps = {
	issueAri: string | null;
	visible: boolean;
	commentAri: string | null;
	showQuickReactions?: boolean;
	onReactionSuccess?: (commentId: string) => void;
};

export type Props = StateProps;

export function CommentReactions(props: Props) {
	const { issueAri, commentAri, visible, showQuickReactions, onReactionSuccess } = props;
	const containerAri = issueAri === null ? undefined : issueAri;
	const objectAri = commentAri === null ? undefined : commentAri;

	return visible ? (
		<Reactions
			containerAri={containerAri}
			objectAri={objectAri}
			showQuickReactions={showQuickReactions}
			onReactionSuccess={onReactionSuccess}
		/>
	) : null;
}

CommentReactions.defaultProps = {
	issueAri: null,
	visible: false,
	commentAri: null,
	showQuickReactions: false,
};
