import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { FieldValueDecorations } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import type {
	Field,
	FieldMap,
	FieldKey,
} from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { PolarisPlay } from '@atlassian/jira-polaris-domain-field/src/play/types.tsx';
import type { SnippetProvider } from '@atlassian/jira-polaris-domain-field/src/snippet/types.tsx';
import type { FieldRemote } from '@atlassian/jira-polaris-remote-field/src/types.tsx';
import type { GlobalFieldRemote } from '@atlassian/jira-polaris-remote-global-field/src/index.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Environment } from '@atlassian/jira-shared-types/src/tenant-context.tsx';

export const DEFAULT_OPTION_WEIGHT = 1;

export type ArchivedFieldsConfig = {
	archivedField: Field | undefined;
	archivedByField: Field | undefined;
	archivedOnField: Field | undefined;
};

/**
 * Container props that when changed should cause state to be refreshed
 */
type LoadingProps = {
	containerAri: Ari;
	isProjectOnboarded: boolean | undefined;
};

/**
 * Container props for the field container
 */
export type Props = LoadingProps & {
	fieldRemote: FieldRemote;
	globalFieldRemote: GlobalFieldRemote;

	// not a remote action -> deletes play from project store, local state action only!
	deletePlay: (play: PolarisPlay) => void;

	projectId: string | undefined;
	cloudId: string;
	isAtlasIntegrationEnabled: boolean;

	hasNoProjectPermissions: boolean;

	issueTypeIds: IssueTypeId[] | undefined;
	createAnalyticsEvent: CreateUIAnalyticsEvent;
	onFieldLoadingFailed: (arg1: Error) => void;
	onDecorationUpdateFailed: (arg1: Error) => void;
	onFieldUpdateFailed: (arg1: Error) => void;
	onActionFailed: (arg1: Error) => void;
	/** Callback to be called when field with cyclic formula is detected */
	onCyclicFormulaDependencyDetected: (fieldLabels: string[]) => void;
	isSiteAdmin: boolean;
	environment: Environment | null;
	isCollectionView?: boolean;
};

export type State = {
	/**
	 * meta information on status of the issue state. does not contain any issue
	 * data; contains additional indicators on to the overall state of the state
	 */
	meta: {
		initialized: boolean;
		loading: boolean;
		loadingSnippetProviders: boolean;
		/**
		 * field -> issueType[] associations that are currently being loaded; needed for
		 * cross project issue linking to work properly
		 */
		connectionsLoadingFieldsAssociations: FieldKey[];
		error: Error | undefined;
		loadingProps: LoadingProps | undefined;
	};
	fields: FieldMap;
	fieldValueDecorations: FieldValueDecorations;
	snippetProviders: SnippetProvider[] | undefined;
	throttledFetchSnippetProviders: ((refetch: boolean, archivedOnly: false) => void) | undefined;
	highlightedFields: FieldKey[];
	/**
	 * We keep track of these to be able to determine whether we should refresh loaded issues
	 * If a newly added field is already in use in a view, we refresh to fetch its values
	 */
	newlyAddedGlobalFields: FieldKey[];
	/**
	 * props mirror for correct selector and hook handling
	 */
	containerProps: Props | undefined;
	prevContainerProps?: Props;
	/**
	 * Stores additional field -> issueType[] associations for issue types that are not part
	 * of the current container (roadmap / project). Needed for cross project issue linking
	 * to work properly
	 */
	connectionsFieldsAssociations: Record<FieldKey, IssueTypeId[]>;
};
