import head from 'lodash/head';
import {
	isIssueTypeIdFilter,
	isIssueTypeNameFilter,
	type ConnectionFieldIssueTypeFilter,
	type ConnectionFieldIssueViewLayoutSort,
} from '@atlassian/jira-polaris-domain-field/src/field/connection/types.tsx';
import type {
	ConnectionFieldConfiguration,
	FieldKey,
} from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import { SUMMARY_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import { createJsonPatch } from '@atlassian/jira-polaris-lib-json-patch/src/index.tsx';
import { createGetFieldByKey } from '../../selectors/field.tsx';
import type { Props, State } from '../../types.tsx';

export const updateConnectionFieldFilter =
	(fieldKey: FieldKey, filter: ConnectionFieldIssueTypeFilter | null) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ onFieldUpdateFailed, fieldRemote, issueTypeIds }: Props,
	): Promise<void> => {
		const state = getState();
		const currentField = createGetFieldByKey(fieldKey)(state);
		const issueTypeId = head(issueTypeIds);

		if (!issueTypeId || !currentField) {
			return;
		}

		const newConfiguration: ConnectionFieldConfiguration = {
			...currentField.configuration,
			issueTypeFilters:
				filter &&
				(isIssueTypeNameFilter(filter) || (isIssueTypeIdFilter(filter) && filter.ids.length > 0))
					? [filter]
					: [],
		};

		try {
			setState({
				fields: {
					...state.fields,
					[fieldKey]: {
						...currentField,
						configuration: newConfiguration,
					},
				},
			});

			await fieldRemote.updateFieldConfiguration({
				fieldKey,
				issueTypeId,
				oldConfiguration: currentField.configuration || {},
				configuration: newConfiguration,
				type: 'updateConnectionField',
			});
		} catch (error) {
			setState({
				fields: {
					...state.fields,
					[fieldKey]: currentField,
				},
			});

			if (error instanceof Error) {
				logSafeErrorWithoutCustomerDataWrapper(
					'polaris.error.controllers.field.actions.update-field-configuration.connection.issue-filters',
					'Failed to update connection field issue filters',
					error,
				);

				onFieldUpdateFailed(error);
			}
		}
	};

export type ConnectionFieldIssueViewLayout = {
	selectedFieldKey?: FieldKey | null;
	sort?: ConnectionFieldIssueViewLayoutSort | null;
};

export const updateConnectionFieldIssueViewLayout =
	(fieldKey: FieldKey, config: ConnectionFieldIssueViewLayout) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ onFieldUpdateFailed, fieldRemote, globalFieldRemote }: Props,
	): Promise<void> => {
		const state = getState();
		const currentField = createGetFieldByKey(fieldKey)(state);

		if (!currentField) {
			return;
		}

		const currentConfiguration = currentField.configuration || {};

		let issueViewLayoutFields: FieldKey[];
		if (config.selectedFieldKey === undefined) {
			issueViewLayoutFields = currentConfiguration.issueViewLayout?.fields || [];
		} else if (config.selectedFieldKey === null) {
			issueViewLayoutFields = [];
		} else {
			issueViewLayoutFields = [config.selectedFieldKey];
		}

		let issueViewLayoutSort: ConnectionFieldIssueViewLayoutSort[];
		if (
			config.selectedFieldKey === null &&
			currentConfiguration.issueViewLayout?.sort?.[0]?.fieldKey !== SUMMARY_FIELDKEY
		) {
			issueViewLayoutSort = [];
		} else if (config.sort === undefined) {
			issueViewLayoutSort = currentConfiguration.issueViewLayout?.sort || [];
		} else if (config.sort === null) {
			issueViewLayoutSort = [];
		} else {
			issueViewLayoutSort = [config.sort];
		}

		const newConfiguration: ConnectionFieldConfiguration = {
			...currentConfiguration,
			issueViewLayout: {
				fields: issueViewLayoutFields,
				sort: issueViewLayoutSort,
			},
		};

		try {
			setState({
				fields: {
					...state.fields,
					[fieldKey]: {
						...currentField,
						configuration: newConfiguration,
					},
				},
			});

			if (currentField.global) {
				await globalFieldRemote.updateGlobalFieldConfiguration({
					fieldKey,
					patch: createJsonPatch(currentConfiguration, newConfiguration),
				});
			} else {
				await fieldRemote.updateFieldConfiguration({
					fieldKey,
					issueTypeId: '',
					oldConfiguration: currentConfiguration,
					configuration: newConfiguration,
					type: 'updateConnectionField',
				});
			}
		} catch (error) {
			setState({
				fields: {
					...state.fields,
					[fieldKey]: currentField,
				},
			});

			if (error instanceof Error) {
				logSafeErrorWithoutCustomerDataWrapper(
					'polaris.error.controllers.field.actions.update-field-configuration.connection.issue-view-layout',
					'Failed to update connection field issue view layout',
					error,
				);

				onFieldUpdateFailed(error);
			}
		}
	};
