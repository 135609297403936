import React, { useState, type ChangeEvent } from 'react';
import { styled } from '@compiled/react';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { InlineField } from './inline-field/index.tsx';
import messages from './messages.tsx';

type Props = {
	isHrefValid: boolean;
	href: string;
	linkText: string;
	onHrefChange: (e: ChangeEvent<HTMLInputElement>) => void;
	onLinkTextChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const URL_PLACEHOLDER = 'https://www.example.com';

export const InlineFields = (props: Props) => {
	const [isHrefFocused, setIsHrefFocused] = useState(true);

	const { href, linkText, onHrefChange, onLinkTextChange, isHrefValid } = props;

	const { formatMessage } = useIntl();

	return (
		<TextFieldsWrapper>
			<HrefFieldWrapper>
				<InlineField
					value={href}
					headingText={formatMessage(messages.url)}
					autoFocus
					placeholder={URL_PLACEHOLDER}
					onChange={onHrefChange}
					onBlur={() => {
						setIsHrefFocused(false);
					}}
					onFocus={() => setIsHrefFocused(true)}
					isInvalid={!isHrefValid}
					/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
					testId="issue.views.issue-base.content.web-links.create-link.inline-fields.url"
				/>
				{!isHrefValid && !isHrefFocused && (
					<ErrorContainer colour={token('color.text.danger')}>
						{}
						<ErrorIcon
							color="currentColor"
							label={formatMessage(messages.errorIconLabel)}
							// @ts-expect-error - TS2769 - No overload matches this call.
							role="presentation"
						/>
						<ErrorLabel>{formatMessage(messages.invalidUrlMessage)}</ErrorLabel>
					</ErrorContainer>
				)}
			</HrefFieldWrapper>
			<LinkTextFieldWrapper>
				<InlineField
					value={linkText}
					headingText={formatMessage(messages.linkText)}
					autoFocus={false}
					placeholder={formatMessage(messages.linkTextPlaceholder)}
					onChange={onLinkTextChange}
					/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
					testId="issue.views.issue-base.content.web-links.create-link.inline-fields.text"
				/>
			</LinkTextFieldWrapper>
		</TextFieldsWrapper>
	);
};

export default InlineFields;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorContainer = styled.div<{ colour: string }>({
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: (props) => props.colour,
	display: 'flex',
	marginTop: token('space.050'),
});

// eslint-disable-next-line @atlaskit/design-system/use-primitives, @atlaskit/ui-styling-standard/no-styled
const ErrorLabel = styled.div({
	marginLeft: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const LinkTextFieldWrapper = styled.div({
	marginLeft: token('space.100'),
	flex: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const HrefFieldWrapper = styled.div({
	marginRight: token('space.100'),
	flex: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const TextFieldsWrapper = styled.div({
	marginTop: token('space.200'),
	display: 'flex',
});
