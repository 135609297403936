import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import { ViewNotFoundError } from '@atlassian/jira-polaris-remote-view/src/common/errors/view-not-found-error.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { createGetViewByAri } from '../../../selectors/view/index.tsx';
import type { Props, State } from '../../../types.tsx';
import { updateViewState } from '../../utils/state/index.tsx';
import { internViewComment } from '../utils.tsx';

export const loadViewComments =
	(viewId: string, refresh = false) =>
	async ({ getState, setState }: StoreActionApi<State>, { viewRemote }: Props) => {
		try {
			if (!refresh && createGetViewByAri(viewId)(getState())?.commentsLoaded) {
				// don't load comments if we already have them
				return;
			}

			if (viewRemote.fetchViewComments !== undefined) {
				const fetchCommentsTimestamp = refresh ? Date.now() : undefined;
				const { changedView, viewSets } = updateViewState(
					getState().viewSets,
					(view: View): boolean => view.viewId === viewId,
					(view: View) => ({ ...view, fetchCommentsTimestamp }),
				);

				if (changedView) {
					setState({ viewSets });
				}

				const data = await viewRemote.fetchViewComments(viewId);

				const { changedView: changedViewAfterCreation, viewSets: viewSetsAfterCreation } =
					updateViewState(
						getState().viewSets,
						(view: View): boolean =>
							view.viewId === viewId && view.fetchCommentsTimestamp === fetchCommentsTimestamp,
						(view: View) => ({
							...view,
							comments: data.comments.map(internViewComment),
							fetchCommentsTimestamp: undefined,
							commentsLoaded: true,
						}),
					);
				if (changedViewAfterCreation) {
					setState({ viewSets: viewSetsAfterCreation });
				}
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireErrorAnalytics(
				createErrorAnalytics(
					'polaris.error.controllers.views.actions.comments.load-view-comments',
					error,
				),
			);

			if (!(error instanceof ViewNotFoundError)) {
				throw error;
			}
		}
	};
