import React, { useRef, memo } from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { token } from '@atlaskit/tokens';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useBackButtonUrl } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import withCompactMode from '@atlassian/jira-issue-view-compact-mode/src/index.tsx';
import { useIsEmbedMode } from '@atlassian/jira-issue-view-embed-mode/src/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { errorSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import type { headerBreadcrumbs_issueViewFoundation_HeaderBreadcrumbsView$key } from '@atlassian/jira-relay/src/__generated__/headerBreadcrumbs_issueViewFoundation_HeaderBreadcrumbsView.graphql';
import BackButton from '../../back-button/view.tsx';
import Breadcrumbs from '../../breadcrumbs/index.tsx';
import { HeaderWrapper } from '../header-actions/styled.tsx';
import { IssueNavigator } from './issue-navigator/index.tsx';

export type HeaderBreadcrumbsViewProps = {
	isCompact?: boolean;
	shouldShowProjectLevelBreadcrumb?: boolean;
	shouldShowRootProjectsBreadcrumb?: boolean;
	error?: string | null;
	issue?: headerBreadcrumbs_issueViewFoundation_HeaderBreadcrumbsView$key | null;
};

export const HeaderBreadcrumbsView = ({
	error = null,
	isCompact,
	shouldShowProjectLevelBreadcrumb = false,
	shouldShowRootProjectsBreadcrumb = false,
	issue,
}: HeaderBreadcrumbsViewProps) => {
	const data =
		issue && fg('relay-migration-issue-header-and-parent')
			? // eslint-disable-next-line @atlassian/relay/query-restriction, react-hooks/rules-of-hooks
				useFragment<headerBreadcrumbs_issueViewFoundation_HeaderBreadcrumbsView$key>(
					graphql`
						fragment headerBreadcrumbs_issueViewFoundation_HeaderBreadcrumbsView on JiraIssue
						@argumentDefinitions(
							issueViewRelayParentFieldFlag: {
								type: "Boolean!"
								provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-parent"
							}
						) {
							...breadcrumbs_issueViewFoundation_IssueBreadcrumbsWithHooksNew
								@include(if: $issueViewRelayParentFieldFlag)
						}
					`,
					issue,
				)
			: undefined;

	const backButtonUrl = useBackButtonUrl();

	const hasSentErrorLog = useRef(false);

	if (error !== null) {
		return null;
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const isEmbedMode = useIsEmbedMode();

	if (!data && fg('relay-migration-issue-header-and-parent')) {
		if (!hasSentErrorLog?.current) {
			log.safeErrorWithoutCustomerData(
				'issue.issue-view-foundation.header-breadcrumbs-view',
				'Issue data is missing',
			);
			hasSentErrorLog.current = true;
		}
		return null;
	}

	return (
		<HeaderBreadcrumbsWrapper>
			<LeftAligned isCompact={isCompact}>
				{backButtonUrl !== undefined && <BackButton url={backButtonUrl} />}
				<Breadcrumbs
					externalId="issue"
					shouldShowProjectLevelBreadcrumb={isEmbedMode ? false : shouldShowProjectLevelBreadcrumb}
					shouldShowRootProjectsBreadcrumb={isEmbedMode ? false : shouldShowRootProjectsBreadcrumb}
					// remove on relay-migration-issue-header-and-parent cleanup
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					issue={data!}
				/>
				<IssueNavigator />
			</LeftAligned>
		</HeaderBreadcrumbsWrapper>
	);
};

export const HeaderBreadcrumbs: (props: HeaderBreadcrumbsViewProps) => React.ReactNode =
	flowWithSafeComponent(
		connect((state: State) => ({
			error: errorSelector(state),
		})),
	)(withCompactMode(memo(HeaderBreadcrumbsView)));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderBreadcrumbsWrapper = styled(HeaderWrapper)({
	marginLeft: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LeftAligned = styled.div<{ isCompact?: boolean }>(
	{
		display: 'flex',
		alignItems: 'center',
		flexGrow: 1,
		overflow: 'visible',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		"[dir='rtl'] &": {
			paddingLeft: 0,
			paddingRight: token('space.250'),
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isCompact }) => (isCompact ? { font: token('font.body.UNSAFE_small') } : {}),
);
