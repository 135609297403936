import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

export const TruncateWrapper = ({ children }: { children: ReactNode }) => (
	<TruncateContainer>{children}</TruncateContainer>
);

export const SimpleTableWrapper = ({
	children,
	rowHeight,
	testId,
}: {
	children: ReactNode;
	rowHeight?: number;
	testId?: string;
}) => (
	<SimpleTableContainer rowHeight={rowHeight} data-testid={testId}>
		{children}
	</SimpleTableContainer>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	paddingBlock: token('space.100'),
	boxSizing: 'border-box',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TruncateContainer = styled.div({
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SimpleTableContainer = styled.div<{ rowHeight?: number }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	'& table tr': ({ rowHeight }) => ({
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		height: rowHeight ? gridSize * rowHeight : 'auto',
	}),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& table > thead > tr > th': {
		verticalAlign: 'top',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& table > thead > tr > th:first-of-type': {
		paddingLeft: 0,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& table > tbody > tr > td:first-of-type': {
		paddingLeft: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export default Container;
