import React from 'react';
import { Flex } from '@atlaskit/primitives';
import ChangesIcon from '@atlaskit/icon/core/changes';
import { IssueTypeIcon } from '@atlassian/jira-polaris-component-issue-types/src/ui/issue-type-icon/issue-type-icon.tsx';
import {
	isIssueTypeIdFilter,
	isIssueTypeNameFilter,
	type ConnectionFieldIssueTypeFilter,
} from '@atlassian/jira-polaris-domain-field/src/field/connection/types.tsx';
import {
	useIssueTypeByNameAndProjectIds,
	useIssueTypeExists,
} from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';

type Props = {
	issueTypeFilters?: ConnectionFieldIssueTypeFilter[];
};

export const ConnectionFieldIcon = ({ issueTypeFilters = [] }: Props) => {
	const issueTypeIds = issueTypeFilters.find(isIssueTypeIdFilter);
	const issueTypeNameFilter = issueTypeFilters.find(isIssueTypeNameFilter);
	const issueTypeByName = useIssueTypeByNameAndProjectIds({
		name: issueTypeNameFilter?.names[0],
		projectIds: issueTypeNameFilter?.projectIds,
	});
	const issueTypeExists = useIssueTypeExists({ issueTypeId: issueTypeIds?.ids[0] });

	if (fg('jpd_cross_project_connecting')) {
		if (issueTypeByName) {
			return (
				<Flex alignItems="center">
					<IssueTypeIcon issueTypeId={issueTypeByName.id} />
				</Flex>
			);
		}

		return <ChangesIcon label="connection" color="var(--ds-icon)" />;
	}

	if (!issueTypeIds?.ids.length || !issueTypeExists) {
		// eslint-disable-next-line jira/i18n/text-wrapped-in-format-message
		return <ChangesIcon label="connection" color="var(--ds-icon)" />;
	}

	return (
		<Flex alignItems="center">
			<IssueTypeIcon issueTypeId={issueTypeIds.ids[0]} />
		</Flex>
	);
};
