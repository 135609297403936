import React from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import ExportIssueConfluence from './export-issue-confluence/index.tsx';
import ExportIssueDoc from './export-issue-doc/index.tsx';
import ExportIssuePDF from './export-issue-pdf/index.tsx';
import ExportIssueXML from './export-issue-xml/index.tsx';
import PrintIssue from './print-issue/index.tsx';
import ExportIssueCSV from './export-issue-csv/index.tsx';

const issueExportDropdownGroup = (shouldShowExportActions: boolean) =>
	({
		name: '',
		key: 'issueExportDropdownGroup',
		items: [
			...(shouldShowExportActions
				? [
						<PrintIssue key="printIssue" />,
						...(expVal(
							'jira-export-issue-details-feature-jracloud-38896',
							'exportIssueDetailsFeatureEnabled',
							false,
						)
							? [<ExportIssueCSV key="exportIssueCSV" />]
							: []),
						<ExportIssueDoc key="exportIssueDoc" />,
						<ExportIssueXML key="exportIssueXML" />,
						<ExportIssueConfluence key="exportIssueConfluence" />,
						<ExportIssuePDF key="exportIssuePDF" />,
					]
				: [<PrintIssue key="printIssue" />]),
		],
	}) as const;

export default issueExportDropdownGroup;
