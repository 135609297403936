/** @jsx jsx */
import React, { useCallback, useMemo, useState } from 'react';
import { jsx, css } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { IconButton } from '@atlaskit/button/new';
import { Box, xcss } from '@atlaskit/primitives';
import EditIcon from '@atlaskit/icon/core/migration/edit';
import Popup from '@atlaskit/popup';
import { useIntl } from '@atlassian/jira-intl';
import type { ui_issueViewCover_issueViewRelayFragment$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewCover_issueViewRelayFragment.graphql';
import type { ui_issueViewCover_rootRelayFragment$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewCover_rootRelayFragment.graphql.ts';
import {
	generateMediaImageUrl,
	themeManager,
} from '@atlassian/jira-custom-theme-constants/src/utils.tsx';
import type { ColorThemeName } from '@atlassian/jira-custom-theme-constants/src/types.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { cardCoverManagerEntryPoint } from '@atlassian/jira-card-cover-manager/entrypoint.tsx';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/index.tsx';

import { JiraIssueAri } from '@atlassian/ari/jira/issue';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';

import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import type { RuntimePropsOfEntryPoint } from '@atlassian/jira-entry-point/src/common/types.tsx';
import type { CoverMedia } from '@atlassian/jira-card-cover-manager/src/common/cover-media.types.tsx';
import { CardCoverSkeleton } from '@atlassian/jira-card-cover-manager/src/ui/CardCoverSkeleton.tsx';
import type { IssueCoverMedia } from '../utils.tsx';
import { useIssueViewCoverStore } from '../store.tsx';
import {
	ISSUE_VIEW_COVER_MEDIA_READ_TOKEN_DURATION,
	ISSUE_VIEW_COVER_MEDIA_READ_TOKEN_MAX_LENGTH,
} from '../constants.tsx';
import messages from './messages.tsx';

export type Props = {
	issueViewRelayFragment: ui_issueViewCover_issueViewRelayFragment$key | null;
	rootRelayFragment: ui_issueViewCover_rootRelayFragment$key | null;
};

export const IssueViewCover = ({ rootRelayFragment, issueViewRelayFragment }: Props) => {
	const { formatMessage } = useIntl();
	const cloudId = useCloudId();
	const issueId = useIssueId();

	const [isHovered, setIsHovered] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [{ coverMedia }, { updateCoverMedia }] = useIssueViewCoverStore();

	const coverData = useFragment<ui_issueViewCover_issueViewRelayFragment$key>(
		graphql`
			fragment ui_issueViewCover_issueViewRelayFragment on JiraIssue {
				jiraCoverMedia {
					... on JiraAttachmentBackground {
						attachment {
							mediaApiFileId
							mediaReadToken(durationInSeconds: 900, maxTokenLength: 65534)
								@optIn(to: "JiraAttachmentMediaReadToken")
						}
					}
					... on JiraColorBackground {
						colorValue
					}
				}
			}
		`,
		issueViewRelayFragment || null,
	);

	const mediaData = useFragment<ui_issueViewCover_rootRelayFragment$key>(
		graphql`
			fragment ui_issueViewCover_rootRelayFragment on JiraQuery
			@argumentDefinitions(cloudId: { type: "ID!" }) {
				mediaClientId(cloudId: $cloudId)
				mediaExternalEndpointUrl(cloudId: $cloudId)
			}
		`,
		rootRelayFragment ?? null,
	);

	const {
		entryPointActions: cardCoverManagerEntryPointActions,
		entryPointReferenceSubject: cardCoverManagerEntryPointReferenceSubject,
	} = useEntryPoint(
		cardCoverManagerEntryPoint,
		useMemo(
			() => ({
				cloudId,
				issueId: JiraIssueAri.create({
					issueId: String(issueId),
					siteId: cloudId,
				}).toString(),
				durationInSeconds: ISSUE_VIEW_COVER_MEDIA_READ_TOKEN_DURATION,
				maxTokenLength: ISSUE_VIEW_COVER_MEDIA_READ_TOKEN_MAX_LENGTH,
			}),
			[cloudId, issueId],
		),
	);

	const onCardCoverUpdated = useCallback<
		RuntimePropsOfEntryPoint<typeof cardCoverManagerEntryPoint>['onUpdated']
	>(
		(newCoverMedia: CoverMedia) => {
			updateCoverMedia(newCoverMedia);
		},
		[updateCoverMedia],
	);

	const finalCoverMedia: IssueCoverMedia | null = useMemo(() => {
		if (coverMedia?.type === 'unset') {
			return null;
		}

		if (coverMedia) {
			return coverMedia;
		}

		if (
			coverData?.jiraCoverMedia?.attachment?.mediaApiFileId &&
			coverData?.jiraCoverMedia?.attachment?.mediaReadToken
		) {
			return {
				type: 'image',
				fileName: '', // Assuming fileName is not available from coverData
				mediaApiFileId: coverData.jiraCoverMedia.attachment.mediaApiFileId,
				mediaReadToken: coverData.jiraCoverMedia.attachment.mediaReadToken,
			};
		}
		if (coverData?.jiraCoverMedia?.colorValue) {
			return {
				type: 'color',
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				value: coverData.jiraCoverMedia.colorValue as ColorThemeName,
			};
		}
		return null;
	}, [coverMedia, coverData]);

	const url = useMemo(() => {
		if (
			finalCoverMedia?.type === 'image' &&
			mediaData?.mediaClientId &&
			mediaData?.mediaExternalEndpointUrl
		) {
			return generateMediaImageUrl({
				mediaApiFileId: finalCoverMedia.mediaApiFileId,
				token: finalCoverMedia.mediaReadToken,
				mediaClientId: mediaData?.mediaClientId,
				mediaExternalEndpointUrl: mediaData?.mediaExternalEndpointUrl,
				mediaEndpoint: 'image',
				width: 1200,
				'max-age': Number.MAX_SAFE_INTEGER,
			});
		}
		return null;
	}, [finalCoverMedia, mediaData]);

	const colorValue = useMemo(() => {
		if (finalCoverMedia?.type === 'color') {
			const colorConfig = themeManager.getColor(finalCoverMedia.value);
			return colorConfig?.theme.light['color.elevation.surface'];
		}
		return null;
	}, [finalCoverMedia]);

	const handleMouseEnter = useCallback(() => {
		setIsHovered(true);
	}, []);

	const handleMouseLeave = useCallback(() => {
		setIsHovered(false);
	}, []);

	const loadCardCoverManagerEntryPoint = useCallback(() => {
		cardCoverManagerEntryPointActions.load();
	}, [cardCoverManagerEntryPointActions]);

	if (!finalCoverMedia) {
		return null;
	}

	return (
		<Box
			xcss={issueViewCoverStyles}
			testId="issue-view-cover.ui.container"
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			// eslint-disable-next-line jira/react/no-style-attribute
			style={{ backgroundColor: url ? 'elevation.surface' : colorValue ?? 'elevation.surface' }}
		>
			{url && <img src={url} alt={formatMessage(messages.coverImageAlt)} css={imageStyles} />}

			<Popup
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				placement="bottom-end"
				content={() => (
					<JiraEntryPointContainer
						id="jira-card-cover-manager-issue-view"
						packageName="jiraCardCoverManager"
						entryPointReferenceSubject={cardCoverManagerEntryPointReferenceSubject}
						fallback={<CardCoverSkeleton />}
						runtimeProps={{
							onClose: () => setIsOpen(false),
							onUpdated: onCardCoverUpdated,
							durationInSeconds: ISSUE_VIEW_COVER_MEDIA_READ_TOKEN_DURATION,
							maxTokenLength: ISSUE_VIEW_COVER_MEDIA_READ_TOKEN_MAX_LENGTH,
							showCardCoverColors: true,
						}}
					/>
				)}
				trigger={(triggerProps) => (
					<Box
						onMouseEnter={loadCardCoverManagerEntryPoint}
						xcss={[
							editButtonStyles,
							isHovered || isOpen ? editButtonVisibleStyles : editButtonHiddenStyles,
						]}
					>
						<IconButton
							{...triggerProps}
							icon={EditIcon}
							isSelected={isOpen}
							onClick={() => setIsOpen(!isOpen)}
							label={formatMessage(messages.editCoverButtonLabel)}
						/>
					</Box>
				)}
			/>
		</Box>
	);
};

const issueViewCoverStyles = xcss({
	height: '120px',
	backgroundColor: 'elevation.surface',
	borderRadius: 'border.radius.100',
	position: 'relative',
	overflow: 'hidden',
	marginTop: 'space.150',
	marginBottom: 'space.150',
	borderStyle: 'solid',
	borderColor: 'color.border',
	borderWidth: 'border.width',
});

const imageStyles = css({
	width: '100%',
	height: '100%',
	objectFit: 'cover',
});

const editButtonStyles = xcss({
	position: 'absolute',
	bottom: 'space.100',
	right: 'space.100',
	transition: 'opacity 0.2s ease-in-out',
	backgroundColor: 'elevation.surface',
	borderRadius: 'border.radius.100',
});

const editButtonVisibleStyles = xcss({
	opacity: 1,
});

const editButtonHiddenStyles = xcss({
	opacity: 0,
});
