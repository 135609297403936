import React, { useMemo } from 'react';
import { IssueAdjustmentsAsync } from '@atlassian/jira-issue-adjustments/src/ui.tsx';
import { useActivationId } from '@atlassian/jira-tenant-context-controller/src/components/activation-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { ViewType } from '@atlassian/ui-modifications-core/src/common/types/context.tsx';
import type { ExecutionContext } from '@atlassian/ui-modifications-core/src/common/types/execution-context.tsx';
import type { UiModificationsExtension } from '@atlassian/ui-modifications-core/src/common/types/extension.tsx';
import type { JSErrorBoundaryProps } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { ForgeAppRenderer } from './forge-app-renderer/index.tsx';

export type UiModificationsJiraCoreRuntimeProps = {
	fieldsCount: number;
	/**
	 * @deprecated use useViewType hook instead or container props in store actions
	 */
	viewType: ViewType;
	resetFormFieldsCounter: number;
	modules: UiModificationsExtension[];
};

export type UiModificationsJiraCoreProps = {
	containerId: string;
	containerPackageName: string;
	executionContext: ExecutionContext;
	triggerPointKey?: string;
	errorFallback?: JSErrorBoundaryProps['fallback'];
	executionContextKey: string;
	runtimeProps: UiModificationsJiraCoreRuntimeProps;
};

// TODO: drop whole file (with tests) on ditt_uim_-_use_platform_uim_core_in_jira feature gate removal
// Move forge-app-renderer to the `ui` catalog from the `ui-modifications-jira-core`
export const UiModificationsJiraCore = (props: UiModificationsJiraCoreProps) => {
	const cloudId = useCloudId();
	const activationId = useActivationId();
	const { executionContextKey } = props;
	const { fieldsCount, viewType, resetFormFieldsCounter, modules } = props.runtimeProps;

	const runtimeProps = useMemo(() => {
		return {
			fieldsCount,
			viewType,
			resetFormFieldsCounter,
			modules,
			executionContextKey,
			ForgeAppRenderer,
		};
	}, [fieldsCount, viewType, resetFormFieldsCounter, modules, executionContextKey]);

	return (
		<IssueAdjustmentsAsync
			{...props}
			runtimeProps={runtimeProps}
			cloudId={cloudId}
			activationId={activationId}
		/>
	);
};
