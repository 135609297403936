import { useCallback, useMemo } from 'react';
import { fetchQuery, graphql } from 'relay-runtime';
import type { Transition } from '@atlassian/jira-issue-fetch-services/src/types.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import type {
	transitionsRelayQueryForStatusFieldQuery,
	transitionsRelayQueryForStatusFieldQuery$data,
} from '@atlassian/jira-relay/src/__generated__/transitionsRelayQueryForStatusFieldQuery.graphql';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import {
	startCapturingTraceIds,
	stopCapturingTraceIds,
	getTraceIds,
} from '@atlassian/relay-traceid';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { fg } from '@atlassian/jira-feature-gating';

type Props = {
	issueKey: IssueKey;
};

const OPERATION_NAME = 'transitionsRelayQueryForStatusFieldQuery';

const fetchTransitionsQuery = (issueKey: IssueKey, cloudId: string) =>
	fetchQuery<transitionsRelayQueryForStatusFieldQuery>(
		getRelayEnvironment(),
		graphql`
			query transitionsRelayQueryForStatusFieldQuery($cloudId: ID!, $issueKey: String!) {
				jira {
					issueByKey(key: $issueKey, cloudId: $cloudId) {
						statusField {
							transitions(sortingOption: OPS_BAR_THEN_STATUS_CATEGORY)
								@optIn(to: "JiraStatusFieldOptions") {
								edges {
									node {
										transitionId
										name
										hasScreen
										isGlobal
										isInitial
										isLooped
										hasPreConditions
										to {
											statusId
											name
											statusCategory {
												statusCategoryId
											}
										}
									}
								}
							}
						}
					}
				}
			}
		`,
		{
			issueKey,
			cloudId,
		},
	);

const formatResponse = (data?: transitionsRelayQueryForStatusFieldQuery$data) =>
	data?.jira?.issueByKey?.statusField?.transitions?.edges?.map((edge) => ({
		id: edge?.node?.transitionId ?? 0,
		name: edge?.node?.name ?? '',
		hasScreen: edge?.node?.hasScreen ?? false,
		isGlobal: edge?.node?.isGlobal ?? false,
		isInitial: edge?.node?.isInitial ?? false,
		isConditional: edge?.node?.hasPreConditions ?? false,
		isLooped: edge?.node?.isLooped ?? false,
		to: {
			id: parseInt(edge?.node?.to?.statusId ?? '', 10),
			name: edge?.node?.to?.name ?? '',
			description: '',
			statusCategory: {
				id: parseInt(edge?.node?.to?.statusCategory?.statusCategoryId ?? '', 10),
			},
		},
		from: null,
	})) ?? [];

export const useFetchTransitionsForStatusField = ({ issueKey }: Props) => {
	const cloudId = useCloudId();
	const fetchTransitions = useCallback(async (): Promise<Transition[]> => {
		if (fg('thor_resolve_transition_event_gate')) {
			startCapturingTraceIds(OPERATION_NAME);
			try {
				const data = await fetchTransitionsQuery(issueKey, cloudId).toPromise();
				return formatResponse(data);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				const firstError = error?.source?.errors?.[0] || {};
				throw new FetchError(
					firstError?.extensions?.statusCode,
					firstError.message ||
						`Relay Fetch call failed with status code: ${firstError?.extensions?.statusCode}`,
					getTraceIds(OPERATION_NAME)?.[0],
				);
			} finally {
				stopCapturingTraceIds(OPERATION_NAME);
			}
		}
		const data = await fetchTransitionsQuery(issueKey, cloudId).toPromise();
		return formatResponse(data);
	}, [cloudId, issueKey]);
	return useMemo(() => ({ fetchTransitions }), [fetchTransitions]);
};
