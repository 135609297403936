import React, { useCallback } from 'react';
import throttle from 'lodash/throttle';
import WatchIcon from '@atlaskit/icon/core/migration/eye-open--watch';
import { getCommandPaletteIssueActionsPriority } from '@atlassian/jira-command-palette-common/src/common/utils/get-command-palette-issue-actions-priority/index.tsx';
import { getCommandPaletteIssueActionsHeader } from '@atlassian/jira-command-palette-common/src/common/utils/index.tsx';
import { useCommandKeywords } from '@atlassian/jira-command-palette-common/src/controllers/use-command-keywords/index.tsx';
import { CommandShortcuts } from '@atlassian/jira-command-palette-common/src/ui/command-shortcuts/index.tsx';
import {
	TASK_FAIL,
	TASK_SUCCESS,
} from '@atlassian/jira-experience-tracker/src/common/constants.tsx';
import type { FeatureFlagValue } from '@atlassian/jira-feature-flagging-using-meta';
import { fg } from '@atlassian/jira-feature-gating';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import {
	THROTTLE_MS,
	TOGGLE_WATCHING_SHORTCUT,
} from '@atlassian/jira-issue-view-common-constants/src/watchers.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	useApplication,
	useEdition,
	useProjectKey,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import messages from './messages.tsx';

type Props = {
	isWatching: boolean;
	onToggleWatching: (
		onSuccess: (source: string, experience: string) => void,
		onError: (source: string, experience: string) => void,
	) => void;
};

export const KeyboardShortcuts = (props: Props) => {
	const { formatMessage } = useIntl();

	const issueKey = useIssueKey();
	const { isWatching } = props;
	const accountId = useAccountId();
	const { getKeywords } = useCommandKeywords();

	const projectKey = useProjectKey(issueKey);
	const application = useApplication(projectKey, true);
	const edition = useEdition(projectKey, true);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onSuccess = useCallback(
		(source: string, experience: string) => {
			sendExperienceAnalytics({
				wasExperienceSuccesful: true,
				action: TASK_SUCCESS,
				analyticsSource: source,
				experience,
				application: application ?? null,
				edition: edition ?? null,
			});

			if (fg('one-event-rule-all-watcher-analytics')) {
				getUpdateAnalyticsFlowHelper().fireAnalyticsEnd('watchers', {
					analytics: createAnalyticsEvent({}),
					attributes: {
						fieldType: 'watchers',
						// isWatching represents user's current state, so we need to send the opposite value:
						actionTaken: isWatching ? 'remove' : 'add',
						oldValId: isWatching ? accountId : '',
						newValId: isWatching ? '' : accountId,
						isCommandPaletteEditing: true,
					},
				});
			}
		},
		[application, createAnalyticsEvent, accountId, edition, isWatching],
	);

	const onError = useCallback(
		(source: string, experience: string, error?: Error | FetchError) => {
			const additionalAttributes: Record<
				string,
				string | boolean | number | Record<string, FeatureFlagValue>
			> = {};

			if (error?.message && fg('thor_add_missing_attributes_across_issue_view_2')) {
				additionalAttributes.errorMessage = error.message;
			}

			if (error instanceof FetchError && fg('thor_add_missing_attributes_across_issue_view_2')) {
				if (error?.traceId) additionalAttributes.traceId = error.traceId;
				if (error?.statusCode) additionalAttributes.statusCode = error.statusCode;
			}
			sendExperienceAnalytics({
				wasExperienceSuccesful: false,
				action: TASK_FAIL,
				analyticsSource: source,
				experience,
				application: application ?? null,
				edition: edition ?? null,
				...(fg('thor_add_missing_attributes_across_issue_view_2') && {
					additionalAttributes,
				}),
			});
		},
		[application, edition],
	);

	const onToggleWatching = throttle(
		() => {
			props.onToggleWatching(onSuccess, onError);
		},
		THROTTLE_MS,
		{ trailing: false },
	);

	const stopWatchingMessage = fg('jira-issue-terminology-refresh-m3')
		? messages.stopWatchingIssueTermRefresh
		: messages.stopWatching;

	const startWatchingMessage = fg('jira-issue-terminology-refresh-m3')
		? messages.startWatchingIssueTermRefresh
		: messages.startWatching;

	const sectionId = getCommandPaletteIssueActionsHeader(issueKey);
	const keyMap = {
		[TOGGLE_WATCHING_SHORTCUT]: {
			callback: onToggleWatching,
			label: <FormattedMessage {...messages.toggleWatching} />,
			registerInCommandPalette: {
				id: `issue-view.watchers.keyboard-shortcuts.${issueKey}.${
					isWatching ? 'stop' : 'start'
				}-watching`,
				name: formatMessage(isWatching ? stopWatchingMessage : startWatchingMessage),
				keywords: getKeywords('startWatchingIssueSynonyms'),
				priority: getCommandPaletteIssueActionsPriority('ISSUE_HEADER_ITEMS'),
				section: sectionId,
				components: {
					LeftIcon: () => (
						<WatchIcon
							spacing="spacious"
							label={formatMessage(isWatching ? stopWatchingMessage : startWatchingMessage)}
						/>
					),
				},
				analytics: {
					action: isWatching ? 'stopWatchingIssue' : 'startWatchingIssue',
				},
			},
		},
	};

	return <CommandShortcuts keyMap={keyMap} />;
};

export default KeyboardShortcuts;
