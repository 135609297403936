import keyBy from 'lodash/keyBy';
import throttle from 'lodash/throttle';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action, StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../types.tsx';

const fetchSnippetProvidersInternal =
	(refetch: boolean, archivedOnly: boolean): Action<State, Props> =>
	async (
		{ setState, getState, dispatch }: StoreActionApi<State>,
		{ onFieldLoadingFailed, fieldRemote, containerAri, hasNoProjectPermissions }: Props,
	) => {
		if (hasNoProjectPermissions) {
			return;
		}

		if (containerAri === undefined) {
			return;
		}

		if (getState().meta.loadingSnippetProviders === true && !archivedOnly) {
			return;
		}

		if (getState().snippetProviders !== undefined && !refetch && !archivedOnly) {
			return;
		}

		try {
			if (getState().throttledFetchSnippetProviders === undefined && !archivedOnly) {
				setState({
					throttledFetchSnippetProviders: throttle(
						(rf: boolean, onlyArchived: false) => {
							dispatch(fetchSnippetProvidersInternal(rf, onlyArchived));
						},
						60 * 1000,
						{
							trailing: false,
							leading: true,
						},
					),
				});
				dispatch(throttledFetchSnippetProviders(refetch));
				return;
			}

			if (!archivedOnly) {
				setState({
					meta: {
						...getState().meta,
						loadingSnippetProviders: true,
					},
				});
			}

			const archivedMode = archivedOnly ? 'ARCHIVED_ONLY' : 'ACTIVE_ONLY';
			const snippetProviders = await fieldRemote.fetchSnippetProviders(archivedMode);

			const existingSnippetProviders = getState().snippetProviders || [];
			const snippetProvidersById = keyBy(snippetProviders, 'id');
			existingSnippetProviders.map((snippetProvider) => {
				if (snippetProvidersById[snippetProvider.id]) {
					delete snippetProvidersById[snippetProvider.id];
					return snippetProvidersById[snippetProvider.id];
				}
				return snippetProvidersById;
			});

			setState({
				snippetProviders: [...existingSnippetProviders, ...Object.values(snippetProvidersById)],
				meta: {
					...getState().meta,
					loadingSnippetProviders: false,
				},
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			setState({
				meta: {
					...getState().meta,
					loadingSnippetProviders: false,
				},
			});
			onFieldLoadingFailed(e);
		}
	};

export const fetchArchivedSnippetProviders =
	(): Action<State, Props> =>
	async ({ dispatch }: StoreActionApi<State>) => {
		dispatch(fetchSnippetProvidersInternal(false, true));
	};

export const throttledFetchSnippetProviders =
	(refetch = false): Action<State, Props> =>
	async ({ getState, dispatch }: StoreActionApi<State>) => {
		if (getState().throttledFetchSnippetProviders === undefined) {
			dispatch(fetchSnippetProvidersInternal(refetch, false));
			return;
		}
		getState().throttledFetchSnippetProviders?.(refetch, false);
	};
