// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State } from '../../types.tsx';
import { setQuery } from '../set-query/index.tsx';

export const closeIssueView =
	() =>
	({ dispatch }: StoreActionApi<State>) => {
		dispatch(
			setQuery({
				selectedIssue: undefined,
				issueViewLayout: undefined,
				issueViewSection: undefined,
				viewCommentId: undefined,
			}),
		);
	};
