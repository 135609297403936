import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { User } from '../../../common/types.tsx';
import type { UsersResponse } from '../types.tsx';

type SearchResponse = {
	values: UsersResponse[];
};

const getBulkUsersUrl = (accountIds: string[]) =>
	`/rest/api/3/user/bulk?maxResults=10&accountId=${accountIds.join('&accountId=')}`;

export const getUsersData = async (accountIds: string[]): Promise<User[]> => {
	const users = await fetchJson<SearchResponse>(getBulkUsersUrl(accountIds));
	return users.values.map(({ accountId, displayName, avatarUrls }) => ({
		id: accountId,
		name: displayName || '',
		avatar:
			avatarUrls['48x48'] ||
			avatarUrls['32x32'] ||
			avatarUrls['24x24'] ||
			Object.values(avatarUrls).pop() ||
			'',
	}));
};
