import React, { useCallback, useEffect, useRef, type KeyboardEvent, type ReactNode } from 'react';
import tabbable from 'tabbable';
import { Box } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';

export interface AtlaskitSelectInlineTabActivatorProps {
	setInputValue?: (value: string) => void;
	setIsMenuOpen: (value: boolean) => void;
	inputValue?: string;
	isMenuOpen: boolean;
	children: ReactNode;
}

export const AtlaskitSelectInlineTabActivator = ({
	setInputValue,
	setIsMenuOpen,
	inputValue,
	isMenuOpen,
	children,
}: AtlaskitSelectInlineTabActivatorProps) => {
	const selectWrapperRef = useRef<HTMLElement>(null);

	const handleBlur = useCallback(
		(e: FocusEvent | MouseEvent) => {
			// Always close the menu on outside focus and mouse events
			if (
				selectWrapperRef.current &&
				e.target instanceof Node &&
				// This is to make sure we only blur when focusing outside of the select
				// This is required to allow interacting with inline buttons inside the select dropdown
				!selectWrapperRef.current.contains(e.target)
			) {
				setInputValue?.('');
				setIsMenuOpen(false);
				return;
			}

			// Always toggle the menu open/closed on internal mousedown events
			if (e.type === 'mousedown') {
				// Reset the input value when closing the menu
				if (isMenuOpen) {
					setInputValue?.('');
				}
				setIsMenuOpen(!isMenuOpen);
			}
		},
		[isMenuOpen, setInputValue, setIsMenuOpen],
	);

	useEffect(() => {
		// Listen to focus and mousedown events to close the select dropdown when clicking or focusing outside of it
		globalThis?.document?.addEventListener('focusin', handleBlur);
		globalThis?.document?.addEventListener('mousedown', handleBlur);

		return () => {
			globalThis?.document?.removeEventListener('focusin', handleBlur);
			globalThis?.document?.removeEventListener('mousedown', handleBlur);
		};
	}, [handleBlur]);

	useEffect(() => {
		// Open the menu when the input value changes
		if (!isMenuOpen && inputValue) {
			setIsMenuOpen(true);
		}
	}, [inputValue, isMenuOpen, setIsMenuOpen]);

	return (
		<Box
			testId="inline-config-buttons-for-select.atlaskit-select-inline-tab-activator"
			ref={selectWrapperRef}
			onKeyDown={(e: KeyboardEvent) => {
				const openMenuKeys = ['ArrowDown', 'ArrowUp', 'Enter', ' '];
				// Only override keydown events when the menu is open or to open the menu
				if (!isMenuOpen && !openMenuKeys.includes(e.key)) {
					return;
				}
				switch (e.key) {
					// This is to handle closing the Select menu when pressing Escape
					case 'Escape': {
						setIsMenuOpen(false);
						setInputValue?.('');
						break;
					}
					// This is to allow tabbing inside the Select menu
					// if the current focus one is last tabbable element, focus the first tabbable element
					// to prevent tabbing out of the Select menu
					case 'Tab': {
						fg('inline_config_a11y_improvements') && e.stopPropagation();
						const tabbableEls = tabbable(e.currentTarget);
						if (tabbableEls.length < 2) {
							return;
						}
						const isLastTabbable =
							tabbableEls[tabbableEls.length - 1] === globalThis?.document?.activeElement;
						if (isLastTabbable) {
							e.preventDefault();
							tabbableEls[0].focus();
						}
						break;
					}
					// This is to allow navigating inside the Select menu using Arrow keys after focusing on inline buttons
					// Because users can only continue navigating using Arrow keys if the focus is on the text input of select
					case 'ArrowDown':
					case 'ArrowUp': {
						if (!isMenuOpen) {
							setIsMenuOpen(true);
							return;
						}
						const tabbableEls = tabbable(e.currentTarget);
						if (tabbableEls.length < 2) {
							return;
						}
						tabbableEls[0].focus();
						break;
					}
					case 'Enter':
					case ' ': {
						if (!isMenuOpen) {
							setIsMenuOpen(true);
						}
						break;
					}
					default:
						break;
				}
			}}
		>
			{children}
		</Box>
	);
};
