import parameters from '@atlassian/jira-relay/src/__generated__/siteSwitcherQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = {
	hostNames: string[] | undefined;
};

export const AiRelatedResourcesEntrypoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-ai-related-resources" */ './src/ui/index.tsx').then(
			(module) => module.RelatedResourcesEntryPointComponent,
		),
	),
	getPreloadProps: ({ hostNames = [] }: EntryPointParams) => {
		return {
			queries: {
				tenantContextQuery: {
					options: {
						fetchPolicy: 'store-and-network' as const,
					},
					parameters,
					variables: {
						hostNames,
					},
				},
			},
		};
	},
});
