import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import { fg } from '@atlassian/jira-feature-gating';
// The eslint rule can't handle imports other than from the jira-relay generated.
/* eslint-disable eslint-comments/no-restricted-disable */
// eslint-disable-next-line @atlassian/react-entrypoint/no-imports-in-entrypoint-file
import {
	type RootUiModificationsQueryType,
	rootUiModificationsQuery,
} from '@atlassian/ui-modifications-core/gql';

/**
 * Entrypoint for the UI modifications root with preloaded props.
 */
export const uiModificationsRootEntrypoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(
			/* webpackChunkName: "async-ui-modifications-root-entrypoint" */ './root-entrypoint-wrapper/index'
		).then((module) => module.RootEntrypointWrapper),
	),
	getPreloadProps: ({
		cloudId,
		forgeExtensionRenderingContext,
		uiModificationsContext,
	}: RootUiModificationsQueryType['variables']) => {
		return {
			queries: {
				rootUiModificationsQuery: {
					parameters: rootUiModificationsQuery,
					variables: {
						cloudId,
						forgeExtensionRenderingContext,
						uiModificationsContext,
						includeEgressOverridesForge: fg('forge-ui-overrides-property-in-extension-list'),
					},
					options: {
						fetchPolicy: 'network-only' as const,
					},
				},
			},
		};
	},
});
