import React from 'react';

import { ActionItem } from '@atlassian/jira-servicedesk-ai-context-common/src/ui/ai-context-item/action-item/index.tsx';
import type { ProblemTicketSuggestedAction } from '@atlassian/jira-servicedesk-ai-context-panel-resources/src/services/ops/common/types.tsx';
import { CreateProblemButton } from '../../../../common/ui/problem-ticket/index.tsx';
import { useAIAnalytics } from '../../../../common/utils/index.tsx';

type props = {
	action: ProblemTicketSuggestedAction;
	onRemoveItem: () => void;
};

export const ProblemTicketItem = ({ action, onRemoveItem }: props) => {
	const [{ onClickFireAnalytics }] = useAIAnalytics(action.type);

	return (
		<ActionItem
			summary={action.content.title}
			description={action.content.description}
			action={
				<CreateProblemButton
					context={action.context}
					onClick={onClickFireAnalytics}
					onSuccess={onRemoveItem}
				/>
			}
		/>
	);
};
