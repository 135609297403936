/**
 * @generated SignedSource<<62179cd0ed16af2331e813dd2aa0548e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_ViewAutomationDiscoveryPanel_userPreferences$data = {
  readonly dismissedAutomationDiscoverabilityTemplates: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly dismissUntilDateTime: AGG$DateTime | null | undefined;
        readonly templateId: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ui_ViewAutomationDiscoveryPanel_userPreferences";
};
export type ui_ViewAutomationDiscoveryPanel_userPreferences$key = {
  readonly " $data"?: ui_ViewAutomationDiscoveryPanel_userPreferences$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_ViewAutomationDiscoveryPanel_userPreferences">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": 20,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": null,
        "direction": "forward",
        "path": [
          "dismissedAutomationDiscoverabilityTemplates"
        ]
      }
    ]
  },
  "name": "ui_ViewAutomationDiscoveryPanel_userPreferences",
  "selections": [
    {
      "alias": "dismissedAutomationDiscoverabilityTemplates",
      "concreteType": "JiraIssueViewPanelAutomationDiscoverabilityDismissedTemplateTypeConnection",
      "kind": "LinkedField",
      "name": "__ui_ViewAutomationDiscoveryPanel_userPreferences_dismissedAutomationDiscoverabilityTemplates_connection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraIssueViewPanelAutomationDiscoverabilityDismissedTemplateTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraIssueViewPanelAutomationDiscoverabilityDismissedTemplateType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "templateId"
                },
                {
                  "kind": "ScalarField",
                  "name": "dismissUntilDateTime"
                },
                {
                  "kind": "ScalarField",
                  "name": "__typename"
                }
              ]
            },
            {
              "kind": "ScalarField",
              "name": "cursor"
            }
          ]
        },
        {
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "endCursor"
            },
            {
              "kind": "ScalarField",
              "name": "hasNextPage"
            }
          ]
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "kind": "ScalarField",
              "name": "__id"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraUserPreferences"
};

(node as any).hash = "e12b30e86520b9d6f84788570a55348c";

export default node;
