import React, { type ComponentType, useMemo, useEffect } from 'react';
import { compose } from 'redux';
import type { ForgeCustomFieldValue } from '@atlassian/jira-forge-ui-types/src/common/types/contexts/custom-field.tsx';
import { useFetchIssueCustomFieldData } from '@atlassian/jira-issue-ecosystem-forge/src/services/fetch-issue-custom-field-data/main.tsx';
import { useOnDemandIssueRefreshTime } from '@atlassian/jira-issue-refresh-service/src/services/main.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { fieldInvalidMessageSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import { fieldUpdate } from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-save-actions.tsx';
import type { CustomFieldLegacy } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import { useReportFailure } from './analytics/report-failure/index.tsx';
import { CustomFieldAnalyticsWrapper } from './analytics/wrapper/index.tsx';
import getComponent from './fields/get-forge-custom-fields.tsx';
import type { FieldProps } from './types.tsx';
import { ForgeCustomFieldView } from './view.tsx';

export type ForgeCustomFieldProps = FieldProps<ForgeCustomFieldValue>;

export const ForgeCustomField = (props: ForgeCustomFieldProps) => {
	const { fieldId } = props;
	const { loading, data } = useFetchIssueCustomFieldData({ fieldId });
	const refreshedOn = useOnDemandIssueRefreshTime();
	const { shouldReportFailure, reportFailure } = useReportFailure(
		data ? data.extension : null,
		fieldId,
		data?.fieldExistsInJira,
		data?.isFilteredOut,
		!!data,
	);

	useEffect(() => {
		if (shouldReportFailure) {
			reportFailure();
		}
	}, [shouldReportFailure, reportFailure]);

	const extension = data ? data.extension : null;
	const ForgeField = useMemo(() => getComponent(extension), [extension]);

	if (loading || !data || !extension || data?.isFilteredOut || !ForgeField) {
		return null;
	}

	const { shouldUseFormatter, extensionData, fieldName } = data;

	return (
		<CustomFieldAnalyticsWrapper extension={extension} key={refreshedOn}>
			<ForgeCustomFieldView
				{...props}
				shouldUseFormatter={shouldUseFormatter}
				// This is because ForgeCustomFieldView uses componentWithFG which merges types and only the old type matches the merged type.
				// This will be removed with removing 'ditto-fcf-support-new-manifest-on-frontend' feature flag.
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				extension={extension as CustomFieldLegacy}
				extensionData={extensionData}
				fieldId={fieldId}
				fieldName={fieldName}
				ForgeField={ForgeField}
			/>
		</CustomFieldAnalyticsWrapper>
	);
};

export default compose<ComponentType<ForgeCustomFieldProps>>(
	connectField((stateOnMount, { fieldId, area }) => ({
		fieldId,
		additionalProps: (state) => ({
			showPinButton: getShowPinButton(area),
			invalidMessage: fieldInvalidMessageSelector(fieldId)(state),
		}),
	})),
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	connect(null, (dispatch, ownProps: any) => ({
		onSave: (value: unknown) =>
			dispatch(fieldUpdate(ownProps.fieldId, value, ownProps.fieldOptions)),
	})),
)(ForgeCustomField);
