// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { AlertId } from '../../../common/types.tsx';
import type { State } from '../../types.tsx';

export const onAlertCheckboxChanged =
	(alertId: AlertId, isSelected: boolean) =>
	({ getState, setState }: StoreActionApi<State>) => {
		const { linkModalAlerts, selectedAlertsToLinkIds } = getState();
		const { data } = linkModalAlerts;

		if (!data?.results) return;

		if (!isSelected) {
			const updatedAlerts = selectedAlertsToLinkIds.filter(
				(selectedAlertId) => selectedAlertId !== alertId,
			);

			setState({
				selectedAlertsToLinkIds: updatedAlerts,
			});
		} else if (!selectedAlertsToLinkIds.includes(alertId)) {
			const updatedAlerts = [...selectedAlertsToLinkIds, alertId];

			setState({
				selectedAlertsToLinkIds: updatedAlerts,
			});
		}
	};
