import React from 'react';
import WhiteboardIcon from '@atlaskit/icon/core/whiteboard';
import { token } from '@atlaskit/tokens';
import Whiteboard16IconLegacy from '@atlassian/jira-icons/src/ui/whiteboard-icon/index.tsx';

export const Whiteboard16Icon = ({
	color = token('color.icon.accent.teal'),
}: {
	color?: string;
	// @ts-expect-error - TS-2322 - The expected type comes from property 'color' which is declared here on type 'IntrinsicAttributes & NewCoreIconProps'
}) => <WhiteboardIcon color={color} label="" LEGACY_fallbackIcon={Whiteboard16IconLegacy} />;
