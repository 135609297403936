import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import timeMessages from '../../../../../messages/time.tsx';

const TimeTrackingHelperText = () => {
	const { formatMessage } = useIntl();
	return (
		<HelperContainer id="time-tracking-formatting-text">
			{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
			<p>
				{formatMessage(timeMessages.exampleFormat, {
					formatExample: '2w 4d 6h 45m',
				})}
			</p>
			<HelperList>
				<li>{formatMessage(timeMessages.weeks, { w: 'w' })}</li>
				<li>{formatMessage(timeMessages.days, { d: 'd' })}</li>
				<li>{formatMessage(timeMessages.hours, { h: 'h' })}</li>
				<li>{formatMessage(timeMessages.minutes, { m: 'm' })}</li>
			</HelperList>
		</HelperContainer>
	);
};

export default TimeTrackingHelperText;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HelperContainer = styled.div({
	paddingTop: token('space.075'),
	paddingRight: token('space.025'),
	paddingBottom: token('space.075'),
	paddingLeft: token('space.025'),
	color: token('color.background.neutral.bold'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HelperList = styled.ul({
	listStyle: 'inside',
	marginTop: token('space.100'),
	paddingLeft: token('space.050'),
	paddingBottom: token('space.050'),
});
