import type { ViewIdentifiers } from '@atlassian/jira-polaris-component-view-id-mapping/src/types.tsx';
import type { ViewUUID } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { getViewLastSeen } from '../../../../services/jpd-views-service/fetch-view-last-seen/index.tsx';
import type { State } from '../../types.tsx';

const handleErrorState =
	(viewUUID: ViewUUID, { getState, setState }: StoreActionApi<State>) =>
	(error: Error) => {
		setState({
			lastSeen: {
				...getState().lastSeen,
				[viewUUID]: {
					loading: false,
					data: {},
					error,
				},
			},
		});
	};

const setViewsLastSeenState =
	(viewUUID: ViewUUID, { getState, setState }: StoreActionApi<State>) =>
	(data: Record<AccountId, Date> | Error) => {
		if (data instanceof Error) {
			throw data;
		}
		setState({
			lastSeen: {
				...getState().lastSeen,
				[viewUUID]: {
					loading: false,
					data,
				},
			},
		});
	};

const loadFromViewsService = (viewIds: ViewIdentifiers) => {
	const loadAllPages = (nextPageKey?: string): Promise<Record<AccountId, Date>> =>
		getViewLastSeen(viewIds.viewUUID, 100, nextPageKey).then(async (raw) => {
			const data: Record<AccountId, Date> = {};
			raw.data.forEach((lv) => {
				data[lv.aaid] = new Date(lv.timestamp);
			});

			const additionalPages = raw.pagination?.nextPageKey
				? await loadAllPages(raw.pagination.nextPageKey)
				: {};

			return {
				...data,
				...additionalPages,
			};
		});

	return loadAllPages();
};

export const loadViewsLastSeen =
	(viewIds: ViewIdentifiers | undefined) => async (api: StoreActionApi<State>) => {
		const { setState, getState } = api;

		if (!viewIds) {
			return;
		}

		if (
			getState().lastSeen[viewIds.viewUUID]?.loading ||
			getState().lastSeen[viewIds.viewUUID]?.data
		) {
			return;
		}

		setState({
			lastSeen: {
				...getState().lastSeen,
				[viewIds.viewUUID]: { loading: true, data: {} },
			},
		});

		loadFromViewsService(viewIds)
			.then(setViewsLastSeenState(viewIds.viewUUID, api))
			.catch(handleErrorState(viewIds.viewUUID, api));
	};
