import { useCallback, useEffect, useState } from 'react';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import {
	dismissConfluencePlaceholderTemplate,
	getHasDismissedConfluencePlaceholderTemplate,
} from '../services/ad-controls.tsx';
import type { Sources } from '../common.tsx';
import type { PlaceholderTargeting } from './targeting.tsx';

export const useAdControls = (targeting: PlaceholderTargeting, source: Sources) => {
	const accountId = useAccountId();
	const [hasDismissed, setHasDismissed] = useState(true);

	const fetchHasDismissed = useCallback(async () => {
		if (accountId && targeting) {
			const fetchedHasDismissed = await getHasDismissedConfluencePlaceholderTemplate(
				accountId,
				targeting,
				source,
			);
			setHasDismissed(fetchedHasDismissed);
		} else {
			setHasDismissed(true);
		}
	}, [accountId, targeting, source]);

	const onDismissPlaceholderTemplate = async () => {
		if (accountId && targeting) {
			dismissConfluencePlaceholderTemplate(accountId, targeting, source);
			setHasDismissed(true);
		}
	};

	useEffect(() => {
		fetchHasDismissed();
	}, [fetchHasDismissed]);

	return { hasDismissed, onDismissPlaceholderTemplate };
};
