import { useCallback } from 'react';
import type {
	JiraTimeFormat,
	JiraTimeUnit,
} from '@atlassian/jira-relay/src/__generated__/originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditViewNew_timeTrackingSettingsfragmentRef.graphql';
import type { TimeFormat, TimeUnit } from '@atlassian/jira-time-tracking-formatter/src/types.tsx';
import { timeTrackingFormatter } from '@atlassian/jira-time-tracking-formatter/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { TimeTrackingSettingsInput } from './types.tsx';
import {
	DEFAULT_TIME_FORMAT,
	DEFAULT_TIME_UNIT,
	DEFAULT_WORKING_DAYS_PER_WEEK,
	DEFAULT_WORKING_HOURS_PER_DAY,
} from './constants.tsx';
import { toLowercaseTimeUnion } from './common.tsx';

const toTimeFormat = (format: JiraTimeFormat | null | undefined): TimeFormat => {
	return format ? toLowercaseTimeUnion(format) : DEFAULT_TIME_FORMAT;
};
const toTimeUnit = (unit: JiraTimeUnit | null | undefined): TimeUnit => {
	return unit ? toLowercaseTimeUnion(unit) : DEFAULT_TIME_UNIT;
};

export const useFormatInputValue = ({
	workingHoursPerDay,
	workingDaysPerWeek,
	defaultFormat,
	defaultUnit,
}: TimeTrackingSettingsInput) => {
	const intl = useIntl();

	return useCallback(
		(value: number): string => {
			return timeTrackingFormatter(
				value,
				{
					workingHoursPerDay: workingHoursPerDay || DEFAULT_WORKING_HOURS_PER_DAY,
					workingDaysPerWeek: workingDaysPerWeek || DEFAULT_WORKING_DAYS_PER_WEEK,
					timeFormat: toTimeFormat(defaultFormat),
					defaultUnit: toTimeUnit(defaultUnit),
				},
				intl,
			);
		},
		[workingHoursPerDay, workingDaysPerWeek, defaultFormat, defaultUnit, intl],
	);
};
