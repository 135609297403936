import parameters, {
	type srcAsyncEditIssueTypeQuery$variables,
} from '@atlassian/jira-relay/src/__generated__/srcAsyncEditIssueTypeQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const asyncEditIssueTypeModalEntrypoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-edit-issue-type-modal-entrypoint" */ './src').then(
			(module) => module.AsyncEditIssueTypeModal,
		),
	),
	getPreloadProps: ({ projectKey, cloudId }: srcAsyncEditIssueTypeQuery$variables) => ({
		queries: {
			editIssueTypeModal: {
				options: { fetchPolicy: 'store-and-network' as const },
				parameters,
				variables: { projectKey, cloudId },
			},
		},
	}),
});
