import React, { useCallback, useMemo } from 'react';
import { useScreenTabs as useUiModificationsScreenTabs } from '@atlassian/jira-issue-adjustments/src/controllers.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type {
	IssueViewRelayFragment,
	MainIssueAggQueryRelayFragment,
} from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { useIssueFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { useFieldsValues } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import {
	CONTENT_AREA,
	isLayoutContainerTabItem,
	type LayoutContainerTemplateItem,
} from '@atlassian/jira-issue-layout-common-constants/src/index.tsx';
import { ItemList } from '@atlassian/jira-issue-view-layout-templates-item-list/src/index.tsx';
import { TabView } from '@atlassian/jira-issue-view-layout-templates-tab-view/src/ui/view.tsx';
import {
	extractTabArray,
	isTabFieldVisibleUtil,
} from '@atlassian/jira-issue-view-layout-templates-tab-view/src/utils/index.tsx';
import { getLayoutFieldTypes } from '@atlassian/jira-platform-field-config/src/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';

type Props = {
	// TODO Decomp BENTO-12514 - add useFragment to this component and replace this prop with more specific fragment key
	issueViewRelayFragment?: IssueViewRelayFragment | null;
	items: LayoutContainerTemplateItem[];
	rootRelayFragment: MainIssueAggQueryRelayFragment | null;
};

export const ContentSectionView = ({ items, issueViewRelayFragment, rootRelayFragment }: Props) => {
	const issueKey = useIssueKey();
	const [{ value: issueFieldsConfig }] = useIssueFieldConfig(issueKey);
	const [issueFieldsValues] = useFieldsValues(issueKey);
	const layoutFieldTypes = getLayoutFieldTypes();

	const isTabFieldVisible = useCallback(
		(item: LayoutContainerTemplateItem) =>
			isTabFieldVisibleUtil({ item, issueFieldsConfig, issueFieldsValues, layoutFieldTypes }),
		[issueFieldsConfig, issueFieldsValues, layoutFieldTypes],
	);

	/**
	 * items can either have tabs or fieldItems but never both at the same time
	 * i.e. JiraIssueItemTabContainer can be in items
	 * so can multiple JiraIssueItemFieldItem
	 * but never JiraIssueItemFieldItem and JiraIssueItemTabContainer since if there are multiple tabs, each field should be in a tab not by itself.
	 * See JRACLOUD-76139 for more information.
	 */
	const tabItems = items.filter(isLayoutContainerTabItem);
	const tabArray = useMemo(
		() =>
			extractTabArray({
				items: tabItems,
				area: CONTENT_AREA,
				issueViewRelayFragment,
				isTabFieldVisible,
				ItemListComponent: ItemList,
				rootRelayFragment,
			}),
		[tabItems, issueViewRelayFragment, isTabFieldVisible, rootRelayFragment],
	);

	// Forge UI Modifications need to have this to initialize when there's no tab
	useUiModificationsScreenTabs({
		tabs: tabArray,
	});

	if (tabArray.length > 0) {
		return (
			<UFOSegment name="issue-tabs">
				<TabView tabArray={tabArray} />
			</UFOSegment>
		);
	}

	return (
		<UFOSegment name="issue-content-fields">
			<ItemList
				items={items}
				area={CONTENT_AREA}
				layoutItemsDataFragment={issueViewRelayFragment ?? null}
				rootRelayFragment={rootRelayFragment}
			/>
		</UFOSegment>
	);
};
