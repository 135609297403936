import React, { type PropsWithChildren } from 'react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createContainer,
	createHook,
	createStore,
	createStateHook,
	createActionsHook,
} from '@atlassian/react-sweet-state';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useEnvironment } from '@atlassian/jira-tenant-context-controller/src/components/environment/index.tsx';
import { useProjectId, useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { actions } from './actions/index.tsx';
import { INITIAL_STATE } from './constants.tsx';
import type { ContainerProps, State, StoreContainerProps } from './types.tsx';

const RecurWorkItemStoreContainer = createContainer<StoreContainerProps>();

export const RecurWorkItemContainer = ({
	children,
	issueKey,
}: PropsWithChildren<ContainerProps>) => {
	const cloudId = useCloudId();
	const environment = useEnvironment();
	const projectKey = useProjectKey(issueKey);
	const projectId = useProjectId(projectKey);
	const { data: currentUser } = useCurrentUser();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	if (!projectId) {
		log.safeErrorWithoutCustomerData(
			'jira-recur-work-item.src.controllers.RecurWorkItemContainer',
			'Error initialising RecurWorkItemContainer, could not retrieve projectId',
		);
		return children;
	}

	return (
		<RecurWorkItemStoreContainer
			scope={`recur-${issueKey}`}
			issueKey={issueKey}
			environment={environment}
			cloudId={cloudId}
			currentUserAccountId={currentUser.user.accountId}
			projectId={projectId}
			createAnalyticsEvent={createAnalyticsEvent}
		>
			{children}
		</RecurWorkItemStoreContainer>
	);
};

export const Store = createStore<State, typeof actions, StoreContainerProps>({
	name: 'RecurWorkItemStore',
	initialState: INITIAL_STATE,
	actions,
	containedBy: RecurWorkItemStoreContainer,
	handlers: {
		onInit:
			() =>
			async ({ dispatch }) => {
				await dispatch(actions.fetch());
			},
	},
});

export const useRecurWorkItem = createHook(Store);
export const useRecurWorkItemState = createStateHook(Store);
export const useRecurWorkItemActions = createActionsHook(Store);
