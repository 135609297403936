import type { RefObject } from 'react';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { KeyPhraseCategory } from '@atlassian/search-ai';
import { resetUnderlineStyle } from '../use-acronym-highlighter/utils.tsx';
import type { PopupType, State } from './types.tsx';
import { calculateTriggerPosition } from './utils.tsx';

export const actions = {
	setContainerRef:
		(ref: RefObject<HTMLDivElement | null>): Action<State> =>
		({ setState }) =>
			setState({ containerRef: ref }),
	setTriggerPosition:
		(mouseX: number, trigger?: HTMLElement, popupType?: PopupType): Action<State> =>
		({ setState, getState }) => {
			const { containerRef } = getState();
			if (!containerRef.current) {
				return;
			}
			const { position: triggerPosition, selectedText } =
				calculateTriggerPosition(containerRef.current, mouseX, trigger) || {};
			const activePopup = selectedText ? popupType || 'action-menu' : undefined;
			setState({ triggerPosition, selectedText, activePopup });
		},
	toggleAcronymDialog:
		(mouseX: number, trigger?: HTMLElement): Action<State> =>
		({ setState, getState, dispatch }) => {
			const { containerRef, triggerPosition } = getState();
			if (!containerRef.current) {
				return;
			}
			if (triggerPosition) {
				dispatch(actions.resetTriggerPosition());
				return;
			}
			const { position, selectedText } =
				calculateTriggerPosition(containerRef.current, mouseX, trigger) || {};
			setState({
				triggerPosition: position,
				selectedText,
				activePopup: 'acronyms-dialog',
				isAutohighlighted: true,
			});
		},
	resetTriggerPosition:
		(): Action<State> =>
		({ setState }) => {
			setState({ triggerPosition: undefined, selectedText: undefined });
		},
	openAnswerDialog:
		(): Action<State> =>
		({ setState, getState }) => {
			if (!getState().selectedText) {
				return;
			}
			setState({ activePopup: 'answer-dialog', isAutohighlighted: false });
		},
	setTargetRef:
		(ref: HTMLElement | null): Action<State> =>
		({ setState }) =>
			setState({ targetRef: ref }),
	setFieldType:
		(fieldType: string | undefined): Action<State> =>
		({ setState }) =>
			setState({ fieldType }),
	resetTargetRef:
		(): Action<State> =>
		({ getState, setState }) => {
			const { targetRef } = getState();

			if (targetRef) {
				resetUnderlineStyle(targetRef);
				setState({ targetRef: null });
			}
		},
	setKeyPhraseCategory:
		(keyPhraseCategory: KeyPhraseCategory): Action<State> =>
		({ setState }) =>
			setState({ keyPhraseCategory }),
} as const;

export type Actions = typeof actions;
