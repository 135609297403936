import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../../types.tsx';
import { getAutosaveAffectedViewConfigurations } from '../../utils/autosave.tsx';
import { updateViewState } from '../../utils/state/index.tsx';
import { currentViewFilter } from '../../utils/views/index.tsx';

export const resetDraft =
	(viewId?: string): Action<State, Props> =>
	({ getState, setState }, { currentViewSlug }) => {
		const state = getState();
		const { changedView, viewSets } = updateViewState(
			state.viewSets,
			viewId ? (view) => view.viewId === viewId : currentViewFilter(currentViewSlug),
			(view: View): View => ({
				...view,
				draft: getAutosaveAffectedViewConfigurations(view),
			}),
		);

		if (changedView) {
			setState({ viewSets });
		}
	};
