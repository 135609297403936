import type { IssueAction } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import type { ForgeResponse } from '@atlassian/jira-issue-fetch-services/src/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';

export const FORGE_ENTITY_TYPE = 'FORGE_ENTITY_TYPE';

type ForgeEntityType = typeof FORGE_ENTITY_TYPE;

type ForgeActionType = ForgeEntityType;

type Action = {
	id: string;
	title: string;
	extension: IssueAction;
	type: ForgeActionType;
};

type ActiveAction = {
	action: Action;
	isLoading: boolean;
};

export type State = ForgeResponse & {
	activeAction: ActiveAction | null;
	isForgeLoadingFailed: boolean;
	isForgeDataComplete: boolean;
	forgeRateLimitedUntil: Millisecond;
	failedEventsCount: number;
};

export type StoreApi = StoreActionApi<State>;

export type ForgeServiceActions = {
	setForge: (
		state: ForgeResponse & { isForgeLoadingFailed: boolean; isForgeDataComplete: boolean },
	) => void;
	setForgeLoadingFailed: () => void;
};
