import type { ProfileCardAction, ProfileCardClientData } from '@atlaskit/profilecard';
import type { IntlShape } from '@atlassian/jira-intl';
import {
	REPORTED_ISSUE_ACTION,
	ASSIGNED_ISSUE_ACTION,
	VIEW_PROFILE_ACTION,
	VIEW_FILTERS_ACTION,
} from '../common/constants.tsx';
import { profileCardMessages } from '../messages.tsx';
import type { Actions, JiraV3UserApiRestResponse } from '../types.tsx';

export const restResponseToUserData = (
	userData: JiraV3UserApiRestResponse,
): ProfileCardClientData => ({
	avatarUrl: userData.avatarUrls?.['48x48'],
	email: userData.emailAddress,
	fullName: userData.displayName,
	isBot: false,
	isCurrentUser: false,
	status: userData.active ? 'active' : 'inactive',
	accountType: userData.accountType,
});

/**
 * @deprecated Check the accountType instead
 * check if the account is a customer account
 */
export const isCustomerAccount = (accountId: string): boolean =>
	accountId ? accountId.lastIndexOf('qm:', 0) === 0 : false;

const getSpaViewProfileAction = (
	intl: IntlShape,
	baseUrl: string,
	accountId: string,
	onPushAction: (href: string) => void,
	onActionCleanup: undefined | (() => void),
) => ({
	label: intl && intl.formatMessage(profileCardMessages.viewProfile),
	id: 'view-profile',
	callback: () => {
		onPushAction(`${baseUrl}/jira/people/${accountId}`);
		onActionCleanup && onActionCleanup();
	},
	link: `${baseUrl}/jira/people/${accountId}`,
	shouldRender: (data: ProfileCardClientData) =>
		/*
		 * Those without @atlassian.com accounts do not have viewable profiles
		 */
		data?.accountType !== 'customer',
});

const getAssignedIssuesAction = (intl: IntlShape, baseUrl: string, accountId: string) => ({
	label: intl && intl.formatMessage(profileCardMessages.assignedIssues),
	id: 'assigned-issues',
	callback: () => {
		const jql = encodeURIComponent(`assignee="${accountId || ''}" AND resolution is empty`);
		const url = `${baseUrl}/issues/?jql=${jql}`;

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.open(url, '_blank');
	},
});

const getReportedIssuesAction = (intl: IntlShape, baseUrl: string, accountId: string) => ({
	label: intl && intl.formatMessage(profileCardMessages.reportedIssues),
	id: 'reported-issues',
	callback: () => {
		const jql = encodeURIComponent(`reporter="${accountId || ''}" order by created DESC`);
		const url = `${baseUrl}/issues/?jql=${jql}`;

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.open(url, '_blank');
	},
});

const getViewFiltersAction = (intl: IntlShape, accountId: string) => ({
	label: intl && intl.formatMessage(profileCardMessages.viewFilters),
	id: 'view-filters',
	callback: () => {
		const url = `/jira/filters?owner=${encodeURIComponent(accountId)}`;

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.open(url, '_blank');
	},
});

export const getSpaActions = (
	accountId: string,
	baseUrl: string,
	intl: IntlShape,
	onPushAction: (href: string) => void,
	actions?: Actions[],
	onActionCleanup?: () => void,
): ProfileCardAction[] => {
	if (actions) {
		return actions.map((action: Actions) => {
			switch (action) {
				case VIEW_PROFILE_ACTION:
					return getSpaViewProfileAction(intl, baseUrl, accountId, onPushAction, onActionCleanup);
				case ASSIGNED_ISSUE_ACTION:
					return getAssignedIssuesAction(intl, baseUrl, accountId);
				case REPORTED_ISSUE_ACTION:
					return getReportedIssuesAction(intl, baseUrl, accountId);
				case VIEW_FILTERS_ACTION:
					return getViewFiltersAction(intl, accountId);
				default:
					throw new Error('unexpected action');
			}
		});
	}

	return [getSpaViewProfileAction(intl, baseUrl, accountId, onPushAction, onActionCleanup)];
};
