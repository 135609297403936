import React, { type ReactNode, useState } from 'react';
import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';
import { IssueSelect as IssueSelectRaw } from '@atlassian/jira-polaris-lib-issue-select/src/ui/index.tsx';
import type { SelectedProject } from '../../../common/ui/project-picker/option/index.tsx';
import { useArchivedFieldsConfig } from '../../../controllers/field/selectors/field-hooks.tsx';
import { useSelectedIssueKey } from '../../../controllers/issue/selectors/properties/hooks.tsx';
import type { GroupedOption, IssueOption } from './types.tsx';

type IssueSelectProps = {
	isDisabled: boolean;
	autoFocus: boolean;
	isAttachedToBody: boolean;
	projectId: string | undefined;
	excludedProjectTypes: ProjectType[];
	defaultOptions?: GroupedOption;
	hideArchived: boolean;
	isIdeasSelect?: boolean;
	placeholder?: string;
	excludedIssueIds?: number[];
	onIssueSelected: (arg1: IssueOption | undefined) => void;
	renderOptionIconAfter?: (option: IssueOption) => ReactNode;
	onProjectChanged?: (payload: SelectedProject | undefined) => void;
};

const EXCLUDED_ISSUE_IDS: IssueSelectProps['excludedIssueIds'] = [];

export const IssueSelect = ({
	isDisabled,
	autoFocus,
	projectId,
	excludedProjectTypes,
	excludedIssueIds = EXCLUDED_ISSUE_IDS,
	hideArchived,
	isIdeasSelect,
	placeholder,
	defaultOptions,
	onIssueSelected,
	isAttachedToBody,
	renderOptionIconAfter,
	onProjectChanged,
}: IssueSelectProps) => {
	const [selectedIssue, setSelectedIssue] = useState<IssueOption | undefined>(undefined);
	const selectedIssueKey = useSelectedIssueKey();
	const [{ archivedField }] = useArchivedFieldsConfig();

	return (
		<IssueSelectRaw
			archivedFieldLabel={archivedField?.label}
			selectedIssueKey={selectedIssueKey}
			isDisabled={isDisabled}
			autoFocus={autoFocus}
			placeholder={placeholder}
			defaultOptions={defaultOptions}
			isAttachedToBody={isAttachedToBody}
			selectedIssue={selectedIssue}
			setSelectedIssue={setSelectedIssue}
			projectId={projectId}
			excludedProjectTypes={excludedProjectTypes}
			onIssueSelected={onIssueSelected}
			excludedIssueIds={excludedIssueIds}
			renderOptionIconAfter={renderOptionIconAfter}
			hideArchived={hideArchived}
			isIdeasSelect={isIdeasSelect}
			onProjectChanged={onProjectChanged}
		/>
	);
};
