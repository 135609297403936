import React, { useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { Inline, xcss } from '@atlaskit/primitives';
import Select, {
	type OptionProps,
	type LoadingIndicatorProps,
	type ValueContainerProps,
	components,
} from '@atlaskit/select';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import {
	fireTrackAnalytics,
	useAnalyticsEvents,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { LabelIcon } from '@atlassian/jira-servicedesk-customer-service-common/src/ui/label-icon/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { OrganizationOption } from '../../types.tsx';
import { editOrganizationFieldIssueViewExperience } from '../../../experiences.tsx';
import messages from './messages.tsx';
import { mapDataToOrganizationOptions } from './utils.tsx';

export type Props = {
	onChange: (newValue: OrganizationOption | null) => void;
	isDropdownMenuFixedAndLayered?: boolean;
	isInvalid?: boolean;
	autoFocus?: boolean;
	openMenuOnFocus?: boolean;
	value?: OrganizationOption;
	classNamePrefix?: string;
	onCloseMenuOnScroll?: (e: Event) => boolean;
	spacing?: 'compact' | 'default';
	searchUrl?: string;
	'aria-label'?: string;
};

export const FETCH_DEBOUNCE_TIME = 300;
// we debounce the success event firing, to more accurately represent a complete search
// as opposed to a search which is triggered mid-way through a user's search term
export const SEARCH_SUCCESS_DEBOUNCE_TIME = 2000;

const getNoOptionsMessage = (error: boolean, inputValue?: string) => {
	if (error) return messages.organizationFieldErrorState;

	return inputValue ? messages.organizationFieldNoResults : messages.organizationFieldNoOptions;
};

const LoadingIndicator = (props: LoadingIndicatorProps<OrganizationOption>) => (
	<Spinner {...props} size="small" />
);

const ValueContainerOld = ({
	children,
	...props
}: ValueContainerProps<OrganizationOption, false>) => (
	<components.ValueContainer {...props}>
		<Inline xcss={iconStyles}>
			<LabelIcon size="xsmall" type="ORGANIZATION" />
			{children}
		</Inline>
	</components.ValueContainer>
);

const ValueContainer = ({ children, ...props }: ValueContainerProps<OrganizationOption, false>) => (
	<components.ValueContainer {...props}>
		<Inline space="space.100" alignBlock="center" xcss={styles}>
			<LabelIcon size="xsmall" type="ORGANIZATION" />
			{children}
		</Inline>
	</components.ValueContainer>
);

const Option = ({ children, ...props }: OptionProps<OrganizationOption>) => (
	<components.Option {...props}>
		<Inline space="space.100" alignBlock="center">
			<LabelIcon size="xsmall" type="ORGANIZATION" />
			{children}
		</Inline>
	</components.Option>
);

export const EditView = ({
	onChange,
	isDropdownMenuFixedAndLayered,
	isInvalid,
	autoFocus,
	openMenuOnFocus,
	value,
	classNamePrefix,
	onCloseMenuOnScroll,
	spacing = 'compact',
	searchUrl,
	...fieldProps
}: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [hasError, setHasError] = useState(false);

	const debouncedFireSearchSuccessEvent = debounce(
		() => fireTrackAnalytics(createAnalyticsEvent({}), 'jcsOrganizationFieldSearch succeeded'),
		SEARCH_SUCCESS_DEBOUNCE_TIME,
		{ leading: true, trailing: false }, // fire event on the leading edge of the debounce
	);

	const searchOrganizations = useCallback(
		(query = '', callback: (arg1: OrganizationOption[]) => void) => {
			setHasError(false);
			performGetRequest(`${searchUrl}?query=${encodeURIComponent(query)}`)
				.then((response) => {
					debouncedFireSearchSuccessEvent();
					callback(mapDataToOrganizationOptions(response));
				})
				.catch((error) => {
					fireErrorAnalytics({
						meta: {
							id: 'searchOrganizations',
							packageName: 'servicedeskCustomerServiceCustomFields',
							teamName: 'boysenberry',
						},
						error,
						sendToPrivacyUnsafeSplunk: true,
					});
					setHasError(true);
					callback([]);
				});
		},
		[debouncedFireSearchSuccessEvent, searchUrl],
	);

	const loadOptionsDebounced = debounce(searchOrganizations, FETCH_DEBOUNCE_TIME);

	return (
		<Select<OrganizationOption, false>
			{...fieldProps}
			inputId="organization"
			loadOptions={loadOptionsDebounced}
			value={value}
			cacheOptions
			placeholder={formatMessage(messages.organizationFieldPlaceholder)}
			autoFocus={autoFocus}
			openMenuOnFocus={openMenuOnFocus}
			spacing={spacing}
			styles={{
				valueContainer: (base) => ({
					...base,
					display: 'flex',
					alignItems: 'center',
					backgroundColor: token('color.background.input'),
				}),
				// Makes sure the cursor is directly to the right of the selected text
				singleValue: (base) => ({
					...base,
					marginRight: 0,
					marginLeft: 0,
				}),
				input: (base) => ({
					...base,
					marginLeft: 0,
				}),
				container: (base) => ({
					...base,
					minWidth: '200px',
				}),
				menu: (base) => ({
					...base,
					zIndex: layers.modal,
				}),
			}}
			components={{
				ValueContainer: fg('jcs-issue-view-truncate-reporter') ? ValueContainer : ValueContainerOld,
				Option,
				LoadingIndicator,
			}}
			noOptionsMessage={({ inputValue }) => (
				<>{formatMessage(getNoOptionsMessage(hasError, inputValue))}</>
			)}
			onChange={(newValue) => {
				editOrganizationFieldIssueViewExperience.start();
				onChange(newValue);

				if (value?.value !== newValue?.value) {
					fireUIAnalytics(
						createAnalyticsEvent({
							action: 'changed',
							actionSubject: 'organizationField',
						}),
						{ hasValue: !!newValue },
					);
				}
			}}
			classNamePrefix={classNamePrefix}
			closeMenuOnScroll={onCloseMenuOnScroll}
			menuPosition={isDropdownMenuFixedAndLayered === true ? 'fixed' : undefined}
			isInvalid={isInvalid}
			isClearable
		/>
	);
};

const iconStyles = xcss({
	marginRight: 'space.100',
	marginLeft: 'space.050',
});

const styles = xcss({
	width: '100%',
	minWidth: '0px',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	paddingInline: 'space.050',
});
