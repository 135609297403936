/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import Button from '@atlaskit/button/new';
import ArrowDownIcon from '@atlaskit/icon/core/arrow-down';
import { layers } from '@atlaskit/theme/constants';
import { SlideIn } from '@atlaskit/motion';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export const NewCommentsFloatingButton = ({
	unreadCommentsCount,
	onClick,
}: {
	unreadCommentsCount: number | undefined;
	onClick: () => void;
}) => {
	const { formatMessage } = useIntl();

	return (
		<SlideIn enterFrom="bottom" fade="inout">
			{(props) => (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop
				<div ref={props.ref} className={props.className} css={containerStyles}>
					<Button
						testId="issue-activity-feed.ui.new-comments-floating-button.button"
						appearance="primary"
						iconBefore={ArrowDownIcon}
						onClick={onClick}
					>
						{`${formatMessage(messages.newComments, { unreadCommentsCount })}`}
					</Button>
				</div>
			)}
		</SlideIn>
	);
};

const containerStyles = css({
	// both the content and context panel become scrollable for width < 1024 so it's not possible to have a sticky element
	// and we use fixed positioning instead
	'@media (min-width: 1024px)': {
		position: 'sticky',
	},
	'@media (max-width: 1024px)': {
		position: 'fixed',
	},
	zIndex: layers.layer(),
	left: 0,
	right: 0,
	bottom: token('space.300'),
	display: 'flex',
	justifyContent: 'center',
});
