import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Container = styled.ul({
	boxShadow: token('elevation.shadow.raised'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('elevation.surface.raised'),
	borderRadius: '3px',
	paddingTop: token('space.025'),
	paddingRight: 0,
	paddingBottom: token('space.025'),
	paddingLeft: 0,
	width: '100%',
});
