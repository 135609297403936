import React from 'react';
import { withMetrics } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import type { LazyRenderer } from '@atlassian/jira-forge-ui-async/src/types.tsx';
import {
	CONTEXT_TOKEN_TYPE_JIRA,
	UI_MODIFICATIONS_MODULE,
} from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import type { ForgeUIExtension } from '@atlassian/jira-forge-ui-renderers/src/ui/forge-ui-extension/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { ForgeAppRendererProps } from '@atlassian/ui-modifications-core/types';

const getModule = () =>
	import(
		/* webpackChunkName: "async-forge-ui-extension" */ '@atlassian/jira-forge-ui-renderers/src/ui/forge-ui-extension'
	);

export const LazyForgeUIExtension: LazyRenderer<typeof ForgeUIExtension> = withMetrics(
	// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
	lazyForPaint(() => getModule().then((module) => module.ForgeUIExtension), {
		ssr: false,
	}),
);

export const ForgeAppRenderer = ({
	extension,
	extensionData,
	customBridgeMethods,
	onIframeLoad,
	isIframeHidden,
	isIframeResizable,
	onConsentSuccess,
	onConsentInitialization,
	consentMessage,
}: ForgeAppRendererProps) => {
	return (
		<LazyForgeUIExtension
			contextToken={CONTEXT_TOKEN_TYPE_JIRA}
			module={UI_MODIFICATIONS_MODULE}
			extension={extension}
			customBridgeMethods={customBridgeMethods}
			onLoad={onIframeLoad}
			extensionData={extensionData}
			isIframeResizable={isIframeResizable}
			isIframeHidden={isIframeHidden}
			onConsentSuccess={onConsentSuccess}
			onConsentInitialization={onConsentInitialization}
			consentMessage={consentMessage}
		/>
	);
};
