import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

export type Props = {
	services: string[];
};

export const ServicesList = ({ services }: Props) => (
	<ListWrapper data-testid="issue-history.ui.history-items.history-item.affected-services-history-item.services-list">
		{services.map((serviceName: string) => (
			<Value
				data-testid="issue-history.ui.history-items.history-item.affected-services-history-item.services-list.value"
				key={serviceName}
			>
				{serviceName}
			</Value>
		))}
	</ListWrapper>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ListWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Value = styled.span({
	overflowX: 'auto',
	marginTop: token('space.050'),
	marginRight: token('space.050'),
	marginBottom: token('space.050'),
	marginLeft: token('space.050'),
});
