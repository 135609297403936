import React, { Component, memo, type ReactNode, type MouseEvent, type ReactElement } from 'react';
import { styled } from '@compiled/react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { DevOpsSummaryContainer } from '@atlassian/jira-issue-dev-ops-summary/src/ui/dev-ops-summary-container/index.tsx';
import { ItemLineCardGroup } from '@atlassian/jira-issue-item-line-card/src/ui/index.tsx';
import type { RelatedIssue } from '@atlassian/jira-issue-line-card/src/common/types.tsx';
import { associatedIssueErrorTypes as errorTypes } from '@atlassian/jira-issue-shared-types/src/common/types/associated-issue-type.tsx';
import {
	DEFAULT_ASSIGNEE_PERMISSION_ERROR_MESSAGE,
	DEFAULT_ASSIGNEE_PERMISSION_ERROR_MESSAGE_FE,
} from '@atlassian/jira-issue-view-common-constants/src/child-issues.tsx';
import type {
	ChildIssue,
	ErrorType,
} from '@atlassian/jira-issue-view-common-types/src/children-issues-type.tsx';
import {
	isLeftClickOnly,
	isMiddleClick,
	isCmdOrCtrlClick,
} from '@atlassian/jira-issue-view-common-utils/src/events/index.tsx';
import { ASSIGNEE } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import { SmartCardProviderWithAIHooks } from '@atlassian/jira-linking-platform-utils/src/index.tsx';
import {
	type IssueKey,
	type Intl,
	toIssueId,
	toIssueKey,
} from '@atlassian/jira-shared-types/src/general.tsx';
import { isDevopsFeatureDisabledInFedRamp } from '@atlassian/jira-software-devops-fedramp-utils/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import JiraIssueLineCard from '../../../../issue-line-card-content/main.tsx';
import { renderInlineMessage } from '../../../../issue-line-card/view/inline-message/index.tsx';
import messages from '../messages.tsx';
import { getIsAnyPrioritySetInAnyChildIssue } from '../utils.tsx';
import { DragPreview } from './drag-preview/index.tsx';
import MiniTimeLogGraph from './mini-time-log-graph/index.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Container = styled.div({
	marginTop: token('space.100'),
	marginBottom: token('space.100'),
	paddingRight: token('space.025'),
});

export type Props = {
	isClassicSubtaskPanel: boolean;
	isChildrenIssuesPanel: boolean;
	isSimplifiedProject: boolean;
	hasExceededChildIssuesLimitOnLoadOrAfter: boolean;
	shouldDisplayWorklogGraph: boolean;
	allIssues: ChildIssue[];
	parentKey: IssueKey;
	incompleteIssues: ChildIssue[];
	projectType: ProjectType | null;
	hideDone: boolean;
	restrictReordering?: boolean;
} & Intl & {
		onNavigateToNewIssue: (arg1: { fromIssueKey: string; toIssueKey: string }) => void;
		onInlineErrorCreateRetry: (optimisticId: string, analyticsEvent: UIAnalyticsEvent) => void;
		onInlineErrorLinkingRetry: (optimisticId: string, analyticsEvent: UIAnalyticsEvent) => void;
		onInlineErrorCancel: (optimisticId: string) => void;
		onReorderChildren: (
			currentOrder: ChildIssue[],
			optimisticNewOrder: ReadonlyArray<ChildIssue>,
			currentIndex: number,
			newIndex: number,
			analyticsEvent: UIAnalyticsEvent,
		) => void;
		onEditIssue: (issue: ChildIssue) => void;
	};

// eslint-disable-next-line jira/react/no-class-components
class IssuesInternal extends Component<Props> {
	static defaultProps = {
		isClassicSubtaskPanel: false,
		isChildrenIssuesPanel: false,
		shouldDisplayWorklogGraph: false,
		allIssues: [],
		incompleteIssues: [],
		projectType: null,
		restrictReordering: false,
		onReorderChildren: noop,
		onEditIssue: noop,
	};

	onChildIssueClick = (issue: ChildIssue, event: MouseEvent) => {
		if (isMiddleClick(event)) {
			return;
		}
		const nextIssueKey = issue.issueKey;
		if (typeof nextIssueKey === 'string' && isLeftClickOnly(event)) {
			this.props.onNavigateToNewIssue({
				fromIssueKey: this.props.parentKey,
				toIssueKey: nextIssueKey,
			});
		} else if (typeof nextIssueKey === 'string' && isCmdOrCtrlClick(event)) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.open(issue.issueLink, '_blank');
		}
	};

	onOrderChange = (
		optimisticNewOrder: ReadonlyArray<ChildIssue>,
		reorderedIssue: ChildIssue,
		currentIssueIndex: number,
		newIssueIndex: number,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		this.props.onReorderChildren(
			this.props.allIssues,
			optimisticNewOrder,
			currentIssueIndex,
			newIssueIndex,
			analyticsEvent,
		);
	};

	inlineErrorMessage(id: string, errorType: ErrorType, error?: ValidationError | Error) {
		const {
			intl,
			onInlineErrorCreateRetry,
			onInlineErrorLinkingRetry,
			onInlineErrorCancel,
			isClassicSubtaskPanel,
		} = this.props;

		const createChildErrorTitleMessage = fg('jira-issue-terminology-refresh-m3')
			? messages.createChildErrorTitleIssueTermRefresh
			: messages.createChildErrorTitle;

		switch (errorType) {
			case errorTypes.linkChild: {
				return renderInlineMessage(
					intl,
					intl.formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.linkIssueErrorTitleIssueTermRefresh
							: messages.linkIssueErrorTitle,
					),
					intl.formatMessage(messages.linkIssueErrorBody),
					(e, analyticsEvent) => onInlineErrorLinkingRetry(id, analyticsEvent),
					() => onInlineErrorCancel(id),
				);
			}
			case errorTypes.createChild:
			default: {
				const errorTitle = isClassicSubtaskPanel
					? messages.createSubtaskErrorTitle
					: createChildErrorTitleMessage;

				if (!isEmpty(error)) {
					const errorMessages = error.message ? error.message.split('; ') : [];

					// @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2339 - Property 'errors' does not exist on type 'Error | ValidationError'.
					const fieldErrorMessages = error.errors?.length
						? // @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2339 - Property 'errors' does not exist on type 'Error | ValidationError'. | TS7031 - Binding element 'field' implicitly has an 'any' type. | TS7031 - Binding element 'errorMessage' implicitly has an 'any' type.
							error.errors.map(({ field, error: errorMessage }) =>
								field === ASSIGNEE && errorMessage === DEFAULT_ASSIGNEE_PERMISSION_ERROR_MESSAGE
									? DEFAULT_ASSIGNEE_PERMISSION_ERROR_MESSAGE_FE
									: errorMessage,
							)
						: [];

					const allErrors = [...errorMessages, ...fieldErrorMessages];

					if (allErrors.length) {
						const limitToTwoErrorMessages = allErrors.slice(0, 2);
						return renderInlineMessage(
							intl,
							intl.formatMessage(errorTitle),
							limitToTwoErrorMessages,
							(e, analyticsEvent) => onInlineErrorCreateRetry(id, analyticsEvent),
							() => onInlineErrorCancel(id),
						);
					}
				}

				return renderInlineMessage(
					intl,
					intl.formatMessage(errorTitle),
					intl.formatMessage(messages.createErrorBody),
					(e, analyticsEvent) => onInlineErrorCreateRetry(id, analyticsEvent),
					() => onInlineErrorCancel(id),
				);
			}
		}
	}

	renderIssueLineCardGroup({
		groupId,
		issues,
		projectType,
		isReorderEnabled,
		isInteractive,
		onEditIssue,
		shouldDisplayWorklogGraph,
		restrictedReorderingMessage,
	}: {
		groupId: string;
		issues: (ChildIssue & { dragPreview: ReactElement; name: string; type: string })[];
		projectType: ProjectType | null;
		isReorderEnabled: boolean;
		isInteractive?: boolean;
		onEditIssue: (issue: ChildIssue) => void;
		shouldDisplayWorklogGraph: boolean;
		restrictedReorderingMessage?: string;
	}) {
		const isAnyPrioritySetForAnyChildIssue =
			isVisualRefreshEnabled() && fg('redesign-linked-issues-issue-view')
				? getIsAnyPrioritySetInAnyChildIssue(issues)
				: false;

		return (
			<ItemLineCardGroup
				items={issues}
				onOrderChange={this.onOrderChange}
				groupId={groupId}
				isReorderEnabled={isReorderEnabled}
				isInteractive={isInteractive}
				onClick={this.onChildIssueClick}
			>
				{({
					isHovering,
					isActive,
					isFocused,
					isDragging,
					item: issue,
					isReorderMenuOpen,
					reorderMenu,
				}) => {
					const handleEdit = (updatedFields: Partial<RelatedIssue>) =>
						onEditIssue && onEditIssue({ ...issue, ...updatedFields });
					const handleClick = (event: MouseEvent) => this.onChildIssueClick({ ...issue }, event);

					const {
						id,
						issueKey,
						issueLink,
						issueTypeIconUrl,
						issueTypeName,
						issuePriorityUrl,
						issuePriorityName,
						issueSummary,
						statusCategory,
						statusCategoryId,
						statusId,
						statusName,
						assigneeUrl,
						assigneeDisplayName,
						isLoading,
						errorInlineMessage,
						hasError,
						estimateFieldId,
						issueTimeTracking,
						isResolved,
					} = issue;

					const additionalItems: Array<ReactNode> = [];
					if (
						shouldDisplayWorklogGraph &&
						issueTimeTracking?.timeSpentSeconds !== undefined &&
						issueTimeTracking.timeSpentSeconds > 0
					) {
						additionalItems.push(
							<MiniTimeLogGraph
								issueTimeTracking={issueTimeTracking}
								statusCategory={issue.statusCategory}
							/>,
						);
					}

					return (
						<JiraIssueLineCard
							id={toIssueId(id)}
							issueKey={toIssueKey(issueKey ?? '')}
							issueLink={issueLink}
							issueTypeIconUrl={issueTypeIconUrl}
							issueTypeName={issueTypeName}
							issuePriorityUrl={issuePriorityUrl}
							issuePriorityName={issuePriorityName}
							issueSummary={issueSummary}
							statusCategory={statusCategory}
							statusCategoryId={statusCategoryId}
							shouldSetWidthForPriorityField={isAnyPrioritySetForAnyChildIssue}
							statusId={statusId}
							statusName={statusName}
							assigneeUrl={assigneeUrl}
							assigneeDisplayName={assigneeDisplayName}
							isLoading={isLoading}
							errorInlineMessage={errorInlineMessage}
							hasError={hasError}
							estimateFieldId={estimateFieldId}
							isHovering={isHovering}
							isActive={isActive}
							/**
							 * Ensures the reorder menu stays visible while it is open.
							 */
							isFocused={isFocused || isReorderMenuOpen}
							isDraggable={isReorderEnabled}
							isDragging={isDragging}
							isInteractive={isInteractive}
							projectType={projectType}
							onEdit={handleEdit}
							onClick={handleClick}
							restrictedReorderingMessage={restrictedReorderingMessage}
							additionalItems={additionalItems}
							shouldDisplayDevOpsData
							isInlineEditEnabled
							isResolved={isResolved}
							reorderMenu={reorderMenu}
							isVisuallyRefreshedIssueKey={isVisualRefreshEnabled() && fg('visual-refresh_drop_5')}
						/>
					);
				}}
			</ItemLineCardGroup>
		);
	}

	render() {
		const {
			allIssues,
			incompleteIssues,
			hideDone,
			isClassicSubtaskPanel,
			isChildrenIssuesPanel,
			isSimplifiedProject,
			projectType,
			onEditIssue,
			shouldDisplayWorklogGraph,
			intl: { formatMessage },
			restrictReordering = false,
			hasExceededChildIssuesLimitOnLoadOrAfter,
		} = this.props;

		const hasMultipleIssues = allIssues.length > 1;
		const hasLoadingIssues = allIssues.filter((issue) => issue.isLoading).length > 0;
		const canReorderIssues =
			(isChildrenIssuesPanel || isClassicSubtaskPanel) &&
			!hideDone &&
			hasMultipleIssues &&
			!hasLoadingIssues &&
			!restrictReordering;

		const issuesToRender = hideDone ? incompleteIssues : allIssues;
		const issues = issuesToRender.map((issue) => {
			const dragPreview = (
				<DragPreview iconUrl={issue.issueTypeIconUrl} issueKey={issue.issueKey} />
			);

			const name = issue.issueSummary ?? '';
			const type = issue.issueTypeName ?? '';

			return issue.errorType
				? {
						...issue,
						errorInlineMessage: this.inlineErrorMessage(issue.id, issue.errorType, issue.error),
						dragPreview,
						name,
						type,
					}
				: { ...issue, dragPreview, name, type };
		});

		const containerProps = {
			'data-testid': 'issue.issue-view.views.common.child-issues-panel.issues-container',
		};

		const reorderingMessageProps = (isChildrenIssuesPanel || isClassicSubtaskPanel) &&
			hasMultipleIssues &&
			restrictReordering &&
			!(!isSimplifiedProject && isChildrenIssuesPanel) && {
				restrictedReorderingMessage: hasExceededChildIssuesLimitOnLoadOrAfter
					? formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.restrictedReorderingExceededLimitMessageIssueTermRefresh
								: messages.restrictedReorderingExceededLimitMessage,
						)
					: formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.restrictedReorderingMessageIssueTermRefresh
								: messages.restrictedReorderingMessage,
						),
			};

		const issueIds = issues.map((issue) => issue.id);

		return (
			<SmartCardProviderWithAIHooks>
				{isDevopsFeatureDisabledInFedRamp() ? (
					<Container {...containerProps}>
						{this.renderIssueLineCardGroup({
							groupId: 'child-issues-panel',
							issues,
							projectType,
							isReorderEnabled: canReorderIssues,
							onEditIssue,
							shouldDisplayWorklogGraph,
							...reorderingMessageProps,
						})}
					</Container>
				) : (
					<DevOpsSummaryContainer issueIds={issueIds} location="issue_view_child_issues_list">
						<Container {...containerProps}>
							{this.renderIssueLineCardGroup({
								groupId: 'child-issues-panel',
								issues,
								projectType,
								isReorderEnabled: canReorderIssues,
								onEditIssue,
								shouldDisplayWorklogGraph,
								...reorderingMessageProps,
							})}
						</Container>
					</DevOpsSummaryContainer>
				)}
			</SmartCardProviderWithAIHooks>
		);
	}
}

// @ts-expect-error - TS2345 - Argument of type 'typeof IssuesInternal' is not assignable to parameter of type 'SFC<Partial<Props>>'.
export const Issues = memo<Partial<Props>>(IssuesInternal, isEqual);
Issues.displayName = 'Issues';
