import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import type { ResourceManager } from '@atlassian/jira-issue-view-common-utils/src/utils/prefetched-resources/prefetched-resource-manager/index.tsx';
import {
	cloudIdSelector,
	issueKeySelector,
	isAnonymousSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { fetchAllForgeData as fetchAllForgeDataService } from './forge/index.tsx';

export const fetchAllForgeDataPromise = (
	state: State,
	issueForgeData?: ResourceManager['issueForgeData'],
) =>
	fetchAllForgeDataService(
		cloudIdSelector(state),
		issueKeySelector(state),
		isAnonymousSelector(state),
		issueForgeData,
	);

export const fetchAllForgeData = (state: State, prefetchResourceManager?: ResourceManager) => {
	const cloudId = cloudIdSelector(state);
	const issueKey = issueKeySelector(state);
	const isAnonymous = isAnonymousSelector(state);

	return Observable.fromPromise(
		fetchAllForgeDataService(
			cloudId,
			issueKey,
			isAnonymous,
			prefetchResourceManager && prefetchResourceManager.issueForgeData,
		),
	);
};
