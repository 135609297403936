import type { AIContextServicePanelResourceResponse } from '@atlassian/jira-servicedesk-ai-context-panel-resources/src/services/service/types.tsx';

export const AI_CONFIG = {
	product: 'jira',
	subproduct: 'serviceDesk',
	aiFeatureName: 'aiContextServicePanel',
	proactiveGeneratedAI: 1,
	userGeneratedAI: 0,
} as const;

export const getAiExperienceName = (data: AIContextServicePanelResourceResponse) => {
	return [
		data?.summary ? 'summary' : null,
		data?.nextSteps ? 'nextSteps' : null,
		data?.reporterDetails ? 'reporterDetails' : null,
		data?.suggestedEscalation ? 'suggestedEscalation' : null,
		data?.suggestedActions?.length ? 'suggestedActions' : null,
	]
		.filter(Boolean)
		.join(',');
};

export const getAiSuggestedActions = (data: AIContextServicePanelResourceResponse | null) => {
	return (
		data?.suggestedActions
			?.map((action) => action.type)
			.filter(Boolean)
			.join(',') || undefined
	);
};

export const getAiSuggestedActionValues = (
	data: AIContextServicePanelResourceResponse | null,
): Record<string, string> => {
	const actionsMap = data?.suggestedActions?.reduce(
		(actions: Map<string, string>, currentAction) => {
			if (currentAction.type === 'assignee') {
				actions.set(currentAction.type, currentAction.context.suggestion.accountId);
			} else if (currentAction.type === 'priority') {
				actions.set(currentAction.type, currentAction.context.suggestion.id);
			}
			return actions;
		},
		new Map(),
	);

	return actionsMap ? Object.fromEntries(actionsMap.entries()) : {};
};
