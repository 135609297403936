import has from 'lodash/has';
import keys from 'lodash/keys';
import remove from 'lodash/remove';
import some from 'lodash/some';
import uniq from 'lodash/uniq';
import { v4 as uuid } from 'uuid';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';

type AsyncProvider<TArgs, TState, TProps, TAsyncResponse> = (
	arg1: TArgs,
	arg2: StoreActionApi<TState>,
	arg3: TProps,
) => Promise<TAsyncResponse>;

type AsyncResponseHandler<TAsyncResponse, TArgs, TState, TProps> = (
	arg1: TAsyncResponse,
	arg2: TArgs,
	arg3: StoreActionApi<TState>,
	arg4: TProps,
) => void;

type AsyncErrorHandler<TArgs, TState, TProps> = (
	arg1: Error,
	arg2: TArgs,
	arg3: StoreActionApi<TState>,
	arg4: TProps,
) => void;

type ActionCache<TArgs> = {
	inCache: (arg1: TArgs, arg2: string) => boolean;
	update: (arg1: TArgs, arg2: string) => void;
	clear: (arg1: TArgs) => void;
};

type SwitchAction<TArgs, TState, TProps> = (
	arg1: TArgs,
) => (arg1: StoreActionApi<TState>, arg2: TProps) => void;

const argsShallowEqual = <TArgs,>(a: TArgs, b: TArgs): boolean =>
	uniq([...keys(a), ...keys(b)]).every((key) => {
		if (!has(b, key)) {
			return false;
		}
		if (!has(a, key)) {
			return false;
		}
		// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'unknown'. | TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'unknown'.
		if (!Object.is(a[key], b[key])) {
			return false;
		}
		return true;
	});

const createCache = <TArgs,>(): ActionCache<TArgs> => {
	const actionCache: {
		args: TArgs;
		executionIdentifier: string;
	}[] = [];

	return {
		inCache: (args: TArgs, executionIdentifier: string): boolean =>
			some(
				actionCache,
				({ args: cachedArgs, executionIdentifier: cachedExecutionIdentifier }) =>
					argsShallowEqual(cachedArgs, args) && cachedExecutionIdentifier === executionIdentifier,
			),
		update: (args: TArgs, executionIdentifier: string) => {
			// remove any tuple with the shallow equal arguments
			remove(actionCache, ({ args: cachedArgs }) => argsShallowEqual(cachedArgs, args));
			// add new tuple
			actionCache.push({ args, executionIdentifier });
		},
		clear: (args: TArgs) => {
			remove(actionCache, ({ args: cachedArgs }) => argsShallowEqual(cachedArgs, args));
		},
	};
};

/**
 * Factory to create a rxjs switchmap-style action handler. You have to provide a promise supplier,
 * and a promise result handler.
 * If this action is called multiple times before the previous promise supplied by the promise
 * supplier has resolved, the result will be ignored and the result handler will only be called for
 * the last promise that was created by the last call to the action.
 *
 * You can also optionally provide an error handler.
 */
export const asyncSwitchAction = <TState, TProps, TArgs, TAsyncResponse>(
	asyncProvider: AsyncProvider<TArgs, TState, TProps, TAsyncResponse>,
	responseHandler: AsyncResponseHandler<TAsyncResponse, TArgs, TState, TProps>,
	errorHandler?: AsyncErrorHandler<TArgs, TState, TProps>,
): SwitchAction<TArgs, TState, TProps> => {
	const cache = createCache<TArgs>();

	return (args: TArgs) => (storeActionApi: StoreActionApi<TState>, props: TProps) => {
		const executionIdentifier = uuid();
		cache.update(args, executionIdentifier);

		asyncProvider(args, storeActionApi, props)
			.then((response) => {
				if (!cache.inCache(args, executionIdentifier)) {
					// has been overwritten by new request. ignore response
					return;
				}

				responseHandler(response, args, storeActionApi, props);

				cache.clear(args);
			})
			.catch((err) => {
				if (!cache.inCache(args, executionIdentifier)) {
					// has been overwritten by new request. ignore error
					return;
				}
				if (errorHandler !== undefined) {
					errorHandler(err, args, storeActionApi, props);
				}
			});
	};
};
