import React, { type FC } from 'react';

import { Box, xcss } from '@atlaskit/primitives';

import { useIntl } from '@atlassian/jira-intl';
import type { ValueContainerProps } from '../../field/types.tsx';
import messages from './messages.tsx';

export const ValueContainer: FC<ValueContainerProps> = ({
	render,
	noVerticalPadding,
	noVerticalPaddingWhenNotEmpty,
	noBorder,
}) => {
	const { formatMessage } = useIntl();

	const result = render();
	const isEmpty = !result;

	return (
		<Box
			xcss={[
				styles,
				noVerticalPadding && noVerticalPaddingStyles,
				noVerticalPaddingWhenNotEmpty && !isEmpty && noVerticalPaddingStyles,
				noBorder && noBorderStyles,
				isEmpty && emptyStyles,
			]}
			data-testId="servicedesk-customer-service-custom-details.common.ui.layout.default.value-container.box"
		>
			{isEmpty ? formatMessage(messages.none) : result}
		</Box>
	);
};

const styles = xcss({
	border: '2px solid transparent',
	margin: 'space.0',
	wordBreak: 'break-word',
	color: 'color.text',
	padding: 'space.075',
	font: 'font.body',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '16px',
});

const noVerticalPaddingStyles = xcss({
	paddingBlock: 'space.0',
});

const emptyStyles = xcss({
	minHeight: '16px',
	color: 'color.text.subtlest',
});

const noBorderStyles = xcss({
	border: 'none',
});
