import React, { useCallback } from 'react';
import Button from '@atlaskit/button/new';
import { Box, xcss } from '@atlaskit/primitives';
import AtlassianIntelligenceIcon from '@atlaskit/icon/core/atlassian-intelligence';
import { AiIcon } from '@atlassian/jira-atlassian-intelligence/src/common/ui/ai-icon/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import {
	steps,
	useRelatedIssuesActions,
	useRelatedIssuesState,
} from '../../controllers/context.tsx';
import { useHasRelatesToIssueLinkType } from '../../utils/index.tsx';
import messages from '../messages.tsx';
import { useAiRelatedIssuesEntryPointActions } from '../../controllers/context-provider.tsx';

/**
 * On experiment proactive-jira-ai-similar-issues experiment cleanup, remove this component since it is not being used anymore inside issue-view-content-issue-links/src/view.tsx
 */
export const FindSimilarIssuesButton = () => {
	const { formatMessage } = useIntl();

	const hasRelatesToLinkType = useHasRelatesToIssueLinkType();
	const { currentStep } = useRelatedIssuesState();
	const { setRelatedIssuesFetchStep, setIsLinkedIssuePanelButtonTriggered } =
		useRelatedIssuesActions();

	const triggerDraftListSuggestion = useCallback(() => {
		setRelatedIssuesFetchStep();
		setIsLinkedIssuePanelButtonTriggered(true);
	}, [setIsLinkedIssuePanelButtonTriggered, setRelatedIssuesFetchStep]);

	const isDisabled = !hasRelatesToLinkType || currentStep === steps.fetching;
	const isSelected = currentStep !== steps.hidden;

	const iconLabel = fg('aix-967-similar-issues-content-review')
		? formatMessage(messages.linkSimilarWorkItems)
		: formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.findSimilarIssuesIssueTermRefresh
					: messages.findSimilarIssues,
			);
	const entryPointActions = useAiRelatedIssuesEntryPointActions();
	const triggerRef = useEntryPointButtonTrigger(entryPointActions, true);

	return (
		<Box xcss={buttonStyles}>
			<Button
				spacing="compact"
				iconBefore={(iconProps) => {
					return fg('update-work-item-icons-to-common-component') ? (
						<AtlassianIntelligenceIcon color="currentColor" label={iconLabel} />
					) : (
						<AiIcon {...iconProps} label={iconLabel} size="small" isDisabled={isDisabled} />
					);
				}}
				onClick={triggerDraftListSuggestion}
				isDisabled={isDisabled}
				isSelected={isSelected}
				ref={triggerRef}
			>
				{fg('aix-967-similar-issues-content-review')
					? formatMessage(messages.linkSimilarWorkItems)
					: formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.findSimilarIssuesIssueTermRefresh
								: messages.findSimilarIssues,
						)}
			</Button>
		</Box>
	);
};

const buttonStyles = xcss({
	marginInlineStart: 'space.075',
});
