import { type PageLoadMetric, metrics } from '@atlassian/browser-metrics';

export const PAGE_LOAD_MARK_INITIAL_RENDER_START = 'PAGE_LOAD_MARK_INITIAL_RENDER_START';
export const PAGE_LOAD_MARK_INITIAL_RENDER_END = 'PAGE_LOAD_MARK_INITIAL_RENDER_END';
export const PAGE_LOAD_MARK_LOAD_PERMISSIONS_START = 'PAGE_LOAD_MARK_LOAD_PERMISSIONS_START';
export const PAGE_LOAD_MARK_LOAD_PERMISSIONS_END = 'PAGE_LOAD_MARK_LOAD_PERMISSIONS_END';
export const PAGE_LOAD_MARK_LOAD_VIEWS_START = 'PAGE_LOAD_MARK_LOAD_VIEWS_START';
export const PAGE_LOAD_MARK_LOAD_VIEWS_END = 'PAGE_LOAD_MARK_LOAD_VIEWS_END';
export const PAGE_LOAD_MARK_LOAD_PROJECT_START = 'PAGE_LOAD_MARK_LOAD_PROJECT_START';
export const PAGE_LOAD_MARK_LOAD_PROJECT_END = 'PAGE_LOAD_MARK_LOAD_PROJECT_END';
export const PAGE_LOAD_MARK_LOAD_INSIGHTS_START = 'PAGE_LOAD_MARK_LOAD_INSIGHTS_START';
export const PAGE_LOAD_MARK_LOAD_INSIGHTS_END = 'PAGE_LOAD_MARK_LOAD_INSIGHTS_END';
export const PAGE_LOAD_MARK_LOAD_FIELDS_START = 'PAGE_LOAD_MARK_LOAD_FIELDS_START';
export const PAGE_LOAD_MARK_LOAD_FIELDS_END = 'PAGE_LOAD_MARK_LOAD_FIELDS_END';
export const PAGE_LOAD_MARK_LOAD_ISSUES_START = 'PAGE_LOAD_MARK_LOAD_ISSUES_START';
export const PAGE_LOAD_MARK_LOAD_ISSUES_END = 'PAGE_LOAD_MARK_LOAD_ISSUES_END';
export const PAGE_LOAD_MARK_RENDER_VIEW_MAIN_START = 'PAGE_LOAD_MARK_RENDER_VIEW_MAIN_START';
export const PAGE_LOAD_MARK_RENDER_VIEW_MAIN_END = 'PAGE_LOAD_MARK_RENDER_VIEW_MAIN_END';
export const PAGE_LOAD_MARK_RENDER_LIST_VIEW_START = 'PAGE_LOAD_MARK_RENDER_LIST_VIEW_START';
export const PAGE_LOAD_MARK_RENDER_LIST_VIEW_END = 'PAGE_LOAD_MARK_RENDER_LIST_VIEW_END';
export const PAGE_LOAD_MARK_RENDER_BOARD_VIEW_START = 'PAGE_LOAD_MARK_RENDER_BOARD_VIEW_START';
export const PAGE_LOAD_MARK_RENDER_BOARD_VIEW_END = 'PAGE_LOAD_MARK_RENDER_BOARD_VIEW_END';
export const PAGE_LOAD_MARK_RENDER_MATRIX_VIEW_START = 'PAGE_LOAD_MARK_RENDER_MATRIX_VIEW_START';
export const PAGE_LOAD_MARK_RENDER_MATRIX_VIEW_END = 'PAGE_LOAD_MARK_RENDER_MATRIX_VIEW_END';
export const PAGE_LOAD_MARK_RENDER_TIMELINE_VIEW_START =
	'PAGE_LOAD_MARK_RENDER_TIMELINE_VIEW_START';
export const PAGE_LOAD_MARK_RENDER_TIMELINE_VIEW_END = 'PAGE_LOAD_MARK_RENDER_TIMELINE_VIEW_END';
export const PAGE_LOAD_MARK_RENDER_BOARD_SWIMLANE_VIEW_START =
	'PAGE_LOAD_MARK_RENDER_BOARD_SWIMLANE_VIEW_START';
export const PAGE_LOAD_MARK_RENDER_BOARD_SWIMLANE_VIEW_END =
	'PAGE_LOAD_MARK_RENDER_BOARD_SWIMLANE_VIEW_END';

const isSharedView = () =>
	/\/jira\/discovery\/share\/views\/[a-zA-Z0-9-]+/.test(globalThis.location?.href);

// Lazily initialize the metric because it depends on the URL
let jpdProjectPageLoadMetric: PageLoadMetric;
export function getJpdProjectPageLoadMetric(): PageLoadMetric {
	if (jpdProjectPageLoadMetric != null) {
		return jpdProjectPageLoadMetric;
	}
	jpdProjectPageLoadMetric = metrics.pageLoad({
		key: isSharedView() ? 'jpd.shared-view' : 'jpd.project',
		featureFlags: [],
		timings: [
			{
				key: 'product/initial-render',
				startMark: PAGE_LOAD_MARK_INITIAL_RENDER_START,
				endMark: PAGE_LOAD_MARK_INITIAL_RENDER_END,
			},
			{
				key: 'product/load-permissions',
				startMark: PAGE_LOAD_MARK_LOAD_PERMISSIONS_START,
				endMark: PAGE_LOAD_MARK_LOAD_PERMISSIONS_END,
			},
			{
				key: 'product/load-views',
				startMark: PAGE_LOAD_MARK_LOAD_VIEWS_START,
				endMark: PAGE_LOAD_MARK_LOAD_VIEWS_END,
			},
			{
				key: 'product/load-project',
				startMark: PAGE_LOAD_MARK_LOAD_PROJECT_START,
				endMark: PAGE_LOAD_MARK_LOAD_PROJECT_END,
			},
			{
				key: 'product/load-insights',
				startMark: PAGE_LOAD_MARK_LOAD_INSIGHTS_START,
				endMark: PAGE_LOAD_MARK_LOAD_INSIGHTS_END,
			},
			{
				key: 'product/load-fields',
				startMark: PAGE_LOAD_MARK_LOAD_FIELDS_START,
				endMark: PAGE_LOAD_MARK_LOAD_FIELDS_END,
			},
			{
				key: 'product/load-issues',
				startMark: PAGE_LOAD_MARK_LOAD_ISSUES_START,
				endMark: PAGE_LOAD_MARK_LOAD_ISSUES_END,
			},
			{
				key: 'product/render-view-main',
				startMark: PAGE_LOAD_MARK_RENDER_VIEW_MAIN_START,
				endMark: PAGE_LOAD_MARK_RENDER_VIEW_MAIN_END,
			},
			{
				key: 'product/render-list-view',
				startMark: PAGE_LOAD_MARK_RENDER_LIST_VIEW_START,
				endMark: PAGE_LOAD_MARK_RENDER_LIST_VIEW_END,
			},
			{
				key: 'product/render-board-view',
				startMark: PAGE_LOAD_MARK_RENDER_BOARD_VIEW_START,
				endMark: PAGE_LOAD_MARK_RENDER_BOARD_VIEW_END,
			},
			{
				key: 'product/render-board-swimlane-view',
				startMark: PAGE_LOAD_MARK_RENDER_BOARD_SWIMLANE_VIEW_START,
				endMark: PAGE_LOAD_MARK_RENDER_BOARD_SWIMLANE_VIEW_END,
			},
			{
				key: 'product/render-matrix-view',
				startMark: PAGE_LOAD_MARK_RENDER_MATRIX_VIEW_START,
				endMark: PAGE_LOAD_MARK_RENDER_MATRIX_VIEW_END,
			},
			{
				key: 'product/render-timeline-view',
				startMark: PAGE_LOAD_MARK_RENDER_TIMELINE_VIEW_START,
				endMark: PAGE_LOAD_MARK_RENDER_TIMELINE_VIEW_END,
			},
		],
	});
	return jpdProjectPageLoadMetric;
}

export const jpdProjectPageLoad: Pick<PageLoadMetric, 'mark'> = {
	mark(...args) {
		getJpdProjectPageLoadMetric().mark(...args);
	},
};

export const jpdProjectPageLoadPermissionsStart = () => {
	jpdProjectPageLoad.mark(PAGE_LOAD_MARK_LOAD_PERMISSIONS_START);
};

export const jpdProjectPageLoadPermissionsEnd = () => {
	jpdProjectPageLoad.mark(PAGE_LOAD_MARK_LOAD_PERMISSIONS_END);
};
