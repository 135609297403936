/**
 * @generated SignedSource<<b3ead049e2c110ffbfe558a7bf7dbf04>>
 * @relayHash ac79db7b1731a53e8c9e545120cb77c5
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 65dbe1bb456d2ee18a3d1952bfd3a3eb8c8aea4b799d334a682042f745e2b51b

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type multiSelectWithRelayEditViewFieldOptionsPrefetchIntentQuery$variables = {
  first?: number | null | undefined;
  id: string;
};
export type multiSelectWithRelayEditViewFieldOptionsPrefetchIntentQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment">;
};
export type multiSelectWithRelayEditViewFieldOptionsPrefetchIntentQuery = {
  response: multiSelectWithRelayEditViewFieldOptionsPrefetchIntentQuery$data;
  variables: multiSelectWithRelayEditViewFieldOptionsPrefetchIntentQuery$variables;
};
({
  "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider": includeSingleSelectColorField_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider: {
    readonly get: () => boolean;
  };
});

import includeSingleSelectColorField_provider from '@atlassian/jira-relay-provider/src/thor-colorful-single-select-milestone2-experiment.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "Variable",
  "name": "id",
  "variableName": "id"
},
v4 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v5 = {
  "kind": "ScalarField",
  "name": "id"
},
v6 = [
  (v2/*: any*/),
  {
    "kind": "Literal",
    "name": "searchBy",
    "value": ""
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "multiSelectWithRelayEditViewFieldOptionsPrefetchIntentQuery",
    "selections": [
      {
        "args": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "multiSelectWithRelayEditViewFieldOptionsPrefetchIntentQuery",
    "selections": [
      {
        "args": [
          (v3/*: any*/)
        ],
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "ScalarField",
                "name": "type"
              }
            ],
            "type": "JiraIssueField",
            "abstractKey": "__isJiraIssueField"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "concreteType": "JiraFieldOperation",
                "kind": "LinkedField",
                "name": "fieldOperations",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "canEdit"
                  }
                ]
              },
              {
                "concreteType": "JiraOptionConnection",
                "kind": "LinkedField",
                "name": "fieldOptions",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "totalCount"
                  }
                ]
              }
            ],
            "type": "JiraSingleSelectField"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v6/*: any*/),
                "concreteType": "JiraSelectableValueConnection",
                "kind": "LinkedField",
                "name": "selectableValueOptions",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraSelectableValueEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "selectableLabel"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "selectableGroupKey"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "selectableIconUrl"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "isDisabled"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "optionId"
                              },
                              {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraColor",
                                    "kind": "LinkedField",
                                    "name": "color",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "colorKey"
                                      },
                                      (v5/*: any*/)
                                    ]
                                  }
                                ]
                              },
                              {
                                "kind": "ScalarField",
                                "name": "value"
                              }
                            ],
                            "type": "JiraOption"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "versionId"
                              }
                            ],
                            "type": "JiraVersion"
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "cursor"
                      }
                    ]
                  },
                  {
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "endCursor"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "hasNextPage"
                      }
                    ]
                  }
                ]
              },
              {
                "args": (v6/*: any*/),
                "filters": [
                  "searchBy",
                  "filterById"
                ],
                "handle": "connection",
                "key": "selectableValue_issueFieldEditviewFull_fieldOptions__selectableValueOptions",
                "kind": "LinkedHandle",
                "name": "selectableValueOptions"
              }
            ],
            "type": "JiraHasSelectableValueOptions",
            "abstractKey": "__isJiraHasSelectableValueOptions"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "65dbe1bb456d2ee18a3d1952bfd3a3eb8c8aea4b799d334a682042f745e2b51b",
    "metadata": {},
    "name": "multiSelectWithRelayEditViewFieldOptionsPrefetchIntentQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider": includeSingleSelectColorField_provider
    }
  }
};
})();

(node as any).hash = "1f210804daf77fd73fbeb09b1537299d";

export default node;
