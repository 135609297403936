import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { State } from '../../types.tsx';

export const incrementOpenUpdateCounter =
	(ids: LocalIssueId[]): Action<State> =>
	({ getState, setState }) => {
		const { openUpdateCounter } = getState();
		ids.forEach((id) => {
			openUpdateCounter[id] = (openUpdateCounter[id] || 0) + 1;
		});
		setState({
			openUpdateCounter,
		});
	};

export const decrementOpenUpdateCounter =
	(id: LocalIssueId): Action<State> =>
	({ getState, setState }) => {
		setState({
			openUpdateCounter: {
				...getState().openUpdateCounter,
				[id]: (getState().openUpdateCounter[id] || 1) - 1,
			},
		});
	};
