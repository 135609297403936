/**
 * @generated SignedSource<<7a7b88557a8f98871200347b348a9ef0>>
 * @relayHash d31608a821a07cb73cca85d5beff002a
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID b0f6f5d6b6f4c13adf6cb2555cf02baecd2b7432412eee10c05ff3ebea94b0e2

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type releaseVersionsEditViewFullFieldQuery$variables = {
  id: string;
};
export type releaseVersionsEditViewFullFieldQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment">;
};
export type releaseVersionsEditViewFullFieldQuery = {
  response: releaseVersionsEditViewFullFieldQuery$data;
  variables: releaseVersionsEditViewFullFieldQuery$variables;
};
({
  "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider": includeSingleSelectColorField_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider: {
    readonly get: () => boolean;
  };
});

import includeSingleSelectColorField_provider from '@atlassian/jira-relay-provider/src/thor-colorful-single-select-milestone2-experiment.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v3 = {
  "kind": "ScalarField",
  "name": "id"
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  },
  {
    "kind": "Literal",
    "name": "searchBy",
    "value": ""
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/)
    ],
    "kind": "Fragment",
    "name": "releaseVersionsEditViewFullFieldQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "releaseVersionsEditViewFullFieldQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "ScalarField",
                "name": "type"
              }
            ],
            "type": "JiraIssueField",
            "abstractKey": "__isJiraIssueField"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "concreteType": "JiraFieldOperation",
                "kind": "LinkedField",
                "name": "fieldOperations",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "canEdit"
                  }
                ]
              },
              {
                "concreteType": "JiraOptionConnection",
                "kind": "LinkedField",
                "name": "fieldOptions",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "totalCount"
                  }
                ]
              }
            ],
            "type": "JiraSingleSelectField"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v4/*: any*/),
                "concreteType": "JiraSelectableValueConnection",
                "kind": "LinkedField",
                "name": "selectableValueOptions",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraSelectableValueEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "selectableLabel"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "selectableGroupKey"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "selectableIconUrl"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "isDisabled"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "optionId"
                              },
                              {
                                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraColor",
                                    "kind": "LinkedField",
                                    "name": "color",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "name": "colorKey"
                                      },
                                      (v3/*: any*/)
                                    ]
                                  }
                                ]
                              },
                              {
                                "kind": "ScalarField",
                                "name": "value"
                              }
                            ],
                            "type": "JiraOption"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "versionId"
                              }
                            ],
                            "type": "JiraVersion"
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "cursor"
                      }
                    ]
                  },
                  {
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "endCursor"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "hasNextPage"
                      }
                    ]
                  }
                ],
                "storageKey": "selectableValueOptions(first:20,searchBy:\"\")"
              },
              {
                "args": (v4/*: any*/),
                "filters": [
                  "searchBy",
                  "filterById"
                ],
                "handle": "connection",
                "key": "selectableValue_issueFieldEditviewFull_fieldOptions__selectableValueOptions",
                "kind": "LinkedHandle",
                "name": "selectableValueOptions"
              }
            ],
            "type": "JiraHasSelectableValueOptions",
            "abstractKey": "__isJiraHasSelectableValueOptions"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "b0f6f5d6b6f4c13adf6cb2555cf02baecd2b7432412eee10c05ff3ebea94b0e2",
    "metadata": {},
    "name": "releaseVersionsEditViewFullFieldQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider": includeSingleSelectColorField_provider
    }
  }
};
})();

(node as any).hash = "e4fde9306f5268fc1a6acd99a90546f7";

export default node;
