import {
	UNDEFINED,
	TODO,
	IN_PROGRESS,
	DONE,
} from '@atlassian/jira-common-constants/src/status-categories.tsx';
import type { ChildIssue } from '@atlassian/jira-issue-view-common-types/src/children-issues-type.tsx';

export const getIsAnyPrioritySetInAnyChildIssue = (childIssues: ChildIssue[] | null): boolean => {
	if (!childIssues) {
		return false;
	}

	return Boolean(childIssues.find((childIssue) => Boolean(childIssue.issuePriorityUrl)));
};

export const sortIssuesByCreated = (issues: ChildIssue[]): ChildIssue[] =>
	[...issues].sort((a, b) => {
		if (a.created === undefined) {
			return 1;
		}
		if (b.created === undefined) {
			return -1;
		}
		// @ts-expect-error - TS2362 - The left-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type. | TS2363 - The right-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type.
		return b.created - a.created;
	});

export const sortIssuesByKey = (issues: ChildIssue[]): ChildIssue[] =>
	[...issues].sort((a, b) => {
		const [aKey = '', aIndex = 0] = (a.issueKey || '').split('-');
		const [bKey = '', bIndex = 0] = (b.issueKey || '').split('-');
		// @ts-expect-error - TS2345 - Argument of type 'string | 0' is not assignable to parameter of type 'string'. | TS2345 - Argument of type 'string | 0' is not assignable to parameter of type 'string'.
		return aKey.localeCompare(bKey) || parseInt(aIndex, 10) - parseInt(bIndex, 10);
	});

const STATUS_SORT_ORDER = {
	[UNDEFINED]: 0,
	[TODO]: 1,
	[IN_PROGRESS]: 2,
	[DONE]: 3,
} as const;

const getStatusSortOrder = (statusCategory?: string | null) => {
	const categoryName = statusCategory == null ? UNDEFINED : statusCategory;
	// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly UNDEFINED: 0; readonly TODO: 1; readonly IN_PROGRESS: 2; readonly DONE: 3; }'.
	return STATUS_SORT_ORDER[categoryName] || 0;
};

export const sortIssuesByStatus = (issues: ChildIssue[]): ChildIssue[] =>
	[...issues].sort(
		(a, b) =>
			getStatusSortOrder(a.statusCategory) - getStatusSortOrder(b.statusCategory) ||
			(a.statusName || '').localeCompare(b.statusName || ''),
	);

export const sortIssuesByAssignee = (issues: ChildIssue[]): ChildIssue[] =>
	[...issues].sort((a, b) => {
		if (a.assigneeDisplayName === '' || a.assigneeDisplayName == null) {
			return 1;
		}
		if (b.assigneeDisplayName === '' || b.assigneeDisplayName == null) {
			return -1;
		}
		return a.assigneeDisplayName.localeCompare(b.assigneeDisplayName);
	});

// this is to ensure issues where priority is empty are placed last
const getPriorityValue = (priorityOrder: string[], priorityName?: string | null) => {
	// @ts-expect-error - TS2345 - Argument of type 'string | null | undefined' is not assignable to parameter of type 'string'.
	const index = priorityOrder.indexOf(priorityName);
	return index >= 0 ? index : Number.MAX_SAFE_INTEGER;
};

export const sortIssuesByPriorityOld = (
	issues: ChildIssue[],
	priorityOrder: string[],
): ChildIssue[] =>
	[...issues].sort(
		(a, b) =>
			getPriorityValue(priorityOrder, a.issuePriorityName) -
			getPriorityValue(priorityOrder, b.issuePriorityName),
	);

export const sortIssuesByPriority = (issues: ChildIssue[]): ChildIssue[] =>
	[...issues].sort(
		(a, b) =>
			(a.issuePrioritySequence || Number.MAX_SAFE_INTEGER) -
			(b.issuePrioritySequence || Number.MAX_SAFE_INTEGER),
	);
