import React, { memo, useCallback, useState, useMemo, useEffect } from 'react';
import { useFragment, graphql, useMutation } from 'react-relay';
import Heading from '@atlaskit/heading';
import { Box, Inline, Stack, Text } from '@atlaskit/primitives';
import { SectionMessageAction } from '@atlaskit/section-message';
import InformationIcon from '@atlaskit/icon/core/information';
import WarningIcon from '@atlaskit/icon/core/warning';
import { token } from '@atlaskit/tokens';
import Spinner from '@atlaskit/spinner';
// eslint-disable-next-line jira/wrm/no-load-bridge
import { jiraBridge } from '@atlassian/jira-common-bridge';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { Reason } from '@atlassian/jira-issue-refresh-service/src/types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { COMPANY_MANAGED_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import type { ui_issueViewLayoutTemplates_DateFieldsMessage$key as DateFieldsMessageFragment } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutTemplates_DateFieldsMessage.graphql';
import type { ui_dateFieldsMessage_DismissActionMutation as DismissActionMutation } from '@atlassian/jira-relay/src/__generated__/ui_dateFieldsMessage_DismissActionMutation.graphql';
import type { ui_dateFieldsMessage_EnableActionMutation as EnableActionMutation } from '@atlassian/jira-relay/src/__generated__/ui_dateFieldsMessage_EnableActionMutation.graphql';
import getConfigurationHref from '@atlassian/jira-issue-view-foundation/src/utils/get-configuration-href.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import {
	useAnalyticsEvents,
	fireOperationalAnalytics,
	fireUIAnalytics,
	FireUiAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import type { MessageAttributesType, Props } from './types.tsx';
import messages from './messages.tsx';
import { PACKAGE_NAME, TEAM_NAME, START_DATE_FIELD_ID, DUE_DATE_FIELD_ID } from './constants.tsx';

export const DateFieldsMessage = memo(
	({ rootRelayFragment, userPreferencesConnectionId }: Props) => {
		const { formatMessage } = useIntl();
		const { showFlag } = useFlagsService();
		const cloudId = useCloudId();
		const issueKey = useIssueKey();
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'button',
		});

		const onSoftRefresh = useCallback(async (key: string, fieldType: string) => {
			try {
				await jiraBridge.refreshIssuePageThrowable(key, Reason.SoftRefresh);
				log.safeInfoWithoutCustomerData(
					'issue.details.use-soft-refresh',
					'issueViewSoftRefresh requested',
					{
						fieldType,
					},
				);
			} catch (error) {
				const errorMessage =
					error instanceof Error ? error.message : 'issueViewSoftRefresh issue refresh failed';
				log.safeErrorWithoutCustomerData(
					'issue.details.use-soft-refresh',
					`${fieldType} - ${errorMessage}`,
				);
			}
		}, []);

		const data = useFragment<DateFieldsMessageFragment>(
			graphql`
				fragment ui_issueViewLayoutTemplates_DateFieldsMessage on JiraQuery {
					issueByKey(cloudId: $cloudId, key: $issueKey) {
						projectField {
							project {
								projectId
								projectStyle
								canAdministerProject: action(type: EDIT_PROJECT_CONFIG) {
									canPerform
								}
							}
						}
					}
				}
			`,
			rootRelayFragment?.jira || null,
		);

		const [dismiss] = useMutation<DismissActionMutation>(graphql`
			mutation ui_dateFieldsMessage_DismissActionMutation($cloudId: ID!, $issueKey: String!)
			@raw_response_type {
				jira {
					userPreferences(cloudId: $cloudId) @optIn(to: "JiraUserPreferences") {
						dismissDateFieldAssociationMessageByIssueKey(issueKey: $issueKey) {
							success
							errors {
								message
							}
						}
					}
				}
			}
		`);

		const project = data?.issueByKey?.projectField?.project;
		const projectStyle = project?.projectStyle ?? COMPANY_MANAGED_PROJECT;
		const canEditProjectConfig = !!project?.canAdministerProject?.canPerform;

		const configureFieldsPageHref = getConfigurationHref(formatMessage);

		const handleDismissAction = useCallback(() => {
			fireUIAnalytics(analyticsEvent, 'dismissDateFieldsMessage', {
				isAdmin: canEditProjectConfig,
			});

			dismiss({
				variables: {
					cloudId,
					issueKey,
				},
				updater: (store, payload) => {
					if (
						payload?.jira?.userPreferences?.dismissDateFieldAssociationMessageByIssueKey
							?.success === true
					) {
						const userPreferencesStore = store.get(userPreferencesConnectionId);
						userPreferencesStore?.setValue(
							false,
							`showDateFieldAssociationMessageByIssueKey(issueKey:"${issueKey}")`,
						);
					}
				},
				onCompleted(response) {
					const dismissResponse =
						response.jira?.userPreferences?.dismissDateFieldAssociationMessageByIssueKey;

					if (dismissResponse?.success !== true) {
						showFlag({
							messageId: 'issue-date-fields-message.ui.show-flag.error.dismiss-message-failed',
							messageType: 'transactional',
							type: 'error',
							title: formatMessage(messages.dismissMessageFailedTitle),
							description: formatMessage(messages.dismissMessageFailedDescription),
						});

						fireErrorAnalytics({
							error: new Error(
								dismissResponse?.errors?.[0]?.message || 'Error dismissing date field association',
							),
							meta: {
								id: 'dismissMessage',
								packageName: PACKAGE_NAME,
								teamName: TEAM_NAME,
							},
							sendToPrivacyUnsafeSplunk: true,
						});
					}

					fireOperationalAnalytics(createAnalyticsEvent({}), 'dismissDateFieldsMessage succeeded');
				},
				onError(error: Error) {
					showFlag({
						messageId: 'issue-date-fields-message.ui.show-flag.error.dismiss-message-failed.1',
						messageType: 'transactional',
						type: 'error',
						title: formatMessage(messages.dismissMessageFailedTitle),
						description: formatMessage(messages.dismissMessageFailedDescription),
					});

					fireErrorAnalytics({
						error,
						meta: {
							id: 'dismissMessage',
							packageName: PACKAGE_NAME,
							teamName: TEAM_NAME,
						},
						sendToPrivacyUnsafeSplunk: true,
					});
				},
			});
		}, [
			cloudId,
			issueKey,
			dismiss,
			userPreferencesConnectionId,
			analyticsEvent,
			canEditProjectConfig,
			createAnalyticsEvent,
			formatMessage,
			showFlag,
		]);

		const [associateField] = useMutation<EnableActionMutation>(graphql`
			mutation ui_dateFieldsMessage_EnableActionMutation(
				$cloudId: ID!
				$projectId: Long!
				$fieldId: String!
			) @raw_response_type {
				jira @optIn(to: ["JiraWorkManagementAssociateFieldMutation"]) {
					jwmAssociateField(
						cloudId: $cloudId
						input: { projectId: $projectId, fieldId: $fieldId }
					) {
						success
						errors {
							extensions {
								errorType
								statusCode
							}
							message
						}
						associatedIssueTypeIds
					}
				}
			}
		`);

		const projectId = data?.issueByKey?.projectField?.project?.projectId;
		const [isAssociatingLoading, setIsAssociatingLoading] = useState(false);

		const fieldsToAssociate = useMemo(
			() => [
				{
					fieldId: START_DATE_FIELD_ID,
					fieldName: 'Start date',
				},
				{ fieldId: DUE_DATE_FIELD_ID, fieldName: 'Due date' },
			],
			[],
		);
		const [fieldsSuccessfullyAssociated, setFieldsSuccessfullyAssociated] = useState<string[]>([]);
		const [fieldsFailedToAssociate, setFieldsFailedToAssociate] = useState<string[]>([]);

		const handleEnableAction = useCallback((): void => {
			setIsAssociatingLoading(true);
			fireUIAnalytics(analyticsEvent, 'enableDateFields');

			for (const field of fieldsToAssociate) {
				associateField({
					variables: {
						cloudId,
						projectId: Number(projectId),
						fieldId: field.fieldId,
					},
					onCompleted(response) {
						const enableResponse = response.jira?.jwmAssociateField;
						if (enableResponse?.success !== true) {
							setFieldsFailedToAssociate((prev) => [...prev, field.fieldName]);

							fireErrorAnalytics({
								error: new Error(
									enableResponse?.errors?.[0]?.message || 'Error associating date field',
								),
								meta: {
									id: 'enableDateField',
									packageName: PACKAGE_NAME,
									teamName: TEAM_NAME,
								},
								sendToPrivacyUnsafeSplunk: true,
							});

							setIsAssociatingLoading(false);

							return;
						}

						setFieldsSuccessfullyAssociated((prev) => [...prev, field.fieldName]);

						fireOperationalAnalytics(createAnalyticsEvent({}), 'enableDateField succeeded');

						onSoftRefresh(issueKey, 'dateFields');
					},
					onError(error: Error) {
						setFieldsFailedToAssociate((prev) => [...prev, field.fieldName]);

						fireErrorAnalytics({
							error,
							meta: {
								id: 'enableDateField',
								packageName: PACKAGE_NAME,
								teamName: TEAM_NAME,
							},
							sendToPrivacyUnsafeSplunk: true,
						});

						setIsAssociatingLoading(false);
					},
				});
			}
		}, [
			associateField,
			cloudId,
			fieldsToAssociate,
			issueKey,
			onSoftRefresh,
			projectId,
			createAnalyticsEvent,
			analyticsEvent,
		]);

		const getResultOfAssociatingFields = useCallback(() => {
			if (fieldsFailedToAssociate.length === 2) {
				// both fields failed to associate
				showFlag({
					messageId: 'issue-date-fields-message.ui.show-flag.error.enable-date-fields-failed',
					messageType: 'transactional',
					type: 'error',
					title: formatMessage(messages.enableDateFieldsFailedTitle),
					description: formatMessage(messages.enableDateFieldsFailedDescription),
				});

				// reset to try again
				setFieldsFailedToAssociate([]);
			} else if (
				fieldsFailedToAssociate.length === 1 &&
				fieldsSuccessfullyAssociated.length === 1
			) {
				// only one field successfully associated
				showFlag({
					messageId:
						'issue-date-fields-message.ui.show-flag.error.enable-date-fields-partially-failed',
					messageType: 'transactional',
					type: 'error',
					title: formatMessage(messages.enableDateFieldsPartiallyFailedTitle),
					description: formatMessage(messages.enableDateFieldsPartiallyFailedDescription, {
						failedField: String(fieldsFailedToAssociate[0]),
						successfulField: String(fieldsSuccessfullyAssociated[0]),
					}),
					actions: [
						{
							onClick: handleEnableAction,
							content: formatMessage(messages.enableDateFieldsPartiallyFailedAction),
						},
					],
				});

				// reset to try again
				setFieldsFailedToAssociate([]);
				setFieldsSuccessfullyAssociated([]);
			} else if (fieldsSuccessfullyAssociated.length === 2) {
				// both fields successfully associated
				showFlag({
					messageId: 'issue-date-fields-message.ui.show-flag.success.enable-date-fields-success',
					messageType: 'transactional',
					type: 'success',
					title: formatMessage(messages.enableDateFieldsSuccessTitle),
					description: formatMessage(messages.enableDateFieldsSuccessDescription),
				});
				setFieldsSuccessfullyAssociated([]);
			}
		}, [
			fieldsFailedToAssociate,
			fieldsSuccessfullyAssociated,
			formatMessage,
			showFlag,
			handleEnableAction,
		]);

		useEffect(() => {
			getResultOfAssociatingFields();
		}, [
			fieldsFailedToAssociate,
			fieldsSuccessfullyAssociated,
			getResultOfAssociatingFields,
			handleEnableAction,
		]);

		const enableOrConfigureFieldsAction =
			projectStyle === COMPANY_MANAGED_PROJECT ? (
				<SectionMessageAction
					key={1}
					href={configureFieldsPageHref}
					onClick={() => {
						fireUIAnalytics(analyticsEvent, 'configureDateFields');
					}}
				>
					{formatMessage(messages.configureFields)}
				</SectionMessageAction>
			) : (
				<SectionMessageAction key={1} onClick={handleEnableAction}>
					{formatMessage(messages.enable)}
				</SectionMessageAction>
			);

		const enableLoadingSpinner = useMemo(
			() => (
				<Spinner
					testId="issue-date-fields-message.ui.spinner"
					interactionName="enablingDateFields"
					label={formatMessage(messages.enablingDateFieldsSpinnerLabel)}
				/>
			),
			[formatMessage],
		);

		const dismissAction = (
			<SectionMessageAction key={2} onClick={handleDismissAction}>
				{formatMessage(messages.dismiss)}
			</SectionMessageAction>
		);

		const messageAttributes: MessageAttributesType = canEditProjectConfig
			? {
					color: token('color.icon.warning'),
					icon: WarningIcon,
					iconLabel: 'warning',
					actions: isAssociatingLoading
						? [enableLoadingSpinner]
						: [enableOrConfigureFieldsAction, dismissAction],
					messageDescription: formatMessage(messages.enableDateFieldsDescriptionAdmin),
				}
			: {
					color: token('color.icon.information'),
					icon: InformationIcon,
					iconLabel: 'information',
					actions: [dismissAction],
					messageDescription: formatMessage(messages.enableDateFieldsDescriptionNonAdmin),
				};

		const Icon = messageAttributes.icon;
		return (
			<Box
				as="section"
				paddingInline="space.100"
				paddingBlock="space.200"
				testId="issue-date-fields-message.ui.date-fields-message"
			>
				<FireUiAnalytics
					actionSubject="sectionMessage"
					actionSubjectId="dateFieldsMessage"
					action="viewed"
					attributes={{ isAdmin: canEditProjectConfig }}
				/>
				<Inline space="space.100" alignBlock="stretch">
					<Box>
						<Icon
							color={messageAttributes.color}
							spacing="spacious"
							label={messageAttributes.iconLabel}
							LEGACY_size="small"
						/>
					</Box>
					<Stack space="space.100">
						<Heading as="h3" size="xsmall">
							{formatMessage(messages.enableDateFieldsTitle)}
						</Heading>

						<Box>
							<Text>{messageAttributes.messageDescription}</Text>
						</Box>

						<Inline shouldWrap separator="·" space="space.100" rowSpace="space.0" role="list">
							{messageAttributes.actions.map((action, id) => (
								<Inline role="listitem" key={id}>
									{action}
								</Inline>
							))}
						</Inline>
					</Stack>
				</Inline>
			</Box>
		);
	},
);
