// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ReactNode, type ReactElement, type ComponentProps } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

type Props = {
	header: string;
	description: string | undefined;
	image: ReactElement<ComponentProps<'img'>>;
	children: ReactNode;
};

export const ErrorDisplay = (props: Props) => (
	<Container data-testid="issue-view-errors.common.ui.error-display.permission-error-display">
		{props.image}
		<Heading>{props.header}</Heading>
		<Description>{props.description}</Description>
		{props.children}
	</Container>
);

ErrorDisplay.defaultProps = {
	description: undefined,
	children: null,
};

/**
 * Duplicated from `src/packages/issue/issue-view/src/views/issue-details/issue-modal/styled.tsx`
 *
 * TODO: Derive properly on `src/packages/issue/issue-view/src/views/issue-details/error` cleanup
 */
const modalDialogContainerSelectorName = 'jira.issue-view.issue-details.modal-dialog-container';
const MODAL_DIALOG_CONTAINER_COMPONENT_SELECTOR = `[data-component-selector="${modalDialogContainerSelectorName}"]`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	textAlign: 'center',
	flexDirection: 'column',

	color: token('color.text'),
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	paddingTop: token('space.800'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	[`${MODAL_DIALOG_CONTAINER_COMPONENT_SELECTOR} &`]: {
		paddingBottom: token('space.800'),
		paddingTop: 0,
	},
});

// The max-width: 100% in Heading and Description are required by ie11
// for the text to wrap correctly
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Heading = styled.h2({
	font: token('font.heading.medium'),
	marginTop: token('space.150'),
	marginBottom: token('space.150'),
	maxWidth: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Description = styled.p({
	maxWidth: '100%',
});
