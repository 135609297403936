/** @jsx jsx */
import React, { useEffect, useState, useCallback, type ReactNode } from 'react';
import { css, jsx } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled, { css as cssStyled } from 'styled-components';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { ErrorFlag } from '@atlassian/jira-issue-error-flag/src/index.tsx';
import { InlineEditContainer } from '@atlassian/jira-issue-field-inline-edit/src/styled.tsx';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import { useStatusField } from '@atlassian/jira-issue-field-status/src/services/use-status-service/index.tsx';
import { DUE_DATE } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	useProjectKey,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { DATE_TEST_ID, DATE_READ_VIEW_TEST_ID } from '../common/constants.tsx';
import type { JiraDatePickerValue } from '../common/types.tsx';
import { useDateField } from '../services/use-date-field/index.tsx';
import { DateFieldEditView } from './edit/index.tsx';
import messages from './messages.tsx';
import type { DateFieldProps } from './types.tsx';
import { DateFieldReadView } from './view/index.tsx';

export const ACTION_SUBJECT = 'dateFieldInlineEdit';

const DateFieldDefault = ({
	fieldKey,
	issueKey,
	isEditing: isForceEditing,
	onFormatDate,
	onCancel,
	onEdit,
	onEscape,
	onConfirm,
	onEnter,
	onUpdate,
	onError,
	readView,
	editView,
	noValueText,
	renderAppEditView,
	showUrgencyIndicator,
	label,
	...rest
}: DateFieldProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isEditing, setIsEditing] = useState<boolean>(false);

	const onSuccess = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'newValue' implicitly has an 'any' type.
		(newValue) => {
			onUpdate?.(newValue);
			setIsEditing(false);
		},
		[onUpdate],
	);

	const [{ value, error, fieldConfig }, { saveValue, resetError }] = useDateField({
		issueKey: toIssueKey(issueKey),
		fieldKey,
		onSuccess,
	});
	const isFieldEditable = fieldConfig?.isEditable ?? false;

	useEffect(() => {
		if (error) {
			setIsEditing(true);
			onError?.(error);
		}
	}, [error, isEditing, onError, value]);

	const cancel = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			resetError();
			setIsEditing(false);

			fireUIAnalytics(analyticsEvent);
		},
		[resetError],
	);

	const onCancelRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			cancel(analyticsEvent);
			onCancel?.(analyticsEvent);
		},
		[onCancel, cancel],
	);

	const onEditRequested = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			setIsEditing(true);
			fireUIAnalytics(analyticsEvent);
			onEdit?.(analyticsEvent);
		},
		[onEdit],
	);

	const onEscapeRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			cancel(analyticsEvent);
			onEscape?.(analyticsEvent);
		},
		[onEscape, cancel],
	);

	const confirm = useCallback(
		(newValue: JiraDatePickerValue, analyticsEvent: UIAnalyticsEvent) => {
			setIsEditing(false);
			fireUIAnalytics(analyticsEvent);
			onConfirm?.(newValue, analyticsEvent);
		},
		[onConfirm],
	);

	const onConfirmRequestWithoutSave = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			confirm(value, analyticsEvent);
		},
		[confirm, value],
	);

	const onChangeWithSave = useCallback(
		(newValue: JiraDatePickerValue) => {
			resetError();

			// Mimic onConfirm analytics
			const analyticsEvent = createAnalyticsEvent({
				action: 'confirmed',
				actionSubject: 'dateFieldInlineEdit',
			});

			if (newValue !== value) {
				saveValue(newValue, null, analyticsEvent);
			}
			confirm(newValue, analyticsEvent);
		},
		[resetError, createAnalyticsEvent, confirm, saveValue, value],
	);

	const renderReadView = (
		<ReadViewContainer data-testid={DATE_READ_VIEW_TEST_ID} isEditable={isFieldEditable}>
			{readView !== undefined ? (
				readView
			) : (
				<DateFieldReadView
					value={value}
					noValueText={noValueText}
					onFormatDate={onFormatDate}
					showUrgencyIndicator={showUrgencyIndicator}
				/>
			)}
		</ReadViewContainer>
	);

	const renderEditView = useCallback(
		() =>
			(renderAppEditView && renderAppEditView()) ||
			(editView !== undefined ? (
				editView
			) : (
				<Box xcss={editViewContainerStyles}>
					<DateFieldEditView
						autoFocus
						value={value}
						onChange={onChangeWithSave}
						isInvalid={!!error}
						label={label}
					/>
				</Box>
			)),
		[editView, error, onChangeWithSave, renderAppEditView, value, label],
	);

	return (
		<>
			{error != null && (
				<ErrorFlag error={error} title={messages.errorTitle} description={messages.errorMessage} />
			)}
			<DateInlineEditContainer isEditable={isFieldEditable}>
				<FieldInlineEditStateLess
					testId={DATE_TEST_ID}
					fieldId={fg('one_event_rules_them_all_fg') ? fieldKey : undefined}
					areActionButtonsHidden
					actionSubject={ACTION_SUBJECT}
					isEditable={isFieldEditable}
					isEditing={isForceEditing ?? isEditing}
					readView={renderReadView}
					editView={isFieldEditable ? renderEditView : null}
					onCancel={onCancelRequest}
					onConfirm={onConfirmRequestWithoutSave}
					onEdit={onEditRequested}
					onEscape={onEscapeRequest}
					{...rest}
				/>
			</DateInlineEditContainer>
		</>
	);
};

// TODO: Merge this logic into DateFieldDefault when cleaning up due_date_colured_field experiment
// I've extracted this piece of logic into a separate component so dependency on useStatusField is also FFed
const DateFieldWithStatusAndFieldKeyChecks = (props: DateFieldProps) => {
	const [{ value: currentStatus }] = useStatusField({
		issueKey: props.issueKey,
	});

	// NOTE: non-JSM check is only for the scope of experiment. Pls remove if this is approved for GA
	const projectKey = useProjectKey(props.issueKey);
	const projectType = useProjectType(projectKey);

	const shouldShowUrgencyIndicator =
		projectType !== 'service_desk' &&
		props.fieldKey === DUE_DATE &&
		currentStatus?.statusCategory.id !== 3 &&
		expVal('due_date_colured_field', 'showUrgencyIndicator', false);

	return <DateFieldDefault {...props} showUrgencyIndicator={shouldShowUrgencyIndicator} />;
};

export const DateField = componentWithFG(
	'issue_view_due_date_colored_field_targeting_gate',
	DateFieldWithStatusAndFieldKeyChecks,
	DateFieldDefault,
);

const ReadViewContainerNew = ({
	isEditable,
	children,
	...props
}: {
	isEditable: boolean;
	children: ReactNode;
}) => (
	<div css={[!isEditable && nonEditableReadViewContainerStyles]} {...props}>
		{children}
	</div>
);

const nonEditableReadViewContainerStyles = css({
	left: 0,
	paddingTop: token('space.075'),
	paddingRight: 0,
	paddingBottom: token('space.075'),
	paddingLeft: 0,
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewContainerOld = styled.div({
	display: 'flex',
	flex: '1 1 auto',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '20px',
	wordBreak: 'break-word',
	position: 'relative',
	width: '100%',
});

const editViewContainerStyles = xcss({
	paddingLeft: 'space.075',
});

// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression
const nonEditableDateInlineEditContainerStyles = cssStyled`
	margin-left: 0;

	${'' /* NonEditableMargin overrides */}
	& > div {
		margin: 11px 0 3px ${token('space.100')};
	}

	${ReadViewContainerOld} {
		left: 0;
		padding: ${token('space.075')} 0;
		line-height: 1;
	}
`;

interface DateInlineEditContainerProps {
	isEditable: boolean;
	children: ReactNode;
}

// @ts-expect-error - TS2345: Argument of type 'StyledComponentClass'
// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DateInlineEditContainer: React.FC<DateInlineEditContainerProps> = styled<DateInlineEditContainerProps>(
	// @ts-expect-error - InlineEditContainerProps Argument of type 'FC<InlineEditContainerPropsNew>' is not assignable to parameter of type 'ComponentType<DateInlineEditContainerProps>'.
	InlineEditContainer,
)`
	${({ isEditable }) => !isEditable && nonEditableDateInlineEditContainerStyles}

	width: 100%;
	margin-left: ${token('space.negative.100')};
	margin-top: ${token('space.negative.100')};
	padding-right: ${token('space.100')};

	& div[data-read-view-fit-container-width] {
		display: flex;
		align-items: center;
		width: 100%;
		min-height: ${gridSize * 4}px;
		padding: 0 0 0 ${token('space.075')};
	}
`;

const ReadViewContainer = componentWithFG(
	'issue_view_field_config_edit',
	ReadViewContainerNew,
	ReadViewContainerOld,
);
