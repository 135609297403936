import React from 'react';
import { styled } from '@compiled/react';
import { useFragment, graphql } from 'react-relay';
import Tag from '@atlaskit/tag';
import { token } from '@atlaskit/tokens';
import { EmptyFieldText } from '@atlassian/jira-issue-empty-field-text/src/ui/index.tsx';
import type { labels_issueFieldLabelsReadviewFull_LabelsReadView$key as LabelReadViewFragment } from '@atlassian/jira-relay/src/__generated__/labels_issueFieldLabelsReadviewFull_LabelsReadView.graphql';
import { memoizeGetAutoGeneratedColorForLabelsTag } from '@atlassian/jira-option-color-picker/src/option-color-generator/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { ColoredTag } from '@atlassian/jira-option-color-picker/src/colored-tag/index.tsx';
import { getLabelHref } from '../../services/transform-strings/utils.tsx';
import { LabelsTruncatedReadView } from './truncated/index.tsx';
import type { LabelsReadViewProps } from './types.tsx';

/**
 * The LabelsReadView will show a read only view of labels.
 * @param props {@link LabelsReadViewProps}
 */
export const LabelsReadView = ({
	baseSearchUrl,
	fragmentRef,
	isTruncated,
	isHoverPopoverEnabled,
}: LabelsReadViewProps) => {
	const data = useFragment<LabelReadViewFragment>(
		graphql`
			fragment labels_issueFieldLabelsReadviewFull_LabelsReadView on JiraLabelsField {
				fieldId
				selectedLabelsConnection(first: 1000) @required(action: THROW) {
					totalCount
					edges {
						node {
							name
						}
					}
				}
			}
		`,
		fragmentRef,
	);

	const { selectedLabelsConnection: labels, fieldId } = data;

	if (labels.totalCount !== 0) {
		const labelValues =
			labels?.edges
				?.filter(Boolean)
				.map((edge) => edge.node)
				.filter((label): label is { name: string } => label != null && label.name != null) || [];

		if (labelValues.length !== 0) {
			if (isTruncated) {
				return (
					<LabelsTruncatedReadView
						baseSearchUrl={baseSearchUrl}
						fieldId={fieldId}
						labelValues={labelValues}
						isHoverPopoverEnabled={isHoverPopoverEnabled}
					/>
				);
			}

			if (
				!isVisualRefreshEnabled() &&
				expVal('adding-color-to-labels-field-in-jira-experiment', 'isColoredLabelsEnabled', false)
			) {
				return (
					<GroupWithNegativeMarginOffset>
						{labelValues.map((label) => (
							<ColoredTag
								key={label.name}
								labelName={label.name}
								href={getLabelHref(fieldId, label.name, baseSearchUrl)}
							/>
						))}
					</GroupWithNegativeMarginOffset>
				);
			}

			return (
				<GroupWithNegativeMarginOffset>
					{labelValues.map((label) => (
						// @ts-expect-error - ts2322 - Type 'string' is not assignable to type 'TagColor'.
						<Tag
							key={label.name}
							isRemovable={false}
							text={label.name}
							href={getLabelHref(fieldId, label.name, baseSearchUrl)}
							{...(expVal(
								'adding-color-to-labels-field-in-jira-experiment',
								'isColoredLabelsEnabled',
								false,
							)
								? { color: memoizeGetAutoGeneratedColorForLabelsTag(label.name) }
								: {})}
						/>
					))}
				</GroupWithNegativeMarginOffset>
			);
		}
	}

	return <EmptyFieldText />;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GroupWithNegativeMarginOffset = styled.div({
	display: 'flex',
	width: '100%',
	flexWrap: 'wrap',
	justifyContent: 'flex-start',
	// Tag has a built-in margin of 4px, so we use a negative side margin to allow tags to align with their parent.
	marginTop: 0,
	marginRight: token('space.negative.050'),
	marginBottom: 0,
	marginLeft: token('space.negative.050'),
});
