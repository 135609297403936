import { CUSTOM_FIELD_MODULE } from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import type { Source } from '@atlassian/jira-forge-ui-types/src/common/types/analytics.tsx';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import { fireOperationalEvent } from '../../common/utils/consumers/index.tsx';
import { getEventId, getEventIdOld } from '../../common/utils/index.tsx';
import { logError, CUSTOM_FIELD_OBJECT_TYPE } from '../../common/utils/logger/index.tsx';
import { MODULE_TYPE_EXTENSION, OPERATIONAL_ACTIONS } from '../../constants.tsx';

const moduleEventId = getEventIdOld({
	module: CUSTOM_FIELD_MODULE,
	moduleType: MODULE_TYPE_EXTENSION,
});

// This method will generate the same metric key as error boundary
export const fireOperationalFailedEvent = (source?: Source, attributes?: Attributes) =>
	fireOperationalEvent(OPERATIONAL_ACTIONS.FAILED, {
		id: fg('ditto-fcf-support-new-manifest-on-frontend')
			? getEventId({
					module: CUSTOM_FIELD_MODULE,
					moduleType: MODULE_TYPE_EXTENSION,
				})
			: moduleEventId,
		source,
		attributes,
	});

// It is a separate event which is excluded from our SLO
// For all other errors the fireOperationalFailedEvent one should be used instead
export const fireOperationalLayoutIssueEvent = (source?: Source, attributes?: Attributes) =>
	fireOperationalEvent('layout issue', {
		id: fg('ditto-fcf-support-new-manifest-on-frontend')
			? getEventId({
					module: CUSTOM_FIELD_MODULE,
					moduleType: MODULE_TYPE_EXTENSION,
				})
			: moduleEventId,
		source,
		attributes,
	});

export const logCustomFieldObjectTypeError = (message: string) =>
	logError(CUSTOM_FIELD_OBJECT_TYPE, message);

export const fireValueFunctionExecuteInitilizedEvent = (source?: Source, attributes?: Attributes) =>
	fireOperationalEvent(OPERATIONAL_ACTIONS.INITIALIZED, {
		id: 'valueFunction',
		source,
		attributes,
	});

export const fireValueFunctionExecuteFailedEvent = (source?: Source, attributes?: Attributes) =>
	fireOperationalEvent(OPERATIONAL_ACTIONS.FAILED, {
		id: 'valueFunction',
		source,
		attributes,
	});
