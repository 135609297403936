import React, { useCallback, useMemo } from 'react';
import {
	fetchQuery,
	graphql,
	useFragment,
	useLazyLoadQuery,
	useMutation,
	useRelayEnvironment,
} from 'react-relay';
import { useIntl } from '@atlassian/jira-intl';
import type { AssigneeInlineEditViewBaseProps } from '@atlassian/jira-issue-field-assignee-inline-edit-full/src/ui/assignee/types.tsx';
import type { AggUser } from '@atlassian/jira-issue-user-picker-edit-view/src/common/types.tsx';
import type { UserOptionValue, UserOption } from '@atlassian/jira-issue-user-picker/src/types.tsx';
import { getInitialOptions } from '@atlassian/jira-issue-view-base/src/context/assignee/assignee-view.tsx';
import { CommandPaletteAssigneeList } from '@atlassian/jira-issue-view-base/src/context/assignee/command-palette-assignee/index.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import type {
	commandPaletteAssignee_issueViewLayoutAssigneeField$key,
	commandPaletteAssignee_issueViewLayoutAssigneeField$data,
} from '@atlassian/jira-relay/src/__generated__/commandPaletteAssignee_issueViewLayoutAssigneeField.graphql';
import type { commandPaletteAssignee_issueViewLayoutAssigneeFieldQuery } from '@atlassian/jira-relay/src/__generated__/commandPaletteAssignee_issueViewLayoutAssigneeFieldQuery.graphql';
import type { commandPaletteAssignee_loggedInUserQuery } from '@atlassian/jira-relay/src/__generated__/commandPaletteAssignee_loggedInUserQuery.graphql';
import type { commandPaletteAssignee_Mutation } from '@atlassian/jira-relay/src/__generated__/commandPaletteAssignee_Mutation.graphql';

const transformRelayToLegacy = (
	user: commandPaletteAssignee_issueViewLayoutAssigneeField$data['user'],
): UserOptionValue => ({
	id: user?.id || '',
	accountId: user?.accountId,
	name: user?.name || '',
	displayName: user?.name,
	avatarUrl: user?.picture,
	active: user?.accountStatus === 'active',
});

export type CommandPaletteAssigneeProps = {
	fragmentKey: commandPaletteAssignee_issueViewLayoutAssigneeField$key;
} & Pick<AssigneeInlineEditViewBaseProps, 'onSubmit' | 'onSubmitFailed' | 'onSubmitSucceeded'>;

export const CommandPaletteAssignee = ({
	fragmentKey,
	onSubmit,
	onSubmitFailed,
	onSubmitSucceeded,
}: CommandPaletteAssigneeProps) => {
	const environment = useRelayEnvironment();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const user = useLazyLoadQuery<commandPaletteAssignee_loggedInUserQuery>(
		graphql`
			query commandPaletteAssignee_loggedInUserQuery {
				me {
					user {
						id
						name
						picture
					}
				}
			}
		`,
		Object.freeze({}),
		{
			fetchPolicy: 'store-or-network',
		},
	);

	const field = useFragment<commandPaletteAssignee_issueViewLayoutAssigneeField$key>(
		graphql`
			fragment commandPaletteAssignee_issueViewLayoutAssigneeField on JiraSingleSelectUserPickerField {
				id
				name
				fieldId
				user {
					accountId
					id
					name
					picture
					accountStatus
				}
				issue {
					projectField {
						project {
							key
						}
					}
				}
			}
		`,
		fragmentKey,
	);

	const fetchSuggestions: (
		query: string,
		assignSessionId?: string,
	) => Promise<readonly (UserOption | null)[]> = useCallback(
		async (query: string) => {
			// fetchQuery
			const response = await fetchQuery<commandPaletteAssignee_issueViewLayoutAssigneeFieldQuery>(
				environment,
				graphql`
					query commandPaletteAssignee_issueViewLayoutAssigneeFieldQuery(
						$id: ID!
						$query: String!
					) {
						node(id: $id) {
							... on JiraSingleSelectUserPickerField {
								users(searchBy: $query, suggested: true) {
									edges {
										node {
											accountId
											id
											name
											picture
											accountStatus
										}
									}
								}
							}
						}
					}
				`,
				{
					id: field.id,
					query,
				},
			).toPromise();
			const result: readonly (UserOption | null)[] =
				response?.node?.users?.edges?.map((edge) => transformRelayToLegacy(edge?.node) || null) ||
				[];
			return result;
		},
		[environment, field.id],
	);

	const transformedValue: UserOptionValue = useMemo(
		() => transformRelayToLegacy(field.user),
		[field.user],
	);
	const projectKey = field?.issue?.projectField?.project?.key || '';

	const [{ canBeAssignedToIssues }] = useProjectPermissions(projectKey);

	const { formatMessage } = useIntl();

	const initialOptions = useMemo(
		() =>
			getInitialOptions(
				{
					id: user?.me?.user?.id || '',
					displayName: user?.me?.user?.name,
					avatarUrl: user?.me?.user?.picture,
				},
				transformedValue,
				formatMessage,
				!!canBeAssignedToIssues,
				true,
			),
		[
			canBeAssignedToIssues,
			formatMessage,
			transformedValue,
			user?.me?.user?.id,
			user?.me?.user?.name,
			user?.me?.user?.picture,
		],
	);

	const [commit] = useMutation<commandPaletteAssignee_Mutation>(graphql`
		mutation commandPaletteAssignee_Mutation($input: JiraUpdateSingleSelectUserPickerFieldInput!)
		@raw_response_type {
			jira {
				updateSingleSelectUserPickerField(input: $input) @optIn(to: "JiraIssueFieldMutations") {
					success
					errors {
						message
					}
					field {
						user {
							accountId
							name
							picture
							accountStatus
							id
						}
					}
				}
			}
		}
	`);
	// #endregion

	const onSave = useCallback(
		async (
			newUser: UserOptionValue,
			onSuccess: (value: UserOptionValue) => void,
			onFail: (err: Error) => void,
		) => {
			const newAggUser: (AggUser & { __typename: 'AtlassianAccountUser' }) | null = newUser
				? {
						id: newUser.id || '',
						accountId: newUser.accountId || '',
						accountStatus: 'active',
						name: newUser.name || '',
						picture: newUser.avatarUrl || '',
						__typename: 'AtlassianAccountUser',
					}
				: null;
			onSubmit?.(newAggUser);
			commit({
				variables: {
					input: {
						id: field.id,
						operation: {
							operation: 'SET',
							id: newUser?.id ?? null,
						},
					},
				},
				onCompleted: (mutationData) => {
					if (mutationData.jira?.updateSingleSelectUserPickerField?.success) {
						onSubmitSucceeded?.(newAggUser);
						onSuccess?.(newAggUser);
						// Field updated event is a core action used to track MCU & collaboration metrics
						// as defined in https://hello.atlassian.net/wiki/spaces/ANALYTICS/pages/3767029088/Monthly+Core+User+MCU+Definition
						// and https://hello.atlassian.net/wiki/spaces/Spork/pages/4098606074/Warepil+-+Collaboration+Statsig+Metric?focusedCommentId=4269736439
						fireTrackAnalytics(createAnalyticsEvent({}), 'field updated', {
							editOperationType: 'set',
							fieldKey: field.fieldId,
							fieldType: 'user',
						});
					} else {
						onSubmitFailed?.();
						onFail(new Error('Failed to update assignee'));
					}
				},
				onError(error: Error) {
					onSubmitFailed?.();
					onFail(error);
				},
				optimisticResponse: {
					jira: {
						updateSingleSelectUserPickerField: {
							success: true,
							errors: null,
							field: {
								id: field.id,
								user: newAggUser,
							},
						},
					},
				},
			});
		},
		[
			onSubmit,
			commit,
			field.id,
			field.fieldId,
			onSubmitSucceeded,
			createAnalyticsEvent,
			onSubmitFailed,
		],
	);

	return (
		<CommandPaletteAssigneeList
			fetchSuggestions={fetchSuggestions}
			initialOptions={initialOptions}
			value={transformedValue}
			onSave={onSave}
		/>
	);
};
