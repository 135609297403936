/**
 * @generated SignedSource<<2ca471c9c3ad28e06afcd91e53ac5368>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type singleSelect_issueFieldSingleSelectReadviewFull_SingleSelectReadView$data = {
  readonly fieldOption: {
    readonly color?: {
      readonly colorKey: string | null | undefined;
    } | null | undefined;
    readonly value: string | null | undefined;
  } | null | undefined;
  readonly fieldOptions: {
    readonly totalCount: number | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "singleSelect_issueFieldSingleSelectReadviewFull_SingleSelectReadView";
};
export type singleSelect_issueFieldSingleSelectReadviewFull_SingleSelectReadView$key = {
  readonly " $data"?: singleSelect_issueFieldSingleSelectReadviewFull_SingleSelectReadView$data;
  readonly " $fragmentSpreads": FragmentRefs<"singleSelect_issueFieldSingleSelectReadviewFull_SingleSelectReadView">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "concreteType": "JiraColor",
    "kind": "LinkedField",
    "name": "color",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "colorKey"
      }
    ]
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider"
    }
  ],
  "kind": "Fragment",
  "name": "singleSelect_issueFieldSingleSelectReadviewFull_SingleSelectReadView",
  "selections": [
    {
      "concreteType": "JiraOptionConnection",
      "kind": "LinkedField",
      "name": "fieldOptions",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "totalCount"
        }
      ]
    },
    {
      "concreteType": "JiraOption",
      "kind": "LinkedField",
      "name": "fieldOption",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "value"
        },
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
          "kind": "Condition",
          "passingValue": true,
          "selections": (v0/*: any*/)
        },
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider",
          "kind": "Condition",
          "passingValue": true,
          "selections": (v0/*: any*/)
        }
      ]
    }
  ],
  "type": "JiraSingleSelectField"
};
})();

(node as any).hash = "10f576713aea3253f6151f9c99b9b5c5";

export default node;
