import React from 'react';
import { graphql, useFragment } from 'react-relay';
import {
	IN_PROGRESS,
	DONE,
	statusCategoryForId,
} from '@atlassian/jira-common-constants/src/status-categories.tsx';
import type { MainIssueAggQueryRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { CHILDREN_WITHIN_LIMIT_GQL_FIELD } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { src_progressSummaryDataFragment$key } from '@atlassian/jira-relay/src/__generated__/src_progressSummaryDataFragment.graphql';
import { ProgressSummaryView } from '../view.tsx';

type Props = {
	rootRelayFragment: MainIssueAggQueryRelayFragment | null;
	isClassicSubtaskPanel: boolean;
};
export const ProgressSummaryData = ({ rootRelayFragment, isClassicSubtaskPanel }: Props) => {
	const fragment =
		rootRelayFragment?.jira?.issueByKey?.childIssues?.__typename === CHILDREN_WITHIN_LIMIT_GQL_FIELD
			? rootRelayFragment?.jira?.issueByKey?.childIssues.issues
			: null;

	const childIssues = useFragment<src_progressSummaryDataFragment$key>(
		graphql`
			fragment src_progressSummaryDataFragment on JiraIssueConnection {
				edges {
					node {
						statusField {
							status {
								statusCategory @required(action: LOG) {
									statusCategoryId
								}
							}
						}
					}
				}
			}
		`,
		fragment || null,
	);

	const issues = childIssues?.edges || [];

	const getCompletedIssues = issues.filter(
		(e) =>
			statusCategoryForId(e?.node?.statusField?.status?.statusCategory.statusCategoryId) === DONE,
	);

	const getInProgressIssues = issues.filter(
		(e) =>
			statusCategoryForId(e?.node?.statusField?.status?.statusCategory.statusCategoryId) ===
			IN_PROGRESS,
	);

	return (
		<ProgressSummaryView
			isClassicSubtaskPanel={isClassicSubtaskPanel}
			totalIssueCount={issues.length}
			inProgressIssueCount={getInProgressIssues.length}
			completedIssueCount={getCompletedIssues.length}
		/>
	);
};
