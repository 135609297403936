// This component deals exclusively with the creation of new comments.
// For editing, there is a separate version available from @atlassian/jira-issue-comment-base

import React, { useMemo, type ComponentPropsWithoutRef, type ComponentType } from 'react';
import {
	CommentEditorWithAIContext,
	CommentEditorWithAI,
} from '@atlassian/jira-canned-comments/src/ui/ai-comments/index.tsx';
import { CannedCommentsPlaceHolderWithClick } from '@atlassian/jira-canned-comments/src/ui/canned-comments-placeholder/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { IssueTaskDecisionProvider } from '@atlassian/jira-issue-task-decision-provider/src/controllers/index.tsx';
import { LazyIssueEditor } from '@atlassian/jira-issue-view-common/src/component/editor/issue-editor.tsx';
import { useHandleQuickReply } from '@atlassian/jira-quick-reply/src/controllers/use-handle-quick-reply/index.tsx';
import { CommentsPlaceholder } from './comments-placeholder.tsx';
import { enhanceIssueCommentEditor, type IssueCommentEditorPropsT } from './common.tsx';

type SmartRepliesType = {
	isSmartRepliesEnabled?: boolean;
	isAIRepliesEnabled?: boolean;
	hasDraft?: boolean;
};

type QuickRepliesType = {
	isQuickRepliesEnabled?: boolean;
};

export type IssueCommentEditorProps = IssueCommentEditorPropsT<typeof LazyIssueEditor> &
	SmartRepliesType &
	QuickRepliesType;

export const IssueEditorWithExperiment = ({
	isSmartRepliesEnabled,
	isQuickRepliesEnabled,
	isAIRepliesEnabled,
	hasDraft,
	...props
}: ComponentPropsWithoutRef<typeof LazyIssueEditor> & SmartRepliesType & QuickRepliesType) => {
	const { hasQuickReply } = isQuickRepliesEnabled
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useHandleQuickReply(props.onChange)
		: { hasQuickReply: false };

	if (!hasQuickReply && isAIRepliesEnabled && expVal('ai_smart_replies', 'isEnabled', false)) {
		const renderContent = () => {
			if (!props.isExpanded) {
				if (hasDraft) {
					return (
						<CommentsPlaceholder
							placeholder={props.collapsedPlaceholder}
							onFocus={props.onExpanded}
							onClick={props.onExpanded}
						/>
					);
				}
				return (
					<CommentEditorWithAI
						onChange={props.onChange}
						onExpanded={props.onExpanded}
						placeholder={props.collapsedPlaceholder}
					/>
				);
			}
			return <LazyIssueEditor {...props} isAddComment />;
		};
		return (
			<JSErrorBoundary
				id="AiSmartReplies"
				packageName="jiraIssueViewActivityComment"
				teamName="jira-warepil"
				fallback={() => <LazyIssueEditor {...props} isAddComment />}
			>
				<CommentEditorWithAIContext>{renderContent()}</CommentEditorWithAIContext>
			</JSErrorBoundary>
		);
	}

	// Statsig Experiment exposure gets fired here
	if (!hasQuickReply && isSmartRepliesEnabled && expVal('smart_replies_m1', 'isEnabled', false)) {
		if (!props.isExpanded) {
			return (
				<JSErrorBoundary
					id="smartRepliesCannedCommentPlacholder"
					packageName="jiraIssueViewActivityComment"
					teamName="jira-warepil"
					fallback={() => <LazyIssueEditor {...props} isAddComment />}
				>
					<CannedCommentsPlaceHolderWithClick
						onChange={props.onChange}
						onExpanded={props.onExpanded}
						collapsedPlaceholder={props.collapsedPlaceholder}
					/>
				</JSErrorBoundary>
			);
		}
		return <LazyIssueEditor {...props} isAddComment />;
	}

	if (!props.isExpanded) {
		return (
			<CommentsPlaceholder
				placeholder={props.collapsedPlaceholder}
				onFocus={props.onExpanded}
				onClick={props.onExpanded}
			/>
		);
	}

	return <LazyIssueEditor {...props} isAddComment />;
};

const IssueEditorWithTaskDecisionProvider = (
	props: ComponentPropsWithoutRef<typeof LazyIssueEditor> & SmartRepliesType & QuickRepliesType,
) => {
	// Create empty `TaskDecisionProvider`; `onSave` will handle the ADF update
	const taskDecisionProvider = useMemo(() => new IssueTaskDecisionProvider(), []);
	return <IssueEditorWithExperiment {...props} taskDecisionProvider={taskDecisionProvider} />;
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default enhanceIssueCommentEditor(
	IssueEditorWithTaskDecisionProvider,
) as ComponentType<IssueCommentEditorProps>;
