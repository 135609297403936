import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	description: {
		id: 'issue-view-activity-comment.comment-add.comment-empty-state.description',
		defaultMessage:
			'Light up the comment section - add an update, ask a question, or @mention someone.',
		description: 'Description for the comment empty state',
	},
	header: {
		id: 'issue-view-activity-comment.comment-add.comment-empty-state.header',
		defaultMessage: 'Spark a conversation',
		description: 'Header for the comment empty state',
	},
});
