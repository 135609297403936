// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { RecurOnScheduleConfig } from '../../common/types.tsx';
import { deleteIssueProperties } from '../../services/set-non-recurring.tsx';
import type { StoreContainerProps, State } from '../types.tsx';
import { callServiceWithAnalytics } from '../../common/utils.tsx';
import { setAsRecurringIssue } from './set-as-recurring-issue.tsx';
import { setAsNonRecurringIssue } from './set-as-non-recurring-issue.tsx';

export type UpdateRuleParams = {
	isProjectAdmin: boolean;
	isRuleOwner: boolean | null;
	isSiteAdmin: boolean;
	recurOnScheduleConfig: RecurOnScheduleConfig;
};

export const updateRule =
	({
		isProjectAdmin,
		isRuleOwner,
		isSiteAdmin,
		recurOnScheduleConfig,
	}: UpdateRuleParams): Action<State, StoreContainerProps> =>
	async ({ setState, getState, dispatch }, { createAnalyticsEvent, issueKey }) => {
		const { issueProperties, errors } = getState();

		if (!issueProperties) {
			// if there is no existing rule, then create a new rule and update issue properties
			await dispatch(
				setAsRecurringIssue({
					recurOnScheduleConfig,
				}),
			);
			return;
		}

		const { automationRuleUuid: ruleUuid, templateId } = issueProperties;

		setState({
			isUpdatingRule: true,
		});

		try {
			await dispatch(
				setAsNonRecurringIssue({
					isProjectAdmin,
					isRuleOwner,
					isSiteAdmin,
					// we'll update issue properties later
					skipDeletingIssueProperties: true,
				}),
			);
		} catch (error: unknown) {
			// don't need to save the error to state because setAsNonRecurringIssue should already do this
			setState({
				isUpdatingRule: false,
			});
			throw error;
		}

		// create the new rule
		try {
			await dispatch(
				setAsRecurringIssue({
					recurOnScheduleConfig,
				}),
			);

			fireTrackAnalytics(createAnalyticsEvent({}), 'recurringRule updated', {
				// TODO: add ruleId attribute
				issueKey,
				ruleUuid,
				templateId,
				recurCondition: recurOnScheduleConfig?.recurCondition,
				isUpdateForExistingRule: true,
			});

			setState({
				isUpdatingRule: false,
			});
		} catch (error: unknown) {
			// if we fail to create the rule and update issue properties, then we need to go back and remove the
			// original recurring rule config from issue properties
			try {
				setState({
					isDeletingRule: true,
				});

				await callServiceWithAnalytics(
					createAnalyticsEvent({}),
					deleteIssueProperties({ issueKey }),
					'deleteIssueProperties',
				);

				setState({
					isDeletingRule: false,
					isUpdatingRule: false,
					issueProperties: null,
					isRuleOwner: null,
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					errors: [...(errors ?? []), error as Error],
				});
			} catch (anotherError: unknown) {
				// we've made no changes to the rule config since we called `setAsNonRecurringIssue` above,
				// so we don't need to update ruleSummary or issueProperties.
				setState({
					isDeletingRule: false,
					isUpdatingRule: false,
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					errors: [...(errors ?? []), error as Error, anotherError as Error],
				});
				throw anotherError;
			}
			// we've just successfully deleted the existing rule; we haven't actually created a new one.
			throw error;
		}
	};
