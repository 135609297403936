// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { ExternalReferenceEntitiesMap } from '../../../../services/atlas/types.tsx';
import type { State } from '../../types.tsx';

export const updateExternalReferenceEntities =
	(newExternalReferenceEntities: ExternalReferenceEntitiesMap) =>
	({ getState, setState }: StoreActionApi<State>) => {
		const somethingMissing = Object.keys(newExternalReferenceEntities).find(
			(key) => !getState().properties.externalReferenceEntities[key],
		);
		if (!somethingMissing) {
			return;
		}
		setState({
			properties: {
				...getState().properties,
				externalReferenceEntities: {
					...getState().properties.externalReferenceEntities,
					...newExternalReferenceEntities,
				},
			},
		});
	};
