/**
 * @generated SignedSource<<37874b2d4899a12504e024c9c1d7afff>>
 * @relayHash f4401fca36279473ff41a41ea649ecd0
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 129a63039aabb7b3b080855ff9249618486ccc39214c5e032651463d703c1501

import type { ConcreteRequest, Query } from 'relay-runtime';
export type contentSmartSummaryQuery$variables = {
  cloudId: string;
  issueId?: string | null | undefined;
};
export type contentSmartSummaryQuery$data = {
  readonly agentAI_summarizeIssue: {
    readonly __typename: "AgentAIIssueSummary";
    readonly createdAt: AGG$Long | null | undefined;
    readonly id: string | null | undefined;
    readonly summary: {
      readonly adf: string | null | undefined;
      readonly text: string | null | undefined;
    } | null | undefined;
  } | {
    readonly __typename: "QueryError";
    readonly extensions: ReadonlyArray<{
      readonly errorType: string | null | undefined;
      readonly statusCode: number | null | undefined;
    }> | null | undefined;
    readonly message: string | null | undefined;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
};
export type contentSmartSummaryQuery = {
  response: contentSmartSummaryQuery$data;
  variables: contentSmartSummaryQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "issueId",
    "variableName": "issueId"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "createdAt"
    },
    {
      "concreteType": "AgentAISummary",
      "kind": "LinkedField",
      "name": "summary",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "adf"
        },
        {
          "kind": "ScalarField",
          "name": "text"
        }
      ]
    }
  ],
  "type": "AgentAIIssueSummary"
},
v5 = {
  "kind": "ScalarField",
  "name": "message"
},
v6 = {
  "kind": "ScalarField",
  "name": "statusCode"
},
v7 = {
  "kind": "ScalarField",
  "name": "errorType"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "contentSmartSummaryQuery",
    "selections": [
      {
        "args": (v2/*: any*/),
        "kind": "LinkedField",
        "name": "agentAI_summarizeIssue",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "name": "extensions",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/)
                ]
              }
            ],
            "type": "QueryError"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "contentSmartSummaryQuery",
    "selections": [
      {
        "args": (v2/*: any*/),
        "kind": "LinkedField",
        "name": "agentAI_summarizeIssue",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "name": "extensions",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ]
              }
            ],
            "type": "QueryError"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "129a63039aabb7b3b080855ff9249618486ccc39214c5e032651463d703c1501",
    "metadata": {},
    "name": "contentSmartSummaryQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "0dcc3734fd81b7495fa22362ae32d018";

export default node;
