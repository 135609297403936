import React from 'react';
import {
	type ManualRule,
	type ManualRuleInvoker,
	ManualRulesContainer as ManualRulesContainerAfe,
} from '@atlassian/automation-manual-triggers';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { Environment } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import messages from '../../common/messages.tsx';

interface ManualRulesData {
	triggerFetch: () => Promise<void>;
	initialised: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	error: any;
	rules: ManualRule[];
	invokingRuleId: number | null;
	invokeRuleOrShowDialog: ManualRuleInvoker;
}

interface ManualRulesContainerProps {
	env: Environment | null;
	cloudId: string;
	issueIds: number[];
	children: (data: ManualRulesData) => React.ReactElement;
}

// pre-prod is a special environment that is used to test pre-prod automation stack in production Jira instances
type AutomationEnvironment = Environment | 'pre-prod' | null;

export const ManualRulesContainer = (props: ManualRulesContainerProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { showFlag } = useFlagsService();
	const { formatMessage } = useIntl();

	const { env, cloudId, issueIds } = props;

	let automationEnv: AutomationEnvironment = env;
	if (env === 'prod') {
		automationEnv = fg('automation-dev-helper') ? 'pre-prod' : 'prod';
	}

	return (
		<ManualRulesContainerAfe
			site={createAri({
				resourceOwner: 'jira',
				resourceType: 'site',
				resourceId: cloudId,
			})}
			query={{
				objects: issueIds?.map((id) =>
					createAri({
						resourceOwner: 'jira',
						resourceType: 'issue',
						cloudId,
						resourceId: id.toString(),
					}),
				),
			}}
			env={automationEnv}
			onInputsModalOpened={() => {
				const analyticsEvent = createAnalyticsEvent({
					actionSubject: 'manualRuleModal',
					action: 'opened',
				});
				const attributes = {
					objectType: 'issue',
				};
				fireUIAnalytics(analyticsEvent, attributes);
			}}
			onRuleInvocationLifecycleStarted={(_, __, userInputs) => {
				const analyticsEvent = createAnalyticsEvent({
					actionSubject: 'manualRule',
					action: 'tryInvoke',
				});
				const attributes = {
					objectType: 'issue',
					ruleHasInputs: Boolean(userInputs),
					objectsSelected: issueIds.length,
				};
				fireUIAnalytics(analyticsEvent, attributes);
			}}
			onRuleInvocationSuccess={() =>
				showFlag({
					type: 'info',
					title: formatMessage(messages.successFlag),
					isAutoDismiss: true,
					messageId: 'automation-platform.ui.manual-rules-container.show-flag.info.success-flag',
					messageType: 'transactional',
				})
			}
			onRuleInvocationFailure={() =>
				showFlag({
					type: 'error',
					title: formatMessage(messages.failureFlagTitle),
					description: formatMessage(messages.failureFlagDescription),
					isAutoDismiss: true,
					messageId: 'automation-platform.ui.manual-rules-container.show-flag.error.failure-flag',
					messageType: 'transactional',
				})
			}
		>
			{props.children}
		</ManualRulesContainerAfe>
	);
};
