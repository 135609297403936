/**
 * @generated SignedSource<<84b0bd80b185f9eefabcaec83a1015fc>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueViewBase_DateTime$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FieldEditIcon">;
  readonly " $fragmentType": "main_issueViewBase_DateTime";
};
export type main_issueViewBase_DateTime$key = {
  readonly " $data"?: main_issueViewBase_DateTime$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueViewBase_DateTime">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueViewBase_DateTime",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "FieldEditIcon"
    }
  ],
  "type": "JiraIssueField",
  "abstractKey": "__isJiraIssueField"
};

(node as any).hash = "4120493fe9733484d917ba9e75354391";

export default node;
