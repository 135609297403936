/**
 * @generated SignedSource<<86e7dbeeefbcb7fe63f5a06d7918b92f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type FieldEditIcon$data = {
  readonly fieldOperations?: {
    readonly canEdit: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "FieldEditIcon";
};
export type FieldEditIcon$key = {
  readonly " $data"?: FieldEditIcon$data;
  readonly " $fragmentSpreads": FragmentRefs<"FieldEditIcon">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscustomfieldconfigrelayprovider"
    }
  ],
  "kind": "Fragment",
  "name": "FieldEditIcon",
  "selections": [
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscustomfieldconfigrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "concreteType": "JiraFieldOperation",
          "kind": "LinkedField",
          "name": "fieldOperations",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "canEdit"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssueField",
  "abstractKey": "__isJiraIssueField"
};

(node as any).hash = "1875358cf5652f4a2505f428e8d4aefc";

export default node;
