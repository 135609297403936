import { createTraitsSelector } from '@atlassian/jira-traits/src/TraitsStore.tsx';
import type { TraitName } from '@atlassian/jira-traits/src/types.tsx';

export const CONFLUENCE_ACTIVATION_DATE_TRAIT = 'confluence.ondemand.activationDate';
export const JSW_ACTIVATION_DATE_TRAIT: TraitName = 'jira-software.ondemand.activationDate';
export const JPD_ACTIVATION_DATE_TRAIT: TraitName = 'jira-product-discovery.activationDate';
export const JSM_ACTIVATION_DATE_TRAIT: TraitName = 'jira-servicedesk.ondemand.activationDate';
export const JWM_ACTIVATION_DATE_TRAIT: TraitName = 'jira-workmanagement.ondemand.activationDate';

export const useIsOptoutAllMessagesSite = createTraitsSelector<'SITE'>(
	'SITE',
	({ getTraitByName }) => {
		const optoutAllMessages = getTraitByName('optout_all_messages')?.value;
		return typeof optoutAllMessages === 'boolean' && optoutAllMessages;
	},
);

export const useIsOptoutAllMessagesSiteUser = createTraitsSelector<'SITE_USER'>(
	'SITE_USER',
	({ getTraitByName }) => {
		const optoutAllMessages = getTraitByName('optout_all_messages_user')?.value;
		return typeof optoutAllMessages === 'boolean' && optoutAllMessages;
	},
);

export const useIsConfluenceActivatedWithin14Days = createTraitsSelector<'SITE'>(
	'SITE',
	({ getTraitByName }) => {
		const confluenceActivationDate = getTraitByName(CONFLUENCE_ACTIVATION_DATE_TRAIT)?.value;
		if (!confluenceActivationDate || typeof confluenceActivationDate !== 'string') return false;
		const today = new Date();
		const twoWeeksLaterActivation = new Date(confluenceActivationDate);
		twoWeeksLaterActivation.setDate(twoWeeksLaterActivation.getDate() + 14);
		return today <= twoWeeksLaterActivation;
	},
);

export const useIsAnyJiraOlderThan14Days = createTraitsSelector<'SITE'>(
	'SITE',
	({ getTraitByName }) => {
		const twoWeeksAgo = new Date();
		twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
		return [
			JSW_ACTIVATION_DATE_TRAIT,
			JPD_ACTIVATION_DATE_TRAIT,
			JSM_ACTIVATION_DATE_TRAIT,
			JWM_ACTIVATION_DATE_TRAIT,
		]
			.map((trait) => {
				const value = getTraitByName(trait)?.value;
				if (!value || typeof value !== 'string') return false;
				const activationDate = new Date(value);
				return activationDate <= twoWeeksAgo;
			})
			.some((ok) => ok);
	},
);
