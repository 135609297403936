import {
	type Action,
	FETCH_DETAIL,
	SET_DETAIL_DATA,
	FETCHING_FAILED,
	FIRE_BITBUCKET_DIFFSTAT_ANALYTICS_EVENT,
	type FireDetailDataFetchedEventAction,
	OPT_OUT_DEPLOYMENTS_DISCOVERY,
	UPDATE_PR_STATUS_AND_UPDATED_AT_AFTER_MERGING,
} from '../../actions/details/index.tsx';
import type { DetailsState } from './types.tsx';

export const defaultState: DetailsState = {
	isLoading: false,
	isError: false,
	isDiffstatAnalyticsFired: false,
	details: {
		instanceTypeDetails: [],
		deploymentProviders: [],
		embeddedMarketplaceDetails: {
			shouldDisplayForBuilds: false,
			shouldDisplayForDeployments: false,
			shouldDisplayForFeatureFlags: false,
		},
	},
	isOptOutOfDeploymentDiscovery: false,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: DetailsState = defaultState, action: Action): DetailsState => {
	switch (action.type) {
		case FETCH_DETAIL:
			return {
				...state,
				isLoading: true,
				isError: false,
			};
		case FIRE_BITBUCKET_DIFFSTAT_ANALYTICS_EVENT:
			return {
				...state,
				isDiffstatAnalyticsFired: true,
			};
		case SET_DETAIL_DATA:
			return {
				...state,
				isLoading: false,
				isError: false,
				details: action.payload.details,
				isOptOutOfDeploymentDiscovery: action.payload.isOptOutOfDeploymentDiscovery,
			};
		case FETCHING_FAILED:
			return {
				...state,
				isLoading: false,
				isError: true,
			};
		case OPT_OUT_DEPLOYMENTS_DISCOVERY:
			return {
				...state,
				isOptOutOfDeploymentDiscovery: true,
			};
		case UPDATE_PR_STATUS_AND_UPDATED_AT_AFTER_MERGING:
			return {
				...state,
				details: {
					...state.details,
					instanceTypeDetails: state.details.instanceTypeDetails.map((instanceTypeDetail) => ({
						...instanceTypeDetail,
						repositories: instanceTypeDetail.repositories.map((repository) => ({
							...repository,
							pullRequests: repository.pullRequests.map((pullRequest) => {
								if (pullRequest.url === action.payload.prUrl) {
									return {
										...pullRequest,
										status: 'MERGED',
										updatedAt: new Date().toISOString(),
									};
								}
								return pullRequest;
							}),
						})),
					})),
				},
			};
		default:
			// prettier-ignore
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			(action as never | FireDetailDataFetchedEventAction);
			return state;
	}
};
