/* eslint-disable jira/restricted/@atlassian/react-sweet-state */
import {
	type SetState,
	createStore,
	createHook,
	createContainer,
} from '@atlassian/react-sweet-state';

type State = {
	isFlaggedFieldPopupOpen: boolean;
};

type Actions = typeof actions;

const initialState: State = {
	isFlaggedFieldPopupOpen: false,
};

const actions = {
	openFlaggedFieldPopup:
		() =>
		({ setState }: { setState: SetState<State> }) => {
			setState({
				isFlaggedFieldPopupOpen: true,
			});
		},

	closeFlaggedFieldPopup:
		() =>
		({ setState }: { setState: SetState<State> }) => {
			setState({
				isFlaggedFieldPopupOpen: false,
			});
		},
};

const Store = createStore<State, Actions>({
	initialState,
	actions,
});

export const useFlaggedFieldPopupStore = createHook(Store);

export const IssueFlagContainer = createContainer(Store);
