import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import {
	type IssueTypeCreateResponse,
	performCreateIssueType,
} from '../../services/jira/create-issue-type.tsx';
import type { IssueType, IssueTypeModifiableProps, State } from '../types.tsx';

const transformIssueType = (rest: IssueTypeCreateResponse): IssueType => ({
	id: String(rest.id),
	name: rest.name,
	entityId: rest.entityId,
	description: rest.description,
	avatarId: String(rest.avatarId),
	hierarchyLevel: rest.hierarchyLevel,
	operation: {
		deletable: true,
		editable: true,
	},
});

export const createIssueType =
	(projectId: ProjectId, data: IssueTypeModifiableProps) =>
	async ({ setState, getState }: StoreActionApi<State>) => {
		try {
			const result = await performCreateIssueType(projectId, {
				name: data.name,
				description: data.description,
				avatarId: Number(data.avatarId),
			});

			const newIssueType = transformIssueType(result);

			const state = getState();

			setState({
				issueTypesById: {
					...state.issueTypesById,
					[newIssueType.id]: newIssueType,
				},
				issueTypesByProjectId: {
					...state.issueTypesByProjectId,
					[projectId]: [...(state.issueTypesByProjectId[projectId] ?? []), newIssueType.id],
				},
			});
		} catch (error) {
			logSafeErrorWithoutCustomerDataWrapper(
				'polaris.error.controllers.issue-types.actions.create-issue-type',
				'Failed to create issue type',
				error instanceof Error ? error : new Error('Error while creating issue type'),
			);

			throw error;
		}
	};
