/**
 * @generated SignedSource<<e18c79ab732f2dcdf2ff0f1eb85d68a2>>
 * @relayHash 456a10a821134cb3b44efe9894113796
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID df33cd93bb941e2805f46fba93a558bbfe2d8489205c5f46a5953f4069646739

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type mainRedactions_setShowRedactionChangeBoardingOnActionMenuMutation$variables = {
  cloudId: string;
  show: boolean;
};
export type mainRedactions_setShowRedactionChangeBoardingOnActionMenuMutation$data = {
  readonly jira: {
    readonly userPreferences: {
      readonly setShowRedactionChangeBoardingOnActionMenu: boolean | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type mainRedactions_setShowRedactionChangeBoardingOnActionMenuMutation = {
  response: mainRedactions_setShowRedactionChangeBoardingOnActionMenuMutation$data;
  variables: mainRedactions_setShowRedactionChangeBoardingOnActionMenuMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "show"
  }
],
v1 = [
  {
    "concreteType": "JiraMutation",
    "kind": "LinkedField",
    "name": "jira",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "cloudId",
            "variableName": "cloudId"
          }
        ],
        "concreteType": "JiraUserPreferencesMutation",
        "kind": "LinkedField",
        "name": "userPreferences",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "show",
                "variableName": "show"
              }
            ],
            "kind": "ScalarField",
            "name": "setShowRedactionChangeBoardingOnActionMenu"
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "mainRedactions_setShowRedactionChangeBoardingOnActionMenuMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mainRedactions_setShowRedactionChangeBoardingOnActionMenuMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "df33cd93bb941e2805f46fba93a558bbfe2d8489205c5f46a5953f4069646739",
    "metadata": {},
    "name": "mainRedactions_setShowRedactionChangeBoardingOnActionMenuMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "d6e39ecc03b6c2a0967f6832f87475fa";

export default node;
