import React from 'react';
import { IconTile } from '@atlaskit/icon';
import Page16Icon from '@atlaskit/icon-object/glyph/page/16';
import PageIcon from '@atlaskit/icon/core/page';
import PageLiveDoc16Icon from '@atlaskit/icon-object/glyph/page-live-doc/16';
import PageLiveDocIcon from '@atlaskit/icon-lab/core/page-live-doc';
import { expVal } from '@atlassian/jira-feature-experiments';
import type { FormatMessage } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from '../create-page-menu-item/messages.tsx';

export const contentMenuItemIcon = (
	isLive: boolean,
	isVisualRefreshEnabled: boolean,
	formatMessage: FormatMessage,
) => {
	if (!isLive) {
		return isVisualRefreshEnabled ? (
			<IconTile
				icon={PageIcon}
				label={formatMessage(messages.confluencePageIcon)}
				appearance="blueBold"
				shape="square"
				size="16"
			/>
		) : (
			<Page16Icon label={formatMessage(messages.confluencePageIcon)} />
		);
	}
	return isVisualRefreshEnabled ? (
		<IconTile
			icon={PageLiveDocIcon}
			label={formatMessage(messages.confluenceLiveDocIcon)}
			appearance="magentaBold"
			shape="square"
			size="16"
		/>
	) : (
		<PageLiveDoc16Icon label={formatMessage(messages.confluenceLiveDocIcon)} />
	);
};

export const quickAddMenuItemIcon = (
	isLive: boolean,
	isQuickActionsGA: boolean | undefined,
	isVisualRefreshEnabled: boolean,
	formatMessage: FormatMessage,
) => {
	if (isQuickActionsGA) {
		if (expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false)) {
			return isLive ? (
				<PageLiveDocIcon label={formatMessage(messages.confluenceLiveDocIcon)} />
			) : (
				<PageIcon label={formatMessage(messages.confluencePageIcon)} />
			);
		}
		if (isVisualRefreshEnabled) {
			return isLive ? (
				<IconTile
					icon={PageLiveDocIcon}
					label={formatMessage(messages.confluenceLiveDocIcon)}
					appearance="magentaBold"
					shape="square"
					size="16"
				/>
			) : (
				<IconTile
					icon={PageIcon}
					label={formatMessage(messages.confluencePageIcon)}
					appearance="blueBold"
					shape="square"
					size="16"
				/>
			);
		}
	}
	return isLive ? (
		<PageLiveDoc16Icon label={formatMessage(messages.confluenceLiveDocIcon)} />
	) : (
		<Page16Icon label={formatMessage(messages.confluencePageIcon)} />
	);
};
