/**
 * @generated SignedSource<<500a7821e643e7ec1b179cee6bf99623>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutCascadingSelectField_IssueViewCascadingSelectField$data = {
  readonly fieldId: string;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditView_fragmentRef" | "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading">;
  readonly " $fragmentType": "ui_issueViewLayoutCascadingSelectField_IssueViewCascadingSelectField";
};
export type ui_issueViewLayoutCascadingSelectField_IssueViewCascadingSelectField$key = {
  readonly " $data"?: ui_issueViewLayoutCascadingSelectField_IssueViewCascadingSelectField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutCascadingSelectField_IssueViewCascadingSelectField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutCascadingSelectField_IssueViewCascadingSelectField",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading"
    },
    {
      "kind": "FragmentSpread",
      "name": "cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditView_fragmentRef"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "id"
    }
  ],
  "type": "JiraCascadingSelectField"
};

(node as any).hash = "ab1df2fe4db308415daae07dfb4e7a51";

export default node;
