/* eslint-disable @atlassian/relay/unused-fields */
/* eslint-disable @atlassian/relay/query-restriction */
import React, { useCallback, useMemo } from 'react';
import type { Dispatch } from 'redux';
import flow from 'lodash/flow';
import { graphql, useFragment } from 'react-relay';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import { fireUiAnalytics } from '@atlassian/jira-analytics-web-react/src/utils/fire-ui-event.tsx';
import { log } from '@atlassian/jira-common-util-logging/src/index.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { User } from '@atlassian/jira-issue-shared-types/src/common/types/user-type.tsx';
import { ADD_WATCHER_ERROR } from '@atlassian/jira-issue-view-common-constants/src/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { transformEdgesToTransformedNodes } from '@atlassian/jira-relay-utils/src/utils/transform-edges-to-transformed-nodes/index.tsx';
import type {
	addWatchers_issueViewWatchers_AddWatchersRelay$data,
	addWatchers_issueViewWatchers_AddWatchersRelay$key,
} from '@atlassian/jira-relay/src/__generated__/addWatchers_issueViewWatchers_AddWatchersRelay.graphql';
import type { AccountId, BaseUrl, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useOnShowFlag } from '../../../../controllers/watchers-context/index.tsx';
import { useWatchesMutation } from '../../../../services/use-watches-mutation/index.tsx';
import type { State } from '../../../model/types.tsx';
import { addWatcherRequest, type AddWatcherRequestAction } from '../../../state/actions.tsx';
import {
	getBaseUrl,
	getCanManageWatchers,
	getIssueKey,
	getWatchersListUsers,
} from '../../../state/selectors.tsx';
import { AddWatchers } from './view.tsx';

type StateProps = {
	issueKey: IssueKey;
	canManageWatchers: boolean;
	baseUrl: BaseUrl;
	watchers: User[];
};

type DispatchProps = {
	onAddWatcher: (
		arg1: User,
		arg2: (accountId: AccountId | null) => void,
		arg3: (error?: Error | FetchError) => void,
		arg4: UIAnalyticsEvent,
	) => void;
};

export const AddWatchersOld = flow(
	ComponentWithAnalytics('button', {
		onShowAddWatchersAnalytics: 'clicked',
	}),
	withFireUiAnalytics({
		onShowAddWatchersAnalytics: 'showAddWatchers',
	}),
	connect(
		(state: State): StateProps => ({
			canManageWatchers: getCanManageWatchers(state),
			baseUrl: getBaseUrl(state),
			issueKey: getIssueKey(state),
			watchers: getWatchersListUsers(state),
		}),
		(dispatch: Dispatch<AddWatcherRequestAction>): DispatchProps => ({
			onAddWatcher: (user, onSuccess, onError, analyticsEvent) => {
				dispatch(addWatcherRequest({ user, onSuccess, onError }));
				fireUiAnalytics(analyticsEvent, { name: 'addWatcher' });
			},
		}),
	),
)(AddWatchers);

const transformNodeToUser = (
	node: NonNullable<
		NonNullable<
			NonNullable<
				NonNullable<
					addWatchers_issueViewWatchers_AddWatchersRelay$data['selectedUsersConnection']
				>['edges']
			>[number]
		>['node']
	>,
): User => ({
	id: node.accountId,
	displayName: node.name,
	avatarUrl: node.picture,
	ari: node.id,
});

const AddWatchersRelay = ({
	watches,
	...rest
}: {
	watches: addWatchers_issueViewWatchers_AddWatchersRelay$key;
	onShowAddWatchersAnalytics: (arg1: UIAnalyticsEvent) => void;
} & Omit<StateProps, 'watchers'>) => {
	const data = useFragment<addWatchers_issueViewWatchers_AddWatchersRelay$key>(
		graphql`
			fragment addWatchers_issueViewWatchers_AddWatchersRelay on JiraWatchesField {
				id
				watch {
					count
					isWatching
					...useWatchesMutation_Mutation_Updatable
				}
				type
				fieldId
				selectedUsersConnection {
					edges {
						node {
							... on User {
								id
								accountId
								name
								picture
							}
						}
					}
				}
			}
		`,
		watches,
	);

	const watchers = useMemo(
		() => transformEdgesToTransformedNodes(data.selectedUsersConnection, transformNodeToUser),
		[data.selectedUsersConnection],
	);

	const { onUpdate } = useWatchesMutation();

	const { onShowFlag } = useOnShowFlag();
	const onAddWatcher = useCallback(
		async (
			user: User,
			onSuccess: (accountId: AccountId | null) => void,
			onError: (error?: Error | FetchError) => void,
			analyticsEvent: UIAnalyticsEvent,
		) => {
			const handleError = (error: Error) => {
				onShowFlag?.(ADD_WATCHER_ERROR);
				log.safeErrorWithoutCustomerData('issue.watchers.add.watcher', error?.message, error);
				onError?.(error);
			};
			if (!user?.ari) {
				handleError(new Error('Could not find user to delete'));
				return;
			}
			onUpdate({
				id: data.id,
				userAri: user?.ari,
				isWatching: true,
				accountId: fg('one-event-rule-all-watcher-analytics') ? user?.id : null,
				onError: handleError,
				onSuccess,
				watch: data.watch,
				field: {
					type: data.type,
					fieldId: data.fieldId,
					watch: {
						count: data?.watch?.count,
						isWatching: data?.watch?.isWatching,
					},
				},
			});
			fireUiAnalytics(analyticsEvent, { name: 'addWatcher' });
		},
		[data, onShowFlag, onUpdate],
	);

	return <AddWatchers {...rest} watchers={watchers} onAddWatcher={onAddWatcher} />;
};

export const AddWatchersNew = flow(
	ComponentWithAnalytics('button', {
		onShowAddWatchersAnalytics: 'clicked',
	}),
	withFireUiAnalytics({
		onShowAddWatchersAnalytics: 'showAddWatchers',
	}),
	connect((state: State) => ({
		canManageWatchers: getCanManageWatchers(state),
		issueKey: getIssueKey(state),
	})),
)(AddWatchersRelay);

export default componentWithCondition(
	() => fg('relay-migration-issue-header-and-parent'),
	AddWatchersNew,
	AddWatchersOld,
);
