// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { State } from '../../types.tsx';

export const setError =
	(error?: Error): Action<State> =>
	({ getState, setState }) => {
		setState({
			meta: {
				...getState().meta,
				error,
			},
		});
	};
