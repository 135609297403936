/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Swedish
export default {
  'fabric.elements.share.copied.to.clipboard.message': 'Länk kopierad till urklipp',
  'fabric.elements.share.copylink.button.text': 'Kopiera länk',
  'fabric.elements.share.copypubliclink.button.text': 'Kopiera offentlig länk',
  'fabric.elements.share.failure.icon.label': 'Fel-ikon',
  'fabric.elements.share.failure.message': 'Det gick inte att dela',
  'fabric.elements.share.form.comment.label': 'Meddelande (valfritt)',
  'fabric.elements.share.form.comment.placeholder': 'Finns det något de behöver veta?',
  'fabric.elements.share.form.info.message.no.invite.confluence': 'Mottagare ser namnet på sidan och ditt meddelande',
  'fabric.elements.share.form.info.message.no.invite.jira': 'Mottagare ser namnet på ärendet och ditt meddelande',
  'fabric.elements.share.form.info.message.no.invite.jira.issue-term-refresh':
    'Mottagare ser namnet på arbetsuppgiften och ditt meddelande',
  'fabric.elements.share.form.no-permissions': 'Du har inte möjlighet att dela.',
  'fabric.elements.share.form.public.send': 'Skicka offentlig länk',
  'fabric.elements.share.form.public.share': 'Dela offentlig länk',
  'fabric.elements.share.form.required-field.summary': 'Obligatoriska fält är markerade med en asterisk',
  'fabric.elements.share.form.retry': 'Försök igen',
  'fabric.elements.share.form.send': 'Skicka',
  'fabric.elements.share.form.share': 'Dela',
  'fabric.elements.share.form.title': 'Dela',
  'fabric.elements.share.form.user-picker.add-more': 'Ange fler',
  'fabric.elements.share.form.user-picker.label.confluence': 'Namn, team, grupper eller e-postadresser',
  'fabric.elements.share.form.user-picker.label.email-disabled-confluence': 'Namn, team eller grupper',
  'fabric.elements.share.form.user-picker.label.email-disabled-jira': 'Namn eller team',
  'fabric.elements.share.form.user-picker.label.email-only': 'E-postadresser',
  'fabric.elements.share.form.user-picker.label.jira': 'Namn, team eller e-postadresser',
  'fabric.elements.share.form.user-picker.no-options.existingUserOnly':
    'Vi hittade inte några resultat för "{inputValue}".',
  'fabric.elements.share.form.user-picker.no-options.generic':
    'Vi hittade inte några resultat för "{inputValue}". Bjud in personer genom att använda e-postadresser.',
  'fabric.elements.share.form.user-picker.placeholder.confluence': 't.ex. Maria, Team Orange, grupp-ett',
  'fabric.elements.share.form.user-picker.placeholder.email-disabled-jira': 't.ex. Maria, Team Orange',
  'fabric.elements.share.form.user-picker.placeholder.email-only': 't.ex. maria@företag.se',
  'fabric.elements.share.form.user-picker.placeholder.jira': 't.ex. Maria, Team Orange, maria@företag.se',
  'fabric.elements.share.form.user-picker.validation.required':
    'Välj minst en användare, en grupp, ett team eller en e-postadress.',
  'fabric.elements.share.form.user-picker.validation.required-message.confluence':
    'Välj minst en person, ett team, en grupp eller en e-postadress',
  'fabric.elements.share.form.user-picker.validation.required-message.email-disabled-confluence':
    'Välj minst en person, ett team eller en grupp',
  'fabric.elements.share.form.user-picker.validation.required-message.email-disabled-jira':
    'Välj minst en person eller ett team',
  'fabric.elements.share.form.user-picker.validation.required-message.email-only': 'Välj minst en e-postadress',
  'fabric.elements.share.form.user-picker.validation.required-message.jira':
    'Välj minst en person, ett team eller en e-postadress',
  'fabric.elements.share.in.integration.button': 'Dela i {integrationName}',
  'fabric.elements.share.invite.to.confluence': 'Bjud in till Confluence',
  'fabric.elements.share.invite.to.jira': 'Bjud in till Jira',
  'fabric.elements.share.main.tab.text.confluence': 'Dela sida',
  'fabric.elements.share.main.tab.text.jira': 'Dela ärende',
  'fabric.elements.share.menu.email.label': 'E-post',
  'fabric.elements.share.menu.slack.label': 'Slack',
  'fabric.elements.share.popup.label': 'Dela',
  'fabric.elements.share.success.message':
    '{object, select,blogpost {Blogginlägg har delats}board {Tavla har delats}calendar {Kalender har delats}draft {Utkast har delats}filter {Filter har delats}issue {Ärende har delats}summary {Sammanfattning har delats}list {Lista har delats}timeline {Tidslinje har delats}form {Formulär har delats}media {Media har delats}page {Sida har delats}project {Projekt har delats}pullrequest {Pull request har delats}question {Fråga har delats}report {Rapport har delats}repository {Lagringsplats har delats}request {Förfrågan har delats}roadmap {Tidslinje har delats}site {Webbplats har delats}space {Utrymme har delats}other {Länk har delats}}',
  'fabric.elements.share.to.integration.button': 'Dela till {integrationName}',
  'fabric.elements.share.trigger.button.icon.label': 'Dela-ikon',
  'fabric.elements.share.trigger.button.invite.text': 'Bjud In',
  'fabric.elements.share.trigger.button.text': 'Dela',
  'fabric.elements.share.trigger.button.tooltip.text': 'Dela',
};
