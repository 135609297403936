import omit from 'lodash/omit';
import reduce from 'lodash/reduce';
import {
	ISSUEID_FIELDKEY,
	ISSUETYPE_FIELDKEY,
	KEY_FIELDKEY,
	SUMMARY_FIELDKEY,
} from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { RemoteIssue } from '@atlassian/jira-polaris-remote-issue/src/controllers/crud/types.tsx';
import { fireOperationalAnalyticsDeferred } from '@atlassian/jira-product-analytics-bridge';
import type { IssueId, IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import { type FieldMappings, getFieldMappings } from '../../selectors/fields.tsx';
import { getFilteredIssueIds } from '../../selectors/filters.tsx';
import {
	type State,
	IssueCreateStatusInCreation,
	IssueCreateStatusInTransition,
	type Props,
	type IssueCreatedPropertyItemGroupType,
	IssueCreateGroupTypeSpecified,
	type IssueCreatedProperty,
	type IssueCreatedPropertyItem,
} from '../../types.tsx';
import { incrementOpenUpdateCounter } from '../real-time/index.tsx';
import { updateFieldValues } from '../update-field-value/index.tsx';
import type { FieldValuesUpdateRequest } from '../update-field-value/types.tsx';

const getNewSortedIds = (
	ids: LocalIssueId[],
	newId: LocalIssueId,
	before?: LocalIssueId,
	after?: LocalIssueId,
): LocalIssueId[] => {
	const newIds: LocalIssueId[] = [...ids];
	if (before === undefined && after === undefined) {
		newIds.push(newId);
	}

	if (before !== undefined) {
		const index = newIds.indexOf(before);
		newIds.splice(index, 0, newId);
	}

	if (before === undefined && after !== undefined) {
		const index = newIds.indexOf(after);
		newIds.splice(index + 1, 0, newId);
	}

	return newIds;
};

export const clearCreatedIssueState = (state: State, id: LocalIssueId): State => {
	const { properties } = state;
	const { created } = properties;
	const newCreated = omit(created, id);

	return {
		...state,
		properties: {
			...properties,
			created: newCreated,
		},
	};
};

type CreateIssueInternalOptions = {
	state: Readonly<State>;
	newId: LocalIssueId;
	rankBefore?: LocalIssueId;
	rankAfter?: LocalIssueId;
	grouping: IssueCreatedPropertyItemGroupType;
};

export const createIssueInternal = ({
	state,
	newId,
	rankBefore,
	rankAfter,
	grouping,
}: CreateIssueInternalOptions): State => {
	const { properties } = state;
	const { created } = properties;

	const rankedIds = state.ids;

	const newIds = getNewSortedIds(rankedIds, newId, rankBefore, rankAfter);

	const newProperties = {
		...properties,
		created: {
			...created,
			[newId]: {
				...grouping,
				status: IssueCreateStatusInCreation,
				anchorBefore: rankAfter,
				anchorAfter: rankBefore,
			},
		},
	};

	return {
		...state,
		ids: newIds,
		properties: newProperties,
	};
};

export const createStateObjForFetchedIssue = (
	state: State,
	id: LocalIssueId,
	fieldMappings: FieldMappings<unknown>,
	fetchResponse: RemoteIssue,
) => {
	const { properties } = state;
	const newProperties = reduce(
		fieldMappings,
		(acc, mapping) => {
			const fieldValue = mapping.getValueFromJiraIssue(fetchResponse);
			return mapping.setImmutable(acc, id, fieldValue !== null ? fieldValue : undefined);
		},
		properties,
	);
	return {
		...state,
		properties: {
			...newProperties,
		},
	};
};

export const updateLocalIssueIdToJiraId = (
	localIssueId: string,
	jiraIssueId: number,
	getState: () => Readonly<State>,
	setState: (arg1: Partial<State>) => void,
) => {
	const { properties } = getState();
	const { number } = properties;
	const issueIds = number[ISSUEID_FIELDKEY];
	setState({
		properties: {
			...properties,
			number: {
				...number,
				[ISSUEID_FIELDKEY]: {
					...issueIds,
					[localIssueId]: jiraIssueId,
				},
			},
		},
	});
};

export const saveIssueInternal = (
	getState: () => Readonly<State>,
	setState: (arg1: Partial<State>) => void,
	dispatch: StoreActionApi<State>['dispatch'],
	props: Props,
	id: LocalIssueId,
	issueTypeId: IssueTypeId,
	summary: string,
	clonedCreatedProperty: IssueCreatedPropertyItem,
	clonedIds: LocalIssueId[],
	onCreatedIssueFiltered: (arg1: LocalIssueId) => void,
	onIssueSaved?: (options: {
		issueId: IssueId;
		localIssueId: LocalIssueId;
		createdProperty: IssueCreatedProperty[string];
	}) => void,
	onIssueSaveError?: (arg1: Error) => void,
) => {
	const { projectId, rankField, isRankingEnabled, createAnalyticsEvent, issuesRemote } = props;

	if (projectId === undefined || issueTypeId === undefined) {
		props.onIssueCreationFailed(new Error('project / issueType undefined'), issueTypeId);
		return;
	}
	const state = getState();
	const { ids, properties } = state;
	const { created, string } = properties;

	let createdIssueData = created[id];

	if (!createdIssueData) {
		// +----------------------+
		// |                      |
		// |     createIssue      |
		// |                      |
		// +----------------------+
		// 	 +----------------------+
		// 	 |                      |
		// 	 |     cancelIssues     |
		// 	 |                      |
		// 	 +----------------------+
		// 							 +---------------------------------+
		// 							 |                                 |
		// 							 |   Type in CreateIdeaRow input   |                                          |
		// 							 |                                 |                                          |
		// 							 +---------------------------------+                                          |
		// 																+-----------------------------------------|------------------------+
		// 																| +--------------------------+            |                        |
		// 																| |   FORM SUBMISSION        |            |                        |
		// 																| |   HIT ENTER / ON BLUR    |            |                        |
		// 																| +--------------------------+            |                        |
		// 																|   +-------------------------------------|---------+              |                    RACE CONDITION
		// 																|   |                                     |         |              |
		// 																|   |     saveIssue                       |-------------------------------------------  We expect to have
		// 																|   |                                     |         |              |                    `created[id]` in the state
		// 																|   +-------------------------------------|---------+              |                    but it was removed by
		// 																+-----------------------------------------|------------------------+                    `createIssue` call
		// 																										  |
		// 																+-----------------------------------------|------------------------+
		// 																| +--------------------------+            |                        |
		// 																| |   CLICK CREATE AN INPUT  |            |                        |
		// 																| +--------------------------+            |                        |
		// 																|                                         |                        |
		// 																|    +----------------------+             |                        |
		// 																|    |                      |             |                        |
		// 																|    |     createIssue      |             |                        |
		// 																|    |                      |             |                        |
		// 																|    +----------------------+             |                        |
		// 																|         +----------------------+        |                        |
		// 																|         |                      |        |                        |
		// 																|         |     cancelIssues     |        |                        |
		// 																|         |                      |        |                        |
		// 																|         +----------------------+        |                        |
		// 																+-----------------------------------------|------------------------+
		// 																										  |
		// 																										  |
		// 																										  |
		//
		// There is possibility that race condition may happen
		// and at the moment when `getState()` is called
		// the `created` property was cleared out by `createIssue` action.
		// To fix that particular case, we pass clones of `clonedCreatedProperty` and `clonedIds`
		// to ensure that `saveAction()` can correctly finish its work.
		createdIssueData = clonedCreatedProperty;
	}

	let shouldUpdateIds = false;
	let updatedIds: LocalIssueId[] = [];
	let index = ids.indexOf(id);
	let anchorAfterId = ids[index + 1];

	if (index === -1) {
		shouldUpdateIds = true;
		index = clonedIds.indexOf(id);
		anchorAfterId = clonedIds[index + 1];
		updatedIds = getNewSortedIds(ids, id, anchorAfterId);
	}

	const anchorAfterIssueKey = properties.string[KEY_FIELDKEY]?.[anchorAfterId];

	setState({
		properties: {
			...properties,
			// optimistic update of summary
			string: {
				...string,
				[SUMMARY_FIELDKEY]: {
					...string[SUMMARY_FIELDKEY],
					[id]: summary,
				},
			},
			// change status of created issue to 'in transition'
			created: {
				...created,
				[id]: {
					...createdIssueData,
					status: IssueCreateStatusInTransition,
				},
			},
		},
		// Ensure that `ids` contains `created[id]`
		ids: shouldUpdateIds ? updatedIds : ids,
	});

	const fieldsToUpdate: Record<string, unknown> = {};

	if (createdIssueData.groupType === IssueCreateGroupTypeSpecified) {
		const { fieldKey, fieldValue } = createdIssueData;

		if (
			// In case view is grouped by issue type, issue is created with specific issue type defined by user selection
			// in the input. In this case we don't want to update issue type of issue from grouping after creation.
			fg('jpd_issue_types_ga') ? fieldValue && fieldKey !== ISSUETYPE_FIELDKEY : fieldValue
		) {
			fieldsToUpdate[fieldKey] = fieldValue;
		}
	}

	const fetchStart = performance.now();

	dispatch(incrementOpenUpdateCounter([id]));
	issuesRemote
		.createIssue({
			rankField,
			issueTypeId: Number(issueTypeId),
			fieldsMap: { summary },
			isRankingEnabled,
			onCreated: (jiraIssueId) =>
				updateLocalIssueIdToJiraId(id, Number(jiraIssueId), getState, setState),
			anchorAfterIssueKey,
		})
		.then((response) => {
			const fieldMappings = getFieldMappings(getState(), props);

			// update issue data
			const stateAfterFetch = createStateObjForFetchedIssue(
				getState(),
				id,
				fieldMappings,
				response,
			);

			fireOperationalAnalyticsDeferred(createAnalyticsEvent({}), 'createIdea success', {
				durationMs: Math.round(performance.now() - fetchStart),
			});

			// Clone `created[id]`
			// before removing it from the state after calling `clearCreatedIssueState`
			const clonedCreatedPropertyAfterFetch = { ...state.properties.created[id] };

			const stateWithClearCreatedIssue = clearCreatedIssueState(stateAfterFetch, id);
			setState({ ...stateWithClearCreatedIssue });

			if (!fg('jpd_fix_filtered_issue_modal')) {
				const filteredIssueIds = getFilteredIssueIds(stateWithClearCreatedIssue, props);
				if (!filteredIssueIds.includes(id)) {
					onCreatedIssueFiltered(id);
				}
			}

			setState({ lastUpdatedIssueIds: [id] });
			onIssueSaved?.({
				issueId: response.id,
				localIssueId: id,
				createdProperty: clonedCreatedPropertyAfterFetch,
			});

			if (Object.keys(fieldsToUpdate).length > 0) {
				dispatch(
					updateFieldValues({
						fields: Object.entries(fieldsToUpdate).reduce<FieldValuesUpdateRequest['fields']>(
							(acc, [keyToUpdate, valueToUpdate]) => {
								acc[keyToUpdate] = {
									newValue: valueToUpdate,
									appendMultiValues: true,
								};

								return acc;
							},
							{},
						),
						localIssueIds: [id],
					}),
				);
			}

			if (fg('jpd_fix_filtered_issue_modal')) {
				const filteredIssueIds = getFilteredIssueIds(getState(), props);
				if (!filteredIssueIds.includes(id)) {
					onCreatedIssueFiltered(id);
				}
			}
		})
		.catch((error) => {
			fireOperationalAnalyticsDeferred(createAnalyticsEvent({}), 'createIdea failure', {
				durationMs: Math.round(performance.now() - fetchStart),
			});
			props.onIssueCreationFailed(error, issueTypeId);

			onIssueSaveError && onIssueSaveError(error);
		});
};
