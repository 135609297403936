import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Container = styled.div<{ top?: number | null; isModal?: boolean }>({
	width: 'inherit',
	height: 'inherit',
	opacity: 1,

	backgroundColor: token('elevation.surface'),
	display: 'flex',
	flexDirection: 'column',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	position: ({ isModal }) => (isModal !== undefined && isModal !== false ? 'initial' : 'fixed'),
	overflow: 'hidden' /* needed not to show panel outside of e.g. Modal on opening animation */,

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	top: ({ top }) => (top != null ? `${top}px;` : 'auto'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HeaderBreadcrumb = styled.div({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	flex: 1,
	height: '30px',
	marginTop: token('space.100'),
	marginRight: token('space.100'),
	marginBottom: 0,
	marginLeft: token('space.100'),
});
