import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../types.tsx';
import { updateViewState } from './utils/state/index.tsx';
import { currentViewFilter } from './utils/views/index.tsx';

export const setLastSelectedIssueTypeId =
	(issueTypeId: IssueTypeId): Action<State, Props> =>
	async ({ getState, setState }, { currentViewSlug }) => {
		const state = getState();

		const { changedView, viewSets } = updateViewState(
			state.viewSets,
			currentViewFilter(currentViewSlug),
			(view: View): View => ({
				...view,
				lastSelectedIssueTypeId: issueTypeId,
			}),
		);

		if (changedView) {
			setState({ viewSets });
		}
	};
