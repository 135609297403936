import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';
import { token } from '@atlaskit/tokens';

export const setUpXAxisGlyph = (props: CustomGlyphProps) => (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
		<path
			d="M3.392 1.607a1 1 0 0 1 2 0v10a1 1 0 1 1-2 0v-10Z"
			fill={token('color.icon.accent.gray')}
		/>
		<path
			d="M3.685.858a1 1 0 1 1 1.414 1.414L2.271 5.1A1 1 0 1 1 .856 3.686L3.685.858Z"
			fill={token('color.icon.accent.gray')}
		/>
		<path
			d="M3.685 2.272A1 1 0 1 1 5.099.858l2.829 2.828A1 1 0 0 1 6.513 5.1L3.685 2.272Z"
			fill={token('color.icon.accent.gray')}
		/>
		<path d="M14.393 10.607a1 1 0 1 1 0 2h-10a1 1 0 1 1 0-2h10Z" fill="currentColor" />
		<path
			d="M15.142 10.9a1 1 0 1 1-1.415 1.414L10.9 9.486a1 1 0 0 1 1.414-1.415l2.829 2.829Z"
			fill="currentColor"
		/>
		<path
			d="M13.727 10.9a1 1 0 1 1 1.415 1.414l-2.829 2.829a1 1 0 0 1-1.414-1.415l2.829-2.828Z"
			fill="currentColor"
		/>
	</svg>
);

export const setUpYAxisGlyph = (props: CustomGlyphProps) => (
	<svg {...props} width="16" height="16" xmlns="http://www.w3.org/2000/svg" fill="none">
		<path
			d="M14.393 10.607a1 1 0 1 1 0 2h-10a1 1 0 1 1 0-2h10Z"
			fill={token('color.icon.accent.gray')}
		/>
		<path
			d="M15.142 10.9a1 1 0 0 1-1.414 1.414l-2.829-2.829a1 1 0 1 1 1.415-1.414l2.828 2.828Z"
			fill={token('color.icon.accent.gray')}
		/>
		<path
			d="M13.728 10.9a1 1 0 1 1 1.414 1.414l-2.828 2.828a1 1 0 0 1-1.415-1.414l2.829-2.829Z"
			fill={token('color.icon.accent.gray')}
		/>
		<path d="M3.392 1.607a1 1 0 1 1 2 0v10a1 1 0 1 1-2 0v-10Z" fill="currentColor" />
		<path
			d="M3.685.858a1 1 0 1 1 1.414 1.414L2.271 5.1A1 1 0 1 1 .856 3.686L3.685.858Z"
			fill="currentColor"
		/>
		<path
			d="M3.685 2.272A1 1 0 1 1 5.099.858l2.829 2.828A1 1 0 0 1 6.513 5.1L3.685 2.272Z"
			fill="currentColor"
		/>
	</svg>
);
