import React from 'react';
import { AsyncDevPanel } from '@atlassian/jira-development-dev-info-panel/src/async.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	idSelector,
	issueAriSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import { canViewDevToolsPermissionsSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/permissions-selector.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import type { Props, StateProps } from './types.tsx';

export const AllDevInfoPanel = ({ canViewDevTools, issueId, issueAri }: Props) =>
	issueId !== null &&
	issueAri !== null &&
	issueId !== undefined &&
	issueAri !== undefined &&
	// async import exists in a different file because if it points to the same file path, webpack will optimise it away
	canViewDevTools ? (
		<UFOSegment name="AllDevInfoPanel">
			<AsyncDevPanel issueId={issueId} issueAri={issueAri} />
		</UFOSegment>
	) : (
		<ErrorBoundary />
	);

AllDevInfoPanel.displayName = 'AllDevInfoPanel';

export default connect(
	(state): StateProps => ({
		canViewDevTools: canViewDevToolsPermissionsSelector(state),
		issueId: idSelector(state),
		issueAri: issueAriSelector(state),
	}),
	{},
)(AllDevInfoPanel);
