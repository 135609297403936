import memoize from 'lodash/memoize';
import { cacheSelectorCreator } from '@atlassian/jira-polaris-lib-selector-creator-cache/src/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createHook,
	type Store,
	type StoreActionApi,
	type ActionThunk,
	type BoundActions,
} from '@atlassian/react-sweet-state';
import { createContainerWithContext } from './main.tsx';
import type {
	JpdSweetStateContainerContext,
	Selector,
	SelectorCreator,
	TPropsAwareState,
} from './types.tsx';

const createSelectorHook = <
	TContainerProps,
	TState extends TPropsAwareState<TContainerProps>,
	TActions extends Record<string, ActionThunk<TState, TActions>>,
	TValue,
>(
	store: Store<TState, TActions>,
	selector: Selector<TState, TContainerProps, TValue>,
): (() => TValue) => {
	const innerHook = createHook(store, {
		selector: (state) => selector(state, state.containerProps),
	});

	return () => {
		const [val] = innerHook();
		return val;
	};
};

export const createHigherLevelHook = <
	TContainerProps,
	TState extends TPropsAwareState<TContainerProps>,
	TActions extends Record<string, ActionThunk<TState, TActions>>,
	TValue,
	SelectorCreatorArgs extends ReadonlyArray<unknown>,
>(
	store: Store<TState, TActions>,
	selectorCreator: SelectorCreator<TState, TContainerProps, TValue, SelectorCreatorArgs>,
): ((...rest: SelectorCreatorArgs) => TValue) => {
	// TODO POL-2195 memoize one is not suitable for this task, as it will only memoize the last invocation. while this
	//               improves subsequent calls with the same argument set, it does not improve calls with different args
	//               this case should be solved with a generic memoization lib, e.g. reselect (4.1+) or moize
	//               discussion: https://hello.atlassian.net/wiki/spaces/TGR/pages/1416371989/Dependency+review+moize
	const memoizedSelectorCreator = cacheSelectorCreator(selectorCreator);

	// TODO POL-2195 in the meantime, we can use lodash/memoize to at least solve the 1-ary case,
	//               which should be 99% of our selector creators anyway
	const monadicSelectorCreatorMemoized = memoize(selectorCreator);

	const rssHook = createHook(store, {
		selector: (state: TState, args: SelectorCreatorArgs) => {
			if (args.length <= 1) {
				return monadicSelectorCreatorMemoized(...args)(state, state.containerProps);
			}
			return memoizedSelectorCreator(...args)(state, state.containerProps);
		},
	});

	return (...args: SelectorCreatorArgs) => {
		// @ts-expect-error - TS2345 - Argument of type 'SelectorCreatorArgs' is not assignable to parameter
		const [val] = rssHook(args);
		return val;
	};
};

/**
 * @deprecated - Use of createJpdContainer is discouraged, use other alternatives to manage state. https://hello.atlassian.net/wiki/x/zIKODQE
 */
export const createJpdContainer = <
	TContainerProps,
	TState extends TPropsAwareState<TContainerProps>,
	TActions extends Record<string, ActionThunk<TState, TActions>>,
>(
	store: Store<TState, TActions>,
	options?: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onInit?: () => (api: StoreActionApi<TState>, containerProps: TContainerProps) => any;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onUpdate?: () => (api: StoreActionApi<TState>, containerProps: TContainerProps) => any;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onCleanup?: () => (api: StoreActionApi<TState>, containerProps: TContainerProps) => any;
		displayName?: string;
	},
): JpdSweetStateContainerContext<TState, TActions, TContainerProps> => {
	const Container = createContainerWithContext(store, options);

	const useEmptyState = createHook(store, {
		selector: null,
	});

	const useActions = (): BoundActions<TState, TActions> => {
		const [, actions] = useEmptyState();
		return actions;
	};

	return {
		createHook: <TValue,>(selector: Selector<TState, TContainerProps, TValue>): (() => TValue) =>
			createSelectorHook(store, selector),
		createHigherLevelHook: <TValue, SelectorCreatorArgs extends ReadonlyArray<unknown>>(
			selectorCreator: SelectorCreator<TState, TContainerProps, TValue, SelectorCreatorArgs>,
		): ((...rest: SelectorCreatorArgs) => TValue) => createHigherLevelHook(store, selectorCreator),
		useActions,
		Container,
	};
};
