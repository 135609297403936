import React, { useCallback } from 'react';
import { useFragment, graphql } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { PriorityInlineEditView } from '@atlassian/jira-issue-field-priority-inline-edit-full/src/ui/priority/index.tsx';
import type { AggJiraPriority } from '@atlassian/jira-issue-field-priority-inline-edit-full/src/ui/priority/types.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import { IssueViewFieldHeading } from '@atlassian/jira-issue-view-layout-field-heading/src/ui/index.tsx';
import type { ui_issueViewLayoutPriorityField_IssueViewPriorityField$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutPriorityField_IssueViewPriorityField.graphql';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';

/**
 * Props for `<\{@link IssueViewPriorityField}/>`
 */
export interface IssueViewPriorityFieldProps {
	/** Defines which area of the Issue View the field will be displayed */
	area?: Area;
	/** This is a reference to the relay fragment of GraphQL type JiraPriorityField */
	fragmentKey: ui_issueViewLayoutPriorityField_IssueViewPriorityField$key;
}

export const IssueViewPriorityField = ({ area, fragmentKey }: IssueViewPriorityFieldProps) => {
	const issueId = useIssueId();
	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();

	const data = useFragment<ui_issueViewLayoutPriorityField_IssueViewPriorityField$key>(
		graphql`
			fragment ui_issueViewLayoutPriorityField_IssueViewPriorityField on JiraPriorityField {
				...ui_issueViewLayoutFieldHeading_IssueViewFieldHeading
				...priority_issueFieldPriorityInlineEditFull_PriorityInlineEditView_fragmentRef
				fieldId
				type
				__typename
			}
		`,
		fragmentKey,
	);

	const onSubmit = useCallback(
		(value: AggJiraPriority) => {
			issueId &&
				fieldChangeRequested(issueId, data.fieldId, value, undefined, {
					type: data.type,
					__typename: data.__typename,
				});
		},
		[data.__typename, data.fieldId, data.type, fieldChangeRequested, issueId],
	);

	const onSubmitSucceeded = useCallback(
		(value: AggJiraPriority) => {
			issueId &&
				fieldChanged(issueId, data.fieldId, value, {
					type: data.type,
					__typename: data.__typename,
				});
		},
		[data.__typename, data.fieldId, data.type, fieldChanged, issueId],
	);

	const onSubmitFailed = useCallback(
		() => issueId && fieldChangeFailed(issueId, data.fieldId),
		[data.fieldId, fieldChangeFailed, issueId],
	);

	return (
		<UFOSegment name="issue-field-priority">
			<IssueViewFieldHeading
				area={area}
				fragmentKey={data}
				testId={`issue.issue-view-layout.issue-view-priority-field.${data.fieldId}`}
			>
				<Box xcss={fieldWrapperStyles}>
					<PriorityInlineEditView
						fragmentRef={data}
						onSubmitNew={onSubmit}
						onSubmitSucceededNew={onSubmitSucceeded}
						onSubmitFailed={onSubmitFailed}
					/>
				</Box>
			</IssueViewFieldHeading>
		</UFOSegment>
	);
};

const fieldWrapperStyles = xcss({
	marginLeft: 'space.negative.100',
	marginRight: 'space.100',
});
