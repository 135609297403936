import { traverse } from '@atlaskit/adf-utils/traverse';
import type { DocNode as ADF, MentionDefinition as MentionNode } from '@atlaskit/adf-schema';
import type { ADFEntity } from '@atlaskit/adf-utils/types';

export const hasMentionNodes = (adfValue: ADF): boolean => {
	let hasMention = false;
	traverse(adfValue, {
		mention: (node: ADFEntity) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const mentionNode = node as MentionNode;

			if (mentionNode.type === 'mention') {
				hasMention = true;
			}
		},
	});

	return hasMention;
};
