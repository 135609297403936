import { PARENT_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createActionsHook, createStateHook } from '@atlassian/react-sweet-state';
import { actions } from './actions/index.tsx';
import type { State, FieldKey } from './types.tsx';

type Actions = typeof actions;

const initialState: State = {
	[PARENT_TYPE]: {},
};

const Store = createStore<State, Actions>({
	initialState,
	actions,
	name: 'jsw-issue-fields-suggestion-cache',
});

export const useFieldsSuggestionsActions = createActionsHook(Store);
export const useFieldsSuggestionsState = createStateHook(Store);

const getFieldPrefetched = (
	state: State,
	{ type, projectKey }: { type: FieldKey; projectKey: string },
) => state[type][projectKey]?.prefetched;

export const useFieldPrefetched = createStateHook(Store, {
	selector: getFieldPrefetched,
});

export const getFieldRecentlySelected = (
	state: State,
	{ type, projectKey }: { type: FieldKey; projectKey: string },
) => state[type][projectKey]?.recentlySelected;

export const useFieldRecentlySelected = createStateHook(Store, {
	selector: getFieldRecentlySelected,
});
