import React from 'react';
import type { Dispatch } from 'redux';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { type IntlShape, useIntl } from '@atlassian/jira-intl';
import IssueCommentItem, {
	type Props as IssueCommentProps,
} from '@atlassian/jira-issue-comment-base/src/index.tsx';
import type { CommentVisibility } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/comments.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import {
	SOFTWARE_PROJECT,
	CORE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import {
	editCommentBegin,
	editCommentCancel,
	editCommentCollapse,
	editCommentUpdate,
	editCommentVisibility,
	pastedIntoCommentForm,
	saveCommentCancel,
	saveCommentEdit,
	saveCommentRequest,
	saveCommentRetry,
	setCommentScrollStatus,
	updateCommentViewInteractionTimeMarker,
	replyCommentBegin,
	setCommentScrollStatusForRealTimeUpdates,
	type CommentSaveRequest,
} from '@atlassian/jira-issue-view-store/src/actions/comment-actions.tsx';
import { mentionProviderSelector } from '@atlassian/jira-issue-view-services/src/mentions/mention-provider-selector.tsx';
import {
	getTopMostUnreadCommentIdSelector,
	canDeleteCommentSelector,
	canEditCommentSelector,
	commentAuthorSelector,
	commentBodyHtmlSelector,
	commentCreatedDateSelector,
	commentEditedSelector,
	commentEditingOrViewBodyAdfSelector,
	commentEditingOrViewVisibilitySelector,
	commentHasDeleteModalSelector,
	commentIsOptimisticSelector,
	commentSaveFailedSelector,
	commentUpdateAuthorSelector,
	commentUpdatedDateSelector,
	commentVisibilitySelector,
	commentOutsiderCommentSelector,
	isCommentVisibilityRestrictionSupportedSelector,
	isEditingCommentSelector,
	isInternalCommentSelector,
	hasScrolledPermalinkCommentSelector,
	commentJsdIncidentActivityViewHiddenSelector,
	commentEventOccurredAtSelector,
	isEditingInternalCommentSelector,
	commentRepliesSelector,
	canAddCommentsSelector,
	isReplyingToCommentSelector,
	commentIsDeletedSelector,
	commentParentIdSelector,
	getPersistedLatestCommentTimeSelector,
	getCommentScrollStatusForRealTimeUpdates,
} from '@atlassian/jira-issue-view-store/src/selectors/comment-selector.tsx';

import { getActivityFeedInViewPort } from '@atlassian/jira-issue-view-store/src/selectors/activity-feed-selector.tsx';

import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';

import {
	editorExpandedFailure,
	editorSaveFailure,
} from '@atlassian/jira-issue-view-store/src/actions/editor-actions.tsx';
import { clearSharedAsCommentValues } from '@atlassian/jira-issue-view-store/src/actions/share-as-comment-actions.tsx';
import { activityProviderSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/activity-provider-selector.tsx';
import { memoizedContextIdentifierSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/comments-selector.tsx';
import { baseUrlSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import {
	issueAriSelector,
	projectTypeSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import { mediaContextSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/media-context-selector.tsx';
import {
	shouldShowReactionsSelector,
	commentAriSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/reactions-selector.tsx';
import { createCommentDraftKey } from '@atlassian/jira-issue-view-store/src/drafts/draft-utils.tsx';
import {
	NEW_COMMENT_ID,
	NEW_REPLY_COMMENT_ID_PREFIX,
} from '@atlassian/jira-issue-view-store/src/selectors/comment-constants.tsx';

import { fieldHasDraftSelector } from '@atlassian/jira-issue-view-store/src/selectors/drafts/selectors.tsx';
import { isIssueRemoteDataLoadingCompleteSelector } from '@atlassian/jira-issue-view-store/src/selectors/issue-remote-data-selector.tsx';
import {
	getTimestampForSharingAsComment,
	getValueForSharingAsComment,
} from '@atlassian/jira-issue-view-store/src/selectors/share-as-comment-selector.tsx';
import { hasFetchedConfluenceWhiteboardsSelector } from '@atlassian/jira-issue-view-store/src/selectors/confluence-whiteboards-selector.tsx';
import { useIssueLayoutActivitySidePanel } from '@atlassian/jira-issue-view-layout/src/services/main.tsx';
import { useRealTimeComments } from '@atlassian/jira-realtime-comments-controller/src/controllers/index.tsx';
import { canCreateChildrenPermissionsSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/permissions-selector.tsx';

type CommentWithMonitoringProps = Flow.Diff<
	IssueCommentProps,
	{
		intl: IntlShape;
	}
>;

const CommentWithMonitoringBase = (props: CommentWithMonitoringProps) => {
	const intl = useIntl();
	const { isActivityInVerticalSidePanel } = useIssueLayoutActivitySidePanel();
	return (
		<ErrorBoundary id="comment-item" packageName="issue" render={() => <></>}>
			<IssueCommentItem {...props} intl={intl} showSmallAvatar={isActivityInVerticalSidePanel} />
		</ErrorBoundary>
	);
};

const CommentWithRealtimeData = (props: CommentWithMonitoringProps) => {
	const [{ isRealTimeCommentUpdated }] = useRealTimeComments();
	return (
		<CommentWithMonitoringBase {...props} isRealTimeCommentUpdated={isRealTimeCommentUpdated} />
	);
};

export const CommentWithMonitoring = componentWithFG(
	'thor_send_realtime_attribute',
	CommentWithRealtimeData,
	CommentWithMonitoringBase,
);

const isNewComment = (commentId: string) =>
	commentId.includes(NEW_COMMENT_ID) ||
	(commentId.includes(NEW_REPLY_COMMENT_ID_PREFIX) && fg('threaded_comments_old_checks'));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getExtractionStateProps = (state: any, commentId: any): Partial<IssueCommentProps> => ({
	baseUrl: baseUrlSelector(state),
	isReactionsFeatureActive: shouldShowReactionsSelector(state),
	issueAri: issueAriSelector(state),
	commentAri: commentAriSelector(commentId)(state),
	projectType: projectTypeSelector(state),
	mentionProvider: mentionProviderSelector(state),
	mediaContext: mediaContextSelector(state),
	contextIdentifier: memoizedContextIdentifierSelector(state, commentId),
	sharedAtTimestamp: getTimestampForSharingAsComment(state),
	sharedValue: getValueForSharingAsComment(state),
	activityProvider: activityProviderSelector(state),
});

const getExtractionDispatchProps = (dispatch: Dispatch): Partial<IssueCommentProps> => ({
	onEditorExpandedFailure: () => {
		dispatch(editorExpandedFailure());
	},
	onEditorSaveFailure: () => {
		dispatch(editorSaveFailure());
	},
	onEditorClearSharedValues: () => {
		dispatch(clearSharedAsCommentValues());
	},
});

export default flowWithSafeComponent(
	ComponentWithAnalytics('issueFields', {
		onEditSave: 'updated',
		onDeleteConfirm: 'deleted',
		onScrolledToPermalink: 'scrolled',
	}),
	withFireUiAnalytics({
		onCopyClick: 'copyCommentUrl',
	}),
	// @ts-expect-error - Argument of type 'InferableComponentEnhancerWithProps<{ commentId: string; isHighlighted: boolean; issueAri?: string | null | undefined; isReactionsFeatureActive?: boolean | undefined; baseUrl?: string | undefined; projectType?: "software" | ... 4 more ... | undefined; ... 55 more ...; intl?: IntlShape | undefined; }, { ...; }>' is not assignable to parameter of type 'FlowStep<WithUIAnalyticsEvent<PropsWithoutRef<Omit<{ [K in keyof Props]: K extends "onEditSave" | "onDeleteConfirm" | "onScrolledToPermalink" ? (...args: [...Parameters<Props[K]>, UIAnalyticsEvent]) => ReturnType<...> : Props[K]; }, keyof WithAnalyticsEventsProps>>, { ...; }>, ConnectedComponent<...>>'.
	connect(
		(initialState, { commentId }: { commentId: string; isHighlighted: boolean }) => {
			const asyncComponentsLoadedSelector = fg('remove_issue_view_remote_data_loading')
				? hasFetchedConfluenceWhiteboardsSelector
				: isIssueRemoteDataLoadingCompleteSelector;

			return (state: State, { isHighlighted }) => ({
				jsdAuthorCanSeeRequest: commentOutsiderCommentSelector(commentId)(state),
				bodyAdf: commentEditingOrViewBodyAdfSelector(commentId)(state),
				bodyHtml: commentBodyHtmlSelector(commentId)(state),
				hasDraft: fieldHasDraftSelector(createCommentDraftKey(commentId))(state),
				author: commentAuthorSelector(commentId)(state),
				updateAuthor: commentUpdateAuthorSelector(commentId)(state),
				createdDate: commentCreatedDateSelector(commentId)(state),
				updatedDate: commentUpdatedDateSelector(commentId)(state),
				edited: commentEditedSelector(commentId)(state),
				isOptimistic: commentIsOptimisticSelector(commentId)(state),
				hasSaveFailed: commentSaveFailedSelector(commentId)(state),
				canEdit: canEditCommentSelector(commentId)(state),
				isEditing: isEditingCommentSelector(commentId)(state),
				canDelete: canDeleteCommentSelector(commentId)(state),
				hasDeleteModal: commentHasDeleteModalSelector(commentId)(state),
				visibility: commentVisibilitySelector(commentId)(state),
				visibilityEdited: commentEditingOrViewVisibilitySelector(commentId)(state),
				isInternal: isInternalCommentSelector(commentId)(state),
				isEditingInternal: isEditingInternalCommentSelector(commentId)(state),
				eventOccurredAt: commentEventOccurredAtSelector(commentId)(state),
				jsdIncidentActivityViewHidden:
					commentJsdIncidentActivityViewHiddenSelector(commentId)(state),
				showReactions: shouldShowReactionsSelector(state),
				shouldShowCommentVisibility: isCommentVisibilityRestrictionSupportedSelector(state),
				// checks whether certain async components that may affect comment scroll has finished loading.
				shouldScrollAfterAsyncComponentsLoaded:
					isHighlighted && asyncComponentsLoadedSelector(state),
				// whether permalink scroll has occured. Need to not scroll again if user resizes to single column view or vice versa
				hasScrolledPermalink: hasScrolledPermalinkCommentSelector(state),
				...(fg('threaded_comments_old_checks') && {
					isDeleted: commentIsDeletedSelector(commentId)(state),
					hasReplies: !!commentRepliesSelector(commentId)(state),
					canAdd: canAddCommentsSelector(state),
					isReplyingToComment: isReplyingToCommentSelector(commentId)(state),
					parentId: commentParentIdSelector(commentId)(state),
				}),
				...((projectTypeSelector(state) === SOFTWARE_PROJECT ||
					projectTypeSelector(state) === CORE_PROJECT) &&
					expVal('thor_issue_view_realtime_updates_experiment', 'isEnabled', false) && {
						latestCommentViewInteractedTime: getPersistedLatestCommentTimeSelector(state),
						topMostUnreadCommentId: getTopMostUnreadCommentIdSelector(state),
						isActivityFeedInViewPort: getActivityFeedInViewPort(state),
						commentScrollStatusForRealTimeUpdates: getCommentScrollStatusForRealTimeUpdates(state),
					}),
				...(fg('issue_create_from_comment_gate') && {
					canCreateWorkItemInComment: canCreateChildrenPermissionsSelector(state),
				}),
				...getExtractionStateProps(state, commentId),
			});
		},
		(dispatch: Dispatch, { commentId }) => ({
			getOnEditClick: (isInternal: boolean, commentSessionId?: string | null) =>
				dispatch(editCommentBegin(commentId, isInternal, commentSessionId)),

			onEditCancel: () => dispatch(editCommentCancel(commentId)),
			onEditUpdate: (editValue: ADF) => dispatch(editCommentUpdate(commentId, editValue)),

			onEditPaste: (pastedContent: string) =>
				dispatch(pastedIntoCommentForm(commentId, pastedContent)),

			onBlur: () => dispatch(editCommentCollapse(commentId)),
			getOnEditSave: (
				eventData: Omit<CommentSaveRequest, 'id'>,
				analyticsEvent: UIAnalyticsEvent,
				commentSessionId?: string | null,
			) =>
				dispatch(
					saveCommentRequest(
						{
							id: commentId,
							...eventData,
						},
						analyticsEvent,
						commentSessionId,
					),
				),
			onCommentVisibilityChange: (visibility: CommentVisibility) => {
				dispatch(editCommentVisibility(commentId, visibility));
			},

			onSaveRetry: () =>
				dispatch(saveCommentRetry(commentId, new Date().toISOString(), isNewComment(commentId))),

			onSaveEdit: () => dispatch(saveCommentEdit(commentId)),
			onSaveCancel: (parentId?: string) =>
				dispatch(saveCommentCancel(commentId, isNewComment(commentId), parentId)),
			setScrollStatus: (status: boolean) => dispatch(setCommentScrollStatus(status)),
			...(fg('threaded_comments_old_checks') && {
				onReplyClick: (
					replyToCommentId: string,
					authorId: string,
					authorDisplayName: string,
					visibility?: CommentVisibility,
					commentSessionId?: string | null,
					extraCommentContent?: ADF,
				) =>
					dispatch(
						replyCommentBegin(
							replyToCommentId,
							authorId,
							authorDisplayName,
							visibility,
							commentSessionId,
							fg('issue_create_from_comment_gate') ? extraCommentContent : undefined,
						),
					),
			}),
			onCommentInteractionUpdateCommentViewInteractionTimeMarker: () =>
				// Update interaction time marker with the current time when user interacted with new comments
				dispatch(updateCommentViewInteractionTimeMarker(new Date().toISOString())),
			...getExtractionDispatchProps(dispatch),
			setScrollStatusForRealTimeUpdate: (status: boolean) =>
				dispatch(setCommentScrollStatusForRealTimeUpdates(status)),
		}),
		// WARNING !!!
		//
		// DO NOT merge props. The underlying comment has been optimised and removed it from the
		// connected component.
		//
		(stateProps, dispatchProps, ownProps) => ({
			...stateProps,
			...dispatchProps,
			...ownProps,
		}),
	),
)(CommentWithMonitoring);
