import React, { useEffect, useMemo } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';

import type { Props } from '@atlassian/jira-ai-proactive-description-completer/src/ui/types.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { aiProactiveDescriptionCompleterEntryPoint } from '@atlassian/jira-ai-proactive-description-completer/entrypoint.tsx';

const entrypointParams = {};

export const AsyncAiProactiveDescriptionCompleter = (props: Props) => {
	const { editorApi, editorExperience, projectType, issueType, issueSummary, issueDescription } =
		props;
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		aiProactiveDescriptionCompleterEntryPoint,
		entrypointParams,
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	const runtimeProps = useMemo(
		() => ({ editorApi, editorExperience, projectType, issueType, issueSummary, issueDescription }),
		[editorApi, editorExperience, issueDescription, issueSummary, issueType, projectType],
	);

	return (
		<JiraEntryPointContainer
			entryPointReferenceSubject={entryPointReferenceSubject}
			id="jiraAiProactiveDescriptionCompleterEntryPoint"
			packageName="jira-ai-proactive-description-completer"
			teamName="crocs"
			fallback={null}
			runtimeProps={runtimeProps}
		/>
	);
};
