import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import { type ActionsObservable, combineEpics } from 'redux-observable';
import get from 'lodash/get';
import { Observable } from 'rxjs/Observable';
import { commitLocalUpdate } from 'react-relay';
import RelayDataID from '@atlassian/relay-data-id';
import { fireTrackAnalytics } from '@atlassian/jira-analytics-web-react/src/utils/fire-track-event.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';
import { getIssueLimitErrorField } from '@atlassian/jira-issue-view-errors/src/common/utils/index.tsx';
import {
	createConfluencePageLinkSuccess,
	createConfluencePageLinkFailed,
	type ConfluencePagesAction,
	type DeleteConfluencePageLinkByAriRequest,
	type DeleteConfluencePageLinkRequest,
	deleteConfluencePageLinkSuccess,
	deleteConfluencePageLinkFailed,
	CREATE_CONFLUENCE_PAGE_LINK_REQUEST,
	DELETE_CONFLUENCE_PAGE_LINK_REQUEST,
	DELETE_CONFLUENCE_PAGE_LINK_REQUEST_BY_ARI,
} from '@atlassian/jira-issue-view-store/src/actions/confluence-pages-actions.tsx';
import {
	baseUrlSelector,
	issueIdSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { toIssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import {
	createConfluencePageLinkRequest,
	getDeleteRequest,
	deleteRequestById,
} from './confluence-pages-server.tsx';
import recentlyVisitedConfluencePages from './recently-visited-confluence-pages-epic.tsx';

const LOG_LOCATION = 'issue.fetch.confluence-pages-epic';
const ERROR_ISSUE_ID = 'addconfluencelink.error.issueId.invalid';

const createConfluencePageLink = (
	action$: ActionsObservable<ConfluencePagesAction>,
	store: MiddlewareAPI<State>,
) =>
	action$.ofType(CREATE_CONFLUENCE_PAGE_LINK_REQUEST).switchMap((action) => {
		const state = store.getState();
		const baseUrl = baseUrlSelector(state);
		const issueId = issueIdSelector(state);
		const { pageHref, triggerInviteExperiment } = action.payload;
		const { analyticsEvent } = action.meta;

		if (!issueId) {
			log.safeErrorWithoutCustomerData(
				LOG_LOCATION,
				CREATE_CONFLUENCE_PAGE_LINK_REQUEST,
				new Error(ERROR_ISSUE_ID),
			);
			return Observable.of(createConfluencePageLinkFailed(ERROR_ISSUE_ID));
		}

		return Observable.from(
			createConfluencePageLinkRequest({
				baseUrl,
				pageHref,
				issueId: toIssueId(issueId.toString()),
			}),
		)
			.do((confluencePageLink) => {
				fireTrackAnalytics(analyticsEvent, {
					action: 'created',
					actionSubjectId: confluencePageLink.id,
					attributes: {
						createdWithError: !!get(confluencePageLink, 'error'),
						needsAuthorization: !!get(confluencePageLink, 'repairLink.href'),
					},
				});
			})
			.map((confluencePageLink) => {
				triggerInviteExperiment?.();
				return createConfluencePageLinkSuccess(confluencePageLink);
			})
			.catch((error) => {
				let invalidMessage;
				if (error.statusCode === 413) {
					invalidMessage = getIssueLimitErrorField(error);
				}

				trackOrLogClientError(LOG_LOCATION, CREATE_CONFLUENCE_PAGE_LINK_REQUEST, error);

				const { error: authLinkValue, field } = error.errors?.[0] || {};
				const authLink = field === 'APPLINK_REQ_AUTH' ? authLinkValue : undefined;

				return Observable.of(
					createConfluencePageLinkFailed(invalidMessage ?? error.message, authLink),
				);
			});
	});

const deleteConfluencePageLink = (
	action$: ActionsObservable<DeleteConfluencePageLinkRequest>,
	store: MiddlewareAPI<State>,
) =>
	action$.ofType(DELETE_CONFLUENCE_PAGE_LINK_REQUEST).switchMap((action) => {
		const state = store.getState();
		const baseUrl = baseUrlSelector(state);
		const issueKey = issueKeySelector(state);
		const { globalId } = action.payload;

		return Observable.fromPromise(getDeleteRequest(baseUrl, issueKey, globalId))
			.map(deleteConfluencePageLinkSuccess)
			.catch((error) => {
				trackOrLogClientError(
					LOG_LOCATION,
					'DELETE_CONFLUENCE_PAGE_LINK_REQUEST: Failed to delete confluence page link',
					error,
				);
				return Observable.of(deleteConfluencePageLinkFailed());
			});
	});

const deleteConfluencePageLinkByAri = (
	action$: ActionsObservable<DeleteConfluencePageLinkByAriRequest>,
	store: MiddlewareAPI<State>,
) =>
	action$.ofType(DELETE_CONFLUENCE_PAGE_LINK_REQUEST_BY_ARI).switchMap((action) => {
		const state = store.getState();
		const issueKey = issueKeySelector(state);
		const { ari } = action.payload;

		// the id is the last part of the ARI
		// e.g. ari:cloud:jira:cloudId:issue-remote-link/activation/d931f8f0/10000/10009
		const id = ari.split('/').pop();

		if (!id) {
			log.safeErrorWithoutCustomerData(
				LOG_LOCATION,
				'DELETE_CONFLUENCE_PAGE_LINK_REQUEST_BY_ARI: Failed to extract ID from ARI',
				new Error('Failed to extract ID from ARI'),
			);
			return Observable.of(deleteConfluencePageLinkFailed());
		}

		return Observable.fromPromise(deleteRequestById(issueKey, id))
			.map(() => {
				const environment = getRelayEnvironment();
				const relayStoreId = RelayDataID({ id: ari }, 'JiraConfluenceRemoteIssueLink');

				if (environment && relayStoreId) {
					// Remove the record from the relay store
					// This is a workaround for the fact that the graphql mutation api is not available
					commitLocalUpdate(environment, (relayStore) => {
						const record = relayStore.get(relayStoreId);

						if (record) {
							relayStore.delete(relayStoreId);
						}
					});
				}

				return deleteConfluencePageLinkSuccess();
			})
			.catch((error) => {
				trackOrLogClientError(
					LOG_LOCATION,
					'DELETE_CONFLUENCE_PAGE_LINK_REQUEST: Failed to delete confluence page link',
					error,
				);
				return Observable.of(deleteConfluencePageLinkFailed());
			});
	});

// @ts-expect-error - TS2769 - No overload matches this call.
const confluencePagesEpic = combineEpics(
	createConfluencePageLink,
	deleteConfluencePageLink,
	recentlyVisitedConfluencePages,
	deleteConfluencePageLinkByAri,
);

export default confluencePagesEpic;
