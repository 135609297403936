import React, { useEffect, useMemo, useRef } from 'react';
import { graphql, useFragment } from 'react-relay';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { getEmptyADF } from '@atlaskit/adf-utils/empty-adf';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import DescriptionField from '@atlassian/jira-issue-view-base/src/common/description/index.tsx';
import MultilineField from '@atlassian/jira-issue-view-base/src/common/switching-multiline-field/view.tsx';
import { DESCRIPTION_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { richTextField_issueViewLayoutRichTextField$key } from '@atlassian/jira-relay/src/__generated__/richTextField_issueViewLayoutRichTextField.graphql';
import { removeCollectionFromAdf } from '@atlassian/jira-rich-content/src/common/adf-parsing-utils.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import type { CommonRichTextFieldProps, OnSave } from '../../common/types.tsx';

const emptyAdfObject = getEmptyADF();

type Props = CommonRichTextFieldProps & {
	richTextField: richTextField_issueViewLayoutRichTextField$key;
};

export const RichTextField = ({ area, onSave, richTextField }: Props) => {
	const data = useFragment<richTextField_issueViewLayoutRichTextField$key>(
		graphql`
			fragment richTextField_issueViewLayoutRichTextField on JiraRichTextField {
				fieldId
				description
				name
				isEditableInIssueView
				type
				richText {
					adfValue {
						json
					}
				}
				...view_issueViewBase_MultilineField
			}
		`,
		richTextField,
	);

	// Although this is a Relay-based implementation, the rich text field uses connectField to integrate with functionality
	// such as drafting, which is Redux-based. connectField is a HOC and runs on module load.
	// However, we need onSave to be updated when moving to a new issue via SPA transition.
	const onSaveRef = useRef<OnSave | null>(null);
	useEffect(() => {
		onSaveRef.current = onSave;
	}, [onSave]);

	const adfValue: ADF = useMemo(() => {
		const adfValueJson = data.richText?.adfValue?.json ?? null;
		return adfValueJson ? removeCollectionFromAdf(adfValueJson) : emptyAdfObject;
	}, [data.richText?.adfValue?.json]);

	const { overriding } = useInlineEditFieldInjections();

	const fieldProps = useMemo(() => {
		const label = overriding.overrideLabel(data.name);
		const description = overriding.overrideDescription(data.description) || '';
		const isEditable = overriding.overrideIsEditable(data.isEditableInIssueView || false);
		return {
			label,
			description,
			isEditable,
			area,
			fieldId: data.fieldId,
			adfValue,
			fragmentKey: data,
		};
	}, [adfValue, area, overriding, data]);

	if (data.type === DESCRIPTION_TYPE) {
		return <DescriptionField onSaveRef={onSaveRef} {...fieldProps} />;
	}
	return (
		<UFOSegment name="issue-field-rich-text" mode="list">
			<MultilineField saveFieldOverride={onSaveRef} {...fieldProps} />
		</UFOSegment>
	);
};
