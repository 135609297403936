/* eslint-disable @atlassian/relay/query-restriction */
import React, { useMemo } from 'react';
import flow from 'lodash/flow';
import { graphql, useFragment } from 'react-relay';
import { AnalyticsSource } from '@atlassian/jira-analytics-web-react/src/components/decorators.tsx';
import ReduxAnalyticsData from '@atlassian/jira-analytics-web-react/src/components/redux-analytics-data.tsx';
import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import { TEAM_MANAGED_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import {
	useIssueKey,
	useOnIssueKeyChange,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { SUMMARY } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import { withViewExperienceTracker } from '@atlassian/jira-issue-view-experience-tracking/src/view-experience/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { getProjectUrl } from '@atlassian/jira-issue-view-services/src/issue/issue-urls.tsx';
import {
	navigateToNewIssue,
	navigateToProject,
} from '@atlassian/jira-issue-view-store/src/actions/issue-navigation-actions.tsx';
import {
	analyticsSourceSelector,
	baseUrlSelector,
	projectKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { fieldPersistedValueSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import {
	errorSelector,
	isCompletedLoadingSelector,
	isSimplifiedProjectSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import {
	canLinkToParentSelector,
	hierarchyLevelSelector,
	parentLevelIssueTypeNamesSelector,
} from '@atlassian/jira-issue-view-store/src/issue-field/state/selectors/hierarchy-level-selector.tsx';
import {
	fullIssueUrlSelector,
	getParentIssuesSelector,
	isIssueTypeFieldEditableSelector,
	issueTypeIconUrlSelector,
	issueTypeNameSelector,
	issueTypeHierarchyLevelSelector,
	issueUrlSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/breadcrumbs-selector.tsx';
import {
	projectAvatarUrlSelector,
	projectNameSelector,
	projectSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/project-selector.tsx';
import { PARENT_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { SCREEN } from '@atlassian/jira-product-analytics-bridge';
import type { breadcrumbs_issueViewFoundation_IssueBreadcrumbsWithHooksNew$key } from '@atlassian/jira-relay/src/__generated__/breadcrumbs_issueViewFoundation_IssueBreadcrumbsWithHooksNew.graphql';
import { useRouterActions } from '@atlassian/react-resource-router';
import type { DispatchProps, Props, StateProps } from './types.tsx';
import { createRelativeIssueUrl } from './utils.tsx';
import { IssueBreadcrumbs } from './view.tsx';

const IssueBreadcrumbsWithHooksOld = ({ projectName, projectAvatarUrl, ...props }: Props) => {
	const { push } = useRouterActions();

	const onIssueKeyChange = useOnIssueKeyChange();
	const issueKey = useIssueKey();
	const intl = useIntl();

	const [fieldConfig] = useFieldConfig(issueKey, PARENT_TYPE);

	return (
		<ErrorBoundary id="issue.issue-view.foundation.breadcrumbs">
			<IssueBreadcrumbs
				pushAction={push}
				onIssueKeyChange={onIssueKeyChange}
				issueKey={issueKey}
				projectName={projectName || ''}
				projectAvatarUrl={projectAvatarUrl || ''}
				intl={intl}
				isEditable={fieldConfig?.value?.isEditable}
				{...props}
			/>
		</ErrorBoundary>
	);
};

type StatePropsWithRelay = Pick<StateProps, 'error' | 'isLoadingComplete'>;

type MappedRelayDataToProps = Omit<StateProps, keyof StatePropsWithRelay> & {
	isEditable: boolean;
};

const IssueBreadcrumbsWithHooksNew = ({
	issue,
	...originalProps
}: Props & { issue: breadcrumbs_issueViewFoundation_IssueBreadcrumbsWithHooksNew$key }) => {
	const { push } = useRouterActions();

	const onIssueKeyChange = useOnIssueKeyChange();
	const intl = useIntl();
	const relayData = useFragment<breadcrumbs_issueViewFoundation_IssueBreadcrumbsWithHooksNew$key>(
		graphql`
			fragment breadcrumbs_issueViewFoundation_IssueBreadcrumbsWithHooksNew on JiraIssue {
				...view_issueViewFoundation_IssueBreadcrumbsNew
				key
				summaryField {
					text
				}
				webUrl
				issueTypesForHierarchySame {
					edges {
						node {
							name
							hierarchy {
								level
								name
							}
						}
					}
				}
				issueTypesForHierarchyAbove {
					edges {
						node {
							hierarchy {
								level
								name
							}
						}
					}
				}
				issueTypeField {
					isEditableInIssueView
					issueType {
						name
						avatar {
							xsmall
						}
					}
				}
				parentIssueField {
					isEditableInIssueView
					parentIssue {
						key
						summaryField {
							text
						}
						webUrl
						issueTypeField {
							issueType {
								name
								avatar {
									xsmall
								}
							}
						}
					}
				}
				projectField {
					name
					isEditableInIssueView
					project {
						name
						webUrl
						projectStyle
						avatar {
							medium
							large
						}
					}
				}
			}
		`,
		issue,
	);

	const mappedRelayDataToProps: MappedRelayDataToProps = useMemo(() => {
		const project = relayData?.projectField?.project;

		const parentField = relayData?.parentIssueField;

		const parentIssue = parentField?.parentIssue;

		const parentHierarchies =
			relayData?.issueTypesForHierarchyAbove?.edges
				?.map((edge) => edge?.node?.hierarchy)
				?.filter((hierarchy) => hierarchy?.name) || [];

		const result: MappedRelayDataToProps = {
			isSimplifiedProject: project?.projectStyle === TEAM_MANAGED_PROJECT,
			issueSummary: relayData?.summaryField?.text || '',
			fullIssueUrl: relayData.webUrl || '',
			relativeIssueUrl: createRelativeIssueUrl(relayData?.webUrl),
			issueTypeName: relayData?.issueTypeField?.issueType?.name || '',
			issueTypeIconUrl: relayData?.issueTypeField?.issueType?.avatar?.xsmall || '',
			parentIssues: parentIssue
				? [
						{
							key: parentIssue?.key,
							summary: parentIssue?.summaryField?.text || '',
							url: parentIssue?.webUrl || '',
							issueTypeName: parentIssue?.issueTypeField?.issueType?.name || '',
							issueTypeIconUrl: parentIssue?.issueTypeField?.issueType?.avatar?.xsmall || '',
						},
					]
				: [],
			projectName: project?.name || '',
			projectAvatarUrl:
				(fg('issue_project_icon_reuse_project_icon_from_nav')
					? project?.avatar?.large
					: project?.avatar?.medium) || '',
			projectUrl: project?.webUrl || '',
			isIssueTypeFieldEditable: relayData?.issueTypeField?.isEditableInIssueView || false,
			canLinkToParent:
				parentHierarchies?.some((hierarchy) => Boolean(hierarchy?.name)) &&
				(parentField?.isEditableInIssueView || false) &&
				parentHierarchies?.length > 0,
			issueHierarchyLevel:
				relayData?.issueTypesForHierarchySame?.edges?.[0]?.node?.hierarchy?.level === undefined
					? null
					: relayData?.issueTypesForHierarchySame?.edges?.[0]?.node?.hierarchy?.level,
			parentLevelIssueTypeNames: parentIssue?.issueTypeField?.issueType?.name
				? [parentIssue?.issueTypeField?.issueType?.name]
				: [],
			isEditable: parentField?.isEditableInIssueView || false,
		};
		return result;
	}, [relayData]);

	const { projectName, projectAvatarUrl, ...props } = useMemo(
		() => ({
			...originalProps,
			...mappedRelayDataToProps,
		}),
		[mappedRelayDataToProps, originalProps],
	);

	return (
		<ErrorBoundary id="issue.issue-view.foundation.breadcrumbs">
			<IssueBreadcrumbs
				pushAction={push}
				onIssueKeyChange={onIssueKeyChange}
				issueKey={relayData?.key}
				projectName={projectName || ''}
				projectAvatarUrl={projectAvatarUrl || ''}
				intl={intl}
				issue={relayData}
				{...props}
			/>
		</ErrorBoundary>
	);
};

const IssueBreadcrumbsWithStoreOld = flow(
	connect(
		(state: State): StateProps => {
			const isSimplifiedProject = isSimplifiedProjectSelector(state);
			const project = projectSelector(state);

			return {
				error: errorSelector(state),
				isSimplifiedProject,
				isLoadingComplete: isCompletedLoadingSelector(state),
				issueSummary: fieldPersistedValueSelector(SUMMARY)(state) || '',
				relativeIssueUrl: issueUrlSelector(state),
				fullIssueUrl: fullIssueUrlSelector(state),
				issueTypeName: issueTypeNameSelector(state),
				issueTypeIconUrl: issueTypeIconUrlSelector(state),
				parentIssues: getParentIssuesSelector(state),
				projectName: projectNameSelector(state),
				projectUrl: getProjectUrl(
					baseUrlSelector(state),
					projectKeySelector(state),
					project?.projectType,
					project?.boardId,
					isSimplifiedProject,
				),
				projectAvatarUrl: projectAvatarUrlSelector(state),
				// @ts-expect-error - TS2322 - Type 'boolean | undefined' is not assignable to type 'boolean'. | TS2345 - Argument of type '{}' is not assignable to parameter of type 'Readonly<{ agile: Agile; context: ContextState; entities: Readonly<{ applicationRoles?: ApplicationRole[] | undefined; cardCover: CardCover; childrenIssues: ChildrenIssuesState; ... 29 more ...; myPreferences?: Partial<...> | undefined; }>; ... 5 more ...; validators: Validators; }>'.
				isIssueTypeFieldEditable: isIssueTypeFieldEditableSelector(state),
				canLinkToParent: canLinkToParentSelector(state),
				issueHierarchyLevel: !isSimplifiedProject
					? issueTypeHierarchyLevelSelector(state)
					: hierarchyLevelSelector(state),
				parentLevelIssueTypeNames: parentLevelIssueTypeNamesSelector(state),
			};
		},
		(dispatch): DispatchProps => ({
			onParentClick: ({ childIssueKey, parentIssueKey, onIssueKeyChange }) => {
				onIssueKeyChange &&
					onIssueKeyChange({
						fromIssueKey: childIssueKey,
						toIssueKey: parentIssueKey,
						meta: { location: 'breadcrumbs' },
					});
				if (!__SPA__) {
					dispatch(
						navigateToNewIssue({
							fromIssueKey: childIssueKey,
							toIssueKey: parentIssueKey,
						}),
					);
				}
			},
			onProjectClick: (projectUrl, pushAction) => {
				dispatch(navigateToProject(projectUrl, pushAction));
			},
		}),
	),
	ReduxAnalyticsData((state: State) => {
		const analyticsSource = analyticsSourceSelector(state);
		const isSimplifiedProject = isSimplifiedProjectSelector(state);
		const project = projectSelector(state);
		return {
			attributes: {
				issueHierarchyLevel: hierarchyLevelSelector(state),
				analyticsSource: `issue-view (${String(analyticsSource)})`,
				nextGenProject: isSimplifiedProject,
				projectConfig: isSimplifiedProject ? 'next-gen' : 'classic',
			},
			containerType: 'project',
			containerId: project ? `${project.projectId}` : '',
		};
	}),
)(IssueBreadcrumbsWithHooksOld);

const IssueBreadcrumbsWithStoreNew = flow(
	connect(
		(state: State): StatePropsWithRelay => {
			return {
				error: errorSelector(state),
				isLoadingComplete: isCompletedLoadingSelector(state),
			};
		},
		(dispatch): DispatchProps => ({
			onParentClick: ({ childIssueKey, parentIssueKey, onIssueKeyChange }) => {
				onIssueKeyChange &&
					onIssueKeyChange({
						fromIssueKey: childIssueKey,
						toIssueKey: parentIssueKey,
						meta: { location: 'breadcrumbs' },
					});
				if (!__SPA__) {
					dispatch(
						navigateToNewIssue({
							fromIssueKey: childIssueKey,
							toIssueKey: parentIssueKey,
						}),
					);
				}
			},
			onProjectClick: (projectUrl, pushAction) => {
				dispatch(navigateToProject(projectUrl, pushAction));
			},
		}),
	),
	ReduxAnalyticsData((state: State) => {
		const analyticsSource = analyticsSourceSelector(state);
		const isSimplifiedProject = isSimplifiedProjectSelector(state);
		const project = projectSelector(state);
		return {
			attributes: {
				issueHierarchyLevel: hierarchyLevelSelector(state),
				analyticsSource: `issue-view (${String(analyticsSource)})`,
				nextGenProject: isSimplifiedProject,
				projectConfig: isSimplifiedProject ? 'next-gen' : 'classic',
			},
			containerType: 'project',
			containerId: project ? `${project.projectId}` : '',
		};
	}),
)(IssueBreadcrumbsWithHooksNew);

const IssueBreadcrumbsWithStore = componentWithCondition(
	() => fg('relay-migration-issue-header-and-parent'),
	IssueBreadcrumbsWithStoreNew,
	IssueBreadcrumbsWithStoreOld,
);

export type BreadcrumbsProps = {
	externalId?: string;
	shouldShowProjectLevelBreadcrumb: boolean;
	shouldShowRootProjectsBreadcrumb: boolean;
	issue: breadcrumbs_issueViewFoundation_IssueBreadcrumbsWithHooksNew$key;
};

const Breadcrumbs: (props: BreadcrumbsProps) => React.ReactNode = flow(
	AnalyticsSource('breadcrumbs', SCREEN),
	withViewExperienceTracker('breadcrumbs'),
	ComponentWithAnalytics('breadcrumbsItem', {
		onRootBreadcrumbClick: 'clicked',
		onProjectClick: 'clicked',
		onParentClick: 'clicked',
		onClick: 'clicked',
	}),
	withFireUiAnalytics({
		onCopyClick: 'copyUrlLink',
		onRootBreadcrumbClick: 'navigateToProjectsList',
		onProjectClick: 'navigateToProject',
		onParentClick: 'navigateToParentIssue',
		onClick: 'navigateToCurrentIssue',
	}),
)(IssueBreadcrumbsWithStore);

export default Breadcrumbs;
