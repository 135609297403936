import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { NetworkError } from './network-error';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	GenericError,
	GenericErrorWithRefresh,
	ProjectNotFoundError,
	ProjectLoadError,
	ProjectNotOnboardedError,
	RateLimitError,
	ViewUnavailableError,
} from './main';

const ERROR_PREFIX = 'polaris-error';

/**
 * Logs polaris errors to the backend so they can be monitored using splunk.
 */
export const logPolarisError = (postfix: string, error: Error) => {
	// TODO: Determine some test function to determine if an error is 'interesting' to us and filter
	// the rest.

	logSafeErrorWithoutCustomerDataWrapper(`${ERROR_PREFIX}.${postfix}`, error.message, error);
};
