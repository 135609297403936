/** @jsx jsx */
import React from 'react';
import { token } from '@atlaskit/tokens';
import type { MediaClientConfig } from '@atlaskit/media-core';
import { Box, Flex } from '@atlaskit/primitives';
import { cssMap, cx, jsx } from '@atlaskit/css';
import { Card } from '@atlaskit/media-card';
import { Truncate } from '@atlaskit/media-ui/truncateText';
import Tooltip from '@atlaskit/tooltip';
import VisuallyHidden from '@atlaskit/visually-hidden';
import { useIntl } from '@atlassian/jira-intl';
import type { Attachment } from '@atlassian/jira-issue-shared-types/src/common/types/attachments.tsx';
import messages from '../messages.tsx';

const dimensions = {
	width: 32,
	height: 32,
};

const styles = cssMap({
	cardWrapper: {
		borderColor: token('color.border'),
		borderWidth: token('border.width'),
		borderStyle: 'solid',
		borderRadius: token('border.radius'),
		marginRight: token('space.050'),
		marginLeft: token('space.050'),
	},
	textWrapper: {
		minWidth: '0px',
		width: '100%',
		paddingLeft: token('space.050'),
	},
	noWrap: {
		whiteSpace: 'nowrap',
	},
});

const NameWithPreview = ({
	attachment,
	mediaClientConfig,
}: {
	attachment: Attachment;
	mediaClientConfig: MediaClientConfig;
}) => {
	const { formatMessage } = useIntl();
	return (
		<>
			<VisuallyHidden testId="issue-attachments-table.ui.name-with-preview.visually-hidden">
				{`${attachment.filename} ${attachment.hasRestrictedParent ? `- ${formatMessage(messages.internalOnlyLabel)}` : ''}`}
			</VisuallyHidden>
			<div aria-hidden css={styles.noWrap}>
				<Flex justifyContent="center" alignItems="center">
					<div css={styles.cardWrapper}>
						<Card
							mediaClientConfig={mediaClientConfig}
							identifier={{
								id: attachment.mediaApiFileId,
								mediaItemType: 'file',
							}}
							dimensions={dimensions}
							disableOverlay
							shouldEnableDownloadButton={false}
							shouldOpenMediaViewer={false}
							testId={`issue-attachments-table.ui.name-with-preview.card-${attachment.mediaApiFileId}`}
							resizeMode="crop"
						/>
					</div>
					<Box xcss={cx(styles.textWrapper)}>
						<Tooltip
							content={attachment.filename}
							testId="issue-attachments-table.ui.name-with-preview.tooltip"
						>
							<Truncate text={attachment.filename} startFixedChars={3} endFixedChars={4} />
						</Tooltip>
					</Box>
				</Flex>
			</div>
		</>
	);
};

export default NameWithPreview;
