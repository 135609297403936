/**
 * @generated SignedSource<<ba4cd99bc0635529309afad3030d288c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type src_issueViewBase_AsyncDesignPanelExperiment$data = {
  readonly devOps: {
    readonly providers: {
      readonly designProviders: ReadonlyArray<{
        readonly supportedActions: {
          readonly checkAuth: boolean | null | undefined;
          readonly getEntityByUrl: boolean | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "src_issueViewBase_AsyncDesignPanelExperiment";
};
export type src_issueViewBase_AsyncDesignPanelExperiment$key = {
  readonly " $data"?: src_issueViewBase_AsyncDesignPanelExperiment$data;
  readonly " $fragmentSpreads": FragmentRefs<"src_issueViewBase_AsyncDesignPanelExperiment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "siteAri"
    }
  ],
  "kind": "Fragment",
  "name": "src_issueViewBase_AsyncDesignPanelExperiment",
  "selections": [
    {
      "concreteType": "DevOps",
      "kind": "LinkedField",
      "name": "devOps",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "id",
              "variableName": "siteAri"
            },
            {
              "kind": "Literal",
              "name": "providerTypes",
              "value": [
                "DESIGN"
              ]
            }
          ],
          "concreteType": "DevOpsProviders",
          "kind": "LinkedField",
          "name": "providers",
          "plural": false,
          "selections": [
            {
              "concreteType": "DevOpsDesignProvider",
              "kind": "LinkedField",
              "name": "designProviders",
              "plural": true,
              "selections": [
                {
                  "concreteType": "DevOpsSupportedActions",
                  "kind": "LinkedField",
                  "name": "supportedActions",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "getEntityByUrl"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "checkAuth"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "414fde429cf66a7d3e6e3fadd1894e29";

export default node;
