import differenceInSeconds from 'date-fns/differenceInSeconds';
import type {
	SuggestedChildIssue,
	ChildIssue,
} from '@atlassian/jira-issue-fetch-services/src/services/issue-breakdown-data-fetcher/types.tsx';
import {
	fireTrackAnalytics,
	type UIAnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import type {
	JiraSuggestedChildIssueStatusType,
	JiraSuggestedIssueErrorType,
} from '@atlassian/jira-relay/src/__generated__/issueBreakdownSubscription.graphql';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	type Action,
	createStore,
	createHook,
	createContainer,
	createActionsHook,
} from '@atlassian/react-sweet-state';
import type { Message } from '@atlassian/ai-work-breakdown';
import { aiwbAnalyticsAttrs } from '../constants.tsx';
import type { SubscriptionError } from '../services/issue-breakdown/types.tsx';

export const steps = {
	defaultStep: 'defaultStep',
	draftListStep: 'draftListStep',
	errorStep: 'errorStep',
} as const;
export type Step = (typeof steps)[keyof typeof steps];

export const reasons = {
	accept: 'accept',
	reject: 'reject',
} as const;
export type Reason = (typeof reasons)[keyof typeof reasons];

type State = {
	currentStep: Step;
	retryAttempts: number;
	suggestedChildIssues: SuggestedChildIssue[];
	promptValue: string;
	headerPromptValue: string;
	selectedIssueId: string | null;
	channelId: string | null;
	acceptedChildIssues: ChildIssue[];
	rejectedChildIssues: ChildIssue[];
	isStreaming: boolean;
	clearListWhenAddSuggestion: boolean;
	status: JiraSuggestedChildIssueStatusType | null;
	streamingError: JiraSuggestedIssueErrorType | SubscriptionError | null;
	startTime: Date | null;
	conversation: Message[];
	progressStatus: string[];
	issueBreakDownButtonClickCount: number;
	isProactivelySuggestingIssues: boolean;
};

const initialState: State = {
	currentStep: steps.defaultStep,
	retryAttempts: 0,
	suggestedChildIssues: [],
	promptValue: '',
	headerPromptValue: '',
	selectedIssueId: null,
	channelId: null,
	acceptedChildIssues: [],
	rejectedChildIssues: [],
	isStreaming: false,
	clearListWhenAddSuggestion: false,
	status: null,
	streamingError: null,
	startTime: null,
	conversation: [],
	progressStatus: [],
	issueBreakDownButtonClickCount: 0,
	isProactivelySuggestingIssues: false,
};

const setIssueBreakdownStep =
	(step: Step): Action<State> =>
	({ setState }) => {
		setState({ currentStep: step });
	};

const setIsProactivelySuggestingIssues =
	(isProactivelySuggestingIssues: boolean): Action<State> =>
	({ setState }) => {
		setState({ isProactivelySuggestingIssues });
	};

const setSuggestedChildIssues =
	(suggestedChildIssues: SuggestedChildIssue[]): Action<State> =>
	({ setState }) => {
		setState({ suggestedChildIssues });
	};

const setSelectedIssueId =
	(selectedIssueId: string | null): Action<State> =>
	({ setState }) => {
		setState({ selectedIssueId });
	};

const unselectCurrentId =
	(): Action<State> =>
	({ setState }) => {
		setState({ selectedIssueId: null });
	};

const setChannelId =
	(channelId: string | null): Action<State> =>
	({ setState }) => {
		setState({ channelId });
	};

const setStartTime =
	(): Action<State> =>
	({ setState }) => {
		setState({ startTime: new Date() });
	};

const resetIssueBreakdownState =
	(
		analyticsEvent?: UIAnalyticsEvent,
		discardAnalyticsAttrs?: { hasRequiredField: boolean; disableBulkCreate: boolean },
	): Action<State> =>
	({ getState, setState }) => {
		if (analyticsEvent) {
			const {
				channelId,
				isStreaming,
				streamingError,
				suggestedChildIssues,
				acceptedChildIssues,
				startTime,
			} = getState();
			const timeSinceStart = startTime && differenceInSeconds(new Date(), startTime);
			const attrs = {
				...aiwbAnalyticsAttrs,
				...discardAnalyticsAttrs,
				singleInstrumentationID: channelId,
				suggestedNumber: suggestedChildIssues.length,
				acceptedNumber: acceptedChildIssues.length,
				isStreaming,
				streamingError,
				timeSinceStart,
			};
			fireTrackAnalytics(analyticsEvent, 'aiInteraction dismissed', 'cancelIssueBreakdown', attrs);
		}
		setState(initialState);
	};

const setPromptValue =
	(promptValue: string): Action<State> =>
	({ setState }) => {
		setState({ promptValue });
	};

const setHeaderPromptValue =
	(headerPromptValue: string): Action<State> =>
	({ setState }) => {
		setState({ headerPromptValue });
	};

const isIssueBreakdownOpen =
	(): Action<State, void, Boolean> =>
	({ getState }) => {
		const { currentStep } = getState();
		return currentStep !== steps.defaultStep;
	};

const getSuggestedLength =
	(): Action<State, void, number> =>
	({ getState }) => {
		const { suggestedChildIssues } = getState();
		return suggestedChildIssues.length;
	};

const updateSuggestedResult =
	({
		channelId,
		suggestedChildIssues,
	}: {
		channelId: string;
		suggestedChildIssues: SuggestedChildIssue[];
	}): Action<State> =>
	({ setState }) => {
		setState({ channelId, suggestedChildIssues });
	};

const addMessage =
	(msg: Message): Action<State> =>
	({ getState, setState }) => {
		const { conversation } = getState();

		setState({
			conversation: [...conversation, msg],
		});
	};

const addProgressStatus =
	(newProgressStatus: string): Action<State> =>
	({ getState, setState }) => {
		const { progressStatus: currentStatusList } = getState();
		if (
			currentStatusList.length > 0 &&
			currentStatusList[currentStatusList.length - 1] === newProgressStatus
		) {
			return;
		}

		const updatedStatusList = [...currentStatusList, newProgressStatus];

		setState({ progressStatus: updatedStatusList });
	};

const resetProgressStatus =
	(): Action<State> =>
	({ setState }) => {
		setState({ progressStatus: [] });
	};

const addSuggestedIssue =
	(newSuggestedIssue: SuggestedChildIssue): Action<State> =>
	({ getState, setState }) => {
		const { suggestedChildIssues: oldIssues, status, clearListWhenAddSuggestion } = getState();
		const suggestedChildIssues = clearListWhenAddSuggestion
			? [newSuggestedIssue]
			: oldIssues.concat(newSuggestedIssue);
		if (status) {
			setState({ suggestedChildIssues, status: null, clearListWhenAddSuggestion: false });
		} else {
			setState({ suggestedChildIssues, clearListWhenAddSuggestion: false });
		}
	};

const removeSuggestedIssue =
	(id: string | string[], reason: Reason): Action<State> =>
	({ getState, setState, dispatch }) => {
		const { suggestedChildIssues, acceptedChildIssues, rejectedChildIssues } = getState();
		const removeIdSet = new Set(Array.isArray(id) ? id : [id]);

		const initialValue: { issues: SuggestedChildIssue[]; removed: ChildIssue[] } = {
			issues: [],
			removed: [],
		};
		const { issues, removed } = suggestedChildIssues.reduce((result, current) => {
			if (removeIdSet.has(current.id)) {
				const { summary, description } = current;
				return Object.assign(result, { removed: result.removed.concat({ summary, description }) });
			}
			return Object.assign(result, { issues: result.issues.concat(current) });
		}, initialValue);

		if (reason === reasons.accept) {
			setState({
				suggestedChildIssues: issues,
				acceptedChildIssues: acceptedChildIssues.concat(removed),
			});
		} else {
			setState({
				suggestedChildIssues: issues,
				rejectedChildIssues: rejectedChildIssues.concat(removed),
			});
		}
		if (issues.length === 0) {
			dispatch(actions.resetIssueBreakdownState());
		}
	};

const updateStreamingStatus =
	(isStreaming: boolean, status: State['status'] = null): Action<State> =>
	({ setState }) => {
		setState({ isStreaming, clearListWhenAddSuggestion: isStreaming, status });
	};

const setStreamingError =
	(error: JiraSuggestedIssueErrorType | SubscriptionError): Action<State> =>
	({ setState, getState }) => {
		const { suggestedChildIssues, promptValue } = getState();

		if (suggestedChildIssues.length > 0 || promptValue) {
			// If it's a refine request,
			// for example, after the initial request has succeeded or the user has typed in text
			// then want to keep the channel ID.
			setState({ streamingError: error });
		} else {
			// If the initial request fails, we want to start a new one
			setState({ channelId: null, streamingError: error });
		}
	};

const clearError =
	(): Action<State> =>
	({ setState }) => {
		setState({ streamingError: null });
	};
const incrementRetryCount =
	(): Action<State> =>
	({ getState, setState }) => {
		const { retryAttempts } = getState();
		setState({ retryAttempts: retryAttempts + 1 });
	};

const incrementIssueBreakDownButtonClickCount =
	(): Action<State> =>
	({ getState, setState }) => {
		const { issueBreakDownButtonClickCount } = getState();
		setState({ issueBreakDownButtonClickCount: issueBreakDownButtonClickCount + 1 });
	};

export const actions = {
	addMessage,
	setIssueBreakdownStep,
	setSuggestedChildIssues,
	setPromptValue,
	setHeaderPromptValue,
	setSelectedIssueId,
	unselectCurrentId,
	setChannelId,
	setStartTime,
	resetIssueBreakdownState,
	updateSuggestedResult,
	addSuggestedIssue,
	removeSuggestedIssue,
	updateStreamingStatus,
	setStreamingError,
	incrementRetryCount,
	isIssueBreakdownOpen,
	getSuggestedLength,
	clearError,
	addProgressStatus,
	resetProgressStatus,
	incrementIssueBreakDownButtonClickCount,
	setIsProactivelySuggestingIssues,
};

type ActionType = typeof actions;

export const IssueBreakdownAiContainer = createContainer();

const Store = createStore<State, ActionType>({
	initialState,
	actions,
	name: 'issue-breakdown',
	containedBy: IssueBreakdownAiContainer,
});

export const useIssueBreakdown = createHook(Store);

export const useIssueBreakdownActions = createActionsHook(Store);
