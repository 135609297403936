import type MarkdownIt from 'markdown-it';
import type StateCore from 'markdown-it/lib/rules_core/state_core.mjs';
import type Token from 'markdown-it/lib/token.mjs';

const SCULPTOR_REGEX = /\[\^(sculptor)\^\]/;

const dataSculptor = (state: StateCore) => {
	const tokens: Token[] = [];

	for (const token of state.tokens) {
		const tokenChildren: Array<Token | null> = [];

		if (token.type !== 'inline' || (token.children && token?.children?.length <= 0)) {
			tokens.push(token);
			continue;
		}

		if (token.children) {
			token.children.forEach((childToken) => {
				if (childToken.type === 'text' && SCULPTOR_REGEX.test(childToken.content)) {
					const citationToken = new state.Token('status', '', 0);
					citationToken.content = '';
					citationToken.block = false;
					citationToken.level = childToken.level;

					citationToken.attrs = [
						['localId', 'data-sculptor'],
						['text', 'data-sculptor'],
						['color', 'blue'],
					];

					const parts = childToken.content.split(SCULPTOR_REGEX);
					parts.forEach((part, index) => {
						if (index % 2 === 0) {
							const textToken = new state.Token('text', '', 0);
							textToken.content = part;
							textToken.level = childToken.level;
							tokenChildren.push(textToken);
						} else {
							tokenChildren.push(citationToken);
						}
					});

					return;
				}

				tokenChildren.push(childToken);
			});
		}

		const filteredTokenChildren = tokenChildren.filter(
			(childToken) => childToken !== null,
		) as Token[];
		token.children = filteredTokenChildren;
		tokens.push(token);
	}

	state.tokens = tokens;
	return;
};

export default (md: MarkdownIt) => md.core.ruler.push('data-sculptor', dataSculptor);
