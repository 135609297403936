/**
 * @generated SignedSource<<f8d51ababf34682bfc346bf7e6d539b3>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueActions_issueViewFoundation_IssueActionsMenuWithRelay_issueViewRelayFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"issueActionsView_issueViewFoundation_IssueActionsMeatballMenuInternal">;
  readonly " $fragmentType": "issueActions_issueViewFoundation_IssueActionsMenuWithRelay_issueViewRelayFragment";
};
export type issueActions_issueViewFoundation_IssueActionsMenuWithRelay_issueViewRelayFragment$key = {
  readonly " $data"?: issueActions_issueViewFoundation_IssueActionsMenuWithRelay_issueViewRelayFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueActions_issueViewFoundation_IssueActionsMenuWithRelay_issueViewRelayFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "issueActions_issueViewFoundation_IssueActionsMenuWithRelay_issueViewRelayFragment",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "issueActionsView_issueViewFoundation_IssueActionsMeatballMenuInternal"
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "163fb5320e6ca13c755f2e241abb43a3";

export default node;
