/** @jsx jsx */
import React, { type ComponentType } from 'react';
import { jsx, css } from '@compiled/react';
import Placeholder from '@atlaskit/react-ufo/placeholder';
import { token } from '@atlaskit/tokens';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

import type { CommentRepliesComponentProps as RepliesProps } from './replies-list/index.tsx';
import ReplyEditorContainer from './replies-editor-wrapper/index.tsx';

type CommentRepliesContainerProps = {
	fullIssueUrl: string | null;
	commentId: string;
	commentSessionId?: string | null;
	hasReplies: boolean;
	isRealTimeCommentUpdated?: boolean;
};

const LazyRepliesList = lazyForPaint<ComponentType<RepliesProps>>(
	() => import(/* webpackChunkName: "async-reply-comments-list" */ './replies-list'),
);

const RepliesContainer = ({ hasReplies, ...otherProps }: CommentRepliesContainerProps) => {
	return (
		<div css={[sectionContainerStyle]}>
			{hasReplies && (
				<Placeholder name="reply-comments-list">
					<LazyRepliesList {...otherProps} />
				</Placeholder>
			)}
			<ReplyEditorContainer {...otherProps} />
		</div>
	);
};

const sectionContainerStyle = css({
	marginTop: token('space.negative.250'),
});

export default RepliesContainer;
