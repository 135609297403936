import type React from 'react';
import {
	useCallback,
	useState,
	type ComponentType,
	type Key,
	type PropsWithChildren,
	type ReactNode,
} from 'react';
import type { CustomItemComponentProps } from '@atlaskit/menu/types';

export type QuickActionDropdownItemProps = {
	name?: string;
	category?: string;
	testId?: string;
	itemKey?: Key | null | undefined;
	onClick?: (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => void;
	isDisabled?: boolean;
	iconComponent?: ReactNode;
	labelComponent?: ReactNode;
	label?: string;
	itemBadgeComponent?: ReactNode;
	parentLabel?: ReactNode;
	searchLabel?: string;
	linkComponent?: string;
	parentLabelMessage?: string;
	additionalDropdownProps?: ComponentType<PropsWithChildren<CustomItemComponentProps>>;
};

export const useDynamicQuickActionListItems = (dropdownItems: QuickActionDropdownItemProps[]) => {
	const [dynamicQuickActionListItems, setDynamicQuickActionListItems] =
		useState<QuickActionDropdownItemProps[]>(dropdownItems);

	const setQuickActionListItem = useCallback(
		(category: string, name: string, newValues: QuickActionDropdownItemProps | null) => {
			setDynamicQuickActionListItems((previousDropdownItems) => {
				const index = previousDropdownItems.findIndex(
					(dropdownItem) => dropdownItem.category === category && dropdownItem.name === name,
				);

				if (index === -1) {
					throw new Error(
						`Dropdown item - category: ${category}, name: ${name} not found in quick actions`,
					);
				}

				const newItem = {
					...previousDropdownItems[index],
					...newValues,
				};

				const newDropdownItems = [...previousDropdownItems];
				newDropdownItems[index] = newItem;

				return newDropdownItems;
			});
		},
		[],
	);

	return { dynamicQuickActionListItems, setQuickActionListItem };
};
