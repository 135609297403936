import React, { useEffect } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { SingleLineSmartLinkEntryPoint } from './entrypoint.tsx';
import { SingleLineSmartLinkSSR } from './index.tsx';
import type { SingleLineSmartLinkProps } from './types.tsx';

const entryPointParams = {};

export const LazySingleLineSmartLink = (props: SingleLineSmartLinkProps) => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		SingleLineSmartLinkEntryPoint,
		entryPointParams,
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		if (fg('jsc_inline_editing_field_refactor')) {
			if (__SPA__) {
				entryPointActions.load();
			}
		} else {
			entryPointActions.load();
		}
	}, [entryPointActions]);

	if (__SERVER__ && fg('jsc_inline_editing_field_refactor')) {
		return <SingleLineSmartLinkSSR {...props} />;
	}

	return (
		<JiraEntryPointContainer
			id="jira.issue-field.single-line-smart-link"
			packageName="issue-field-smart-link"
			entryPointReferenceSubject={entryPointReferenceSubject}
			runtimeProps={props}
		/>
	);
};
