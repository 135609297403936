/**
 * @generated SignedSource<<db6da513951acc38ceeb6df7dda2f6eb>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditView$data = {
  readonly " $fragmentSpreads": FragmentRefs<"originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditViewNew_timeTrackingSettingsfragmentRef" | "originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditViewOld_timeTrackingSettingsfragmentRef">;
  readonly " $fragmentType": "originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditView";
};
export type originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditView$key = {
  readonly " $data"?: originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditView$data;
  readonly " $fragmentSpreads": FragmentRefs<"originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditView",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditViewOld_timeTrackingSettingsfragmentRef"
    },
    {
      "kind": "FragmentSpread",
      "name": "originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditViewNew_timeTrackingSettingsfragmentRef"
    }
  ],
  "type": "JiraTimeTrackingSettings"
};

(node as any).hash = "6a57f51036e79f4daed0880835e3a443";

export default node;
