// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { TenantContext, AppProps, State, BaseProps, ConnectProps } from '../model/types';

export const SHORTCUT_SOURCE = 'shortcut' as const;
export const BUTTON_SOURCE = 'button' as const;
export const DROPDOWN_SOURCE = 'dropdown' as const;
// suppressed to allow migration to typescript-eslint v8 - this is a new error reported with v8 and is only used as a type
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPOTLIGHT_SOURCE = 'watchFirstIssueV1Spotlight' as const;

export type ToggleWatchingSource =
	| typeof BUTTON_SOURCE
	| typeof SHORTCUT_SOURCE
	| typeof DROPDOWN_SOURCE
	| typeof SPOTLIGHT_SOURCE;
