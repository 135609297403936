import React from 'react';
import Button from '@atlaskit/button/new';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';

import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useSingleSelectField } from '@atlassian/jira-issue-field-select/src/services/use-select-field/index.tsx';
import type { UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import type { SeveritySuggestedAction } from '@atlassian/jira-servicedesk-ai-context-panel-resources/src/services/ops/common/types.tsx';
import { commonMessages } from '../../messages.tsx';
import type { ActionButtonProps } from '../../types.tsx';
import { messages } from './messages.tsx';

type Props = ActionButtonProps<SeveritySuggestedAction['context']>;

export const ChangeSeverityButton = ({
	context,
	onSuccess: onSuccessProp,
	onFailure: onFailureProp,
	onClick: onClickProp,
}: Props) => {
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagsService();
	const issueKey = useIssueKey();
	const { fieldId, suggestion } = context;

	const onSuccess = () => {
		showFlag({
			type: 'success',
			title: formatMessage(messages.changeSeveritySuccess),
			messageId: 'servicedesk-ai-context-ops-panel.common.ui.change-severity.show-flag.success',
			messageType: 'transactional',
		});
		onSuccessProp?.();
	};

	const onFailure = (error: Error) => {
		showFlag({
			type: 'error',
			title: formatMessage(commonMessages.error),
			messageId: 'servicedesk-ai-context-ops-panel.common.ui.change-severity.show-flag.error',
			messageType: 'transactional',
		});
		onFailureProp?.(error);
	};

	const [{ loading }, { saveValue }] = useSingleSelectField({
		issueKey,
		fieldKey: fieldId,
		onSuccess,
		onFailure,
	});

	const onClickHandler = (_: unknown, event: UIAnalyticsEvent) => {
		saveValue(suggestion, null, event);
		onClickProp?.();
	};
	return (
		<Button isLoading={loading} onClick={onClickHandler}>
			{formatMessage(commonMessages.accept)}
		</Button>
	);
};
