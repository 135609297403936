import { fetchQuery, graphql } from 'react-relay';
import { toSanitizedUrl } from '@atlassian/jira-development-common/src/model/common-types.tsx';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import type { detailsDeploymentDataDetectedQuery$data } from '@atlassian/jira-relay/src/__generated__/detailsDeploymentDataDetectedQuery.graphql';
import type { detailsWorkSuggestionsDataQuery$data } from '@atlassian/jira-relay/src/__generated__/detailsWorkSuggestionsDataQuery.graphql';
import type { ApplinkData, ErrorLink } from '../../../common/model/types.tsx';
import type { BuildProvider } from '../../model/builds.tsx';
import type { Author } from '../../model/common.tsx';
import { DEVINFO_DIALOG_PR_SUGGESTIONS_TASK_TYPES } from '../../model/constants.tsx';
import type {
	Deployment,
	DeploymentEnvironment,
	DeploymentProvider,
} from '../../model/deployments.tsx';
import type {
	Branch,
	BranchPR,
	Commit,
	Details,
	EmbeddedMarketplaceDetails,
	InstanceTypeDetail,
	PullRequest,
	Repository,
	Review,
	WorkSuggestions,
} from '../../model/index.tsx';
import getDetails from '../../rest/details/index.tsx';
import type {
	BranchesType,
	BranchPRType,
	BuildProviderType,
	CommitsType,
	DeploymentEnvironmentType,
	DeploymentProviderType,
	DeploymentType,
	InstanceType,
	PullRequestsType,
	RepositoryType,
	ResponseData,
	ReviewType,
} from '../../rest/details/types.tsx';
import { transformFeatureFlags } from './feature-flag-transformer.tsx';
import { transformRemoteLinks } from './remote-link-transformer.tsx';
import { randomizeDevinfoDetailsDialogPrSuggestions } from './utils.tsx';

const transformBranchPR: (arg1: BranchPRType) => BranchPR = (swagBranchPR) => ({
	...swagBranchPR,
});

const transformReview: (arg1: ReviewType) => Review = (swagReview) => ({
	...swagReview,
});

const UNAUTHORIZED_ERROR = 'unauthorized';

const hasUnauthorizedError = (errorMessages: string[] = []) =>
	errorMessages.indexOf(UNAUTHORIZED_ERROR) > -1;

// Make a string for errors
// @ts-expect-error - TS7006 - Parameter 'instanceType' implicitly has an 'any' type.
const mapErrors = (instanceType): ErrorLink | null => {
	const errorMessages = instanceType.devStatusErrorMessages || [];
	if (errorMessages.length && !hasUnauthorizedError(instanceType.devStatusErrorMessages)) {
		return {
			url: instanceType.baseUrl,
			name: instanceType.name,
		};
	}
	return null;
};

// @ts-expect-error - TS7006 - Parameter 'instanceType' implicitly has an 'any' type.
const mapUnauthorizedLinks = (instanceType): ApplinkData | null => {
	if (hasUnauthorizedError(instanceType.devStatusErrorMessages)) {
		return {
			applicationLinkId: instanceType.id,
			name: instanceType.name,
		};
	}
	return null;
};

const transformInstanceType = (
	// @ts-expect-error - TS7006 - Parameter 'instanceType' implicitly has an 'any' type.
	instanceType,
	repositories: Array<Repository>,
	danglingPullRequests: Array<PullRequest>,
	buildProviders: Array<BuildProvider>,
): InstanceTypeDetail => ({
	id: instanceType.id,
	name: instanceType.name,
	type: instanceType.type,
	typeName: instanceType.typeName,
	baseUrl: toSanitizedUrl(instanceType.baseUrl),
	repositories,
	danglingPullRequests,
	buildProviders,
	errorLink: mapErrors(instanceType),
	unauthorizedLink: mapUnauthorizedLinks(instanceType),
});

// @ts-expect-error - TS7006 - Parameter 'swagBranch' implicitly has an 'any' type.
const transformLastCommit = (swagBranch) =>
	swagBranch.lastCommit
		? {
				...swagBranch.lastCommit,
				id: swagBranch.lastCommit.displayId,
			}
		: null;

export const transformBranch: (
	arg1: InstanceType,
	arg2: RepositoryType,
	arg3: BranchesType,
	arg4: number,
) => Branch = (instanceType, repository, swagBranch, index) => ({
	id: `${instanceType.id}/${repository.name}/${index}`,
	name: swagBranch.name,
	url: swagBranch.url,
	createPullRequestUrl: swagBranch.createPullRequestUrl,
	createReviewUrl: swagBranch.createReviewUrl,
	pullRequests: swagBranch.pullRequests.map<BranchPR>(transformBranchPR),
	reviews: swagBranch.reviews.map<Review>(transformReview),
	lastCommit: transformLastCommit(swagBranch),
});

export const transformPullRequest: (arg1: PullRequestsType, arg2: string) => PullRequest = (
	swagPr,
	uid,
	// @ts-expect-error - TS2322 - Type '{ uid: string; id: string; name: string; branchUrl: string; branchName: string; url: string; status: string; author: { readonly avatarUrl: string; readonly name: string; }; commentCount: number; lastUpdate: string; reviewers: { ...; }[]; }' is not assignable to type 'PullRequest'.
) => ({
	...swagPr,
	uid,
});

const mapCommitAuthor = (author: {
	readonly avatarUrl: string;
	readonly name: string;
}): Author => ({
	name: author.name,
	avatar: author.avatarUrl,
});

// @ts-expect-error - TS2304 - Cannot find name 'FileType'.
const mapCommitFiles = (files: Array<FileType>) =>
	files.map((file) => ({
		...file,
	}));

export const transformCommit: (arg1: CommitsType) => Commit = (swagCommit) => ({
	id: swagCommit.id,
	displayId: swagCommit.displayId,
	url: swagCommit.url,
	createReviewUrl: swagCommit.createReviewUrl,
	message: swagCommit.message,
	authorTimestamp: swagCommit.timestamp,
	author: mapCommitAuthor(swagCommit.author),
	merge: swagCommit.isMerge,
	fileCount: swagCommit.files.length,
	files: mapCommitFiles(swagCommit.files),
});

const transformPullRequests = (
	instanceId: string,
	pullRequests: PullRequestsType[] = [],
	repositoryName = '__',
): PullRequest[] =>
	pullRequests
		.map((pullRequest) =>
			transformPullRequest(pullRequest, `${instanceId}/${repositoryName}/${pullRequest.id}`),
		)
		// @ts-expect-error - TS2362 - The left-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type. | TS2363 - The right-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type.
		.sort((a, b) => new Date(b.lastUpdate) - new Date(a.lastUpdate));

export const transformRepository: (
	arg1: InstanceType,
	arg2: RepositoryType,
	arg3: number,
) => Repository = (instanceType, repository, repoIndex) => ({
	id: repository.id ?? `${instanceType.id}/${repoIndex}`,
	name: repository.name,
	url: repository.url != null ? toSanitizedUrl(repository.url) : null,
	avatar: repository.avatarUrl != null ? toSanitizedUrl(repository.avatarUrl) : null,
	description: repository.description,
	instanceName: instanceType.typeName,
	instanceId: instanceType.id,
	branches: repository.branches.map((branch, index) =>
		transformBranch(instanceType, repository, branch, index),
	),
	pullRequests: transformPullRequests(instanceType.id, repository.pullRequests, repository.name),
	commits: repository.commits
		.map((commit) => transformCommit(commit))
		// @ts-expect-error - TS2362 - The left-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type. | TS2363 - The right-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type.
		.sort((a, b) => new Date(b.authorTimestamp) - new Date(a.authorTimestamp)),
});

const transformDanglingPullRequests: (arg1: InstanceType) => PullRequest[] = (instanceType) =>
	transformPullRequests(instanceType.id, instanceType.danglingPullRequests);

export const transformBuildProvider: (arg1: BuildProviderType) => BuildProvider = (
	buildProvider,
) => ({
	id: buildProvider.id,
	name: buildProvider.name,
	url: toSanitizedUrl(buildProvider.url),
	description: buildProvider.description,
	avatarUrl: buildProvider.avatarUrl != null ? toSanitizedUrl(buildProvider.avatarUrl) : null,
	builds: buildProvider.builds.sort(
		(a, b) => new Date(b.lastUpdated).valueOf() - new Date(a.lastUpdated).valueOf(),
	),
});

export const transformInstanceTypes = (response: ResponseData): InstanceTypeDetail[] => {
	const instanceTypes = response.data.developmentInformation.details.instanceTypes.map(
		(instanceType) => {
			const transformedRepositories = instanceType.repository.map((repository, index) =>
				transformRepository(instanceType, repository, index),
			);
			const transformedDanglingPullRequests = transformDanglingPullRequests(instanceType);
			const transformedBuildProviders = instanceType.buildProviders.map((buildProvider) =>
				transformBuildProvider(buildProvider),
			);
			return transformInstanceType(
				instanceType,
				transformedRepositories,
				transformedDanglingPullRequests,
				transformedBuildProviders,
			);
		},
	);

	return instanceTypes;
};

const transformEnvironment: (arg1: DeploymentEnvironmentType) => DeploymentEnvironment = (
	environment,
) => ({
	id: environment.id,
	displayName: environment.displayName,
	// @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'EnvironmentType'.
	type: environment.type,
});

const transformDeployment: (arg1: DeploymentType) => Deployment = (deployment) => ({
	displayName: deployment.displayName,
	url: deployment.url,
	// @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'DeploymentState'.
	state: deployment.state,
	lastUpdated: deployment.lastUpdated,
	environment: transformEnvironment(deployment.environment),
	pipelineId: deployment.pipelineId,
	pipelineDisplayName: deployment.pipelineDisplayName,
	pipelineUrl: deployment.pipelineUrl,
});

const transformDeploymentProvider: (arg1: DeploymentProviderType) => DeploymentProvider = (
	deploymentProvider,
) => ({
	id: deploymentProvider.id,
	name: deploymentProvider.name,
	homeUrl: toSanitizedUrl(deploymentProvider.homeUrl),
	logoUrl: deploymentProvider.logoUrl != null ? toSanitizedUrl(deploymentProvider.logoUrl) : null,
	deployments: deploymentProvider.deployments.map((deployment) => transformDeployment(deployment)),
});

export const transformDeploymentProviders = (result: ResponseData): DeploymentProvider[] =>
	result.data.developmentInformation.details.deploymentProviders.map((deploymentProvider) =>
		transformDeploymentProvider(deploymentProvider),
	);

export const transformMarketplaceSwagResponse = (
	response: ResponseData,
): EmbeddedMarketplaceDetails | undefined =>
	response.data.developmentInformation.details.embeddedMarketplace;

/**
 * Visible for testing
 */
export const transformResult = (result: ResponseData): Details => {
	const { featureFlagProviders, remoteLinksByType } = result.data.developmentInformation.details;
	return {
		instanceTypeDetails: transformInstanceTypes(result),
		deploymentProviders: transformDeploymentProviders(result),
		featureFlags: featureFlagProviders ? transformFeatureFlags(featureFlagProviders) : {},
		remoteLinkInformation: remoteLinksByType
			? transformRemoteLinks(remoteLinksByType)
			: { numProviders: 0, remoteLinksByType: {} },
		embeddedMarketplaceDetails: transformMarketplaceSwagResponse(result),
	};
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default async (baseUrl: string, issueId: string): Promise<Details> => {
	const result: ResponseData = await getDetails(baseUrl, issueId);
	if (result.errors && result.errors.length) {
		const errorMessages = result.errors
			.map((error) => error.message)
			.reduce((acc, current) => `${acc}\n ${current}`, '');
		throw Error(`Error retrieving details from service: ${errorMessages}`);
	}
	try {
		return transformResult(result);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		throw Error(`Error creating details, error: ${error}`);
	}
};

export const getDeploymentDataDetected = (projectAri: Ari, updatedFrom: string): Promise<boolean> =>
	fetchQuery(
		getRelayEnvironment(),
		graphql`
			query detailsDeploymentDataDetectedQuery($projectAri: ID!, $updatedFrom: DateTime) {
				devOps @required(action: THROW) {
					ariGraph {
						deploymentRelationships: relationships(
							filter: {
								from: $projectAri
								type: "project-associated-deployment"
								updatedFrom: $updatedFrom
							}
							first: 1
						) {
							nodes {
								__typename
							}
						}
					}
				}
			}
		`,
		{
			projectAri,
			updatedFrom,
		},
		{
			fetchPolicy: 'store-or-network',
		},
	)
		.toPromise()
		.then((result) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const data = result as detailsDeploymentDataDetectedQuery$data;
			return (data?.devOps?.ariGraph?.deploymentRelationships?.nodes ?? []).length > 0;
		});

export const getWorkSuggestionsData = (
	cloudId: string,
	issueId: string,
): Promise<WorkSuggestions> => {
	const prSuggestionsTaskTypes = randomizeDevinfoDetailsDialogPrSuggestions();
	const isPrMergeable =
		prSuggestionsTaskTypes === DEVINFO_DIALOG_PR_SUGGESTIONS_TASK_TYPES.PR_MERGEABLE;
	const isPrReviewable = !isPrMergeable;

	return fetchQuery(
		getRelayEnvironment(),
		graphql`
			query detailsWorkSuggestionsDataQuery(
				$cloudId: ID!
				$issueId: ID!
				$isPrMergeable: Boolean!
				$isPrReviewable: Boolean!
			) {
				workSuggestions @optIn(to: "WorkSuggestions") {
					suggestionsByIssues(cloudId: $cloudId, issueIds: [$issueId])
						@optIn(to: "WorkSuggestionsByIssues") {
						recentPullRequests @optIn(to: "WorkSuggestionsRecentPullRequestsSuggestions") {
							pullRequestReviewSuggestions
								@optIn(to: "WorkSuggestionsPullRequestReviewTask")
								@include(if: $isPrReviewable) {
								id
								url
							}
							mergeableSuggestions @include(if: $isPrMergeable) {
								id
								url
								isMergeActionEnabled
							}
						}
					}
				}
			}
		`,
		{
			cloudId,
			issueId,
			isPrMergeable,
			isPrReviewable,
		},
		{
			fetchPolicy: 'network-only',
		},
	)
		.toPromise()
		.then((result: unknown) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const typedResult = result as detailsWorkSuggestionsDataQuery$data;
			const mergeableSuggestions =
				typedResult?.workSuggestions?.suggestionsByIssues?.recentPullRequests?.mergeableSuggestions;
			const pullRequestReviewSuggestions =
				typedResult?.workSuggestions?.suggestionsByIssues?.recentPullRequests
					?.pullRequestReviewSuggestions;
			return {
				pullRequestReviewSuggestions,
				mergeableSuggestions: mergeableSuggestions
					?.filter((suggestion) => suggestion.isMergeActionEnabled)
					.map((suggestion) => ({
						id: suggestion.id,
						url: suggestion.url,
					})),
			};
		});
};
