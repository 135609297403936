import React, { createContext, useState, useCallback, useEffect, useMemo, useContext } from 'react';
import noop from 'lodash/noop';

import {
	MAX_ROW_SIZE,
	DEFAULT_PAGE_SIZE,
	MAX_COLUMN_SIZE,
	MIN_COLUMN_SIZE,
	DEFAULT_COLUMN_SIZE,
} from '../constants.tsx';

export type GridContextType = {
	currentPage: number;
	startIndex: number;
	colSize: number;
	gridSize: number;
	updateColSize: (col: number) => void;
	updatePageNumber: (page: number) => void;
	updateTotalItems: (total: number) => void;
	totalPages: number[];
	showPagination: boolean;
};

const GridContext = createContext<GridContextType>({
	currentPage: 1,
	startIndex: 0,
	colSize: DEFAULT_COLUMN_SIZE,
	gridSize: DEFAULT_PAGE_SIZE,
	updateColSize: noop,
	updatePageNumber: noop,
	updateTotalItems: noop,
	totalPages: [],
	showPagination: false,
});

const GridProvider = ({ children }: { children: React.ReactNode }) => {
	const [startIndex, setStartIndex] = useState(0);
	const [currentPage, setCurrentPage] = useState(0);
	const [colSize, setColSize] = useState(DEFAULT_COLUMN_SIZE);
	const [gridSize, setGridSize] = useState(DEFAULT_PAGE_SIZE);
	const [totalItems, setTotalItems] = useState(DEFAULT_PAGE_SIZE);

	const updateColSize = useCallback((col: number) => {
		let columns;

		if (col > MAX_COLUMN_SIZE) {
			columns = MAX_COLUMN_SIZE;
		} else if (col < MIN_COLUMN_SIZE) {
			columns = 1; // MIN_COLUMN_SIZE;
		} else {
			columns = col;
		}

		setColSize(columns);
	}, []);

	const updatePageNumber = useCallback(
		(page: number) => {
			setCurrentPage(page);

			const newStartIndex = gridSize * (page - 1);
			setStartIndex(newStartIndex);
		},
		[gridSize, setStartIndex],
	);

	const updateTotalItems = useCallback((total: number) => {
		setTotalItems(total);
	}, []);

	// Update grid size when columns change
	useEffect(() => {
		const newGridSize = colSize * MAX_ROW_SIZE;
		setGridSize(newGridSize);
	}, [colSize]);

	// Update current page when startIndex or gridSize changes
	useEffect(() => {
		/**
		 * This is to handle following case
		 *
		 * User is on page 2 with total 13 items and grid size 9. User will see 4 items on 2nd page.
		 * Now user changes grid size to 15, user should see all the attachments on 1st page.
		 * But since user is on page 2, startIndex will be 9 and user will see 4 items.
		 */
		if (gridSize >= totalItems) {
			updatePageNumber(1);
			return;
		}
		setCurrentPage(Math.floor(startIndex / gridSize + 1));
	}, [startIndex, gridSize, totalItems, updatePageNumber]);

	const totalPages = useMemo(() => {
		const length = Math.ceil(totalItems / gridSize) || 1;
		return Array.from({ length }, (_, index) => index + 1);
	}, [totalItems, gridSize]);

	const showPagination = useMemo(() => {
		return totalItems > gridSize;
	}, [totalItems, gridSize]);

	return (
		<GridContext.Provider
			value={{
				startIndex,
				currentPage,
				colSize,
				gridSize,
				updateColSize,
				updatePageNumber,
				updateTotalItems,
				totalPages,
				showPagination,
			}}
		>
			{children}
		</GridContext.Provider>
	);
};

const useGridContext = (): GridContextType => {
	const context = useContext(GridContext);
	return context;
};

export { useGridContext, GridProvider };
