// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook, createStore, createContainer } from '@atlassian/react-sweet-state';
import * as actions from './actions/index.tsx';
import type { State } from './types.tsx';

export type Actions = typeof actions;

export const store = createStore<State, Actions>({
	name: 'issue-view-layout-forge-apps-service',
	initialState: {
		issueGlance: [],
		issueContext: [],
		issueAction: [],
		issuePanel: [],
		issueActivity: [],
		customField: [],
		issueViewBackgroundScript: [],
		customFieldType: [],
		activeAction: null,
		uiModifications: [],
		isForgeLoadingFailed: false,
		isForgeDataComplete: false,
		blockedExtensions: {
			issueGlance: [],
			issueContext: [],
			issueAction: [],
			issuePanel: [],
			issueActivity: [],
			customField: [],
			issueViewBackgroundScript: [],
			customFieldType: [],
			uiModifications: [],
		},
		// Not rate limited
		forgeRateLimitedUntil: 0,
		failedEventsCount: 0,
	},
	actions,
});

export const useForgeStore = createHook(store);

export const useForgeActionsStore = createHook(store, {
	selector: null,
});

export const ForceStoreContainer = createContainer(store);
