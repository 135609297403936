import React from 'react';
import type { Dispatch } from 'redux';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { useAnalyticsEvents, type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Placeholder from '@atlaskit/react-ufo/placeholder';
import type { CommentVisibility } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/comments.tsx';
import type { User } from '@atlassian/jira-issue-shared-types/src/common/types/user-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import {
	replyCommentUpdate,
	replyCommentCancel,
	saveCommentRequest,
} from '@atlassian/jira-issue-view-store/src/actions/comment-actions.tsx';
import { loggedInUserDetailsSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import { NEW_REPLY_COMMENT_ID_PREFIX } from '@atlassian/jira-issue-view-store/src/selectors/comment-constants.tsx';
import {
	isReplyingToCommentSelector,
	replyContentSelector,
	commentEditingOrViewVisibilitySelector,
	replyingToAuthorSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/comment-selector.tsx';
import { isOnlyWhitespaceAdf } from '@atlassian/jira-rich-content/src/common/adf-parsing-utils.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import ReplyCommentWrapper from '../../comment-reply-wrapper/index.tsx';

type ReplySaveData = {
	authorId: AccountId | null;
	bodyAdf: ADF;
	isNewComment: boolean;
	visibility: CommentVisibility;
};

export type CommentEditorProps = {
	fullIssueUrl: string | null;
	commentId: string;
	commentSessionId?: string | null;
};

type CommentRepliesProps = CommentEditorProps & {
	loggedInUser: User | null;
	replyContent?: ADF;
	replyVisibility: CommentVisibility;
	isReplyingToComment?: boolean;
	replyingToAuthor?: User;
	isRealTimeCommentUpdated?: boolean;
	onReplyUpdate?: (arg1: ADF) => void;
	onReplyCancel?: () => void;
	onReplySave?: (
		arg1: ReplySaveData,
		arg2: UIAnalyticsEvent,
		commentSessionId?: string | null,
	) => void;
	onReplyCommentVisibilityChange?: (arg1: CommentVisibility) => void;
};

const LazyReplyEditor = lazyForPaint(() =>
	import(/* webpackChunkName: "async-reply-comment-editor" */ '../../comment-reply-editor').then(
		(module) => module.CommentReplyEditor,
	),
);

const CommentReplies = (props: CommentRepliesProps) => {
	const {
		commentId,
		loggedInUser,
		replyContent,
		replyVisibility,
		isReplyingToComment,
		replyingToAuthor,
		onReplyUpdate,
		onReplyCancel,
		onReplySave,
		commentSessionId,
		isRealTimeCommentUpdated,
	} = props;

	const { createAnalyticsEvent } = useAnalyticsEvents();

	if (!isReplyingToComment) return null;

	const onReplySaveHandler = (value: ADF): void => {
		if (!onReplySave) {
			return;
		}
		const isValueEmpty = isOnlyWhitespaceAdf(value);
		if (!isValueEmpty) {
			const authorId = loggedInUser ? loggedInUser.id : null;
			const analyticsEvent = createAnalyticsEvent({
				action: 'created',
				isReply: true,
			});

			if (fg('thor_send_realtime_attribute')) {
				analyticsEvent.update({
					isRealTimeCommentUpdated,
				});
			}

			onReplySave(
				{
					authorId,
					bodyAdf: value,
					isNewComment: true,
					visibility: replyVisibility,
				},
				analyticsEvent,
				commentSessionId,
			);
		}
	};

	return (
		<>
			{isReplyingToComment && (
				// reply editor always open at end so isLast is true
				<ReplyCommentWrapper hasReplyEditor isLast>
					<Placeholder name="reply-comment-editor-wrapper">
						<LazyReplyEditor
							parentCommentId={commentId}
							author={loggedInUser}
							replyToAuthorName={replyingToAuthor?.displayName}
							bodyAdf={replyContent}
							onChange={onReplyUpdate}
							onCancel={onReplyCancel}
							onSave={onReplySaveHandler}
							onBlur={onReplyCancel}
						/>
					</Placeholder>
				</ReplyCommentWrapper>
			)}
		</>
	);
};

export default connect(
	(_) => (state: State, ownProps: CommentEditorProps) => ({
		isReplyingToComment: isReplyingToCommentSelector(ownProps.commentId)(state),
		loggedInUser: loggedInUserDetailsSelector(state),
		replyContent: replyContentSelector(ownProps.commentId)(state),
		replyVisibility: commentEditingOrViewVisibilitySelector(
			`${NEW_REPLY_COMMENT_ID_PREFIX}-${ownProps.commentId}`,
		)(state),
		replyingToAuthor: replyingToAuthorSelector(state),
	}),
	(dispatch: Dispatch, ownProps: CommentEditorProps) => ({
		onReplyUpdate: (editValue: ADF) => dispatch(replyCommentUpdate(ownProps.commentId, editValue)),
		onReplyCancel: () => dispatch(replyCommentCancel(ownProps.commentId)),
		onReplySave: (
			eventData: ReplySaveData,
			analyticsEvent: UIAnalyticsEvent,
			commentSessionId?: string | null,
		) =>
			dispatch(
				saveCommentRequest(
					{
						id: `${NEW_REPLY_COMMENT_ID_PREFIX}-${ownProps.commentId}-${new Date().getTime()}`, // needed to support multiple optimistic replies at the same time
						parentId: ownProps.commentId,
						createdDate: new Date().toISOString(),
						bodyHtml: undefined,
						...eventData,
					},
					analyticsEvent,
					commentSessionId,
				),
			),
	}),
)(CommentReplies);
