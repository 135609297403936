import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { getLocalIssueIdToJiraId } from '../../../selectors/issue-ids.tsx';
import { createIsIssueHavingUnseenInsights } from '../../../selectors/properties/insights/index.tsx';
import { createGetProject } from '../../../selectors/properties/project/index.tsx';
import type { Props, State } from '../../../types.tsx';

export const setTimestampIssueInsightsSeen =
	(localIssueId: LocalIssueId, timestampCurrentUserSeenInsights: number): Action<State, Props> =>
	async ({ getState, setState }, props) => {
		const state = getState();
		const isIssueHavingUnseenInsights = createIsIssueHavingUnseenInsights(localIssueId)(
			state,
			props,
		);

		if (!isIssueHavingUnseenInsights) {
			return;
		}

		setState({
			properties: {
				...state.properties,
				insightsMetadata: {
					...state.properties.insightsMetadata,
					[localIssueId]: { timestampCurrentUserSeenInsights },
				},
			},
		});

		const { issuesRemote } = props;
		const issueId = getLocalIssueIdToJiraId(state, props)[localIssueId];
		const projectId = props.projectId || createGetProject(localIssueId)(state)?.id;

		if (!projectId) {
			throw new Error(
				'IssueController action setTimestampIssueInsightsSeen: projectId is undefined',
			);
		}

		if (issueId !== undefined) {
			issuesRemote.updateLastInsightsViewedTimestamp({
				issueId,
				timestamp: new Date(timestampCurrentUserSeenInsights).toISOString(),
				projectId,
			});
		}
	};
