import intersection from 'lodash/intersection';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import type { State, Props } from '../../types.tsx';
import { getConnectionFieldMappings } from '../../selectors/fields.tsx';
import { fetchIssuesWithPagination } from '../load-issues/index.tsx';
import { getLocalIssueIdToJiraId } from '../../selectors/issue-ids.tsx';

// This action is used to reset the connection fields values for the given localIssueIds
// It removes the connnections values that needs to be refetched after with refreshConnectionFieldsValues
// In case localIssueIdsToReset is not provided we reset connection fields to empty values for all issues
export const resetConnectionFieldsValues =
	(connectionFieldKeys: FieldKey[], localIssueIdsToReset: string[] = []): Action<State, Props> =>
	async ({ getState, setState }, props) => {
		if (connectionFieldKeys.length === 0) {
			return;
		}

		const state = getState();

		const allConnectionFieldsMappings = getConnectionFieldMappings(state, props);
		const connectionFieldMappings = connectionFieldKeys
			.map((fieldKey) => allConnectionFieldsMappings[fieldKey])
			.filter(Boolean);

		let newProperties = { ...state.properties };

		const localIssueIdToJiraId = getLocalIssueIdToJiraId(state, props);
		const jiraIssueIdsToReset = localIssueIdsToReset.map(
			(localId) => localIssueIdToJiraId[localId],
		);

		state.ids.forEach((localIssueId) => {
			connectionFieldMappings.forEach((fieldMapping) => {
				// In case localIssueIdsToReset is not provided we reset all the connections fields to empty values
				if (localIssueIdsToReset.length === 0) {
					newProperties = fieldMapping.setImmutable(newProperties, localIssueId, []);
					return;
				}

				// In case localIssueIdsToReset is provided we remove only corresponding ids from the connection fields
				const currentValue = fieldMapping.valueAccessor(state, props, localIssueId) || [];
				const currentValueIds = currentValue.map(({ id }) => id);
				const changedIds = intersection(jiraIssueIdsToReset, currentValueIds);

				if (changedIds.length > 0) {
					newProperties = fieldMapping.modifyImmutableIfMultiValueField(
						newProperties,
						localIssueId,
						undefined,
						changedIds.map((id) => ({ id })),
					);
				}
			});
		});

		setState({ properties: newProperties });
	};

export const refreshConnectionFieldsValues =
	(fieldKeys: FieldKey[]): Action<State, Props> =>
	async ({ getState, setState }, props) => {
		if (fieldKeys.length === 0) {
			return;
		}

		const state = getState();
		const { issuesRemote } = props;
		let newProperties = { ...state.properties };

		const allConnectionFieldsMappings = getConnectionFieldMappings(state, props);
		const connectionFieldMappings = fieldKeys
			.map((fieldKey) => allConnectionFieldsMappings[fieldKey])
			.filter(Boolean);

		try {
			const { issues } = await fetchIssuesWithPagination(issuesRemote, {
				archivedFilter: 'ACTIVE_ONLY',
				fields: fieldKeys,
			});

			const localIssueIdToJiraId = getLocalIssueIdToJiraId(state, props);

			connectionFieldMappings.forEach((fieldMapping) => {
				state.ids.forEach((localIssueId) => {
					const jiraIssueId = localIssueIdToJiraId[localIssueId];
					const issue = issues.find(({ id }) => id === jiraIssueId);
					if (!issue) {
						return;
					}

					const fieldValue = fieldMapping.getValueFromJiraIssue(issue);
					newProperties = fieldMapping.setImmutable(newProperties, localIssueId, fieldValue);
				});
			});

			setState({ properties: newProperties });
		} catch (error) {
			if (error instanceof Error) {
				logSafeErrorWithoutCustomerDataWrapper(
					'polaris.issue.action.refreshConnectionFieldsValues',
					'Failed to refresh connection fields values',
					error,
				);
			}
		}
	};
