import mapValues from 'lodash/mapValues';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createActionsHook,
	createContainer,
	createHook,
	createStore,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';

type Actions = {
	/**
	 * Sets the editing state of the given fieldId
	 * @param fieldId
	 * @param isEditing
	 */
	setFieldEditingState: (
		fieldId: string,
		isEditing: boolean,
	) => (state: StoreActionApi<Record<string, boolean>>) => void;
	/**
	 * Simple flips the editing state value of the given fieldId and returns it
	 * @param fieldId
	 */
	toggleFieldEditingState: (
		fieldId: string,
		// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	) => (state: StoreActionApi<Record<string, boolean>>) => boolean | void;
	/**
	 * Gets the editing state value of the given fieldId
	 * @param fieldId
	 */
	getFieldEditingState: (
		fieldId: string,
	) => (state: StoreActionApi<Record<string, boolean>>) => boolean;
	/**
	 * FOR TESTING ONLY
	 * Sets the editing state value of every fieldId in the store to be false
	 */
	resetFieldEditingStateStore: () => (state: StoreActionApi<Record<string, boolean>>) => void;
};

const Store = createStore<Record<string, boolean>, Actions>({
	initialState: {},
	actions: {
		setFieldEditingState:
			(fieldId: string, isEditing: boolean) =>
			({ setState }) => {
				setState({ [fieldId]: isEditing });
			},
		toggleFieldEditingState:
			(fieldId: string) =>
			({ setState, getState }) => {
				if (getState()[fieldId] == null) {
					return undefined;
				}
				const toggledValue = !getState()[fieldId];
				setState({ [fieldId]: toggledValue });
				return toggledValue;
			},
		getFieldEditingState:
			(fieldId: string) =>
			({ getState }) =>
				getState()[fieldId],
		resetFieldEditingStateStore:
			() =>
			({ setState, getState }) => {
				setState(mapValues(getState(), () => false));
			},
	},
	name: 'FieldEditingState',
});

export const useFieldIsEditing = createHook(Store, {
	selector: (state: Record<string, boolean>, arg: string): boolean =>
		state[arg] != null ? state[arg] : false,
});
export const useFieldIsEditingActions = createActionsHook(Store);
export const FieldEditingContainer = createContainer(Store);
