import React, { useCallback, useMemo } from 'react';
import { useThemeObserver } from '@atlaskit/tokens';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import type { ConfluenceWhiteboard } from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import type { FailedRemoteLink } from '@atlassian/jira-issue-shared-types/src/common/types/remote-link-error-type.tsx';
import { embeddedWhiteboardModalEntrypoint } from '../../ui/embedded-whiteboard-modal/entrypoint.tsx';
import { useEmbeddedPageTracking } from '../use-embedded-page-tracking/index.tsx';
import { useParentProduct } from '../use-parent-product/index.tsx';
import { getSpaceKey } from '../utils/getSpaceKey.tsx';

const entryPointParams = {};
export const useEmbeddedWhiteboardModalEntryPoint = (
	whiteboard?: ConfluenceWhiteboard | FailedRemoteLink,
	embeddedConfluenceSource?: string,
) => {
	const parentProduct = useParentProduct();
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const isWhiteboardError = !whiteboard || (whiteboard as FailedRemoteLink).error;

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		embeddedWhiteboardModalEntrypoint,
		entryPointParams,
	);

	const fireAnalyticsEvent = useEmbeddedPageTracking();
	const themeState = useThemeObserver();

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const confluenceWhiteboard = whiteboard as ConfluenceWhiteboard;

	const hostname = confluenceWhiteboard?.hostname ?? '';

	const spaceKey = getSpaceKey(confluenceWhiteboard?.href) ?? '';

	const onEmbeddedContentAction = useCallback(
		(action: string, eventName: string) => {
			fireAnalyticsEvent({
				action,
				eventName,
				embeddedConfluenceSource,
			});
		},
		[fireAnalyticsEvent, embeddedConfluenceSource],
	);

	const onClose = useCallback(() => {
		fireAnalyticsEvent({
			action: 'clicked',
			eventName: 'closeLinkedConfluenceWhiteboard',
			embeddedConfluenceSource,
		});
		entryPointActions.unload();
	}, [fireAnalyticsEvent, entryPointActions, embeddedConfluenceSource]);

	const runtimeProps = useMemo(
		() => ({
			parentProduct,
			hostname,
			themeState,
			embeddedConfluenceSource,
			spaceKey,
			onClose,
			onEmbeddedContentAction,
			isOpen: true,
			modalTitle: confluenceWhiteboard?.title ?? '',
			url: confluenceWhiteboard?.href,
			contentId: confluenceWhiteboard?.whiteboardId,
		}),
		[
			parentProduct,
			hostname,
			themeState,
			embeddedConfluenceSource,
			spaceKey,
			onClose,
			onEmbeddedContentAction,
			confluenceWhiteboard?.title,
			confluenceWhiteboard?.href,
			confluenceWhiteboard?.whiteboardId,
		],
	);

	return !isWhiteboardError
		? {
				entryPointActions,
				entryPoint: (
					<JiraEntryPointContainer
						entryPointReferenceSubject={entryPointReferenceSubject}
						id="embeddedWhiteboardModalEntrypointContainer"
						packageName="@atlassian/jira-issue-create-confluence-content"
						teamName="confluence-better-together"
						runtimeProps={runtimeProps}
					/>
				),
			}
		: { entryPointActions: undefined, entryPoint: null };
};
