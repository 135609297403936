import { getTenantContext_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-common-util-get-tenant-context/src/index.tsx';

export const createCacheKey = (endpoint: string): string => {
	const { atlassianAccountId, cloudId } = getTenantContext_DEPRECATED_DO_NOT_USE();

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const url = new URL(endpoint, window.location.origin);
	const searchParams = new URLSearchParams(url.search);
	const sortedParams = new URLSearchParams([...searchParams.entries()].sort());
	url.search = sortedParams.toString();
	const prefix = (atlassianAccountId + cloudId).replace(/-/g, '');
	const endpointWithSearch = prefix + url.pathname + url.search;
	return endpointWithSearch.replace(/[/?&=]/g, '-').substring(1);
};
