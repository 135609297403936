import noop from 'lodash/noop';
import type { StoreApi, OpenExportExcelModalProps } from './types.tsx';

export const ExportExcelModalActions = {
	openExportExcelModal:
		({ issueKey, issueId, onExportExcelSuccess }: OpenExportExcelModalProps) =>
		({ setState }: StoreApi) => {
			setState({
				isExportExcelModalOpen: true,
				issueKey,
				issueId,
				onExportExcelSuccess,
				onCloseIssueViewModal: noop,
			});
		},
	closeExportExcelModal:
		() =>
		({ setState }: StoreApi) => {
			setState({
				isExportExcelModalOpen: false,
				issueKey: null,
				issueId: null,
				onExportExcelSuccess: null,
				onCloseIssueViewModal: null,
			});
		},
};

export type Actions = typeof ExportExcelModalActions;
