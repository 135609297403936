import type { MediaUploadPermissionResponse } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-media-upload-permissions/index.tsx';
import type { UserAuthResponse } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-media-user-auth/index.tsx';
import type { IssueAggData } from '@atlassian/jira-issue-fetch-services/src/services/issue-agg-data/index.tsx';
import type { GiraNonCriticalGraphQlResponse } from '@atlassian/jira-issue-fetch-services/src/services/issue-gira-non-critical-data/types.tsx';
import type { ForgeResponse } from '@atlassian/jira-issue-fetch-services/src/types.tsx';
import type { GraphQlResponse as GiraResponse } from '@atlassian/jira-issue-view-common-types/src/gira.tsx';
import type { ViewResponse } from '@atlassian/jira-issue-view-common-types/src/view-context-type.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createContainer, createStateHook, createStore } from '@atlassian/react-sweet-state';

export type AllData = {
	mediaUserAuth?: UserAuthResponse | null;
	mediaReadPermission?: ViewResponse | null;
	mediaUploadPermission?: MediaUploadPermissionResponse | null;
	issueGiraData?: GiraResponse | null;
	issueAggData?: IssueAggData | null;
};

export type AllPromises = {
	mediaUserAuthPromise: Promise<UserAuthResponse> | null;
	mediaReadPermissionPromise: Promise<ViewResponse> | null;
	mediaUploadPermissionPromise: Promise<MediaUploadPermissionResponse> | null;
	issueGiraDataPromise: Promise<GiraResponse> | null;
	issueAggDataPromise: Promise<IssueAggData> | null;
};

class PrefetchedResourceManager {
	issueMediaUserAuth: Promise<UserAuthResponse> | null = null;

	issueMediaReadPermission: Promise<ViewResponse> | null = null;

	issueMediaUploadPermission: Promise<MediaUploadPermissionResponse> | null = null;

	issueGiraData: Promise<GiraResponse> | null = null;

	issueAggData: Promise<IssueAggData> | null = null;

	issueForgeData?: Promise<ForgeResponse> | null = null; // Delete with cleanup of move_forge_data_fetch_to_main_issue_agg

	issueGiraNonCriticalData: Promise<GiraNonCriticalGraphQlResponse> | null = null;

	allData: AllData = {};

	issueKey: string | undefined;

	setAllData(data: AllData) {
		this.allData = data;
	}

	setAllPromises(promises: AllPromises) {
		this.issueMediaUserAuth = promises.mediaUserAuthPromise;
		this.issueMediaUploadPermission = promises.mediaUploadPermissionPromise;
		this.issueMediaReadPermission = promises.mediaReadPermissionPromise;
		this.issueGiraData = promises.issueGiraDataPromise;
		this.issueAggData = promises.issueAggDataPromise;
	}

	setGiraPromise(promise: Promise<GiraResponse> | null) {
		this.issueGiraData = promise;
	}

	setAggPromise(promise: Promise<IssueAggData> | null) {
		this.issueAggData = promise;
	}

	setAggData(data: IssueAggData | null) {
		this.allData.issueAggData = data;
	}

	/**
	 * Delete with cleanup of move_forge_data_fetch_to_main_issue_agg
	 * forge data is now fetched in the main issue agg query
	 * @deprecated
	 */
	setForgePromise(promise: Promise<ForgeResponse>) {
		this.issueForgeData = promise;
	}

	setGiraNonCriticalPromise(promise: Promise<GiraNonCriticalGraphQlResponse>) {
		this.issueGiraNonCriticalData = promise;
	}

	getAllPromises() {
		return [
			this.issueMediaUserAuth,
			this.issueMediaUploadPermission,
			this.issueMediaReadPermission,
			this.issueGiraData,
			this.issueAggData,
		];
	}

	getGiraData = () => this.allData.issueGiraData && this.allData.issueGiraData.data;

	getGiraErrors = () => this.allData.issueGiraData?.errors;

	getAggData = () => this.allData.issueAggData && this.allData.issueAggData.data;

	getAggErrors = () => this.allData.issueAggData?.errors;

	getMediaUser = () => this.allData.mediaUserAuth;

	getMediaReadPermission = () => this.allData.mediaReadPermission;

	getMediaUploadPermission = () => this.allData.mediaUploadPermission;

	// Helper
	isAllDataReady = (): boolean => Boolean(this.getGiraData() && this.getAggData());

	containsInitialPromises = (): boolean =>
		Boolean(
			this.issueMediaUploadPermission &&
				this.issueMediaUserAuth &&
				this.issueMediaReadPermission &&
				this.issueGiraData &&
				this.issueAggData,
		);

	clearAll() {
		this.allData = {};
		this.issueMediaReadPermission = null;
		this.issueMediaUploadPermission = null;
		this.issueMediaUserAuth = null;
		this.issueGiraData = null;
		this.issueForgeData = null;
		this.issueAggData = null;
	}

	clearGiraNonCriticalData() {
		this.issueGiraNonCriticalData = null;
	}
}

export type { PrefetchedResourceManager as ResourceManager };

export default PrefetchedResourceManager;

/**
 * Container for {@link useSelfClearingPrefetchedResourceManager}. One must wrap each issue app
 */
export const PrefetchedResourceStoreContainer = createContainer();
const PrefetchedResourceManagerStore = createStore<
	{ prefetchedResourceManager: PrefetchedResourceManager },
	{}
>({
	name: 'issue-view-prefetched-resource-manager',
	containedBy: PrefetchedResourceStoreContainer,
	initialState: {
		prefetchedResourceManager: new PrefetchedResourceManager(),
	},
	actions: {},
	handlers: {
		onInit:
			() =>
			({ setState }) => {
				setState({
					prefetchedResourceManager: new PrefetchedResourceManager(),
				});
			},
	},
});
const usePrefetchedResourceStore = createStateHook(PrefetchedResourceManagerStore, {
	selector: ({ prefetchedResourceManager }) => prefetchedResourceManager,
});

/**
 * Returns an instance of PrefetchedResourceManager which will reset itself
 * during the render phase whenever the given `issueKey` prop updates
 * It's critical to perform this side effect in the render phase because lazy-fetching
 * (like with `useLazyLoadedQuery`) of issue-data happens in the render phase.
 * If we reset too late (in a useEffect), then stale data is pulled into the legacy stores
 * which use this (like redux / sweet-state)
 *
 * Also supports executing a side-effect on onIssueKeyChange right after resetting.
 * The side-effect only executes once (even if the render aborts for eg due to suspense).
 * This can be used for performing fetches for the prefetchedResourceManager asap
 *
 * Only ONE of these should be present per-issue-view app
 */
export function useSelfClearingPrefetchedResourceManager(
	issueKey: string,
	onIssueKeyChange?: (props: {
		issueKey: string;
		prefetchedResourceManager: PrefetchedResourceManager;
	}) => void,
): PrefetchedResourceManager {
	const prefetchedResourceManager = usePrefetchedResourceStore();

	// By inspecting and mutating an object stored in sweet state instead of `useState` or `useRef`
	// we avoid losing state when a render aborts (eg from suspense).
	// Which allows us to guarantee that onIssueKeyChange only runs once when the issue-key updates
	// even though we're running in the render phase instead of a useEffect
	if (issueKey !== prefetchedResourceManager.issueKey) {
		prefetchedResourceManager.issueKey = issueKey;
		prefetchedResourceManager.clearAll();
		onIssueKeyChange?.({ issueKey, prefetchedResourceManager });
	}
	return prefetchedResourceManager;
}
