import { type RefObject, useEffect } from 'react';
import { useIsAiEnabledForIssue } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIsPremium } from '@atlassian/jira-platform-react-hooks-use-ai-opt-in/src/index.tsx';
import { useRovoEntitlementResource } from '@atlassian/jira-router-resources-rovo-entitlement/src/services/index.tsx';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createActionsHook,
	createStateHook,
	createContainer,
} from '@atlassian/react-sweet-state';
import { useUserHasConfluenceAccess } from '../use-has-confluence-access/index.tsx';
import { actions, type Actions } from './actions.tsx';
import type { State } from './types.tsx';

const Store = createStore<State, Actions>({
	initialState: {
		containerRef: { current: null },
		activePopup: undefined,
		selectedText: undefined,
		isAutohighlighted: false,
		targetRef: undefined,
		keyPhraseCategory: undefined,
		fieldType: undefined,
	},
	actions,
	name: 'highlight-actions-store',
});

export const HighlightActionsContainer = createContainer(Store);

const getTriggerPosition = (state: State) => state.triggerPosition;
export const useTriggerPosition = createStateHook(Store, {
	selector: getTriggerPosition,
});

const getSelectedText = (state: State) => state.selectedText;
export const useSelectedText = createStateHook(Store, {
	selector: getSelectedText,
});

export const useHighlightActions = createActionsHook(Store);

export const useHighlightActionsState = createStateHook(Store);

const getActivePopup = (state: State) => state.activePopup;
export const useActivePopup = createStateHook(Store, {
	selector: getActivePopup,
});

const getTargetHighlightRef = (state: State) => state.targetRef;
export const useTargetHighlightRef = createStateHook(Store, {
	selector: getTargetHighlightRef,
});

const getIsAutohighlighted = (state: State) => state.isAutohighlighted;
export const useIsAutohighlighted = createStateHook(Store, {
	selector: getIsAutohighlighted,
});

const getKeyPhraseCategory = (state: State) => state.keyPhraseCategory;
export const useKeyPhraseCategory = createStateHook(Store, {
	selector: getKeyPhraseCategory,
});

export const useResetTargetHighlight = () => {
	const { resetTargetRef } = useHighlightActions();

	return {
		resetTargetHighlight: resetTargetRef,
	};
};

export const useSetHighlightContainerRef = (ref: RefObject<HTMLDivElement | null>) => {
	const { setContainerRef } = useHighlightActions();
	useEffect(() => {
		setContainerRef(ref);
	}, [ref, setContainerRef]);
};

const getAutoHighlightClickedFieldType = (state: State) => state.fieldType;
export const useAutoHighlightClickedFieldType = createStateHook(Store, {
	selector: getAutoHighlightClickedFieldType,
});

export const useIsHighlightEnabled = (): { highlightEnabled: boolean } => {
	const rovoEntitlement = useRovoEntitlementResource();
	const hasConfluenceAccess = useUserHasConfluenceAccess().hasAccess;
	const isAnonymous = useIsAnonymous();

	const isPremium = useIsPremium();
	const isAiOptInEnabled = useIsAiEnabledForIssue();
	const isAIFeatureEnabled = isPremium && isAiOptInEnabled;

	const isRovoLLMEnabled = rovoEntitlement?.data?.isRovoLLMEnabled ?? false;

	return {
		highlightEnabled:
			// this is equal to
			// hasConfluenceAccess && !isAnonymous && (isAIFeatureEnabled || isRovo1.0Enabled || (isRovo1.1Enabled && isAIFeatureEnabled))
			hasConfluenceAccess && !isAnonymous && (isAIFeatureEnabled || isRovoLLMEnabled),
	};
};
