/**
 * @generated SignedSource<<56606b230984fa4c3279d54322e7d09c>>
 * @relayHash 861223c3cd6bbab7f071dc849fc3d20e
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2744bc46a837749020911a4978039428b005001f5d22e846b3e1cb93ebbcde48

import type { ConcreteRequest, Query } from 'relay-runtime';
export type commentFetchAggServerChildCommentsQuery$variables = {
  after?: string | null | undefined;
  before?: string | null | undefined;
  commentAri: string;
  first?: number | null | undefined;
  last?: number | null | undefined;
};
export type commentFetchAggServerChildCommentsQuery$data = {
  readonly node: {
    readonly childComments?: {
      readonly edges: ReadonlyArray<{
        readonly cursor: string;
        readonly node: {
          readonly __typename: string;
          readonly author: {
            readonly accountId: string;
            readonly name: string;
            readonly picture: AGG$URL;
          } | null | undefined;
          readonly commentId: string;
          readonly created: AGG$DateTime;
          readonly isDeleted: boolean | null | undefined;
          readonly permissionLevel: {
            readonly group: {
              readonly groupId: string;
              readonly id: string;
              readonly name: string;
            } | null | undefined;
            readonly role: {
              readonly id: string;
              readonly name: string | null | undefined;
            } | null | undefined;
          } | null | undefined;
          readonly richText: {
            readonly adfValue: {
              readonly json: AGG$JSON | null | undefined;
            } | null | undefined;
          } | null | undefined;
          readonly updateAuthor: {
            readonly accountId: string;
            readonly name: string;
            readonly picture: AGG$URL;
          } | null | undefined;
          readonly updated: AGG$DateTime | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly pageInfo: {
        readonly endCursor: string | null | undefined;
        readonly hasNextPage: boolean;
        readonly hasPreviousPage: boolean;
        readonly startCursor: string | null | undefined;
      };
    } | null | undefined;
  } | null | undefined;
};
export type commentFetchAggServerChildCommentsQuery = {
  response: commentFetchAggServerChildCommentsQuery$data;
  variables: commentFetchAggServerChildCommentsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "commentAri"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "commentAri"
  }
],
v6 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  }
],
v7 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v8 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v9 = {
  "kind": "ScalarField",
  "name": "commentId"
},
v10 = {
  "kind": "ScalarField",
  "name": "created"
},
v11 = {
  "kind": "ScalarField",
  "name": "updated"
},
v12 = {
  "kind": "ScalarField",
  "name": "isDeleted"
},
v13 = {
  "kind": "ScalarField",
  "name": "name"
},
v14 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v15 = {
  "kind": "ScalarField",
  "name": "picture"
},
v16 = [
  (v13/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/)
],
v17 = {
  "concreteType": "JiraRichText",
  "kind": "LinkedField",
  "name": "richText",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraADF",
      "kind": "LinkedField",
      "name": "adfValue",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "json"
        }
      ]
    }
  ]
},
v18 = {
  "kind": "ScalarField",
  "name": "id"
},
v19 = {
  "concreteType": "JiraPermissionLevel",
  "kind": "LinkedField",
  "name": "permissionLevel",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraGroup",
      "kind": "LinkedField",
      "name": "group",
      "plural": false,
      "selections": [
        (v18/*: any*/),
        (v13/*: any*/),
        {
          "kind": "ScalarField",
          "name": "groupId"
        }
      ]
    },
    {
      "concreteType": "JiraRole",
      "kind": "LinkedField",
      "name": "role",
      "plural": false,
      "selections": [
        (v18/*: any*/),
        (v13/*: any*/)
      ]
    }
  ]
},
v20 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "hasNextPage"
    },
    {
      "kind": "ScalarField",
      "name": "hasPreviousPage"
    },
    {
      "kind": "ScalarField",
      "name": "startCursor"
    },
    {
      "kind": "ScalarField",
      "name": "endCursor"
    }
  ]
},
v21 = [
  (v8/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/),
  (v18/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "name": "commentFetchAggServerChildCommentsQuery",
    "selections": [
      {
        "args": (v5/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v6/*: any*/),
                "concreteType": "JiraCommentConnection",
                "kind": "LinkedField",
                "name": "childComments",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraCommentEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          {
                            "kind": "LinkedField",
                            "name": "author",
                            "plural": false,
                            "selections": (v16/*: any*/)
                          },
                          {
                            "kind": "LinkedField",
                            "name": "updateAuthor",
                            "plural": false,
                            "selections": (v16/*: any*/)
                          },
                          (v17/*: any*/),
                          (v19/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v20/*: any*/)
                ]
              }
            ],
            "type": "JiraPlatformComment"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "commentFetchAggServerChildCommentsQuery",
    "selections": [
      {
        "args": (v5/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v6/*: any*/),
                "concreteType": "JiraCommentConnection",
                "kind": "LinkedField",
                "name": "childComments",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraCommentEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          {
                            "kind": "LinkedField",
                            "name": "author",
                            "plural": false,
                            "selections": (v21/*: any*/)
                          },
                          {
                            "kind": "LinkedField",
                            "name": "updateAuthor",
                            "plural": false,
                            "selections": (v21/*: any*/)
                          },
                          (v17/*: any*/),
                          (v19/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v18/*: any*/)
                            ],
                            "type": "Node",
                            "abstractKey": "__isNode"
                          }
                        ]
                      }
                    ]
                  },
                  (v20/*: any*/)
                ]
              }
            ],
            "type": "JiraPlatformComment"
          },
          (v18/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "id": "2744bc46a837749020911a4978039428b005001f5d22e846b3e1cb93ebbcde48",
    "metadata": {},
    "name": "commentFetchAggServerChildCommentsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "9500c89b505306a6596799c54e314ddd";

export default node;
