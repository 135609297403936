import { keyframes, styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import type { MountAnimationProps } from '@atlassian/jira-packages-components-animations/src/mount-animator.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ContentContainer = styled.div({
	position: 'relative',
	overflow: 'hidden',
	display: 'flex',
	borderRadius: '3px',
	marginLeft: token('space.negative.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${4 * gridSize}px`,
	width: '100%',
	transition: 'background-color 0.2s ease-in-out, border-color 0.2s ease-in-out',
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.background.neutral.subtle.hovered'),
		cursor: 'pointer',
	},
	'&:focus': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		boxShadow: `0 0 0 2px ${token('color.border.focused')}`,
		outline: 0,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.background.neutral.subtle.pressed'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Icon = styled.div({
	overflow: 'hidden',
	marginTop: token('space.050'),
	marginBottom: token('space.050'),
	marginLeft: token('space.100'),
	height: '24px',
	width: '24px',
	alignItems: 'center',
	paddingLeft: token('space.025'),
	paddingRight: token('space.025'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Content = styled.div({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	flex: 1,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/design-system/use-tokens-typography -- Ignored via go/DSP-18766
	lineHeight: `${gridSize * 4}px`,
	paddingLeft: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StatusContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest'),
	display: 'flex',
	alignItems: 'center',
	marginRight: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RoundButtonContainer = styled.div({
	position: 'absolute',
	right: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 7}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 4}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RoundButton = styled.div({
	position: 'absolute',
	right: 0,
	marginTop: token('space.025'),
	marginRight: token('space.100'),
	marginBottom: token('space.025'),
	marginLeft: token('space.100'),
	height: '26px',
	width: '26px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.neutral.hovered'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.icon.subtle'),
	borderRadius: '50%',
	alignItems: 'center',
	paddingTop: token('space.025'),
	paddingRight: 0,
	paddingBottom: 0,
	paddingLeft: token('space.025'),
});

const slideIn = keyframes({
	'0%': {
		right: '-28px',
	},
	'100%': {
		right: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SlideInAnimation = styled.div<MountAnimationProps>({
	position: 'absolute',
	animation: `${slideIn} 0.15s ease-in-out`,
});
