import React, { type ReactNode } from 'react';
import { Box, Text, xcss, Pressable } from '@atlaskit/primitives';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/show-more-horizontal';
import Link from '@atlaskit/link';
import {
	AdControlDropdownMenu,
	DismissalButton,
	ReasonsButton,
} from '@atlassian/ad-control-toolkit';
import { useIntl } from '@atlassian/jira-intl';
import {
	ContextualAnalyticsData,
	MODAL,
	MountEvent,
	fireScreenAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
	type AnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import messages from './messages.tsx';
import { DATA_PRIVACY_LINK, MENU_LOCATION, QUICK_MENU_TYPE } from './constants.tsx';

type Props = {
	onDismiss: () => Promise<void>;
};

export const AdControls = ({ onDismiss }: Props) => {
	const { formatMessage } = useIntl();
	const isSiteAdmin = useIsSiteAdmin();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const eventAttributes = {
		menuLocation: MENU_LOCATION,
		quickMenuType: QUICK_MENU_TYPE,
		isSiteAdmin,
	};

	const buttonClicked = createAnalyticsEvent({
		action: 'clicked',
		actionSubject: 'button',
	});

	const linkClicked = createAnalyticsEvent({
		action: 'clicked',
		actionSubject: 'link',
	});

	return (
		<AdControlDropdownMenu
			itemId="ad-control-dropdown-ad-controls"
			trigger={({ triggerRef, ...props }) => (
				<Pressable
					{...props}
					xcss={pressableStyles}
					backgroundColor="color.background.neutral.subtle"
					testId="issue-view-foundation.quick-add.ad-control-section.ad-control-dropdown.pressable"
					ref={triggerRef}
					onClick={(e) => {
						if (props.onClick) {
							props.onClick(e);
						}
						fireUIAnalytics(buttonClicked, 'adControlsButton', eventAttributes);
					}}
				>
					<ShowMoreHorizontalIcon label="" />
				</Pressable>
			)}
		>
			{(props) => (
				<Box testId="issue-view-foundation.quick-add.ad-control-section.ad-control-dropdown.box">
					<DismissalButton
						{...props}
						flagProps={{
							title: formatMessage(messages.dismissFlagTitle),
							description: formatMessage(messages.dismissFlagDescription),
						}}
						onClick={() => {
							fireUIAnalytics(buttonClicked, 'notInterestedButton', eventAttributes);
							return onDismiss();
						}}
						testId="issue-view-foundation.quick-add.ad-control-section.ad-control-dropdown.dismissal-button"
					/>
					<ReasonsButton
						{...props}
						testId="issue-view-foundation.quick-add.ad-control-section.ad-control-dropdown.reasons-button"
						onClick={() => {
							fireUIAnalytics(buttonClicked, 'whyAmISeeingThisButton', eventAttributes);
						}}
						modalProps={{
							title: formatMessage(messages.reasonsModalTitle),
							testId:
								'issue-view-foundation.quick-add.ad-control-section.ad-control-dropdown.modal',
							body: (
								<ContextualAnalyticsData sourceName="whyAmISeeingThis" sourceType={MODAL}>
									<MountEvent
										onMount={(analyticsEvent: AnalyticsEvent) => {
											fireScreenAnalytics(analyticsEvent, eventAttributes);
										}}
									/>
									{formatMessage(messages.reasonsModalExplanation, {
										strong: (chunks: ReactNode[]) => <Text weight="bold">{chunks}</Text>,
									})}
									<ul>
										<li>{formatMessage(messages.reasonsModalBulletOne)}</li>
										<li>{formatMessage(messages.reasonsModalBulletTwo)}</li>
									</ul>
									<Box xcss={privacyLinkStyles}>
										<Link
											href={DATA_PRIVACY_LINK}
											target="_blank"
											onClick={() => {
												fireUIAnalytics(linkClicked, 'dataPrivacyLink', eventAttributes);
											}}
										>
											{formatMessage(messages.privacyPolicy)}
										</Link>
									</Box>
								</ContextualAnalyticsData>
							),
						}}
					/>
				</Box>
			)}
		</AdControlDropdownMenu>
	);
};

const privacyLinkStyles = xcss({
	marginTop: 'space.150',
});

const pressableStyles = xcss({
	paddingInline: 'space.100',
	paddingBlock: 'space.025',
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
	borderRadius: 'border.radius.100',
});
