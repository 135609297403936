import type { MessageDescriptor } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import messages from '../messages.tsx';
import type { Sources } from '../common.tsx';

const ELIGIBLE_ISSUE_TYPES_SET = new Set<string>(['bug', 'story', 'epic']);

type SupportedIssueTypes = 'bug' | 'story' | 'epic';

export const BUG_TRANSLATIONS_SET = new Set([
	'bug',
	'\u0431\u0430\u0433', // баг (Russian)
	'\u30d0\u30b0', // バグ (Japanese)
	'\u6f0f\u6d1e', // 漏洞 (Chinese)
	'\u7f3a\u9677', // 缺陷 (Chinese)
	'\ubc84\uadf8', // 버그 (Korean)
	'b\u0142\u0105d w programie', // błąd w programie (Polish)
	'bugg',
	'chyba',
	'error',
	'feil',
	'hata',
	'viga',
	'virhe',
]);

export const STORY_TRANSLATIONS_SET = new Set([
	'story',
	'\u0438\u0441\u0442\u043e\u0440\u0438\u044f', // история (Russian)
	'\u30b9\u30c8\u30fc\u30ea\u30fc', // ストーリー (Japanese)
	'\u6545\u4e8b', // 故事 (Chinese)
	'\uc2a4\ud1a0\ub9ac', // 스토리 (Korean)
	'ber\u00e4ttelse', // berättelse (Swedish)
	'hikaye',
	'hist\u00f3ria', // história (Portuguese)
	'historia',
	'historie',
	'lugu',
	'sc\u00e9n\u00e1\u0159', // scénář (Czech)
	'tarina',
]);

export const EPIC_TRANSLATIONS_SET = new Set([
	'epic',
	'\u044d\u043f\u0438\u043a', // эпик (Russian)
	'\u30a8\u30d4\u30c3\u30af', // エピック (Japanese)
	'\u5927\u578b\u5de5\u4f5c', // 大型工作 (Chinese)
	'\u957f\u7bc7\u6545\u4e8b', // 长篇故事 (Chinese)
	'\uc5d0\ud53d', // 에픽 (Korean)
	'eepos',
	'epik',
	'eposz',
]);

export const getIssueTypeEnglish = (issueType: string | undefined, source: Sources) => {
	if (!issueType) return;

	if (BUG_TRANSLATIONS_SET.has(issueType)) {
		return 'bug';
	}
	if (STORY_TRANSLATIONS_SET.has(issueType)) {
		return 'story';
	}
	if (EPIC_TRANSLATIONS_SET.has(issueType)) {
		return 'epic';
	}

	if (source === 'listViewEntrypoint') {
		return 'listViewDefault';
	}
};

export interface ConfluenceTemplate {
	name: MessageDescriptor;
	url: string;
	suggestedIssueType?: string;
	blueprintModuleCompleteKey: string;
}

export const getConfluencePlaceholderTemplate = (
	issueTypeEnglish: string | undefined,
): ConfluenceTemplate | undefined => {
	if (!issueTypeEnglish) return;
	return confluencePlaceholderTemplates[issueTypeEnglish];
};

export const isSupportedIssueType = (
	issueTypeEnglish: string | undefined,
): issueTypeEnglish is SupportedIssueTypes => {
	if (fg('placeholder_template_i18n_killswitch')) {
		return ELIGIBLE_ISSUE_TYPES_SET.has(issueTypeEnglish ?? '');
	}
	return ELIGIBLE_ISSUE_TYPES_SET.has(issueTypeEnglish?.toLowerCase() ?? '');
};

/**
 * The only issue types we are targeting for the Confluence Placeholder
 * Template experiment are 'story', 'epic', and 'bug'. Selected issue
 * type to template pairings are listed here:
 * https://hello.atlassian.net/wiki/spaces/ECON/pages/2768820121/Issue+type+template+pairing
 */
export const confluencePlaceholderTemplates: {
	[key: string]: ConfluenceTemplate;
} = {
	story: {
		name: messages.productRequirementsTemplateName,
		url: 'https://www.atlassian.com/software/confluence/templates/product-requirements',
		suggestedIssueType: 'story',
		blueprintModuleCompleteKey:
			'com.atlassian.confluence.plugins.confluence-software-blueprints:requirements-blueprint',
	},
	epic: {
		name: messages.projectPlanTemplateName,
		url: 'https://www.atlassian.com/software/confluence/templates/project-plan',
		suggestedIssueType: 'epic',
		blueprintModuleCompleteKey:
			'com.atlassian.confluence.plugins.confluence-business-blueprints:projectplan-blueprint',
	},
	bug: {
		name: messages.knownErrorsTemplateName,
		url: 'https://www.atlassian.com/software/confluence/templates/itsm-known-errors',
		suggestedIssueType: 'bug',
		blueprintModuleCompleteKey:
			'com.atlassian.confluence.plugins.confluence-business-blueprints:itsm-known-errors-blueprint',
	},
	listViewDefault: {
		name: messages.meetingNotesTemplateName,
		url: 'https://www.atlassian.com/software/confluence/templates/meeting-notes',
		blueprintModuleCompleteKey:
			'com.atlassian.confluence.plugins.confluence-business-blueprints:meeting-notes-blueprint',
	},
};
