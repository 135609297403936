import React, { useCallback, useRef } from 'react';
import { styled } from '@compiled/react';
import { useFragment, graphql } from 'react-relay';
import { Inline, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { EmptyFieldText } from '@atlassian/jira-issue-empty-field-text/src/ui/index.tsx';
import type { parent_issueFieldParentReadviewFull_ParentReadView$key as ParentFragment } from '@atlassian/jira-relay/src/__generated__/parent_issueFieldParentReadviewFull_ParentReadView.graphql';
import messages from './messages.tsx';
import { Summary } from './summary/index.tsx';
import type { ParentReadViewProps } from './types.tsx';

/**
 * The ParentReadView will show a read only view of Parent field.
 * @param props [ParentReadViewProps](./types.tsx)
 */
export const ParentReadView = ({
	fragmentRef,
	disableClickAction,
	emptyText,
	onClick,
	isTruncated = true,
	isSingleLineRowHeightEnabled,
	renderHoverPopover,
}: ParentReadViewProps) => {
	const { parentIssue } = useFragment<ParentFragment>(
		graphql`
			fragment parent_issueFieldParentReadviewFull_ParentReadView on JiraParentIssueField {
				parentIssue {
					key
					issueColorField {
						color {
							colorKey
						}
					}
					issueTypeField {
						issueType {
							avatar {
								xsmall
							}
							name
						}
					}
					summaryField {
						text
					}
				}
			}
		`,
		fragmentRef,
	);
	const { formatMessage } = useIntl();

	const contentRef = useRef(null);

	const issueType = parentIssue?.issueTypeField?.issueType;
	const label = `${parentIssue?.key} ${parentIssue?.summaryField?.text ?? ''}`;
	const iconUrl = issueType?.avatar?.xsmall;

	const hoverPopoverRenderer = useCallback(
		() =>
			renderHoverPopover?.({
				content: label,
				children: (
					<Summary
						disableClickAction={disableClickAction}
						issueKey={parentIssue?.key || ''}
						label={label}
						colorKey={parentIssue?.issueColorField?.color?.colorKey}
						onClick={onClick}
						isTruncated={isTruncated}
						contentRef={contentRef}
					/>
				),
				contentRef,
			}),
		[
			disableClickAction,
			isTruncated,
			label,
			onClick,
			parentIssue?.issueColorField?.color?.colorKey,
			parentIssue?.key,
			renderHoverPopover,
		],
	);

	if (!parentIssue) {
		return <EmptyFieldText emptyText={emptyText} />;
	}

	if (!isSingleLineRowHeightEnabled)
		return (
			<Tooltip content={label} position="bottom">
				<Inline alignBlock="center" space="space.100" xcss={isTruncated ? containerStyles : null}>
					{iconUrl && (
						<Icon
							src={iconUrl}
							alt={formatMessage(messages.icon, {
								issueType: issueType?.name ?? '',
							})}
						/>
					)}
					<Summary
						disableClickAction={disableClickAction}
						issueKey={parentIssue.key}
						label={label}
						colorKey={parentIssue.issueColorField?.color?.colorKey}
						onClick={onClick}
						isTruncated={isTruncated}
					/>
				</Inline>
			</Tooltip>
		);

	return (
		<Inline alignBlock="center" space="space.100" xcss={containerStyles}>
			{iconUrl && (
				<Icon
					src={iconUrl}
					alt={formatMessage(messages.icon, {
						issueType: issueType?.name ?? '',
					})}
				/>
			)}
			{hoverPopoverRenderer()}
		</Inline>
	);
};

const containerStyles = xcss({
	overflow: 'hidden',
	whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Icon = styled.img({
	maxWidth: '16px',
});
