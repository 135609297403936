/**
 * @generated SignedSource<<88ec38a21adcfcfd82513822ddb8e467>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditView_fragmentRef$data = {
  readonly " $fragmentSpreads": FragmentRefs<"lite_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditView_fragmentRef" | "singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditViewOld_fragmentRef">;
  readonly " $fragmentType": "singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditView_fragmentRef";
};
export type singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditView_fragmentRef$key = {
  readonly " $data"?: singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditView_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditView_fragmentRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditView_fragmentRef",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "lite_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditView_fragmentRef"
    },
    {
      "kind": "FragmentSpread",
      "name": "singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditViewOld_fragmentRef"
    }
  ],
  "type": "JiraSingleSelectField"
};

(node as any).hash = "ff01dbbf315ef855fbb8523719808ba0";

export default node;
