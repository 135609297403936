/* eslint-disable react-hooks/rules-of-hooks */
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	useProjectKey,
	useProjectType,
	useEdition,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { PREMIUM_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';

export const useIsConcealActionsInMeatballExpEnabled = () => {
	if (!fg('_conceal_items_into_meatball_menu')) {
		return false;
	}
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);
	const edition = useEdition(projectKey, true);
	return (
		edition !== PREMIUM_EDITION &&
		projectType !== SERVICE_DESK_PROJECT &&
		expVal('conceal_items_into_meatball_menu', 'isConcealActionsEnabled', false)
	);
};
