import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	loomRecordVideoButtonLabel: {
		id: 'issue-view-foundation.quick-add.loom-record-item-button.loom-record-video-button-label',
		defaultMessage: 'Record a Loom video',
		description:
			'The label used on the quick add button to record a new video via Atlassian Loom product',
	},
	newLoomVideoButtonLabel: {
		id: 'issue-view-foundation.quick-add.loom-record-item-button.new-loom-video-button-label',
		defaultMessage: 'New Loom video',
		description:
			'The label used on the quick add button to record a new video for co-use flow via Atlassian Loom product, quick actions menu GA experiment only',
	},
	createVideoButtonLabel: {
		id: 'issue-view-foundation.quick-add.loom-record-item-button.create-video-button-label',
		defaultMessage: 'Create video',
		description:
			'The label used on the quick add button to record a new video for co-use flow via Atlassian Loom product, quick actions menu GA experiment only',
	},
	recordVideoButtonLabel: {
		id: 'issue-view-foundation.quick-add.loom-record-item-button.record-video-button-label',
		defaultMessage: 'Record video',
		description:
			'The label used on the quick add button to record a new video for co-use flow via Atlassian Loom product, quick actions menu GA experiment only',
	},
	createVideoButtonLabelSearch: {
		id: 'issue-view-foundation.quick-add.loom-record-item-button.create-video-button-label-search',
		defaultMessage: 'Create video in loom',
		description:
			'The label used for search on the quick add button to record a new video for co-use flow via Atlassian Loom product, quick actions menu GA experiment only',
	},
	recordVideoButtonLabelSearch: {
		id: 'issue-view-foundation.quick-add.loom-record-item-button.record-video-button-label-search',
		defaultMessage: 'Record video in loom',
		description:
			'The label used for search on the quick add button to record a new video for co-use flow via Atlassian Loom product, quick actions menu GA experiment only',
	},
	loomVideoButtonLabel: {
		id: 'issue-view-foundation.quick-add.loom-record-item-button.loom-video-button-label',
		defaultMessage: 'Loom video',
		description:
			'The label used on the quick add button to record a new video for cross-flow or cross-join via Atlassian Loom product, quick actions menu GA experiment only',
	},
	videoButtonLabel: {
		id: 'issue-view-foundation.quick-add.loom-record-item-button.video-button-label',
		defaultMessage: 'Video',
		description:
			'The label used on the quick add button to record a new video for cross-flow or cross-join via Atlassian Loom product, quick actions menu M2 experiment only',
	},
	tryLozenge: {
		id: 'issue-view-foundation.quick-add.loom-record-item-button.try-lozenge',
		defaultMessage: 'Try',
		description: 'Lozenge to ask users to try the record video feature in Atlassian Loom product',
	},
	addLozenge: {
		id: 'issue-view-foundation.quick-add.loom-record-item-button.add-lozenge',
		defaultMessage: 'Add',
		description:
			'Lozenge to ask users to try the record video feature in Atlassian Loom product (experimental)',
	},
	newLozenge: {
		id: 'issue-view-foundation.quick-add.loom-record-item-button.new-lozenge',
		defaultMessage: 'New',
		description: 'Lozenge to tell users that a new loom recording feature is now available',
	},
	videoIcon: {
		id: 'issue-view-foundation.quick-add.loom-record-item-button.video-icon',
		defaultMessage: 'Video icon',
		description: 'Icon used to represent a video',
	},
});

export default messages;
