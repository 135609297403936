import React, { type ComponentPropsWithoutRef } from 'react';
import { styled } from '@compiled/react';
import Heading from '@atlaskit/heading';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineMessageHeadingComponent = styled.div({
	marginBottom: token('space.200'),
});

export const InlineMessageHeading = (
	props: ComponentPropsWithoutRef<typeof InlineMessageHeadingComponent>,
) => (
	<Heading size="xsmall" as="h1">
		<InlineMessageHeadingComponent {...props} />
	</Heading>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InlineMessageText = styled.div({
	marginBottom: token('space.200'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InlineMessageButtonsWrapper = styled.div({
	display: 'flex',
	justifyContent: 'flex-start',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InlineMessageButtonPadding = styled.div({
	marginLeft: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InlineMessageRightButtonPadding = styled.div({
	marginRight: token('space.100'),
});
