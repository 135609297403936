import { addSpanToAll } from '@atlaskit/react-ufo/interaction-metrics';
import { fg } from '@atlassian/jira-feature-gating';

export const wrapPromiseWithFetchInteraction = <R,>(
	promise: Promise<R>,
	name: string,
	type: 'fetch' | 'graphql' = 'fetch',
): Promise<R> => {
	const fetchStartTime = performance.now();
	return promise.then((response) => {
		if (fg('jpd-trace-ufo-transition')) {
			addSpanToAll(
				type,
				name,
				[{ name: 'network' }, { name: 'jpdApp' }],
				fetchStartTime,
				performance.now(),
			);
		}
		return response;
	});
};
