import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { EngagementSpotlight } from '@atlassiansox/engagekit-ts';
import { SpotlightTarget } from '@atlaskit/onboarding';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { useScreenTabs as useUiModificationsScreenTabs } from '@atlassian/jira-issue-adjustments/src/controllers.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { IssueViewRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import {
	fireUIAnalytics,
	useAnalyticsEvents,
	fireOperationalAnalytics,
	MountEvent,
	type UIAnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { useTabsLocalStorage } from '../services/local-storage/index.tsx';
import { StaticTabsSpotlight } from './static-tabs-spotlight/index.tsx';
import { StaticTabs } from './static-tabs/index.tsx';
import type { TabData } from './types.tsx';

const TemplatesTabViewOnboardingSpotlightTarget = 'TemplatesTabViewOnboardingSpotlightTarget';
const logInfo = (msg: string) =>
	log.safeInfoWithoutCustomerData('issue.issue-view-layout-templates.components.tab-view', msg);
const logWarning = (msg: string) =>
	log.safeWarnWithoutCustomerData('issue.issue-view-layout-templates.components.tab-view', msg);

type Props = {
	// TODO Decomp BENTO-12514 - add useFragment to this component and replace this prop with more specific fragment key
	issueViewRelayFragment?: IssueViewRelayFragment | null;
	tabArray: TabData[];
};

export const TabView = ({ tabArray }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const [selectedTabId, setSelectedTabId] = useTabsLocalStorage(projectKey);

	const handleChange = useCallback(
		(index: number) => {
			const payload = {
				totalTabCount: tabArray.length,
				selectedTabPosition: index + 1,
			};
			const event = createAnalyticsEvent({
				action: 'selected',
				actionSubject: 'tab',
				actionSubjectId: 'issueFieldTabs',
			});
			fireUIAnalytics(event, 'issueFieldTabs', payload);
			setSelectedTabId(tabArray[index]?.id);
		},
		[createAnalyticsEvent, setSelectedTabId, tabArray],
	);

	const selectedTabIndex = tabArray?.findIndex((tab) => tab && tab.id === selectedTabId) ?? -1;

	const { controlledTabs, controlledSelected, controlledOnChange } = useUiModificationsScreenTabs({
		tabs: tabArray,
		selected: selectedTabIndex === -1 ? 0 : selectedTabIndex,
		onChange: handleChange,
	});
	logInfo('TabViewNew has been intialised');

	// This is an edge case and should never happen.
	// However in some situations it's possible that tabArray is empty due to async nature of data we're receiving here.
	// Normally, we'd ignore this cause eventually we get all needed data and tabArray gets the right shape,
	// but in this case we wrap tabs in React.memo to prevent unwanted re-renders. So once it was rendered with 0 tabs, it'll never re-render when the tabs are there.
	if (tabArray?.length === 0) {
		logWarning('TabViewNew - tabArray is empty due to async data load');
		return null;
	}

	// If there is just one Tab, we exit early,
	// and display the content normally.
	if (tabArray?.length === 1) {
		logInfo('TabViewNew - tabArray contains only one tab leading to an early exit');
		const oneFoundTab = tabArray[0];
		return oneFoundTab && oneFoundTab.content;
	}

	return (
		<UFOSegment name="issue-view-screen-tabs">
			<JSErrorBoundary
				id="issue.issue-view.screen-tabs"
				packageName="jiraScreenTabs"
				teamName="bento"
				fallback="unmount"
			>
				<TabsWrapper>
					<TabsSpotlightWrapper>
						<SpotlightTarget name={TemplatesTabViewOnboardingSpotlightTarget}>
							<StaticTabsSpotlight tabs={controlledTabs} />
						</SpotlightTarget>
					</TabsSpotlightWrapper>
					<EngagementSpotlight engagementId={TemplatesTabViewOnboardingSpotlightTarget} />
					<MountEvent
						onMount={(analyticsEvent: UIAnalyticsEvent) =>
							fireOperationalAnalytics(analyticsEvent, 'TemplatesTabViewOnboarding mounted')
						}
					/>
					<StaticTabs
						tabs={controlledTabs}
						selected={controlledSelected}
						onChange={controlledOnChange}
					/>
				</TabsWrapper>
			</JSErrorBoundary>
		</UFOSegment>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TabsWrapper = styled.div({
	position: 'relative',
	width: 'calc(100% + 16px)',
	// Workaround for wrapping excessive tabs until AtlasKit support it natively
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"div[role='tablist']": {
		flexWrap: 'wrap',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const TabsSpotlightWrapper = styled.div({
	visibility: 'hidden',
	position: 'absolute',
	zIndex: -1,
	width: '100%',
});
