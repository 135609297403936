/**
 * @generated SignedSource<<553ffffdf17a912e968f5f4e099ae542>>
 * @relayHash 8e1782ab00eaf4e2a62ca8165e4a53d1
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 349b62d9a2ae01177c6349397ef0a02533e916acd4be6638555d6ca5510bb207

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraUiModificationsViewType = "GIC" | "IssueTransition" | "IssueView" | "JSMRequestCreate" | "%future added value";
export type JiraExtensionRenderingContextInput = {
  issueKey?: string | null | undefined;
  portalId?: string | null | undefined;
  projectKey?: string | null | undefined;
};
export type JiraUiModificationsContextInput = {
  issueKey?: string | null | undefined;
  issueTypeId?: string | null | undefined;
  portalId?: string | null | undefined;
  projectId?: string | null | undefined;
  requestTypeId?: string | null | undefined;
  viewType: JiraUiModificationsViewType;
};
export type rootUiModificationsQuery$variables = {
  cloudId: string;
  forgeExtensionRenderingContext: JiraExtensionRenderingContextInput;
  includeEgressOverridesForge: boolean;
  uiModificationsContext: JiraUiModificationsContextInput;
};
export type rootUiModificationsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"fragment_AtlassianUiModificationsCore_RootFragment">;
};
export type rootUiModificationsQuery = {
  response: rootUiModificationsQuery$data;
  variables: rootUiModificationsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "forgeExtensionRenderingContext"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeEgressOverridesForge"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uiModificationsContext"
},
v4 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v5 = {
  "kind": "ScalarField",
  "name": "id"
},
v6 = {
  "kind": "ScalarField",
  "name": "type"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "rootUiModificationsQuery",
    "selections": [
      {
        "args": [
          (v4/*: any*/),
          {
            "kind": "Variable",
            "name": "forgeExtensionRenderingContext",
            "variableName": "forgeExtensionRenderingContext"
          },
          {
            "kind": "Variable",
            "name": "includeEgressOverridesForge",
            "variableName": "includeEgressOverridesForge"
          },
          {
            "kind": "Variable",
            "name": "uiModificationsContext",
            "variableName": "uiModificationsContext"
          }
        ],
        "kind": "FragmentSpread",
        "name": "fragment_AtlassianUiModificationsCore_RootFragment"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "rootUiModificationsQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "context",
                "variableName": "uiModificationsContext"
              }
            ],
            "concreteType": "JiraAppUiModifications",
            "kind": "LinkedField",
            "name": "uiModifications",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "appEnvId"
              },
              {
                "concreteType": "JiraUiModification",
                "kind": "LinkedField",
                "name": "uiModifications",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "data"
                  }
                ]
              }
            ]
          },
          {
            "concreteType": "JiraForgeQuery",
            "kind": "LinkedField",
            "name": "forge",
            "plural": false,
            "selections": [
              {
                "alias": "uiModificationsExtensions",
                "args": [
                  (v4/*: any*/),
                  {
                    "kind": "Variable",
                    "name": "context",
                    "variableName": "forgeExtensionRenderingContext"
                  },
                  {
                    "kind": "Literal",
                    "name": "types",
                    "value": [
                      "jira:uiModifications"
                    ]
                  }
                ],
                "concreteType": "JiraForgeExtension",
                "kind": "LinkedField",
                "name": "extensions",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "appVersion"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "installationId"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "environmentId"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "environmentType"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "environmentKey"
                  },
                  {
                    "concreteType": "JiraForgeExtensionLicense",
                    "kind": "LinkedField",
                    "name": "license",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "active"
                      },
                      (v6/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "supportEntitlementNumber"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "trialEndDate"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "subscriptionEndDate"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "isEvaluation"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "billingPeriod"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "ccpEntitlementId"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "ccpEntitlementSlug"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "capabilitySet"
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "name": "properties"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "scopes"
                  },
                  (v6/*: any*/),
                  {
                    "concreteType": "JiraForgeAppEgressDeclaration",
                    "kind": "LinkedField",
                    "name": "egress",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "addresses"
                      },
                      {
                        "condition": "includeEgressOverridesForge",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "category"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "inScopeEUD"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "condition": "includeEgressOverridesForge",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "overrides"
                      },
                      {
                        "concreteType": "JiraForgeInstallationConfigExtension",
                        "kind": "LinkedField",
                        "name": "installationConfig",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "key"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "value"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "name": "consentUrl"
                  },
                  {
                    "concreteType": "JiraUserAppAccess",
                    "kind": "LinkedField",
                    "name": "userAccess",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "hasAccess"
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "name": "hiddenBy"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "349b62d9a2ae01177c6349397ef0a02533e916acd4be6638555d6ca5510bb207",
    "metadata": {},
    "name": "rootUiModificationsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "e9e68d25e9903be8d01585d94c33f309";

export default node;
