/**
 * @generated SignedSource<<d80cc04ad9749fa06ea7aa2b7e8957b6>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_redactions_issueActionMenu_user$data = {
  readonly userPreferences: {
    readonly __id: string;
    readonly showRedactionChangeBoardingOnActionMenu: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "main_redactions_issueActionMenu_user";
};
export type main_redactions_issueActionMenu_user$key = {
  readonly " $data"?: main_redactions_issueActionMenu_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_redactions_issueActionMenu_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    }
  ],
  "kind": "Fragment",
  "name": "main_redactions_issueActionMenu_user",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "cloudId",
          "variableName": "cloudId"
        }
      ],
      "concreteType": "JiraUserPreferences",
      "kind": "LinkedField",
      "name": "userPreferences",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "showRedactionChangeBoardingOnActionMenu"
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "kind": "ScalarField",
              "name": "__id"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraQuery"
};

(node as any).hash = "b424aa262d8bb9396fabb4f1dafc8378";

export default node;
