import type { ComponentClass, FunctionComponent } from 'react';
import type { UIAnalyticsEvent, AnalyticsEventPayload } from '@atlaskit/analytics-next';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import type { ChildIssue } from '@atlassian/jira-issue-view-common-types/src/children-issues-type.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	getNoValueText,
	getEpicLinkFieldKey,
	getChildIssuesPanelType,
	getParentKey,
	getBaseUrl,
	getIssueTypeWFValidators,
	getParentIssueTypeId,
	getProjectId,
	getFilterSubtasks,
} from '../state/context/selectors.tsx';
import { childCreatedInFullDialog, createChildSuccess } from '../state/entities/actions.tsx';
import {
	getAllIssues,
	getIncompleteIssues,
	getIssueHierarchyLevel,
	getIssueTypes,
} from '../state/entities/selectors.tsx';
import type { State } from '../state/types.tsx';
import { CHILD_PANEL_VIEWS } from '../state/ui/actions.tsx';
import { getAddClickCount, getChildPanelView } from '../state/ui/selectors.tsx';
import AddExistingIssue from './add-existing-issue/index.tsx';
import Heading from './heading/index.tsx';
import InlineCreate from './inline-create/index.tsx';
import Issues from './issues/index.tsx';
import ProgressSummary from './progress-summary/index.tsx';
import { ChildIssuesPanel } from './view.tsx';

const FlowWithSafeComponent: ComponentClass<unknown, unknown> | FunctionComponent<unknown> =
	flowWithSafeComponent(
		// @ts-expect-error - Argument of type '<T extends ComponentType<any>, Props = ComponentPropsWithRef<T>>(WrappedComponent: T) => FC<Omit<{ [K in keyof Props]: K extends "onFullDialogCreate" | "onSuggestedChildIssueCreateSuccess" ? (...args: [......]) => ReturnType<...> : Props[K]; }, keyof WithAnalyticsEventsProps>>' is not assignable to parameter of type 'FlowStep<T, ComponentType<Matching<({ Heading: ConnectedComponent<typeof Heading, { childIssuesLimitStatus: ChildIssuesLimitStatus; sourceName?: string | undefined; onAddChildClick: AddChildEventHandler | undefined; issueHierarchyLevel: number | undefined; context?: Context<...> | undefined; store?: Store<...> | und...'.
		ComponentWithAnalytics('childIssuePanelInlineCreate', {
			onFullDialogCreate: 'onFullDialogCreate',
			onSuggestedChildIssueCreateSuccess: 'onSuggestedChildIssueCreateSuccess',
		}),
		connect(
			(state: State) => {
				if (!state) {
					// Monitoring has shown that state can be `undefined` but we have
					// not been able to replicate it.
					return {
						Heading,
						InlineCreate,
						Issues,
						ProgressSummary,
						AddExistingIssue,
						childPanelView: CHILD_PANEL_VIEWS.InlineCreate,
						allIssues: [],
						incompleteIssues: [],
						addClickCount: 0,
						noValueText: null,
						projectId: null,
						parentIssueTypeId: null,
					};
				}

				return {
					Heading,
					InlineCreate,
					Issues,
					ProgressSummary,
					AddExistingIssue,
					childPanelView: getChildPanelView(state),
					allIssues: getAllIssues(state),
					incompleteIssues: getIncompleteIssues(state),
					addClickCount: getAddClickCount(state),
					noValueText: getNoValueText(state),
					childIssuesPanelType: getChildIssuesPanelType(state),
					issueHierarchyLevel: getIssueHierarchyLevel(state),
					epicLinkFieldKey: getEpicLinkFieldKey(state),
					parentIssueKey: getParentKey(state),
					baseUrl: getBaseUrl(state),

					// getIssueTypes only gets the issue types available for child issues
					childIssueTypes: getIssueTypes(state),
					issueTypeWithWorkflowValidators: getIssueTypeWFValidators(state),
					projectId: getProjectId(state),
					parentIssueTypeId: getParentIssueTypeId(state),
					filterSubtasks: getFilterSubtasks(state),
				};
			},
			{
				onFullDialogCreate: (
					optimisticId: string,
					childIssue: ChildIssue,
					additionalAttributes: AnalyticsEventPayload['attributes'],
					analyticsEvent: UIAnalyticsEvent,
				) =>
					childCreatedInFullDialog(optimisticId, childIssue, analyticsEvent, additionalAttributes),
				onSuggestedChildIssueCreateSuccess: (
					createdChild: ChildIssue,
					additionalAttributes: AnalyticsEventPayload['attributes'],
					analyticsEvent: UIAnalyticsEvent,
				) =>
					createChildSuccess(
						createdChild.id,
						createdChild,
						analyticsEvent,
						false,
						additionalAttributes,
					),
			},
		),
	)(ChildIssuesPanel);
export default FlowWithSafeComponent;
