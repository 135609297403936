import type { WordMap } from './types.tsx';

export const WORD_TEMPLATE_MAP: WordMap = {
	aws: 'itsm_template_24',
	ansible: 'itsm_template_18',
	azure: 'itsm_template_26',
	vm: 'itsm_template_26',
	'virtual machine': 'itsm_template_26',
	server: 'itsm_template_25',
	firewall: 'itsm_template_25',
	local: 'itsm_template_25',
	script: 'itsm_template_25',
	teams: 'itsm_template_21',
	microsoft: 'itsm_template_21',
	chat: 'itsm_template_21',
	meeting: 'itsm_template_21',
	incident: 'itsm_template_20',
	down: 'itsm_template_20',
	urgent: 'itsm_template_20',
	impacted: 'itsm_template_20',
	channel: 'itsm_template_20',
	slack: 'itsm_template_20',
};
