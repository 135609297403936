import React, { useCallback, useMemo, type RefObject } from 'react';
import { useFragment, graphql, useMutation } from 'react-relay';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import {
	convertToProjectType,
	type JiraProjectType,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import FetchError, { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import {
	getTraceIds,
	startCapturingTraceIds,
	stopCapturingTraceIds,
} from '@atlassian/relay-traceid';
import type {
	StatusTransition,
	StatusValue,
} from '@atlassian/jira-issue-field-status/src/common/types.tsx';
import type {
	OnStatusChangeSuccess,
	onUpdateStatusMutationProps,
} from '@atlassian/jira-issue-field-status/src/services/status-service/types.tsx';
import { StatusField } from '@atlassian/jira-issue-field-status/src/ui/main.tsx';
import type { Props as StatusFieldProps } from '@atlassian/jira-issue-field-status/src/ui/types.tsx';
import type { StatusDetails } from '@atlassian/jira-issue-shared-types/src/common/types/status-type.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/index.tsx';
import type {
	ui_issueViewLayoutStatusField_IssueViewStatusField$key,
	JiraStatusCategoryColor,
} from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutStatusField_IssueViewStatusField.graphql';
import type { ui_issueViewLayoutStatusField_IssueViewStatusField_Mutation } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutStatusField_IssueViewStatusField_Mutation.graphql';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { HIERARCHY_LEVEL_SAME_LEVEL } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';

const operationName = 'ui_issueViewLayoutStatusField_IssueViewStatusField_Mutation';

export type AggItemValue = {
	readonly description: string | null | undefined;
	readonly id: string;
	readonly statusId: string;
	readonly name: string | null | undefined;
	readonly statusCategory:
		| {
				readonly colorName: JiraStatusCategoryColor | null | undefined;
				readonly id: string;
				readonly key: string | null | undefined;
				readonly name: string | null | undefined;
				readonly statusCategoryId: string;
		  }
		| null
		| undefined;
};

export const toComponentValueShape = (
	fieldData: AggItemValue | undefined | null,
): StatusDetails | undefined =>
	fieldData
		? {
				id: fieldData.statusId,
				name: fieldData.name ?? '',
				description: fieldData.description ?? '',
				statusCategory: {
					id: Number(fieldData.statusCategory?.statusCategoryId),
					colorName: fieldData.statusCategory?.colorName ?? '',
					key: fieldData.statusCategory?.key ?? '',
					name: fieldData.statusCategory?.name ?? '',
				},
				statusId: fieldData.statusId ?? '',
			}
		: undefined;

function transformComponentValueToAggShape(valueLegacy: StatusValue): AggItemValue {
	return {
		id: valueLegacy.id,
		statusId: valueLegacy.id,
		description: valueLegacy.description,
		name: valueLegacy.name,
		statusCategory: {
			id: valueLegacy.statusCategory.id.toString(),
			statusCategoryId: valueLegacy.statusCategory.id.toString(),
			name: valueLegacy.statusCategory.name,
			key: valueLegacy.statusCategory.key,
			colorName: undefined, // legacy field does not have color, it should be valueLegacy.statusCategory.colorName as JiraStatusCategoryColor,
		},
	};
}

export type IssueViewStatusFieldProps = {
	fragmentKey: ui_issueViewLayoutStatusField_IssueViewStatusField$key;
	statusButtonRef?: RefObject<HTMLElement>;

	// still in redux. Indicates if the issue view is in a loading state or not
	isCompletedLoading?: boolean;
	// triggers on cancel edit redux action required for shortcut to work
	onEditCancel?: () => void;
	onEditStart?: () => void;
	onSuccess?: OnStatusChangeSuccess;
};

export function IssueViewStatusField(props: IssueViewStatusFieldProps) {
	const [, { fieldChangeFailed, fieldChangeRequested }] = useIssueViewFieldUpdateEvents();

	const data = useFragment<ui_issueViewLayoutStatusField_IssueViewStatusField$key>(
		graphql`
			fragment ui_issueViewLayoutStatusField_IssueViewStatusField on JiraIssue {
				projectField {
					project {
						projectType
					}
				}
				statusField {
					id
					fieldId
					type
					name
					description
					__typename

					issue {
						issueId
						key
					}

					status {
						id
						statusId
						name
						description
						statusCategory {
							id
							statusCategoryId
							key
							name
							colorName
						}
					}
				}
			}
		`,
		props.fragmentKey,
	);
	const { projectType } = data?.projectField?.project ?? {};
	const {
		issue,
		status,
		fieldId,
		type,
		__typename,
		id: uniqueFieldId,
	} = data?.statusField ?? {
		id: '',
		fieldId: '',
		type: '',
		__typename: 'JiraStatusField',
	};

	// This submit function is used in the status update mutation for non-screen transitions
	const onSubmit = useCallback(
		(value: AggItemValue | null | undefined) => {
			issue?.issueId &&
				fieldChangeRequested(issue?.issueId, fieldId, value, undefined, {
					type,
					__typename,
				});
		},
		[__typename, fieldId, type, fieldChangeRequested, issue?.issueId],
	);

	// This is needed for JSW's monitoring of Issue Hierarchy in Next-gen projects
	const [sameLevel] = fg('relay-636-add-missing-analytics')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useFieldValue({
				issueKey: issue?.key ?? '',
				fieldKey: HIERARCHY_LEVEL_SAME_LEVEL,
			})
		: [{}];
	const issueHierarchyLevel = sameLevel?.level;

	// This submit function is used for both screen and non-screen transitions
	const onStatusSubmitted = (
		oldValue: StatusValue,
		newValue: StatusValue,
		meta: {
			transition: StatusTransition;
		},
		event: UIAnalyticsEvent,
	) => {
		fireTrackAnalytics(event, 'issueStatus updated', {
			oldValId: oldValue.id,
			oldStatusCategoryId: oldValue.statusCategory.id,
			oldStatusCategoryName: oldValue.statusCategory.name,
			newValId: newValue.id,
			newStatusCategoryId: newValue.statusCategory.id,
			newStatusCategoryName: newValue.statusCategory.name,
			hasScreen: meta.transition.hasScreen,
			isConditional: meta.transition.isConditional,
			issueHierarchyLevel,
		});
	};

	const onSubmitFailed = useCallback(
		() => issue?.issueId && fieldChangeFailed(issue?.issueId, fieldId),
		[fieldId, fieldChangeFailed, issue?.issueId],
	);

	// no optimistic update for status field as legacy component handles it internally
	const [commit] = useMutation<ui_issueViewLayoutStatusField_IssueViewStatusField_Mutation>(graphql`
		mutation ui_issueViewLayoutStatusField_IssueViewStatusField_Mutation(
			$input: JiraUpdateStatusFieldInput!
		) @raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateStatusByQuickTransition(input: $input) {
					success
					errors {
						message
						extensions {
							statusCode
						}
					}
				}
			}
		}
	`);

	const onUpdateStatusMutation = useCallback(
		({ transitionId, newStatus }: onUpdateStatusMutationProps) => {
			return new Promise<void>((resolve, reject) => {
				const newAggValue = transformComponentValueToAggShape(newStatus);
				onSubmit(newAggValue);

				const handleError = (error: Error) => {
					onSubmitFailed();
					reject(error);
				};

				if (fg('obsrve-2239-traceid-errorflag-issue-transition')) {
					startCapturingTraceIds(operationName);
				}
				commit({
					variables: {
						input: {
							id: uniqueFieldId,
							statusTransitionId: parseInt(transitionId, 10),
						},
					},
					onCompleted(mutationData) {
						if (mutationData.jira?.updateStatusByQuickTransition?.success) {
							resolve();
						} else if (mutationData.jira?.updateStatusByQuickTransition?.errors?.[0]?.message) {
							if (fg('thor_resolve_transition_event_gate')) {
								const firstError = mutationData.jira.updateStatusByQuickTransition.errors[0];
								handleError(
									new ValidationError(
										firstError.message ?? '',
										[],
										firstError?.extensions?.statusCode ?? undefined,
										getTraceIds(operationName)?.[0],
									),
								);
							} else {
								handleError(
									new ValidationError(
										mutationData.jira.updateStatusByQuickTransition.errors[0].message,
									),
								);
							}
						} else if (fg('obsrve-2239-traceid-errorflag-issue-transition')) {
							const traceId = getTraceIds(operationName)[0];
							handleError(new FetchError(0, 'Could not update status', traceId));
						} else {
							handleError(new Error('Could not update status'));
						}
						if (fg('obsrve-2239-traceid-errorflag-issue-transition')) {
							stopCapturingTraceIds(operationName);
						}
					},
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					onError: (error: any) => {
						if (fg('obsrve-2239-traceid-errorflag-issue-transition')) {
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							const additionalAttributes: any = {};
							if (fg('thor_resolve_transition_event_gate')) {
								const firstError = error?.source?.errors?.[0];
								additionalAttributes.statusCode =
									firstError?.extensions?.statusCode ?? error.statusCode;
								additionalAttributes.traceId = getTraceIds(operationName)[0] ?? error.traceId;
								additionalAttributes.message = firstError?.message ?? error.message;
							}
							handleError?.({
								...error,
								...(error instanceof FetchError ? { traceId: getTraceIds(operationName)[0] } : {}),
								...additionalAttributes,
							});
							stopCapturingTraceIds(operationName);
						} else {
							handleError?.(error);
						}
					},
					optimisticResponse: {
						jira: {
							updateStatusByQuickTransition: {
								success: true,
								errors: null,
							},
						},
					},
				});
			});
		},
		[commit, onSubmit, onSubmitFailed, uniqueFieldId],
	);

	const value = useMemo(() => toComponentValueShape(status), [status]);

	// eslint-disable-next-line jira/ff/inline-usage
	const statusFieldProps: StatusFieldProps = {
		issueKey: issue?.key ?? '',
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		projectType: projectType ? convertToProjectType(projectType as JiraProjectType) : null,
		initialValue: value,
		isEditable: props.isCompletedLoading,
		isOptimistic: !fg('issue-view-status-transition-optimistic-update'),
		appearance: 'button',
		preFetchTransitions: true,
		registerInCommandPalette: true,
		statusButtonRef: props.statusButtonRef,
		onEditCancel: props.onEditCancel,
		onSuccess: props.onSuccess,
		onUpdateStatusMutation,
		...(fg('one_event_rules_them_all_fg') ? { onEditStart: props.onEditStart } : {}),
		...(fg('relay-636-add-missing-analytics') ? { onSubmit: onStatusSubmitted } : {}),
	};

	return <StatusField {...statusFieldProps} />;
}
