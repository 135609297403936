/**
 * @generated SignedSource<<76ed3c34abd33cd4f71506e06b13d6fa>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type src_issueViewLayoutTemplatesItemList_ItemList_rootRelayFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal_rootRelayFragment">;
  readonly " $fragmentType": "src_issueViewLayoutTemplatesItemList_ItemList_rootRelayFragment";
};
export type src_issueViewLayoutTemplatesItemList_ItemList_rootRelayFragment$key = {
  readonly " $data"?: src_issueViewLayoutTemplatesItemList_ItemList_rootRelayFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"src_issueViewLayoutTemplatesItemList_ItemList_rootRelayFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "src_issueViewLayoutTemplatesItemList_ItemList_rootRelayFragment",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal_rootRelayFragment"
    }
  ],
  "type": "Query"
};

(node as any).hash = "e0d78b0f540f71bc0637eed40bfbf4bb";

export default node;
