import { expVal } from '@atlassian/jira-feature-experiments';
import { useIsEmbedPageIssueView } from '@atlassian/jira-issue-context-service/src/index.tsx';
import {
	tenantHasActiveProductDiscoveryLicenseSelector,
	hasActivatedConfluenceWithinThirtyDaysSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import {
	getIssueStatusCategoryIdSelector,
	isEpicIssueTypeSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import { canLinkIssuesSelector } from '@atlassian/jira-issue-view-store/src/selectors/issue-actions-permissions-selector.tsx';
import { useSelector } from '@atlassian/jira-react-redux/src/index.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';

const UNSTARTED_STATUS_CATEGORY_ID = 2;

export const useCrossflowIdeaCardEligible = (): boolean => {
	const { licensedProducts } = useTenantContext();
	const isEpic = useSelector(isEpicIssueTypeSelector);
	const issueStatusCategoryId = useSelector(getIssueStatusCategoryIdSelector);
	const isAdmin = useIsAdmin();
	const isEmbedded = useIsEmbedPageIssueView();
	const tenantHasActiveJpdLicense = useSelector(tenantHasActiveProductDiscoveryLicenseSelector);
	const tenantHasActivatedConfluenceWithin30Days = useSelector(
		hasActivatedConfluenceWithinThirtyDaysSelector,
	);
	const canLinkIssues = useSelector(canLinkIssuesSelector);
	const tenantHasConfluence = licensedProducts?.confluence === true;

	const shouldShow =
		isEpic &&
		issueStatusCategoryId === UNSTARTED_STATUS_CATEGORY_ID &&
		canLinkIssues &&
		isAdmin &&
		!isEmbedded &&
		!tenantHasActiveJpdLicense &&
		!tenantHasActivatedConfluenceWithin30Days &&
		tenantHasConfluence;

	if (shouldShow) {
		expVal('discover_ideas_in_issue_view_aa_test', 'AA', false);
		return expVal('discover_ideas_in_issue_view', 'isIdeaDiscoveryEnabled', false);
	}

	return false;
};
