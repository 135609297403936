import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import FetchError, { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import {
	FIELD_TYPES,
	type FieldType,
} from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import {
	SUMMARY_FIELDKEY,
	STATUS_FIELDKEY,
	ISSUETYPE_FIELDKEY,
} from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId, Value } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { fireAnalyticsEventForIssueUpdate } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import { runInBatch } from '@atlassian/jira-polaris-lib-run-in-batch/src/index.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import {
	createGetField,
	createGetFieldMapping,
	getConfiguredConnectionFieldsKeys,
} from '../../selectors/fields.tsx';
import { getFilteredIssueIds } from '../../selectors/filters.tsx';
import { getLocalIssueIdToJiraId } from '../../selectors/issue-ids.tsx';
import {
	createGetKeySelector,
	getSelectedIssueLocalIssueId,
	createGetIssueAnalyticsAttributes,
	createGetIdSelector,
} from '../../selectors/properties/index.tsx';
import { getSortedIssueIds } from '../../selectors/sort.tsx';
import { getCurrentViewSelectedIssueIds } from '../../selectors/view.tsx';
import type { State, Props, PropertyMaps } from '../../types.tsx';
import type { FieldMapping } from '../../utils/field-mapping/types.tsx';
import { updateConnectionFieldValue } from '../connection/index.tsx';
import { incrementOpenUpdateCounter } from '../real-time/index.tsx';
import { updateStatus } from '../update-status/index.tsx';
import { updateGoalsFieldValue } from '../goals/index.tsx';
import {
	refreshConnectionFieldsValues,
	resetConnectionFieldsValues,
} from '../refresh-connection-field-values/index.tsx';
import { updateFieldValuesOnBackendBulk } from './bulk/index.tsx';
import type {
	FieldValueUpdateRequest,
	FieldValuesUpdateRequest,
	FieldValueUpdateRequestForSelectedIssue,
} from './types.tsx';
import {
	getNewPropertiesMapping,
	getTransitionsForTargetStatusByLocalIssueIds,
	getUpdatedStateForDualWrite,
	hasIssueTypeField,
	isBulkOperationSupported,
} from './utils.tsx';

type RunInBatchResult = Error | undefined;

const BATCH_SIZE = 5;

const DUAL_WRITE_SUPPORTED_FIELD_TYPES = new Set<FieldType>([
	FIELD_TYPES.ATLAS_PROJECT,
	FIELD_TYPES.ARCHIVED,
	FIELD_TYPES.ARCHIVED_BY,
	FIELD_TYPES.ARCHIVED_ON,
]);

const updateFieldValueOnBackend = (
	state: State,
	props: Props,
	fieldKey: FieldKey,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	fieldMapping: FieldMapping<any>,
	localIssueIds: Array<LocalIssueId>,
	dispatch: StoreActionApi<State>['dispatch'],
	updateIssueSuccess?: (
		isBulkEditingOperation: boolean,
		localIssueId: string,
		fieldKey: string,
	) => void,
	updateIssueError?: (err: Error, isBulkEditingOperation: boolean) => void,
) => {
	const issuesCount = localIssueIds.length;
	const isBulkEditingOperation = issuesCount > 1;
	if (isBulkEditingOperation) {
		fireTrackAnalytics(
			props.createAnalyticsEvent({}),
			'jpd.updateFieldValueOnBackend.bulk.started tracked',
		);
	}

	dispatch(incrementOpenUpdateCounter(localIssueIds));
	return runInBatch<RunInBatchResult>(
		localIssueIds.map((localIssueId) => {
			const issueKey = createGetKeySelector(localIssueId)(state);
			// generic update REST call to Jira
			const value = fieldMapping.getFieldValueForJiraUpdate(
				fieldMapping.valueAccessor(state, props, localIssueId),
			);

			return () =>
				fieldMapping
					.getJiraUpdateService(issueKey, value)
					.then(() => {
						updateIssueSuccess &&
							updateIssueSuccess(isBulkEditingOperation, localIssueId, fieldKey);
						return undefined;
					})
					.catch((err: Error) => {
						updateIssueError && updateIssueError(err, isBulkEditingOperation);
						props.onIssueUpdateFailed(err);
						return err;
					});
		}),
		BATCH_SIZE,
	).then((results: RunInBatchResult[]) => {
		if (fg('jpd_issues_relationships')) {
			if (hasIssueTypeField([fieldKey], state, props)) {
				dispatch(refreshConnectionFieldsValues(getConfiguredConnectionFieldsKeys(state, props)));
			}
		}

		if (!isBulkEditingOperation) {
			return;
		}

		const errorCount = results.filter((result) => result instanceof Error).length;
		if (errorCount > 0) {
			fireTrackAnalytics(
				props.createAnalyticsEvent({}),
				'jpd.updateFieldValueOnBackend.bulk.failed tracked',
				{
					issuesCount,
					errorCount,
				},
			);
		} else {
			fireTrackAnalytics(
				props.createAnalyticsEvent({}),
				'jpd.updateFieldValueOnBackend.bulk.finished tracked',
				{
					issuesCount,
				},
			);
		}
	});
};

const updateFieldValuesOnBackend = (
	state: State,
	props: Props,
	fieldKeys: Array<FieldKey>,
	localIssueIds: Array<LocalIssueId>,
	dispatch: StoreActionApi<State>['dispatch'],
	updateIssuesSuccess: (isBulkEditingOperation: boolean, localIssueId: string) => void,
	updateIssuesError: (err: Error, isBulkEditingOperation: boolean) => void,
) => {
	const issuesCount = localIssueIds.length;
	const isBulkEditingOperation = issuesCount > 1;
	if (isBulkEditingOperation) {
		fireTrackAnalytics(
			props.createAnalyticsEvent({}),
			'jpd.updateFieldValuesOnBackend.bulk.started tracked',
		);
	}

	dispatch(incrementOpenUpdateCounter(localIssueIds));
	return runInBatch<RunInBatchResult>(
		localIssueIds.map((localIssueId) => {
			const issueKey = createGetKeySelector(localIssueId)(state);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const update = fieldKeys.reduce<Record<string, any>>((result, fieldKey) => {
				const fieldMapping = createGetFieldMapping(fieldKey)(state, props);
				if (!fieldMapping) {
					return result;
				}
				return {
					// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
					...result,
					[fieldKey]: fieldMapping.getFieldValueForJiraUpdate(
						fieldMapping.valueAccessor(state, props, localIssueId),
					),
				};
			}, {});

			return () =>
				props.issuesRemote
					.updateIssueFields({ issueKey, update })
					.then(() => {
						updateIssuesSuccess?.(isBulkEditingOperation, localIssueId);
						return undefined;
					})
					.catch((err) => {
						props.onIssueUpdateFailed(err);
						updateIssuesError?.(err, isBulkEditingOperation);
						return err;
					});
		}),
		BATCH_SIZE,
	).then((results: RunInBatchResult[]) => {
		if (fg('jpd_issues_relationships')) {
			if (hasIssueTypeField(fieldKeys, state, props)) {
				dispatch(refreshConnectionFieldsValues(getConfiguredConnectionFieldsKeys(state, props)));
			}
		}

		if (!isBulkEditingOperation) {
			return;
		}

		const errorCount = results.filter((result) => result instanceof Error).length;
		if (errorCount > 0) {
			fireTrackAnalytics(
				props.createAnalyticsEvent({}),
				'jpd.updateFieldValuesOnBackend.bulk.failed tracked',
				{
					issuesCount,
					errorCount,
				},
			);
		} else {
			fireTrackAnalytics(
				props.createAnalyticsEvent({}),
				'jpd.updateFieldValuesOnBackend.bulk.finished tracked',
				{
					issuesCount,
				},
			);
		}
	});
};

const getIsFilteredResult =
	(localIssueIds: LocalIssueId[]) =>
	({ getState }: StoreActionApi<State>, props: Props) => {
		const filteredIdsAfterStateChange = getFilteredIssueIds(getState(), props);

		return !localIssueIds.every((id) => filteredIdsAfterStateChange.includes(id));
	};

export const updateFieldValue =
	<TFieldValue,>(
		{
			fieldKey,
			localIssueIds,
			newValue,
			removeValue,
			appendMultiValues = false,
			performSideEffects = true,
			skipNetworkRequest = false,
		}: FieldValueUpdateRequest<TFieldValue>,
		onSuccess?: () => void,
		onError?: (err?: Error) => void,
		onConnectionsUpdated?: () => void,
	) =>
	({ getState, setState, dispatch }: StoreActionApi<State>, props: Props) => {
		const state = getState();
		const fieldMapping = createGetFieldMapping(fieldKey)(state, props);
		const field = createGetField(fieldKey)(state, props);
		const prevLastUpdatedIssueIds = [...getState().lastUpdatedIssueIds];

		if (!field || !fieldMapping) {
			return { filtered: undefined };
		}

		if (field.type === FIELD_TYPES.CONNECTION && fg('jpd_issues_relationships')) {
			setState({
				lastUpdatedIssueIds: localIssueIds,
			});

			dispatch(
				updateConnectionFieldValue({
					localIssueIds,
					fieldKey,
					newValue,
					removeValue,
					appendMultiValues,
					onError: () => {
						setState({
							lastUpdatedIssueIds: prevLastUpdatedIssueIds,
						});
					},
					onConnectionsUpdated,
				}),
			);

			return {
				filtered: dispatch(getIsFilteredResult(localIssueIds)),
			};
		}

		const dispatchUpdateGoalsFieldValue = (innerFieldKey: string) => {
			if (fg('jpd_platform_goals_field_support')) {
				dispatch(
					updateGoalsFieldValue({
						localIssueIds,
						fieldKey: innerFieldKey,
						newValue,
						removeValue,
						appendMultiValues,
						skipNetworkRequest,
						onError: () => {
							setState({
								lastUpdatedIssueIds: prevLastUpdatedIssueIds,
							});
						},
					}),
				);
			}
		};

		if (field.type === FIELD_TYPES.PLATFORM_GOALS) {
			setState({
				lastUpdatedIssueIds: localIssueIds,
			});

			dispatchUpdateGoalsFieldValue(fieldKey);

			if (!fg('jpd_dual_write_from_platform_to_atlas_goals')) {
				return {
					filtered: dispatch(getIsFilteredResult(localIssueIds)),
				};
			}
		}

		const updateIssueFieldExperience = experience.view.makeGenericIssueFieldUpdate();
		updateIssueFieldExperience.start();

		const { hasBulkChangePermissions } = props;
		const jiraIdMap = getLocalIssueIdToJiraId(getState(), props);

		const updateIssueBulkSuccess = () => {
			updateIssueFieldExperience.success();
			fireTrackAnalytics(props.createAnalyticsEvent({}), 'jpd.updateIssueValue.success tracked', {
				isBulkEditingOperation: true,
			});
		};

		const updateIssueSuccess = (
			isBulkEditingOperation: boolean,
			localIssueId: string,
			key: string,
		) => {
			onSuccess?.();
			updateIssueFieldExperience.success();

			const issueId = jiraIdMap[localIssueId];
			fireAnalyticsEventForIssueUpdate(props.createAnalyticsEvent({}), issueId, {
				updatedItems: [{ name: key }],
				...createGetIssueAnalyticsAttributes(localIssueId)(getState()),
			});
			fireTrackAnalytics(props.createAnalyticsEvent({}), 'jpd.updateIssueValue.success tracked', {
				isBulkEditingOperation,
			});
		};

		const prevProperties = cloneDeep(getState().properties);
		const updateIssueError = (err: Error, isBulkEditingOperation: boolean) => {
			if (onError) {
				// revert the optimistic state update on error
				setState({
					properties: prevProperties,
					lastUpdatedIssueIds: prevLastUpdatedIssueIds,
				});

				onError(err);
				updateIssueFieldExperience.successWithReason(err);
				return;
			}
			if (!isClientFetchError(err)) {
				updateIssueFieldExperience.failure(err);
			}
			fireErrorAnalytics({
				...createErrorAnalytics('jpd.updateIssueValue.error', err),
				attributes: {
					isBulkEditingOperation,
					statusCode:
						err instanceof ValidationError || err instanceof FetchError ? err?.statusCode : -1,
				},
			});

			if (field.type === FIELD_TYPES.ISSUE_TYPE) {
				logSafeErrorWithoutCustomerDataWrapper(
					'polaris.error.controllers.issue.actions.update-issue-issue-type',
					`Failed to update issue issue type, isBulkEditingOperation: ${isBulkEditingOperation}, statusCode: ${err instanceof ValidationError || err instanceof FetchError ? err?.statusCode : -1}`,
					err,
				);
			}
		};

		// optimistic state update
		const newMapping = getNewPropertiesMapping(
			fieldMapping,
			state.properties,
			localIssueIds,
			newValue,
			removeValue,
			appendMultiValues,
		);

		let fieldToDualWrite: Field | undefined;
		let fieldToDualWriteFieldMapping: ReturnType<ReturnType<typeof createGetFieldMapping>>;
		let propertiesForAtlasGoalsDualWrite: PropertyMaps | undefined;

		if (
			field.type === FIELD_TYPES.ATLAS_PROJECT ||
			field.type === FIELD_TYPES.ATLAS_GOAL ||
			field.type === FIELD_TYPES.PLATFORM_GOALS
		) {
			const result = getUpdatedStateForDualWrite({
				field,
				propertyMaps: newMapping,
				localIssueIds,
				newValue,
				removeValue,
				appendMultiValues,
				state,
				props,
			});

			fieldToDualWrite = result.fieldToDualWrite;
			fieldToDualWriteFieldMapping = result.fieldToDualWriteFieldMapping;

			if (field.type === FIELD_TYPES.PLATFORM_GOALS) {
				propertiesForAtlasGoalsDualWrite = result.newProperties;
			} else {
				setState({
					properties: result.newProperties,
					lastUpdatedIssueIds: localIssueIds,
				});
			}
		} else {
			setState({
				properties: newMapping,
				lastUpdatedIssueIds: localIssueIds,
			});
		}

		if (fg('jpd_issues_relationships')) {
			if (hasIssueTypeField([fieldKey], state, props)) {
				dispatch(
					resetConnectionFieldsValues(
						getConfiguredConnectionFieldsKeys(state, props),
						localIssueIds,
					),
				);
			}
		}

		const isBulkEditingOperation = localIssueIds.length > 1;

		if (performSideEffects && field.type !== FIELD_TYPES.REACTIONS) {
			if (field.type === FIELD_TYPES.STATUS) {
				// special handling for status fields. they are updated through a different endpoint
				// it is therefore required to use the update status action, and this becomes a noop
				throw new Error('Unable to update status field through updateFieldValueAction');
			} else if (
				hasBulkChangePermissions &&
				isBulkEditingOperation &&
				isBulkOperationSupported([field.key], state, props)
			) {
				updateIssueFieldExperience.mark('backend_request_start');

				if (fieldToDualWrite && fieldToDualWriteFieldMapping) {
					if (fieldToDualWrite.type === FIELD_TYPES.PLATFORM_GOALS) {
						dispatchUpdateGoalsFieldValue(fieldToDualWrite.key);

						updateFieldValuesOnBackendBulk(
							getState(),
							props,
							[field.key],
							localIssueIds,
							dispatch,
							updateIssueBulkSuccess,
							updateIssueError,
						);
					} else if (
						fieldToDualWrite.type === FIELD_TYPES.ATLAS_GOAL &&
						propertiesForAtlasGoalsDualWrite
					) {
						updateFieldValuesOnBackendBulk(
							{ ...getState(), properties: propertiesForAtlasGoalsDualWrite },
							props,
							[fieldToDualWrite.key],
							localIssueIds,
							dispatch,
							updateIssueBulkSuccess,
							updateIssueError,
						);
					} else {
						updateFieldValuesOnBackendBulk(
							getState(),
							props,
							[field.key, fieldToDualWrite.key],
							localIssueIds,
							dispatch,
							updateIssueBulkSuccess,
							updateIssueError,
						);
					}
				} else if (field.type !== FIELD_TYPES.PLATFORM_GOALS) {
					updateFieldValuesOnBackendBulk(
						getState(),
						props,
						fieldToDualWrite && fieldToDualWriteFieldMapping
							? [field.key, fieldToDualWrite.key]
							: [field.key],
						localIssueIds,
						dispatch,
						updateIssueBulkSuccess,
						updateIssueError,
					);
				}
			} else {
				updateIssueFieldExperience.mark('backend_request_start');

				if (fieldToDualWrite && fieldToDualWriteFieldMapping) {
					if (fieldToDualWrite.type === FIELD_TYPES.PLATFORM_GOALS) {
						dispatchUpdateGoalsFieldValue(fieldToDualWrite.key);

						updateFieldValueOnBackend(
							getState(),
							props,
							field.key,
							fieldMapping,
							localIssueIds,
							dispatch,
							updateIssueSuccess,
							updateIssueError,
						);
					} else if (
						fieldToDualWrite.type === FIELD_TYPES.ATLAS_GOAL &&
						propertiesForAtlasGoalsDualWrite
					) {
						updateFieldValueOnBackend(
							{ ...getState(), properties: propertiesForAtlasGoalsDualWrite },
							props,
							fieldToDualWrite.key,
							fieldToDualWriteFieldMapping,
							localIssueIds,
							dispatch,
							updateIssueSuccess,
							updateIssueError,
						);
					} else {
						updateFieldValuesOnBackend(
							getState(),
							props,
							[field.key, fieldToDualWrite.key],
							localIssueIds,
							dispatch,
							(_isBulkEditingOperation, localIssueId) => {
								updateIssueSuccess(isBulkEditingOperation, localIssueId, field.key);
							},
							updateIssueError,
						);
					}
				} else if (field.type !== FIELD_TYPES.PLATFORM_GOALS) {
					updateFieldValueOnBackend(
						getState(),
						props,
						field.key,
						fieldMapping,
						localIssueIds,
						dispatch,
						updateIssueSuccess,
						updateIssueError,
					);
				}
			}
		}

		return {
			filtered: dispatch(getIsFilteredResult(localIssueIds)),
		};
	};

export const updateFieldValues =
	(request: FieldValuesUpdateRequest) =>
	({ getState, setState, dispatch }: StoreActionApi<State>, props: Props) => {
		experience.view.updateIssueFields.start();

		const { hasBulkChangePermissions } = props;

		const jiraIdMap = getLocalIssueIdToJiraId(getState(), props);

		const updateIssueSuccess = (isBulkEditingOperation: boolean) => {
			experience.view.updateIssueFields.success();
			fireTrackAnalytics(props.createAnalyticsEvent({}), 'jpd.updateIssueValue.success tracked', {
				isBulkEditingOperation,
				isMultiValues: true,
			});
		};

		const prevProperties = cloneDeep(getState().properties);
		const prevLastUpdatedIssueIds = [...getState().lastUpdatedIssueIds];
		const updateIssueError = (err: Error, isBulkEditingOperation: boolean) => {
			// revert the optimistic state update on error
			setState({
				properties: prevProperties,
				lastUpdatedIssueIds: prevLastUpdatedIssueIds,
			});

			if (!isClientFetchError(err)) {
				experience.view.updateIssueFields.failure(err);
			}

			fireErrorAnalytics({
				...createErrorAnalytics('jpd.updateIssueValue.error', err),
				attributes: {
					isBulkEditingOperation,
					isMultiValues: true,
					statusCode:
						err instanceof ValidationError || err instanceof FetchError ? err?.statusCode : -1,
				},
			});

			if (Object.keys(request.fields).includes(ISSUETYPE_FIELDKEY)) {
				logSafeErrorWithoutCustomerDataWrapper(
					'polaris.error.controllers.issue.actions.update-issue-issue-type',
					`Failed to update issue issue type, isBulkEditingOperation: ${isBulkEditingOperation}, isMultiValues: true, statusCode: ${err instanceof ValidationError || err instanceof FetchError ? err?.statusCode : -1}`,
					err,
				);
			}
		};

		const { fields, localIssueIds } = request;

		const performSideEffects =
			request.performSideEffects !== undefined ? request.performSideEffects : true;

		let newState = getState();

		const fieldKeys = Object.keys(fields);
		const fieldKeysForBeUpdate = [...fieldKeys];

		const statusFieldValue = fields[STATUS_FIELDKEY]?.newValue;
		const transitionsByLocalIssueIds =
			statusFieldValue !== undefined
				? getTransitionsForTargetStatusByLocalIssueIds(
						localIssueIds,
						// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'Status'.
						statusFieldValue,
						newState,
						props,
					)
				: {};

		let didUpdate = fieldKeys.every((fieldKey) => {
			const { appendMultiValues: appendMultiValuesParam, removeValue } = fields[fieldKey];
			const appendMultiValues =
				appendMultiValuesParam !== undefined ? appendMultiValuesParam : false;
			const fieldMapping = createGetFieldMapping(fieldKey)(newState, props);
			const field = createGetField(fieldKey)(newState, props);
			if (!field || !fieldMapping) {
				return false;
			}

			let { newValue } = fields[fieldKey];

			// Below we convert newValue to full object (for status, user, people fields types)
			// as newValue can be retrieved from filters and there we don't store the full object

			if (
				(field.type === FIELD_TYPES.ASSIGNEE || field.type === FIELD_TYPES.REPORTER) &&
				newValue !== undefined
			) {
				newValue = Object.values(fieldMapping.getAllValues(newState, props)).find(
					// @ts-expect-error - TS2571 - Object is of type 'unknown'.
					(value) => value?.accountId === newValue.accountId,
				);
			}

			if (
				(field.type === FIELD_TYPES.PEOPLE || field.type === FIELD_TYPES.JSW_PEOPLE) &&
				isArray(newValue)
			) {
				const allValues = Object.values(fieldMapping.getAllValues(newState, props)).flat();
				newValue = newValue.map((user) =>
					allValues.find((value) => value?.accountId === user.accountId),
				);
			}

			if (field.type === FIELD_TYPES.STATUS && newValue !== undefined) {
				const isValidStatusTransition = Object.values(transitionsByLocalIssueIds).every(
					(transition) => transition !== undefined,
				);

				if (isValidStatusTransition) {
					newValue = Object.values(fieldMapping.getAllValues(newState, props)).find(
						// @ts-expect-error - TS2571 - Object is of type 'unknown'.
						(value) => value?.id === newValue.id,
					);
				} else {
					// Don't use newValue as the transition is invalid, instead use previous Status
					return true;
				}
			}

			const newMapping = getNewPropertiesMapping(
				fieldMapping,
				newState.properties,
				localIssueIds,
				newValue,
				removeValue,
				appendMultiValues,
			);

			if (DUAL_WRITE_SUPPORTED_FIELD_TYPES.has(field.type)) {
				const { fieldToDualWrite, newProperties } = getUpdatedStateForDualWrite({
					field,
					propertyMaps: newMapping,
					localIssueIds,
					newValue,
					removeValue,
					appendMultiValues,
					state: newState,
					props,
				});

				if (fieldToDualWrite?.key) {
					fieldKeysForBeUpdate.push(fieldToDualWrite.key);
				}

				newState = {
					...newState,
					properties: newProperties,
				};
			} else {
				newState = {
					...newState,
					properties: newMapping,
				};
			}

			return true;
		});

		if (!didUpdate) {
			return { filtered: undefined };
		}

		// optimistic state update
		setState(newState);

		if (fg('jpd_issues_relationships')) {
			if (hasIssueTypeField(fieldKeys, newState, props)) {
				dispatch(
					resetConnectionFieldsValues(
						getConfiguredConnectionFieldsKeys(newState, props),
						localIssueIds,
					),
				);
			}
		}

		let hasFilteredIssuesByStatus = false;

		const isBulkEditingOperation = localIssueIds.length > 1;
		if (performSideEffects) {
			const fieldKeysForBulkUpdate: FieldKey[] = [];
			didUpdate = fieldKeysForBeUpdate.every((fieldKey) => {
				const field = createGetField(fieldKey)(newState, props);
				if (!field) {
					return false;
				}

				if (field.type === FIELD_TYPES.STATUS) {
					Object.entries(transitionsByLocalIssueIds).forEach(
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						([issueId, transition]: [any, any]) => {
							if (transition !== undefined) {
								const { filtered } = dispatch(updateStatus(STATUS_FIELDKEY, issueId, transition));
								if (filtered) {
									hasFilteredIssuesByStatus = true;
								}
							}
						},
					);
					return true;
				}

				if (fg('jpd_issues_relationships')) {
					if (field.type === FIELD_TYPES.CONNECTION) {
						dispatch(
							updateConnectionFieldValue({
								localIssueIds,
								fieldKey,
								newValue: fields[fieldKey].newValue,
								removeValue: fields[fieldKey].removeValue,
								appendMultiValues: fields[fieldKey].appendMultiValues ?? false,
							}),
						);
						return true;
					}
				}

				if (fg('jpd_platform_goals_field_support')) {
					if (field.type === FIELD_TYPES.PLATFORM_GOALS) {
						dispatch(
							updateGoalsFieldValue({
								localIssueIds,
								fieldKey,
								newValue: fields[fieldKey].newValue,
								removeValue: fields[fieldKey].removeValue,
								appendMultiValues: fields[fieldKey].appendMultiValues ?? false,
							}),
						);
						return true;
					}
				}

				const fieldMapping = createGetFieldMapping(fieldKey)(newState, props);
				if (!fieldMapping) {
					return false;
				}
				if (fieldMapping.isSupportedByIssueUpdateApi) {
					fieldKeysForBulkUpdate.push(field.key);
				} else if (
					hasBulkChangePermissions &&
					isBulkEditingOperation &&
					isBulkOperationSupported([field.key], newState, props)
				) {
					updateFieldValuesOnBackendBulk(
						newState,
						props,
						[field.key],
						localIssueIds,
						dispatch,
						updateIssueSuccess,
						updateIssueError,
					);
				} else {
					updateFieldValuesOnBackend(
						newState,
						props,
						[field.key],
						localIssueIds,
						dispatch,
						updateIssueSuccess,
						updateIssueError,
					);
				}
				return true;
			});

			if (!didUpdate && !hasFilteredIssuesByStatus) {
				return { filtered: undefined };
			}

			if (fieldKeysForBulkUpdate.length > 0) {
				if (
					isBulkEditingOperation &&
					isBulkOperationSupported(fieldKeysForBulkUpdate, newState, props)
				) {
					updateFieldValuesOnBackendBulk(
						newState,
						props,
						fieldKeysForBulkUpdate,
						localIssueIds,
						dispatch,
						updateIssueSuccess,
						updateIssueError,
					);
				} else {
					updateFieldValuesOnBackend(
						newState,
						props,
						fieldKeysForBulkUpdate,
						localIssueIds,
						dispatch,
						updateIssueSuccess,
						updateIssueError,
					);
				}
			}
		}

		const updatedItems = Object.keys(request.fields).map((name) => ({ name }));
		const issueId = jiraIdMap[request.localIssueIds[0]];
		const { selectedViewId } = props;

		fireAnalyticsEventForIssueUpdate(
			props.createAnalyticsEvent({}),
			issueId,
			request.localIssueIds.length === 1
				? {
						updatedItems,
						selectedViewId,
						...createGetIssueAnalyticsAttributes(request.localIssueIds[0])(getState()),
					}
				: {
						updatedItems,
						issueCount: request.localIssueIds.length,
						issueIds: request.localIssueIds.map((id) =>
							createGetIdSelector(id)(getState())?.toString(),
						),
						actionType: 'bulk',
					},
		);

		return {
			filtered: dispatch(getIsFilteredResult(localIssueIds)) || hasFilteredIssuesByStatus,
		};
	};

export const updateFieldValueForSelectedIssue =
	<TFieldValue,>(
		request: FieldValueUpdateRequestForSelectedIssue<TFieldValue>,
		onSuccess?: () => void,
		onError?: (err?: Error) => void,
	) =>
	(api: StoreActionApi<State>, props: Props) => {
		const state = { ...api.getState() };
		const selectedIssueLocalIssueId = getSelectedIssueLocalIssueId(state);
		if (selectedIssueLocalIssueId !== undefined) {
			updateFieldValue(
				{
					localIssueIds: [selectedIssueLocalIssueId],
					...request,
				},
				onSuccess,
				onError,
			)(api, props);
		}
	};

export const updateFieldValueWithBulk =
	(
		localIssueId: LocalIssueId,
		fieldKey: FieldKey,
		newValue: Value,
		onSuccess?: () => void,
		onError?: (err?: Error) => void,
		onConnectionsUpdated?: () => void,
	) =>
	({ getState, dispatch }: StoreActionApi<State>, props: Props) => {
		const state = getState();

		const selectedIssueIds = getCurrentViewSelectedIssueIds(state);
		const sortedIssueIds = getSortedIssueIds(state, props);
		const field = createGetField(fieldKey)(state, props);

		const visibleSelectedIssueIds = sortedIssueIds.filter((id) => selectedIssueIds.includes(id));

		// Find the localIssueId in the array and move it to the first position
		const localIssueIdIdx = visibleSelectedIssueIds.indexOf(localIssueId);
		if (localIssueIdIdx !== -1) {
			visibleSelectedIssueIds.splice(localIssueIdIdx, 1);
			visibleSelectedIssueIds.unshift(localIssueId);
		}

		// Skip updating all selected issues if the update comes from issue connections tab
		const isBulkEditingOperation =
			fieldKey !== SUMMARY_FIELDKEY &&
			localIssueIdIdx !== -1 &&
			(!state.selectedIssue || !fg('jpd_issues_relationships'));

		const isPlatformGoalsField =
			fg('jpd_platform_goals_field_support') && field?.type === FIELD_TYPES.PLATFORM_GOALS;

		if (isBulkEditingOperation) {
			return dispatch(
				updateFieldValue(
					{
						fieldKey,
						localIssueIds: visibleSelectedIssueIds,
						newValue,
						skipNetworkRequest: isPlatformGoalsField,
					},
					onSuccess,
					onError,
					onConnectionsUpdated,
				),
			);
		}

		return dispatch(
			updateFieldValue(
				{
					fieldKey,
					localIssueIds: [localIssueId],
					newValue,
					skipNetworkRequest: isPlatformGoalsField,
				},
				onSuccess,
				onError,
				onConnectionsUpdated,
			),
		);
	};

export const resetLastUpdatedIssueIds =
	() =>
	({ setState, getState }: StoreActionApi<State>) => {
		// not set empty array again to prevent selectors re-calculations
		if (getState().lastUpdatedIssueIds.length === 0) {
			return;
		}
		setState({
			lastUpdatedIssueIds: [],
		});
	};
