/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Russian
export default {
  'fabric.elements.share.copied.to.clipboard.message': 'Ссылка скопирована в буфер обмена',
  'fabric.elements.share.copylink.button.text': 'Копировать ссылку',
  'fabric.elements.share.copypubliclink.button.text': 'Копировать публичную ссылку',
  'fabric.elements.share.failure.icon.label': 'Значок ошибки',
  'fabric.elements.share.failure.message': 'Не удается поделиться',
  'fabric.elements.share.form.comment.label': 'Сообщение (необязательно)',
  'fabric.elements.share.form.comment.placeholder': 'Что пользователям следует знать?',
  'fabric.elements.share.form.info.message.no.invite.confluence': 'Получатели увидят имя страницы и ваше сообщение',
  'fabric.elements.share.form.info.message.no.invite.jira': 'Получатели увидят имя задачи и ваше сообщение',
  'fabric.elements.share.form.info.message.no.invite.jira.issue-term-refresh':
    'Получатели увидят имя задачи и ваше сообщение',
  'fabric.elements.share.form.no-permissions': 'У вас нет возможности предоставить общий доступ.',
  'fabric.elements.share.form.public.send': 'Отправить публичную ссылку',
  'fabric.elements.share.form.public.share': 'Поделиться ссылкой общего доступа',
  'fabric.elements.share.form.required-field.summary': 'Обязательные поля отмечены звездочкой',
  'fabric.elements.share.form.retry': 'Повторить',
  'fabric.elements.share.form.send': 'Отправить',
  'fabric.elements.share.form.share': 'Поделиться',
  'fabric.elements.share.form.title': 'Поделиться',
  'fabric.elements.share.form.user-picker.add-more': 'Добавьте больше значений',
  'fabric.elements.share.form.user-picker.label.confluence': 'Имена, команды, группы или адреса электронной почты',
  'fabric.elements.share.form.user-picker.label.email-disabled-confluence': 'Имена, команды или группы',
  'fabric.elements.share.form.user-picker.label.email-disabled-jira': 'Имена или команды',
  'fabric.elements.share.form.user-picker.label.email-only': 'Адреса электронной почты',
  'fabric.elements.share.form.user-picker.label.jira': 'Имена, команды или адреса электронной почты',
  'fabric.elements.share.form.user-picker.no-options.existingUserOnly': 'Нет результатов по запросу «{inputValue}».',
  'fabric.elements.share.form.user-picker.no-options.generic':
    'Нет результатов по запросу «{inputValue}». Пригласите пользователей по адресу электронной почты.',
  'fabric.elements.share.form.user-picker.placeholder.confluence': 'например, Мария, команда «Апельсин», группа-один',
  'fabric.elements.share.form.user-picker.placeholder.email-disabled-jira': 'например, Мария, команда «Апельсин»',
  'fabric.elements.share.form.user-picker.placeholder.email-only': 'например, maria@company.com',
  'fabric.elements.share.form.user-picker.placeholder.jira': 'например, Мария, команда «Апельсин», maria@company.com',
  'fabric.elements.share.form.user-picker.validation.required':
    'Выберите хотя бы 1 пользователя, группу, команду или адрес эл. почты.',
  'fabric.elements.share.form.user-picker.validation.required-message.confluence':
    'Выберите хотя бы одного пользователя, команду, группу или адрес электронной почты',
  'fabric.elements.share.form.user-picker.validation.required-message.email-disabled-confluence':
    'Выберите хотя бы одного пользователя, команду или группу',
  'fabric.elements.share.form.user-picker.validation.required-message.email-disabled-jira':
    'Выберите хотя бы одного пользователя или команду',
  'fabric.elements.share.form.user-picker.validation.required-message.email-only':
    'Выберите хотя бы один адрес электронной почты',
  'fabric.elements.share.form.user-picker.validation.required-message.jira':
    'Выберите хотя бы одного пользователя, команду или адрес электронной почты',
  'fabric.elements.share.in.integration.button': 'Поделиться в {integrationName}',
  'fabric.elements.share.invite.to.confluence': 'Пригласить в Confluence',
  'fabric.elements.share.invite.to.jira': 'Пригласить в Jira',
  'fabric.elements.share.main.tab.text.confluence': 'Поделиться страницей',
  'fabric.elements.share.main.tab.text.jira': 'Поделиться задачей',
  'fabric.elements.share.menu.email.label': 'Электронная почта',
  'fabric.elements.share.menu.slack.label': 'Slack',
  'fabric.elements.share.popup.label': 'Поделиться',
  'fabric.elements.share.success.message':
    '{object, select,blogpost {Доступ к записи в блоге предоставлен}board {Доступ к доске предоставлен}calendar {Доступ к календарю предоставлен}draft {Доступ к черновику предоставлен}filter {Доступ к фильтру предоставлен}issue {Доступ к задаче предоставлен}summary {Доступ к теме предоставлен}list {Доступ к списку предоставлен}timeline {Доступ к хронологии предоставлен}form {Доступ к форме предоставлен}media {Доступ к медиафайлу предоставлен}page {Доступ к странице предоставлен}project {Доступ к проекту предоставлен}pullrequest {Доступ к запросу pull предоставлен}question {Доступ к вопросу предоставлен}report {Доступ к отчету предоставлен}repository {Доступ к репозиторию предоставлен}request {Доступ к запросу предоставлен}roadmap {Доступ к хронологии предоставлен}site {Доступ к сайту предоставлен}space {Доступ к разделу предоставлен}other {Доступ к ссылке предоставлен}}',
  'fabric.elements.share.to.integration.button': 'Поделиться в {integrationName}',
  'fabric.elements.share.trigger.button.icon.label': 'Значок «Поделиться»',
  'fabric.elements.share.trigger.button.invite.text': 'Пригласить',
  'fabric.elements.share.trigger.button.text': 'Поделиться',
  'fabric.elements.share.trigger.button.tooltip.text': 'Поделиться',
};
