import React, { useMemo, useRef } from 'react';
import { graphql, useFragment } from 'react-relay';
import Placeholder from '@atlaskit/react-ufo/placeholder';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { SORT_ASC } from '@atlassian/jira-native-issue-table/src/common/constants.tsx';
import type { async_issueViewCommonViews_AsyncConfigurableChildIssuesPanelUsingEntryPoint$key } from '@atlassian/jira-relay/src/__generated__/async_issueViewCommonViews_AsyncConfigurableChildIssuesPanelUsingEntryPoint.graphql';
import {
	ChildIssuesPanelSkeleton,
	ChildIssuesPanelSkeletonNew,
} from '@atlassian/jira-skeletons/src/ui/child-issues-panel/components/ChildIssuesPanelSkeleton.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import { SORT_OPTION_CREATED } from '../../../../model/types.tsx';
import { configurableChildIssuesPanelEntryPoint } from '../../entrypoint.tsx';
import { JQLBuilder, createConfigurableChildIssuesPanelQueryVariables } from '../utils/index.tsx';
import type TConfigurableChildIssuesPanel from './index.tsx';
import type { ConfigurableChildIssuesPanelProps } from './types.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyConfigurableChildIssuesPanel = lazy<typeof TConfigurableChildIssuesPanel>(
	() => import(/* webpackChunkName: "async-configurable-child-issues-panel" */ './index'),
	{ ssr: false },
);

const Skeleton = componentWithFG(
	'jira-issue-view-child-issues-panel-skeleton',
	ChildIssuesPanelSkeletonNew,
	ChildIssuesPanelSkeleton,
);

const AsyncConfigurableChildIssuesPanelUsingLazy = (props: ConfigurableChildIssuesPanelProps) => {
	return (
		<JSErrorBoundary
			id="AsyncConfigurableChildIssuesPanel"
			packageName="jiraIssueViewCommonViews"
			teamName="bento"
			fallback={() => <></>}
		>
			<Placeholder
				name="ConfigurableChildIssuesPanel"
				fallback={<Skeleton rows={props.totalChildIssueCount} />}
			>
				<LazyConfigurableChildIssuesPanel {...props} />
			</Placeholder>
		</JSErrorBoundary>
	);
};

interface ConfigurableChildIssuesPanelEntryPointProps {
	rankFieldJql: string;
	hideDone: boolean;
	// TODO: Remove on jira-issue-view-child-issues-panel-merge-subtasks cleanup
	filterSubtasks: boolean | null;
}

const useConfigurableChildIssuesPanelEntryPoint = ({
	filterSubtasks,
	hideDone,
	rankFieldJql,
}: ConfigurableChildIssuesPanelEntryPointProps) => {
	const cloudId = useCloudId();
	const issueKey = useIssueKey();

	const entryPointParams = useMemo(
		() => ({
			variables: createConfigurableChildIssuesPanelQueryVariables({
				cloudId,
				jql: JQLBuilder({
					issueKey,
					filterSubtasks,
					hideDone: hideDone ? 'true' : null,
					sortColumn: rankFieldJql,
					sortDirection: SORT_ASC,
				}),
				fieldSetIds: [],
				issueKey,
			}),
		}),
		[cloudId, filterSubtasks, hideDone, issueKey, rankFieldJql],
	);
	const hasLoadedEntrypoint = useRef(false);

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		configurableChildIssuesPanelEntryPoint,
		entryPointParams,
	);

	if (!hasLoadedEntrypoint.current) {
		entryPointActions.load();
		hasLoadedEntrypoint.current = true;
	}

	return { entryPointReferenceSubject };
};

const AsyncConfigurableChildIssuesPanelUsingEntryPoint = ({
	projectId,
	parentIssueTypeId,
	filterSubtasks,
	rootRelayFragment,
	headerComponents,
	totalChildIssueCount,
	Heading,
	progressSummary,
}: ConfigurableChildIssuesPanelProps) => {
	const data =
		useFragment<async_issueViewCommonViews_AsyncConfigurableChildIssuesPanelUsingEntryPoint$key>(
			graphql`
				fragment async_issueViewCommonViews_AsyncConfigurableChildIssuesPanelUsingEntryPoint on JiraQuery {
					userPreferences(cloudId: $cloudId) @optIn(to: "JiraUserPreferences") {
						isIssueViewHideDoneChildIssuesFilterEnabled
					}
					rankField(cloudId: $cloudId) {
						jqlTerm
					}
				}
			`,
			rootRelayFragment?.jira ?? null,
		);

	const rankFieldJql = data?.rankField?.jqlTerm || SORT_OPTION_CREATED;

	const runtimeProps = useMemo(
		() => ({
			headerComponents,
			filterSubtasks,
			projectId,
			parentIssueTypeId,
			rankFieldJql,
			rootRelayFragment,
			Heading,
			progressSummary,
		}),
		[
			headerComponents,
			filterSubtasks,
			projectId,
			parentIssueTypeId,
			rankFieldJql,
			rootRelayFragment,
			Heading,
			progressSummary,
		],
	);

	const { entryPointReferenceSubject } = useConfigurableChildIssuesPanelEntryPoint({
		filterSubtasks,
		rankFieldJql,
		hideDone: Boolean(data?.userPreferences?.isIssueViewHideDoneChildIssuesFilterEnabled),
	});

	return (
		<JiraEntryPointContainer
			id="AsyncConfigurableChildIssuesPanel"
			packageName="jiraIssueViewCommonViews"
			teamName="bento"
			entryPointReferenceSubject={entryPointReferenceSubject}
			fallback={<Skeleton rows={totalChildIssueCount} />}
			runtimeProps={runtimeProps}
		/>
	);
};

export const AsyncConfigurableChildIssuesPanel = componentWithFG(
	'jira-issue-view-child-issues-panel-entrypoint',
	AsyncConfigurableChildIssuesPanelUsingEntryPoint,
	AsyncConfigurableChildIssuesPanelUsingLazy,
);
