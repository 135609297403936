/**
 * @generated SignedSource<<39d637e2f84ca5abba6dda936d258d8d>>
 * @relayHash c6316e2741865b9b36b6345ea34bbcb2
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID bf2c1370016a2b38e58ddb562e7c61991ed2add534c17c2fa508f7338eb9ed1d

import type { ConcreteRequest, Query } from 'relay-runtime';
export type AccountStatus = "active" | "closed" | "inactive" | "%future added value";
export type commandPaletteAssignee_issueViewLayoutAssigneeFieldQuery$variables = {
  id: string;
  query: string;
};
export type commandPaletteAssignee_issueViewLayoutAssigneeFieldQuery$data = {
  readonly node: {
    readonly users?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly accountId: string;
          readonly accountStatus: AccountStatus;
          readonly id: string;
          readonly name: string;
          readonly picture: AGG$URL;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type commandPaletteAssignee_issueViewLayoutAssigneeFieldQuery = {
  response: commandPaletteAssignee_issueViewLayoutAssigneeFieldQuery$data;
  variables: commandPaletteAssignee_issueViewLayoutAssigneeFieldQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "searchBy",
    "variableName": "query"
  },
  {
    "kind": "Literal",
    "name": "suggested",
    "value": true
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = {
  "kind": "ScalarField",
  "name": "name"
},
v6 = {
  "kind": "ScalarField",
  "name": "picture"
},
v7 = {
  "kind": "ScalarField",
  "name": "accountStatus"
},
v8 = {
  "kind": "ScalarField",
  "name": "__typename"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "commandPaletteAssignee_issueViewLayoutAssigneeFieldQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v2/*: any*/),
                "concreteType": "JiraUserConnection",
                "kind": "LinkedField",
                "name": "users",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraUserEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ],
            "type": "JiraSingleSelectUserPickerField"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "commandPaletteAssignee_issueViewLayoutAssigneeFieldQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v2/*: any*/),
                "concreteType": "JiraUserConnection",
                "kind": "LinkedField",
                "name": "users",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraUserEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ],
            "type": "JiraSingleSelectUserPickerField"
          },
          (v4/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "id": "bf2c1370016a2b38e58ddb562e7c61991ed2add534c17c2fa508f7338eb9ed1d",
    "metadata": {},
    "name": "commandPaletteAssignee_issueViewLayoutAssigneeFieldQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "47cf495e56dcdefe7fbaa987d8eb93bd";

export default node;
