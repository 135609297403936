// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createHook, type Action } from '@atlassian/react-sweet-state';
import type { State } from './types.tsx';

const initialState: State = {
	isRealTimeCommentUpdated: false,
};

const actions = {
	setRealTimeCommentUpdated:
		(isRealTimeCommentUpdated: boolean): Action<State> =>
		({ setState }) => {
			setState({ isRealTimeCommentUpdated });
		},
};

type Actions = typeof actions;

const store = createStore<State, Actions>({
	name: 'issue-view.controllers.realtime-comments',
	initialState,
	actions,
});

export const useRealTimeComments = createHook(store);
