import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { PageTiming } from '@atlassian/jira-spa-state-controller/src/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createHook,
	createActionsHook,
	createContainer,
	createStore,
} from '@atlassian/react-sweet-state';
import { customFieldPageSegmentLoadMetric } from '../../common/utils/performance-analytics/main.tsx';
import {
	globalActions,
	containerActions,
	type GlobalActions,
	type ContainerActions,
} from './actions.tsx';
import type {
	GlobalState,
	ContainerState,
	ContainerStoreApi,
	ExtensionsMeasuredStartTime,
	PerformanceAttributes,
	PerformanceAdditionalTimings,
	PerformanceAnalyticsContainerProps,
} from './types.tsx';
import { defaultShouldMeasurePageSegmentLoad, getExtensionMeasuredKey } from './utils.tsx';

// Global Store: used by all extensions, persists across components remount
export const initialGlobalState = { extensionsMeasuredStartTime: {} } as const;

const globalStore = createStore<GlobalState, GlobalActions>({
	initialState: initialGlobalState,
	actions: globalActions,
	name: 'forge-ui-performance-analytics-global',
});

export const usePerformanceAnalytics = createHook(globalStore);

// Container Store: used by single extension (scoped by <PerformanceAnalyticsContainer />)
export const initialContainerState: ContainerState = {
	performanceAttributes: undefined,
	pageSegmentLoadPreviouslyMeasured: false,
	pageSegmentLoadMetric: undefined,
	pageSegmentLoadAdditionalTimings: undefined,
	interactionMetric: undefined,
	interactionAdditionalTimings: undefined,
};

const containerStore = createStore<ContainerState, ContainerActions>({
	initialState: initialContainerState,
	actions: containerActions,
	name: 'forge-ui-performance-analytics-container',
});

export const usePerformanceAnalyticsActions = createActionsHook(containerStore);

export function initContainerState(
	{ setState }: ContainerStoreApi,
	{
		issueKey,
		currentPageTiming,
		extensionsMeasuredStartTime,
		updateExtensionMeasuredStartTime,
		performanceAttributes,
		additionalTimings,
	}: {
		issueKey: IssueKey;
		currentPageTiming: PageTiming;
		extensionsMeasuredStartTime: ExtensionsMeasuredStartTime;
		updateExtensionMeasuredStartTime: (
			issueKey: IssueKey,
			extensionId: string,
			startTime: number,
		) => void;
		performanceAttributes: PerformanceAttributes;
		additionalTimings?: PerformanceAdditionalTimings;
	},
	shouldMeasurePageSegmentLoad: (
		source: string | undefined,
		renderContext: string,
	) => boolean = defaultShouldMeasurePageSegmentLoad,
) {
	const { extensionId, module, source, renderContext } = performanceAttributes;
	const { startTime: currentPageStartTime } = currentPageTiming;
	const extensionMeasuredKey =
		typeof extensionId === 'string' ? getExtensionMeasuredKey(issueKey, extensionId) : undefined;

	/**
	 * How to prevent `pageSegmentLoadMetric` to be measured on "every" component remount?
	 * - We store `extensionsMeasuredStartTime` in a global store for every extension (filled with `currentPageStartTime`)
	 * - `currentPageStartTime` is brought by @atlassian/jira-spa-state-controller
	 *
	 * We then measure a "pageSegmentLoad" metric:
	 * - if [extensionMeasuredKey][extensionsMeasuredStartTime] === undefined (initial load)
	 * - if [extensionMeasuredKey][extensionsMeasuredStartTime] < `currentPageStartTime` (renewed after each SPA transition)
	 *
	 * This way, "pageSegmentLoad" metric is only measured on initial load, SPA transition, but not during any other interaction causing a component remount.
	 */
	const pageSegmentLoadPreviouslyMeasured =
		typeof extensionMeasuredKey === 'string' &&
		typeof extensionsMeasuredStartTime[extensionMeasuredKey] === 'number' &&
		currentPageStartTime <= extensionsMeasuredStartTime[extensionMeasuredKey];

	switch (module) {
		case 'jira:customField':
		case 'jira:customFieldType': {
			if (shouldMeasurePageSegmentLoad(source, renderContext)) {
				const pageSegmentLoadMetric = customFieldPageSegmentLoadMetric(
					additionalTimings?.pageSegmentLoad,
				);

				// the trick here not to measure is simply not to start our metric
				if (!pageSegmentLoadPreviouslyMeasured && typeof extensionId === 'string') {
					pageSegmentLoadMetric.startFromPageLoad();
					updateExtensionMeasuredStartTime(issueKey, extensionId, currentPageStartTime);
				}

				setState({
					pageSegmentLoadPreviouslyMeasured,
					pageSegmentLoadMetric,
				});
			}

			setState({
				performanceAttributes,
				pageSegmentLoadAdditionalTimings: additionalTimings?.pageSegmentLoad,
				interactionAdditionalTimings: additionalTimings?.interaction,
			});

			break;
		}
		default: {
			// performance analytics turned off as default behavior
		}
	}
}

export const PerformanceAnalyticsContainer = createContainer<
	ContainerState,
	ContainerActions,
	PerformanceAnalyticsContainerProps
>(containerStore, {
	displayName: 'PerformanceAnalyticsContainer',
	onInit:
		() =>
		(
			storeApi: ContainerStoreApi,
			{
				issueKey,
				currentPageTiming,
				extensionsMeasuredStartTime,
				updateExtensionMeasuredStartTime,
				performanceAttributes,
				additionalTimings,
			},
		) =>
			initContainerState(storeApi, {
				issueKey,
				currentPageTiming,
				extensionsMeasuredStartTime,
				updateExtensionMeasuredStartTime,
				performanceAttributes,
				additionalTimings,
			}),
});
