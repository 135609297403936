/* eslint-disable @atlassian/relay/unused-fields */
import React, { useCallback, useMemo } from 'react';
import { commitLocalUpdate, graphql, useFragment, useRelayEnvironment } from 'react-relay';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import TextAreaInlineEditView from '@atlassian/jira-issue-internal-fields/src/text-area/text-area-inline-edit-view.tsx';
import messages from '@atlassian/jira-issue-view-base/src/common/multiline-plaintext/messages.tsx';

import MultilinePlainTextView from '@atlassian/jira-issue-view-base/src/common/multiline-plaintext/view.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import { getDisplayName } from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field.tsx';
import {
	useConnectRelayField,
	type PropsCallback,
} from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-relay-field.tsx';
import { ConnectedRelayFieldWrapper } from '@atlassian/jira-issue-view-common-views/src/connect-field/relay-field/field-wrapper.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import { convertWikiToAdf } from '@atlassian/jira-platform-convert-wiki-to-adf/src/main.tsx';
import type {
	plainTextField_issueViewLayoutRichTextField$data,
	plainTextField_issueViewLayoutRichTextField$key,
} from '@atlassian/jira-relay/src/__generated__/plainTextField_issueViewLayoutRichTextField.graphql';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { AggJiraRichText, CommonRichTextFieldProps } from '../../common/types.tsx';

type Props = CommonRichTextFieldProps & {
	richTextField: plainTextField_issueViewLayoutRichTextField$key;
};

/**
 * To create this field, in CMP create a custom field of type "Paragraph (supports rich text)"
 * Then go to the Global Field Configuration page: https://${your-instance}/secure/admin/ViewFieldLayouts.jspa
 * click "Configure" on one a scheme your field is in.
 * From there you can search for the rich text field and click on "Renderers"
 * Choose "Default Text Renderer" and click "Update"
 */
export const PlainTextField = ({ area, richTextField }: Props) => {
	const data = useFragment<plainTextField_issueViewLayoutRichTextField$key>(
		graphql`
			fragment plainTextField_issueViewLayoutRichTextField on JiraRichTextField {
				__id
				__typename
				type
				fieldId
				name
				description
				issue {
					issueId
					key
				}
				renderer
				fieldConfig {
					isEditable
				}
				richText {
					plainText
				}
			}
		`,
		richTextField,
	);
	const env = useRelayEnvironment();

	const issueId = useMemo(() => data.issue?.issueId, [data.issue?.issueId]);
	const issueKey = useMemo(() => data.issue?.key, [data.issue?.key]);
	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();
	const onSubmit = useCallback(
		(value: AggJiraRichText) => {
			issueId &&
				fieldChangeRequested(issueId, data.fieldId, value, undefined, {
					type: data.type,
					__typename: data.__typename,
					renderer: data.renderer,
				});
		},
		[data.__typename, data.fieldId, data.renderer, data.type, fieldChangeRequested, issueId],
	);

	const onSubmitSucceeded = useCallback(
		(value: AggJiraRichText) => {
			issueId &&
				fieldChanged(issueId, data.fieldId, value, {
					type: data.type,
					__typename: data.__typename,
					renderer: data.renderer,
				});
		},
		[data.__typename, data.fieldId, data.renderer, data.type, fieldChanged, issueId],
	);

	const onSubmitFailed = useCallback(
		() => issueId && fieldChangeFailed(issueId, data.fieldId),
		[data.fieldId, fieldChangeFailed, issueId],
	);

	const onSavePlainText = useCallback(
		async ({ value }: { value: string }) => {
			const newAggValue: AggJiraRichText = {
				plainText: value,
				adfValue: {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					json: convertWikiToAdf(value) as ADF,
				},
			};
			onSubmit(newAggValue);

			try {
				await performPutRequest(`/rest/api/2/issue/${issueKey}`, {
					body: JSON.stringify({
						fields: {
							[data.fieldId]: value,
						},
					}),
				});

				commitLocalUpdate(env, (store) => {
					const field = store.get(data.__id);
					if (!field) {
						return;
					}
					if (data.richText !== null) {
						field.setValue(value, 'plainText');
					}
				});
				onSubmitSucceeded(newAggValue);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (httpError: any) {
				onSubmitFailed();
			}
		},
		[
			data.__id,
			data.fieldId,
			data.richText,
			env,
			issueKey,
			onSubmit,
			onSubmitFailed,
			onSubmitSucceeded,
		],
	);

	const getComponentProps = useCallback<
		PropsCallback<
			plainTextField_issueViewLayoutRichTextField$key,
			plainTextField_issueViewLayoutRichTextField$data,
			string,
			AggJiraRichText,
			{
				noValueText: string;
				area: Area;
			}
		>
	>(
		({ intl }) => {
			const fieldValue = data?.richText?.plainText;
			return {
				jiraIssueField: data,
				value: fieldValue || '',
				onValueConfirm(componentValue) {
					return onSavePlainText({ value: componentValue });
				},
				additionalProps: {
					noValueText: intl.formatMessage(messages.noValue),
					area,
				},
			};
		},
		[data, area, onSavePlainText],
	);

	const connectField = useConnectRelayField(
		{ area, fragmentKey: richTextField },
		data,
		getComponentProps,
	);
	const componentName = getDisplayName(MultilinePlainTextView);

	return (
		<ConnectedRelayFieldWrapper componentName={componentName}>
			<TextAreaInlineEditView {...connectField.componentProps} />
		</ConnectedRelayFieldWrapper>
	);
};
