import React, { useCallback } from 'react';
import { DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { Box, Text, xcss } from '@atlaskit/primitives';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { AdControlProvider } from '@atlassian/ad-control-toolkit';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';

import { AdControls } from './ad-control-dropdown/view.tsx';
import messages from './messages.tsx';

type Props = {
	testId?: string;
	onDismiss: () => void;
	label?: string;
	actions?: (React.JSX.Element | null)[];
	searchQuery?: string;
};

export const AdControlSection = (props: Props) => {
	const { formatMessage } = useIntl();

	const {
		onDismiss: onDismissCallback,
		label = formatMessage(messages.adSectionTitle),
		testId = 'issue-view-foundation.quick-add.ad-control-section.dropdown-item',
		actions = [],
	} = props;

	const onDismiss = useCallback(() => {
		onDismissCallback();
		return Promise.resolve();
	}, [onDismissCallback]);

	return (
		<AdControlProvider>
			<UFOSegment name="quick-actions-ad-control-section">
				<JSErrorBoundary
					id="quick-actions-ad-control-section"
					packageName="jiraQuickActions"
					teamName="bento"
					fallback="unmount"
				>
					<DropdownItemGroup hasSeparator>
						<Box testId={testId} xcss={headerStyles}>
							<Text weight="bold" size="small" color="color.text.subtle">
								{label}
							</Text>

							<AdControls onDismiss={onDismiss} />
						</Box>
						{actions}
					</DropdownItemGroup>
				</JSErrorBoundary>
			</UFOSegment>
		</AdControlProvider>
	);
};

const headerStyles = xcss({
	paddingBlock: 'space.100',
	paddingInline: 'space.200',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
});
