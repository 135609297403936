import React from 'react';
import { useResourceWithCustomRouterContext } from '@atlassian/jira-resource-with-custom-router-context/src/controllers/use-resource-with-custom-router-context/index.tsx';
import { aiContextPanelResource } from '@atlassian/jira-servicedesk-ai-context-panel-resources/src/services/index.tsx';
import { AIContextServicePanel } from '@atlassian/jira-servicedesk-ai-context-service-panel/src/ui/index.tsx';
import { SERVICE_REQUEST_FEATURE } from '@atlassian/jira-servicedesk-common/src/service-project-features/constants.tsx';
import type { UseResourceHookResponse } from '@atlassian/react-resource-router';
import type { AIContextServicePanelResourceResponse } from '@atlassian/jira-servicedesk-ai-context-panel-resources/src/services/service/types.tsx';
import { useRefreshOnIssueIdChange } from '../../../controllers/use-refresh-on-issue-id-change/index.tsx';
import type { DummyRoute, DummyRouteMatchParams } from './types.tsx';

// Check the comments in the implementation of useResourceWithCustomRouterContext hook for more detail about this dummy route
const DUMMY_ROUTE_WITH_ISSUE_ID: DummyRoute = {
	name: 'SERVICEDESK_AI_CONTEXT_SERVICE_PANEL_DUMMY_ROUTE_NAME',
	path: '/servicedesk-ai-context-service-panel-dummy-route/:issueId/:issueKey/:practice/:skipChecks',
};

export const AIContextServicePanelWithPrefetchedCommonResource = ({ issueId, issueKey }: Props) => {
	const resource = useResourceWithCustomRouterContext(
		aiContextPanelResource,
		{
			route: DUMMY_ROUTE_WITH_ISSUE_ID,
			matchParams: {
				issueId,
				issueKey,
				practice: SERVICE_REQUEST_FEATURE,
				skipChecks: 1,
			} satisfies DummyRouteMatchParams,
		},
		{
			shouldPrefetch: true,
		},
	);
	useRefreshOnIssueIdChange({ issueId, refreshResource: resource.refresh });

	if (resource.data && resource.data.type !== 'ServicePanelSuggestions') {
		return null;
	}

	return (
		<AIContextServicePanel
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			resource={resource as UseResourceHookResponse<AIContextServicePanelResourceResponse>}
		/>
	);
};

type Props = {
	issueId: string;
	issueKey: string;
};
