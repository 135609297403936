import type { FieldMap } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { isShallowEqual } from '@atlassian/jira-polaris-lib-equals/src/index.tsx';
import {
	AccessForbiddenError,
	RemoteFieldsNotInitializedError,
	SharedViewAuthenticationMissingError,
	SharedViewFieldsUnavailableError,
} from '@atlassian/jira-polaris-remote-field/src/controllers/utils.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import {
	jpdProjectPageLoad,
	PAGE_LOAD_MARK_LOAD_FIELDS_START,
	PAGE_LOAD_MARK_LOAD_FIELDS_END,
} from '@atlassian/jira-polaris-component-metrics/src/project.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { Props, State } from '../types.tsx';

// Error message: [...] API-9998 permission check failed [...]
const isPermissionCheckError = (error: Error | unknown): boolean =>
	error instanceof Error ? error.message.search('API-9998') !== -1 : false;

const detectAndHandleCyclicFormulaDependency = (containerProps: Props, fields: FieldMap) => {
	const cyclicFieldLabels = Object.values(fields)
		.map((field) => (field.hasCyclicFormulaDependency ? field.label : undefined))
		.filter(Boolean);
	if (cyclicFieldLabels.length > 0) {
		containerProps.onCyclicFormulaDependencyDetected(cyclicFieldLabels);
	}
};

export const loadFields =
	(forceReload?: boolean): Action<State, Props> =>
	async ({ getState, setState }, containerProps) => {
		const { containerAri, isProjectOnboarded, fieldRemote, isSiteAdmin } = containerProps;
		const loadingProps = { containerAri, isProjectOnboarded };
		const {
			fields,
			meta: { loadingProps: previousLoadingProps, loading: isLoadingInProgress },
		} = getState();

		// Only load again when the props this function depends on have changed
		if (forceReload !== true && isShallowEqual(loadingProps, previousLoadingProps)) {
			return;
		}

		// Check if loading is already in progress and return early if it is
		if (isLoadingInProgress && fg('jpd-dont-refetch-fields')) {
			return;
		}

		// In the case of the first onboarding of the project, "isProjectOnboarded" will be first "false", and then "true".
		const gotIsOnboardedStateInFirstTime =
			isProjectOnboarded === true && previousLoadingProps?.isProjectOnboarded === undefined;

		if (
			// We will not fetch fields again if they are already pulled out once and the project has status onboarded = true.
			gotIsOnboardedStateInFirstTime &&
			Object.keys(fields).length > 0 &&
			fg('jpd-dont-refetch-fields')
		) {
			return;
		}

		jpdProjectPageLoad.mark(PAGE_LOAD_MARK_LOAD_FIELDS_START);

		setState({
			meta: {
				...getState().meta,
				initialized: false,
				loading: true,
				error: undefined,
				loadingProps,
			},
		});

		try {
			const fieldData = await fieldRemote.fetchAllFields();
			jpdProjectPageLoad.mark(PAGE_LOAD_MARK_LOAD_FIELDS_END);
			setState({
				meta: {
					...getState().meta,
					initialized: true,
					loading: false,
					error: undefined,
					loadingProps,
				},
				...fieldData,
			});
			detectAndHandleCyclicFormulaDependency(containerProps, fieldData.fields);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			jpdProjectPageLoad.mark(PAGE_LOAD_MARK_LOAD_FIELDS_END);

			if (error instanceof RemoteFieldsNotInitializedError) {
				if (fg('jpd-dont-refetch-fields')) {
					setState({
						meta: {
							...getState().meta,
							initialized: false,
							loading: false,
							loadingProps: undefined,
						},
					});
				}

				// we return early here,
				// we want to try loading again since the project is still loading
				return;
			}

			setState({
				meta: {
					...getState().meta,
					initialized: false,
					loading: false,
					error,
					loadingProps: undefined,
				},
				fields: {},
				fieldValueDecorations: {},
			});

			if (error instanceof AccessForbiddenError) {
				return;
			}

			if (
				error instanceof SharedViewFieldsUnavailableError ||
				error instanceof SharedViewAuthenticationMissingError
			) {
				return;
			}

			if (isPermissionCheckError(error) && isSiteAdmin) {
				// shallow perms error for site admins as they still need to access setting page
				// even without project permissions
				return;
			}

			containerProps.onFieldLoadingFailed(error);
		}
	};
