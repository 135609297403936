import React, { useCallback, useMemo, useRef, type ReactNode, useEffect } from 'react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import { token } from '@atlaskit/tokens';
import { Text, xcss, Inline } from '@atlaskit/primitives';
import { PopupUserPicker, type ActionTypes, type Value } from '@atlaskit/user-picker';

import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { INVITE_AND_ASSIGN_ID } from '@atlassian/jira-invite-and-assign/src/common/constants.tsx';
import { useInvitePeopleDrawer } from '@atlassian/jira-invite-people-drawer/src/controllers/index.tsx';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import {
	fireOperationalAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { ExperiencePerformanceTypes, ExperienceTypes, UFOExperience } from '@atlassian/ufo';
import { expVal } from '@atlassian/jira-feature-experiments';

import { INVITE_PEOPLE_ID, UNASSIGNED_ID } from '../../../common/constants.tsx';
import type { UserOption, UserValue } from '../../../common/types.tsx';
import { AssigneePickerView } from '../../../common/ui/read-view/popover/index.tsx';
import { getUserFromUserOption } from '../../../common/utils.tsx';
import messages from '../../../messages.tsx';
import { useAssigneeOptions } from '../../../services/assignee-options/index.tsx';
import useUsersQuery from '../../../services/users-query/index.tsx';
import type { AssigneePickerEditProps } from './types.tsx';

const DEFAULT_SEARCH_DEBOUCE_TIMEOUT = 300;
const ACTION_SUBJECT = 'assigneePicker';

const AssigneeFieldOptionsLoadExperience = new UFOExperience('assignee-picker.field-options-load', {
	type: ExperienceTypes.Operation,
	performanceType: ExperiencePerformanceTypes.Custom,
	category: 'assignee-picker.field-options-load',
});

const AssigneePickerEdit = ({
	actionSubject,
	shouldPreloadAssignToMe = false,
	autoCompleteUrl,
	value = null,
	onFocus,
	onBlur,
	onChange,
	searchDebounceTimeout = DEFAULT_SEARCH_DEBOUCE_TIMEOUT,
	enablePeopleInvite = true,
	popupPlacement = 'auto',
	isEligibleForInviteAndAssign,
	suggestedEmailDomain,
	onInviteAndAssignOption,
	onOpen,
	onClose,
	enableAutomaticOption = true,
	customFetchUsers,
	shouldShowNameLabel = false,
	strategy = 'fixed',
}: AssigneePickerEditProps) => {
	const intl = useIntl();
	const self = useRef<{
		previousQuery?: string;
	}>({ previousQuery: undefined }).current;

	const [{ data: users, loading, error }, fetchUsers] = useUsersQuery(
		autoCompleteUrl || '',
		fg('issue_cards_in_program_board') ? customFetchUsers : undefined,
	);
	const [assigneeValue, assigneeOptions] = useAssigneeOptions(
		value,
		users,
		intl,
		shouldPreloadAssignToMe,
		self.previousQuery,
		enablePeopleInvite,
		enableAutomaticOption,
		suggestedEmailDomain,
		isEligibleForInviteAndAssign,
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [, { openDrawer }] = useInvitePeopleDrawer();

	useEffect(() => {
		if (loading) {
			fireOperationalAnalytics(
				createAnalyticsEvent({
					actionSubject: actionSubject ?? ACTION_SUBJECT,
					action: 'loading',
				}),
				'assigneeOptions loading',
			);
			AssigneeFieldOptionsLoadExperience.start();
		}
		if (error) {
			fireOperationalAnalytics(
				createAnalyticsEvent({
					actionSubject: actionSubject ?? ACTION_SUBJECT,
					action: 'failed',
				}),
				'assigneeOptions failed',
			);
			AssigneeFieldOptionsLoadExperience.failure();
		}
		if (users.length) {
			fireOperationalAnalytics(
				createAnalyticsEvent({
					actionSubject: actionSubject ?? ACTION_SUBJECT,
					action: 'loaded',
				}),
				'assigneeOptions loaded',
			);
			AssigneeFieldOptionsLoadExperience.success();
		}
	}, [loading, error, users, createAnalyticsEvent, actionSubject]);

	const handleSearch: (query?: string) => void = useMemo(
		() =>
			debounce((query?: string) => {
				query !== self.previousQuery && fetchUsers(query);
				self.previousQuery = query;
				fireUIAnalytics(
					createAnalyticsEvent({
						actionSubject: actionSubject ?? ACTION_SUBJECT,
						action: 'searched',
					}),
				);
			}, searchDebounceTimeout),
		// go/jfe-eslint
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[fetchUsers, searchDebounceTimeout, self.previousQuery],
	);

	const handleOnFocus = useCallback(
		(sessionId?: string) => {
			onFocus?.(sessionId);
			fireUIAnalytics(
				createAnalyticsEvent({
					actionSubject: actionSubject ?? ACTION_SUBJECT,
					action: 'focused',
				}),
			);

			if (!users || !users.length) {
				fetchUsers();
			}
		},
		[onFocus, users, createAnalyticsEvent, actionSubject, fetchUsers],
	);

	const handleOnBlur = useCallback(
		(sessionId?: string) => {
			onBlur?.(sessionId);
			fireUIAnalytics(
				createAnalyticsEvent({
					actionSubject: actionSubject ?? ACTION_SUBJECT,
					action: 'blurred',
				}),
			);

			self.previousQuery = undefined;
		},
		[actionSubject, createAnalyticsEvent, onBlur, self],
	);

	const handleOnChange = useCallback(
		(updatedValue: Value, action: ActionTypes) => {
			if (!updatedValue || Array.isArray(updatedValue)) {
				return;
			}

			if (isEligibleForInviteAndAssign && updatedValue.id === INVITE_AND_ASSIGN_ID) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				onInviteAndAssignOption?.(updatedValue as UserOption, action);
				return;
			}

			if (fg('one_event_rules_them_all_fg')) {
				getUpdateAnalyticsFlowHelper().setAttributes('assignee', {
					oldValId: value ? value.accountId : null,
					newValId: updatedValue ? updatedValue.id : null,
				});
			}
			if (enablePeopleInvite && updatedValue.id === INVITE_PEOPLE_ID) {
				openDrawer(createAnalyticsEvent ?? null, { inviteFlow: 'assignee' });
				onChange?.(value, action);
				return;
			}

			const newValue: UserValue =
				updatedValue.id === UNASSIGNED_ID
					? null
					: users.find(({ accountId }) => accountId === updatedValue.id) ||
						getUserFromUserOption(updatedValue);

			onChange?.(newValue, action);
			if (fg('thor-issue-view-experiment-unassigned-icon')) {
				const oldValId = value ? value.accountId : null;
				fireUIAnalytics(createAnalyticsEvent({}), 'inlineEdit changed', 'inlineEdit changed', {
					isUnassignedClicked: oldValId === null,
				});
			} else {
				fireUIAnalytics(
					createAnalyticsEvent({
						actionSubject: actionSubject ?? ACTION_SUBJECT,
						action: 'changed',
					}),
				);
			}
		},
		[
			isEligibleForInviteAndAssign,
			enablePeopleInvite,
			users,
			onChange,
			createAnalyticsEvent,
			actionSubject,
			onInviteAndAssignOption,
			openDrawer,
			value,
		],
	);

	return (
		<PopupUserPicker
			fieldId={null}
			placeholder={intl.formatMessage(messages.searchForAssignee)}
			isLoading={loading}
			ariaLabel={intl.formatMessage(messages.searchForAssignee)}
			options={assigneeOptions}
			target={({ ref, isOpen }) =>
				/*
					onClick is being used here as a workaround. onOpen should be being passed to the PopupUserPicker
					but there is a bug with the atlaskit component, where the onOpen function is being called when the
					popup is closed.
				*/
				fg('jira_show_label_with_assignee_avatar') ? (
					<Inline xcss={buttonWrapperStyles} ref={ref}>
						<AvatarButton
							active={isOpen}
							onClick={onOpen}
							data-testid="issue-field-assignee.ui.popover.edit-view.test"
							{...(fg('jfp_a11y_autodev_fix_issue_assignee_edit_expanded')
								? { 'aria-expanded': isOpen }
								: {})}
						>
							{expVal(
								'thor_unassigned_icon_update_milestone1_experiment',
								'isUnassignedIconUpdated',
								false,
							) ? (
								<AssigneePickerView value={value} isOpen={isOpen} />
							) : (
								<AssigneePickerView value={value} />
							)}
						</AvatarButton>
						{shouldShowNameLabel && (
							<Text
								color="color.text.subtlest"
								testId="issue-field-assignee.ui.popover.edit-view.text"
							>
								{value ? value.displayName : intl.formatMessage(messages.unassignedOption)}
							</Text>
						)}
					</Inline>
				) : (
					<AvatarButton
						innerRef={ref}
						active={isOpen}
						onClick={onOpen}
						data-testid="issue-field-assignee.ui.popover.edit-view.test"
						{...(fg('jfp_a11y_autodev_fix_issue_assignee_edit_expanded')
							? { 'aria-expanded': isOpen }
							: {})}
					>
						{expVal(
							'thor_unassigned_icon_update_milestone1_experiment',
							'isUnassignedIconUpdated',
							false,
						) ? (
							<AssigneePickerView value={value} isOpen={isOpen} />
						) : (
							<AssigneePickerView value={value} />
						)}
					</AvatarButton>
				)
			}
			value={assigneeValue}
			onFocus={handleOnFocus}
			onBlur={handleOnBlur}
			onChange={handleOnChange}
			onInputChange={handleSearch}
			placement={popupPlacement}
			onClose={onClose}
			{...(fg('show_additional_fields_picker_popup_as_portal') ? { strategy } : {})}
		/>
	);
};

export default AssigneePickerEdit;

type AvatarButtonProps = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	innerRef?: any;
	active: boolean;
	children: ReactNode;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const AvatarButtonOld = styled.button<AvatarButtonProps>((props) => ({
	/* button css reset */
	display: 'block',
	background: 'transparent',
	border: 0,
	outline: 0,
	padding: 0,
	margin: 0,
	borderRadius: '50%',
	boxShadow: '0 0 0 2px transparent',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		cursor: 'pointer',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'span:first-of-type::after': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		opacity: props.active ? 1 : 0,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		backgroundColor: props.active ? token('color.interaction.pressed') : 'transparent',
	},
	'&:active': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
		boxShadow: '0 0 0 2px transparent !important',
	},
	'&:focus, &:focus-within': {
		boxShadow: `0 0 0 2px ${token('color.border.focused')}`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:hover span:first-of-type::after': {
		opacity: 1,
		backgroundColor: token('color.interaction.hovered'),
	},
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const AvatarButtonNew = styled.button<AvatarButtonProps>((props) => ({
	/* button css reset */
	display: 'block',
	background: 'transparent',
	border: 0,
	outline: 0,
	padding: 0,
	margin: 0,
	borderRadius: '50%',
	boxShadow: '0 0 0 2px transparent',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		cursor: 'pointer',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'span:first-of-type::after': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		opacity: props.active ? 1 : 0,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		backgroundColor: props.active ? token('color.interaction.pressed') : 'transparent',
	},
	'&:active': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
		boxShadow: '0 0 0 2px transparent !important',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:hover span:first-of-type::after': {
		opacity: 1,
		backgroundColor: token('color.interaction.hovered'),
	},
}));

const buttonWrapperStyles = xcss({
	alignItems: 'center',
	gap: 'space.050',
	cursor: 'pointer',
});

const AvatarButton = componentWithCondition(
	isVisualRefreshEnabled,
	AvatarButtonNew,
	AvatarButtonOld,
);
