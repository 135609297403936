/**
 * @generated SignedSource<<17988d9a03701ed30cceda863414106d>>
 * @relayHash ff4a6ae9d5bbc162748bb1bca8a2b1b8
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d2230a4692af2c3ead2e7042ef99fc99ff9794a71253a384c7a7654dcf644fe5

import type { ConcreteRequest, Query } from 'relay-runtime';
export type main_votersListService_issueFieldVoters_IssueVotersRelayQuery$variables = {
  cloudId: string;
  issueKey: string;
};
export type main_votersListService_issueFieldVoters_IssueVotersRelayQuery$data = {
  readonly jira: {
    readonly issueByKey: {
      readonly votesField: {
        readonly selectedUsersConnection: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly accountId: string;
              readonly id: string;
              readonly name: string;
              readonly picture: AGG$URL;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type main_votersListService_issueFieldVoters_IssueVotersRelayQuery = {
  response: main_votersListService_issueFieldVoters_IssueVotersRelayQuery$data;
  variables: main_votersListService_issueFieldVoters_IssueVotersRelayQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueKey"
},
v2 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "issueKey"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "id"
},
v4 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v5 = {
  "kind": "ScalarField",
  "name": "name"
},
v6 = {
  "kind": "ScalarField",
  "name": "picture"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "main_votersListService_issueFieldVoters_IssueVotersRelayQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueByKey",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraVotesField",
                "kind": "LinkedField",
                "name": "votesField",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraUserConnection",
                    "kind": "LinkedField",
                    "name": "selectedUsersConnection",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraUserEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "main_votersListService_issueFieldVoters_IssueVotersRelayQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueByKey",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraVotesField",
                "kind": "LinkedField",
                "name": "votesField",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraUserConnection",
                    "kind": "LinkedField",
                    "name": "selectedUsersConnection",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraUserEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "__typename"
                              },
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  (v3/*: any*/)
                ]
              },
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "d2230a4692af2c3ead2e7042ef99fc99ff9794a71253a384c7a7654dcf644fe5",
    "metadata": {},
    "name": "main_votersListService_issueFieldVoters_IssueVotersRelayQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "8ef7a1e2a722cf363b2ef7771616c306";

export default node;
