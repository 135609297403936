import React, { useContext, useEffect } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { IssueSmartRequestSummaryEntryPointContext } from '@atlassian/jira-issue-view-smart-request-summary-entrypoint/src/index.tsx';

const runtimeProps = {};

export const IssueSmartRequestSummaryEntryPointContainerOld = () => {
	const { entryPointReferenceSubject } = useContext(IssueSmartRequestSummaryEntryPointContext);

	return (
		<JiraEntryPointContainer
			entryPointReferenceSubject={entryPointReferenceSubject}
			id="issueSmartRequestSummary"
			packageName="issue"
			teamName="Bento"
			runtimeProps={runtimeProps}
			fallback={null}
			errorFallback="flag"
		/>
	);
};

export const IssueSmartRequestSummaryEntryPointContainer = () => {
	const { entryPointReferenceSubject, entryPointActions } = useContext(
		IssueSmartRequestSummaryEntryPointContext,
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	return (
		<JiraEntryPointContainer
			entryPointReferenceSubject={entryPointReferenceSubject}
			id="issueSmartRequestSummary"
			packageName="issue"
			teamName="Bento"
			runtimeProps={runtimeProps}
			fallback={null}
			errorFallback="flag"
		/>
	);
};
