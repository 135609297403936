import { useCallback } from 'react';
import { fireAIOpsEvent, useAIUnifiedAnalytics } from '@atlassian/eoc-aiops-instrumentation';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useVisibleActions } from '@atlassian/jira-servicedesk-ai-context-common/src/controllers/useVisibleActions.tsx';
import type { FeedbackDirection } from '@atlassian/jira-servicedesk-ai-context-common/src/ui/ai-context-container/feedback/index.tsx';
import type { ActionType } from '@atlassian/jira-servicedesk-ai-context-panel-resources/src/services/ops/common/types.tsx';
import type { IncidentSuggestionResource } from '../../common/types.tsx';

const VISIBLE_ACTIONS_EXCEPTIONS = ['problem_ticket', 'rca_with_automation'];

const TOP_SUGGESTED_ACTION_COUNT = 3;

export const useOpsPanel = (resource: IncidentSuggestionResource) => {
	const { data: incidentSuggestion, update } = resource;
	const { createAIOpsEventPayload } = useAIUnifiedAnalytics();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	if (!incidentSuggestion) {
		throw new Error('Assertion failed, incidentSuggestion data cannot be null in useOpsPanel');
	}

	const visibleActions = useVisibleActions({
		actions: incidentSuggestion.suggestedActions,
		exceptions: VISIBLE_ACTIONS_EXCEPTIONS,
	});
	const topVisibleActions = visibleActions.slice(0, TOP_SUGGESTED_ACTION_COUNT);

	const createOnRemoveAction = (actionType: ActionType) => () => {
		update((state) => {
			const newSuggestedActions = state.suggestedActions.filter(
				(action) => action.type !== actionType,
			);

			return {
				...state,
				suggestedActions: newSuggestedActions,
			};
		});
	};

	const onFeedbackClicked = useCallback(
		(direction: FeedbackDirection) => {
			fireAIOpsEvent(
				createAnalyticsEvent({}),
				createAIOpsEventPayload({
					type: 'feedback',
					isProactive: true,
					isAIOpsUsed: false,
					feedback: direction,
				}),
			);
		},
		[createAIOpsEventPayload, createAnalyticsEvent],
	);

	const onFeedbackSent = useCallback(
		(direction: FeedbackDirection) => {
			fireUIAnalytics(createAnalyticsEvent({}), 'aiContextPanelOpsFeedback clicked', {
				direction,
			});
		},
		[createAnalyticsEvent],
	);

	const handleDwellEvent = useCallback(() => {
		const suggestedActions = Object.fromEntries(
			topVisibleActions.map((action) => [action.type, true]),
		);

		fireAIOpsEvent(
			createAnalyticsEvent({}),
			createAIOpsEventPayload({
				type: 'viewed',
				isProactive: true,
				suggestedActions,
				doesNotMeetMAUCriteria: false,
				dwell: true,
				isAIOpsUsed: true,
				suggestions: {
					RCA: Boolean(incidentSuggestion.probableRCA),
				},
			}),
		);
	}, [topVisibleActions, createAnalyticsEvent, createAIOpsEventPayload, incidentSuggestion]);

	return [
		{
			incidentSuggestion,
			topVisibleActions,
		},
		{
			onFeedbackClicked,
			onFeedbackSent,
			createOnRemoveAction,
			handleDwellEvent,
		},
	] as const;
};
