import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { styled } from '@compiled/react';
import { graphql, usePaginationFragment, type RefetchFnDynamic } from 'react-relay';
import { IconButton } from '@atlaskit/button/new';
import EditIcon from '@atlaskit/icon/core/edit';
import { Box, xcss } from '@atlaskit/primitives';
import UFOLabel from '@atlaskit/react-ufo/label';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import usePressTracing from '@atlaskit/react-ufo/use-press-tracing';
import { type FormatOptionLabelMeta, CreatableSelect } from '@atlaskit/select';
import type { AtlaskitSelectRefType } from '@atlaskit/select/types';
import DeleteIcon from '@atlaskit/icon/core/delete';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import type { PopupComponentProps, TriggerProps } from '@atlaskit/popup';
import { layers } from '@atlaskit/theme/constants';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useActivationId } from '@atlassian/jira-tenant-context-controller/src/components/activation-id/index.tsx';
import type {
	Option,
	Group,
} from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types.tsx';
import { ModalEntryPointIconButtonTrigger } from '@atlassian/jira-modal-entry-point-icon-button-trigger/src/ModalEntryPointIconButtonTrigger.tsx';
import type {
	ColorOptionData,
	ColorPickerStateType,
} from '@atlassian/jira-option-color-picker/src/types.tsx';
import type {
	OptionToFilter,
	ActionMeta,
} from '@atlassian/jira-common-components-picker/src/model.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import {
	FRAGMENT_SELECTABLE_FIELD_OPTIONS_FIRST,
	SEARCH_DEBOUNCE_TIMEOUT,
	SELECTABLE_FIELD_PAGE_OPTIONS,
} from '@atlassian/jira-issue-field-constants/src/index.tsx';
import { useSuspenselessRefetch } from '@atlassian/jira-issue-hooks/src/services/use-suspenseless-refetch/index.tsx';
import { defaultSelectStyles } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/styled.tsx';

import { OptionTag } from '@atlassian/jira-option-color-picker/src/option-tag/index.tsx';
import useDebouncedCallback from '@atlassian/jira-platform-use-debounce/src/utils/use-debounce-callback/index.tsx';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import selectableFieldSearchRefetchQuery, {
	type selectableFieldSearchRefetchQuery as SelectableFieldRefetchQuery,
} from '@atlassian/jira-relay/src/__generated__/selectableFieldSearchRefetchQuery.graphql';
import type { ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment$key as SelectableFieldFragment } from '@atlassian/jira-relay/src/__generated__/ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment.graphql';
import { JiraIssueFieldOptionAri } from '@atlassian/ari/jira/issue-field-option';
import { ColorPickerForm } from '@atlassian/jira-option-color-picker/src/index.tsx';
import { COLOR_PICKER_STATE } from '@atlassian/jira-option-color-picker/src/constants.tsx';
import { getRandomColor } from '@atlassian/jira-option-color-picker/src/color-picker/utils/index.tsx';
import { DeleteOptionDialogEntryPoint } from './delete-option-modal/entrypoint.tsx';
import messages from './messages.tsx';
import {
	ACTION,
	type OperationType,
	type OptionsInput,
	type SelectableFieldEditViewWithFieldOptionsFragmentProps,
	type SelectableValueOption,
} from './types.tsx';
import { UFOCustomDataFragment } from './ufo-data-fragment/index.tsx';
import { getGroupedOptions, flattenOptions, flattenSelectedOptions } from './utils.tsx';

const EMPTY_USER_INPUT = '';

const LoadingIndicator = () => (
	<Box padding="space.100">
		<Spinner size="small" interactionName="loading" />
	</Box>
);

const isSingleSelectMilestone2Experiment = (fieldType?: string, totalOptionsCount = 0) => {
	return (
		totalOptionsCount <= FRAGMENT_SELECTABLE_FIELD_OPTIONS_FIRST &&
		fieldType === 'com.atlassian.jira.plugin.system.customfieldtypes:select' &&
		expVal(
			'thor_colourful_single_select_milestone2_experiment',
			'isColorfulSingleSelectEnabled',
			false,
		)
	);
};

/**
 * MultiSelectableFieldEditViewWithFieldOptionsFragment is a version of the edit view that allows
 * the passing of a fragment for experiences like GIC that might want to group fetch data on mount
 * instead of having a separate network request for it
 *
 * @param props [SelectableFieldEditViewWithFieldOptionsFragmentProps](./types.tsx)
 */
export const SelectableFieldEditViewWithFieldOptionsFragment = ({
	autoFocus = false,
	cachedOptions,
	classNamePrefix,
	closeMenuOnSelect = false,
	fieldId,
	fieldType,
	fieldOptionsFragmentRef,
	filterOptionsById = null,
	footer,
	formatGroupLabel,
	hasInfiniteScroll = true,
	validationState = 'default',
	isMulti = false,
	isClearable,
	isDisabled: isFieldDisabled = false,
	isInvalid = false,
	onChange,
	onFooterSelect,
	required = false,
	inputId,
	menuPosition,
	styles,
	value,
	loadingMessage,
	noOptionsMessage,
	loadOptions,
	optionsFirstCount = FRAGMENT_SELECTABLE_FIELD_OPTIONS_FIRST,
	optionsPageCount = SELECTABLE_FIELD_PAGE_OPTIONS,
	spacing = 'default',
	sortOptions,
	placeholder,
	recentOptionsLabel,
	allOptionsLabel,
	ariaLabel,
	ariaLabelledBy,
	onOptionChange,
}: SelectableFieldEditViewWithFieldOptionsFragmentProps) => {
	const { formatMessage } = useIntl();

	const selectFieldRef = useRef<AtlaskitSelectRefType>(null);

	const [colorPickerState, setColorPickerState] = useState<ColorPickerStateType>(
		COLOR_PICKER_STATE.IDLE,
	);
	const cloudId = useCloudId();
	const activationId = useActivationId();
	const [selectedOptionForEdit, setSelectedOptionForEdit] = useState<Option>();

	const loadOptionsTracing = usePressTracing('selectable-field-edit-view.load-options');

	const getPlaceholderMessage = useCallback(
		() => placeholder ?? formatMessage(messages.placeholder),
		[formatMessage, placeholder],
	);

	const getRecentOptionsLabel = useCallback(
		() => recentOptionsLabel ?? formatMessage(messages.recent),
		[formatMessage, recentOptionsLabel],
	);

	const getAllOptionsLabel = useCallback(
		() => allOptionsLabel ?? formatMessage(messages.all),
		[formatMessage, allOptionsLabel],
	);

	const onEditOption = (option: Option) => {
		setColorPickerState(COLOR_PICKER_STATE.EDIT);
		setSelectedOptionForEdit(option);
	};

	const onDeleteModalConfirm = (optionIdToDelete?: string | number) => {
		submitUpdateOperation(optionIdToDelete, ACTION.DELETE);
	};

	const {
		data: fieldOptionsSearchData,
		refetch,
		loadNext,
		hasNext,
		isLoadingNext,
	} = usePaginationFragment<SelectableFieldRefetchQuery, SelectableFieldFragment>(
		graphql`
			fragment ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment on Query
			@refetchable(queryName: "selectableFieldSearchRefetchQuery")
			@argumentDefinitions(
				id: { type: "ID!" }
				searchBy: { type: "String", defaultValue: "" }
				first: { type: "Int", defaultValue: 20 }
				after: { type: "String", defaultValue: null }
				filterById: { type: "JiraFieldOptionIdsFilterInput" }
				includeSingleSelectColorField: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/thor-colorful-single-select-milestone2-experiment.relayprovider"
				}
			) {
				node(id: $id) {
					...ufoDataFragment_issueSelectableFieldEditView_UFOCustomDataFragment
					... on JiraSingleSelectField {
						fieldOperations {
							canEdit
						}
						fieldOptions {
							totalCount
						}
					}
					... on JiraHasSelectableValueOptions {
						selectableValueOptions(
							searchBy: $searchBy
							first: $first
							after: $after
							filterById: $filterById
						)
							@connection(
								key: "selectableValue_issueFieldEditviewFull_fieldOptions__selectableValueOptions"
							) {
							edges {
								node {
									id
									selectableLabel
									selectableGroupKey
									selectableIconUrl
									... on JiraOption {
										isDisabled
										optionId
										color @include(if: $includeSingleSelectColorField) {
											colorKey
										}
										value
									}
									... on JiraVersion {
										versionId
									}
								}
							}
						}
					}
				}
			}
		`,
		fieldOptionsFragmentRef,
	);

	const hasAdminPermission = fieldOptionsSearchData.node?.fieldOperations?.canEdit ?? false;
	const totalOptionsCount = fieldOptionsSearchData.node?.fieldOptions?.totalCount ?? 0;
	// #region Common state (might be handy for other components)
	/** Determines if options are being loaded externally; used by the `loadOptions` callback */
	const [isLoadingOptions, setIsLoadingOptions] = useState<boolean>(false);

	/** The Relay options transformed to the AK Select `options` prop */
	const [loadedOptions, setLoadedOptions] = useState<SelectableValueOption[]>([]);
	const [initialLoadedOptions, setInitialLoadedOptions] = useState<SelectableValueOption[]>([]);

	const [searchByString, setSearchByString] = useState<string>('');

	// #endregion

	// #region Refetching suggestions
	const refetchSuggestions: RefetchFnDynamic<SelectableFieldRefetchQuery, SelectableFieldFragment> =
		useCallback(
			(variables, options = {}) =>
				refetch(variables, {
					...options,
					onComplete: () => {
						setIsLoadingOptions(true);
					},
				}),
			[refetch],
		);
	// #endregion

	// #region Debounced suspensless refetch helpers
	const [searchSuspenselessRefetch, isFetching, lastFetchError] = useSuspenselessRefetch<
		SelectableFieldRefetchQuery,
		SelectableFieldFragment
	>(selectableFieldSearchRefetchQuery, refetchSuggestions);

	const [searchSuspenselessNetworkRefetch] = useSuspenselessRefetch<
		SelectableFieldRefetchQuery,
		SelectableFieldFragment
	>(selectableFieldSearchRefetchQuery, refetchSuggestions, { fetchPolicy: 'network-only' });

	const searchSuspenselessNetworkRefetchWithTracing = useCallback(
		(...args: Parameters<typeof searchSuspenselessNetworkRefetch>) => {
			// Traces both `selectableFieldSearchRefetchQuery` and the `loadOptions` callback (if provided)
			loadOptionsTracing();

			return searchSuspenselessNetworkRefetch(...args);
		},
		[loadOptionsTracing, searchSuspenselessNetworkRefetch],
	);

	const searchSuspenselessRefetchWithTracing = useCallback(
		(...args: Parameters<typeof searchSuspenselessRefetch>) => {
			// Traces both `selectableFieldSearchRefetchQuery` and the `loadOptions` callback (if provided)
			loadOptionsTracing();

			return searchSuspenselessRefetch(...args);
		},
		[loadOptionsTracing, searchSuspenselessRefetch],
	);

	const [searchDebouncedSuspenselessRefetch] = useDebouncedCallback(
		searchSuspenselessRefetchWithTracing,
		SEARCH_DEBOUNCE_TIMEOUT,
	);

	const [searchLeadingEdgeSuspenselessRefetch] = useDebouncedCallback(
		searchSuspenselessRefetchWithTracing,
		SEARCH_DEBOUNCE_TIMEOUT,
		{ leading: true },
	);
	// #endregion

	// #region Common callbacks for the selector
	const onFocus = useCallback(() => {
		searchLeadingEdgeSuspenselessRefetch({
			id: fieldId,
			searchBy: searchByString,
			filterById: filterOptionsById,
			first: optionsFirstCount,
		});
	}, [
		fieldId,
		searchByString,
		searchLeadingEdgeSuspenselessRefetch,
		filterOptionsById,
		optionsFirstCount,
	]);

	const onSearchByStringChangeFunction = useCallback(
		(newSearchByString: string): void => {
			setSearchByString(newSearchByString);
			searchDebouncedSuspenselessRefetch({
				id: fieldId,
				searchBy: newSearchByString,
				filterById: filterOptionsById,
				first: optionsFirstCount,
			});
		},
		[fieldId, searchDebouncedSuspenselessRefetch, filterOptionsById, optionsFirstCount],
	);

	const handleFilterOption = useCallback(
		(option: OptionToFilter, query = ''): boolean =>
			option.data?.__isNew__ || option.label.toLowerCase().includes(query.toLowerCase()),
		[],
	);
	// #endregion

	const defaultFailedOption = useMemo(
		() => ({
			label: formatMessage(messages.failedFetch),
			content: formatMessage(messages.failedFetch),
			value: '',
			isDisabled: true,
		}),
		[formatMessage],
	);

	const selectableValueOptions = useMemo(() => {
		const { edges } = fieldOptionsSearchData.node?.selectableValueOptions || {};

		return (
			edges
				?.map((edge) => edge?.node ?? null)
				.filter(Boolean)
				.filter((option) => option && option.selectableLabel != null) || []
		);
	}, [fieldOptionsSearchData.node?.selectableValueOptions]);

	const options = useMemo(() => {
		if (lastFetchError != null) {
			return [defaultFailedOption];
		}

		const sortedOptions = sortOptions != null ? sortOptions(loadedOptions) : loadedOptions;

		const groupedOptions = getGroupedOptions(
			sortedOptions,
			cachedOptions,
			getAllOptionsLabel(),
			getRecentOptionsLabel(),
			formatMessage(messages.other),
		);

		return groupedOptions;
	}, [
		loadedOptions,
		lastFetchError,
		defaultFailedOption,
		cachedOptions,
		formatMessage,
		getAllOptionsLabel,
		getRecentOptionsLabel,
		sortOptions,
	]);
	// #endregion

	const selectedValue: ReadonlyArray<Option> | null = useMemo(() => {
		if (!value) {
			return null;
		}

		const optionsArray = Array.isArray(value) ? value : [value];

		return optionsArray.map((option): Option => {
			const optionBaseObject = {
				ari: option.id,
				id: option.versionId || option.optionId,
				label: option.selectableLabel || '',
				value: option.selectableLabel || '',
				isDisabled: option.isDisabled || undefined,
				groupKey: option.selectableGroupKey,
				content: '',
				iconUrl: option.selectableIconUrl,
			};
			if (isSingleSelectMilestone2Experiment(fieldType, totalOptionsCount)) {
				// Add customColor property if colorful single select experiment is enabled
				return {
					...optionBaseObject,
					customColor: option.color ? option.color.colorKey : null,
				};
			}
			return optionBaseObject;
		});
	}, [value, fieldType, totalOptionsCount]);

	const getSelectableValueOption = useCallback(
		(selectedOption: Option): SelectableValueOption | undefined => {
			if (!selectedOption.ari) {
				return undefined;
			}

			const fieldOptionId =
				typeof selectedOption.id === 'number' ? String(selectedOption.id) : selectedOption.id;

			const selectableValueOptionBaseObject = {
				id: selectedOption.ari,
				selectableLabel: selectedOption.label,
				selectableGroupKey: selectedOption.groupKey,
				selectableIconUrl: selectedOption.iconUrl,
				isDisabled: selectedOption.isDisabled ?? false,
				optionId: fieldOptionId,
				versionId: fieldOptionId,
			};

			if (isSingleSelectMilestone2Experiment(fieldType, totalOptionsCount)) {
				// Add color property if colorful single select experiment is enabled
				return {
					...selectableValueOptionBaseObject,
					color: selectedOption.customColor ? { colorKey: selectedOption.customColor } : null,
				};
			}

			return selectableValueOptionBaseObject;
		},
		[fieldType, totalOptionsCount],
	);

	const handleOnChangeNew = (
		selectedOption: Group | Option | readonly (Group | Option)[] | null | undefined,
		actionMeta: ActionMeta<Option>,
	): void => {
		if (onChange) {
			if (!selectedOption) {
				onChange(null, { action: actionMeta.action });
				return;
			}

			const option: Option[] = Array.isArray(selectedOption) ? selectedOption : [selectedOption];

			const selectedOptions = option.map((opt) => getSelectableValueOption(opt)).filter(Boolean);

			onChange(selectedOptions, {
				action: actionMeta.action,
				option:
					actionMeta.option && 'value' in actionMeta.option
						? getSelectableValueOption(actionMeta.option)
						: undefined,
				removedValue:
					actionMeta.removedValue && 'value' in actionMeta.removedValue
						? getSelectableValueOption(actionMeta.removedValue)
						: undefined,
			});
		}
	};

	const getLoadingMessageNew: (obj: { inputValue: string }) => React.ReactNode = useMemo(() => {
		if (loadingMessage) {
			return ({ inputValue }: { inputValue: string }) => loadingMessage(inputValue);
		}

		return () => formatMessage(messages.loading);
	}, [loadingMessage, formatMessage]);

	const getNoOptionsMessageNew: (obj: { inputValue: string }) => React.ReactNode = useMemo(() => {
		if (noOptionsMessage) {
			return ({ inputValue }: { inputValue: string }) => noOptionsMessage(inputValue);
		}

		return () => formatMessage(messages.empty);
	}, [noOptionsMessage, formatMessage]);

	const selectValidationState = useMemo(() => {
		if (isInvalid) {
			return 'error';
		}

		return validationState;
	}, [isInvalid, validationState]);

	const setOrLoadOptions = useCallback(async () => {
		if (loadOptions == null) {
			setLoadedOptions(selectableValueOptions);
		} else if (isLoadingOptions) {
			/**
			 * Call only when `refetch` completes, via the `isLoadingOptions` prop.
			 *
			 * This prevents `loadOptions` from getting called twice on initial render
			 * if the fetch policy includes getting cached data.
			 */
			const result = await loadOptions(selectableValueOptions);
			setLoadedOptions(result);
		}

		setIsLoadingOptions(false);
	}, [isLoadingOptions, loadOptions, selectableValueOptions]);

	useEffect(() => {
		if (!isFetching) {
			setOrLoadOptions();
		}
	}, [isFetching, setOrLoadOptions]);

	useEffect(() => {
		const { edges } = fieldOptionsSearchData.node?.selectableValueOptions || {};
		if (searchByString.length || !edges?.length) return;
		setInitialLoadedOptions(
			edges
				?.map((edge) => edge?.node ?? null)
				.filter(Boolean)
				.filter((option) => option && option.selectableLabel != null) || [],
		);
	}, [fieldOptionsSearchData, searchByString]);

	const shouldShowFooter = !!footer;

	const noOptionLabel = getNoOptionsMessageNew({ inputValue: EMPTY_USER_INPUT });

	const loadingLabel = getLoadingMessageNew({ inputValue: EMPTY_USER_INPUT });

	const isOptionShownInDropdown = (option: Option, inputValue: string) =>
		// will not be filtered out by ak/select based on user input (even if there is no refetch)
		handleFilterOption(option, inputValue) &&
		// not a manually added noOptionsMessageOption
		option.label !== noOptionLabel &&
		option.label !== loadingLabel &&
		// not in selected values
		!flattenSelectedOptions(selectedValue).find(
			(selectedOption) => selectedOption.value === option.value,
		);

	const filterOptionWithFooterAndShowNoOptionsMessage = (
		option: OptionToFilter,
		inputValue: string,
	) => {
		const isLoading = isFetching || isLoadingOptions;
		if (option.label === noOptionLabel) {
			return (
				isLoading !== true &&
				!flattenOptions(options).some((opt) => isOptionShownInDropdown(opt, inputValue))
			);
		}
		if (option.label === loadingLabel) {
			return (
				isLoading === true &&
				!flattenOptions(options).some((opt) => isOptionShownInDropdown(opt, inputValue))
			);
		}

		return handleFilterOption(option, inputValue); // apply default filtering function
	};

	const selectWithFooterOptions =
		shouldShowFooter === true
			? [
					...options,
					...(noOptionsMessage
						? [
								{
									label: noOptionsMessage(EMPTY_USER_INPUT) || '',
									isDisabled: true,
									value: noOptionsMessage(EMPTY_USER_INPUT) || '',
									content: noOptionsMessage(EMPTY_USER_INPUT) || '',
								},
							]
						: []),
					...(loadingMessage
						? [
								{
									label: loadingMessage(EMPTY_USER_INPUT) || '',
									isDisabled: true,
									value: loadingMessage(EMPTY_USER_INPUT) || '',
									content: '',
								},
							]
						: []),
				]
			: options;

	const onMenuScrollToBottom = () => {
		if (hasNext) {
			loadNext(optionsPageCount);
		}
	};

	const formatCreateLabel = (inputValue: string) => {
		if (footer === undefined) {
			return inputValue;
		}

		if (typeof footer === 'function') {
			return footer(inputValue);
		}

		return footer;
	};

	const resetColorPickerState = () => {
		setColorPickerState(COLOR_PICKER_STATE.IDLE);
		setSelectedOptionForEdit(undefined);
		setTimeout(() => {
			if (globalThis.document?.activeElement !== selectFieldRef?.current?.select) {
				selectFieldRef?.current?.select?.focus();
			}
		}, 0);
	};

	const isValidNewOption = (inputValue: string) => {
		return (
			!!footer ||
			(hasAdminPermission &&
				isSingleSelectMilestone2Experiment(fieldType, totalOptionsCount) &&
				!(isFetching || isLoadingOptions || isLoadingNext) &&
				inputValue.length &&
				!initialLoadedOptions.filter((option) => option.value === inputValue).length)
		);
	};

	const refreshOptions = (updatedOptions: SelectableValueOption[]) => {
		if (updatedOptions) {
			setInitialLoadedOptions(updatedOptions);
			setLoadedOptions(updatedOptions);
		}
		setTimeout(() => {
			searchSuspenselessNetworkRefetchWithTracing({
				id: fieldId,
				filterById: filterOptionsById,
				first: optionsFirstCount,
				searchBy: '',
				includeSingleSelectColorField: isSingleSelectMilestone2Experiment(
					fieldType,
					totalOptionsCount,
				),
			});
		}, 2000);
	};

	const handleCreateNewColorfulOption = (inputValue: string) => {
		if (onFooterSelect) {
			onFooterSelect?.(inputValue);
			return;
		}
		// Create a new option with a random color
		submitUpdateOperation(undefined, ACTION.ADD, {
			name: inputValue,
			color: getRandomColor(),
		});
	};

	const selectAriaLabel = ariaLabel || formatMessage(messages.selectLabel);
	const selectField = (
		<UFOLabel name="selectable-field-edit-view">
			<CreatableSelect
				ref={selectFieldRef}
				aria-label={selectAriaLabel}
				autoFocus={autoFocus}
				classNamePrefix={classNamePrefix}
				components={{ LoadingIndicator }}
				filterOption={
					shouldShowFooter === true
						? filterOptionWithFooterAndShowNoOptionsMessage
						: handleFilterOption
				}
				footer={footer}
				{...(formatGroupLabel ? { formatGroupLabel } : {})}
				{...(SelectableOptions
					? {
							formatOptionLabel: isSingleSelectMilestone2Experiment(fieldType, totalOptionsCount)
								? (option: Option, context: FormatOptionLabelMeta<Option>) => {
										return SelectableOptions(
											option,
											context,
											hasAdminPermission,
											fieldType,
											onEditOption,
											onDeleteModalConfirm,
											totalOptionsCount,
										);
									}
								: SelectableOptions,
						}
					: {})}
				isDisabled={isFieldDisabled || colorPickerState !== COLOR_PICKER_STATE.IDLE}
				isLoading={isFetching || isLoadingOptions || isLoadingNext}
				isMulti={isMulti}
				isClearable={isClearable}
				isRequired={required}
				loadingMessage={getLoadingMessageNew}
				noOptionsMessage={getNoOptionsMessageNew}
				onChange={handleOnChangeNew}
				onFocus={onFocus}
				onCreateOption={
					hasAdminPermission && isSingleSelectMilestone2Experiment(fieldType, totalOptionsCount)
						? handleCreateNewColorfulOption
						: onFooterSelect
				}
				onFooterSelect={
					hasAdminPermission && isSingleSelectMilestone2Experiment(fieldType, totalOptionsCount)
						? handleCreateNewColorfulOption
						: onFooterSelect
				}
				onInputChange={onSearchByStringChangeFunction}
				options={selectWithFooterOptions}
				placeholder={getPlaceholderMessage()}
				{...(hasInfiniteScroll && {
					onMenuScrollToBottom,
				})}
				{...(isSingleSelectMilestone2Experiment(fieldType, totalOptionsCount) && {
					menuIsOpen: colorPickerState === COLOR_PICKER_STATE.IDLE,
				})}
				shouldShowFooter={!!footer}
				styles={styles ?? defaultSelectStyles}
				validationState={selectValidationState}
				value={selectedValue}
				openMenuOnFocus
				closeMenuOnSelect={closeMenuOnSelect}
				spacing={spacing}
				inputId={inputId}
				menuPosition={menuPosition}
				formatCreateLabel={formatCreateLabel}
				isValidNewOption={isValidNewOption}
				hideSelectedOptions={!isSingleSelectMilestone2Experiment(fieldType, totalOptionsCount)}
				allowCreateWhileLoading
				aria-labelledby={ariaLabelledBy}
			/>
			{fieldOptionsSearchData.node && (
				<UFOCustomDataFragment
					hasSearchByString={searchByString.length > 0}
					baseIssueFieldFragmentRef={fieldOptionsSearchData.node}
				/>
			)}

			{loadOptions != null && <UFOLoadHold name="load-options-callback" hold={isLoadingOptions} />}
		</UFOLabel>
	);

	const getAvailableOptionsList = (): OptionsInput[] => {
		return initialLoadedOptions.map((option) => ({
			optionId: option?.optionId ?? null,
			value: option.selectableLabel ?? '',
			color: option?.color?.colorKey ?? null,
		}));
	};

	const submitUpdateOperation = (
		optionId: string | number | undefined,
		operation: OperationType,
		param?: ColorOptionData,
	) => {
		const updatedOptionsInput = getUpdatedOptions(operation, optionId, param);
		onOptionChange?.(updatedOptionsInput, operation).then((result) => {
			if (result?.data?.jira?.jwmUpdateField?.options) {
				refreshOptions([
					...result.data.jira.jwmUpdateField.options.map((option) => ({
						...option,
						id: JiraIssueFieldOptionAri.create({
							siteId: cloudId,
							activationId,
							fieldId: result?.data?.jira?.jwmUpdateField?.fieldId ?? fieldId,
							optionId: option.optionId,
						}).toString(),
					})),
				]);
			}
		});
	};

	const getUpdatedOptions = (
		operation: OperationType,
		optionId?: string | number,
		param?: ColorOptionData,
	): OptionsInput[] => {
		const optionsInput: OptionsInput[] = getAvailableOptionsList();
		switch (operation) {
			case ACTION.ADD:
				return [
					...optionsInput,
					{
						value: param?.name || '',
						color: param?.color,
					},
				];

			case ACTION.DELETE:
				return optionsInput.filter((opt) => opt?.optionId !== optionId);

			case ACTION.UPDATE:
				return optionsInput.map((opt) => {
					const isSelected = opt?.optionId === optionId;

					return {
						...opt,
						...(isSelected ? { value: param?.name, color: param?.color } : {}),
					};
				});

			default:
				return optionsInput;
		}
	};

	const popupContent = () => (
		<>
			<ColorPickerForm
				onInputBlur={(e) => {
					if (!e.relatedTarget) {
						e.stopPropagation();
					}
				}}
				onCancel={() => {
					resetColorPickerState();
				}}
				onSubmit={(param: ColorOptionData) => {
					resetColorPickerState();
					submitUpdateOperation(selectedOptionForEdit?.id, ACTION.UPDATE, param);
				}}
				initialData={{
					name: selectedOptionForEdit?.label ?? '',
					color: selectedOptionForEdit?.customColor ?? '',
				}}
				type={colorPickerState}
				projectType="software"
				colorPaletteVariant="new"
				colorCardVariant="outline"
				showCancelButton={false}
				availableOptions={getAvailableOptionsList().map((opt) => opt.value.trim())}
			/>
		</>
	);
	return hasAdminPermission && isSingleSelectMilestone2Experiment(fieldType, totalOptionsCount) ? (
		<Popup
			zIndex={layers.modal()}
			isOpen={colorPickerState !== COLOR_PICKER_STATE.IDLE}
			autoFocus={false}
			placement="bottom-start"
			fallbackPlacements={['bottom-end']}
			trigger={(triggerProps: TriggerProps) => (
				<span {...triggerProps} role="menu">
					{selectField}
				</span>
			)}
			popupComponent={PopupContainer}
			onClose={resetColorPickerState}
			content={popupContent}
			messageId="issue-selectable-field-edit-view.ui.popup"
			messageType="transactional"
		/>
	) : (
		selectField
	);
};

const renderDeleteButton = (
	option: Option,
	context: FormatOptionLabelMeta<Option>,
	hasAdminPermission?: Boolean,
	fieldType?: string,
	onDeleteModalConfirm?: (optionIdToDelete?: string | number) => void,
	totalOptionsCount = 0,
) => {
	if (
		hasAdminPermission &&
		isSingleSelectMilestone2Experiment(fieldType, totalOptionsCount) &&
		totalOptionsCount > 1 &&
		context.context !== 'value' &&
		option.id
	) {
		return (
			<Box xcss={InlineEditBoxStyles}>
				<ModalEntryPointIconButtonTrigger
					testId={`selectable-field-edit-view.inline-delete-button-${option.id}`}
					appearance="subtle"
					spacing="compact"
					entryPoint={DeleteOptionDialogEntryPoint}
					entryPointProps={{
						optionIdToDelete: option.id,
						onDeleteModalConfirm,
					}}
					interactionName="single-select-option-delete"
					modalProps={{ width: 'small' }}
					useInternalModal
					icon={DeleteIcon}
					label=""
					isDisabled={option.isDisabled}
					onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
						event.stopPropagation();
					}}
				/>
			</Box>
		);
	}

	return null;
};

const renderInlineEditButton = (
	option: Option,
	context: FormatOptionLabelMeta<Option>,
	hasAdminPermission?: boolean,
	fieldType?: string,
	onEditOption?: (option: Option) => void,
	totalOptionsCount?: number,
) => {
	if (
		hasAdminPermission &&
		isSingleSelectMilestone2Experiment(fieldType, totalOptionsCount) &&
		context.context !== 'value' &&
		option.id
	)
		return (
			<Box xcss={InlineEditBoxStyles}>
				<IconButton
					testId={`selectable-field-edit-view.inline-edit-button-${option.id}`}
					isDisabled={option.isDisabled}
					appearance="subtle"
					icon={EditIcon}
					spacing="compact"
					onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
						event.stopPropagation();
						onEditOption?.(option);
					}}
					label=""
				/>
			</Box>
		);

	return null;
};

const RenderLabel = ({
	option,
	context,
	fieldType,
	totalOptionsCount,
}: {
	option: Option;
	context: FormatOptionLabelMeta<Option>;
	fieldType?: string;
	totalOptionsCount?: number;
}) => {
	const { formatMessage } = useIntl();
	if (
		isSingleSelectMilestone2Experiment(fieldType, totalOptionsCount) &&
		context.context !== 'value'
	) {
		return (
			<LabelTextWrapper>
				<OptionTag name={option.label} color={option.customColor} />
				{isSingleSelectMilestone2Experiment(fieldType, totalOptionsCount) && !option.id
					? formatMessage(messages.newOptionLabel)
					: ''}
			</LabelTextWrapper>
		);
	}

	return <LabelTextWrapper>{option.label}</LabelTextWrapper>;
};

export const SelectableOptions = (
	option: Option,
	context: FormatOptionLabelMeta<Option>,
	hasAdminPermission?: boolean,
	fieldType?: string,
	onEditOption?: (option: Option) => void,
	onDeleteModalConfirm?: (optionIdToDelete?: string | number) => void,
	totalOptionsCount?: number,
) => {
	return (
		<OptionWrapper>
			{option.iconUrl ? (
				<ImgSpan>
					<img
						src={option.iconUrl || ''}
						alt={option.label}
						width={2 * gridSize}
						height={2 * gridSize}
						role="none"
					/>
				</ImgSpan>
			) : null}
			<RenderLabel option={option} context={context} fieldType={fieldType} />
			{renderInlineEditButton(
				option,
				context,
				hasAdminPermission,
				fieldType,
				onEditOption,
				totalOptionsCount,
			)}
			{renderDeleteButton(
				option,
				context,
				hasAdminPermission,
				fieldType,
				onDeleteModalConfirm,
				totalOptionsCount,
			)}
		</OptionWrapper>
	);
};
type ExtendedPopupComponentProps = PopupComponentProps & {
	onClick?: (e: React.MouseEvent) => void;
};

const PopupContainer = forwardRef<HTMLDivElement, ExtendedPopupComponentProps>((props, ref) => (
	<div {...props} ref={ref} />
));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LabelTextWrapper = styled.span({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	flex: 1,
});

const InlineEditBoxStyles = xcss({
	visibility: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const OptionWrapper = styled.span({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'&:hover > div': {
		visibility: 'visible',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const ImgSpan = styled.span({
	display: 'flex',
	paddingRight: token('space.100'),
});
