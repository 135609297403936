import React from 'react';
import { styled } from '@compiled/react';
import AkSpinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<SpinnerWrapper>
		<SpinnerAligner>
			<AkSpinner size="large" />
		</SpinnerAligner>
	</SpinnerWrapper>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerWrapper = styled.div({
	display: 'flex',
	minHeight: '500px',
	justifyContent: 'center',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerAligner = styled.div({
	position: 'relative',
	bottom: token('space.400'),
});
