import { useMemo } from 'react';
import { templates as defaultTemplates } from '../templates/index.tsx';
import type { Document } from '../types.tsx';

export const useTemplateSelection = (
	templateId: string,
	templates = defaultTemplates,
): Document => {
	const document = useMemo(() => {
		const template = templates[templateId];
		if (template) {
			return {
				templateId,
				document: template.template,
				template,
			};
		}
		return {
			templateId,
			document: { version: 1, type: 'doc', content: [] },
			template: null,
		};
	}, [templateId, templates]);

	return document;
};
