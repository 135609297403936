import { getAutomationStack } from '@atlassian/jira-automation-platform/src/common/utils.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { Environment } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import type { RuleConfig } from '../common/types.tsx';
import type { RuleState } from '../common/constants.tsx';

export type FetchRuleConfigResult = {
	key?: string;
	value: RuleConfig | null;
};

export type RuleSummaryResponse = {
	id: string;
	idUuid: string;
	name: string;
	description: string;
	ruleState: RuleState;
	authorAccountId: string;
};

export const fetchIssueProperties = async (issueKey: string): Promise<FetchRuleConfigResult> => {
	const recurrenceConfigEndpoint = `/rest/api/2/issue/${issueKey}/properties/recurrence-config`;
	try {
		return await performGetRequest(recurrenceConfigEndpoint);
	} catch (error) {
		if (error instanceof FetchError && error.statusCode === 404) {
			return Promise.resolve({
				value: null,
			});
		}
		throw error;
	}
};

export const fetchRuleSummary = async (
	environment: Environment | null,
	cloudId: string,
	projectId: string,
	ruleUuid: string,
): Promise<RuleSummaryResponse | null> => {
	const automationStack = await getAutomationStack(environment, cloudId);
	const summaryEndpoint = `/gateway/api/automation/internal-api/jira/${cloudId}/${automationStack}/rest/${projectId}/rule/${ruleUuid}/summary`;

	try {
		return await performGetRequest(summaryEndpoint);
	} catch (error) {
		if (error instanceof FetchError && error.statusCode === 404) {
			return null;
		}
		throw error;
	}
};
