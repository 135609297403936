/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { AppSkeletonImage } from '../../../common/ui/AppSkeletonImage.tsx';
import imgSVG from '../assets/native-issue-table-skeleton.tpl.svg';
import imgSVGNew from '../assets/native-issue-table-skeleton-new.tpl.svg';

const customCssStyles = css({
	// increase self specificity to ensure deterministic override
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		border: 'none',
	},
});

const MAX_ROWS = 10;
const ROW_HEIGHT_EM = 2.86;

const containerStyles = { height: '448px' } as const;

export const ChildIssuesPanelSkeleton = () => (
	<AppSkeletonImage src={imgSVG} css={customCssStyles} containerStyles={containerStyles} />
);

export const ChildIssuesPanelSkeletonNew = ({ rows = MAX_ROWS }: { rows?: number }) => {
	const height = ROW_HEIGHT_EM + ROW_HEIGHT_EM * Math.min(rows, MAX_ROWS); // Header plus max number of rows

	return (
		<AppSkeletonImage
			src={imgSVGNew}
			css={customCssStyles}
			containerStyles={{ height: `${height}em` }}
		/>
	);
};
