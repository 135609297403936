import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { FlagRenderer } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';

type Props = {
	error: Error | ValidationError;
	title: MessageDescriptor | string;
	description: MessageDescriptor;
};

export const ErrorFlag = ({ error, title, description }: Props) => {
	if (!error) {
		return null;
	}
	if (error instanceof ValidationError) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const errorMessage: any =
			error.message || (error.errors && error.errors[0] && error.errors[0].error) || description;

		return (
			<FlagRenderer
				flag={{
					messageId:
						'issue-error-flag.flag.warning.issue.fields.components.error-flag.flag.warning',
					messageType: 'transactional',
					type: 'warning',
					title,
					description: errorMessage,
					testId: fg('relay-migration-issue-fields-number')
						? 'issue-error-flag.error-flag.warning'
						: undefined,
				}}
			/>
		);
	}
	return (
		<FlagRenderer
			flag={{
				messageId: 'issue-error-flag.flag.error.issue.fields.components.error-flag.flag.error',
				messageType: 'transactional',
				type: 'error',
				title,
				description,
				testId: fg('relay-migration-issue-fields-number')
					? 'issue-error-flag.error-flag.error'
					: undefined,
			}}
		/>
	);
};
