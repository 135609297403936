import { useCallback, useMemo } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import {
	useAnalyticsEvents,
	fireTrackAnalytics,
	type ActionSubjectAndAction,
} from '@atlassian/jira-product-analytics-bridge';
import type { AnalyticsAttributesUntyped } from '@atlassian/jira-product-analytics-bridge/src/types';
import {
	useApplication,
	useEdition,
	useProjectKey,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { JIRA_SOFTWARE } from '@atlassian/jira-shared-types/src/application.tsx';
import { PREMIUM_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import type { SubscriptionError } from '../../services/issue-breakdown/types.tsx';
import { useIssueBreakdown } from '../../controllers/context.tsx';
import { aiwbAnalyticsAttrs } from '../../constants.tsx';

type Params = {
	channelId?: string | null;
	consumer?: string;
};

export const IMPROVE_ISSUE_DROPDOWN_ID = 'improveIssueDropdown';
export const ISSUE_BREAKDOWN_BUTTON_ID = 'issueBreakdownButton';
export const ISSUE_BREAKDOWN_PROACTIVE_CHILD_PANEL_ADD_ID = 'childPanelAddButton';
export const ISSUE_BREAKDOWN_PROACTIVE_QUICK_ADD_ID = 'quickActionsAddChildButton';

export const useAiWorkBreakDownAnalytics = (params: Params = {}) => {
	const { channelId, consumer } = params;
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [{ issueBreakDownButtonClickCount }] = fg(
		'proactive_ai_suggestions_for_child_items_targeting',
	)
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useIssueBreakdown()
		: [{ issueBreakDownButtonClickCount: undefined }];
	const isProactiveExperimentEnabled =
		fg('proactive_ai_suggestions_for_child_items_targeting') &&
		UNSAFE_noExposureExp('proactive_ai_suggestions_for_child_items')[0]?.get(
			'shouldProactivelyTriggerAIWB',
			false,
		);

	const actions = useMemo(() => {
		const analyticsEvent = createAnalyticsEvent({});
		const defaultAttrs = {
			...aiwbAnalyticsAttrs,
			singleInstrumentationID: channelId,
			consumer,
			...(fg('proactive_ai_suggestions_for_child_items_targeting')
				? {
						proactiveAIGenerated: issueBreakDownButtonClickCount === 0 ? 1 : 0,
						isProactiveExperimentEnabled,
					}
				: {}),
		};
		return {
			analyticsEvent,
			fireTrack: (name: ActionSubjectAndAction, id: string, attrs?: AnalyticsAttributesUntyped) =>
				fireTrackAnalytics(analyticsEvent, name, id, { ...defaultAttrs, ...attrs }),
		};
	}, [
		channelId,
		consumer,
		createAnalyticsEvent,
		isProactiveExperimentEnabled,
		issueBreakDownButtonClickCount,
	]);
	return actions;
};

export const useAiWorkBreakDownExperienceAnalyticsOld = () => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const application = useApplication(projectKey, true);
	const edition = useEdition(projectKey, true);

	const analyticsSource = 'jiraSuggestedChildIssue';
	const experience = 'aiIssueBreakdown';

	// Currently we are sending notEnoughInformation, noFurtherSuggestions and unethicalContent as success event.
	// We want to track these valid errors.
	const sendSuccessEvent = useCallback(
		(validError?: SubscriptionError) => {
			sendExperienceAnalytics({
				experience,
				wasExperienceSuccesful: true,
				analyticsSource,
				application: application ?? null,
				edition: edition ?? null,
				additionalAttributes: validError ? { validError } : {},
			});
		},
		[application, edition],
	);

	const sendFailedEvent = useCallback(
		(error: SubscriptionError, errorMessage?: string | null, statusCode?: number | null) => {
			sendExperienceAnalytics({
				experience,
				wasExperienceSuccesful: false,
				analyticsSource,
				application: application ?? null,
				edition: edition ?? null,
				additionalAttributes: {
					error,
					...(fg('ai_work_breakdown_gql_error_fields_query')
						? {
								statusCode: statusCode ?? 'No status code',
								jiraErrorMessage: errorMessage ?? 'No jira error message',
							}
						: {}),
				},
			});
		},
		[application, edition],
	);

	return { sendSuccessEvent, sendFailedEvent };
};

export const useAiWorkBreakDownExperienceAnalyticsNew = ({
	projectKey,
	consumer,
}: {
	projectKey: string;
	consumer: string;
}) => {
	const application = useApplication(projectKey, true);
	const edition = useEdition(projectKey, true);

	const analyticsSource = `jiraSuggestedChildIssue-${consumer}`;
	const experience = 'aiIssueBreakdown';

	// Currently we are sending notEnoughInformation, noFurtherSuggestions and unethicalContent as success event.
	// We want to track these valid errors.
	const sendSuccessEvent = useCallback(
		(validError?: SubscriptionError) => {
			sendExperienceAnalytics({
				experience,
				wasExperienceSuccesful: true,
				analyticsSource,
				// If we call this outside the issue view, application & edition are undefined.
				// We use JIRA_SOFTWARE for application if we're calling outside the issue view for backlogs.
				// Edition will always be premium (includes enterprise).
				application: application ?? JIRA_SOFTWARE,
				edition: edition ?? PREMIUM_EDITION,
				additionalAttributes: validError ? { validError } : {},
			});
		},
		[analyticsSource, application, edition],
	);

	const sendFailedEvent = useCallback(
		(error: SubscriptionError, errorMessage: string, statusCode?: number) => {
			sendExperienceAnalytics({
				experience,
				wasExperienceSuccesful: false,
				analyticsSource,
				application: application ?? JIRA_SOFTWARE,
				edition: edition ?? PREMIUM_EDITION,
				additionalAttributes: {
					error,
					errorMessage,
					statusCode: statusCode ?? 'No status code',
				},
			});
		},
		[analyticsSource, application, edition],
	);

	return { sendSuccessEvent, sendFailedEvent };
};
