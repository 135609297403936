import {
	performGetRequest,
	performPostRequest,
} from '@atlassian/jira-fetch/src/utils/requests.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { PlaceholderTemplateError } from '../placeholder-template-error.tsx';

interface CreatedSpace {
	id: string | null;
	key: string | null;
	name: string | null;
	description: string | null;
	url: string | null;
	draftId?: string | null;
	templateId?: string | null;
	spaceType?: string | null;
}

interface CreateSpaceRequestParams {
	spaceName: string; // eg, 'Knowledge base'
	spaceKey: string; // eg, KB1, LEGAL2, etc.
	spaceBlueprintId: string; // eg, '52c190a9-fe00-4cb7-9bd0-93f992012081',
}

/**
 * https://www.iana.org/assignments/media-types/media-types.xhtml
 */
const MediaType = {
	JSON: 'application/json',
	X_WWW_FORM_URLENCODED: 'application/x-www-form-urlencoded',
} as const;

type MediaType = (typeof MediaType)[keyof typeof MediaType];

type Space = {
	readonly name: string;
	readonly key: string;
};

// Input 'KB' returns 'KB1' or 'KB2' or next available iteration.
// Should return something like 'KB3' or 'LEGAL2' etc.
export async function generateSpaceKey(spaceName: string): Promise<string | undefined> {
	if (!spaceName) {
		return '';
	}

	try {
		const api = `/wiki/rest/onboarding/1/space/generate?key=${spaceName}`;
		const { key } = await performGetRequest(api);

		// todo: fire track event for successful key generation, implementing in STAR-1248

		return key;
	} catch (error: unknown) {
		throw new PlaceholderTemplateError(
			'Failed to generate a space key',
			'generateSpaceKey',
			error instanceof FetchError ? error.statusCode : undefined,
			error instanceof FetchError ? error.traceId : undefined,
		);
	}
}

export async function createSpace({
	spaceName,
	spaceKey,
	spaceBlueprintId,
}: CreateSpaceRequestParams): Promise<CreatedSpace | undefined> {
	try {
		const api = '/wiki/rest/create-dialog/1.0/space-blueprint/create-space?favorite=true';
		const data = await performPostRequest(api, {
			body: JSON.stringify({
				context: {
					ContentPageTitle: spaceName,
					members: '',
				},
				name: spaceName,
				spaceKey,
				spaceBlueprintId,
				description: '',
			}),
			headers: {
				'Content-Type': MediaType.JSON,
				Accept: MediaType.JSON,
			},
		});

		// fire track event for successful space creation, implementing in STAR-1248

		return data;
	} catch (error: unknown) {
		throw new PlaceholderTemplateError(
			'Failed to create a JSW project name Confluence space',
			'createSpace',
			error instanceof FetchError ? error.statusCode : undefined,
			error instanceof FetchError ? error.traceId : undefined,
		);
	}
}

export async function hasSpaceWithProjectName(projectName: string): Promise<string | undefined> {
	try {
		const api = '/wiki/api/v2/spaces';
		const spaces = await performGetRequest(api);

		const spaceKey: string = spaces.results?.find(
			(space: Space) => space.name === projectName,
		)?.key;

		return spaceKey;
	} catch (error: unknown) {
		throw new PlaceholderTemplateError(
			'Failed to fetch Confluence spaces',
			'hasSpaceWithProjectName',
			error instanceof FetchError ? error.statusCode : undefined,
			error instanceof FetchError ? error.traceId : undefined,
		);
	}
}
