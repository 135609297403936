import React from 'react';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { useIsConcealActionsInMeatballExpEnabled } from '@atlassian/jira-is-conceal-actions-experiment-enabled/src/useIsConcealActionsInMeatballExpEnabled.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { FIELD_KEY } from '../../common/types.tsx';
import VoteButtonRefresh from '../../common/vote-button-refresh/index.tsx';
import { VOTERS_UI_STYLED_BUTTON } from '../../constants.tsx';
import { useVoteField } from '../../services/vote-field-service/index.tsx';
import VoteIcon from '../icon/index.tsx';
import { StyledButton } from './styled.tsx';
import type { Props } from './types.tsx';

export const VoteButton = ({
	onClick,
	// @ts-expect-error - TS2322 - Type 'null' is not assignable to type 'string'.
	tooltip = null,
	isSelected = false,
	isDisabled = false,
	ariaLabel = '',
	useVoteField: useVoteFieldFromProps,
	...rest
}: Props) => {
	const issueKey = useIssueKey();

	const [fieldValue] = fg('relay-migration-issue-header-and-parent')
		? [{}]
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useFieldValue({
				issueKey,
				fieldKey: FIELD_KEY,
			});
	const [{ value: voteValue }] = fg('relay-migration-issue-header-and-parent')
		? (useVoteFieldFromProps || useVoteField)({})
		: [{ value: { votes: 0, hasVoted: false } }];
	const votes = fg('relay-migration-issue-header-and-parent')
		? voteValue?.votes
		: fieldValue?.votes || 0;
	const isConcealActionsExpEnabled = useIsConcealActionsInMeatballExpEnabled();
	const shouldShowEmptyLikeButton = !isConcealActionsExpEnabled || votes !== 0;
	const hasVoted = fg('relay-migration-issue-header-and-parent')
		? voteValue?.hasVoted
		: fieldValue?.hasVoted;
	return (
		<Tooltip content={tooltip} position="top">
			{isVisualRefreshEnabled() ? (
				<VoteButtonRefresh
					testId={VOTERS_UI_STYLED_BUTTON}
					onClick={onClick}
					isSelected={isSelected}
					label={ariaLabel}
					aria-expanded={isSelected}
					isDisabled={isDisabled}
					hasVoted={hasVoted}
					votes={votes}
					shouldShowEmptyLikeButton={shouldShowEmptyLikeButton}
					{...(fg('relay-migration-issue-header-and-parent') ? { ...rest } : {})}
				/>
			) : (
				shouldShowEmptyLikeButton && (
					<StyledButton
						testId={VOTERS_UI_STYLED_BUTTON}
						onClick={onClick}
						isSelected={isSelected}
						appearance="subtle"
						spacing="default"
						aria-label={ariaLabel}
						aria-expanded={isSelected}
						isDisabled={isDisabled}
					>
						<VoteIcon
							isSelected={isSelected}
							hasVoted={hasVoted}
							votes={votes}
							{...(fg('relay-migration-issue-header-and-parent') ? { ...rest } : {})}
						/>
					</StyledButton>
				)
			)}
		</Tooltip>
	);
};

export default VoteButton;
