import React, { useEffect, useLayoutEffect, useRef } from 'react';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { useUimAnalytics } from '@atlassian/jira-issue-adjustments/src/controllers.tsx';
import {
	useForgeLoadingFailed,
	useForgeDataComplete,
	useForgeUimModules,
} from '@atlassian/jira-issue-view-forge-service/src/services/main.tsx';
import { SpaStateSubscriber } from '@atlassian/jira-spa-state-controller/src/common/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { Metrics } from '../context/index.tsx';
import {
	markIssueRender,
	markIssueLegacyInteractive,
	markSPAReadyEndOfCriticalPath,
	isFullIssueView,
} from '../index.tsx';

type Props = {
	isLoading: boolean;
	isPreview: boolean;
	isComplete: boolean;
	issueKey: string;
	isInitialRender: boolean;
	error?: string;
	metrics: Metrics;
	lastTransitionStartTime?: number;
};

export const BentoMetrics = (props: Props) => {
	const {
		isPreview,
		isComplete,
		issueKey,
		isInitialRender,
		error,
		metrics,
		lastTransitionStartTime,
	} = props;

	const [isForgeLoadedSuccessfully] = useForgeDataComplete();
	const isForgeLoadingFailed = useForgeLoadingFailed();
	const uimModules = useForgeUimModules();

	const { isUimFinishedLoading } = useUimAnalytics({
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		forgeFinishedLoading: isForgeLoadedSuccessfully! || isForgeLoadingFailed!,
		forgeModulesCount: uimModules.length,
	});

	const isMountedRef = useRef<boolean>(false);
	const hasErroredRef = useRef<boolean>(false);
	const prevPropsRef = useRef<Props>(props);

	let endOfCriticalPath: (endTime?: number | undefined) => void | Promise<void>;
	let errorOnCriticalPath: (endTime?: number | undefined) => void | Promise<void>;

	const isFirstLoadOfRecentIssue = (prevProps: Props) =>
		issueKey !== prevProps.issueKey && isComplete;

	const didBentoMoveOutOfLoadingState = (prevProps: Props) =>
		prevProps.isLoading && (isPreview || isComplete);

	const isPreviewModeAfterBentoSpaTransition = (prevProps: Props) =>
		prevProps.issueKey !== issueKey && isPreview;

	const didJustMoveToComplete = (prevProps: Props) => isComplete && !prevProps.isComplete;

	const onEndOfCriticalPath = () => {
		if (endOfCriticalPath) {
			endOfCriticalPath();
			markSPAReadyEndOfCriticalPath();
		}
	};

	const { onMarkIssueOnPageLoadEvent } = metrics;
	useEffect(
		() => {
			// Add render mark and issue page load event
			markIssueRender();

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.addEventListener('load', onMarkIssueOnPageLoadEvent);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			return () => window.removeEventListener('load', onMarkIssueOnPageLoadEvent);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	useEffect(() => {
		// Measure initial issue render TTI
		if (!isMountedRef.current) {
			if (!hasErroredRef.current && isInitialRender && isFullIssueView()) {
				if (isComplete) {
					metrics.onInteractive({
						lastTransitionStartTime,
					});
					onEndOfCriticalPath();
				}
			}

			isMountedRef.current = true;
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			return () => {};
		}

		if (error) {
			hasErroredRef.current = true;
			if (errorOnCriticalPath) {
				errorOnCriticalPath();
			}
		}

		// Measure issue preview/load TTI, after initial render
		if (!hasErroredRef.current) {
			if (
				isPreviewModeAfterBentoSpaTransition(prevPropsRef.current) ||
				didBentoMoveOutOfLoadingState(prevPropsRef.current)
			) {
				metrics.onPreviewRendered({ isInitialRender, lastTransitionStartTime });
			}

			if (
				didJustMoveToComplete(prevPropsRef.current) ||
				isFirstLoadOfRecentIssue(prevPropsRef.current)
			) {
				markIssueLegacyInteractive();
				metrics.onInteractive({
					lastTransitionStartTime,
				});
				onEndOfCriticalPath();
			}
		}

		return () => {
			prevPropsRef.current = props;
		};
	});

	// Replicate old class based timings
	useLayoutEffect(() => {
		if (!hasErroredRef.current) {
			if (isPreview || isComplete) {
				metrics.onPreviewRendered({ isInitialRender, lastTransitionStartTime });
			}

			if (isComplete) {
				markIssueLegacyInteractive();
				if (!isInitialRender || !isFullIssueView()) {
					metrics.onInteractive({ lastTransitionStartTime });
					onEndOfCriticalPath();
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{!fg('jira-concurrent-disable-iv-uim-hold') && (
				<UFOLoadHold name="forge-ui-modifications-count" hold={!isUimFinishedLoading} />
			)}

			<SpaStateSubscriber>
				{(_spaState, { setAppReady, setAppErrorReady }) => {
					endOfCriticalPath = setAppReady;
					errorOnCriticalPath = setAppErrorReady;
					return null;
				}}
			</SpaStateSubscriber>
		</>
	);
};
