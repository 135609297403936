import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import type { TeamValue } from '@atlassian/jira-issue-field-team/src/common/types.tsx';
import { extractId } from '@atlassian/jira-polaris-lib-team-value/src/utils.tsx';
import { defaultJiraUpdateService } from '../common/index.tsx';
import { TEAM_SELECT } from '../constants.tsx';
import type { JiraFieldMapping } from '../types.tsx';

export const jiraTeamMapping = (
	issuesRemote: IssuesRemote,
	field: Field,
): JiraFieldMapping<TeamValue | undefined> => ({
	field,
	getValueFromJiraIssue: (issue) => {
		return issue.fields[field.key];
	},
	getFieldValueForJiraUpdate: (value) => {
		if (!value) {
			return null;
		}
		return extractId(value.id);
	},
	getFieldValueForJiraBulkUpdate: (value) => {
		return {
			[TEAM_SELECT]: {
				fieldId: field.key,
				team: { teamId: value?.id ? extractId(value.id) : '' },
			},
		};
	},
	isMultiValueField: false,
	isSupportedByIssueUpdateApi: true,
	...defaultJiraUpdateService(issuesRemote, field.key),
});
