import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import type { Props } from './types.tsx';

export const CascadingSelectOptionTruncated = ({ value }: Props) => {
	return (
		<Box as="span" xcss={cascadingValueStylesTruncated}>
			{value}
		</Box>
	);
};

const cascadingValueStylesTruncated = xcss({
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	display: 'inline-block',
	width: '100%',
});
