import React, { useCallback, forwardRef } from 'react';
import { Pressable, xcss, Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { getColorMetadata } from '../color-picker/utils/index.tsx';
import { memoizeGetAutoGeneratedColorForLabels } from '../option-color-generator/index.tsx';
import type { Props } from './types.tsx';

export const ColoredTag = forwardRef<HTMLButtonElement, Props>(
	({ labelName, href, actionSubjectId, pressableXcss, contentXcss, ...restProps }, ref) => {
		const { createAnalyticsEvent } = useAnalyticsEvents();

		const { backgroundColor: color } = getColorMetadata(
			memoizeGetAutoGeneratedColorForLabels(labelName),
		);

		const onHandleClick = useCallback(() => {
			if (href) {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.location.href = href;
			}
			if (actionSubjectId) {
				fireUIAnalytics(
					createAnalyticsEvent({
						action: 'clicked',
						actionSubject: 'link',
					}),
					actionSubjectId,
				);
			}
		}, [actionSubjectId, createAnalyticsEvent, href]);

		return (
			<Pressable
				ref={ref}
				xcss={[tagStyles, ...(pressableXcss ? [pressableXcss] : [])]}
				onClick={onHandleClick}
				// eslint-disable-next-line jira/react/no-style-attribute
				style={{ borderColor: color }}
				{...restProps}
			>
				<Box xcss={[tagTextStyles, ...(contentXcss ? [contentXcss] : [])]}>{labelName}</Box>
			</Pressable>
		);
	},
);

const tagStyles = xcss({
	display: 'flex',
	backgroundColor: 'color.background.neutral.subtle',
	borderStyle: 'solid',
	borderWidth: '1px',
	borderRadius: token('border.radius.100'),
	paddingInline: 'space.050',
	paddingBlock: '0',
	font: 'font.body',
	marginBlock: 'space.050',
	marginInline: 'space.050',
	height: '20px',
	alignItems: 'center',
	maxWidth: '100%',
	':hover': {
		backgroundColor: 'color.background.neutral.hovered',
		textDecoration: 'underline',
	},
});

const tagTextStyles = xcss({
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	maxWidth: '180px',
});
