import React from 'react';
import { styled } from '@compiled/react';
import ArrowRightIcon from '@atlaskit/icon/core/migration/arrow-right';
import { Flex } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type { StatusTransition } from '../../../../common/types.tsx';
import { StatusLozenge } from '../../../../common/ui/status-lozenge/index.tsx';
import messages from './messages.tsx';

type Props = {
	isDisabled?: boolean;
	transition: StatusTransition;
	addTransitionArrowSpacing?: boolean;
};

const getLabel = (transition: StatusTransition, intl: IntlShape) => {
	if (transition.isGlobal) {
		if (transition.name !== transition.to.name) {
			return transition.name;
		}
		return intl.formatMessage(messages.globalTransition);
	}
	return transition.name;
};

export const TransitionWithLabel = (props: Props) => {
	const intl = useIntl();

	const content = (
		<>
			<LabelWrapper>{getLabel(props.transition, intl)}</LabelWrapper>
			<TransitionWrapper visualRefresh={isVisualRefreshEnabled()}>
				<VerticalCenter addTransitionArrowSpacing={props.addTransitionArrowSpacing}>
					<ArrowRightIcon
						label=""
						LEGACY_size="small"
						testId="issue-field-status.ui.status-view.transition.with-label.arrow-right"
					/>
				</VerticalCenter>
				<StatusLozenge status={props.transition.to} isDisabled={props.isDisabled} />
			</TransitionWrapper>
		</>
	);

	if (isVisualRefreshEnabled()) {
		return <Flex gap="space.100">{content}</Flex>;
	}

	return <Wrapper>{content}</Wrapper>;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LabelWrapper = styled.span({
	flexGrow: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TransitionWrapper = styled.span<{ visualRefresh: boolean }>(
	{
		flexShrink: 0,
		display: 'flex',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	({ visualRefresh }) =>
		visualRefresh && {
			alignItems: 'center',
			gap: token('space.100'),
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const VerticalCenter = styled.div<{ addTransitionArrowSpacing?: boolean }>({
	display: 'inline-flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	margin: ({ addTransitionArrowSpacing }) =>
		addTransitionArrowSpacing ? `0 ${token('space.150')}` : 'inherit',
});
