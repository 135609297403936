import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	overdue: {
		id: 'business.list.ui.list-view.date-cell.overdue',
		defaultMessage: 'Overdue since {date}',
		description: 'Message shown when a task is overdue',
	},
	due: {
		id: 'business.list.ui.list-view.date-cell.due',
		defaultMessage: 'Due on {date}',
		description: 'Message shown when a task is due',
	},
});
