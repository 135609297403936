import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	whatIsThis: {
		id: 'smart-related-issues-panel.header.what-is-this',
		defaultMessage: 'What is this?',
		description: 'Label of button for documentation on similar requests',
	},
	feedback: {
		id: 'smart-related-issues-panel.header.feedback',
		defaultMessage: 'Give feedback',
		description: 'Label of button for giving feedback',
	},
	hideSimilarRequests: {
		id: 'smart-related-issues-panel.header.hide-similar-requests',
		defaultMessage: 'Hide similar requests',
		description: 'The hide similar requests menu item, shown within the more menu for admins.',
	},
	hideSimilarRequestsHover: {
		id: 'smart-related-issues-panel.header.hide-similar-requests-hover',
		defaultMessage: 'Your project admin can disable this feature in the project settings',
		description: 'The hide similar requests menu item, shown when hovering on the hide button',
	},
	title: {
		id: 'smart-related-issues-panel.header.title',
		defaultMessage: 'Similar requests',
		description: 'Heading text for the Related Issues Panel section in a JSM issue view.',
	},
	similarWorkItemsTitle: {
		id: 'smart-related-issues-panel.header.title-work-items',
		defaultMessage: 'Similar work items',
		description: 'Heading text for the Related Issues Panel section in a JSM issue view.',
	},
	loadingSimilarWorkItemsCount: {
		id: 'smart-related-issues-panel.header.loading-work-items-count',
		defaultMessage: 'Loading the number of similar work items',
		description: 'Label that indicates loading of similar work items count',
	},
	description: {
		id: 'smart-related-issues-panel.header.description',
		defaultMessage: 'Results with a similar summary',
		description:
			'Description in the header of the Related Issues Panel section in a JSM issue view.',
	},
});
