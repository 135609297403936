import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	paginationNext: {
		id: 'issue-view-base.content.attachment.grid-view-relay.pagination-next',
		defaultMessage: 'Next',
		description: '',
	},
	paginationPrevious: {
		id: 'issue-view-base.content.attachment.grid-view-relay.pagination-previous',
		defaultMessage: 'Previous',
		description: '',
	},
	paginationLabel: {
		id: 'issue-view-base.content.attachment.grid-view-relay.pagination-label',
		defaultMessage: 'Page',
		description: '',
	},
});
