import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	inviteBannerTitle: {
		id: 'persistent-invite-prompt.invite-banner.title',
		defaultMessage:
			'{numOfAssignees} project {numOfAssignees, plural, =0 {assignee} one {assignee} other {assignees}} need access to view this content',
		description: 'Title of the invite banner in the Confluecne Content section',
	},
	shareAccessButtonTitle: {
		id: 'persistent-invite-prompt.invite-banner.share-access-button.title',
		defaultMessage: 'Share Access',
		description:
			'Title of the share access button. When clicked, it invites members to the associated Confluence site.',
	},
	requestAccessButtonTitle: {
		id: 'persistent-invite-prompt.invite-banner.request-access-button.title',
		defaultMessage: 'Request Access',
		description:
			'Title of the request access button. When clicked, it created access request for members to the associated Confluence site.',
	},
	teamIconLabel: {
		id: 'persistent-invite-prompt.invite-banner.team-icon.label',
		defaultMessage: 'team',
		description: 'Label of the team icon',
	},
	closeIconLabel: {
		id: 'persistent-invite-prompt.invite-banner.close-icon.label',
		defaultMessage: 'close',
		description: 'Label of the close icon',
	},
	avatarTooltipDescription: {
		id: 'persistent-invite-prompt.invite-banner.avatar.tooltip.description',
		defaultMessage: 'People who need access',
		description: 'Description of the avatar tooltip, will be displayed when hovered.',
	},
});
