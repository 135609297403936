export const mergeObjects = (objects: Record<string, unknown>[]): Record<string, unknown> => {
	const result: Record<string, unknown> = {};

	for (const object of objects) {
		for (const key in object) {
			if (typeof object === 'object' && object && key in object) {
				if (result[key]) {
					const value = result[key];
					if (Array.isArray(value)) {
						value.push(object[key]);
					} else {
						result[key] = [result[key], object[key]];
					}
				} else {
					result[key] = object[key];
				}
			}
		}
	}

	return result;
};
