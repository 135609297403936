import React, { useCallback, type SyntheticEvent, useMemo, type Ref } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { Flex, Pressable, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { asyncInlineCreateStatusModal } from '@atlassian/jira-inline-create-status-modal/entrypoint.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { defaultModalProps } from '@atlassian/jira-status-config-common/src/constants.tsx';
import {
	ISSUE_VIEW_SOURCE,
	type ConfigureSource,
} from '@atlassian/jira-status-config-common/src/types.tsx';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { createStatusExperience } from '../../../experiences.tsx';
import { messages } from './messages.tsx';

export type CreateStatusButtonProps = {
	projectId: string;
	projectKey: string;
	projectType: ProjectType;
	issueTypeId: string;
	statusNames?: string[];
	isPressable?: boolean;
	onClick: () => void;
	onClose: () => void;
	onStatusCreated?: () => Promise<void>;
	buttonRef?: Ref<HTMLButtonElement>;
	source?: ConfigureSource;
};

export const CreateStatusButton = ({
	projectId,
	projectKey,
	projectType,
	issueTypeId,
	statusNames = [],
	isPressable = false,
	onClick,
	onClose,
	onStatusCreated,
	buttonRef = null,
	source,
}: CreateStatusButtonProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const cloudId = useCloudId();
	const [inlineStatusConfigExp] = UNSAFE_noExposureExp('inline_status_add_and_rename_in_gic');
	const isInlineStatusExperimentEnabled = inlineStatusConfigExp.get(
		'isInlineStatusConfigEnabled',
		false,
	);

	const handleClick = useCallback(
		(_e: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			onClick();
			fireUIAnalytics(analyticsEvent, 'createStatusButton');
			// Because we are triggering the click manually for keyboard users
			// this handler becomes the source-of-truth handler for both keyboard users and mouse users
			// However, we need to stopPropagation from here to prevent double modal for mouse users
			// When mouse user clicks the button, if we don't stop propagation here, handleChange
			// will be called which will trigger click manually again and cause double modal rendering
			isInlineStatusExperimentEnabled && _e.stopPropagation();
		},
		[isInlineStatusExperimentEnabled, onClick],
	);

	const onModalClose = useCallback(() => {
		onClose();
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'closed',
				actionSubject: 'inlineCreateStatusModal',
			}),
		);
	}, [createAnalyticsEvent, onClose]);

	const onMouseDown = () => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			}),
			'createStatusButton',
		);
	};

	const entryPointParams = useMemo(
		() => ({
			cloudId,
			projectKey,
		}),
		[cloudId, projectKey],
	);

	const entryPointProps = useMemo(
		() => ({
			statusNames,
			projectId,
			projectKey,
			projectType,
			onClose: onModalClose,
			isNewControllerEnabled: true,
			workflowAssociatedIssueTypeId: issueTypeId,
			createStatusExperience,
			source: source || ISSUE_VIEW_SOURCE,
			onStatusCreated,
		}),
		[
			statusNames,
			projectId,
			projectKey,
			projectType,
			onModalClose,
			issueTypeId,
			source,
			onStatusCreated,
		],
	);

	const getClickElement = (ref: React.RefObject<HTMLElement>) =>
		isPressable ? (
			<Pressable
				xcss={pressableStyles}
				onClick={handleClick}
				data-show-on-hover
				// ref returned from entrypoint is of Type 'RefObject<HTMLElement>' is not assignable to type 'Ref<HTMLButtonElement>
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				ref={ref as React.Ref<HTMLButtonElement>}
				onMouseDown={(event) => {
					if (event.button === 0) {
						// Left mouse button was clicked
						onMouseDown();
					}
				}}
			>
				{formatMessage(messages.createStatus)}
			</Pressable>
		) : (
			<Flex xcss={flexContainerStyles}>
				<StyledButton
					appearance="subtle"
					shouldFitContainer
					interactionName="issue.fields.status.create-status.popup.open"
					onClick={handleClick}
					ref={ref}
				>
					{formatMessage(messages.createStatus)}
				</StyledButton>
			</Flex>
		);

	return (
		<ModalEntryPointPressableTrigger
			entryPoint={asyncInlineCreateStatusModal}
			entryPointProps={entryPointProps}
			entryPointParams={entryPointParams}
			interactionName="create-status-modal"
			modalProps={defaultModalProps}
			useInternalModal
			forwardedRef={buttonRef}
		>
			{({ ref }) => getClickElement(ref)}
		</ModalEntryPointPressableTrigger>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const StyledButton = styled(Button)({
	alignItems: 'center',
	textAlign: 'left',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
	color: token('color.text'),
	paddingTop: '10px',
	paddingRight: token('space.150'),
	paddingBottom: '10px',
	paddingLeft: token('space.150'),
	height: '100%',
});

const flexContainerStyles = xcss({
	flexGrow: 1,
	height: token('space.500'),
});

const pressableStyles = xcss({
	height: '100%',
	width: '100%',
	backgroundColor: 'color.background.neutral.subtle',
	alignItems: 'center',
	textAlign: 'left',
	fontWeight: 'font.weight.medium',
});
