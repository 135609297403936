import React from 'react';
import { UpdatePriority } from '@atlassian/jira-servicedesk-ai-context-common/src/ui/actions/update-priority/index.tsx';
import { ActionItem } from '@atlassian/jira-servicedesk-ai-context-common/src/ui/ai-context-item/action-item/index.tsx';
import type { PrioritySuggestedAction } from '@atlassian/jira-servicedesk-ai-context-panel-resources/src/services/ops/common/types.tsx';
import { useAIAnalytics } from '../../../../common/utils/index.tsx';

type props = {
	action: PrioritySuggestedAction;
	onRemoveItem: () => void;
};

export const PriorityItem = ({ action, onRemoveItem }: props) => {
	const [{ onClickFireAnalytics, onFailureFireAnalytics }] = useAIAnalytics(action.type);

	const onFailure = (e: unknown) => {
		e && onFailureFireAnalytics(new Error(e?.toString()));
	};
	return (
		<ActionItem
			summary={action.content.title}
			description={action.content.description}
			action={
				<UpdatePriority
					context={action.context}
					onClick={onClickFireAnalytics}
					onSuccess={onRemoveItem}
					onFailure={onFailure}
				/>
			}
		/>
	);
};
