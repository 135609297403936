/**
 * @generated SignedSource<<4da06b446d2d2c465b6bc9dfeeaf7b4c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal_rootRelayFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"relayLayoutItems_issueViewLayoutTemplatesLayoutItem_RelayLayoutItemFragmentContainer_rootRelayFragment">;
  readonly " $fragmentType": "src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal_rootRelayFragment";
};
export type src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal_rootRelayFragment$key = {
  readonly " $data"?: src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal_rootRelayFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal_rootRelayFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal_rootRelayFragment",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "relayLayoutItems_issueViewLayoutTemplatesLayoutItem_RelayLayoutItemFragmentContainer_rootRelayFragment"
    }
  ],
  "type": "Query"
};

(node as any).hash = "6b347931add2a46bb66c7bd997be498d";

export default node;
