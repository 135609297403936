// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { getDeliveryDataFetchError } from '../../selectors/meta.tsx';
import { isDeliveryFieldVisibleInCurrentView } from '../../selectors/view/index.tsx';
import type { Props, State } from '../../types.tsx';

export const handleDeliveryDataFetchError =
	(error: Error): Action<State, Props> =>
	({ getState, setState }, props) => {
		const state = getState();
		if (
			!props.delayDeliveryDataFetchFailedNotification ||
			isDeliveryFieldVisibleInCurrentView(state, props)
		) {
			props.onDeliveryDataFetchFailed(error);
		} else {
			setState({
				...state,
				meta: {
					...state.meta,
					deliveryDataFetchError: error,
				},
			});
		}
	};

export const showStoredDeliveryDataFetchError =
	(): Action<State, Props> =>
	({ getState, setState }, { onDeliveryDataFetchFailed }) => {
		const state = getState();
		const deliveryDataFetchError = getDeliveryDataFetchError(state);
		if (deliveryDataFetchError) {
			onDeliveryDataFetchFailed(deliveryDataFetchError);
			setState({
				...state,
				meta: {
					...state.meta,
					deliveryDataFetchError: undefined,
				},
			});
		}
	};
