/**
 * @generated SignedSource<<552dc3a05ed5f6d783af14a0f4b74a40>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraTimeFormat = "DAYS" | "HOURS" | "PRETTY" | "%future added value";
export type JiraTimeUnit = "DAY" | "HOUR" | "MINUTE" | "WEEK" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type lite_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewIsEditable_fragmentRef$data = {
  readonly fieldId: string;
  readonly id: string;
  readonly name: string;
  readonly originalEstimate: {
    readonly timeInSeconds: AGG$Long | null | undefined;
  } | null | undefined;
  readonly timeTrackingSettings: {
    readonly defaultFormat: JiraTimeFormat | null | undefined;
    readonly defaultUnit: JiraTimeUnit | null | undefined;
    readonly workingDaysPerWeek: number | null | undefined;
    readonly workingHoursPerDay: number | null | undefined;
  } | null | undefined;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadView">;
  readonly " $fragmentType": "lite_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewIsEditable_fragmentRef";
};
export type lite_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewIsEditable_fragmentRef$key = {
  readonly " $data"?: lite_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewIsEditable_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"lite_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewIsEditable_fragmentRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "lite_issueFieldOriginalEstimateInlineEditFull_OriginalEstimateInlineEditViewIsEditable_fragmentRef",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadView"
    },
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "concreteType": "JiraEstimate",
      "kind": "LinkedField",
      "name": "originalEstimate",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "timeInSeconds"
        }
      ]
    },
    {
      "concreteType": "JiraTimeTrackingSettings",
      "kind": "LinkedField",
      "name": "timeTrackingSettings",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "workingHoursPerDay"
        },
        {
          "kind": "ScalarField",
          "name": "workingDaysPerWeek"
        },
        {
          "kind": "ScalarField",
          "name": "defaultFormat"
        },
        {
          "kind": "ScalarField",
          "name": "defaultUnit"
        }
      ]
    }
  ],
  "type": "JiraTimeTrackingField"
};

(node as any).hash = "92ecace176224672cc8283c177c6cb95";

export default node;
