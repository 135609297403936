import { useMemo } from 'react';
import { createLocalExpirableStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import type { InviteCapability } from '../invite-capability/types.tsx';

export const PersistentInviteCacheKeys = {
	ELIGIBILITY: 'eligibility',
	DISMISSED: 'dismissed',
	INVITE_CAPABILITY: 'invite-capability',
	INVITEES: 'invitees',
	VIEW_COUNT: 'view-count',
} as const;

type PersistentInviteCacheDataTypes = {
	[PersistentInviteCacheKeys.ELIGIBILITY]: boolean;
	[PersistentInviteCacheKeys.DISMISSED]: boolean;
	[PersistentInviteCacheKeys.INVITE_CAPABILITY]: InviteCapability;
	[PersistentInviteCacheKeys.INVITEES]: string[];
	[PersistentInviteCacheKeys.VIEW_COUNT]: number;
};

type PersistentInviteCacheKey = keyof PersistentInviteCacheDataTypes;

const ONE_WEEK_IN_MS = 7 * 24 * 60 * 60 * 1000;
const ONE_MONTH_IN_MS = 30 * ONE_WEEK_IN_MS;

const getExpirationTime = (key: PersistentInviteCacheKey) => {
	switch (key) {
		case PersistentInviteCacheKeys.ELIGIBILITY:
			return Date.now() + ONE_WEEK_IN_MS;
		case PersistentInviteCacheKeys.INVITEES:
			return Date.now() + ONE_WEEK_IN_MS;
		case PersistentInviteCacheKeys.INVITE_CAPABILITY:
			return Date.now() + ONE_MONTH_IN_MS;
		case PersistentInviteCacheKeys.DISMISSED:
			return Date.now() + ONE_MONTH_IN_MS;
		case PersistentInviteCacheKeys.VIEW_COUNT:
			return Date.now() + ONE_MONTH_IN_MS;
		default:
			return undefined;
	}
};

export const usePersistentInvitesCache = () => {
	const cloudId = useCloudId();
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const accountId = useAccountId();

	return useMemo(
		() => createPersistentInviteCache(`persistent-invite-${cloudId}-${projectKey}-${accountId}`),
		[cloudId, projectKey, accountId],
	);
};

export const createPersistentInviteCache = (appPrefix: string) => {
	const storage = createLocalExpirableStorageProvider(appPrefix);

	const setPersistentInvitesCache = <K extends PersistentInviteCacheKey>(
		key: K,
		data: PersistentInviteCacheDataTypes[K],
	) => {
		storage.set(key, data, getExpirationTime(key));
	};

	const getPersistentInvitesCache = <K extends PersistentInviteCacheKey>(
		key: K,
	): PersistentInviteCacheDataTypes[K] | undefined => storage.get(key);

	const removePersistentInvitesCache = (key: PersistentInviteCacheKey) => {
		storage.remove(key);
	};

	return {
		setPersistentInvitesCache,
		getPersistentInvitesCache,
		removePersistentInvitesCache,
	};
};

export type PersistentInviteCache = ReturnType<typeof createPersistentInviteCache>;
