import React, {
	createRef,
	Component,
	type ReactNode,
	type FocusEvent,
	type ComponentPropsWithoutRef,
} from 'react';
import { styled } from '@compiled/react';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { Box, xcss } from '@atlaskit/primitives';
import Select, {
	type OptionType,
	CreatableSelect,
	AsyncCreatableSelect,
	CheckboxSelect,
	components,
} from '@atlaskit/select';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { AsyncSelectWithAnalytics } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/async-select.tsx';
import { FieldLabel } from '@atlassian/jira-common-components-field-label/src/main.tsx';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { JiraInlineDialog as InlineDialog } from '@atlassian/jira-inline-dialog/src/ui/jira-inline-dialog.tsx';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Props as BaseProps, ElementStyles, SelectElementState } from '../model.tsx';
import messages from './messages.tsx';

export const DEBOUNCE_TIME = 500;
export type Props<SelectOption> = Flow.Diff<
	BaseProps<SelectOption>,
	{
		noOptionsMessage: BaseProps<SelectOption>['noOptionsMessage'];
		loadingMessage: BaseProps<SelectOption>['loadingMessage'];
		placeholder: string;
	}
> & {
	placeholder?: string;
	menuIsOpen?: boolean;
	onSelected?: (arg1: SelectOption | undefined, arg2: UIAnalyticsEvent | undefined) => void;
	onMultiSelected?: (arg1: ReadonlyArray<SelectOption>) => void;
};
type PropsWithDefaults<SelectOption> = Props<SelectOption> & {
	onSelected: (arg1: SelectOption | undefined, arg2: UIAnalyticsEvent | undefined) => void;
	onMultiSelected: (arg1: SelectOption[]) => void;
};
export type ViewProps<SelectOption> = Intl &
	PropsWithDefaults<SelectOption> & {
		listItemCustomStyles?: ElementStyles;
		hasDraggableParentComponent?: boolean;
	};
type State = {
	validationState: 'default' | 'error';
	showErrorMessage: boolean;
	menuWasOpen: boolean;
	menuIsOpen: boolean;
	initialFocusHandled: boolean;
};
type MenuListProps = {
	hasValue: boolean;
	children: ReactNode;
	// eslint-disable-next-line jira/react/handler-naming
	clearValue: () => void;
};
export const getOptionBaseStyles = (optionState: SelectElementState): ElementStyles => ({
	...(optionState.isDisabled
		? {
				cursor: 'not-allowed',
				color: token('color.text.subtlest'),
				'&, :active': {
					backgroundColor: 'transparent',
				},
			}
		: {
				cursor: 'pointer',
			}),
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});
interface PickerRenderOprionPropsData {
	disableReason?: string;
}

/**
 * Defines a Picker component tailored for selecting options, supporting various features like
 * multi-selection, asynchronous option loading, creatable options, and more. It manages the overall
 * state and behavior of the selection process, including handling default properties, user interactions,
 * validation states, and rendering custom components or messages based on the selection context.
 */
// eslint-disable-next-line jira/react/no-class-components
export class Picker<SelectOption> extends Component<ViewProps<SelectOption>, State> {
	static defaultProps = {
		isRequired: false,
		isDisabled: false,
		isClearable: false,
		isCompact: false,
		isCreatable: false,
		isMulti: false,
		noPortal: false,
		isCheckbox: false,
		label: '',
		defaultValue: undefined,
		width: 'auto',
		onSelected: noop,
		onMultiSelected: noop,
	};

	static getDerivedStateFromProps<SelectOption>(props: ViewProps<SelectOption>) {
		if (props.menuIsOpen !== undefined) {
			return {
				menuIsOpen: props.menuIsOpen,
			};
		}
		return null;
	}

	constructor(props: ViewProps<SelectOption>) {
		super(props);

		// This is initialized in constructor because when it is a class property then flow gets confused about the generic type in callback.
		// That is a known bug: https://github.com/facebook/flow/issues/6978
		this.debouncedLoadOptions = debounce(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(filter: string, callback: (arg1: SelectOption[]) => Record<any, any>) => {
				// @ts-expect-error - TS2349 - This expression is not callable.
				this.props.options(filter).then(callback, () => {
					callback([]);
					this.setErrorState();
				});
			},
			DEBOUNCE_TIME,
		);

		// @ts-expect-error - Property 'selectRef' does not exist on type 'Picker<SelectOption>'
		this.selectRef = createRef();
	}

	// @ts-expect-error - TS2416 - Property 'state' in type 'Picker<SelectOption>' is not assignable to the same property in base type 'Component<ViewProps<SelectOption>, State, any>'.
	state = {
		validationState: 'default',
		showErrorMessage: false,
		menuWasOpen: false,
		menuIsOpen: false,
		initialFocusHandled: false,
	};

	debouncedLoadOptions: (
		filter: string,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		callback: (arg1: SelectOption[]) => Record<any, any>,
	) => void;

	onSelected: // eslint-disable-next-line @typescript-eslint/no-explicit-any
	| ((selection: null, action: any, analyticsEvent: UIAnalyticsEvent | undefined) => void)
		| ((
				selection: SelectOption[] | SelectOption | null,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				action: any,
				analyticsEvent: UIAnalyticsEvent | undefined,
		  ) => void) = (
		selection: SelectOption[] | SelectOption | null,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		action: any,
		analyticsEvent?: UIAnalyticsEvent,
	) => {
		this.setState(() => ({
			menuIsOpen: false,
		}));
		const { isMulti } = this.props;
		if (Array.isArray(selection)) {
			if (isMulti) {
				// @ts-expect-error - TS2554 - Expected 1 arguments, but got 2.
				this.props.onMultiSelected(selection, analyticsEvent);
			} else {
				// When the selection is cleared in single select mode, selection is an array.
				this.props.onSelected(undefined, analyticsEvent);
			}
		} else {
			// @ts-expect-error - TS2339 - Property 'value' does not exist on type 'SelectOption'.
			selection && selection.value
				? this.props.onSelected(selection, analyticsEvent)
				: this.props.onSelected(undefined, analyticsEvent);
		}
	};

	onMenuOpen = () => {
		this.setState(() => ({
			menuIsOpen: true,
			menuWasOpen: true,
		}));
	};

	onMenuClose = () => {
		this.setState(() => ({
			menuIsOpen: false,
		}));
	};

	onBlur = (event: FocusEvent<HTMLElement>) => {
		this.hideErrorMessage();
		const { onBlur } = this.props;
		if (onBlur && typeof onBlur === 'function') {
			onBlur(event);
		}
	};

	onFocus = (event: FocusEvent<HTMLElement>) => {
		this.showErrorMessage();
		const { onFocus } = this.props;
		if (onFocus && typeof onFocus === 'function') {
			onFocus(event);
		}
	};

	setErrorState = () => {
		this.setState({
			showErrorMessage: true,
			validationState: 'error',
		});
	};

	getSelectComponent(isAsync: boolean) {
		if (this.props.isCreatable) {
			return isAsync ? AsyncCreatableSelect : CreatableSelect;
		}
		if (isAsync) {
			return AsyncSelectWithAnalytics;
		}
		return this.props.isCheckbox ? CheckboxSelect<OptionType> : Select;
	}

	getComponentOverrides() {
		const { menuWasOpen } = this.state;
		const Option = {
			Option: this.renderOption,
		};
		// Remove MenuList when clean up FG since we no longer need to override to add `Clear Selected` button
		const MenuList = fg('jfp-a11y-team_update_legacy_select_clear_fuction')
			? {}
			: {
					MenuList: this.renderMenuList,
				};
		const LoadingIndicator = menuWasOpen
			? {}
			: {
					LoadingIndicator: null,
				};
		return {
			...Option,
			...MenuList,
			...LoadingIndicator,
		};
	}

	showErrorMessage = () =>
		this.setState({
			showErrorMessage: true,
		});

	hideErrorMessage = () =>
		this.setState({
			showErrorMessage: false,
		});

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	loadOptions: (filter: string, callback: (arg1: SelectOption[]) => Record<any, any>) => void = (
		filter: string,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		callback: (arg1: SelectOption[]) => Record<any, any>,
	) => {
		this.setState({
			validationState: 'default',
		});
		this.debouncedLoadOptions(filter, callback);
	};

	renderOption = (
		props: JSX.LibraryManagedAttributes<
			typeof components.Option,
			ComponentPropsWithoutRef<typeof components.Option>
		>,
	) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const propsData = props.data as PickerRenderOprionPropsData;
		const disableReason = (props.isDisabled && propsData && propsData.disableReason) || null;
		return (
			<Tooltip position="right" content={disableReason}>
				<components.Option
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					{...(props as JSX.LibraryManagedAttributes<
						typeof components.Option,
						ComponentPropsWithoutRef<typeof components.Option>
					>)}
				/>
			</Tooltip>
		);
	};

	// Will remove this renderMenuList when clean up FG since it's no longer needed
	renderMenuList = (menuListProps: MenuListProps) => {
		const {
			isClearable,
			intl: { formatMessage },
		} = this.props;
		return (
			// @ts-expect-error - TS2740 - Type '{ children: (Element | ReactNode)[]; hasValue: boolean; clearValue: () => void; }' is missing the following properties from type 'CommonProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>>': cx, getStyles, getValue, isMulti, and 6 more.
			<components.MenuList {...menuListProps}>
				{isClearable && menuListProps.hasValue && (
					<Box xcss={clearButtonWrapperStyles}>
						<Button appearance="link" shouldFitContainer onClick={menuListProps.clearValue}>
							{formatMessage(messages.clearSelected)}
						</Button>
					</Box>
				)}
				{menuListProps.children}
			</components.MenuList>
		);
	};

	renderValueContainer = (
		props: JSX.LibraryManagedAttributes<
			typeof components.ValueContainer,
			ComponentPropsWithoutRef<typeof components.ValueContainer>
		>,
	) => {
		const { onValueContainerClick } = props.selectProps;
		return (
			<StyledValueContainer
				data-testid="common-components-picker.base.react-dnd-and-select-focus-bug-fix"
				onClick={onValueContainerClick}
			>
				<components.ValueContainer {...props} />
			</StyledValueContainer>
		);
	};

	render() {
		const { showErrorMessage, validationState, menuIsOpen } = this.state;
		const {
			intl: { formatMessage },
		} = this.props;
		const {
			label,
			listItemCustomStyles,
			placeholder = formatMessage(messages.defaultPlaceholder),
			defaultValue,
			isRequired,
			isDisabled,
			isCompact,
			isClearableWithCrossIcon,
			errorMessage,
			options,
			width,
			minWidth,
			noPortal,
			isCheckbox,
			hasDraggableParentComponent,
		} = this.props;
		const isAsync: boolean = typeof options === 'function';
		const SelectComponent = this.getSelectComponent(isAsync);
		const isErrorMessageOpened: boolean = showErrorMessage && validationState === 'error';
		let selectParams = {
			placeholder,
			isDisabled,
			isClearable: isClearableWithCrossIcon || false,
			defaultValue,
			defaultOptions: true,
			autoFocus: !!this.props.autoFocus,
			loadOptions: isAsync && this.loadOptions,
			options: !isAsync && options,
			onChange: this.onSelected,
			noOptionsMessage: () => formatMessage(messages.noOptions),
			loadingMessage: () => formatMessage(messages.loading),
			validationState: this.state.validationState,
			onBlur: this.onBlur,
			onFocus: this.onFocus,
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			menuPortalTarget: noPortal ? null : document.body,
			spacing: isCompact ? 'compact' : 'default',
			styles: {
				...(fg('jira_apply_prop_stylings_to_base_picker') ? this.props.styles : undefined),
				// without properly set zIndex value dropdown with options is cut inside modal dialogs
				// @ts-expect-error - TS7006 - Parameter 'base' implicitly has an 'any' type.
				menuPortal: (base) => ({
					...base,
					zIndex: layers.modal,
				}),
				// @ts-expect-error - TS7006 - Parameter 'base' implicitly has an 'any' type.
				container: (base) => ({
					...base,
					width,
					minWidth,
				}),
				// @ts-expect-error - TS7006 - Parameter 'base' implicitly has an 'any' type. | TS7006 - Parameter 'optionState' implicitly has an 'any' type.
				option: (base, optionState) => ({
					...base,
					...getOptionBaseStyles(optionState),
					...listItemCustomStyles,
				}),
			},
		};
		if (!isCheckbox) {
			selectParams = {
				...selectParams,
				// @ts-expect-error - TS2322 - Type '{ menuIsOpen: boolean; onMenuOpen: () => void; onMenuClose: () => void; components: { LoadingIndicator?: undefined; MenuList: (menuListProps: MenuListProps) => JSX.Element; Option: (props: CommonProps<...> & ... 1 more ... & { ...; }) => JSX.Element; } | { ...; }; ... 16 more ...; styles: { ...; }; }' is not assignable to type '{ placeholder: string; isDisabled: boolean | undefined; isClearable: boolean; defaultValue: SelectOption | undefined; defaultOptions: boolean; autoFocus: boolean; loadOptions: false | ((filter: string, callback: (arg1: SelectOption[]) => Record<...>) => void); ... 9 more ...; styles: { ...; }; }'.
				menuIsOpen,
				onMenuOpen: this.onMenuOpen,
				onMenuClose: this.onMenuClose,
				components: this.getComponentOverrides(),
			};
		}

		// When "hasDraggableParentComponent" is true, this means <Select /> is nested inside <Draggable /> from react-beautiful-dnd
		// This relationship prevents the dropdown menu from opening because of bugs in the default focus state and clicking in a particular area of <Select />
		// Context: https://hello.atlassian.net/wiki/spaces/~989411314/pages/2861097485/Investigation+Notes+for+atlaskit+select+react-beautiful-dnd#Temporary-Solution
		if (hasDraggableParentComponent) {
			const { initialFocusHandled } = this.state;
			const handleClick = () => {
				if (initialFocusHandled) {
					// As <Select /> is already focused, calling this.selectRef.current.focus() again no longer triggers its own handlers (i.e. onMenuOpen, onMenuClose)
					// We have to manually handle the open and close states of the dropdown menu based on a user's click from this point onwards to prevent the bug
					this.setState((state) => ({
						menuIsOpen: !state.menuIsOpen,
					}));
				} else if (!menuIsOpen) {
					// Trigger focus state when ValueContainer is clicked for the first time
					// The focused state will then invoke <Select /> instance's own handlers (e.g. onMenuOpen, onMenuClose)
					// to manage the state of the dropdown menu
					// @ts-expect-error - Property 'selectRef' does not exist on type 'Picker<SelectOption>'
					this.selectRef.current.focus();
					this.setState(() => ({
						initialFocusHandled: true,
					}));
				}
			};
			selectParams = {
				...selectParams,
				// @ts-expect-error - Object literal may only specify known properties, and 'ref' does not exist in type '{ placeholder: string; isDisabled: boolean | undefined; isClearable: boolean; defaultValue: SelectOption | undefined; defaultOptions: boolean; autoFocus: boolean; loadOptions: false | ((filter: string, callback: (arg1: SelectOption[]) => Record<...>) => void); ... 9 more ...; styles: { ...; }; }'
				// + Property 'selectRef' does not exist on type 'Picker<SelectOption>'
				ref: this.selectRef,
				components: {
					...this.getComponentOverrides,
					ValueContainer: this.renderValueContainer,
				},
				onValueContainerClick: handleClick,
				openMenuOnFocus: true,
			};
		}
		const { isClearable } = this.props;
		return (
			<>
				{label && <FieldLabel htmlFor={this.props.inputId} label={label} isRequired={isRequired} />}
				<InlineDialog
					content={errorMessage}
					isOpen={isErrorMessageOpened}
					messageId={`common-components-picker.base.jira-inline-dialog.pickerErrorMessage.${this.props.inputId ?? 'unnamedInputId'}`}
					messageType="transactional"
					placement="right"
				>
					<SelectComponent
						inputId={this.props.inputId}
						{...this.props}
						{...selectParams}
						{...(fg('jfp-a11y-team_update_legacy_select_clear_fuction') ? { isClearable } : {})}
						// @ts-expect-error - Type 'AkSelectOptions<SelectOption> | undefined' is not assignable to type '(OptionsOrGroups<SelectOption, GroupBase<SelectOption>> & OptionsOrGroups<OptionType, GroupBase<OptionType>>) | undefined'.
						options={isAsync ? undefined : this.props.options}
					/>
				</InlineDialog>
			</>
		);
	}
}

const PickerWithIntl = injectIntl(Picker);

const DefaultPicker = <SelectOption,>(props: Omit<ViewProps<SelectOption>, 'intl'>) => {
	// this function exists only to "preserve" generic on the Picker that is lost at injectIntl
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	return <PickerWithIntl {...(props as any)} />;
};
DefaultPicker.defaultProps = Picker.defaultProps;
export default DefaultPicker;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledValueContainer = styled.div({
	flexGrow: '1',
});

const clearButtonWrapperStyles = xcss({
	borderBottom: `${token('border.width')} solid ${token('color.border')}`,
});
