/**
 * @generated SignedSource<<9c1197b3c4202e92fc3f979a38f61d82>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewCover_issueViewRelayFragment$data = {
  readonly jiraCoverMedia: {
    readonly attachment?: {
      readonly mediaApiFileId: string | null | undefined;
      readonly mediaReadToken: string | null | undefined;
    } | null | undefined;
    readonly colorValue?: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ui_issueViewCover_issueViewRelayFragment";
};
export type ui_issueViewCover_issueViewRelayFragment$key = {
  readonly " $data"?: ui_issueViewCover_issueViewRelayFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewCover_issueViewRelayFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewCover_issueViewRelayFragment",
  "selections": [
    {
      "kind": "LinkedField",
      "name": "jiraCoverMedia",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "kind": "LinkedField",
              "name": "attachment",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "mediaApiFileId"
                },
                {
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "durationInSeconds",
                      "value": 900
                    },
                    {
                      "kind": "Literal",
                      "name": "maxTokenLength",
                      "value": 65534
                    }
                  ],
                  "kind": "ScalarField",
                  "name": "mediaReadToken",
                  "storageKey": "mediaReadToken(durationInSeconds:900,maxTokenLength:65534)"
                }
              ]
            }
          ],
          "type": "JiraAttachmentBackground"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "kind": "ScalarField",
              "name": "colorValue"
            }
          ],
          "type": "JiraColorBackground"
        }
      ]
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "94caa2b1dd50278ff361d8bdb29b385b";

export default node;
