/**
 * @generated SignedSource<<71d04c63b054fa718f291e0b7e5dc3b0>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type relayLayoutItems_issueViewLayoutTemplatesLayoutItem_RelayLayoutItemFragmentContainer_rootRelayFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutSingleSelectUserPickerField_IssueViewSingleSelectUserPickerField_rootRelayFragment">;
  readonly " $fragmentType": "relayLayoutItems_issueViewLayoutTemplatesLayoutItem_RelayLayoutItemFragmentContainer_rootRelayFragment";
};
export type relayLayoutItems_issueViewLayoutTemplatesLayoutItem_RelayLayoutItemFragmentContainer_rootRelayFragment$key = {
  readonly " $data"?: relayLayoutItems_issueViewLayoutTemplatesLayoutItem_RelayLayoutItemFragmentContainer_rootRelayFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"relayLayoutItems_issueViewLayoutTemplatesLayoutItem_RelayLayoutItemFragmentContainer_rootRelayFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "relayLayoutItems_issueViewLayoutTemplatesLayoutItem_RelayLayoutItemFragmentContainer_rootRelayFragment",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutSingleSelectUserPickerField_IssueViewSingleSelectUserPickerField_rootRelayFragment"
    }
  ],
  "type": "Query"
};

(node as any).hash = "f738cfb090c8c6a6980c9dc0f0d52f94";

export default node;
