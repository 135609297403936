import React, { memo, useCallback, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import IssueKeyWithResolution from '@atlassian/jira-issue-key-with-resolution/src/ui/index.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import { isLeftClickOnly } from '../../common/utils.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const DATA_TEST_ID = 'issue.issue-view.views.common.issue-line-card.issue-line-card-view.key';

export const IssueKey = memo<Props>(
	({
		issueKey,
		issueLink,
		remoteServerHostname,
		hideTooltipOnMouseDown,
		showHoverPreviewEnabled,
		isResolved,
		statusCategory,
		isVisuallyRefreshedStyle,
	}: Props) => {
		const intl = useIntl();

		const { push } = useRouterActions();
		const tooltipContent = remoteServerHostname === undefined ? null : remoteServerHostname;

		const handleLinkKeyClick = useCallback(
			(e: MouseEvent) => {
				e.preventDefault();
				if (issueLink != null && isLeftClickOnly(e)) {
					push(issueLink);
				} else {
					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					window.open(issueLink, '_blank');
				}
			},
			[issueLink, push],
		);

		return (
			<IssueKeyWithResolution
				issueKey={issueKey}
				issueLink={issueLink}
				tooltipContent={tooltipContent}
				hideTooltipOnMouseDown={hideTooltipOnMouseDown}
				showHoverPreviewEnabled={showHoverPreviewEnabled}
				isResolved={isResolved}
				statusCategory={statusCategory}
				isVisuallyRefreshedStyle={isVisuallyRefreshedStyle}
				onClick={handleLinkKeyClick}
				testId={DATA_TEST_ID}
				label={intl.formatMessage(messages.issueKeyWithResolution, { issueKey, isResolved })}
			/>
		);
	},
);

export default IssueKey;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Key = styled.a<{ shouldDisplayStrikethrough?: boolean }>({
	outline: 'none',
	marginLeft: token('space.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.link'),
	font: token('font.body.UNSAFE_small'),
	fontWeight: token('font.weight.medium'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	textDecoration: (props) => (props.shouldDisplayStrikethrough ? 'line-through' : 'none'),
	whiteSpace: 'nowrap',
});
