/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import { ConfluencePageLineCardGroup } from '@atlassian/jira-issue-view-common-views/src/confluence-content-line-card/ui/confluence-page-line-card-group-view/confluence-page-line-card-group-view.tsx';
import type { ConfluencePage } from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import { Container } from '@atlassian/jira-issue-view-common-views/src/smart-link-content/styled.tsx';
import { FormattedMessage, type MessageDescriptor } from '@atlassian/jira-intl';

export type ConfluenceLinksSectionBaseProps = {
	groupId: string;
	onPageClick?: () => void;
	onAuthenticateApplink: (href: string) => void;
	onDeleteConfluencePageLink: (id: string) => void;
	systemConfluenceAppLinkUrl: string | undefined;
};

export type ConfluenceLinksSectionProps = ConfluenceLinksSectionBaseProps & {
	pages: ConfluencePage[];
	onShowMore: () => void;
	hasNextMentionedPage: boolean;
	headingDescriptor: MessageDescriptor;
	showMoreButtonDescriptor: MessageDescriptor;
};

export const ConfluenceLinksSection = ({
	pages,
	groupId,
	hasNextMentionedPage,
	onShowMore,
	onPageClick,
	onAuthenticateApplink,
	onDeleteConfluencePageLink,
	systemConfluenceAppLinkUrl,
	headingDescriptor,
	showMoreButtonDescriptor,
}: ConfluenceLinksSectionProps) => {
	return (
		<>
			<Box xcss={groupContainerStyles}>
				<Heading as="h3" size="xxsmall">
					<FormattedMessage {...headingDescriptor} />
				</Heading>
				<Box xcss={containerWrapperStyles}>
					<Container>
						<ConfluencePageLineCardGroup
							groupId={groupId}
							pages={pages}
							onClick={onPageClick}
							onAuthenticateApplink={onAuthenticateApplink}
							onDeleteConfluencePageLink={onDeleteConfluencePageLink}
							systemConfluenceAppLinkUrl={systemConfluenceAppLinkUrl}
						/>
					</Container>
				</Box>
			</Box>
			{hasNextMentionedPage && (
				<Button
					css={showMoreButtonStyles}
					appearance="link"
					spacing="compact"
					onClick={onShowMore}
					testId="issue-view-base.content.confluence-content.confluence-links-section.confluence-mentioned-pages-show-more"
				>
					<FormattedMessage {...showMoreButtonDescriptor} />
				</Button>
			)}
		</>
	);
};

/**
 * Using the Button component from @atlaskit/button here to keep the styles consistent with
 * web-links section. The new button from @atlaskit/button/new has different looks.
 * We will migrate to the new button in https://jplat.atlassian.net/browse/JIV-20267
 */
const showMoreButtonStyles = css({
	marginTop: token('space.050'),
	paddingLeft: '0',
	paddingRight: '0',
});

const containerWrapperStyles = xcss({
	marginTop: 'space.100',
});

// 1px padding to the right to be aligned with the add comment box because of the shadow around it
const groupContainerStyles = xcss({
	marginTop: 'space.100',
	marginRight: '0',
	marginBottom: 'space.100',
	marginLeft: '0',
	paddingRight: 'space.025',
});
