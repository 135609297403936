import React from 'react';
import { keyframes, styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { ITEMS_PER_PAGE } from '../constants.tsx';

type Props = {
	pageNumber: number;
	totalCount: number;
};

type TableItemProps = {
	nameTextWidthPercentage: number;
	dateAddedWidth: number;
};

const TableItem = ({ nameTextWidthPercentage, dateAddedWidth }: TableItemProps) => {
	const lineHeight = 40;
	return (
		<SkeletonContainer>
			<SkeletonBox height={24} minWidth={24} lineHeight={lineHeight} borderLeft={6} />
			<SkeletonBox
				height={14}
				width={`${nameTextWidthPercentage}%`}
				lineHeight={lineHeight}
				borderLeft={8}
				borderRight={20}
			/>
			<WhiteDiv width={`${100 - nameTextWidthPercentage}%`} />
			<SkeletonBox height={14} minWidth={50} lineHeight={lineHeight} />
			<SkeletonBox height={14} minWidth={dateAddedWidth} lineHeight={lineHeight} borderLeft={16} />
			<SkeletonBox
				height={24}
				minWidth={24}
				lineHeight={lineHeight}
				borderLeft={62 + (170 - dateAddedWidth)}
				borderRight={12}
			/>
		</SkeletonContainer>
	);
};

const AttachmentsTableSkeleton = (props: Props) => {
	const { pageNumber, totalCount } = props;

	const remaining = totalCount - ITEMS_PER_PAGE * (pageNumber - 1);
	const numberOfRows = Math.min(remaining, ITEMS_PER_PAGE);

	const tableRowValues = [
		{ nameTextWidthPercentage: 80, dateAddedWidth: 170 },
		{ nameTextWidthPercentage: 70, dateAddedWidth: 150 },
		{ nameTextWidthPercentage: 80, dateAddedWidth: 160 },
		{ nameTextWidthPercentage: 60, dateAddedWidth: 140 },
		{ nameTextWidthPercentage: 70, dateAddedWidth: 155 },
		{ nameTextWidthPercentage: 80, dateAddedWidth: 170 },
	];

	// this is to make sure there is no added jank from pagination buttons below the table
	const tableBottomPadding = totalCount > 6 ? 55 : 23;

	return (
		<SkeletonContainer>
			<SkeletonBackground />
			<ContainerMask paddingTop={0} paddingBottom={tableBottomPadding}>
				<SkeletonContainer>
					<SkeletonBox height={14} minWidth={50} lineHeight={26} />
					<WhiteDiv width="100%" />
					<SkeletonBox height={14} minWidth={40} lineHeight={26} borderLeft={16} />
					<SkeletonBox height={14} minWidth={100} lineHeight={26} borderLeft={26} />
					<SkeletonBox height={0} minWidth={24} lineHeight={26} borderLeft={144} />
				</SkeletonContainer>
				<SkeletonBox height={2} lineHeight={2} />
				<ContainerMask paddingTop={0} paddingBottom={0}>
					{Array.from({ length: numberOfRows }).map((_, index) => (
						<TableItem {...tableRowValues[index]} key={`table-item--${index}`} />
					))}
				</ContainerMask>
				<SkeletonBox height={2} lineHeight={2} />
			</ContainerMask>
		</SkeletonContainer>
	);
};

AttachmentsTableSkeleton.defaultProps = {
	pageNumber: 1,
	totalCount: ITEMS_PER_PAGE,
};

export default AttachmentsTableSkeleton;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SkeletonContainer = styled.div({
	width: '100%',
	display: 'flex',
	position: 'relative',
	/* This css reset can be removed once JSD attachments dialog is deprecated. */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		minHeight: 'initial',
	},
});

const shimmer = keyframes({
	'0%': {
		backgroundPosition: '-300px 0',
	},
	'100%': {
		backgroundPosition: '2000px 0',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SkeletonBackground = styled.div({
	animationDuration: '1s',
	animationFillMode: 'forwards',
	animationIterationCount: 'infinite',
	animationName: shimmer,
	animationTimingFunction: 'linear',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.neutral'),
	backgroundImage: `linear-gradient( to right, ${token('color.background.neutral')} 10%, ${token('color.background.neutral.subtle')} 30%, ${token('color.background.neutral')} 50% )`,
	backgroundRepeat: 'no-repeat',
	position: 'absolute',
	left: 0,
	right: 0,
	top: 0,
	bottom: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SkeletonBox = styled.div<{
	height: number;
	minWidth?: number;
	width?: string;
	lineHeight: number;
	borderRight?: number;
	borderLeft?: number;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	minHeight: (props) => `${props.height}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	minWidth: (props) => `${props.minWidth}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: (props) => props.width,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderTop: (props) =>
		`${(props.lineHeight - props.height) / 2}px solid ${token('elevation.surface')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderBottom: (props) =>
		`${(props.lineHeight - props.height) / 2}px solid ${token('elevation.surface')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderRight: (props) => `${props.borderRight}px solid ${token('elevation.surface')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderLeft: (props) => `${props.borderLeft}px solid ${token('elevation.surface')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContainerMask = styled.div<{
	paddingTop: number;
	paddingBottom: number;
}>({
	position: 'relative',
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderTop: (props) => `${props.paddingTop}px solid ${token('elevation.surface')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderBottom: (props) => `${props.paddingBottom}px solid ${token('elevation.surface')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WhiteDiv = styled.div<{ width: string }>({
	backgroundColor: token('elevation.surface'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: (props) => props.width,
});
