import React from 'react';
import { token } from '@atlaskit/tokens';

const unknownColor = token('color.icon.success');
const backgroundColor = token('color.icon.inverse');

export const Unknown = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="100%"
		height="100%"
		fill="none"
		viewBox="0 0 16 16"
	>
		<circle cx="8" cy="8" r="7" fill={backgroundColor} />
		<mask id="a" fill={backgroundColor}>
			<path
				d="M15.4 4.9a8 8 0 0 0-1.7-2.5A8.1 8.1 0 0 0 11 .6 7.8 7.8 0 0 0 8 0a7.8 7.8 0 0 0-3.1.6 8 8 0 0 0-2.5 1.7A8.1 8.1 0 0 0 .6 5 7.7 7.7 0 0 0 0 8c0 1 .2 2.1.6 3.1a8 8 0 0 0 1.7 2.5A8.1 8.1 0 0 0 5 15.4 7.8 7.8 0 0 0 8 16a7.8 7.8 0 0 0 3.1-.6 8 8 0 0 0 2.5-1.7 8.2 8.2 0 0 0 1.8-2.6c.4-1 .6-2 .6-3.1a7.8 7.8 0 0 0-.6-3.1z"
				clipRule="evenodd"
				fillRule="evenodd"
			/>
		</mask>
		<path
			fill={unknownColor}
			d="m11.1.6.8-1.8-.8 1.8zM5 .6l.8 1.9L4.9.6zM.6 5l1.9.8-1.9-.8zm0 6.2-1.8.8L.6 11zm1.7 2.5 1.5-1.4-1.5 1.4zM5 15.4l.8-1.9-.8 1.9zm6.2 0 .8 1.8-.8-1.8zm2.5-1.7 1.5 1.4-1.5-1.4zm1.8-2.6 1.8.8-1.8-.8zm1.8-7A10 10 0 0 0 15.1 1l-2.8 2.9a6 6 0 0 1 1.2 1.9L17.2 4zM15.1 1a10.1 10.1 0 0 0-3.2-2l-1.6 3.6a6.1 6.1 0 0 1 2 1.3L15 .9zm-3.2-2A9.8 9.8 0 0 0 8-2v4c.8 0 1.6.2 2.3.5L12-1.2zM8-2a9.8 9.8 0 0 0-3.9.8l1.6 3.7A5.8 5.8 0 0 1 8 2v-4zm-3.9.8A10 10 0 0 0 1 .9l2.9 2.9a6 6 0 0 1 1.9-1.3L4-1.2zM1 .9a10.1 10.1 0 0 0-2.1 3.2l3.7 1.6a6.1 6.1 0 0 1 1.3-2L.9 1zm-2 3.2A9.7 9.7 0 0 0-2 8h4c0-.8.2-1.6.5-2.3L-1.2 4zM-2 8c0 1.4.3 2.7.8 3.9l3.7-1.6A5.8 5.8 0 0 1 2 8h-4zm.8 3.9A10 10 0 0 0 .9 15l2.8-2.9a6 6 0 0 1-1.2-1.9L-1.2 12zM.9 15a10.1 10.1 0 0 0 3.2 2.1l1.6-3.7a6.1 6.1 0 0 1-2-1.3L1 15.1zm3.2 2.1A9.8 9.8 0 0 0 8 18v-4a5.8 5.8 0 0 1-2.3-.5L4 17.2zM8 18c1.4 0 2.7-.3 3.9-.8l-1.6-3.7c-.7.3-1.5.5-2.3.5v4zm3.9-.8a10 10 0 0 0 3.2-2.1l-2.9-2.9a6 6 0 0 1-1.9 1.3l1.6 3.7zm3.2-2.1c.9-1 1.6-2 2.1-3.2l-3.7-1.6a6.2 6.2 0 0 1-1.3 2l2.9 2.8zm2.1-3.2A9.8 9.8 0 0 0 18 8h-4c0 .8-.2 1.6-.5 2.3l3.7 1.6zM18 8c0-1.4-.3-2.7-.8-3.9l-3.7 1.6c.3.7.5 1.5.5 2.3h4z"
			mask="url(#a)"
		/>
		<path
			fill={unknownColor}
			d="M5.4 6.4H7c0-.6.4-1 1-1s1 .4 1 1c0 .4-.2.7-.8 1-.8.5-1 1-1 1.9v.3h1.4v-.3c0-.5.2-.8 1-1.2.7-.5 1-1 1-1.8 0-1.3-1-2.1-2.5-2.1-1.6 0-2.6.9-2.6 2.2zM8 12.2c.6 0 1-.4 1-1 0-.5-.4-.8-1-.8s-1 .3-1 .9c0 .5.4.9 1 .9z"
		/>
	</svg>
);

Unknown.displayName = 'Unknown';
export default Unknown;
