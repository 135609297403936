/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Spanish (International)
export default {
  'fabric.elements.share.copied.to.clipboard.message': 'Enlace copiado en el portapapeles',
  'fabric.elements.share.copylink.button.text': 'Copiar enlace',
  'fabric.elements.share.copypubliclink.button.text': 'Copiar enlace público',
  'fabric.elements.share.failure.icon.label': 'Icono de error',
  'fabric.elements.share.failure.message': 'No se puede compartir',
  'fabric.elements.share.form.comment.label': 'Mensaje (opcional)',
  'fabric.elements.share.form.comment.placeholder': '¿Hay algo que haya que saber?',
  'fabric.elements.share.form.info.message.no.invite.confluence':
    'Los destinatarios verán el nombre de la página y tu mensaje',
  'fabric.elements.share.form.info.message.no.invite.jira':
    'Los destinatarios verán el nombre de la incidencia y tu mensaje',
  'fabric.elements.share.form.info.message.no.invite.jira.issue-term-refresh':
    'Los destinatarios verán el nombre de la actividad y tu mensaje',
  'fabric.elements.share.form.no-permissions': 'No puedes compartir.',
  'fabric.elements.share.form.public.send': 'Enviar enlace público',
  'fabric.elements.share.form.public.share': 'Compartir enlace público',
  'fabric.elements.share.form.required-field.summary': 'Los campos obligatorios están marcados con un asterisco',
  'fabric.elements.share.form.retry': 'Reintentar',
  'fabric.elements.share.form.send': 'Enviar',
  'fabric.elements.share.form.share': 'Compartir',
  'fabric.elements.share.form.title': 'Compartir',
  'fabric.elements.share.form.user-picker.add-more': 'Introduce más cosas',
  'fabric.elements.share.form.user-picker.label.confluence': 'Nombres, equipos, grupos o correos electrónicos',
  'fabric.elements.share.form.user-picker.label.email-disabled-confluence': 'Nombres, equipos o grupos',
  'fabric.elements.share.form.user-picker.label.email-disabled-jira': 'Nombres o equipos',
  'fabric.elements.share.form.user-picker.label.email-only': 'Correos electrónicos',
  'fabric.elements.share.form.user-picker.label.jira': 'Nombres, equipos o correos electrónicos',
  'fabric.elements.share.form.user-picker.no-options.existingUserOnly':
    'No hemos encontrado ningún resultado para "{inputValue}".',
  'fabric.elements.share.form.user-picker.no-options.generic':
    'No hemos encontrado ningún resultado para "{inputValue}". Invita a la gente mediante una dirección de correo electrónico.',
  'fabric.elements.share.form.user-picker.placeholder.confluence': 'p. ej. María, equipo naranja, grupo uno',
  'fabric.elements.share.form.user-picker.placeholder.email-disabled-jira': 'p. ej. María, equipo naranja',
  'fabric.elements.share.form.user-picker.placeholder.email-only': 'p. ej. maria@empresa.com',
  'fabric.elements.share.form.user-picker.placeholder.jira': 'p. ej. María, equipo naranja, maria@empresa.com',
  'fabric.elements.share.form.user-picker.validation.required':
    'Selecciona al menos un usuario, grupo, equipo o correo electrónico.',
  'fabric.elements.share.form.user-picker.validation.required-message.confluence':
    'Selecciona al menos una persona, equipo, grupo o correo electrónico',
  'fabric.elements.share.form.user-picker.validation.required-message.email-disabled-confluence':
    'Selecciona al menos una persona, equipo o grupo',
  'fabric.elements.share.form.user-picker.validation.required-message.email-disabled-jira':
    'Selecciona al menos una persona o equipo',
  'fabric.elements.share.form.user-picker.validation.required-message.email-only':
    'Selecciona al menos un correo electrónico',
  'fabric.elements.share.form.user-picker.validation.required-message.jira':
    'Selecciona al menos una persona, equipo o correo electrónico',
  'fabric.elements.share.in.integration.button': 'Compartir en {integrationName}',
  'fabric.elements.share.invite.to.confluence': 'Invitar a Confluence',
  'fabric.elements.share.invite.to.jira': 'Invitar a Jira',
  'fabric.elements.share.main.tab.text.confluence': 'Compartir página',
  'fabric.elements.share.main.tab.text.jira': 'Compartir incidencia',
  'fabric.elements.share.menu.email.label': 'Correo electrónico',
  'fabric.elements.share.menu.slack.label': 'Slack',
  'fabric.elements.share.popup.label': 'Compartir',
  'fabric.elements.share.success.message':
    '{object, select,blogpost {Entrada de blog compartida}board {Tablero compartido}calendar {Calendario compartido}draft {Borrador compartido}filter {Filtro compartido}issue {Incidencia compartida}summary {Resumen compartido}list {Lista compartida}timeline {Cronograma compartido}form {Formulario compartido}media {Recursos multimedia compartidos}page {Página compartida}project {Proyecto compartido}pullrequest {Solicitud de incorporación de cambios compartida}question {Pregunta compartida}report {Informe compartido}repository {Repositorio compartido}request {Solicitud compartida}roadmap {Cronograma compartido}site {Sitio compartido}space {Espacio compartido}other {Enlace compartido}}',
  'fabric.elements.share.to.integration.button': 'Compartir en {integrationName}',
  'fabric.elements.share.trigger.button.icon.label': 'Icono de compartir',
  'fabric.elements.share.trigger.button.invite.text': 'Invitar',
  'fabric.elements.share.trigger.button.text': 'Compartir',
  'fabric.elements.share.trigger.button.tooltip.text': 'Compartir',
};
