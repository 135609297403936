import React, { useState, useEffect, useCallback } from 'react';
import noop from 'lodash/noop';
import { Box, xcss, Pressable } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import CommentAddIcon from '@atlaskit/icon/core/comment-add';
import CloseIcon from '@atlaskit/icon/core/close';
import { IconButton } from '@atlaskit/button/new';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import withContainerWidth from '@atlassian/jira-issue-view-common-utils/src/with-container-width/index.tsx';
import {
	canAddCommentsSelector,
	totalCommentsSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/comment-selector.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { COMMENTS } from '@atlassian/jira-issue-view-common-constants/src/activity-items.tsx';
import { setSelectedActivityItem } from '@atlassian/jira-issue-view-store/src/actions/activity-feed-actions.tsx';
import { useDispatch, useSelector } from '@atlassian/jira-react-redux/src/index.tsx';
import { getSelectedActivityItem } from '@atlassian/jira-issue-view-store/src/selectors/activity-feed-selector.tsx';
import { addCommentFormExpand } from '@atlassian/jira-issue-view-store/src/actions/comment-actions.tsx';
import { messages as activityFeedMessages } from '../../messages.tsx';
import { messages } from './messages.tsx';
import { setHideAddCommentPlaceholder, getHideCommentPlaceholder } from './utils.tsx';

type Props = {
	totalComments: number;
	canAddComments?: boolean;
	containerWidth?: number;
	isSidebarCollapsed?: boolean;
	onToggleSidebar?: (expand: boolean, source?: string) => void;
};

export const AddCommentPlaceholder = ({
	canAddComments,
	totalComments,
	isSidebarCollapsed = false,
	onToggleSidebar = noop,
}: Props) => {
	const { formatMessage } = useIntl();
	const [isHidden, setIsHidden] = useState(getHideCommentPlaceholder());
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const dispatch = useDispatch();
	const selectedActivityItem = useSelector(getSelectedActivityItem);

	useEffect(() => {
		setIsHidden(getHideCommentPlaceholder());
	}, []);

	const handleClose = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'add-comment-placeholder-cross-button',
		});
		fireUIAnalytics(analyticsEvent, 'dismissAddCommentPlaceholder');
		setHideAddCommentPlaceholder(true);
		setIsHidden(true);
	}, [createAnalyticsEvent]);

	if (!canAddComments || totalComments > 0 || isHidden) {
		return null;
	}

	const handleClick = () => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'add-comment-placeholder',
		});
		fireUIAnalytics(analyticsEvent, 'clickAddCommentPlaceholder');

		// Expand the sidebar if it's collapsed
		if (isSidebarCollapsed) {
			onToggleSidebar(true, 'commentPlaceholder');
		}

		// Dispatch a custom event to hide the details tab if it's showing
		if (globalThis.document) {
			const toggleDetailsTabEvent = new CustomEvent('toggle-details-tab', {
				bubbles: true,
				detail: { visible: false },
			});
			globalThis.document.dispatchEvent(toggleDetailsTabEvent);
		}

		// Dispatch Redux action to select the comments tab
		if (!selectedActivityItem || selectedActivityItem.type !== COMMENTS) {
			dispatch(
				setSelectedActivityItem({
					key: COMMENTS,
					type: COMMENTS,
					name: formatMessage(activityFeedMessages.comments),
				}),
			);
		}

		// dispatch addCommentFormExpand which ensures the focus
		dispatch(addCommentFormExpand());
	};

	return (
		<Box xcss={containerStyles}>
			<Pressable
				testId="issue-activity-feed.ui.add-comment-placeholder"
				xcss={wrapperStyles}
				onClick={handleClick}
			>
				<CommentAddIcon label="" color={token('color.icon.subtle')} LEGACY_size="medium" />
				<Box xcss={textStyles}>{formatMessage(messages.addCommentPlaceholderText)}</Box>
			</Pressable>
			<Box xcss={iconContainerStyles}>
				<IconButton
					label={formatMessage(messages.closeAddCommentPlaceholder)}
					onClick={handleClose}
					icon={CloseIcon}
					appearance="subtle"
				/>
			</Box>
		</Box>
	);
};

const containerStyles = xcss({
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
	marginTop: 'space.250',
});

const iconContainerStyles = xcss({
	position: 'absolute',
	right: 'space.100',
});

const wrapperStyles = xcss({
	borderStyle: 'dashed',
	borderColor: 'color.border',
	borderWidth: token('border.width'),
	borderRadius: token('border.radius'),
	height: '48px',
	width: '100%',
	paddingLeft: 'space.200',
	paddingRight: 'space.500',
	display: 'flex',
	alignItems: 'center',
	gap: 'space.100',
	backgroundColor: 'color.background.neutral.subtle',
	':hover': {
		borderStyle: 'solid',
	},
});

const textStyles = xcss({
	font: 'font.heading.xsmall',
	fontWeight: 'font.weight.medium',
	color: 'color.text.subtle',
});

export default flowWithSafeComponent(
	connect(
		(state: State): Props => ({
			canAddComments: canAddCommentsSelector(state),
			totalComments: totalCommentsSelector(state),
		}),
	),
)(withContainerWidth(AddCommentPlaceholder));
