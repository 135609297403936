/**
 * @generated SignedSource<<1bca358a3525e68e9eb9ddcd3f75d84d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type singleLineTextInlineEditView_issueInternalFieldSingleLineText$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FieldEditIcon">;
  readonly " $fragmentType": "singleLineTextInlineEditView_issueInternalFieldSingleLineText";
};
export type singleLineTextInlineEditView_issueInternalFieldSingleLineText$key = {
  readonly " $data"?: singleLineTextInlineEditView_issueInternalFieldSingleLineText$data;
  readonly " $fragmentSpreads": FragmentRefs<"singleLineTextInlineEditView_issueInternalFieldSingleLineText">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "singleLineTextInlineEditView_issueInternalFieldSingleLineText",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "FieldEditIcon"
    }
  ],
  "type": "JiraIssueField",
  "abstractKey": "__isJiraIssueField"
};

(node as any).hash = "d346aa340cbf26814a20c13145e7b2c6";

export default node;
