// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State } from './types.tsx';

type StoreApi = StoreActionApi<State>;

const setState =
	(state: Partial<State> = {}) =>
	({ setState: setSweetState, getState }: StoreApi) => {
		const newState: State = {
			...getState(),
			...state,
		};
		setSweetState(newState);
	};

export const setTransitionInProgress =
	(transitionInProgress: boolean) =>
	({ setState: setSweetState, getState }: StoreApi) => {
		setSweetState({
			ui: {
				...getState().ui,
				transitionInProgress,
			},
		});
	};

export const actions = { setState, setTransitionInProgress } as const;
export type Actions = typeof actions;
