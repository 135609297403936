import {
	CUSTOM_FIELD_MODULE,
	CUSTOM_FIELD_TYPE_MODULE,
	ISSUE_ACTION_MODULE,
	ISSUE_ACTIVITY_MODULE,
	ISSUE_CONTEXT_MODULE,
	ISSUE_GLANCE_MODULE,
	ISSUE_PANEL_MODULE,
	ISSUE_VIEW_BACKGROUND_SCRIPT_MODULE,
	UI_MODIFICATIONS_MODULE,
} from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import type {
	AggExtension,
	Extension,
} from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import { isExtensionHiddenByAppAccessRules } from '@atlassian/jira-forge-ui-utils/src/utils/extension/index.tsx';
import { toMappedExtensions } from '@atlassian/jira-forge-ui-utils/src/utils/fetch-modules/data-transformer/index.tsx';
import type { ForgeResponse } from '@atlassian/jira-issue-fetch-services/src/types.tsx';

/**
 * This function deduplicates issueGlances if and only if there is an issueContext that shares the same installationId.
 * This would happen if both jira:issueContext and jira:issueGlance modules are defined in the same manifest.yml file for a forge app,
 * and solves the issue of having both a glance and context panel appear for the same app.
 * @param forgeData required to iterate through issueGlance and issueContext
 * @returns the same ForgeResponse object
 */
export const dedupeContextAndGlance = (forgeData: ForgeResponse): ForgeResponse => {
	const contextInstallationIds = new Set(
		forgeData.issueContext?.map(({ installationId }) => installationId),
	);
	const newGlanceItems = forgeData.issueGlance.filter(
		({ installationId }) => !contextInstallationIds.has(installationId),
	);

	return {
		...forgeData,
		issueGlance: newGlanceItems,
	};
};

// Transforms the response from gql into the ForgeResponse which is expected by the state
export function formatForgeExtensions(forgeExtensions: AggExtension[]): ForgeResponse {
	const extensions = toMappedExtensions(
		[
			ISSUE_ACTION_MODULE,
			ISSUE_GLANCE_MODULE,
			ISSUE_CONTEXT_MODULE,
			ISSUE_PANEL_MODULE,
			ISSUE_ACTIVITY_MODULE,
			CUSTOM_FIELD_MODULE,
			CUSTOM_FIELD_TYPE_MODULE,
			ISSUE_VIEW_BACKGROUND_SCRIPT_MODULE,
			UI_MODIFICATIONS_MODULE,
		],
		forgeExtensions,
	);

	const getFilteredExtensions = (filterFn: (extension: Extension) => boolean) => ({
		issueAction: extensions[ISSUE_ACTION_MODULE].filter(filterFn),
		issueGlance: extensions[ISSUE_GLANCE_MODULE].filter(filterFn),
		issueContext: extensions[ISSUE_CONTEXT_MODULE].filter(filterFn),
		issuePanel: extensions[ISSUE_PANEL_MODULE].filter(filterFn),
		issueActivity: extensions[ISSUE_ACTIVITY_MODULE].filter(filterFn),
		customField: extensions[CUSTOM_FIELD_MODULE].filter(filterFn),
		customFieldType: extensions[CUSTOM_FIELD_TYPE_MODULE].filter(filterFn),
		issueViewBackgroundScript: extensions[ISSUE_VIEW_BACKGROUND_SCRIPT_MODULE].filter(filterFn),
		uiModifications: extensions[UI_MODIFICATIONS_MODULE].filter(filterFn),
	});

	const mappedResult = {
		...getFilteredExtensions((extension) => !isExtensionHiddenByAppAccessRules(extension)),
		blockedExtensions: {
			...getFilteredExtensions((extension) => isExtensionHiddenByAppAccessRules(extension)),
		},
	};

	const payload = dedupeContextAndGlance(mappedResult);

	return payload;
}
