import type { LiveState } from 'relay-runtime';
import { issueAttachmentsStore } from '@atlassian/jira-issue-attachments-store/src/index.tsx';
import type { State as AttachmentsState } from '@atlassian/jira-issue-attachments-store/src/types.tsx';
import type { State as IssueState } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { issueViewStore } from '@atlassian/jira-issue-view-react-sweet-state/src/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { defaultRegistry } from '@atlassian/react-sweet-state';

type Selector<T> = (state: IssueState) => T;
type SelectorAttachments<T> = (state: AttachmentsState) => T;

// A utility for mapping a Redux selector applied to Issue View Redux store to a Relay Live Resolver
// LiveState value.
export function selectIssueViewState<T>(selector: Selector<T>): LiveState<T> {
	const store = defaultRegistry.getStore(issueViewStore)?.storeState?.getState()?.store;

	if (!store) {
		throw new Error('Issue view store is not available');
	}

	let currentValue = selector(store.getState());
	return {
		read: () => currentValue,
		subscribe: (cb: () => void) =>
			store.subscribe(() => {
				const newValue = selector(store.getState());
				if (newValue === currentValue) {
					return;
				}
				currentValue = newValue;
				cb();
			}),
	};
}

// A utility for mapping a Redux selector applied to Attachements React Sweet State to a Relay Live Resolver
// LiveState value.
export function selectAttachmentsSweetState<T>(selector: SelectorAttachments<T>): LiveState<T> {
	const store = defaultRegistry.getStore(issueAttachmentsStore)?.storeState;
	if (!store) {
		throw new Error('Issue view attachments store is not available');
	}

	let currentValue = selector(store.getState());
	return {
		read: () => currentValue,
		subscribe: (cb: () => void) =>
			store.subscribe(() => {
				const newValue = selector(store.getState());
				if (newValue === currentValue) {
					return;
				}
				currentValue = newValue;
				cb();
			}),
	};
}
