import React, { useEffect, useMemo } from 'react';
import type { Dispatch } from 'redux';
import { styled } from '@compiled/react';
import memoizeOne from 'memoize-one';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { FieldDescription } from '@atlassian/jira-issue-field-description/src/ui/index.tsx';
import { FieldEditIcon } from '@atlassian/jira-issue-field-edit-icon/src/FieldEditIcon.tsx';
import {
	FieldHeadingIconsContainer,
	MultilineFieldHeading,
	MultilineFieldHeadingTitle,
	MultilineFieldHeadingView,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin/src/index.tsx';
import RichTextInlineEditView, {
	type Props as RichTextInlineEditProps,
} from '@atlassian/jira-issue-internal-fields/src/rich-text/rich-text-inline-edit-view.tsx';
import { IssueTaskDecisionProvider } from '@atlassian/jira-issue-task-decision-provider/src/controllers/index.tsx';
import { useRichTextFieldTasksUpdater } from '@atlassian/jira-issue-task-decision-provider/src/controllers/use-rich-text-field-tasks-updater/index.tsx';
import { RICH_CONTENT_FIELD_CONFIRM_ERROR } from '@atlassian/jira-issue-view-common-constants/src/flags.tsx';
import {
	type Area,
	type FieldOptions,
	CONTENT,
	CONTEXT,
} from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import { getIssueModalEditorDropdownPortal } from '@atlassian/jira-issue-view-common-utils/src/get-element/index.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import withContainerWidth from '@atlassian/jira-issue-view-common-utils/src/with-container-width/index.tsx';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field.tsx';
import {
	SectionHeading,
	SectionHeadingTitle,
	DraftIndicator,
	HeadingWithDraft,
} from '@atlassian/jira-issue-view-common/src/component/section-heading/section-heading-view.tsx';
import draftMessages from '@atlassian/jira-issue-view-common/src/messages/drafts.tsx';
import {
	useIssueLayout,
	MIN_EDITOR_SIDEBAR_CONTAINER_WIDTH,
} from '@atlassian/jira-issue-view-services/src/issue-layout-service/context.tsx';
import { mentionProviderSelector } from '@atlassian/jira-issue-view-services/src/mentions/mention-provider-selector.tsx';
import {
	editorExpandedFailure,
	editorSaveFailure,
	editorChangeFailure,
} from '@atlassian/jira-issue-view-store/src/actions/editor-actions.tsx';
import { activityProviderSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/activity-provider-selector.tsx';
import { baseUrlSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import {
	fieldEditingAdfValueSelector,
	fieldHtmlValueSelector,
	fieldInvalidSelector,
	fieldInvalidMessageSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import { mediaContextSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/media-context-selector.tsx';
import { fieldHasDraftSelector } from '@atlassian/jira-issue-view-store/src/selectors/drafts/selectors.tsx';
import { richTextContextIdentifierSelector } from '@atlassian/jira-issue-view-store/src/selectors/rich-text-selector.tsx';
import { isFieldInTabSelector } from '@atlassian/jira-issue-view-store/src/selectors/tab-selector.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { view_issueViewBase_MultilineField$key } from '@atlassian/jira-relay/src/__generated__/view_issueViewBase_MultilineField.graphql';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import { messages } from './messages.tsx';

// @ts-expect-error - TS2304 - Cannot find name 'BaseUrl'.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const performPut = (baseUrl: BaseUrl, issueKey: IssueKey, fieldId: string, value: any) => {
	const url = `${baseUrl}/rest/api/3/issue/${issueKey}`;

	return performPutRequest(url, {
		body: JSON.stringify({
			fields: {
				[fieldId]: value,
			},
		}),
	});
};

export type Props = RichTextInlineEditProps & {
	area: Area;
	hasDraft: boolean | undefined;
	label: string;
	containerWidth: number;
	issueKey: IssueKey;
	fieldOptions: FieldOptions<ADF>;

	// overrides
	adfValue?: ADF | undefined;
	adfValueDraft?: ADF | undefined;
	onSave?: (value: ADF) => void;
	isEditable?: boolean;
	fragmentKey: view_issueViewBase_MultilineField$key;
};

export const MultilineField = (props: Props) => {
	const {
		label,
		area,
		hasDraft,
		isEditing,
		containerWidth,
		issueKey,
		adfValue: adfValueServer,
		adfValueDraft,
		fragmentKey,
		...otherProps
	} = props;

	const adfValue = useMemo(() => {
		if (fg('relay-migration-issue-fields-multi-line-text-fg')) {
			return hasDraft && isEditing ? adfValueDraft : adfValueServer;
		}
		return adfValueServer;
	}, [adfValueDraft, adfValueServer, hasDraft, isEditing]);

	const intl = useIntl();
	const shouldShowDraftIndicator = hasDraft && !isEditing;
	const MultilineFieldHeadingWrapper = fg('issue_view_field_config_edit')
		? MultilineFieldHeadingView
		: MultilineFieldHeading;
	const HeadingWrapper = area === CONTENT ? SectionHeading : MultilineFieldHeadingWrapper;
	const HeadingTitle = area === CONTENT ? SectionHeadingTitle : MultilineFieldHeadingTitle;
	const showPinButton = getShowPinButton(area);
	const [, { updateSidebarMinWidth, resetSidebarMinWidth }] = useIssueLayout();

	// When edit-view is displayed, create an empty `TaskDecisionProvider` since `props.onConfirm` will handle the ADF update
	const editorTaskDecisionProvider = useMemo(() => new IssueTaskDecisionProvider(), []);

	const toggleTask = useRichTextFieldTasksUpdater(props.fieldId, props.fieldOptions);
	const rendererTaskDecisionProvider = useMemo(
		() => new IssueTaskDecisionProvider(toggleTask),
		[toggleTask],
	);

	// eslint-disable-next-line @atlassian/relay/query-restriction
	const data = useFragment<view_issueViewBase_MultilineField$key>(
		graphql`
			fragment view_issueViewBase_MultilineField on JiraIssueField {
				...FieldEditIcon
			}
		`,
		fragmentKey,
	);

	useEffect(() => {
		if (area === CONTEXT) {
			if (isEditing === true) {
				updateSidebarMinWidth(MIN_EDITOR_SIDEBAR_CONTAINER_WIDTH);
				return;
			}
			resetSidebarMinWidth();
		}
	}, [isEditing, area, updateSidebarMinWidth, resetSidebarMinWidth]);

	return (
		<TextareaWrapperWithoutMargin>
			<HeadingWrapper>
				<HeadingWithDraft>
					<HeadingTitle data-testid={`issue.views.issue-base.common.${props.fieldId}.label`}>
						{label}
					</HeadingTitle>
					{shouldShowDraftIndicator === true ? (
						<DraftIndicator data-testid="issue-view-base.common.switching-multiline-field.draft-indicator">
							{`• ${intl.formatMessage(draftMessages.draftIndicator)}`}
						</DraftIndicator>
					) : null}
					<FieldHeadingIconsContainer
						isCustomFieldConfigurationEnabled={fg('issue_view_field_config_edit')}
					>
						{issueKey !== undefined && props.fieldId !== undefined && (
							<FieldDescription issueKey={issueKey} fieldKey={props.fieldId} label={label} />
						)}
						{showPinButton === true && <FieldPin fieldId={props.fieldId} label={label} />}
						{fg('issue_view_field_config_edit') && (
							<UFOSegment name="issue-view-field-icon-edit">
								<FieldEditIcon fieldId={props.fieldId} fragmentKey={data} />
							</UFOSegment>
						)}
					</FieldHeadingIconsContainer>
				</HeadingWithDraft>
			</HeadingWrapper>
			{fg('adding-more-context-for-drop-events') ? (
				<ContextualAnalyticsData sourceType={SCREEN} sourceName="rich-text-field">
					<RichTextInlineEditView
						isEditing={isEditing}
						label={label}
						adfValue={adfValue}
						editorTaskDecisionProvider={editorTaskDecisionProvider}
						rendererTaskDecisionProvider={rendererTaskDecisionProvider}
						{...otherProps}
					/>
				</ContextualAnalyticsData>
			) : (
				<RichTextInlineEditView
					isEditing={isEditing}
					label={label}
					adfValue={adfValue}
					editorTaskDecisionProvider={editorTaskDecisionProvider}
					rendererTaskDecisionProvider={rendererTaskDecisionProvider}
					{...otherProps}
				/>
			)}
		</TextareaWrapperWithoutMargin>
	);
};

const additionalCallbacksMemo = memoizeOne((dispatch: Dispatch) => ({
	onExpandedFailure: () => dispatch(editorExpandedFailure()),
	onSaveFailure: () => dispatch(editorSaveFailure()),
	onChangeFailure: () => dispatch(editorChangeFailure()),
}));

export default connectField(
	(stateOnMount, ownPropsOnMount) => ({
		fieldId: ownPropsOnMount.fieldId,
		shouldSaveDraft: true,
		// See: https://jdog.jira-dev.com/browse/BENTO-631
		isOptimistic: true,
		saveField:
			ownPropsOnMount.saveFieldOverride && fg('relay-migration-issue-fields-multi-line-text-fg')
				? async ({ value }) => {
						return ownPropsOnMount?.saveFieldOverride?.current?.({ value });
					}
				: ({ baseUrl, issueKey, value }) =>
						performPut(baseUrl, issueKey, ownPropsOnMount.fieldId, value),
		canContainMediaContent: true,
		onSaveFailureFlagType: RICH_CONTENT_FIELD_CONFIRM_ERROR,
		// @ts-expect-error - TS2322 - Type '(state: Readonly<{ agile: Agile; context: ContextState; entities: Readonly<{ applicationRoles?: ApplicationRole[] | undefined; cardCover: CardCover; childrenIssues: ChildrenIssuesState; ... 29 more ...; myPreferences?: Partial<...> | undefined; }>; ... 5 more ...; validators: Validators; }>, intl: IntlShapeV2) => { ...' is not assignable to type 'AdditionalProps<unknown>'.
		additionalProps: (state, intl) => {
			const baseUrl = baseUrlSelector(state);
			return {
				baseUrl,
				portalElement: isFieldInTabSelector(ownPropsOnMount.fieldId)(state)
					? // Using a portal element here because otherwise dropdowns would get cut off
						// See: https://jdog.jira-dev.com/browse/BENTO-4100

						// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
						getIssueModalEditorDropdownPortal() || document.body
					: undefined,
				adfValue: fieldEditingAdfValueSelector(ownPropsOnMount.fieldId)(state),
				...(fg('relay-migration-issue-fields-multi-line-text-fg')
					? {
							/**
							 * This is technically the redux value, not just draft value
							 * It will return essentially draft ?? realValue
							 * So we use hasDraft and isEditing to determine if we should use draft or real value in the component
							 */
							adfValueDraft: fieldEditingAdfValueSelector(ownPropsOnMount.fieldId)(state),
						}
					: {
							htmlValue: fieldHtmlValueSelector(ownPropsOnMount.fieldId)(state),
						}),
				hasDraft: fieldHasDraftSelector(ownPropsOnMount.fieldId)(state),
				isInvalid: fieldInvalidSelector(ownPropsOnMount.fieldId)(state),
				invalidMessage: fieldInvalidMessageSelector(ownPropsOnMount.fieldId)(state),
				mediaContext: mediaContextSelector(state),
				mentionProvider: mentionProviderSelector(state),
				activityProvider: activityProviderSelector(state),
				contextIdentifier: richTextContextIdentifierSelector(state),
				externalId: `issue.${ownPropsOnMount.fieldId}`,
				noValueText: intl.formatMessage(messages.noValueText),
				area: ownPropsOnMount.area,
			};
		},
		additionalCallbacks: additionalCallbacksMemo,
	}),
	undefined,
	{
		shouldPassProps: true,
	},
)(withContainerWidth(MultilineField));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TextareaWrapperWithoutMargin = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"[class*='CompactWrapper']": {
		marginBottom: token('space.200'),
	},
});
