/**
 * This store registry logic is required to ensure that we only initialize the store once per component instance in an SSR friendly way
 *
 * useMemo unfortunately will re-run in concurrent mode, and cause us to create the store multiple times.
 * useMemo also does not listen to changes to useRef so would always run twice even if we tried to update a ref to say it has already been run
 *
 * We can't useLayoutEffect or useEffect either as this will cause SSR to fail
 *
 * This leaves us with the only option of storing refs outside of react.
 *
 * Usage:
 * - Create a ref via useKeyedRef
 * - Use useStableInitialization and pass the ref from useKeyedRef to ensure that the store is only initialized once per component instance
 * - Use toBeCalledOnceFor with the above store to ensure that a function is only called once per store
 */

import { useEffect, useMemo, useState } from 'react';
import type { Store } from 'redux';
import noop from 'lodash/noop';
import type { State as IssueState } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { fg } from '@atlassian/jira-feature-gating';

type KeyedRef = { [issueKey: string]: string };
const keyedRefs: { [issueKey: string]: KeyedRef | undefined } = {};

/**
 * Unfortunately, useRef was not stable enough for our use case in concurrent mode
 * We need to use refs outside of react to ensure a stable reference between concurrent renders eg in strict mode
 */
export const useKeyedRef = (key: string) => {
	if (!keyedRefs[key]) {
		keyedRefs[key] = { key };
	}

	useEffect(() => {
		keyedRefs[key] = undefined;
	}, [key]);

	return keyedRefs[key];
};

const stableRefRegistrySingleton = {
	stableRefRegistry: new WeakMap(),
};

/**
 * A special function to ease feature flagging of store initialization
 *
 */
export const useStableInitialization = <T,>(
	ref: KeyedRef | undefined,
	factoryFunction: () => T,
): [T, () => void] => {
	const useStableFactory = () => {
		const { stableRefRegistry } = stableRefRegistrySingleton;
		// A localRef will ensure better garbage collection in the WeakMap
		const [localRef] = useState<KeyedRef>(() => ref || { no: 'none' });
		if (!stableRefRegistry.has(localRef)) {
			stableRefRegistry.set(localRef, factoryFunction());
		}
		const dispose = () => {
			if (stableRefRegistry.has(localRef)) {
				stableRefRegistry.delete(localRef);
			}
		};
		const result: [T, () => void] = [stableRefRegistry.get(localRef), dispose];
		return result;
	};

	const useMemoryFactory = () => useMemo((): [T, () => void] => [factoryFunction(), noop], []);
	const factoryMethod = fg('jira-concurrent-issue-view-double-setup-fix')
		? // use setState as it preserved value between concurrent renders
			useStableFactory
		: // use memo can be called multiple times for concurrent mode and is unsafe for side-effects
			useMemoryFactory;
	return factoryMethod();
};

const storeInitializationRegistrySingleton = {
	storeInitializationRegistry: new WeakSet<Store<IssueState>>(),
};

export const toBeCalledOnceFor = (callingFor: Store<IssueState>, cb: () => void) => {
	// this function contains a side effect and cannot be called once for a given store
	if (fg('jira-concurrent-issue-view-double-setup-fix')) {
		const storeInitializationRegistry =
			storeInitializationRegistrySingleton.storeInitializationRegistry;
		if (storeInitializationRegistry.has(callingFor)) {
			// already initialized
			return;
		}
		storeInitializationRegistry.add(callingFor);
	}
	cb();
};

export const toBeCalledOnceForCleanup = (callingFor: Store<IssueState>): void => {
	if (fg('jira-concurrent-issue-view-double-setup-fix')) {
		const storeInitializationRegistry =
			storeInitializationRegistrySingleton.storeInitializationRegistry;
		if (storeInitializationRegistry.has(callingFor)) {
			storeInitializationRegistry.delete(callingFor);
		}
	}
};

export const resetStoreRegistriesForTesting = () => {
	// keyedRefs
	Object.keys(keyedRefs).forEach((key) => {
		keyedRefs[key] = undefined;
	});

	// storeInitializationRegistry
	storeInitializationRegistrySingleton.storeInitializationRegistry = new WeakSet<
		Store<IssueState>
	>();

	// stableRefRegistry
	stableRefRegistrySingleton.stableRefRegistry = new WeakMap();
};
