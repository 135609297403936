import isEmpty from 'lodash/isEmpty';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import {
	GENERIC_FIELD_FILTER,
	type Filter,
} from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import type { IssueType } from '@atlassian/jira-polaris-component-issue-types/src/controllers/types.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import { ISSUETYPE_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { State, Props } from '../../types.tsx';
import { saveViewWithAutoSave } from '../save/index.tsx';
import { updateViewState } from '../utils/state/index.tsx';
import { currentViewFilter } from '../utils/views/index.tsx';
import { getCurrentView } from '../../selectors/view/index.tsx';

const isIssueTypeId = (issueTypeIdOrName: string | undefined): boolean =>
	issueTypeIdOrName !== undefined && /^\d+$/.test(issueTypeIdOrName);

const getIssueTypeNameFromId = (
	issueTypeId: IssueTypeId | undefined,
	issueTypes: IssueType[],
): string | undefined => issueTypes.find(({ id }) => id === issueTypeId)?.name;

// New configuration of views uses issue type name, but some lighthouse customers might have configured views using issue type id
// As temporary solution this action converts usages of issue type ids to names in view configs in case:
// - view is filtered by issue type and issue types ids are used as filter values
// - view is grouped by issue type and issue types ids are used as group values
// - view is vertically grouped by issue type and issue types ids are used as vertical group values
// It is dispatched when the view is opened and IIR is enabled.
// After most of the views are migrated to use issue type name in configs, this action will be removed.
export const updateViewIfConfiguredWithIssueTypeIds =
	(): Action<State, Props> =>
	async ({ getState, setState, dispatch }, props) => {
		try {
			const { currentViewSlug, issueTypes } = props;
			const state = getState();
			const currentView = getCurrentView(state, props);

			if (!currentView) {
				return;
			}

			const changedProperties: Partial<View> = {};

			let shouldUpdateFilter = false;
			const newFilter: Filter[] = currentView.filter.map((filter) => {
				if (
					filter.type === GENERIC_FIELD_FILTER &&
					filter.field === ISSUETYPE_FIELDKEY &&
					filter.values.some(({ stringValue }) => isIssueTypeId(stringValue))
				) {
					shouldUpdateFilter = true;

					return {
						...filter,
						values: filter.values.map(({ stringValue }) => ({
							stringValue: getIssueTypeNameFromId(stringValue, issueTypes),
						})),
					};
				}

				return filter;
			});

			if (shouldUpdateFilter) {
				changedProperties.filter = newFilter;
			}

			if (currentView.groupBy === ISSUETYPE_FIELDKEY) {
				const groupByValues = currentView.groupValues;

				if (groupByValues.some(({ id }) => isIssueTypeId(id))) {
					changedProperties.groupValues = groupByValues.map((groupValue) => ({
						...groupValue,
						id: getIssueTypeNameFromId(groupValue.id, issueTypes),
					}));
				}
			}

			if (currentView.verticalGroupBy === ISSUETYPE_FIELDKEY) {
				const verticalGroupByValues = currentView.verticalGroupValues;

				if (verticalGroupByValues.some(({ id }) => isIssueTypeId(id))) {
					changedProperties.verticalGroupValues = verticalGroupByValues.map((groupValue) => ({
						...groupValue,
						id: getIssueTypeNameFromId(groupValue.id, issueTypes),
					}));
				}
			}

			if (isEmpty(changedProperties)) {
				return;
			}

			const { changedView, viewSets } = updateViewState(
				state.viewSets,
				currentViewFilter(currentViewSlug),
				(view: View): View => {
					return {
						...view,
						...changedProperties,
						modified: true,
						draft: {
							...view.draft,
							...changedProperties,
						},
					};
				},
			);

			if (changedView) {
				setState({ viewSets });

				const logChangedProperties = {
					filter: changedProperties.filter !== undefined,
					groupValues: changedProperties.groupValues !== undefined,
					verticalGroupValues: changedProperties.verticalGroupValues !== undefined,
				};

				dispatch(
					saveViewWithAutoSave(changedView.id, (view) => {
						if (view && view.saveError) {
							log.safeErrorWithoutCustomerData(
								'polaris.views.actions.updateViewIfConfiguredWithIssueTypeIds.failure',
								'View configuration failed to update',
								{
									errorMessage: view.saveError.message,
									errorStack: view.saveError.stack,
									...logChangedProperties,
								},
							);
						} else if (view && !view.saveError) {
							log.safeInfoWithoutCustomerData(
								'polaris.views.actions.updateViewIfConfiguredWithIssueTypeIds.success',
								'View configuration was updated',
								logChangedProperties,
							);
						}
					}),
				);
			}
		} catch (error) {
			if (error instanceof Error) {
				log.safeErrorWithoutCustomerData(
					'polaris.views.actions.updateViewIfConfiguredWithIssueTypeIds.error',
					'General error while updating view configuration',
					error,
				);
			}
		}
	};
