import gqlTagGira from 'graphql-tag';

export const QUERY = gqlTagGira`
mutation JwmUpdateField ($input: JiraWorkManagementUpdateFieldInput!) {
  jira {
    jwmUpdateField (input: $input) {
      success
      errors {
        extensions {
          errorType
          statusCode
        }
        message
      }
      fieldId
      options {
			  id
        optionId
        value
				color {
          colorKey
        }
				isDisabled
				selectableGroupKey
				selectableIconUrl
				selectableLabel
				selectableUrl
      }
    }
  }
}
`;
