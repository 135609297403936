import React, { useCallback } from 'react';
import { ButtonItem } from '@atlaskit/menu';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const EditCoverButtonItem = ({
	onClick,
	setDropdownItemRef,
	itemKey,
	isSelected = false,
}: Props) => {
	const { formatMessage } = useIntl();

	const handleClick = useCallback(() => {
		onClick?.(itemKey);
	}, [onClick, itemKey]);

	return (
		<ButtonItem
			key="editCover"
			isSelected={isSelected}
			ref={setDropdownItemRef}
			role="menuitem"
			onClick={handleClick}
		>
			{formatMessage(messages.editCover)}
		</ButtonItem>
	);
};

export default EditCoverButtonItem;
