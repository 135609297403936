import React, { type ReactNode } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { components } from '@atlaskit/select';
import Team from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/other/components/team/index.tsx';
import defaultAvatarImg from '../assets/default-avatar.svg';
import type { TeamFieldType } from '../types.tsx';
import { Icon, OptionWrapper, OptionText } from './styled.tsx';

type Props = {
	data: TeamFieldType;
	children: ReactNode;
};

const OptionComponent = (props: Props) => {
	const value = props.data;
	if (value) {
		const { avatarUrl } = value;

		return (
			// @ts-expect-error - TS2740 - Type '{ children: Element; data: TeamFieldType; }' is missing the following properties from type 'CommonProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>>': clearValue, cx, getStyles, getValue, and 8 more.
			<components.Option {...props}>
				<OptionWrapper>
					{!avatarUrl ? (
						<Box xcss={imageWrapperStyles}>
							<Team alt="" width={16} height={16} />
						</Box>
					) : (
						<Icon src={avatarUrl || defaultAvatarImg} alt="" />
					)}
					<OptionText>{props.children}</OptionText>
				</OptionWrapper>
			</components.Option>
		);
	}
	return null;
};

export default OptionComponent;

const imageWrapperStyles = xcss({
	marginRight: 'space.100',
});
