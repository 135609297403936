/**
 * @generated SignedSource<<225e36f12955615f7e77253c536a8178>>
 * @relayHash e216ac469c4d46d47bca6d6bf136e954
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 9722d57b957675cf7b1d284d50b7d792455a100075a5811b8c62ef5f04307083

import type { ConcreteRequest, Query } from 'relay-runtime';
export type useProjectSpaceInfoGetConfluenceRelationshipQuery$variables = {
  projectAri: string;
};
export type useProjectSpaceInfoGetConfluenceRelationshipQuery$data = {
  readonly devOps: {
    readonly ariGraph: {
      readonly relationships: {
        readonly nodes: ReadonlyArray<{
          readonly to: {
            readonly data: {
              readonly __typename: "ConfluencePage";
              readonly pageId: string;
              readonly space: {
                readonly key: string | null | undefined;
                readonly name: string | null | undefined;
                readonly spaceId: string;
              } | null | undefined;
            } | {
              readonly __typename: "ConfluenceSpace";
              readonly key: string | null | undefined;
              readonly name: string | null | undefined;
              readonly spaceId: string;
            } | {
              // This will never be '%other', but we need some
              // value in case none of the concrete values match.
              readonly __typename: "%other";
            } | null | undefined;
            readonly id: string;
          };
        } | null | undefined> | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type useProjectSpaceInfoGetConfluenceRelationshipQuery = {
  response: useProjectSpaceInfoGetConfluenceRelationshipQuery$data;
  variables: useProjectSpaceInfoGetConfluenceRelationshipQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectAri"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "from",
        "variableName": "projectAri"
      },
      {
        "kind": "Literal",
        "name": "type",
        "value": "project-documentation-entity"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Literal",
    "name": "sort",
    "value": {
      "lastUpdatedSortDirection": "DESC"
    }
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "id"
},
v3 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v4 = {
  "kind": "ScalarField",
  "name": "spaceId"
},
v5 = {
  "kind": "ScalarField",
  "name": "name"
},
v6 = {
  "kind": "ScalarField",
  "name": "key"
},
v7 = [
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/)
],
v8 = {
  "kind": "ScalarField",
  "name": "pageId"
},
v9 = [
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v2/*: any*/)
],
v10 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "useProjectSpaceInfoGetConfluenceRelationshipQuery",
    "selections": [
      {
        "concreteType": "DevOps",
        "kind": "LinkedField",
        "name": "devOps",
        "plural": false,
        "selections": [
          {
            "concreteType": "AriGraph",
            "kind": "LinkedField",
            "name": "ariGraph",
            "plural": false,
            "selections": [
              {
                "args": (v1/*: any*/),
                "concreteType": "AriGraphRelationshipConnection",
                "kind": "LinkedField",
                "name": "relationships",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "AriGraphRelationship",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "AriGraphRelationshipNode",
                        "kind": "LinkedField",
                        "name": "to",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "LinkedField",
                            "name": "data",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": (v7/*: any*/),
                                "type": "ConfluenceSpace"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v8/*: any*/),
                                  {
                                    "concreteType": "ConfluenceSpace",
                                    "kind": "LinkedField",
                                    "name": "space",
                                    "plural": false,
                                    "selections": (v7/*: any*/)
                                  }
                                ],
                                "type": "ConfluencePage"
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useProjectSpaceInfoGetConfluenceRelationshipQuery",
    "selections": [
      {
        "concreteType": "DevOps",
        "kind": "LinkedField",
        "name": "devOps",
        "plural": false,
        "selections": [
          {
            "concreteType": "AriGraph",
            "kind": "LinkedField",
            "name": "ariGraph",
            "plural": false,
            "selections": [
              {
                "args": (v1/*: any*/),
                "concreteType": "AriGraphRelationshipConnection",
                "kind": "LinkedField",
                "name": "relationships",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "AriGraphRelationship",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "AriGraphRelationshipNode",
                        "kind": "LinkedField",
                        "name": "to",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "LinkedField",
                            "name": "data",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": (v9/*: any*/),
                                "type": "ConfluenceSpace"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v8/*: any*/),
                                  {
                                    "concreteType": "ConfluenceSpace",
                                    "kind": "LinkedField",
                                    "name": "space",
                                    "plural": false,
                                    "selections": (v9/*: any*/)
                                  },
                                  (v2/*: any*/)
                                ],
                                "type": "ConfluencePage"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v10/*: any*/),
                                "type": "Node",
                                "abstractKey": "__isNode"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v10/*: any*/),
                                "type": "DevOpsFeatureFlag"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v10/*: any*/),
                                "type": "DevOpsOperationsComponentDetails"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v10/*: any*/),
                                "type": "DevOpsOperationsIncidentDetails"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v10/*: any*/),
                                "type": "DevOpsOperationsPostIncidentReviewDetails"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v10/*: any*/),
                                "type": "DevOpsPullRequestDetails"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v10/*: any*/),
                                "type": "DevOpsRepository"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v10/*: any*/),
                                "type": "DevOpsSecurityVulnerabilityDetails"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v10/*: any*/),
                                "type": "JiraAutodevJob"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v10/*: any*/),
                                "type": "JiraConfluenceRemoteIssueLink"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v10/*: any*/),
                                "type": "JiraCustomRemoteIssueLink"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v10/*: any*/),
                                "type": "JiraIssueRemoteIssueLink"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v10/*: any*/),
                                "type": "JiraWebRemoteIssueLink"
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "9722d57b957675cf7b1d284d50b7d792455a100075a5811b8c62ef5f04307083",
    "metadata": {},
    "name": "useProjectSpaceInfoGetConfluenceRelationshipQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "8aa456fe308803a84ec6398e9ea4c11a";

export default node;
