import React from 'react';
import { SOFTWARE_ISSUE } from '@atlassian/jira-common-constants/src/analytics-sources.tsx';
import type { ViewModeOptions } from '@atlassian/jira-issue-view-model/src/view-mode-options.tsx';
import { isInSidebar } from '@atlassian/jira-issue-view-services/src/issue/issue-view-mode.tsx';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import SwitchToModal from '../issue-configuration-dropdown-group/switch-to-modal/index.tsx';
import SwitchToSidebar from '../issue-configuration-dropdown-group/switch-to-sidebar/index.tsx';
import { TriggerButton } from '../compal-button-dropdown-group/trigger-button/index.tsx';

// Handling for view mode switching resides in the Legacy JS code
// We are currently supporting view mode switching from Bento
// See https://extranet.atlassian.com/display/JPLAT/Bento+Sidebar+on+Boards
const emitEventToLegacyApp = (event: string) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const container = document.getElementById('ghx-issue-fragment');

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (window.AJS) {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.AJS.$(container).trigger(event);
	}
};

const issueOpenActionDropdownGroup = (
	viewModeOptions: ViewModeOptions,
	isSoftwareProjectType: boolean,
	shouldShow: boolean,
	onClick: (itemKey: string, event?: Event, actionAttributes?: Attributes) => void,
	analyticsSource?: string,
) => {
	const viewModeToggleAvailable =
		(isSoftwareProjectType && analyticsSource === SOFTWARE_ISSUE) ||
		viewModeOptions.viewModeSwitchEnabled;
	const COM_PAL_ITEM_KEY = 'compalDialog';

	const shouldShowSwitchToModal =
		viewModeToggleAvailable === true && isInSidebar(viewModeOptions.viewMode);
	const shouldShowSwitchToSidebar =
		viewModeToggleAvailable === true && !isInSidebar(viewModeOptions.viewMode);

	return {
		name: '',
		key: 'issueOpenActionDropdownGroup',
		items: [
			...(shouldShow
				? [<TriggerButton onClick={() => onClick(COM_PAL_ITEM_KEY)} key={COM_PAL_ITEM_KEY} />]
				: []),
			...(shouldShowSwitchToModal
				? [
						<SwitchToModal
							key="switchToModal"
							emitEventToLegacyApp={emitEventToLegacyApp}
							viewModeOptions={viewModeOptions}
						/>,
					]
				: []),
			...(shouldShowSwitchToSidebar
				? [
						<SwitchToSidebar
							key="switchToSidebar"
							emitEventToLegacyApp={emitEventToLegacyApp}
							viewModeOptions={viewModeOptions}
						/>,
					]
				: []),
		],
	};
};

export default issueOpenActionDropdownGroup;
