import React, { useCallback, useEffect, type SyntheticEvent } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import WatchIcon from '@atlaskit/icon/core/migration/eye-open--watch';
import Lozenge from '@atlaskit/lozenge';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import type { FeatureFlagValue } from '@atlassian/jira-feature-flagging-using-meta';
import {
	TASK_FAIL,
	TASK_SUCCESS,
} from '@atlassian/jira-experience-tracker/src/common/constants.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import { TOGGLE_WATCHING_SHORTCUT } from '@atlassian/jira-issue-view-common-constants/src/watchers.tsx';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	useApplication,
	useEdition,
	useProjectKey,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import messages from './messages.tsx';

export type OwnProps = {
	onToggle: (isWatching: boolean) => void;
};

export type Props = {
	isWatching: boolean;
	isKeyboardShortcutEnabled: boolean;
} & OwnProps & {
		onToggleWatching: (
			arg1: SyntheticEvent,
			onSuccess: (source: string, experience: string) => void,
			onError: (source: string, experience: string) => void,
		) => void;
	};

export const ChangeWatchState = (props: Props) => {
	const { isWatching, onToggleWatching, isKeyboardShortcutEnabled, onToggle } = props;
	const { formatMessage } = useIntl();
	const prevIsWatching = usePrevious(isWatching);

	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const application = useApplication(projectKey, true);
	const edition = useEdition(projectKey, true);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const accountId = useAccountId();

	const onSuccess = useCallback(
		(source: string, experience: string) => {
			sendExperienceAnalytics({
				wasExperienceSuccesful: true,
				action: TASK_SUCCESS,
				analyticsSource: source,
				experience,
				application: application ?? null,
				edition: edition ?? null,
			});
			if (fg('one-event-rule-all-watcher-analytics')) {
				getUpdateAnalyticsFlowHelper().fireAnalyticsEnd('watchers', {
					analytics: createAnalyticsEvent({}),
					attributes: {
						fieldType: 'watchers',
						// isWatching represents user's current state, so we need to send the opposite value:
						actionTaken: isWatching ? 'remove' : 'add',
						oldValId: isWatching ? accountId : '',
						newValId: isWatching ? '' : accountId,
					},
				});
			}
		},
		[application, createAnalyticsEvent, accountId, edition, isWatching],
	);

	const onError = useCallback(
		(source: string, experience: string, error?: Error | FetchError) => {
			const additionalAttributes: Record<
				string,
				string | boolean | number | Record<string, FeatureFlagValue>
			> = {};

			if (error?.message && fg('thor_add_missing_attributes_across_issue_view_2')) {
				additionalAttributes.errorMessage = error.message;
			}

			if (error instanceof FetchError && fg('thor_add_missing_attributes_across_issue_view_2')) {
				if (error?.traceId) additionalAttributes.traceId = error.traceId;
				if (error?.statusCode) additionalAttributes.statusCode = error.statusCode;
			}
			sendExperienceAnalytics({
				wasExperienceSuccesful: false,
				action: TASK_FAIL,
				analyticsSource: source,
				experience,
				application: application ?? null,
				edition: edition ?? null,
				...(fg('thor_add_missing_attributes_across_issue_view_2') && {
					additionalAttributes,
				}),
			});
		},
		[application, edition],
	);

	const onToggleWatchingHandler = useCallback(
		(event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) =>
			onToggleWatching(event, onSuccess, onError),
		[onToggleWatching, onSuccess, onError],
	);

	useEffect(() => {
		if (prevIsWatching !== undefined && prevIsWatching !== isWatching) {
			onToggle(isWatching);
		}
	}, [isWatching, onToggle, prevIsWatching]);

	const message = isWatching
		? formatMessage(messages.stopWatching)
		: formatMessage(messages.startWatching);

	return (
		<DropdownItem
			elemBefore={
				<WatchIcon
					label=""
					spacing="spacious"
					LEGACY_size="medium"
					color={isWatching ? 'currentColor' : token('color.icon.selected')}
				/>
			}
			onClick={onToggleWatchingHandler}
			elemAfter={isKeyboardShortcutEnabled ? <Lozenge>{TOGGLE_WATCHING_SHORTCUT}</Lozenge> : null}
		>
			{message}
		</DropdownItem>
	);
};

export default ChangeWatchState;
