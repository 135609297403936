// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createContainer,
	createStore,
	createHook,
	type Action,
} from '@atlassian/react-sweet-state';
import { type Actions, actions } from './actions/index.tsx';
import type { State, ContainerProps, AffectedServicesUpdatedState } from './types.tsx';

export const StakeholderUpdatesStoreContainer = createContainer<ContainerProps>();

export const initialState: State = {
	issueVersion: {
		isNew: undefined,
	},
	limits: {
		emailQuota: 50,
		isEmailQuotaExceeded: false,
		isTrial: false,
	},
	informModal: {
		isUpdateStakeholderLoading: false,
		latestUpdate: {
			isLoading: false,
		},
	},
	isStakeholderUpdatesModalOpen: false,
	stakeholders: {
		loading: true,
	},
};
export const stakeholderUpdatesStore = createStore<State, Actions, ContainerProps>({
	name: 'JSDIncidentManagementStakeholderUpdatesStore',
	initialState,
	actions,
	containedBy: StakeholderUpdatesStoreContainer,
	handlers: {
		onInit:
			(): Action<State, ContainerProps> =>
			async ({ dispatch, getState }) => {
				await dispatch(actions.getIssueVersion());

				const { issueVersion } = getState();

				if (issueVersion.isNew) {
					await dispatch(actions.getLimits());
				}
			},
	},
});

export const useStakeholderUpdatesStore = createHook(stakeholderUpdatesStore);

const delays = [10000, 15000, 25000];
const updatedServicesStore = createStore<
	AffectedServicesUpdatedState,
	{
		updateServices: () => Action<
			AffectedServicesUpdatedState,
			void,
			undefined | Promise<undefined>
		>;
	}
>({
	initialState: {
		hasServicesUpdated: '',
	},

	actions: {
		updateServices:
			() =>
			// @ts-expect-error - TS2322 - Type '({ setState }: StoreActionApi<AffectedServicesUpdatedState>) => void' is not assignable to type 'Action<AffectedServicesUpdatedState, void, Promise<undefined> | undefined>'.
			({ setState }) => {
				delays.forEach((delay) => {
					setTimeout(() => {
						setState({
							hasServicesUpdated: new Date().toISOString(),
						});
					}, delay);
				});
			},
	},
});

export const useUpdatedServices = createHook(updatedServicesStore);
