import React, { type ComponentProps } from 'react';

import { type IntlShape, useIntl } from 'react-intl-next';

import { IconTile, type NewCoreIconProps } from '@atlaskit/icon';
// The linting is a false positive, this is a valid import
// eslint-disable-next-line @atlassian/tangerine/import/entry-points
import AkGoalIcon from '@atlaskit/icon/core/goal';
import KeyResultIcon from '@atlaskit/icon/core/key-result';
import ObjectiveIcon from '@atlaskit/icon/core/objective';
import { token } from '@atlaskit/tokens';

import {
	type GoalAppearance,
	type GoalIconKey,
	type GoalStatus,
	type GoalStatusValue,
	isGoalAppearance,
} from '../types';

import { isMessageId, messages } from './messages';

export interface GoalIconProps {
	/**
	 * The apperance from the goal. This can be obtained from the icon field on a goal in AGG.
	 */
	appearance: GoalAppearance;

	/**
	 * The specific icon to show based on the goal type. This can be obtained from the icon field on a goal or goal type in AGG.
	 */
	goalIconKey: GoalIconKey | undefined;

	/**
	 * The size of the icon, based on the Atlaskit IconTile sizes. This only applies when the visual refresh is not on.
	 */
	size?: ComponentProps<typeof IconTile>['size'];

	/**
	 * A label used for the icon. You must handle localization yourself for this label, but can customise it as you choose.
	 * If you prefer to use the goal's status as a label, you can use the `localizedLabel` prop instead.
	 */
	label?: string;

	/**
	 * A localized label, based on the goal status. This can be obtained from the localizedLabel field on a goal in AGG.
	 *
	 * You can use the `label` prop instead to provide a more contextual label if that's appropriate.
	 */
	localizedLabel?: GoalStatus['localizedLabel'];

	/**
	 * If the visual refresh is enabled.
	 */
	isVisualRefresh?: boolean;

	/**
	 * The spacing to use when the visual refresh is enabled.
	 */
	visualRefreshSpacing?: ComponentProps<typeof AkGoalIcon>['spacing'];

	/**
	 * To upscale the icon to take its container's size
	 */
	shouldScale?: boolean;
}

type ResizableIconProps = NewCoreIconProps & { shouldScale?: boolean };
type ResizableIconType = (props: ResizableIconProps) => JSX.Element;

const appearanceToColorMap = {
	DEFAULT: 'gray',
	MENU: 'gray',
	OFF_TRACK: 'red',
	AT_RISK: 'yellow',
	ON_TRACK: 'green',
} as const;

const appearanceToNewColorMap = {
	MENU: token('color.icon'),
	DEFAULT: token('color.icon.accent.gray'),
	OFF_TRACK: token('color.icon.danger'),
	AT_RISK: token('color.icon.warning'),
	ON_TRACK: token('color.icon.success'),
} as const;

const keyToIconMap = {
	GOAL: AkGoalIcon,
	OBJECTIVE: ObjectiveIcon,
	KEY_RESULT: KeyResultIcon,
};

const getLabel = (
	intl: IntlShape,
	label: GoalIconProps['label'],
	localizedLabel: GoalIconProps['localizedLabel'],
) => {
	if (label) {
		return label;
	}

	if (localizedLabel && isMessageId(localizedLabel?.messageId)) {
		return intl.formatMessage(messages[localizedLabel.messageId]);
	}
	return '';
};

/**
 * The goal icon component is a wrapper which allows displaying a goal icon based on a semantic
 * appearance prop.
 */
export const GoalIcon = ({
	appearance,
	size,
	localizedLabel,
	goalIconKey = 'GOAL',
	isVisualRefresh = false,
	visualRefreshSpacing = 'none',
	label,
	shouldScale = false,
}: GoalIconProps) => {
	const intl = useIntl();

	const iconLabel = getLabel(intl, label, localizedLabel);

	const Icon = keyToIconMap[goalIconKey] ?? keyToIconMap.GOAL;

	if (isVisualRefresh) {
		const ResizableIcon = Icon as ResizableIconType;
		return (
			<ResizableIcon
				shouldScale={shouldScale}
				color={appearanceToNewColorMap[appearance]}
				label={iconLabel}
				spacing={visualRefreshSpacing}
			/>
		);
	}

	return (
		<IconTile
			size={size}
			icon={Icon}
			label={iconLabel}
			appearance={appearanceToColorMap[appearance]}
		/>
	);
};

/**
 * This is here to support backwards compatability with our old API. Where possible, source goal appearance
 * directly from the Atlassian GraphQL API: https://api.atlassian.com/graphql
 *
 * @param statusValue - The status of the goal. If you pass in an unsupported status, it will return the default appearance.
 */
export const normalizeGoalAppearance = (
	statusValue: GoalStatusValue | Lowercase<GoalStatusValue> | string,
): GoalAppearance => {
	const appearanceUppercase = statusValue.toLocaleUpperCase();

	return isGoalAppearance(appearanceUppercase) ? appearanceUppercase : 'DEFAULT';
};
