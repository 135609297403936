import { useState, useRef, useEffect, useCallback, type RefObject } from 'react';

type Props = {
	hoverRef?: RefObject<HTMLElement>;
	shouldTargetFirstChild?: boolean;
};

export const useShowHoverPopover = (props?: Props) => {
	const { hoverRef, shouldTargetFirstChild } = props || {};
	const [isHoverPopoverVisible, setIsHoverPopoverVisible] = useState(false);
	const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

	useEffect(() => {
		return () => {
			timeoutRef.current && clearTimeout(timeoutRef.current);
		};
	}, []);

	const setShowHoverPopover = () => {
		timeoutRef.current = setTimeout(() => setIsHoverPopoverVisible(true), 200);
	};

	const handleShowHoverPopover = useCallback(() => {
		if (!hoverRef) {
			setShowHoverPopover();
			return;
		}
		const target = shouldTargetFirstChild
			? hoverRef?.current?.firstElementChild
			: hoverRef?.current;

		const clientWidth = target?.clientWidth || 0;
		const scrollWidth = target?.scrollWidth || 0;

		if (scrollWidth > clientWidth) {
			setShowHoverPopover();
		}
	}, [hoverRef, shouldTargetFirstChild]);

	const handleRemoveHoverPopover = useCallback(() => {
		timeoutRef.current && clearTimeout(timeoutRef.current);
		setIsHoverPopoverVisible(false);
	}, []);

	return {
		isHoverPopoverVisible,
		hoverAttributes: {
			onMouseEnter: handleShowHoverPopover,
			onMouseLeave: handleRemoveHoverPopover,
			onFocus: handleShowHoverPopover,
			onBlur: handleRemoveHoverPopover,
		},
	};
};
