import React, { memo, useMemo } from 'react';
import { useContextStateIssueAri, useReactionsStateIsActive } from '../../../state/context.tsx';
import { CommentReactions } from './main.tsx';

type ConnectedCommentReactionsProps = {
	showQuickReactions?: boolean;
	onReactionSuccess?: (commentId: string) => void;
};

const ConnectedCommentReactions = memo(
	({ showQuickReactions, onReactionSuccess }: ConnectedCommentReactionsProps) => {
		const [{ issueAri, commentAri }] = useContextStateIssueAri();
		const [{ isActive }] = useReactionsStateIsActive();

		const props = useMemo(
			() => ({
				visible: isActive,
				issueAri,
				commentAri,
				showQuickReactions,
				onReactionSuccess,
			}),
			[isActive, issueAri, commentAri, showQuickReactions, onReactionSuccess],
		);

		const MemoizedCommentReactions = memo(CommentReactions);

		return <MemoizedCommentReactions {...props} />;
	},
);

export default ConnectedCommentReactions;
