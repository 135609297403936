import React, { useCallback, useEffect, useMemo, useState } from 'react';
import memoizeOne from 'memoize-one';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { ConnectedReactionsView, ReactionUpdateType } from '@atlaskit/reactions';
import ThumbsUpIcon from '@atlaskit/icon/core/thumbs-up';
import { Inline, Pressable, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { JiraCommentAri } from '@atlassian/ari/jira';
import { getEmojiProviderWithCustomEmojiUploadDisabled } from '@atlassian/jira-common-atlaskit-services/src/emoji.tsx';
import { store as reactionsStoreFactory } from '@atlassian/jira-common-atlaskit-services/src/reactions.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { shouldShowReactionsSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/reactions-selector.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { COMMENTS } from '@atlassian/jira-issue-view-common-constants/src/activity-items.tsx';
import { getPermalinkStatus } from '@atlassian/jira-platform-issue-permalinks/src/index.tsx';
import UFOLoadHold from '@atlassian/react-ufo/load-hold';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { useRealTimeComments } from '@atlassian/jira-realtime-comments-controller/src/controllers/index.tsx';
import {
	useIsBusinessIssue,
	useIsSoftwareIssue,
} from '@atlassian/jira-issue-util-hooks/src/index.tsx';
import type { Props, StateProps } from './types.tsx';
import { quickEmojiIds, thumbsUpEmojiId } from './constants.tsx';
import messages from './messages.tsx';

// memoize reaction store so it can store all reaction data from issue view comments
const memoizedStoreFactory = memoizeOne(reactionsStoreFactory);

export const ReactionsComponentExperiment = ({
	containerAri = '',
	objectAri = '',
	reactionsStore: reactionsStoreProp,
	showReactions,
	showQuickReactions,
	isRealTimeCommentUpdated,
	onReactionSuccess,
}: Props) => {
	const { formatMessage } = useIntl();
	const [isReactionAlreadyPresent, setIsReactionAlreadyPresent] = useState(false);
	const [isReady, setIsReady] = useState(false);
	const loggedInUserAccountId = useAccountId();
	const cloudId = useCloudId();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const reactionsStore = useMemo(() => {
		if (fg('batch-reactions-iv-llc-comments')) {
			return reactionsStoreProp ?? memoizedStoreFactory();
		}
		return reactionsStoreProp ?? reactionsStoreFactory();
	}, [reactionsStoreProp]);

	const checkReactionsState = useCallback(() => {
		const reactionsState = reactionsStore.getState().reactions[`${containerAri}|${objectAri}`];
		setIsReady(reactionsState?.status === 'READY');
		setIsReactionAlreadyPresent(
			reactionsState?.status === 'READY' ? reactionsState.reactions.length > 0 : false,
		);
	}, [containerAri, objectAri, reactionsStore]);

	useEffect(() => {
		// Register the callback
		reactionsStore.onChange(checkReactionsState);
		// Initial check when the component mounts
		checkReactionsState();
		// Cleanup on unmount
		return () => {
			reactionsStore.removeOnChangeListener(checkReactionsState);
		};
	}, [checkReactionsState, reactionsStore]);

	const emojiProvider = useMemo(
		() => getEmojiProviderWithCustomEmojiUploadDisabled(cloudId, loggedInUserAccountId, false),
		[cloudId, loggedInUserAccountId],
	);

	const reactionSuccessEvent = useCallback(
		(
			action: string,
			commentAri: string,
			emojiId: string,
			reactionsCount: number,
			isDefault = false,
		) => {
			const reactionCountHierarchy = reactionsCount > 1 ? 1 : 0;

			const commentId = JiraCommentAri.parse(commentAri).resourceId;

			const { hasPermalink, permalinkId } = getPermalinkStatus(COMMENTS);
			if (fg('thor-experiment-surface-reaction-comment-analytics')) {
				fireTrackAnalytics(createAnalyticsEvent({}), `reaction ${action}`, emojiId, {
					...(action === ReactionUpdateType.added && { reactionCountHierarchy }),
					commentId,
					isFocusedComment: hasPermalink && commentId === permalinkId,
					isDefaultReaction: isDefault,
					isRealTimeCommentUpdated,
				});
			} else {
				fireTrackAnalytics(createAnalyticsEvent({}), `reaction ${action}`, emojiId, {
					...(action === ReactionUpdateType.added && { reactionCountHierarchy }),
					commentId,
					isRealTimeCommentUpdated,
				});
			}
			onReactionSuccess?.(commentId);
		},
		[createAnalyticsEvent, isRealTimeCommentUpdated, onReactionSuccess],
	);

	const addThumbsUpReaction = useCallback(() => {
		reactionsStore.addReaction(
			containerAri,
			objectAri,
			thumbsUpEmojiId,
			(action, commentAri, emojiId, reactionsCount) => {
				reactionSuccessEvent(action, commentAri, emojiId, reactionsCount, true);
			},
		);
	}, [containerAri, objectAri, reactionSuccessEvent, reactionsStore]);

	if (!showReactions || !reactionsStore || !emojiProvider) {
		return null;
	}

	return (
		<Inline space="space.050" shouldWrap>
			{!isReactionAlreadyPresent && !showQuickReactions && (
				<Pressable
					testId="issue.component.reactions.thumbs-up-button"
					onClick={addThumbsUpReaction}
					xcss={[
						reactionStyles,
						!isReady && disabledStyles,
						isVisualRefreshEnabled() && reactionStylesRefresh,
					]}
					isDisabled={!isReady}
				>
					<ThumbsUpIcon
						color={!isReady ? token('color.icon.disabled') : token('color.icon')}
						label={formatMessage(messages.addThumbsUpReactionLabel)}
					/>
				</Pressable>
			)}
			<ConnectedReactionsView
				ari={objectAri}
				containerAri={containerAri}
				store={reactionsStore}
				emojiProvider={emojiProvider}
				allowAllEmojis
				quickReactionEmojis={
					showQuickReactions
						? {
								ari: objectAri,
								containerAri,
								emojiIds: quickEmojiIds,
							}
						: undefined
				}
				onReactionSuccess={reactionSuccessEvent}
			/>
		</Inline>
	);
};

export const ReactionsComponentControl = ({
	containerAri = '',
	objectAri = '',
	reactionsStore: reactionsStoreProp,
	showReactions,
	isRealTimeCommentUpdated,
	onReactionSuccess,
}: Props) => {
	const loggedInUserAccountId = useAccountId();
	const cloudId = useCloudId();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [emojiProviderInitialising, setEmojiProviderInitialising] = useState(true);

	const reactionsStore = useMemo(() => {
		if (fg('batch-reactions-iv-llc-comments')) {
			return reactionsStoreProp ?? memoizedStoreFactory();
		}
		return reactionsStoreProp ?? reactionsStoreFactory();
	}, [reactionsStoreProp]);

	const emojiProvider = useMemo(
		() => getEmojiProviderWithCustomEmojiUploadDisabled(cloudId, loggedInUserAccountId, false),
		[cloudId, loggedInUserAccountId],
	);

	const reactionSuccessEvent = useCallback(
		(action: string, commentAri: string, emojiId: string, reactionsCount: number) => {
			const reactionCountHierarchy = reactionsCount > 1 ? 1 : 0;
			const commentId = JiraCommentAri.parse(commentAri).resourceId;
			const { hasPermalink, permalinkId } = getPermalinkStatus(COMMENTS);
			if (fg('thor-experiment-surface-reaction-comment-analytics')) {
				fireTrackAnalytics(createAnalyticsEvent({}), `reaction ${action}`, emojiId, {
					...(action === ReactionUpdateType.added && { reactionCountHierarchy }),
					commentId,
					isRealTimeCommentUpdated,
					isFocusedComment: hasPermalink && commentId === permalinkId,
				});
			} else {
				fireTrackAnalytics(createAnalyticsEvent({}), `reaction ${action}`, emojiId, {
					...(action === ReactionUpdateType.added && { reactionCountHierarchy }),
					commentId,
					isRealTimeCommentUpdated,
				});
			}
			onReactionSuccess?.(commentId);
		},
		[createAnalyticsEvent, isRealTimeCommentUpdated, onReactionSuccess],
	);

	useEffect(() => {
		emojiProvider?.finally(() => setEmojiProviderInitialising(false));
	}, [emojiProvider]);

	if (!showReactions || !reactionsStore || !emojiProvider) {
		return null;
	}

	return (
		<>
			{/* Adding a yucky non-suspenseful hold so we wait for emojis to render */}
			{emojiProviderInitialising && fg('jiv-20205-more-ufo-tracing') && (
				<UFOLoadHold name="reactions-view-emoji-provider" />
			)}
			<ConnectedReactionsView
				ari={objectAri}
				containerAri={containerAri}
				store={reactionsStore}
				emojiProvider={emojiProvider}
				allowAllEmojis
				onReactionSuccess={reactionSuccessEvent}
			/>
		</>
	);
};

export const ReactionsComponent = componentWithCondition(
	() => {
		const isSoftwareProject = useIsSoftwareIssue();
		const isBusinessProject = useIsBusinessIssue();
		return (
			(isSoftwareProject || isBusinessProject) &&
			expVal('thor_surface_reaction_comment_experiment', 'isSurfaceReactionsEnabled', false)
		);
	},
	ReactionsComponentExperiment,
	ReactionsComponentControl,
);

const ReactionsWithErrorBoundary = (props: Props) => (
	<ErrorBoundary>
		<ReactionsComponent {...props} />
	</ErrorBoundary>
);

const ReactionsWithRealtimeData = (props: Props) => {
	const [{ isRealTimeCommentUpdated }] = useRealTimeComments();
	return (
		<ReactionsWithErrorBoundary {...props} isRealTimeCommentUpdated={isRealTimeCommentUpdated} />
	);
};

export const Reactions = componentWithFG(
	'thor_send_realtime_attribute',
	ReactionsWithRealtimeData,
	ReactionsWithErrorBoundary,
);

export default connect(
	(state: State): StateProps => ({
		showReactions: shouldShowReactionsSelector(state),
	}),
	{},
)(Reactions);

const reactionStylesRefresh = xcss({
	borderRadius: 'border.radius',
});

const reactionStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	height: '24px',
	backgroundColor: 'color.background.neutral.subtle',
	borderWidth: 'border.width',
	borderStyle: 'solid',
	borderColor: 'color.border',
	borderRadius: 'border.radius.circle',
	position: 'relative',
	color: 'color.text.subtle',

	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
	':active': {
		backgroundColor: 'color.background.neutral.subtle.pressed',
	},
});

const disabledStyles = xcss({
	borderColor: 'color.border.disabled',
	backgroundColor: 'color.background.disabled',
});
