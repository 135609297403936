import React from 'react';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIsJCSProjectType } from '@atlassian/jira-servicedesk-customer-service-common/src/utils/use-is-jcs/index.tsx';
import { useContextState } from '../../../state/context.tsx';
import { CommentContent, type OwnProps } from './view.tsx';

const ConnectedCommentContent = (props: OwnProps) => {
	const [{ projectType }] = useContextState();
	const isJCS = useIsJCSProjectType(projectType);
	const intl = useIntl();
	if (!useContextState) {
		return null;
	}
	return (
		<CommentContent
			{...props}
			isServiceDesk={projectType === SERVICE_DESK_PROJECT}
			isCustomerService={isJCS}
			intl={intl}
		/>
	);
};
export default ConnectedCommentContent;
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { EditorContainer } from './view';
