// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { State, StoreContainerProps } from '../types.tsx';
import type { RuleConfig } from '../../common/types.tsx';
import { fetch } from './fetch.tsx';
import { setAsNonRecurringIssue } from './set-as-non-recurring-issue.tsx';
import { setAsRecurringIssue } from './set-as-recurring-issue.tsx';
import { updateRule } from './update-rule.tsx';

export const setIssueProperties =
	(issueProperties: RuleConfig): Action<State, StoreContainerProps> =>
	({ setState }) => {
		setState({
			issueProperties,
		});
	};

export type Actions = {
	fetch: typeof fetch;
	setIssueProperties: typeof setIssueProperties;
	setAsNonRecurringIssue: typeof setAsNonRecurringIssue;
	setAsRecurringIssue: typeof setAsRecurringIssue;
	updateRule: typeof updateRule;
};

export const actions = {
	fetch,
	setIssueProperties,
	setAsNonRecurringIssue,
	setAsRecurringIssue,
	updateRule,
};
