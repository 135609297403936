import { useCallback } from 'react';
import { useCommandPaletteSessionId } from '@atlassian/jira-command-palette/src/controllers/command-palette/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import type { UserValue } from '@atlassian/jira-issue-field-assignee/src/common/types.tsx';
import useAssigneeField from '@atlassian/jira-issue-field-assignee/src/services/use-assignee-field/index.tsx';
import type { UserOptionValue } from '@atlassian/jira-issue-user-picker/src/types.tsx';
import {
	fireTrackAnalytics,
	useAnalyticsEvents,
	type UIAnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import type { CommandPaletteAssigneeListProps } from '../types.tsx';
import { messages } from './messages.tsx';

export const useSaveAssigneeField = (
	issueKey: string,
	onSave?: CommandPaletteAssigneeListProps['onSave'],
) => {
	const { formatMessage } = useIntl();

	const [{ commandPaletteSessionId }] = useCommandPaletteSessionId();
	const { showFlag } = useFlagsService();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onSaveSuccessfully = useCallback(
		(user: UserValue | UserOptionValue) => {
			const action = 'commandPalette';
			const actionSubject = 'assignIssue';
			const eventActionAndSubject = `${actionSubject} ${action}`;

			const event = createAnalyticsEvent({
				action,
				actionSubject,
			});

			fireTrackAnalytics(event, eventActionAndSubject, {
				assigneeId: user?.accountId,
				commandPaletteSessionId,
			});

			showFlag({
				messageId:
					'issue-view-base.context.assignee.command-palette-assignee.use-save-assignee-field.show-flag.success',
				messageType: 'transactional',
				type: 'success',
				title: formatMessage(messages.successFlagMessage),
			});
		},
		[commandPaletteSessionId, createAnalyticsEvent, formatMessage, showFlag],
	);

	const onFailSave = useCallback(
		(err: Error) => {
			fireErrorAnalytics({
				error: err,
				meta: {
					id: 'commandPaletteAssignIssue',

					packageName: 'jiraIssueView',

					teamName: 'deliveroo',
				},
			});
			showFlag({
				messageId:
					'issue-view-base.context.assignee.command-palette-assignee.use-save-assignee-field.show-flag.error',
				messageType: 'transactional',
				type: 'error',
				title: formatMessage(messages.errorFlagTitle),
				description: formatMessage(messages.errorFlagDescription),
			});
		},
		[formatMessage, showFlag],
	);

	// NOTE - this implementation of saveAssigneeField uses the accountId
	const [, { saveValue: saveAssigneeFieldOld }] = useAssigneeField({
		issueKey,
		onSuccess: onSaveSuccessfully,
		onFailure: onFailSave,
	});

	const saveAssigneeField: (
		value: UserOptionValue,
		meta: null,
		analyticsEvent: UIAnalyticsEvent,
	) => Promise<void> = useCallback(
		async (value) => {
			return onSave?.(value, onSaveSuccessfully, onFailSave);
		},
		[onFailSave, onSave, onSaveSuccessfully],
	);

	// Remove saveAssigneFieldOld when cleaning up relay-migration-issue-fields-assignee-fg
	return { saveAssigneeField, saveAssigneeFieldOld };
};
