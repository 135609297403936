// eslint-disable-next-line max-classes-per-file
import type { AnalyticsEventPayload, UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { PollingOptions } from '@atlassian/jira-issue-create-confluence-content/src/ui/background-provisioning-editor/types.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { SupportedIssueTypes } from '../../../common/constants.tsx';

export type JobParameters = {
	projectId: number;
	requestedEditionId: string;
	sourceComponent: string;
	sourceContext: string;
};

export const LOAD_MESSAGE_NAME = 'GRW_1033_PROVISIONING_REQUEST';
export const PRELOAD_MESSAGE_NAME = 'GRW_1033_PROVISIONING_PRELOAD_REQUEST';

export type LoadMessageData = {
	[LOAD_MESSAGE_NAME]: {
		issueType: SupportedIssueTypes;
		targetEdition: string;
		sourceComponent: string;
		sourceContext: string;
	};
};

type PreloadMessageData = {
	[PRELOAD_MESSAGE_NAME]: null;
};

export type Events = LoadMessageData | PreloadMessageData | null;

export const DEFAULT_POLLING_OPTIONS: Omit<PollingOptions, 'signal'> = {
	maxAttempts: 60,
	maxDuration: 120_000,
	interval: 1_500,
	initialDelay: 0,
};

export const ISSUE_TYPE_TO_TEMPLATE: Record<SupportedIssueTypes, string> = {
	story: 'com.atlassian.confluence.plugins.confluence-software-blueprints:requirements-blueprint',
	epic: 'com.atlassian.confluence.plugins.confluence-business-blueprints:projectplan-blueprint',
	bug: 'com.atlassian.confluence.plugins.confluence-business-blueprints:itsm-known-errors-blueprint',
};

export const DEFAULT_JOB_PARAMETERS: Omit<JobParameters, 'projectId'> = {
	requestedEditionId: 'standard',
	sourceComponent: 'placeholder-template',
	sourceContext: 'placeholder-template',
};

export const DEFAULT_ISSUE_TYPE: SupportedIssueTypes = 'story';

export const JOB_ID = 'placeholder-template';

export const SOURCE_COMPONENT = 'confluencePlaceholderTemplateScreen';

export const SOURCE_CONTEXT = 'confluencePlaceholderTemplateLink';

const ACTION_SUBJECT = 'backgroundProvisioningEditor';

export class MissingMessageData extends Error {}
export class PostMessageError extends Error {}
export class AnalyticsWebClientInitializationError extends Error {}

export const ANALYTICS_EVENTS = {
	preloadedEditor(event: UIAnalyticsEvent, attributes: AnalyticsEventPayload['attributes']) {
		fireTrackAnalytics(event, `${ACTION_SUBJECT} preloaded`, attributes);
	},
	loadedEditor(event: UIAnalyticsEvent, attributes: AnalyticsEventPayload['attributes']) {
		fireTrackAnalytics(event, `${ACTION_SUBJECT} loaded`, attributes);
	},
	pagePublished(event: UIAnalyticsEvent, attributes: AnalyticsEventPayload['attributes']) {
		fireTrackAnalytics(event, `${ACTION_SUBJECT} pagePublished`, attributes);
	},
} as const;

export type AnalyticsEventHandler = (typeof ANALYTICS_EVENTS)[keyof typeof ANALYTICS_EVENTS];
