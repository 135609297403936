import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	NO_GROUPING_GROUP_ID,
	type ArrangementInformation,
	type GroupValueIdToIdArrangement,
} from '@atlassian/jira-polaris-domain-view/src/timeline/types.tsx';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { getCurrentView } from '../../../selectors/view/current/index.tsx';
import { getCurrentViewVerticalGroupBy } from '../../../selectors/view/index.tsx';
import { getCurrentViewArrangementInformation } from '../../../selectors/view/timeline/index.tsx';
import type { Props, State } from '../../../types.tsx';
import { logViewError } from '../../utils/errors.tsx';
import { updateViewState } from '../../utils/state/index.tsx';

const createViewMutation =
	(arrangementInformation = {}) =>
	(view: View): View => {
		if (
			!view.timelineConfig ||
			isEqual(arrangementInformation, view.timelineConfig?.arrangementInformation)
		) {
			return view;
		}

		try {
			return {
				...view,
				timelineConfig: {
					...view.timelineConfig,
					arrangementInformation,
				},
			};
		} catch (error) {
			return view;
		}
	};

const tryParseArrangementString = (
	arrangementString: string | undefined,
): ArrangementInformation | undefined => {
	try {
		return arrangementString ? JSON.parse(arrangementString) : undefined;
	} catch (error) {
		return undefined;
	}
};

export const refreshArrangementInformation =
	(viewId: Ari): Action<State, Props> =>
	async ({ getState, setState }, { viewRemote, onViewLoadingFailed, fields }) => {
		viewRemote
			.fetchArrangementInfo(viewId, fields)
			.then((arrangementString) => {
				const { viewSets, currentView, changedView } = updateViewState(
					getState().viewSets,
					(view) => view.viewId === viewId,
					createViewMutation(tryParseArrangementString(arrangementString)),
				);
				if (currentView === changedView) {
					return;
				}
				setState({
					viewSets,
				});
			})
			.catch((error) => {
				logViewError('refresh-arrangement', error);
				onViewLoadingFailed(error);
			});
	};

const createNewRemoteArrangementString = (
	remoteArrangementString: string | undefined,
	fieldKey: FieldKey,
	groupValueIdToIdArrangement: GroupValueIdToIdArrangement | undefined,
): string =>
	JSON.stringify({
		...(tryParseArrangementString(remoteArrangementString) || {}),
		[fieldKey]: groupValueIdToIdArrangement || {},
	});

export const updateArrangementInformation =
	(groupValueIdToIdArrangement: GroupValueIdToIdArrangement | undefined): Action<State, Props> =>
	async ({ getState, setState }, props) => {
		const view = getCurrentView(getState(), props);
		if (view?.viewId === undefined) {
			return;
		}

		const { viewId } = view;
		const arrangementInfo = getCurrentViewArrangementInformation(getState(), props) || {};
		const groupingKey =
			getCurrentViewVerticalGroupBy(getState(), props)?.key || NO_GROUPING_GROUP_ID;

		const newArrangementInfo = merge({}, arrangementInfo, {
			[groupingKey]: groupValueIdToIdArrangement,
		});

		const { changedView, currentView, viewSets } = updateViewState(
			getState().viewSets,
			(v) => v.viewId === viewId,
			createViewMutation(newArrangementInfo),
		);
		if (changedView === currentView) {
			return;
		}
		setState({
			viewSets,
		});
		props.viewRemote
			.fetchArrangementInfo(viewId, props.fields)
			.then((remoteArrangementString) => {
				props.viewRemote.updateArrangementInfo({
					viewId: props.isCollectionView && view.uuid ? view.uuid : viewId,
					arrangement: createNewRemoteArrangementString(
						remoteArrangementString,
						groupingKey,
						newArrangementInfo[groupingKey],
					),
				});
			})
			.catch((error) => {
				logViewError('update-arrangement', error);
				props.onViewLoadingFailed(error);
			});
	};

export const setArrangementInformation =
	(viewId: Ari, arrangementInformation: ArrangementInformation | undefined): Action<State> =>
	({ getState, setState }) => {
		const { viewSets, changedView } = updateViewState(
			getState().viewSets,
			(view) => view.viewId === viewId,
			createViewMutation(arrangementInformation),
		);

		if (changedView) {
			setState({
				viewSets,
			});
		}
	};
