/**
 * @generated SignedSource<<7f7c5a5058e754cfb708fb2f4c8037c3>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraProjectStyle = "COMPANY_MANAGED_PROJECT" | "TEAM_MANAGED_PROJECT" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type breadcrumbs_issueViewFoundation_IssueBreadcrumbsWithHooksNew$data = {
  readonly issueTypeField: {
    readonly isEditableInIssueView: boolean | null | undefined;
    readonly issueType: {
      readonly avatar: {
        readonly xsmall: string | null | undefined;
      } | null | undefined;
      readonly name: string;
    } | null | undefined;
  } | null | undefined;
  readonly issueTypesForHierarchyAbove: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly hierarchy: {
          readonly level: number | null | undefined;
          readonly name: string | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly issueTypesForHierarchySame: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly hierarchy: {
          readonly level: number | null | undefined;
          readonly name: string | null | undefined;
        } | null | undefined;
        readonly name: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly key: string;
  readonly parentIssueField: {
    readonly isEditableInIssueView: boolean | null | undefined;
    readonly parentIssue: {
      readonly issueTypeField: {
        readonly issueType: {
          readonly avatar: {
            readonly xsmall: string | null | undefined;
          } | null | undefined;
          readonly name: string;
        } | null | undefined;
      } | null | undefined;
      readonly key: string;
      readonly summaryField: {
        readonly text: string | null | undefined;
      } | null | undefined;
      readonly webUrl: AGG$URL | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly projectField: {
    readonly isEditableInIssueView: boolean | null | undefined;
    readonly name: string;
    readonly project: {
      readonly avatar: {
        readonly large: string | null | undefined;
        readonly medium: string | null | undefined;
      } | null | undefined;
      readonly name: string;
      readonly projectStyle: JiraProjectStyle | null | undefined;
      readonly webUrl: AGG$URL | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly summaryField: {
    readonly text: string | null | undefined;
  } | null | undefined;
  readonly webUrl: AGG$URL | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"view_issueViewFoundation_IssueBreadcrumbsNew">;
  readonly " $fragmentType": "breadcrumbs_issueViewFoundation_IssueBreadcrumbsWithHooksNew";
};
export type breadcrumbs_issueViewFoundation_IssueBreadcrumbsWithHooksNew$key = {
  readonly " $data"?: breadcrumbs_issueViewFoundation_IssueBreadcrumbsWithHooksNew$data;
  readonly " $fragmentSpreads": FragmentRefs<"breadcrumbs_issueViewFoundation_IssueBreadcrumbsWithHooksNew">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "key"
},
v1 = {
  "concreteType": "JiraSingleLineTextField",
  "kind": "LinkedField",
  "name": "summaryField",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "text"
    }
  ]
},
v2 = {
  "kind": "ScalarField",
  "name": "webUrl"
},
v3 = {
  "kind": "ScalarField",
  "name": "name"
},
v4 = {
  "concreteType": "JiraIssueTypeHierarchyLevel",
  "kind": "LinkedField",
  "name": "hierarchy",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "level"
    },
    (v3/*: any*/)
  ]
},
v5 = {
  "kind": "ScalarField",
  "name": "isEditableInIssueView"
},
v6 = {
  "concreteType": "JiraIssueType",
  "kind": "LinkedField",
  "name": "issueType",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "concreteType": "JiraAvatar",
      "kind": "LinkedField",
      "name": "avatar",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "xsmall"
        }
      ]
    }
  ]
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "breadcrumbs_issueViewFoundation_IssueBreadcrumbsWithHooksNew",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "view_issueViewFoundation_IssueBreadcrumbsNew"
    },
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "concreteType": "JiraIssueTypeConnection",
      "kind": "LinkedField",
      "name": "issueTypesForHierarchySame",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraIssueTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraIssueType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                (v4/*: any*/)
              ]
            }
          ]
        }
      ]
    },
    {
      "concreteType": "JiraIssueTypeConnection",
      "kind": "LinkedField",
      "name": "issueTypesForHierarchyAbove",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraIssueTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraIssueType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v4/*: any*/)
              ]
            }
          ]
        }
      ]
    },
    {
      "concreteType": "JiraIssueTypeField",
      "kind": "LinkedField",
      "name": "issueTypeField",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        (v6/*: any*/)
      ]
    },
    {
      "concreteType": "JiraParentIssueField",
      "kind": "LinkedField",
      "name": "parentIssueField",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        {
          "concreteType": "JiraIssue",
          "kind": "LinkedField",
          "name": "parentIssue",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "concreteType": "JiraIssueTypeField",
              "kind": "LinkedField",
              "name": "issueTypeField",
              "plural": false,
              "selections": [
                (v6/*: any*/)
              ]
            }
          ]
        }
      ]
    },
    {
      "concreteType": "JiraProjectField",
      "kind": "LinkedField",
      "name": "projectField",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v5/*: any*/),
        {
          "concreteType": "JiraProject",
          "kind": "LinkedField",
          "name": "project",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v2/*: any*/),
            {
              "kind": "ScalarField",
              "name": "projectStyle"
            },
            {
              "concreteType": "JiraAvatar",
              "kind": "LinkedField",
              "name": "avatar",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "medium"
                },
                {
                  "kind": "ScalarField",
                  "name": "large"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssue"
};
})();

(node as any).hash = "7d4441e85888d3df32df884e0c26d93e";

export default node;
