import React, { memo, useEffect, useCallback, useMemo, useState, useRef } from 'react';
import type { Dispatch } from 'redux';
import isEqual from 'lodash/isEqual';
import { useFragment, graphql } from 'react-relay';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import PerformanceMark from '@atlassian/jira-common-performance/src/set-performance-mark.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import type { EntryPointActions } from '@atlassian/jira-entry-point/src/controllers/utils/types.tsx';
import type { EntryPointReferenceSubject } from '@atlassian/jira-entry-point/src/controllers/utils/use-entry-point-load-manager/index.tsx';
import { expVal, expValEquals } from '@atlassian/jira-feature-experiments';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type {
	IssueViewRelayFragment,
	MainIssueAggQueryRelayFragment,
} from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { useIssueFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { usePinnedFields } from '@atlassian/jira-issue-field-pin/src/utils.tsx';
import { CONTEXT_AREA } from '@atlassian/jira-issue-layout-common-constants/src/index.tsx';
import { useUserPreferencesValue } from '@atlassian/jira-issue-user-preference-services/src/main.tsx';
import type { Action } from '@atlassian/jira-issue-view-actions/src/index.tsx';
import { PINNED_FIELDS_SORTABLE_ID } from '@atlassian/jira-issue-view-common-constants/src/layout.tsx';
import {
	PINNED_FIELDS_PANEL,
	PIN_FIELD_PLACEHOLDER_BANNER,
	DETAILS_PANEL,
} from '@atlassian/jira-issue-view-common-constants/src/onboarding-constants.tsx';
import { WrappedInlineFieldConfigContentEntryPoint } from '@atlassian/jira-issue-view-common/src/component/inline-field-config-content/WrappedInlineFieldConfigContentEntryPoint.tsx';
import { FieldsCompactModeProvider } from '@atlassian/jira-issue-view-layout-templates-compact-mode-provider/src/index.tsx';
import * as PerfMarks from '@atlassian/jira-issue-view-layout-templates-constants/src/performance.tsx';
import { ItemList } from '@atlassian/jira-issue-view-layout-templates-item-list/src/index.tsx';
import {
	PinnedFieldHighlightWrapper,
	pinnedFieldHighlightWrapperSelectorName,
} from '@atlassian/jira-issue-view-layout-templates-pinned-fields-banner/src/common/ui/styled.tsx';
import { PinnedFieldsBanner } from '@atlassian/jira-issue-view-layout-templates-pinned-fields-banner/src/ui/index.tsx';
import type { LayoutItems } from '@atlassian/jira-issue-view-layout-templates-services/src/services/context/visible-hidden/visible-hidden-items/index.tsx';
import { ContextGroup } from '@atlassian/jira-issue-view-layout-templates-views-context-group/src/index.tsx';
import { getLayoutItemId } from '@atlassian/jira-issue-view-layout/src/services/utils.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { useEditFieldConfigContext } from '@atlassian/jira-issue-field-edit-field-config-context/src/context.tsx';
import { inlineFieldConfigEntryPoint } from '@atlassian/jira-issue-field-edit-field-config-context/src/inlineFieldConfigEntryPoint.tsx';
import { refreshIssueRequest } from '@atlassian/jira-issue-view-store/src/common/actions/issue-fetch-actions.tsx';
import {
	fireTrackAnalytics,
	useAnalyticsEvents,
	ContextualAnalyticsData,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import {
	useProjectKey,
	useProjectId,
	useApplication,
	useEdition,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { useGlobalRefStoreActions } from '@atlassian/jira-refs-store/src/index.tsx';
import { useIsReorderingContextFieldsExpEnabled } from '@atlassian/jira-issue-view-layout-templates-utils/src/use-is-reorder-fields-experiment-enabled/index.tsx';
import { getApplicationForProject } from '@atlassian/jira-shared-types/src/application.tsx';
import { getEdition, PREMIUM_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { useCustomFieldsConfigurationExperiment } from '@atlassian/jira-issue-field-edit-icon/src/controllers/useCustomFieldsConfigurationExperiment.tsx';
import useConfigurationMode from '@atlassian/jira-issue-view-foundation/src/utils/config-link-mode.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { DateFieldsMessage } from '@atlassian/jira-issue-date-fields-message/src/ui/index.tsx';
import type { ui_issueViewLayoutTemplates_UserPreferences$key as UserPreferencesFragment } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutTemplates_UserPreferences.graphql.js';
import { useGenerateAdditionalAnalyticsEventAttributes } from '../../../../common/utils.tsx';
import AutomationIssueAuditLogPanel from './automation-issue-audit-log-panel/index.tsx';
import { generateContextPanels } from './context-group/utils.tsx';
import { IssueDetailsSettingsPopover } from './IssueDetailsSettingsPopover.tsx';
import messages from './messages.tsx';
import PinFieldDiscoverabilityBanner from './pin-field-configurability/pin-field-discoverability-banner/index.tsx';
import PinFieldInfoBanner from './pin-field-configurability/pin-field-info-banner/index.tsx';

const SHOW_MORE = 'SHOW_MORE' as const;

type Props = {
	// TODO Decomp BENTO-12514 - add useFragment to this component and replace this prop with more specific fragment key
	issueViewRelayFragment?: IssueViewRelayFragment | null;
	rootRelayFragment: MainIssueAggQueryRelayFragment | null;
	items: LayoutItems;
	onToggleExpanded?: (value: boolean) => void;
	onRefreshIssue: () => void;
	containerElement?: HTMLElement | null;
};

type EntryPointProps = {
	entryPointReferenceSubject?: EntryPointReferenceSubject<typeof inlineFieldConfigEntryPoint>;
	entryPointActions?: EntryPointActions;
};

type DivRefWrapperProps = {
	children: React.ReactNode;
	registerKey: string;
};

const DivWithRef = ({ children, registerKey }: DivRefWrapperProps) => {
	const [, { register }] = useGlobalRefStoreActions();

	const onRef = useCallback(
		(ref: HTMLElement | null) => {
			register(registerKey, ref);
		},
		[register, registerKey],
	);

	return <div ref={onRef}>{children}</div>;
};

type AddFieldState = 'closed' | 'adding' | 'edit';

export const PINNED_FIELD_EXPERIMENT_USER_BANNER_PREFERENCE_KEY =
	'jira.user.issue.pinned-fields.banner.project';

const hasPinFieldInfoBannerShownLocStg = createLocalStorageProvider('hasPinFieldInfoBannerShown');

const VisibleHiddenContextSectionViewInner = ({
	items,
	onToggleExpanded,
	issueViewRelayFragment,
	rootRelayFragment,
	onRefreshIssue,
	entryPointReferenceSubject,
	entryPointActions,
	containerElement,
}: Props & EntryPointProps) => {
	const { formatMessage } = useIntl();
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const [{ value: issueFieldsConfig }] = useIssueFieldConfig(issueKey);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [, , sortPinnedFields] = usePinnedFields(issueKey);
	const isCustomFieldConfigurationEnabled = useCustomFieldsConfigurationExperiment();
	const mode = useConfigurationMode();

	let editFieldConfigState;
	let setEditFieldConfigState;
	let inlineFieldConfigEntryPointReferenceSubject;
	let inlineFieldConfigEntryPointActions;
	if (fg('issue_view_field_config_edit')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const contextState = useEditFieldConfigContext();
		editFieldConfigState = contextState.editFieldConfigState;
		setEditFieldConfigState = contextState.setEditFieldConfigState;
		inlineFieldConfigEntryPointReferenceSubject =
			contextState.inlineFieldConfigEntryPointReferenceSubject;
		inlineFieldConfigEntryPointActions = contextState.inlineFieldConfigEntryPointActions;
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		[editFieldConfigState, setEditFieldConfigState] = useState<{
			mode: AddFieldState;
			fieldId: string | null;
		}>({
			mode: 'closed',
			fieldId: null,
		});
		inlineFieldConfigEntryPointReferenceSubject = entryPointReferenceSubject;
		inlineFieldConfigEntryPointActions = entryPointActions;
	}

	let isBannerPreferenceSet: string | null | undefined;
	let setIsBannerPreferenceSet: {
		setValue: (newValue: string | null) => Promise<unknown>;
		deleteValue: () => Promise<unknown>;
	} | null = null;
	let isPinFieldConfigurabilityExperimentEnabled = false;
	let isPinFieldObservabilityExpEnabled = false;
	/* eslint-disable react-hooks/rules-of-hooks */
	if (fg('pin_fields_ga') || fg('jira_pin_field_configurability')) {
		[isBannerPreferenceSet, setIsBannerPreferenceSet] = useUserPreferencesValue(
			PINNED_FIELD_EXPERIMENT_USER_BANNER_PREFERENCE_KEY,
		);
		const projectType = useProjectType(projectKey) || null;
		const isJSMProject = projectType === SERVICE_DESK_PROJECT;
		const appEditions = useAppEditions();
		let editionFromTenantContext;
		if (projectType) {
			editionFromTenantContext = getEdition(getApplicationForProject(projectType), appEditions);
		}
		if (!isJSMProject && editionFromTenantContext !== PREMIUM_EDITION && fg('pin_fields_ga')) {
			isPinFieldConfigurabilityExperimentEnabled = expVal(
				'pin_fields_observation_exp',
				'isPinFieldObservabilityExpEnabled',
				false,
			);
			isPinFieldObservabilityExpEnabled = isPinFieldConfigurabilityExperimentEnabled;
		}
		if (
			!isPinFieldConfigurabilityExperimentEnabled &&
			!isJSMProject &&
			editionFromTenantContext !== PREMIUM_EDITION &&
			fg('jira_pin_field_configurability')
		) {
			isPinFieldConfigurabilityExperimentEnabled = expVal(
				'jira_pin_field_configurability_experiment',
				'isPinFieldConfigurabilityEnabled',
				false,
			);
		}
	}
	const initialIsBannerPreferenceSet = useRef(isBannerPreferenceSet);
	const initialProjectKey = useRef(projectKey);
	const application = useApplication(projectKey, true);
	const projectId = useProjectId(projectKey);
	const edition = useEdition(projectKey, true);

	const [, setPinnedFields] = usePinnedFields(issueKey);
	const [showPinFieldInfoBanner, setShowPinFieldInfoBanner] = useState(false);
	const [showPinFieldDiscoverability, setShowPinFieldDiscoverability] = useState(true);
	const [showPinFieldSection, setShowPinFieldSection] = useState(false);

	useEffect(() => {
		if (
			initialProjectKey &&
			isBannerPreferenceSet !== initialProjectKey.current &&
			!isPinFieldObservabilityExpEnabled
		) {
			setShowPinFieldDiscoverability(false);
			setShowPinFieldSection(false);
		}
	}, [isBannerPreferenceSet, isPinFieldObservabilityExpEnabled]);

	useEffect(() => {
		if (items.pinnedFields.length > 0) {
			setShowPinFieldDiscoverability(false);
		}

		if (items.pinnedFields.length === 0 && !showPinFieldDiscoverability) {
			setShowPinFieldSection(false);
		}
	}, [items.pinnedFields.length, showPinFieldDiscoverability]);

	useEffect(() => {
		if (initialIsBannerPreferenceSet.current === initialProjectKey.current) {
			setShowPinFieldSection(true);
			setShowPinFieldDiscoverability(true);
			fireUIAnalytics(createAnalyticsEvent({}), 'banner shown', 'pinFieldDiscoverability');
		}
	}, [createAnalyticsEvent]);

	useEffect(() => {
		if (
			initialIsBannerPreferenceSet.current === initialProjectKey.current &&
			isBannerPreferenceSet !== '{}' &&
			isPinFieldObservabilityExpEnabled
		) {
			setIsBannerPreferenceSet && setIsBannerPreferenceSet.setValue('{}');
		}
	}, [isBannerPreferenceSet, setIsBannerPreferenceSet, isPinFieldObservabilityExpEnabled]);

	useEffect(() => {
		fireTrackAnalytics(
			createAnalyticsEvent({
				action: 'success',
			}),
			'onboarding initialized',
			'bentoCollapsibleGroup',
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isReorderingContextFieldsExpEnabled = useIsReorderingContextFieldsExpEnabled();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const getSubtitle = (accumulator: any, item: any) => {
		const id = getLayoutItemId(item);
		if (!issueFieldsConfig || !issueFieldsConfig[id]) {
			return accumulator;
		}
		if (accumulator.length === 0) {
			return `${issueFieldsConfig[id].title}`;
		}
		return `${accumulator}, ${issueFieldsConfig[id].title}`;
	};

	const additionalPinnedEventAttributes = useGenerateAdditionalAnalyticsEventAttributes(
		items.pinnedFields,
	);
	const additionalContextEventAttributes = useGenerateAdditionalAnalyticsEventAttributes(
		items.visibleItems,
	);
	const additionalContextHiddenAttributes = useGenerateAdditionalAnalyticsEventAttributes(
		items.hiddenItems,
	);

	const hiddenItemsSubtitle = items.hiddenItems.reduce(getSubtitle, '');
	const visibleItemsSubtitle = items.visibleItems.reduce(getSubtitle, '');
	const pinnedItemsSubtitle = items.pinnedFields.reduce(getSubtitle, '');
	const hasPinnedFields = items.pinnedFields.length > 0;
	const detailsAndMoreFieldsMergedItems = [...items.visibleItems, ...items.hiddenItems];

	const onCreateFieldClick = useCallback(() => {
		inlineFieldConfigEntryPointActions && inlineFieldConfigEntryPointActions.load();
		setEditFieldConfigState({
			mode: 'adding',
			fieldId: null,
		});
	}, [inlineFieldConfigEntryPointActions, setEditFieldConfigState]);

	const handleButtonClick = () => {
		const defaultPinFields = 'assignee,duedate,priority';
		setPinnedFields([defaultPinFields]);
		setShowPinFieldDiscoverability(false);
		hasPinFieldInfoBannerShownLocStg.set('hasPinFieldInfoBannerShown', 'true');
		setShowPinFieldInfoBanner(true);
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'pinnedFieldSetup');
	};

	const handleBannerDimiss = () => {
		setIsBannerPreferenceSet && setIsBannerPreferenceSet.setValue('{}');
		setShowPinFieldDiscoverability(false);
		setShowPinFieldSection(false);
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'pinnedFieldDismiss');
	};

	const handlePinFieldInfoBannerClose = () => {
		setShowPinFieldInfoBanner(false);
	};

	// !Important Ensure https://codebarrel.atlassian.net/browse/GLIDE-155 is done before cleaning this up or two panels will show
	const contextPanels = items.apps.contextPanels.filter((contextItem) => {
		// optional chaining to be extra safe
		return contextItem?.payload?.appKey !== 'com.codebarrel.addons.automation';
	});

	const projectType = expValEquals('jsw_start_and_due_dates', 'cohort', 'variation')
		? useProjectType(projectKey)
		: null;
	const isSoftwareProject = projectType === 'software';

	const route = useCurrentRoute();

	const isInTimelineOrCalendarView = useMemo(() => {
		const timelineAndCalendarRoutes = [
			'software-timeline',
			'software-timeline-classic',
			'software-calendar',
			'software-calendar-classic',
		];
		return timelineAndCalendarRoutes.includes(route?.name);
	}, [route?.name]);

	const isEligibleForDateFieldsMessage =
		isSoftwareProject &&
		isInTimelineOrCalendarView &&
		expValEquals('jsw_start_and_due_dates', 'cohort', 'variation');

	let shouldShowMessage = false;
	let userPreferencesConnectionId = '';

	if (expValEquals('jsw_start_and_due_dates', 'cohort', 'variation')) {
		const userPreferencesData = useFragment<UserPreferencesFragment>(
			graphql`
				fragment ui_issueViewLayoutTemplates_UserPreferences on JiraQuery
				@argumentDefinitions(
					isInTimelineOrCalendarView: { type: "Boolean!", defaultValue: false }
				) {
					userPreferences(cloudId: $cloudId) @include(if: $isInTimelineOrCalendarView) {
						__id
						showDateFieldAssociationMessageByIssueKey(issueKey: $issueKey)
					}
				}
			`,
			rootRelayFragment?.jira || null,
		);

		shouldShowMessage =
			!!userPreferencesData?.userPreferences?.showDateFieldAssociationMessageByIssueKey;

		userPreferencesConnectionId = userPreferencesData?.userPreferences?.__id ?? '';
	}

	const shouldRenderActionButton =
		isCustomFieldConfigurationEnabled && mode === 'show' && fg('inline_config_in_issue_view');

	if (
		editFieldConfigState.mode !== 'closed' &&
		projectId &&
		projectKey &&
		inlineFieldConfigEntryPointReferenceSubject
	) {
		return (
			<WrappedInlineFieldConfigContentEntryPoint
				editFieldConfigState={editFieldConfigState}
				setEditFieldConfigState={setEditFieldConfigState}
				inlineFieldConfigEntryPointReferenceSubject={inlineFieldConfigEntryPointReferenceSubject}
				onRefreshIssue={onRefreshIssue}
				projectId={projectId}
				projectKey={projectKey}
			/>
		);
	}

	return (
		<div data-testid="issue-view-layout-templates-views.ui.context.visible-hidden.ui.context-items">
			{!isPinFieldConfigurabilityExperimentEnabled && !hasPinnedFields && (
				<DivWithRef registerKey={PIN_FIELD_PLACEHOLDER_BANNER}>
					<SpotlightTarget name={PIN_FIELD_PLACEHOLDER_BANNER}>
						<PinnedFieldsBanner />
					</SpotlightTarget>
				</DivWithRef>
			)}

			<PinnedFieldHighlightWrapper
				data-component-selector={pinnedFieldHighlightWrapperSelectorName}
			>
				<div data-testid="issue-view-layout-templates-views.ui.context.visible-hidden.ui.primary-items">
					{isPinFieldConfigurabilityExperimentEnabled &&
					(showPinFieldSection || hasPinnedFields) ? (
						<DivWithRef registerKey={PINNED_FIELDS_PANEL}>
							<SpotlightTarget name={PINNED_FIELDS_PANEL}>
								<PerformanceMark metricKey={PerfMarks.PINNED_FIELDS_BEGIN_MARK_KEY} />
								<ContextualAnalyticsData
									attributes={{
										isPinnedField: true,
									}}
								>
									<ContextGroup
										title={formatMessage(
											messages.pinnedFieldsTitleForPinnedFieldAwarenessExperiment,
										)}
										groupId="pinned-context-items"
										subTitle={pinnedItemsSubtitle}
										initialOpened
										additionalEventAttributes={additionalPinnedEventAttributes}
										containerElement={containerElement}
									>
										<>
											{showPinFieldDiscoverability && (
												<PinFieldDiscoverabilityBanner
													onButtonClick={handleButtonClick}
													onBannerDismiss={handleBannerDimiss}
													isPinFieldObservabilityExpEnabled={isPinFieldObservabilityExpEnabled}
												/>
											)}

											{hasPinnedFields && (
												<ItemList
													items={items.pinnedFields}
													area={CONTEXT_AREA}
													layoutItemsDataFragment={issueViewRelayFragment ?? null}
													sortableId={PINNED_FIELDS_SORTABLE_ID}
													onSortItems={sortPinnedFields}
													rootRelayFragment={rootRelayFragment}
												/>
											)}
											{hasPinnedFields && showPinFieldInfoBanner && (
												<PinFieldInfoBanner onBannerDismiss={handlePinFieldInfoBannerClose} />
											)}
										</>
									</ContextGroup>
								</ContextualAnalyticsData>
								<PerformanceMark metricKey={PerfMarks.PINNED_FIELDS_END_MARK_KEY} />
							</SpotlightTarget>
						</DivWithRef>
					) : (
						hasPinnedFields && (
							<DivWithRef registerKey={PINNED_FIELDS_PANEL}>
								<SpotlightTarget name={PINNED_FIELDS_PANEL}>
									<PerformanceMark metricKey={PerfMarks.PINNED_FIELDS_BEGIN_MARK_KEY} />
									<ContextualAnalyticsData
										attributes={{
											isPinnedField: true,
										}}
									>
										<ContextGroup
											title={formatMessage(messages.pinnedFieldsTitle)}
											groupId="pinned-context-items"
											subTitle={pinnedItemsSubtitle}
											initialOpened
											additionalEventAttributes={additionalPinnedEventAttributes}
											containerElement={containerElement}
										>
											<ItemList
												items={items.pinnedFields}
												area={CONTEXT_AREA}
												layoutItemsDataFragment={issueViewRelayFragment ?? null}
												sortableId={PINNED_FIELDS_SORTABLE_ID}
												onSortItems={sortPinnedFields}
												rootRelayFragment={rootRelayFragment ?? null}
											/>
										</ContextGroup>
									</ContextualAnalyticsData>
									<PerformanceMark metricKey={PerfMarks.PINNED_FIELDS_END_MARK_KEY} />
								</SpotlightTarget>
							</DivWithRef>
						)
					)}

					{(isReorderingContextFieldsExpEnabled
						? detailsAndMoreFieldsMergedItems.length > 0
						: items.visibleItems.length > 0) && (
						<DivWithRef registerKey={DETAILS_PANEL}>
							<PerformanceMark metricKey={PerfMarks.DETAILS_FIELDS_BEGIN_MARK_KEY} />
							<ContextGroup
								title={formatMessage(messages.detailsText)}
								groupId="details-group"
								subTitle={visibleItemsSubtitle}
								initialOpened
								additionalEventAttributes={additionalContextEventAttributes}
								actionButton={
									shouldRenderActionButton ? (
										<IssueDetailsSettingsPopover onCreateFieldClick={onCreateFieldClick} />
									) : null
								}
								containerElement={containerElement}
							>
								<ItemList
									items={
										isReorderingContextFieldsExpEnabled
											? detailsAndMoreFieldsMergedItems
											: items.visibleItems
									}
									area={CONTEXT_AREA}
									layoutItemsDataFragment={issueViewRelayFragment ?? null}
									rootRelayFragment={rootRelayFragment ?? null}
								/>
							</ContextGroup>
							<PerformanceMark metricKey={PerfMarks.DETAILS_FIELDS_END_MARK_KEY} />
						</DivWithRef>
					)}
				</div>
				{!isReorderingContextFieldsExpEnabled && items.hiddenItems.length > 0 && (
					<>
						<PerformanceMark metricKey={PerfMarks.MORE_FIELDS_BEGIN_MARK_KEY} />

						<SpotlightTarget name={SHOW_MORE}>
							<ContextGroup
								title={formatMessage(messages.moreFieldsText)}
								groupId="secondary-context-items"
								onChange={onToggleExpanded}
								subTitle={hiddenItemsSubtitle}
								initialOpened={false}
								additionalEventAttributes={additionalContextHiddenAttributes}
								containerElement={containerElement}
							>
								<ItemList
									items={items.hiddenItems}
									area={CONTEXT_AREA}
									layoutItemsDataFragment={issueViewRelayFragment ?? null}
									rootRelayFragment={rootRelayFragment ?? null}
								/>
							</ContextGroup>
						</SpotlightTarget>

						<PerformanceMark metricKey={PerfMarks.MORE_FIELDS_END_MARK_KEY} />
					</>
				)}
				<AutomationIssueAuditLogPanel />
				{contextPanels.length > 0 && (
					<>
						<PerformanceMark metricKey={PerfMarks.ECOSYSTEM_CONTEXT_BEGIN_MARK_KEY} />
						{generateContextPanels(
							contextPanels,
							issueViewRelayFragment ?? null,
							rootRelayFragment ?? null,
							application,
							edition,
						)}
						<PerformanceMark metricKey={PerfMarks.ECOSYSTEM_CONTEXT_END_MARK_KEY} />
					</>
				)}
				{isEligibleForDateFieldsMessage && shouldShowMessage && (
					<DateFieldsMessage
						rootRelayFragment={rootRelayFragment ?? null}
						userPreferencesConnectionId={userPreferencesConnectionId}
					/>
				)}
			</PinnedFieldHighlightWrapper>
		</div>
	);
};

export const VisibleHiddenContextSectionView = (props: Props) => {
	const { entryPointReferenceSubject, entryPointActions } = useEntryPoint(
		inlineFieldConfigEntryPoint,
		useMemo(() => ({}), []),
	);

	return fg('issue_view_field_config_edit') ? (
		<VisibleHiddenContextSectionViewInner {...props} />
	) : (
		<VisibleHiddenContextSectionViewInner
			{...props}
			// entryPoint props need to be created and passed down if they are not created and retrieved
			// from useEditFieldConfigContext, behind the issue_view_field_config_edit fg.
			entryPointReferenceSubject={entryPointReferenceSubject}
			entryPointActions={entryPointActions}
		/>
	);
};

const VisibleHiddenContextSectionViewInternalNew = (props: Props) => (
	<FieldsCompactModeProvider>
		<VisibleHiddenContextSectionView {...props} />
	</FieldsCompactModeProvider>
);

const VisibleHiddenContextSectionViewInternalOld = (props: Props) => (
	<FieldsCompactModeProvider>
		<VisibleHiddenContextSectionView {...props} />
	</FieldsCompactModeProvider>
);

const VisibleHiddenContextSectionViewInternal = componentWithFG(
	'issue_view_field_config_edit',
	VisibleHiddenContextSectionViewInternalNew,
	VisibleHiddenContextSectionViewInternalOld,
);

const MemoisedVisibleHiddenContextSectionView = memo<Props>(
	VisibleHiddenContextSectionViewInternal,
	isEqual,
);
MemoisedVisibleHiddenContextSectionView.displayName = 'MemoisedVisibleHiddenContextSectionView';

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
	onRefreshIssue: () => {
		dispatch(refreshIssueRequest());
	},
});

export default connect(
	() => ({}),
	mapDispatchToProps,
)((props: Props) => <MemoisedVisibleHiddenContextSectionView {...props} />);
