import { styled } from '@compiled/react';

import * as colors from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ManualRuleEmptyState = styled.div({
	maxWidth: `348px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Message = styled.div({
	font: token('font.body'),
	fontStyle: 'normal',
	display: 'flex',
	alignItems: 'center',
	textAlign: 'center',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginTop: '10px',
	marginRight: token('space.250', '20px'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginBottom: '10px',
	marginLeft: token('space.250', '20px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.accent.gray', colors.N800),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Links = styled.div({
	marginLeft: token('space.050', '4px'),
	marginTop: token('space.025', '1px'),
	justifyContent: 'center',
	display: 'grid',
	textAlign: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ActionLink = styled.span({
	marginTop: token('space.025', '5px'),
	marginRight: token('space.025', '5px'),
	marginBottom: token('space.025', '5px'),
	marginLeft: token('space.025', '5px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EmptyStateIconContainer = styled.div({
	height: `212px`,
	width: `300px`,
	marginTop: token('space.250', '20px'),
	marginRight: token('space.250', '20px'),
	marginBottom: token('space.250', '20px'),
	marginLeft: token('space.250', '20px'),
});
