import { defineMessages } from '@atlassian/jira-intl';

/**
 * Note: any key you add to this will add a similar requirement to add an attached emoji in `constants.tsx`,
 * as well as provide it as an available value in the 'quickReply' query parameter on any issue view route.
 *
 * This value will populate the comment box on any issue when the query param is
 */
export default defineMessages({
	looksGood: {
		id: 'quick-reply.common.looks-good',
		description: 'Prefilled text in the comment box when clicking a link on email with smart reply',
		defaultMessage: 'Looks good!',
	},
	onTrack: {
		id: 'quick-reply.common.on-track',
		description: 'Prefilled text in the comment box when clicking a link on email with smart reply',
		defaultMessage: 'This is on track',
	},
	canHelp: {
		id: 'quick-reply.common.can-help',
		description: 'Prefilled text in the comment box when clicking a link on email with smart reply',
		defaultMessage: 'I can help with this one',
	},
});
