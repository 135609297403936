import {
	getTotalCount,
	getIssueAttachment,
} from '@atlassian/jira-issue-attachments-selectors/src/index.tsx';
import { issueAttachmentsStore } from '@atlassian/jira-issue-attachments-store/src/index.tsx';
import type { State } from '@atlassian/jira-issue-attachments-store/src/types.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook } from '@atlassian/react-sweet-state';

const getIsFetching = (state: State, issueKey: IssueKey) =>
	getIssueAttachment(state, issueKey).isFetching;
const getError = (state: State, issueKey: IssueKey) => getIssueAttachment(state, issueKey).error;
const getOrderDirection = (state: State) => state.meta.orderDirection;
const getOrderField = (state: State) => state.meta.orderField;
const getDeletableCount = (state: State, issueKey: IssueKey) =>
	getIssueAttachment(state, issueKey).deletableCount;
const getStartAt = (state: State) => state.meta.startAt;
const getVisibleAttachments = (state: State, issueKey: IssueKey) =>
	getIssueAttachment(state, issueKey).visibleAttachments;

export const useAttachmentStoreIsFetching = createHook(issueAttachmentsStore, {
	selector: getIsFetching,
});

export const useAttachmentStoreError = createHook(issueAttachmentsStore, {
	selector: getError,
});

export const useAttachmentStoreOrderDirection = createHook(issueAttachmentsStore, {
	selector: getOrderDirection,
});

export const useAttachmentStoreOrderField = createHook(issueAttachmentsStore, {
	selector: getOrderField,
});

export const useAttachmentStoreTotalCount = createHook(issueAttachmentsStore, {
	selector: getTotalCount,
});

export const useAttachmentStoreDeletableCount = createHook(issueAttachmentsStore, {
	selector: getDeletableCount,
});

export const useAttachmentStoreStartAt = createHook(issueAttachmentsStore, {
	selector: getStartAt,
});

export const useAttachmentStoreVisibleAttachments = createHook(issueAttachmentsStore, {
	selector: getVisibleAttachments,
});

export const useAttachmentStoreActions = createHook(issueAttachmentsStore, {
	selector: null,
});
