/**
 * @generated SignedSource<<66c4beb86863b05af194da6693e68612>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ViewPlaybooks$data = {
  readonly isPlaybooksEnabled: boolean | null | undefined;
  readonly " $fragmentType": "ViewPlaybooks";
};
export type ViewPlaybooks$key = {
  readonly " $data"?: ViewPlaybooks$data;
  readonly " $fragmentSpreads": FragmentRefs<"ViewPlaybooks">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ViewPlaybooks",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isPlaybooksEnabled"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "2bafb4f5142aa4dac6346bf392d6a982";

export default node;
