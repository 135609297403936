import React, { useMemo } from 'react';
import type { OptionProps, OptionType } from '@atlaskit/select';
import type { GroupBase, OptionsOrGroups } from '@atlaskit/react-select';
import { useIntl } from '@atlassian/jira-intl';
import { OptionWithButtonStyle } from '@atlassian/jira-select-with-footer-options/src/OptionWithButtonStyle.tsx';
import {
	useAppendStickyFooterOptions,
	type FooterOption,
} from '@atlassian/jira-select-with-footer-options/src/utils.tsx';
import { OptionWithAutoKeyboardScroll } from '@atlassian/jira-select-with-footer-options/src/OptionWithAutoKeyboardScroll.tsx';
import { CREATE_STATUS_OPTION_TYPE, MANAGE_WORKFLOW_OPTION_TYPE } from '../../common/types.tsx';
import messages from './messages.tsx';

export const OptionComponent = ({ children, ...optionProps }: OptionProps) => {
	if (
		optionProps.data.type === CREATE_STATUS_OPTION_TYPE ||
		optionProps.data.type === MANAGE_WORKFLOW_OPTION_TYPE
	) {
		return <OptionWithButtonStyle {...optionProps}>{children}</OptionWithButtonStyle>;
	}
	return <OptionWithAutoKeyboardScroll {...optionProps}>{children}</OptionWithAutoKeyboardScroll>;
};

export const useOptionsWithFooterActions = (
	targetOptions: OptionType[],
): OptionsOrGroups<OptionType, GroupBase<OptionType>> => {
	const { formatMessage } = useIntl();

	const footerOptions: FooterOption[] = useMemo(() => {
		return [
			{
				label: formatMessage(messages.createStatus),
				type: CREATE_STATUS_OPTION_TYPE,
				value: CREATE_STATUS_OPTION_TYPE,
			},
			{
				label: formatMessage(messages.manageWorkflow),
				type: MANAGE_WORKFLOW_OPTION_TYPE,
				value: MANAGE_WORKFLOW_OPTION_TYPE,
			},
		];
	}, [formatMessage]);

	return useAppendStickyFooterOptions(targetOptions, footerOptions);
};
