/**
 * @generated SignedSource<<375213c366bab4911443ac663867df37>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type src_issueViewLayoutTemplatesItemList_ItemList$data = {
  readonly fieldFragments: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly fieldId: string;
        readonly " $fragmentSpreads": FragmentRefs<"src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal_layoutItemFragment">;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "src_issueViewLayoutTemplatesItemList_ItemList";
};
export type src_issueViewLayoutTemplatesItemList_ItemList$key = {
  readonly " $data"?: src_issueViewLayoutTemplatesItemList_ItemList$data;
  readonly " $fragmentSpreads": FragmentRefs<"src_issueViewLayoutTemplatesItemList_ItemList">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayAssigneeFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayDateFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayDateTimeFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayNumberFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayPeopleAndApproversFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayPriorityFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayProjectFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelaySingleLineTextFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelaySprintFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayUrlFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "jcsFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "jsmSentimentCustomFieldFlag"
    }
  ],
  "kind": "Fragment",
  "name": "src_issueViewLayoutTemplatesItemList_ItemList",
  "selections": [
    {
      "alias": "fieldFragments",
      "concreteType": "JiraIssueFieldConnection",
      "kind": "LinkedField",
      "name": "fields",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraIssueFieldEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "fieldId"
                },
                {
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "issueViewRelayAssigneeFieldFlag",
                      "variableName": "issueViewRelayAssigneeFieldFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "issueViewRelayDateFieldFlag",
                      "variableName": "issueViewRelayDateFieldFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "issueViewRelayDateTimeFieldFlag",
                      "variableName": "issueViewRelayDateTimeFieldFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "issueViewRelayNumberFieldFlag",
                      "variableName": "issueViewRelayNumberFieldFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "issueViewRelayPeopleAndApproversFieldFlag",
                      "variableName": "issueViewRelayPeopleAndApproversFieldFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "issueViewRelayPriorityFieldFlag",
                      "variableName": "issueViewRelayPriorityFieldFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "issueViewRelayProjectFieldFlag",
                      "variableName": "issueViewRelayProjectFieldFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "issueViewRelaySingleLineTextFieldFlag",
                      "variableName": "issueViewRelaySingleLineTextFieldFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "issueViewRelaySprintFieldFlag",
                      "variableName": "issueViewRelaySprintFieldFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "issueViewRelayUrlFieldFlag",
                      "variableName": "issueViewRelayUrlFieldFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "jcsFlag",
                      "variableName": "jcsFlag"
                    },
                    {
                      "kind": "Variable",
                      "name": "jsmSentimentCustomFieldFlag",
                      "variableName": "jsmSentimentCustomFieldFlag"
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal_layoutItemFragment"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "508e5d398228f00251a3e663cb53d0b0";

export default node;
