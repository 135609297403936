import { parseISO, isValid } from 'date-fns';
import type {
	DateTime,
	ICalEvent,
	ICalField,
	ParseResult,
	RRuleProperty,
	RecurrenceRule,
} from './types.tsx';
import {
	BYDAY,
	BYHOUR,
	BYMINUTE,
	BYSECOND,
	BYMONTH,
	BYMONTHDAY,
	DTSTART,
	FREQ,
	INTERVAL,
	RRULE,
	UNTIL,
	NEWLINE_REGEX,
	FOLD_LINES_REGEX,
} from './constants.tsx';
import {
	extractTZID,
	isValidByDayValue,
	isValidFrequency,
	isValidHour,
	isValidMinute,
	isValidSecond,
	isValidMonth,
	isValidMonthDay,
	isValidInterval,
	toRuleProperty,
	toICalField,
} from './utils.tsx';
import { validate } from './validate.tsx';

export const EMPTY_EVENT: ICalEvent = { dtstart: undefined, rrule: undefined };

export const EMPTY_RRULE: RecurrenceRule = {
	frequency: undefined,
	interval: undefined,
	byMonthDay: undefined,
	byMonth: undefined,
	byDay: undefined,
	byHour: undefined,
	byMinute: undefined,
	bySecond: undefined,
	until: undefined,
};

function getImmutableEmptyEvent(): ICalEvent {
	return { ...EMPTY_EVENT };
}

function getImmutableEmptyRRule(): RecurrenceRule {
	return { ...EMPTY_RRULE };
}

function parseDateTime(line: string): DateTime | undefined {
	const { dateTimeString, timezone } = extractTZID(line);
	const date = dateTimeString.trim() ? parseISO(dateTimeString.trim()) : undefined;
	if (date && isValid(date)) {
		return { date, timezone };
	}
	return undefined;
}

function parseMultiValue<T>(
	value: string,
	validator: (value: string) => boolean,
	mapper: (value: string) => T,
): T[] {
	return value?.split(',')?.filter(validator).map(mapper) ?? [];
}

type Parser<T> = (item: T, value: string) => T;

function getParser<T, K extends string>(
	parsers: Record<K, Parser<T>> | Partial<Record<K, Parser<T>>>,
	toKey: (propertyKey: string) => K | undefined,
): (propertyKey: string) => Parser<T> | undefined {
	return (propertyKey: string) => {
		const parserKey = toKey(propertyKey?.toUpperCase());
		return parserKey ? parsers[parserKey] : undefined;
	};
}

type RuleParser = (rule: RecurrenceRule, value: string) => RecurrenceRule;

const recurrenceRuleParsers: Record<RRuleProperty, RuleParser> = {
	[FREQ]: (rule: RecurrenceRule, value: string) => {
		if (isValidFrequency(value)) {
			return Object.assign(rule, { frequency: value });
		}
		return rule;
	},
	[INTERVAL]: (rule: RecurrenceRule, value: string) => {
		if (isValidInterval(value)) {
			const interval = Number(value);
			return Object.assign(rule, { interval });
		}
		return rule;
	},
	[BYMONTH]: (rule: RecurrenceRule, value: string) => {
		const byMonth = parseMultiValue<number>(value, isValidMonth, Number);
		return Object.assign(rule, { byMonth });
	},
	[BYMONTHDAY]: (rule: RecurrenceRule, value: string) => {
		const byMonthDay = parseMultiValue<number>(value, isValidMonthDay, Number);
		return Object.assign(rule, { byMonthDay });
	},
	[BYDAY]: (rule: RecurrenceRule, value: string) => {
		const byDay = parseMultiValue<string>(value, isValidByDayValue, String);
		return Object.assign(rule, { byDay });
	},
	[BYHOUR]: (rule: RecurrenceRule, value: string) => {
		const byHour = parseMultiValue<number>(value, isValidHour, Number);
		return Object.assign(rule, { byHour });
	},
	[BYMINUTE]: (rule: RecurrenceRule, value: string) => {
		const byMinute = parseMultiValue<number>(value, isValidMinute, Number);
		return Object.assign(rule, { byMinute });
	},
	[BYSECOND]: (rule: RecurrenceRule, value: string) => {
		const bySecond = parseMultiValue<number>(value, isValidSecond, Number);
		return Object.assign(rule, { bySecond });
	},
	/**
	 * The value of the UNTIL rule part MUST have the same value type as the "DTSTART" property.
	 * If the "DTSTART" property is specified as a date with local time, then the UNTIL rule part MUST also be specified as a date with local time.
	 * If the "DTSTART" property is specified as a date with UTC time or a date with local time and time zone reference, then the UNTIL rule part MUST be specified as a date with UTC time.
	 * Reference: [RFC 5545 specification for RRULE](https://datatracker.ietf.org/doc/html/rfc5545#section-3.3.10)
	 */
	[UNTIL]: (rule: RecurrenceRule, value: string) => {
		const until = parseISO(value);
		return Object.assign(rule, { until: isValid(until) ? until : undefined });
	},
};

const getRuleParser = getParser<RecurrenceRule, RRuleProperty>(
	recurrenceRuleParsers,
	toRuleProperty,
);

function parseRecurrenceRule(line: string): RecurrenceRule {
	const ruleParts = line.split(':');
	const rules = ruleParts.slice(1).join(':').split(';');

	return rules.reduce<RecurrenceRule>((rule, property) => {
		const [key, value] = property?.split('=');

		const parser = getRuleParser(key);
		if (parser) {
			return parser(rule, value);
		}

		return rule;
	}, getImmutableEmptyRRule());
}

type EventParser = (event: ICalEvent, line: string) => ICalEvent;

const eventParsers: Partial<Record<ICalField, EventParser>> = {
	[DTSTART]: (event: ICalEvent, line: string) => {
		const dtstart = parseDateTime(line);
		return Object.assign(event, { dtstart });
	},
	[RRULE]: (event: ICalEvent, line: string) => {
		return Object.assign(event, { rrule: parseRecurrenceRule(line) });
	},
};

const getEventParser = getParser<ICalEvent, ICalField>(eventParsers, toICalField);

/**
 * Parses an iCalendar string and returns the parsed event along with validation results.
 *
 * @param {string | undefined | null} iCalString - The iCalendar string to parse.
 * @returns {ParseResult} The result of parsing the iCalendar string, including validation results and the parsed event.
 *
 * The function performs the following steps:
 * 1. Validates the iCalendar string using the `validate` function.
 * 2. If the validation fails, it returns the validation errors and sets the event to `undefined`.
 * 3. If the validation succeeds, it processes the iCalendar string to parse the event properties.
 * 4. The iCalendar string is split into lines, and each line is trimmed and processed.
 * 5. For each line, the corresponding parser is retrieved using the `getEventParser` function.
 * 6. The parser processes the line and updates the event object.
 * 7. The final parsed event and validation results are returned.
 *
 * Example usage:
 * ```tsx
 * const iCalString = `
 * BEGIN:VEVENT
 * DTSTART;TZID=Australia/Sydney:20250108T140336
 * RRULE:FREQ=WEEKLY;BYHOUR=9,15;BYMINUTE=0,30;BYSECOND=0,30;BYDAY=MO,WE,FR;BYMONTH=1,3,6;BYMONTHDAY=1,15;INTERVAL=1;UNTIL=20250407T140000Z
 * END:VEVENT
 * `;
 * const result = parse(iCalString);
 * if (result.isValid) {
 *   console.log('Parsed event:', result.event);
 * } else {
 *   console.log('Validation errors:', result.errors);
 * }
 * ```
 */
export function parse(iCalString: string | undefined | null): ParseResult {
	const validationResults = validate(iCalString);

	if (!validationResults.isValid) {
		return {
			...validationResults,
			event: undefined,
		};
	}

	const event =
		iCalString
			?.replace(FOLD_LINES_REGEX, '')
			?.split(NEWLINE_REGEX)
			?.reduce((entity, line) => {
				const trimmedLine = line?.trim();
				const property = trimmedLine?.split(':')?.[0]?.split(';')?.[0];

				const parser = getEventParser(property);
				if (parser) {
					return parser(entity, trimmedLine);
				}

				return entity;
			}, getImmutableEmptyEvent()) ?? undefined;

	return {
		...validationResults,
		event,
	};
}
