import { useMemo } from 'react';
import { Journeys, Targets, type CrossFlowContextType } from '@atlassiansox/cross-flow-support';
import { getUserPropertiesUrl } from '@atlassian/jira-common-rest/src/api/latest/user-properties/index.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { getDefaultOptions } from '@atlassian/jira-fetch/src/utils/fetch-default-options.tsx';
import type { IssueType } from '@atlassian/jira-issue-field-issue-type/src/common/types.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useFireCrossFlowAnalyticsEvents as useFireCrossFlowAnalyticsEventsBase } from '@atlassian/surface-analytics';
import type { ISSUE_VIEW_END_USERS_CROSS_FLOW_VIA_E2E_ANALYTICS_KEY } from '../confluence-dummy-link/placeholder-template-end-user-e2e/constants.tsx';
import {
	type ConfluenceTemplate,
	confluencePlaceholderTemplates,
	ELIGIBLE_ISSUE_TYPES_SET,
	type SupportedIssueTypes,
} from './constants.tsx';

export const getConfluencePlaceholderTemplate = (
	issueType?: IssueType | null,
): ConfluenceTemplate | null => {
	const issueTypeName = issueType?.name.toLowerCase() || '';
	return confluencePlaceholderTemplates[issueTypeName] || null;
};

export const isSupportedIssueType = (
	issueType: string | null | undefined,
): issueType is SupportedIssueTypes => {
	return ELIGIBLE_ISSUE_TYPES_SET.has(issueType ?? '');
};

export const getHasDismissedConfluencePlaceholderTemplate = <T,>(
	accountId: AccountId,
	propertyKey: string,
): Promise<T> => {
	const url = getUserPropertiesUrl(accountId, propertyKey);

	const response = fetch(url, getDefaultOptions(url));

	return response
		.then((res) => {
			if (res.status === 200) {
				return res.json();
			}
			// 401: If credential is incorrect, the user is not authorised and should not see the banner
			// 403: User doesn't have permission, so they should not see the banner
			if (res.status === 401 || res.status === 403) {
				return true;
			}

			// 404: API deliberately returns 404 if user property is not found, which implies the user has not dismissed the banner
			if (res.status === 404) {
				return false;
			}

			return res.text().then((errResp) => {
				throw new FetchError(res.status, errResp);
			});
		})
		.then((data) => data.value);
};

export type LinkId = 'confluencePlaceholderTemplateLink';
export type ExperimentName =
	| 'placeholderTemplateContextual'
	| 'placeholderTemplateE2EIteration'
	| 'placeholderTemplateEndUserRequestExperiment'
	| typeof ISSUE_VIEW_END_USERS_CROSS_FLOW_VIA_E2E_ANALYTICS_KEY;
export const useFireCrossFlowAnalyticsEvents = (canProvisionConfluence: boolean) => {
	const { fireViewedScreenEvent, fireInteractedUIEvent } = useFireCrossFlowAnalyticsEventsBase();

	return useMemo(
		() => ({
			fireViewedEvent: () => {
				fireViewedScreenEvent(
					'confluencePlaceholderTemplateScreen',
					{
						recommendedProductIds: [Targets.CONFLUENCE],
					},
					{
						canProvisionConfluence,
					},
				);
			},
			fireClickedEvent: (linkId: LinkId, experiment?: ExperimentName) => {
				fireInteractedUIEvent(
					{
						action: 'clicked',
						actionSubject: 'link',
						actionSubjectId: linkId,
					},
					{
						targetProductId: Targets.CONFLUENCE,
						recommendedProductIds: [Targets.CONFLUENCE],
						source: 'confluencePlaceholderTemplateScreen',
						...(experiment ? { experiment } : {}),
					},
					{
						canProvisionConfluence,
					},
				);
			},
		}),
		[fireViewedScreenEvent, fireInteractedUIEvent, canProvisionConfluence],
	);
};

export const getSpaceKeyAndUpdateUrl = () => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const url = new URL(window.location.href);
	const spaceKey = url.searchParams.get('spaceKey');

	if (spaceKey) {
		url.searchParams.delete('spaceKey');

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.history.replaceState({}, '', url);
	}

	return spaceKey;
};

export const openCffeForConfluence = async (
	crossFlow: CrossFlowContextType,
	sourceComponent: string,
	sourceContext: string,
	experimentalOptions: {},
) => {
	if (crossFlow.isEnabled) {
		await crossFlow.api.open({
			targetProduct: Targets.CONFLUENCE,
			journey: Journeys.GET_STARTED,
			sourceComponent,
			sourceContext,
			experimentalOptions,
		});
	}
};
