import { format, parse, isAfter } from 'date-fns';
import intervals from '@atlassian/jira-common-constants/src/datetimepicker-available-times.tsx';
import { formatWithLocale } from '@atlassian/jira-platform-utils-date-fns/src/main.tsx';

function getLocalisedTimeString(intervalTime: Date, intervalString: string, userLocale?: string) {
	if (userLocale) {
		const normalizedLocale = userLocale.replace(/_/g, '-');

		return new Intl.DateTimeFormat(normalizedLocale, {
			hour: 'numeric',
			minute: 'numeric',
		}).format(intervalTime);
	}
	return intervalString;
}

export function getNext30MinuteInterval(date?: Date, userLocale?: string) {
	// Get the current date and time
	const now = date ?? new Date();
	// Format current time to 'HH:mm'
	const currentTime = format(now, 'HH:mm');
	// Find the next interval
	for (const interval of intervals) {
		// Parse the interval time to a Date object
		const intervalTime = parse(interval, 'HH:mm', now);
		// Check if the interval is after the current time
		if (isAfter(intervalTime, now) || interval === currentTime) {
			return getLocalisedTimeString(intervalTime, interval, userLocale);
		}
	}
	const intervalTime = parse(intervals[0], 'HH:mm', now);
	// If no interval is found that is after the current time, return the first interval of the next day
	return getLocalisedTimeString(intervalTime, intervals[0], userLocale);
}

export const getFormattedDate = (userLocale: string) => {
	return formatWithLocale(new Date(), 'P', userLocale);
};

export const constructDateTimeFromPartialData = (
	date: string | null,
	time: string | null,
): string => {
	if (!date && !time) {
		return '';
	}

	const referenceDate = new Date();

	let resultDate;

	if (date && !time) {
		resultDate = parse(date, 'yyyy-MM-dd', referenceDate);
		const nextInterval = getNext30MinuteInterval();
		const parsedTime = parse(nextInterval, 'HH:mm', referenceDate);
		resultDate.setHours(parsedTime.getHours(), parsedTime.getMinutes());
	} else if (time && !date) {
		resultDate = parse(time, 'HH:mm', referenceDate);
		resultDate.setFullYear(
			referenceDate.getFullYear(),
			referenceDate.getMonth(),
			referenceDate.getDate(),
		);
	}

	return resultDate ? resultDate.toISOString() : '';
};
