import React, { useCallback, useEffect, useMemo } from 'react';
import EmojiFlagsIcon from '@atlaskit/icon/core/migration/flag--emoji-flags';
import { getCommandPaletteIssueActionsPriority } from '@atlassian/jira-command-palette-common/src/common/utils/get-command-palette-issue-actions-priority/index.tsx';
import { getCommandPaletteIssueActionsHeader } from '@atlassian/jira-command-palette-common/src/common/utils/index.tsx';
import {
	type Command,
	CommandActionType,
} from '@atlassian/jira-command-palette/src/common/types/commands/index.tsx';
import { useCommandPaletteIsOpen } from '@atlassian/jira-command-palette/src/controllers/command-palette/index.tsx';
import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useSearchCustomFieldKeys } from '@atlassian/jira-issue-field-base/src/services/custom-field-key-service/index.tsx';
import { useFieldsValuesActions } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { useIssueTypeField } from '@atlassian/jira-issue-field-issue-type/src/services/index.tsx';
import { useSummaryField } from '@atlassian/jira-issue-field-summary-common/src/services/index.tsx';
import { FLAGGED_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import {
	type IssueForFlagWithCommentModal,
	FlagAction,
} from '@atlassian/jira-software-add-issue-flag-with-comment/src/common/types.tsx';
import { useFlagIssueModalActions } from '@atlassian/jira-software-add-issue-flag-with-comment/src/controllers/flag-with-comment/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { FIELD_TYPE_MAP } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/constants.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { messages } from '../messages.tsx';
import { FLAG_MODAL_SOURCE, PREFIX_ADD_FLAG_ID, PREFIX_REMOVE_FLAG_ID } from './constants.tsx';

export const useFlagIssueCommand = (issueKey: string, onAddComment: () => void): Command => {
	const [{ isOpen }] = useCommandPaletteIsOpen();

	const { open, preload } = useFlagIssueModalActions();
	const issueId = useIssueId() || '';
	const [{ value: summary }] = useSummaryField({ issueKey, issueId });
	const [{ value: issueType }] = useIssueTypeField({ issueKey });
	const [flaggedFieldKey] = useSearchCustomFieldKeys(issueKey, FLAGGED_CF_TYPE) || [''];
	const { formatMessage } = useIntl();
	const [, { setFieldValue, getFieldValue }] = useFieldsValuesActions();
	const saveValue = useCallback(
		(flag: boolean) => {
			setFieldValue(issueKey, flaggedFieldKey, flag ? [{ value: true }] : null);
		},
		[flaggedFieldKey, issueKey, setFieldValue],
	);

	const { showFlag } = useFlagsService();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		if (isOpen) {
			preload();
		}
	}, [isOpen, preload]);

	const isFlagged = getFieldValue(issueKey, flaggedFieldKey)?.[0]?.value ?? false;

	const issue: IssueForFlagWithCommentModal = useMemo(
		() => ({
			issueId,
			issueKey,
			summary,
			type: {
				id: issueType?.id ?? '',
				name: issueType?.name ?? '',
				iconUrl: issueType?.iconUrl ?? '',
			},
		}),
		[issueId, issueKey, issueType?.iconUrl, issueType?.id, issueType?.name, summary],
	);

	const handleOpenFlagModal = useCallback(() => {
		if (fg('one_event_rules_them_all_fg')) {
			getUpdateAnalyticsFlowHelper().fireAnalyticsStart(FIELD_TYPE_MAP[FLAGGED_CF_TYPE], {
				analytics: createAnalyticsEvent({}),
				attributes: {
					fieldType: FLAGGED_CF_TYPE,
					isCommandPaletteEditing: true,
				},
			});
		}
		open({
			issues: [issue],
			flagAction: isFlagged ? FlagAction.REMOVE : FlagAction.ADD,
			callback: ({ flag }) => {
				if (fg('one_event_rules_them_all_fg')) {
					getUpdateAnalyticsFlowHelper().fireAnalyticsEnd(FIELD_TYPE_MAP[FLAGGED_CF_TYPE], {
						analytics: createAnalyticsEvent({}),
						attributes: {
							fieldType: FLAGGED_CF_TYPE,
							action: flag ? 'add' : 'remove',
							isCommandPaletteEditing: true,
							...(fg('one_event_improvements_1') && { issueId }),
						},
					});
				}
				saveValue(flag);
				onAddComment();
				showFlag({
					messageId:
						'issue-view-foundation.issue-actions.command-palette-issue-actions.flag-issue-command.show-flag.success',
					messageType: 'transactional',
					key: `${flag ? PREFIX_REMOVE_FLAG_ID : PREFIX_ADD_FLAG_ID}${issueKey}`,
					type: 'success',
					title: formatMessage(
						flag ? messages.flagAddedSuccessfully : messages.flagRemovedSuccessfully,
					),
					description: formatMessage(
						flag ? messages.flagAddedDescription : messages.flagRemovedDescription,
						{ issueKey },
					),
				});
			},
			source: FLAG_MODAL_SOURCE,
		});
	}, [
		createAnalyticsEvent,
		formatMessage,
		isFlagged,
		issue,
		issueId,
		issueKey,
		onAddComment,
		open,
		saveValue,
		showFlag,
	]);

	const command: Command = useMemo(
		() => ({
			id: `${isFlagged ? PREFIX_REMOVE_FLAG_ID : PREFIX_ADD_FLAG_ID}${issueKey}`,
			name: formatMessage(isFlagged ? messages.removeFlag : messages.addFlag),
			section: getCommandPaletteIssueActionsHeader(issueKey),
			priority: getCommandPaletteIssueActionsPriority('ISSUE_HEADER_ITEMS'),
			components: {
				LeftIcon: () => (
					<EmojiFlagsIcon
						color="currentColor"
						label={formatMessage(isFlagged ? messages.removeFlag : messages.addFlag)}
					/>
				),
			},
			primaryAction: {
				type: CommandActionType.PERFORM,
				perform: handleOpenFlagModal,
			},
			analytics: {
				action: isFlagged ? 'removeFlagIssue' : 'addFlagIssue',
			},
		}),
		[formatMessage, handleOpenFlagModal, isFlagged, issueKey],
	);

	return command;
};
