import { toRankCustomFieldId } from '@atlassian/jira-polaris-domain-field/src/field-types/rank/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { createGetKeySelector } from '../../selectors/properties/index.tsx';
import type { State, Props } from '../../types.tsx';
import { clearCreatedProperty } from '../create-issue/index.tsx';
import { incrementOpenUpdateCounter } from '../real-time/index.tsx';
import { updateIssuesRanks } from '../utils/index.tsx';

export const moveIssuesToTop =
	(localIssueIds: LocalIssueId[]) =>
	({ getState, setState, dispatch }: StoreActionApi<State>, props: Props) => {
		const { rankField, isRankingEnabled } = props;

		if (!isRankingEnabled) {
			return;
		}

		const localIssueIdSet = new Set(localIssueIds);
		// move is only possible when sorted by rank - therefore it is safe to clear the temporary rank-information of newly-created issues.
		dispatch(clearCreatedProperty());
		dispatch(incrementOpenUpdateCounter(localIssueIds));

		// spread state into new object as the store action api gives us a read only state,
		// which leads to flow errors on the reselect selector creators
		const state = getState();

		const topId = state.ids[0];
		const ids = [...state.ids].filter((id) => !localIssueIdSet.has(id));

		const rankKeys = localIssueIds.map((id) => createGetKeySelector(id)(state));

		const rankBeforeKey = createGetKeySelector(topId)(state);

		// optimistic ranking: do not wait for backend response
		props.issuesRemote
			.rankIssues({
				issueKeys: rankKeys,
				rankBeforeIssueKey: rankBeforeKey,
				rankAfterIssueKey: undefined,
				rankCustomFieldId: rankField !== undefined ? toRankCustomFieldId(rankField) : undefined,
			})
			.then(() => {
				dispatch(updateIssuesRanks([...localIssueIdSet]));
			})
			.catch((error) => props.onIssueUpdateFailed(error));

		// add issue id to ids again at the new position
		ids.splice(0, 0, ...localIssueIds);

		setState({
			ids,
		});
	};
