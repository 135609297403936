import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	bugIssueTypeName: {
		id: 'issue-view-common.component.loom-videos.bug-issue-type-name',
		defaultMessage: 'Bug',
		description:
			'Name of one of standard issue types users can choose from template list to create.',
	},
	taskIssueTypeName: {
		id: 'issue-view-common.component.loom-videos.task-issue-type-name',
		defaultMessage: 'Task',
		description:
			'Name of one of standard issue types users can choose from template list to create.',
	},
});
