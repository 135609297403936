import { createResource } from '@atlassian/react-resource-router';
import type { AIContextServicePanelResourceResponse } from './types.tsx';
import { getServiceSuggestionData } from './endpoints/index.tsx';

export const aiContextServicePanelResource = createResource<AIContextServicePanelResourceResponse>({
	type: 'AI-CONTEXT-SERVICE-PANEL-RESOURCE-TYPE',
	getKey: ({ match }) => {
		const issueId = match.params.issueId || '';
		return `aiContextServicePanelResource-${issueId}`;
	},
	getData: async ({ match }, { tenantContext }) => {
		const issueId = match.params.issueId;

		if (!issueId) {
			throw new Error('AI context service panel resource cannot be loaded without an issueId');
		}
		return getServiceSuggestionData(issueId, tenantContext.cloudId);
	},
	maxAge: 0,
	isBrowserOnly: true,
});
