// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	type Action,
	createStore,
	createHook,
	createContainer,
} from '@atlassian/react-sweet-state';

type RepoPromptForGitHubBannerDetail = {
	isConnectedToGitHub: boolean;
	isDismissed: boolean;
	totalCount: number;
};

type State = {
	[repoId: string]: RepoPromptForGitHubBannerDetail;
};

export const initialState: State = {};

export const RepoPromptForGithubBannerContainer = createContainer();

export const actions = {
	setRepoPromptForGitHubBannerDetails:
		(repoId: string, details: Partial<RepoPromptForGitHubBannerDetail>): Action<State> =>
		({ getState, setState }) => {
			const state = getState();
			const repoDetail = state[repoId] || {};
			setState({ ...state, [repoId]: { ...repoDetail, ...details } });
		},
} as const;

type Actions = typeof actions;

export const Store = createStore<State, Actions>({
	initialState,
	containedBy: RepoPromptForGithubBannerContainer,
	actions,
	name: 'repo-prompt-for-github-banner-store',
});

export const useRepoPromptForGitHubBannerStore = createHook(Store);
