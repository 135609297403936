import React, { type Ref } from 'react';
import { styled } from '@compiled/react';
import DropdownMenu, { DropdownItemGroup, DropdownItem } from '@atlaskit/dropdown-menu';
import { DragHandleButton } from '@atlaskit/pragmatic-drag-and-drop-react-accessibility/drag-handle-button';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type ReorderMenuProps = {
	/**
	 * The index of the item this menu is associated with.
	 */
	itemIndex: number;
	onReorderItem: (args: { startIndex: number; finishIndex: number }) => void;
	numItems: number;
	itemName: string;
	itemType: string;

	setIsOpen: (isOpen: boolean) => void;
};

const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

export function ReorderMenu({
	itemIndex,
	onReorderItem,
	numItems,
	itemName,
	itemType,
	setIsOpen,
}: ReorderMenuProps) {
	const intl = useIntl();

	return (
		<DropdownMenu
			testId="issue-item-line-card.ui.item-line-card.reorder-menu.reorder-menu"
			trigger={({ triggerRef, ...triggerProps }) => {
				setIsOpen(triggerProps.isSelected ?? false);
				return (
					/**
					 * Cancelling events here so that clicks on the trigger
					 * don't trigger the card's `onClick()` propagation.
					 *
					 * Ideally we would not nest interactive components,
					 * but this is already the existing behavior.
					 */
					<TriggerWrapper onClick={preventDefault}>
						<DragHandleButton
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							ref={triggerRef as Ref<HTMLButtonElement>}
							label={intl.formatMessage(messages.triggerLabel, {
								itemName,
								itemType,
							})}
							appearance="subtle"
							{...triggerProps}
						/>
					</TriggerWrapper>
				);
			}}
		>
			<DropdownItemGroup>
				<DropdownItem
					testId="issue-item-line-card.ui.item-line-card.reorder-menu.move-up"
					onClick={() => {
						onReorderItem({ startIndex: itemIndex, finishIndex: itemIndex - 1 });
					}}
					isDisabled={itemIndex === 0}
				>
					{intl.formatMessage(messages.moveUp)}
				</DropdownItem>
				<DropdownItem
					testId="issue-item-line-card.ui.item-line-card.reorder-menu.move-down"
					onClick={() => {
						onReorderItem({ startIndex: itemIndex, finishIndex: itemIndex + 1 });
					}}
					isDisabled={itemIndex === numItems - 1}
				>
					{intl.formatMessage(messages.moveDown)}
				</DropdownItem>
			</DropdownItemGroup>
		</DropdownMenu>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TriggerWrapper = styled.div({
	/**
	 * Using negative margins to make the trigger act as if it was smaller,
	 * so that it fits in the icon space without causing layout shift.
	 */
	marginTop: token('space.negative.050'),
	marginRight: token('space.negative.050'),
	marginBottom: token('space.negative.050'),
	marginLeft: token('space.negative.050'),
});
