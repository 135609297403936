import type { ColorThemeName } from '@atlassian/jira-custom-theme-constants/src/types.tsx';
import type { CoverMedia } from '@atlassian/jira-card-cover-manager/src/common/cover-media.types.tsx';

type IssueCoverMediaUnset = {
	type: 'unset';
};

type IssueColorCoverMedia = {
	type: 'color';
	value: ColorThemeName;
};

type IssueImageCoverMedia = {
	type: 'image';
	fileName: string;
	mediaApiFileId: string;
	mediaReadToken: string;
};

export type IssueCoverMedia = IssueCoverMediaUnset | IssueColorCoverMedia | IssueImageCoverMedia;

export const transformCoverMedia = (coverMedia: CoverMedia): IssueCoverMedia | null => {
	if (!coverMedia) {
		return {
			type: 'unset',
		};
	}

	switch (coverMedia.__typename) {
		case 'JiraAttachmentBackground': {
			const { fileName, mediaApiFileId, mediaReadToken } = coverMedia.attachment ?? {};

			if (fileName == null || mediaApiFileId == null || mediaReadToken == null) {
				return null;
			}

			return {
				type: 'image',
				fileName,
				mediaApiFileId,
				mediaReadToken,
			};
		}
		case 'JiraColorBackground':
			return {
				type: 'color',
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				value: coverMedia.colorValue as ColorThemeName,
			};

		default:
			return {
				type: 'unset',
			};
	}
};
