import React from 'react';
import { Flex, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';

export const CardCoverSkeleton = () => (
	<Flex justifyContent="center" alignItems="center" xcss={skeletonStyles}>
		<Spinner size="medium" />
	</Flex>
);

const skeletonStyles = xcss({
	width: '366px',
	height: '300px',
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
});
