import { useCallback } from 'react';

import { type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireAnalytics } from '@atlassian/analytics-bridge';
import { assert, AssertionError } from '@atlassian/eoc-common';

import { type AIOpsEventAttributes, type UnifiedAIEventPaload } from '../../common/types';

import { useAIOpsUnfiedEventStore } from './store';
import { type AIOpsFeatureName } from './store/types';
import { type AIOpsMission, type CreateAIOpsEvent } from './types';

const FEATURE_MISSION_MAP: Record<AIOpsFeatureName, AIOpsMission> = {
	alertGroup: 'detect',
	genAIPIR: 'resolve',
	smartCreateIncident: 'detect',
	suggestedResources: 'resolve',
	relatedAlertGroup: 'detect',
	incidentContextPanel: 'resolve',
	pastSimilarAlerts: 'detect',
	pastResponders: 'detect',
	relatedResources: 'detect',
} as const;

export const useAIUnifiedAnalytics = () => {
	const [{ singleInstrumentationID, aiFeatureName, source }] = useAIOpsUnfiedEventStore();

	const createAIOpsEventPayload: CreateAIOpsEvent = useCallback(
		(payload) => {
			assert(
				!!aiFeatureName && !!singleInstrumentationID,
				new AssertionError(
					'useAIUnifiedAnalytics',
					`AIOps aiFeatureName - ${aiFeatureName} or singleInstrumentationID - ${singleInstrumentationID} is missing for unified AI instrumentation`,
				),
			);

			const { type, isProactive, isUserGenerated, isAIOpsUsed, experienceName, aiAction, ...rest } =
				payload;

			let attributes: AIOpsEventAttributes = {
				proactiveGeneratedAI: isProactive ? 1 : 0,
				userGeneratedAI: isUserGenerated ? 1 : 0,
				isAIOpsUsed: isAIOpsUsed ? 1 : 0,
				isAIOpsFeature: 1,
				isAIFeature: 1,
				aiFeatureName,
				singleInstrumentationID,
				AIOps: FEATURE_MISSION_MAP[aiFeatureName],
				aiExperienceName: experienceName,
				...rest,
			};

			let eventPayload: UnifiedAIEventPaload;

			switch (type) {
				case 'initiated':
					eventPayload = {
						action: 'initiated',
						actionSubject: 'aiInteraction',
						attributes,
					};
					break;
				case 'viewed':
					eventPayload = {
						action: 'viewed',
						actionSubject: 'aiResult',
						attributes,
					};
					break;
				case 'dismissed':
					eventPayload = {
						action: 'dismissed',
						actionSubject: 'aiInteraction',
						attributes,
					};
					break;
				case 'error':
					eventPayload = {
						action: 'error',
						actionSubject: 'aiResult',
						attributes,
					};
					break;
				case 'actioned':
					eventPayload = {
						action: 'actioned',
						actionSubject: 'aiResult',
						attributes: {
							...attributes,
							aiResultAction: aiAction,
						},
					};
					break;
				case 'feedback':
					eventPayload = {
						action: 'submitted',
						actionSubject: 'aiFeedback',
						attributes: {
							...attributes,
							aiFeedbackResult: payload.feedback,
						},
					};
					break;
				default:
					throw new Error('[AIOps] Incompatible AI event type for AIOps unified instrumentation');
			}

			if (source) {
				eventPayload.source = source;
			}

			return eventPayload;
		},
		[singleInstrumentationID, aiFeatureName, source],
	);

	return { createAIOpsEventPayload };
};

export const fireAIOpsEvent = (
	event: UIAnalyticsEvent,
	eventPayload: UnifiedAIEventPaload,
	actionSubjectId?: string,
) => {
	if (eventPayload.source) {
		event.context.push({
			source: eventPayload.source,
		});
	}

	fireAnalytics(event, 'TRACK', { ...eventPayload, actionSubjectId });
};
