import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { Attributes } from '../../services/pendo/types.tsx';
import { extractAttributesFromContext } from './extract-attributes-from-context.tsx';

type ViewViewedPendoEventAttributes = Attributes & {
	viewHasConnectionFields?: boolean;
};

export const getViewViewedPendoEventAttributes = (event: UIAnalyticsEvent) => {
	const pendoEventAttributes: ViewViewedPendoEventAttributes = {};

	const attributes = extractAttributesFromContext(event.context);
	pendoEventAttributes.viewHasConnectionFields = !!attributes.viewHasConnectionFields;

	return pendoEventAttributes;
};
