import { di } from 'react-magnetic-di';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';

/**
 * @returns the absolute URL for the current issue.
 * @example https://jdog.jira-dev.com/browse/MAGMA-215
 */
export const useIssueAbsoluteUrl = (): string => {
	di(window);
	const issueKey = useIssueKey();

	if (typeof window !== 'undefined') {
		return `${window.location.protocol}//${window.location.host}/browse/${issueKey}`;
	}
	// this operation is not defined at server side
	return '#';
};
