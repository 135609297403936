/** @jsx jsx */
import React from 'react';
import { keyframes, jsx } from '@compiled/react';
import { cssMap } from '@atlaskit/css';
import { token } from '@atlaskit/tokens';
import { SkeletonCompiled } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';

// This component and its animations were implemented as part of PHANTOM-2721
// For more details, see: https://hello.jira.atlassian.cloud/browse/PHANTOM-2721
export const PlaybooksPanelLoadingView = () => (
	<div css={styles.loading}>
		<SkeletonCompiled width="100%" height="32px" />
		<SkeletonCompiled width="100%" height="32px" />
	</div>
);

const fadeIn = keyframes({
	from: {
		opacity: 0,
	},
	to: {
		opacity: 1,
	},
});

const styles = cssMap({
	loading: {
		opacity: 0,
		animationName: fadeIn,
		animationDelay: '200ms',
		animationDuration: '1s',
		animationFillMode: 'forwards',
		display: 'flex',
		flexDirection: 'column',
		gap: token('space.100'),
	},
});
