import React from 'react';
import { Flex, Inline, Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type Props = {
	menuItems: React.JSX.Element[];
};

export const CommentFloatingMenu = ({ menuItems }: Props) => {
	const { formatMessage } = useIntl();
	return menuItems.length > 0 ? (
		<Box xcss={containerStyle} role="toolbar" aria-label={formatMessage(messages.commentActions)}>
			<Flex justifyContent="end" xcss={floatingMenuStyle} gap="space.050">
				<Inline>{menuItems}</Inline>
			</Flex>
		</Box>
	) : null;
};

const floatingMenuStyle = xcss({
	padding: 'space.050',
	display: 'inline-block',
});

const containerStyle = xcss({
	position: 'absolute',
	top: token('space.negative.200'),
	right: token('space.0'),
	backgroundColor: 'elevation.surface',
	zIndex: '1',
	borderRadius: 'border.radius.200',
	border: `1px solid ${token('color.border')}`,
});
