import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import {
	getFilterFromLocalStorage,
	TEXT_SEARCH_KEY,
	SAVED_SEARCH_ID_KEY,
} from '../../filter-local-storage/index.tsx';
import { DEFAULT_SELECTED_SAVED_SEARCH_ID, resetState } from '../../main.tsx';
import type { State } from '../../types.tsx';

export const openLinkAlertsModal =
	(shouldUseJSM?: boolean) =>
	({ getState, setState }: StoreActionApi<State>) => {
		// need to reset state since closeLinkAlertsModal() is not always called when Link modal is closed (e.g. after you linked alerts).
		resetState(
			{
				...getState(),
				isLinkModalOpen: true,
				...(shouldUseJSM ? {} : { isViewModalOpen: false }),
			},
			setState,
		);
	};

export const closeLinkAlertsModal =
	() =>
	({ getState, setState }: StoreActionApi<State>) => {
		resetState(
			{
				...getState(),
				isLinkModalOpen: false,
			},
			setState,
		);
	};

export const initLinkModal =
	(analyticsEvent: UIAnalyticsEvent) =>
	async ({ getState, setState }: StoreActionApi<State>) => {
		const { linkModalFilter } = getState();

		const textSearch = getFilterFromLocalStorage(TEXT_SEARCH_KEY, analyticsEvent) || '';
		const selectedSavedSearchId =
			getFilterFromLocalStorage(SAVED_SEARCH_ID_KEY, analyticsEvent) ||
			DEFAULT_SELECTED_SAVED_SEARCH_ID;

		setState({
			linkModalFilter: {
				...linkModalFilter,
				textSearch,
				selectedSavedSearchId,
			},
		});
	};
