// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Props, State } from '../../types.tsx';

export const loadGlobalFieldsAssociations =
	(fieldKeys: FieldKey[]): Action<State, Props> =>
	async ({ getState, setState }, { globalFieldRemote }) => {
		const {
			meta: { connectionsLoadingFieldsAssociations },
			connectionsFieldsAssociations,
		} = getState();

		const fieldKeysToSend = fieldKeys.filter(
			(fieldKey) =>
				!connectionsFieldsAssociations[fieldKey] &&
				!connectionsLoadingFieldsAssociations.includes(fieldKey),
		);

		if (!fieldKeysToSend.length) {
			return;
		}

		setState({
			meta: {
				...getState().meta,
				connectionsLoadingFieldsAssociations: [
					...getState().meta.connectionsLoadingFieldsAssociations,
					...fieldKeysToSend,
				],
			},
		});

		try {
			const result = await globalFieldRemote.getProjectsForFields({
				fieldKeys: fieldKeysToSend,
			});

			const remappedFields = Object.entries(result).reduce<Record<FieldKey, IssueTypeId[]>>(
				(acc, [fieldKey, projects]) => {
					const issueIdsFromProjects = projects.reduce<string[]>((accInner, project) => {
						if (project.issueTypes) {
							return accInner.concat(project.issueTypes.map(({ id }) => String(id)));
						}
						return accInner;
					}, []);

					acc[fieldKey] = issueIdsFromProjects;

					return acc;
				},
				{},
			);

			setState({
				meta: {
					...getState().meta,
					connectionsLoadingFieldsAssociations:
						getState().meta.connectionsLoadingFieldsAssociations.filter((key) =>
							fieldKeysToSend.includes(key),
						),
				},
				connectionsFieldsAssociations: {
					...getState().connectionsFieldsAssociations,
					...remappedFields,
				},
			});
		} catch (error) {
			logSafeErrorWithoutCustomerDataWrapper(
				'polaris.error.fields.connections.loadGlobalFieldsAssociations',
				'Failed to load global fields associations for connection highlighted fields',
				error instanceof Error
					? error
					: new Error(
							'Error while loading global fields associations for connection highlighted fields',
						),
			);

			setState({
				meta: {
					...getState().meta,
					connectionsLoadingFieldsAssociations:
						getState().meta.connectionsLoadingFieldsAssociations.filter((key) =>
							fieldKeysToSend.includes(key),
						),
				},
			});
		}
	};
