/**
 * @generated SignedSource<<acd3285791c2df2cc13a1c601e12a9f9>>
 * @relayHash 7e0ba23f0ddb110cb030640eb1fa4003
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 62703d869fbcc3fe5ca5b7a02a3f929babe05bbaf3e0fa09e28a21442e6446f2

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type IssueConfluenceMentionedLinksQuery$variables = {
  after?: string | null | undefined;
  first?: number | null | undefined;
  id: string;
};
export type IssueConfluenceMentionedLinksQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"mentionedConfluenceLinksSection_issueViewBase">;
  } | null | undefined;
};
export type IssueConfluenceMentionedLinksQuery = {
  response: IssueConfluenceMentionedLinksQuery$data;
  variables: IssueConfluenceMentionedLinksQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": 10,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "IssueConfluenceMentionedLinksQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "FragmentSpread",
            "name": "mentionedConfluenceLinksSection_issueViewBase"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "IssueConfluenceMentionedLinksQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v2/*: any*/),
                "concreteType": "JiraConfluenceRemoteIssueLinkConnection",
                "kind": "LinkedField",
                "name": "confluenceMentionedLinks",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "totalCount"
                  },
                  {
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "hasNextPage"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "endCursor"
                      }
                    ]
                  },
                  {
                    "concreteType": "JiraConfluenceRemoteIssueLinkEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraConfluenceRemoteIssueLink",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "href"
                          },
                          (v3/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "cursor"
                      }
                    ]
                  }
                ]
              },
              {
                "args": (v2/*: any*/),
                "handle": "connection",
                "key": "IssueConfluenceContent__confluenceMentionedLinks",
                "kind": "LinkedHandle",
                "name": "confluenceMentionedLinks"
              }
            ],
            "type": "JiraIssue"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "62703d869fbcc3fe5ca5b7a02a3f929babe05bbaf3e0fa09e28a21442e6446f2",
    "metadata": {},
    "name": "IssueConfluenceMentionedLinksQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "e750cf3257597badfc45552a400c57da";

export default node;
