/**
 * @generated SignedSource<<77d63f119f1956e628268db58d70c8b5>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditViewOld_fragmentRef$data = {
  readonly fieldConfig: {
    readonly isEditable: boolean | null | undefined;
  } | null | undefined;
  readonly fieldId: string;
  readonly fieldOption: {
    readonly id: string;
    readonly optionId: string;
    readonly value: string | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly name: string;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"singleSelect_issueFieldSingleSelectReadviewFull_SingleSelectReadView">;
  readonly " $fragmentType": "singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditViewOld_fragmentRef";
};
export type singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditViewOld_fragmentRef$key = {
  readonly " $data"?: singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditViewOld_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditViewOld_fragmentRef">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditViewOld_fragmentRef",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "FragmentSpread",
      "name": "singleSelect_issueFieldSingleSelectReadviewFull_SingleSelectReadView"
    },
    {
      "concreteType": "JiraOption",
      "kind": "LinkedField",
      "name": "fieldOption",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "name": "optionId"
        },
        {
          "kind": "ScalarField",
          "name": "value"
        }
      ]
    },
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        }
      ]
    }
  ],
  "type": "JiraSingleSelectField"
};
})();

(node as any).hash = "d59d29db1d3146edca86c799faffbb7c";

export default node;
