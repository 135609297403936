import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

const headerWordWrap = {
	/* Style only for Google Chrome */
	'@media screen and (-webkit-min-device-pixel-ratio: 0)': {
		wordBreak: 'break-word',
	},
	/* Style only for Safari */
	'@media screen and (-webkit-transition)': {
		wordBreak: 'break-word',
	},
	/* Style only for Mozilla Firefox */
	'@-moz-document url-prefix()': {
		overflowWrap: 'anywhere',
	},
} as const;

const summaryTextFontStyles = {
	font: token('font.heading.large'),
} as const;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SummaryHeading = styled.h1(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	summaryTextFontStyles,
	{
		width: '100%',
		margin: 0,
		padding: 0,
		color: token('color.text'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	headerWordWrap,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SummaryHeadingNew = styled.h1({
	font: token('font.body.large'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> textarea': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		...summaryTextFontStyles,
		margin: 0,
		color: token('color.text'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		...headerWordWrap,
		/* workaround for Firefox bug https://bugzilla.mozilla.org/show_bug.cgi?id=33654
           FF adds height to the TextArea to reserve place for scroll-bars.
           Setting overflow-x to hidden tells Firefox to not reserve any height for scroll bars.
           @atlaskit/textarea component has a custom script that always ensure that the textarea
           is shown fully hence there is no need for scrollbars.
        */
		overflowX: 'hidden',
	},
	width: '100%',
});
