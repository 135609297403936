import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	formTitle: {
		id: 'recur-work-item.form-title',
		defaultMessage: 'Set to recur',
		description: 'The title of the form',
	},
	saveButtonText: {
		id: 'recur-work-item.save-button-text',
		defaultMessage: 'Save',
		description: 'Text for recurring work popup save button',
	},
	cancelButtonText: {
		id: 'recur-work-item.cancel-button-text',
		defaultMessage: 'Cancel',
		description: 'Text for recurring work popup cancel button',
	},
	deleteButtonText: {
		id: 'recur-work-item.delete-button-text',
		defaultMessage: 'Delete',
		description: 'Text for recurring work popup delete button',
	},
	recurFieldsLabel: {
		id: 'recur-work-item.recur-fields-label',
		defaultMessage: 'Recur',
		description: 'Label for recur work item popup form field.',
	},
	setToRecur: {
		id: 'recur-work-item.set-to-recur',
		defaultMessage: 'Set to recur',
		description: 'Label for a set recurring menu item',
	},
	recursDaily: {
		id: 'recur-work-item.recurs-daily',
		defaultMessage: 'Recurs daily',
		description: 'Label for the recurring menu item when the work item will recur every day',
	},
	recursEveryWeekday: {
		id: 'recur-work-item.recurs-every-weekday',
		defaultMessage: 'Every weekday (Monday to Friday)',
		description: 'Label for the recurring menu item when the work item will recur every weekday',
	},
	recursWeekly: {
		id: 'recur-work-item.recurs-weekly',
		defaultMessage: 'Recurs weekly',
		description: 'Label for the recurring menu item when the work item will recur every week',
	},
	recursEveryTwoWeeks: {
		id: 'recur-work-item.recurs-every-two-weeks',
		defaultMessage: 'Recurs every 2 weeks',
		description: 'Label for the recurring menu item when the work item will recur every 2 weeks',
	},
	recursOnComplete: {
		id: 'recur-work-item.recurs-on-complete',
		defaultMessage: 'Recurs when completed',
		description: 'Label for the recurring menu item when the work item will recur when completed',
	},
	successFlagTitleRecurOnScheduleIfCompleteDaily: {
		id: 'recur-work-item.success-flag-title-recur-on-schedule-if-complete-daily',
		defaultMessage: '{issueKey} recurs every day',
		description:
			'Title of the success flag shown when a work item is successfully set to recur on a daily schedule if complete.',
	},
	successFlagTitleRecurOnScheduleIfCompleteWeekday: {
		id: 'recur-work-item.success-flag-title-recur-on-schedule-if-complete-weekday',
		defaultMessage: '{issueKey} recurs every weekday',
		description:
			'Title of the success flag shown when a work item is successfully set to recur on a weekday schedule if complete.',
	},
	successFlagTitleRecurOnScheduleIfCompleteWeekly: {
		id: 'recur-work-item.success-flag-title-recur-on-schedule-if-complete-weekly',
		defaultMessage: '{issueKey} recurs every week',
		description:
			'Title of the success flag shown when a work item is successfully set to recur on a weekly schedule if complete.',
	},
	successFlagTitleRecurOnScheduleIfCompleteFortnightly: {
		id: 'recur-work-item.success-flag-title-recur-on-schedule-if-complete-fortnightly',
		defaultMessage: '{issueKey} recurs every 2 weeks',
		description:
			'Title of the success flag shown when a work item is successfully set to recur on a fortnightly schedule if complete.',
	},
	successFlagDescriptionRecurOnScheduleIfCompleteDaily: {
		id: 'recur-work-item.success-flag-description-recur-on-schedule-if-complete-daily',
		defaultMessage: 'This {issueType} recurs daily when completed',
		description:
			'Description of the success flag shown when a work item is successfully set to recur on a daily schedule if complete.',
	},
	successFlagDescriptionRecurOnScheduleIfCompleteWeekday: {
		id: 'recur-work-item.success-flag-description-recur-on-schedule-if-complete-weekday',
		defaultMessage: 'This {issueType} recurs every weekday when completed',
		description:
			'Description of the success flag shown when a work item is successfully set to recur on a weekday schedule if complete.',
	},
	successFlagDescriptionRecurOnScheduleIfCompleteWeekly: {
		id: 'recur-work-item.success-flag-description-recur-on-schedule-if-complete-weekly',
		defaultMessage: 'This {issueType} recurs weekly on {weekDayNames} when completed',
		description:
			'Description of the success flag shown when a work item is successfully set to recur on a weekly schedule if complete.',
	},
	successFlagDescriptionRecurOnScheduleIfCompleteFortnightly: {
		id: 'recur-work-item.success-flag-description-recur-on-schedule-if-complete-fortnightly',
		defaultMessage: 'This {issueType} recurs every 2 weeks on {weekDayNames} when completed',
		description:
			'Description of the success flag shown when a work item is successfully set to recur on a fortnightly schedule if complete.',
	},
	successFlagTitleRecurOnScheduleDaily: {
		id: 'recur-work-item.success-flag-title-recur-on-schedule-daily',
		defaultMessage: '{issueKey} recurs every day',
		description:
			'Title of the success flag shown when a work item is successfully set to recur on a daily schedule.',
	},
	successFlagTitleRecurOnScheduleWeekday: {
		id: 'recur-work-item.success-flag-title-recur-on-schedule-weekday',
		defaultMessage: '{issueKey} recurs every weekday',
		description:
			'Title of the success flag shown when a work item is successfully set to recur on a weekday schedule.',
	},
	successFlagTitleRecurOnScheduleWeekly: {
		id: 'recur-work-item.success-flag-title-recur-on-schedule-weekly',
		defaultMessage: '{issueKey} recurs every week on {weekDayNames}',
		description:
			'Title of the success flag shown when a work item is successfully set to recur on a weekly schedule.',
	},
	successFlagTitleRecurOnScheduleFortnightly: {
		id: 'recur-work-item.success-flag-title-recur-on-schedule-fortnightly',
		defaultMessage: '{issueKey} recurs every 2 weeks on {weekDayNames}',
		description:
			'Title of the success flag shown when a work item is successfully set to recur on a fortnightly schedule.',
	},
	goBackLabel: {
		id: 'recur-work-item.go-back-label',
		defaultMessage: 'Go back',
		description: 'Label for the chevron left icon button to go back to the previous screen',
	},
	errorFlagReloadPageButton: {
		id: 'recur-work-item.error-flag-reload-page-button',
		defaultMessage: 'Reload page',
		description: 'Label of the button to reload the page after an error occurs',
	},
	errorFlagDescription: {
		id: 'recur-work-item.error-flag-description',
		defaultMessage:
			'If this keeps happening, share this information with your admin, who should <link>contact support</link>.',
		description: 'Description of the error',
	},
	errorFlagTitle: {
		id: 'recur-work-item.error-flag-title',
		defaultMessage: 'Something went wrong on our end',
		description: 'Title of the error flag',
	},
});
