import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { Fill } from '@atlassian/jira-common-components-skeleton/src/fill.tsx';
import { SpacerCompiled } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';
import { H100LCompiled } from '@atlassian/jira-common-components-skeleton/src/text.tsx';
import { Button as ButtonSkeleton } from '@atlassian/jira-common-components-skeleton/src/button.tsx';

export const Skeleton = () => (
	<Box xcss={skeletonContainerStyles}>
		<H100LCompiled />
		<Fill />
		<SpacerCompiled height={token('space.050')} />
		<Fill />
		<SpacerCompiled height={token('space.050')} />
		<Fill />
		<SpacerCompiled height={token('space.050')} />
		<H100LCompiled />
		<Fill />
		<SpacerCompiled height={token('space.050')} />
		<Fill />
		<SpacerCompiled height={token('space.050')} />
		<H100LCompiled />
		<Fill />
		<SpacerCompiled height={token('space.050')} />
		<ButtonSkeleton />
	</Box>
);

const skeletonContainerStyles = xcss({
	minWidth: '300px',
});
