/**
 * Based on jira/src/packages/issue/issue-view/src/epics/experience-tracking/field-experience-tracking-epic.tsx
 */

import { useCallback, useMemo } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { FeatureFlagValue } from '@atlassian/jira-feature-flagging-using-meta';
import { fg } from '@atlassian/jira-feature-gating';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import {
	useAnalyticsSource,
	useIssueKey,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	sendExperienceAnalytics,
	type ExperienceDescription,
} from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import fieldErrorClassifier, {
	SERVER_ERROR,
} from '@atlassian/jira-issue-view-store/src/common/experience-tracking/field-error-classifier.tsx';
import {
	useApplication,
	useEdition,
	useProjectKey,
} from '@atlassian/jira-project-context-service/src/main.tsx';

type GetEditEventPayloadArg = {
	hasSuccess: boolean;
	hasEdit: boolean;
	errorMessage?: string;
	traceId?: string;
	statusCode?: number;
};

/**
 * This hook is used to help with tracking the experience/SLOs of editing a field.
 * We only want to do this for critical fields. See https://hello.atlassian.net/wiki/spaces/JIE/pages/3166048849/Analytics+events+for+relay-powered+Issue+View+fields
 */
export const useFieldExperienceTracking = ({ fieldId }: { fieldId: string }) => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const application = useApplication(projectKey, true);
	const edition = useEdition(projectKey, true);
	const analyticsSource = useAnalyticsSource();

	const getEditEventPayload = useCallback(
		({
			hasSuccess,
			hasEdit,
			errorMessage,
			traceId,
			statusCode,
		}: GetEditEventPayloadArg): ExperienceDescription => {
			const additionalAttributes: Record<
				string,
				string | boolean | number | Record<string, FeatureFlagValue>
			> = {};
			if (errorMessage != null) {
				additionalAttributes.errorMessage = errorMessage;
			}

			if (traceId != null) {
				additionalAttributes.traceId = traceId;
			}

			if (statusCode && fg('thor_populate_missing_attributes_across_issue_view')) {
				additionalAttributes.statusCode = statusCode;
			}

			return {
				experience: 'editIssue',
				analyticsSource: analyticsSource || 'unknown',
				application: application || null,
				edition: edition || null,
				wasExperienceSuccesful: hasSuccess,
				field: fieldId,
				hasEdit,
				additionalAttributes,
			};
		},
		[analyticsSource, application, edition, fieldId],
	);

	const sendSubmitSucceededExperienceEvent = useCallback(() => {
		sendExperienceAnalytics(
			getEditEventPayload({
				hasSuccess: true,
				hasEdit: true,
			}),
		);
	}, [getEditEventPayload]);

	const sendSubmitFailedExperienceEvent = useCallback(
		(error?: Error) => {
			if ((!fg('bento_send_4xx_to_task_fail_gate_1') && isClientFetchError(error)) || !error) {
				return;
			}
			const errorType = fieldErrorClassifier(error);
			const match = error?.message?.match(/response: (\d+)/);
			const statusCode = match ? parseInt(match[1], 10) : undefined;
			if (statusCode && statusCode >= 400 && statusCode < 500) {
				fireErrorAnalytics({
					error,
					meta: {
						id: 'issue.issue-view.editIssue',
						teamName: 'obsidian',
					},
					attributes: {
						experience: 'editIssue',
						analyticsSource: analyticsSource || 'unknown',
						application: application || null,
						edition: edition || null,
						wasExperienceSuccesful: false,
						field: fieldId,
						hasEdit: true,
						traceId: 'traceId' in error ? String(error?.traceId) : undefined,
					},
				});
			}

			sendExperienceAnalytics(
				getEditEventPayload({
					hasSuccess: !fg('bento_send_4xx_to_task_fail_gate_1') && errorType !== SERVER_ERROR,
					hasEdit: true,
					errorMessage:
						'statusCode' in error ? `${error.statusCode} - ${error.message}` : error.message,
					traceId: 'traceId' in error ? String(error?.traceId) : undefined,
					statusCode:
						'statusCode' in error &&
						typeof error?.statusCode === 'number' &&
						fg('thor_populate_missing_attributes_across_issue_view')
							? error.statusCode
							: undefined,
				}),
			);
		},
		[getEditEventPayload, analyticsSource, application, edition, fieldId],
	);

	const sendEditCancelExperienceEvent = useCallback(() => {
		sendExperienceAnalytics(
			getEditEventPayload({
				hasSuccess: true,
				hasEdit: false,
			}),
		);
	}, [getEditEventPayload]);

	return useMemo(
		() => ({
			sendSubmitSucceededExperienceEvent,
			sendSubmitFailedExperienceEvent,
			sendEditCancelExperienceEvent,
		}),
		[
			sendEditCancelExperienceEvent,
			sendSubmitFailedExperienceEvent,
			sendSubmitSucceededExperienceEvent,
		],
	);
};
