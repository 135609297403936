import React, { type PropsWithChildren } from 'react';
import { graphql, useFragment } from 'react-relay';
import { fg } from '@atlassian/jira-feature-gating';
import { FieldDescriptionIcon } from '@atlassian/jira-issue-field-description-icon/src/ui/index.tsx';
import { FieldEditIcon } from '@atlassian/jira-issue-field-edit-icon/src/FieldEditIcon.tsx';
import { FieldHeading } from '@atlassian/jira-issue-field-heading/src/index.tsx';
import {
	FieldHeadingIconsContainer,
	FieldHeadingTitle,
	FieldWrapper,
	SideBySideField,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin/src/index.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import type { ui_issueViewLayoutFieldHeading_IssueViewFieldHeading$key as IssueViewFieldHeadingFragment } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutFieldHeading_IssueViewFieldHeading.graphql';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';

export type IssueViewFieldHeadingProps = PropsWithChildren<{
	testId?: string;
	area?: string;
	fragmentKey: IssueViewFieldHeadingFragment;
}>;

export const IssueViewFieldHeading = ({
	children,
	area,
	fragmentKey,
	testId,
}: IssueViewFieldHeadingProps) => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const showPinButton = getShowPinButton(area as Area);
	const { overriding } = useInlineEditFieldInjections();

	const data = useFragment<IssueViewFieldHeadingFragment>(
		graphql`
			fragment ui_issueViewLayoutFieldHeading_IssueViewFieldHeading on JiraIssueField {
				...FieldEditIcon
				fieldId
				name
				description
			}
		`,
		fragmentKey,
	);

	const label = overriding.overrideLabel(data.name);
	const description = overriding.overrideDescription(data.description);

	return (
		<FieldWrapper data-testid={testId}>
			<FieldHeading fieldId={data.fieldId}>
				<FieldHeadingTitle>{label}</FieldHeadingTitle>
				<FieldHeadingIconsContainer
					isCustomFieldConfigurationEnabled={fg('issue_view_field_config_edit')}
				>
					{description && <FieldDescriptionIcon description={description} />}
					{showPinButton && <FieldPin fieldId={data.fieldId} label={label} />}
					{fg('issue_view_field_config_edit') && (
						<UFOSegment name="issue-view-field-icon-edit">
							<FieldEditIcon fieldId={data.fieldId} fragmentKey={data} />
						</UFOSegment>
					)}
				</FieldHeadingIconsContainer>
			</FieldHeading>
			<SideBySideField>{children}</SideBySideField>
		</FieldWrapper>
	);
};
