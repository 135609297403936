import React, { useEffect } from 'react';
import type { UFOExperience } from '@atlassian/ufo';

export const ExperienceFailure = ({
	experience,
	config,
}: {
	experience: UFOExperience;
	config?: Parameters<UFOExperience['failure']>[0];
}) => {
	useEffect(() => {
		experience.failure(config);
		// as config is an object, we want to avoid the failure to rerun if that object is not memoized.
		// technically, it shouldn't but, but hey, better be sure
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [experience]);

	return <></>;
};
