import React, { useEffect, useCallback, useRef, useMemo, useLayoutEffect } from 'react';
import { withAnalyticsEvents, type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fg } from '@atlassian/jira-feature-gating';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { Transition } from '@atlassian/jira-issue-fetch-services/src/types.tsx';
import { useFieldsValues } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';
import isValidExperienceError from '@atlassian/jira-issue-view-common-utils/src/utils/is-valid-experience-error.tsx';
import { WorkflowTransitionsStoreContainer } from '@atlassian/jira-issue-workflow-transitions-services/src/context.tsx';
import { useWorkflowTransitionsValue } from '@atlassian/jira-issue-workflow-transitions-services/src/main.tsx';
import { fireTrackAnalyticsDeferred } from '@atlassian/jira-product-analytics-bridge';
import type { BaseUrl, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';
import { useAnalyticsSource } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { STATUS_FIELD_FETCH_TRANSITIONS_KEY } from '../../common/constants.tsx';
import { transitionIssueExperienceDescription } from '../experience-descriptions/index.tsx';
import { useFetchTransitionsForStatusField } from '../transitions-relay-query/index.tsx';
import type { Props } from './types.tsx';
import {
	filterTransitionsWithDestinationAndCategory,
	transformTransitionToStore,
	transformTransitionFromStore,
} from './utils.tsx';

export const getStatusTransitionsUrl = (_baseUrl: BaseUrl, issueKey: IssueKey) =>
	`/rest/api/2/issue/${issueKey}/transitions?sortByOpsBarAndStatus=true`;

const enhancedSendExperienceAnalytics = (
	successful: boolean,
	projectType?: ProjectType | null,
	errorMessage?: undefined | string,
	traceId?: string,
	statusCode?: number,
	source?: string,
) => {
	const description =
		typeof errorMessage === 'string' || fg('thor_resolve_transition_event_gate')
			? transitionIssueExperienceDescription(
					successful,
					'GET',
					fg('thor_resolve_transition_event_gate')
						? source ?? ''
						: 'Issue View BaseTransitionsQuery',
					projectType || null,
					errorMessage,
					traceId,
					fg('thor_populate_missing_attributes_across_issue_view') ? statusCode : undefined,
				)
			: transitionIssueExperienceDescription(
					successful,
					'GET',
					fg('thor_resolve_transition_event_gate')
						? source ?? ''
						: 'Issue View BaseTransitionsQuery',
					projectType || null,
				);
	sendExperienceAnalytics(description);
};

export const BaseTransitionsQuery = (props: Props) => {
	const {
		status,
		issueKey,
		baseUrl,
		projectType,
		preFetch,
		createAnalyticsEvent,
		isSaving,
		source,
	} = props;
	const [transitionsState, setTransitionsState] = useWorkflowTransitionsValue();
	const isSingletonRequestRef = useRef(false);

	const [fieldValues] = useFieldsValues(issueKey);
	const { fetchTransitions } = fg('relay-migration-issue-fields-status')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useFetchTransitionsForStatusField({ issueKey })
		: { fetchTransitions: () => [] };

	const fetch = useCallback(
		async (event: UIAnalyticsEvent) => {
			if (transitionsState.loading) {
				return;
			}
			if (isSingletonRequestRef.current) {
				return;
			}
			isSingletonRequestRef.current = true;

			setTransitionsState({
				loading: true,
				error: null,
			});

			try {
				let transitions: Transition[];

				if (fg('relay-migration-issue-fields-status')) {
					transitions = await fetchTransitions();
				} else {
					const result = await fetchJson(getStatusTransitionsUrl(baseUrl, issueKey));
					transitions = filterTransitionsWithDestinationAndCategory(result.transitions).map(
						transformTransitionToStore,
					);
				}

				fireTrackAnalyticsDeferred(event, 'statusField transitionsFetched', 'statusField', {
					count: transitions.length,
				});

				// Issue Transition SLA
				if (fg('thor_resolve_transition_event_gate')) {
					enhancedSendExperienceAnalytics(
						true,
						projectType,
						undefined,
						undefined,
						undefined,
						source,
					);
				} else {
					enhancedSendExperienceAnalytics(true, projectType);
				}

				setTransitionsState({
					data: transitions,
					loading: false,
					error: null,
				});
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (e: any) {
				trackOrLogClientError(STATUS_FIELD_FETCH_TRANSITIONS_KEY, e.toString(), e);

				// Issue Transition SLA
				if (isValidExperienceError(e) || fg('thor_resolve_transition_event_gate')) {
					const shouldSendStatusCode =
						e instanceof FetchError && fg('thor_populate_missing_attributes_across_issue_view');

					enhancedSendExperienceAnalytics(
						false,
						projectType,
						`${e.name || ''} ${e.message}`.trim(),
						e.traceId,
						shouldSendStatusCode ? e.statusCode : undefined,
						fg('thor_resolve_transition_event_gate') ? source : undefined,
					);
				} else {
					enhancedSendExperienceAnalytics(true, projectType);
				}

				setTransitionsState({
					data: null,
					loading: false,
					error: e,
				});
			}
			isSingletonRequestRef.current = false;
		},
		// we want to fetch the transitions whenever any field values change
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[baseUrl, issueKey, projectType, setTransitionsState, fieldValues],
	);

	const useProperEffect = fg('relay-migration-issue-fields-status') ? useLayoutEffect : useMemo;

	// re-evaluates fetching data again whenever issueKey changes
	// we have to use useLayoutEffect instead of useEffect here because useMemo will be invoked before render
	// we cannot use useMemo in concurrent - if setState within a useMemo concurrent breaks
	// eg, when you have a parent issue and navigate to a child issue - the child issue loads the parent data instead
	// when you use useMemo
	useProperEffect(() => {
		if (preFetch === true && createAnalyticsEvent) {
			fetch(createAnalyticsEvent({}));
		}
	}, [issueKey, preFetch, createAnalyticsEvent, setTransitionsState, fetch]);

	// We need to clear the "data" list whenever the status object changes, because
	// each status has a different list of transitions associated with it.
	// We could achieve a similar behaviour by using a "key" prop when rendering
	// TransitionQuery, but that causes problems with keeping the focus on the component
	// when the status changes. We are using the full status object as "self-transitions"
	// from one status to itself need to exhibit the same behaviour, and it also fixes a bug
	// with PopupSelect downstream
	useEffect(() => {
		if (isSaving) {
			setTransitionsState({
				data: null,
			});
		}
		// component just finished saving, changing from saving to not saving, so we fetch the transitions for the saved status!
		else if (!isSaving && preFetch === true && createAnalyticsEvent) {
			fetch(createAnalyticsEvent({}));
		}
	}, [isSaving, status, preFetch, createAnalyticsEvent, setTransitionsState, fetch]);

	return props.children({
		...transitionsState,
		data: transitionsState.data ? transitionsState.data.map(transformTransitionFromStore) : null,
		fetch,
	});
};

const ScopedTransitionsQueryBase = (props: Props) => (
	<WorkflowTransitionsStoreContainer scope={props.issueKey}>
		<BaseTransitionsQuery {...props} />
	</WorkflowTransitionsStoreContainer>
);

const TransitionQueryWithAnalyticsSource = (props: Props) => {
	const source = useAnalyticsSource();
	return <ScopedTransitionsQueryBase {...props} source={source} />;
};

export const ScopedTransitionsQuery = componentWithFG(
	'thor_resolve_transition_event_gate',
	TransitionQueryWithAnalyticsSource,
	ScopedTransitionsQueryBase,
);

const transactionsQueryWithAnalytics = withAnalyticsEvents()(ScopedTransitionsQuery);
export { transactionsQueryWithAnalytics as TransitionsQuery };
