/**
 * @generated SignedSource<<fad6ea416e8f0fef1804bf1c127c937d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type AssignToMeInlineEditInner_rootFragmentKey$data = {
  readonly " $fragmentSpreads": FragmentRefs<"assignToMe_issueFieldAssigneeAssignToMe_fragmentRefRoot">;
  readonly " $fragmentType": "AssignToMeInlineEditInner_rootFragmentKey";
};
export type AssignToMeInlineEditInner_rootFragmentKey$key = {
  readonly " $data"?: AssignToMeInlineEditInner_rootFragmentKey$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssignToMeInlineEditInner_rootFragmentKey">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "AssignToMeInlineEditInner_rootFragmentKey",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "assignToMe_issueFieldAssigneeAssignToMe_fragmentRefRoot"
    }
  ],
  "type": "Query"
};

(node as any).hash = "b100f56c7e7ba0a1a5348dc3923caafb";

export default node;
