import * as messages from '../../../common/messages.tsx';
import type {
	ISSUE_MAP_KEYS,
	ALERT_MAP_KEYS,
	MappedType,
	MappedTypeResponseWithHeader,
} from '../../../common/types.tsx';

export const PROPERTY_KEY = 'automation-discoverability-dismiss-flow-temp';

export const ISSUE_MAP: MappedType<keyof typeof ISSUE_MAP_KEYS, MappedTypeResponseWithHeader> = {
	itsm_template_18: {
		info: {
			header: messages.itsmTemplate18.headerText,
		},
	},
	itsm_template_20: {
		info: {
			header: messages.itsmTemplate20.headerText,
		},
	},
	itsm_template_21: {
		info: {
			header: messages.itsmTemplate21.headerText,
		},
	},
	itsm_template_24: {
		info: {
			header: messages.itsmTemplate24.headerText,
		},
	},
	itsm_template_25: {
		info: {
			header: messages.itsmTemplate25.headerText,
		},
	},
	itsm_template_26: {
		info: {
			header: messages.itsmTemplate26.headerText,
		},
	},
	default: {
		id: 'itsm_template_15',
		info: {
			header: messages.itsmTemplate15.headerText,
		},
	},
};

export const ALERT_MAP: MappedType<keyof typeof ALERT_MAP_KEYS, MappedTypeResponseWithHeader> = {
	itsm_template_19: {
		info: {
			header: messages.itsmTemplate19.headerText,
		},
	},
	itsm_template_19_newrelic: {
		id: 'itsm_template_19',
		info: {
			header: messages.itsmTemplate19.headerTextNewrelic,
		},
	},
	itsm_template_27: {
		info: {
			header: messages.itsmTemplate27.headerText,
		},
	},
	itsm_template_29: {
		info: {
			header: messages.itsmTemplate29.headerText,
		},
	},
	itsm_template_23: {
		info: {
			header: messages.itsmTemplate23.headerText,
		},
	},
	default: {
		id: 'itsm_template_19',
		info: {
			header: messages.itsmTemplate19.headerText,
		},
	},
};
