import { useMemo } from 'react';
import type { QuickAddPayloadType } from '@atlassian/jira-issue-view-store/src/actions/child-panel-actions.tsx';
import { QUICK_ACTION_HOTKEYS } from './constants.tsx';

type keymapParams = {
	onChildCreateClick?: (payload?: QuickAddPayloadType) => void;
	onCreateIssueInEpicClick?: (payload?: QuickAddPayloadType) => void;
	onCreateSubtaskClick?: (payload?: QuickAddPayloadType) => void;
	onAddIssueLinkClick?: (payload?: QuickAddPayloadType) => void;
	shouldShowCreateSubtaskButton?: boolean;
	supportsChildCreation?: boolean;
	shouldShowIssueInEpicButton?: boolean;
	canCreateIssueLink?: boolean;
};

export const useQuickActionShortcuts = ({
	onChildCreateClick,
	onCreateIssueInEpicClick,
	onCreateSubtaskClick,
	onAddIssueLinkClick,
	shouldShowCreateSubtaskButton,
	supportsChildCreation,
	shouldShowIssueInEpicButton,
	canCreateIssueLink,
}: keymapParams) => {
	const keymap = useMemo(() => {
		const _keymap: Record<string, { callback: (ev?: KeyboardEvent) => void }> = {};

		if (canCreateIssueLink) {
			_keymap[QUICK_ACTION_HOTKEYS.linkIssue.key] = {
				callback: (ev?: KeyboardEvent) => {
					onAddIssueLinkClick?.({ triggeredViaHotKey: true });
					// ev.preventDefault to prevent printing "K" in input element for link issue action
					ev?.preventDefault();
				},
			};
		}

		let createChildCallback = null;

		/**
		 * "shift + c" hotkey is same for all child creation (subtask / issueInEpic / child item ),
		 * so we need to figure out from shouldRender flags which callback to wire up with this hotkey
		 */
		if (shouldShowCreateSubtaskButton) {
			createChildCallback = onCreateSubtaskClick;
		}
		if (shouldShowIssueInEpicButton) {
			createChildCallback = onCreateIssueInEpicClick;
		}
		if (supportsChildCreation) {
			createChildCallback = onChildCreateClick;
		}

		if (createChildCallback) {
			_keymap[QUICK_ACTION_HOTKEYS.createChild.key] = {
				callback: () => {
					createChildCallback?.({ triggeredViaHotKey: true });
				},
			};
		}

		return _keymap;
	}, [
		canCreateIssueLink,
		onAddIssueLinkClick,
		onChildCreateClick,
		onCreateIssueInEpicClick,
		onCreateSubtaskClick,
		shouldShowCreateSubtaskButton,
		shouldShowIssueInEpicButton,
		supportsChildCreation,
	]);

	return { keymap };
};
