import React, {
	useCallback,
	useMemo,
	useEffect,
	useRef,
	useState,
	forwardRef,
	type Ref,
} from 'react';
import { styled } from '@compiled/react';
import {
	graphql,
	useLazyLoadQuery,
	usePaginationFragment,
	type EntryPointProps,
} from 'react-relay';
import Select, { createFilter, type OptionType as Option } from '@atlaskit/select';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import {
	FRAGMENT_SELECTABLE_FIELD_OPTIONS_FIRST,
	SEARCH_DEBOUNCE_TIMEOUT,
	SELECTABLE_FIELD_PAGE_OPTIONS,
} from '@atlassian/jira-issue-field-constants/src/index.tsx';
import { useSuspenselessRefetch } from '@atlassian/jira-issue-hooks/src/services/use-suspenseless-refetch/index.tsx';
import useDebouncedCallback from '@atlassian/jira-platform-use-debounce/src/utils/use-debounce-callback/index.tsx';
import cascadingSelectSearchRefetchQuery, {
	type cascadingSelect_cascadingSelectSearchRefetchQuery as CascadingSelectRefetchQuery,
	type JiraFieldOptionIdsFilterInput,
	type cascadingSelect_cascadingSelectSearchRefetchQuery$variables,
} from '@atlassian/jira-relay/src/__generated__/cascadingSelect_cascadingSelectSearchRefetchQuery.graphql';
import type { cascadingSelect_issueFieldCascadingSelectEditviewFull_CascadingSelectEditViewWithFieldOptionsFragment$key as CascadingSelectFragment } from '@atlassian/jira-relay/src/__generated__/cascadingSelect_issueFieldCascadingSelectEditviewFull_CascadingSelectEditViewWithFieldOptionsFragment.graphql';
import { fg } from '@atlassian/jira-feature-gating';
import messages from './messages.tsx';
import type {
	CascadingOption,
	CascadingSelectEditViewProps,
	CascadingSelectEditViewWithFieldOptionsFragmentProps,
} from './types.tsx';

const aggToAtlaskitOption = (item?: CascadingOption | null): Option | null =>
	item
		? {
				label: item.value || '',
				value: item.id,
				isDisabled: Boolean(item.isDisabled),
			}
		: null;

const atlaskitOptionToAgg = (item?: Option | null): CascadingOption | null =>
	item
		? {
				value: item.label,
				id: String(item.value),
				isDisabled: Boolean(item.isDisabled),
			}
		: null;

const filterOption = createFilter({ stringify: (option: Option) => option.label });

export type CascadingSelectEditViewSuggestionsVariables = {
	id: string;
	selectedParentId: string | undefined;
	first: number;
	queryString: string | undefined;
	filterParentOptionsById: JiraFieldOptionIdsFilterInput | null;
	filterChildOptionsById: JiraFieldOptionIdsFilterInput | null;
};

export const getCascadingSelectEditViewSuggestionsVariables = ({
	id,
	selectedParentId,
	first,
	queryString,
	filterParentOptionsById,
	filterChildOptionsById,
}: CascadingSelectEditViewSuggestionsVariables): cascadingSelect_cascadingSelectSearchRefetchQuery$variables => ({
	id,
	first,
	selectedParentIdFilter: { optionIds: [selectedParentId || ''], operation: 'ALLOW' },
	isSelectedParentIdSet: !!selectedParentId,
	searchBy: queryString || '',
	filterById: filterParentOptionsById || null,
	childOptionsFilterById: filterChildOptionsById || null,
});

/**
 * CascadingSelectEditViewWithFieldOptionsFragment is a version of the edit view that allows
 * the passing of a fragment for experiences like GIC that might want to group fetch data on mount
 * instead of having a separate network request for it
 *
 * @param props [CascadingSelectEditViewWithFieldOptionsFragmentProps](./types.tsx)
 */
export const CascadingSelectEditViewWithFieldOptionsFragment = forwardRef(
	(
		{
			autoFocus = true,
			areOptionsOnSameRow = false,
			fieldId,
			fieldOptionsFragmentRef,
			onChange,
			parentOptionValue,
			childOptionValue,
			filterParentOptionsById = null,
			filterChildOptionsById = null,
			isDisabled = false,
			isInvalid = false,
			fetchSuggestionsOnMount = false,
			spacing = 'default',
			menuPosition,
			menuPortalTarget,
			ariaLabel = 'Cascading Select',
			ariaLabelledBy = '',
			wrapper: Wrapper,
		}: CascadingSelectEditViewWithFieldOptionsFragmentProps,
		ref: Ref<HTMLDivElement>,
	) => {
		const { formatMessage } = useIntl();
		const loadingMessage = useCallback(() => formatMessage(messages.loading), [formatMessage]);
		const noOptionsMessage = useCallback(() => formatMessage(messages.empty), [formatMessage]);
		const placeholderMessage = useMemo(
			() => formatMessage(messages.noOptionsSelected),
			[formatMessage],
		);
		const childRef = useRef(null);
		/** Whether the end-user interacted with the parent selector or not.
		 * The child selector should never be auto-focussed on the first render.
		 * However, if the parent option is changed and child options are available, that's fine.
		 */
		const [userChosenParentOption, setUserChosenParentOption] = useState<string | null>(null);

		const [searchByString, setSearchByString] = useState<string>('');

		const firstUpdate = useRef(true);

		// #region Relay
		// suggestions fragment with pagination and refetch
		const {
			data: fieldOptionsSearchData,
			refetch: refetchSuggestions,
			loadNext,
			hasNext,
			isLoadingNext,
		} = usePaginationFragment<CascadingSelectRefetchQuery, CascadingSelectFragment>(
			graphql`
				fragment cascadingSelect_issueFieldCascadingSelectEditviewFull_CascadingSelectEditViewWithFieldOptionsFragment on Query
				@refetchable(queryName: "cascadingSelect_cascadingSelectSearchRefetchQuery")
				@argumentDefinitions(
					id: { type: "ID!" }
					selectedParentIdFilter: { type: "JiraFieldOptionIdsFilterInput!" }
					isSelectedParentIdSet: { type: "Boolean!" }
					first: { type: "Int", defaultValue: 50 }
					after: { type: "String", defaultValue: null }
					searchBy: { type: "String", defaultValue: "" }
					filterById: { type: "JiraFieldOptionIdsFilterInput" }
					childOptionsFilterById: { type: "JiraFieldOptionIdsFilterInput" }
				) {
					node(id: $id) {
						... on JiraCascadingSelectField {
							parentOptions(
								first: $first
								after: $after
								searchBy: $searchBy
								filterById: $filterById
							)
								@optIn(to: "JiraCascadingParentOptions")
								@connection(
									key: "cascadingSelect_issueFieldCascadingSelectEditviewFull_parentOptions"
								) {
								edges {
									node {
										id
										value
										isDisabled
										childOptions(filterById: $childOptionsFilterById) {
											edges {
												node {
													id
													value
													isDisabled
												}
											}
										}
									}
								}
							}
							selectedParentOptions: parentOptions(filterById: $selectedParentIdFilter)
								@include(if: $isSelectedParentIdSet)
								@optIn(to: "JiraCascadingParentOptions") {
								edges {
									node {
										id
										value
										isDisabled
										childOptions(filterById: $childOptionsFilterById) {
											edges {
												node {
													id
													value
													isDisabled
												}
											}
										}
									}
								}
							}
						}
					}
				}
			`,
			fieldOptionsFragmentRef,
		);

		const { parentOptions, selectedParentOptions } = fieldOptionsSearchData.node || {};
		const { edges: fieldOptionsData } = parentOptions || {};
		const selectedParentData = selectedParentOptions?.edges?.[0]?.node;
		// #endregion

		// #region Debounced suspensless refetch helpers
		const [searchSuspenselessRefetch, isLoading, lastFetchError] = useSuspenselessRefetch<
			CascadingSelectRefetchQuery,
			CascadingSelectFragment
		>(cascadingSelectSearchRefetchQuery, refetchSuggestions);

		const [debouncedSuggestionsRefetcher] = useDebouncedCallback(
			searchSuspenselessRefetch,
			SEARCH_DEBOUNCE_TIMEOUT,
		);

		const [searchLeadingEdgeSuspenselessRefetch] = useDebouncedCallback(
			searchSuspenselessRefetch,
			SEARCH_DEBOUNCE_TIMEOUT,
			{ leading: true },
		);
		// #endregion

		// #region Common callbacks for the selector
		const onFocusParent = useCallback(() => {
			searchLeadingEdgeSuspenselessRefetch(
				getCascadingSelectEditViewSuggestionsVariables({
					id: fieldId,
					selectedParentId: parentOptionValue?.id,
					queryString: searchByString,
					filterParentOptionsById,
					filterChildOptionsById,
					first: FRAGMENT_SELECTABLE_FIELD_OPTIONS_FIRST,
				}),
			);
		}, [
			fieldId,
			filterChildOptionsById,
			filterParentOptionsById,
			parentOptionValue?.id,
			searchByString,
			searchLeadingEdgeSuspenselessRefetch,
		]);

		const onSearchByStringChangeFunction = useCallback((newSearchByString: string): void => {
			setSearchByString(newSearchByString);
		}, []);

		useEffect(() => {
			if (firstUpdate.current) {
				firstUpdate.current = false;
				return;
			}

			debouncedSuggestionsRefetcher(
				getCascadingSelectEditViewSuggestionsVariables({
					id: fieldId,
					selectedParentId: parentOptionValue?.id,
					queryString: searchByString,
					filterParentOptionsById,
					filterChildOptionsById,
					first: FRAGMENT_SELECTABLE_FIELD_OPTIONS_FIRST,
				}),
			);
		}, [
			debouncedSuggestionsRefetcher,
			fieldId,
			filterChildOptionsById,
			filterParentOptionsById,
			parentOptionValue?.id,
			searchByString,
			searchLeadingEdgeSuspenselessRefetch,
		]);

		const onMenuScrollToBottom = () => {
			if (hasNext) {
				loadNext(SELECTABLE_FIELD_PAGE_OPTIONS);
			}
		};

		const onChangeChild = useCallback(
			(selectedChildOption: Option | null) => {
				const newValue = {
					parentOptionValue: parentOptionValue ?? null,
					childOptionValue: atlaskitOptionToAgg(selectedChildOption),
				};
				onChange && onChange(newValue);
			},
			[onChange, parentOptionValue],
		);

		const onChangeParent = useCallback(
			(selectedParentOption: Option | null) => {
				const newValue = {
					parentOptionValue: atlaskitOptionToAgg(selectedParentOption),
					childOptionValue: null,
				};

				// Manage whether the child selector should be focussed.
				// @ts-expect-error Atlaskit's select does not have a typed `focus` prototype member, though it exists in practice.
				childRef && childRef.current && childRef.current.focus();

				// once a user has selected a parent, it's fine to auto-focus the child when it disappeared then re-appeared.
				setUserChosenParentOption(newValue.parentOptionValue?.id || null);

				onChange && onChange(newValue);
			},
			[onChange],
		);
		// #endregion

		// #region Transform options data from relay to AK Select
		const defaultFailedOption: Option = useMemo(
			() => ({
				label: formatMessage(messages.failedFetch),
				value: '',
				isDisabled: true,
			}),
			[formatMessage],
		);

		const withErrorOption = useCallback(
			(options: Option[]): Option[] => {
				const arr = options || [];
				if (lastFetchError) {
					arr.push(defaultFailedOption);
				}
				return arr;
			},
			[lastFetchError, defaultFailedOption],
		);

		const cascadingOptionResults = useMemo(
			() => fieldOptionsData?.map((edge) => edge?.node).filter(Boolean) ?? [],
			[fieldOptionsData],
		);

		// Add selected parent data and child options to the main options
		// This serves two purposes:
		// 1. Always ensures that the selected parent option is an option in the drop down (i.e. if it's out of the first 100 fetched options)
		// 2. Ensures, that after search of parent options and selecting the option, the child options data remain available in the cashe
		//    For example, when you search for Option101 (that is outside of the first 100 options fetched), the data for Option101 will be fetched
		//    But, upon selection, the search is cleared which resets the fetch to first 100 options, and the 101st option would be removed from cascadingOptionResults,
		//    That means the child options for the selected Option101 are gone and child option can not be selected.
		useMemo(() => {
			if (
				selectedParentData?.id &&
				cascadingOptionResults.findIndex(
					(parentOption) => selectedParentData?.id === parentOption?.id,
				) === -1
			) {
				cascadingOptionResults.push({
					id: selectedParentData.id ?? '',
					value: selectedParentData.value ?? undefined,
					isDisabled: selectedParentData.isDisabled ?? undefined,
					childOptions: selectedParentData.childOptions ?? undefined,
				});
			}
		}, [
			cascadingOptionResults,
			selectedParentData?.childOptions,
			selectedParentData?.id,
			selectedParentData?.isDisabled,
			selectedParentData?.value,
		]);

		// Extract list of allowed parent values. It's just the full list of what we've found for now.
		const parentOptionResults: Option[] = useMemo(
			() =>
				withErrorOption(
					cascadingOptionResults.map((result) => aggToAtlaskitOption(result)).filter(Boolean),
				),
			[withErrorOption, cascadingOptionResults],
		);

		// Extract list of allowed child values. Only do so if a parent value exists and has a valid optionId, otherwise
		// there's no way we could select a child option.
		const childOptionResults: Option[] = useMemo(
			() =>
				withErrorOption(
					(parentOptionValue?.id &&
						cascadingOptionResults
							.find((result) => parentOptionValue.id === result?.id)
							?.childOptions?.edges?.map((edge) => aggToAtlaskitOption(edge?.node))
							.filter(Boolean)) ||
						[],
				),
			[withErrorOption, cascadingOptionResults, parentOptionValue],
		);
		// #endregion

		// #region state control between parent and child UI components
		const showChildOptionSelector = useMemo(
			() => parentOptionValue?.value && (childOptionValue?.value || childOptionResults.length > 0),
			[childOptionResults.length, childOptionValue?.value, parentOptionValue?.value],
		);
		// #endregion

		useEffect(() => {
			if (fetchSuggestionsOnMount) {
				searchLeadingEdgeSuspenselessRefetch(
					getCascadingSelectEditViewSuggestionsVariables({
						id: fieldId,
						selectedParentId: parentOptionValue?.id,
						queryString: searchByString,
						filterParentOptionsById,
						filterChildOptionsById,
						first: FRAGMENT_SELECTABLE_FIELD_OPTIONS_FIRST,
					}),
				);
			}
		}, [
			fieldId,
			searchLeadingEdgeSuspenselessRefetch,
			fetchSuggestionsOnMount,
			filterChildOptionsById,
			filterParentOptionsById,
			parentOptionValue?.id,
			searchByString,
		]);

		const content = (
			<Container
				areOptionsOnSameRow={areOptionsOnSameRow}
				data-testid="issue-field-cascading-select-editview-full.ui.cascading-select.container"
				ref={fg('jsc_inline_editing_field_refactor') ? ref : undefined}
			>
				<Select
					autoFocus={autoFocus}
					isDisabled={isDisabled}
					isInvalid={isInvalid}
					// This prop defaults to true in a test environment and false in a browser environment
					{...(fg('cascading_select_edit_field_no_blur_on_select')
						? { blurInputOnSelect: false }
						: {})}
					openMenuOnFocus
					closeMenuOnSelect
					isClearable
					isLoading={isLoading || isLoadingNext}
					loadingMessage={loadingMessage}
					noOptionsMessage={noOptionsMessage}
					options={parentOptionResults}
					onInputChange={onSearchByStringChangeFunction}
					onMenuScrollToBottom={onMenuScrollToBottom}
					filterOption={filterOption}
					value={aggToAtlaskitOption(parentOptionValue)}
					placeholder={formatMessage(messages.noOptionsSelected)}
					onChange={onChangeParent}
					onFocus={onFocusParent}
					spacing={spacing}
					menuPosition={menuPosition}
					menuPortalTarget={fg('jsc_inline_editing_field_refactor') ? menuPortalTarget : undefined}
					aria-label={ariaLabel}
					aria-labelledby={ariaLabelledBy}
				/>
				{showChildOptionSelector && (
					<Select
						isDisabled={isDisabled}
						isInvalid={isInvalid}
						// This prop defaults to true in a test environment and false in a browser environment
						{...(fg('cascading_select_edit_field_no_blur_on_select')
							? { blurInputOnSelect: false }
							: {})}
						ref={childRef}
						autoFocus={!!userChosenParentOption}
						openMenuOnFocus
						closeMenuOnSelect
						isClearable
						isLoading={isLoading || isLoadingNext}
						loadingMessage={loadingMessage}
						noOptionsMessage={noOptionsMessage}
						options={childOptionResults}
						filterOption={filterOption}
						value={aggToAtlaskitOption(childOptionValue)}
						placeholder={placeholderMessage}
						onChange={onChangeChild}
						spacing={spacing}
						menuPosition={menuPosition}
						menuPortalTarget={
							fg('jsc_inline_editing_field_refactor') ? menuPortalTarget : undefined
						}
						aria-label={ariaLabel}
						aria-labelledby={ariaLabelledBy}
					/>
				)}
			</Container>
		);

		if (Wrapper != null && fg('jsc_inline_editing_field_refactor')) {
			return <Wrapper>{content}</Wrapper>;
		}

		return content;
	},
);

/**
 * Simple use case variant which handles its own fetching of suggestions data.
 * Use the CascadingSelectEditViewWithFieldOptionsFragment variant if you want to handle
 * prefilling the suggestions data as part of a larger query instead of on mount / on focus.
 *
 * @param props [CascadingSelectEditViewProps](./types.tsx)
 */
export const CascadingSelectEditView = forwardRef(
	(props: CascadingSelectEditViewProps, ref: Ref<HTMLDivElement>) => {
		const suggestionsData = useLazyLoadQuery<CascadingSelectRefetchQuery>(
			graphql`
				query cascadingSelect_cascadingSelectSearchQuery(
					$id: ID!
					$selectedParentIdFilter: JiraFieldOptionIdsFilterInput!
					$isSelectedParentIdSet: Boolean!
				) {
					...cascadingSelect_issueFieldCascadingSelectEditviewFull_CascadingSelectEditViewWithFieldOptionsFragment
						@arguments(
							id: $id
							selectedParentIdFilter: $selectedParentIdFilter
							isSelectedParentIdSet: $isSelectedParentIdSet
						)
				}
			`,
			getCascadingSelectEditViewSuggestionsVariables({
				id: props.fieldId,
				selectedParentId: props.parentOptionValue?.id,
				queryString: '',
				filterParentOptionsById: null,
				filterChildOptionsById: null,
				first: FRAGMENT_SELECTABLE_FIELD_OPTIONS_FIRST,
			}),
			{ fetchPolicy: 'store-only' },
		);

		return (
			<CascadingSelectEditViewWithFieldOptionsFragment
				{...props}
				ref={fg('jsc_inline_editing_field_refactor') ? ref : undefined}
				fieldOptionsFragmentRef={suggestionsData}
			/>
		);
	},
);

const CascadingSelectEditViewEntryPoint = ({
	props,
}: EntryPointProps<{}, {}, CascadingSelectEditViewProps, {}>) => (
	<CascadingSelectEditView {...props} />
);

export default CascadingSelectEditViewEntryPoint;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ areOptionsOnSameRow?: boolean }>({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	flexDirection: (props) => (props.areOptionsOnSameRow ? 'row' : 'column'),
	gap: token('space.050'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> *': {
		flexBasis: 0,
		flexGrow: 1,
	},
});
