import React from 'react';
import Icon from '@atlaskit/icon';
import type { Size } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';

type Props = {
	size: Size;
	label: string;
};

export const BitbucketPullrequestMerged = ({ size, label }: Props) => (
	<Icon
		size={size}
		label={label}
		glyph={() => (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 24"
				width="24"
				height="24"
				fill="none"
			>
				<path fill={token('color.icon.inverse')} d="M0 0h24v24H0z" fillOpacity=".01" />
				<g fill={token('color.icon.success')} clipRule="evenodd" fillRule="evenodd">
					<path d="M7 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zM4 6a3 3 0 1 1 4 2.83V20a1 1 0 1 1-2 0V8.83A3.001 3.001 0 0 1 4 6z" />
					<path d="M7 7a1 1 0 0 1 1 1v2a7.001 7.001 0 0 0 6.188 6.953 3.001 3.001 0 1 1-.03 2.008A9.001 9.001 0 0 1 6 10V8a1 1 0 0 1 1-1zm10 10a1 1 0 1 0 0 2 1 1 0 0 0 0-2zM20.707 4.293a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L16 7.586l3.293-3.293a1 1 0 0 1 1.414 0z" />
				</g>
			</svg>
		)}
	/>
);
export const BitbucketPullrequestDeclined = ({ size, label }: Props) => (
	<Icon
		size={size}
		label={label}
		glyph={() => (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 24"
				width="24"
				height="24"
				fill="none"
			>
				<path fill={token('color.icon.inverse')} d="M0 0h24v24H0z" fillOpacity="0" />
				<g fill={token('color.icon.danger')} clipRule="evenodd" fillRule="evenodd">
					<path d="M7 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zM4 6a3 3 0 1 1 4 2.8V20a1 1 0 1 1-2 0V8.8A3 3 0 0 1 4 6zM17 11c.6 0 1 .4 1 1v3.2a3 3 0 1 1-2 0V12c0-.6.4-1 1-1zm0 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2zM13.8 2.8a1 1 0 0 1 1.4 0L17 4.6l1.8-1.8a1 1 0 1 1 1.4 1.4L18.4 6l1.8 1.8a1 1 0 0 1-1.4 1.4L17 7.4l-1.8 1.8a1 1 0 1 1-1.4-1.4L15.6 6l-1.8-1.8a1 1 0 0 1 0-1.4z" />
				</g>
			</svg>
		)}
	/>
);

export const DeploymentProdSuccess = ({ size, label }: Props) => (
	<Icon
		size={size}
		label={label}
		glyph={() => (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 16 16"
				width="16"
				height="16"
				fill="none"
			>
				<path fill={token('color.icon.inverse')} d="M0 0h16v16H0z" fillOpacity=".01" />
				<path
					fill={token('color.icon.success')}
					d="M1 9.75a3.15 3.15 0 0 0 2.8 3.13v.02h8.4v-.02a3.15 3.15 0 0 0 .533-6.155 4.902 4.902 0 0 0-9.466 0A3.151 3.151 0 0 0 1 9.75Zm5.85-1.8a.669.669 0 1 0-.946.946l1.15 1.15a.67.67 0 0 0 .946 0l2.096-2.096a.67.67 0 1 0-.946-.946L7.527 8.627 6.85 7.95Z"
					clipRule="evenodd"
					fillRule="evenodd"
				/>
			</svg>
		)}
	/>
);

export const DeploymentNonProdSuccess = ({ size, label }: Props) => (
	<Icon
		size={size}
		label={label}
		glyph={() => (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 16 16"
				width="16"
				height="16"
				fill="none"
			>
				<path fill={token('color.icon.inverse')} d="M0 0h16v16H0z" fillOpacity="0" />
				<mask id="a" fill={token('color.icon.inverse')}>
					<path
						fill={token('color.icon.success')}
						d="M3.8 12.9a3.2 3.2 0 0 1-.5-6.2 4.9 4.9 0 0 1 9.4 0 3.2 3.2 0 0 1-.5 6.2H3.8Z"
						clipRule="evenodd"
						fillRule="evenodd"
					/>
				</mask>
				<path
					fill={token('color.icon.success')}
					d="M3.8 12.9h1.5v-1.4L4 11.4l-.2 1.5Zm-.5-6.2.4 1.5.8-.3.2-.8-1.4-.4Zm9.4 0-1.4.4.2.8.8.3.4-1.5Zm-.5 6.2-.2-1.5-1.3.1V13h1.5Zm0 0v1.5h1.5v-1.5h-1.5Zm-8.4 0H2.3v1.5h1.5v-1.5Zm.2-1.5c-.9-.1-1.5-.8-1.5-1.7h-3c0 2.4 1.8 4.4 4.1 4.7l.4-3ZM2.5 9.7c0-.7.5-1.3 1.2-1.5l-.9-3C1 5.9-.5 7.7-.5 9.8h3Zm2.2-2.6A3.4 3.4 0 0 1 8 4.6v-3c-3 0-5.4 2-6.2 4.7l3 .8ZM8 4.6c1.6 0 2.9 1 3.3 2.5l2.9-.8A6.4 6.4 0 0 0 8 1.6v3Zm4.3 3.6c.7.2 1.2.8 1.2 1.5h3c0-2-1.4-3.9-3.3-4.4l-.9 2.9Zm1.2 1.5c0 .9-.6 1.6-1.5 1.7l.4 3a4.7 4.7 0 0 0 4.1-4.7h-3ZM10.7 13h3-3Zm1.5-1.5H3.8v3h8.4v-3Zm-6.9 1.5h-3 3Z"
					mask="url(#a)"
				/>
				<path
					fill={token('color.icon.success')}
					d="M6.8 7.9 7 8l.6.6L9 7.2a.6.6 0 1 1 .8.8L8 9.8a.6.6 0 0 1-.8 0l-1-1a.6.6 0 0 1 .6-1Z"
				/>
			</svg>
		)}
	/>
);

export const DeploymentProdFailed = ({ size, label }: Props) => (
	<Icon
		size={size}
		label={label}
		glyph={() => (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 16 16"
				width="16"
				height="16"
				fill="none"
			>
				<path fill={token('color.icon.inverse')} d="M0 0h16v16H0z" fillOpacity=".01" />
				<path
					fill={token('color.icon.danger')}
					d="M3.8 12.88a3.15 3.15 0 0 1-.53-6.15 4.9 4.9 0 0 1 9.46 0 3.15 3.15 0 0 1-.53 6.15v.02H3.8v-.02Zm3.55-6.63a.75.75 0 0 1 1.5 0v2a.75.75 0 0 1-1.5 0v-2ZM8.1 9.5a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5Z"
					clipRule="evenodd"
					fillRule="evenodd"
				/>
			</svg>
		)}
	/>
);

export const DeploymentNonProdFailed = ({ size, label }: Props) => (
	<Icon
		size={size}
		label={label}
		glyph={() => (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 16 16"
				width="16"
				height="16"
				fill="none"
			>
				<path fill={token('color.icon.inverse')} d="M0 0h16v16H0z" fillOpacity="0" />
				<mask id="a" fill={token('color.icon.inverse')}>
					<path
						fill={token('color.icon.danger')}
						d="M3.8 12.9a3.2 3.2 0 0 1-.5-6.2 4.9 4.9 0 0 1 9.4 0 3.2 3.2 0 0 1-.5 6.2H3.8Z"
						clipRule="evenodd"
						fillRule="evenodd"
					/>
				</mask>
				<path
					fill={token('color.icon.danger')}
					d="M3.8 12.9h1.5v-1.4L4 11.4l-.2 1.5Zm-.5-6.2.4 1.5.8-.3.2-.8-1.4-.4Zm9.4 0-1.4.4.2.8.8.3.4-1.5Zm-.5 6.2-.2-1.5-1.3.1V13h1.5Zm0 0v1.5h1.5v-1.5h-1.5Zm-8.4 0H2.3v1.5h1.5v-1.5Zm.2-1.5c-.9-.1-1.5-.8-1.5-1.7h-3c0 2.4 1.8 4.4 4.1 4.7l.4-3ZM2.5 9.7c0-.7.5-1.3 1.2-1.5l-.9-3C1 5.9-.5 7.7-.5 9.8h3Zm2.2-2.6A3.4 3.4 0 0 1 8 4.6v-3c-3 0-5.4 2-6.2 4.7l3 .8ZM8 4.6c1.6 0 2.9 1 3.3 2.5l2.9-.8A6.4 6.4 0 0 0 8 1.6v3Zm4.3 3.6c.7.2 1.2.8 1.2 1.5h3c0-2-1.4-3.9-3.3-4.4l-.9 2.9Zm1.2 1.5c0 .9-.6 1.6-1.5 1.7l.4 3a4.7 4.7 0 0 0 4.1-4.7h-3ZM10.7 13h3-3Zm1.5-1.5H3.8v3h8.4v-3Zm-6.9 1.5h-3 3Z"
					mask="url(#a)"
				/>
				<rect width="1.5" height="3.5" x="7.4" y="5.5" fill={token('color.icon.danger')} rx=".8" />
				<rect width="1.5" height="1.5" x="7.4" y="9.5" fill={token('color.icon.danger')} rx=".8" />
			</svg>
		)}
	/>
);
