import React, { type MutableRefObject } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import type { EditorActions, EditorProps } from '@atlaskit/editor-core';
import type { MentionProvider } from '@atlaskit/mention/resource';
import type { TaskDecisionProvider } from '@atlaskit/task-decision';
import type { CardPlugin } from '@atlaskit/editor-plugin-card';
import type { PublicPluginAPI } from '@atlaskit/editor-common/types';
import type { ActivityProvider } from '@atlassian/activity-provider';
import type { MediaContext } from '@atlassian/jira-issue-view-common-types/src/media-context-type.tsx';
import {
	IssueEditorReadonly,
	IssueEditorBoundary,
} from '@atlassian/jira-issue-view-common/src/component/editor/async.tsx';
import type IssueEditorType from '@atlassian/jira-issue-view-common/src/component/editor/index.tsx';
import type { ContextIdentifier } from '@atlassian/jira-rich-content/src/index.tsx';
import type { BaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import { useCanDownloadAttachments } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';

type Props = {
	isDisabled: boolean;
	isInvalid: boolean;
	isExpanded: boolean;
	shouldFocus: boolean;
	value: ADF | string;
	externalId: string;
	baseUrl: BaseUrl;
	mentionProvider: MentionProvider | null;
	activityProvider: ActivityProvider | null;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	emojiProvider: Promise<any> | null;
	taskDecisionProvider?: TaskDecisionProvider;
	editorActionsRef?: MutableRefObject<EditorActions | null>;
	editorApiRef?: MutableRefObject<PublicPluginAPI<[CardPlugin]> | null>;
	setEditorApi?: React.Dispatch<React.SetStateAction<PublicPluginAPI<[CardPlugin]> | undefined>>;
	contentComponents?: EditorProps['contentComponents'];
	mediaContext: MediaContext;
	contextIdentifier?: ContextIdentifier;
	// eslint-disable-next-line jira/react/handler-naming
	mentionEncoder?: (arg1: string) => string;
	portalElement?: HTMLElement;
	onCancel?: () => void;
	onBlur: () => void;
	onSave: (() => void) | undefined;
	onChange: (arg1: ADF | string) => void;
	onSaveFailure: () => void;
	onChangeFailure: () => void;
	onPaste: (arg1: string) => void;
	onViewRefresh?: () => void;
	onUploadRefresh?: () => void;
	onEditorReady?: (arg1: ADF) => void;
	assistiveLabel?: string;
};

// TODO reevaluate phase - forPaint is simply for initial parity
export const IssueEditor = lazyForPaint<typeof IssueEditorType>(
	() =>
		import(
			/* webpackChunkName: "async-editor-view" */ '@atlassian/jira-issue-view-common/src/component/editor'
		),
);

export default function RichTextFieldView(props: Props) {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const canDownloadMedia = useCanDownloadAttachments(projectKey);

	const disableMediaDownload =
		!canDownloadMedia && fg('block_attachment_download_on_data_export_dsp_rule');

	const fallback = (
		<IssueEditorReadonly
			mediaContext={props.mediaContext}
			defaultValue={props.value}
			onViewRefresh={props.onViewRefresh}
		/>
	);

	return (
		<EditorContainer
			// eslint-disable-next-line jira/integration/test-id-by-folder-structure
			data-testid="issue.views.field.rich-text.editor-container"
		>
			<IssueEditorBoundary packageName="issue" fallback={fallback}>
				<IssueEditor
					portalElement={props.portalElement}
					mediaContext={props.mediaContext}
					onViewRefresh={props.onViewRefresh}
					onUploadRefresh={props.onUploadRefresh}
					mentionProvider={props.mentionProvider}
					contextIdentifier={props.contextIdentifier}
					mentionEncoder={props.mentionEncoder}
					activityProvider={props.activityProvider}
					emojiProvider={props.emojiProvider}
					taskDecisionProvider={props.taskDecisionProvider}
					externalId={props.externalId}
					defaultValue={props.value}
					onCancel={props.onCancel}
					onBlur={props.onBlur}
					onSave={props.onSave}
					onChange={props.onChange}
					isDisabled={props.isDisabled}
					isInvalid={props.isInvalid}
					isExpanded={props.isExpanded}
					actionsRef={props.editorActionsRef}
					editorApiRef={props.editorApiRef}
					setEditorMentionsAPI={props.setEditorApi}
					shouldFocus={props.shouldFocus}
					onSaveFailure={props.onSaveFailure}
					onChangeFailure={props.onChangeFailure}
					onPaste={props.onPaste}
					onEditorReady={props.onEditorReady}
					useStickyToolbar
					contentComponents={props.contentComponents}
					disableMediaDownload={disableMediaDownload}
					{...(props.assistiveLabel && {
						assistiveLabel: props.assistiveLabel,
					})}
				/>
			</IssueEditorBoundary>
		</EditorContainer>
	);
}

RichTextFieldView.defaultProps = {
	isDisabled: false,
	isInvalid: false,
	isExpanded: false,
	shouldFocus: false,
	value: null,
	mediaContext: null,
	mentionEncoder: null,
	emojiProvider: null,
	onPaste: noop,
};

RichTextFieldView.displayName = 'RichTextFieldView';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditorContainer = styled.div({
	width: '100%',
});
