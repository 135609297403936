/**
 * @generated SignedSource<<d27d84d6a6762ed03f32716a3ba625d3>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type CommandPaletteAssignee_me_user$data = {
  readonly id: string;
  readonly name: string;
  readonly picture: AGG$URL;
  readonly " $fragmentType": "CommandPaletteAssignee_me_user";
};
export type CommandPaletteAssignee_me_user$key = {
  readonly " $data"?: CommandPaletteAssignee_me_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommandPaletteAssignee_me_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "CommandPaletteAssignee_me_user",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "picture"
    }
  ],
  "type": "User",
  "abstractKey": "__isUser"
};

(node as any).hash = "59d50a39b54eff41ce2a45b0e82423a5";

export default node;
