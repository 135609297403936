import React, { useCallback, useMemo } from 'react';
import { useFragment, graphql } from 'react-relay';
import { Box } from '@atlaskit/primitives';
import inlineEditMessages from '@atlassian/jira-common-components-inline-edit/src/messages.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import {
	WrappedTimeTrackingView,
	extractedInvalidTimeFormatMessage,
} from '@atlassian/jira-issue-view-base/src/context/time-tracking/index.tsx';
import messages from '@atlassian/jira-issue-view-base/src/context/time-tracking/messages.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { ADD_MODAL } from '@atlassian/jira-issue-view-common-types/src/worklog-type.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import { useIsMobile } from '@atlassian/jira-issue-view-layout-mobile/src/is-mobile/index.tsx';
import { IssueViewTimeTrackingController } from '@atlassian/jira-issue-view-layout-time-tracking-utils/src/ui/index.tsx';
import { openModal } from '@atlassian/jira-issue-view-store/src/common/actions/worklog-actions.tsx';
import { permissionsSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/permissions-selector.tsx';
import { useDispatch, useStore } from '@atlassian/jira-react-redux/src/index.tsx';
import type { ui_issueViewLayoutTimeTrackingField_IssueViewTimeTrackingField$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutTimeTrackingField_IssueViewTimeTrackingField.graphql';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';

export interface IssueViewTimeTrackingFieldProps {
	area?: Area;
	fragmentKey: ui_issueViewLayoutTimeTrackingField_IssueViewTimeTrackingField$key;
}

export const IssueViewTimeTrackingField = (props: IssueViewTimeTrackingFieldProps) => {
	const data = useFragment<ui_issueViewLayoutTimeTrackingField_IssueViewTimeTrackingField$key>(
		graphql`
			fragment ui_issueViewLayoutTimeTrackingField_IssueViewTimeTrackingField on JiraTimeTrackingField {
				...ui_issueViewLayoutTimeTrackingUtils_IssueViewTimeTrackingController

				name
				fieldId

				issue @required(action: NONE) {
					key @required(action: NONE)
					issueId
				}

				timeTrackingSettings {
					isJiraConfiguredTimeTrackingEnabled
				}
			}
		`,
		props.fragmentKey,
	);

	const intl = useIntl();
	const isMobile = useIsMobile();
	const formatMessage = intl.formatMessage;
	const store = useStore<State>();
	const dispatch = useDispatch();

	const canLogTime = useMemo(
		() =>
			Boolean(data?.timeTrackingSettings?.isJiraConfiguredTimeTrackingEnabled) &&
			// TODO [RELAY]: Check permissions.canLogWork: https://hello.jira.atlassian.cloud/browse/RELAY-78
			permissionsSelector(store.getState()).canLogWork &&
			!isMobile,
		[data?.timeTrackingSettings?.isJiraConfiguredTimeTrackingEnabled, isMobile, store],
	);

	const onEditRequest = useCallback(() => {
		dispatch(openModal(ADD_MODAL, null));
	}, [dispatch]);

	if (!data) {
		return null;
	}

	const area = props.area || 'context';
	const label = data.name;

	return (
		<UFOSegment name="issue-field-time-tracking">
			<JSErrorBoundary id="issue.issue-view.context.time-tracking-field">
				{/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */}
				<Box testId="issue-field-time-tracking-readview-full.ui.time-tracking.wrapper">
					<IssueViewTimeTrackingController fragmentKey={data}>
						{({
							config,
							isClassicProject,
							isDone,
							shouldDisplayRollUpDataControl,
							getTimeTrackingValue,
						}) => (
							<WrappedTimeTrackingView
								area={area}
								intl={intl}
								formatMessage={formatMessage}
								fieldId={data.fieldId}
								timeTrackingCalculationSelector={getTimeTrackingValue}
								config={config}
								issueKey={data.issue.key}
								issueId={data.issue?.issueId}
								isDone={isDone}
								isEditable={canLogTime}
								isMobile={isMobile}
								isVisible={data.timeTrackingSettings?.isJiraConfiguredTimeTrackingEnabled ?? false}
								label={label}
								editButtonLabel={formatMessage(inlineEditMessages.editButtonLabel, {
									fieldName: label,
								})}
								confirmButtonLabel={formatMessage(inlineEditMessages.confirmButtonLabel, {
									fieldName: label,
								})}
								cancelButtonLabel={formatMessage(inlineEditMessages.cancelButtonLabel, {
									fieldName: label,
								})}
								timeRemainingFieldLabel={formatMessage(messages.timeRemainingFieldLabel)}
								rollupLabel={
									isClassicProject
										? formatMessage(messages.includeSubtasksLabel)
										: formatMessage(messages.includeChildrenLabel)
								}
								showPinButton={getShowPinButton(area)}
								shouldDisplayRollUpDataControl={shouldDisplayRollUpDataControl}
								placeholderMessage="2w 4d 6h 45m"
								invalidTimeFormatMessage={extractedInvalidTimeFormatMessage}
								onEditRequest={onEditRequest}
							/>
						)}
					</IssueViewTimeTrackingController>
				</Box>
			</JSErrorBoundary>
		</UFOSegment>
	);
};
