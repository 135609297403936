import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { DateWithUrgencyIndicator } from '@atlassian/jira-date-with-urgency-indicator/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useDateTimeFormatter } from '@atlassian/jira-issue-format-date/src/main.tsx';
import type { JiraDatePickerValue, FormatDateFn } from '../../common/types.tsx';
import messages from './messages.tsx';

export type DateFieldReadViewProps = {
	value: JiraDatePickerValue;
	noValueText: string | null | undefined;
	onFormatDate?: FormatDateFn;
	showUrgencyIndicator?: boolean;
};

const DateFieldReadViewDefault = ({ value, noValueText, onFormatDate }: DateFieldReadViewProps) => {
	const { formatMessage } = useIntl();
	const { formatDateOnly } = useDateTimeFormatter();

	if (value == null || value === '') {
		return <EmptyValue>{noValueText ?? formatMessage(messages.defaultNoValueText)}</EmptyValue>;
	}

	if (onFormatDate) {
		return <>{onFormatDate(value)}</>;
	}

	return <>{formatDateOnly(value)}</>;
};

export const DateFieldReadView = ({
	showUrgencyIndicator,
	value,
	...otherProps
}: DateFieldReadViewProps) => {
	if (showUrgencyIndicator === true && value) {
		const formattedDate = otherProps.onFormatDate ? otherProps.onFormatDate(value) : undefined;
		return <DateWithUrgencyIndicator value={value} formattedDateString={formattedDate} />;
	}
	return <DateFieldReadViewDefault value={value} {...otherProps} />;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const EmptyValue = styled.span({
	color: token('color.text.subtlest'),
});
