import React, { memo } from 'react';

import { fg } from '@atlassian/jira-feature-gating';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import type { MainIssueAggQueryRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { PREMIUM_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useIssueKey, useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import { PlaybooksPanel } from '@atlassian/jira-playbooks-agent-view/src/ui/playbook-panel/PlaybooksPanel.tsx';

import { ViewPlaybooks } from './src/ui/ViewPlaybooks.tsx';

const PlaybooksContainer = ({
	rootRelayFragment,
}: {
	rootRelayFragment: MainIssueAggQueryRelayFragment | null;
}) => {
	const issueKey = useIssueKey();
	const issueId = useIssueId();
	const cloudId = useCloudId();
	const projectKey = useProjectKey(issueKey);
	const appEditions = useAppEditions();
	// Playbooks are only available in Jira Service Management Premium plan

	if (rootRelayFragment == null || issueId == null || appEditions.serviceDesk !== PREMIUM_EDITION) {
		return null;
	}

	if (fg('playbook_in_jsm_ga')) {
		return (
			<JSErrorBoundary
				id="PlaybooksContainerWithViewPanel"
				packageName="jiraIssueViewBase"
				fallback="unmount"
			>
				<ViewPlaybooks
					issueId={issueId}
					cloudId={cloudId}
					projectKey={projectKey}
					rootRelayFragment={rootRelayFragment}
				/>
			</JSErrorBoundary>
		);
	}

	return (
		<JSErrorBoundary id="PlaybooksContainer" packageName="jiraIssueViewBase" fallback="unmount">
			<PlaybooksPanel issueId={issueId} cloudId={cloudId} projectKey={projectKey} />
		</JSErrorBoundary>
	);
};

export default memo(PlaybooksContainer);
