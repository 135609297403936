/**
 * @generated SignedSource<<8fc054c14d6bddea696dededf37d80ed>>
 * @relayHash f4c7db359db24a283c738bcd80ff62d1
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 0881cf501af0bbe13bb67be56841a880890dc42b5d656d4e5b524a7a8606f76f

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraSingleValueFieldOperations = "SET" | "%future added value";
export type JiraUpdateCascadingSelectFieldInput = {
  id: string;
  operation: JiraCascadingSelectFieldOperationInput;
};
export type JiraCascadingSelectFieldOperationInput = {
  childOption?: string | null | undefined;
  operation: JiraSingleValueFieldOperations;
  parentOption?: string | null | undefined;
};
export type cascadingSelect_issueFieldCascadingSelect_CascadingSelectField_Mutation$variables = {
  input: JiraUpdateCascadingSelectFieldInput;
};
export type cascadingSelect_issueFieldCascadingSelect_CascadingSelectField_Mutation$data = {
  readonly jira: {
    readonly updateCascadingSelectField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly " $fragmentSpreads": FragmentRefs<"cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditViewOld_fragmentRef">;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type cascadingSelect_issueFieldCascadingSelect_CascadingSelectField_Mutation$rawResponse = {
  readonly jira: {
    readonly updateCascadingSelectField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly cascadingOption: {
          readonly childOptionValue: {
            readonly id: string;
            readonly isDisabled: boolean | null | undefined;
            readonly value: string | null | undefined;
          } | null | undefined;
          readonly parentOptionValue: {
            readonly id: string;
            readonly isDisabled: boolean | null | undefined;
            readonly value: string | null | undefined;
          } | null | undefined;
        } | null | undefined;
        readonly fieldConfig: {
          readonly isEditable: boolean | null | undefined;
        } | null | undefined;
        readonly id: string;
        readonly name: string;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type cascadingSelect_issueFieldCascadingSelect_CascadingSelectField_Mutation = {
  rawResponse: cascadingSelect_issueFieldCascadingSelect_CascadingSelectField_Mutation$rawResponse;
  response: cascadingSelect_issueFieldCascadingSelect_CascadingSelectField_Mutation$data;
  variables: cascadingSelect_issueFieldCascadingSelect_CascadingSelectField_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = [
  {
    "kind": "ScalarField",
    "name": "value"
  },
  (v4/*: any*/),
  {
    "kind": "ScalarField",
    "name": "isDisabled"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "cascadingSelect_issueFieldCascadingSelect_CascadingSelectField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraCascadingSelectFieldPayload",
            "kind": "LinkedField",
            "name": "updateCascadingSelectField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraCascadingSelectField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditViewOld_fragmentRef"
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "cascadingSelect_issueFieldCascadingSelect_CascadingSelectField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraCascadingSelectFieldPayload",
            "kind": "LinkedField",
            "name": "updateCascadingSelectField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraCascadingSelectField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "name"
                  },
                  {
                    "concreteType": "JiraCascadingOption",
                    "kind": "LinkedField",
                    "name": "cascadingOption",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraOption",
                        "kind": "LinkedField",
                        "name": "parentOptionValue",
                        "plural": false,
                        "selections": (v5/*: any*/)
                      },
                      {
                        "concreteType": "JiraOption",
                        "kind": "LinkedField",
                        "name": "childOptionValue",
                        "plural": false,
                        "selections": (v5/*: any*/)
                      }
                    ]
                  },
                  {
                    "concreteType": "JiraFieldConfig",
                    "kind": "LinkedField",
                    "name": "fieldConfig",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "isEditable"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "0881cf501af0bbe13bb67be56841a880890dc42b5d656d4e5b524a7a8606f76f",
    "metadata": {},
    "name": "cascadingSelect_issueFieldCascadingSelect_CascadingSelectField_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "d2aa8009e389257d3576f281dee876bf";

export default node;
