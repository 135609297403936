import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@compiled/react';
import isEmpty from 'lodash/isEmpty';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import {
	useAnalyticsEvents,
	ContextualAnalyticsData,
} from '@atlassian/jira-product-analytics-bridge';
import { useAIAnalyticsContext } from '@atlassian/jira-instrumentation-common/src/ai-analytics-context/context.tsx';
import { AI_EVENTS } from '@atlassian/jira-instrumentation-common/src/ai-analytics-context/common.tsx';
import { AiFooter } from '@atlassian/jira-atlassian-intelligence/src/common/ui/ai-footer/index.tsx';
import { useIsAiEnabledForIssue } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useRelatedIssuesForIssue } from '../services/context.tsx';
import { useQueryText } from '../services/use-query-text/index.tsx';
import { getTopFiveDistances, getTopFiveCrossEncoderScores } from '../services/utils.tsx';
import { RelatedIssuesFeedbackSentiment } from './feedback-sentiment/index.tsx';
import PanelHeader from './header/index.tsx';
import IssueLineCardGroup from './issue-line-card-group/index.tsx';
import StatusTabs from './status-tabs/index.tsx';

export type Props = {
	sessionId: string;
};

type AIEventsFired = Record<'init', boolean>;

export const RelatedIssuesPanelView = ({ sessionId }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { fireTrackAI, addTraceId } = useAIAnalyticsContext();
	const [isExpanded, setExpanded] = useState(false);
	const [hasClickedSentiment, setHasClickedSentiment] = useState(false);
	const queryText = useQueryText();
	const aiEventsFiredRef = useRef<AIEventsFired>({ init: false });
	const isAiOptInEnabled = useIsAiEnabledForIssue();

	const [{ data, status, error, isLoading, traceIds }, { fetchRelatedIssuesForIssue }] =
		useRelatedIssuesForIssue();
	useEffect(() => {
		if (isExpanded) {
			// User can expand the panel multiple times, so we only want to fire the init event once while data is still loading.
			if (!aiEventsFiredRef.current.init) {
				fireTrackAI(AI_EVENTS.INIT);
				aiEventsFiredRef.current.init = true;
			}
			if (!error) {
				fetchRelatedIssuesForIssue(sessionId, createAnalyticsEvent, queryText);
			}
		}
	}, [
		createAnalyticsEvent,
		fetchRelatedIssuesForIssue,
		isExpanded,
		sessionId,
		queryText,
		fireTrackAI,
		data,
		isLoading,
		error,
	]);

	useEffect(() => {
		if (traceIds) {
			traceIds.forEach(addTraceId);
		}
	}, [traceIds, addTraceId]);

	useEffect(() => {
		if (error) {
			fireTrackAI(AI_EVENTS.ERROR, {
				aiErrorCode: error.statusCode,
				aiErrorMessage: error.message,
			});
		}
	}, [error, fireTrackAI]);

	useEffect(() => {
		if (!isExpanded && isLoading) {
			fireTrackAI(AI_EVENTS.DISMISSED);
		}
	}, [isExpanded, isLoading, fireTrackAI]);

	useEffect(() => {
		if (isExpanded && !isEmpty(data)) {
			fireTrackAI(AI_EVENTS.VIEWED, {
				relatedIssuesStatus: status,
				relatedIssuesCount: data[status]?.length ?? 0,
			});
		}
	}, [status, data, isExpanded, fireTrackAI]);

	const renderPanelBody = () => (
		<>
			<StatusTabs sessionId={sessionId} />
			<FieldsContainer data-testid="smart-related-issues-panel.ui.fields-container">
				<IssueLineCardGroup sessionId={sessionId} />
			</FieldsContainer>
			<Box xcss={sentimentContainerStyles}>
				<RelatedIssuesFeedbackSentiment
					sessionId={sessionId}
					hasClickedSentiment={hasClickedSentiment}
					setHasClickedSentiment={setHasClickedSentiment}
					openIssuesDistance={getTopFiveDistances(data.OPEN)}
					resolvedIssuesDistance={getTopFiveDistances(data.RESOLVED)}
					openIssuesCrossEncoderScore={getTopFiveCrossEncoderScores(data.OPEN)}
					resolvedIssuesCrossEncoderScore={getTopFiveCrossEncoderScores(data.RESOLVED)}
				/>
			</Box>
			{isAiOptInEnabled && fg('agent_ai_enable_similar_issues_improvements') && (
				<Box xcss={aiFooterContainerStyles}>
					<AiFooter testId="smart-related-issues-panel.ui.ai-footer" isBeta={false} />
				</Box>
			)}
		</>
	);

	const renderRelatedIssues = () => (
		<>
			<PanelHeader
				sessionId={sessionId}
				onExpand={() => setExpanded(true)}
				onCollapse={() => setExpanded(false)}
				isExpanded={isExpanded}
			/>
			{isExpanded && <PanelBody>{renderPanelBody()}</PanelBody>}
		</>
	);

	return (
		<ContextualAnalyticsData
			attributes={{
				selectedStatusFilter: status,
			}}
		>
			<RelatedIssuesContainer isExpanded={isExpanded}>
				{renderRelatedIssues()}
			</RelatedIssuesContainer>
		</ContextualAnalyticsData>
	);
};

export default RelatedIssuesPanelView;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RelatedIssuesContainer = styled.div<{
	isExpanded: boolean;
	hasContentItems?: boolean;
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
}>(({ isExpanded, hasContentItems }) => isExpanded && hasContentItems && 'padding-bottom: 0');

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PanelBody = styled.div({
	marginBottom: token('space.300'),
	display: 'flex',
	flexDirection: 'column',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderBottomLeftRadius: `${gridSize / 2}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderBottomRightRadius: `${gridSize / 2}px`,

	borderBottom: `1px solid ${token('color.border')}`,

	borderLeft: `1px solid ${token('color.border')}`,

	borderRight: `1px solid ${token('color.border')}`,

	backgroundColor: token('elevation.surface'),
	paddingTop: token('space.100'),
	paddingRight: token('space.100'),
	paddingBottom: token('space.100'),
	paddingLeft: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldsContainer = styled.div({
	paddingTop: token('space.200'),
});

const sentimentContainerStyles = xcss({
	marginTop: 'space.200',
});

const aiFooterContainerStyles = xcss({
	marginTop: 'space.200',
	marginBottom: 'space.negative.100',
	marginInline: 'space.negative.100',
});
