import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';

export const extractAttributesFromContext = (
	context: UIAnalyticsEvent['context'],
): Record<string, unknown> => {
	try {
		let attributes = {};
		Object.values(context).forEach((value) => {
			if (typeof value === 'object' && 'attributes' in value) {
				attributes = {
					...attributes,
					...value.attributes,
				};
			}
		});
		return attributes;
	} catch (error) {
		return {};
	}
};
