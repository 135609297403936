import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import Lozenge from '@atlaskit/lozenge';
import { token } from '@atlaskit/tokens';
import { expVal } from '@atlassian/jira-feature-experiments';
import { type IntlShape, useIntl } from '@atlassian/jira-intl';
import TimestampView from '@atlassian/jira-issue-timestamp/src/main.tsx';
import {
	ActivityTimestampStyles,
	Emphasise,
} from '@atlassian/jira-issue-view-activity-common/src/styles/index.tsx';
import WithProfileCardNext from '@atlassian/jira-issue-view-internal-profilecard-next/src/index.tsx';
import { useIssueHistoryUi } from '../../../state/context.tsx';
import type { User } from '../../../types.tsx';
import messages from './messages.tsx';

type Props = {
	actor: User | null;
	action: ReactNode;
	timestamp: Date;
	change: ReactNode;
	testId?: string;
};

const renderActorNameWithProfileCard = (actor: User | null, intl: IntlShape, testId?: string) => {
	if (actor === null) {
		return intl.formatMessage(messages.anonymousUser);
	}
	return (
		<Wrapper>
			<WithProfileCardNext
				userAccount={actor}
				analyticsData={{ trigger: 'history-item-actor-name' }}
				testId={`${testId}--name-trigger-container`}
			>
				<Emphasise>{actor?.displayName}</Emphasise>
			</WithProfileCardNext>
		</Wrapper>
	);
};

const HistoryItem = ({ action, change, actor, timestamp, testId }: Props) => {
	const intl = useIntl();
	const [{ showLozenge }] = useIssueHistoryUi();

	const HistoryItemComponentOld = (
		<ContainerOld data-testid={testId || 'history-item-without-test-id'}>
			<WithProfileCardNext
				userAccount={actor}
				analyticsData={{ trigger: 'history-item-actor-avatar' }}
				testId={`${testId}--avatar-trigger-container`}
				ariaHideProfileTrigger
			>
				<AvatarContainerOld>
					<Avatar size="medium" src={actor ? actor.avatarUrl : undefined} />
				</AvatarContainerOld>
			</WithProfileCardNext>
			<ContentOld>
				<div>
					{renderActorNameWithProfileCard(actor, intl, testId)} {action}
					<TimestampContainer>
						<TimestampView
							value={timestamp.getTime()}
							extraStyles={ActivityTimestampStyles}
							tooltipPosition="top"
						/>
					</TimestampContainer>
					{showLozenge && (
						<LozengeWrapper>
							<Lozenge testId="issue-history.common.ui.history-item.lozenge">
								{intl.formatMessage(messages.lozenge)}
							</Lozenge>
						</LozengeWrapper>
					)}
				</div>
				{change}
			</ContentOld>
		</ContainerOld>
	);

	const HistoryItemComponent = (
		<Container data-testid={testId || 'history-item-without-test-id'}>
			<WithProfileCardNext
				userAccount={actor}
				analyticsData={{ trigger: 'history-item-actor-avatar' }}
				testId={`${testId}--avatar-trigger-container`}
				ariaHideProfileTrigger
			>
				<AvatarContainer>
					<Avatar size="small" src={actor ? actor.avatarUrl : undefined} />
				</AvatarContainer>
			</WithProfileCardNext>
			<Content>
				<div>
					{renderActorNameWithProfileCard(actor, intl, testId)} {action}
					<TimestampContainer>
						<TimestampView
							value={timestamp.getTime()}
							extraStyles={ActivityTimestampStyles}
							tooltipPosition="top"
						/>
					</TimestampContainer>
				</div>
				{change}
			</Content>
		</Container>
	);

	return expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false)
		? HistoryItemComponent
		: HistoryItemComponentOld;
};

export default HistoryItem;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'inline-flex',
});

const ContainerOld =
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
	styled.div({
		marginTop: token('space.100'),
		marginRight: 0,
		marginBottom: token('space.400'),
		marginLeft: 0,
		display: 'flex',
	});

const Container =
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
	styled.div({
		marginTop: token('space.100'),
		marginRight: 0,
		marginBottom: token('space.200'),
		marginLeft: 0,
		display: 'flex',
	});

Container.displayName = 'Container';

const AvatarContainerOld =
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
	styled.div({
		height: '36px', // Required because AkAvatar is styled as inline-block
		marginRight: token('space.100'),
		// Required because JiraIcon isn't the same width as AkAvatar
		width: '36px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	});

const AvatarContainer =
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
	styled.div({
		height: '28px', // Required because AkAvatar is styled as inline-block
		marginRight: token('space.100'),
		// Required because JiraIcon isn't the same width as AkAvatar
		width: '28px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	});

AvatarContainer.displayName = 'Avatar';

const ContentOld =
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
	styled.div({
		flexGrow: 1,
		alignSelf: 'center',
		/*
			Might be able to remove when we use Fabric Renderer
			Need to give width to prevent overflow (BENTO-1779)
			95% so it's not too close to edge (esp in single column layout)
			30px arbitrary width to accomodate the arrow
		*/
		width: 'calc(95% - 30px)',
	});

const Content =
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
	styled.div({
		flexGrow: 1,
		alignSelf: 'center',
		/*
            Might be able to remove when we use Fabric Renderer
            Need to give width to prevent overflow (BENTO-1779)
            95% so it's not too close to edge (esp in single column layout)
            30px arbitrary width to accomodate the arrow
        */
		width: 'calc(95% - 30px)',
		paddingTop: token('space.050'),
	});

Content.displayName = 'Content';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TimestampContainer = styled.span({
	marginLeft: token('space.100'),
});

TimestampContainer.displayName = 'TimestampContainer';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LozengeWrapper = styled.div({
	display: 'inline-flex',
	marginLeft: token('space.150'),
});
