import React from 'react';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import CalendarIcon from '@atlaskit/icon/core/migration/calendar';
import ClockIcon from '@atlaskit/icon/core/clock';
import WarningOutlineIcon from '@atlaskit/icon-lab/core/warning-outline';
import Tooltip from '@atlaskit/tooltip';
import { token } from '@atlaskit/tokens';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';

const DISPLAY_FORMAT_OPTIONS = {
	day: 'numeric',
	month: 'short',
	year: 'numeric',
} as const;

const TOOLTIP_FORMAT_OPTIONS = {
	day: 'numeric',
	month: 'short',
	year: 'numeric',
	timeZone: 'UTC',
	timeZoneName: 'short',
} as const;

type ColoredDueDateProps = {
	dateString: string;
	pastDue: boolean;
	dueToday: boolean;
	warningIconTestId: string;
	clockIconTestId: string;
	calendarIconTestId: string;
};

export const ColoredDueDate = ({
	dateString,
	pastDue,
	dueToday,
	warningIconTestId,
	clockIconTestId,
	calendarIconTestId,
}: ColoredDueDateProps) => {
	const { formatDate } = useIntl();
	const displayDate = formatDate(dateString, DISPLAY_FORMAT_OPTIONS);
	const tooltipDate = formatDate(dateString, TOOLTIP_FORMAT_OPTIONS);

	const dueMessage = pastDue ? (
		<FormattedMessage {...messages.overdue} values={{ date: tooltipDate.replace(',', '') }} />
	) : (
		<FormattedMessage {...messages.due} values={{ date: tooltipDate.replace(',', '') }} />
	);

	return (
		<Flex
			xcss={[
				styles.dateTag,
				pastDue ? styles.overdue : undefined,
				dueToday ? styles.dueToday : undefined,
				!pastDue && !dueToday ? styles.normalDate : undefined,
			]}
		>
			{pastDue && (
				<Tooltip content={dueMessage} position="top">
					<Box xcss={styles.dueDateIcon}>
						<WarningOutlineIcon
							LEGACY_size="medium"
							label=""
							color={token('color.icon.danger')}
							testId={warningIconTestId}
						/>
					</Box>
				</Tooltip>
			)}
			{dueToday && (
				<Tooltip content={dueMessage} position="top">
					<Box xcss={styles.dueDateIcon}>
						<ClockIcon
							LEGACY_size="medium"
							label=""
							color={token('color.icon.warning')}
							testId={clockIconTestId}
						/>
					</Box>
				</Tooltip>
			)}
			{!dueToday && !pastDue && (
				<Tooltip content={dueMessage} position="top">
					<Box xcss={styles.dueDateIcon}>
						<CalendarIcon
							label=""
							color={token('color.icon.subtle')}
							LEGACY_size="medium"
							testId={calendarIconTestId}
						/>
					</Box>
				</Tooltip>
			)}
			{displayDate.replace(',', '')}
		</Flex>
	);
};

const styles = {
	dateTag: xcss({
		paddingLeft: 'space.075',
		paddingRight: 'space.075',
		paddingTop: 'space.025',
		paddingBottom: 'space.025',
		borderStyle: 'solid',
		borderRadius: 'border.radius.100',
		borderWidth: 'border.width',
		color: 'color.text',
	}),
	overdue: xcss({
		color: 'color.text.danger',
		borderColor: 'color.border.danger',
	}),
	dueToday: xcss({
		color: 'color.text.warning',
		borderColor: 'color.border.warning',
	}),
	normalDate: xcss({
		color: 'color.text',
		borderColor: 'color.border',
	}),
	dueDateIcon: xcss({
		marginRight: 'space.050',
		display: 'flex',
		alignItems: 'center',
		height: '100%',
	}),
};
