import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createConfluencePageItem: {
		defaultMessage: 'Page',
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-page-menu-item.create-confluence-page-item',
		description: "The 'Page' option in the create confluence content menu",
	},
	createConfluencepageQuickActionsList: {
		defaultMessage: 'Create Confluence page',
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-page-menu-item.create-confluencepage-quick-actions-list',
		description:
			"The 'Page' option in the create confluence content menu with quick action list view enabled",
	},
	newConfluencePageQuickActionsList: {
		defaultMessage: 'New Confluence page',
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-page-menu-item.new-confluence-page-quick-actions-list',
		description:
			'Dropdown item to create a new confluence page that appears in the quick actions dropdown of issue view',
	},
	createPageQuickActionsNestedList: {
		defaultMessage: 'Create page',
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-page-menu-item.create-page-quick-actions-nested-list',
		description:
			'Dropdown item to create a new confluence page that appears in the quick actions dropdown of issue view in nested menu',
	},
	createPageQuickActionsNestedListSearch: {
		defaultMessage: 'Create page in confluence',
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-page-menu-item.create-page-quick-actions-nested-list-search',
		description:
			'Search text for Dropdown item to create a new confluence page that appears in the quick actions dropdown of issue view in nested menu',
	},
	confluencePageQuickActionsParent: {
		defaultMessage: 'Page',
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-page-menu-item.confluence-page-quick-actions-parent',
		description:
			'Dropdown parent for confluence page to be displayed for nested quick actions in the quick actions dropdown of issue view',
	},
	createConfluenceLiveDocItem: {
		defaultMessage: 'Live Doc',
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-page-menu-item.create-confluence-live-doc-item',
		description: "The 'Live Doc' option in the create confluence content menu",
	},
	createConfluenceLiveDocQuickActionsList: {
		defaultMessage: 'Create Confluence live doc',
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-page-menu-item.create-confluence-live-doc-quick-actions-list',
		description:
			"The 'live doc' option in the create confluence content menu with quick action list view enabled",
	},
	newConfluenceLiveDocQuickActionsList: {
		defaultMessage: 'New Confluence live doc',
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-page-menu-item.new-confluence-live-doc-quick-actions-list',
		description:
			'Dropdown item to create a new confluence live doc that appears in the quick actions dropdown of issue view',
	},
	createLiveDocQuickActionsNestedList: {
		defaultMessage: 'Create live doc',
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-page-menu-item.create-live-doc-quick-actions-nested-list',
		description:
			'Dropdown item to create a new confluence live doc that appears in the quick actions dropdown of issue view in nested menu',
	},
	createLiveDocQuickActionsNestedListSearch: {
		defaultMessage: 'Create live doc in confluence',
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-page-menu-item.create-live-doc-quick-actions-nested-list-search',
		description:
			'Search text for Dropdown item to create a new confluence live doc that appears in the quick actions dropdown of issue view in nested menu',
	},
	confluenceLiveDocQuickActionsParent: {
		defaultMessage: 'Live Doc',
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-page-menu-item.confluence-live-doc-quick-actions-parent',
		description:
			'Dropdown parent for confluence live doc to be displayed for nested quick actions in the quick actions dropdown of issue view',
	},
	confluenceLiveDocIcon: {
		defaultMessage: 'Live doc icon',
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-page-menu-item.confluence-live-doc-icon',
		description: 'Label for live doc icon',
	},
	confluencePageIcon: {
		defaultMessage: 'Page icon',
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-page-menu-item.confluence-page-icon',
		description: 'Label for page icon',
	},
});
