import { useCallback, useMemo } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { useIntl } from '@atlassian/jira-intl';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { VIEW_PROFILE_ACTION } from '@atlassian/jira-profilecard-next/src/common/constants.tsx';
import { createUserProfileCardClient } from '@atlassian/jira-profilecard-next/src/controllers/profileclient/index.tsx';
import { getSpaActions } from '@atlassian/jira-profilecard-next/src/utils/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useOrgId } from '@atlassian/jira-tenant-context-controller/src/components/org-id/index.tsx';
import { useFabricProfileDirectoryUrl } from '@atlassian/jira-tenant-context-controller/src/components/fabric-profile-directory-url/index.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import type { Actions } from '@atlassian/jira-profilecard-next/src/types.tsx';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { DEFAULT_PROFILE_DIR_URL, GATEWAY_GRAPHQL_URL } from './constants.tsx';
import type { ProfileCardProvider, Props } from './types.tsx';

const defaultActions: Actions[] = [VIEW_PROFILE_ACTION];

// TODO: Remove once profilecard-next's usage of baseUrl is also removed
const baseUrl = '';

const useProfileCardProviderWithoutOrgId = (
	props: Partial<Props> = {},
): Promise<ProfileCardProvider> => {
	const { onLoadError: providedOnLoadError, actions = defaultActions } = props;

	const cloudId = useCloudId();
	const fabricPfDirUrl = useFabricProfileDirectoryUrl();

	const intl = useIntl();
	const { push } = useRouterActions();

	const onLoadError = useCallback(
		(error: Error, analyticsEvent?: UIAnalyticsEvent) => {
			fireErrorAnalytics({
				meta: {
					id: 'loadProfileCardClient',
					packageName: 'jiraProfilecardProvider',
					teamName: 'bento',
				},
				error,
				// Skipping sentry since there is no follow-up action for these errors.
				skipSentry: true,
			});

			providedOnLoadError && providedOnLoadError(error, analyticsEvent);
		},
		[providedOnLoadError],
	);

	const profileClient = useMemo(
		() =>
			createUserProfileCardClient(
				fabricPfDirUrl || DEFAULT_PROFILE_DIR_URL,
				onLoadError,
				GATEWAY_GRAPHQL_URL,
				cloudId,
			),
		[fabricPfDirUrl, onLoadError, cloudId],
	);

	const getActions = useCallback(
		(id: string) => getSpaActions(id, baseUrl, intl, push, actions),
		[actions, intl, push],
	);

	const provider = useMemo(
		() =>
			Promise.resolve({
				cloudId,
				getActions,
				resourceClient: profileClient,
			}),
		[cloudId, getActions, profileClient],
	);

	return provider;
};

const useProfileCardProviderWithOrgId = (
	props: Partial<Props> = {},
): Promise<ProfileCardProvider> => {
	const { onLoadError: providedOnLoadError, actions = defaultActions } = props;

	const cloudId = useCloudId();
	const orgId = useOrgId();
	const fabricPfDirUrl = useFabricProfileDirectoryUrl();

	const intl = useIntl();
	const { push } = useRouterActions();

	const onLoadError = useCallback(
		(error: Error, analyticsEvent?: UIAnalyticsEvent) => {
			fireErrorAnalytics({
				meta: {
					id: 'loadProfileCardClient',
					packageName: 'jiraProfilecardProvider',
					teamName: 'bento',
				},
				error,
				// Skipping sentry since there is no follow-up action for these errors.
				skipSentry: true,
			});

			providedOnLoadError && providedOnLoadError(error, analyticsEvent);
		},
		[providedOnLoadError],
	);

	const profileClient = useMemo(
		() =>
			createUserProfileCardClient(
				fabricPfDirUrl || DEFAULT_PROFILE_DIR_URL,
				onLoadError,
				GATEWAY_GRAPHQL_URL,
				cloudId,
				orgId,
			),
		[fabricPfDirUrl, onLoadError, cloudId, orgId],
	);

	const getActions = useCallback(
		(id: string) => getSpaActions(id, baseUrl, intl, push, actions),
		[actions, intl, push],
	);

	const provider = useMemo(
		() =>
			Promise.resolve({
				cloudId,
				getActions,
				resourceClient: profileClient,
			}),
		[cloudId, getActions, profileClient],
	);

	return provider;
};

export const useProfileCardProvider = functionWithCondition(
	() => fg('issue_view_prevent_unnecessary_orgid_query'),
	useProfileCardProviderWithOrgId,
	useProfileCardProviderWithoutOrgId,
);
