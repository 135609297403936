import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import type { ViewAccessLevel } from '@atlassian/jira-polaris-domain-view/src/view-access/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import {
	isAccessForbiddenError,
	isNotFoundError,
} from '@atlassian/jira-polaris-lib-errors/src/controllers/utils.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { applyUpdateViewAccessLevel } from '../../../services/jpd-views-service/index.tsx';
import type { State } from '../../types.tsx';
import { loadViewPermissionsConfig } from '../load-view-permissions-config/index.tsx';

export const updateViewAccessLevel =
	({
		viewUUID,
		accessLevel,
		viewId,
		createAnalyticsEvent,
		onActionFailed,
	}: {
		viewUUID: string;
		accessLevel: ViewAccessLevel;
		viewId?: string;
		createAnalyticsEvent: CreateUIAnalyticsEvent;
		onActionFailed?: (err: Error) => void;
	}): Action<State> =>
	async ({ getState, setState, dispatch }) => {
		const oldAccessLevel = getState().accessLevel;

		setState({
			accessLevel,
		});

		try {
			experience.viewPermissions.updateViewAccess.start();

			await applyUpdateViewAccessLevel({ viewUUID, accessLevel });
			await dispatch(loadViewPermissionsConfig({ viewUUID, viewId, createAnalyticsEvent }));

			// https://data-portal.internal.atlassian.com/analytics/registry/69638
			fireTrackAnalytics(createAnalyticsEvent({}), 'viewAccessData accessLevelChanged', {
				viewUuid: viewUUID,
				viewId,
				from: oldAccessLevel,
				to: accessLevel,
			});

			sendPendoTrackEvent({
				actionSubjectAndAction: 'viewAccessData accessLevelChanged',
				actionSubjectId: 'viewAccessData',
				attributes: {
					viewUuid: viewUUID,
					viewId: viewId || '',
					from: oldAccessLevel,
					to: accessLevel,
				},
			});

			experience.viewPermissions.updateViewAccess.success();
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			if (isClientFetchError(error) || isAccessForbiddenError(error) || isNotFoundError(error)) {
				experience.viewPermissions.updateViewAccess.abort(error);
			} else {
				experience.viewPermissions.updateViewAccess.failure(error);
			}

			fireErrorAnalytics(createErrorAnalytics('polaris.view-access.update-access-level', error));

			setState({
				accessLevel: oldAccessLevel,
			});

			onActionFailed?.(error);
		}
	};
