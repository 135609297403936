import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const aiProactiveDescriptionCompleterEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-ai-proactive-description-completer" */ './src/index').then(
			(module) => module.AiProactiveDescriptionCompleterEntryPointComponent,
		),
	),
	getPreloadProps: () => ({}),
});
