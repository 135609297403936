import React, { useMemo } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import type { asyncRecurWorkFormEntryPoint } from '@atlassian/jira-recur-work-item/entrypoint.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import type { EntryPointReferenceSubject } from '@atlassian/jira-entry-point/src/controllers/utils/use-entry-point-load-manager/index.tsx';
import { Skeleton } from '@atlassian/jira-recur-work-item/src/common/ui/skeleton/index.tsx';
import { PACKAGE_NAME } from '../constants.tsx';

type Props = {
	entryPointRef: EntryPointReferenceSubject<typeof asyncRecurWorkFormEntryPoint>;
	issueKey: string;
	onBack: () => void;
	onClose: () => void;
};

export const RecurWorkContainer = ({ entryPointRef, issueKey, onBack, onClose }: Props) => {
	const runtimeProps = useMemo(
		() => ({
			issueKey,
			onCancel: onBack,
			onClose,
			hasBackButton: true,
			recurWorkFormEntryPoint: 'actionsMenu',
		}),
		[issueKey, onBack, onClose],
	);

	return (
		<Box xcss={containerStyles}>
			<JiraEntryPointContainer
				entryPointReferenceSubject={entryPointRef}
				id="issue-view-automation-menu-recur-work-containers"
				packageName={PACKAGE_NAME}
				fallback={<Skeleton />}
				runtimeProps={runtimeProps}
				errorFallback="flag"
			/>
		</Box>
	);
};

const containerStyles = xcss({
	// maxHeight = total height - top navigation height(56px) - top and bottom space (20px)
	maxHeight: 'calc(100vh - 76px)',
	overflow: 'auto',
	paddingBlock: 'space.300',
	paddingInline: 'space.250',
});
