import React from 'react';
import noop from 'lodash/noop';
import Button from '@atlaskit/button/standard-button';
import ShareIcon from '@atlaskit/icon/core/migration/share';
import { token } from '@atlaskit/tokens';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { useIntl } from '@atlassian/jira-intl';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import type {
	LegacyShareClientConfig,
	RenderCustomTriggerButton,
	CustomTriggerButtonProps,
} from './common/types.tsx';
import { messages } from './messages.tsx';
import type { Props } from './ui/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { LegacyShareClientConfig, RenderCustomTriggerButton, CustomTriggerButtonProps };

type LoaderProps = {
	triggerButtonStyle?: 'icon-only' | 'icon-with-text';
};

export const ShareLoadingPlaceholder = ({ triggerButtonStyle = 'icon-only' }: LoaderProps) => {
	const { formatMessage } = useIntl();

	return (
		<div>
			<Button
				appearance="subtle"
				iconBefore={
					<ShareIcon
						LEGACY_size="medium"
						spacing="spacious"
						label={formatMessage(messages.shareTriggerButtonText)}
						color={token('color.icon')}
					/>
				}
				isDisabled
			>
				{triggerButtonStyle === 'icon-with-text'
					? formatMessage(messages.shareTriggerButtonText)
					: null}
			</Button>
		</div>
	);
};

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const ShareButton = lazyAfterPaint(
	() => import(/* webpackChunkName: "async-share-button" */ './ui'),
	{ ssr: false },
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: Props) => (
	<ErrorBoundary id="async.share.button" packageName="share-button">
		<Placeholder
			name="share-button"
			fallback={
				props.renderCustomTriggerButton === undefined ? (
					<ShareLoadingPlaceholder triggerButtonStyle={props.triggerButtonStyle} />
				) : (
					<props.renderCustomTriggerButton isDisabled onClick={noop} />
				)
			}
		>
			<ShareButton {...props} />
		</Placeholder>
	</ErrorBoundary>
);

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Props as ShareButtonProps };
