/**
 * @generated SignedSource<<05ff16598aaed1a371df86cb608e27a5>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditViewOld_fragmentRef$data = {
  readonly cascadingOption: {
    readonly childOptionValue: {
      readonly id: string;
      readonly isDisabled: boolean | null | undefined;
      readonly value: string | null | undefined;
    } | null | undefined;
    readonly parentOptionValue: {
      readonly id: string;
      readonly isDisabled: boolean | null | undefined;
      readonly value: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly fieldConfig: {
    readonly isEditable: boolean | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"cascadingSelect_issueFieldCascadingSelectReadviewFull_CascadingSelectReadView">;
  readonly " $fragmentType": "cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditViewOld_fragmentRef";
};
export type cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditViewOld_fragmentRef$key = {
  readonly " $data"?: cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditViewOld_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditViewOld_fragmentRef">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
},
v1 = [
  (v0/*: any*/),
  {
    "kind": "ScalarField",
    "name": "value"
  },
  {
    "kind": "ScalarField",
    "name": "isDisabled"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditViewOld_fragmentRef",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "FragmentSpread",
      "name": "cascadingSelect_issueFieldCascadingSelectReadviewFull_CascadingSelectReadView"
    },
    {
      "concreteType": "JiraCascadingOption",
      "kind": "LinkedField",
      "name": "cascadingOption",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraOption",
          "kind": "LinkedField",
          "name": "parentOptionValue",
          "plural": false,
          "selections": (v1/*: any*/)
        },
        {
          "concreteType": "JiraOption",
          "kind": "LinkedField",
          "name": "childOptionValue",
          "plural": false,
          "selections": (v1/*: any*/)
        }
      ]
    },
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        }
      ]
    }
  ],
  "type": "JiraCascadingSelectField"
};
})();

(node as any).hash = "5bb28472de24371a143da3ea82f76473";

export default node;
