import React from 'react';
import Button from '@atlaskit/button/new';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';

import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import { useRespondersField } from '@atlassian/jira-responders/src/services/use-responders/index.tsx';
import type { ResponderSuggestedAction } from '@atlassian/jira-servicedesk-ai-context-panel-resources/src/services/ops/common/types.tsx';
import { commonMessages } from '../../messages.tsx';
import type { ActionButtonProps } from '../../types.tsx';

import { messages } from './messages.tsx';

type Props = ActionButtonProps<ResponderSuggestedAction['context']>;

export const AddRespondersButton = ({
	context,
	onSuccess: onSuccessProp,
	onFailure: onFailureProp,
	onClick: onClickProp,
}: Props) => {
	const { formatMessage } = useIntl();
	const issueKey = useIssueKey();
	const { showFlag } = useFlagsService();
	const { suggestion } = context;

	const onSuccess = () => {
		showFlag({
			type: 'success',
			title: formatMessage(messages.addRespondersSuccess, {
				suggestedResponderCount: suggestion.length,
			}),
			messageId: 'servicedesk-ai-context-ops-panel.common.ui.add-responders.show-flag.success',
			messageType: 'transactional',
		});
		onSuccessProp?.();
	};

	const onFailure = (error: Error) => {
		showFlag({
			type: 'error',
			title: formatMessage(commonMessages.error),
			messageId: 'servicedesk-ai-context-ops-panel.common.ui.add-responders.show-flag.error',
			messageType: 'transactional',
		});
		onFailureProp?.(error);
	};

	const [{ value: currentResponders }, { addResponders }] = useRespondersField({
		issueKey,
		onSuccess,
		onFailure,
	});

	const onClickHandler = (_: unknown, event: UIAnalyticsEvent) => {
		const allResponders = (currentResponders || []).concat(suggestion);
		addResponders(suggestion, allResponders, null, event);
		onClickProp?.();
	};

	return <Button onClick={onClickHandler}>{formatMessage(commonMessages.accept)}</Button>;
};
