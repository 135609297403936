import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { FieldDescription } from '@atlassian/jira-issue-field-description/src/ui/index.tsx';
import { FieldHeading } from '@atlassian/jira-issue-field-heading/src/index.tsx';
import {
	FieldHeadingTitle,
	SideBySideField,
	FieldWrapper,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import IssueOriginalEstimateField from '@atlassian/jira-issue-field-original-estimate/src/ui/index.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin/src/index.tsx';
import type { Props } from './types.tsx';

export const OriginalEstimate = ({
	isEditable,
	isMobile,
	config,
	fieldId,
	invalidTimeFormatMessage,
	issueId,
	issueKey,
	label,
	placeholder,
	timeTrackingValue,
	showPinButton,
	onUpdate,
}: Props) => (
	<OriginalEstimateFieldWrapper
		data-testid={`issue-view.issue-base.context.original-estimate.${fieldId}`}
	>
		<FieldHeading>
			<FieldHeadingTitle>{label}</FieldHeadingTitle>
			{issueKey !== undefined && label !== undefined && fieldId !== undefined && (
				<FieldDescription issueKey={issueKey} fieldKey={fieldId} label={label} />
			)}
			{showPinButton === true && <FieldPin fieldId={fieldId} label={label} />}
		</FieldHeading>
		<SideBySideField>
			<IssueOriginalEstimateField
				isEditable={isEditable}
				isFitContainerWidthReadView
				isMobile={isMobile}
				config={config}
				fieldId={fieldId}
				invalidTimeFormatMessage={invalidTimeFormatMessage}
				issueId={issueId}
				issueKey={issueKey}
				label={label}
				placeholder={placeholder}
				timeTrackingValue={timeTrackingValue}
				onUpdate={onUpdate}
			/>
		</SideBySideField>
	</OriginalEstimateFieldWrapper>
);

export default memo<Props>(OriginalEstimate);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OriginalEstimateFieldWrapper = styled(FieldWrapper)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div > div > div > div > div > div > div': {
		width: '100%',
	},
});
