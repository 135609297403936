import type { Dispatch } from 'redux';
import flow from 'lodash/flow';
import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { getIsClassicSubtaskPanel } from '../../../state/context/selectors.tsx';
import type { State } from '../../../state/types.tsx';
import { showInlineCreate, type ShowInlineCreateAction } from '../../../state/ui/actions.tsx';
import type { AddChildEventHandler, DispatchProps, StateProps } from './types.tsx';
import { AddChild } from './view.tsx';

export default flow(
	ComponentWithAnalytics('button', {
		onClick: 'clicked',
	}),
	withFireUiAnalytics({
		onClick: (...args: Parameters<AddChildEventHandler>) => {
			const [, , issueCreateSessionId, hasDescription, descriptionLength] = args;
			// If add_description_range_to_add_child_click is false & new_attributes_for_child_issue_create_click is true then send hasDescription
			// If both add_description_range_to_add_child_click & new_attributes_for_child_issue_create_click are true then send hasDescription & descriptionLength
			// If new_attributes_for_child_issue_create_click is false dont send any description attributes
			return {
				name: 'showChildIssuesInlineCreate',
				sessionId: issueCreateSessionId,
				...(!fg('add_description_range_to_add_child_click') &&
				fg('new_attributes_for_child_issue_create_click')
					? { hasDescription }
					: {}),
				// eslint-disable-next-line jira/ff/no-preconditioning
				...(fg('new_attributes_for_child_issue_create_click') &&
				fg('add_description_range_to_add_child_click')
					? { hasDescription, descriptionLength }
					: {}),
			};
		},
	}),
	connect(
		(state: State): StateProps => ({
			isClassicSubtaskPanel: getIsClassicSubtaskPanel(state),
		}),
		(dispatch: Dispatch<ShowInlineCreateAction>, props: DispatchProps): DispatchProps => ({
			onClick: (...args) => {
				dispatch(showInlineCreate());
				props.onClick(...args);
			},
		}),
	),
)(AddChild);
