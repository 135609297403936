/**
 * @generated SignedSource<<d588e81838d8297798146b298c8c1a3d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraForgeEnvironmentType = "DEVELOPMENT" | "PRODUCTION" | "STAGING" | "%future added value";
export type JiraVisibilityControlMechanism = "AppAccessRules" | "DisplayConditions" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type view_forgeDataStoreInitFragment$data = {
  readonly forge: {
    readonly extensions: ReadonlyArray<{
      readonly appVersion: string;
      readonly consentUrl: string | null | undefined;
      readonly egress: ReadonlyArray<{
        readonly addresses: ReadonlyArray<string> | null | undefined;
        readonly category?: string | null | undefined;
        readonly inScopeEUD?: boolean | null | undefined;
        readonly type: string | null | undefined;
      } | null | undefined>;
      readonly environmentId: string;
      readonly environmentKey: string;
      readonly environmentType: JiraForgeEnvironmentType;
      readonly hiddenBy: JiraVisibilityControlMechanism | null | undefined;
      readonly id: string;
      readonly installationConfig?: ReadonlyArray<{
        readonly key: string;
        readonly value: boolean;
      }> | null | undefined;
      readonly installationId: string;
      readonly license: {
        readonly active: boolean;
        readonly billingPeriod: string | null | undefined;
        readonly capabilitySet: string | null | undefined;
        readonly ccpEntitlementId: string | null | undefined;
        readonly ccpEntitlementSlug: string | null | undefined;
        readonly isEvaluation: boolean | null | undefined;
        readonly subscriptionEndDate: AGG$DateTime | null | undefined;
        readonly supportEntitlementNumber: string | null | undefined;
        readonly trialEndDate: AGG$DateTime | null | undefined;
        readonly type: string | null | undefined;
      } | null | undefined;
      readonly overrides?: AGG$JSON | null | undefined;
      readonly properties: AGG$JSON;
      readonly scopes: ReadonlyArray<string>;
      readonly type: string;
      readonly userAccess?: {
        readonly hasAccess: boolean;
      } | null | undefined;
    }> | null | undefined;
  };
  readonly " $fragmentType": "view_forgeDataStoreInitFragment";
};
export type view_forgeDataStoreInitFragment$key = {
  readonly " $data"?: view_forgeDataStoreInitFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"view_forgeDataStoreInitFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "type"
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "kind": "RootArgument",
      "name": "forgeTypes"
    },
    {
      "kind": "RootArgument",
      "name": "includeEgressOverridesForge"
    },
    {
      "kind": "RootArgument",
      "name": "includeUserAccessForge"
    },
    {
      "kind": "RootArgument",
      "name": "issueKey"
    }
  ],
  "kind": "Fragment",
  "name": "view_forgeDataStoreInitFragment",
  "selections": [
    {
      "concreteType": "JiraForgeQuery",
      "kind": "LinkedField",
      "name": "forge",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "cloudId",
              "variableName": "cloudId"
            },
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "issueKey",
                  "variableName": "issueKey"
                }
              ],
              "kind": "ObjectValue",
              "name": "context"
            },
            {
              "kind": "Literal",
              "name": "includeHidden",
              "value": true
            },
            {
              "kind": "Variable",
              "name": "types",
              "variableName": "forgeTypes"
            }
          ],
          "concreteType": "JiraForgeExtension",
          "kind": "LinkedField",
          "name": "extensions",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "hiddenBy"
            },
            {
              "kind": "ScalarField",
              "name": "scopes"
            },
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "name": "id"
            },
            {
              "kind": "ScalarField",
              "name": "environmentId"
            },
            {
              "kind": "ScalarField",
              "name": "environmentKey"
            },
            {
              "kind": "ScalarField",
              "name": "environmentType"
            },
            {
              "kind": "ScalarField",
              "name": "installationId"
            },
            {
              "kind": "ScalarField",
              "name": "appVersion"
            },
            {
              "kind": "ScalarField",
              "name": "consentUrl"
            },
            {
              "kind": "ScalarField",
              "name": "properties"
            },
            {
              "condition": "includeUserAccessForge",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "concreteType": "JiraUserAppAccess",
                  "kind": "LinkedField",
                  "name": "userAccess",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "hasAccess"
                    }
                  ]
                }
              ]
            },
            {
              "concreteType": "JiraForgeAppEgressDeclaration",
              "kind": "LinkedField",
              "name": "egress",
              "plural": true,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "addresses"
                },
                (v0/*: any*/),
                {
                  "condition": "includeEgressOverridesForge",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "category"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "inScopeEUD"
                    }
                  ]
                }
              ]
            },
            {
              "condition": "includeEgressOverridesForge",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "overrides"
                },
                {
                  "concreteType": "JiraForgeInstallationConfigExtension",
                  "kind": "LinkedField",
                  "name": "installationConfig",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "key"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "value"
                    }
                  ]
                }
              ]
            },
            {
              "concreteType": "JiraForgeExtensionLicense",
              "kind": "LinkedField",
              "name": "license",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "active"
                },
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "supportEntitlementNumber"
                },
                {
                  "kind": "ScalarField",
                  "name": "trialEndDate"
                },
                {
                  "kind": "ScalarField",
                  "name": "subscriptionEndDate"
                },
                {
                  "kind": "ScalarField",
                  "name": "isEvaluation"
                },
                {
                  "kind": "ScalarField",
                  "name": "billingPeriod"
                },
                {
                  "kind": "ScalarField",
                  "name": "ccpEntitlementId"
                },
                {
                  "kind": "ScalarField",
                  "name": "ccpEntitlementSlug"
                },
                {
                  "kind": "ScalarField",
                  "name": "capabilitySet"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraQuery"
};
})();

(node as any).hash = "becc588256855a2794487033b3a42c01";

export default node;
