import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import messages from '@atlassian/jira-common-components-inline-edit/src/messages.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { FieldDescription } from '@atlassian/jira-issue-field-description/src/ui/index.tsx';
import { FieldEditIcon } from '@atlassian/jira-issue-field-edit-icon/src/FieldEditIcon.tsx';
import { FieldHeading } from '@atlassian/jira-issue-field-heading/src/index.tsx';
import {
	FieldHeadingTitle,
	FieldWrapper,
	SideBySideField,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';

import {
	ReadViewContainer,
	// eslint-disable-next-line jira/styled/no-styled-import-alias
	InlineEditContainer as BaseInlineEditContainer,
	READ_VIEW_CONTAINER_SELECTOR,
} from '@atlassian/jira-issue-field-inline-edit/src/styled.tsx';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin/src/index.tsx';
import TagView from '@atlassian/jira-issue-view-internal-tagview/src/tag-view.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { checkboxInlineEditView_issueInternalFields$key } from '@atlassian/jira-relay/src/__generated__/checkboxInlineEditView_issueInternalFields.graphql';
import CheckboxFieldView, { type CheckboxValueItemShape } from './checkbox-field-view.tsx';

export type Props = {
	isEditable?: boolean;
	isEditing?: boolean;
	isMobile?: boolean;
	issueKey?: IssueKey; // TODO - make issueKey mandatory in BENTO-11149,
	options?: CheckboxValueItemShape[];
	value?: CheckboxValueItemShape[];
	label?: string;
	tagAppearance?: string;
	placeholder?: string;
	noValueText?: string;
	invalidMessage?: string;
	fieldId: string;
	showPinButton?: boolean;
	onChange: (arg1: CheckboxValueItemShape[]) => void;
	onEditRequest: () => void;
	onConfirm: () => void;
	onCancel: () => void;
	fragmentKey: checkboxInlineEditView_issueInternalFields$key;
};

export const CheckboxInlineEditView = ({
	isEditable = false,
	isEditing = false,
	isMobile = false,
	options = [],
	value = [],
	label = '',
	placeholder,
	noValueText,
	tagAppearance = 'default',
	invalidMessage,
	issueKey,
	fieldId,
	showPinButton,
	onChange,
	onEditRequest,
	onConfirm,
	onCancel,
	fragmentKey,
}: Props) => {
	const { formatMessage } = useIntl();

	const renderReadView = useCallback(
		() => (
			<TagView
				value={value.map(({ label: lLabel, value: lValue }) => ({
					content: lLabel,
					value: lValue,
				}))}
				noValueText={noValueText}
				tagAppearance={tagAppearance}
				shouldHideLinks={isMobile}
			/>
		),
		[isMobile, noValueText, tagAppearance, value],
	);

	const renderEditView = useCallback(
		() => (
			<CheckboxFieldView
				value={value}
				options={options}
				onChange={onChange}
				placeholder={placeholder}
				invalidMessage={invalidMessage}
			/>
		),
		[value, onChange, options, placeholder, invalidMessage],
	);

	// eslint-disable-next-line @atlassian/relay/query-restriction
	const data = useFragment<checkboxInlineEditView_issueInternalFields$key>(
		graphql`
			fragment checkboxInlineEditView_issueInternalFields on JiraIssueField {
				...FieldEditIcon
			}
		`,
		fragmentKey,
	);

	return (
		<FieldWrapper data-testid={`issue.views.field.checkbox-inline-edit.${fieldId}`}>
			<FieldHeading fieldId={fieldId}>
				<FieldHeadingTitle>{label}</FieldHeadingTitle>
				{issueKey !== undefined && fieldId !== undefined && (
					<FieldDescription issueKey={issueKey} fieldKey={fieldId} label={label} />
				)}
				{showPinButton === true && <FieldPin fieldId={fieldId} label={label} />}
				{fg('issue_view_field_config_edit') && (
					<UFOSegment name="issue-view-field-icon-edit">
						<FieldEditIcon fieldId={fieldId} fragmentKey={data} />
					</UFOSegment>
				)}
			</FieldHeading>
			<SideBySideField isEditing={isEditing}>
				<InlineEditContainer isEditable={isEditable} hasValue={value && value.length !== 0}>
					<FieldInlineEditStateLess
						isLabelHidden
						label={label}
						fieldId={fg('one_event_rules_them_all_fg') ? fieldId : undefined}
						readView={<ReadViewContainer>{renderReadView()}</ReadViewContainer>}
						testId={`issue.views.field.checkbox-inline-edit.${fieldId}`}
						editView={isEditable ? renderEditView() : null}
						// @ts-expect-error - TS2322 - Property 'disableEditViewFieldBase' does not exist on type 'IntrinsicAttributes & FieldInlineEditStateLessProps<unknown>'.
						disableEditViewFieldBase
						areActionButtonsHidden
						isEditing={isEditing}
						onConfirm={onConfirm}
						onCancel={onCancel}
						onEscape={onCancel}
						onEditRequested={onEditRequest}
						isFitContainerWidthReadView={!isMobile}
						editButtonLabel={formatMessage(messages.editButtonLabel, {
							fieldName: label,
						})}
						confirmButtonLabel={formatMessage(messages.confirmButtonLabel, {
							fieldName: label,
						})}
						cancelButtonLabel={formatMessage(messages.cancelButtonLabel, {
							fieldName: label,
						})}
						isEditable={isEditable}
						aria-label={label}
					/>
				</InlineEditContainer>
			</SideBySideField>
		</FieldWrapper>
	);
};

export default CheckboxInlineEditView;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const InlineEditContainer = styled(BaseInlineEditContainer)<{
	isEditable: boolean;
	hasValue: boolean;
}>(
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		paddingRight: `${gridSize}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& div[data-read-view-fit-container-width]': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			padding: ({ hasValue }) => (hasValue ? '4px 0' : '7px 0'),
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isEditable }) =>
		!isEditable
			? {
					marginLeft: 0,
					'& > div': {
						margin: '14px 0 6px',
					},
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					[READ_VIEW_CONTAINER_SELECTOR]: {
						left: 0,
						'& > div > div > div > div': {
							padding: 0,
						},
					},
				}
			: undefined,
);
