/**
 * @generated SignedSource<<216ab3296ee9179b56fa84495704b839>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutSingleSelectField_IssueViewSingleSelectField$data = {
  readonly fieldId: string;
  readonly " $fragmentSpreads": FragmentRefs<"singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditView_fragmentRef" | "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading">;
  readonly " $fragmentType": "ui_issueViewLayoutSingleSelectField_IssueViewSingleSelectField";
};
export type ui_issueViewLayoutSingleSelectField_IssueViewSingleSelectField$key = {
  readonly " $data"?: ui_issueViewLayoutSingleSelectField_IssueViewSingleSelectField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutSingleSelectField_IssueViewSingleSelectField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutSingleSelectField_IssueViewSingleSelectField",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading"
    },
    {
      "kind": "FragmentSpread",
      "name": "singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditView_fragmentRef"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    }
  ],
  "type": "JiraSingleSelectField"
};

(node as any).hash = "7c43bcecec4c2dd1b10d212542e3b915";

export default node;
