// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { componentWithCondition } from './utils/component-with-condition';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { componentWithFF } from './utils/component-with-ff';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { functionUnionWithCondition, functionWithCondition } from './utils/function-with-condition';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { functionWithFF } from './utils/function-with-ff';

export function functionWithConditionAndDifferentArgs<
	TArg1 extends unknown[],
	TArg2 extends unknown[],
	TReturn,
>(
	condition: () => boolean,
	functionTrue: (...args: TArg1) => TReturn,
	functionFalse: (...args: TArg2) => TReturn,
): (...args: TArg1 | TArg2) => TReturn {
	const fnWithCondition = (...args: TArg1 | TArg2) =>
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		condition() ? functionTrue(...(args as TArg1)) : functionFalse(...(args as TArg2));
	return fnWithCondition;
}
