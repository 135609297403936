import React, { useCallback, type MouseEvent } from 'react';
import { token } from '@atlaskit/tokens';
import SuccessIcon from '@atlaskit/icon/core/migration/success--check-circle';
import { useService } from '@atlassian/jira-common-services/src/use-service/index.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIntl, defineMessages } from '@atlassian/jira-intl';
import { fireUIAnalytics, type AnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';

import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { PROPERTY_KEY } from '../../common/constants.tsx';
import { useDeleteDismissDataService } from '../remove-dismiss-data/index.tsx';
import type { DismissData, DismissTemplate } from '../../common/types.tsx';

const updateDismissDataRequest = async (body: DismissData) => {
	try {
		return await performPutRequest(url, { body: JSON.stringify(body) });
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			error,
			meta: {
				id: 'updateDismissData',
				teamName: 'ITOps Phantom',
				packageName: 'jiraAutomationDiscoverability',
			},
		});
	}
};

export const useUpdateDismissDataService = (
	templateId: string,
	dismissData: DismissData,
	setDismissData: (template: DismissTemplate, isDismiss: boolean) => void,
) => {
	const { showFlag, dismissFlag } = useFlagsService();
	const { formatMessage } = useIntl();
	const { removeDismissData } = useDeleteDismissDataService();

	const request = useCallback(async () => {
		const resetUntil30Days = new Date(Date.now() + MILLISECONDS.DAYS * 30).toISOString();
		const existingTemplates = dismissData ?? [];
		const newTemplate = { templateId, dismissUntilDateTime: resetUntil30Days };
		let body;
		if (!existingTemplates.some((template) => templateId === template.templateId)) {
			body = [...existingTemplates, newTemplate];
		} else {
			body = existingTemplates.map((template) =>
				template.templateId === templateId
					? { ...template, dismissUntilDateTime: resetUntil30Days }
					: template,
			);
		}

		const data = await updateDismissDataRequest(body);
		setDismissData(newTemplate, true);
		if (data == null) {
			const flagId = showFlag({
				type: 'success',
				messageId: 'automation-discoverability.services.update-dismiss-data.show-flag.success',
				messageType: 'transactional',
				description: formatMessage(messages.successDismissDesc),
				title: formatMessage(messages.successDismissTitle),
				icon: <SuccessIcon label="" color={token('color.icon.success')} />,
				actions: [
					{
						content: formatMessage(messages.actionButtonUndo),
						onClick: (e?: MouseEvent<HTMLElement>, analyticsEvent?: AnalyticsEvent) => {
							analyticsEvent && fireUIAnalytics(analyticsEvent, 'automationDiscoveryDismissUndo');
							if (Object.keys(body).length > 1) {
								// Templates are already dismissed so use the previous data to update
								updateDismissDataRequest(existingTemplates);
							} else {
								removeDismissData();
							}
							flagId && dismissFlag(flagId);
							setDismissData(newTemplate, false);
						},
					},
				],
			});
		}
		return data;
	}, [
		templateId,
		dismissData,
		dismissFlag,
		formatMessage,
		removeDismissData,
		showFlag,
		setDismissData,
	]);

	const { loading, error, data, fetch } = useService(request);

	return { loading, error, data, updateDismissData: fetch };
};

const messages = defineMessages({
	successDismissTitle: {
		id: 'automation-discovery.actions.success-dismiss-title',
		defaultMessage: 'Recommendation dismissed',
		description: 'Dismiss flag title',
	},
	successDismissDesc: {
		id: 'automation-discovery.actions.success-dismiss-desc',
		defaultMessage:
			"We'll no longer show you recommendations for the automation rule template for this incident.",
		description: 'Dismiss flag description',
	},
	errorDismissTitle: {
		id: 'automation-discovery.actions.error-dismiss-title',
		defaultMessage: 'Error',
		description: 'Dismiss flag error title',
	},
	errorDismissDesc: {
		id: 'automation-discovery.actions.error-dismiss-desc',
		defaultMessage: 'Please try again later',
		description: 'Dismiss flag error title',
	},
	actionButtonUndo: {
		id: 'automation-discovery.actions.action-button-undo',
		defaultMessage: 'Undo',
		description: 'action for undo',
	},
});

const url = `/rest/api/3/mypreferences?key=${PROPERTY_KEY}`;

const MILLISECONDS = {
	DAYS: 24 * 60 * 60 * 1000,
};
