/**
 * @generated SignedSource<<a1849770e42ee95a09fed9be5fb1fe64>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraProjectStyle = "COMPANY_MANAGED_PROJECT" | "TEAM_MANAGED_PROJECT" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type currentIssue_issueViewFoundation_BreadcrumbCurrentIssue_issue$data = {
  readonly fieldsById: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: "JiraProjectField";
        readonly project: {
          readonly projectStyle: JiraProjectStyle | null | undefined;
        } | null | undefined;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly issueTypeField: {
    readonly isEditableInIssueView: boolean | null | undefined;
    readonly issueType: {
      readonly avatar: {
        readonly xsmall: string | null | undefined;
      } | null | undefined;
      readonly name: string;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcherWithRefetch">;
  } | null | undefined;
  readonly key: string;
  readonly webUrl: AGG$URL | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"currentIssue_issueViewFoundation_BreadcrumbsWithTooltip_issue">;
  readonly " $fragmentType": "currentIssue_issueViewFoundation_BreadcrumbCurrentIssue_issue";
};
export type currentIssue_issueViewFoundation_BreadcrumbCurrentIssue_issue$key = {
  readonly " $data"?: currentIssue_issueViewFoundation_BreadcrumbCurrentIssue_issue$data;
  readonly " $fragmentSpreads": FragmentRefs<"currentIssue_issueViewFoundation_BreadcrumbCurrentIssue_issue">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsissuetypeswitcherrelayprovider"
    }
  ],
  "kind": "Fragment",
  "name": "currentIssue_issueViewFoundation_BreadcrumbCurrentIssue_issue",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "key"
    },
    {
      "kind": "ScalarField",
      "name": "webUrl"
    },
    {
      "concreteType": "JiraIssueTypeField",
      "kind": "LinkedField",
      "name": "issueTypeField",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditableInIssueView"
        },
        {
          "concreteType": "JiraIssueType",
          "kind": "LinkedField",
          "name": "issueType",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "name"
            },
            {
              "concreteType": "JiraAvatar",
              "kind": "LinkedField",
              "name": "avatar",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "xsmall"
                }
              ]
            }
          ]
        },
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsissuetypeswitcherrelayprovider",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcherWithRefetch"
            }
          ]
        }
      ]
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "ids",
          "value": [
            "parent",
            "project"
          ]
        }
      ],
      "concreteType": "JiraIssueFieldConnection",
      "kind": "LinkedField",
      "name": "fieldsById",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraIssueFieldEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "__typename"
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "concreteType": "JiraProject",
                      "kind": "LinkedField",
                      "name": "project",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "name": "projectStyle"
                        }
                      ]
                    }
                  ],
                  "type": "JiraProjectField"
                }
              ]
            }
          ]
        }
      ],
      "storageKey": "fieldsById(ids:[\"parent\",\"project\"])"
    },
    {
      "kind": "FragmentSpread",
      "name": "currentIssue_issueViewFoundation_BreadcrumbsWithTooltip_issue"
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "337922176b918085ab35039cd062cf23";

export default node;
