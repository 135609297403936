import { useCallback, useMemo } from 'react';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { FAIL_FLAGS, SUCCESS_FLAGS } from '../../common/constants/flag-ids.tsx';
import {
	CONF_REQUEST_ACCESS_CAPABILITIES_ARI,
	directJoinConfluence,
	getCurrentConfluenceUser,
} from '../../services/confluence-rest-api/index.tsx';
import {
	handleFailureWithNotification,
	handleSuccessWithNotification,
} from '../../services/utils/index.tsx';
import { useEmbeddedPageTracking } from '../use-embedded-page-tracking/index.tsx';
import {
	ACCESS_DENIED_STATUSES,
	LICENCE_EXCEEDED_STATUS,
	VALID_DIRECT_ACCESS_STATUS,
} from '../utils/index.tsx';
import messages from './messages.tsx';

/*
        We arbitrarily set the base retry number to 15 because there is a 10-20s delay between
        a user being granted access to Confluence and their Confluence account being created.
        Their account must be created in order for them to be able to create a page.
    */
const RETRIES = 15;
const BASE_DELAY = 300;
const MAX_DELAY = 10000;
interface Params {
	embeddedConfluenceSource: string;
	onError?: (error: unknown) => void;
	onSuccess?: (result: string) => void;
}

export const useDirectJoinConfluence = ({
	embeddedConfluenceSource,
	onError,
	onSuccess,
}: Params) => {
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagsService();
	const { cloudId } = useTenantContext();
	const fireAnalyticsEvent = useEmbeddedPageTracking();
	const handleError = useMemo(
		() =>
			handleFailureWithNotification({
				embeddedConfluenceSource,
				showFlag,
			}),
		[embeddedConfluenceSource, showFlag],
	);
	const handleSuccess = useMemo(
		() =>
			handleSuccessWithNotification({
				fireAnalytics: fireAnalyticsEvent,
				embeddedConfluenceSource,
				showFlag,
			}),
		[embeddedConfluenceSource, fireAnalyticsEvent, showFlag],
	);

	const directJoin = useCallback(
		async ({ retries = RETRIES, baseDelay = BASE_DELAY, maxDelay = MAX_DELAY }) => {
			try {
				const response = await directJoinConfluence(cloudId);
				const data = await response.json();
				const status = data?.results?.[`${CONF_REQUEST_ACCESS_CAPABILITIES_ARI}${cloudId}`];
				if (status !== undefined) {
					handleSuccess({
						eventName: 'directJoinConfluence',
						flagId: SUCCESS_FLAGS.directJoinSuccess,
						title: formatMessage(messages.directJoinSuccessTitle),
						description: formatMessage(messages.directJoinSuccessDescription),
						useConfIcon: true,
						messageId:
							'issue-create-confluence-content.controllers.use-direct-join-confluence.handle-success.direct-join-success-flag',
						messageType: 'transactional',
					});
					if (VALID_DIRECT_ACCESS_STATUS.includes(status)) {
						const requestResponse = await getCurrentConfluenceUser({
							cloudId,
							retries,
							baseDelay,
							maxDelay,
						});
						if (!requestResponse.success) throw requestResponse.error;

						onSuccess && onSuccess(status);
						handleSuccess({
							action: 'joined',
							actionSubject: 'product',
							eventName: 'product joined',
							eventType: 'track',
							originProduct: 'jira',
							requestedProduct: 'confluence',
							flagId: SUCCESS_FLAGS.verifyDirectJoinSuccess,
							title: formatMessage(messages.directJoinVerifySuccessTitle),
							description: formatMessage(messages.directJoinVerifySuccessDescription),
							messageId:
								'issue-create-confluence-content.controllers.use-direct-join-confluence.handle-success.direct-join-verify-success-flag',
							messageType: 'transactional',
							useConfIcon: true,
						});
					} else if (ACCESS_DENIED_STATUSES.includes(status)) {
						handleSuccess({
							action: 'denied',
							actionSubject: 'product',
							eventName: 'product joined',
							originProduct: 'jira',
							requestedProduct: 'confluence',
							flagId: FAIL_FLAGS.directJoinDenied,
							title: formatMessage(messages.directJoinDeniedTitle),
							description: formatMessage(messages.directJoinDeniedDescription),
							messageId:
								'issue-create-confluence-content.controllers.use-direct-join-confluence.handle-success.direct-join-denied-flag',
							messageType: 'transactional',
							useConfIcon: true,
						});
					} else {
						throw new Error(`User status is ${status}`);
					}
				} else if (data?.code && data.code === LICENCE_EXCEEDED_STATUS) {
					handleSuccess({
						action: 'denied',
						actionSubject: 'product',
						eventName: 'product joined',
						originProduct: 'jira',
						requestedProduct: 'confluence',
						flagId: FAIL_FLAGS.directJoinFailed,
						title: formatMessage(messages.directJoinFailTitle),
						description: formatMessage(messages.directJoinExceededDescription),
						messageId:
							'issue-create-confluence-content.controllers.use-direct-join-confluence.handle-success.direct-join-licence-exceeded-flag',
						messageType: 'transactional',
						useConfIcon: true,
					});
				} else {
					throw new Error(`Direct join API response is ${JSON.stringify(data)}`);
				}
			} catch (error) {
				onError && onError(error);
				const failureReason = error instanceof Error ? error.message : JSON.stringify(error);
				handleError({
					id: 'directJoinConfluence',
					flagId: FAIL_FLAGS.directJoinFailed,
					title: formatMessage(messages.directJoinFailTitle),
					description: formatMessage(messages.directJoinFailDescription),
					messageId:
						'issue-create-confluence-content.controllers.use-direct-join-confluence.handle-error.direct-join-fail-flag',
					messageType: 'transactional',
					error: new Error(`Failed to direct join Confluence: ${failureReason}`),
				});
			}
		},
		[cloudId, formatMessage, handleError, handleSuccess, onError, onSuccess],
	);

	return { directJoin };
};
