/** @jsx jsx */
import React from 'react';
import { css, jsx, cssMap } from '@compiled/react';
import Avatar, { type AvatarPropTypes } from '@atlaskit/avatar';
import PersonIcon from '@atlaskit/icon/core/person';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';

export interface UnassignedAvatarProps extends AvatarPropTypes {
	isSelected?: boolean;
}

const selectedStyles = css({
	borderColor: token('color.border.selected'),
	color: token('color.icon.selected'),
});

const unSelectedStyles = css({
	'&:hover': {
		color: token('color.background.accent.gray.subtle.hovered'),
	},
});

const nestedSvgStylesMap = cssMap({
	xsmall: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'& svg': {
			width: '16px',
			height: '16px',
		},
	},
	small: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'& svg': {
			width: '24px',
			height: '24px',
		},
	},
	medium: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'& svg': {
			width: '32px',
			height: '32px',
		},
	},
	large: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'& svg': {
			width: '40px',
			height: '40px',
		},
	},
	xlarge: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'& svg': {
			width: '48px',
			height: '48px',
		},
	},
	xxlarge: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'& svg': {
			width: '56px',
			height: '56px',
		},
	},
});

const baseStyles = css({
	display: 'inline-flex',
	borderRadius: '50%',
	justifyContent: 'center',
	alignItems: 'center',
	marginTop: token('space.025'),
	marginRight: token('space.025'),
	marginBottom: token('space.025'),
	marginLeft: token('space.025'),
	boxShadow: `0 0 0 2px ${token('color.background.neutral.subtle')}`,
	borderColor: 'currentColor',
	borderStyle: 'dashed',
	borderWidth: '1px',
	color: token('color.background.accent.gray.subtle'),
});

export const UnassignedAvatar: React.FC<UnassignedAvatarProps> = ({
	isSelected = false,
	size = 'medium',
	testId,
	...restAvatarProps
}) => {
	const { formatMessage } = useIntl();
	return (
		<Avatar size={size} testId={testId} {...restAvatarProps}>
			<span
				css={[baseStyles, isSelected ? selectedStyles : unSelectedStyles, nestedSvgStylesMap[size]]}
				data-testId={testId ? `${testId}--inner` : undefined}
			>
				<PersonIcon
					label={formatMessage({ id: 'unassigned', defaultMessage: 'unassigned' })}
					color="currentColor"
					testId={testId && `${testId}--inner--person`}
					spacing="spacious"
				/>
			</span>
		</Avatar>
	);
};
