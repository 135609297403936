import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { trackFetchErrors } from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { getEscalatorBaseUrl } from '@atlassian/jira-servicedesk-incident-management-common/src/utils.tsx';
import type { CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { CREATED_BY_ME, SHARED_WITH_ME, PREDEFINED } from '../../../common/constants.tsx';
import commonMessages from '../../../common/messages.tsx';
import type { SavedSearchByCategory } from '../../../common/types.tsx';
import type { State } from '../../types.tsx';
import messages from './messages.tsx';
import type { SavedSearchOption } from './types.tsx';

export const transformEscalatorResponse = (data: SavedSearchOption[]): SavedSearchByCategory => {
	const transformedData = {
		[PREDEFINED]: [],
		[CREATED_BY_ME]: [],
		[SHARED_WITH_ME]: [],
	};

	// @ts-expect-error - TS2345 - Argument of type '{ id: string; name: string; description: string | null; alertSearchQuery: string; }' is not assignable to parameter of type 'never'.
	data.forEach(({ category, ...rest }) => transformedData[category].push(rest));

	return transformedData;
};

type Args = {
	cloudId: CloudId;
	hasOpsgenieAccount: boolean | undefined;
	analyticsEvent: UIAnalyticsEvent;
};

export const fetchSavedSearches =
	({ cloudId, hasOpsgenieAccount = false, analyticsEvent }: Args) =>
	async ({ getState, setState }: StoreActionApi<State>) => {
		const { showFlag } = useFlagsService();
		const { linkModalFilter } = getState();
		const { savedSearchOptions } = linkModalFilter;

		if (savedSearchOptions.isLoading || !hasOpsgenieAccount) {
			return;
		}

		setState({
			linkModalFilter: {
				...linkModalFilter,
				savedSearchOptions: {
					...savedSearchOptions,
					isLoading: true,
				},
			},
		});

		try {
			const response = await fetchJson(`${getEscalatorBaseUrl(cloudId)}/alerts/saved-searches`);

			setState({
				linkModalFilter: {
					...linkModalFilter,
					savedSearchOptions: {
						error: undefined,
						isLoading: false,
						data: transformEscalatorResponse(response.results),
					},
				},
			});

			fireOperationalAnalytics(analyticsEvent, 'fetchSavedSearches succeeded');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			showFlag({
				type: 'error',
				title: messages.errorTitle,
				description: commonMessages.errorDescription,
				messageId: 'linked-alerts.services.actions.fetch-saved-searches.show-flag.error',
				messageType: 'transactional',
			});

			trackFetchErrors({
				event: analyticsEvent,
				error: e,
				id: 'fetchSavedSearches',
				sendToPrivacyUnsafeSplunk: true,
			});

			setState({
				linkModalFilter: {
					...linkModalFilter,
					savedSearchOptions: {
						...savedSearchOptions,
						error: new Error(e),
						isLoading: false,
					},
				},
			});
		}
	};
