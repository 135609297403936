import React, { memo } from 'react';
import { styled } from '@compiled/react';
import DragHandleVerticalIcon from '@atlaskit/icon/core/migration/drag-handle-vertical--drag-handler';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import AsyncIcon from '@atlassian/jira-common-components-async-icon/src/view.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const InnerActionContainer = styled.div({
	gridArea: 'slot',
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'center',
	opacity: 'var(--action-opacity,1)',
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const InnerIconContainer = styled.div({
	gridArea: 'slot',
	display: 'inline-flex',
	opacity: 'calc(1 - var(--action-opacity,1))',
	pointerEvents: 'none',
});
// Intermediate div(TypeIconContainer) for AKTooltip to bind to
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const TypeIconContainer = styled.div<{ isActionVisible: boolean; isRestricted: boolean }>(
	{
		width: 16,
		display: 'grid',
		gridTemplateAreas: 'slot',
		placeItems: 'center',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		'--action-opacity': ({ isActionVisible }) => (isActionVisible ? '1' : '0'),
		'@supports not selector(:has(*))': {
			'&:focus-within': {
				'--action-opacity': '1',
			},
		},
		'@supports selector(:has(*))': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
			'&:has(:focus-visible)': {
				'--action-opacity': '1',
			},
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isRestricted }) =>
		isRestricted
			? {
					cursor: 'not-allowed',
					'&:hover': {
						opacity: 0.5,
					},
				}
			: undefined,
);

/**
 * Displays the issue type icon, as well as the drag handle on hover/focus.
 *
 * Dragging is forcibly disabled when the number of children is over the
 * child issue limit. In that case the restricted message is displayed in the
 * tooltip.
 */
export const IssueType = memo<Props>(
	({
		issueTypeName,
		issueTypeIconUrl,
		isDraggable,
		isActionVisible,
		restrictedReorderingMessage,
		onHandleUserClickAnalytics,
		reorderMenu,
		issueTypeIcon,
	}: Props) => {
		const intl = useIntl();
		const title = intl.formatMessage(messages.issueTypeTitle, { issueTypeName });
		const hasValidRestrictedMessage = restrictedReorderingMessage !== '';
		const tooltipContent = issueTypeName || '';

		const action = reorderMenu || (
			<DragHandleVerticalIcon spacing="spacious" label="" color={token('color.icon.subtle')} />
		);

		// Using an intermediate div(TypeIconContainer) since AKTooltip needs an element to bind to
		// We aren't wrapping just ListIcon or AsyncIcon since AKTooltip is stateful
		// and we don't want it to unmount as the mouseHover changes
		return (
			<Tooltip
				content={
					!isDraggable && hasValidRestrictedMessage
						? String(restrictedReorderingMessage)
						: tooltipContent
				}
				hideTooltipOnMouseDown={isDraggable}
				position="top"
				// eslint-disable-next-line jira/integration/test-id-by-folder-structure
				testId="issue-line-card.issue-type.tooltip"
			>
				<TypeIconContainer
					onClick={() => onHandleUserClickAnalytics?.('typeIcon')}
					data-testid="issue-line-card-issue-type.issue-type"
					isActionVisible={isDraggable && isActionVisible}
					isRestricted={hasValidRestrictedMessage}
				>
					<InnerIconContainer>
						{issueTypeIcon ?? <AsyncIcon alt={title} url={issueTypeIconUrl} />}
					</InnerIconContainer>
					{isDraggable && <InnerActionContainer>{action}</InnerActionContainer>}
				</TypeIconContainer>
			</Tooltip>
		);
	},
);
