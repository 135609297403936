import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import parameters from '@atlassian/jira-relay/src/__generated__/contentSmartSummaryQuery.graphql.ts';

export const jsmSmartRequestPreloadedSummaryEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-smart-request-summary" */ '..').then(
			(module) => module.SmartRequestSummary,
		),
	),
	getPreloadProps: ({ cloudId, issueId }: { cloudId: string; issueId: string }) => ({
		queries: {
			smartSummaryQuery: {
				parameters,
				variables: {
					cloudId,
					issueId,
				},
				options: {
					fetchPolicy: 'store-and-network' as const,
				},
			},
		},
	}),
});
