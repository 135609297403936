import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { getSiteAri } from '../../../utils/index.tsx';
import type { InviteApiSuccessResponse } from '../types.tsx';
import { INVITE_USERS_WITHIN_ORG_ENDPOINT } from './constants.tsx';
import type { InviteParams } from './types.tsx';

export const inviteWithinOrg = async ({
	userIds,
	continueUrl,
	tenantId,
	product,
}: InviteParams): Promise<Array<InviteApiSuccessResponse>> => {
	const resources = [getSiteAri(tenantId, product)];
	const response = await performPostRequest<Array<InviteApiSuccessResponse>>(
		INVITE_USERS_WITHIN_ORG_ENDPOINT,
		{
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				continueUrl,
				resources,
				userIds,
				cloudId: tenantId,
				experience: 'invite-on-link-insert',
			}),
		},
	);
	return response;
};
