import React, { type ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { useAssociatedIssuesContextActions } from '@atlassian/jira-associated-issues-context-service/src/context.tsx';
import { ClipboardContextBoundaryContainer as ClipboardBoundary } from '@atlassian/jira-clipboard/src/controllers/boundary/index.tsx';
import { DISPOSE_ACTION } from '@atlassian/jira-common-redux-disposable/src/index.tsx';
import { disposeDispatch } from '@atlassian/jira-common-redux-disposable/src/dispose-dispatch.tsx';
import { CrossFlowProvider } from '@atlassian/jira-cross-flow/src/services/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIssueAttachmentsActions } from '@atlassian/jira-issue-attachments-base/src/services/attachments-service/main.tsx';
import { useIssueContextActions } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { MultipleEntrypointsContextProvider } from '@atlassian/jira-issue-entrypoint-context-provider/src/index.tsx';
import { useFieldConfigActions } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { useFieldsValuesActions } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import {
	IssueRefreshLoadingContainer,
	useIssueRefreshLoadingActions,
} from '@atlassian/jira-issue-field-base/src/services/issue-refresh-loading-service/index.tsx';
import { IssueFieldEditingContextProvider } from '@atlassian/jira-issue-field-optional-editing-state-manager/src/index.tsx';
import { useMediaContextActions } from '@atlassian/jira-issue-media-context-service/src/main.tsx';
import useIssueViewNonCriticalData from '@atlassian/jira-issue-non-critical-gira-service/src/controllers/use-issue-view-non-critical-data/index.tsx';
import { OnboardingTourContainer } from '@atlassian/jira-issue-onboarding-modal/src/services/onboarding-tour/context.tsx';
import { useIssueRefreshServiceActions } from '@atlassian/jira-issue-refresh-service/src/services/main.tsx';
import { useIssueScrollActions } from '@atlassian/jira-issue-scroll/src/services/main.tsx';
import { useUserPreferencesActions } from '@atlassian/jira-issue-user-preference-services/src/main.tsx';
import AppBase from '@atlassian/jira-issue-view-app-base/src/index.tsx';
import type {
	ErrorMessageUi,
	State,
} from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
// eslint-disable-next-line camelcase
import { useStoreActionsForIssueViewShortcutActions_DO_NOT_USE } from '@atlassian/jira-issue-view-common-views/src/store-provider/index.tsx';
import { useEcosystemActions } from '@atlassian/jira-issue-view-ecosystem-service/src/services/main.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import { useForgeActions } from '@atlassian/jira-issue-view-forge-service/src/services/main.tsx';
import { IssueKeyboardShortcutsContainer } from '@atlassian/jira-issue-view-keyboard-shortcuts/src/services/store.tsx';
import { useIssueLayoutActions } from '@atlassian/jira-issue-view-layout/src/services/main.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { useRecentIssueActions } from '@atlassian/jira-issue-view-services/src/recent-issues-service/main.tsx';
import { resetQuickAddClickedCount } from '@atlassian/jira-issue-view-store/src/actions/child-panel-actions.tsx';
import { setContext } from '@atlassian/jira-issue-view-store/src/actions/context-actions.tsx';
import {
	type ExternalAction,
	transformExternalAction,
} from '@atlassian/jira-issue-view-store/src/actions/external-actions.tsx';
import {
	cancelRefreshIssueRequest,
	refreshIssueRequest,
} from '@atlassian/jira-issue-view-store/src/common/actions/issue-fetch-actions.tsx';
import {
	errorMessageSelector,
	errorSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import { WorkflowTransitionsStoreContainer } from '@atlassian/jira-issue-workflow-transitions-services/src/context.tsx';
import { useWorkflowTransitionsActions } from '@atlassian/jira-issue-workflow-transitions-services/src/main.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { useProjectContextActions } from '@atlassian/jira-project-context-service/src/main.tsx';
import { useProjectPermissionsActions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { getDataProvider } from '@atlassian/jira-providers-issue/src/service/index.tsx';
import JiraRelayEnvironmentProvider from '@atlassian/jira-relay-environment-provider/src/index.tsx';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id/src/index.tsx';
import { useJiraSettingsActions } from '@atlassian/jira-settings-service/src/main.tsx';
import { useSubProduct } from '@atlassian/jira-subproduct/src/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { UiModificationsContainer } from '@atlassian/jira-ui-modifications-view-issue-view/src/ui/container/index.tsx';
import { WorklogTimeTrackingContainer } from '@atlassian/jira-worklog-time-tracking/src/services/store/index.tsx';
import { ForceStoreContainer } from '@atlassian/jira-issue-view-forge-service/src/services/context.tsx';
import { StateContainer as IssueSmartSummaryContainer } from '@atlassian/jira-issue-smart-request-summary-state/src/controllers/state/index.tsx';
import { markIssueStartRendering } from '../../../common/metrics/index.tsx';
import FetchScreenIdService from '../fetch-screen-id-service/main.tsx';
import IssueRealtimeSupport from '../issue-realtime-support.tsx';
import ForgeDataStoreInit from '../forge-data-store-init/index.tsx';
import { useFieldChangeSoftRefreshSync } from './field-change-soft-refresh-sync/index.tsx';
import { useIssueKeyMismatchListener } from './issuekey-mismatch-listener/index.tsx';
import { CombineStateContainers } from './state-containers/index.tsx';
import type { Props } from './types.tsx';
import { useAnalyticsService } from './use-analytics-service/index.tsx';
import {
	getContext,
	getIssueContext,
	getStore,
	setup,
	useScopedStoreActions,
	useSweetStateToReduxSynchronization,
} from './utils.tsx';
import {
	toBeCalledOnceFor,
	toBeCalledOnceForCleanup,
	useKeyedRef,
	useStableInitialization,
} from './store-registry.tsx';

const ConditionalCrossFlowProvider = ({
	children,
	isModal,
}: {
	children: ReactNode;
	isModal: boolean;
}) => (!isModal ? <CrossFlowProvider>{children}</CrossFlowProvider> : <>{children}</>);

// used to feature flag sweet-state containers
const EmptyContainer = ({ children }: { children: ReactNode; scope: string }) => <>{children}</>;

type ErrorWithMetadata = Error & { errorAttributes?: ErrorMessageUi };

// TODO Cleanup with JIV-14818
const ReduxErrorListenerInnerContent = (props: {
	error: string | null;
	errorMessage: ErrorMessageUi;
}) => {
	const { error, errorMessage } = props;

	if (error) {
		const errorWithMetadata: ErrorWithMetadata = new Error(error);
		errorWithMetadata.errorAttributes = errorMessage;

		throw errorWithMetadata;
	}
	return null;
};

const ReduxErrorListener = connect(
	(state: State) => ({
		error: errorSelector(state),
		errorMessage: errorMessageSelector(state),
	}),
	{},
)(ReduxErrorListenerInnerContent);

const AppProvider = (props: Props) => {
	const {
		analyticsSource,
		backButtonUrl,
		boardId,
		boardType,
		dispatchExternalActionRef,
		estimationStatistic,
		isLoadedWithPage,
		isModal,
		issueKey,
		isSpaEnabled,
		loginRedirectUrl,
		rapidViewId,
		rapidViewMap,
		viewMode,
		children,
		onIssueKeyChange,
	} = props;

	const { prefetchedResourceManager, issueAggQueryData } = props;
	const tenantContext = useTenantContext();
	const dataProvider = getDataProvider();
	const route = useCurrentRoute();
	const subProduct = useSubProduct();

	const [, recentIssuesActions] = useRecentIssueActions();
	const [, fieldsConfigActions] = useFieldConfigActions();
	const [, issueRefreshServiceActions] = useIssueRefreshServiceActions();
	const [, fieldsValueActions] = useFieldsValuesActions();

	const issueRefreshLoadingActionsRef = useScopedStoreActions(useIssueRefreshLoadingActions);

	const [, issueViewNonCriticalDataActions] = fg(
		'move_iv_noncriticalgiraquery_out_of_critical_path',
	)
		? []
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useIssueViewNonCriticalData(issueKey, prefetchedResourceManager);

	const [, userPreferenceActions] = useUserPreferencesActions();

	const [, attachmentActions] = useIssueAttachmentsActions();
	const [, issueScrollActions] = useIssueScrollActions();
	const [, permissionActions] = useProjectPermissionsActions();
	const [, mediaContextActions] = useMediaContextActions();
	const [, issueContextActions] = useIssueContextActions();
	const [, issueViewLayoutActions] = useIssueLayoutActions();
	const [, forgeActions] = useForgeActions();
	const [, ecosystemActions] = useEcosystemActions();
	const [, jiraSettingsActions] = useJiraSettingsActions();
	const [, projectContextActions] = useProjectContextActions();
	const [, workflowTransitionsActions] = useWorkflowTransitionsActions();
	const associatedIssuesContextActions = useAssociatedIssuesContextActions();

	const [, issueViewFieldUpdateActions] = useIssueViewFieldUpdateEvents();
	const { registerEventHandler } = issueViewFieldUpdateActions;

	const { data: orgId } = useOrgId();

	useEffect(() => {
		const unregister = registerEventHandler('onChange', props.onChange);
		return () => unregister();
	}, [props.onChange, registerEventHandler]);

	const stableRef = useKeyedRef(props.issueKey);

	// We only need to create the store once
	const [store, stableInitializationCleanup] = useStableInitialization(stableRef, () =>
		getStore(
			props,
			tenantContext,
			subProduct,
			dataProvider,
			recentIssuesActions,
			issueRefreshServiceActions,
			fieldsValueActions,
			fieldsConfigActions,
			issueViewLayoutActions,
			issueViewFieldUpdateActions,
			forgeActions,
			ecosystemActions,
			userPreferenceActions,
			prefetchedResourceManager,
			attachmentActions,
			issueScrollActions,
			permissionActions,
			jiraSettingsActions,
			projectContextActions,
			issueContextActions,
			workflowTransitionsActions,
			orgId,
			mediaContextActions,
			issueRefreshLoadingActionsRef,
			associatedIssuesContextActions,
			fg('move_iv_noncriticalgiraquery_out_of_critical_path')
				? undefined
				: issueViewNonCriticalDataActions,
		),
	);

	useSweetStateToReduxSynchronization(store);

	useFieldChangeSoftRefreshSync(store);

	// eslint-disable-next-line react-hooks/rules-of-hooks
	fg('one_event_rules_them_all_fg') && useAnalyticsService(store);

	const { setStore } = useStoreActionsForIssueViewShortcutActions_DO_NOT_USE();

	useEffect(() => {
		setStore(store);
		return () => {
			setStore(null);
		};
	}, [store, setStore]);

	const dispatchExternalAction = useCallback(
		(action: ExternalAction) => {
			const triggeredAction = transformExternalAction(action);
			store.dispatch(triggeredAction);
		},
		[store],
	);

	useMemo(() => {
		toBeCalledOnceFor(store, () =>
			setup(
				props,
				tenantContext,
				dataProvider,
				store,
				recentIssuesActions,
				fieldsValueActions,
				fieldsConfigActions,
				userPreferenceActions,
				prefetchedResourceManager,
				dispatchExternalAction,
				attachmentActions,
				issueContextActions,
				permissionActions,
				route,
				issueViewLayoutActions,
				forgeActions,
				ecosystemActions,
				jiraSettingsActions,
				projectContextActions,
				orgId,
				associatedIssuesContextActions,
			),
		);
		// Equivalent to the constructor
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(
		() => () => {
			// clear UI store state
			store.dispatch(resetQuickAddClickedCount());

			const state = store.getState();
			if (state.ui.loadingStage === 'COMPLETE') {
				recentIssuesActions.setRecentIssue(issueKey, state);
			}
		},
		[issueKey, recentIssuesActions, store],
	);

	const isFirstRun = useRef(true);

	useEffect(() => {
		if (!isFirstRun.current) {
			store.dispatch(setContext(getContext(props, tenantContext, orgId)));
			issueContextActions.setIssueContext(getIssueContext(props));
		}

		// There is no need of setting the context on the first run
		// We are explicitly listing which props should cause setContext to be re-dispatched
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		backButtonUrl,
		issueKey,
		loginRedirectUrl,
		rapidViewId,
		boardType,
		boardId,
		isLoadedWithPage,
		isModal,
		rapidViewMap,
		estimationStatistic,
		analyticsSource,
		viewMode,
		orgId,
	]);

	useEffect(() => {
		// Refresh the issue in the background for recent issues
		if (!isFirstRun.current) {
			const recentIssue = recentIssuesActions.getRecentIssue(props.issueKey);
			if (recentIssue) {
				store.dispatch(refreshIssueRequest());
			}
		}
		isFirstRun.current = false;
		return () => {
			// When switching issueKeys, cancel any prior refreshRequests.
			// Previously, switchMap handled race conditions by cancelling the last observable.
			// However, with the introduction of throttleTime, this behavior has changed.
			// Therefore, we must manually cancel observables scheduled on the previous issue view.
			store.dispatch(cancelRefreshIssueRequest());
		};
	}, [props.issueKey, recentIssuesActions, store]);

	const disposeTimeout = useRef<number>();
	useEffect(
		() => {
			if (fg('jfp_magma_jira-issue-view-redux-store')) {
				// cancel dispose event
				if (disposeTimeout.current) {
					clearTimeout(disposeTimeout.current);
					disposeTimeout.current = undefined;
				}
				// connect(or re-connect) external consumers to redux store
				if (dispatchExternalActionRef) {
					dispatchExternalActionRef(dispatchExternalAction);
				}
			}
			// componentWillUnmount
			return () => {
				if (isSpaEnabled === false || isSpaEnabled === null || isSpaEnabled === undefined) {
					// we put a setTimeout here as it allows any other unmount actions
					// to be called and to take effect to cleanup after themselves
					// dispose action stops redux epics and store becomes fully dysfunctional after that
					disposeTimeout.current = +setTimeout(() => {
						store.dispatch(DISPOSE_ACTION);
						// this operation does nothing in production mode
						disposeDispatch(store);

						stableInitializationCleanup?.();
						toBeCalledOnceForCleanup?.(store);
					});
				}

				// disconnect external consumers to redux store
				if (dispatchExternalActionRef) {
					dispatchExternalActionRef(null);
				}
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	useIssueKeyMismatchListener({
		issueKeyFromProps: issueKey,
		issueKeyFromData: issueAggQueryData?.jira?.issueByKey?.key,
		onIssueKeyChange,
	});

	markIssueStartRendering();
	const childrenOrCallChildrenFunction =
		typeof children === 'function'
			? children(issueAggQueryData?.jira?.issueByKey ?? null, issueAggQueryData)
			: children;

	const core = (
		<OnboardingTourContainer>
			{/* Remove when IssueStateContainers used */}
			<CombineStateContainers
				scope={issueKey}
				containers={[
					WorklogTimeTrackingContainer,
					UiModificationsContainer,
					IssueFieldEditingContextProvider,
					...(fg('move_forge_data_fetch_to_main_issue_agg') ? [ForceStoreContainer] : []),
					...(fg('jira_comment_summary_container_update') ? [IssueSmartSummaryContainer] : []),
				]}
			>
				<MultipleEntrypointsContextProvider>
					<ConditionalCrossFlowProvider isModal={isModal}>
						<AppBase store={store} hasErrorBoundary={false}>
							<>
								{fg('move_forge_data_fetch_to_main_issue_agg') && (
									<ForgeDataStoreInit rootRelayFragment={issueAggQueryData} />
								)}
								<IssueRealtimeSupport />
								{/* JIV-18779: remove FetchScreenIdService on cleanup of FG issue-view-remove-viewscreenidquery */}
								<FetchScreenIdService />
								<ReduxErrorListener />
								{childrenOrCallChildrenFunction}
							</>
						</AppBase>
					</ConditionalCrossFlowProvider>
				</MultipleEntrypointsContextProvider>
			</CombineStateContainers>
		</OnboardingTourContainer>
	);

	return <IssueKeyboardShortcutsContainer>{core}</IssueKeyboardShortcutsContainer>;
};

const ScopedAppProvider = (props: Props) => {
	// In order to override the relay environment in storybook
	// we need to make it injectable.
	// We probably should use the useEnvironment() hook instead.
	// Not doing it now as it would be a change to production code that would
	// likely need a feature flag.
	// https://jdog.jira-dev.com/browse/BENTO-11548

	// TODO: apply isolation when impact known https://jplat.atlassian.net/browse/JIV-14663
	// const MaybeStateContainer = IssueStateContainers()
	const MaybeStateContainer = EmptyContainer;

	return (
		<WorkflowTransitionsStoreContainer scope={props.issueKey}>
			<JiraRelayEnvironmentProvider>
				<ClipboardBoundary>
					<IssueRefreshLoadingContainer scope={props.issueKey}>
						<MaybeStateContainer scope={props.issueKey}>
							<AppProvider {...props} />
						</MaybeStateContainer>
					</IssueRefreshLoadingContainer>
				</ClipboardBoundary>
			</JiraRelayEnvironmentProvider>
		</WorkflowTransitionsStoreContainer>
	);
};

export default ScopedAppProvider;
