import { fireOperationalAnalyticsDeferred } from '@atlassian/jira-product-analytics-bridge';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { fetchEntityLimits } from '../../../../services/polaris-api/limits/index.tsx';
import type { State, Props } from '../../types.tsx';

export const loadEntityLimits =
	(): Action<State, Props> =>
	async ({ getState, setState }, { createAnalyticsEvent }) => {
		const {
			meta: { loaded, loading },
		} = getState();

		// Fetch only once
		if (loaded || loading) {
			return;
		}

		setState({
			meta: {
				loaded: false,
				loading: true,
			},
			entityLimits: undefined,
		});

		try {
			const entityLimits = await fetchEntityLimits();
			setState({
				meta: {
					loaded: true,
					loading: false,
				},
				entityLimits,
			});
		} catch {
			fireOperationalAnalyticsDeferred(createAnalyticsEvent({}), 'loadLimits failure');
		}
	};
