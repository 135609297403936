import type { Props } from './types.tsx';

/*
 * This component will NOT render children on the server when the feature flag is on. It will render the fallback instead.
 * The server implementation is in RenderFallbackOnServer.server.tsx which is used in SSR builds.
 * This is so we can stop rendering somewhere down the component tree in order to iteratively deliver issue view SSR.
 * This component should be used carefully to ensure we don't break existing components that are already SSR'd
 *
 * This client version does nothing, **always** returning children.
 */
export function RenderFallbackOnServer({ children }: Props) {
	return children;
}
