import React from 'react';
import { styled } from '@compiled/react';

import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIsModal } from '@atlassian/jira-issue-context-service/src/main.tsx';
import Avatar from '@atlassian/jira-issue-view-common/src/skeleton/avatar.tsx';
import ContainerMask from '@atlassian/jira-issue-view-common/src/skeleton/container-mask-view.tsx';
import SkeletonBackground, {
	CleanDiv,
} from '@atlassian/jira-issue-view-common/src/skeleton/skeleton-background-view.tsx';
import SkeletonContainer from '@atlassian/jira-issue-view-common/src/skeleton/skeleton-container-view.tsx';
import TextLineMask from '@atlassian/jira-issue-view-common/src/skeleton/text-line-mask-view.tsx';
import { useIsEmbedMode } from '@atlassian/jira-issue-view-embed-mode/src/index.tsx';

const activityItemSkeleton = (isModal: boolean) => (
	<>
		<AvatarWrapper isModal={isModal}>
			<Avatar isModal={isModal} />
		</AvatarWrapper>
		<Divider isModal={isModal} />
		<ContainerMask isModal={isModal} paddingTop={0} paddingBottom={0}>
			<ContainerMask isModal={isModal} paddingTop={1} paddingBottom={5}>
				<TextLineMask isModal={isModal} fontSize={14} lineHeight={16} intrusion={250} />
			</ContainerMask>
			<ContainerMask isModal={isModal} paddingTop={4} paddingBottom={0}>
				<TextLineMask isModal={isModal} fontSize={24} lineHeight={24} intrusion={0} />
			</ContainerMask>
			<FillerMask isModal={isModal} />
		</ContainerMask>
	</>
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => {
	const isModal = useIsModal();
	const isEmbedMode = useIsEmbedMode();
	const isLayered = isModal || isEmbedMode;

	return (
		<>
			<UFOLoadHold name="activity-feed-skeleton" />
			<Container>
				<SkeletonBackground />
				<FormContainer isModal={isLayered} paddingTop={0} paddingBottom={0}>
					{activityItemSkeleton(isLayered)}
				</FormContainer>
				<FormContainer isModal={isLayered} paddingTop={0} paddingBottom={0}>
					{activityItemSkeleton(isLayered)}
				</FormContainer>
			</Container>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled(SkeletonContainer)({
	flexDirection: 'column',
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FormContainer = styled(ContainerMask)({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Divider = styled(CleanDiv)({
	width: '9px',
	flexShrink: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FillerMask = styled(CleanDiv)({
	height: '22px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarWrapper = styled.div<{ isModal: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderBottom: (props) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		`${gridSize * 4}px solid ${
			props.isModal ? token('elevation.surface.overlay') : token('elevation.surface')
		}`,
	height: '40px',
	flexShrink: 0,
});
