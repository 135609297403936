import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	atlassianIntelligence: {
		id: 'issue-improve-issue-dropdown.atlassian-intelligence',
		defaultMessage: 'Atlassian Intelligence',
		description: 'The content to be displayed in the dropdown for improve issue',
	},
	buttonImproveIssue: {
		id: 'issue-improve-issue-dropdown.button-improve-issue',
		defaultMessage: 'Improve issue',
		description: 'Button label for improving issue in the quick add list',
	},
	aiSuggestRelatedResourcesLabel: {
		id: 'issue-improve-issue-dropdown.ai-suggest-related-resources-label',
		defaultMessage: 'Suggest Confluence pages',
		description: 'The label to be used on the suggest related resources to trigger the AI feature',
	},
	aiSuggestRelatedContentLabel: {
		id: 'issue-improve-issue-dropdown.ai-suggest-related-content-label',
		defaultMessage: 'Suggest Confluence content',
		description: 'The label to be used on the suggest related content to trigger the AI feature',
	},
	aiSuggestRelatedIssuesLabel: {
		id: 'issue-improve-issue-dropdown.ai-suggest-related-issues-label',
		defaultMessage: 'Show similar issues',
		description: 'The label to be used on the suggest related issues to trigger the AI feature',
	},
	aiSuggestImproveDescriptionLabel: {
		id: 'issue-improve-issue-dropdown.ai-suggest-improve-description-label',
		defaultMessage: 'Improve issue description',
		description: 'The label to be used on the improve issue description to trigger the AI feature',
	},
	aiSuggestSummarizeCommentsLabel: {
		id: 'issue-improve-issue-dropdown.ai-suggest-summarize-comments-label',
		defaultMessage: 'Summarize comments',
		description: 'The label to be used on the summarise comments to trigger the AI feature',
	},
	aiSuggestSummarizeCommentsLabelExperiment: {
		id: 'issue-improve-issue-dropdown.ai-suggest-summarize-comments-label-experiment-non-final',
		defaultMessage: 'Summarize issue',
		description: 'The label to be used on the summarise comments to trigger the AI feature',
	},
	aiSuggestSummarizeCommentsLabelExperimentIssueTermRefresh: {
		id: 'issue-improve-issue-dropdown.ai-suggest-summarize-comments-label-experiment-non-final-issue-term-refresh',
		defaultMessage: 'Summarize work item',
		description: 'The label to be used on the summarise comments to trigger the AI feature',
	},
	aiSuggestChildIssuesLabel: {
		id: 'issue-improve-issue-dropdown.ai-suggest-child-issues-label',
		defaultMessage: 'Suggest child issues',
		description: 'The label to be used on the suggest child issues to trigger the AI feature',
	},
	aiSuggestRelatedResourcesDisabledMessage: {
		id: 'issue-improve-issue-dropdown.ai-suggest-related-resources-disabled-message',
		defaultMessage:
			'We can’t suggest related Confluence pages because linking pages to issues is disabled on this site.',
		description: 'The message for disabled state of the suggest related resources AI feature',
	},
	aiSuggestRelatedContentDisabledMessage: {
		id: 'issue-improve-issue-dropdown.ai-suggest-related-content-disabled-message',
		defaultMessage:
			'We can’t suggest related Confluence content because linking pages to issues is disabled on this site.',
		description: 'The message for disabled state of the suggest related resources AI feature',
	},
	aiSuggestRelatedResourcesDisabledMessageIssueTermRefresh: {
		id: 'issue-improve-issue-dropdown.ai-suggest-related-resources-disabled-message-issue-term-refresh',
		defaultMessage:
			'We can’t suggest related Confluence pages because linking pages to work items is disabled on this site.',
		description: 'The message for disabled state of the suggest related resources AI feature',
	},
	aiSuggestRelatedContentDisabledMessageIssueTermRefresh: {
		id: 'issue-improve-issue-dropdown.ai-suggest-related-content-disabled-message-issue-term-refresh',
		defaultMessage:
			'We can’t suggest related Confluence content because linking pages to work items is disabled on this site.',
		description: 'The message for disabled state of the suggest related resources AI feature',
	},
	aiSuggestRelatedIssuesDisabledMessage: {
		id: 'issue-improve-issue-dropdown.ai-suggest-related-issues-disabled-message-v2',
		defaultMessage:
			'We can’t suggest similar issues because issue linking is turned off on this site.',
		description: 'The message for disabled state of the suggest related issues AI feature',
	},
	aiSuggestRelatedIssuesIssueLinkTypeDisabledMessage: {
		id: 'issue-improve-issue-dropdown.ai-suggest-related-issues-issue-link-type-disabled-message-v2',
		defaultMessage:
			'We can’t suggest similar issues because your site doesn’t have the <b>relates to</b> issue link type.',
		description:
			'The message for disabled state for issue link type of the suggest related issues AI feature',
	},
	aiSuggestImproveDescriptionDisabledMessage: {
		id: 'issue-improve-issue-dropdown.ai-suggest-improve-description-disabled-message',
		defaultMessage: 'First enter an issue description to improve it with AI.',
		description: 'The message for disabled state of the improve issue description AI feature',
	},
	aiSuggestSummarizeCommentsDisabledMessage: {
		id: 'issue-improve-issue-dropdown.ai-suggest-summarize-comments-disabled-message',
		defaultMessage: 'No comments on this issue yet, so can’t summarize them with AI.',
		description: 'The message for disabled state of the summarise comments AI feature',
	},
	aiSuggestChildIssuesDisabledState: {
		id: 'issue-improve-issue-dropdown.ai-suggest-child-issues-disabled-state',
		defaultMessage: 'Can’t add child issues to a sub-task.',
		description: 'The message for disabled state of the suggest child issues AI feature',
	},
	buttonImproveIssueIssueTermRefresh: {
		id: 'issue-improve-issue-dropdown.button-improve-issue-issue-term-refresh',
		defaultMessage: 'Improve work item',
		description: 'Button label for improving issue in the quick add list',
	},
	aiSuggestRelatedIssuesLabelIssueTermRefresh: {
		id: 'issue-improve-issue-dropdown.ai-suggest-related-issues-label-issue-term-refresh',
		defaultMessage: 'Show similar work items',
		description: 'The label to be used on the suggest related issues to trigger the AI feature',
	},
	aiSuggestImproveDescriptionLabelIssueTermRefresh: {
		id: 'issue-improve-issue-dropdown.ai-suggest-improve-description-label-issue-term-refresh',
		defaultMessage: 'Improve description',
		description: 'The label to be used on the improve issue description to trigger the AI feature',
	},
	aiSuggestChildIssuesLabelIssueTermRefresh: {
		id: 'issue-improve-issue-dropdown.ai-suggest-child-issues-label-issue-term-refresh',
		defaultMessage: 'Suggest child work items',
		description: 'The label to be used on the suggest child issues to trigger the AI feature',
	},
	aiSuggestRelatedIssuesDisabledMessageIssueTermRefresh: {
		id: 'issue-improve-issue-dropdown.ai-suggest-related-issues-disabled-message-issue-term-refresh',
		defaultMessage:
			'We can’t suggest similar work items because work item linking is turned off on this site.',
		description: 'The message for disabled state of the suggest related issues AI feature',
	},
	aiSuggestRelatedIssuesIssueLinkTypeDisabledMessageIssueTermRefresh: {
		id: 'issue-improve-issue-dropdown.ai-suggest-related-issues-issue-link-type-disabled-message-issue-term-refresh',
		defaultMessage:
			'We can’t suggest similar work items because your site doesn’t have the <b>relates to</b> work item link type.',
		description:
			'The message for disabled state for issue link type of the suggest related issues AI feature',
	},
	aiSuggestImproveDescriptionDisabledMessageIssueTermRefresh: {
		id: 'issue-improve-issue-dropdown.ai-suggest-improve-description-disabled-message-issue-term-refresh',
		defaultMessage: 'First enter a description to improve it with AI.',
		description: 'The message for disabled state of the improve issue description AI feature',
	},
	aiSuggestSummarizeCommentsDisabledMessageIssueTermRefresh: {
		id: 'issue-improve-issue-dropdown.ai-suggest-summarize-comments-disabled-message-issue-term-refresh',
		defaultMessage: 'No comments on this work item yet, so can’t summarize them with AI.',
		description: 'The message for disabled state of the summarise comments AI feature',
	},
	aiSuggestChildIssuesDisabledStateIssueTermRefresh: {
		id: 'issue-improve-issue-dropdown.ai-suggest-child-issues-disabled-state-issue-term-refresh',
		defaultMessage: 'Can’t add child work items to a subtask.',
		description: 'The message for disabled state of the suggest child issues AI feature',
	},
	aiSuggestRelatedIssuesLabelReviewed: {
		id: 'issue-improve-issue-dropdown.ai-suggest-related-issues-label-reviewed',
		defaultMessage: 'Link similar work items',
		description: 'The label to be used on link related issues to trigger the AI feature',
	},
	aiSuggestRelatedIssuesTooltipReviewed: {
		id: 'issue-improve-issue-dropdown.ai-suggest-related-issues-tooltip-reviewed',
		defaultMessage: 'Links work items with a <b>relates to</b> link type.',
		description: 'The tooltip to be used on link related issues to trigger the AI feature',
	},
	aiSuggestRelatedIssuesDisabledMessageReviewed: {
		id: 'issue-improve-issue-dropdown.ai-suggest-related-issues-disabled-message-reviewed',
		defaultMessage:
			'We can’t find similar issues because issue linking is turned off on this site.',
		description: 'The message for disabled state of the suggest related issues AI feature',
	},
	aiSuggestRelatedIssueLinkTypeDisabledMessageReviewed: {
		id: 'issue-improve-issue-dropdown.ai-suggest-related-issue-link-type-disabled-message-reviewed',
		defaultMessage:
			'We can’t find similar issues because your site doesn’t have the <b>relates to</b> issue link type.',
		description:
			'The message for disabled state for issue link type of the suggest related issues AI feature',
	},
	aiSuggestRelatedResourcesDisabledMessageReviewed: {
		id: 'issue-improve-issue-dropdown.ai-suggest-related-resources-disabled-message-reviewed',
		defaultMessage:
			'We can’t find Confluence content to link because content linking is disabled on this site.',
		description:
			'The message for disabled state of the suggest related Confluence content AI feature',
	},
	aiLinkRelatedConfluenceLabel: {
		id: 'issue-improve-issue-dropdown.ai-link-related-confluence-label',
		defaultMessage: 'Link Confluence content',
		description:
			'The label to be used on the link related Confluence content to trigger the AI feature',
	},
	aiSuggestRelatedResourcesConfluenceNotLinked: {
		id: 'issue-improve-issue-dropdown.ai-suggest-related-resources-confluence-not-linked',
		defaultMessage:
			'Add Confluence to this site or link it using app links to link related content using Atlassian Intelligence.',
		description:
			'The message for the suggest related resources AI feature when Confluence is not linked',
	},
});
