import mapValues from 'lodash/mapValues';
import { ISSUEID_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { Snippet } from '@atlassian/jira-polaris-domain-insight/src/snippet/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../../types.tsx';

export const refreshInsights =
	(ideaId: LocalIssueId) =>
	({ getState, setState }: StoreActionApi<State>, { projectId, insightsRemote }: Props) => {
		const state = getState();

		const jiraIssueId = state.properties.number[ISSUEID_FIELDKEY][ideaId];

		if (
			jiraIssueId === undefined ||
			projectId === undefined ||
			!insightsRemote.fetchInsightsForIssue
		) {
			return;
		}

		insightsRemote
			.fetchInsightsForIssue({
				issueId: String(jiraIssueId),
			})
			.then((insights) => {
				setState({
					properties: {
						...getState().properties,
						insights: {
							...getState().properties.insights,
							[ideaId]: insights || [],
						},
					},
				});
			});
	};

const optimisicEmptySnippetRefreshState = {
	error: null,
	errorType: null,
	last: null,
	next: null,
} as const;

const updateSnippet = (
	state: State,
	snippetId: string,
	update: (arg1: Snippet) => Snippet,
): State => ({
	...state,
	properties: {
		...state.properties,
		insights: mapValues(state.properties.insights, (insights) =>
			insights.map((insight) => {
				let { updated } = insight;
				const snippets = insight.snippets.map((snippet: Snippet) => {
					if (snippet.id !== snippetId) {
						return snippet;
					}
					const updatedSnippet = update(snippet);
					updated = updatedSnippet.updated;
					return updatedSnippet;
				});

				return {
					...insight,
					updated,
					snippets,
				};
			}),
		),
	},
});

export const refreshSnippet =
	(snippetId: string) =>
	async ({ getState, setState }: StoreActionApi<State>, { projectId, insightsRemote }: Props) => {
		if (projectId === undefined) {
			return;
		}

		setState(
			updateSnippet(getState(), snippetId, (snippet) => ({
				...snippet,
				refresh: {
					...(snippet.refresh || optimisicEmptySnippetRefreshState),
					queued: 'manual',
				},
			})),
		);

		insightsRemote
			.refreshSnippet?.({
				id: snippetId,
			})
			.then((snippets) => {
				setState(
					snippets
						.filter(Boolean)
						.reduce((acc, snippet) => updateSnippet(acc, snippet.id, () => snippet), getState()),
				);
			});
	};
