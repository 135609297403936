import {
	setUserProperties,
	getUserProperty,
} from '@atlassian/jira-common-rest/src/api/latest/user-properties/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { createLocalExpirableStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';

const localStorageManager = (accountId: AccountId, propertyKey: string) => {
	const localStorageProvider = createLocalExpirableStorageProvider('placeholderTemplateStorage');
	const STORAGE_KEY = accountId ? `${propertyKey}_${accountId}` : propertyKey;
	const getLocalStorage = () => localStorageProvider.get(STORAGE_KEY);
	const LOCAL_STORAGE_EXPIRY_MS = 24 * 60 * 60 * 1000; // one day
	const setLocalStorage = (value: boolean) =>
		localStorageProvider.set(STORAGE_KEY, value, Date.now() + LOCAL_STORAGE_EXPIRY_MS);

	return { getLocalStorage, setLocalStorage };
};

export const getPlaceholderTemplateUserProperty = async (
	accountId: AccountId,
	propertyKey: string,
) => {
	const { getLocalStorage, setLocalStorage } = localStorageManager(accountId, propertyKey);

	const hasDismissed = getLocalStorage();
	if (hasDismissed !== undefined) {
		return hasDismissed;
	}

	try {
		const property = await getUserProperty(accountId, propertyKey);
		setLocalStorage(!!property);
		return !!property;
	} catch (error) {
		if (error instanceof FetchError) {
			if (error.statusCode === 401 || error.statusCode === 403) {
				return true;
			}

			// 404: API deliberately returns 404 if user property is not found, which implies the property has not been set
			if (error.statusCode === 404) {
				setLocalStorage(false);
				return false;
			}
		}

		let adControlsError: Error;

		if (error instanceof FetchError) {
			adControlsError = new Error(
				`Fetch Error, failed to get user property: ${propertyKey}, status code was: ${error.statusCode}`,
			);
		} else {
			adControlsError = new Error(`Failed to get user property: ${propertyKey}`, {
				cause: error,
			});
		}

		fireErrorAnalytics({
			meta: {
				id: 'userPropertiesFetchError',
				packageName: 'jiraIssueViewBase',
				teamName: 'starburst',
			},
			error: adControlsError,
		});

		return true;
	}
};

export const setPlaceholderTemplateUserProperty = async (
	accountId: AccountId,
	propertyKey: string,
) => {
	const { setLocalStorage } = localStorageManager(accountId, propertyKey);
	setLocalStorage(true);
	await setUserProperties(accountId, propertyKey, JSON.stringify(true));
};
