/**
 * @generated SignedSource<<0d77deec72f584a15f220244b0295554>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraTimeFormat = "DAYS" | "HOURS" | "PRETTY" | "%future added value";
export type JiraTimeUnit = "DAY" | "HOUR" | "MINUTE" | "WEEK" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditViewNew_timeTrackingSettingsfragmentRef$data = {
  readonly defaultFormat: JiraTimeFormat | null | undefined;
  readonly defaultUnit: JiraTimeUnit | null | undefined;
  readonly workingDaysPerWeek: number | null | undefined;
  readonly workingHoursPerDay: number | null | undefined;
  readonly " $fragmentType": "originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditViewNew_timeTrackingSettingsfragmentRef";
};
export type originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditViewNew_timeTrackingSettingsfragmentRef$key = {
  readonly " $data"?: originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditViewNew_timeTrackingSettingsfragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditViewNew_timeTrackingSettingsfragmentRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditViewNew_timeTrackingSettingsfragmentRef",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "workingHoursPerDay"
    },
    {
      "kind": "ScalarField",
      "name": "workingDaysPerWeek"
    },
    {
      "kind": "ScalarField",
      "name": "defaultFormat"
    },
    {
      "kind": "ScalarField",
      "name": "defaultUnit"
    }
  ],
  "type": "JiraTimeTrackingSettings"
};

(node as any).hash = "f00a33433ce42edb96f82d15e00a06b6";

export default node;
