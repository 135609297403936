import {
	CUSTOMER_SERVICE_PROJECT,
	SERVICE_DESK_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIsIssueOfDeveloperEscalationPractice } from '@atlassian/jira-issue-field-servicedesk-practices/src/services/use-practices-field-value/index.tsx';
import {
	useProjectKey,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';

export const useIsDeveloperEscalationsEnabled = (): boolean => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const [{ canEditIssues }] = useProjectPermissions(projectKey);
	const isIssueOfDeveloperEscalations = useIsIssueOfDeveloperEscalationPractice();
	const projectType = useProjectType(projectKey);
	const isServiceDeskProject = projectType === SERVICE_DESK_PROJECT;

	if (fg('jcs_developer_escalations')) {
		switch (projectType) {
			case SERVICE_DESK_PROJECT:
				return !isIssueOfDeveloperEscalations && canEditIssues;
			case CUSTOMER_SERVICE_PROJECT:
				return canEditIssues;
			default:
				return false;
		}
	}

	return isServiceDeskProject && !isIssueOfDeveloperEscalations && canEditIssues;
};
