import differenceBy from 'lodash/differenceBy';
import intersectionBy from 'lodash/intersectionBy';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { ConnectionsBulkRequestInput } from '@atlassian/jira-polaris-remote-issue/src/services/jira/connection/types.tsx';
import type { ConnectionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/connection/types.tsx';
import {
	ISSUEID_FIELDKEY,
	ISSUETYPE_FIELDKEY,
	KEY_FIELDKEY,
	SUMMARY_FIELDKEY,
} from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { IssueTypeFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/issue-type/types.tsx';
import { createGetConnectionFieldIssueIds } from '../../selectors/connection.tsx';
import { getJiraIdToLocalIssueId, getLocalIssueIdToJiraId } from '../../selectors/issue-ids.tsx';
import type { Props, State } from '../../types.tsx';
import { isConnectionFieldValue } from '../../utils/field-mapping/connection/index.tsx';
import { updateConnectionsProperties } from '../common/connection/index.tsx';
import { generateLocalIssueId } from '../../utils/local-id.tsx';
import { getFieldMappings } from '../../selectors/fields.tsx';
import {
	createGetUpdateIssueFieldsBulkProgress,
	getFailedConnections,
	getFilteredIssuesForConnections,
	logUpdateIssueConnectionsError,
	updateFieldProperties,
} from './utils.tsx';

class UpdateIssueConnectionsError extends Error {}

type UpdateIssueConnectionsRequestCommon = {
	issuesToConnect?: ConnectionFieldValue[];
	issuesToDisconnect?: ConnectionFieldValue[];
	onError?: (error: Error) => void;
};

type UpdateIssueConnectionsRequestIssueId =
	| {
			localIssueId: LocalIssueId;
	  }
	| { issueId: string };

export type UpdateIssueConnectionsRequest = UpdateIssueConnectionsRequestCommon &
	UpdateIssueConnectionsRequestIssueId;

const getIssueIds = ({
	issueIdArgs,
	localIssueIdToJiraId,
	jiraIdToLocalIssueId,
}: {
	issueIdArgs: UpdateIssueConnectionsRequestIssueId;
	localIssueIdToJiraId: ReturnType<typeof getLocalIssueIdToJiraId>;
	jiraIdToLocalIssueId: ReturnType<typeof getJiraIdToLocalIssueId>;
}): { sourceIssueId: string; localIssueId: LocalIssueId } => {
	if ('localIssueId' in issueIdArgs) {
		return {
			localIssueId: issueIdArgs.localIssueId,
			sourceIssueId: localIssueIdToJiraId[issueIdArgs.localIssueId],
		};
	}

	return {
		localIssueId: jiraIdToLocalIssueId[Number(issueIdArgs.issueId)],
		sourceIssueId: issueIdArgs.issueId,
	};
};

export const updateIssueConnections =
	({
		issuesToConnect = [],
		issuesToDisconnect = [],
		onError,
		...issueIdArgs
	}: UpdateIssueConnectionsRequest): Action<State, Props> =>
	async ({ setState, getState }, props) => {
		const state = getState();
		const previousProperties = { ...state.properties };
		const localIssueIdToJiraId = getLocalIssueIdToJiraId(state, props);
		const jiraIdToLocalIssueId = getJiraIdToLocalIssueId(state);

		const { sourceIssueId, localIssueId } = getIssueIds({
			issueIdArgs,
			jiraIdToLocalIssueId,
			localIssueIdToJiraId,
		});

		const issuesToConnectFiltered = getFilteredIssuesForConnections(
			localIssueId,
			issuesToConnect,
			state,
			props,
		);

		if (!issuesToConnectFiltered.length && !issuesToDisconnect.length) {
			return;
		}

		const { issuesRemote, onIssueUpdateFailed } = props;

		const optimisticProperties = updateConnectionsProperties({
			issuesToDisconnect,
			issuesToConnect: issuesToConnectFiltered,
			localIssueId,
			state,
			props,
		});

		setState({
			properties: optimisticProperties,
		});

		let failedAddConnections: ConnectionFieldValue[] | undefined;
		let failedDeleteConnections: ConnectionFieldValue[] | undefined;

		try {
			const addConnectionsPromise =
				issuesToConnectFiltered.length > 0
					? issuesRemote
							.createConnections({
								issueFrom: sourceIssueId,
								issueTo: issuesToConnectFiltered.map(({ id }) => id),
							})
							.then(({ failures }) =>
								getFailedConnections(failures, issuesToConnectFiltered, 'create'),
							)
					: Promise.resolve([]);

			const deleteConnectionsPromise =
				issuesToDisconnect.length > 0
					? issuesRemote
							.deleteConnections({
								issueFrom: sourceIssueId,
								issueTo: issuesToDisconnect.map(({ id }) => id),
							})
							.then(({ failures }) => getFailedConnections(failures, issuesToDisconnect, 'delete'))
					: Promise.resolve([]);

			const [failedAddConnectionsResult, failedDeleteConnectionsResult] = await Promise.allSettled([
				addConnectionsPromise,
				deleteConnectionsPromise,
			]);

			if (failedAddConnectionsResult.status === 'rejected') {
				logUpdateIssueConnectionsError(failedAddConnectionsResult.reason, 'create');
				failedAddConnections = issuesToConnectFiltered;
			} else {
				failedAddConnections = failedAddConnectionsResult.value;
			}

			if (failedDeleteConnectionsResult.status === 'rejected') {
				logUpdateIssueConnectionsError(failedDeleteConnectionsResult.reason, 'delete');
				failedDeleteConnections = issuesToDisconnect;
			} else {
				failedDeleteConnections = failedDeleteConnectionsResult.value;
			}

			if (failedAddConnections.length || failedDeleteConnections.length) {
				throw new UpdateIssueConnectionsError();
			}
		} catch (error) {
			if (error instanceof Error) {
				if (error instanceof UpdateIssueConnectionsError) {
					// revert optimistic update for failed connections
					const revertedProperties = updateConnectionsProperties({
						issuesToDisconnect: failedAddConnections ?? issuesToConnectFiltered,
						issuesToConnect: failedDeleteConnections ?? issuesToDisconnect,
						localIssueId,
						state: {
							...state,
							properties: optimisticProperties,
						},
						props,
					});

					setState({ properties: revertedProperties });
				} else {
					// Log error in case of runtime error caused by code in this action or it's dependencies
					logUpdateIssueConnectionsError(error);
					// and revert all changed properties in case of runtime error
					setState({ properties: previousProperties });
				}

				onIssueUpdateFailed(error);
				onError?.(error);
			}
		}
	};

type UpdateIssueConnectionsBulkMap = Map<
	LocalIssueId,
	{
		issuesToConnect: ConnectionFieldValue[];
		issuesToDisconnect: ConnectionFieldValue[];
	}
>;

export const updateIssueConnectionsBulk =
	(updatesMap: UpdateIssueConnectionsBulkMap, onFinished?: () => void): Action<State, Props> =>
	async ({ setState, getState }, props) => {
		if (updatesMap.size === 0) {
			return;
		}

		const { issuesRemote, onIssueUpdateFailed, onIssueBulkUpdate } = props;

		const previousProperties = { ...getState().properties };
		const optimisticState = { ...getState() };
		const localIssueIdToJiraId = getLocalIssueIdToJiraId(optimisticState, props);

		const addConnections: ConnectionsBulkRequestInput = [];
		const deleteConnections: ConnectionsBulkRequestInput = [];

		updatesMap.forEach(({ issuesToConnect, issuesToDisconnect }, localIssueId) => {
			const issuesToConnectFiltered = getFilteredIssuesForConnections(
				localIssueId,
				issuesToConnect,
				optimisticState,
				props,
			);

			if (!issuesToConnectFiltered.length && !issuesToDisconnect.length) {
				return;
			}

			optimisticState.properties = updateConnectionsProperties({
				issuesToDisconnect,
				issuesToConnect: issuesToConnectFiltered,
				localIssueId,
				state: optimisticState,
				props,
			});

			if (issuesToConnectFiltered.length > 0) {
				addConnections.push({
					issueFrom: localIssueIdToJiraId[localIssueId],
					issueTo: issuesToConnectFiltered.map(({ id }) => id),
				});
			}

			if (issuesToDisconnect.length > 0) {
				deleteConnections.push({
					issueFrom: localIssueIdToJiraId[localIssueId],
					issueTo: issuesToDisconnect.map(({ id }) => id),
				});
			}
		});

		if (addConnections.length === 0 && deleteConnections.length === 0) {
			return;
		}

		setState(optimisticState);

		try {
			const [createConnectionsResponse, deleteConnectionsResponse] = await Promise.all([
				addConnections.length > 0 ? issuesRemote.createConnectionsBulk(addConnections) : undefined,
				deleteConnections.length > 0
					? issuesRemote.deleteConnectionsBulk(deleteConnections)
					: undefined,
			]);

			onIssueBulkUpdate({
				getUpdateIssueFieldsBulkProgress: createGetUpdateIssueFieldsBulkProgress(
					createConnectionsResponse,
					deleteConnectionsResponse,
					props,
				),
				taskId: `${createConnectionsResponse?.taskId ?? ''}-${deleteConnectionsResponse?.taskId ?? ''}`,
				onFinished,
			});
		} catch (error) {
			setState({ properties: previousProperties });

			if (error instanceof Error) {
				logUpdateIssueConnectionsError(error, undefined, true);

				onIssueUpdateFailed(error);
			}
		}
	};

type UpdateConnectionFieldValueRequest = {
	fieldKey: FieldKey;
	localIssueIds: LocalIssueId[];
	newValue: unknown | undefined;
	removeValue?: unknown | undefined;
	appendMultiValues: boolean;
	onError?: (error: Error) => void;
	onConnectionsUpdated?: () => void;
};

export const updateConnectionFieldValue =
	({
		fieldKey,
		localIssueIds,
		newValue: newValueUnknown,
		removeValue: removeValueUnknown,
		appendMultiValues,
		onError,
		onConnectionsUpdated,
	}: UpdateConnectionFieldValueRequest): Action<State, Props, Promise<void>> =>
	async ({ getState, dispatch }, props) => {
		if (localIssueIds.length === 0) {
			return;
		}

		const state = getState();
		const newValue = Array.isArray(newValueUnknown)
			? newValueUnknown.filter(isConnectionFieldValue)
			: [];
		const removeValue = Array.isArray(removeValueUnknown)
			? removeValueUnknown.filter(isConnectionFieldValue)
			: [];

		const updateIssueConnectionsBulkRequest = localIssueIds.reduce<UpdateIssueConnectionsBulkMap>(
			(acc, localIssueId) => {
				const currentConnections = createGetConnectionFieldIssueIds(fieldKey, localIssueId)(
					state,
					props,
				);

				const issuesToConnect = differenceBy(newValue, currentConnections, 'id');
				const issuesToDisconnect = appendMultiValues
					? intersectionBy(removeValue, currentConnections, 'id')
					: differenceBy(currentConnections, newValue, 'id');

				acc.set(localIssueId, { issuesToConnect, issuesToDisconnect });

				return acc;
			},
			new Map(),
		);

		if (localIssueIds.length === 1) {
			const localIssueId = localIssueIds[0];
			await dispatch(
				updateIssueConnections({
					localIssueId,
					issuesToConnect: updateIssueConnectionsBulkRequest.get(localIssueId)?.issuesToConnect,
					issuesToDisconnect:
						updateIssueConnectionsBulkRequest.get(localIssueId)?.issuesToDisconnect,
					onError,
				}),
			);
			onConnectionsUpdated?.();
		} else {
			await dispatch(
				updateIssueConnectionsBulk(updateIssueConnectionsBulkRequest, onConnectionsUpdated),
			);
		}
	};

type AddConnectionIssuesDataRequest = {
	issueKey: string;
	summary: string;
	issueId: string;
	issueType: IssueTypeFieldValue;
}[];

/**
 * Adds issue data to issue store so it can be used for optimistic updates
 * for connection fields. Required in case we're connecting to an issue
 * from a different container.
 */
export const addConnectionIssuesData =
	(issuesData: AddConnectionIssuesDataRequest): Action<State, Props> =>
	async ({ getState, setState }, props) => {
		if (issuesData.length === 0) {
			return;
		}

		const jiraIdToLocalIssueId = getJiraIdToLocalIssueId(getState());

		const fieldMappings = getFieldMappings(getState(), props);

		const keyMapping = fieldMappings[KEY_FIELDKEY];
		const summaryMapping = fieldMappings[SUMMARY_FIELDKEY];
		const issueTypeMapping = fieldMappings[ISSUETYPE_FIELDKEY];
		const issueIdMapping = fieldMappings[ISSUEID_FIELDKEY];

		const newConnectionIds: string[] = [];
		const properties = {
			...getState().properties,
		};

		const updatedProperties = issuesData.reduce((acc, externalIssue) => {
			if (jiraIdToLocalIssueId[Number(externalIssue.issueId)]) {
				return acc;
			}

			const externalIssueLocalId = generateLocalIssueId();
			newConnectionIds.push(externalIssueLocalId);

			let modifiedProperties = acc;
			modifiedProperties = updateFieldProperties(
				modifiedProperties,
				keyMapping,
				externalIssueLocalId,
				externalIssue.issueKey,
			);
			modifiedProperties = updateFieldProperties(
				modifiedProperties,
				summaryMapping,
				externalIssueLocalId,
				externalIssue.summary,
			);
			modifiedProperties = updateFieldProperties(
				modifiedProperties,
				issueTypeMapping,
				externalIssueLocalId,
				externalIssue.issueType,
			);
			modifiedProperties = updateFieldProperties(
				modifiedProperties,
				issueIdMapping,
				externalIssueLocalId,
				externalIssue.issueId,
			);

			return modifiedProperties;
		}, properties);

		setState({
			properties: updatedProperties,
			...(newConnectionIds.length > 0 && {
				connectionIssueIds: [...getState().connectionIssueIds, ...newConnectionIds],
			}),
		});
	};
