import type MarkdownIt from 'markdown-it';
import type StateCore from 'markdown-it/lib/rules_core/state_core.mjs';
import type Token from 'markdown-it/lib/token.mjs';

// Regex test is based on what's documented here:
// https://hello.atlassian.net/wiki/spaces/I/pages/1124693006/AAID+Format
const isPeopleLink = (url: string): boolean => {
	return /^\/people\/[a-zA-Z0-9_\|\-\:]{1,128}$/g.test(url);
};

const getAccountId = (url: string): string | null => {
	const match = url.match(/\/people\/([a-zA-Z0-9_\|\-\:]{1,128})/);
	return match ? match[1] : null;
};

const mentionLinks = (state: StateCore) => {
	const tokens: Token[] = [];

	for (const token of state.tokens) {
		const tokenChildren: Array<Token | null> = [];

		// We're only looking for inline children
		if (token.type !== 'inline' || (token.children && token?.children?.length <= 0)) {
			tokens.push(token);
			continue;
		}

		if (token.children) {
			token.children.forEach((childToken, i) => {
				const url = childToken.attrGet('href') ?? '';
				const discardedToken =
					['link_close', 'text'].includes(childToken.type) &&
					[tokenChildren[i - 1]?.type, tokenChildren[i - 2]?.type].includes('mention');

				// Discarded tokens are preserved as `null` so index
				// cross-referencing between processed and raw tokens may
				// be preserved, however null are filtered out later.
				if (discardedToken) {
					tokenChildren.push(null);
					return;
				}

				if (childToken.type === 'link_open' && isPeopleLink(url)) {
					const mentionText = token.children?.[i + 1]?.content ?? '';
					let mentionToken = new state.Token('mention', '', 0);
					mentionToken.content = '';
					mentionToken.block = false;
					mentionToken.level = childToken.level;
					mentionToken.attrSet('id', `${getAccountId(url)}`);

					if (mentionText) {
						mentionToken.attrSet('text', `@${mentionText}`);
					}

					tokenChildren.push(mentionToken);
					return;
				}

				tokenChildren.push(childToken);
			});
		}

		const filteredTokenChildren = tokenChildren.filter(
			(childToken) => childToken !== null,
		) as Token[];
		token.children = filteredTokenChildren;
		tokens.push(token);
	}

	state.tokens = tokens;
	return;
};

export default (md: MarkdownIt) => md.core.ruler.push('mention', mentionLinks);
