import type { ValueDecoration } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import {
	convertRulesToLogic,
	generateLocalDecorationId,
	isDecorationWithLogic,
} from '@atlassian/jira-polaris-domain-field/src/decoration/utils.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { isGlobalSystemField } from '@atlassian/jira-polaris-domain-field/src/field/utils.tsx';
import type { FieldRemote } from '@atlassian/jira-polaris-remote-field/src/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State } from '../../types.tsx';

type UpdateParams = {
	fieldKey: FieldKey;
	fieldType?: FieldType;
	issueTypeId: string | undefined;
	fieldRemote: FieldRemote;
	existingDecorations: ValueDecoration[];
	newDecorations: ValueDecoration[];
	isGlobalSystemField?: boolean;
};

export const remapValueDecorations = (decorations: ValueDecoration[], fieldType?: FieldType) =>
	decorations.length > 0
		? {
				valueDecorations: decorations.map((decoration) => ({
					...(decoration.backgroundColor ? { backgroundColor: decoration.backgroundColor } : {}),
					...(decoration.emoji ? { emoji: decoration.emoji } : {}),
					highlightContainer: decoration.highlightContainer,
					logic: isDecorationWithLogic(decoration)
						? decoration.logic
						: convertRulesToLogic(decoration.rules, fieldType),
				})),
			}
		: {};

export const performJiraConfigurationUpdate = ({
	fieldKey,
	fieldType,
	issueTypeId,
	fieldRemote,
	existingDecorations,
	newDecorations,
	isGlobalSystemField: isGlobalSystemFieldArg,
}: UpdateParams) => {
	const oldConfiguration = remapValueDecorations(existingDecorations, fieldType);
	const configuration = remapValueDecorations(newDecorations, fieldType);
	return fieldRemote.updateFieldConfiguration({
		fieldKey,
		issueTypeId: issueTypeId ?? '',
		oldConfiguration,
		configuration,
		type: 'updateFieldDecorations',
		isGlobalSystemField: isGlobalSystemFieldArg,
	});
};

export const dualWriteValueDecorationsBasedOnFieldType = async ({
	fieldKey,
	fieldType,
	issueTypeId,
	fieldRemote,
	newValueDecorations,
	getState,
	setState,
}: {
	fieldKey: FieldKey;
	fieldType: FieldType;
	issueTypeId: string | undefined;
	fieldRemote: FieldRemote;
	newValueDecorations: ValueDecoration[];
	getState: StoreActionApi<State>['getState'];
	setState: StoreActionApi<State>['setState'];
}) => {
	const fields = getState().fields;
	const otherField = Object.values(fields).find(
		(field) => field.type === fieldType && field.key !== fieldKey,
	);

	if (!otherField) {
		return;
	}

	const currentValueDecorations = getState().fieldValueDecorations;
	const otherFieldOldValueDecorations = currentValueDecorations[otherField.key];
	const otherFieldNewValueDecorations = newValueDecorations.map((decoration) => ({
		...decoration,
		localDecorationId: generateLocalDecorationId(),
	}));

	setState({
		fieldValueDecorations: {
			...currentValueDecorations,
			[otherField.key]: otherFieldNewValueDecorations,
		},
	});

	try {
		await performJiraConfigurationUpdate({
			fieldKey: otherField.key,
			fieldType,
			issueTypeId,
			fieldRemote,
			existingDecorations: otherFieldOldValueDecorations,
			newDecorations: otherFieldNewValueDecorations,
			isGlobalSystemField: isGlobalSystemField(otherField),
		});
	} catch (error) {
		setState({
			fieldValueDecorations: {
				...getState().fieldValueDecorations,
				[otherField.key]: otherFieldOldValueDecorations,
			},
		});

		throw error;
	}
};
