import React, { useMemo } from 'react';
import noop from 'lodash/noop';
import TrashIcon from '@atlaskit/icon/core/migration/delete--trash';
import type { IntlShape } from '@atlassian/jira-intl';
import GoToButton from '@atlassian/jira-issue-attachments-base/src/ui/goto-button/index.tsx';
import { gridView } from '@atlassian/jira-issue-attachments-base/src/ui/types.tsx';
import { issueAttachmentsDeleteModalEntrypoint } from '@atlassian/jira-issue-attachments-base/src/ui/with-issue-attachments-delete/entrypoint.tsx';
import { ATTACHMENT_PARENT_ISSUE } from '@atlassian/jira-issue-attachments-store/src/common/constants.tsx';
import type {
	AttachmentId,
	AttachmentParent,
} from '@atlassian/jira-issue-attachments-store/src/common/types.tsx';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages.tsx';
import { MAX_COLUMN_SIZE, GRID_ITEM_DIMENSIONS } from './constants.tsx';

const { minWidth: gridItemMinWidth, spacing: gridItemGap } = GRID_ITEM_DIMENSIONS;

/**
 * Breakpoints are determined by the minimum width of the grid container plus padding.
 *
 * Each grid item has a minimum width of 132px and 4px padding, totaling 136px.
 * The grid container has 4px padding on both sides, adding up to 8px.
 *
 * Therefore, the breakpoints are calculated as follows:
 * 2 columns: 136px * 2 + 8px = 280px
 * 3 columns: 136px * 3 + 8px = 424px
 * 4 columns: 136px * 4 + 8px = 568px
 * 5 columns: 136px * 5 + 8px = 712px
 *
 * The grid can display a maximum of 7 columns. Beyond that, grid items will resize without adding new columns.
 *
 * If the grid container's width is less than a breakpoint, the number of columns is reduced by 1.
 * For instance, if the container width is 300px, the grid will display 2 columns instead of 3.
 */
export function getColumnSize(width: number): number {
	const containerGap = 8;
	const gridItemWidth = gridItemMinWidth + gridItemGap;

	const columnSize = Math.floor((width - containerGap) / gridItemWidth);

	return columnSize > MAX_COLUMN_SIZE ? MAX_COLUMN_SIZE : columnSize;
}

const DeleteTrashIcon = ({
	attachmentId,
	deleteMessage,
}: {
	attachmentId: AttachmentId;
	deleteMessage: string;
}) => {
	const entryPointProps = useMemo(
		() => ({
			attachmentView: gridView,
			deletingAttachmentId: attachmentId,
		}),
		[attachmentId],
	);
	const modalProps = useMemo(() => ({ width: 'small' }), []);
	return (
		<ModalEntryPointPressableTrigger
			entryPoint={issueAttachmentsDeleteModalEntrypoint}
			entryPointProps={entryPointProps}
			modalProps={modalProps}
			useInternalModal
			interactionName="issue-attachment-table-delete-attachment"
		>
			{({ ref }) => (
				<span ref={ref}>
					<TrashIcon
						color="currentColor"
						label={deleteMessage}
						testId={`issue.views.issue-base.content.attachment.grid-view.trash-icon.${attachmentId}`}
					/>
				</span>
			)}
		</ModalEntryPointPressableTrigger>
	);
};

export const getActionsForAttachment = (
	attachmentId: AttachmentId,
	attachmentParentName: AttachmentParent,
	attachmentParentId: string,
	canDeleteAttachment: boolean,
	intl: IntlShape,
	issueKey: IssueKey,
	onScrollToComments: () => void,
	onScrollToWorklogs: () => void,
) => {
	const deleteMessage = intl.formatMessage(messages.delete);

	if (attachmentParentName === ATTACHMENT_PARENT_ISSUE) {
		// no permission to delete attachment
		if (!canDeleteAttachment) {
			return [];
		}

		return [
			{
				label: deleteMessage,
				handler: noop,
				icon: <DeleteTrashIcon attachmentId={attachmentId} deleteMessage={deleteMessage} />,
			},
		];
	}

	// show the goto button as attachment in either commment/workog, customfield, description or envrionment
	return [
		{
			label: intl.formatMessage(messages.goto),
			handler: noop,
			icon: (
				<GoToButton
					attachmentParent={attachmentParentName}
					attachmentParentId={attachmentParentId}
					attachmentView={gridView}
					onScrollToComments={onScrollToComments}
					onScrollToWorklogs={onScrollToWorklogs}
					issueKey={issueKey}
					// This is a div to match the CardActionButton in the media-card component
					component="div"
					attachmentId={attachmentId}
				/>
			),
		},
	];
};
