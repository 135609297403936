import React, { memo, type ChangeEvent } from 'react';
import { styled } from '@compiled/react';
import { ErrorMessage } from '@atlaskit/form';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import Textfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { ClassicStoryPointValue } from '../../common/types.tsx';
import messages from '../../messages.tsx';

type Props = {
	isInvalid?: boolean;
	type: string;
	defaultValue: ClassicStoryPointValue;
	onChange?: (arg1: ChangeEvent<HTMLInputElement>) => void;
	ariaLabel?: string;
};

export const ClassicStoryPointEdit = memo<Props>((props: Props) => {
	const { isInvalid = false, type, defaultValue, ariaLabel, ...rest } = props;

	const { formatMessage } = useIntl();

	const value = defaultValue === null ? '' : defaultValue;
	return (
		<Container>
			<Textfield
				testId="issue-field-classic-story-point.ui.edit.inline-edit-field"
				appearance="subtle"
				autoFocus
				isCompact
				// @ts-expect-error - TS2322 - Type '{ onChange?: ((arg1: ChangeEvent<HTMLInputElement>) => void) | undefined; testId: string; appearance: "subtle"; autoFocus: true; isCompact: true; isLabelHidden: true; ... 4 more ...; elemAfterInput: Element | null; }' is not assignable to type 'IntrinsicAttributes & TextfieldProps & RefAttributes<unknown>'.
				isLabelHidden
				isInvalid={isInvalid}
				type={type}
				value={value}
				step="any"
				aria-label={ariaLabel || formatMessage(messages.editStoryPointsDefaultLabel)}
				elemAfterInput={
					isInvalid === true ? (
						<WarningWrapper>
							<WarningIcon
								LEGACY_size="medium"
								spacing="spacious"
								color={token('color.icon.warning')}
								label=""
							/>
						</WarningWrapper>
					) : null
				}
				{...rest}
			/>
			{isInvalid && (
				<ErrorMessage>{formatMessage(messages.negativeNumbersNotAllowed)}</ErrorMessage>
			)}
		</Container>
	);
});

export default ClassicStoryPointEdit;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Container = styled.div({
	cursor: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const WarningWrapper = styled.div({
	marginLeft: token('space.negative.050'),
	marginRight: token('space.050'),
});
