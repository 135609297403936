// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createHook,
	createStore,
	createContainer,
	createActionsHook,
} from '@atlassian/react-sweet-state';
import { actions, type Actions } from './actions.tsx';
import type { State } from './types.tsx';

export const initialState = {
	localIssues: {},
	remoteIssues: {},
};

export const store = createStore<State, Actions>({
	name: 'associated-issues-context-service',
	initialState,
	actions,
});

const getLocalAssociatedIssuesContext = (state: State) => state.localIssues;
const getRemoteAssociatedIssuesContext = (state: State) => state.remoteIssues;

// DO NOT USE OUTSIDE OF ISSUE VIEW CHILD ISSUES PANEL/ISSUE LINKS EXPERIENCE.
export const useLocalAssociatedIssuesContext = createHook(store, {
	selector: getLocalAssociatedIssuesContext,
});
export const useRemoteAssociatedIssuesContext = createHook(store, {
	selector: getRemoteAssociatedIssuesContext,
});

export const AssociatedIssuesContextContainer = createContainer<State, Actions>(store);

export const useAssociatedIssuesContextActions = createActionsHook(store);
