import { useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import type {
	ClientEventValidator,
	ClientValidator,
} from '@atlassian/jira-issue-field-validation-handler/src/controllers/client-validation-handler/types.tsx';
import { useValidateTimeString } from '@atlassian/jira-issue-field-original-estimate-readview-full/src/ui/original-estimate/utils/parsing.tsx';
import type { TimeTrackingSettingsInput } from '@atlassian/jira-issue-field-original-estimate-readview-full/src/ui/original-estimate/utils/types.tsx';
import messages from './messages.tsx';

export const useOriginalEstimateValidator = (
	settingsInput: TimeTrackingSettingsInput,
): ClientEventValidator<string> => {
	const { formatMessage } = useIntl();
	const validate = useValidateTimeString(settingsInput);

	const submit: ClientValidator<string> = useCallback(
		(value: string) => {
			if (!validate(value)) {
				return {
					type: 'error',
					message: formatMessage(messages.invalidInput),
				};
			}

			return null;
		},
		[formatMessage, validate],
	);

	return { submit };
};
