import React, { useEffect, useMemo } from 'react';
import usePressTracing from '@atlaskit/react-ufo/use-press-tracing';
import { fg } from '@atlassian/jira-feature-gating';
import {
	useEntryPointButtonTrigger,
	getDefaultEntrypointActionEventMap,
} from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import type { AnyEntryPoint } from '@atlassian/jira-entry-point/src/common/types.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import type { ValidationFieldProps } from '@atlassian/jira-issue-field-validation-popup/src/ui/types.tsx';
import FieldInlineEditLite from './field-inline-edit-lite/index.tsx';
import { ReadViewNonEditable } from './field-inline-edit-lite/read-view-non-editable/index.tsx';
import type { FieldInlineEditLiteWithEntryPointProps } from './types.tsx';

/**
 * Wrapper component around `InlineEditLite` which provides consistent spacing and validation UX for issue fields. In
 * addition, it adds support for rendering the `editView` asynchronously using an entrypoint.
 */
export const FieldInlineEditLiteWithEntryPoint = <TEntryPoint extends AnyEntryPoint>({
	editViewEntryPoint,
	editViewEntryPointParams,
	getEditViewProps,
	...props
}: FieldInlineEditLiteWithEntryPointProps<TEntryPoint>) => {
	const {
		hideEditButton,
		isEditable,
		isEditing,
		readView,
		readViewFitContainerHeight,
		hasReadViewTruncateDisabled,
	} = props;

	const pressTrace = usePressTracing('issue.field.inline-edit-lite');

	// eslint-disable-next-line @atlassian/react-entrypoint/prefer-entrypoint-file-import
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		editViewEntryPoint,
		editViewEntryPointParams,
	);
	const entryPointEventMap = useMemo(() => {
		const actions = getDefaultEntrypointActionEventMap(entryPointActions);
		if (fg('jiv-20205-more-ufo-tracing')) {
			const { click } = actions;
			actions.click = (e) => {
				pressTrace();
				click(e);
			};
		}
		return actions;
	}, [entryPointActions, pressTrace]);
	const readViewTrigger = useEntryPointButtonTrigger(entryPointActions, true, entryPointEventMap);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		// This component can be mounted in an editing state or toggled externally, so we need to ensure the entrypoint
		// is always explicitly loaded when entering edit view.
		if (isEditing) {
			entryPointActions.load();
		}
	}, [isEditing, entryPointActions]);

	const renderEditView = (fieldProps: ValidationFieldProps) => (
		<JiraEntryPointContainer
			entryPointReferenceSubject={entryPointReferenceSubject}
			id="editView"
			packageName="jiraIssueFieldInlineEditLite"
			fallback={
				<ReadViewNonEditable
					hideEditButton={hideEditButton}
					isLoading
					readViewFitContainerHeight={readViewFitContainerHeight}
				>
					{readView()}
				</ReadViewNonEditable>
			}
			runtimeProps={getEditViewProps(fieldProps)}
		/>
	);

	return (
		<FieldInlineEditLite
			{...props}
			readViewContainerRef={isEditable ? readViewTrigger : undefined}
			editView={renderEditView}
			hasReadViewTruncateDisabled={hasReadViewTruncateDisabled}
		/>
	);
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as FieldInlineEditLite } from './field-inline-edit-lite';
