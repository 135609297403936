/**
 * @generated SignedSource<<e0b63844dbb05d16c67fb5f024b2390d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal_layoutItemFragment$data = {
  readonly __typename: string;
  readonly fieldId: string;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueViewBase_Date" | "main_issueViewBase_DateTime" | "relayLayoutItems_issueViewLayoutTemplatesLayoutItem_RelayLayoutItemFragmentContainer_fragmentKey" | "view_issueViewBase_MultilineField">;
  readonly " $fragmentType": "src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal_layoutItemFragment";
};
export type src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal_layoutItemFragment$key = {
  readonly " $data"?: src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal_layoutItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal_layoutItemFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayAssigneeFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayDateFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayDateTimeFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayNumberFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayPeopleAndApproversFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayPriorityFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayProjectFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelaySingleLineTextFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelaySprintFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayUrlFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "jcsFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "jsmSentimentCustomFieldFlag"
    }
  ],
  "kind": "Fragment",
  "name": "src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal_layoutItemFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "FragmentSpread",
      "name": "main_issueViewBase_DateTime"
    },
    {
      "kind": "FragmentSpread",
      "name": "main_issueViewBase_Date"
    },
    {
      "kind": "FragmentSpread",
      "name": "view_issueViewBase_MultilineField"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "issueViewRelayAssigneeFieldFlag",
          "variableName": "issueViewRelayAssigneeFieldFlag"
        },
        {
          "kind": "Variable",
          "name": "issueViewRelayDateFieldFlag",
          "variableName": "issueViewRelayDateFieldFlag"
        },
        {
          "kind": "Variable",
          "name": "issueViewRelayDateTimeFieldFlag",
          "variableName": "issueViewRelayDateTimeFieldFlag"
        },
        {
          "kind": "Variable",
          "name": "issueViewRelayNumberFieldFlag",
          "variableName": "issueViewRelayNumberFieldFlag"
        },
        {
          "kind": "Variable",
          "name": "issueViewRelayPeopleAndApproversFieldFlag",
          "variableName": "issueViewRelayPeopleAndApproversFieldFlag"
        },
        {
          "kind": "Variable",
          "name": "issueViewRelayPriorityFieldFlag",
          "variableName": "issueViewRelayPriorityFieldFlag"
        },
        {
          "kind": "Variable",
          "name": "issueViewRelayProjectFieldFlag",
          "variableName": "issueViewRelayProjectFieldFlag"
        },
        {
          "kind": "Variable",
          "name": "issueViewRelaySingleLineTextFieldFlag",
          "variableName": "issueViewRelaySingleLineTextFieldFlag"
        },
        {
          "kind": "Variable",
          "name": "issueViewRelaySprintFieldFlag",
          "variableName": "issueViewRelaySprintFieldFlag"
        },
        {
          "kind": "Variable",
          "name": "issueViewRelayUrlFieldFlag",
          "variableName": "issueViewRelayUrlFieldFlag"
        },
        {
          "kind": "Variable",
          "name": "jcsFlag",
          "variableName": "jcsFlag"
        },
        {
          "kind": "Variable",
          "name": "jsmSentimentCustomFieldFlag",
          "variableName": "jsmSentimentCustomFieldFlag"
        }
      ],
      "kind": "FragmentSpread",
      "name": "relayLayoutItems_issueViewLayoutTemplatesLayoutItem_RelayLayoutItemFragmentContainer_fragmentKey"
    }
  ],
  "type": "JiraIssueField",
  "abstractKey": "__isJiraIssueField"
};

(node as any).hash = "9f01320e7d472695d79685f359b95db1";

export default node;
