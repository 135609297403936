import { useState, useEffect, useCallback, useMemo } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useProductEntitlementDetails } from '@atlassian/jira-tenant-context-controller/src/components/product-entitlement-details/index.tsx';
import { SOFTWARE } from '@atlassian/jira-shared-types/src/application-key.tsx';

import { createLocalExpirableStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { FREE_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { fetchEndUserAddProductPermissions } from '../services/user-product-permission.tsx';

const CONFLUENCE_ADD_PRODUCT_PERMISSION_STORAGE_KEY = 'confluence-add-product-permission';

type Status = {
	canProvisionConfluenceEndUser: boolean;
	fetchStatus: 'pending' | 'resolved' | 'failed';
};

type Props = {
	skip?: boolean;
};

export const localStorageManager = () => {
	const localStorageProvider = createLocalExpirableStorageProvider(
		'placeholderTemplateAddProductPermissionStorage',
	);

	const getLocalStorage = () =>
		localStorageProvider.get(CONFLUENCE_ADD_PRODUCT_PERMISSION_STORAGE_KEY);
	const LOCAL_STORAGE_EXPIRY_MS = 24 * 60 * 60 * 1000; // one day
	const setLocalStorage = (value: Status | undefined) =>
		localStorageProvider.set(
			CONFLUENCE_ADD_PRODUCT_PERMISSION_STORAGE_KEY,
			value,
			Date.now() + LOCAL_STORAGE_EXPIRY_MS,
		);

	return { getLocalStorage, setLocalStorage };
};

export const useUserHasAddProductPermissions = ({ skip = false }: Props) => {
	const cloudId = useCloudId();
	const appEditions = useAppEditions();
	const productEntitlementDetails = useProductEntitlementDetails();
	const isBillingSystemCcp = productEntitlementDetails?.[SOFTWARE]?.billingSourceSystem === 'CCP';
	const { getLocalStorage, setLocalStorage } = useMemo(() => localStorageManager(), []);
	const cachedStatus = getLocalStorage();

	const initialStatus = cachedStatus || {
		canProvisionConfluenceEndUser: false,
		fetchStatus: 'pending',
	};
	const [status, setStatus] = useState<Status>(initialStatus);

	const handleFetchPermissions = useCallback(async (): Promise<void> => {
		try {
			let newStatus: Status;

			if (fg('end_user_activate_eligibility_check')) {
				const endUserResponse = await fetchEndUserAddProductPermissions(
					cloudId,
					isBillingSystemCcp,
				);

				newStatus = {
					canProvisionConfluenceEndUser: endUserResponse?.eligible,
					fetchStatus: 'resolved',
				};
			} else {
				// If the feature flag is not enabled, set default status
				newStatus = {
					canProvisionConfluenceEndUser: appEditions.software === FREE_EDITION,
					fetchStatus: 'resolved',
				};
			}
			setStatus(newStatus);
			setLocalStorage(newStatus);
		} catch (error: unknown) {
			fireErrorAnalytics({
				meta: {
					id: CONFLUENCE_ADD_PRODUCT_PERMISSION_STORAGE_KEY,
					packageName: 'jiraIssueViewBase',
					teamName: 'starburst',
				},
				error: new Error(
					`Failed to get ${CONFLUENCE_ADD_PRODUCT_PERMISSION_STORAGE_KEY} status: ${error}`,
				),
			});

			const errorStatus: Status = {
				canProvisionConfluenceEndUser: false,
				fetchStatus: 'failed',
			};
			setStatus(errorStatus);
			setLocalStorage(errorStatus);
		}
	}, [cloudId, isBillingSystemCcp, appEditions.software, setLocalStorage]);

	useEffect(() => {
		if (!skip && cloudId && !cachedStatus) {
			handleFetchPermissions();
		}
	}, [cloudId, skip, cachedStatus, handleFetchPermissions]);

	return status;
};
