import React, { type ReactNode, useEffect } from 'react';
import { type UIAnalyticsEvent, AnalyticsListener } from '@atlaskit/analytics-next';
import {
	GlobalContextContainer as EocGlobalContext,
	isHTTPError,
	type Config,
} from '@atlassian/eoc-global-context';
import { addSearchSessionAttributes } from '@atlassian/jira-analytics-providers/src/common/utils/add-search-session-attributes/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { TRACE_ID_HEADER } from '@atlassian/jira-fetch/src/utils/constants.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { useFlagService } from '@atlassian/jira-flags';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { setSubProduct } from '@atlassian/jira-platform-router-utils/src/common/utils/index.tsx';
import { getEvent } from '@atlassian/jira-product-analytics-bridge';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import { fg } from '@atlassian/jira-feature-gating';

const addSearchPayload = (event: UIAnalyticsEvent) => {
	try {
		const searchPayload = addSearchSessionAttributes();
		if (Object.keys(searchPayload).length > 0) {
			// eslint-disable-next-line no-param-reassign
			event.payload.attributes = {
				...event.payload.attributes,
				...searchPayload,
			};
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		log.safeErrorWithoutCustomerData(
			'observability.analytics-providers-eoc.addSearchPayload',
			'Failed to dynamically add search payload',
			e,
		);
	}

	return event;
};

// @ts-expect-error - TS7006 - Parameter 'event' implicitly has an 'any' type.
const sendAnalyticsEvent = (event) => {
	getAnalyticsWebClientPromise().then((client) => client.sendEvent(event));
};

// A custom listener to send events from analyticsBridge channel. Using the analytics bridge on AFE code
// creates events in this channel, which we can then listen here and send.
const EocAnalyticsListener = ({
	children,
	shouldSetSubProduct = true,
}: {
	children: ReactNode;
	shouldSetSubProduct?: boolean;
}) => {
	useEffect(() => {
		if (shouldSetSubProduct) {
			setSubProduct('serviceDesk');
		}
	}, [shouldSetSubProduct]);

	if (__SERVER__) {
		return <>{children}</>;
	}

	return (
		<AnalyticsListener
			onEvent={(event: UIAnalyticsEvent) => {
				// Mimic the behaviour on JFE, is this really necessary?
				const enrichedEvent = addSearchPayload(event);
				sendAnalyticsEvent(getEvent(enrichedEvent));
			}}
			channel="analyticsBridge"
		>
			{children}
		</AnalyticsListener>
	);
};

export const GlobalContextContainer = <T,>({
	children,
	packageName,
	teamName,
	config,
	shouldSetSubProduct = true,
}: {
	packageName: string;
	teamName: string;
	children?: React.ReactNode;
	config?: Config<T>;
	shouldSetSubProduct?: boolean;
}) => {
	const flagService = useFlagService();
	const isNewNavigation = getWillShowNav4();

	return (
		<EocGlobalContext
			config={{ ...config, isNewNavigation }}
			flagService={flagService}
			reporter={({ id, error: reporterError }) => {
				const error = isHTTPError(reporterError)
					? new FetchError(
							reporterError.response.status,
							reporterError.message,
							reporterError.response.headers.get(TRACE_ID_HEADER) ?? undefined,
							reporterError.response,
						)
					: // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						(reporterError as Error | undefined);

				fireErrorAnalytics({
					meta: {
						id,
						packageName,
						teamName,
					},
					error,
				});
			}}
		>
			<EocAnalyticsListener
				shouldSetSubProduct={fg('your-work-mau-arrangements') ? shouldSetSubProduct : true}
			>
				{children}
			</EocAnalyticsListener>
		</EocGlobalContext>
	);
};
