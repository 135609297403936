import type { ViewAccessLevel } from '@atlassian/jira-polaris-domain-view/src/view-access/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';

export const setCurrentViewAccessLevel =
	(accessLevel?: ViewAccessLevel): Action<State, Props> =>
	async ({ setState }) => {
		setState({ accessLevel });
	};
