import React from 'react';

import Icon, { type IconProps } from '@atlaskit/icon';

import { token } from '@atlaskit/tokens';

const NoActionGlyph: React.FunctionComponent = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="16" height="16" fill={token('elevation.surface')} fillOpacity="0.01" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.99998 14.6667C11.6819 14.6667 14.6666 11.6819 14.6666 8.00001C14.6666 4.31811 11.6819 1.33334 7.99998 1.33334C4.31808 1.33334 1.33331 4.31811 1.33331 8.00001C1.33331 11.6819 4.31808 14.6667 7.99998 14.6667ZM5.33331 7.33334C4.96512 7.33334 4.66665 7.63182 4.66665 8.00001C4.66665 8.3682 4.96512 8.66668 5.33331 8.66668H10.6666C11.0348 8.66668 11.3333 8.3682 11.3333 8.00001C11.3333 7.63182 11.0348 7.33334 10.6666 7.33334H5.33331Z"
			fill="currentColor"
		/>
	</svg>
);

const NoActionCircle: React.FunctionComponent<IconProps> = (props) => (
	<Icon {...props} glyph={() => <NoActionGlyph />} />
);

export default NoActionCircle;
