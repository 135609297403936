import React, { createContext, useContext, useMemo, type PropsWithChildren } from 'react';
import { VIEW_ACCESS_LEVELS } from '@atlassian/jira-polaris-domain-view/src/view-access/constants.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createActionsHook,
	createStateHook,
	createStore,
	type Selector,
} from '@atlassian/react-sweet-state';
import { addViewAccessPrincipals } from './actions/add-view-access-principals/index.tsx';
import { changeViewAccessPrincipalRole } from './actions/change-view-access-principal-role/index.tsx';
import { clearFailures } from './actions/clear-failures/index.tsx';
import { clearSkippedAccountFailures } from './actions/clear-skipped-account-failures/index.tsx';
import { loadCurrentUserViewAccess } from './actions/load-current-user-view-access/index.tsx';
import { loadViewPermissionsConfig } from './actions/load-view-permissions-config/index.tsx';
import { removeViewAccessPrincipals } from './actions/remove-view-access-principals/index.tsx';
import { setHasManageViewsPermission } from './actions/set-has-manage-views-permission/index.tsx';
import { updateViewAccessLevel } from './actions/update-view-access-level/index.tsx';
import type { State } from './types.tsx';

const actions = {
	addViewAccessPrincipals,
	changeViewAccessPrincipalRole,
	clearFailures,
	clearSkippedAccountFailures,
	loadCurrentUserViewAccess,
	loadViewPermissionsConfig,
	removeViewAccessPrincipals,
	setHasManageViewsPermission,
	updateViewAccessLevel,
};

const initialState: State = {
	config: {
		isAddingPrincipals: false,
		error: undefined,
		skippedAccountFailures: [],
	},
	accessLevel: VIEW_ACCESS_LEVELS.LIMITED,
	additionalAccess: {
		profiles: [],
		groups: [],
	},
	hasManageViewsPermission: false,
	viewAccessMap: {},
};

const Store = createStore({
	initialState,
	actions,
	name: 'PolarisViewAccessStore',
});

export const createViewAccessStateHook = <T,>(selector: Selector<State, void, T>) =>
	createStateHook<State, typeof actions, T>(Store, { selector });

export const useActions = createActionsHook(Store);

type EditModeContext = {
	editingMode: 'roadmap' | 'view';
	nonEditablePrincipalIds: Set<string>;
};

const PermissionsSettingsContext = createContext<EditModeContext>({
	editingMode: 'view',
	nonEditablePrincipalIds: new Set(),
});

type PermissionsSettingsProviderProps = PropsWithChildren<{
	editingMode: 'roadmap' | 'view';
	nonEditablePrincipalIds?: string[];
}>;

export const PermissionsSettingsProvider = ({
	children,
	editingMode,
	nonEditablePrincipalIds,
}: PermissionsSettingsProviderProps) => {
	const value = useMemo(
		() => ({
			editingMode,
			nonEditablePrincipalIds: new Set(nonEditablePrincipalIds),
		}),
		[editingMode, nonEditablePrincipalIds],
	);

	return (
		<PermissionsSettingsContext.Provider value={value}>
			{children}
		</PermissionsSettingsContext.Provider>
	);
};

export const usePermissionsEditMode = () => useContext(PermissionsSettingsContext).editingMode;
export const useNonEditablePrincipalIds = () =>
	useContext(PermissionsSettingsContext).nonEditablePrincipalIds;
