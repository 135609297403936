import {
	Days,
	DTStartProperties,
	Frequencies,
	ICalSpecificationFields,
	ICalSupportedFields,
	INVALID,
	LF,
	NewlineCharacters,
	NewlineCharacterValues,
	RRuleProperties,
	VALID,
} from './constants.tsx';
import type {
	ByDay,
	DTStartProperty,
	ICalField,
	Frequency,
	ICalSupportedField,
	NewLineCharacterName,
	NewLineCharacterValue,
	RRuleProperty,
	ValidationResult,
} from './types.tsx';

/**
 * Checks if the given value is a string.
 *
 * @param {unknown} value - The value to check.
 * @returns {boolean} True if the value is a string, false otherwise.
 */
export function isString(value: unknown): value is string {
	return typeof value === 'string';
}

/**
 * Checks if the given value is a number.
 *
 * @param {unknown} value - The value to check.
 * @returns {boolean} True if the value is a number, false otherwise.
 */
export function isNumber(value: unknown): value is number {
	return typeof value === 'number';
}

/**
 * Checks if the given value is a non-negative number.
 *
 * @param {string | number} value - The value to check.
 * @returns {boolean} True if the value is a non-negative number, false otherwise.
 */
export const isNonNegativeNumber = (value: string | number): boolean =>
	isString(value) || isNumber(value) ? !Number.isNaN(Number(value)) && Number(value) >= 0 : false;

/**
 * Checks if the given frequency is valid.
 *
 * @param {string | number} frequency - The frequency to check.
 * @returns {boolean} True if the frequency is valid, false otherwise.
 */
export function isValidFrequency(frequency: string | number): frequency is Frequency {
	return isString(frequency) ? frequency in Frequencies : false;
}

/**
 * Checks if the given interval is valid.
 *
 * @param {string | number} interval - The interval to check.
 * @returns {boolean} True if the interval is valid, false otherwise.
 */
export function isValidInterval(interval: string | number): boolean {
	if (isString(interval) || isNumber(interval)) {
		const intervalNumber = Number(interval);
		return isNonNegativeNumber(intervalNumber) && intervalNumber > 0;
	}
	return false;
}

/**
 * Checks if the given day is a valid ByDay value.
 *
 * @param {string | number} day - The day to check.
 * @returns {boolean} True if the day is a valid ByDay value, false otherwise.
 */
export function isValidByDayValue(day: string | number): day is ByDay {
	return isString(day) ? day in Days : false;
}

/**
 * Converts an array of strings to an array of ByDay values.
 *
 * @param {string[]} days - The array of strings to convert.
 * @returns {ByDay[]} The array of ByDay values.
 */
export function convertToByDay(days: string[]): ByDay[] {
	return days.every(isValidByDayValue) ? days : [];
}

/**
 * Checks if the given month is valid.
 *
 * @param {string | number} month - The month to check.
 * @returns {boolean} True if the month is valid, false otherwise.
 */
export function isValidMonth(month: string | number): boolean {
	if (isString(month) || isNumber(month)) {
		const monthNumber = Number(month);
		return isNonNegativeNumber(month) && monthNumber >= 1 && monthNumber <= 12;
	}
	return false;
}

/**
 * Checks if the given day is a valid month day.
 *
 * @param {string | number} day - The day to check.
 * @returns {boolean} True if the day is a valid month day, false otherwise.
 */
export function isValidMonthDay(day: string | number): boolean {
	if (isString(day) || isNumber(day)) {
		const dayNumber = Number(day);
		return isNonNegativeNumber(dayNumber) && dayNumber >= 1 && dayNumber <= 31;
	}
	return false;
}

/**
 * Checks if the given hour is valid.
 *
 * @param {string | number} hour - The hour to check.
 * @returns {boolean} True if the hour is valid, false otherwise.
 */
export function isValidHour(hour: string | number): boolean {
	if (isString(hour) || isNumber(hour)) {
		const hourNumber = Number(hour);
		return isNonNegativeNumber(hourNumber) && hourNumber >= 0 && hourNumber <= 23;
	}
	return false;
}

/**
 * Checks if the given minute is valid.
 *
 * @param {string | number} minute - The minute to check.
 * @returns {boolean} True if the minute is valid, false otherwise.
 */
export function isValidMinute(minute: string | number): boolean {
	if (isString(minute) || isNumber(minute)) {
		const minuteNumber = Number(minute);
		return isNonNegativeNumber(minuteNumber) && minuteNumber >= 0 && minuteNumber <= 59;
	}
	return false;
}

/**
 * Checks if the given second is valid.
 *
 * @param {string | number} second - The second to check.
 * @returns {boolean} True if the second is valid, false otherwise.
 */
export function isValidSecond(second: string | number): boolean {
	if (isString(second) || isNumber(second)) {
		const secondNumber = Number(second);
		return isNonNegativeNumber(secondNumber) && secondNumber >= 0 && secondNumber <= 59;
	}
	return false;
}

function isProperty<T extends string>(key: string, properties: Record<string, unknown>): key is T {
	return key in properties;
}

function toProperty<T extends string>(
	key: string,
	properties: Record<string, unknown>,
): T | undefined {
	return isProperty<T>(key, properties) ? key : undefined;
}

/**
 * Converts a key to a DTSTART property if it exists in the properties.
 *
 * @param {string} key - The key to convert.
 * @returns {DTStartProperty | undefined} The DTSTART property or undefined if not found.
 */
export function toDTStartProperty(key: string): DTStartProperty | undefined {
	return toProperty<DTStartProperty>(key, DTStartProperties);
}

/**
 * Converts a key to a rule property if it exists in the properties.
 *
 * @param {string} key - The key to convert.
 * @returns {RRuleProperty | undefined} The rule property or undefined if not found.
 */
export function toRuleProperty(key: string): RRuleProperty | undefined {
	return toProperty<RRuleProperty>(key, RRuleProperties);
}

/**
 * Checks if the given key is an iCal field.
 *
 * @param {string} key - The key to check.
 * @returns {boolean} True if the key is an iCal field, false otherwise.
 */
export function isICalField(key: string): key is ICalField {
	return isProperty<ICalField>(key?.toUpperCase(), ICalSpecificationFields);
}

/**
 * Converts a key to an iCal field if it exists in the properties.
 *
 * @param {string} key - The key to convert.
 * @returns {ICalField | undefined} The iCal field or undefined if not found.
 */
export function toICalField(key: string): ICalField | undefined {
	return toProperty<ICalField>(key, ICalSpecificationFields);
}

/**
 * Converts a key to an iCal supported field if it exists in the properties.
 *
 * @param {string} key - The key to convert.
 * @returns {ICalSupportedField | undefined} The iCal supported field or undefined if not found.
 */
export function toICalSupportedField(key: string): ICalSupportedField | undefined {
	return toProperty<ICalSupportedField>(key, ICalSupportedFields);
}

/**
 * Checks if the given key is a newline character.
 *
 * @param {string} key - The key to check.
 * @returns {boolean} True if the key is a newline character, false otherwise.
 */
export function isNewLineCharacter(key: string): key is NewLineCharacterName {
	return isProperty<NewLineCharacterName>(key, NewlineCharacters);
}

/**
 * Converts a key to a newline character value.
 *
 * @param {string} key - The key to convert.
 * @returns {NewLineCharacterValue} The newline character value.
 */
export function toNewLineCharacter(key: string): NewLineCharacterValue {
	if (isNewLineCharacter(key)) {
		return NewlineCharacterValues[key];
	}
	return NewlineCharacterValues[LF];
}

/**
 * Extracts the TZID (time zone identifier) from a date-time string.
 *
 * @param {string} dtString - The date-time string to extract the TZID from.
 * @returns {{ dateTimeString: string; timezone?: string }} An object containing the extracted date-time string and optional time zone identifier.
 */
export function extractTZID(dtString: string): { dateTimeString: string; timezone?: string } {
	if (dtString.includes('TZID=')) {
		const parts = dtString.split(':');
		return {
			dateTimeString: parts?.slice?.(1).join?.(':')?.trim(), // Extract actual date-time
			timezone: parts?.[0]?.split('=')?.[1]?.trim(), // Extract TZID value
		};
	}

	return {
		dateTimeString: dtString?.split?.(':')?.slice?.(1)?.join?.(':')?.trim(),
		timezone: undefined,
	};
}

/**
 * Formats validation errors into a string.
 *
 * @param {Record<string, string>[]} errors - The array of error objects to format.
 * @returns {string} The formatted validation errors as a string.
 */
export function formatValidationErrors(errors: Record<string, string>[]): string {
	return errors
		.map((error) =>
			Object.entries(error)
				.map(([key, value]) => `${key}:${value}`)
				.join(','),
		)
		.join('; ');
}

/**
 * Formats the validation result into a string.
 *
 * @param {ValidationResult} result - The validation result to format.
 * @returns {string} The formatted validation result as a string.
 */
export function formatValidationResult(result: ValidationResult): string {
	const status = result?.isValid ? VALID : INVALID;

	const output: string[] = [`[ICalendar ${status}]`];
	if (result?.errors) {
		output.push(formatValidationErrors(result.errors));
	}

	return output.join(' ').trim();
}
