import React, { memo, useCallback, useMemo } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import InlineEditStateless, { type InlineEditProps } from '@atlaskit/inline-edit';
import { token } from '@atlaskit/tokens';
import usePressTracing from '@atlaskit/react-ufo/use-press-tracing';
import EnterEscapeHandler from '@atlassian/jira-common-components-enter-escape-handler/src/index.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { getFieldType } from '@atlassian/jira-issue-view-common-utils/src/fields/index.tsx';
import { getFieldIdFromAri } from '@atlassian/jira-issue-view-common-utils/src/id-from-ari/index.tsx';
import {
	AnalyticsEventToProps,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { readOnlyViewContainerSelectorName } from '../styled.tsx';
import type { FieldInlineEditStateLessProps } from '../types.tsx';

export const EnterEscapeHandlerWithAnalytics = AnalyticsEventToProps('EnterEscapeHandler', {
	onEnter: 'entered',
	onEscape: 'escaped',
})(EnterEscapeHandler);

export const EscapeHandlerWithAnalytics = AnalyticsEventToProps('EscapeHandler', {
	onEscape: 'escaped',
})(EnterEscapeHandler);

export const EnterHandlerWithAnalytics = AnalyticsEventToProps('EnterHandler', {
	onEnter: 'entered',
})(EnterEscapeHandler);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldContainer = styled.div({
	/* @see: https://bento-project-backup.atlassian.net/browse/BENTO-10009 */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& > form > div > div > button:focus': {
		outline: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NonEditableMargin = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	margin: `${2 * gridSize - 2}px 0px ${token('space.075')} ${token('space.025')}`,
});

const useCallbackWithAnalyticsEvent = (
	callback: ((analyticsEvent: UIAnalyticsEvent) => void) | undefined,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	payload: Record<any, any>,
) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	return useCallback(() => {
		if (typeof callback === 'function') {
			callback(createAnalyticsEvent(payload));
		}
	}, [callback, createAnalyticsEvent, payload]);
};

const useCallbackWithAnalyticsEventForEdit = (
	callback: ((analyticsEvent: UIAnalyticsEvent) => void) | undefined,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	payload: Record<any, any>,
) => {
	const { fieldId, fieldType: fieldTypeFromProps, isInlineEditing = false } = payload;
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const issueKey = useIssueKey();
	const pressTrace = usePressTracing('issue.field.inline-edit');

	const fieldIdWithoutAri = getFieldIdFromAri(fieldId);
	const [fieldConfig] = useFieldConfig(issueKey || '', fieldIdWithoutAri || '');
	const fieldType = fieldTypeFromProps || getFieldType(fieldConfig?.value);

	return useCallback(() => {
		fg('jiv-20205-more-ufo-tracing') && pressTrace();
		if (fieldIdWithoutAri && fieldType) {
			const event = createAnalyticsEvent({});

			getUpdateAnalyticsFlowHelper().fireAnalyticsStart(fieldIdWithoutAri, {
				analytics: event,
				attributes: {
					fieldType,
					isInlineEditing,
				},
			});
		}

		if (typeof callback === 'function') {
			callback(createAnalyticsEvent(payload));
		}
	}, [
		callback,
		createAnalyticsEvent,
		fieldIdWithoutAri,
		fieldType,
		payload,
		isInlineEditing,
		pressTrace,
	]);
};

function FieldInlineEditStateLessInner<FieldValue = string>(
	props: FieldInlineEditStateLessProps<FieldValue>,
) {
	const {
		actionSubject = 'fieldInlineEdit',
		editView,
		isEditable = false,
		isEditing = false,
		isLabelHidden = true,
		readView,
		testId,
		fieldId,
		onCancel,
		onConfirm,
		onEdit,
		onEditRequested,
		onEnter,
		onEscape,
	} = props;

	const handleEnter = useCallbackWithAnalyticsEvent(onEnter, {
		action: 'entered',
		actionSubject,
		...props.componentAnalyticsData,
	});
	const handleEscape = useCallbackWithAnalyticsEvent(onEscape, {
		action: 'escaped',
		actionSubject,
		...props.componentAnalyticsData,
	});
	const handleEdit = fg('one_event_rules_them_all_fg')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useCallbackWithAnalyticsEventForEdit(onEditRequested || onEdit, {
				action: 'focused',
				actionSubject,
				fieldId,
				...props.componentAnalyticsData,
			}) // eslint-disable-next-line react-hooks/rules-of-hooks
		: useCallbackWithAnalyticsEvent(onEditRequested || onEdit, {
				action: 'focused',
				actionSubject,
				...props.componentAnalyticsData,
			});
	const handleCancel = useCallbackWithAnalyticsEvent(onCancel, {
		action: 'canceled',
		actionSubject,
		...props.componentAnalyticsData,
	});
	const handleConfirm: InlineEditProps<FieldValue>['onConfirm'] = useCallback(
		(_, event) => {
			if (typeof onConfirm === 'function') {
				onConfirm(
					event.update({
						action: 'confirmed',
						actionSubject,
						...props.componentAnalyticsData,
					}),
				);
			}
		},
		[actionSubject, onConfirm, props.componentAnalyticsData],
	);

	const renderReadView = typeof readView === 'function' ? readView : () => readView;
	const renderEditView = useMemo(() => {
		if (typeof editView === 'function') {
			return editView;
		}
		return () => editView;
	}, [editView]);

	const renderEditViewWithHandlers: InlineEditProps<FieldValue>['editView'] = useCallback(
		(editProps, ref) => {
			if (editView === null) return null;

			if (onEnter && onEscape) {
				return (
					<EnterEscapeHandlerWithAnalytics onEnter={handleEnter} onEscape={handleEscape}>
						{renderEditView(editProps, ref)}
					</EnterEscapeHandlerWithAnalytics>
				);
			}

			if (onEnter) {
				return (
					<EnterHandlerWithAnalytics onEnter={handleEnter}>
						{renderEditView(editProps, ref)}
					</EnterHandlerWithAnalytics>
				);
			}

			if (onEscape) {
				return (
					<EscapeHandlerWithAnalytics onEscape={handleEscape}>
						{renderEditView(editProps, ref)}
					</EscapeHandlerWithAnalytics>
				);
			}

			return renderEditView(editProps, ref);
		},
		[editView, onEnter, onEscape, handleEnter, handleEscape, renderEditView],
	);

	const inlineEditProps: InlineEditProps<FieldValue> = {
		defaultValue: 'defaultValue' in props ? props.defaultValue : props.value,
		onConfirm: handleConfirm,
		readView: renderReadView,
		editView: renderEditViewWithHandlers,
		isEditing,
		onCancel: handleCancel,
		onEdit: handleEdit,
		isRequired: props.isRequired,
		analyticsContext: props.analyticsContext,
		cancelButtonLabel: props.cancelButtonLabel,
		confirmButtonLabel: props.confirmButtonLabel,
		editButtonLabel: props.editButtonLabel,
		hideActionButtons:
			'hideActionButtons' in props ? props.hideActionButtons : props.areActionButtonsHidden,
		keepEditViewOpenOnBlur:
			'keepEditViewOpenOnBlur' in props
				? props.keepEditViewOpenOnBlur
				: props.isConfirmOnBlurDisabled,
		label: isLabelHidden === true ? undefined : props.label,
		readViewFitContainerWidth:
			'readViewFitContainerWidth' in props
				? props.readViewFitContainerWidth
				: props.isFitContainerWidthReadView,
		startWithEditViewOpen: props.startWithEditViewOpen,
		validate: props.validate,
	};

	return isEditable ? (
		<FieldContainer data-testid={testId != null ? `${testId}--container` : undefined}>
			<InlineEditStateless {...inlineEditProps} />
		</FieldContainer>
	) : (
		<NonEditableMargin
			data-component-selector={readOnlyViewContainerSelectorName}
			data-testid={testId != null ? `${testId}--read-only-container` : undefined}
		>
			{renderReadView()}
		</NonEditableMargin>
	);
}

export const FieldInlineEditStateLess = memo(FieldInlineEditStateLessInner);

export default FieldInlineEditStateLess;
