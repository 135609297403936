/**
 * @generated SignedSource<<2db0fab7ace85801c97c84b9e090669e>>
 * @relayHash 6a6f6d2dbf261855577970eaddb30675
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 7535921cc408028d90e73c75438f5538f14e15a19b3d34cc2d6bd07696dad14e

import type { ConcreteRequest, Query } from 'relay-runtime';
export type JiraServiceManagementCommentVisibility = "INTERNAL" | "VISIBLE_TO_HELPSEEKER" | "%future added value";
export type commentFetchAggServerQuery$variables = {
  after?: string | null | undefined;
  afterTarget?: number | null | undefined;
  before?: string | null | undefined;
  beforeTarget?: number | null | undefined;
  first?: number | null | undefined;
  hasCommentTargetId: boolean;
  issueAri: string;
  jiraThreadedCommentsEnabled: boolean;
  last?: number | null | undefined;
  targetId?: string | null | undefined;
};
export type commentFetchAggServerQuery$data = {
  readonly node: {
    readonly comments?: {
      readonly edges: ReadonlyArray<{
        readonly cursor: string;
        readonly node: {
          readonly __typename: string;
          readonly author: {
            readonly accountId: string;
            readonly name: string;
            readonly picture: AGG$URL;
          } | null | undefined;
          readonly authorCanSeeRequest?: boolean | null | undefined;
          readonly commentId: string;
          readonly created: AGG$DateTime;
          readonly eventOccurredAt?: AGG$DateTime | null | undefined;
          readonly jsdIncidentActivityViewHidden?: boolean | null | undefined;
          readonly permissionLevel: {
            readonly group: {
              readonly groupId: string;
              readonly id: string;
              readonly name: string;
            } | null | undefined;
            readonly role: {
              readonly id: string;
              readonly name: string | null | undefined;
            } | null | undefined;
          } | null | undefined;
          readonly richText: {
            readonly adfValue: {
              readonly json: AGG$JSON | null | undefined;
            } | null | undefined;
          } | null | undefined;
          readonly updateAuthor: {
            readonly accountId: string;
            readonly name: string;
            readonly picture: AGG$URL;
          } | null | undefined;
          readonly updated: AGG$DateTime | null | undefined;
          readonly visibility?: JiraServiceManagementCommentVisibility | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly pageInfo: {
        readonly endCursor: string | null | undefined;
        readonly hasNextPage: boolean;
        readonly hasPreviousPage: boolean;
        readonly startCursor: string | null | undefined;
      };
    } | null | undefined;
    readonly threadedComments?: {
      readonly edges: ReadonlyArray<{
        readonly cursor: string;
        readonly node: {
          readonly __typename: string;
          readonly author: {
            readonly accountId: string;
            readonly name: string;
            readonly picture: AGG$URL;
          } | null | undefined;
          readonly authorCanSeeRequest?: boolean | null | undefined;
          readonly childComments?: {
            readonly edges: ReadonlyArray<{
              readonly cursor: string;
              readonly node: {
                readonly __typename: string;
                readonly author: {
                  readonly accountId: string;
                  readonly name: string;
                  readonly picture: AGG$URL;
                } | null | undefined;
                readonly commentId: string;
                readonly created: AGG$DateTime;
                readonly isDeleted: boolean | null | undefined;
                readonly permissionLevel: {
                  readonly group: {
                    readonly groupId: string;
                    readonly id: string;
                    readonly name: string;
                  } | null | undefined;
                  readonly role: {
                    readonly id: string;
                    readonly name: string | null | undefined;
                  } | null | undefined;
                } | null | undefined;
                readonly richText: {
                  readonly adfValue: {
                    readonly json: AGG$JSON | null | undefined;
                  } | null | undefined;
                } | null | undefined;
                readonly updateAuthor: {
                  readonly accountId: string;
                  readonly name: string;
                  readonly picture: AGG$URL;
                } | null | undefined;
                readonly updated: AGG$DateTime | null | undefined;
              } | null | undefined;
            } | null | undefined> | null | undefined;
            readonly pageInfo: {
              readonly endCursor: string | null | undefined;
              readonly hasNextPage: boolean;
              readonly hasPreviousPage: boolean;
              readonly startCursor: string | null | undefined;
            };
          } | null | undefined;
          readonly commentId: string;
          readonly created: AGG$DateTime;
          readonly eventOccurredAt?: AGG$DateTime | null | undefined;
          readonly isDeleted: boolean | null | undefined;
          readonly jsdIncidentActivityViewHidden?: boolean | null | undefined;
          readonly permissionLevel: {
            readonly group: {
              readonly groupId: string;
              readonly id: string;
              readonly name: string;
            } | null | undefined;
            readonly role: {
              readonly id: string;
              readonly name: string | null | undefined;
            } | null | undefined;
          } | null | undefined;
          readonly richText: {
            readonly adfValue: {
              readonly json: AGG$JSON | null | undefined;
            } | null | undefined;
          } | null | undefined;
          readonly targetChildComments?: {
            readonly edges: ReadonlyArray<{
              readonly cursor: string;
              readonly node: {
                readonly __typename: string;
                readonly author: {
                  readonly accountId: string;
                  readonly name: string;
                  readonly picture: AGG$URL;
                } | null | undefined;
                readonly commentId: string;
                readonly created: AGG$DateTime;
                readonly isDeleted: boolean | null | undefined;
                readonly permissionLevel: {
                  readonly group: {
                    readonly groupId: string;
                    readonly id: string;
                    readonly name: string;
                  } | null | undefined;
                  readonly role: {
                    readonly id: string;
                    readonly name: string | null | undefined;
                  } | null | undefined;
                } | null | undefined;
                readonly richText: {
                  readonly adfValue: {
                    readonly json: AGG$JSON | null | undefined;
                  } | null | undefined;
                } | null | undefined;
                readonly updateAuthor: {
                  readonly accountId: string;
                  readonly name: string;
                  readonly picture: AGG$URL;
                } | null | undefined;
                readonly updated: AGG$DateTime | null | undefined;
              } | null | undefined;
            } | null | undefined> | null | undefined;
            readonly pageInfo: {
              readonly endCursor: string | null | undefined;
              readonly hasNextPage: boolean;
              readonly hasPreviousPage: boolean;
              readonly startCursor: string | null | undefined;
            };
          } | null | undefined;
          readonly updateAuthor: {
            readonly accountId: string;
            readonly name: string;
            readonly picture: AGG$URL;
          } | null | undefined;
          readonly updated: AGG$DateTime | null | undefined;
          readonly visibility?: JiraServiceManagementCommentVisibility | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly pageInfo: {
        readonly endCursor: string | null | undefined;
        readonly hasNextPage: boolean;
        readonly hasPreviousPage: boolean;
        readonly startCursor: string | null | undefined;
      };
    } | null | undefined;
  } | null | undefined;
};
export type commentFetchAggServerQuery = {
  response: commentFetchAggServerQuery$data;
  variables: commentFetchAggServerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "afterTarget"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "beforeTarget"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasCommentTargetId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueAri"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jiraThreadedCommentsEnabled"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "targetId"
},
v10 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "issueAri"
  }
],
v11 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v12 = {
  "kind": "Variable",
  "name": "afterTarget",
  "variableName": "afterTarget"
},
v13 = {
  "kind": "Variable",
  "name": "before",
  "variableName": "before"
},
v14 = {
  "kind": "Variable",
  "name": "beforeTarget",
  "variableName": "beforeTarget"
},
v15 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v16 = {
  "kind": "Variable",
  "name": "last",
  "variableName": "last"
},
v17 = {
  "kind": "Literal",
  "name": "sortBy",
  "value": {
    "field": "CREATED",
    "order": "DESC"
  }
},
v18 = {
  "kind": "Variable",
  "name": "targetId",
  "variableName": "targetId"
},
v19 = [
  (v11/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/),
  (v16/*: any*/),
  (v17/*: any*/),
  (v18/*: any*/)
],
v20 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v21 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v22 = {
  "kind": "ScalarField",
  "name": "commentId"
},
v23 = {
  "kind": "ScalarField",
  "name": "created"
},
v24 = {
  "kind": "ScalarField",
  "name": "updated"
},
v25 = {
  "kind": "ScalarField",
  "name": "name"
},
v26 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v27 = {
  "kind": "ScalarField",
  "name": "picture"
},
v28 = [
  (v25/*: any*/),
  (v26/*: any*/),
  (v27/*: any*/)
],
v29 = {
  "kind": "LinkedField",
  "name": "author",
  "plural": false,
  "selections": (v28/*: any*/)
},
v30 = {
  "kind": "LinkedField",
  "name": "updateAuthor",
  "plural": false,
  "selections": (v28/*: any*/)
},
v31 = {
  "concreteType": "JiraRichText",
  "kind": "LinkedField",
  "name": "richText",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraADF",
      "kind": "LinkedField",
      "name": "adfValue",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "json"
        }
      ]
    }
  ]
},
v32 = {
  "kind": "ScalarField",
  "name": "id"
},
v33 = {
  "concreteType": "JiraPermissionLevel",
  "kind": "LinkedField",
  "name": "permissionLevel",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraGroup",
      "kind": "LinkedField",
      "name": "group",
      "plural": false,
      "selections": [
        (v32/*: any*/),
        (v25/*: any*/),
        {
          "kind": "ScalarField",
          "name": "groupId"
        }
      ]
    },
    {
      "concreteType": "JiraRole",
      "kind": "LinkedField",
      "name": "role",
      "plural": false,
      "selections": [
        (v32/*: any*/),
        (v25/*: any*/)
      ]
    }
  ]
},
v34 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "authorCanSeeRequest"
    },
    {
      "kind": "ScalarField",
      "name": "eventOccurredAt"
    },
    {
      "kind": "ScalarField",
      "name": "visibility"
    },
    {
      "kind": "ScalarField",
      "name": "jsdIncidentActivityViewHidden"
    }
  ],
  "type": "JiraServiceManagementComment"
},
v35 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "hasNextPage"
    },
    {
      "kind": "ScalarField",
      "name": "hasPreviousPage"
    },
    {
      "kind": "ScalarField",
      "name": "startCursor"
    },
    {
      "kind": "ScalarField",
      "name": "endCursor"
    }
  ]
},
v36 = [
  (v11/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/),
  (v16/*: any*/),
  {
    "kind": "Literal",
    "name": "rootCommentsOnly",
    "value": true
  },
  (v17/*: any*/),
  (v18/*: any*/)
],
v37 = {
  "kind": "ScalarField",
  "name": "isDeleted"
},
v38 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v39 = [
  {
    "concreteType": "JiraCommentEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      (v20/*: any*/),
      {
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v37/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v33/*: any*/)
        ]
      }
    ]
  },
  (v35/*: any*/)
],
v40 = [
  {
    "kind": "Literal",
    "name": "afterTarget",
    "value": 1
  },
  {
    "kind": "Literal",
    "name": "beforeTarget",
    "value": 1
  },
  (v18/*: any*/)
],
v41 = [
  (v21/*: any*/),
  (v25/*: any*/),
  (v26/*: any*/),
  (v27/*: any*/),
  (v32/*: any*/)
],
v42 = {
  "kind": "LinkedField",
  "name": "author",
  "plural": false,
  "selections": (v41/*: any*/)
},
v43 = {
  "kind": "LinkedField",
  "name": "updateAuthor",
  "plural": false,
  "selections": (v41/*: any*/)
},
v44 = {
  "kind": "InlineFragment",
  "selections": [
    (v32/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v45 = [
  {
    "concreteType": "JiraCommentEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      (v20/*: any*/),
      {
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v37/*: any*/),
          (v42/*: any*/),
          (v43/*: any*/),
          (v31/*: any*/),
          (v33/*: any*/),
          (v44/*: any*/)
        ]
      }
    ]
  },
  (v35/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Fragment",
    "name": "commentFetchAggServerQuery",
    "selections": [
      {
        "args": (v10/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v19/*: any*/),
                "concreteType": "JiraCommentConnection",
                "kind": "LinkedField",
                "name": "comments",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraCommentEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v20/*: any*/),
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v21/*: any*/),
                          (v22/*: any*/),
                          (v23/*: any*/),
                          (v24/*: any*/),
                          (v29/*: any*/),
                          (v30/*: any*/),
                          (v31/*: any*/),
                          (v33/*: any*/),
                          (v34/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v35/*: any*/)
                ]
              },
              {
                "condition": "jiraThreadedCommentsEnabled",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": "threadedComments",
                    "args": (v36/*: any*/),
                    "concreteType": "JiraCommentConnection",
                    "kind": "LinkedField",
                    "name": "comments",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraCommentEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          (v20/*: any*/),
                          {
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v21/*: any*/),
                              (v22/*: any*/),
                              (v23/*: any*/),
                              (v24/*: any*/),
                              (v37/*: any*/),
                              (v29/*: any*/),
                              (v30/*: any*/),
                              (v31/*: any*/),
                              (v33/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "args": (v38/*: any*/),
                                    "concreteType": "JiraCommentConnection",
                                    "kind": "LinkedField",
                                    "name": "childComments",
                                    "plural": false,
                                    "selections": (v39/*: any*/),
                                    "storageKey": "childComments(first:1)"
                                  },
                                  {
                                    "condition": "hasCommentTargetId",
                                    "kind": "Condition",
                                    "passingValue": true,
                                    "selections": [
                                      {
                                        "alias": "targetChildComments",
                                        "args": (v40/*: any*/),
                                        "concreteType": "JiraCommentConnection",
                                        "kind": "LinkedField",
                                        "name": "childComments",
                                        "plural": false,
                                        "selections": (v39/*: any*/)
                                      }
                                    ]
                                  }
                                ],
                                "type": "JiraPlatformComment"
                              },
                              (v34/*: any*/)
                            ]
                          }
                        ]
                      },
                      (v35/*: any*/)
                    ]
                  }
                ]
              }
            ],
            "type": "JiraIssue"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v6/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v8/*: any*/),
      (v2/*: any*/),
      (v9/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v7/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "commentFetchAggServerQuery",
    "selections": [
      {
        "args": (v10/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v21/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v19/*: any*/),
                "concreteType": "JiraCommentConnection",
                "kind": "LinkedField",
                "name": "comments",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraCommentEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v20/*: any*/),
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v21/*: any*/),
                          (v22/*: any*/),
                          (v23/*: any*/),
                          (v24/*: any*/),
                          (v42/*: any*/),
                          (v43/*: any*/),
                          (v31/*: any*/),
                          (v33/*: any*/),
                          (v34/*: any*/),
                          (v44/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v35/*: any*/)
                ]
              },
              {
                "condition": "jiraThreadedCommentsEnabled",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": "threadedComments",
                    "args": (v36/*: any*/),
                    "concreteType": "JiraCommentConnection",
                    "kind": "LinkedField",
                    "name": "comments",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraCommentEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          (v20/*: any*/),
                          {
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v21/*: any*/),
                              (v22/*: any*/),
                              (v23/*: any*/),
                              (v24/*: any*/),
                              (v37/*: any*/),
                              (v42/*: any*/),
                              (v43/*: any*/),
                              (v31/*: any*/),
                              (v33/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "args": (v38/*: any*/),
                                    "concreteType": "JiraCommentConnection",
                                    "kind": "LinkedField",
                                    "name": "childComments",
                                    "plural": false,
                                    "selections": (v45/*: any*/),
                                    "storageKey": "childComments(first:1)"
                                  },
                                  {
                                    "condition": "hasCommentTargetId",
                                    "kind": "Condition",
                                    "passingValue": true,
                                    "selections": [
                                      {
                                        "alias": "targetChildComments",
                                        "args": (v40/*: any*/),
                                        "concreteType": "JiraCommentConnection",
                                        "kind": "LinkedField",
                                        "name": "childComments",
                                        "plural": false,
                                        "selections": (v45/*: any*/)
                                      }
                                    ]
                                  }
                                ],
                                "type": "JiraPlatformComment"
                              },
                              (v34/*: any*/),
                              (v44/*: any*/)
                            ]
                          }
                        ]
                      },
                      (v35/*: any*/)
                    ]
                  }
                ]
              }
            ],
            "type": "JiraIssue"
          },
          (v32/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "id": "7535921cc408028d90e73c75438f5538f14e15a19b3d34cc2d6bd07696dad14e",
    "metadata": {},
    "name": "commentFetchAggServerQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "149b35889a0113ab9ade341dd04cb6f8";

export default node;
