import React, { useContext } from 'react';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { createUseEntryPointContext } from '@atlassian/jira-entry-point/src/controllers/utils/create-use-entry-point-context/index.tsx';
import type { EntryPointActions } from '@atlassian/jira-entry-point/src/controllers/utils/types.tsx';
import { AiRelatedIssuesEntrypoint } from '../../entrypoint.tsx';

export const AiRelatedIssuesEntrypointContext =
	createUseEntryPointContext<typeof AiRelatedIssuesEntrypoint>();

const EMPTY_ENTRY_POINT_PARAMS = {};

export const AiRelatedIssuesEntrypointContextProvider = ({
	children,
}: React.PropsWithChildren<{}>) => {
	const entryPoint = useEntryPoint(AiRelatedIssuesEntrypoint, EMPTY_ENTRY_POINT_PARAMS);

	return (
		<AiRelatedIssuesEntrypointContext.Provider value={entryPoint}>
			{children}
		</AiRelatedIssuesEntrypointContext.Provider>
	);
};

export const useAiRelatedIssuesEntryPointActions = (): EntryPointActions =>
	useContext(AiRelatedIssuesEntrypointContext).entryPointActions;
