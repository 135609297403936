export class PlaceholderTemplateError extends Error {
	sourceActivity: string;

	statusCode: number | undefined;

	traceId: string | undefined;

	constructor(message: string, sourceActivity: string, statusCode?: number, traceId?: string) {
		super(message);
		this.sourceActivity = sourceActivity;
		this.statusCode = statusCode;
		this.traceId = traceId;
	}
}
