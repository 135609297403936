import type { WorkSuggestions } from '../../../model/index.tsx';

// FETCH WORK SUGGESTIONS
export const FETCH_WORK_SUGGESTIONS =
	'state.actions.work-suggestions.FETCH_WORK_SUGGESTIONS' as const;

export type FetchWorkSuggestionsAction = {
	type: typeof FETCH_WORK_SUGGESTIONS;
};

export const fetchWorkSuggestions = (): FetchWorkSuggestionsAction => ({
	type: FETCH_WORK_SUGGESTIONS,
});

// SET WORK SUGGESTIONS DATA
export const SET_WORK_SUGGESTIONS_DATA =
	'state.actions.work-suggestions.SET_WORK_SUGGESTIONS_DATA' as const;

export type SetWorkSuggestionsDataAction = {
	type: typeof SET_WORK_SUGGESTIONS_DATA;
	payload: WorkSuggestions;
};

export const setWorkSuggestionsData = (payload: WorkSuggestions): SetWorkSuggestionsDataAction => ({
	type: SET_WORK_SUGGESTIONS_DATA,
	payload,
});

export const DISABLE_MERGEABLE_SUGGESTIONS_ACTION =
	'state.actions.work-suggestions.DISABLE_MERGEABLE_SUGGESTIONS_ACTION' as const;

export type DISABLE_MERGEABLE_SUGGESTIONS_PAYLOAD_TYPE = {
	suggestionId: string;
};
export type DisableMergeableSuggestionsAction = {
	type: typeof DISABLE_MERGEABLE_SUGGESTIONS_ACTION;
	payload: DISABLE_MERGEABLE_SUGGESTIONS_PAYLOAD_TYPE;
};

export const disableMergeableSuggestions = (
	payload: DISABLE_MERGEABLE_SUGGESTIONS_PAYLOAD_TYPE,
): DisableMergeableSuggestionsAction => ({
	type: DISABLE_MERGEABLE_SUGGESTIONS_ACTION,
	payload,
});

export type Action =
	| FetchWorkSuggestionsAction
	| SetWorkSuggestionsDataAction
	| DisableMergeableSuggestionsAction;
