import { useCallback, useEffect, useState } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performGetRequest, performPutRequest } from '@atlassian/jira-fetch/src/index.tsx';
import { CROSSFLOW_AD_DISMISSED_USER_PREF_KEY } from '../constants.tsx';

/**
 * API request to update user prefs for Crossflow Ad
 */
export const setCrossflowPreferences = async (value: boolean) => {
	try {
		await performPutRequest(
			`/rest/api/3/mypreferences?key=${CROSSFLOW_AD_DISMISSED_USER_PREF_KEY}`,
			{
				body: JSON.stringify({ value }),
			},
		);
	} catch (error) {
		fireErrorAnalytics({
			error: error instanceof Error ? error : new Error('Failed to update user prefs Crossflow Ad'),
			meta: {
				id: 'setCrossflowPreferences',
				packageName: 'jiraQuickActions',
				teamName: 'bento',
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}
};

/**
 * API request to get user prefs for Crossflow Ad
 * @deprecated moved to relay
 */
const getCrossflowPreferences = async () => {
	try {
		const apiResult = await performGetRequest(
			`/rest/api/3/mypreferences?key=${CROSSFLOW_AD_DISMISSED_USER_PREF_KEY}`,
		);
		return apiResult.value;

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		if (error.statusCode === 404) {
			// Error 404 is acceptable as the first time the key does not exist in the user preferences.
			return false;
		}
		fireErrorAnalytics({
			error:
				error instanceof Error ? error : new Error('Failed to get user prefs for Crossflow Ad'),
			meta: {
				id: 'getCrossflowPreferences',
				packageName: 'jiraQuickActions',
				teamName: 'bento',
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}
};

/**
 * Hook to
 * - check if user has dismissed crossflow Ad in quick actions
 * - dismiss Ad by sending sending a user preference request to BE
 */
export const useCrossflowAdControls = () => {
	const [userHasDismissedAd, setUserHasDismissedAd] = useState(false);

	useEffect(() => {
		const run = async () => {
			const value = await getCrossflowPreferences();
			setUserHasDismissedAd(!!value);
		};
		run();
	}, []);

	const dismissAd = useCallback(() => {
		setUserHasDismissedAd(true);
		setCrossflowPreferences(true);
	}, []);

	return { userHasDismissedAd, dismissAd };
};
