import { useCallback, useMemo } from 'react';
import { createSessionStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/session-storage/index.tsx';
import { useTenantHasConfluence } from '@atlassian/jira-business-confluence-pages/src/utils/use-tenant-has-confluence/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import {
	CONFLUENCE_ACCESS_STORAGE_KEY,
	CONFLUENCE_ACCESS_STORAGE_PROVIDER_KEY,
} from '@atlassian/jira-confluence-integration-controls/src/constants.tsx';
import {
	getSessionStorageData,
	setSessionStorageData,
	isStorageKeyExpired,
} from '@atlassian/jira-confluence-integration-controls/src/services/session-storage/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useEmbeddedPageTracking } from '../use-embedded-page-tracking/index.tsx';
import { fetchConfluenceUserPermissions } from '../../services/permissions/index.tsx';

const TIME_TO_LIVE = 30 * 60 * 1000; // 30 minutes

const storage = createSessionStorageProvider(CONFLUENCE_ACCESS_STORAGE_PROVIDER_KEY);

export const useUserHasConfluenceAccessCallback = (
	embeddedConfluenceSource?: string,
): { getConfluencePermissions: () => Promise<boolean> } => {
	const cloudId = useCloudId();
	const fireAnalyticsEvent = useEmbeddedPageTracking();
	const tenantHasConfluence = useTenantHasConfluence();
	const permitted = useMemo(
		() => getSessionStorageData(CONFLUENCE_ACCESS_STORAGE_KEY, storage)?.permitted,
		[],
	);

	const getConfluencePermissions = useCallback(async () => {
		if (!getSessionStorageData(CONFLUENCE_ACCESS_STORAGE_KEY, storage)) {
			setSessionStorageData({
				key: CONFLUENCE_ACCESS_STORAGE_KEY,
				value: {},
				ttl: TIME_TO_LIVE,
				storage,
			});
		}

		if (!isStorageKeyExpired(CONFLUENCE_ACCESS_STORAGE_KEY, storage) && permitted !== undefined) {
			return permitted;
		}

		if (!tenantHasConfluence) {
			setSessionStorageData({
				key: CONFLUENCE_ACCESS_STORAGE_KEY,
				value: { permitted: false },
				ttl: TIME_TO_LIVE,
				storage,
			});
			return false;
		}

		try {
			if (!cloudId) return false;
			const response = await fetchConfluenceUserPermissions(cloudId);
			fireAnalyticsEvent({
				action: 'success',
				eventName: 'userConfluenceAccess',
				embeddedConfluenceSource,
			});
			setSessionStorageData({
				key: CONFLUENCE_ACCESS_STORAGE_KEY,
				value: response,
				ttl: TIME_TO_LIVE,
				storage,
			});
			return response?.permitted;
		} catch (error) {
			fireErrorAnalytics({
				meta: {
					id: 'userConfluenceAccess',
					teamName: 'confluence-better-together',
				},
				attributes: { embeddedConfluenceSource },
				error: new Error(`Failed to get user confluence access status: ${error}`),
			});
			return false;
		}
	}, [cloudId, embeddedConfluenceSource, fireAnalyticsEvent, permitted, tenantHasConfluence]);

	return { getConfluencePermissions };
};
