import React from 'react';
import { useQueryLoader, type PreloadedQuery } from 'react-relay';
import AddIcon from '@atlaskit/icon/core/migration/add';
import {
	FooterContainer,
	OptionLabel,
} from '@atlassian/jira-issue-internal-field-select/src/common/styled.tsx';
import createReleaseModalQuery, {
	type createReleaseModalQuery as createReleaseModalQueryType,
} from '@atlassian/jira-relay/src/__generated__/createReleaseModalQuery.graphql';
import { useCreateReleaseExperienceStart } from '@atlassian/jira-software-releases-release-modals-relay/src/services/create-release-experience-tracker/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';

const ISSUE_VIEW = 'issueView';

export type Props = {
	createNewItemLabel: string;
	createNewItemIconLabel?: string;
	onCreate: (
		createReleaseModalQueryReference:
			| PreloadedQuery<createReleaseModalQueryType, Record<string, unknown>>
			| null
			| undefined,
	) => void;
};

export const CreateVersionButton = ({
	createNewItemLabel,
	createNewItemIconLabel,
	onCreate,
}: Props) => {
	const accountId = useAccountId();
	const [createReleaseFormQueryRef, loadQuery] =
		useQueryLoader<createReleaseModalQueryType>(createReleaseModalQuery);
	const experienceStart = useCreateReleaseExperienceStart(ISSUE_VIEW);

	const loadCreateReleaseQuery = () => {
		if (!createReleaseFormQueryRef && accountId) {
			loadQuery({ accountId });
		}
	};

	return (
		<FooterContainer
			/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */
			className="issue-view__select-footer"
			onClick={() => {
				experienceStart();
				loadCreateReleaseQuery();
				onCreate(createReleaseFormQueryRef);
			}}
			onFocus={loadCreateReleaseQuery}
			onMouseOver={loadCreateReleaseQuery}
		>
			<AddIcon label={createNewItemIconLabel || ''} LEGACY_size="small" />
			<OptionLabel>{createNewItemLabel}</OptionLabel>
		</FooterContainer>
	);
};
