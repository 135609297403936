// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State } from '../../types.tsx';

export const setSignature =
	(cannedResponseContent: string | null) =>
	async ({ getState, setState }: StoreActionApi<State>) => {
		const formattedContent = cannedResponseContent && JSON.parse(cannedResponseContent);
		setState({
			...getState(),
			signature: formattedContent || null,
		});
	};

export const resetSignature =
	() =>
	async ({ getState, setState }: StoreActionApi<State>) => {
		setState({
			...getState(),
			signature: undefined,
		});
	};
