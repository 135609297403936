/**
 * @generated SignedSource<<43ea609a525947a446b70ec9eff24d2f>>
 * @relayHash 6ffd0e080815b3aa85b4d35f6f7f2f87
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 6dc65dcdc5db304002654de8f5a8498b43423a8d89571e0e03e18aad70a7fa2c

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueViewLayoutIssueTypeSwitcherRefetchQuery$variables = {
  id: string;
};
export type issueViewLayoutIssueTypeSwitcherRefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcherWithRefetch">;
  } | null | undefined;
};
export type issueViewLayoutIssueTypeSwitcherRefetchQuery = {
  response: issueViewLayoutIssueTypeSwitcherRefetchQuery$data;
  variables: issueViewLayoutIssueTypeSwitcherRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "id"
},
v3 = {
  "kind": "ScalarField",
  "name": "name"
},
v4 = {
  "kind": "ScalarField",
  "name": "issueTypeId"
},
v5 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "xsmall"
    }
  ]
},
v6 = {
  "concreteType": "JiraIssueTypeHierarchyLevel",
  "kind": "LinkedField",
  "name": "hierarchy",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "level"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "issueViewLayoutIssueTypeSwitcherRefetchQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcherWithRefetch"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "issueViewLayoutIssueTypeSwitcherRefetchQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "__typename"
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "ScalarField",
                "name": "fieldId"
              },
              {
                "kind": "ScalarField",
                "name": "type"
              },
              {
                "concreteType": "JiraIssue",
                "kind": "LinkedField",
                "name": "issue",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "issueId"
                  },
                  {
                    "concreteType": "JiraProjectField",
                    "kind": "LinkedField",
                    "name": "projectField",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraProject",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "projectStyle"
                          },
                          (v2/*: any*/)
                        ]
                      },
                      (v2/*: any*/)
                    ]
                  },
                  (v2/*: any*/)
                ]
              },
              {
                "concreteType": "JiraIssueType",
                "kind": "LinkedField",
                "name": "issueType",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ]
              },
              {
                "concreteType": "JiraIssueTypeConnection",
                "kind": "LinkedField",
                "name": "issueTypes",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraIssueType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v4/*: any*/),
                          (v3/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ],
            "type": "JiraIssueTypeField"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "6dc65dcdc5db304002654de8f5a8498b43423a8d89571e0e03e18aad70a7fa2c",
    "metadata": {},
    "name": "issueViewLayoutIssueTypeSwitcherRefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "7b39cce63227cc91be2a29e2494bfb4d";

export default node;
