import React, {
	memo,
	type LegacyRef,
	useState,
	useEffect,
	useCallback,
	type ChangeEvent,
} from 'react';
import { styled } from '@compiled/react';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import TextArea from '@atlaskit/textarea';
import { token } from '@atlaskit/tokens';
import { JiraInlineDialog as InlineDialog } from '@atlassian/jira-inline-dialog/src/ui/jira-inline-dialog.tsx';
import TextAreaOld from '@atlassian/jira-common-components-text-area/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { StringValue } from '@atlassian/jira-issue-field-summary-common/src/services/types.tsx';
import { useIsCompactMode } from '@atlassian/jira-issue-view-compact-mode/src/index.tsx';
import { SummaryHeading, SummaryHeadingNew } from '../../common/ui/styled.tsx';
import messages from './messages.tsx';

type PropsOld = {
	passedRef?: LegacyRef<HTMLHeadingElement>;
	defaultValue: StringValue;
	onChange: (arg1: string) => void;
};
export type Props = {
	isInvalid?: boolean;
	invalidMessage?: string | undefined;
} & PropsOld;

export const SummaryEdit = memo<Props>(
	({ defaultValue, passedRef, onChange, isInvalid, invalidMessage }: Props) => {
		const testId = 'issue.views.issue-base.foundation.summary.heading.writeable';
		const [openDialog, setOpenDialog] = useState(isInvalid);
		const isCompact = useIsCompactMode();
		const { formatMessage } = useIntl();

		useEffect(() => {
			setOpenDialog(isInvalid);
		}, [isInvalid]);

		const onChangeHandler = useCallback(
			(event: ChangeEvent<HTMLTextAreaElement>) => {
				onChange(event.target.value);
			},
			[onChange],
		);

		const onFocusHandler = useCallback(() => {
			if (isInvalid === true) {
				setOpenDialog(true);
			}
		}, [isInvalid]);

		const onBlurHandler = useCallback(() => {
			setOpenDialog(false);
		}, []);

		return (
			<InlineDialog
				messageId="issue-field-summary.ui.edit.inline-dialog.main.inline-dialog"
				messageType="transactional"
				content={invalidMessage}
				isOpen={isInvalid === true && openDialog}
				placement={isCompact === true ? 'auto-start' : 'right'}
			>
				<EditViewContainer>
					<SummaryHeadingNew ref={passedRef} data-testid={testId}>
						<TextArea
							aria-required
							autoFocus
							aria-label={formatMessage(messages.summaryTextareaLabel)}
							isInvalid={isInvalid}
							value={defaultValue || ''}
							onChange={onChangeHandler}
							onFocus={onFocusHandler}
							onBlur={onBlurHandler}
							minimumRows={1}
						/>
					</SummaryHeadingNew>
					{isInvalid === true && (
						<WarningIcon
							LEGACY_size="medium"
							spacing="spacious"
							color={token('color.icon.warning')}
							label=""
						/>
					)}
				</EditViewContainer>
			</InlineDialog>
		);
	},
);

export const SummaryEditOld = memo<PropsOld>(({ defaultValue, passedRef, onChange }: PropsOld) => {
	const testId = 'issue.views.issue-base.foundation.summary.heading.writeable';
	const { formatMessage } = useIntl();
	return (
		<SummaryHeading ref={passedRef} data-testid={testId}>
			<TextAreaOld
				shouldAutoFocus
				value={defaultValue}
				onChange={onChange}
				aria-label={formatMessage(messages.summaryTextareaLabel)}
			/>
		</SummaryHeading>
	);
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditViewContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: 1,
	maxWidth: '100%',
	wordBreak: 'break-word',
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	span: {
		position: 'absolute',
		right: token('space.100'),
	},
});
