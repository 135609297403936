import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import {
	OLDEST_FIRST,
	NEWEST_FIRST,
} from '@atlassian/jira-issue-shared-types/src/common/types/activity-sort-order-type.tsx';
import { issueViewLayers as layers } from '@atlassian/jira-issue-view-layers/src/index.tsx';

const CONTAINER_HEIGHT = 100;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledCommentShortcutHint = styled.small({
	color: token('color.text.subtlest'),
	display: 'block',
	marginTop: token('space.100'),
	whiteSpace: 'nowrap',
	font: token('font.body.UNSAFE_small'),
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766, PLEASE FIX - ENABLING FLAT LINT CONFIG
export const StickyWrapper = styled.div<{
	isHidden: boolean;
	isKeylineVisible: boolean;
	shouldApplySticky: boolean;
	shouldApplyTopSticky: boolean;
	sortOrder: string;
	isModal: boolean;
	isCompact: boolean;
}>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		props.shouldApplySticky && {
			position: props.isCompact ? 'static' : 'sticky',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			zIndex: props.isKeylineVisible ? layers.baseLayer : 0,
			transition: 'transform 0.3s cubic-bezier(0.86, 0, 0.07, 1) 0.1s',
			transform: props.isHidden
				? `translate(0, ${
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						props.sortOrder === OLDEST_FIRST ? CONTAINER_HEIGHT : CONTAINER_HEIGHT * -1
					}px)`
				: 'translate(0, 0)',

			bottom: token('space.negative.025'),

			top: props.shouldApplyTopSticky ? 0 : undefined,
			background: props.isModal ? token('elevation.surface.overlay') : token('elevation.surface'),
			paddingLeft: token('space.100'),
			marginLeft: token('space.negative.100'),
		},
);

// keyline is displayed above the form when it is in a sticky position to the bottom
// keyline is displayed below the form when it is in a sticky position to the top
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Wrapper = styled.div<{
	isKeylineVisible: boolean;
	isModal: boolean;
	shouldApplySticky: boolean;
	shouldApplyTopSticky: boolean;
	sortOrder: string;
	shouldOverlap: boolean;
}>(
	{
		display: 'flex',
		paddingTop: token('space.200'),
		paddingBottom: token('space.200'),
		/* Needed to adjust the amount of space above and below the comment add box when it is at the top of the feed */
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		marginBottom: (props) =>
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			props.sortOrder === NEWEST_FIRST && props.shouldOverlap ? token('space.negative.200') : 0,
		marginTop: token('space.100'),

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		background: (props) =>
			props.isModal ? token('elevation.surface.overlay') : token('elevation.surface'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		props.isKeylineVisible && props.shouldApplySticky
			? {
					boxShadow: props.shouldApplyTopSticky
						? `0 2px 0 0 ${token('color.border')}, 0 -2px 0 0 ${token('color.border')}`
						: `0 -2px 0 0 ${token('color.border')}`,
					marginTop: token('space.100'),
				}
			: undefined,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AvatarContainer = styled.div({
	marginRight: token('space.150'),
	marginTop: token('space.025'),
});
