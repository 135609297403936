import head from 'lodash/head';
import { fg } from '@atlassian/jira-feature-gating';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { DynamicFieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { isGlobalSystemField } from '@atlassian/jira-polaris-domain-field/src/field/utils.tsx';
import { getSFGDualWriteFieldFormula } from '../../utils/dual-write-field-formula.tsx';
import type { Props, State } from '../../types.tsx';

export const updateFieldFormula =
	(fieldKey: FieldKey, formula: DynamicFieldFormula) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ issueTypeIds, fieldRemote, createAnalyticsEvent }: Props,
	): Promise<Field> => {
		const state = getState();
		const currentField = state.fields[fieldKey];

		fireTrackAnalytics(createAnalyticsEvent({}), 'issueFields updated', {
			updatedItems: [{ name: 'formula' }],
			issueFieldKey: currentField.key,
			issueFieldType: currentField.type,
		});

		const issueTypeId = head(issueTypeIds);

		if (issueTypeId === undefined) {
			throw new Error('cannot update field formula for unknown issue type');
		}

		const { formula: newFormula, formulaBackup } = getSFGDualWriteFieldFormula(
			state.fields,
			formula,
			!!currentField.configuration?.polarisFormulaBackup,
		);

		const oldConfiguration = currentField.formula
			? {
					polarisFormula: currentField.formula,
					polarisFormulaBackup: currentField.configuration?.polarisFormulaBackup,
				}
			: {};

		const configuration = fg('jpd-sfg-dualwrite-field-formula')
			? {
					polarisFormula: newFormula,
					polarisFormulaBackup: formulaBackup,
				}
			: { polarisFormula: formula };

		await fieldRemote.updateFieldConfiguration({
			fieldKey,
			issueTypeId,
			oldConfiguration,
			configuration,
			type: 'updateFieldFormula',
			isGlobalSystemField: isGlobalSystemField(currentField),
		});

		const newField = {
			...currentField,
			formula,
			configuration: {
				...currentField.configuration,
				...configuration,
			},
		};
		setState({
			fields: {
				...getState().fields,
				[fieldKey]: newField,
			},
		});

		return newField;
	};
