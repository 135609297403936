// Exclude union value automatically added by Relay
// eslint-disable-next-line @atlassian/relay/no-future-added-value
type LowercaseTimeUnion<Union extends string> = Lowercase<Exclude<Union, '%future added value'>>;

/* Convert the input string to lowercase and explicitly cast the return type so we can enforce type safety between AGG
 * enum types and supported UI values.
 */
export const toLowercaseTimeUnion = <Union extends string>(
	union: Union,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
): LowercaseTimeUnion<Union> => union.toLowerCase() as LowercaseTimeUnion<Union>;
