import { useEffect, useState } from 'react';
import {
	useIsAiEnabledForIssue,
	useIssueKey,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useDescriptionField } from '@atlassian/jira-issue-field-rich-text/src/services/index.tsx';
import { getADFCharacterCount } from '@atlassian/jira-issue-view-common/src/utils/adf-utils/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';

const MIN_DESCRIPTION_LENGTH_FOR_PROACTIVE_SUGGESTIONS = 100;
const MAX_CHILD_ISSUES_FOR_PROACTIVE_SUGGESTIONS = 3;

/**
 * Custom hook to determine if proactive issue suggestions should be made.
 *
 * @param {number} totalChildIssuesCount - The total number of child issues.
 * @returns {boolean} - Returns true if proactive suggestions should be made, otherwise false.
 * Proactive suggestions are made if the following conditions are met:
 * - The total number of child issues is less than or equal to 3.
 * - The description length is greater than or equal to 100 characters.
 * - AI is enabled for the issue.
 */
export const useShouldProactivelySuggestIssues = (totalChildIssuesCount: number): boolean => {
	const issueKey = useIssueKey();
	const [{ value: description }] = useDescriptionField({ issueKey });
	const isAiOptInEnabled = useIsAiEnabledForIssue();

	const [shouldProactivelySuggestIssues, setShouldProactivelySuggestIssues] = useState(false);

	useEffect(() => {
		// NOTE: The order of this condition check is important for performance.
		// getADFCharacterCount is a relatively expensive operation and should be called only after others have passed.
		const preConditions =
			isAiOptInEnabled &&
			totalChildIssuesCount <= MAX_CHILD_ISSUES_FOR_PROACTIVE_SUGGESTIONS &&
			getADFCharacterCount(description) >= MIN_DESCRIPTION_LENGTH_FOR_PROACTIVE_SUGGESTIONS;
		if (
			preConditions &&
			expVal('proactive_ai_suggestions_for_child_items', 'shouldProactivelyTriggerAIWB', false)
		) {
			setShouldProactivelySuggestIssues(true);
			return;
		}
		setShouldProactivelySuggestIssues(false);
	}, [totalChildIssuesCount, isAiOptInEnabled, description]);

	return shouldProactivelySuggestIssues;
};
