import React, { useCallback } from 'react';
import { graphql, useFragment } from 'react-relay';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/index.tsx';
import type { AggJiraVote } from '@atlassian/jira-issue-field-voters/src/common/types.tsx';
import { UseVoteFieldRelayGenerator } from '@atlassian/jira-issue-field-voters/src/services/vote-field-service-relay/index.tsx';
import { useVoteField } from '@atlassian/jira-issue-field-voters/src/services/vote-field-service/index.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/index.tsx';
import type { main_issueViewFoundation_VoteButtonRelay$key } from '@atlassian/jira-relay/src/__generated__/main_issueViewFoundation_VoteButtonRelay.graphql';
import messages from './messages.tsx';

const VoteButtonRelay = ({
	votesField,
}: {
	votesField: main_issueViewFoundation_VoteButtonRelay$key;
}) => {
	// eslint-disable-next-line @atlassian/relay/query-restriction
	const data = useFragment<main_issueViewFoundation_VoteButtonRelay$key>(
		graphql`
			fragment main_issueViewFoundation_VoteButtonRelay on JiraVotesField {
				fieldId
				__typename
				type
				...voteFieldServiceRelay_issueFieldVoters_UseVoteFieldRelayGenerator
			}
		`,
		votesField,
	);

	const { formatMessage } = useIntl();

	const issueId = useIssueId();
	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();

	const onSubmit = useCallback(
		(value: AggJiraVote) => {
			issueId &&
				fieldChangeRequested(issueId, data.fieldId, value, undefined, {
					type: data.type,
					__typename: data.__typename,
				});
		},
		[data.__typename, data.fieldId, data.type, fieldChangeRequested, issueId],
	);

	const onSubmitSucceeded = useCallback(
		(value: AggJiraVote) => {
			issueId &&
				fieldChanged(issueId, data.fieldId, value, {
					type: data.type,
					__typename: data.__typename,
				});
		},
		[data.__typename, data.fieldId, data.type, fieldChanged, issueId],
	);

	const onSubmitFailed = useCallback(
		() => issueId && fieldChangeFailed(issueId, data.fieldId),
		[data.fieldId, fieldChangeFailed, issueId],
	);

	const useVoteFieldRelay = UseVoteFieldRelayGenerator({
		votesField: data,
		onSubmit,
		onSubmitFailed,
		onSubmitSucceeded,
	});

	const [{ value }, { toggleValue }] = useVoteFieldRelay({});

	const handleClick = useCallback(() => {
		toggleValue(false, true);
	}, [toggleValue]);

	return (
		<DropdownItem key="vote" onClick={handleClick}>
			{value.hasVoted ? formatMessage(messages.removeVote) : formatMessage(messages.addVote)}
		</DropdownItem>
	);
};

const VoteButtonRedux = () => {
	const { formatMessage } = useIntl();

	const [{ value }, { toggleValue }] = useVoteField({});

	const handleClick = useCallback(() => {
		toggleValue(false, true);
	}, [toggleValue]);

	return (
		<DropdownItem key="vote" onClick={handleClick}>
			{value.hasVoted ? formatMessage(messages.removeVote) : formatMessage(messages.addVote)}
		</DropdownItem>
	);
};

export const VoteButton = ({
	votesField,
}: {
	votesField?: main_issueViewFoundation_VoteButtonRelay$key | null;
}) => {
	/**
	 * Checking `votesField` at the top so that even if there is issue in case of fetching
	 * votesField in relay, we have redux implementation of VoteButton to show
	 */
	return votesField && fg('relay-migration-issue-header-and-parent') ? (
		<VoteButtonRelay votesField={votesField} />
	) : (
		<VoteButtonRedux />
	);
};
