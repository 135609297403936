import React, { useEffect } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useExportExcelModalStates } from './controllers/selectors.tsx';
import { exportExcelModalEntryPoint } from './entrypoint.tsx';

const entryPointParams = {};
const runtimeProps = {};

const AsyncExportExcelModal = () => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		exportExcelModalEntryPoint,
		entryPointParams,
	);
	const { isExportExcelModalOpen } = useExportExcelModalStates();

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		if (isExportExcelModalOpen) {
			entryPointActions.load();
			return () => {
				entryPointActions.unload();
			};
		}
	}, [entryPointActions, isExportExcelModalOpen]);

	return (
		<JiraEntryPointContainer
			id="jira-issue-export-excel-modal"
			packageName="jiraIssueExportExcelModal"
			entryPointReferenceSubject={entryPointReferenceSubject}
			runtimeProps={runtimeProps}
		/>
	);
};

export default AsyncExportExcelModal;
