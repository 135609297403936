import { metrics } from '@atlassian/browser-metrics';
import { issueViewLoad } from '@atlassian/jira-issue-view-common-utils/src/utils/performance-analytics/index.tsx';

export const fullPageIssueViewLoad = metrics.pageLoad({
	key: 'issue-view',
	until: issueViewLoad,
	include: [issueViewLoad],
	ssr: {
		doneAsFmp: true,
	},
});

export const embedPageIssueViewLoad = metrics.pageLoad({
	key: 'issue-view-embed',
	until: issueViewLoad,
	include: [issueViewLoad],
	ssr: {
		doneAsFmp: true,
	},
});
