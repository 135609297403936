import React from 'react';
import Icon, { type IconProps } from '@atlaskit/icon';
import { token } from '@atlaskit/tokens';

const recurWorkIconSvg = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_432_193281)">
			<path
				d="M2.73411 5.87941C2.319 6.32489 2.06601 6.89719 2.01594 7.50404C1.96587 8.11088 2.12163 8.71691 2.45811 9.22441C2.4862 9.26936 2.51762 9.31215 2.55211 9.35241L1.79711 10.0084C1.73261 9.93261 1.67347 9.85242 1.62011 9.76841C1.15374 9.07073 0.939836 8.23462 1.01386 7.3987C1.08789 6.56278 1.44539 5.77727 2.02711 5.17241C2.3975 4.79965 2.83819 4.50412 3.32363 4.30294C3.80908 4.10176 4.32963 3.99893 4.85511 4.00041H11.0201L9.42011 2.39841L10.1271 1.69141L12.9351 4.50041L10.1261 7.30941L9.41911 6.60241L11.0211 5.00041H4.85611C4.46185 4.99932 4.07129 5.07645 3.70705 5.22733C3.34281 5.37821 3.01211 5.59985 2.73411 5.87941ZM14.3911 6.23141C14.3389 6.14807 14.2804 6.06884 14.2161 5.99441L13.4571 6.64641C13.4919 6.68735 13.5237 6.7308 13.5521 6.77641C13.8887 7.28379 14.0446 7.88976 13.9948 8.4966C13.9449 9.10345 13.6921 9.67581 13.2771 10.1214C12.9991 10.401 12.6684 10.6226 12.3042 10.7735C11.9399 10.9244 11.5494 11.0015 11.1551 11.0004H4.98011L6.58211 9.39841L5.87511 8.69141L3.06511 11.5004L5.87411 14.3094L6.58111 13.6024L4.97911 12.0004H11.1551C11.6808 12.002 12.2015 11.8993 12.6871 11.6981C13.1727 11.4969 13.6136 11.2013 13.9841 10.8284C14.547 10.213 14.8918 9.42953 14.9654 8.59877C15.0389 7.76801 14.8371 6.93613 14.3911 6.23141Z"
				fill="currentColor"
			/>
		</g>
		<defs>
			<clipPath id="clip0_432_193281">
				<rect width="16" height="16" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

const RecurWorkIcon = (props: IconProps) => (
	<Icon primaryColor={token('color.icon.subtle')} {...props} glyph={recurWorkIconSvg} />
);

export default RecurWorkIcon;
