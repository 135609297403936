import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { Reason } from '@atlassian/jira-issue-refresh-service/src/types.tsx';
import type { IssueError } from '@atlassian/jira-issue-shared-types/src/common/types/error-type.tsx';
import type { IssueViewRealtimeEventType } from '@atlassian/jira-issue-shared-types/src/common/types/realtime-support.tsx';
import type { IssueId, ProjectId, AccountId } from '@atlassian/jira-shared-types/src/general.tsx';

// FETCH_ISSUE_REQUEST
export const FETCH_ISSUE_REQUEST = 'FETCH_ISSUE_REQUEST' as const;

export type FetchIssueFailureDescription = {
	error: IssueError;
	statusCode: number | null | undefined;
	errorMessage?: string | null | undefined;
	traceId?: string | null | undefined;
	endpoint?: string | null | undefined;
};

export const fetchIssueRequest = () => ({
	type: FETCH_ISSUE_REQUEST,
});

// FETCH_ISSUE_SUCCESS
export const FETCH_ISSUE_SUCCESS = 'FETCH_ISSUE_SUCCESS' as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchIssueSuccess = (entities: any) => ({
	type: FETCH_ISSUE_SUCCESS,
	payload: entities,
});

// FETCH_ISSUE_FAILURE
export const FETCH_ISSUE_FAILURE = 'FETCH_ISSUE_FAILURE' as const;

export const fetchIssueFailure = (failureDescription: FetchIssueFailureDescription) => ({
	type: FETCH_ISSUE_FAILURE,
	payload: failureDescription,
});

// REFRESH_ISSUE_REQUEST
export type RefreshIssueRequestPayload<T = Reason> = T extends typeof Reason.Realtime
	? {
			reason: typeof Reason.Realtime;
			details: {
				updateType: IssueViewRealtimeEventType;
				atlassianId: AccountId;
				issueId: IssueId;
				projectId: ProjectId;
				loadingStage?: string;
			};
		}
	: T extends typeof Reason.SoftRefresh
		? { reason: typeof Reason.SoftRefresh }
		: { reason: typeof Reason.OnDemand };

export const REFRESH_ISSUE_REQUEST = 'REFRESH_ISSUE_REQUEST' as const;

export const refreshIssueRequest = (
	payload?: RefreshIssueRequestPayload,
	meta?: { analyticsEvent: UIAnalyticsEvent },
) => ({
	type: REFRESH_ISSUE_REQUEST,
	payload,
	meta,
});

export const CANCEL_REFRESH_ISSUE_REQUEST = 'CANCEL_REFRESH_ISSUE_REQUEST' as const;

export const cancelRefreshIssueRequest = () => ({
	type: CANCEL_REFRESH_ISSUE_REQUEST,
});

// REFRESH_ISSUE_SUCCESS
export const REFRESH_ISSUE_SUCCESS = 'REFRESH_ISSUE_SUCCESS' as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const refreshIssueSuccess = (entities: any) => ({
	type: REFRESH_ISSUE_SUCCESS,
	payload: entities,
});

// FETCH_FORGE_REQUEST
export const FETCH_FORGE_REQUEST = 'FETCH_FORGE_REQUEST' as const;

export const fetchForgeRequest = () => ({
	type: FETCH_FORGE_REQUEST,
});

export type FetchIssueRequestAction = ReturnType<typeof fetchIssueRequest>;
export type FetchIssueSuccessAction = ReturnType<typeof fetchIssueSuccess>;
export type FetchIssueFailureAction = ReturnType<typeof fetchIssueFailure>;
export type RefreshIssueRequestAction = ReturnType<typeof refreshIssueRequest>;
export type RefreshIssueSuccessAction = ReturnType<typeof refreshIssueSuccess>;
export type FetchForgeRequestAction = ReturnType<typeof fetchForgeRequest>;

export type IssueFetchAction =
	| FetchIssueRequestAction
	| FetchIssueSuccessAction
	| FetchIssueFailureAction
	| RefreshIssueRequestAction
	| RefreshIssueSuccessAction
	| FetchForgeRequestAction;
