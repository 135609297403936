import React, { useCallback, useEffect, useState } from 'react';
import { graphql, useFragment, useMutation } from 'react-relay';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { Flex, xcss } from '@atlaskit/primitives';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import { issueRedactionTableEntrypoint } from '@atlassian/jira-issue-redaction-table/entrypoint.tsx';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { main_redactions_issueActionMenu_user$key } from '@atlassian/jira-relay/src/__generated__/main_redactions_issueActionMenu_user.graphql';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

export const Redactions = ({
	onClick,
	label,
	itemKey,
	redactionCount,
	rootRelayFragment,
}: Props) => {
	// eslint-disable-next-line @atlassian/relay/query-restriction
	const user = useFragment<main_redactions_issueActionMenu_user$key>(
		graphql`
			fragment main_redactions_issueActionMenu_user on JiraQuery {
				userPreferences(cloudId: $cloudId) @optIn(to: "JiraUserPreferences") {
					__id
					showRedactionChangeBoardingOnActionMenu
				}
			}
		`,
		rootRelayFragment || null,
	);

	const [commit] = useMutation(graphql`
		mutation mainRedactions_setShowRedactionChangeBoardingOnActionMenuMutation(
			$cloudId: ID!
			$show: Boolean!
		) {
			jira {
				userPreferences(cloudId: $cloudId) @optIn(to: "JiraUserPreferences") {
					setShowRedactionChangeBoardingOnActionMenu(show: $show)
				}
			}
		}
	`);

	const { formatMessage } = useIntl();
	const [showSpotlight, setShowSpotlight] = useState(false);
	const cloudId = useCloudId();

	const handleClick = useCallback(() => {
		onClick?.(itemKey);
	}, [onClick, itemKey]);

	const onClose = useCallback(() => {
		setShowSpotlight(false);
		commit({
			variables: {
				cloudId,
				show: false,
			},
			updater: (store) => {
				const userPreferences = store.get(user?.userPreferences?.__id ?? '');
				userPreferences &&
					userPreferences.setValue(false, 'showRedactionChangeBoardingOnActionMenu');
			},
		});
	}, [cloudId, commit, user?.userPreferences?.__id]);

	useEffect(() => {
		if (user?.userPreferences?.showRedactionChangeBoardingOnActionMenu) {
			setShowSpotlight(true);
		}
	}, [user?.userPreferences?.showRedactionChangeBoardingOnActionMenu]);

	useEffect(
		() => () => {
			if (showSpotlight) {
				onClose();
			}
		},
		[onClose, showSpotlight],
	);

	const onReadMore = useCallback(() => {
		globalThis.open('/', '_blank');
	}, []);

	return (
		<>
			<ModalEntryPointPressableTrigger
				entryPoint={issueRedactionTableEntrypoint}
				entryPointProps={{ redactionCount }}
				modalProps={modalProps}
				useInternalModal
			>
				{({ ref }) => (
					<SpotlightTarget name="action-menu-redactions">
						<DropdownItem ref={ref} key="redaction" onClick={handleClick}>
							<Flex justifyContent="space-between">
								{label}
								<Flex xcss={countStyles}>{redactionCount}</Flex>
							</Flex>
						</DropdownItem>
					</SpotlightTarget>
				)}
			</ModalEntryPointPressableTrigger>
			{showSpotlight && (
				<JiraSpotlight
					actions={[
						{ onClick: onClose, text: formatMessage(messages.ok), autoFocus: true },
						{ onClick: onReadMore, text: formatMessage(messages.readMore) },
					]}
					dialogPlacement="left top"
					target="action-menu-redactions"
					messageType="transactional"
					messageId="issue-view-foundation.issue-actions.add-to-issue-dropdown-group.redactions.jira-spotlight.action-menu-redactions.spotlight-redaction-message-marker"
				>
					{formatMessage(messages.spotlightMessage)}
				</JiraSpotlight>
			)}
		</>
	);
};

const modalProps = {
	width: 'large',
};

const countStyles = xcss({
	height: '100%',
	paddingInline: 'space.075',
	justifyContent: 'center',
	alignItems: 'center',
	borderRadius: '50%',
	backgroundColor: 'color.background.accent.gray.subtler',
});
