import { createIssueType } from './create-issue-type.tsx';
import { deleteIssueType } from './delete-issue-type.tsx';
import { fetchIssueTypesForProject, fetchIssueTypesForProjects } from './fetch-for-project.tsx';
import { updateIssueType } from './update-issue-type.tsx';

export const actions = {
	fetchIssueTypesForProject,
	fetchIssueTypesForProjects,
	updateIssueType,
	createIssueType,
	deleteIssueType,
};
