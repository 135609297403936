export interface InvitationCapabilitiesV3Response {
	resourceARI: string;
	role: string;
	directInvite: {
		domains?: string[];
		mode: 'NONE' | 'ANYONE' | 'DOMAIN_RESTRICTED';
	};
	invitePendingApproval: {
		mode: 'NONE' | 'ANYONE' | 'DOMAIN_RESTRICTED';
	};
}

export const InviteCapabilities = {
	DIRECT_INVITE: 'directInvite',
	INVITE_PENDING_APPROVAL: 'invitePendingApproval',
	NONE: 'none',
} as const;

export type InviteCapability = (typeof InviteCapabilities)[keyof typeof InviteCapabilities];
