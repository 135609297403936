/**
 * @generated SignedSource<<cfe041bce17c70246e176d8d380c574f>>
 * @relayHash 81fce2b0f50b40b6dcbbd8b4b964a98e
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 0061195a5b8dfd221cab1993956d2b5f7e5e91296c1c7afa11e3524cfc0bc3c3

import type { ConcreteRequest, Query } from 'relay-runtime';
export type fetchUsersForWatchersFieldQuery$variables = {
  cloudId: string;
  issueKey: string;
  query: string;
};
export type fetchUsersForWatchersFieldQuery$data = {
  readonly jira: {
    readonly issueByKey: {
      readonly watchesField: {
        readonly suggestedWatchers: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly accountId: string;
              readonly id: string;
              readonly name: string;
              readonly picture: AGG$URL;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type fetchUsersForWatchersFieldQuery = {
  response: fetchUsersForWatchersFieldQuery$data;
  variables: fetchUsersForWatchersFieldQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "issueKey"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "issueKey"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "searchBy",
    "variableName": "query"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "id"
},
v4 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v5 = {
  "kind": "ScalarField",
  "name": "name"
},
v6 = {
  "kind": "ScalarField",
  "name": "picture"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "fetchUsersForWatchersFieldQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueByKey",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraWatchesField",
                "kind": "LinkedField",
                "name": "watchesField",
                "plural": false,
                "selections": [
                  {
                    "args": (v2/*: any*/),
                    "concreteType": "JiraUserConnection",
                    "kind": "LinkedField",
                    "name": "suggestedWatchers",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraUserEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "fetchUsersForWatchersFieldQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueByKey",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraWatchesField",
                "kind": "LinkedField",
                "name": "watchesField",
                "plural": false,
                "selections": [
                  {
                    "args": (v2/*: any*/),
                    "concreteType": "JiraUserConnection",
                    "kind": "LinkedField",
                    "name": "suggestedWatchers",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraUserEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "__typename"
                              },
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  (v3/*: any*/)
                ]
              },
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "0061195a5b8dfd221cab1993956d2b5f7e5e91296c1c7afa11e3524cfc0bc3c3",
    "metadata": {},
    "name": "fetchUsersForWatchersFieldQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "d41c46589fa57baac6c68d26675e88b1";

export default node;
