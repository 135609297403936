import React, { Fragment, useCallback } from 'react';
import { type UIAnalyticsEvent, AnalyticsListener } from '@atlaskit/analytics-next';
import { AiIssueBreakdownEntryPointContextProvider } from '@atlassian/jira-ai-work-breakdown/src/controllers/context-provider.tsx';
import { useIsAiEnabledForIssue } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	IssueSmartRequestSummaryEntryPointContextProvider,
	JsmSmartRequestSummaryEntryPointContextProvider,
} from '@atlassian/jira-issue-view-smart-request-summary-entrypoint/src/index.tsx';
import { useIsPremium } from '@atlassian/jira-platform-react-hooks-use-ai-opt-in/src/index.tsx';
import { useSubProduct } from '@atlassian/jira-subproduct/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import getMeta from '@atlassian/jira-get-meta';
import { AiRelatedIssuesEntrypointContextProvider } from '@atlassian/jira-ai-related-issues/src/controllers/context-provider.tsx';
import { AIOpsLinkIncidentSuggestionsEntrypointContextProvider } from '@atlassian/jira-servicedesk-aiops-link-incident-suggestions/src/controllers/entrypoint-context-provider.tsx';
import { AIOpsLinkConfluenceSuggestionsEntrypointContextProvider } from '@atlassian/jira-servicedesk-aiops-link-confluence-suggestions/src/controllers/entrypoint-context-provider.tsx';

export const MultipleEntrypointsContextProvider = ({ children }: { children: React.ReactNode }) => {
	const subProduct = useSubProduct();

	const SmartRequestSummaryEntryPointContextProvider =
		subProduct !== 'serviceDesk'
			? IssueSmartRequestSummaryEntryPointContextProvider
			: JsmSmartRequestSummaryEntryPointContextProvider;

	/**
	 * This listener is used to add shard and region attributes to the improve description (issue reformatter) analytics event
	 * since this is fired from the platform side, we need to modify the event and add our attributes before it is fired
	 * TODO: Check whether we can skip modifying the events, and instead fix editor-ai events to respect the event context
	 * https://jplat.atlassian.net/browse/CRCS-1226 to investigate if this can be done in a better way by the editorPluginAI side.
	 */
	const handleAiResultEvent = useCallback((e: UIAnalyticsEvent) => {
		const payload = e.payload || {};
		if (payload.actionSubjectId === 'editorPluginAI') {
			e.update({
				...payload,
				attributes: {
					shard: getMeta('ajs-shard'),
					region: getMeta('ajs-region'),
					...payload.attributes,
				},
			});
			e.fire();
		}
	}, []);

	if (!fg('jira_ai_fix_improved_button_remount')) {
		// TODO GRAVITYAI-1511 Check if these checks are correct for all AI features
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const isAiOptInEnabled = useIsAiEnabledForIssue();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const isPremium = useIsPremium();
		const isFeatureEnabled = isPremium && isAiOptInEnabled;

		if (!isFeatureEnabled) {
			return children;
		}
	}

	const AIOpsIncidentSuggestionsEntrypointProvider = fg('aiops_suggestion_panel_for_incident')
		? AIOpsLinkIncidentSuggestionsEntrypointContextProvider
		: Fragment;

	const AIOpsConfluenceSuggestionsEntrypointProvider = fg('aiops_suggestion_panel_for_incident')
		? AIOpsLinkConfluenceSuggestionsEntrypointContextProvider
		: Fragment;

	return (
		<AiIssueBreakdownEntryPointContextProvider>
			<SmartRequestSummaryEntryPointContextProvider>
				<AiRelatedIssuesEntrypointContextProvider>
					<AIOpsIncidentSuggestionsEntrypointProvider>
						<AIOpsConfluenceSuggestionsEntrypointProvider>
							{/* Specifically listen for the editor channel to modify the event being fired */}
							<AnalyticsListener channel="editor" onEvent={handleAiResultEvent}>
								{children}
							</AnalyticsListener>
						</AIOpsConfluenceSuggestionsEntrypointProvider>
					</AIOpsIncidentSuggestionsEntrypointProvider>
				</AiRelatedIssuesEntrypointContextProvider>
			</SmartRequestSummaryEntryPointContextProvider>
		</AiIssueBreakdownEntryPointContextProvider>
	);
};
