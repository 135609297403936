// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createContainer } from '@atlassian/react-sweet-state';
import * as actions from '../actions/index.tsx';
import type { State } from '../types.tsx';

export type Actions = typeof actions;

export const initialState: State = {
	isRollingUp: true,
};

export const store = createStore<State, Actions>({
	name: 'worklog-time-tracking',
	initialState,
	actions,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const WorklogTimeTrackingContainer = createContainer<State, Actions, Record<any, any>>(
	store,
);
