// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore } from '@atlassian/react-sweet-state';
import actions from './actions/index.tsx';
import type { State } from './types.tsx';

export type Actions = typeof actions;

export const initialState: State = {
	data: null,
	loading: false,
	error: null,
};

export default createStore<State, Actions>({
	name: 'issue-view-comment-visibilities',
	initialState,
	actions,
});
