/**
 * @generated SignedSource<<c0c1920287b2f0da505059658ce5b261>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type AssignToMeInlineEdit_fragmentKey$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AssignToMeInlineEditInner">;
  readonly " $fragmentType": "AssignToMeInlineEdit_fragmentKey";
};
export type AssignToMeInlineEdit_fragmentKey$key = {
  readonly " $data"?: AssignToMeInlineEdit_fragmentKey$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssignToMeInlineEdit_fragmentKey">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "AssignToMeInlineEdit_fragmentKey",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "AssignToMeInlineEditInner"
    }
  ],
  "type": "JiraSingleSelectUserPickerField"
};

(node as any).hash = "03d3568286d29c9357dc7c9906b04816";

export default node;
