// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps } from 'react';
import { Popper } from '@atlaskit/popper';
import type { MaybeChoreographedComponentProps } from '@atlassian/jira-choreographer-services/src/types.tsx';
import { shouldBeChoreographed } from '@atlassian/jira-choreographer-services/src/utils.tsx';
import { ChoreographerPopper } from './choreographer-popper.tsx';

export type JiraPopperProps = ComponentProps<typeof Popper> & MaybeChoreographedComponentProps;

export const JiraPopper = (props: JiraPopperProps) => {
	if (shouldBeChoreographed(props)) {
		const { messageType, ...rest } = props;
		return <ChoreographerPopper {...rest} />;
	}

	const { messageType, messageId, onMessageDisposition, ...rest } = props;
	return <Popper {...rest} />;
};
