import type { ForgeResponse } from '@atlassian/jira-issue-fetch-services/src/types.tsx';
import type { StoreApi } from '../types.tsx';

export const setForge =
	(forge: ForgeResponse & { isForgeLoadingFailed: boolean; isForgeDataComplete: boolean }) =>
	({ setState }: StoreApi) => {
		setState(forge);
	};

export const setForgeLoadingFailed =
	() =>
	({ setState }: StoreApi) => {
		setState({ isForgeLoadingFailed: true });
	};
