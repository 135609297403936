/**
 * @generated SignedSource<<24df0051a1da3d535b90371bbd481517>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutDateField_IssueViewDateField$data = {
  readonly __typename: "JiraDatePickerField";
  readonly fieldId: string;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"date_issueFieldDateInlineEditFull_DateInlineEditView_fragmentRef" | "main_issueViewBase_Date" | "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading">;
  readonly " $fragmentType": "ui_issueViewLayoutDateField_IssueViewDateField";
};
export type ui_issueViewLayoutDateField_IssueViewDateField$key = {
  readonly " $data"?: ui_issueViewLayoutDateField_IssueViewDateField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutDateField_IssueViewDateField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutDateField_IssueViewDateField",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading"
    },
    {
      "kind": "FragmentSpread",
      "name": "date_issueFieldDateInlineEditFull_DateInlineEditView_fragmentRef"
    },
    {
      "kind": "FragmentSpread",
      "name": "main_issueViewBase_Date"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "__typename"
    }
  ],
  "type": "JiraDatePickerField"
};

(node as any).hash = "ff505716d5d8a11687ad5a7ee0ada3b8";

export default node;
