import React from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { Skeleton } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';

const LoadingSkeleton = () => (
	<LineItemWrapper>
		<Skeleton height="24px" width="48px" />
		<Box xcss={skeletonDividerStyles} />
		<Skeleton height="24px" width="48px" />
	</LineItemWrapper>
);

export default LoadingSkeleton;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LineItemWrapper = styled.div({
	marginLeft: token('space.050'),
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
});

const skeletonDividerStyles = xcss({
	marginRight: 'space.100',
});
