/**
 * @generated SignedSource<<4927cfd579260e6c6befc74834b7eb74>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditView_fragmentRef$data = {
  readonly " $fragmentSpreads": FragmentRefs<"cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditViewNew_fragmentRef" | "cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditViewOld_fragmentRef">;
  readonly " $fragmentType": "cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditView_fragmentRef";
};
export type cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditView_fragmentRef$key = {
  readonly " $data"?: cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditView_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditView_fragmentRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditView_fragmentRef",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditViewNew_fragmentRef"
    },
    {
      "kind": "FragmentSpread",
      "name": "cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditViewOld_fragmentRef"
    }
  ],
  "type": "JiraCascadingSelectField"
};

(node as any).hash = "b309285458f0ab8d823f851bf8dce495";

export default node;
