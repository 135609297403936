/** @jsx jsx */
import { useCallback, useMemo, useState } from 'react';
import { css, styled, jsx } from '@compiled/react';
import { useFragment, graphql, useMutation } from 'react-relay';
import { useAnalyticsEvents, type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { InlineEditProps } from '@atlaskit/inline-edit';
import { token } from '@atlaskit/tokens';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useFieldFlags } from '@atlassian/jira-issue-field-base/src/services/field-flags-service/index.tsx';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import { SingleLineTextHeadingEditView } from '@atlassian/jira-issue-field-single-line-text-editview-heading/src/ui/single-line-text-heading/index.tsx';
import { SingleLineTextHeadingReadView } from '@atlassian/jira-issue-field-single-line-text-readview-heading/src/ui/single-line-text-heading/index.tsx';
import { fireTrackAnalytics, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type {
	singleLineTextHeading_issueFieldSingleLineTextHeading_SingleLineTextHeadingField_Mutation as SingleLineTextHeadingMutation,
	singleLineTextHeading_issueFieldSingleLineTextHeading_SingleLineTextHeadingField_Mutation$data as SingleLineTextHeadingMutationData,
} from '@atlassian/jira-relay/src/__generated__/singleLineTextHeading_issueFieldSingleLineTextHeading_SingleLineTextHeadingField_Mutation.graphql';
import type { singleLineTextHeading_issueFieldSingleLineTextInlineEditHeading_SingleLineTextHeadingInlineEditView$key as SingleLineTextHeadingFragment } from '@atlassian/jira-relay/src/__generated__/singleLineTextHeading_issueFieldSingleLineTextInlineEditHeading_SingleLineTextHeadingInlineEditView.graphql';
import {
	startCapturingTraceIds,
	stopCapturingTraceIds,
	getTraceIds,
} from '@atlassian/relay-traceid';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { useRealTimeComments } from '@atlassian/jira-realtime-comments-controller/src/controllers/index.tsx';
import { MAXIMUM_TEXT_LENGTH } from './constants.tsx';
import messages from './messages.tsx';
import type {
	SingleLineTextHeadingInlineEditViewProps,
	AggError,
	SingleLineTextHeadingValue,
} from './types.tsx';
import { getInvalidMessage } from './utils.tsx';

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components.
 * Prefer leveraging `FieldInlineEditLiteWithEntryPoint` where possible to archive more consistent across decomposed fields.
 *
 * @param props [SingleLineTextHeadingInlineEditViewProps](./types.tsx)
 */
export const SingleLineTextHeadingInlineEditView = ({
	fragmentRef,
	invalidMessageDialogPlacement,
	isEditing: startWithEditViewOpen = false,
	readViewFitContainerWidth = true,
	inlineEditTestId = 'issue-field-single-line-text-inline-edit-heading.ui.single-line-text-heading.field-inline-edit-state-less',
	readViewHeadingTestId,
	editViewInputWrapperTestId,
	maxLength = MAXIMUM_TEXT_LENGTH,
	onSubmit,
	onSubmitFailed,
	onSubmitSucceeded,
	onCancel,
	onConfirm,
}: SingleLineTextHeadingInlineEditViewProps) => {
	// #region Relay
	const data = useFragment<SingleLineTextHeadingFragment>(
		graphql`
			fragment singleLineTextHeading_issueFieldSingleLineTextInlineEditHeading_SingleLineTextHeadingInlineEditView on JiraSingleLineTextField {
				id
				name
				fieldId
				isEditableInIssueView
				type
				text
				...singleLineTextHeading_issueFieldSingleLineTextReadviewHeading_SingleLineTextHeadingReadView
				fieldConfig {
					isRequired
				}
			}
		`,
		fragmentRef,
	);
	const [{ isRealTimeCommentUpdated }] = useRealTimeComments();

	const { id: uniqueFieldId, name, fieldConfig, fieldId, isEditableInIssueView, type, text } = data;

	// Node: Change the MUTATION_NAME whenever the mutation name is also being changed
	const MUTATION_NAME =
		'singleLineTextHeading_issueFieldSingleLineTextHeading_SingleLineTextHeadingField_Mutation';
	const [commit] = useMutation<SingleLineTextHeadingMutation>(graphql`
		mutation singleLineTextHeading_issueFieldSingleLineTextHeading_SingleLineTextHeadingField_Mutation(
			$input: JiraUpdateSingleLineTextFieldInput!
		) @raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateSingleLineTextField(input: $input) {
					success
					errors {
						message
						extensions {
							statusCode
						}
					}
					field {
						...singleLineTextHeading_issueFieldSingleLineTextInlineEditHeading_SingleLineTextHeadingInlineEditView
					}
				}
			}
		}
	`);
	// #endregion

	// #region Common state
	const { overriding } = useInlineEditFieldInjections();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const isFieldEditable = overriding.overrideIsEditable(isEditableInIssueView ?? false);
	const fieldName = overriding.overrideLabel(name);

	const [isEditing, setIsEditing] = useState<boolean>(startWithEditViewOpen);
	const [newValue, setNewValue] = useState<string | null>(null);
	const [errors, setErrors] = useState<ReadonlyArray<AggError>>([]);
	const { onFailure: showErrorFlag } = useFieldFlags();

	const componentAnalyticsData = useMemo(
		() => ({
			fieldKey: fieldId,
			fieldType: type,
			...(fg('thor_send_realtime_attribute')
				? {
						isRealTimeCommentUpdated: !!isRealTimeCommentUpdated,
					}
				: {}),
		}),
		[fieldId, type, isRealTimeCommentUpdated],
	);

	const { formatMessage } = useIntl();

	const invalidMessage = useMemo(
		() => getInvalidMessage(newValue, fieldName, formatMessage, fieldConfig?.isRequired, maxLength),
		[fieldConfig?.isRequired, fieldName, formatMessage, maxLength, newValue],
	);

	const validate = useCallback(
		(value: string) =>
			getInvalidMessage(value, fieldName, formatMessage, fieldConfig?.isRequired, maxLength),
		[fieldName, formatMessage, fieldConfig?.isRequired, maxLength],
	);

	const cancelButtonLabel = formatMessage(messages.cancelButtonLabel, {
		fieldName,
	});
	const confirmButtonLabel = formatMessage(messages.confirmButtonLabel, {
		fieldName,
	});
	const getEditButtonLabel = useCallback(
		() =>
			text
				? formatMessage(messages.extendedEditButtonLabel, {
						inputFieldValue: text,
						fieldName,
					})
				: formatMessage(messages.editButtonLabel, { fieldName }),
		[fieldName, formatMessage, text],
	);
	// #endregion

	const handleErrors = useCallback(
		(commitErrors: ReadonlyArray<AggError | Error>) => {
			setErrors(commitErrors);
			setIsEditing(true);
			const firstCommitError = commitErrors[0];
			const error =
				firstCommitError instanceof Error && fg('thor_add_missing_attributes_across_issue_view_1')
					? firstCommitError
					: new Error(commitErrors[0]?.message || formatMessage(messages.errorTitle));

			showErrorFlag({
				error,
				title: messages.errorTitle,
				description: messages.errorMessage,
			});
			onSubmitFailed?.(error);
		},
		[showErrorFlag, formatMessage, onSubmitFailed, setIsEditing],
	);

	// #region Handle new value
	const onEditViewChange = useCallback(
		(value: SingleLineTextHeadingValue) => {
			setErrors([]);
			// @ts-expect-error - Argument of type 'string | null | undefined' is not assignable to parameter of type 'SetStateAction<string | null>'.
			setNewValue(value);
		},
		[setNewValue],
	);
	const cancel = useCallback(() => {
		setErrors([]);
		setIsEditing(false);
	}, []);

	const onCommitCompleted = useCallback(
		(response: SingleLineTextHeadingMutationData, newText?: string | null, traceId?: string) => {
			if (!response.jira?.updateSingleLineTextField) {
				handleErrors([]);
				return;
			}

			const { success, errors: responseErrors } = response.jira.updateSingleLineTextField;
			if (!success) {
				if (
					responseErrors?.[0].extensions?.statusCode &&
					fg('thor_send_additional_attributes_mutation_1')
				) {
					const errorWithTraceId = new FetchError(
						responseErrors?.[0].extensions?.statusCode,
						responseErrors?.[0].message || undefined,
						traceId,
					);
					handleErrors([errorWithTraceId]);
				} else {
					handleErrors(responseErrors || []);
				}
				return;
			}
			// Field updated event is a core action used to track MCU as defined in https://hello.atlassian.net/wiki/spaces/ANALYTICS/pages/3767029088/Monthly+Core+User+MCU+Definition
			fireTrackAnalytics(createAnalyticsEvent({}), 'field updated', componentAnalyticsData);
			onSubmitSucceeded?.(newText);
		},
		[createAnalyticsEvent, componentAnalyticsData, handleErrors, onSubmitSucceeded],
	);

	const handleNewValue = useCallback(
		(newText?: string | null) => {
			// Clear errors and exit editing mode
			setErrors([]);
			setIsEditing(false);
			// Skip mutation if value is unchanged
			if (newText === text) {
				return;
			}

			if (fg('thor_send_additional_attributes_mutation_1')) {
				startCapturingTraceIds(MUTATION_NAME);
			}

			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operation: {
							operation: 'SET',
							text: newValue,
						},
					},
				},
				onCompleted(res) {
					if (fg('thor_send_additional_attributes_mutation_1')) {
						const traceIds = getTraceIds(MUTATION_NAME);
						stopCapturingTraceIds(MUTATION_NAME);
						onCommitCompleted(res, newText, traceIds[0]);
					} else {
						onCommitCompleted(res, newText);
					}
				},
				onError(error) {
					if (fg('thor_send_additional_attributes_mutation_1')) {
						stopCapturingTraceIds(MUTATION_NAME);
					}
					handleErrors([error]);
				},
				optimisticResponse: {
					jira: {
						updateSingleLineTextField: {
							success: true,
							errors: null,
							field: {
								id: uniqueFieldId,
								name: fieldName,
								type,
								isEditableInIssueView,
								fieldId,
								text: newValue,
								fieldConfig,
							},
						},
					},
				},
			});
		},
		[
			text,
			commit,
			uniqueFieldId,
			newValue,
			fieldName,
			type,
			isEditableInIssueView,
			fieldId,
			fieldConfig,
			onCommitCompleted,
			handleErrors,
		],
	);

	const submitValue = useCallback(
		(value: string | null) => {
			if (!invalidMessage) {
				onSubmit?.(value);
				handleNewValue(value);
			}
		},
		[handleNewValue, invalidMessage, onSubmit],
	);
	// #endregion

	// #region Common callbacks
	const onCancelRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			cancel();
			onCancel?.(analyticsEvent);
			fireUIAnalytics(analyticsEvent);
		},
		[cancel, onCancel],
	);

	const onConfirmRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			submitValue(newValue);
			onConfirm && onConfirm(analyticsEvent);
			fireUIAnalytics(analyticsEvent);
		},
		[newValue, onConfirm, submitValue],
	);

	const onEditRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			setErrors([]);
			// @ts-expect-error - Argument of type 'string | null | undefined' is not assignable to parameter of type 'SetStateAction<string | null>'.
			setNewValue(text);
			setIsEditing(true);
			fireUIAnalytics(analyticsEvent);
		},
		[text],
	);

	// #endregion

	// #region Read view
	const renderReadView = () => {
		if (fg('enable_redaction_support_in_jira_issue_view')) {
			return (
				<SpotlightTarget name={`redaction-marker-${fieldId}`}>
					<ReadViewContainer data-testid="issue-field-single-line-text-inline-edit-heading.ui.single-line-text-heading.read-view">
						<SingleLineTextHeadingReadView
							fragmentRef={data}
							readViewHeadingTestId={readViewHeadingTestId}
						/>
					</ReadViewContainer>
				</SpotlightTarget>
			);
		}
		return (
			<ReadViewContainer data-testid="issue-field-single-line-text-inline-edit-heading.ui.single-line-text-heading.read-view">
				<SingleLineTextHeadingReadView
					fragmentRef={data}
					readViewHeadingTestId={readViewHeadingTestId}
				/>
			</ReadViewContainer>
		);
	};
	// #endregion

	// #region Edit view
	const renderEditView: InlineEditProps<unknown>['editView'] = (fieldProps) => (
		<EditViewContainer data-testid="issue-field-single-line-text-inline-edit-heading.ui.single-line-text-heading.edit-view">
			<SingleLineTextHeadingEditView
				isInvalid={!!errors.length || fieldProps.isInvalid}
				invalidMessage={invalidMessage}
				value={newValue || ''}
				onChange={(value) => {
					fieldProps.onChange(value);
					onEditViewChange(value);
				}}
				invalidMessageDialogPlacement={invalidMessageDialogPlacement}
				editViewInputWrapperTestId={editViewInputWrapperTestId}
				label={fieldName}
			/>
		</EditViewContainer>
	);

	// #endregion

	return (
		<InlineEditContainer isEditable={isFieldEditable}>
			<FieldInlineEditStateLess
				testId={inlineEditTestId}
				fieldId={fg('one_event_rules_them_all_fg') ? fieldId : undefined}
				readView={renderReadView}
				editView={renderEditView}
				onCancel={onCancelRequest}
				onConfirm={onConfirmRequest}
				onEnter={onConfirmRequest}
				onEdit={onEditRequest}
				readViewFitContainerWidth={readViewFitContainerWidth}
				isEditing={isEditing}
				isEditable={isFieldEditable}
				editButtonLabel={getEditButtonLabel()}
				cancelButtonLabel={cancelButtonLabel}
				confirmButtonLabel={confirmButtonLabel}
				isLabelHidden
				label={fieldName}
				componentAnalyticsData={componentAnalyticsData}
				defaultValue={text ?? ''}
				validate={validate}
			/>
		</InlineEditContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditViewContainer = styled.div({
	zIndex: 300,
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewContainer = styled.div({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: 1,
	maxWidth: '100%',
	wordBreak: 'break-word',
	paddingTop: token('space.075'),
	paddingBottom: token('space.075'),
	position: 'relative',
	left: token('space.075'),
});

const nonEditableStyles = css({
	display: 'flex',
	flex: '1 1 auto',
	wordBreak: 'break-word',
	position: 'relative',

	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '20px',
	paddingLeft: token('space.075'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineEditContainer = styled.div<{ isEditable: boolean }>(
	{
		width: '100%',
		marginLeft: token('space.negative.100'),
		marginTop: token('space.negative.100'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& div[data-read-view-fit-container-width]': {
			display: 'flex',
			alignItems: 'center',
			width: '100%',
			minHeight: '32px',
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isEditable }) => (!isEditable ? nonEditableStyles : undefined),
);
