import { JiraProjectAri } from '@atlassian/ari/jira/project';
import { getAutomationStack } from '@atlassian/jira-automation-platform/src/common/utils.tsx';
import {
	performPostRequest,
	performPutRequest,
} from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { Environment } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import type { RecurCondition, RuleConfig } from '../common/types.tsx';
import { SOFTWARE_END_USER_TEMPLATE_2 } from '../common/constants.tsx';

export type CreateTemplateParams = {
	environment: Environment | null;
	cloudId: string;
	projectId: string;
	recurrenceToken: string;
	recurCondition: RecurCondition;
	rRule: string;
	dueDateOffsetSmartValue: string;
};

type ParamField = {
	value: string;
	type: string;
};

type CreateTemplateRequestPayload = {
	templateId: string;
	ruleHome: JiraProjectAri;
	parameters: {
		recurrenceToken: ParamField;
		rRule: ParamField;
		dueDateOffsetSmartValue: ParamField;
		recurCondition: ParamField;
	};
};

export type CreateTemplateResult = { ruleUuid: string };

export const createTemplate = async ({
	environment,
	cloudId,
	projectId,
	recurrenceToken,
	recurCondition,
	rRule,
	dueDateOffsetSmartValue,
}: CreateTemplateParams): Promise<CreateTemplateResult> => {
	const automationStack = await getAutomationStack(environment, cloudId);
	const CREATE_TEMPLATE_URL = `/gateway/api/automation/internal-api/jira/${cloudId}/${automationStack}/public/rest/v1/template/create`;

	const ruleHomeARI = JiraProjectAri.create({
		siteId: cloudId,
		projectId: String(projectId),
	});

	const payload: CreateTemplateRequestPayload = {
		ruleHome: ruleHomeARI,
		templateId: SOFTWARE_END_USER_TEMPLATE_2,
		parameters: {
			recurrenceToken: {
				value: recurrenceToken,
				type: 'TEXT',
			},
			rRule: {
				value: rRule,
				type: 'TEXT',
			},
			dueDateOffsetSmartValue: {
				value: dueDateOffsetSmartValue,
				type: 'TEXT',
			},
			recurCondition: {
				value: recurCondition,
				type: 'TEXT',
			},
		},
	};

	return performPostRequest(CREATE_TEMPLATE_URL, {
		body: JSON.stringify(payload),
	});
};

type SetIssuePropertiesParams = {
	issueKey: string;
	ruleUuid: string;
	recurrenceToken: string;
	recurCondition: RecurCondition;
	rRule: string;
	templateId: string;
	dueDateOffsetSmartValue: string;
};

export type SetIssuePropertiesResult = {};

export const setIssueProperties = async ({
	issueKey,
	ruleUuid,
	recurrenceToken,
	recurCondition,
	rRule,
	templateId,
	dueDateOffsetSmartValue,
}: SetIssuePropertiesParams): Promise<SetIssuePropertiesResult> => {
	const issuePropertiesEndpoint = `/rest/api/2/issue/${issueKey}/properties/recurrence-config`;

	const requestBody: RuleConfig = {
		automationRuleUuid: ruleUuid,
		token: recurrenceToken,
		templateId,
		recurOnScheduleConfig: {
			rRule,
			dueDateOffsetSmartValue,
			recurCondition,
		},
	};

	return performPutRequest(issuePropertiesEndpoint, {
		body: JSON.stringify(requestBody),
	});
};
