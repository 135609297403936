import { expVal } from '@atlassian/jira-feature-experiments';
import {
	useIsBusinessIssue,
	useIsSoftwareIssue,
} from '@atlassian/jira-issue-util-hooks/src/index.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';

/**
 * @returns true only when experiment is enabled, user is admin and project is either JSW or JWM
 */
export const useIsReorderingContextFieldsExpEnabled = () => {
	const isJSW = useIsSoftwareIssue();
	const isJWM = useIsBusinessIssue();
	const isAdmin = useIsAdmin();

	return (
		isAdmin &&
		(isJSW || isJWM) &&
		expVal('issue_view_field_reordering_in_context_panel', 'isReorderingFieldsEnabled', false)
	);
};

/**
 * Why isAdmin check is needed here and not later in the code ?
 *
 * This experiment is only for admins. So the end users (the non-admins) should not see the reordering fields feature.
 * But if the isAdmin is checked later in the code, then those users will be part of exposure because the expVal is evaluated for them as well.
 * This can skew the result in negative way.
 *
 * Example of what will happen when isAdmin check is not present here ?
 * Suppose there are 100 users in Test environment. 40 are admins and 60 are non-admins. Only 40 (admins) of them will see the option to go into edit mode
 * and the rest 60 (non-admins) won't even see that. But all the 100 are part of exposure because the isAdmin check is done later in the code.
 * Suppose 10 admins have clicked the option to go into edit mode. Now the actual percentage of our target audience who opt-in for edit mode
 * is 10/40 = 25% but what will see in our result is, only 10 users have opted-in out of 100 which is 10/100 = 10%. To prevent this from happening,
 * isAdmin check is needed here.
 *
 * https://hello.atlassian.net/wiki/spaces/~732385844/pages/3187295823/Exposure+Events+101
 */
