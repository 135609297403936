import React, { type ComponentType, useCallback } from 'react';
import memoizeOne from 'memoize-one';
import { fg } from '@atlassian/jira-feature-gating';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import ChildIssuesPanel from '@atlassian/jira-issue-view-common-views/src/child-issues-panel/index.tsx';
import {
	type ChangeEvent,
	ChangeEventTypes,
} from '@atlassian/jira-issue-view-common-views/src/child-issues-panel/model/on-change-callback.tsx';
import { CLASSIC_SUBTASKS } from '@atlassian/jira-issue-view-common-views/src/child-issues-panel/model/types.tsx';
import type { Props as ChildIssuesPanelProps } from '@atlassian/jira-issue-view-common-views/src/child-issues-panel/state/types.tsx';
import { SUBTASKS } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { updateAssociatedIssueRequest } from '@atlassian/jira-issue-view-store/src/actions/associated-issue-actions.tsx';
import {
	childIssueFieldUpdate,
	childIssuePanelAddClick,
	issueChildrenOrderChangeSuccess,
	subtaskAddSuccess,
} from '@atlassian/jira-issue-view-store/src/actions/child-panel-actions.tsx';
import { navigateToNewIssue } from '@atlassian/jira-issue-view-store/src/actions/issue-navigation-actions.tsx';
import { issueIdSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import {
	issueTypeIdSelector,
	childIssuesLimitUrlSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';

import { isCompletedLoadingSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import { hierarchyLevelSelector } from '@atlassian/jira-issue-view-store/src/issue-field/state/selectors/hierarchy-level-selector.tsx';
import { totalChildIssueCountSelector } from '@atlassian/jira-issue-view-store/src/selectors/child-issues-limit-selector.tsx';
import {
	hasMultipleChildPanelsSelector,
	subtaskQuickAddClickCountSelector,
	triggeredViaHotKeySelector,
} from '@atlassian/jira-issue-view-store/src/selectors/child-panel-ui-selector.tsx';

import { fetchIssueTypesStatusSelector } from '@atlassian/jira-issue-view-store/src/selectors/issue-types-selector.tsx';
import { subtaskTypesSelector } from '@atlassian/jira-issue-view-store/src/selectors/subtask-types-selector.tsx';
import {
	canCreateSubtaskSelector,
	subtaskEntitiesSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/subtasks-selector.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { MainIssueAggQueryRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import messages from './messages.tsx';

export const ConnectedComponent = (props: ChildIssuesPanelProps & ChildViewProps) => {
	const reduxOnChange = props.onChange;

	const issueKey = useIssueKey();
	const [currentValue, { setFieldValue }] = useFieldValue({
		issueKey,
		fieldKey: SUBTASKS,
	});

	const onChange = useCallback(
		(event: ChangeEvent) => {
			switch (event.type) {
				case ChangeEventTypes.CHILD_ISSUE_ADDED: {
					setFieldValue(
						issueKey,
						SUBTASKS,
						currentValue.concat({
							id: event.meta.childIssue.id,
							key: event.meta.childIssue.issueKey,
						}),
					);
					break;
				}
				default:
					break;
			}
			reduxOnChange(event);
		},
		[currentValue, issueKey, reduxOnChange, setFieldValue],
	);

	return (
		<ChildIssuesPanel
			{...props}
			onChange={onChange}
			filterSubtasks={
				props.hasMultipleChildPanels && !fg('jira-issue-view-child-issues-panel-merge-subtasks')
					? true
					: null
			}
		/>
	);
};

const onNavigateToNewIssueMemoized = memoizeOne((onNavigateToNewIssueDispatchProp) =>
	// @ts-expect-error - TS7031 - Binding element 'fromIssueKey' implicitly has an 'any' type. | TS7031 - Binding element 'toIssueKey' implicitly has an 'any' type.

	({ fromIssueKey, toIssueKey }) => {
		onNavigateToNewIssueDispatchProp &&
			onNavigateToNewIssueDispatchProp({ fromIssueKey, toIssueKey });
	},
);

export type ChildViewProps = {
	rootRelayFragment: MainIssueAggQueryRelayFragment | null;
};

const ReduxConnectedComponent = connect(
	(state: State, ownProps: ChildViewProps) => {
		return {
			childIssuesPanelType: CLASSIC_SUBTASKS,
			parentId: issueIdSelector(state),
			issueTypes: subtaskTypesSelector(state),
			issues: subtaskEntitiesSelector(state),
			parentIssueTypeId: issueTypeIdSelector(state),
			childIssuesLimitUrl: childIssuesLimitUrlSelector(state)(SUBTASKS),
			messages,
			supportsIssueCreation: canCreateSubtaskSelector(state),
			quickAddClickCount: subtaskQuickAddClickCountSelector(state),
			triggeredViaHotKey: triggeredViaHotKeySelector(state),
			isIssueViewComplete: isCompletedLoadingSelector(state),
			fetchIssueTypesStatus: fetchIssueTypesStatusSelector(state),
			issueHierarchyLevel: hierarchyLevelSelector(state),
			hasExceededIssuesLimitAfterLoad: undefined, // TODO: Clean up or change
			totalChildIssueCount: totalChildIssueCountSelector(state),
			hasMultipleChildPanels: hasMultipleChildPanelsSelector(state),
			rootRelayFragment: ownProps.rootRelayFragment,
		};
	},
	(dispatch) => ({
		onChange: (event: ChangeEvent) => {
			switch (event.type) {
				case ChangeEventTypes.CHILD_ISSUE_ADDED:
					dispatch(subtaskAddSuccess(event.meta.childIssue, event.meta.fieldsData));
					break;
				case ChangeEventTypes.ISSUE_CHILDREN_ORDER_CHANGED:
					dispatch(issueChildrenOrderChangeSuccess(event.meta));
					break;
				case ChangeEventTypes.CHILD_ISSUE_FIELD_UPDATED:
					dispatch(childIssueFieldUpdate(event.meta.childIssueId));
					break;
				default:
					break;
			}
		},
		onAddChildClick: () => dispatch(childIssuePanelAddClick()),
		// @ts-expect-error - TS7031 - Binding element 'fromIssueKey' implicitly has an 'any' type. | TS7031 - Binding element 'toIssueKey' implicitly has an 'any' type.
		onNavigateToNewIssueDispatchProp: ({ fromIssueKey, toIssueKey }) => {
			if (!__SPA__) {
				dispatch(navigateToNewIssue({ fromIssueKey, toIssueKey }));
			}
		},
		onEditAssociatedIssue: (issueKey?: IssueKey) => {
			dispatch(updateAssociatedIssueRequest(issueKey));
		},
	}),
	(stateProps, dispatchProps) => {
		const { onNavigateToNewIssueDispatchProp, ...restDispatchProps } = dispatchProps;

		return {
			...stateProps,
			...restDispatchProps,
			onNavigateToNewIssue: onNavigateToNewIssueMemoized(onNavigateToNewIssueDispatchProp),
		};
	},
	// @ts-expect-error - TS2345 - Argument of type '(props: ChildIssuesPanelProps) => JSX.Element' is not assignable to parameter of type 'Component<{ onNavigateToNewIssue: ({ fromIssueKey, toIssueKey }: { fromIssueKey: any; toIssueKey: any; }) => void; onChange: (event: ChangeEvent) => void; onAddChildClick: () => { type: "CHILD_ISSUE_PANEL_ADD_CLICKED"; }; ... 11 more ...; totalChildIssueCount: any; }>'.
)(ConnectedComponent);

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ReduxConnectedComponent as unknown as ComponentType<{}>;
