// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createActionsHook,
	createContainer,
	createHook,
	createStore,
} from '@atlassian/react-sweet-state';
import {
	loadSummaryStream,
	stopLoading,
	closeSummaryPanel,
	setIsGenerating,
	fetchProactiveSummary,
	setTriggeredProactive,
	setHasScrolledLayoutContainer,
	setProactivePanelShown,
	updateProactiveSummaryCache,
} from './actions/index.tsx';
import type { State } from './types.tsx';

export const StateContainer = createContainer();

export const initialState: State = {
	isPanelShown: false,
	isProactivePanelShown: false,
	isGenerating: false,
	content: null,
	error: undefined,
	hasTriggeredProactive: false,
	hasTriggeredNonProactive: false,
	hasValidProactiveResponse: false,
	hasScrolledLayoutContainer: false,
	hasUpdatedProactiveCache: false,
};

const actions = {
	loadSummaryStream,
	stopLoading,
	closeSummaryPanel,
	setIsGenerating,
	fetchProactiveSummary,
	setTriggeredProactive,
	setHasScrolledLayoutContainer,
	setProactivePanelShown,
	updateProactiveSummaryCache,
};

const store = createStore({
	name: 'issue-smart-request-summary',
	initialState,
	actions,
	containedBy: StateContainer,
});

export const useActions = createActionsHook(store);

export const useIsPanelShown = createHook(store, {
	selector: (state) => state.isPanelShown,
});

export const useIsProactivePanelShown = createHook(store, {
	selector: (state) => state.isProactivePanelShown,
});

export const useHasTriggeredNonProactive = createHook(store, {
	selector: (state) => state.hasTriggeredNonProactive,
});

export const useHasTriggeredProactive = createHook(store, {
	selector: (state) => state.hasTriggeredProactive,
});

export const useHasScrolledLayoutContainer = createHook(store, {
	selector: (state) => state.hasScrolledLayoutContainer,
});

export const useHasValidProactiveResponse = createHook(store, {
	selector: (state) => state.hasValidProactiveResponse,
});

export const useIsGenerating = createHook(store, {
	selector: (state) => state.isGenerating,
});

export const useContent = createHook(store, {
	selector: (state) => state.content,
});

export const useError = createHook(store, {
	selector: (state) => state.error,
});
