import React, { useEffect, useRef, useMemo } from 'react';
// TODO: JFP-2824 | Suppressed to enable upgrade to ESLint v9 - please fix this if you can!
// eslint-disable-next-line jira/restricted/@atlaskit+analytics-next
import { AnalyticsContext } from '@atlaskit/analytics-next';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import type { JSErrorBoundaryProps } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { getErrorType } from '@atlassian/ui-modifications-analytics';
import { TenantContextProvider } from '@atlassian/ui-modifications-core/src/controllers/tenant-context/index.tsx';
import { useAdjustmentsContextActions } from '@atlassian/ui-modifications-core/src/controllers/adjustments-context/index.tsx';
import { ExecutionContextProvider } from '@atlassian/ui-modifications-core/src/controllers/execution-context/index.tsx';
import type { TenantContextValue } from '@atlassian/ui-modifications-core/src/controllers/tenant-context/types.tsx';
import type { ExecutionContext } from '@atlassian/ui-modifications-core/src/common/types/execution-context.tsx';
import {
	isTriggerPointSupported,
	isExecutionContextSupported,
} from '@atlassian/ui-modifications-core/src/controllers/view-configuration/index.tsx';
import { UiModificationsErrorHandler } from '@atlassian/ui-modifications-core';
import type { EnabledIssueAdjustmentsProps } from './enabled-issue-adjustments/index.tsx';
import { issueAdjustmentsEntryPoint } from './entrypoint.tsx';

// TODO: drop the whole @atlassian/jira-issue-adjustments pacakge on ditt_uim_-_use_platform_uim_core_in_jira feature gate removal

export type IssueAdjustmentsAsyncProps = {
	containerId: string;
	containerPackageName: string;
	runtimeProps: EnabledIssueAdjustmentsProps;
	executionContext: ExecutionContext;
	triggerPointKey?: string;
	errorFallback?: JSErrorBoundaryProps['fallback'];
	cloudId: string;
	activationId: string;
};

export const useDefaultErrorFallback = () => () => <UiModificationsErrorHandler />;

const STABLE_EMPTY_OBJECT = {};

export const IssueAdjustmentsAsync = ({
	containerId,
	containerPackageName,
	runtimeProps,
	executionContext,
	triggerPointKey,
	errorFallback,
	cloudId,
	activationId,
}: IssueAdjustmentsAsyncProps) => {
	const { modules, viewType } = runtimeProps;
	const { entryPointReferenceSubject, entryPointActions } = useEntryPoint(
		issueAdjustmentsEntryPoint,
		STABLE_EMPTY_OBJECT,
	);
	const { setShouldUiModificationsLoad } = useAdjustmentsContextActions();
	const defaultErrorFallback = useDefaultErrorFallback();
	const hasUiModificationsLoaded = useRef(false);

	const shouldUiModificationsLoad =
		modules?.length > 0 &&
		isTriggerPointSupported(viewType, triggerPointKey) &&
		executionContext &&
		isExecutionContextSupported(executionContext);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		const hasProjectAndIssueTypeFinishedLoading = executionContext !== null;
		// otherwise we don't set the supported status when changing issue types only
		if (hasProjectAndIssueTypeFinishedLoading) {
			setShouldUiModificationsLoad(shouldUiModificationsLoad ? 'yes' : 'no');
		}
		if (shouldUiModificationsLoad && !hasUiModificationsLoaded.current) {
			entryPointActions.load();
			hasUiModificationsLoaded.current = true;
		}
	}, [
		shouldUiModificationsLoad,
		setShouldUiModificationsLoad,
		entryPointActions,
		executionContext,
	]);

	const tenantContext = useMemo<TenantContextValue>(
		() => ({
			cloudId,
			activationId,
		}),
		[cloudId, activationId],
	);

	if (shouldUiModificationsLoad) {
		return (
			<ExecutionContextProvider value={executionContext}>
				<AnalyticsContext
					data={{
						attributes: {
							triggerPointKey,
						},
					}}
				>
					<TenantContextProvider value={tenantContext}>
						<JiraEntryPointContainer
							entryPointReferenceSubject={entryPointReferenceSubject}
							id={containerId}
							packageName={containerPackageName}
							fallback={<></>} // Placeholder fallback
							errorFallback={errorFallback || defaultErrorFallback}
							errorAttributes={(error: Error) => ({
								errorType: getErrorType(error),
								viewType: runtimeProps.viewType,
							})}
							runtimeProps={runtimeProps}
						/>
					</TenantContextProvider>
				</AnalyticsContext>
			</ExecutionContextProvider>
		);
	}
	return null;
};
