import React, { useCallback, type RefObject } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import Button from '@atlaskit/button/new';
import { defineMessages, useIntl } from '@atlassian/jira-intl';
import type { IncidentPanelExternalHandlers } from '@atlassian/jira-servicedesk-aiops-suggestions-common/src/ui/index.tsx';
import { useAIOpsLinkIncidentSuggestionsEntryPointContext } from '@atlassian/jira-servicedesk-aiops-link-incident-suggestions/src/controllers/entrypoint-context-provider.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { fireUIAnalytics, type UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';

const messages = defineMessages({
	findRelatedWorkItemButton: {
		id: 'issue.issue-find-related-work-item',
		defaultMessage: 'Find related work item',
		description: 'Clicking on the button will open "Find Related work item" suggestion panel',
	},
});

type Props = {
	isPanelVisible?: boolean;
	suggestionPanelRef: RefObject<IncidentPanelExternalHandlers>;
	onShowPanel: () => void;
};

export const FinRelatedWorkItemButton = ({
	isPanelVisible,
	suggestionPanelRef,
	onShowPanel,
}: Props) => {
	const { entryPointActions } = useAIOpsLinkIncidentSuggestionsEntryPointContext();

	const triggerRef = useEntryPointButtonTrigger(entryPointActions);

	const { formatMessage } = useIntl();

	const onClick = useCallback(
		(_: unknown, event: UIAnalyticsEvent) => {
			onShowPanel();
			suggestionPanelRef.current?.scrollIntoView();
			fireUIAnalytics(event, 'findRelatedWorkItem');
		},
		[onShowPanel, suggestionPanelRef],
	);

	return (
		<Box xcss={relatedIncidentButtonStyles}>
			<Button
				testId="issue-view-content-issue-links.ai-suggestion-panel.find-related-work-item-button.find-related-work-item-button"
				ref={triggerRef}
				spacing="compact"
				isSelected={isPanelVisible}
				onClick={onClick}
			>
				{formatMessage(messages.findRelatedWorkItemButton)}
			</Button>
		</Box>
	);
};

const relatedIncidentButtonStyles = xcss({
	marginLeft: 'space.075',
});
