import React from 'react';
import noop from 'lodash/noop';
import TrashIcon from '@atlaskit/icon/core/migration/delete--trash';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import GoToButton from '@atlassian/jira-issue-attachments-base/src/ui/goto-button/index.tsx';
import { filmStripView } from '@atlassian/jira-issue-attachments-base/src/ui/types.tsx';
import { ATTACHMENT_PARENT_ISSUE } from '@atlassian/jira-issue-attachments-store/src/common/constants.tsx';
import type {
	AttachmentId,
	AttachmentParent,
} from '@atlassian/jira-issue-attachments-store/src/common/types.tsx';
import type { CardCover } from '@atlassian/jira-issue-view-common-types/src/card-cover-type.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import i18n from './messages.tsx';

const getDeleteOrGotoActionForAttachment = (
	attachmentId: AttachmentId,
	attachmentParentName: AttachmentParent,
	attachmentParentId: string,
	canDeleteAttachment: boolean,
	intl: IntlShape,
	issueKey: IssueKey,
	onDeleteAttachment: (arg1: AttachmentId) => void,
	onScrollToComments: () => void,
	onScrollToWorklogs: () => void,
) => {
	const deleteMessage = intl.formatMessage(i18n.delete);

	if (attachmentParentName === ATTACHMENT_PARENT_ISSUE) {
		// no permission to delete attachment
		if (!canDeleteAttachment) {
			return [];
		}

		return [
			{
				label: deleteMessage,
				handler: () => onDeleteAttachment(attachmentId),
				icon: (
					<TrashIcon
						spacing="spacious"
						label={deleteMessage}
						testId={`issue.views.issue-base.content.attachment.filmstrip-view.trash-icon.${attachmentId}`}
					/>
				),
			},
		];
	}

	// show the goto button as attachment in either commment/workog, customfield, description or envrionment
	return [
		{
			label: intl.formatMessage(i18n.goto),
			handler: noop,
			icon: (
				<GoToButton
					attachmentParent={attachmentParentName}
					attachmentParentId={attachmentParentId}
					attachmentView={filmStripView}
					onScrollToComments={onScrollToComments}
					onScrollToWorklogs={onScrollToWorklogs}
					issueKey={issueKey}
					// This is a div to match the CardActionButton in the media-card component
					component="div"
					attachmentId={attachmentId}
				/>
			),
		},
	] as const;
};

const getCardCoverActionForAttachment = (
	attachmentId: AttachmentId,
	cardCover: CardCover | null,
	intl: IntlShape,
	onCardCoverShow: (arg1: number | null, arg2: number) => void,
	onCardCoverHide: (arg1: number) => void,
) => {
	const idAsNumber = Number(attachmentId);
	const hideMsg = intl.formatMessage(i18n.hide);
	const showMsg = intl.formatMessage(i18n.show);

	if (
		!cardCover ||
		!cardCover.isEnabled ||
		!cardCover.availableAttachmentIds.includes(idAsNumber)
	) {
		return [];
	}

	if (idAsNumber === cardCover.attachmentId) {
		return [
			{
				label: hideMsg,
				handler: () => {
					onCardCoverHide(idAsNumber);
				},
			},
		];
	}
	return [
		{
			label: showMsg,
			handler: () => {
				onCardCoverShow(cardCover.attachmentId, idAsNumber);
			},
		},
	] as const;
};

export const getActionsForAttachment = (
	attachmentId: AttachmentId,
	attachmentParentName: AttachmentParent,
	attachmentParentId: string,
	cardCover: CardCover | null,
	canDeleteAttachment: boolean,
	intl: IntlShape,
	issueKey: IssueKey,
	onDeleteAttachment: (arg1: AttachmentId) => void,
	onCardCoverShow: (arg1: number | null, arg2: number) => void,
	onCardCoverHide: (arg1: number) => void,
	onScrollToComments: () => void,
	onScrollToWorklogs: () => void,
) => [
	...getDeleteOrGotoActionForAttachment(
		attachmentId,
		attachmentParentName,
		attachmentParentId,
		canDeleteAttachment,
		intl,
		issueKey,
		onDeleteAttachment,
		onScrollToComments,
		onScrollToWorklogs,
	),
	...getCardCoverActionForAttachment(
		attachmentId,
		cardCover,
		intl,
		onCardCoverShow,
		onCardCoverHide,
	),
];
