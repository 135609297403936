import { useMemo } from 'react';
import merge from 'lodash/merge';
import type { Field } from '@atlassian/jira-issue-shared-types/src/common/types/field-type.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createHook,
	createSubscriber,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';

export type IssueFields = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
};

type State = Record<IssueKey, IssueFields | undefined>;

export type IssueWithFields = Record<IssueKey, IssueFields>;

type StoreApi = StoreActionApi<State>;

const setIssue =
	(issueKey: IssueKey, fields: IssueFields) =>
	({ setState }: StoreApi) => {
		setState({
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			[issueKey as string]: fields,
		});
	};

const setIssues =
	(issues: IssueWithFields[]) =>
	({ setState }: StoreApi) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const newIssues: Record<string, any> = {};
		issues.forEach((issue) => {
			Object.assign(newIssues, issue);
		});
		setState(newIssues);
	};

const setFieldValue =
	(
		issueKey: IssueKey,
		fieldKey: string,
		value: any, // eslint-disable-line @typescript-eslint/no-explicit-any
	) =>
	({ setState, getState }: StoreApi) => {
		setState({
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			[issueKey as string]: {
				...getState()[issueKey],
				[fieldKey]: value,
			},
		});
	};

const getFieldValue =
	(issueKey: IssueKey, fieldKey: string) =>
	({ getState }: StoreApi) => {
		const issueFieldValues = getState()[issueKey];

		return issueFieldValues && issueFieldValues[fieldKey];
	};

const mergeIssue =
	(issueKey: IssueKey, fields: IssueFields) =>
	({ setState, getState }: StoreApi) => {
		setState({
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			[issueKey as string]: merge({}, getState()[issueKey], fields),
		});
	};

export const mergeIssues =
	(issues: IssueWithFields[]) =>
	({ setState, getState }: StoreApi) => {
		if (issues.length === 0) {
			return;
		}

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const newIssues: Record<string, any> = {};
		issues.forEach((issue) => {
			Object.assign(newIssues, issue);
		});
		setState(merge({}, getState(), newIssues));
	};

const actions = {
	setFieldValue,
	getFieldValue,
	setIssue,
	mergeIssue,
	setIssues,
	mergeIssues,
} as const;

export type Actions = typeof actions;

// this is a global store
const store = createStore<State, Actions>({
	name: 'issue-fields',
	initialState: {},
	actions,
});

export type FieldValueServiceActions = {
	setIssue: (issueKey: IssueKey, fields: IssueFields) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setFieldValue: (issueKey: IssueKey, fieldKey: string, value?: any) => void;
	getFieldValue: <T>(issueKey: IssueKey, fieldKey: string) => T;
	mergeIssue: (issueKey: IssueKey, fields: IssueFields) => void;
	setIssues: (issues: IssueWithFields[]) => void;
	mergeIssues: (issues: IssueWithFields[]) => void;
};

export type FieldValueSelectorProps = {
	issueKey: IssueKey;
	fieldKey: string;
};

type FieldValuesForIssuesSelectorProps = {
	issueKeys: IssueKey[];
	fieldKey: string;
};

type MultiFieldValuesForIssuesSelectorProps = {
	issueKeys: IssueKey[];
	fieldKeys: string[];
};

const getFieldValueSelector = (state: State, { issueKey, fieldKey }: FieldValueSelectorProps) => {
	const issueFieldValues = state[issueKey];

	return issueFieldValues && issueFieldValues[fieldKey];
};

const getFieldValuesForIssuesSelector = (
	state: State,
	{ issueKeys, fieldKey }: FieldValuesForIssuesSelectorProps,
) => (issueKeys || []).map((issueKey) => getFieldValueSelector(state, { fieldKey, issueKey }));

const getMultiFieldValuesForIssuesSelector = (
	state: State,
	{ issueKeys, fieldKeys }: MultiFieldValuesForIssuesSelectorProps,
) =>
	(issueKeys || []).reduce((accumulator: IssueFields, issueKey) => {
		if (!state[issueKey]) {
			return accumulator;
		}

		const fieldValue = fieldKeys.reduce((acc: Field['value'], fieldKey: string) => {
			acc[fieldKey] = getFieldValueSelector(state, { fieldKey, issueKey });
			return acc;
		}, {});

		accumulator[issueKey] = fieldValue;
		return accumulator;
	}, {});

const getAllFields = (state: State, issueKey: IssueKey) => state[issueKey];

export const useFieldValue = createHook(store, {
	selector: getFieldValueSelector,
});

export const useFieldsValues = createHook(store, {
	selector: getAllFields,
});

export const useFieldValuesForIssues = createHook(store, {
	selector: getFieldValuesForIssuesSelector,
});

export const useMultiFieldValuesForIssues = createHook(store, {
	selector: getMultiFieldValuesForIssuesSelector,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FieldValueSubscriber = createSubscriber<State, Actions, any, FieldValueSelectorProps>(
	store,
	{
		selector: getFieldValueSelector,
	},
);

const useFieldValueStoreActions = createHook(store, {
	selector: null,
});

export const useFieldsValuesActions = () => {
	const [, fieldValueStoreActions] = useFieldValueStoreActions();

	const fieldsValuesActions = useMemo(() => {
		const setIssueAction = (issueKey: IssueKey, fields: IssueFields) =>
			fieldValueStoreActions.setIssue(issueKey, fields);

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const setFieldValueAction = (issueKey: IssueKey, fieldKey: string, value: any) =>
			fieldValueStoreActions.setFieldValue(issueKey, fieldKey, value);

		const getFieldValueAction = (issueKey: IssueKey, fieldKey: string) =>
			fieldValueStoreActions.getFieldValue(issueKey, fieldKey);

		const mergeIssueAction = (issueKey: IssueKey, fields: IssueFields) =>
			fieldValueStoreActions.mergeIssue(issueKey, fields);

		const setIssuesAction = (issues: IssueWithFields[]) => fieldValueStoreActions.setIssues(issues);

		const mergeIssuesAction = (issues: IssueWithFields[]) =>
			fieldValueStoreActions.mergeIssues(issues);

		return {
			setIssue: setIssueAction,
			setFieldValue: setFieldValueAction,
			getFieldValue: getFieldValueAction,
			mergeIssue: mergeIssueAction,
			setIssues: setIssuesAction,
			mergeIssues: mergeIssuesAction,
		};
	}, [fieldValueStoreActions]);

	return [null, fieldsValuesActions] as const;
};
