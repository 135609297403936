import { fetchJson } from '@atlassian/jira-fetch/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { RestIssueType } from './types.tsx';

export type IssueTypesResponse = {
	issueTypeCollection: {
		limitPerProject: number;
		data: RestIssueType[];
	};
};

type IssueTypesForProjectsResponse = {
	projects: {
		projectId: ProjectId;
		issueTypes: RestIssueType[];
	}[];
};

export const performGetIssueTypesForProject = (projectId: ProjectId): Promise<RestIssueType[]> => {
	return fetchJson(
		`/rest/polaris/v2/project/${projectId}/settings/issuetype${fg('jpd-trace-fetch-network-call') ? '?operation=GetIssueTypesForProject' : ''}`,
	).then((response: IssueTypesResponse) => {
		return response.issueTypeCollection.data;
	});
};

export const performGetIssueTypesForProjects = (projectIds: ProjectId[]) => {
	if (!projectIds.length) {
		throw new Error('No project ids provided');
	}

	const queryParams = new URLSearchParams();

	projectIds.forEach((projectId) => {
		queryParams.append('projectId', projectId);
	});

	return fetchJson<IssueTypesForProjectsResponse>(
		`/rest/polaris/v2/project/settings/issuetype?${queryParams.toString()}`,
	);
};
