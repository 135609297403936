// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	enterOrSelectConfluenceLink: {
		id: 'issue.views.confluence-pages.link.selector.placeholder',
		defaultMessage: 'Search pages or paste in a link',
		description: 'Placeholder for searching or pasting in a Confluence page link',
	},
	enterOrSelectConfluenceContentLink: {
		id: 'issue.views.confluence-pages.link.selector.placeholder.content',
		defaultMessage: 'Search content or paste in a link',
		description: 'Placeholder for searching or pasting in a Confluence content link',
	},
	noOptionsMessage: {
		id: 'issue.views.confluence-pages.link.selector.noOptionsMessage',
		defaultMessage: 'No recently visited pages',
		description: 'Message for no options in drop down',
	},
	noItemsOptionsMessage: {
		id: 'issue.views.confluence-pages.link.selector.noItemsOptionsMessage',
		defaultMessage: 'No recently visited items',
		description: 'Message for no options in drop down',
	},
});
