import { useEffect } from 'react';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIsIssueOfIncidentsPractice } from '@atlassian/jira-issue-field-servicedesk-practices/src/services/use-practices-field-value/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createContainer, createHook } from '@atlassian/react-sweet-state';
import { type Actions, actions } from './actions/index.tsx';
import type { State } from './types.tsx';

export const initialState: State = {
	incidentCapabilitiesProvisionState: {
		data: undefined,
		forIssueId: undefined,
		error: undefined,
		isLoading: false,
	},
	provisioningIncidentCapabalities: {
		hasUpdateCompleted: false,
		error: undefined,
		isLoading: false,
	},
};

const incidentManagementIncidentCapabilitiesProvisionStore = createStore<State, Actions>({
	name: 'JSDIncidentManagementIncidentCapabilitiesProvision',
	initialState,
	actions,
});

export const IncidentCapabilitiesProvisionContainer = createContainer<
	State,
	Actions,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Record<any, any>
>(incidentManagementIncidentCapabilitiesProvisionStore);

export const useIncidentCapabilitiesProvisionStateInternal = createHook(
	incidentManagementIncidentCapabilitiesProvisionStore,
	{
		selector: ({ incidentCapabilitiesProvisionState }) => incidentCapabilitiesProvisionState,
	},
);

export const useIncidentCapabilitiesProvisionState = () => {
	const result = useIncidentCapabilitiesProvisionStateInternal();
	const [{ forIssueId }, { fetchIncidentCapabilitiesProvisionState }] = result;
	const issueId = useIssueId();
	const cloudId = useCloudId();
	const isIncidentsPracticeEnabled = useIsIssueOfIncidentsPractice();

	useEffect(() => {
		if (isIncidentsPracticeEnabled && issueId && forIssueId !== issueId) {
			fetchIncidentCapabilitiesProvisionState(issueId, cloudId);
		}
	}, [
		isIncidentsPracticeEnabled,
		issueId,
		cloudId,
		forIssueId,
		fetchIncidentCapabilitiesProvisionState,
	]);

	return result;
};

export const useProvisionIncidentCapabalities = createHook(
	incidentManagementIncidentCapabilitiesProvisionStore,
	{
		selector: ({ provisioningIncidentCapabalities }) => provisioningIncidentCapabalities,
	},
);
