import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next/types';
import {
	toBaseUrl,
	toCloudId,
	toIssueId,
	toProjectKey,
	toProjectId,
	type BaseUrl,
	type CloudId,
	type IssueId,
	type ProjectKey,
	type ProjectId,
} from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createContainer,
	createHook,
	type Action,
} from '@atlassian/react-sweet-state';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { OPEN, RESOLVED } from '../common/constants.tsx';
import type {
	IssueStatus,
	RelatedIssue,
	TAffectedServices,
	TServiceNameObject,
	TServiceObject,
	TSimilarIncident,
	TSimilarIncidentResponse,
} from '../common/types.tsx';
import {
	fetchSimilarIssues as fetchSimilarIssuesMemo,
	fetchSimilarIncidents,
	fetchServiceNames,
} from './main.tsx';
import type { Context, Filter } from './types.tsx';
import { transformResponseToSimilarIssues, buildJql } from './utils.tsx';

// fields to hydrate issue line card contents
const FIELDS = ['summary', 'issuetype'];

export type State = {
	data: Partial<Record<IssueStatus, RelatedIssue[]>>;
	similarIncidentData: TSimilarIncidentResponse;
	isLoading: boolean;
	error: FetchError | undefined;
	issueId: IssueId;
	projectId: ProjectId;
	projectKey: ProjectKey;
	cloudId: CloudId;
	baseUrl: BaseUrl;
	status: IssueStatus;
	traceIds: Set<string>;
};

export type PropType = {
	baseUrl: BaseUrl;
	issueId: IssueId;
	projectId: ProjectId;
	projectKey: ProjectKey;
	cloudId: CloudId;
};

export const initialState: State = {
	data: {},
	similarIncidentData: { suggestionId: '', similarIssues: [] },
	isLoading: false,
	error: undefined,
	baseUrl: toBaseUrl(''),
	issueId: toIssueId(''),
	projectId: toProjectId(''),
	projectKey: toProjectKey(''),
	cloudId: toCloudId(''),
	status: OPEN,
	traceIds: new Set(),
};

const fetchSimilarIssuesForStatus = async (
	status: typeof OPEN | typeof RESOLVED,
	sessionId: string,
	state: Readonly<State>,
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	queryText?: string,
) => {
	const { projectId, issueId, cloudId, projectKey, baseUrl } = state;

	// if queryText is null (shouldn't resolve to null since it's based on issue summary)
	// FRS will fallback to relying on issueId for hydration
	const context: Context = {
		projectId,
		issueId,
		sessionId,
		cloudId,
		queryText,
	};

	const jql = buildJql(status);
	const filter: Filter = {
		jql,
		fields: FIELDS,
		projectKey,
		columnTypesAsJson: FIELDS,
	};

	const { data, traceId } = await fetchSimilarIssuesMemo(
		context,
		baseUrl,
		filter,
		createAnalyticsEvent,
	);

	return { data: transformResponseToSimilarIssues(data), traceId };
};

const checkResultsNeedFetching = ({ data, isLoading, status }: Readonly<State>): boolean => {
	// don't fetch if loading
	if (isLoading === false) {
		// fetch if data is not populated
		return data[status] === undefined;
	}
	return false;
};

export const fetchRelatedIssuesForIssue =
	(
		sessionId: string,
		createAnalyticsEvent: CreateUIAnalyticsEvent,
		queryText?: string,
	): Action<State> =>
	async ({ getState, setState }) => {
		const prevState: Readonly<State> = getState();

		// predicate prevents flickering by skipping if results are already present
		if (checkResultsNeedFetching(prevState)) {
			setState({
				isLoading: true,
				error: undefined,
			});

			try {
				const { status } = prevState;

				let newStatus = status;

				const [
					{ data: openTransformedIssues, traceId: trOpen },
					{ data: resolvedTransformedIssues, traceId: trResolved },
				] = await Promise.all([
					fetchSimilarIssuesForStatus(OPEN, sessionId, prevState, createAnalyticsEvent, queryText),
					fetchSimilarIssuesForStatus(
						RESOLVED,
						sessionId,
						prevState,
						createAnalyticsEvent,
						queryText,
					),
				]);
				if (openTransformedIssues.length === 0 && resolvedTransformedIssues.length > 0) {
					newStatus = RESOLVED;
				}
				setState({
					data: {
						[OPEN]: openTransformedIssues,
						[RESOLVED]: resolvedTransformedIssues,
					},
					isLoading: false,
					error: undefined,
					status: newStatus,
					traceIds: new Set([trOpen, trResolved]),
				});
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				// enable users to re-fetch if responds with error
				fetchSimilarIssuesMemo.cache?.clear?.();
				setState({
					isLoading: false,
					error,
					traceIds: new Set([error.traceId]),
				});
			}
		}
	};

const checkIncidentsNeedFetching = ({
	similarIncidentData: { suggestionId },
	isLoading,
}: Readonly<State>): boolean => {
	// don't fetch if loading
	if (isLoading === false) {
		// fetch if data is not populated
		return !suggestionId;
	}
	return false;
};

export const fetchRelatedIncidentsForIssue =
	(createAnalyticsEvent: CreateUIAnalyticsEvent): Action<State> =>
	async ({ setState, getState }) => {
		const prevState: Readonly<State> = getState();
		if (checkIncidentsNeedFetching(prevState)) {
			setState({
				isLoading: true,
				error: undefined,
			});

			try {
				const state = getState();
				const { issueId, projectId } = state;
				const { data: similarIncidents, traceId } = await fetchSimilarIncidents(
					issueId,
					projectId,
					createAnalyticsEvent,
				);

				// get serviceIds from affected services list
				const serviceIds = similarIncidents.similarIssues
					.flatMap(
						(currentSimilarIncident: TSimilarIncident) =>
							currentSimilarIncident &&
							currentSimilarIncident.affectedServices &&
							currentSimilarIncident.affectedServices.map(
								(service: TAffectedServices) => service.id,
							),
					)
					.filter((a: string) => a);

				// fetch service names and create a map
				const serviceNames = await fetchServiceNames(serviceIds, createAnalyticsEvent);
				const serviceNamesMap = serviceNames.services.reduce(
					(acc: TServiceNameObject, obj: TServiceObject) => {
						acc[obj.id] = obj.name;
						return acc;
					},
					{},
				);

				// populate service names in affected services
				const newSimilarIncidents = similarIncidents.similarIssues.map(
					(currentSimilarIncident: TSimilarIncident) => ({
						...currentSimilarIncident,
						...(currentSimilarIncident.affectedServices
							? {
									affectedServices: currentSimilarIncident.affectedServices.map(
										(service: TAffectedServices) => ({
											id: service.id,
											name: serviceNamesMap[service.id],
										}),
									),
								}
							: []),
					}),
				);

				setState({
					similarIncidentData: {
						suggestionId: similarIncidents.suggestionId,
						similarIssues: newSimilarIncidents,
					},
					traceIds: new Set([traceId]),
					isLoading: false,
					error: undefined,
				});
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				setState({
					isLoading: false,
					similarIncidentData: { suggestionId: '', similarIssues: [] },
					error,
					traceIds: new Set([error.traceId]),
				});
			}
		}
	};

export const setIssueStatus =
	(status: IssueStatus): Action<State> =>
	({ getState, setState }) => {
		const { status: oldStatus } = getState();
		if (status !== oldStatus) {
			setState({ status });
		}
	};

const setContext =
	(): Action<State, PropType> =>
	({ setState }, updatedState) => {
		setState(updatedState);
	};

const actions = {
	fetchRelatedIssuesForIssue,
	setContext,
	setIssueStatus,
	fetchRelatedIncidentsForIssue,
} as const;

export type Actions = typeof actions;

const store = createStore<State, Actions>({
	name: 'FetchRelatedIssuesForIssue',
	initialState,
	actions,
});

export const Container = createContainer<State, Actions, PropType>(store, {
	onInit: actions.setContext,
});

export const useRelatedIssuesForIssue = createHook(store);

export const useRelatedIncidentsForIssue = createHook(store);
