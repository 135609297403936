import React, { createContext, useContext, useMemo, useState, type ReactNode } from 'react';
import type { AccessRequestType } from '../../services/api/api-hooks.tsx';

interface ConfluenceDummyLink {
	accessRequestResult: AccessRequestType | undefined;
	isBannerHiddenAfterJoined: boolean;
	isEpModalOpen: boolean; // used to control the DraftEPModal
	isProvisioning: boolean;
	isRequestingAccess: boolean;
	setIsEpModalOpen: (isOpen: boolean) => void;
	activePageUrl: string; // used by the DraftEpModal to load the appropriate modal.
	setActivePageUrl: (activePageUrl: string) => void;
	embeddedConfluenceSource: string;
	setAccessRequestResult: (state: AccessRequestType) => void;
	setIsBannerHidden: (state: boolean) => void;
	setIsProvisioning: (state: boolean) => void;
	setIsRequestingAccess: (state: boolean) => void;
}

const ConfluenceDummyLinkContext = createContext<ConfluenceDummyLink>({
	accessRequestResult: undefined,
	isBannerHiddenAfterJoined: false,
	isEpModalOpen: false,
	isProvisioning: false,
	isRequestingAccess: false,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setIsEpModalOpen: () => {},
	activePageUrl: '',
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setActivePageUrl: () => {},
	embeddedConfluenceSource: '',
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setAccessRequestResult: () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setIsBannerHidden: () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setIsProvisioning: () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setIsRequestingAccess: () => {},
});

export const useConfluenceDummyLinkContext = () => useContext(ConfluenceDummyLinkContext);

interface ConfluenceDummyLinkProviderProps {
	children: ReactNode;
	embeddedConfluenceSource?: string;
}

export const ConfluenceDummyLinkProvider = ({
	children,
	embeddedConfluenceSource = 'jiraIssueView',
}: ConfluenceDummyLinkProviderProps) => {
	const [accessRequestResult, setAccessRequestResult] = useState<AccessRequestType | undefined>();
	const [isEpModalOpen, setIsEpModalOpen] = useState(false);
	const [activePageUrl, setActivePageUrl] = useState('');
	const [isProvisioning, _setIsProvisioning] = useState(false);
	const [isRequestingAccess, setIsRequestingAccess] = useState(false);
	const [isBannerHiddenAfterJoined, setIsBannerHidden] = useState(false);
	const setIsProvisioning = (newState: boolean) => {
		_setIsProvisioning((previousState: boolean) => {
			if (previousState === true && newState === false) {
				setIsBannerHidden(true);
			}
			return newState;
		});
	};

	return (
		<ConfluenceDummyLinkContext.Provider
			value={useMemo(
				() => ({
					accessRequestResult,
					isBannerHiddenAfterJoined,
					isEpModalOpen,
					isProvisioning,
					isRequestingAccess,
					setIsEpModalOpen,
					activePageUrl,
					setActivePageUrl,
					embeddedConfluenceSource,
					setAccessRequestResult,
					setIsBannerHidden,
					setIsProvisioning,
					setIsRequestingAccess,
				}),
				[
					accessRequestResult,
					activePageUrl,
					embeddedConfluenceSource,
					isBannerHiddenAfterJoined,
					isEpModalOpen,
					isProvisioning,
					isRequestingAccess,
				],
			)}
		>
			{children}
		</ConfluenceDummyLinkContext.Provider>
	);
};
