import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	editCover: {
		id: 'issue-view-foundation.issue-actions.issue-manipulation-dropdown-group.edit-cover.edit-cover',
		defaultMessage: 'Edit cover',
		description: 'Label for the issue actions item to edit cover',
	},
	editCoverIconLabel: {
		id: 'issue-view-foundation.issue-actions.issue-manipulation-dropdown-group.edit-cover.edit-cover-icon-label',
		defaultMessage: 'Edit cover chevron right icon label',
		description: 'Label for the edit cover chevron right icon',
	},
});
