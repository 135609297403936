import { setMark, setMeasure } from '@atlassian/jira-common-performance/src/marks.tsx';
import fireErrorAnalytics, {
	isNetworkError,
} from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { PACKAGE_NAME, SEVEN_DAYS_IN_MILLIS, TEAM_NAME } from '../constants.tsx';
import { createCacheKey } from '../create-cache-key/index.tsx';
import { errorHandler } from '../error-handler/index.tsx';
import { jiraCache } from '../indexed-db-storage/index.tsx';

export const withCache =
	<T,>(fn: (url: string, options: RequestInit) => Promise<T>) =>
	async <E,>(
		url: string,
		onFnErrorCacheHandler?: (error: E) => T,
		maxAge: number = SEVEN_DAYS_IN_MILLIS,
		options: RequestInit = {},
	): Promise<T> => {
		if (__SERVER__) {
			return fn(url, options);
		}
		try {
			if (!expValEquals('jira_caching_layer_milestone_1_exp', 'cohort', 'variation')) {
				return fn(url, options);
			}
			const cachedKey = createCacheKey(url);
			const readMeasureName = 'indexed-db-cache-read';
			const readStartMark = `${readMeasureName}:start`;
			const readEndMark = `${readMeasureName}:end`;
			setMark(readStartMark);
			const cachedData = await jiraCache.get(cachedKey);
			setMark(readEndMark);
			setMeasure(readMeasureName, readStartMark, readEndMark);
			if (cachedData !== undefined && Date.now() < cachedData.expiresAt) {
				return cachedData.data;
			}
			let data: T;
			try {
				data = await fn(url, options);
			} catch (error: unknown) {
				if (onFnErrorCacheHandler) {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					data = onFnErrorCacheHandler(error as E);
				} else {
					throw error;
				}
			}
			const writeMeasureName = 'indexed-db-cache-write';
			const writeStartMark = `${writeMeasureName}:start`;
			const writeEndMark = `${writeMeasureName}:end`;
			setMark(writeStartMark);
			jiraCache.set(cachedKey, { data, expiresAt: Date.now() + maxAge }).then(() => {
				setMark(writeEndMark);
				setMeasure(writeMeasureName, writeStartMark, writeEndMark);
			});
			return data;
		} catch (error) {
			if (fg('jcl_error_handling_and_conditional_skip_sentry')) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				errorHandler(error as Error, 'withCache');
			} else {
				fireErrorAnalytics({
					meta: {
						id: 'withCache',
						packageName: PACKAGE_NAME,
						teamName: TEAM_NAME,
					},
					error: new Error('Error in JCL withCache'),
					attributes: {
						cause: error instanceof Error ? error.message : 'Not known',
					},
					sendToPrivacyUnsafeSplunk: true,
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					skipSentry: isNetworkError(error as Error),
				});
			}
			return fn(url, options);
		}
	};
