import { useMemo } from 'react';
import type {
	JiraTimeFormat,
	JiraTimeUnit,
} from '@atlassian/jira-relay/src/__generated__/originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditViewNew_timeTrackingSettingsfragmentRef.graphql';
import {
	parseTimeString,
	isValidTimeString,
} from '@atlassian/jira-issue-format-time/src/index.tsx';
import type {
	DefaultUnit,
	Format,
	TimeTrackingConfig,
} from '@atlassian/jira-issue-shared-types/src/common/types/jira-settings-type.tsx';
import type { TimeTrackingSettingsInput } from './types.tsx';
import {
	DEFAULT_TIME_FORMAT,
	DEFAULT_TIME_UNIT,
	DEFAULT_WORKING_DAYS_PER_WEEK,
	DEFAULT_WORKING_HOURS_PER_DAY,
} from './constants.tsx';
import { toLowercaseTimeUnion } from './common.tsx';

const toTimeFormat = (format: JiraTimeFormat | null | undefined): Format => {
	return format ? toLowercaseTimeUnion(format) : DEFAULT_TIME_FORMAT;
};
const toTimeUnit = (unit: JiraTimeUnit | null | undefined): DefaultUnit => {
	return unit ? toLowercaseTimeUnion(unit) : DEFAULT_TIME_UNIT;
};

const useTimeTrackingParsingConfig = ({
	workingHoursPerDay,
	workingDaysPerWeek,
	defaultFormat,
	defaultUnit,
}: TimeTrackingSettingsInput): TimeTrackingConfig => {
	return useMemo(
		() => ({
			hoursPerDay: workingHoursPerDay || DEFAULT_WORKING_HOURS_PER_DAY,
			daysPerWeek: workingDaysPerWeek || DEFAULT_WORKING_DAYS_PER_WEEK,
			format: toTimeFormat(defaultFormat),
			defaultUnit: toTimeUnit(defaultUnit),
			isTimeTrackingEnabled: true,
		}),
		[defaultFormat, defaultUnit, workingDaysPerWeek, workingHoursPerDay],
	);
};

export const useValidateTimeString = (input: TimeTrackingSettingsInput) => {
	const config = useTimeTrackingParsingConfig(input);
	return useMemo(() => isValidTimeString(config), [config]);
};

export const useParseTimeString = (input: TimeTrackingSettingsInput) => {
	const config = useTimeTrackingParsingConfig(input);
	return useMemo(() => parseTimeString(config), [config]);
};
