import React, { Component, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import EnterEscapeHandler from '@atlassian/jira-common-components-enter-escape-handler/src/index.tsx';
import { FieldHeading } from '@atlassian/jira-issue-field-heading/src/index.tsx';
import {
	SideBySideField,
	FieldWrapper,
	FieldHeadingTitle,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin/src/index.tsx';
import IssueRemainingEstimate from '@atlassian/jira-issue-field-remaining-estimate/src/ui/index.tsx';
import type { TimeTrackingConfig } from '@atlassian/jira-issue-shared-types/src/common/types/jira-settings-type.tsx';
import type { TimeTrackingState } from '@atlassian/jira-issue-shared-types/src/common/types/time-tracking-type.tsx';
import { RollupCheckbox } from '@atlassian/jira-issue-view-common/src/component/log-time-modal/modal/roll-up-checkbox/index.tsx';
import LoggedTime from '@atlassian/jira-issue-view-common/src/component/logged-time/view.tsx';
import type {
	IssueKey,
	IssueId,
	FormatMessage,
} from '@atlassian/jira-shared-types/src/general.tsx';
import { transformFromStateValue } from '../common/transformers/index.tsx';
import { getEditButtonLabel } from '../utils/get-edit-button-label.tsx';
import { RegisterTimeTrackingCommandPalette } from './command-palette/index.tsx';

const location = 'include-subtasks-children-in-time-tracking-toggle-from-context';
const actionSubjectId = 'toggleRollUpDataControlFromContext';

export type StateProps = {
	isDone: boolean;
	isEditable: boolean;
	isMobile: boolean;
	isVisible: boolean;
	issueKey: IssueKey;
	issueId?: IssueId;
	isRollingUpData: boolean;
	shouldDisplayRollUpDataControl: boolean;
	cancelButtonLabel: string;
	rollupLabel: string;
	value: TimeTrackingState;
	config: TimeTrackingConfig;
	label: string;
	// go/jfe-eslint
	editButtonLabel: string;
	confirmButtonLabel: string;
	timeRemainingFieldLabel: string;
	fieldId: string;
	showPinButton?: boolean;
	invalidTimeFormatMessage: ReactNode;
	placeholderMessage: string;
	formatMessage: FormatMessage;
};

export type DispatchProps = {
	onEditRequest: () => void;
	onToggleRollingUpData: (arg1: boolean, arg2: UIAnalyticsEvent) => void;
};

export type Props = StateProps & DispatchProps;

// eslint-disable-next-line jira/react/no-class-components
export default class TimeTrackingView extends Component<Props> {
	static displayName = 'TimeTrackingView';

	renderReadView() {
		const { value, isDone, config, isEditable } = this.props;
		return (
			<LoggedTime
				value={transformFromStateValue(value)}
				estimatedTime={value.originalEstimateSeconds}
				isDone={isDone}
				config={config}
				isEditable={isEditable}
			/>
		);
	}

	renderRegisterTimeTrackingCommandPalette() {
		const { onEditRequest, isEditable } = this.props;
		return isEditable ? <RegisterTimeTrackingCommandPalette onEditRequest={onEditRequest} /> : null;
	}

	renderRemainingEstimateView() {
		const {
			config,
			fieldId,
			issueKey,
			issueId,
			showPinButton,
			invalidTimeFormatMessage,
			placeholderMessage,
			timeRemainingFieldLabel,
		} = this.props;

		if (!issueId) {
			return null;
		}

		return (
			<RemainingEstimateFieldWrapper>
				<FieldHeading>
					<FieldHeadingTitle>{timeRemainingFieldLabel}</FieldHeadingTitle>
					{showPinButton === true && <FieldPin fieldId={fieldId} label={timeRemainingFieldLabel} />}
				</FieldHeading>
				<SideBySideField>
					<IssueRemainingEstimate
						config={config}
						issueKey={issueKey}
						issueId={issueId}
						value=""
						placeholderMessage={placeholderMessage}
						invalidTimeFormatMessage={invalidTimeFormatMessage}
						isFitContainerWidthReadView
					/>
				</SideBySideField>
			</RemainingEstimateFieldWrapper>
		);
	}

	renderRollUpToggle() {
		const { isRollingUpData, onToggleRollingUpData } = this.props;
		return (
			<RollupToggleWrapper
				data-testid="issue-view-base.context.time-tracking.rollup-toggle"
				sideBySideMode
			>
				<RollupCheckbox
					isRollingUpData={isRollingUpData}
					location={location}
					actionSubjectId={actionSubjectId}
					onToggleRollingUpData={onToggleRollingUpData}
				/>
			</RollupToggleWrapper>
		);
	}

	render() {
		const {
			isVisible,
			label,
			isEditable,
			isMobile,
			onEditRequest,
			confirmButtonLabel,
			cancelButtonLabel,
			fieldId,
			showPinButton,
			shouldDisplayRollUpDataControl,
			formatMessage,
			config,
			value,
		} = this.props;

		if (!isVisible) {
			return this.renderRemainingEstimateView();
		}

		const editButtonLabel = getEditButtonLabel(formatMessage, label, config, value);

		return (
			<FieldWrapper data-testid={`issue.views.issue-base.context.time-tracking.${fieldId}`}>
				<FieldHeading fieldId={fieldId}>
					<FieldHeadingTitle>{label}</FieldHeadingTitle>
					{showPinButton === true && <FieldPin fieldId={fieldId} label={label} />}
				</FieldHeading>
				<SideBySideField
					/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
					data-testid="issue.views.issue-base.context.time-tracking.value"
				>
					<EnterEscapeHandler>
						{
							<CompactWrapper isEditable={isEditable}>
								<FieldInlineEditStateLess
									label={label}
									readView={this.renderReadView()}
									editView={
										/* We need to provide a value here to force the InlineEdit to display the hover styles */
										isEditable ? <div /> : null
									}
									isEditing={false}
									isEditable={isEditable}
									areActionButtonsHidden
									// @ts-expect-error - TS2322 - Property 'disableEditViewFieldBase' does not exist on type 'IntrinsicAttributes & FieldInlineEditStateLessProps<unknown>'.
									disableEditViewFieldBase
									isFitContainerWidthReadView={!isMobile}
									isLabelHidden
									onEditRequested={onEditRequest}
									editButtonLabel={editButtonLabel}
									confirmButtonLabel={confirmButtonLabel}
									cancelButtonLabel={cancelButtonLabel}
								/>
							</CompactWrapper>
						}
						{shouldDisplayRollUpDataControl && this.renderRollUpToggle()}
						{this.renderRegisterTimeTrackingCommandPalette()}
					</EnterEscapeHandler>
				</SideBySideField>
			</FieldWrapper>
		);
	}
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CompactWrapper = styled.div<{ isEditable: boolean }>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isEditable }) =>
		isEditable && {
			marginLeft: token('space.negative.100'),
			marginTop: token('space.negative.100'),
			marginRight: token('space.100'),
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RemainingEstimateFieldWrapper = styled(FieldWrapper)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div > div > div > div > div > div > div': {
		width: '100%',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RollupToggleWrapper = styled.div<{ sideBySideMode?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginBottom: (props) => `${props.sideBySideMode ? 0 : token('space.300')}`,
	marginLeft: token('space.negative.050'),
	paddingTop: token('space.075'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > label > span': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text.subtle'),
	},
});
