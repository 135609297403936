import React, { useEffect, useMemo } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import type { JSErrorBoundaryProps } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { getErrorType } from '@atlassian/ui-modifications-analytics';
import type { RootUiModificationsQueryType } from '@atlassian/ui-modifications-core/gql';
import { ForgeAppRenderer } from '@atlassian/jira-ui-modifications-common/src/common/ui/ui-modifications-jira-core/forge-app-renderer/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useActivationId } from '@atlassian/jira-tenant-context-controller/src/components/activation-id/index.tsx';
import { UiModificationsErrorHandler } from '@atlassian/ui-modifications-core';
import {
	isTriggerPointSupported,
	isExecutionContextSupported,
} from '@atlassian/ui-modifications-core/src/controllers/view-configuration/index.tsx';
import type { ExecutionContext } from '@atlassian/ui-modifications-core/types';
import { uiModificationsRootEntrypoint } from './entrypoint.tsx';
import type { RootEntrypointWrapperRuntimeProps } from './root-entrypoint-wrapper/index.tsx';

export type UiModificationsJiraRootProps = {
	containerId: string;
	containerPackageName: string;
	queryParams: RootUiModificationsQueryType['variables'];
	/**
	 * If not provided, the default `UiModificationsErrorHandler` error fallback will be used.
	 */
	errorFallback?: JSErrorBoundaryProps['fallback'];
	executionContext: ExecutionContext;
} & Omit<
	RootEntrypointWrapperRuntimeProps,
	'cloudId' | 'activationId' | 'ForgeAppRenderer' | 'executionContext'
>;

const defaultErrorFallback = () => <UiModificationsErrorHandler />;

/**
 * Jira UI modifications root component with entrypoint support and query loading.
 */
export const UiModificationsJiraRoot = ({
	containerId,
	containerPackageName,
	errorFallback,
	queryParams,
	executionContextKey,
	executionContext,
	triggerPointKey,
	numberOfSupportedFields,
	resetFormFieldsCounter,
}: UiModificationsJiraRootProps) => {
	const cloudId = useCloudId();
	const activationId = useActivationId();
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		uiModificationsRootEntrypoint,
		queryParams,
	);

	const shouldUiModificationsLoad =
		executionContext &&
		isExecutionContextSupported(executionContext) &&
		isTriggerPointSupported(queryParams.uiModificationsContext.viewType, triggerPointKey);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		shouldUiModificationsLoad && entryPointActions.load();
	}, [entryPointActions, queryParams, executionContextKey, shouldUiModificationsLoad]);

	const runtimeProps = useMemo<RootEntrypointWrapperRuntimeProps>(
		() => ({
			cloudId,
			activationId,
			executionContextKey,
			executionContext,
			triggerPointKey,
			numberOfSupportedFields,
			resetFormFieldsCounter,
			ForgeAppRenderer,
		}),
		[
			activationId,
			cloudId,
			executionContextKey,
			executionContext,
			triggerPointKey,
			numberOfSupportedFields,
			resetFormFieldsCounter,
		],
	);

	return (
		<JiraEntryPointContainer
			entryPointReferenceSubject={entryPointReferenceSubject}
			id={containerId}
			packageName={containerPackageName}
			fallback={<></>}
			errorFallback={errorFallback ?? defaultErrorFallback}
			errorAttributes={(error: Error) => ({
				errorType: getErrorType(error),
				viewType: queryParams.uiModificationsContext.viewType,
			})}
			runtimeProps={runtimeProps}
		/>
	);
};
