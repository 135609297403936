/** @jsx jsx */
import React, { type HTMLAttributes } from 'react';
import { styled, css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';

import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { ReadViewContainer } from '@atlassian/jira-issue-field-inline-edit/src/styled.tsx';
import { LozengeChildNumberFieldContainer } from '@atlassian/jira-issue-field-number/src/ui/index.tsx';
import { NumberView as IssueNumberFieldReadonly } from '@atlassian/jira-issue-field-number/src/ui/view/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { stopPropagation } from '../../common/utils.tsx';
import messages from './messages.tsx';

type Props = {
	issueKey: IssueKey;
	estimateFieldId: string;
	isLinkedIssuesRefreshEnabled?: boolean;
};

export const ReadOnlyEstimate = ({
	issueKey,
	estimateFieldId,
	isLinkedIssuesRefreshEnabled = false,
}: Props) => {
	const [estimateValue] = useFieldValue({ issueKey, fieldKey: estimateFieldId });
	const { formatMessage } = useIntl();
	const Container = isLinkedIssuesRefreshEnabled ? NewContainer : OldContainer;

	return (
		<Container
			data-testid="issue-line-card.ui.read-only-estimate.readonly-estimate-field"
			role="button"
			onClick={stopPropagation}
			onFocus={stopPropagation}
			aria-label={formatMessage(messages.ariaLabelForEstimateField)}
		>
			{isLinkedIssuesRefreshEnabled ? (
				<ReadViewContainer>
					<IssueNumberFieldReadonly isEditable={false} value={estimateValue} />
				</ReadViewContainer>
			) : (
				/* @ts-expect-error - TS2741 - Property 'hasValue' is missing in type '{ children: Element; }' but required in type 'Readonly<ThemedOuterStyledProps<ClassAttributes<HTMLDivElement> & HTMLAttributes<HTMLDivElement> & { hasValue: boolean; }, any>>'. */
				<LozengeChildNumberFieldContainer>
					<ReadViewContainer>
						<IssueNumberFieldReadonly isEditable={false} value={estimateValue} />
					</ReadViewContainer>
				</LozengeChildNumberFieldContainer>
			)}
		</Container>
	);
};

const newContainerStyles = css({
	display: 'flex',
	alignItems: 'center',
});

export const NewContainer = ({ children, ...rest }: HTMLAttributes<HTMLDivElement>) => (
	<div css={newContainerStyles} {...rest}>
		{children}
	</div>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OldContainer = styled.div({
	paddingLeft: token('space.050'),
});
