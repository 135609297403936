import React, { useEffect, useCallback, useReducer, useMemo, useRef } from 'react';
import type AnalyticsWebClient from '@atlassiansox/analytics-web-client';
import type { EmojiProvider } from '@atlaskit/emoji';
import { getEmojiProvider } from '@atlassian/jira-common-atlaskit-services/src/emoji.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import { entryPoint } from '@atlassian/jira-issue-create-confluence-content/src/ui/background-provisioning-editor/entrypoint.tsx';
import { useTemplateSelection } from '@atlassian/jira-issue-create-confluence-content/src/ui/background-provisioning-editor/hooks/use-template-selection.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import { isSupportedIssueType } from '../../../common/utils.tsx';
import messages from '../../messages.tsx';
import {
	type AnalyticsEventHandler,
	type JobParameters,
	type LoadMessageData,
	type Events,
	PRELOAD_MESSAGE_NAME,
	LOAD_MESSAGE_NAME,
	DEFAULT_ISSUE_TYPE,
	DEFAULT_JOB_PARAMETERS,
	DEFAULT_POLLING_OPTIONS,
	ISSUE_TYPE_TO_TEMPLATE,
	JOB_ID,
	SOURCE_COMPONENT,
	SOURCE_CONTEXT,
	MissingMessageData,
	PostMessageError,
	AnalyticsWebClientInitializationError,
	ANALYTICS_EVENTS,
} from './constants.tsx';

type Props = {
	issueType: string | undefined;
	projectId: string | undefined;
	cloudId: string;
	onCloseEditor?: () => void;
};

type State = {
	isOpen: boolean;
	jobParameters: Omit<JobParameters, 'projectId'>;
	editorProps: {
		emojiProvider: Promise<EmojiProvider> | undefined;
	};
};

type Action =
	| { type: 'CLOSE' }
	| { type: 'OPEN' }
	| { type: 'START_PROVISIONING'; payload: Partial<JobParameters> };

function reducer(state: State, action: Action): State {
	switch (action.type) {
		case 'CLOSE':
			return { ...state, isOpen: false };
		case 'OPEN':
			return { ...state, isOpen: true };
		case 'START_PROVISIONING':
			return {
				...state,
				isOpen: true,
				editorProps: {
					...state.editorProps,
					emojiProvider: getEmojiProvider() ?? undefined,
				},
				jobParameters: { ...state.jobParameters, ...action.payload },
			};
		default:
			return state;
	}
}

const entryPointParams = {};

export const BackgroundProvisioningEditor = (props: Props) => {
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagsService();
	const [state, dispatch] = useReducer(reducer, {
		isOpen: false,
		jobParameters: DEFAULT_JOB_PARAMETERS,
		editorProps: {
			emojiProvider: undefined,
		},
	});
	const isPreloaded = useRef(false);
	const jobParametersWithProjectId: JobParameters = useMemo(
		() => ({
			...state.jobParameters,
			projectId: Number(props?.projectId),
		}),
		[props?.projectId, state.jobParameters],
	);
	const issueType = useMemo(
		() => (isSupportedIssueType(props.issueType) ? props.issueType : DEFAULT_ISSUE_TYPE),
		[props.issueType],
	);
	const template = useTemplateSelection(ISSUE_TYPE_TO_TEMPLATE[issueType]);
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		entryPoint,
		entryPointParams,
	);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const analyticsWebClientRef = useRef<AnalyticsWebClient | null>(null);

	const fireEvent = useCallback(
		(handler: AnalyticsEventHandler) => {
			handler(createAnalyticsEvent({}), {
				sourceComponent: SOURCE_COMPONENT,
				sourceContext: SOURCE_CONTEXT,
				issueType,
			});
		},
		[createAnalyticsEvent, issueType],
	);

	const onClose = useCallback(() => {
		dispatch({ type: 'CLOSE' });
		entryPointActions.unload();
		props.onCloseEditor && props.onCloseEditor();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [entryPointActions, dispatch]);

	const onPublished = useCallback(() => {
		fireEvent(ANALYTICS_EVENTS.pagePublished);
		dispatch({ type: 'CLOSE' });
		showFlag({
			messageId:
				'issue-view-base.content.confluence-placeholder-template-experiment.confluence-dummy-link.experiments.background-provisioning-editor.show-flag.success.page-published',
			messageType: 'transactional',
			type: 'success',
			isAutoDismiss: true,
			title: formatMessage(messages.pagePublishedFlagTitle),
			description: formatMessage(messages.pagePublishedFlagDescription),
		});
		entryPointActions.unload();
		props.onCloseEditor && props.onCloseEditor();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [entryPointActions, showFlag, formatMessage, fireEvent]);

	const handleLoadMessage = useCallback(
		(eventData: LoadMessageData) => {
			const requestedEditionId = eventData?.[LOAD_MESSAGE_NAME]?.targetEdition;
			if (requestedEditionId) {
				dispatch({
					type: 'START_PROVISIONING',
					payload: {
						requestedEditionId,
					},
				});
				entryPointActions.load();
				fireEvent(ANALYTICS_EVENTS.loadedEditor);
			} else {
				fireErrorAnalytics({
					error: new MissingMessageData(
						`Missing required data requestedEditionId in postMessage event: ${LOAD_MESSAGE_NAME}`,
					),
					meta: {
						id: MissingMessageData.name,
						teamName: 'starburst',
						packageName:
							'@atlassian/jira-issue-view-base/confluence-dummy-link/background-provisioning-editor',
					},
					attributes: {
						eventData: {
							requestedEditionId,
						},
					},
				});
			}
		},
		[entryPointActions, dispatch, fireEvent],
	);

	const handleOnProvisioned = useCallback(() => {
		try {
			if (analyticsWebClientRef.current) {
				analyticsWebClientRef.current.setEmbeddedProduct('confluence');
			}
		} catch (error) {
			fireErrorAnalytics({
				error: new AnalyticsWebClientInitializationError(
					'Could not set confluence embedded product',
					{
						cause: error,
					},
				),
				meta: {
					id: AnalyticsWebClientInitializationError.name,
					teamName: 'starburst',
					packageName:
						'@atlassian/jira-issue-view-base/confluence-dummy-link/background-provisioning-editor',
				},
			});
		}
	}, []);

	const handlePostMessage = useCallback(
		(event: MessageEvent<Events>) => {
			try {
				if (
					event.origin !== globalThis.window.location.origin ||
					!event.data ||
					typeof event.data !== 'object'
				) {
					// Ignore messages from different origins
					return;
				}
				if (LOAD_MESSAGE_NAME in event?.data) {
					handleLoadMessage(event?.data);
				} else if (PRELOAD_MESSAGE_NAME in event?.data && !isPreloaded.current) {
					isPreloaded.current = true;
					entryPointActions.preload();
					fireEvent(ANALYTICS_EVENTS.preloadedEditor);
				}
			} catch (error) {
				fireErrorAnalytics({
					error: new PostMessageError('Error handling postMessage event', {
						cause: error,
					}),
					meta: {
						id: PostMessageError.name,
						teamName: 'starburst',
						packageName:
							'@atlassian/jira-issue-view-base/confluence-dummy-link/background-provisioning-editor',
					},
				});
			}
		},
		[entryPointActions, handleLoadMessage, fireEvent],
	);

	useEffect(() => {
		globalThis.window.addEventListener('message', handlePostMessage);
		const analyticsClient = analyticsWebClientRef.current;
		if (!analyticsClient) {
			getAnalyticsWebClientPromise()
				.then((client) => {
					analyticsWebClientRef.current = client.getInstance();
				})
				.catch(() => {
					fireErrorAnalytics({
						meta: {
							id: AnalyticsWebClientInitializationError.name,
							teamName: 'starburst',
							packageName:
								'@atlassian/jira-issue-view-base/confluence-dummy-link/background-provisioning-editor',
						},
						error: new AnalyticsWebClientInitializationError(
							'Could not initialize analytics web client, will not report ui viewed event',
						),
					});
				});
		}

		return () => {
			globalThis.window.removeEventListener('message', handlePostMessage);
			entryPointActions.unload();
		};
	}, [entryPointActions, handlePostMessage]);

	return (
		<JiraEntryPointContainer
			data-testid="issue-view-base.content.confluence-placeholder-template-experiment.confluence-dummy-link.experiments.background-provisioning-editor.background-provisioning-editor"
			entryPointReferenceSubject={entryPointReferenceSubject}
			id="sandbox-editor"
			packageName="@atlassian/jira-issue-view-base/confluence-dummy-link/background-provisioning-editor"
			teamName="starburst"
			runtimeProps={{
				jobParameters: jobParametersWithProjectId,
				isOpen: state.isOpen,
				cloudId: props.cloudId,
				jobId: JOB_ID,
				sourceContext: SOURCE_CONTEXT,
				sourceComponent: SOURCE_COMPONENT,
				onClose,
				onPublished,
				onProvisioned: handleOnProvisioned,
				editorProps: state.editorProps,
				initialTemplate: template,
				openInConfluenceUrl: '',
				pollingOptions: DEFAULT_POLLING_OPTIONS,
			}}
		/>
	);
};
