// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	fieldLabel: {
		id: 'issue.views.confluence-pages.link.label',
		defaultMessage: 'Search pages or paste in a link',
		description: 'Label for Confluence page linking field',
	},
	fieldPlaceholder: {
		id: 'issue.views.confluence-pages.link.placeholder',
		defaultMessage: 'Search pages or paste in a link',
		description: 'Placeholder text to prompt users to enter a link to a Confluence page',
	},
	genericErrorText: {
		id: 'issue.views.confluence-pages.link.error.generic',
		defaultMessage: "We're having trouble. Check your connection and try again.",
		description: 'Inline error text when we are not sure what went wrong',
	},
	duplicateErrorText: {
		id: 'issue.views.confluence-pages.link.error.duplicate',
		defaultMessage: 'That page is already linked to this issue.',
		description: 'Duplicate page add attempt error.',
	},
	duplicateItemErrorText: {
		id: 'issue.views.confluence-pages.link.error.duplicate.item',
		defaultMessage: 'That item is already linked to this issue.',
		description: 'Duplicate item add attempt error.',
	},
	forbiddenErrorText: {
		id: 'issue.views.confluence-pages.link.error.forbidden',
		defaultMessage: "You don't have permission to link to Confluence pages.",
		description: "User doesn't have permission to add the page.",
	},
	forbiddenContentErrorText: {
		id: 'issue.views.confluence-pages.link.error.forbidden.content',
		defaultMessage: "You don't have permission to link to Confluence content.",
		description: "User doesn't have permission to add the content.",
	},
	issueIdErrorText: {
		id: 'issue.views.confluence-pages.link.error.issue-id',
		defaultMessage: "We're having trouble. Check your connection and try again.",
		description: "Inline error text when we can't retrieve the issue id.",
	},
	noMatchingApplinkErrorText: {
		id: 'issue.views.confluence-pages.link.error.no-matching-app-link',
		defaultMessage: "You don't have an application link set up for that Confluence site.",
		description: "The page reuqested doesn't exist in the Confluence sites connected to this Jira.",
	},
	pageIdNotFoundErrorText: {
		id: 'issue.views.confluence-pages.link.error.page-id-not-found',
		defaultMessage: "We can't find that page. It might be deleted or restricted.",
		description: "This page can't be found.",
	},
	contentIdNotFoundErrorText: {
		id: 'issue.views.confluence-pages.link.error.content-id-not-found',
		defaultMessage: "We can't find that item. It might be deleted or restricted.",
		description: "This item can't be found.",
	},
	unauthorizedErrorText: {
		id: 'issue.views.confluence-pages.link.error.unauthorized',
		defaultMessage: "You need to authorize this pages' Confluence site.",
		description: 'The Conflue applink has not been authorized yet.',
	},
	unauthorizedErrorPrompt: {
		id: 'issue.views.confluence-pages.link.error.unauthorized-prompt',
		defaultMessage:
			"Unable to link the Confluence page. You don't have permission or need to {linkStart}authenticate{linkEnd}",
		description: 'Prompt the user to authorize the Confluence applink',
	},
	unauthorizedContentErrorPrompt: {
		id: 'issue.views.confluence-pages.link.error.unauthorized-prompt-content',
		defaultMessage:
			"Unable to link the Confluence content. You don't have permission or need to {linkStart}authenticate{linkEnd}",
		description: 'Prompt the user to authorize the Confluence applink',
	},
	urlInvalidErrorText: {
		id: 'issue.views.confluence-pages.link.error.url-invalid',
		defaultMessage: "That URL doesn't look right. Try entering it again.",
		description: 'The link the user entered is invalid',
	},
	urlRequiredErrorText: {
		id: 'issue.views.confluence-pages.link.error.url-required',
		defaultMessage: "That URL doesn't look right. Try entering it again.",
		description: 'User sent an incomplete url',
	},
	linkButton: {
		id: 'issue.views.confluence-pages.link.link-button',
		defaultMessage: 'Link',
		description: 'Button text to link the confluence page',
	},
	cancelButton: {
		id: 'issue.views.confluence-pages.link.cancel-button',
		defaultMessage: 'Cancel',
		description: 'Button text to cancel operation and close the field',
	},
	linkCrossOriginWhiteboardFailedTitle: {
		defaultMessage: "We couldn't link this whiteboard",
		id: 'issue.views.confluence-content.whiteboard-link-failed-title',
		description:
			'This error message title will be shown when the user fails to link a Confluence Whiteboard issue to a jira issue.',
	},
	linkCrossOriginWhiteboardFailedDescription: {
		defaultMessage: "We can't link a whiteboard from a Confluence site with a different domain",
		id: 'issue.views.confluence-content.whiteboard-link-failed-description',
		description:
			'This error message description will be shown when the user fails to link a Confluence Whiteboard issue to a jira issue.',
	},
	duplicateErrorTextIssueTermRefresh: {
		id: 'issue.views.confluence-pages.link.error.duplicate-issue-term-refresh',
		defaultMessage: 'That page is already linked to this work item.',
		description: 'Duplicate page add attempt error.',
	},
	duplicateItemErrorTextIssueTermRefresh: {
		id: 'issue.views.confluence-pages.link.error.duplicate-item-issue-term-refresh',
		defaultMessage: 'That item is already linked to this work item.',
		description: 'Duplicate item add attempt error.',
	},
});
