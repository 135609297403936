import React from 'react';
import { AdControlProvider } from '@atlassian/ad-control-toolkit';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import Placeholder from '@atlassian/react-ufo/placeholder';
import { ConfluenceDummyLinkProvider } from '../../controllers/confluence-dummy-link-context/index.tsx';
import type { ConfluenceContentPlaceholderCrossJoinProps } from './confluence-content.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const ConfluenceContentPlaceholderCrossJoinModule = lazy(() =>
	import(
		/* webpackChunkName: "async-confluence-content-placeholder-experiment" */ './confluence-content.tsx'
	).then((module) => module.ConfluenceContentPlaceholderCrossJoin),
);

export const PersistentCrossJoinNudge = (props: ConfluenceContentPlaceholderCrossJoinProps) => (
	<ErrorBoundary
		id="persistent-cross-join-nudge"
		packageName="@atlassian/persistent-cross-join-nudge"
	>
		<ConfluenceDummyLinkProvider>
			<AdControlProvider>
				<Placeholder name="persistent-cross-join-nudges" fallback={null}>
					<ConfluenceContentPlaceholderCrossJoinModule {...props} />
				</Placeholder>
			</AdControlProvider>
		</ConfluenceDummyLinkProvider>
	</ErrorBoundary>
);
