import React from 'react';
import Tabs, { Tab, TabList } from '@atlaskit/tabs';
import { ConditionallyVisibleDiv } from '@atlassian/jira-issue-adjustments/src/ui.tsx';

type TabData = {
	id?: string;
	label: string;
	content: JSX.Element;
	isVisible: boolean;
};

type StaticProps = {
	tabs: TabData[];
};

export const StaticTabsSpotlight = ({ tabs }: StaticProps) => (
	<Tabs
		id="issue-view-layout-templates-tabs-spotlight"
		shouldUnmountTabPanelOnChange
		testId="issue-view-layout-templates-tab-view.ui.static-tabs-spotlight.issue-view-layout-templates-tabs-spotlight"
	>
		<TabList>
			{tabs.map((tab, index) => (
				<ConditionallyVisibleDiv key={tab.id} isVisible={tab.isVisible}>
					<Tab key={index}>{tab?.label}</Tab>
				</ConditionallyVisibleDiv>
			))}
		</TabList>
	</Tabs>
);
