import head from 'lodash/head';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { DeliveryFieldPresentationType } from '@atlassian/jira-polaris-domain-field/src/presentation/types.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { isGlobalSystemField } from '@atlassian/jira-polaris-domain-field/src/field/utils.tsx';
import { createGetFieldByKey } from '../../selectors/field.tsx';
import type { Props, State } from '../../types.tsx';

export const updateDeliveryFieldPresentation =
	(fieldKey: FieldKey, presentation: DeliveryFieldPresentationType) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ issueTypeIds, fieldRemote, createAnalyticsEvent }: Props,
	): Promise<void> => {
		const state = getState();
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const currentField = createGetFieldByKey(fieldKey)(state)!;

		fireTrackAnalytics(createAnalyticsEvent({}), 'issueFields updated', {
			updatedItems: [{ name: 'presentation' }],
			issueFieldKey: currentField.key,
			issueFieldType: currentField.type,
		});

		setState({
			fields: {
				...state.fields,
				[fieldKey]: {
					...state.fields[fieldKey],
					presentation: {
						type: presentation,
					},
				},
			},
		});

		const issueTypeId = head(issueTypeIds);

		if (issueTypeId === undefined) {
			throw new Error('cannot update delivery field presentation for unknown issue type');
		}

		await fieldRemote.updateFieldConfiguration({
			fieldKey,
			issueTypeId,
			oldConfiguration: currentField.presentation
				? {
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						polarisPresentation: currentField.presentation.type as DeliveryFieldPresentationType,
					}
				: {},
			configuration: { polarisPresentation: presentation },
			type: 'updateDeliveryFieldPresentation',
			isGlobalSystemField: isGlobalSystemField(currentField),
		});
	};
