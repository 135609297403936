/** @jsx jsx */
import React, { memo, useRef } from 'react';
import { jsx, css } from '@compiled/react';
import { Inline, xcss, Text } from '@atlaskit/primitives';
import Avatar, {
	AVATAR_SIZES,
	Skeleton,
	type AppearanceType,
	type AvatarPropTypes,
	type SizeType,
} from '@atlaskit/avatar';
import { MediaImage } from '@atlaskit/media-image';
import type { MediaImageWithMediaClientConfigProps } from '@atlaskit/media-image/internal-types';
import { SimpleTag } from '@atlaskit/tag';
import { token } from '@atlaskit/tokens';
import { ColoredTag } from '@atlassian/jira-option-color-picker/src/colored-tag/index.tsx';
import { useShowHoverPopover } from '../../common/controllers/show-hover-popover/index.tsx';
import Link from '../../common/ui/link/index.tsx';
import { HoverPopover } from '../../common/ui/hover-popover/index.tsx';

export type TagItemMediaImageProps = Omit<MediaImageWithMediaClientConfigProps, 'children'>;

type TagItemIconProps = {
	url?: string;
	mediaImage?: TagItemMediaImageProps;
};

type Props = {
	/** The tag name to be displayed to users for the item */
	name: string;
	/** URL to direct users to when they click on the item */
	href?: string;
	/** Optional icon to display next to the item */
	icon?: TagItemIconProps;
	/** Optional boolean to apply strikethrough style to the item */
	shouldDisplayStrikethrough?: boolean;
	/** Optional boolean to check if colors should be added to the item */
	isColored?: boolean;
	/** Optional prop to enable hover popover */
	isHoverPopoverEnabled?: boolean;
};

const AVATAR_SIZE: SizeType = 'xsmall';
const AVATAR_APPEARANCE: AppearanceType = 'square';

const tagMarginFixStyles = css({
	display: 'inline-block',
	marginTop: token('space.negative.050'),
	marginBottom: token('space.negative.050'),
	maxWidth: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'> span': {
		maxWidth: '100%',
	},
});

const tagMarginInlineFixStyles = css({
	marginRight: token('space.negative.050'),
	marginLeft: token('space.negative.050'),
});

const strikethroughStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	a: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
		textDecoration: 'line-through !important',
	},
});

const TagItemAvatar = ({
	src,
	testId = 'list-with-popup.ui.tag-item.avatar',
}: Pick<AvatarPropTypes, 'src' | 'testId'>) => (
	<Avatar
		appearance={AVATAR_APPEARANCE}
		size={AVATAR_SIZE}
		src={src}
		borderColor="transparent"
		testId={testId}
	/>
);

const TagItemMediaImage = memo(
	({ mediaImage, fallbackSrc }: { mediaImage: TagItemMediaImageProps; fallbackSrc?: string }) => (
		<MediaImage
			identifier={mediaImage.identifier}
			mediaClientConfig={mediaImage.mediaClientConfig}
			apiConfig={{
				...mediaImage.apiConfig,
				width: AVATAR_SIZES[AVATAR_SIZE],
				height: AVATAR_SIZES[AVATAR_SIZE],
			}}
		>
			{({ loading, error, data }) => {
				if (loading) {
					return <Skeleton appearance={AVATAR_APPEARANCE} size={AVATAR_SIZE} />;
				}

				if (error || !data?.src) {
					return (
						<TagItemAvatar
							src={fallbackSrc}
							testId="list-with-popup.ui.tag-item.media-image-error"
						/>
					);
				}

				return (
					<TagItemAvatar src={data?.src} testId="list-with-popup.ui.tag-item.media-image-avatar" />
				);
			}}
		</MediaImage>
	),
);

const TagItemIcon = ({ icon }: { icon: TagItemIconProps }) => (
	<>
		{icon.mediaImage ? (
			<TagItemMediaImage mediaImage={icon.mediaImage} fallbackSrc={icon.url} />
		) : (
			<TagItemAvatar src={icon.url} />
		)}
	</>
);

type HoverTagItemProps = {
	name: string;
	href?: string;
	icon?: TagItemIconProps;
	shouldDisplayStrikethrough?: boolean;
	isColored?: boolean;
};

const HoverTagItem = ({
	name,
	href,
	icon,
	shouldDisplayStrikethrough,
	isColored,
}: HoverTagItemProps) => {
	const tagRef = useRef<HTMLButtonElement | null>(null);
	const { hoverAttributes, isHoverPopoverVisible } = useShowHoverPopover({
		hoverRef: tagRef,
		shouldTargetFirstChild: true,
	});

	if (isColored) {
		return (
			<>
				<ColoredTag
					labelName={name}
					href={href}
					actionSubjectId="listWithPopup"
					pressableXcss={extraColoredTagStyles}
					contentXcss={extraColoredTagTextStyles}
					ref={tagRef}
					{...hoverAttributes}
				/>
				{isHoverPopoverVisible && (
					<HoverPopover>
						<Text>{name}</Text>
					</HoverPopover>
				)}
			</>
		);
	}

	return (
		<>
			<span
				data-testid="list-with-popup.ui.tag-item.tag-item"
				css={[
					tagMarginFixStyles,
					shouldDisplayStrikethrough && strikethroughStyles,
					hoverPopoverStyles,
				]}
				{...hoverAttributes}
			>
				<SimpleTag
					elemBefore={icon ? <TagItemIcon icon={icon} /> : undefined}
					text={name}
					href={href}
					ref={tagRef}
					linkComponent={Link}
				/>
				{isHoverPopoverVisible && (
					<HoverPopover>
						<Text>{name}</Text>
					</HoverPopover>
				)}
			</span>
		</>
	);
};

/**
 * An item formatted with an Atlaskit Tag wrapper.
 * It's recommended to use the TagItemPopupContentWrapper with this item component.
 */
export const TagItem = ({
	name,
	href,
	icon,
	shouldDisplayStrikethrough,
	isColored,
	isHoverPopoverEnabled = false,
}: Props) => {
	if (isHoverPopoverEnabled) {
		return (
			<HoverTagItem
				name={name}
				href={href}
				icon={icon}
				shouldDisplayStrikethrough={shouldDisplayStrikethrough}
				isColored={isColored}
			/>
		);
	}

	if (isColored) {
		return (
			<ColoredTag
				labelName={name}
				href={href}
				actionSubjectId="listWithPopup"
				pressableXcss={extraColoredTagStyles}
				contentXcss={extraColoredTagTextStyles}
			/>
		);
	}

	return (
		<span
			data-testid="list-with-popup.ui.tag-item.tag-item"
			css={[
				tagMarginFixStyles,
				tagMarginInlineFixStyles,
				shouldDisplayStrikethrough && strikethroughStyles,
			]}
		>
			<SimpleTag
				elemBefore={icon ? <TagItemIcon icon={icon} /> : undefined}
				text={name}
				href={href}
				linkComponent={Link}
			/>
		</span>
	);
};

export const TagItemPopupContentWrapper = ({ children }: { children: React.ReactNode }) => (
	<Inline space="space.100" shouldWrap>
		{children}
	</Inline>
);

const extraColoredTagTextStyles = xcss({
	maxWidth: '100%',
});

const extraColoredTagStyles = xcss({
	marginInline: 'space.0',
});

const hoverPopoverStyles = css({
	position: 'relative',
});
