import React, { useEffect, useState, type MutableRefObject, type ReactElement } from 'react';
import { ButtonGroup } from '@atlaskit/button';
import Button from '@atlaskit/button/new';
import { Flex, xcss } from '@atlaskit/primitives';
import { useSharedPluginState } from '@atlaskit/editor-common/hooks';
import type { PublicPluginAPI } from '@atlaskit/editor-common/types';
import type { MediaPlugin } from '@atlaskit/editor-plugins/media';
import { useIntl } from '@atlassian/jira-intl';
import messages from '../../messages.tsx';

export type EditorAPI = PublicPluginAPI<[MediaPlugin]>;
export type EditorAPIRef = MutableRefObject<EditorAPI | null>;

export type Props = {
	isInternal: boolean;
	onSave: () => void;
	onCancel: () => void;
	editorApiRef?: EditorAPIRef;
	secondaryToolbarComponents?: ReactElement | ReactElement[];
};

export const CustomSecondaryToolbar = ({
	isInternal,
	onSave,
	onCancel,
	editorApiRef,
	secondaryToolbarComponents,
}: Props) => {
	const { formatMessage } = useIntl();
	const { mediaState } = useSharedPluginState(editorApiRef?.current, ['media']);
	const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

	useEffect(() => {
		if (mediaState) {
			mediaState.subscribeToUploadInProgressState(setSaveButtonDisabled);
		}
		return () => mediaState?.unsubscribeFromUploadInProgressState(setSaveButtonDisabled);
	}, [mediaState]);

	return (
		<Flex
			xcss={actionsStyles}
			testId="issue-view-servicedesk-editor-container.customer-service.custom-secondary-toolbar.flex"
		>
			<ButtonGroup>
				<Button isDisabled={saveButtonDisabled} onClick={onSave} appearance="primary">
					{formatMessage(isInternal ? messages.addNote : messages.replyToCustomer)}
				</Button>
				<Button onClick={onCancel} appearance="subtle">
					{formatMessage(messages.cancel)}
				</Button>
			</ButtonGroup>
			{secondaryToolbarComponents && (
				<Flex gap="space.050">
					{Array.isArray(secondaryToolbarComponents)
						? secondaryToolbarComponents.map((Component) => Component)
						: secondaryToolbarComponents}
				</Flex>
			)}
		</Flex>
	);
};

const actionsStyles = xcss({
	marginTop: 'space.150',
	marginBottom: 'space.150',
	justifyContent: 'space-between',
});
