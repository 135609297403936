import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import without from 'lodash/without';
import type { IssueTypeId, ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { logSafeErrorWithoutCustomerDataWrapper } from '@atlassian/jira-polaris-lib-errors/src/common/utils/index.tsx';
import { performDeleteIssueType } from '../../services/jira/delete-issue-type.tsx';
import type { State } from '../types.tsx';

export const deleteIssueType =
	(issueTypeId: IssueTypeId, projectId: ProjectId) =>
	async ({ setState, getState }: StoreActionApi<State>) => {
		try {
			await performDeleteIssueType(issueTypeId, projectId);

			const state = getState();

			setState({
				issueTypesById: omit(state.issueTypesById, issueTypeId),
				issueTypesByProjectId: mapValues(state.issueTypesByProjectId, (issueTypeIds) =>
					without(issueTypeIds, issueTypeId),
				),
			});
		} catch (error) {
			logSafeErrorWithoutCustomerDataWrapper(
				'polaris.error.controllers.issue-types.actions.delete-issue-type',
				'Failed to delete issue type',
				error instanceof Error ? error : new Error('Error while deleting issue type'),
			);

			throw error;
		}
	};
