import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';

export default function linkChildToParent(parentId: IssueId, childId: IssueId) {
	return performPutRequest(`/rest/api/3/issue/${childId}`, {
		body: JSON.stringify({
			fields: {
				parent: { id: parentId },
			},
		}),
	});
}
