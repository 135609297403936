/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//
export default {
  'fabric.elements.share.copied.to.clipboard.message': '⁣⁢Link copied to clipboard‍⁡⁫⁪‍⁮⁬⁪⁬‌⁯⁬⁫⁡⁪⁪⁬⁠⁡⁣⁤',
  'fabric.elements.share.copylink.button.text': '⁣⁢Copy link⁬⁮⁪‍⁮؜⁡⁮⁭⁡‌⁯⁡⁪⁫⁮‌⁠⁯⁣⁤',
  'fabric.elements.share.copypubliclink.button.text': '⁣⁢Copy public link⁡‌⁯‍⁭⁡⁬⁭⁡⁪⁪⁮⁠؜⁠⁯⁮⁫⁭‌؜‌⁣⁤',
  'fabric.elements.share.failure.icon.label': '⁣⁢Error icon‍؜⁬⁮⁯⁡⁮⁯⁠؜؜‍‌⁪⁪‌‌⁮⁯‍⁡⁫⁪⁠‌⁮⁣⁤',
  'fabric.elements.share.failure.message': '⁣⁢Unable to share⁪⁮‍⁡⁪⁭⁠⁬⁡⁬⁬⁬⁫⁫‌‍⁠⁣⁤',
  'fabric.elements.share.form.comment.label': '⁣⁢Message (optional)‍⁯⁫⁠⁡⁭⁮⁯⁡⁠⁬⁮⁫‌⁭⁬⁠؜⁠⁠‍‍⁯⁣⁤',
  'fabric.elements.share.form.comment.placeholder': '⁣⁢Anything they should know?⁠⁯؜⁡⁡‍⁫⁫⁯⁪⁡‌⁠⁮⁮⁭‍⁬⁮⁪⁬⁠⁪⁭⁣⁤',
  'fabric.elements.share.form.info.message.no.invite.confluence':
    '⁣⁢Recipients will see the name of the page and your message⁯⁠‌‌؜⁠‌⁪⁡؜⁮⁠⁭⁭‌‌⁭‌⁠⁪‌⁣⁤',
  'fabric.elements.share.form.info.message.no.invite.jira':
    '⁣⁢Recipients will see the name of the issue and your message⁬⁬⁯‍⁫‍⁠؜⁡⁭⁪⁫⁪⁭⁮⁭‍⁬⁮⁯⁠⁣⁤',
  'fabric.elements.share.form.info.message.no.invite.jira.issue-term-refresh':
    '⁣⁢Recipients will see the name of the work item and your message؜⁫‍⁫⁭⁠‍‌‌⁭⁠‌⁮⁫⁮⁠⁯⁠⁭⁠⁯⁫⁫⁣⁤',
  'fabric.elements.share.form.no-permissions': '⁣⁢You do not have the ability to share.⁫⁯؜⁫⁭⁬⁯⁯⁬⁠⁮‍‍‍⁫⁮⁬⁪⁡⁠⁡⁭⁮⁣⁤',
  'fabric.elements.share.form.public.send': '⁣⁢Send public link⁮⁫⁭⁮⁬⁠⁯⁫⁯؜⁠‍⁯⁠⁡⁮؜⁯⁭‌⁭⁡⁫⁠⁣⁤',
  'fabric.elements.share.form.public.share': '⁣⁢Share public link⁠⁡‍⁪⁪‍⁯‍⁫‌⁠⁬؜‍⁬‌⁯⁯‍⁭⁡⁡⁭⁡؜⁮⁣⁤',
  'fabric.elements.share.form.required-field.summary':
    '⁣⁢Required fields are marked with an asterisk‍⁬⁠⁯⁪⁯‍⁪⁭⁫⁬‌⁬⁡‌‌⁠‍؜⁫⁫⁣⁤',
  'fabric.elements.share.form.retry': '⁣⁢Retry‌⁫⁯⁭‌⁬⁡؜‌⁬⁬⁪⁬⁬⁪⁬⁪‌⁡⁡⁯‌⁣⁤',
  'fabric.elements.share.form.send': '⁣⁢Send‍⁭‌⁠⁠‍؜‍‌⁭⁯‍⁮⁫⁭⁡⁯⁯⁯⁯؜⁪⁭⁪⁠؜⁣⁤',
  'fabric.elements.share.form.share': '⁣⁢Share⁠‌‍⁪⁭⁫⁬⁠⁬‌⁪‌؜⁠؜؜⁭⁮‌⁣⁤',
  'fabric.elements.share.form.title': '⁣⁢Share⁮⁫⁠⁮⁬؜⁪⁫⁭⁯‌⁯‍؜‍⁮⁠⁮⁯؜⁡⁫‌⁣⁤',
  'fabric.elements.share.form.user-picker.add-more': '⁣⁢Enter more⁠⁠⁡‍⁫⁯⁮⁫‌؜؜‌‍⁠⁬⁪⁫؜⁬‍⁠⁣⁤',
  'fabric.elements.share.form.user-picker.label.confluence': '⁣⁢Names, teams, groups, or emails⁯⁯⁭⁡‍⁮⁠⁡⁭‍⁭‍⁪⁯⁬؜‌‍⁮‍⁣⁤',
  'fabric.elements.share.form.user-picker.label.email-disabled-confluence':
    '⁣⁢Names, teams, or groups⁪⁯⁮‍⁮⁠؜⁯⁬‌⁯؜⁬⁫⁠؜⁠⁮⁡⁪⁣⁤',
  'fabric.elements.share.form.user-picker.label.email-disabled-jira': '⁣⁢Names or teams⁫⁬⁭⁮‌⁪⁭‍⁯‍؜؜⁯⁯؜⁮⁯⁫⁠؜⁠‍⁡⁣⁤',
  'fabric.elements.share.form.user-picker.label.email-only': '⁣⁢Emails؜⁬⁪‌⁪⁯⁠⁫⁯⁪؜⁫⁯⁮؜⁬⁮⁯⁠⁬⁬⁣⁤',
  'fabric.elements.share.form.user-picker.label.jira': '⁣⁢Names, teams, or emails‌⁡⁭⁫‌؜⁯؜⁠‌⁡⁭⁫؜‍⁡⁮؜⁭⁠؜⁠⁫⁯‌⁣⁤',
  'fabric.elements.share.form.user-picker.no-options.existingUserOnly':
    '⁣⁢We couldn’t find any results for "{inputValue}".⁭⁡⁭‌⁭‌⁮⁯⁫؜⁡⁡⁯⁡⁠⁠⁫‍⁭‍⁪⁣⁤',
  'fabric.elements.share.form.user-picker.no-options.generic':
    '⁣⁢We couldn’t find any results for "{inputValue}". Invite people by using an email address.⁡؜⁮؜‍⁠⁪⁪؜⁠⁠⁡⁮؜⁯⁬⁡⁠⁭‍⁫‍⁣⁤',
  'fabric.elements.share.form.user-picker.placeholder.confluence':
    '⁣⁢e.g. Maria, Team Orange, group-one‍⁭⁡‍⁠⁫؜⁬⁮⁪؜⁮‍⁬⁡⁬⁯⁯⁬⁣⁤',
  'fabric.elements.share.form.user-picker.placeholder.email-disabled-jira':
    '⁣⁢e.g. Maria, Team Orange⁮⁬؜⁡⁪⁯⁬‌⁡‍⁭⁯؜⁬⁬⁬؜⁯؜⁪؜⁣⁤',
  'fabric.elements.share.form.user-picker.placeholder.email-only': '⁣⁢e.g. maria@company.com⁫⁪؜⁫⁠⁫⁫⁬⁫‌⁬‍⁬⁯؜⁫⁪⁠‍؜⁯⁮‌؜⁣⁤',
  'fabric.elements.share.form.user-picker.placeholder.jira':
    '⁣⁢e.g. Maria, Team Orange, maria@company.com⁪‌⁡؜⁭⁡⁪‍⁪⁭⁡⁡؜‌‌⁬‍⁪⁬⁠⁭⁫⁡⁣⁤',
  'fabric.elements.share.form.user-picker.validation.required':
    '⁣⁢Select at least one user, group, team or email.⁮⁪⁯⁡⁭⁡؜⁫⁯‌‍⁭⁫⁪⁯‌⁫⁠؜⁫⁣⁤',
  'fabric.elements.share.form.user-picker.validation.required-message.confluence':
    '⁣⁢Select at least one person, team, group, or email⁫⁮⁭‍‌⁭⁠⁫⁬⁬⁡⁭⁯‌‍⁯‌⁪⁭⁡⁠‍⁣⁤',
  'fabric.elements.share.form.user-picker.validation.required-message.email-disabled-confluence':
    '⁣⁢Select at least one person, team, or group⁬⁯؜⁭⁭⁪‌⁮⁠⁭⁪⁪⁠‌⁪‌⁮⁡⁣⁤',
  'fabric.elements.share.form.user-picker.validation.required-message.email-disabled-jira':
    '⁣⁢Select at least one person or team⁪⁯‍⁭⁯⁫⁬⁮⁠‍⁡⁪⁮⁯⁠⁪⁬‌⁡⁣⁤',
  'fabric.elements.share.form.user-picker.validation.required-message.email-only':
    '⁣⁢Select at least one email⁯⁭‍⁫⁫⁭‌‌⁯‍‍⁠⁡⁯؜⁯‍⁡⁪‌‌⁣⁤',
  'fabric.elements.share.form.user-picker.validation.required-message.jira':
    '⁣⁢Select at least one person, team, or email⁠⁠⁪‌‌؜⁬‍‍‍⁠‌⁬⁪⁭⁭⁮‍‍؜‌⁠⁪⁫⁡⁪⁠⁣⁤',
  'fabric.elements.share.in.integration.button': '⁣⁢Share in {integrationName}⁠؜⁪⁡⁬⁭⁡⁭‌⁫⁪⁬⁡؜‌⁮⁪⁯⁬‌⁬‌⁭⁣⁤',
  'fabric.elements.share.invite.to.confluence': '⁣⁢Invite to Confluence⁪⁮⁡⁭⁫⁪⁯؜‍⁫⁪‍⁠⁭‍⁡⁠⁡⁮⁬؜‍‍⁣⁤',
  'fabric.elements.share.invite.to.jira': '⁣⁢Invite to Jira؜⁠⁡⁯⁬⁠‌⁮⁮؜⁠⁮⁠‍⁫⁯‍⁫⁡⁬⁣⁤',
  'fabric.elements.share.main.tab.text.confluence': '⁣⁢Share page⁪⁯⁭⁬⁬⁪⁬⁯‌‍‌‍⁠⁫⁡؜⁮‌⁮⁣⁤',
  'fabric.elements.share.main.tab.text.jira': '⁣⁢Share issue؜⁭‍⁮‍⁬؜‍⁬⁯⁡⁠⁠⁪⁮؜‍⁣⁤',
  'fabric.elements.share.menu.email.label': '⁣⁢Email⁮‍⁫⁡⁬؜⁪⁪‌‌؜‌‌⁬⁭‌⁪⁯⁡⁡⁮⁡⁮⁬⁪⁪⁣⁤',
  'fabric.elements.share.menu.slack.label': '⁣⁢Slack⁫‌⁫؜⁭؜‍⁠‍⁬⁯‌⁡⁯‍⁮‌⁬⁠⁠⁣⁤',
  'fabric.elements.share.popup.label': '⁣⁢Share⁭⁭⁫⁬‍‌⁬⁬⁫⁪⁡‌⁯⁪‌⁮⁮‌‍‍⁬⁣⁤',
  'fabric.elements.share.success.message':
    '⁣⁢{object, select,blogpost {Blog post shared}board {Board shared}calendar {Calendar shared}draft {Draft shared}filter {Filter shared}issue {Issue shared}summary {Summary shared}list {List shared}timeline {Timeline shared}form {Form shared}media {Media shared}page {Page shared}project {Project shared}pullrequest {Pull Request shared}question {Question shared}report {Report shared}repository {Repository shared}request {Request shared}roadmap {Timeline shared}site {Site shared}space {Space shared}other {Link Shared}}⁪⁯؜⁯⁪‌⁬⁯⁯⁬⁬⁬⁭⁬⁪⁮⁠‍؜⁮⁭⁣⁤',
  'fabric.elements.share.to.integration.button': '⁣⁢Share to {integrationName}⁭‌⁬⁮⁮⁭⁪⁡‌⁠⁯⁡⁡⁡⁠‍‍⁪؜‌⁫⁡⁣⁤',
  'fabric.elements.share.trigger.button.icon.label': '⁣⁢Share icon‌⁬‍⁮⁯⁫⁯؜‍⁬⁯‍⁭؜⁪‌⁪⁫⁡⁣⁤',
  'fabric.elements.share.trigger.button.invite.text': '⁣⁢Invite⁡‍⁪⁫⁭‍⁯⁠⁫⁫⁮؜⁡‌⁮⁠؜؜⁮؜⁭⁪؜⁣⁤',
  'fabric.elements.share.trigger.button.text': '⁣⁢Share⁫⁪‌⁭⁭⁡‍⁯‌⁬‍⁪⁫⁯؜؜⁮⁡‍⁫⁣⁤',
  'fabric.elements.share.trigger.button.tooltip.text': '⁣⁢Share‌⁯⁡⁪‌؜‌‌⁪⁯⁭⁠؜⁡⁭⁯⁮⁭‌‌‍⁪⁮⁪‌⁬⁪⁣⁤',
};
