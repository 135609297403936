import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { PlaceholderTargeting } from '../controllers/targeting.tsx';
import type { Sources } from '../common.tsx';
import {
	getPlaceholderTemplateUserProperty,
	setPlaceholderTemplateUserProperty,
} from './user-properties.tsx';

const getPropertyKey = (targeting: PlaceholderTargeting, source: Sources) => {
	if (source === 'issueView') {
		return targeting === 'CoUse'
			? 'hasDismissedConfluenceDummyLinkCoUse'
			: 'hasDismissedConfluenceDummyLink';
	}
	return `hasDismissed${source}`;
};

export const getHasDismissedConfluencePlaceholderTemplate = async (
	accountId: AccountId,
	targeting: PlaceholderTargeting,
	source: Sources,
) => {
	const propertyKey = getPropertyKey(targeting, source);
	return getPlaceholderTemplateUserProperty(accountId, propertyKey);
};

export const dismissConfluencePlaceholderTemplate = async (
	accountId: AccountId,
	targeting: PlaceholderTargeting,
	source: Sources,
) => {
	const propertyKey = getPropertyKey(targeting, source);
	setPlaceholderTemplateUserProperty(accountId, propertyKey);
};
