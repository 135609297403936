import React, { type ReactNode, useRef, useEffect, useCallback } from 'react';

type Props = {
	onVisibilityChangeInViewPort: (isVisible: boolean) => void;
	children?: ReactNode;
};

const VISIBILITY_THRESHOLD = [0.09, 0.16, 0.23];

export const IntersectionObserverWrapper: React.FC<Props> = ({
	onVisibilityChangeInViewPort,
	children,
}) => {
	const activityFeedContainerRef = useRef<HTMLSpanElement>(null);

	const calculateVisibility = useCallback((entry: IntersectionObserverEntry) => {
		const { intersectionRect, boundingClientRect } = entry;
		const visibleHeight = intersectionRect.height;
		const totalHeight = boundingClientRect.height;
		return visibleHeight / totalHeight;
	}, []);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const observer = new IntersectionObserver(
				([entry]) => {
					const visiblePercentage = calculateVisibility(entry);
					onVisibilityChangeInViewPort(
						VISIBILITY_THRESHOLD.some((threshold) => visiblePercentage >= threshold),
					);
				},
				{
					root: null,
					rootMargin: '0px',
					threshold: VISIBILITY_THRESHOLD, // Change this value to adjust the visibility threshold
				},
			);

			const currentRef = activityFeedContainerRef.current;

			if (currentRef) {
				observer.observe(currentRef);
			}

			return () => {
				if (currentRef) {
					observer.unobserve(currentRef);
				}
			};
		}
	}, [onVisibilityChangeInViewPort, calculateVisibility]);

	/**
	 * We use a span element to attach the IntersectionObserver and a reference to it. Span doesn't interfere with the existing layout.
	 */
	return (
		<span
			ref={activityFeedContainerRef}
			data-testid="issue-activity-feed.feed-display-with-intersection-observer"
		>
			{children}
		</span>
	);
};
