import { useCallback } from 'react';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { getAriConfig } from '@atlassian/jira-platform-ari/src/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { CONFLUENCE } from '@atlassian/jira-shared-types/src/application-key.tsx';
import { InviteCapabilities, type InvitationCapabilitiesV3Response } from './types.tsx';

export const getInvitationCapabilitiesV3Url = (cloudId: string): string =>
	`/gateway/api/v3/invitations/capabilities?resource=ari:cloud:platform::site/${cloudId}`;

export const useCanInvite = () =>
	useCallback((cloudId: string, product: string) => canInvite(cloudId, product), []);

export const canInvite = async (cloudId: string, product: string): Promise<boolean> => {
	const capabilities = await fetchJson<InvitationCapabilitiesV3Response[]>(
		getInvitationCapabilitiesV3Url(cloudId),
	);

	const capability = capabilities.find((cap) => {
		const ari = getAriConfig(cap.resourceARI);
		return ari.resourceOwner === product;
	});

	if (!capability) return false;

	return (
		capability.directInvite?.mode === 'ANYONE' ||
		capability.invitePendingApproval?.mode === 'ANYONE'
	);
};

export const useInviteCapability = () => {
	const cloudId = useCloudId();
	return useCallback(async () => {
		const capabilities = await fetchJson<InvitationCapabilitiesV3Response[]>(
			getInvitationCapabilitiesV3Url(cloudId),
		);

		const capability = capabilities.find((cap) => {
			const ari = getAriConfig(cap.resourceARI);
			return ari.resourceOwner === CONFLUENCE;
		});

		if (capability?.directInvite.mode === 'ANYONE') return InviteCapabilities.DIRECT_INVITE;
		if (capability?.invitePendingApproval.mode === 'ANYONE')
			return InviteCapabilities.INVITE_PENDING_APPROVAL;

		return InviteCapabilities.NONE;
	}, [cloudId]);
};
