import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const asyncRecurWorkFormEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-recur-work-form" */ './src/ui/RecurWorkForm').then(
			({ RecurWorkForm }) => RecurWorkForm,
		),
	),
	getPreloadProps: () => ({}),
});
