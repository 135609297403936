import memoizeOne from 'memoize-one';

import { ConnectionHandler, type RecordSourceSelectorProxy } from 'relay-runtime';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { traverse } from '@atlaskit/adf-utils/traverse';
import RelayDataID from '@atlassian/relay-data-id';
import type { DismissTemplate } from '@atlassian/jira-automation-discoverability/src/common/types.tsx';
import { WORD_TEMPLATE_MAP } from './constants.tsx';
import type { WordMap, IssueTemplates } from './types.tsx';

const NODE_TYPE = 'JiraIssueViewPanelAutomationDiscoverabilityDismissedTemplateType';
const EDGE_TYPE = 'JiraIssueViewPanelAutomationDiscoverabilityDismissedTemplateTypeEdge';

export const getTemplateFromADF = (doc: ADF, wordMap: WordMap): IssueTemplates | null => {
	let template: IssueTemplates | null = null;
	traverse(doc, {
		text: (node) => {
			const match = getTemplateFromText(node.text || '', wordMap);
			// check for first match
			if (match && !template) {
				template = match;
			}
		},
	});
	return template;
};
export const getTemplateFromText = (main: string, wordMap: WordMap): IssueTemplates | null => {
	const match = Object.entries(wordMap).find(([word]) => {
		const regex = new RegExp(word, 'gmi');
		return regex.test(main);
	});
	if (match) {
		return match[1];
	}
	return null;
};
export const getTemplate = memoizeOne((summary: string, desc: ADF): IssueTemplates | null => {
	const templateSummary = getTemplateFromText(summary, WORD_TEMPLATE_MAP);
	if (templateSummary) {
		return templateSummary;
	}
	// check for desc match only if not in summary
	const templateDesc = getTemplateFromADF(desc, WORD_TEMPLATE_MAP);
	return templateDesc;
});
export const updateDismissCache = (
	store: RecordSourceSelectorProxy,
	connectionId: string,
	cloudId: string,
	newDismissTemplate: DismissTemplate,
) => {
	const connection = store.get(connectionId);
	const edges = connection?.getLinkedRecords('edges');
	if (connection && edges) {
		const existingEdge = edges.find(
			(edge) =>
				edge?.getLinkedRecord('node')?.getValue('templateId') === newDismissTemplate.templateId,
		);

		if (existingEdge) {
			const node = existingEdge.getLinkedRecord('node');
			node?.setValue(newDismissTemplate.dismissUntilDateTime, 'dismissUntilDateTime');
			return;
		}
		const nodeId = getNodeId(cloudId, edges.length);

		let newNode = store.get(nodeId);

		if (!newNode) {
			newNode = store.create(nodeId, NODE_TYPE);
		}
		newNode.setValue(newDismissTemplate.templateId, 'templateId');
		newNode.setValue(newDismissTemplate.dismissUntilDateTime, 'dismissUntilDateTime');

		const newEdge = ConnectionHandler.createEdge(
			store,
			connection,
			newNode,
			EDGE_TYPE /* GraphQl Type for edge */,
		);
		ConnectionHandler.insertEdgeAfter(connection, newEdge);
	}
};
const getNodeId = (cloudId: string, edges: number) => {
	const id = `edges:${edges}:node`;
	return RelayDataID({ id }, NODE_TYPE) ?? id;
};
