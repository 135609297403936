import React from 'react';
import { Inline, Box, xcss } from '@atlaskit/primitives';
import { LabelIcon } from '@atlassian/jira-servicedesk-customer-service-common/src/ui/label-icon/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import messages from './messages.tsx';

type Props = {
	name?: string;
};

const TextWrapper = ({ children }: { children: React.ReactNode }) => {
	return fg('jcs-issue-view-truncate-reporter') ? (
		<Box xcss={textStyles}>{children}</Box>
	) : (
		<>{children}</>
	);
};

export const ReadView = ({ name }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<Inline
			space="space.100"
			alignBlock="center"
			xcss={[
				fg('jcs-issue-view-truncate-reporter') ? containerStyles : containerStylesOld,
				!name && placeholderStyles,
			]}
			testId="servicedesk-customer-service-custom-fields.common.ui.organization-read-view.inline"
		>
			<LabelIcon size="xsmall" type="ORGANIZATION" />
			<TextWrapper>{name || formatMessage(messages.none)}</TextWrapper>
		</Inline>
	);
};

const containerStylesOld = xcss({
	margin: 'space.050',
	overflowWrap: 'anywhere',
	paddingLeft: 'space.100',
});

const containerStyles = xcss({
	paddingInline: 'space.100',
	paddingBlock: 'space.050',
	display: 'flex',
	alignItems: 'center',
	width: '100%',
});

const textStyles = xcss({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	minWidth: '0px',
	flexGrow: 1,
});

const placeholderStyles = xcss({
	color: 'color.text.subtlest',
});
