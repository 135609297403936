import has from 'lodash/has';
import reject from 'lodash/reject';
import { createAri, getAriConfig } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { Insight } from '@atlassian/jira-polaris-domain-insight/src/insight/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { getLocalIssueIdsByJiraIssueId } from '../../../selectors/issue-ids.tsx';
import type { Props, State } from '../../../types.tsx';

export const deleteInsightFromRemote =
	(insightIdAsNumber: number, jiraIssueId: number) =>
	({ getState, setState }: StoreActionApi<State>, props: Props) => {
		const state = getState();
		const localIdMap = getLocalIssueIdsByJiraIssueId(state, props);

		const { cloudId } = props;

		// sadly, there is a terrible inconsistency.  Our ARIs for events were ari:cloud:jira-polaris:...:insight/nnn
		// (we are no longer emitting events on those channels, for different reasons) but our internal objects
		// are ari:cloud:jira:...:polaris-insight/nnn
		// See POL-21 :'(
		const insightId = createAri({
			resourceOwner: 'jira',
			cloudId,
			resourceType: 'polaris-insight',
			resourceId: String(insightIdAsNumber),
		});

		if (!has(localIdMap, jiraIssueId)) {
			return;
		}

		const original = state.properties.insights[localIdMap[jiraIssueId]] || [];
		const trimmed = reject(original, ({ id }) => id === insightId);

		setState({
			properties: {
				...state.properties,
				insights: {
					...state.properties.insights,
					[localIdMap[jiraIssueId]]: trimmed,
				},
			},
		});
	};

export const deleteInsight =
	(insight: Insight) =>
	({ getState, setState }: StoreActionApi<State>, props: Props) => {
		const state = getState();
		const localIdMap = getLocalIssueIdsByJiraIssueId(state, props);

		if (!insight.container) {
			return;
		}

		const { resourceId } = getAriConfig(insight.container);

		if (!has(localIdMap, resourceId)) {
			return;
		}

		setState({
			properties: {
				...state.properties,
				insights: {
					...state.properties.insights,
					[localIdMap[resourceId]]: reject(
						state.properties.insights[localIdMap[resourceId]] || [],
						({ id }) => id === insight.id,
					),
				},
			},
		});
	};
