/** @jsx jsx */
import React from 'react';
import { css, keyframes, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';

export const HoverPopover = ({
	children,
	isContentInteractable,
	isOldTagItem,
}: {
	children: React.ReactNode;
	isContentInteractable?: boolean;
	isOldTagItem?: boolean;
}) => (
	<div css={[containerStyles, isContentInteractable ? paddingTopStyles : marginTopStyles]}>
		<div css={[popoverStyles, isOldTagItem ? oldTagItemMarginStyles : newTagItemMarginStyles]}>
			{children}
		</div>
	</div>
);

const fadeIn = keyframes({
	from: {
		opacity: '0',
	},
	to: {
		opacity: '100',
	},
});

const containerStyles = css({
	position: 'absolute',
	top: '100%',
	left: '0',
	zIndex: 9999, // Tooltip z-index
	animationName: `${fadeIn}`,
	opacity: '0',
	animationDuration: '0.1s',
	animationTimingFunction: 'ease-in',
	animationFillMode: 'forwards',
});

const paddingTopStyles = css({
	paddingTop: token('space.100'),
});

const marginTopStyles = css({
	marginTop: token('space.050'),
});

const popoverStyles = css({
	paddingInline: token('space.050'),
	paddingBlock: token('space.050'),
	borderRadius: token('border.radius'),
	boxShadow: token('elevation.shadow.overlay'),
	overflowY: 'scroll',
	width: 'max-content',
	maxWidth: '300px',
	maxHeight: '400px',
	backgroundColor: token('elevation.surface.overlay'),
	textAlign: 'left',
});

const oldTagItemMarginStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'> *': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
		margin: '0px !important',
	},
});

const newTagItemMarginStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'> *': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
		marginBlock: '4px !important',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
		marginInline: '4px !important',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
		display: 'inline-flex !important',
	},
});
