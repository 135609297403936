import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type {
	SaveRemoteIssueLinkPayload,
	SaveRemoteIssueLinkResponse,
} from '@atlassian/jira-issue-shared-types/src/common/types/remote-issue-link-add-type.tsx';
import type { Application } from '@atlassian/jira-shared-types/src/application.tsx';
import type { ApplicationEdition } from '@atlassian/jira-shared-types/src/edition.tsx';

type HandleError = (analyticsSource: string, error?: Error) => void;
type HandleErrorOld = (analyticsSource: string, errorMessage?: string) => void;

export const DELETE_REMOTE_LINKED_ISSUE_REQUEST = 'DELETE_REMOTE_LINKED_ISSUE_REQUEST' as const;

export const deleteRemoteLinkedIssueRequest = (
	globalId: string,
	application: Application | undefined,
	edition: ApplicationEdition | undefined,
	analyticsEvent?: UIAnalyticsEvent,
) => ({
	type: DELETE_REMOTE_LINKED_ISSUE_REQUEST,
	payload: {
		globalId,
		analyticsEvent,
		application,
		edition,
	},
});

export type DeleteRemoteLinkedIssueRequest = ReturnType<typeof deleteRemoteLinkedIssueRequest>;

export const DELETE_REMOTE_LINKED_ISSUE_SUCCESS = 'DELETE_REMOTE_LINKED_ISSUE_SUCCESS' as const;

export const deleteRemoteLinkedIssueSuccess = (globalId: string) => ({
	type: DELETE_REMOTE_LINKED_ISSUE_SUCCESS,
	payload: globalId,
});

export type DeleteRemoteLinkedIssueSuccess = ReturnType<typeof deleteRemoteLinkedIssueSuccess>;

export const DELETE_REMOTE_LINKED_ISSUE_FAILURE = 'DELETE_REMOTE_LINKED_ISSUE_FAILURE' as const;

export const deleteRemoteLinkedIssueFailure = (globalId: string) => ({
	type: DELETE_REMOTE_LINKED_ISSUE_FAILURE,
	payload: globalId,
});

export type DeleteRemoteLinkedIssueFailure = ReturnType<typeof deleteRemoteLinkedIssueFailure>;

export const SAVE_REMOTE_LINKED_ISSUE_REQUEST = 'SAVE_REMOTE_LINKED_ISSUE_REQUEST' as const;

export const saveRemoteLinkedIssueRequest = (
	saveRemoteIssueLinkPayload: SaveRemoteIssueLinkPayload,
	requestAnalyticsEvent: UIAnalyticsEvent,
	analyticsEvent: UIAnalyticsEvent,
	onSuccess: (() => void) | undefined,
	onError: HandleError | HandleErrorOld | undefined,
) => ({
	type: SAVE_REMOTE_LINKED_ISSUE_REQUEST,
	payload: {
		saveRemoteIssueLinkPayload,
		requestAnalyticsEvent,
		analyticsEvent,
		onSuccess,
		onError,
	},
});

export type SaveRemoteLinkedIssueRequest = ReturnType<typeof saveRemoteLinkedIssueRequest>;

export const SAVE_REMOTE_LINKED_ISSUE_SUCCESS = 'SAVE_REMOTE_LINKED_ISSUE_SUCCESS' as const;

export const saveRemoteLinkedIssueSuccess = (
	optimisticId: string,
	saveRemoteLinkResponse: SaveRemoteIssueLinkResponse,
	analyticsEvent: UIAnalyticsEvent,
) => ({
	type: SAVE_REMOTE_LINKED_ISSUE_SUCCESS,
	payload: { optimisticId, saveRemoteLinkResponse, analyticsEvent },
});

export type SaveRemoteLinkedIssueSuccess = ReturnType<typeof saveRemoteLinkedIssueSuccess>;

export const SAVE_REMOTE_LINKED_ISSUE_FAILURE = 'SAVE_REMOTE_LINKED_ISSUE_FAILURE' as const;

export const saveRemoteLinkedIssueFailure = (
	optimisticId: string,
	remoteIssueKey: string,
	error: FetchError,
	analyticsEvent: UIAnalyticsEvent,
) => ({
	type: SAVE_REMOTE_LINKED_ISSUE_FAILURE,
	payload: { optimisticId, error, analyticsEvent, remoteIssueKey },
});

export type SaveRemoteLinkedIssueFailure = ReturnType<typeof saveRemoteLinkedIssueFailure>;

export const SAVE_REMOTE_LINKED_ISSUE_RETRY = 'SAVE_REMOTE_LINKED_ISSUE_RETRY' as const;

export const saveRemoteLinkedIssueRetry = (
	optimisticId: string,
	requestAnalyticsEvent: UIAnalyticsEvent,
	analyticsEvent: UIAnalyticsEvent,
	onSuccess: () => void,
	onError: HandleError | HandleErrorOld,
) => ({
	type: SAVE_REMOTE_LINKED_ISSUE_RETRY,
	payload: { optimisticId, requestAnalyticsEvent, analyticsEvent, onSuccess, onError },
});

export type SaveRemoteLinkedIssueRetry = ReturnType<typeof saveRemoteLinkedIssueRetry>;

export const SAVE_REMOTE_LINKED_ISSUE_CANCEL = 'SAVE_REMOTE_LINKED_ISSUE_CANCEL' as const;

export const saveRemoteLinkedIssueCancel = (optimisticId: string) => ({
	type: SAVE_REMOTE_LINKED_ISSUE_CANCEL,
	payload: optimisticId,
});

export type SaveRemoteLinkedIssueCancel = ReturnType<typeof saveRemoteLinkedIssueCancel>;

export type RemoteIssueLinksAction =
	| DeleteRemoteLinkedIssueRequest
	| DeleteRemoteLinkedIssueFailure
	| DeleteRemoteLinkedIssueSuccess
	| SaveRemoteLinkedIssueRequest
	| SaveRemoteLinkedIssueSuccess
	| SaveRemoteLinkedIssueFailure
	| SaveRemoteLinkedIssueRetry
	| SaveRemoteLinkedIssueCancel;
