import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	filterBy: {
		id: 'smart-related-issues-panel.status-tabs.filter-by',
		defaultMessage: 'Show:',
		description: 'Prompt the user to show the selected Related Issues results',
	},
	open: {
		id: 'smart-related-issues-panel.status-tabs.open',
		defaultMessage: 'Open',
		description: "The 'Open' issue status, shown as a label of a button as part of a tab group.",
	},
	openWithCount: {
		id: 'smart-related-issues-panel.status-tabs.open-count',
		defaultMessage: '{count} Open',
		description:
			"The 'Open' issue status with count, shown as a label of a button as part of a tab group.",
	},
	resolved: {
		id: 'smart-related-issues-panel.status-tabs.resolved',
		defaultMessage: 'Resolved',
		description:
			"The 'Resolved' issue status, shown as a label of a button as part of a tab group.",
	},
	resolvedWithCount: {
		id: 'smart-related-issues-panel.status-tabs.resolved-count',
		defaultMessage: '{count} Resolved',
		description:
			"The 'Resolved' issue status with count, shown as a label of a button as part of a tab group.",
	},
	openAriaLabel: {
		id: 'smart-related-issues-panel.status-tabs.open-aria-label',
		defaultMessage: 'Show open issues',
		description: "The aria label for 'Open' button.",
	},
	resolvedAriaLabel: {
		id: 'smart-related-issues-panel.status-tabs.resolved-aria-label',
		defaultMessage: 'Show resolved issues',
		description: "The aria label for 'Resolved' button.",
	},
	openAriaLabelIssueTermRefresh: {
		id: 'smart-related-issues-panel.status-tabs.open-aria-label-issue-term-refresh',
		defaultMessage: 'Show open work items',
		description: "The aria label for 'Open' button.",
	},
	resolvedAriaLabelIssueTermRefresh: {
		id: 'smart-related-issues-panel.status-tabs.resolved-aria-label-issue-term-refresh',
		defaultMessage: 'Show resolved work items',
		description: "The aria label for 'Resolved' button.",
	},
});
