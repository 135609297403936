export const QUICK_EDIT_PLUGIN_WRM_KEY = 'wrc!com.atlassian.jira.jira-quick-edit-plugin:quick-form';

const CONNECT_PLUGIN_WRM_KEY =
	'wrc!com.atlassian.plugins.atlassian-connect-plugin:jira-atlassian-connect-resources-v5';

const SIDEBAR_PLUGIN_WRM_KEY = 'wrc!com.atlassian.jira.jira-atlaskit-plugin:sidebar';

export const EXCLUDED_KEYS = [
	QUICK_EDIT_PLUGIN_WRM_KEY,
	CONNECT_PLUGIN_WRM_KEY,
	SIDEBAR_PLUGIN_WRM_KEY,
] as const;
