/* TODO(@compiled/react codemod): "ThemeProvider" is not exported from "@compiled/react" at the moment. Please find an alternative for it. */
// This component exists as a layer between @atlaskit/modal-dialog and @atlaskit/page/grid
// There is collisions between who owns the padding between these 2 components, so
// we can isolate any necessary overrides within this file until the responsibilities
// are resolved
import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, jira/restricted/styled-components, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { ThemeProvider } from 'styled-components';
import { Grid } from '@atlaskit/page';
import { token } from '@atlaskit/tokens';
import { ConditionalEditFieldConfigContextProvider } from '@atlassian/jira-issue-field-edit-field-config-context/src/context.tsx';
import { gridPaddingOverrideSelectorName } from './styled.tsx';

export const issuePageGridColumns = 10;

const theme = {
	columns: issuePageGridColumns,
} as const;

type Props = {
	isCompact?: boolean;
	hasMultipleScrollingColumns?: boolean;
	children: ReactNode;
	issueKey: string;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	{ children, isCompact = false, hasMultipleScrollingColumns, issueKey }: Props, // ThemeProvider is needed here as we are using Grid without a parent Page that usually adds the ThemeProvider
) => (
	<ThemeProvider theme={theme}>
		<ConditionalEditFieldConfigContextProvider issueKey={issueKey}>
			<GridPaddingOverride
				isCompact={isCompact}
				hasMultipleScrollingColumns={hasMultipleScrollingColumns}
				data-component-selector={gridPaddingOverrideSelectorName}
			>
				<Grid spacing="comfortable" layout="fluid">
					{children}
				</Grid>
			</GridPaddingOverride>
		</ConditionalEditFieldConfigContextProvider>
	</ThemeProvider>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GridPaddingOverride = styled.div<{
	hasMultipleScrollingColumns?: boolean;
	isCompact: boolean;
}>(
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'> div': {
			/* Targets <Grid> element */
			padding: 0,
			/* When we render the LinkedIssueModal in IssueLinksView, extra margin was added to the LinkedIssueModal's grids,
			 * as they are considered nested grid. Currently there is no way to change the "isNestedGrid" property of the
			 * theme, and it would not be logically correct to render the LinkedIssueModal outside of IssueLinksView. So we
			 * reset the margin here. */
			margin: 0,
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ hasMultipleScrollingColumns, isCompact }) =>
		hasMultipleScrollingColumns
			? {
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					'> div': {
						flexWrap: 'nowrap',
						height: '100%',
						/* targets Grid element of @atlaskit/page, which has align-items: flex-start by default */
						alignItems: 'initial',
					},
				}
			: {
					paddingLeft: `${isCompact ? 0 : token('space.050')}`,
					/* Targets <GridColumn> element */
					'> div > div': {
						margin: '0',
						maxWidth: '100%',
					},
				},
);
