import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FeedbackComment = styled.div({
	maxWidth: '220px',
	paddingRight: token('space.100'),
	marginRight: token('space.negative.200'),
	marginBottom: token('space.100'),
	maxHeight: '240px',
	overflow: 'auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&::-webkit-scrollbar': {
		width: '8px',
		background: 'transparent',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&::-webkit-scrollbar-thumb': {
		// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
		background: '#c1c1c1',
		borderRadius: '10px',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FeedbackDate = styled.div<{ hasComment: boolean }>(
	{
		height: '20px',
		color: token('color.text.subtlest'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		!props.hasComment && {
			margin: `${token('space.negative.200')} 0`,
			padding: `${token('space.150')} 0`,
		},
);
