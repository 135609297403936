// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';

export const batchStateUpdates =
	<TContainerProps, TState>(cb: (api: StoreActionApi<TState>, props: TContainerProps) => void) =>
	(api: StoreActionApi<TState>, containerProps: TContainerProps) => {
		let finalized = false;
		let localState = api.getState();
		const setState: StoreActionApi<TState>['setState'] = (newState) => {
			if (finalized) {
				api.setState(newState);
				return;
			}
			localState = { ...localState, ...newState };
		};
		const getState: StoreActionApi<TState>['getState'] = () =>
			finalized ? api.getState() : localState;
		const dispatch: StoreActionApi<TState>['dispatch'] = (action) =>
			finalized
				? action(api, containerProps)
				: action({ dispatch, setState, getState }, containerProps);
		const patchedApi = { setState, getState, dispatch };
		cb(patchedApi, containerProps);
		finalized = true;
		api.setState({ ...localState });
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
		localState = null as any;
	};
