import type { EntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createActionsHook,
	type Action,
	createStateHook,
} from '@atlassian/react-sweet-state';

export type State = {
	entrypointTriggerRef: EntryPointButtonTrigger;
};

type Actions = {
	setData: (data: Partial<State>) => Action<State>;
};

const initialState: State = {
	entrypointTriggerRef: () => undefined,
};

const Store = createStore<State, Actions>({
	initialState,
	actions: {
		setData:
			(data: Partial<State>) =>
			({ setState }) => {
				setState(data);
			},
	},
});

export const useRelatedResourcesEntrypointStoreActions = createActionsHook(Store);

export const useRelatedConfluencePagesTriggerRef = createStateHook(Store, {
	selector: (state) => state.entrypointTriggerRef,
});
