import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	empty: {
		id: 'issue-field-single-select-editview-full.single-select.empty',
		defaultMessage: 'No matches found',
		description: 'Message when no options are found in a select',
	},
	failedFetch: {
		id: 'issue-field-single-select-editview-full.single-select.failed-fetch',
		defaultMessage: 'Search failed.',
		description: 'Message when fetching suggestions fails',
	},
	loading: {
		id: 'issue-field-single-select-editview-full.single-select.loading',
		defaultMessage: 'Searching...',
		description: 'Message when options are being loaded from the server.',
	},
	placeholder: {
		id: 'issue-field-single-select-editview-full.single-select.placeholder',
		defaultMessage: 'Select {fieldName}',
		description: 'Placeholder message for when no value selected',
	},
	queryError: {
		id: 'issue-field-single-select-editview-full.single-select.query-error',
		defaultMessage: 'Search failed.',
		description: 'Message when fetching suggestions fails',
	},
	updateFieldErrorTitle: {
		id: 'issue-field-single-select-editview-full.single-select.update-field-error-title.non-final',
		defaultMessage: 'Unable to update {fieldName}',
		description: 'Error title informing the user that updating the field failed',
	},
	updateFieldErrorMessage: {
		id: 'issue-field-single-select-editview-full.single-select.update-field-error-message.non-final',
		defaultMessage:
			'Check your connection, then give it another try. You could try updating the field by going to issue types in your project settings.',
		description: 'Error message informing the user that updating the field failed',
	},
	updateFieldErrorMessageIssueTermRefresh: {
		id: 'issue-field-single-select-editview-full.single-select.update-field-error-message-issue-term-refresh.non-final',
		defaultMessage:
			'Check your connection, then give it another try. You could try updating the field by going to work types in your project settings.',
		description: 'Error message informing the user that updating the field failed',
	},
});
