/**
 * @generated SignedSource<<9e1ec4caf9533988f2ba650b501cd70f>>
 * @relayHash 643e893473d6da86f9c160341adc3774
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d02d80792cb6231b24c01fdd22b07c504eca0e77eee873d4c5b3368c867a754e

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraSingleValueFieldOperations = "SET" | "%future added value";
export type JiraUpdateSingleSelectFieldInput = {
  id: string;
  operation: JiraSingleSelectOperationInput;
};
export type JiraSingleSelectOperationInput = {
  fieldOption?: string | null | undefined;
  id?: string | null | undefined;
  operation: JiraSingleValueFieldOperations;
};
export type singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectField_Mutation$variables = {
  input: JiraUpdateSingleSelectFieldInput;
};
export type singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectField_Mutation$data = {
  readonly jira: {
    readonly updateSingleSelectField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly " $fragmentSpreads": FragmentRefs<"singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditViewOld_fragmentRef">;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectField_Mutation$rawResponse = {
  readonly jira: {
    readonly updateSingleSelectField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly fieldConfig: {
          readonly isEditable: boolean | null | undefined;
        } | null | undefined;
        readonly fieldId: string;
        readonly fieldOption: {
          readonly color: {
            readonly colorKey: string | null | undefined;
            readonly id: string | null | undefined;
          } | null | undefined;
          readonly id: string;
          readonly optionId: string;
          readonly value: string | null | undefined;
        } | null | undefined;
        readonly fieldOptions: {
          readonly totalCount: number | null | undefined;
        } | null | undefined;
        readonly id: string;
        readonly name: string;
        readonly type: string;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectField_Mutation = {
  rawResponse: singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectField_Mutation$rawResponse;
  response: singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectField_Mutation$data;
  variables: singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectField_Mutation$variables;
};
({
  "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider": includeSingleSelectColorField_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider": isJscInlineEditRefactorEnabled_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider: {
    readonly get: () => boolean;
  };
});

import isJscInlineEditRefactorEnabled_provider from '@atlassian/jira-relay-provider/src/jsc-inline-editing-field-refactor.relayprovider';
import includeSingleSelectColorField_provider from '@atlassian/jira-relay-provider/src/thor-colorful-single-select-milestone2-experiment.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = [
  {
    "concreteType": "JiraColor",
    "kind": "LinkedField",
    "name": "color",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "colorKey"
      },
      (v4/*: any*/)
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/)
    ],
    "kind": "Fragment",
    "name": "singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraSingleSelectFieldPayload",
            "kind": "LinkedField",
            "name": "updateSingleSelectField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraSingleSelectField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditViewOld_fragmentRef"
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraSingleSelectFieldPayload",
            "kind": "LinkedField",
            "name": "updateSingleSelectField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraSingleSelectField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "name"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "fieldId"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "type"
                  },
                  {
                    "concreteType": "JiraOptionConnection",
                    "kind": "LinkedField",
                    "name": "fieldOptions",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "totalCount"
                      }
                    ]
                  },
                  {
                    "concreteType": "JiraOption",
                    "kind": "LinkedField",
                    "name": "fieldOption",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "value"
                      },
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": (v5/*: any*/)
                      },
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": (v5/*: any*/)
                      },
                      (v4/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "optionId"
                      }
                    ]
                  },
                  {
                    "concreteType": "JiraFieldConfig",
                    "kind": "LinkedField",
                    "name": "fieldConfig",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "isEditable"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "d02d80792cb6231b24c01fdd22b07c504eca0e77eee873d4c5b3368c867a754e",
    "metadata": {},
    "name": "singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectField_Mutation",
    "operationKind": "mutation",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider": includeSingleSelectColorField_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider": isJscInlineEditRefactorEnabled_provider
    }
  }
};
})();

(node as any).hash = "1ca1a4dabd6c514b7b52acb97029c26f";

export default node;
