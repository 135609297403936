import React from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type {
	IssueViewRelayFragment,
	MainIssueAggQueryRelayFragment,
} from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const RedactionOnboardingAndFlag = lazyForPaint(
	() =>
		import(/* webpackChunkName: "async-redaction-new-changeboarding-flag" */ './index').then(
			(module) => module.default,
		),
	{ ssr: false },
);

type Props = {
	issueViewRelayFragment: IssueViewRelayFragment | null;
	rootRelayFragment: MainIssueAggQueryRelayFragment | null;
};

export const AsyncRedactionOnboardingAndFlag = (props: Props) => (
	<Placeholder name="redaction-onboarding-flag" fallback={null}>
		<RedactionOnboardingAndFlag {...props} />
	</Placeholder>
);
