import React from 'react';
import { styled } from '@compiled/react';
import { SimpleTag as Tag } from '@atlaskit/tag';
import { token } from '@atlaskit/tokens';
import { Box, xcss } from '@atlaskit/primitives';
import { getIdFromAri } from '@atlassian/jira-compass-common/src/common/utils.tsx';
import type { Item } from '@atlassian/jira-issue-view-internal-tagview/src/tag-view.tsx';
import {
	type UIAnalyticsEvent,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';

const getComponentTag = (
	isGlobalComponent: boolean,
	isGlobalComponentsEnabled: boolean,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	createAnalyticsEvent: (arg1: any) => UIAnalyticsEvent,
) => {
	if (isGlobalComponentsEnabled) {
		if (isGlobalComponent) {
			return (props: Item) => (
				<Box testId="issue-view-base.context.components.component-tag" xcss={globalComponentCss}>
					<EnabledGlobalComponentTag
						{...props}
						data-testid="issue-view-base.context.components.enabled-global-component-tag"
						target="_blank"
						rel="noopener noreferrer"
						onClick={() => {
							fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'compassComponentLink');
						}}
					/>
				</Box>
			);
		}
		return (props: Item) => (
			<DisabledProjectComponentTag
				{...props}
				data-testid="issue-view-base.context.components.disabled-project-component-tag"
			/>
		);
	}
	if (isGlobalComponent) {
		return (props: Item) => (
			<Box testId="issue-view-base.context.components.component-tag" xcss={globalComponentCss}>
				<DisabledGlobalComponentTag
					{...props}
					data-testid="issue-view-base.context.components.disabled-global-component-tag"
				/>
			</Box>
		);
	}
	return (props: Item) => (
		<EnabledProjectComponentTag
			{...props}
			data-testid="issue-view-base.context.components.enabled-project-component-tag"
		/>
	);
};

type Props = {
	item: Item & {
		ari?: string;
		metadata?: { typeId: string };
	};
	globalComponentsEnabled: boolean;
};

export const ComponentTag = (props: Props) => {
	const { item, globalComponentsEnabled } = props;
	const isGlobalComponent = typeof item.ari === 'string' && item.metadata?.typeId !== undefined;
	const isDisabledComponent = isGlobalComponent
		? !globalComponentsEnabled
		: globalComponentsEnabled;
	const componentId = item.ari && getIdFromAri(item.ari);

	const text = 'content' in item ? item.content : item.label;
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const TagComponent = getComponentTag(
		isGlobalComponent,
		globalComponentsEnabled,
		createAnalyticsEvent,
	);

	const renderComponentIcon = (isOpaque: boolean) => {
		const typeId =
			item?.metadata?.typeId && !item?.metadata?.typeId.startsWith('ari:cloud:compass')
				? item?.metadata?.typeId?.replaceAll('_', '-').toLowerCase()
				: 'other';

		return (
			<GlobalComponentIcon
				src={`https://compass-ui.prod-east.frontend.public.atl-paas.net/assets/${typeId}.svg`}
				height={16}
				width={16}
				isOpaque={isOpaque}
				alt={typeId}
			/>
		);
	};

	return (
		<Tag
			text={text}
			href={isGlobalComponent && componentId ? `/compass/component/${componentId}` : item.href}
			linkComponent={TagComponent}
			elemBefore={
				isGlobalComponent
					? renderComponentIcon(isDisabledComponent && isGlobalComponent)
					: item.elemBefore
			}
		/>
	);
};
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EnabledGlobalComponentTag = styled.a({
	paddingLeft: token('space.100'),
	color: token('color.link'),
	'&:hover': {
		textDecoration: 'underline',
		cursor: 'pointer',
	},
	maxWidth: '145px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DisabledGlobalComponentTag = styled.button({
	border: 'none',
	background: 'none',
	paddingLeft: token('space.100'),
	// COMPASS-17792: investigate why !important is required to get styles to match in storybook
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	color: `${token('color.text.disabled')} !important`,
	'&:hover': {
		cursor: 'pointer',
		textDecoration: 'none',
		color: token('color.text.selected'),
	},
	maxWidth: '145px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EnabledProjectComponentTag = styled.a({
	paddingTop: token('space.025'),
	color: token('color.text'),
	'&:hover': {
		textDecoration: 'underline',
		cursor: 'pointer',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DisabledProjectComponentTag = styled.button({
	border: 'none',
	background: 'none',
	paddingTop: token('space.025'),
	// COMPASS-17792: investigate why !important is required to get styles to match in storybook
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	color: `${token('color.text.disabled')} !important`,
	'&:hover': {
		cursor: 'pointer',
		textDecoration: 'none',
		color: token('color.text.disabled'),
	},
});

// using this in a test to mock the component icon
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const GlobalComponentIcon = styled.img<{ isOpaque: boolean }>({
	paddingLeft: token('space.050'),
	paddingTop: token('space.025'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: ({ isOpaque }) => (isOpaque ? '.5' : '1'),
});

const globalComponentCss = xcss({
	alignItems: 'center',
	display: 'flex',
	justifyContent: 'center',
	textAlign: 'center',
});
