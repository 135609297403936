/**
 * @generated SignedSource<<bb1a7035d717609d4b76065b32bb7f31>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type view_issueViewBase_MultilineField$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FieldEditIcon">;
  readonly " $fragmentType": "view_issueViewBase_MultilineField";
};
export type view_issueViewBase_MultilineField$key = {
  readonly " $data"?: view_issueViewBase_MultilineField$data;
  readonly " $fragmentSpreads": FragmentRefs<"view_issueViewBase_MultilineField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "view_issueViewBase_MultilineField",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "FieldEditIcon"
    }
  ],
  "type": "JiraIssueField",
  "abstractKey": "__isJiraIssueField"
};

(node as any).hash = "fca2a961e9473356b174eb6bd59044cd";

export default node;
