import React, { useEffect, useRef, useState } from 'react';
import { graphql, useFragment, useQueryLoader } from 'react-relay';
import Placeholder from '@atlassian/react-ufo/placeholder';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import PanelViewCustomerQuery, {
	type panelViewCustomerQuery,
} from '@atlassian/jira-relay/src/__generated__/panelViewCustomerQuery.graphql';
import { PanelView } from '@atlassian/jira-servicedesk-customer-service-issue-view-panel/src/ui/customer/panel-view/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { issueViewReporterField_servicedeskCustomerServiceCustomFields_JCSReporterFieldInner$key } from '@atlassian/jira-relay/src/__generated__/issueViewReporterField_servicedeskCustomerServiceCustomFields_JCSReporterFieldInner.graphql';
import { ExpandButton } from '../../common/ui/expand-button/index.tsx';
import messages from './messages.tsx';
import { ReporterField } from './reporter-field/index.tsx';

export type JCSReporterFieldProps = {
	fragmentKey: issueViewReporterField_servicedeskCustomerServiceCustomFields_JCSReporterFieldInner$key;
};

export const JCSReporterFieldInner = ({ fragmentKey }: JCSReporterFieldProps) => {
	const cloudId = useCloudId();
	const { formatMessage } = useIntl();
	const issueId = useIssueId() || '';
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [queryReference, loadQuery, disposeQuery] =
		useQueryLoader<panelViewCustomerQuery>(PanelViewCustomerQuery);
	const idRef = useRef<string | undefined>(undefined);
	const hasQueryReference = !!queryReference;

	const data =
		useFragment<issueViewReporterField_servicedeskCustomerServiceCustomFields_JCSReporterFieldInner$key>(
			graphql`
				fragment issueViewReporterField_servicedeskCustomerServiceCustomFields_JCSReporterFieldInner on JiraSingleSelectUserPickerField {
					...reporterField_servicedeskCustomerServiceCustomFields
					user {
						accountId
						name
					}
				}
			`,
			fragmentKey,
		);

	const { user } = data;

	useEffect(() => {
		if (isOpen && idRef.current !== user?.accountId) {
			setIsOpen(false);
		}

		if (!isOpen && hasQueryReference) {
			disposeQuery();
		}

		idRef.current = user?.accountId;
	}, [disposeQuery, hasQueryReference, isOpen, user?.accountId]);

	const onToggle = () => {
		setIsOpen((prev) => {
			if (!prev && user) {
				loadQuery({
					cloudId,
					accountId: user.accountId,
					useReporterOrganizations: false,
					filter: { context: { type: 'ISSUE', issueId } },
				});
			}

			return !prev;
		});
	};

	return (
		<>
			<ReporterField
				fragmentKey={data}
				expandButton={
					user && (
						<ExpandButton
							fieldName="reporter"
							isOpen={isOpen}
							onToggle={onToggle}
							expandAriaLabel={formatMessage(messages.expandAriaLabel, {
								reporterName: user.name,
							})}
							collapseAriaLabel={formatMessage(messages.collapseAriaLabel, {
								reporterName: user.name,
							})}
						/>
					)
				}
			/>
			<div hidden={!isOpen} id="reporter-custom-field-expand-panel">
				{isOpen && queryReference && <PanelView queryReference={queryReference} showProfileLink />}
			</div>
		</>
	);
};

export const JCSReporterField = (props: JCSReporterFieldProps) => (
	<ErrorBoundary id="IssueViewReporterField" packageName="servicedeskCustomerServiceCustomFields">
		<Placeholder name="customer-service-custom-fields-reporter-field" fallback={null}>
			<JCSReporterFieldInner {...props} />
		</Placeholder>
	</ErrorBoundary>
);
