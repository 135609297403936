import memoize from 'lodash/memoize';
import {
	type ProjectType,
	CORE_PROJECT,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useIsSimplifiedProject } from '@atlassian/jira-project-context-service/src/main.tsx';
import { useCanConfigureIssue } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import type { Project } from './types.tsx';

export const useCanConfigureIssueType = (projectKey: string) => {
	const isTeamManaged = useIsSimplifiedProject(projectKey);
	const hasAdminPermissions = useCanConfigureIssue(projectKey);

	return isTeamManaged && hasAdminPermissions;
};

export const shouldShowConfigureIssueTypeOption = (projectType: ProjectType | null) => {
	return projectType === CORE_PROJECT || projectType === SOFTWARE_PROJECT;
};

export const canConfigureIssueTypeForProject = memoize(
	({ project, isProjectAdmin }: { project: Project | null; isProjectAdmin: boolean }) => {
		return (
			isProjectAdmin &&
			(project?.isSimplified ?? false) &&
			shouldShowConfigureIssueTypeOption(project?.projectType ?? null)
		);
	},
);
