import { useMemo } from 'react';
import { useIssueLayoutFields } from '@atlassian/jira-issue-view-layout/src/utils.tsx';
import type { IncidentAction } from '@atlassian/jira-servicedesk-ai-context-panel-resources/src/services/ops/common/types.tsx';
import type { ServiceAction } from '@atlassian/jira-servicedesk-ai-context-panel-resources/src/services/service/types.tsx';

type Action = IncidentAction | ServiceAction;

type UseVisibleActionsProps<T> = {
	actions?: Array<T>;
	exceptions?: Array<string>;
};

export const useVisibleActions = <T extends Action>({
	actions = [],
	exceptions = [],
}: UseVisibleActionsProps<T>): Array<T> => {
	const [{ issueLayoutFields }] = useIssueLayoutFields();
	const visibleActions = useMemo(() => {
		const visibleFields = new Set(issueLayoutFields);

		return actions.filter(
			(action) =>
				visibleFields.has(action.context.fieldId) || exceptions.includes(action.context.fieldId),
		);
	}, [actions, exceptions, issueLayoutFields]);

	return visibleActions;
};
