/**
 * @generated SignedSource<<dcc48fd11ecfcb4d6be5ee4aee24b325>>
 * @relayHash e5f46b08312f96c4c8f900fe3d649b2d
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID cd8bcf79424e8c19c6a5288a7d1abb912f412b5b2aeb00ef38a95322fa8f075f

import type { ConcreteRequest, Query } from 'relay-runtime';
export type commandPaletteAssignee_loggedInUserQuery$variables = Record<PropertyKey, never>;
export type commandPaletteAssignee_loggedInUserQuery$data = {
  readonly me: {
    readonly user: {
      readonly id: string;
      readonly name: string;
      readonly picture: AGG$URL;
    } | null | undefined;
  };
};
export type commandPaletteAssignee_loggedInUserQuery = {
  response: commandPaletteAssignee_loggedInUserQuery$data;
  variables: commandPaletteAssignee_loggedInUserQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
},
v1 = {
  "kind": "ScalarField",
  "name": "name"
},
v2 = {
  "kind": "ScalarField",
  "name": "picture"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "name": "commandPaletteAssignee_loggedInUserQuery",
    "selections": [
      {
        "concreteType": "AuthenticationContext",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "commandPaletteAssignee_loggedInUserQuery",
    "selections": [
      {
        "concreteType": "AuthenticationContext",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "__typename"
              },
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "cd8bcf79424e8c19c6a5288a7d1abb912f412b5b2aeb00ef38a95322fa8f075f",
    "metadata": {},
    "name": "commandPaletteAssignee_loggedInUserQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "b7ed256165650d1bf94951eca5e245eb";

export default node;
