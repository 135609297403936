import { createStateHook } from 'react-sweet-state';

import type { ViewType } from '../../../../common/types/context';
import { type Actions, store } from '../../main';
import type { IssueAdjustmentsState } from '../../types';

export const useViewType = createStateHook<IssueAdjustmentsState, Actions, ViewType>(store, {
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	selector: (state) => state.viewType!,
});
