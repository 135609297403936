/** @jsx jsx */
import React, { forwardRef, useContext, useCallback, useEffect, useMemo } from 'react';
import { jsx } from '@compiled/react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import type { CreatePayload } from '@atlaskit/link-create';
import { Box } from '@atlaskit/primitives';
import WhiteboardIcon from '@atlaskit/icon/core/whiteboard';
import { useTenantHasConfluence } from '@atlassian/jira-business-confluence-pages/src/utils/use-tenant-has-confluence/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { toFlagId, useFlagService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueAri } from '@atlassian/jira-issue-hooks/src/services/use-issue-ari/index.tsx';
import {
	type ConfluenceWhiteboard,
	CONFLUENCE_WHITEBOARD,
} from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { fetchConfluenceWhiteboardSuccess } from '@atlassian/jira-issue-view-store/src/actions/confluence-whiteboards-actions.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { useEmbeddedConfluenceSidePanel } from '@atlassian/jira-confluence-integration-controls/src/controllers/use-embedded-confluence-side-panel/index.tsx';
import { useIsFullPageIssueView } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { hasActivatedConfluenceWithinFourteenDaysSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import {
	SET_CREATE_CONTENT_RUNTIME_PROPS,
	SET_IS_CREATE_CONTENT_MODAL_OPEN,
	SET_IS_EMBEDDED_WHITEBOARD_MODAL_OPEN,
} from '../../../common/types/actions.tsx';
import { CONTENT_TYPE } from '../../../common/types/types.tsx';
import { ContentMenuContext } from '../../../controllers/create-content-menu-context/index.tsx';
import { useCreateContentClickBehavior } from '../../../controllers/use-create-content-click-behavior/index.tsx';
import { useEmbeddedPageTracking } from '../../../controllers/use-embedded-page-tracking/index.tsx';
import { useConfluenceWhiteboard } from '../../../controllers/use-fetch-whiteboard/index.tsx';
import { useWhiteboardIssueLinking } from '../../../controllers/use-whiteboard-issue-linking/index.tsx';
import { ACCESS_REQUEST_CAPABILITIES } from '../../../controllers/utils/index.tsx';
import { useParentProduct } from '../../../controllers/use-parent-product/index.tsx';
import { Whiteboard16Icon } from './assets/index.tsx';
import messages from './messages.tsx';
import type {
	CreateWhiteboardMenuItemComponentType,
	CreateWhiteboardMenuItemStateProps,
	WhiteboardLinkProps,
} from './types.tsx';

const WhiteboardLink = forwardRef<HTMLAnchorElement, WhiteboardLinkProps>(
	({ children, ...props }, ref: React.Ref<HTMLAnchorElement>) => (
		<a
			{...props}
			target="_blank"
			ref={ref}
			href="https://www.atlassian.com/software/confluence/whiteboards"
		>
			{children}
		</a>
	),
);

export const CreateWhiteboardMenuItemComponent = ({
	onCancel,
	onCloseComplete,
	onCreate,
	onFailure,
	onOpenComplete,
	onFetchConfluenceWhiteboardSuccess,
	isQuickActionsListView,
	setQuickActionListItem,
	hasActivatedConfluenceWithinFourteenDays,
}: CreateWhiteboardMenuItemComponentType) => {
	const { formatMessage } = useIntl();

	const { embeddedConfluenceSource, accessStatus, setActiveWhiteboardData, setContentMenuState } =
		useContext(ContentMenuContext);
	const [, sidePanelActions] = useEmbeddedConfluenceSidePanel(embeddedConfluenceSource);
	const { openSidePanel } = sidePanelActions;
	const issueAri = useIssueAri();
	const { linkIssueToWhiteboard } = useWhiteboardIssueLinking({ issueAri });
	const { fetchConfluenceWhiteboard } = useConfluenceWhiteboard();
	const fireAnalyticsEvent = useEmbeddedPageTracking();
	const { showFlag } = useFlagService();
	const tenantHasConfluence = useTenantHasConfluence();
	const getCreateContentClickBehavior = useCreateContentClickBehavior();
	const isFullIssueView = useIsFullPageIssueView();
	const parentProduct = useParentProduct();

	const shouldRenderInPanel = useMemo(
		() => isFullIssueView && !hasActivatedConfluenceWithinFourteenDays && parentProduct !== 'JSM',
		[isFullIssueView, hasActivatedConfluenceWithinFourteenDays, parentProduct],
	);

	const showWhiteboardLink = useCallback(() => {
		if (tenantHasConfluence && accessStatus !== ACCESS_REQUEST_CAPABILITIES.ACCESS_EXISTS) {
			return { component: WhiteboardLink };
		}
		return {};
	}, [accessStatus, tenantHasConfluence]);

	const linkWhiteboard = useCallback(
		async (whiteboardAri: string, confluenceWhiteboardUrl: string) => {
			try {
				await linkIssueToWhiteboard(whiteboardAri);

				showFlag({
					key: toFlagId('createdWhiteboardSucceededOnLink'),
					type: 'success',
					title: formatMessage(messages.createdWhiteboardSucceededOnLinkTitle),
					description: formatMessage(messages.createdWhiteboardSucceededOnLinkDescription),
					messageId:
						'issue-create-confluence-content.ui.create-confluence-content-menu.create-whiteboard-menu-item.show-flag.success.link-success-flag',
					messageType: 'transactional',
				});

				fireAnalyticsEvent({
					action: 'success',
					eventName: 'linkIssueToWhiteboard',
					embeddedConfluenceSource,
					eventType: 'operational',
				});
			} catch (error) {
				showFlag({
					key: toFlagId('createdWhiteboardFailedOnLink'),
					type: 'error',
					title: formatMessage(messages.createdWhiteboardFailedOnLinkTitle),
					description: formatMessage(messages.createdWhiteboardFailedOnLinkDescription),
					messageId:
						'issue-create-confluence-content.ui.create-confluence-content-menu.create-whiteboard-menu-item.show-flag.error.link-failure-flag',
					messageType: 'transactional',
					actions: [
						{
							// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
							onClick: () => window.open(confluenceWhiteboardUrl, '_blank'),
							content: messages.createdWhiteboardFailedOnLinkAction,
						},
					],
				});

				fireErrorAnalytics({
					meta: {
						id: 'linkIssueToWhiteboard',
						teamName: 'confluence-better-together',
						packageName: 'jiraIssueCreateConfluenceContent',
					},
					attributes: { embeddedConfluenceSource },
					error: new Error(
						`Failed to link Confluence whiteboard: ${error instanceof Error ? error.message : JSON.stringify(error)}`,
					),
				});

				throw new Error('linkIssueToWhiteboardFailed');
			}
		},
		[embeddedConfluenceSource, fireAnalyticsEvent, formatMessage, linkIssueToWhiteboard, showFlag],
	);

	const linkAndFetchWhiteboard = useCallback(
		async (whiteboardAri: string, confluenceWhiteboardUrl: string) => {
			try {
				await linkWhiteboard(whiteboardAri, confluenceWhiteboardUrl);
				const confluenceWhiteboard = await fetchConfluenceWhiteboard(whiteboardAri);
				const whiteboard: ConfluenceWhiteboard = {
					whiteboardId: confluenceWhiteboard.whiteboardId,
					href: `${confluenceWhiteboard.links?.base}${confluenceWhiteboard.links?.webUi}`,
					author: confluenceWhiteboard.author,
					title: confluenceWhiteboard.title,
					id: confluenceWhiteboard.id,
					type: CONFLUENCE_WHITEBOARD,
				};

				onFetchConfluenceWhiteboardSuccess(whiteboard);
				fireAnalyticsEvent({
					action: 'success',
					eventName: 'fetchConfluenceWhiteboard',
					embeddedConfluenceSource,
					eventType: 'operational',
				});
			} catch (error) {
				showFlag({
					key: toFlagId('createdWhiteboardFailedOnLinkFetch'),
					type: 'error',
					title: formatMessage(messages.createdWhiteboardFailedOnLinkTitle),
					description: formatMessage(messages.createdWhiteboardFailedOnLinkDescription),
					messageId:
						'issue-create-confluence-content.ui.create-confluence-content-menu.create-whiteboard-menu-item.show-flag.error.link-fetch-failure-flag',
					messageType: 'transactional',
					actions: [
						{
							// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
							onClick: () => window.open(confluenceWhiteboardUrl, '_blank'),
							content: messages.createdWhiteboardFailedOnLinkAction,
						},
					],
				});

				if (error instanceof Error && error?.message === 'linkIssueToWhiteboardFailed') {
					return;
				}

				fireErrorAnalytics({
					meta: {
						id: 'fetchConfluenceWhiteboard',
						teamName: 'confluence-better-together',
						packageName: 'jiraIssueCreateConfluenceContent',
					},
					attributes: { embeddedConfluenceSource },
					error: new Error(
						`Failed to fetch Confluence whiteboard: ${error instanceof Error ? error.message : JSON.stringify(error)}`,
					),
				});
			}
		},
		[
			linkWhiteboard,
			fetchConfluenceWhiteboard,
			onFetchConfluenceWhiteboardSuccess,
			fireAnalyticsEvent,
			embeddedConfluenceSource,
			showFlag,
			formatMessage,
		],
	);

	const openOnCreate = useCallback(
		(payload: CreatePayload) => {
			onCreate && onCreate(payload);
			const { url, ari: whiteboardAri } = payload;
			if (!whiteboardAri || !url) {
				showFlag({
					key: toFlagId('createConfluenceWhiteboardFailed'),
					type: 'error',
					title: formatMessage(messages.createWhiteboardFailedTitle),
					description: formatMessage(messages.createWhiteboardFailedDescription),
					messageId:
						'issue-create-confluence-content.ui.create-confluence-content-menu.create-whiteboard-menu-item.show-flag.error.create-whiteboard-failure-flag',
					messageType: 'transactional',
				});

				fireErrorAnalytics({
					meta: {
						id: 'createConfluenceWhiteboard',
						teamName: 'confluence-better-together',
						packageName: 'jiraIssueCreateConfluenceContent',
					},
					attributes: { embeddedConfluenceSource },
					error: new Error('Failed to create Confluence whiteboard'),
				});

				return;
			}
			const standaloneConfluenceWhiteboardUrl = `${url}?openTemplatePicker=true`;
			linkAndFetchWhiteboard(whiteboardAri, standaloneConfluenceWhiteboardUrl);

			const openInModal = () => {
				setActiveWhiteboardData(payload);
				setContentMenuState({
					type: SET_IS_EMBEDDED_WHITEBOARD_MODAL_OPEN,
					payload: true,
				});
			};

			if (shouldRenderInPanel) {
				openSidePanel({
					contentId: payload.objectId ?? '',
					contentType: CONTENT_TYPE.WHITEBOARD,
					data: payload,
					url,
					openInModal,
				});
			} else {
				openInModal();
			}

			fireAnalyticsEvent({
				action: 'success',
				eventName: 'createConfluenceWhiteboard',
				// TODO update this if needed since we consider the panel another source
				embeddedConfluenceSource,
				eventType: 'operational',
			});
		},
		[
			onCreate,
			showFlag,
			formatMessage,
			embeddedConfluenceSource,
			linkAndFetchWhiteboard,
			setActiveWhiteboardData,
			shouldRenderInPanel,
			openSidePanel,
			fireAnalyticsEvent,
			setContentMenuState,
		],
	);

	const openOnCreateOld = useCallback(
		(payload: CreatePayload) => {
			onCreate && onCreate(payload);
			const { url, ari: whiteboardAri } = payload;
			const standaloneConfluenceWhiteboardUrl = `${url}?openTemplatePicker=true`;
			if (!whiteboardAri || !url) {
				showFlag({
					key: toFlagId('createConfluenceWhiteboardFailed'),
					type: 'error',
					title: formatMessage(messages.createWhiteboardFailedTitle),
					description: formatMessage(messages.createWhiteboardFailedDescription),
					messageId:
						'issue-create-confluence-content.ui.create-confluence-content-menu.create-whiteboard-menu-item.show-flag.error.create-whiteboard-failure-flag.1',
					messageType: 'transactional',
				});

				fireErrorAnalytics({
					meta: {
						id: 'createConfluenceWhiteboard',
						teamName: 'confluence-better-together',
						packageName: 'jiraIssueCreateConfluenceContent',
					},
					attributes: { embeddedConfluenceSource },
					error: new Error('Failed to create Confluence whiteboard'),
				});

				return;
			}

			linkAndFetchWhiteboard(whiteboardAri, standaloneConfluenceWhiteboardUrl);

			setActiveWhiteboardData(payload);
			setContentMenuState({
				type: SET_IS_EMBEDDED_WHITEBOARD_MODAL_OPEN,
				payload: true,
			});

			fireAnalyticsEvent({
				action: 'success',
				eventName: 'createConfluenceWhiteboard',
				embeddedConfluenceSource,
				eventType: 'operational',
			});
		},
		[
			onCreate,
			showFlag,
			formatMessage,
			embeddedConfluenceSource,
			linkAndFetchWhiteboard,
			fireAnalyticsEvent,
			setContentMenuState,
			setActiveWhiteboardData,
		],
	);

	const createConfluenceWhiteboard = useCallback(() => {
		setContentMenuState({ type: SET_IS_CREATE_CONTENT_MODAL_OPEN, payload: true });
		setContentMenuState({
			type: SET_CREATE_CONTENT_RUNTIME_PROPS,
			payload: {
				isOpen: true,
				isQuickActionsListView,
				contentType: CONTENT_TYPE.WHITEBOARD,
				onCancel,
				onOpenComplete,
				onCloseComplete,
				onCreate: (payload: CreatePayload): void => {
					fg('jira_issue_view_modeless_whiteboard')
						? openOnCreate(payload)
						: openOnCreateOld(payload);
				},
				onFailure: (error: unknown): void => {
					setContentMenuState({ type: SET_IS_CREATE_CONTENT_MODAL_OPEN, payload: false });

					if (error instanceof Error && error?.message === 'No Whiteboard access') {
						showFlag({
							type: 'error',
							title: formatMessage(messages.noWhiteboardBetaAccessToastTitle),
							messageId:
								'issue-create-confluence-content.ui.create-confluence-content-menu.create-whiteboard-menu-item.show-flag.error.no-whiteboard-access-flag',
							messageType: 'transactional',
							actions: [
								{
									onClick: () =>
										// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
										window.open(
											'https://www.atlassian.com/software/confluence/whiteboards',
											'_blank',
										),
									content: messages.noWhiteboardBetaAccessToastAction,
								},
							],
						});
						return;
					}

					showFlag({
						key: toFlagId('createConfluenceWhiteboardFailed'),
						type: 'error',
						title: formatMessage(messages.createWhiteboardFailedTitle),
						description: formatMessage(messages.createWhiteboardFailedDescription),
						messageId:
							'issue-create-confluence-content.ui.create-confluence-content-menu.create-whiteboard-menu-item.show-flag.error.create-whiteboard-failure-flag.2',
						messageType: 'transactional',
					});

					fireErrorAnalytics({
						meta: {
							id: 'createConfluenceWhiteboard',
							teamName: 'confluence-better-together',
							packageName: 'jiraIssueCreateConfluenceContent',
						},
						attributes: { embeddedConfluenceSource },
						error: new Error(
							`Failed to create Confluence whiteboard from Link Create Component: ${error instanceof Error ? error.message : JSON.stringify(error)}`,
						),
					});
					onFailure && onFailure(error);
				},
			},
		});
	}, [
		embeddedConfluenceSource,
		formatMessage,
		onCancel,
		onCloseComplete,
		onFailure,
		onOpenComplete,
		setContentMenuState,
		showFlag,
		isQuickActionsListView,
		openOnCreate,
		openOnCreateOld,
	]);

	const onClick = useCallback(() => {
		fireAnalyticsEvent({
			action: 'clicked',
			eventName: 'createWhiteboardMenuItem',
			embeddedConfluenceSource,
			tenantHasConfluence,
		});

		// When cross join is implemented we may not need the if condition here
		if (!tenantHasConfluence || accessStatus === ACCESS_REQUEST_CAPABILITIES.ACCESS_EXISTS) {
			getCreateContentClickBehavior({
				accessStatus,
				createContent: createConfluenceWhiteboard,
				tenantHasConfluence,
			})();
		}
	}, [
		fireAnalyticsEvent,
		embeddedConfluenceSource,
		tenantHasConfluence,
		accessStatus,
		getCreateContentClickBehavior,
		createConfluenceWhiteboard,
	]);

	const isQuickActionsGA = fg('quick_actions_menu_ga') && isQuickActionsListView;

	useEffect(() => {
		const label = expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false)
			? formatMessage(messages.createWhiteboardQuickActionsNestedList)
			: formatMessage(messages.newConfluenceWhiteboardQuickActionsList);

		// eslint-disable-next-line jira/ff/no-preconditioning
		fg('quick_actions_menu_ga') &&
			setQuickActionListItem &&
			setQuickActionListItem('confluence', 'new-whiteboard', {
				onClick,
				isDisabled: false,
				testId:
					'issue-create-confluence-content.ui.create-confluence-content-menu.create-whiteboard-menu-item.create-whiteboard-menu-item',
				iconComponent: (
					<Box>
						{isQuickActionsGA &&
						expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false) ? (
							<WhiteboardIcon label="" />
						) : (
							<Whiteboard16Icon />
						)}
					</Box>
				),
				parentLabel: formatMessage(messages.confluenceWhiteboardQuickActionsParent),
				labelComponent: label,
				label,
				searchLabel: formatMessage(messages.createWhiteboardQuickActionsNestedListSearch),
				additionalDropdownProps: { ...showWhiteboardLink() },
			});
	}, [formatMessage, onClick, showWhiteboardLink, setQuickActionListItem, isQuickActionsGA]);

	if (isQuickActionsGA) {
		return null;
	}

	return (
		<DropdownItem
			testId="issue-create-confluence-content.ui.create-confluence-content-menu.create-whiteboard-menu-item.create-whiteboard-menu-item"
			elemBefore={<Whiteboard16Icon />}
			onClick={onClick}
			{...showWhiteboardLink()}
		>
			{formatMessage(messages.createConfluenceWhiteboardItem)}
		</DropdownItem>
	);
};

const mapStateToProps =
	() =>
	(state: State): CreateWhiteboardMenuItemStateProps => ({
		hasActivatedConfluenceWithinFourteenDays:
			hasActivatedConfluenceWithinFourteenDaysSelector(state),
	});
const mapDispatchToProps = {
	onFetchConfluenceWhiteboardSuccess: fetchConfluenceWhiteboardSuccess,
};
export const CreateWhiteboardMenuItem = connect(
	mapStateToProps,
	mapDispatchToProps,
)(CreateWhiteboardMenuItemComponent);
