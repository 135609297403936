import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { ConfluencePage } from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import type { FailedRemoteLink } from '@atlassian/jira-issue-shared-types/src/common/types/remote-link-error-type.tsx';
import type { Href } from '@atlassian/jira-shared-types/src/general.tsx';

// LINK_CONFLUENCE_PAGE_CLICKED
export const LINK_CONFLUENCE_PAGE_CLICKED = 'LINK_CONFLUENCE_PAGE_CLICKED' as const;

export const linkConfluencePageClicked = () => ({
	type: LINK_CONFLUENCE_PAGE_CLICKED,
	payload: { isAISuggestionsOpen: false },
});

export const linkConfluencePageClickedWithAISuggestions = () => ({
	type: LINK_CONFLUENCE_PAGE_CLICKED,
	payload: { isAISuggestionsOpen: true },
});

// LINK_CONFLUENCE_PAGE_CLOSED
export const LINK_CONFLUENCE_PAGE_CLOSED = 'LINK_CONFLUENCE_PAGE_CLOSED' as const;

export const linkConfluencePageClosed = () => ({
	type: LINK_CONFLUENCE_PAGE_CLOSED,
});

export const LINK_CONFLUENCE_PAGE_AI_SUGGESTIONS_CLOSED =
	'LINK_CONFLUENCE_PAGE_AI_SUGGESTIONS_CLOSED' as const;

export const linkConfluencePageAISuggestionsClosed = () => ({
	type: LINK_CONFLUENCE_PAGE_AI_SUGGESTIONS_CLOSED,
});

// CREATE_CONFLUENCE_PAGE_LINK_MODIFIED
export const CREATE_CONFLUENCE_PAGE_LINK_MODIFIED = 'CREATE_CONFLUENCE_PAGE_LINK_MODIFIED' as const;

export const createConfluencePageLinkModified = () => ({
	type: CREATE_CONFLUENCE_PAGE_LINK_MODIFIED,
});

// CREATE_CONFLUENCE_PAGE_LINK_REQUEST
export const CREATE_CONFLUENCE_PAGE_LINK_REQUEST = 'CREATE_CONFLUENCE_PAGE_LINK_REQUEST' as const;

export const createConfluencePageLinkRequest = (
	pageHref: Href,
	analyticsEvent: UIAnalyticsEvent,
	triggerInviteExperiment?: () => void,
) => ({
	type: CREATE_CONFLUENCE_PAGE_LINK_REQUEST,
	payload: { pageHref, triggerInviteExperiment },
	meta: { analyticsEvent },
});

// CREATE_CONFLUENCE_PAGE_LINK_SUCCESS
export const CREATE_CONFLUENCE_PAGE_LINK_SUCCESS = 'CREATE_CONFLUENCE_PAGE_LINK_SUCCESS' as const;

export const createConfluencePageLinkSuccess = (
	confluencePageLink: ConfluencePage | FailedRemoteLink,
) => ({
	type: CREATE_CONFLUENCE_PAGE_LINK_SUCCESS,
	payload: { confluencePageLink },
});

// CREATE_CONFLUENCE_PAGE_LINK_FAILED
export const CREATE_CONFLUENCE_PAGE_LINK_FAILED = 'CREATE_CONFLUENCE_PAGE_LINK_FAILED' as const;

/**
 * We failed to link the confluence page.
 * If the user hasn't authorised to their App Link to a Confluence _Server_ instance yet, we get an
 * APPLINK_REQ_AUTH/repair link for use with the APPLINK_AUTHENTICATION_REQUEST
 */
export const createConfluencePageLinkFailed = (errorMessage: string, repairLink?: string) => ({
	type: CREATE_CONFLUENCE_PAGE_LINK_FAILED,
	payload: { errorMessage, repairLink },
});
// DELETE_CONFLUENCE_PAGE_LINK_REQUEST
export const DELETE_CONFLUENCE_PAGE_LINK_REQUEST = 'DELETE_CONFLUENCE_PAGE_LINK_REQUEST' as const;

export const deleteConfluencePageLinkRequest = (globalId: string) => ({
	type: DELETE_CONFLUENCE_PAGE_LINK_REQUEST,
	payload: {
		globalId,
	},
});

export const DELETE_CONFLUENCE_PAGE_LINK_REQUEST_BY_ARI =
	'DELETE_CONFLUENCE_PAGE_LINK_REQUEST_BY_ARI' as const;

export const deleteConfluencePageLinkByAriRequest = (ari: string) => ({
	type: DELETE_CONFLUENCE_PAGE_LINK_REQUEST_BY_ARI,
	payload: {
		ari,
	},
});

// DELETE_CONFLUENCE_PAGE_LINK_SUCCESS
export const DELETE_CONFLUENCE_PAGE_LINK_SUCCESS = 'DELETE_CONFLUENCE_PAGE_LINK_SUCCESS' as const;

export const deleteConfluencePageLinkSuccess = () => ({
	type: DELETE_CONFLUENCE_PAGE_LINK_SUCCESS,
});

// DELETE_CONFLUENCE_PAGE_LINK_FAILED
export const DELETE_CONFLUENCE_PAGE_LINK_FAILED = 'DELETE_CONFLUENCE_PAGE_LINK_FAILED' as const;

export const deleteConfluencePageLinkFailed = () => ({
	type: DELETE_CONFLUENCE_PAGE_LINK_FAILED,
});

// BATCH_CREATE_CONFLUENCE_PAGE_LINK_REQUESTS
export const BATCH_CREATE_CONFLUENCE_PAGE_LINK_REQUESTS =
	'BATCH_CREATE_CONFLUENCE_PAGE_LINK_REQUESTS' as const;

/**
 * Used to update state without triggering createConfluencePageLink epic
 * which only can only handle one link at a time
 */

export const batchCreateConfluencePageLinkRequests = () => ({
	type: BATCH_CREATE_CONFLUENCE_PAGE_LINK_REQUESTS,
});

// BATCH_CREATE_CONFLUENCE_PAGE_LINKS_COMPLETE
export const BATCH_CREATE_CONFLUENCE_PAGE_LINKS_COMPLETE =
	'BATCH_CREATE_CONFLUENCE_PAGE_LINKS_COMPLETE' as const;

export const batchCreateConfluencePageLinksSuccess = (
	confluencePageLinks: (ConfluencePage | FailedRemoteLink)[],
) => ({
	type: BATCH_CREATE_CONFLUENCE_PAGE_LINKS_COMPLETE,
	payload: { confluencePageLinks },
});

export type LinkConfluencePageClicked = ReturnType<typeof linkConfluencePageClicked>;

export type LinkConfluencePageClosed = ReturnType<typeof linkConfluencePageClosed>;

export type LinkConfluencePageAISuggestionsClosed = ReturnType<
	typeof linkConfluencePageAISuggestionsClosed
>;

export type CreateConfluencePageLinkModified = ReturnType<typeof createConfluencePageLinkModified>;

export type CreateConfluencePageLinkRequest = ReturnType<typeof createConfluencePageLinkRequest>;

export type CreateConfluencePageLinkSuccess = ReturnType<typeof createConfluencePageLinkSuccess>;

export type CreateConfluencePageLinkFailed = ReturnType<typeof createConfluencePageLinkFailed>;

export type DeleteConfluencePageLinkRequest = ReturnType<typeof deleteConfluencePageLinkRequest>;

export type DeleteConfluencePageLinkByAriRequest = ReturnType<
	typeof deleteConfluencePageLinkByAriRequest
>;

export type DeleteConfluencePageLinkSuccess = ReturnType<typeof deleteConfluencePageLinkSuccess>;

export type DeleteConfluencePageLinkFailed = ReturnType<typeof deleteConfluencePageLinkFailed>;

export type BatchCreateConfluencePageLinkRequests = ReturnType<
	typeof batchCreateConfluencePageLinkRequests
>;

export type BatchCreateConfluencePageLinksSuccess = ReturnType<
	typeof batchCreateConfluencePageLinksSuccess
>;

export type ConfluencePagesAction =
	| LinkConfluencePageClicked
	| LinkConfluencePageClosed
	| CreateConfluencePageLinkModified
	| CreateConfluencePageLinkRequest
	| CreateConfluencePageLinkSuccess
	| CreateConfluencePageLinkFailed
	| DeleteConfluencePageLinkRequest
	| DeleteConfluencePageLinkSuccess
	| DeleteConfluencePageLinkFailed
	| BatchCreateConfluencePageLinkRequests
	| BatchCreateConfluencePageLinksSuccess
	| DeleteConfluencePageLinkByAriRequest;
