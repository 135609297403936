// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { StoreContainerProps, State } from '../types.tsx';
import { NO_RULE_PARTIAL_STATE } from '../constants.tsx';
import {
	deleteIssueProperties,
	deleteRule,
	disableRule,
} from '../../services/set-non-recurring.tsx';
import { callServiceWithAnalytics } from '../../common/utils.tsx';

export type SetAsNonRecurringIssueParams = {
	isProjectAdmin: boolean;
	isRuleOwner: boolean | null;
	isSiteAdmin: boolean;
	skipDeletingIssueProperties?: boolean;
};

export const setAsNonRecurringIssue =
	({
		isProjectAdmin,
		isRuleOwner,
		isSiteAdmin,
		skipDeletingIssueProperties = false,
	}: SetAsNonRecurringIssueParams): Action<State, StoreContainerProps, Promise<void>> =>
	async (
		{ setState, getState },
		{ createAnalyticsEvent, issueKey, environment, cloudId, projectId },
	) => {
		const { issueProperties, errors, isUpdatingRule } = getState();
		// this can't be !automationRuleUuid because recur-on-complete rules won't have an
		// automationRuleUuid, but they will have issueProperties
		if (!issueProperties) {
			const error = new Error(
				'User is trying to delete a rule before the rule data has been stored.',
			);
			setState({
				errors: [...(errors ?? []), error],
			});
			throw error;
		}

		if (!isSiteAdmin && !isProjectAdmin && !isRuleOwner) {
			const error = new Error('User does not have permission to set issue as non-recurring.');
			setState({
				// TODO: decide if we want to be appending to this list or overwriting it
				errors: [...(errors ?? []), error],
			});
			throw error;
		}

		const { automationRuleUuid: ruleUuid, templateId, recurOnScheduleConfig } = issueProperties;

		if (skipDeletingIssueProperties && !ruleUuid) {
			// nothing to do
			return;
		}

		setState({
			isDeletingRule: true,
		});

		const analyticsEvent = createAnalyticsEvent({});

		// delete or disable the rule if we know the rule UUID
		if (ruleUuid) {
			try {
				if (isRuleOwner || isSiteAdmin) {
					await callServiceWithAnalytics(
						analyticsEvent,
						deleteRule({ environment, cloudId, projectId, ruleUuid }),
						'deleteRule',
					);

					fireTrackAnalytics(analyticsEvent, 'recurringRule deleted', {
						// TODO: add ruleId attribute
						issueKey,
						ruleUuid,
						templateId,
						recurCondition: recurOnScheduleConfig?.recurCondition,
						isUpdateForExistingRule: isUpdatingRule,
					});
				} else {
					await callServiceWithAnalytics(
						analyticsEvent,
						disableRule({ environment, cloudId, projectId, ruleUuid }),
						'disableRule',
					);

					fireTrackAnalytics(analyticsEvent, 'recurringRule disabled', {
						// TODO: add ruleId attribute
						issueKey,
						ruleUuid,
						templateId,
						recurCondition: recurOnScheduleConfig?.recurCondition,
						isUpdateForExistingRule: isUpdatingRule,
					});
				}
			} catch (error: unknown) {
				// abort if the rule cannot be deleted/disabled
				setState({
					isDeletingRule: false,
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					errors: [...(errors ?? []), error as Error],
				});
				throw error;
			}
		}

		if (skipDeletingIssueProperties) {
			// we only get here if we've successfully deleted/disabled a recur-on-schedule rule.
			// it's not possible to re-enable disabled rules, so we can assume that the rule will
			// no longer generate future work items. this means that the issue's issue properties
			// are no longer important to store.
			setState({
				isDeletingRule: false,
				...NO_RULE_PARTIAL_STATE,
			});
			return;
		}

		try {
			await callServiceWithAnalytics(
				analyticsEvent,
				deleteIssueProperties({ issueKey }),
				'deleteIssueProperties',
			);

			setState({
				isDeletingRule: false,
				...NO_RULE_PARTIAL_STATE,
			});
		} catch (error: unknown) {
			if (!ruleUuid) {
				// in this case, we haven't deleted or disabled the rule, so the rule can still generate
				// future work items. we leave the rule stored in state unchanged and rethrow the error.
				setState({
					isDeletingRule: false,
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					errors: [...(errors ?? []), error as Error],
				});
				throw error;
			}
			// don't rethrow because from the user's perspective, the work item is no longer recurring.
			setState({
				isDeletingRule: false,
				...NO_RULE_PARTIAL_STATE,
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				errors: [...(errors ?? []), error as Error],
			});
		}
	};
