import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createConfluencewhiteboardQuickActionsList: {
		defaultMessage: 'Create Confluence whiteboard',
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-whiteboard-menu-item.create-confluencewhiteboard-quick-actions-list',
		description:
			"The 'Whiteboard' option in the create confluence content menu with quick action list view enabled",
	},
	newConfluenceWhiteboardQuickActionsList: {
		defaultMessage: 'New Confluence whiteboard',
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-whiteboard-menu-item.new-confluence-whiteboard-quick-actions-list',
		description:
			'Dropdown item to create a new confluence whiteboard that appears in the quick actions dropdown of issue view',
	},
	createConfluenceWhiteboardItem: {
		defaultMessage: 'Whiteboard',
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-whiteboard-menu-item.create-confluence-whiteboard-item',
		description: "The 'Whiteboard' option in the create confluence content menu",
	},
	whiteboardComingSoonLozenge: {
		defaultMessage: 'COMING SOON',
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-whiteboard-menu-item.whiteboard-coming-soon-lozenge',
		description:
			'A lozenge that indicates the ability to create confluence white boards is not available yet but coming soon',
	},
	createWhiteboardFailedTitle: {
		defaultMessage: "We're unable to create a whiteboard",
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-whiteboard-menu-item.create-whiteboard-failed-title',
		description:
			'This error message title will be shown when the user fails to create a Confluence Whiteboard.',
	},
	createWhiteboardFailedDescription: {
		defaultMessage: 'Please refresh and try again.',
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-whiteboard-menu-item.create-whiteboard-failed-description',
		description:
			'This error message description will be shown when the user fails to create a Confluence Whiteboard.',
	},
	createdWhiteboardFailedOnLinkTitle: {
		defaultMessage: "We couldn't link this whiteboard",
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-whiteboard-menu-item.created-whiteboard-failed-on-link-title',
		description:
			'This error message title will be shown when the user fails to link the issue to a Confluence Whiteboard.',
	},
	createdWhiteboardFailedOnLinkDescription: {
		defaultMessage: "We've created the whiteboard but we couldn't link it to Jira.",
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-whiteboard-menu-item.created-whiteboard-failed-on-link-description',
		description:
			'This error message description will be shown when the user fails to link the issue to a Confluence Whiteboard.',
	},
	createdWhiteboardFailedOnLinkAction: {
		defaultMessage: 'View whiteboard',
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-whiteboard-menu-item.created-whiteboard-failed-on-link-action',
		description: 'This action will allow the user to view the whiteboard in Confluence.',
	},
	createdWhiteboardSucceededOnLinkTitle: {
		defaultMessage: 'Linked!',
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-whiteboard-menu-item.created-whiteboard-succeeded-on-link-title',
		description:
			'This success message title will be shown when the user successfully links a newly created Confluence Whiteboard.',
	},
	createdWhiteboardSucceededOnLinkDescription: {
		defaultMessage: 'Successfully linked whiteboard',
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-whiteboard-menu-item.created-whiteboard-succeeded-on-link-description',
		description:
			'This success message description will be shown when the user successfully links a newly created Confluence Whiteboard.',
	},
	noWhiteboardBetaAccessToastTitle: {
		defaultMessage: 'Your org does not allow whiteboards',
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-whiteboard-menu-item.no-whiteboard-beta-access-toast-title',
		description:
			'400 Error title displayed when attempting to create whiteboard before opting into the beta',
	},
	noWhiteboardBetaAccessToastAction: {
		defaultMessage: 'Learn more about whiteboards',
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-whiteboard-menu-item.no-whiteboard-beta-access-toast-action',
		description:
			'400 Error description displayed when attempting to create whiteboard before opting into the beta',
	},
	createWhiteboardQuickActionsNestedList: {
		defaultMessage: 'Create whiteboard',
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-whiteboard-menu-item.create-whiteboard-quick-actions-nested-list',
		description:
			'Dropdown item to create a new confluence whiteboard that appears in the quick actions dropdown of issue view in nested menu',
	},
	createWhiteboardQuickActionsNestedListSearch: {
		defaultMessage: 'Create whiteboard in confluence',
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-whiteboard-menu-item.create-whiteboard-quick-actions-nested-list-search',
		description:
			'Search text for Dropdown item to create a new confluence whiteboard that appears in the quick actions dropdown of issue view in nested menu',
	},
	confluenceWhiteboardQuickActionsParent: {
		defaultMessage: 'Whiteboard',
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-whiteboard-menu-item.confluence-whiteboard-quick-actions-parent',
		description:
			'Dropdown parent for confluence whiteboard to be displayed for nested quick actions in the quick actions dropdown of issue view',
	},
});
