import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import { useFragment, graphql } from 'react-relay';
import Badge from '@atlaskit/badge';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { EmptyFieldText } from '@atlassian/jira-issue-empty-field-text/src/ui/index.tsx';
// eslint-disable-next-line jira/styled/no-styled-import-alias
import { ReadViewContainer as BaseReadViewContainer } from '@atlassian/jira-issue-field-inline-edit/src/styled.tsx';
import type { originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadView$key as OriginalEstimateFragment } from '@atlassian/jira-relay/src/__generated__/originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadView.graphql';
import {
	timeTrackingFormatterWithDefaults,
	transformAGGToJiraTimeFormat,
	transformAGGToJiraTimeUnit,
} from '@atlassian/jira-time-tracking-formatter/src/main.tsx';
import type { originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadViewOld_fragmentRef$key as OriginalEstimateFragmentOld } from '@atlassian/jira-relay/src/__generated__/originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadViewOld_fragmentRef.graphql';
import type { originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadViewNew_fragmentRef$key as OriginalEstimateFragmentNew } from '@atlassian/jira-relay/src/__generated__/originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadViewNew_fragmentRef.graphql';
import { fg } from '@atlassian/jira-feature-gating';
import type { OriginalEstimateReadViewProps } from './types.tsx';
import { useFormatInputValue } from './utils/formatting.tsx';

/**
 * The OriginalEstimateReadView will show a read only view of OriginalEstimate field.
 * @param props [OriginalEstimateReadViewProps](./types.tsx)
 */
export const OriginalEstimateReadViewOld = ({
	fragmentRef,
	enableHover = false,
}: Omit<OriginalEstimateReadViewProps, 'fragmentRef'> & {
	fragmentRef: OriginalEstimateFragmentOld | null;
}) => {
	const testId = 'issue-field-original-estimate-read-view';
	const data = useFragment<OriginalEstimateFragmentOld>(
		graphql`
			fragment originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadViewOld_fragmentRef on JiraTimeTrackingField {
				originalEstimate {
					timeInSeconds
				}
				timeTrackingSettings {
					workingHoursPerDay
					workingDaysPerWeek
					defaultFormat
					defaultUnit
				}
			}
		`,
		fragmentRef,
	);

	const intl = useIntl();
	const value = data?.originalEstimate?.timeInSeconds;
	const readTimeString = useMemo(
		() =>
			timeTrackingFormatterWithDefaults(
				value || 0,
				{
					workingHoursPerDay: data?.timeTrackingSettings?.workingHoursPerDay,
					workingDaysPerWeek: data?.timeTrackingSettings?.workingDaysPerWeek,
					timeFormat: transformAGGToJiraTimeFormat(data?.timeTrackingSettings?.defaultFormat),
					defaultUnit: transformAGGToJiraTimeUnit(data?.timeTrackingSettings?.defaultUnit),
				},
				intl,
			),
		[
			intl,
			data?.timeTrackingSettings?.defaultFormat,
			data?.timeTrackingSettings?.defaultUnit,
			data?.timeTrackingSettings?.workingDaysPerWeek,
			data?.timeTrackingSettings?.workingHoursPerDay,
			value,
		],
	);

	const getRenderView = () => {
		const badge = <Badge testId={`${testId}.badge`}>{readTimeString}</Badge>;

		if (enableHover) {
			return <HoverContainer>{badge}</HoverContainer>;
		}

		return badge;
	};

	if (!data) {
		return <EmptyFieldText />;
	}

	return (
		<ReadViewContainer>
			<BadgeWrapper data-testid="issue-field-original-estimate-readview-full.ui.original-estimate.test-id">
				{getRenderView()}
			</BadgeWrapper>
		</ReadViewContainer>
	);
};
/**
 * The OriginalEstimateReadView will show a read only view of OriginalEstimate field.
 * @param props [OriginalEstimateReadViewProps](./types.tsx)
 */
export const OriginalEstimateReadViewNew = ({
	fragmentRef,
}: Omit<OriginalEstimateReadViewProps, 'fragmentRef'> & {
	fragmentRef: OriginalEstimateFragmentNew | null;
}) => {
	const data = useFragment<OriginalEstimateFragmentNew>(
		graphql`
			fragment originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadViewNew_fragmentRef on JiraTimeTrackingField {
				originalEstimate {
					timeInSeconds
				}
				timeTrackingSettings {
					workingHoursPerDay
					workingDaysPerWeek
					defaultFormat
					defaultUnit
				}
			}
		`,
		fragmentRef,
	);

	const settingsInput = {
		workingHoursPerDay: data?.timeTrackingSettings?.workingHoursPerDay,
		workingDaysPerWeek: data?.timeTrackingSettings?.workingDaysPerWeek,
		defaultFormat: data?.timeTrackingSettings?.defaultFormat,
		defaultUnit: data?.timeTrackingSettings?.defaultUnit,
	};

	const formatInputValue = useFormatInputValue(settingsInput);
	const timeString = useMemo(
		() => (data ? formatInputValue(data.originalEstimate?.timeInSeconds ?? 0) : null),
		[data, formatInputValue],
	);

	if (timeString === null) {
		return <EmptyFieldText />;
	}

	return <Badge>{timeString}</Badge>;
};

export const OriginalEstimateReadView = ({
	fragmentRef,
	...props
}: OriginalEstimateReadViewProps) => {
	const data = useFragment<OriginalEstimateFragment>(
		/* eslint-disable @atlassian/relay/graphql-naming */
		graphql`
			fragment originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadView on JiraTimeTrackingField {
				...originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadViewNew_fragmentRef
				...originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadViewOld_fragmentRef
			}
		`,
		fragmentRef,
	);

	if (fg('jsc_inline_editing_field_refactor')) {
		return <OriginalEstimateReadViewNew fragmentRef={data} {...props} />;
	}
	return <OriginalEstimateReadViewOld fragmentRef={data} {...props} />;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewContainer = styled(BaseReadViewContainer)({
	paddingRight: token('space.025'),
	marginRight: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HoverContainer = styled.div({
	cursor: 'pointer',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&:hover > span': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		color: `${token('color.text')} !important`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: `${token('color.background.neutral.hovered')} !important`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/design-system/use-tokens-typography -- Ignored via go/DSP-18766
	lineHeight: `${gridSize * 2.5}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BadgeWrapper = styled.div({
	display: 'inline-block',
	position: 'relative',
	marginTop: token('space.negative.075'),
	marginBottom: token('space.negative.075'),
	marginRight: token('space.negative.025'),
	marginLeft: token('space.025'),
});
