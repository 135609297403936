/**
 * @generated SignedSource<<71daf17df2b5557176e7ee592688e383>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadView$data = {
  readonly " $fragmentSpreads": FragmentRefs<"originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadViewNew_fragmentRef" | "originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadViewOld_fragmentRef">;
  readonly " $fragmentType": "originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadView";
};
export type originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadView$key = {
  readonly " $data"?: originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadView$data;
  readonly " $fragmentSpreads": FragmentRefs<"originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadView",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadViewNew_fragmentRef"
    },
    {
      "kind": "FragmentSpread",
      "name": "originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadViewOld_fragmentRef"
    }
  ],
  "type": "JiraTimeTrackingField"
};

(node as any).hash = "2a15c08b9114d781806c532c837870f5";

export default node;
