import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { toAri, type Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { PolarisComment } from '@atlassian/jira-polaris-domain-comment/src/index.tsx';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action, StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';
import { saveView } from '../save/index.tsx';
import { updateViewState } from '../utils/state/index.tsx';
import { loadViewComments } from './load-view-comments/index.tsx';
import { internViewComment } from './utils.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { loadViewComments };

export const seenViewComments =
	(viewId: Ari): Action<State, Props> =>
	async ({ getState, setState, dispatch }: StoreActionApi<State>) => {
		const lastCommentsViewedTimestamp = Date.now();
		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			(view: View): boolean => view.viewId === viewId,
			(view: View) => ({ ...view, modified: true, lastCommentsViewedTimestamp }),
		);

		if (changedView) {
			setState({ viewSets });
			dispatch(saveView(changedView.id));
		}
	};

export const createViewComment =
	(viewIdString: string, content: ADF) =>
	async (
		{ getState, setState, dispatch }: StoreActionApi<State>,
		{ viewRemote, onViewUpdateFailed }: Props,
	) => {
		if (viewRemote.createViewComment !== undefined) {
			const viewId = toAri(viewIdString);
			if (viewId === undefined) {
				throw new Error(`view argument must be a polaris view ARI, not "${viewIdString}"`);
			}
			try {
				const result = await viewRemote.createViewComment({
					viewId,
					content,
				});

				// throw new Error(`TODO createViewComment "${viewIdString}" -> ${viewId}`);
				const lastCommentsViewedTimestamp = Date.now();
				const { currentView, changedView, viewSets } = updateViewState(
					getState().viewSets,
					(view: View): boolean => view.viewId === viewId,
					(view: View) => ({
						...view,
						modified: true,
						fetchCommentsTimestamp: undefined,
						comments: [internViewComment(result), ...(view.comments || [])],
						lastCommentsViewedTimestamp,
					}),
				);
				if (
					currentView?.viewId !== undefined &&
					currentView?.fetchCommentsTimestamp !== undefined
				) {
					dispatch(loadViewComments(currentView.viewId, true));
				}
				if (changedView) {
					setState({ viewSets });
					dispatch(saveView(changedView.id));
				}
			} catch (err) {
				const error = err instanceof Error ? err : new Error('Unknown error');
				onViewUpdateFailed(error);
			}
		}
	};

export const updateViewComment =
	(commentIdString: string, update: ADF) =>
	async ({ getState, setState, dispatch }: StoreActionApi<State>, { viewRemote }: Props) => {
		if (viewRemote.updateViewComment !== undefined) {
			const commentId = toAri(commentIdString);
			if (commentId === undefined) {
				throw new Error(`comment argument must be a polaris comment ARI, not "${commentIdString}"`);
			}
			const result = await viewRemote.updateViewComment({ commentId, update });
			const updatedComment = internViewComment(result);

			const { currentView, changedView, viewSets } = updateViewState(
				getState().viewSets,
				(view: View): boolean =>
					!!view.comments?.find((comment: PolarisComment) => comment.id === commentId),
				(view: View) => ({
					...view,
					fetchCommentsTimestamp: undefined,
					comments: (view.comments || []).map((comment) =>
						comment.id === commentId ? updatedComment : comment,
					),
				}),
			);
			if (currentView?.viewId !== undefined && currentView?.fetchCommentsTimestamp !== undefined) {
				dispatch(loadViewComments(currentView.viewId, true));
			}
			if (changedView) {
				setState({ viewSets });
			}
		}
	};

export const deleteViewComment =
	(commentIdString: string) =>
	async ({ getState, setState, dispatch }: StoreActionApi<State>, { viewRemote }: Props) => {
		if (viewRemote.deleteViewComment !== undefined) {
			const commentId = toAri(commentIdString);
			if (commentId === undefined) {
				throw new Error(`comment argument must be a polaris comment ARI, not "${commentIdString}"`);
			}
			await viewRemote.deleteViewComment(commentId);
			const { currentView, changedView, viewSets } = updateViewState(
				getState().viewSets,
				(view: View): boolean =>
					!!view.comments?.find((comment: PolarisComment) => comment.id === commentId),
				(view: View) => ({
					...view,
					fetchCommentsTimestamp: undefined,
					comments: (view.comments || []).filter((comment) => comment.id !== commentId),
				}),
			);
			if (currentView?.viewId !== undefined && currentView?.fetchCommentsTimestamp !== undefined) {
				dispatch(loadViewComments(currentView.viewId, true));
			}
			if (changedView) {
				setState({ viewSets });
			}
		}
	};
