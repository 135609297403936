// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	popupTextUpdated: {
		id: 'issue-view.placeholder-template-experiment.info-icon-popup-updated-text',
		defaultMessage: "This template is powered by Confluence, Atlassian's documentation tool.",
		description: 'Popup text that is displayed when the user clicks the info icon.',
	},
	tryTemplateLozenge: {
		id: 'issue-view.placeholder-template-experiment.try-template-lozenge',
		defaultMessage: 'Try template',
		description: 'A call to action to try a template',
	},
	requestToTryLozenge: {
		id: 'issue-view.placeholder-template-experiment.request-to-try-lozenge',
		defaultMessage: 'Request to try',
		description: 'A call to action to request to try a template',
	},
	requestSentLozenge: {
		id: 'issue-view.placeholder-template-experiment.request-sent-lozenge',
		defaultMessage: 'Request sent',
		description: 'The lozenge text after a request has been sent',
	},
	updatedJustNow: {
		id: 'issue-view.placeholder-template-experiment.updated-just-now',
		defaultMessage: 'Updated just now',
		description: 'An updated timestamp for the dummy link',
	},
	newHeading: {
		id: 'issue-view.placeholder-template-experiment.new-heading',
		defaultMessage: 'Confluence content',
		description: 'New heading for Confluence dummy link',
	},
	pageLoadFailFlagTitle: {
		id: 'confluence-placeholder-template.dismiss-flag-title',
		defaultMessage: 'Template failed to load',
		description: 'Title of the flag that shows up when the embedded confluence page fails to load',
	},
	pageLoadFailFlagDescription: {
		id: 'confluence-placeholder-template.dismiss-flag-description',
		defaultMessage: 'Refresh the page and try again',
		description:
			'Description of the flag that shows up when the embedded confluence page fails to load',
	},
	pagePublishedFlagTitle: {
		id: 'confluence-placeholder-template.publish-flag-title',
		defaultMessage: 'Published!',
		description: 'Title of the flag that shows up when the embedded confluence page is published',
	},
	pagePublishedFlagDescription: {
		id: 'confluence-placeholder-template.publish-flag-description',
		defaultMessage: 'Your page has been published and linked to your Jira issue.',
		description:
			'Description of the flag that shows up when the embedded confluence page is published',
	},
	loadingTemplate: {
		id: 'issue-view.placeholder-template-experiment.loading-template',
		defaultMessage: 'Loading template',
		description: 'Text shown to users when loading the embedded confluence page',
	},
	loadingTemplateLong: {
		id: 'issue-view.placeholder-template-experiment.loading-template-long',
		defaultMessage: 'Almost there! Your page will be ready soon.',
		description: 'Text shown to users when loading the embedded confluence page',
	},
	requestSentFlagTitle: {
		id: 'confluence-placeholder-template.request-sent-flag-title',
		defaultMessage: 'Confluence template failed to load',
		description: 'Title of the flag that shows up when the user requests to try the template',
	},
	pagePublishedFlagDescriptionIssueTermRefresh: {
		id: 'confluence-placeholder-template.publish-flag-description-issue-term-refresh',
		defaultMessage: 'Your page has been published and linked to your work item.',
		description:
			'Description of the flag that shows up when the embedded confluence page is published',
	},
});
