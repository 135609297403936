import React, { useCallback, useEffect, useState } from 'react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import Button from '@atlaskit/button/standard-button';
import type { EditorActions } from '@atlaskit/editor-core';
import AddCannedResponseIcon from '@atlaskit/icon/core/migration/comment-add--media-services-add-comment';
import Tooltip from '@atlaskit/tooltip';
import type { IssueViewData } from '@atlassian/jira-canned-response-common/src/types.tsx';
import {
	insertCannedResponseInEditor,
	focusEditor,
} from '@atlassian/jira-canned-response-common/src/utils.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import CannedResponseLazyLoadedModal from '../../common/ui/canned-response-lazy-loaded-modal/index.tsx';
import messages from './messages.tsx';

type CannedResponseToolbarButtonProps = {
	editorActions?: EditorActions;
	isCompact: boolean;
};

export const CannedResponseToolbarButton = ({
	editorActions,
	isCompact = false,
}: CannedResponseToolbarButtonProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const { formatMessage, locale } = useIntl();
	const [content, setContent] = useState<ADF | null>(null);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [issueViewData, setIssueViewData] = useState<IssueViewData | null>(null);

	useEffect(() => {
		// need to make sure that dropdown is closed before inserting extension node to manage cursor position
		if (!isOpen && content && issueViewData) {
			insertCannedResponseInEditor(content, issueViewData, editorActions);
		} else if (!isOpen && !content) {
			focusEditor(editorActions);
		}
	}, [isOpen, content, editorActions, issueViewData]);

	const openModal = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), 'cannedResponsesButton clicked');
		setContent(null);
		setIsOpen(true);
	}, [createAnalyticsEvent]);

	const closeModal = useCallback(() => {
		setIsOpen(false);
	}, []);

	const onInsert = (cannedResponseContent: ADF, templateVariableData: IssueViewData) => {
		setIsOpen(false);
		setContent(cannedResponseContent);
		setIssueViewData(templateVariableData);
	};

	return (
		<>
			{isCompact ? (
				<Tooltip
					content={formatMessage(messages.cannedResponseToolbarButton)}
					testId="canned-response-extension.ui.canned-response-toolbar-button.tooltip"
				>
					<Button
						onClick={openModal}
						appearance="subtle"
						iconBefore={
							<AddCannedResponseIcon
								label={formatMessage(messages.cannedResponseToolbarButton)}
								LEGACY_size="medium"
								spacing="spacious"
							/>
						}
					/>
				</Tooltip>
			) : (
				<Button
					onClick={openModal}
					iconBefore={<AddCannedResponseIcon label="" LEGACY_size="medium" />}
				>
					{formatMessage(messages.cannedResponseToolbarButton)}
				</Button>
			)}
			{isOpen && (
				<CannedResponseLazyLoadedModal
					isOpen={isOpen}
					onClose={closeModal}
					intlLocale={locale}
					onInsert={onInsert}
				/>
			)}
		</>
	);
};

export default CannedResponseToolbarButton;
