import React, { useMemo } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useProjectKey, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useSearchCustomFieldKeys } from '@atlassian/jira-issue-field-base/src/services/custom-field-key-service/index.tsx';
import { useFlaggedField } from '@atlassian/jira-issue-field-flagged/src/services/main.tsx';
import { FLAG_ACTIONS } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import { FLAGGED_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import { useProjectConfiguration } from '@atlassian/jira-project-context-service/src/main.tsx';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import type { ShortcutOption } from '@atlassian/jira-shortcuts-dialog/src/common/types.tsx';
import { useDisableResolvedIssueVoting } from '@atlassian/jira-issue-field-voters/src/services/disable-resolved-issue-voting-service/index.tsx';
import type { main_issueViewFoundation_VoteButtonRelay$key } from '@atlassian/jira-relay/src/__generated__/main_issueViewFoundation_VoteButtonRelay.graphql.ts';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { VotersListContainer } from '@atlassian/jira-issue-field-voters/src/services/voters-list-service/context.tsx';
import FlagButtonItem from '../add-to-issue-dropdown-group/flag/button-item.tsx';
import { addFlagLabel, removeFlagLabel } from '../add-to-issue-dropdown-group/flag/index.tsx';
import FlagIssueActionView from '../add-to-issue-dropdown-group/flag/main.tsx';
import { VoteButton } from '../vote-issue/main.tsx';

export const addToIssueDropdownActionGroup = (
	actions: ShortcutOption[],
	onClick: (
		itemKey: string,
		event?: Event,
		actionAttributes?: Attributes,
	) => Promise<undefined> | undefined,
) => ({
	name: '',
	key: 'AddToIssueActionDropdownGroup',
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	items: actions.reduce<Array<any>>((itemsArray, { Component, label, key }) => {
		if (Component) {
			itemsArray.push(<Component label={label} key={key} itemKey={key} onClick={onClick} />);
		}
		return itemsArray;
	}, []),
});

export const useAddToIssueDropdownActions = (
	votesField?: main_issueViewFoundation_VoteButtonRelay$key | null,
): ShortcutOption[] => {
	const { formatMessage } = useIntl();
	const issueKey = useIssueKey();
	const projectKey = useProjectKey();
	const { issueActions } = useProjectConfiguration(projectKey);
	const [permissions] = useProjectPermissions(projectKey);
	const [fieldKey] = useSearchCustomFieldKeys(issueKey, FLAGGED_CF_TYPE);
	const [{ value: isFlagged }] = useFlaggedField({ issueKey, fieldKey: fieldKey || '' });
	const { isDisabled: shouldNotShowVote }: { isDisabled: boolean } =
		useDisableResolvedIssueVoting();

	const shouldShowFlag = useMemo(
		() => permissions.canEditIssues && !!issueActions?.includes(FLAG_ACTIONS),
		[permissions, issueActions],
	);
	const items = useMemo(
		() => [
			...(shouldShowFlag
				? [
						{
							Component: FlagIssueActionView,
							DialogComponent: FlagButtonItem,
							label: formatMessage(isFlagged ? removeFlagLabel : addFlagLabel),
							key: 'flagIssue',
						},
					]
				: []),
			...(!shouldNotShowVote
				? [
						{
							Component: () => (
								<ErrorBoundary id="issue.details.vote-action">
									<VotersListContainer scope={issueKey}>
										<VoteButton votesField={votesField} />
									</VotersListContainer>
								</ErrorBoundary>
							),
							label: 'Vote',
							key: 'vote',
						},
					]
				: []),
		],
		[shouldShowFlag, formatMessage, isFlagged, shouldNotShowVote, issueKey, votesField],
	);

	return items;
};
