import React, { memo } from 'react';
import Spinner from '@atlaskit/spinner';
import { IconContainer, PaddedFlexContainer } from '../../common/ui/styled.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LoadingSpinner = memo<Record<any, any>>(({ isLinkedIssuesRefreshEnabled = false }) => {
	const Component = isLinkedIssuesRefreshEnabled ? IconContainer : PaddedFlexContainer;
	return (
		<Component>
			<Spinner size="small" />
		</Component>
	);
});
