import React, { memo, useCallback } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { fg } from '@atlassian/jira-feature-gating';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { expVal } from '@atlassian/jira-feature-experiments';
import type { QuickAddItemToRender } from '../../types.tsx';
import { useQuickActionTimingMetrics } from '../../timing-metrics/context.tsx';

export const dropDownListButtonSubject = 'linkDropdownListButton';
export const dropDownListButtonSubjectId = 'moreLinksDropdownToggled';

export const dropDownListItemSubject = 'linkDropdownListItem';
export const dropDownListItemSubjectId = 'createLink';

export type Props = {
	item: QuickAddItemToRender;
	isQuickActionsListView?: boolean;
};

const DropdownListItem = memo<Props>((props: Props) => {
	const { id, icon, label, onClick, testId, isHidden, rightElement, ref } = props.item;
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { measureTimeOnClick } = useQuickActionTimingMetrics();

	const onItemClick = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'dropdownItem',
		});
		fireUIAnalytics(analyticsEvent, dropDownListItemSubjectId, {
			actionItemId: id,
			name: dropDownListItemSubject,
		});
		onClick && onClick();
		if (fg('quick_actions_click_timing_metrics')) {
			measureTimeOnClick(id);
		}
	}, [createAnalyticsEvent, id, onClick, measureTimeOnClick]);

	return (
		<DropdownItem
			elemBefore={icon}
			onClick={onItemClick}
			data-testid={testId}
			isDisabled={!!isHidden}
			elemAfter={
				props.isQuickActionsListView &&
				expVal('quick_actions_m3_experiment', 'hotKeysEnabled', false)
					? rightElement
					: null
			}
			ref={ref}
		>
			{isHidden ? null : label}
		</DropdownItem>
	);
});

export default DropdownListItem;
