import React from 'react';
import { styled } from '@compiled/react';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import type { Props } from './types.tsx';
import messages from './messages.tsx';

const assertUnreachable = (_x: never): never => {
	throw new Error("Didn't expect to get here");
};

const useIcon = (type: 'error' | 'warning') => {
	const { formatMessage } = useIntl();
	switch (type) {
		case 'error': {
			return (
				<ErrorIcon
					spacing="spacious"
					label={fg('jsc_inline_editing_field_refactor') ? formatMessage(messages.errorLabel) : ''}
					color={token('color.icon.danger')}
				/>
			);
		}
		case 'warning': {
			return (
				<WarningIcon
					spacing="spacious"
					label={
						fg('jsc_inline_editing_field_refactor') ? formatMessage(messages.warningLabel) : ''
					}
					color={token('color.icon.warning')}
				/>
			);
		}
		default: {
			assertUnreachable(type);
		}
	}
};

const PopupContent = ({ className, id, message, onFocus, onBlur, testId, title, type }: Props) => {
	const icon = useIcon(type);
	return (
		<Content
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={className}
			role="alert"
			id={id}
			hasTitle={!!title}
			onFocus={onFocus}
			onBlur={onBlur}
			data-testid={testId}
		>
			{icon}
			{title ? (
				<Box>
					<Box paddingBlockEnd="space.075" xcss={titleStyles}>
						{title}
					</Box>
					{message}
				</Box>
			) : (
				message
			)}
		</Content>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Content = styled.div<{ hasTitle: boolean }>(
	{
		display: 'flex',
		paddingTop: token('space.200'),
		paddingRight: token('space.200'),
		paddingBottom: token('space.200'),
		paddingLeft: token('space.200'),
		boxSizing: 'border-box',
		maxWidth: '368px',
		backgroundColor: token('elevation.surface.overlay'),
		// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "4px" and fallback "3px" do not match and can't be replaced automatically.
		borderRadius: token('border.radius', '3px'),
		boxShadow: token('elevation.shadow.overlay'),
		width: 'fit-content',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		props.hasTitle
			? {
					gap: token('space.200'),
				}
			: {
					alignItems: 'center',
					gap: token('space.075'),
				},
);

const titleStyles = xcss({
	color: 'color.text.subtle',
	fontWeight: token('font.weight.semibold'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '24px',
});

export default PopupContent;
