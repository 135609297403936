import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

// Remove .then when cleaning up iv-llc-unnecessary-bundles-onboarding-modal
export const optInBackOnboardingEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-issue-details-onboarding-modal-entrypoint" */ './main').then(
			({ OptInBackOnboardingWrapper }) => OptInBackOnboardingWrapper,
		),
	),
	getPreloadProps: () => ({}),
});
