import type {
	AccountId,
	Href,
	ConfluencePageId,
	ConfluenceWhiteboardId,
	RemoteLinkGlobalId,
	DisplayName,
} from '@atlassian/jira-shared-types/src/general.tsx';
import type { FailedRemoteLink } from './remote-link-error-type.tsx';

export const CONFLUENCE_BLOGPOST = 'blogpost' as const;
export const CONFLUENCE_PAGE = 'page' as const;
export const CONFLUENCE_WHITEBOARD = 'whiteboard' as const;

export type ConfluencePageType = typeof CONFLUENCE_BLOGPOST | typeof CONFLUENCE_PAGE;

export const toConfluencePageType = (type: string) =>
	type === CONFLUENCE_BLOGPOST ? CONFLUENCE_BLOGPOST : CONFLUENCE_PAGE;

export type ConfluencePage = {
	id: ConfluencePageId;
	globalId: RemoteLinkGlobalId;
	href: Href;
	title: string;
	type: ConfluencePageType;
	hostname?: string;
	space?: string;
	lastUpdatedContext?: {
		user: {
			displayName: DisplayName;
			avatarUrl: Href;
		};
	};
	lastModified?: number;
	spaceKey?: string;
	__isNew__?: boolean;
	subtype?: string;
};

type ConfluencePageSet = (ConfluencePage | FailedRemoteLink)[];

type ConfluencePageMapObject = {
	pages?: ConfluencePageSet;
	pageCount: number;
};

export type ConfluencePageMap = {
	linkedPages: ConfluencePageMapObject;
	mentionedPages: ConfluencePageMapObject;
};
type CreateLinkedPage = {
	clickedCount: number;
	isLoading: boolean;
	isInvalid: boolean;
	errorMessage: string;
	repairLink?: string;
};

export type ConfluencePages = ConfluencePageMap & {
	createLinkedPage: CreateLinkedPage;
	isLinkingEnabled?: boolean;
	isAISuggestionsOpen?: boolean;
	hasFetchedLinkedPages?: boolean;
};

type CreateLinkedWhiteboard = {
	isLoading: boolean;
};

type ConfluenceWhiteboardMapObject = {
	whiteboards?: ConfluenceWhiteboard[];
	whiteboardCount: number;
};

export type ConfluenceWhiteboards = {
	hasFetchedLinkedWhiteboards?: boolean;
	linkedWhiteboards: ConfluenceWhiteboardMapObject;
	createLinkedWhiteboard?: CreateLinkedWhiteboard;
};

export type ConfluenceWhiteboard = {
	whiteboardId: ConfluenceWhiteboardId;
	href: Href;
	hostname?: string;
	author?: {
		user?: {
			accountId: AccountId;
			accountStatus: string;
			name: DisplayName;
			picture: Href;
		} | null;
	} | null;
	title?: string | null;
	id: string;
	type: typeof CONFLUENCE_WHITEBOARD;
};
