import memoize from 'lodash/memoize';
import {
	type ProjectType,
	CORE_PROJECT,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useIsSimplifiedProject } from '@atlassian/jira-project-context-service/src/main.tsx';
import {
	useCanAdministerProject,
	useCanConfigureIssue,
} from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { Project } from './types.tsx';

export const useCanConfigureStatus = (projectKey: string) => {
	const isProjectAdmin = useCanAdministerProject(projectKey);
	const isTeamManaged = useIsSimplifiedProject(projectKey);
	const canConfigureIssue = useCanConfigureIssue(projectKey);

	const hasAdminPermissions = fg('jira_site_admin_permissions_for_inline_config')
		? canConfigureIssue
		: isProjectAdmin;

	return isTeamManaged && hasAdminPermissions;
};

export const shouldShowConfigureStatusOption = (projectType: ProjectType | null) => {
	return projectType === CORE_PROJECT || projectType === SOFTWARE_PROJECT;
};

export const canConfigureStatusForProject = memoize(
	({ project, isProjectAdmin }: { project: Project | null; isProjectAdmin: boolean }) => {
		return (
			isProjectAdmin &&
			(project?.isSimplified ?? false) &&
			shouldShowConfigureStatusOption(project?.projectType ?? null)
		);
	},
);
