// TODO - delete this file when cleaning up visual-refresh
import React from 'react';
import { token } from '@atlaskit/tokens';
import type { Props } from './types.tsx';

const containerStyles = {
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "4px" and fallback "3px" do not match and can't be replaced automatically.
	borderRadius: token('border.radius', '3px'),
	boxSizing: 'border-box',
	display: 'inline-block',
	font: token('font.body.small'),
	fontWeight: token('font.weight.bold'),
	maxWidth: '100%',
	padding: 0,
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	textTransform: 'uppercase',
	verticalAlign: 'top',
} as const;

const contentStyles = {
	boxSizing: 'border-box',
	display: 'inline-block',
	maxWidth: 200,
	overflow: 'hidden',
	padding: `0 ${token('space.050')}`,
	textOverflow: 'ellipsis',
	verticalAlign: 'top',
	whiteSpace: 'nowrap',
	width: '100%',
} as const;

/**
 * An optimised version of the default Atlaskit lozenge, without the overhead
 * of Emotion or Styled Components, built for the status field of child issues.
 */
export const LozengeOld = ({ children }: Props) => (
	<span
		// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
		style={containerStyles}
	>
		<span
			// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={contentStyles}
		>
			{children}
		</span>
	</span>
);

LozengeOld.displayName = 'Lozenge';
