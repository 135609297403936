/* eslint-disable @atlassian/relay/unused-fields */
import { useEffect, useState } from 'react';
import { commitLocalUpdate, graphql, useFragment, useRelayEnvironment } from 'react-relay';
import {
	useForgeActions,
	useForgeDataComplete,
} from '@atlassian/jira-issue-view-forge-service/src/services/main.tsx';
import RelayDataID from '@atlassian/relay-data-id';
import type { AggExtension } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import type { view_forgeDataStoreInitFragment$key } from '@atlassian/jira-relay/src/__generated__/view_forgeDataStoreInitFragment.graphql';
import { canFetchForgeModules } from '@atlassian/jira-forge-ui-utils/src/utils/can-fetch-forge/index.tsx';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge/src/index.tsx';
import { formatForgeExtensions } from './utils.tsx';
import type { Props } from './types.tsx';

const JiraForgeExtension = 'JiraForgeExtension';

/**
 * This is a workaround for the Relay store not updating when Forge extension data changes.
 * Each Forge extension has the same ID (ari) regardless of context, causing stale data between issue key changes.
 * We manually invalidate Forge extension records in the Relay store to ensure a network fetch.
 * This is a temporary solution until the root cause is resolved. Do not use, do not copy pattern. This is a workaround.
 */
const useForgeExtensionInvalidationWorkaround = () => {
	const relayEnvironment = useRelayEnvironment();
	const [extensionIds, setExtensionIds] = useState<string[]>([]);

	useEffect(() => {
		if (extensionIds.length === 0) {
			return;
		}
		commitLocalUpdate(relayEnvironment, (store) => {
			extensionIds.forEach((id) => {
				const extension = store.get(id);
				if (extension) {
					extension.invalidateRecord();
				}
			});
		});
	}, [extensionIds, relayEnvironment]);

	return setExtensionIds;
};

export const ForgeDataStoreInit = ({ rootRelayFragment, onForgeSuccess }: Props) => {
	const [, forgeActions] = useForgeActions();
	const [isForgeLoadedSuccessfully] = useForgeDataComplete();
	const isAnonymous = useIsAnonymous();
	const cloudId = useCloudId();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const setExtensionIds = useForgeExtensionInvalidationWorkaround();

	const data = useFragment<view_forgeDataStoreInitFragment$key>(
		graphql`
			fragment view_forgeDataStoreInitFragment on JiraQuery {
				forge {
					extensions(
						cloudId: $cloudId
						context: { issueKey: $issueKey }
						types: $forgeTypes
						includeHidden: true
					) {
						hiddenBy
						scopes
						type
						id
						environmentId
						environmentKey
						environmentType
						installationId
						appVersion
						consentUrl
						properties
						userAccess @include(if: $includeUserAccessForge) {
							hasAccess
						}
						egress {
							addresses
							type
							category @include(if: $includeEgressOverridesForge)
							inScopeEUD @include(if: $includeEgressOverridesForge)
						}
						overrides @include(if: $includeEgressOverridesForge)
						installationConfig @include(if: $includeEgressOverridesForge) {
							key
							value
						}
						license {
							active
							type
							supportEntitlementNumber
							trialEndDate
							subscriptionEndDate
							isEvaluation
							billingPeriod
							ccpEntitlementId
							ccpEntitlementSlug
							capabilitySet
						}
					}
				}
			}
		`,
		rootRelayFragment?.jira || null,
	);

	useEffect(() => {
		// canFetchForgeModules always returns false for anon users (for historical reasons we do not fetch Forge modules for anons)
		// Early return and no error state needed for anon users
		if (isAnonymous) {
			return;
		}

		if (isForgeLoadedSuccessfully) {
			return;
		}
		if (!canFetchForgeModules({ isAnonymous, cloudId }) || data == null) {
			forgeActions.setForgeLoadingFailed();
			return;
		}

		try {
			const mutableExtensions: AggExtension[] = Array.from(data?.forge?.extensions || []);
			const statePayload = formatForgeExtensions(mutableExtensions);
			forgeActions.setForge({
				...statePayload,
				isForgeDataComplete: true,
				isForgeLoadingFailed: false,
			});

			onForgeSuccess(statePayload);

			setExtensionIds(
				mutableExtensions.reduce<string[]>((acc, extension) => {
					const dataId = RelayDataID({ id: extension.id }, JiraForgeExtension);
					if (dataId != null) {
						acc.push(dataId);
					}
					return acc;
				}, []),
			);
		} catch (error) {
			fireOperationalAnalytics(createAnalyticsEvent({}), `Forge loading failed: ${error}`);
			forgeActions.setForgeLoadingFailed();
		}
	}, [
		cloudId,
		createAnalyticsEvent,
		data,
		forgeActions,
		isAnonymous,
		isForgeLoadedSuccessfully,
		onForgeSuccess,
		rootRelayFragment,
		setExtensionIds,
	]);

	return undefined;
};
