import React, { useCallback } from 'react';
import { commitLocalUpdate, useRelayEnvironment } from 'react-relay';
import { AutomationDiscovery } from '@atlassian/jira-automation-discoverability/src/ui/automation-discovery/index.tsx';

import { useIssueKey, useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useDescriptionField } from '@atlassian/jira-issue-field-rich-text/src/services/index.tsx';
import { useSummaryField } from '@atlassian/jira-issue-field-summary-common/src/services/index.tsx';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import {
	useCanAdministerJira,
	useCanAdministerProject,
} from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';

import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type {
	DismissTemplate,
	DismissData,
} from '@atlassian/jira-automation-discoverability/src/common/types.tsx';
import { getTemplate, updateDismissCache } from './utils.tsx';

export const AutomationDiscoveryContainer = ({
	dismissData,
	connectionId,
}: {
	dismissData: DismissData;
	connectionId: string;
}) => {
	const issueKey = useIssueKey();
	const issueId = useIssueId();
	const projectKey = useProjectKey(issueKey);
	const [{ value: currentSummary }] = useSummaryField({ issueKey, issueId });
	const [{ value: currentDesc }] = useDescriptionField({ issueKey });
	const isJiraAdmin = useCanAdministerJira(projectKey);
	const isProjectAdmin = useCanAdministerProject(projectKey);
	const accountId: string | null = useAccountId();
	const environment = useRelayEnvironment();
	const cloudId = useCloudId();
	const setDismissData = useCallback(
		(newDismissTemplate: DismissTemplate, isDismiss: boolean) => {
			commitLocalUpdate(environment, (store) => {
				const connection = store.get(connectionId);
				const edges = connection?.getLinkedRecords('edges');
				if (connection && edges) {
					if (isDismiss) {
						// isDismiss is to find the edge if exist and update the same or create a new edge
						updateDismissCache(store, connectionId, cloudId, newDismissTemplate);
					} else {
						// undo
						const filteredEdge = edges.filter((edge) => {
							const templateId = edge?.getLinkedRecord('node')?.getValue('templateId');
							return templateId !== newDismissTemplate.templateId;
						});
						connection?.setLinkedRecords(filteredEdge, 'edges');
					}
				}
			});
		},
		[cloudId, connectionId, environment],
	);

	const getTemplatePath = useCallback(
		(templateId: string) =>
			`/jira/servicedesk/projects/${projectKey}/settings/automate#/rule/template?templateId=${templateId}`,
		[projectKey],
	);

	const onRedirectToRuleBuilder = useCallback(
		(templateId: string) => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.open(getTemplatePath(templateId), '_blank');
		},
		[getTemplatePath],
	);

	if (currentSummary && accountId != null) {
		const template = getTemplate(currentSummary, currentDesc);
		return template ? (
			<AutomationDiscovery
				onRedirectToRuleBuilder={onRedirectToRuleBuilder}
				dismissData={dismissData}
				setDismissData={setDismissData}
				type="issue"
				isUserAuthorizedToCreateRule={isJiraAdmin || isProjectAdmin}
				templateId={template}
				accountId={accountId}
				getAutomationTemplateLink={(templateId) =>
					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					`${window.location.origin}${getTemplatePath(templateId)}`
				}
			/>
		) : null;
	}
	return null;
};
