/** @jsx jsx */
import { useCallback, useEffect, useRef, useState } from 'react';
import { css, jsx } from '@compiled/react';
import { Box, xcss, Text, Pressable } from '@atlaskit/primitives';
import { IconButton } from '@atlaskit/button/new';
import ChevronLeftIcon from '@atlaskit/icon/utility/chevron-left';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { token } from '@atlaskit/tokens';
import type { PublicPluginAPI } from '@atlaskit/editor-common/types';
import type { MentionsPlugin } from '@atlaskit/editor-plugin-mentions';
import Avatar from '@atlaskit/avatar';
import AddIcon from '@atlaskit/icon/utility/add';
import { useIntl } from '@atlassian/jira-intl';
import { useScrollCarousel } from '@atlassian/jira-canned-comments/src/controllers/use-scroll-carousel/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	useDataForRecommendationsInEditor,
	type MentionUser,
} from './data-for-recommendations-in-editor.tsx';
import messages from './messages.tsx';

type EditorMentionsAPI = PublicPluginAPI<[MentionsPlugin]>;

export const MentionsContainer = ({ api }: { api?: EditorMentionsAPI }) => {
	const {
		setRef,
		setCanScrollDebounced,
		shouldHideScrollLeftButton,
		shouldHideScrollRightButton,
		scrollToNext,
		scrollToPrevious,
	} = useScrollCarousel();

	const initialMentionUsersData = useDataForRecommendationsInEditor();
	const [mentionUsersData, setMentionUsersData] = useState<MentionUser[]>([]);
	const hasViewedEventFired = useRef(false);

	useEffect(() => {
		if (initialMentionUsersData) {
			setMentionUsersData(initialMentionUsersData);
		}
	}, [initialMentionUsersData]);

	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const hasFired = useRef(false);

	const sendAnalyticsEvent = useCallback(
		(action: string) => {
			if (action === 'viewed') {
				fireUIAnalytics(
					createAnalyticsEvent({}),
					'panel viewed',
					'recommendedUserMentionInsideEditorPanel',
					{},
				);
			} else if (action === 'clicked' && !hasFired.current) {
				fireUIAnalytics(
					createAnalyticsEvent({}),
					'button clicked',
					'recommendedUserMentionInsideEditorButton',
					{},
				);
				hasFired.current = true;
			}
		},
		[createAnalyticsEvent],
	);

	useEffect(() => {
		if (!hasViewedEventFired.current) {
			sendAnalyticsEvent('viewed');
			hasViewedEventFired.current = true;
		}
	}, [sendAnalyticsEvent]);

	const handleMentionClick = useCallback(
		(name: string, userId: string) => {
			setMentionUsersData((prevUsers) => prevUsers.filter((user) => user.userId !== userId));
			sendAnalyticsEvent('clicked');
			api?.core.actions.execute(({ tr }) => {
				const { schema } = tr.doc.type;
				const mentionNode = schema.nodes.mention.createChecked(
					{
						text: name,
						id: userId,
					},
					null,
					undefined,
				);
				// Insert a space before the mention node to prevent the mention from being merged with the previous node
				tr.insertText(' ', tr.selection.from);
				return tr.insert(tr.selection.from, mentionNode);
			});
			api?.core.actions.focus();
		},
		[api, sendAnalyticsEvent],
	);

	return mentionUsersData && mentionUsersData.length > 0 ? (
		<Box xcss={boxStyles}>
			<Box
				xcss={[hoverButtonStyle, leftButtonStyle, shouldHideScrollLeftButton && hiddenButtonStyle]}
			>
				<IconButton
					shape="circle"
					appearance="subtle"
					icon={ChevronLeftIcon}
					isTooltipDisabled
					label="Previous"
					onClick={scrollToPrevious}
					tabIndex={-1}
				/>
			</Box>
			<div
				ref={setRef}
				css={commentsContainerStyle}
				onScroll={setCanScrollDebounced}
				role="group"
				aria-label="Mention"
				tabIndex={-1}
			>
				<Text>{formatMessage(messages.mentionRecommendationText)}</Text>
				{mentionUsersData.map((user) => (
					<Pressable
						key={user.userId}
						onClick={() => handleMentionClick(user.name, user.userId)}
						xcss={buttonStyles}
						tabIndex={-1}
					>
						<AddIcon LEGACY_size="small" label="" />
						<Avatar size="xsmall" src={user.avatarUrl} />
						{user.name}
					</Pressable>
				))}
			</div>
			<Box
				xcss={[
					hoverButtonStyle,
					rightButtonStyle,
					shouldHideScrollRightButton && hiddenButtonStyle,
				]}
			>
				<IconButton
					shape="circle"
					appearance="subtle"
					icon={ChevronRightIcon}
					isTooltipDisabled
					label="Next"
					onClick={scrollToNext}
					tabIndex={-1}
				/>
			</Box>
		</Box>
	) : undefined;
};

const boxStyles = xcss({
	padding: 'space.100',
	paddingLeft: 'space.150',
	backgroundColor: 'color.background.accent.gray.subtlest',
	display: 'flex',
	alignItems: 'center',
	gap: 'space.050',
	overflowX: 'auto',
	whiteSpace: 'nowrap',
	position: 'relative',
	marginTop: 'space.200',
	marginLeft: 'space.negative.200',
	marginRight: 'space.negative.200',
	marginBottom: 'space.negative.200',
	borderRadius: 'border.radius.100',
});

const buttonStyles = xcss({
	backgroundColor: 'color.background.neutral.subtle',
	height: '24px',
	paddingTop: 'space.025',
	paddingBottom: 'space.025',
	paddingLeft: 'space.050',
	paddingRight: 'space.050',
	borderRadius: 'border.radius.100',
	borderColor: 'color.border',
	borderWidth: 'border.width',
	borderStyle: 'solid',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-start',
	gap: 'space.050',
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
	':active': {
		backgroundColor: 'color.background.neutral.subtle.pressed',
	},
});

const commentsContainerStyle = css({
	width: '100%',
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
	gap: token('space.100'),

	overflowX: 'scroll',
	scrollbarWidth: 'none',
	'-ms-overflow-style': 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
	'::-webkit-scrollbar': {
		display: 'none',
	},
});

const hoverButtonStyle = xcss({
	zIndex: 'layer',
	alignItems: 'center',
	position: 'absolute',
	backgroundColor: 'color.background.accent.gray.subtlest',
	opacity: 1,
	height: '100%',
	display: 'flex',
	justifyContent: 'center',
	cursor: 'pointer',
});

const rightButtonStyle = xcss({
	right: 'space.0',
});

const leftButtonStyle = xcss({
	left: 'space.0',
});

const hiddenButtonStyle = xcss({
	display: 'none',
});
