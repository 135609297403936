import React from 'react';

import { ButtonGroup } from '@atlaskit/button';
import Button, { IconButton } from '@atlaskit/button/new';
import AutomationIcon from '@atlaskit/icon/core/automation';
import HipchatChevronUpIcon from '@atlaskit/icon/utility/migration/chevron-up--hipchat-chevron-up';
import Lozenge from '@atlaskit/lozenge';
import { Box, Inline, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';

import { useUpdateDismissDataService } from '../../../../services/update-dismiss-data/index.tsx';

import messages from './messages.tsx';
import type { AutomationDiscoveryPanelProps } from './types.tsx';

export const AutomationDiscoveryPanel = ({
	onClose,
	header,
	isRuleExpanded,
	isAnimationFinished,
	onExpandRule,
	templateId,
	isUserAuthorizedToCreateRule,
	triggerRef,
	dismissData,
	setDismissData,
}: AutomationDiscoveryPanelProps) => {
	const { formatMessage } = useIntl();
	const { loading: dismissLoading, updateDismissData } = useUpdateDismissDataService(
		templateId,
		dismissData,
		setDismissData,
	);

	const renderButton = () => {
		if (isRuleExpanded && isAnimationFinished) {
			return null; // remove the button once animation is finished
		}
		return (
			<Button
				testId="automation-discoverability.ui.automation-discovery.panel-controller.panel.automation-discovery-panel-build-rule"
				appearance="primary"
				onClick={(_, analyticsEvent) => {
					fireUIAnalytics(analyticsEvent, 'automationDiscoveryBuildRule');
					onExpandRule();
				}}
				isLoading={isRuleExpanded && !isAnimationFinished}
				ref={triggerRef}
			>
				{isUserAuthorizedToCreateRule
					? formatMessage(messages.buildThisRule)
					: formatMessage(messages.viewThisRule)}
			</Button>
		);
	};

	return (
		<Box xcss={summaryStyles}>
			<Inline spread="space-between" alignBlock="center">
				<Inline space="space.150" alignBlock="center">
					<AutomationIcon
						label={formatMessage(messages.automationIcon)}
						color={token('color.icon.accent.blue')}
					/>
					<Text weight="bold">{formatMessage(header)}</Text>
					<Box xcss={lozengeStyle}>
						<Lozenge appearance="new">{formatMessage(messages.new)}</Lozenge>
					</Box>
				</Inline>
				<Box xcss={iconButtonStyles}>
					<ButtonGroup label={formatMessage(messages.buttonGroupLabel)}>
						<Button
							testId="automation-discoverability.ui.automation-discovery.panel-controller.panel.automation-discovery-panel-dismiss"
							appearance="subtle"
							onClick={(_, analyticsEvent) => {
								fireUIAnalytics(analyticsEvent, 'automationDiscoveryDismiss');
								updateDismissData();
							}}
							isLoading={dismissLoading}
						>
							{formatMessage(messages.dismiss)}
						</Button>
						{renderButton()}
						{isRuleExpanded && isAnimationFinished ? (
							<IconButton
								onClick={(_, analyticsEvent) => {
									fireUIAnalytics(analyticsEvent, 'automationDiscoveryClose');
									onClose();
								}}
								testId="automation-discoverability.ui.automation-discovery.panel-controller.panel.automation-discovery-panel-close"
								appearance="subtle"
								icon={HipchatChevronUpIcon}
								label={formatMessage(messages.chevronUp)}
							/>
						) : null}
					</ButtonGroup>
				</Box>
			</Inline>
		</Box>
	);
};

const summaryStyles = xcss({
	paddingInline: 'space.200',
	paddingBlock: 'space.150',
	listStyle: 'none',
});

const iconButtonStyles = xcss({
	paddingRight: 'space.050',
});

const lozengeStyle = xcss({
	flex: '0 0 auto',
});
