/**
 * @generated SignedSource<<aff872f23813c6502e18740786371e96>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutSingleSelectUserPickerField_IssueViewSingleSelectUserPickerField_rootRelayFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutAssigneeField_IssueViewAssigneeField_rootRelayFragment">;
  readonly " $fragmentType": "ui_issueViewLayoutSingleSelectUserPickerField_IssueViewSingleSelectUserPickerField_rootRelayFragment";
};
export type ui_issueViewLayoutSingleSelectUserPickerField_IssueViewSingleSelectUserPickerField_rootRelayFragment$key = {
  readonly " $data"?: ui_issueViewLayoutSingleSelectUserPickerField_IssueViewSingleSelectUserPickerField_rootRelayFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutSingleSelectUserPickerField_IssueViewSingleSelectUserPickerField_rootRelayFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutSingleSelectUserPickerField_IssueViewSingleSelectUserPickerField_rootRelayFragment",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutAssigneeField_IssueViewAssigneeField_rootRelayFragment"
    }
  ],
  "type": "Query"
};

(node as any).hash = "81bfbf08d5bb6cbda9b90569c9a03865";

export default node;
