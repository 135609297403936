import React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled, { css } from 'styled-components';
import { token } from '@atlaskit/tokens';
import EnterEscapeHandler from '@atlassian/jira-common-components-enter-escape-handler/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { FieldDescription } from '@atlassian/jira-issue-field-description/src/ui/index.tsx';
import { FieldHeading } from '@atlassian/jira-issue-field-heading/src/index.tsx';
import {
	SideBySideField,
	FieldWrapper,
	FieldHeadingTitle,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import {
	InlineEditContainer,
	ReadViewContainer,
} from '@atlassian/jira-issue-field-inline-edit/src/styled.tsx';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin/src/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import EditableField from './editable/view.tsx';
import messages from './messages.tsx';
import type { GroupOption, TeamFieldType } from './types.tsx';
import ViewField from './view-field/view.tsx';

export type Props = {
	isEditable: boolean;
	isEditing: boolean;
	isMobile: boolean;
	isSubTask: boolean;
	issueKey?: IssueKey; // TODO - make issueKey mandatory when cleaning up isFieldDescriptionEnabled,
	value: TeamFieldType;
	// eslint-disable-next-line jira/react/handler-naming
	fetchSuggestions: (query: string) => Promise<GroupOption[]>;
	portalElement?: HTMLElement;
	menuPlacement?: string;
	noValueText: string;
	fieldId: string;
	showPinButton?: boolean;
	onChange: (arg1: TeamFieldType | null) => void;
	onCancel: () => void;
	onConfirm: () => void;
	onEditRequest: () => void;
};

export type RenderEditableFieldProps = {
	isEditable: boolean;
	isSubTask: boolean;
	value: TeamFieldType;
	// eslint-disable-next-line jira/react/handler-naming
	fetchSuggestions: (query: string) => Promise<GroupOption[]>;
	portalElement?: HTMLElement;
	menuPlacement?: string;
	onChange: (arg1: TeamFieldType | null) => void;
	onConfirm: () => void;
};

const nonEditableInlineEditContainerStyles = css({
	marginLeft: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	[`${''} & > div`]: {
		margin: `${token('space.150')} 0 ${token('space.075')}`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	[`${ReadViewContainer}`]: {
		left: 0,
	},
});

export const RenderEditableField = (props: RenderEditableFieldProps) => {
	const {
		isEditable,
		isSubTask,
		fetchSuggestions,
		onChange,
		onConfirm,
		value,
		portalElement,
		menuPlacement,
	} = props;

	// Using an extra span to explicitly mark this as not included by our selector.
	// We are using the custom wrapper + selector as a workaround until we can move
	// to using inline edits in compact mode.
	// See: https://jdog.jira-dev.com/browse/BENTO-2979
	return isEditable && !isSubTask ? (
		<span>
			<EditableField
				value={value}
				loadOptions={fetchSuggestions}
				portalElement={portalElement}
				menuPlacement={menuPlacement}
				onChange={onChange}
				onConfirm={onConfirm}
			/>
		</span>
	) : null;
};

export const TeamField = (props: Props) => {
	const {
		isSubTask,
		fetchSuggestions,
		portalElement,
		menuPlacement,
		onChange,
		value,
		noValueText,
		isEditing,
		onConfirm,
		onCancel,
		onEditRequest,
		isMobile,
		fieldId,
		showPinButton,
		isEditable,
		issueKey,
	} = props;

	const { formatMessage } = useIntl();

	const label = formatMessage(messages.label);

	const renderReadField = (
		<ReadViewContainer>
			<ViewField noValueText={noValueText} value={value} />
		</ReadViewContainer>
	);

	return (
		<FieldWrapper>
			<FieldHeading>
				<FieldHeadingTitle>{label}</FieldHeadingTitle>
				{issueKey !== undefined && fieldId !== undefined && (
					<FieldDescription issueKey={issueKey} fieldKey={fieldId} label={label} />
				)}
				{showPinButton === true && <FieldPin fieldId={fieldId} label={label} />}
			</FieldHeading>
			<SideBySideField isEditing={isEditing}>
				<EnterEscapeHandler onEscape={onCancel}>
					{/* @ts-expect-error - TS2322 - Type '{ children: Element; hasValue: boolean; isEditable: boolean; }' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<Component<ThemedOuterStyledProps<ClassAttributes<HTMLDivElement> & HTMLAttributes<HTMLDivElement> & { ...; }, any>, any, any>> & Readonly<...> & Readonly<...>'. */}
					<TeamInlineEditContainer hasValue={!!value} isEditable={isEditable}>
						<FieldInlineEditStateLess
							isLabelHidden
							label={label}
							fieldId={fg('one_event_rules_them_all_fg') ? fieldId : undefined}
							readView={renderReadField}
							editView={
								<RenderEditableField
									isSubTask={isSubTask}
									fetchSuggestions={fetchSuggestions}
									portalElement={portalElement}
									menuPlacement={menuPlacement}
									onChange={onChange}
									onConfirm={onConfirm}
									isEditable={isEditable}
									value={value}
								/>
							}
							// @ts-expect-error - TS2322 - Property 'disableEditViewFieldBase' does not exist on type 'IntrinsicAttributes & FieldInlineEditStateLessProps<unknown>'.
							disableEditViewFieldBase
							areActionButtonsHidden
							isEditing={isEditing}
							onConfirm={onConfirm}
							onCancel={onCancel}
							onEditRequested={onEditRequest}
							isFitContainerWidthReadView={!isMobile}
							onEscape={onCancel}
							isEditable={isEditable}
							aria-label={label}
						/>
					</TeamInlineEditContainer>
				</EnterEscapeHandler>
			</SideBySideField>
		</FieldWrapper>
	);
};

export default TeamField;

// @ts-expect-error - TS2345: Argument of type 'StyledComponentClass'
// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TeamInlineEditContainer = styled<{ isEditable: boolean }>(InlineEditContainer)`
	${ReadViewContainer} {
		line-height: 20px;
	}

	${({ isEditable }) => !isEditable && nonEditableInlineEditContainerStyles}
`;
