import React from 'react';
import EmptyState from '@atlaskit/empty-state';
import { Box, xcss } from '@atlaskit/primitives';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import LockClosedImage from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/objects/components/lock-closed/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { ErrorDisplay } from '../../common/ui/error-display/index.tsx';
import { messages } from './messages.tsx';

const ErrorImage = () =>
	fg('replace_error_display_with_empty_state') ? (
		<Box xcss={imageWrapperStyles}>
			<LockClosedImage width={90} height={130} alt="" />
		</Box>
	) : (
		<LockClosedImage width={90} height={130} alt="" />
	);

export const PermissionErrorView = () => {
	const intl = useIntl();

	return fg('replace_error_display_with_empty_state') ? (
		<EmptyState
			testId="issue-view-errors.ui.permission-error-view.permission-error-view"
			header={intl.formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.permissionErrorHeaderIssueTermRefresh
					: messages.permissionErrorHeader,
			)}
			description={intl.formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.permissionErrorDescriptionIssueTermRefresh
					: messages.permissionErrorDescription,
			)}
			renderImage={() => <ErrorImage />}
		/>
	) : (
		<ErrorDisplay
			header={intl.formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.permissionErrorHeaderIssueTermRefresh
					: messages.permissionErrorHeader,
			)}
			description={intl.formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.permissionErrorDescriptionIssueTermRefresh
					: messages.permissionErrorDescription,
			)}
			image={<ErrorImage />}
		/>
	);
};

const imageWrapperStyles = xcss({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});
