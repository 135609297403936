import React, { useMemo, useCallback, useEffect } from 'react';
import { type OnEmbeddedContentAction, parseUrl } from '@atlassian/embedded-confluence';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import type { EmbeddedPageModalProps } from '@atlassian/jira-issue-create-confluence-content/src/common/types/types.tsx';
import { useParentProduct } from '@atlassian/jira-issue-create-confluence-content/src/controllers/use-parent-product/index.tsx';
import { draftEmbeddedPageModalEntrypoint } from '@atlassian/jira-issue-create-confluence-content/src/ui/draft-embedded-page-modal/entrypoint.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';

export interface EmbeddedConfluenceModalProps {
	activePageUrl: string;
	onClose: () => void;
	embeddedConfluenceSource: string;
	onEmbeddedContentAction?: OnEmbeddedContentAction;
	onPublish?: () => void;
	linkConfluencePage?: (link: string, analyticsEvent: UIAnalyticsEvent) => void;
}

export const EmbeddedConfluenceModal = ({
	activePageUrl,
	onClose,
	embeddedConfluenceSource,
	onEmbeddedContentAction,
	onPublish,
	linkConfluencePage,
}: EmbeddedConfluenceModalProps) => {
	const parentProduct = useParentProduct();
	const locale = useLocale();

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		draftEmbeddedPageModalEntrypoint,
		{},
	);

	const onCloseInternal = useCallback(() => {
		entryPointActions.unload();
		onClose();
	}, [onClose, entryPointActions]);

	const runtimeProps: EmbeddedPageModalProps = useMemo(
		() => ({
			...(fg('jira_issue_view_expand_modeless_ep') && { linkConfluencePage }),
			hasFooterLogo: false,
			isOpen: true,
			onClose: onCloseInternal,
			parentProduct,
			url: activePageUrl,
			embeddedConfluenceSource,
			spaceKey: parseUrl(activePageUrl)?.spaceKey,
			contentId: parseUrl(activePageUrl)?.contentId,
			onPageAction: onEmbeddedContentAction,
			allowedFeatures: {
				view: [
					'byline-contributors',
					'byline-extensions',
					'page-comments',
					'page-reactions',
					'inline-comments',
					'non-licensed-share',
				],
			},
			onPublish,
			locale,
		}),
		[
			onCloseInternal,
			parentProduct,
			activePageUrl,
			embeddedConfluenceSource,
			onEmbeddedContentAction,
			onPublish,
			linkConfluencePage,
			locale,
		],
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	return (
		<JiraEntryPointContainer
			entryPointReferenceSubject={entryPointReferenceSubject}
			id="epModalEntrypointContainer"
			packageName="@atlassian/jira-issue-content-placeholder-template"
			teamName="bento"
			runtimeProps={runtimeProps}
		/>
	);
};
