export const LOOM_BANNER_AD_CONTROL_DISMISS_KEY = 'loom-banner-ad-control-dismissed';
export const LOOM_QUICK_ADD_AD_CONTROL_DISMISS_KEY = 'loom-quick-add-ad-control-dismissed';
export const LOOM_TASK_BANNER_AD_CONTROL_DISMISS_KEY = 'loom-task-banner-ad-control-dismissed';

export const BANNER_LOCATIONS = {
	DESCRIPTION: 'Description',
	COMMENT: 'Comment',
	COMMENT_THREAD: 'CommentThread',
} as const;

export type BannerLocation =
	| typeof BANNER_LOCATIONS.DESCRIPTION
	| typeof BANNER_LOCATIONS.COMMENT
	| typeof BANNER_LOCATIONS.COMMENT_THREAD;

export const BANNER_CONTEXTS = {
	BUG: 'Bug',
	TASK: 'Task',
};

export type BannerContext = typeof BANNER_CONTEXTS.BUG | typeof BANNER_CONTEXTS.TASK;
