import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';

export const editIssueTypeExperience = new UFOExperience('issue-view.edit-issue-type', {
	type: ExperienceTypes.Operation,
	performanceType: ExperiencePerformanceTypes.Custom,
});

export const createIssueTypeExperience = new UFOExperience('issue-view.create-issue-type', {
	type: ExperienceTypes.Operation,
	performanceType: ExperiencePerformanceTypes.Custom,
});
