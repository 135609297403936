import React, { memo, useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useProjectKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { ErrorFlag } from '@atlassian/jira-issue-error-flag/src/index.tsx';
import { smoothScrollIntoCenterIfNeeded } from '@atlassian/jira-issue-view-common-utils/src/scroll/index.tsx';
import SquareAddIconButton from '@atlassian/jira-issue-view-common-views/src/button/square-add-icon-button.tsx';
import {
	SectionHeading,
	SectionHeadingIcons,
	SectionHeadingTitle,
} from '@atlassian/jira-issue-view-common/src/component/section-heading/section-heading-view.tsx';
import { useIsJCSProjectType } from '@atlassian/jira-servicedesk-customer-service-common/src/utils/use-is-jcs/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useForms } from '@atlassian/jira-proforma-panel-in-issue-view-services/src/services/forms-status-service/index.tsx';
import { ProformaFormsViewLazy } from '@atlassian/jira-proforma-panel-in-issue-view-ui/src/async.tsx';
import { useCanAdministerProject } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { useProjectType } from '@atlassian/jira-project-context-service/src/main.tsx';
import messages from './messages.tsx';

export const actionSubject = 'summaryInlineEdit';

type Props = {
	issueKey: string;
};

export const ProformaFormsPanelView = memo(({ issueKey }: Props) => {
	const projectKey = useProjectKey();
	const projectType = useProjectType(projectKey);
	const isProjectAdmin = useCanAdministerProject(projectKey);
	const formsContainer = useRef<HTMLDivElement>(null);
	const [panelAddFormButtonClickedCount, setPanelAddFormButtonClickedCount] = useState(0);
	const { formatMessage } = useIntl();

	const [
		{
			value: { hasIssueForms, issueViewAddFormButtonClickedCount },
			fieldConfig,
			error,
		},
		{ resetAddFormButtonCount },
	] = useForms({
		issueKey: toIssueKey(issueKey),
	});

	const isFormsEditable = useMemo(() => fieldConfig?.value?.isEditable || false, [fieldConfig]);

	useEffect(() => {
		if (issueViewAddFormButtonClickedCount > 0) {
			smoothScrollIntoCenterIfNeeded(formsContainer.current);
		}
	}, [issueViewAddFormButtonClickedCount]);

	useEffect(() => {
		// Close Add Form dropdown panel and reset local state if issue key changes
		resetAddFormButtonCount();
		setPanelAddFormButtonClickedCount(0);
	}, [issueKey, resetAddFormButtonCount]);

	const shouldShowFormsPanel = useMemo(() => {
		return issueViewAddFormButtonClickedCount > 0 ? true : hasIssueForms === true;
	}, [issueViewAddFormButtonClickedCount, hasIssueForms]);

	const onClickAddIconButton = useCallback(
		(_: React.MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
			setPanelAddFormButtonClickedCount((prevCount) => prevCount + 1);
			fireUIAnalytics(analyticsEvent, 'addFormIcon');
		},
		[],
	);

	const isJCS = useIsJCSProjectType(projectType);

	const attachedFormsHeader = useMemo(
		() => formatMessage(messages.attachedFormsHeader),
		[formatMessage],
	);

	const formHeader = useMemo(() => formatMessage(messages.formHeader), [formatMessage]);

	const addFormIconLabel = useMemo(() => formatMessage(messages.addFormIconLabel), [formatMessage]);

	const panelAddFormButtonClickedCountCombined = useMemo(() => {
		return issueViewAddFormButtonClickedCount + panelAddFormButtonClickedCount;
	}, [issueViewAddFormButtonClickedCount, panelAddFormButtonClickedCount]);

	if (error) {
		return (
			<ErrorFlag
				error={error}
				title={messages.useFormsErrorTitle}
				description={messages.useFormsErrorMessage}
			/>
		);
	}

	return shouldShowFormsPanel ? (
		<UFOSegment name="issue-proforma-forms">
			<div
				ref={formsContainer}
				/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
				data-testid="issue.views.issue-base.content.proforma-forms"
			>
				<SectionHeading>
					{isJCS ? (
						<SectionHeadingTitle>{formHeader}</SectionHeadingTitle>
					) : (
						<SectionHeadingTitle>{attachedFormsHeader}</SectionHeadingTitle>
					)}
					<SectionHeadingIcons>
						{isFormsEditable && (
							<SquareAddIconButton label={addFormIconLabel} onClick={onClickAddIconButton} />
						)}
					</SectionHeadingIcons>
				</SectionHeading>
				<ProformaFormsViewContainer>
					<ProformaFormsViewLazy
						canEditIssues={isFormsEditable}
						isProjectAdmin={isProjectAdmin}
						panelAddFormButtonClickedCount={panelAddFormButtonClickedCountCombined}
					/>
				</ProformaFormsViewContainer>
			</div>
		</UFOSegment>
	) : null;
});

export const ProformaFormsPanelViewErrorWrapper = ({ issueKey }: Props) => (
	<JSErrorBoundary
		id="ProformaFormsPanelViewBoundary"
		packageName="jiraIssueViewBase"
		teamName="jsm-request-intake"
		/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
		data-testid="issue.views.issue-base.content.proforma-forms.dependency-line"
	>
		<ProformaFormsPanelView issueKey={issueKey} />
	</JSErrorBoundary>
);

export default memo<Props>(ProformaFormsPanelViewErrorWrapper);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProformaFormsViewContainer = styled.div({
	paddingTop: `${token('space.100')}`,
	paddingBottom: `${token('space.500')}`,
});
