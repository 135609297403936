/**
 * @generated SignedSource<<7d901e334a037138c747b1cb1cbff2f4>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type JiraProjectStyle = "COMPANY_MANAGED_PROJECT" | "TEAM_MANAGED_PROJECT" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcherWithRefetch$data = {
  readonly __typename: "JiraIssueTypeField";
  readonly fieldId: string;
  readonly id: string;
  readonly issue: {
    readonly issueId: string;
    readonly projectField: {
      readonly project: {
        readonly projectStyle: JiraProjectStyle | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly issueType: {
    readonly avatar: {
      readonly xsmall: string | null | undefined;
    } | null | undefined;
    readonly hierarchy: {
      readonly level: number | null | undefined;
    } | null | undefined;
    readonly id: string;
    readonly issueTypeId: string | null | undefined;
    readonly name: string;
  } | null | undefined;
  readonly issueTypes: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly avatar: {
          readonly xsmall: string | null | undefined;
        } | null | undefined;
        readonly hierarchy: {
          readonly level: number | null | undefined;
        } | null | undefined;
        readonly id: string;
        readonly issueTypeId: string | null | undefined;
        readonly name: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly type: string;
  readonly " $fragmentType": "ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcherWithRefetch";
};
export type ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcherWithRefetch$key = {
  readonly " $data"?: ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcherWithRefetch$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcherWithRefetch">;
};

import issueViewLayoutIssueTypeSwitcherRefetchQuery_graphql from './issueViewLayoutIssueTypeSwitcherRefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
},
v1 = {
  "kind": "ScalarField",
  "name": "name"
},
v2 = {
  "kind": "ScalarField",
  "name": "issueTypeId"
},
v3 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "xsmall"
    }
  ]
},
v4 = {
  "concreteType": "JiraIssueTypeHierarchyLevel",
  "kind": "LinkedField",
  "name": "hierarchy",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "level"
    }
  ]
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "fragmentPathInResult": [
        "node"
      ],
      "operation": issueViewLayoutIssueTypeSwitcherRefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "ui_issueViewLayoutIssueTypeSwitcher_IssueTypeSwitcherWithRefetch",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "issue",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "issueId"
        },
        {
          "concreteType": "JiraProjectField",
          "kind": "LinkedField",
          "name": "projectField",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraProject",
              "kind": "LinkedField",
              "name": "project",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "projectStyle"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "concreteType": "JiraIssueType",
      "kind": "LinkedField",
      "name": "issueType",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/)
      ]
    },
    {
      "concreteType": "JiraIssueTypeConnection",
      "kind": "LinkedField",
      "name": "issueTypes",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraIssueTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraIssueType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/),
                (v1/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssueTypeField"
};
})();

(node as any).hash = "7b39cce63227cc91be2a29e2494bfb4d";

export default node;
