import { createSelector } from 'reselect';
import {
	accountIdloggedInUserSelector,
	baseUrlSelector,
	cloudIdSelector,
	issueKeySelector,
	orgIdSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { projectTypeSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import IssueUserTeamMentionProviderWithResolver from './issue-user-team-mention-provider-with-resolver.tsx';

export const mentionProviderSelector = createSelector(
	baseUrlSelector,
	issueKeySelector,
	accountIdloggedInUserSelector,
	projectTypeSelector,
	orgIdSelector,
	cloudIdSelector,
	(baseUrl, issueKey, loggedInAccountId, projectType, orgId, cloudId) =>
		new IssueUserTeamMentionProviderWithResolver(
			baseUrl,
			issueKey,
			loggedInAccountId,
			`${baseUrl}/jira/people/search#createTeam`,
			projectType,
			orgId,
			cloudId,
		),
);
