import head from 'lodash/head';
import type {
	LocalDecorationId,
	ValueDecoration,
} from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import {
	generateLocalDecorationId,
	isDecorationWithLogic,
	isDecorationWithRules,
} from '@atlassian/jira-polaris-domain-field/src/decoration/utils.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { isGlobalSystemField } from '@atlassian/jira-polaris-domain-field/src/field/utils.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';
import {
	dualWriteValueDecorationsBasedOnFieldType,
	performJiraConfigurationUpdate,
} from './utils.tsx';

type CreateValueDecorationShared = {
	fieldKey: FieldKey;
};

type SimpleUnionOmit<T, K extends string | number | symbol> = T extends unknown
	? Omit<T, K>
	: never;

type CreateValueDecorationProps = CreateValueDecorationShared &
	SimpleUnionOmit<ValueDecoration, 'localDecorationId' | 'backendId'>;

type CreateValueDecorationBulkProps = CreateValueDecorationShared & {
	decorations: SimpleUnionOmit<ValueDecoration, 'localDecorationId' | 'backendId'>[];
};

export const createValueDecoration =
	(createProps: CreateValueDecorationProps) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ fieldRemote, onDecorationUpdateFailed, createAnalyticsEvent, issueTypeIds }: Props,
	): Promise<LocalDecorationId> => {
		const { fieldKey, backgroundColor, highlightContainer, emoji } = createProps;
		const rules = isDecorationWithRules(createProps) ? createProps.rules : [];
		const logic = isDecorationWithLogic(createProps) ? createProps.logic : undefined;
		const state = getState();
		const { fieldValueDecorations, fields } = state;
		const field = fields[fieldKey];
		const existingValueDecorations = fieldValueDecorations[fieldKey] || [];

		const issueTypeId = head(issueTypeIds);

		const localDecorationId = generateLocalDecorationId();
		const newValueDecorations = [...existingValueDecorations];

		newValueDecorations.push({
			localDecorationId,
			backgroundColor,
			emoji,
			highlightContainer,
			...(logic ? { logic } : { rules }),
		});

		setState({
			fieldValueDecorations: {
				...fieldValueDecorations,
				[fieldKey]: newValueDecorations,
			},
		});

		try {
			try {
				await performJiraConfigurationUpdate({
					fieldKey,
					fieldType: field.type,
					issueTypeId,
					fieldRemote,
					existingDecorations: existingValueDecorations,
					newDecorations: newValueDecorations,
					isGlobalSystemField: isGlobalSystemField(field),
				});

				fireCompoundAnalyticsEvent.FieldConfig.valueDecorationChanged(createAnalyticsEvent({}), {
					issueFieldKey: fieldKey,
					issueFieldType: field.type,
				});
			} catch (error) {
				setState({
					fieldValueDecorations: {
						...getState().fieldValueDecorations,
						[fieldKey]: existingValueDecorations,
					},
				});

				throw error;
			}

			if (field.type === FIELD_TYPES.LINKED_ISSUES) {
				await dualWriteValueDecorationsBasedOnFieldType({
					fieldKey,
					fieldType: field.type,
					issueTypeId,
					fieldRemote,
					newValueDecorations,
					getState,
					setState,
				});
			}
		} catch (error) {
			onDecorationUpdateFailed(
				error instanceof Error ? error : new Error('Failed to create value decoration'),
			);
		}

		return localDecorationId;
	};

export const createValueDecorationBulk =
	(createProps: CreateValueDecorationBulkProps) =>
	(stateParams: StoreActionApi<State>, propParams: Props): LocalDecorationId[] => {
		const { getState, setState } = stateParams;
		const { fieldRemote, onDecorationUpdateFailed, createAnalyticsEvent, issueTypeIds } =
			propParams;

		const { fieldKey, decorations } = createProps;
		const state = getState();
		const { fieldValueDecorations, fields } = state;
		const field = fields[fieldKey];

		const existingValueDecorations = fieldValueDecorations[fieldKey] || [];
		const issueTypeId = head(issueTypeIds);
		const newValueDecorations = [...existingValueDecorations];
		const localDecorationIds: string[] = [];

		decorations.forEach((decoration) => {
			const { backgroundColor, highlightContainer, emoji } = decoration;
			const localDecorationId = generateLocalDecorationId();
			const rules = isDecorationWithRules(decoration) ? decoration.rules : [];
			const logic = isDecorationWithLogic(decoration) ? decoration.logic : undefined;

			localDecorationIds.push(localDecorationId);
			newValueDecorations.push({
				localDecorationId,
				backgroundColor,
				emoji,
				highlightContainer,
				...(logic ? { logic } : { rules }),
			});
		});

		performJiraConfigurationUpdate({
			fieldKey,
			fieldType: field.type,
			issueTypeId,
			fieldRemote,
			existingDecorations: existingValueDecorations,
			newDecorations: newValueDecorations,
			isGlobalSystemField: isGlobalSystemField(field),
		}).catch((error: Error) => {
			onDecorationUpdateFailed(error);
		});

		setState({
			fieldValueDecorations: {
				...fieldValueDecorations,
				[fieldKey]: newValueDecorations,
			},
		});

		fireCompoundAnalyticsEvent.FieldConfig.valueDecorationChanged(createAnalyticsEvent({}), {
			issueFieldKey: fieldKey,
			issueFieldType: field.type,
		});

		return localDecorationIds;
	};
