import { useCallback, useMemo } from 'react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { CANNED_COMMENTS_KEY } from '../../common/constants.tsx';
import messages from '../../common/messages.tsx';
import { isCannedCommentInContent } from './utils.tsx';

export const useCannedCommentAnalyticsAttributes = () => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const cannedComments = useMemo(
		() =>
			CANNED_COMMENTS_KEY.map((key) =>
				`${key.emoji.text} ${formatMessage(messages[key.commentKey])}`.trim(),
			),
		[formatMessage],
	);

	const getCannedCommentAnalyticsAttributes = useCallback(
		(doc: ADF) => {
			// The experiment is already finished, so we don't care firing exposure event here
			const { cannedCommentExactMatch, cannedCommentUsed } = expVal(
				'smart_replies_m1',
				'isEnabled',
				false,
			)
				? isCannedCommentInContent(doc, cannedComments, createAnalyticsEvent({}))
				: {
						cannedCommentExactMatch: undefined,
						cannedCommentUsed: undefined,
					};

			return { cannedCommentUsed, cannedCommentExactMatch };
		},
		[cannedComments, createAnalyticsEvent],
	);

	return { getCannedCommentAnalyticsAttributes };
};
