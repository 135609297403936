/**
 * @generated SignedSource<<137ced9faeedd341ecb134562898a6de>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutAssigneeField_IssueViewAssigneeField_rootRelayFragment$data = {
  readonly me: {
    readonly user: {
      readonly " $fragmentSpreads": FragmentRefs<"CommandPaletteAssignee_me_user">;
    } | null | undefined;
  };
  readonly " $fragmentSpreads": FragmentRefs<"AssignToMeInlineEdit">;
  readonly " $fragmentType": "ui_issueViewLayoutAssigneeField_IssueViewAssigneeField_rootRelayFragment";
};
export type ui_issueViewLayoutAssigneeField_IssueViewAssigneeField_rootRelayFragment$key = {
  readonly " $data"?: ui_issueViewLayoutAssigneeField_IssueViewAssigneeField_rootRelayFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutAssigneeField_IssueViewAssigneeField_rootRelayFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutAssigneeField_IssueViewAssigneeField_rootRelayFragment",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "AssignToMeInlineEdit"
    },
    {
      "concreteType": "AuthenticationContext",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "CommandPaletteAssignee_me_user"
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "3cf59ed921af46c99e69584f2a85fb36";

export default node;
