import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

export function DragPreview({
	iconUrl,
	issueKey,
}: {
	/**
	 * This image should be inline or preloaded, otherwise it might not be
	 * loaded in time for the `onGenerateDragPreview` snapshot.
	 */
	iconUrl?: string | null;
	issueKey?: string;
}) {
	return (
		<Container>
			{iconUrl && (
				<img
					src={iconUrl}
					/**
					 * This preview won't be read by assistive technology,
					 * so can be treated as purely decorative.
					 */
					alt=""
				/>
			)}
			<Key>{issueKey}</Key>
		</Container>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	backgroundColor: token('elevation.surface.overlay'),
	borderRadius: token('border.radius'),
	display: 'flex',
	alignItems: 'center',
	paddingTop: token('space.100'),
	paddingRight: token('space.100'),
	paddingBottom: token('space.100'),
	paddingLeft: token('space.100'),
	gap: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Key = styled.span({
	color: token('color.link'),
	font: token('font.heading.xxsmall'),
	fontWeight: token('font.weight.medium'),
	whiteSpace: 'nowrap',
});
