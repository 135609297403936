import React, { useEffect, useMemo, useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import { JiraIssueAri } from '@atlassian/ari/jira/issue';
import { JiraSiteAri } from '@atlassian/ari/jira/site';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { designPanelEntryPoint } from '@atlassian/jira-issue-design-section/entrypoint.tsx';
import DesignPanelWrapper from '@atlassian/jira-issue-design-section/src/ui/index.tsx';
import type { MainIssueAggQueryRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { src_issueViewBase_AsyncDesignPanelExperiment$key } from '@atlassian/jira-relay/src/__generated__/src_issueViewBase_AsyncDesignPanelExperiment.graphql.tsx';

export const AsyncDesignPanel = ({
	shouldRenderDesignsPanel = true,
}: {
	shouldRenderDesignsPanel?: boolean;
}) => {
	const [isReadyToLoad, setIsReadyToLoad] = useState(false);
	const cloudId = useCloudId();
	const issueId = useIssueId();

	const entryPointParams = useMemo(() => {
		if (!issueId) {
			return { siteAri: '', cloudId: '', issueAri: '' };
		}
		const issueAri = JiraIssueAri.create({ issueId, siteId: cloudId }).toString();
		const siteAri = JiraSiteAri.create({ siteId: cloudId }).toString();
		setIsReadyToLoad(true);
		return { siteAri, cloudId, issueAri };
	}, [cloudId, issueId]);

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		designPanelEntryPoint,
		entryPointParams,
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		if (isReadyToLoad && shouldRenderDesignsPanel) {
			entryPointActions.load();
			setIsReadyToLoad(false);
		}
	}, [isReadyToLoad, entryPointActions, shouldRenderDesignsPanel]);

	const runtimeProps = useMemo(() => ({ isExpanded: true }), []);

	return (
		<JiraEntryPointContainer
			entryPointReferenceSubject={entryPointReferenceSubject}
			id="jiraIssueDesignPanel"
			packageName="jiraIssueViewBase"
			errorFallback="unmount"
			teamName="helios-headcoach"
			runtimeProps={runtimeProps}
		/>
	);
};

export const AsyncDesignPanelExperiment = ({
	rootRelayFragment,
}: {
	rootRelayFragment: MainIssueAggQueryRelayFragment;
}) => {
	const data = useFragment<src_issueViewBase_AsyncDesignPanelExperiment$key>(
		graphql`
			fragment src_issueViewBase_AsyncDesignPanelExperiment on Query {
				devOps @optIn(to: "DevOpsSecurityInJira") {
					providers(id: $siteAri, providerTypes: [DESIGN]) {
						designProviders {
							supportedActions {
								getEntityByUrl
								checkAuth
							}
						}
					}
				}
			}
		`,
		rootRelayFragment,
	);

	const shouldRenderDesignsPanel = useMemo(() => {
		const designProviders = data.devOps?.providers?.designProviders ?? [];

		const associateEntitySupportedProviders = designProviders.filter((provider) =>
			provider != null && provider.supportedActions != null
				? provider.supportedActions.getEntityByUrl && provider.supportedActions.checkAuth
				: false,
		);
		return associateEntitySupportedProviders.length > 0;
	}, [data.devOps?.providers?.designProviders]);

	return <AsyncDesignPanel shouldRenderDesignsPanel={shouldRenderDesignsPanel} />;
};

export type SyncDesignPanelProps = {
	rootRelayFragment: MainIssueAggQueryRelayFragment | null;
};

export const SyncDesignPanel = ({ rootRelayFragment }: SyncDesignPanelProps) => {
	const cloudId = useCloudId();
	const issueId = useIssueId();
	const issueAri =
		issueId != null ? JiraIssueAri.create({ issueId, siteId: cloudId }).toString() : '';

	if (rootRelayFragment == null) {
		return null;
	}

	return (
		<DesignPanelWrapper
			isExpanded={false}
			issueAri={issueAri}
			rootRelayFragment={rootRelayFragment}
		/>
	);
};
