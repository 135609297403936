export type ClipboardValidationErrorType =
	| 'copyOperationUnsupported'
	| 'cutOperationUnsupported'
	| 'cutNonEditableError'
	| 'pasteOperationUnsupported'
	| 'pasteUnexpectedComponentType'
	| 'pasteUnsupportedFormat';

/** Represents a validation error that occurs when parsing/serialising component data to the Clipboard API. */
export class ClipboardValidationError extends Error {
	type: ClipboardValidationErrorType;

	constructor(type: ClipboardValidationErrorType, message: string) {
		super(message);
		this.name = 'ClipboardValidationError';
		this.type = type;
	}

	static copyOperationUnsupported(): ClipboardValidationError {
		return new ClipboardValidationError(
			'copyOperationUnsupported',
			'Copy operation not supported.',
		);
	}

	static cutOperationUnsupported(): ClipboardValidationError {
		return new ClipboardValidationError('cutOperationUnsupported', 'Cut operation not supported.');
	}

	static cutNonEditableError(actual: string): ClipboardValidationError {
		return new ClipboardValidationError(
			'cutNonEditableError',
			`Received component type '${actual}' cannot be cut.`,
		);
	}

	static pasteOperationUnsupported(): ClipboardValidationError {
		return new ClipboardValidationError(
			'pasteOperationUnsupported',
			'Paste operation not supported.',
		);
	}

	static pasteUnexpectedComponentType(actual: string, expected: string): ClipboardValidationError {
		return new ClipboardValidationError(
			'pasteUnexpectedComponentType',
			`Received component type '${actual}' when '${expected}' was expected.`,
		);
	}

	static pasteUnsupportedFormat(format: string): ClipboardValidationError {
		return new ClipboardValidationError(
			'pasteUnsupportedFormat',
			`Unsupported data transfer format '${format}'.`,
		);
	}
}
