import React, { forwardRef, type Ref } from 'react';
import { styled } from '@compiled/react';
import type { EntryPointProps } from 'react-relay';
import { useIntl } from '@atlassian/jira-intl';
import { SingleLineTextEditView } from '@atlassian/jira-issue-field-single-line-text-editview-full/src/ui/single-line-text/index.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import messages from './messages.tsx';
import type { OriginalEstimateEditViewWithFormattedValueProps } from './types.tsx';

const compactWidthPixels = `${gridSize * 14.5}px`;

export const OriginalEstimateEditViewWithFormattedValue = forwardRef(
	(
		{
			'aria-describedby': ariaDescribedby,
			autoFocus = true,
			value,
			onChange,
			isDisabled = false,
			isInvalid = false,
			placeholder = '',
			spacing,
			invalidMessage,
			onFocus,
			onBlur,
		}: OriginalEstimateEditViewWithFormattedValueProps,
		ref: Ref<HTMLInputElement>,
	) => {
		const { formatMessage } = useIntl();

		if (fg('jsc_inline_editing_field_refactor')) {
			return (
				<SingleLineTextEditView
					aria-describedby={ariaDescribedby}
					ref={ref}
					isDisabled={isDisabled}
					isInvalid={isInvalid}
					onBlur={onBlur}
					autoFocus={autoFocus}
					onFocus={onFocus}
					spacing={spacing}
					value={value}
					placeholder={placeholder}
					onChange={onChange}
					invalidMessage={invalidMessage}
					ariaLabel={formatMessage(messages.label)}
				/>
			);
		}

		return (
			<OriginalEstimateEditContainer
				data-testid="issue-field-original-estimate-editview-full.ui.original-estimate.original-estimate-with-formatted-value.edit"
				isCompact={spacing === 'compact'}
			>
				<SingleLineTextEditView
					ref={ref}
					isDisabled={isDisabled}
					isInvalid={isInvalid}
					onBlur={onBlur}
					autoFocus={autoFocus}
					onFocus={onFocus}
					spacing={spacing}
					value={value}
					placeholder={placeholder}
					onChange={onChange}
					invalidMessage={invalidMessage}
					ariaLabel={formatMessage(messages.label)}
				/>
			</OriginalEstimateEditContainer>
		);
	},
);

const OriginalEstimateEditViewWithFormattedValueEntryPoint = ({
	props,
}: EntryPointProps<{}, {}, OriginalEstimateEditViewWithFormattedValueProps, {}>) => (
	<OriginalEstimateEditViewWithFormattedValue {...props} />
);

export default OriginalEstimateEditViewWithFormattedValueEntryPoint;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const OriginalEstimateEditContainer = styled.div<{ isCompact: boolean }>({
	cursor: 'auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ isCompact }) => (isCompact ? compactWidthPixels : '100%'),
});
