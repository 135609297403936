/**
 * @generated SignedSource<<9a92093530951b240bc66c77f11417f9>>
 * @relayHash 2b71fc746331359b106fb95c3e29a341
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f0ea23ecb96f1ebbf832480f95d0e3e529c379b376fcf52770efc277df3892b3

import type { ConcreteRequest, Query } from 'relay-runtime';
export type dataForRecommendationsInEditorQuery$variables = {
  cloudId: string;
  issueKey: string;
  maxResults: number;
  organizationId: string;
  query: string;
  sessionId: string;
};
export type dataForRecommendationsInEditorQuery$data = {
  readonly jira: {
    readonly searchUserTeamMention: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly user: {
            readonly __typename: string;
            readonly accountId: string;
            readonly name: string;
            readonly picture: AGG$URL;
          } | null | undefined;
        };
      } | null | undefined>;
    } | null | undefined;
  } | null | undefined;
};
export type dataForRecommendationsInEditorQuery = {
  response: dataForRecommendationsInEditorQuery$data;
  variables: dataForRecommendationsInEditorQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueKey"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "maxResults"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sessionId"
},
v6 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "issueKey",
    "variableName": "issueKey"
  },
  {
    "kind": "Variable",
    "name": "maxResults",
    "variableName": "maxResults"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  },
  {
    "kind": "Variable",
    "name": "sessionId",
    "variableName": "sessionId"
  }
],
v7 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v8 = {
  "kind": "ScalarField",
  "name": "name"
},
v9 = {
  "kind": "ScalarField",
  "name": "picture"
},
v10 = {
  "kind": "ScalarField",
  "name": "__typename"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "name": "dataForRecommendationsInEditorQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v6/*: any*/),
            "concreteType": "JiraMentionableConnection",
            "kind": "LinkedField",
            "name": "searchUserTeamMention",
            "plural": false,
            "selections": [
              {
                "kind": "RequiredField",
                "field": {
                  "concreteType": "JiraMentionableEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": {
                        "concreteType": "JiraMentionable",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              (v8/*: any*/),
                              (v9/*: any*/),
                              (v10/*: any*/)
                            ]
                          }
                        ]
                      },
                      "action": "THROW",
                      "path": "jira.searchUserTeamMention.edges.node"
                    }
                  ]
                },
                "action": "THROW",
                "path": "jira.searchUserTeamMention.edges"
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "dataForRecommendationsInEditorQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v6/*: any*/),
            "concreteType": "JiraMentionableConnection",
            "kind": "LinkedField",
            "name": "searchUserTeamMention",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraMentionableEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraMentionable",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "id"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "f0ea23ecb96f1ebbf832480f95d0e3e529c379b376fcf52770efc277df3892b3",
    "metadata": {},
    "name": "dataForRecommendationsInEditorQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "96de78460558597c7895447069471dce";

export default node;
