import React from 'react';

import Pagination from '@atlaskit/pagination';
import { Flex, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';

import { useGridContext } from '../context/index.tsx';
import messages from './messages.tsx';

const PaginationComponent = ({ isDisabled }: { isDisabled?: boolean }) => {
	const { formatMessage } = useIntl();
	const { totalPages, currentPage, updatePageNumber } = useGridContext();

	return (
		<Flex
			justifyContent="center"
			xcss={paginationWrapperStyle}
			testId="issue-view-base.content.attachment.grid-view.ui.components.pagination"
		>
			<Pagination
				selectedIndex={currentPage - 1}
				isDisabled={isDisabled}
				nextLabel={formatMessage(messages.paginationNext)}
				label={formatMessage(messages.paginationLabel)}
				pageLabel={formatMessage(messages.paginationLabel)}
				pages={totalPages}
				onChange={(_, page) => updatePageNumber(page)}
				previousLabel={formatMessage(messages.paginationPrevious)}
			/>
		</Flex>
	);
};

export default PaginationComponent;

const paginationWrapperStyle = xcss({
	marginTop: 'space.150',
});
