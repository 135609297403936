import { defineMessages } from '@atlassian/jira-intl';

export const itsmTemplate18 = defineMessages({
	trigger: {
		id: 'automation-discovery.common.itsm_template_18.trigger',
		defaultMessage: 'When an incident is created',
		description: 'Trigger definition for incident created',
	},
	action: {
		id: 'automation-discovery.common.itsm_template_18.action',
		defaultMessage: 'Launch a job template in Ansible',
		description: 'Action definition for Ansible',
	},
	actionNext: {
		id: 'automation-discovery.common.itsm_template_18.action_next',
		defaultMessage: 'Attach the response to the incident',
		description: 'Action definition for attach the response to the incident',
	},
	headerText: {
		id: 'automation-discovery.common.itsm_template_18.header_text',
		defaultMessage: 'Create automation rules to trigger the Ansible job template',
		description: 'Automation can auto trigger Ansible job template',
	},
	footerText: {
		id: 'automation-discovery.common.itsm_template_18.footer_text',
		defaultMessage:
			'Your team can save up to <b>{savedMinutes, plural, one {# minute} other {# minutes}} per month</b> with this rule! {learn}',
		description: 'One can save upto 100 minutes',
	},
	footerTextNonAdmin: {
		id: 'automation-discovery.common.itsm_template_18.footer_text_non_admin',
		defaultMessage:
			'Automating this rule can save you up to <b>{savedMinutes, plural, one {# minute} other {# minutes}} per month</b>. Copy and send this message to your admin to request this rule. {learn}',
		description: 'One can save upto 100 minutes',
	},
	learnMoreText: {
		id: 'automation-discovery.common.itsm_template_18.learn_more_text.non-final',
		defaultMessage: 'More about automation with Ansible',
		description: 'Learn More text for Ansible',
	},
});

export const itsmTemplate19 = defineMessages({
	trigger: {
		id: 'automation-discovery.common.itsm_template_19.trigger',
		defaultMessage: 'When an alert is created',
		description: 'Trigger definition for alert created',
	},
	action: {
		id: 'automation-discovery.common.itsm_template_19.action',
		defaultMessage: 'Check for New Relic data',
		description: 'Action definition for New relic',
	},
	actionNext: {
		id: 'automation-discovery.common.itsm_template_19.action_next',
		defaultMessage: 'Run AWS SSM document',
		description: 'Action definition for running aws document',
	},
	actionNext2: {
		id: 'automation-discovery.common.itsm_template_19.action_next_2',
		defaultMessage: 'Close the alert',
		description: 'Action definition for closing the alert',
	},
	headerText: {
		id: 'automation-discovery.common.itsm_template_19.header_text',
		defaultMessage: 'Create automation rules to trigger AWS documents',
		description: 'Automation can auto trigger Ansible job template',
	},
	headerTextNewrelic: {
		id: 'automation-discovery.common.itsm_template_19.header_text_new_relic',
		defaultMessage: 'Set up automation rule to get New Relic data and run remediation',
		description: 'Automation can get New relic data and run remediation',
	},
	footerTextAws: {
		id: 'automation-discovery.common.itsm_template_19.footer_text_aws',
		defaultMessage:
			'Your team can save up to <b>{savedMinutes, plural, one {# minute} other {# minutes}} per month</b> with this rule! {learn}',
		description: 'One can save upto 100 minutes',
	},
	footerTextNr: {
		id: 'automation-discovery.common.itsm_template_19.footer_text_nr',
		defaultMessage:
			'Your team can save up to <b>{savedMinutes, plural, one {# minute} other {# minutes}} per month</b> with this rule! {learn}',
		description: 'One can save upto 100 minutes',
	},
	footerTextAwsNonAdmin: {
		id: 'automation-discovery.common.itsm_template_19.footer_text_aws_non_admin',
		defaultMessage:
			'Automating this rule can save you up to <b>{savedMinutes, plural, one {# minute} other {# minutes}} per month</b>. Copy and send this message to your admin to request this rule. {learn}',
		description: 'One can save upto 100 minutes',
	},
	footerTextNrNonAdmin: {
		id: 'automation-discovery.common.itsm_template_19.footer_text_nr_non_admin',
		defaultMessage:
			'Automating this rule can save you up to <b>{savedMinutes, plural, one {# minute} other {# minutes}} per month</b>. Copy and send this message to your admin to request this rule. {learn}',
		description: 'One can save upto 100 minutes',
	},
	learnMoreTextAws: {
		id: 'automation-discovery.common.itsm_template_19.learn_more_text_aws',
		defaultMessage: 'More about automation with AWS',
		description: 'Learn More text for aws',
	},
	learnMoreTextNr: {
		id: 'automation-discovery.common.itsm_template_19.learn_more_text_nr',
		defaultMessage: 'More about automation with New Relic',
		description: 'Learn More text for new relic',
	},
});

export const itsmTemplate21 = defineMessages({
	trigger: {
		id: 'automation-discovery.common.itsm_template_21.trigger',
		defaultMessage: 'When an incident is created',
		description: 'Trigger definition for incident created',
	},
	action: {
		id: 'automation-discovery.common.itsm_template_21.action',
		defaultMessage: 'Create MS Team chat or meeting',
		description: 'Action definition for MSteam',
	},
	headerText: {
		id: 'automation-discovery.common.itsm_template_21.header_text',
		defaultMessage: 'Create automation rules to schedule Microsoft Teams meetings or group chats',
		description: 'Header for Microsoft teams meeting',
	},
	footerText: {
		id: 'automation-discovery.common.itsm_template_21.footer_text',
		defaultMessage:
			'Your team can save up to <b>{savedMinutes, plural, one {# minute} other {# minutes}} per month</b> with this rule! {learn}',
		description: 'One can save upto 3000 minutes',
	},
	footerTextNonAdmin: {
		id: 'automation-discovery.common.itsm_template_21.footer_text_non_admin',
		defaultMessage:
			'Automating this rule can save you up to <b>{savedMinutes, plural, one {# minute} other {# minutes}} per month</b>. Copy and send this message to your admin to request this rule. {learn}',
		description: 'One can save upto 3000 minutes',
	},
	learnMoreText: {
		id: 'automation-discovery.common.itsm_template_21.learn_more_text',
		defaultMessage: 'More about automation',
		description: 'Learn More text for msteams',
	},
});

export const itsmTemplate20 = defineMessages({
	trigger: {
		id: 'automation-discovery.common.itsm_template_20.trigger',
		defaultMessage: 'When an incident is created',
		description: 'Trigger definition for incident created',
	},
	action: {
		id: 'automation-discovery.common.itsm_template_20.action',
		defaultMessage: 'Create Slack channel',
		description: 'Action definition for slack',
	},
	headerText: {
		id: 'automation-discovery.common.itsm_template_20.header_text',
		defaultMessage: 'Create automation rules to create a Slack channel',
		description: 'Header for Slack channel creation',
	},
	footerText: {
		id: 'automation-discovery.common.itsm_template_20.footer_text',
		defaultMessage:
			'Your team can save up to <b>{savedMinutes, plural, one {# minute} other {# minutes}} per month</b> with this rule! {learn}',
		description: 'One can save upto 100 minutes',
	},
	footerTextNonAdmin: {
		id: 'automation-discovery.common.itsm_template_20.footer_text_non_admin',
		defaultMessage:
			'Automating this rule can save you up to <b>{savedMinutes, plural, one {# minute} other {# minutes}} per month</b>. Copy and send this message to your admin to request this rule. {learn}',
		description: 'One can save upto 100 minutes',
	},
	learnMoreText: {
		id: 'automation-discovery.common.itsm_template_20.learn_more_text',
		defaultMessage: 'More about automation',
		description: 'Learn More text for slack',
	},
});

export const itsmTemplate15 = defineMessages({
	trigger: {
		id: 'automation-discovery.common.itsm_template_15.trigger',
		defaultMessage: 'When an incident is created',
		description: 'Trigger definition for incident created',
	},
	action: {
		id: 'automation-discovery.common.itsm_template_15.action',
		defaultMessage: 'Send email',
		description: 'Action definition for email',
	},
	actionNext: {
		id: 'automation-discovery.common.itsm_template_15.action_next',
		defaultMessage: 'Send Slack message',
		description: 'Action definition for slack',
	},
	headerText: {
		id: 'automation-discovery.common.itsm_template_15.header_text',
		defaultMessage: 'Set up automation rules to send email and slack message',
		description: 'Header for Slack message',
	},
	footerText: {
		id: 'automation-discovery.common.itsm_template_15.footer_text',
		defaultMessage:
			'Your team can save up to <b>{savedMinutes, plural, one {# minute} other {# minutes}} per month</b> with this rule! {learn}',
		description: 'One can save upto 100 minutes',
	},
	footerTextNonAdmin: {
		id: 'automation-discovery.common.itsm_template_15.footer_text_non_admin',
		defaultMessage:
			'Automating this rule can save you up to <b>{savedMinutes, plural, one {# minute} other {# minutes}} per month</b>. Copy and send this message to your admin to request this rule. {learn}',
		description: 'One can save upto 100 minutes',
	},
	learnMoreText: {
		id: 'automation-discovery.common.itsm_template_15.learn_more_text',
		defaultMessage: 'More about automation',
		description: 'Learn More text for msteams',
	},
});

export const itsmTemplate24 = defineMessages({
	trigger: {
		id: 'automation-discovery.common.itsm_template_24.trigger',
		defaultMessage: 'When an incident is created',
		description: 'Trigger definition for issue created',
	},
	action: {
		id: 'automation-discovery.common.itsm_template_24.action',
		defaultMessage: 'Check for New Relic data',
		description: 'Action definition for New Relic Data',
	},
	actionNext: {
		id: 'automation-discovery.common.itsm_template_24.action_next',
		defaultMessage: 'Run the AWS SSM document',
		description: 'Action definition for run the AWS SSM document',
	},
	actionNext2: {
		id: 'automation-discovery.common.itsm_template_24.action_next_2',
		defaultMessage: 'Close the incident',
		description: 'Action definition for closing the incident',
	},
	headerText: {
		id: 'automation-discovery.common.itsm_template_24.header_text',
		defaultMessage: 'Create automation rules to trigger AWS documents',
		description: 'Header for creating automation rules to trigger AWS documents',
	},
	footerText: {
		id: 'automation-discovery.common.itsm_template_24.footer_text',
		defaultMessage:
			'Your team can save up to <b>{savedMinutes, plural, one {# minute} other {# minutes}} per month</b> with this rule! {learn}',
		description: 'One can save upto 100 minutes',
	},
	footerTextNonAdmin: {
		id: 'automation-discovery.common.itsm_template_24.footer_text_non_admin',
		defaultMessage:
			'Automating this rule can save you up to <b>{savedMinutes, plural, one {# minute} other {# minutes}} per month</b>. Copy and send this message to your admin to request this rule. {learn}',
		description: 'One can save upto 100 minutes',
	},
	learnMoreText: {
		id: 'automation-discovery.common.itsm_template_24.learn_more_text',
		defaultMessage: 'More about automation with AWS',
		description: 'Learn More text for aws action',
	},
});

export const itsmTemplate25 = defineMessages({
	trigger: {
		id: 'automation-discovery.common.itsm_template_25.trigger',
		defaultMessage: 'When an incident is created',
		description: 'Trigger definition for issue created',
	},
	action: {
		id: 'automation-discovery.common.itsm_template_25.action',
		defaultMessage: 'Check for New Relic data',
		description: 'Action definition for New Relic Data',
	},
	actionNext: {
		id: 'automation-discovery.common.itsm_template_25.action_next',
		defaultMessage: 'Run JEC script',
		description: 'Action definition for run jec script',
	},
	headerText: {
		id: 'automation-discovery.common.itsm_template_25.header_text',
		defaultMessage: 'Set up automation rules to execute a script on your local on-premises system',
		description: 'Header for setting up automation rules to execute a script ',
	},
	footerText: {
		id: 'automation-discovery.common.itsm_template_25.footer_text',
		defaultMessage:
			'Your team can save up to <b>{savedMinutes, plural, one {# minute} other {# minutes}} per month</b> with this rule! {learn}',
		description: 'One can save upto 100 minutes',
	},
	footerTextNonAdmin: {
		id: 'automation-discovery.common.itsm_template_25.footer_text_non_admin',
		defaultMessage:
			'Automating this rule can save you up to <b>{savedMinutes, plural, one {# minute} other {# minutes}} per month</b>. Copy and send this message to your admin to request this rule. {learn}',
		description: 'One can save upto 100 minutes',
	},
	learnMoreText: {
		id: 'automation-discovery.common.itsm_template_25.learn_more_text',
		defaultMessage: 'More about automation with JEC',
		description: 'Learn More text for aws action',
	},
});

export const itsmTemplate26 = defineMessages({
	trigger: {
		id: 'automation-discovery.common.itsm_template_26.trigger',
		defaultMessage: 'When an incident is created',
		description: 'Trigger definition for issue created',
	},
	action: {
		id: 'automation-discovery.common.itsm_template_26.action',
		defaultMessage: 'Check for New Relic data',
		description: 'Action definition for New Relic Data',
	},
	actionNext: {
		id: 'automation-discovery.common.itsm_template_26.action_next',
		defaultMessage: 'Restart VM',
		description: 'Action definition for restarting vm',
	},
	actionNext2: {
		id: 'automation-discovery.common.itsm_template_26.action_next_2',
		defaultMessage: 'Run Azure runbook',
		description: 'Action definition for running Azure runbook',
	},
	headerText: {
		id: 'automation-discovery.common.itsm_template_26.header_text',
		defaultMessage:
			'Set up automation rules to restart a Virtual Machine or run a runbook on Azure',
		description: 'Header for setting up automation rules to restart a Virtual Machine',
	},
	footerText: {
		id: 'automation-discovery.common.itsm_template_26.footer_text',
		defaultMessage:
			'Your team can save up to <b>{savedMinutes, plural, one {# minute} other {# minutes}} per month</b> with this rule! {learn}',
		description: 'One can save upto 100 minutes',
	},
	footerTextNonAdmin: {
		id: 'automation-discovery.common.itsm_template_26.footer_text_non_admin',
		defaultMessage:
			'Automating this rule can save you up to <b>{savedMinutes, plural, one {# minute} other {# minutes}} per month</b>. Copy and send this message to your admin to request this rule. {learn}',
		description: 'One can save upto 100 minutes',
	},
	learnMoreText: {
		id: 'automation-discovery.common.itsm_template_26.learn_more_text',
		defaultMessage: 'More about automation with Microsoft Azure',
		description: 'Learn More text for aws action',
	},
});

export const itsmTemplate23 = defineMessages({
	trigger: {
		id: 'automation-discovery.common.itsm_template_23.trigger',
		defaultMessage: 'When an alert is created',
		description: 'Trigger definition for alert created',
	},
	action: {
		id: 'automation-discovery.common.itsm_template_23.action',
		defaultMessage: 'Check for New Relic data',
		description: 'Action definition for New Relic Data',
	},
	actionNext: {
		id: 'automation-discovery.common.itsm_template_23.action_next',
		defaultMessage: 'Restart VM',
		description: 'Action definition for restarting vm',
	},
	actionNext2: {
		id: 'automation-discovery.common.itsm_template_23.action_next_2',
		defaultMessage: 'Run Azure runbook',
		description: 'Action definition for running Azure runbook',
	},
	headerText: {
		id: 'automation-discovery.common.itsm_template_23.header_text',
		defaultMessage: 'Set up automation rules to restart VM or run a runbook on Azure',
		description: 'Header for setting up automation rules to restart a Virtual Machine',
	},
	footerText: {
		id: 'automation-discovery.common.itsm_template_23.footer_text',
		defaultMessage:
			'Your team can save up to <b>{savedMinutes, plural, one {# minute} other {# minutes}} per month</b> with this rule! {learn}',
		description: 'One can save upto 100 minutes',
	},
	footerTextNonAdmin: {
		id: 'automation-discovery.common.itsm_template_23.footer_text_non_admin',
		defaultMessage:
			'Automating this rule can save you up to <b>{savedMinutes, plural, one {# minute} other {# minutes}} per month</b>. Copy and send this message to your admin to request this rule. {learn}',
		description: 'One can save upto 100 minutes',
	},
	learnMoreText: {
		id: 'automation-discovery.common.itsm_template_23.learn_more_text',
		defaultMessage: 'More about automation with Microsoft Azure',
		description: 'Learn More text for aws action',
	},
});

export const itsmTemplate27 = defineMessages({
	trigger: {
		id: 'automation-discovery.common.itsm_template_27_new.trigger',
		defaultMessage: 'When an alert is created',
		description: 'Trigger definition for alert created',
	},
	action: {
		id: 'automation-discovery.common.itsm_template_27_new.action',
		defaultMessage: 'Launch a job template in Ansible',
		description: 'Action definition for launching a job in Ansible',
	},
	actionNext: {
		id: 'automation-discovery.common.itsm_template_27_new.action_next',
		defaultMessage: 'Attach the response to the incident',
		description: 'Action definition for response to incident',
	},
	headerText: {
		id: 'automation-discovery.common.itsm_template_27_new.header_text',
		defaultMessage: 'Create automation rules to trigger Ansible job template',
		description: 'Header for setting up automation rules to trigger Ansible job Templates',
	},
	footerText: {
		id: 'automation-discovery.common.itsm_template_27.footer_text',
		defaultMessage:
			'Your team can save up to <b>{savedMinutes, plural, one {# minute} other {# minutes}} per month</b> with this rule! {learn}',
		description: 'One can save upto 100 minutes',
	},
	footerTextNonAdmin: {
		id: 'automation-discovery.common.itsm_template_27.footer_text_non_admin',
		defaultMessage:
			'Automating this rule can save you up to <b>{savedMinutes, plural, one {# minute} other {# minutes}} per month</b>. Copy and send this message to your admin to request this rule. {learn}',
		description: 'One can save upto 100 minutes',
	},
	learnMoreText: {
		id: 'automation-discovery.common.itsm_template_27_new.learn_more_text',
		defaultMessage: 'More about automation with Ansible',
		description: 'Learn More text for aws action',
	},
});

export const itsmTemplate29 = defineMessages({
	trigger: {
		id: 'automation-discovery.common.itsm_template_29.trigger',
		defaultMessage: 'When an alert is created',
		description: 'Trigger definition for alert created',
	},
	action: {
		id: 'automation-discovery.common.itsm_template_29.action_next',
		defaultMessage: 'Run JEC script',
		description: 'Action definition for run jec script',
	},
	actionNext: {
		id: 'automation-discovery.common.itsm_template_19.action_next_2',
		defaultMessage: 'Close the alert',
		description: 'Action definition for closing the alert',
	},
	headerText: {
		id: 'automation-discovery.common.itsm_template_29.header_text',
		defaultMessage: 'Set up automation rules to execute a script on your local on-premises system',
		description: 'Header for setting up automation rules to execute a script',
	},
	footerText: {
		id: 'automation-discovery.common.itsm_template_29.footer_text',
		defaultMessage:
			'Your team can save up to <b>{savedMinutes, plural, one {# minute} other {# minutes}} per month</b> with this rule! {learn}',
		description: 'One can save upto 100 minutes',
	},
	footerTextNonAdmin: {
		id: 'automation-discovery.common.itsm_template_29.footer_text_non_admin',
		defaultMessage:
			'Automating this rule can save you up to <b>{savedMinutes, plural, one {# minute} other {# minutes}} per month</b>. Copy and send this message to your admin to request this rule. {learn}',
		description: 'One can save upto 100 minutes',
	},
	learnMoreText: {
		id: 'automation-discovery.common.itsm_template_29.learn_more_text',
		defaultMessage: 'More about automation with JEC',
		description: 'Learn More text for jec action',
	},
});
