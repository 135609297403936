import React, { useState } from 'react';

import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';

import { PanelController } from './panel-controller/index.tsx';
import type { AutomationDiscoveryProps } from './types.tsx';

export const AutomationDiscoveryContainer = ({
	templateId,
	type,
	onRedirectToRuleBuilder,
	isUserAuthorizedToCreateRule,
	getAutomationTemplateLink,
	dismissData,
	setDismissData,
}: AutomationDiscoveryProps) => {
	const [isRuleExpanded, setRuleExpanded] = useState(false);

	const onExpandRule = () => {
		setRuleExpanded(true);
	};

	const onClose = () => {
		setRuleExpanded(false);
	};

	return (
		<ContextualAnalyticsData
			sourceName="AutomationDiscoveryPanel"
			attributes={{
				type,
				templateId,
				isUserAuthorizedToCreateRule,
			}}
			sourceType={SCREEN}
		>
			<PanelController
				type={type}
				isRuleExpanded={isRuleExpanded}
				dismissData={dismissData}
				setDismissData={setDismissData}
				onClose={onClose}
				onExpandRule={onExpandRule}
				onRedirectToRuleBuilder={onRedirectToRuleBuilder}
				templateId={templateId}
				isUserAuthorizedToCreateRule={isUserAuthorizedToCreateRule}
				getAutomationTemplateLink={getAutomationTemplateLink}
			/>
		</ContextualAnalyticsData>
	);
};

export const AutomationDiscovery = (props: AutomationDiscoveryProps) => {
	return <AutomationDiscoveryContainer {...props} />;
};
