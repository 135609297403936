/* eslint-disable @atlassian/relay/unused-fields */
import React, { useCallback, type ComponentPropsWithoutRef } from 'react';
import { useFragment, graphql } from 'react-relay';
import type { TeamValue } from '@atlassian/jira-issue-field-team/src/common/types.tsx';
import { useTeamFieldRelay } from '@atlassian/jira-issue-field-team/src/services/index.tsx';
import { View } from '@atlassian/jira-issue-view-base/src/context/team-next/view.tsx';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import { getDisplayName } from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field.tsx';
import {
	useConnectRelayField,
	type ConnectedLayoutProps,
	type PropsCallback,
} from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-relay-field.tsx';
import { ConnectedRelayFieldWrapper } from '@atlassian/jira-issue-view-common-views/src/connect-field/relay-field/field-wrapper.tsx';
import getXsrfToken from '@atlassian/jira-platform-xsrf-token/src/index.tsx';
import type {
	ui_issueViewLayoutTeamField_IssueViewTeamField$key as ComponentsInlineEditFragment,
	ui_issueViewLayoutTeamField_IssueViewTeamField$data as ComponentsInlineEditFragmentData,
} from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutTeamField_IssueViewTeamField.graphql';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';

type ComponentValueShape = TeamValue;
type AggValueShape = Pick<ComponentsInlineEditFragmentData, 'selectedTeam'>['selectedTeam'];

type AdditionalProps = Pick<
	ComponentPropsWithoutRef<typeof View>,
	| 'issueKey'
	| 'issueId'
	| 'noValueText'
	| 'isEditable'
	| 'showPinButton'
	| 'isSubTask'
	| 'fieldId'
	| 'fieldKey'
	| 'useTeamField'
	| 'fieldIdForPinning'
> & {
	value: null;
};

export type IssueViewTeamFieldProps = ConnectedLayoutProps<ComponentsInlineEditFragment>;

export function IssueViewTeamField(props: IssueViewTeamFieldProps) {
	const data = useFragment<ComponentsInlineEditFragment>(
		graphql`
			fragment ui_issueViewLayoutTeamField_IssueViewTeamField on JiraTeamViewField {
				id
				fieldId
				type
				name
				description
				__typename

				fieldConfig {
					isEditable
					isRequired
				}

				issue {
					id
					key
				}
				selectedTeam {
					jiraSuppliedId
					jiraSuppliedName
					jiraSuppliedVisibility
					jiraSuppliedTeamId
					jiraIncludesYou
					jiraMemberCount
					jiraSuppliedIsVerified
				}
				...services_issueViewLayoutTeamField_IssueViewTeamField
			}
		`,
		props.fragmentKey,
	);

	const getComponentProps = useCallback<
		PropsCallback<
			ComponentsInlineEditFragment,
			ComponentsInlineEditFragmentData,
			ComponentValueShape,
			AggValueShape,
			AdditionalProps
		>
	>(
		({ intl }) => {
			return {
				jiraIssueField: data,

				// Note: data is passed down via useTeamFieldRelay hook instead of via connect relay field
				value: null,

				// eslint-disable-next-line @typescript-eslint/no-empty-function
				onValueConfirm() {},

				additionalProps: {
					issueKey: data.issue?.key ?? '',
					issueId: data.issue?.id ?? '',
					noValueText: intl.formatMessage(genericMessages.noValue),
					// this field is not used in legacy component
					isEditable: !!data.fieldConfig?.isEditable && !!getXsrfToken(),
					showPinButton: getShowPinButton(props.area),
					// this field is not used in legacy component
					isSubTask: false,
					useTeamField: ({ onSuccess, onFailure }) =>
						useTeamFieldRelay({
							fragmentKey: data,
							onSuccess,
							onFailure,
						}),
					fieldKey: data.id,
					fieldId: data.id,
					fieldIdForPinning: fg('ken_2097_pinning_team_issue_view') ? data.fieldId : data.id,
					value: null,
				},
			};
		},
		[data, props.area],
	);

	const connectField = useConnectRelayField(props, data, getComponentProps);
	const componentName = getDisplayName(View);

	return (
		<ConnectedRelayFieldWrapper componentName={componentName}>
			<UFOSegment name="issue-field-team">
				<View {...connectField.componentProps} />
			</UFOSegment>
		</ConnectedRelayFieldWrapper>
	);
}
