import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	type BoundActions,
	createStore,
	createHook,
	createContainer,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';
import type { AggFieldChangeProps, FieldValue } from '../../common/types.tsx';
import { transformAggToLegacy } from '../../common/utils/transform-agg-to-legacy/index.tsx';
import { ChangeEventTypes } from './constants.tsx';
import type { IssueViewLegacyEventHandler, OnChangeHandler } from './types.tsx';

type State = {
	eventHandlers: {
		onChange: OnChangeHandler[];
	};
};

type StoreApi = StoreActionApi<State>;

const registerEventHandler =
	(type: string, eventHandler?: IssueViewLegacyEventHandler) =>
	({ getState, setState }: StoreApi) => {
		if (typeof eventHandler !== 'function') {
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			return () => {};
		}

		const { eventHandlers } = getState();
		// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ onChange: OnChangeHandler[]; }'.
		const typeEventHandlers = eventHandlers[type];

		setState({
			eventHandlers: {
				...eventHandlers,
				[type]: typeEventHandlers.concat([eventHandler]),
			},
		});

		return () => {
			const { eventHandlers: eventHandlersForUnregister } = getState();
			// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ onChange: OnChangeHandler[]; }'.
			const typeEventHandlersForUnregister = eventHandlersForUnregister[type];
			const newTypeEventHandlers = typeEventHandlersForUnregister.filter(
				// @ts-expect-error - TS7006 - Parameter 'handler' implicitly has an 'any' type.
				(handler) => handler !== eventHandler,
			);
			setState({
				eventHandlers: {
					...eventHandlersForUnregister,
					[type]: newTypeEventHandlers,
				},
			});
		};
	};

const fieldChanged =
	<P,>(
		issueId: IssueId,
		fieldId: string,
		fieldValue: FieldValue,
		aggFieldChangeProps?: AggFieldChangeProps,
		additionalProperties?: P,
	) =>
	({ getState }: StoreApi) => {
		let value = fieldValue;
		if (aggFieldChangeProps) {
			value = transformAggToLegacy({ fieldValue, aggFieldChangeProps });
		}

		const eventHandlers = getState().eventHandlers.onChange;
		eventHandlers.forEach((eventHandler: OnChangeHandler) => {
			eventHandler({
				issueId,
				// field id is required to be compatible with v1 onChange API
				fieldId,
				meta: {
					fieldId,
					fieldValue: value,
					additionalProperties,
				},
				type: ChangeEventTypes.FIELD_CHANGED,
			});
		});
	};

const fieldChangeFailed =
	(issueId: IssueId, fieldId: string) =>
	({ getState }: StoreApi) => {
		const eventHandlers = getState().eventHandlers.onChange;
		eventHandlers.forEach((eventHandler: OnChangeHandler) => {
			eventHandler({
				issueId,
				meta: {
					fieldId,
				},
				type: ChangeEventTypes.FIELD_CHANGE_FAILED,
			});
		});
	};

const fieldChangeRequested =
	<P,>(
		issueId: IssueId,
		fieldId: string,
		fieldValue: FieldValue,
		additionalProperties?: P,
		aggFieldChangeProps?: AggFieldChangeProps,
	) =>
	({ getState }: StoreApi) => {
		let value = fieldValue;
		if (aggFieldChangeProps) {
			value = transformAggToLegacy({ fieldValue, aggFieldChangeProps });
		}
		const eventHandlers = getState().eventHandlers.onChange;
		eventHandlers.forEach((eventHandler: OnChangeHandler) => {
			eventHandler({
				issueId,
				meta: {
					fieldId,
					fieldValue: value,
					additionalProperties,
				},
				type: ChangeEventTypes.FIELD_CHANGE_REQUESTED,
			});
		});
	};

const actions = {
	fieldChanged,
	fieldChangeFailed,
	fieldChangeRequested,
	registerEventHandler,
} as const;

type Actions = typeof actions;

export type IssueViewFieldUpdateActions = BoundActions<State, Actions>;

// this is a global state
const store = createStore<State, Actions>({
	name: 'issue-view-field-update-events',
	initialState: {
		eventHandlers: {
			onChange: [],
		},
	},
	actions,
});

/**
 * Intended for use either firing field events or subscribing to them within the issue-view app
 *
 * Do NOT use these events for the following purposes:
 * <ol>
 *     <li>Listening to events fired inside the issue-view app from components mounted outside it
 *     <ul>
 *         <li>This will simply not work, there is a container preventing leakage of these events
 *         <li>Use the issue-view app's `onChange` prop instead
 *     </ul>
 *     <li>Synchronising remote (backend) data from the issue view to your app's own remote data store
 *     <ul>
 *         <li>This applies to using the `onChange` prop as well
 *         <li>Prefer using `react-relay` and its shared cache for this
 *         <li>If you can't use Relay yet, prefer using the (deprecated) `useEditField`, `useFieldValue`, or `use${fieldName}Field` hooks in the meantime, they are based on a sweet state store that was also designed to be a shared data store before we aligned on Relay
 *     </ul>
 *  </ol>
 */
export const useIssueViewFieldUpdateEvents = createHook(store, {
	selector: null,
});

export const IssueViewFieldUpdateEventContainer = createContainer(store);
