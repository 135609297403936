import React, { memo } from 'react';
import IssueAssigneeField from '@atlassian/jira-issue-field-assignee/src/ui/popover/index.tsx';
import { IconContainer, PaddedFlexContainer } from '../../../common/ui/styled.tsx';
import { preventDefault, stopPropagation } from '../../../common/utils.tsx';
import type { Props } from './types.tsx';

export const InteractiveAssignee = memo<Props>(
	({ issueKey, onUpdate, isLinkedIssuesRefreshEnabled = false }: Props) => {
		const Container = isLinkedIssuesRefreshEnabled ? IconContainer : PaddedFlexContainer;
		return (
			<Container role="button" onClick={preventDefault} onFocus={stopPropagation}>
				<IssueAssigneeField issueKey={issueKey} onUpdate={onUpdate} />
			</Container>
		);
	},
);
