import React, { useCallback, useMemo, useReducer, useEffect, type ReactElement } from 'react';
import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { usePerformanceAnalyticsActions } from '@atlassian/jira-forge-ui-analytics/src/controllers/performance-analytics/main.tsx';
import {
	fireTrackEditedEvent,
	fireUiCanceledEvent,
	fireUiTriggerClickEvent,
} from '@atlassian/jira-forge-ui-analytics/src/services/custom-field-edit/index.tsx';
import { AsyncCustomFieldEdit } from '@atlassian/jira-forge-ui-async/src/ui/custom-fields/custom-field-edit/async.tsx';
import type { ForgeCustomFieldValue } from '@atlassian/jira-forge-ui-types/src/common/types/contexts/custom-field.tsx';
import type { ForgeUiIssueData } from '@atlassian/jira-forge-ui-types/src/common/types/extension-data.tsx';
import type { CustomFieldExtension } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import customFieldGenericError from '@atlassian/jira-forge-ui/src/common/ui/flags/custom-field/edit-failed/generic-error/index.tsx';
import customFieldModalOpenFailed from '@atlassian/jira-forge-ui/src/common/ui/flags/custom-field/edit-failed/open-model-error/index.tsx';
import { useCustomFieldService } from '@atlassian/jira-forge-ui/src/services/custom-fields/save-field/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useAnalyticsAttributesContext } from '../analytics/atrributes-context/index.tsx';
import {
	forgeCustomFieldEditInitialization,
	forgeCustomFieldEditCancel,
	forgeCustomFieldEditSetValue,
	forgeCustomFieldEditRendered,
	forgeCustomFieldEditFailed,
} from './actions.tsx';
import editReducer from './state.tsx';

export type Props = {
	issueKey: IssueKey;
	extension: CustomFieldExtension;
	extensionData: ForgeUiIssueData | null;
	fieldId: string;
	fieldName: string;
	value: ForgeCustomFieldValue;
	// eslint-disable-next-line jira/react/handler-naming
	viewComponent: (
		switchToEditMode: () => void,
		newValue: ForgeCustomFieldValue,
		isLoading: boolean,
	) => ReactElement;
	onCancel: () => void;
	onSave?: (value: ForgeCustomFieldValue) => void;
};

export const ForgeCustomFieldEditView = ({
	issueKey,
	extensionData,
	extension,
	fieldId,
	fieldName,
	value,
	viewComponent,
	onCancel,
	onSave,
}: Props) => {
	const [{ isEditing, isLoading, editValue }, dispatch] = useReducer(editReducer, {
		isEditing: false,
		isLoading: false,
		editValue: value,
	});

	const analyticsAttributes = useAnalyticsAttributesContext();
	const performanceAnalyticsActions = usePerformanceAnalyticsActions();

	useEffect(() => {
		dispatch(forgeCustomFieldEditSetValue(value));
	}, [value]);

	const attributes = useMemo(
		() => ({ ...analyticsAttributes, displayType: 'modal' }),
		[analyticsAttributes],
	);
	const { showFlag } = useFlagsService();

	const onEditConfirm = useCallback(
		(newValue: ForgeCustomFieldValue) => {
			// to update redux store with new value
			onSave && onSave(newValue);
			dispatch(forgeCustomFieldEditSetValue(newValue));
			fireTrackEditedEvent(attributes.source, attributes);
		},
		[onSave, attributes],
	);
	const onEditCancel = useCallback(() => {
		onCancel();
		dispatch(forgeCustomFieldEditCancel());
		fireUiCanceledEvent(attributes.source, attributes);
	}, [onCancel, attributes]);

	const onEditRender = useCallback(() => {
		dispatch(forgeCustomFieldEditRendered());
		performanceAnalyticsActions.finishUserInteraction();
	}, [performanceAnalyticsActions]);

	const onEditFail = useCallback(() => {
		if (isEditing) {
			// if isEditing, user has edit modal open and is trying to submit it
			showFlag(customFieldGenericError());
		} else {
			// otherwise, edit modal isn't open, and the error is firing as they click on the field
			showFlag(customFieldModalOpenFailed());
		}

		dispatch(forgeCustomFieldEditFailed());
	}, [showFlag, isEditing]);

	const switchToEditMode = useCallback(() => {
		fireUiTriggerClickEvent(extension.id, attributes.source, attributes);
		performanceAnalyticsActions.startUserInteraction();
		dispatch(forgeCustomFieldEditInitialization());
	}, [extension.id, attributes, performanceAnalyticsActions]);

	// switchToEditMode visual feedback
	useEffect(() => {
		if (isEditing && isLoading) {
			performanceAnalyticsActions.markUserInteractionFeedback();
		}
	}, [isEditing, isLoading, performanceAnalyticsActions]);

	const { save } = useCustomFieldService({
		issueKey,
		fieldId,
		fieldType: extension.id,
		source: attributes.source,
	});

	const memoizedExtensionData = useMemo(
		() => ({
			...extensionData,
			fieldId,
			fieldName,
			fieldType: extension.id,
			renderContext: 'issue-view',
			...(fg('ditto-fcf-support-new-manifest-on-frontend') && {
				experience: 'issue-view' as const,
			}),
		}),
		[extension, extensionData, fieldId, fieldName],
	);

	const memoizedExtensionPayload = useMemo(
		() => ({
			fieldValue: editValue,
		}),
		[editValue],
	);

	return (
		<>
			{viewComponent(switchToEditMode, editValue, isLoading)}
			{isEditing && (
				<AsyncCustomFieldEdit
					extension={extension}
					extensionData={memoizedExtensionData}
					extensionPayload={memoizedExtensionPayload}
					onConfirm={onEditConfirm}
					onCancel={onEditCancel}
					onRender={onEditRender}
					onError={onEditFail}
					onSave={save}
					analyticsAttributes={attributes}
				/>
			)}
		</>
	);
};
