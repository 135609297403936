import React, { useRef, useEffect, type RefObject } from 'react';
import { components, type OptionProps, type OptionType } from '@atlaskit/select';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import { scrollIntoView } from './utils.tsx';
import { STICKY_FOOTER_CLASS_NAME } from './constant.tsx';

export const OptionWithAutoKeyboardScroll = <T = OptionType,>({
	children,
	containerRef,
	...optionProps
}: { containerRef?: RefObject<HTMLDivElement> } & OptionProps<T>) => {
	const focusedOptionRef = useRef<HTMLElement>();

	useEffect(() => {
		if (optionProps.isFocused) {
			// We only auto scroll for focus with keyboard only, not for focus with mouse hover
			// because mouse users will manual scroll when browsing options
			if (!focusedOptionRef.current?.parentElement || focusedOptionRef.current?.matches(':hover')) {
				return;
			}

			// We need to add bottom offset when scrolling which is the current height of sticky footer options group
			const stickFooterBottomOffset =
				globalThis?.document.querySelector(`.${STICKY_FOOTER_CLASS_NAME}`)?.clientHeight ?? 0;

			scrollIntoView(
				containerRef?.current ?? focusedOptionRef.current.parentElement,
				focusedOptionRef.current,
				stickFooterBottomOffset,
			);
		}
	}, [containerRef, optionProps.isFocused]);

	return (
		<components.Option
			{...optionProps}
			innerRef={mergeRefs(optionProps.innerRef, focusedOptionRef)}
		>
			{children}
		</components.Option>
	);
};
