import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { type ActionsObservable, combineEpics } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types.tsx';
import { CONTENT_TOO_LARGE } from '@atlassian/jira-common-constants/src/http-status-codes.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { isHttpClientErrorResponse } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { ISSUE_LINKS_PER_ISSUE_LIMIT_EXCEEDED } from '@atlassian/jira-issue-view-common-constants/src/flags.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import {
	SAVE_LINKED_ISSUE_REQUEST,
	SAVE_LINKED_ISSUE_RETRY,
	saveLinkedIssueFailure,
	saveLinkedIssueSuccess,
} from '@atlassian/jira-issue-view-store/src/actions/issue-links-actions.tsx';
import {
	baseUrlSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { issueLinkSelector } from '@atlassian/jira-issue-view-store/src/selectors/issue-links-selector.tsx';
import { saveIssueLink } from './issue-links-add-server.tsx';

const LOG_LOCATION = 'issue.issuelinks.save.linked.issues.epic';

type HandleError = (analyticsSource: string, error?: Error) => void;
type HandleErrorOld = (analyticsSource: string, errorMessage?: string) => void;

const saveLinkedIssue = (
	state: State,
	optimisticId: string,
	linkedIssueKey: string,
	issueLinkType: { direction: string; id: string },
	requestAnalyticsEvent: AnalyticsEvent,
	onSuccess: (() => void) | undefined,
	onError: HandleError | HandleErrorOld | undefined,
	postAnalyticsEvent?: AnalyticsEvent,
) => {
	const baseUrl = baseUrlSelector(state);
	const thisIssueKey = issueKeySelector(state);

	return saveIssueLink(baseUrl, issueLinkType, thisIssueKey, linkedIssueKey)
		.map(() => {
			if (fg('operandi_issue_view_additional_logging')) {
				return saveLinkedIssueSuccess(
					optimisticId,
					requestAnalyticsEvent.update({ action: 'succeeded' }),
					linkedIssueKey,
					postAnalyticsEvent,
				);
			}
			return saveLinkedIssueSuccess(
				optimisticId,
				requestAnalyticsEvent.update({ action: 'succeeded' }),
			);
		})
		.do(() => {
			onSuccess?.();
		})
		.catch((error) => {
			log.safeErrorWithoutCustomerData(LOG_LOCATION, 'Error while adding linked issues', error);

			let issueLinkError = error;

			if (error instanceof FetchError && error.statusCode === CONTENT_TOO_LARGE) {
				issueLinkError = ISSUE_LINKS_PER_ISSUE_LIMIT_EXCEEDED;
			} else {
				issueLinkError = error.message;
			}

			if (!(error instanceof FetchError && isHttpClientErrorResponse(error))) {
				fg('thor_add_missing_attributes_across_issue_view_1')
					? onError?.('jiraLocalLinkedIssues', error)
					: onError?.('jiraLocalLinkedIssues', error?.message);
			}

			return Observable.of(
				saveLinkedIssueFailure(
					optimisticId,
					issueLinkError,
					requestAnalyticsEvent?.update({ action: 'failed' }),
				),
			);
		});
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const saveLinkedIssueRequestEpic = (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
	action$.ofType(SAVE_LINKED_ISSUE_REQUEST).mergeMap((action) => {
		const state = store.getState();
		const { optimisticId, linkedIssueKey, issueLinkType } = action.payload.newIssueLinks;
		const {
			requestAnalyticsEvent,
			postAnalyticsEvent,
			onSuccess = undefined,
			onError = undefined,
		} = action.payload;
		if (fg('operandi_issue_view_additional_logging')) {
			return saveLinkedIssue(
				state,
				optimisticId,
				linkedIssueKey,
				issueLinkType,
				requestAnalyticsEvent,
				onSuccess,
				onError,
				postAnalyticsEvent,
			);
		}
		return saveLinkedIssue(
			state,
			optimisticId,
			linkedIssueKey,
			issueLinkType,
			requestAnalyticsEvent,
			onSuccess,
			onError,
		);
	});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const saveLinkedIssueRetryEpic = (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
	action$.ofType(SAVE_LINKED_ISSUE_RETRY).mergeMap((action) => {
		const state = store.getState();
		const { optimisticId, requestAnalyticsEvent, postAnalyticsEvent, onSuccess, onError } =
			action.payload;
		const issueLink = issueLinkSelector(optimisticId)(state);
		const linkedIssueKey = issueLink && issueLink.linkedIssueKey;
		const issueLinkType = issueLink && { direction: issueLink.direction, id: issueLink.typeId };
		if (fg('operandi_issue_view_additional_logging')) {
			return saveLinkedIssue(
				state,
				optimisticId,
				linkedIssueKey,
				issueLinkType,
				requestAnalyticsEvent,
				onSuccess,
				onError,
				postAnalyticsEvent,
			);
		}
		return saveLinkedIssue(
			state,
			optimisticId,
			linkedIssueKey,
			issueLinkType,
			requestAnalyticsEvent,
			onSuccess,
			onError,
		);
	});

export default combineEpics(saveLinkedIssueRetryEpic, saveLinkedIssueRequestEpic);
