import React from 'react';

import { Grid, xcss, Box } from '@atlaskit/primitives';

import { useGridContext } from '../context/index.tsx';
import Pagination from './pagination.tsx';

export type GridViewProps = {
	isLoading?: boolean;
	children: React.ReactNode;
};

const GridView = ({ isLoading, children }: GridViewProps) => {
	const { showPagination, colSize } = useGridContext();

	return (
		<Box
			xcss={gridLayoutStyle}
			testId="issue-view-base.content.attachment.grid-view.ui.components.grid-view-container"
		>
			<Grid
				gap="space.100"
				alignItems="center"
				templateColumns={`repeat(${colSize}, 1fr`}
				testId="issue-view-base.content.attachment.grid-view.ui.components.grid"
				role="grid"
				xcss={gridStyle}
			>
				{children}
			</Grid>
			{showPagination ? <Pagination isDisabled={isLoading} /> : null}
		</Box>
	);
};

export default GridView;

const gridLayoutStyle = xcss({
	gap: 'space.150',
	overflow: 'hidden',
});

const gridStyle = xcss({
	/**
	 * Maximumm height for grid restricted to max 3 rows. And based on that get height of 399px
	 *
	 * 3 * (GRID_ITEM_DIMENSIONS.height + GRID_ITEM_DIMENSIONS.spacing) = 3 * (125 + 8) = 399
	 */
	maxHeight: '399px',
	overflow: 'hidden',
	padding: 'space.050',
});
