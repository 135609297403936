import { useState, useEffect, useRef, useCallback } from 'react';
import isEqual from 'lodash/isEqual';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { useFlagsService, type FlagConfiguration } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { fireOperationalFailedEvent } from '@atlassian/jira-forge-ui-analytics/src/services/custom-field/index.tsx';
import type { ForgeCustomFieldValue } from '@atlassian/jira-forge-ui-types/src/common/types/contexts/custom-field.tsx';
import { ChangeEventTypes } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/constants.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import type { IssueKey, IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useAnalyticsAttributesContext } from '../analytics/atrributes-context/index.tsx';
import { messages } from './messages.tsx';

const FETCH_ERROR_FLAG: FlagConfiguration = {
	messageId:
		'issue-view-base.context.ecosystem.forge.custom-field.formatter-value.fetch-error-flag.error.flag.fetch-error',
	messageType: 'transactional',
	type: 'error',
	title: messages.fetchErrorTitle,
};

export const useFormatterValue = ({
	value,
	formatterProperty,
	fieldId,
	issueKey,
	issueId,
	shouldUseFormatter,
	isEditing,
}: {
	value: ForgeCustomFieldValue;
	formatterProperty?: {
		expression: string;
	};
	fieldId: string;
	issueKey: IssueKey;
	issueId: IssueId | undefined;
	shouldUseFormatter: boolean;
	isEditing: boolean;
}): { formatterValue: string | null; isLoading: boolean } => {
	const [formatterValue, setFormatterValue] = useState(null);
	const [fieldValue, setFieldValue] = useState<ForgeCustomFieldValue>(value);
	const [isLoading, setIsLoading] = useState(shouldUseFormatter);
	const hasPendingChangeRequestRef = useRef<boolean>(false);
	const isEditModeRef = useRef<boolean>(isEditing);
	const previousValue = useRef<unknown>(undefined);
	const { showFlag } = useFlagsService();
	const [, { registerEventHandler }] = useIssueViewFieldUpdateEvents();
	const analyticsAttributes = useAnalyticsAttributesContext();

	const fetchFormattedValue = useCallback(async () => {
		if (formatterProperty) {
			try {
				setIsLoading(true);
				const result = await fetchJson(
					`/rest/api/3/issue/${issueKey}?fields=${fieldId}&expand=renderedFields`,
				);
				setFormatterValue(result.renderedFields[fieldId]);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				showFlag(FETCH_ERROR_FLAG);
				fireOperationalFailedEvent(analyticsAttributes.source, {
					...analyticsAttributes,
					error: Error(`Fetching formatted value after edit failed: ${err}`),
				});
			} finally {
				setIsLoading(false);
			}
		}
	}, [analyticsAttributes, formatterProperty, fieldId, issueKey, showFlag]);

	useEffect(() => {
		if (shouldUseFormatter) {
			// extra check for object type
			if (!isEqual(fieldValue, previousValue.current)) {
				fetchFormattedValue();
			}
		}
		previousValue.current = fieldValue;
	}, [shouldUseFormatter, fieldValue, fetchFormattedValue]);

	useEffect(() => {
		isEditModeRef.current = isEditing;
	}, [isEditing]);

	useEffect(() => {
		// We should set the new value when not in the edit mode and a few other conditions are met.
		// The reason for that is that we may get new value from the server as well as from the current edit component.
		// So when it comes from the server we just set it and when it is from the edit component, we are leaving the set operation for the field edit flow.
		// Because the hook is running in the edit mode as well, we should ignore all the changes coming from there and instead wait for the change request.
		// We know whether it is a new server data or an edit mode/submission based on the refs.
		// We can't use props/state in here because of race conditions with update events, instead we have to switch to refs.
		// The refs are mutated instantly and do not cause useEffect overload since they aren't part of the dependency array.
		if (
			shouldUseFormatter &&
			!isEditModeRef.current &&
			!hasPendingChangeRequestRef.current &&
			!isEqual(fieldValue, value)
		) {
			setFieldValue(value);
		}
	}, [shouldUseFormatter, value, fieldValue]);

	useEffect(() => {
		if (shouldUseFormatter) {
			const unregister = registerEventHandler('onChange', (event) => {
				if (event.meta.fieldId === fieldId && event.issueId === issueId) {
					hasPendingChangeRequestRef.current = false;
					switch (event.type) {
						case ChangeEventTypes.FIELD_CHANGE_REQUESTED:
							hasPendingChangeRequestRef.current = true;
							setIsLoading(true);
							break;
						case ChangeEventTypes.FIELD_CHANGED:
							// We do not unset the loading in here because we want to keep the spinner spinning until the formatted value is fetched.
							setFieldValue(event.meta.fieldValue);
							break;
						case ChangeEventTypes.FIELD_CHANGE_FAILED:
							showFlag(FETCH_ERROR_FLAG);
							setIsLoading(false);
							break;
						default:
							setIsLoading(false);
					}
				}
			});

			return unregister;
		}
	}, [registerEventHandler, shouldUseFormatter, fieldId, issueId, showFlag]);

	return { formatterValue, isLoading };
};
