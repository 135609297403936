import {
	AGILITY_BACKLOG,
	BUSINESS_LIST,
	FULL_ISSUE,
	GLOBAL_ISSUE_NAVIGATOR_ISSUE,
	ISSUE_NAVIGATOR,
	SIMPLE_SOFTWARE_BOARD,
	SOFTWARE_BACKLOG_ISSUE,
	SOFTWARE_ISSUE,
} from '@atlassian/jira-common-constants/src/analytics-sources.tsx';
import {
	SOFTWARE_PROJECT,
	type ProjectType,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	type AnalyticsResult,
	type ViewConfiguration,
	LOOKUP_SOURCE_USERS_ASYNC_DATA,
	type BaseFieldConfiguration,
	type FieldType,
} from '@atlassian/jira-issue-adjustments/src/common.tsx';

import useAssigneeField from '@atlassian/jira-issue-field-assignee/src/services/use-assignee-field/index.tsx';
import { useUserCustomField } from '@atlassian/jira-issue-field-base-user-picker/src/services/use-user-picker-field/index.tsx';
import { useCascadeSelectField } from '@atlassian/jira-issue-field-cascading-select/src/service/cascading-select-field-service/index.tsx';
import { useCheckboxSelectField } from '@atlassian/jira-issue-field-checkbox-select/src/services/use-checkbox-select-field/index.tsx';
import { useComponentsField } from '@atlassian/jira-issue-field-components-field/src/services/use-components-field/index.tsx';
import { useDateTimeField } from '@atlassian/jira-issue-field-date-time/src/services/use-date-time-field/index.tsx';
import { useDateField } from '@atlassian/jira-issue-field-date/src/services/use-date-field/index.tsx';
import { useLabelsField } from '@atlassian/jira-issue-field-labels/src/services/labels-field-service/index.tsx';
import { useMultiUserCustomField } from '@atlassian/jira-issue-field-multi-user-picker-editview-full/src/services/use-multi-user-picker-field/index.tsx';
import { useNumberField } from '@atlassian/jira-issue-field-number/src/services/index.tsx';
import { useOriginalEstimateFieldWithTimeTracking } from '@atlassian/jira-issue-field-original-estimate/src/services/index.tsx';
import { useParentField } from '@atlassian/jira-issue-field-parent/src/services/parent-field/index.tsx';
import { usePeopleCustomField } from '@atlassian/jira-issue-field-people/src/services/use-people-custom-field/index.tsx';
import { usePriorityField } from '@atlassian/jira-issue-field-priority-chip/src/services/index.tsx';
import { useRadioSelectCustomField } from '@atlassian/jira-issue-field-radio-select/src/services/index.tsx';
import { useReporterField } from '@atlassian/jira-issue-field-reporter/src/services/use-reporter-field/index.tsx';
import {
	useDescriptionField,
	useParagraphField,
} from '@atlassian/jira-issue-field-rich-text/src/services/index.tsx';
import {
	useMultiSelectField,
	useSingleSelectField,
} from '@atlassian/jira-issue-field-select/src/services/use-select-field/index.tsx';
import { useTextCustomField } from '@atlassian/jira-issue-field-single-line-text/src/services/index.tsx';
import { useStatusField } from '@atlassian/jira-issue-field-status/src/services/use-status-service/index.tsx';
import { useSummaryField } from '@atlassian/jira-issue-field-summary-common/src/services/index.tsx';
import { useUrlCustomField } from '@atlassian/jira-issue-field-url/src/services/use-url-custom-field/index.tsx';
import { useVersionsFields } from '@atlassian/jira-issue-field-versions/src/ui/services/use-versions-fields/index.tsx';
import {
	AFFECTS_VERSIONS_TYPE,
	ASSIGNEE_TYPE,
	CASCADING_SELECT_CF_TYPE,
	COMPONENTS_TYPE,
	DATETIME_CF_TYPE,
	DATE_CF_TYPE,
	DESCRIPTION_TYPE,
	FIX_VERSIONS_TYPE,
	LABELS_TYPE,
	MULTI_CHECKBOXES_CF_TYPE,
	MULTI_SELECT_CF_TYPE,
	MULTI_USER_CF_TYPE,
	NUMBER_CF_TYPE,
	PARENT_TYPE,
	PEOPLE_CF_TYPE,
	PRIORITY_TYPE,
	RADIO_BUTTONS_CF_TYPE,
	REPORTER_TYPE,
	SELECT_CF_TYPE,
	STATUS_TYPE,
	SUMMARY_TYPE,
	TEXT_AREA_CF_TYPE,
	TEXT_CF_TYPE,
	TIME_ESTIMATE_TYPE,
	URL_CF_TYPE,
	USER_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';

import {
	assigneeGetValueTransformer,
	assigneeSetValueTransformer,
	assigneeSetValueValidator,
} from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/assignee.tsx';
import { validateCascadingSelectWithAriSetValue } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/cascading-select.tsx';
import { validateComponentsSetValue } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/components.tsx';
import {
	datePickerGetValueTransformer,
	datePickerSetValueValidator,
} from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/date-picker.tsx';
import {
	transformDateTimePickerGetValue,
	validateDateTimePickerSetValue,
} from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/date-time-picker.tsx';
import { validateMultiCheckboxSetValue } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/multi-checkbox.tsx';
import { validateMultiSelectSetValue } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/multi-select.tsx';
import { validateMultiUserPickerSetValue } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/multi-user-picker.tsx';
import { validateNumberValue } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/number.tsx';
import {
	transformGetValueOriginalEstimate,
	transformSetValueOriginalEstimate,
	validateOriginalEstimate,
} from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/original-estimate.tsx';
import { parentSetValueValidator } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/parent.tsx';
import { validatePeopleSetValue } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/people.tsx';
import { validatePrioritySetValue } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/priority.tsx';
import { radioButtonsSetValueValidator } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/radio-buttons.tsx';
import { reporterSetValueValidator } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/reporter.tsx';
import { transformSingleLineTextToPublicValue } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/single-line-text.tsx';
import { userPickerSetValueValidator } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/user-picker.tsx';
import { validateVersionValue } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/versions.tsx';
import { getCommonFieldValidators } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/utils/get-common-field-validators/index.tsx';
import {
	transformSelectGetOptionsVisibility,
	transformSelectSetOptionsVisibility,
	transformSetOptionsVisibilityForFrontendFilteringWithARIs,
	validateSetOptionsVisibility,
	transformSetOptionsVisibilityAri,
} from '@atlassian/jira-ui-modifications-common/src/common/utils/common/utils/options-visibility/index.tsx';
import { validateSingleLineTextValue } from '@atlassian/ui-modifications-public-api-utils/field/single-line-text';
import { selectWithAriSetValueValidator } from '@atlassian/ui-modifications-public-api-utils/field/select';
import type { ParentFieldOption } from '@atlassian/jira-issue-field-parent/src/common/types.tsx';
import type {
	ParentFieldResponse,
	ParentIdShape,
} from '@atlassian/jira-issue-field-parent/src/services/parent-field/types.tsx';
import type { VersionFieldProps } from '@atlassian/jira-issue-field-versions/src/ui/services/use-versions-fields/types.tsx';
import {
	cancelTTI,
	markFieldsRegistered,
	markIframeLoad,
	markOnInitResolved,
	stopTTI,
} from '../../performance-analytics/index.tsx';
import type { IssueViewExecutionContext } from '../../context.tsx';
import { adaptIssueViewPerFieldHook } from '../../../ui/entry-point/fields-processor/utils.tsx';
import type { UseFieldHook } from '../../../ui/entry-point/fields-processor/field-processor/types.tsx';
import { assigneeIssueViewScopes } from './fields/assignee.tsx';
import {
	cascadingSelectIssueViewScopes,
	transformGetValueCascadingSelect,
	transformSetValueCascadingSelect,
} from './fields/cascading-select.tsx';
import {
	componentsIssueViewScopes,
	transformComponentsGetValue,
	transformComponentsSetValue,
} from './fields/components.tsx';
import { datePickerIssueViewScopes } from './fields/date-picker.tsx';
import { dateTimePickerIssueViewScopes } from './fields/date-time-picker.tsx';
import {
	descriptionIssueViewScopes,
	transformRichTextToPublicValue,
	validateRichText,
} from './fields/description.tsx';
import { labelsRequiredScopes, validateLabelsSetValue } from './fields/labels.tsx';
import {
	multiCheckboxGetValueTransformer,
	multiCheckboxScopes,
	multiCheckboxSetValueTransformer,
} from './fields/multi-checkbox.tsx';
import {
	multiSelectScopes,
	transformMultiSelectGetValue,
	transformMultiSelectSetValue,
} from './fields/multi-select.tsx';
import {
	transformMultiUserPickerGetValue,
	transformMultiUserPickerSetValue,
} from './fields/multi-user-picker.tsx';
import { numberIssueViewScopes } from './fields/number.tsx';
import { originalEstimateIssueViewScopes } from './fields/original-estimate.tsx';
import {
	paragraphGetValueTransformer,
	paragraphSetValueTransformer,
	validateParagraphSetValue,
} from './fields/paragraph.tsx';
import {
	parentIssueViewScopes,
	transformParentGetValue,
	transformParentSetValue,
} from './fields/parent.tsx';
import {
	peopleIssueViewScopes,
	transformPeopleGetValue,
	transformPeopleSetValue,
} from './fields/people.tsx';
import {
	priorityIssueViewScopes,
	transformPriorityGetValue,
	transformPrioritySetValue,
	transformSetOptionsVisibilityAriPriority,
} from './fields/priority.tsx';
import {
	radioButtonsGetValueTransformer,
	radioButtonsIssueViewScopes,
	radioButtonsSetValueTransformer,
} from './fields/radio-buttons.tsx';
import {
	selectGetValueTransformer,
	selectIssueViewScopes,
	selectSetValueTransformer,
} from './fields/select.tsx';
import { singleLineTextIssueViewScopes } from './fields/single-line-text.tsx';
import {
	statusIssueViewScopes,
	transformStatusGetValue,
	transformStatusSetValue,
	validateStatusSetValue,
} from './fields/status.tsx';
import { summaryIssueViewScopes } from './fields/summary.tsx';
import { urlIssueViewScopes } from './fields/url.tsx';
import {
	transformVersionToInternalValue,
	transformVersionToPublicValue,
	versionsRequiredScopes,
} from './fields/versions.tsx';

import messages from './messages.tsx';

export interface IssueViewFieldConfiguration extends BaseFieldConfiguration {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	fieldProcessor: UseFieldHook<any, any, any, any>;
}

export class IssueViewViewConfiguration
	//  To be changed to IssueViewViewConfiguration with 'refactor-field-processor' clean-up
	implements ViewConfiguration<BaseFieldConfiguration, IssueViewExecutionContext>
{
	private analytics: AnalyticsResult = {
		stopTTI,
		cancelTTI,
		markIframeLoad,
		markFieldsRegistered,
		markOnInitResolved,
	};

	private supportedProjectTypes: ProjectType[] = [SOFTWARE_PROJECT];

	getSupportedFieldsConfiguration() {
		const commonFieldValidators = getCommonFieldValidators();

		return {
			[SUMMARY_TYPE]: {
				publicShapeValidators: {
					isReadOnly: commonFieldValidators.isReadOnly,
					value: validateSingleLineTextValue,
				},
				publicToInternalTransformers: {},
				internalToPublicTransformers: {
					value: transformSingleLineTextToPublicValue,
				},
				/**
				 * On the Issue View cannot differentiate between change and blur since only change is being observed.
				 * 'change' on the Issue View means e.g. that user "blurs" from a text field or change the selection in a select field.
				 */
				emitChangeOn: 'change',
				requiredScopes: summaryIssueViewScopes,
				...(fg('refactor-field-processor')
					? {
							fieldProcessor: adaptIssueViewPerFieldHook({
								hook: useSummaryField,
								saveFnName: 'saveValue',
							}),
						}
					: null),
			},
			[DESCRIPTION_TYPE]: {
				publicShapeValidators: {
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: validateRichText,
				},
				publicToInternalTransformers: {},
				internalToPublicTransformers: {
					value: transformRichTextToPublicValue,
				},
				emitChangeOn: 'change',
				requiredScopes: descriptionIssueViewScopes,
				...(fg('refactor-field-processor')
					? {
							fieldProcessor: adaptIssueViewPerFieldHook({
								hook: useDescriptionField,
								saveFnName: 'saveValue',
							}),
						}
					: null),
			},
			[ASSIGNEE_TYPE]: {
				publicShapeValidators: {
					description: commonFieldValidators.description,
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: assigneeSetValueValidator,
				},
				publicToInternalTransformers: {
					value: assigneeSetValueTransformer,
				},
				internalToPublicTransformers: {
					value: assigneeGetValueTransformer,
				},
				emitChangeOn: 'change',
				lookupSource: LOOKUP_SOURCE_USERS_ASYNC_DATA,
				requiredScopes: assigneeIssueViewScopes,
				...(fg('refactor-field-processor')
					? {
							fieldProcessor: adaptIssueViewPerFieldHook({
								hook: useAssigneeField,
								saveFnName: 'saveValue',
							}),
						}
					: null),
			},
			[PRIORITY_TYPE]: {
				publicShapeValidators: {
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: validatePrioritySetValue,
					optionsVisibility: validateSetOptionsVisibility,
				},
				publicToInternalTransformers: {
					value: transformPrioritySetValue,
					optionsVisibility: fg('relay-migration-issue-fields-priority')
						? transformSetOptionsVisibilityAriPriority
						: transformSelectSetOptionsVisibility,
				},
				internalToPublicTransformers: {
					value: transformPriorityGetValue,
					optionsVisibility: transformSelectGetOptionsVisibility,
				},
				emitChangeOn: 'change',
				requiredScopes: priorityIssueViewScopes,
				propertyMapping: {
					optionsVisibility: fg('relay-migration-issue-fields-priority')
						? 'fieldOptionsFilter'
						: 'allowedValuesOverrides',
				},
				...(fg('refactor-field-processor')
					? {
							fieldProcessor: adaptIssueViewPerFieldHook({
								hook: usePriorityField,
								saveFnName: 'saveById',
							}),
						}
					: null),
			},
			[TEXT_CF_TYPE]: {
				publicShapeValidators: {
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: validateSingleLineTextValue,
					description: commonFieldValidators.description,
				},
				publicToInternalTransformers: {},
				internalToPublicTransformers: {
					value: transformSingleLineTextToPublicValue,
				},
				emitChangeOn: 'change',
				requiredScopes: singleLineTextIssueViewScopes,
				...(fg('refactor-field-processor')
					? {
							fieldProcessor: adaptIssueViewPerFieldHook({
								hook: useTextCustomField,
								saveFnName: 'saveValue',
							}),
						}
					: null),
			},
			[URL_CF_TYPE]: {
				publicShapeValidators: {
					description: commonFieldValidators.description,
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: validateSingleLineTextValue,
				},
				publicToInternalTransformers: {},
				internalToPublicTransformers: {
					value: transformSingleLineTextToPublicValue,
				},
				emitChangeOn: 'change',
				requiredScopes: urlIssueViewScopes,
				...(fg('refactor-field-processor')
					? {
							fieldProcessor: adaptIssueViewPerFieldHook({
								hook: useUrlCustomField,
								saveFnName: 'saveValue',
							}),
						}
					: null),
			},
			[TEXT_AREA_CF_TYPE]: {
				publicShapeValidators: {
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: validateParagraphSetValue,
					description: commonFieldValidators.description,
				},
				publicToInternalTransformers: {
					value: paragraphSetValueTransformer,
				},
				internalToPublicTransformers: {
					value: paragraphGetValueTransformer,
				},
				emitChangeOn: 'change',
				requiredScopes: descriptionIssueViewScopes,
				...(fg('refactor-field-processor')
					? {
							fieldProcessor: adaptIssueViewPerFieldHook({
								hook: useParagraphField,
								saveFnName: 'saveValue',
							}),
						}
					: null),
			},
			[MULTI_SELECT_CF_TYPE]: {
				publicShapeValidators: {
					description: commonFieldValidators.description,
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: validateMultiSelectSetValue,
					optionsVisibility: validateSetOptionsVisibility,
				},
				publicToInternalTransformers: {
					value: transformMultiSelectSetValue,
					optionsVisibility: transformSetOptionsVisibilityAri,
				},
				internalToPublicTransformers: {
					value: transformMultiSelectGetValue,
					optionsVisibility: transformSelectGetOptionsVisibility,
				},
				emitChangeOn: 'change',
				requiredScopes: multiSelectScopes,
				propertyMapping: {
					optionsVisibility: 'fieldOptionsFilter',
				},
				...(fg('refactor-field-processor')
					? {
							fieldProcessor: adaptIssueViewPerFieldHook({
								hook: useMultiSelectField,
								saveFnName: 'saveById',
							}),
						}
					: null),
			},
			[SELECT_CF_TYPE]: {
				publicShapeValidators: {
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: selectWithAriSetValueValidator,
					description: commonFieldValidators.description,
					optionsVisibility: validateSetOptionsVisibility,
				},
				publicToInternalTransformers: {
					value: selectSetValueTransformer,
					optionsVisibility: transformSetOptionsVisibilityAri,
				},
				internalToPublicTransformers: {
					value: selectGetValueTransformer,
					optionsVisibility: transformSelectGetOptionsVisibility,
				},
				emitChangeOn: 'change',
				requiredScopes: selectIssueViewScopes,
				propertyMapping: {
					optionsVisibility: 'fieldOptionsFilter',
				},
				...(fg('refactor-field-processor')
					? {
							fieldProcessor: adaptIssueViewPerFieldHook({
								hook: useSingleSelectField,
								saveFnName: 'saveValue',
							}),
						}
					: null),
			},
			[MULTI_CHECKBOXES_CF_TYPE]: {
				publicShapeValidators: {
					description: commonFieldValidators.description,
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: validateMultiCheckboxSetValue,
					optionsVisibility: validateSetOptionsVisibility,
				},
				publicToInternalTransformers: {
					value: multiCheckboxSetValueTransformer,
					optionsVisibility: fg('relay-migration-issue-fields-checkbox-select')
						? transformSetOptionsVisibilityForFrontendFilteringWithARIs
						: transformSelectSetOptionsVisibility,
				},
				internalToPublicTransformers: {
					value: multiCheckboxGetValueTransformer,
					optionsVisibility: transformSelectGetOptionsVisibility,
				},
				emitChangeOn: 'change',
				requiredScopes: multiCheckboxScopes,
				propertyMapping: {
					optionsVisibility: 'allowedValuesOverrides',
				},
				...(fg('refactor-field-processor')
					? {
							fieldProcessor: adaptIssueViewPerFieldHook({
								hook: useCheckboxSelectField,
								saveFnName: 'saveById',
							}),
						}
					: null),
			},
			[RADIO_BUTTONS_CF_TYPE]: {
				publicShapeValidators: {
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					description: commonFieldValidators.description,
					value: radioButtonsSetValueValidator,
					optionsVisibility: validateSetOptionsVisibility,
				},
				publicToInternalTransformers: {
					value: radioButtonsSetValueTransformer,
					optionsVisibility: fg('relay-migration-issue-fields-radio')
						? transformSetOptionsVisibilityForFrontendFilteringWithARIs
						: transformSelectSetOptionsVisibility,
				},
				internalToPublicTransformers: {
					value: radioButtonsGetValueTransformer,
					optionsVisibility: transformSelectGetOptionsVisibility,
				},
				emitChangeOn: 'change',
				requiredScopes: radioButtonsIssueViewScopes,
				propertyMapping: {
					optionsVisibility: 'allowedValuesOverrides',
				},
				...(fg('refactor-field-processor')
					? {
							fieldProcessor: adaptIssueViewPerFieldHook({
								hook: useRadioSelectCustomField,
								saveFnName: 'saveById',
							}),
						}
					: null),
			},
			[FIX_VERSIONS_TYPE]: {
				publicShapeValidators: {
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					description: commonFieldValidators.description,
					value: validateVersionValue,
				},
				publicToInternalTransformers: {
					value: transformVersionToInternalValue,
				},
				internalToPublicTransformers: {
					value: transformVersionToPublicValue,
				},
				emitChangeOn: 'change',
				requiredScopes: versionsRequiredScopes,
				...(fg('refactor-field-processor')
					? {
							fieldProcessor: adaptIssueViewPerFieldHook({
								hook: function useVersions(props: VersionFieldProps) {
									return useVersionsFields({ ...props, fieldKey: FIX_VERSIONS_TYPE });
								},
								saveFnName: 'saveById',
							}),
						}
					: null),
			},
			[AFFECTS_VERSIONS_TYPE]: {
				publicShapeValidators: {
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					description: commonFieldValidators.description,
					value: validateVersionValue,
				},
				publicToInternalTransformers: {
					value: transformVersionToInternalValue,
				},
				internalToPublicTransformers: {
					value: transformVersionToPublicValue,
				},
				emitChangeOn: 'change',
				requiredScopes: versionsRequiredScopes,
				...(fg('refactor-field-processor')
					? {
							fieldProcessor: adaptIssueViewPerFieldHook({
								hook: useVersionsFields,
								saveFnName: 'saveById',
							}),
						}
					: null),
			},
			[NUMBER_CF_TYPE]: {
				publicShapeValidators: {
					description: commonFieldValidators.description,
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: validateNumberValue,
				},
				publicToInternalTransformers: {},
				internalToPublicTransformers: {},
				emitChangeOn: 'change',
				requiredScopes: numberIssueViewScopes,
				...(fg('refactor-field-processor')
					? {
							fieldProcessor: adaptIssueViewPerFieldHook({
								hook: useNumberField,
								saveFnName: 'saveValue',
							}),
						}
					: null),
			},
			[LABELS_TYPE]: {
				publicShapeValidators: {
					description: commonFieldValidators.description,
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: validateLabelsSetValue,
				},
				publicToInternalTransformers: {},
				internalToPublicTransformers: {},
				emitChangeOn: 'change',
				requiredScopes: labelsRequiredScopes,
				...(fg('refactor-field-processor')
					? {
							fieldProcessor: adaptIssueViewPerFieldHook({
								hook: useLabelsField,
								saveFnName: 'saveValue',
							}),
						}
					: null),
			},
			[DATE_CF_TYPE]: {
				publicShapeValidators: {
					description: commonFieldValidators.description,
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: datePickerSetValueValidator,
				},
				publicToInternalTransformers: {},
				internalToPublicTransformers: {
					value: datePickerGetValueTransformer,
				},
				emitChangeOn: 'change',
				requiredScopes: datePickerIssueViewScopes,
				...(fg('refactor-field-processor')
					? {
							fieldProcessor: adaptIssueViewPerFieldHook({
								hook: useDateField,
								saveFnName: 'saveValue',
							}),
						}
					: null),
			},
			[DATETIME_CF_TYPE]: {
				publicShapeValidators: {
					description: commonFieldValidators.description,
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: validateDateTimePickerSetValue,
				},
				publicToInternalTransformers: {},
				internalToPublicTransformers: {
					value: transformDateTimePickerGetValue,
				},
				emitChangeOn: 'change',
				requiredScopes: dateTimePickerIssueViewScopes,
				...(fg('refactor-field-processor')
					? {
							fieldProcessor: adaptIssueViewPerFieldHook({
								hook: useDateTimeField,
								saveFnName: 'saveValue',
							}),
						}
					: null),
			},
			[USER_CF_TYPE]: {
				publicShapeValidators: {
					description: commonFieldValidators.description,
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: userPickerSetValueValidator,
				},
				publicToInternalTransformers: {
					value: assigneeSetValueTransformer,
				},
				internalToPublicTransformers: {
					value: assigneeGetValueTransformer,
				},
				emitChangeOn: 'change',
				lookupSource: LOOKUP_SOURCE_USERS_ASYNC_DATA,
				requiredScopes: assigneeIssueViewScopes,
				...(fg('refactor-field-processor')
					? {
							fieldProcessor: adaptIssueViewPerFieldHook({
								hook: useUserCustomField,
								saveFnName: 'saveValue',
							}),
						}
					: null),
			},
			[REPORTER_TYPE]: {
				publicShapeValidators: {
					description: commonFieldValidators.description,
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: reporterSetValueValidator,
				},
				publicToInternalTransformers: {
					value: assigneeSetValueTransformer,
				},
				internalToPublicTransformers: {
					value: assigneeGetValueTransformer,
				},
				emitChangeOn: 'change',
				lookupSource: LOOKUP_SOURCE_USERS_ASYNC_DATA,
				requiredScopes: assigneeIssueViewScopes,
				...(fg('refactor-field-processor')
					? {
							fieldProcessor: adaptIssueViewPerFieldHook({
								hook: useReporterField,
								saveFnName: 'saveValue',
							}),
						}
					: null),
			},
			[MULTI_USER_CF_TYPE]: {
				publicShapeValidators: {
					description: commonFieldValidators.description,
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: validateMultiUserPickerSetValue,
				},
				publicToInternalTransformers: {
					value: transformMultiUserPickerSetValue,
				},
				internalToPublicTransformers: {
					value: transformMultiUserPickerGetValue,
				},
				emitChangeOn: 'change',
				lookupSource: LOOKUP_SOURCE_USERS_ASYNC_DATA,
				requiredScopes: assigneeIssueViewScopes,
				...(fg('refactor-field-processor')
					? {
							fieldProcessor: adaptIssueViewPerFieldHook({
								hook: useMultiUserCustomField,
								saveFnName: 'saveValue',
							}),
						}
					: null),
			},
			[COMPONENTS_TYPE]: {
				publicShapeValidators: {
					description: commonFieldValidators.description,
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: validateComponentsSetValue,
				},
				publicToInternalTransformers: {
					value: transformComponentsSetValue,
				},
				internalToPublicTransformers: {
					value: transformComponentsGetValue,
				},
				emitChangeOn: 'change',
				requiredScopes: componentsIssueViewScopes,
				...(fg('refactor-field-processor')
					? {
							fieldProcessor: adaptIssueViewPerFieldHook({
								hook: useComponentsField,
								saveFnName: 'saveById',
							}),
						}
					: null),
			},
			[PARENT_TYPE]: {
				publicShapeValidators: {
					fieldName: commonFieldValidators.fieldName,
					description: commonFieldValidators.description,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: parentSetValueValidator,
				},
				publicToInternalTransformers: { value: transformParentSetValue },
				internalToPublicTransformers: {
					value: transformParentGetValue,
				},
				emitChangeOn: 'change',
				requiredScopes: parentIssueViewScopes,
				...(fg('refactor-field-processor')
					? {
							fieldProcessor: adaptIssueViewPerFieldHook<
								ParentFieldOption | null,
								null,
								ParentFieldResponse,
								ParentIdShape | null
							>({
								hook: useParentField,
								saveFnName: 'saveById',
							}),
						}
					: null),
			},
			[CASCADING_SELECT_CF_TYPE]: {
				publicShapeValidators: {
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					value: validateCascadingSelectWithAriSetValue,
					description: commonFieldValidators.description,
				},
				publicToInternalTransformers: {
					value: transformSetValueCascadingSelect,
				},
				internalToPublicTransformers: {
					value: transformGetValueCascadingSelect,
				},
				emitChangeOn: 'change',
				requiredScopes: cascadingSelectIssueViewScopes,
				...(fg('refactor-field-processor')
					? {
							fieldProcessor: adaptIssueViewPerFieldHook({
								hook: useCascadeSelectField,
								saveFnName: 'saveValue',
							}),
						}
					: null),
			},
			[PEOPLE_CF_TYPE]: {
				publicShapeValidators: {
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					description: commonFieldValidators.description,
					value: validatePeopleSetValue,
				},
				publicToInternalTransformers: {
					value: transformPeopleSetValue,
				},
				internalToPublicTransformers: {
					value: transformPeopleGetValue,
				},
				emitChangeOn: 'change',
				lookupSource: LOOKUP_SOURCE_USERS_ASYNC_DATA,
				requiredScopes: peopleIssueViewScopes,
				...(fg('refactor-field-processor')
					? {
							fieldProcessor: adaptIssueViewPerFieldHook({
								hook: usePeopleCustomField,
								saveFnName: 'saveValue',
							}),
						}
					: null),
			},
			[STATUS_TYPE]: {
				publicShapeValidators: {
					value: validateStatusSetValue,
				},
				publicToInternalTransformers: { value: transformStatusSetValue },
				internalToPublicTransformers: {
					value: transformStatusGetValue,
				},
				emitChangeOn: 'change',
				requiredScopes: statusIssueViewScopes,
				...(fg('refactor-field-processor')
					? {
							fieldProcessor: adaptIssueViewPerFieldHook({
								hook: useStatusField,
								saveFnName: 'saveById',
							}),
						}
					: null),
			},
			[TIME_ESTIMATE_TYPE]: {
				publicShapeValidators: {
					fieldName: commonFieldValidators.fieldName,
					isReadOnly: commonFieldValidators.isReadOnly,
					isVisible: commonFieldValidators.isVisible,
					description: commonFieldValidators.description,
					value: validateOriginalEstimate,
				},
				publicToInternalTransformers: {
					value: transformSetValueOriginalEstimate,
				},
				internalToPublicTransformers: {
					value: transformGetValueOriginalEstimate,
				},
				emitChangeOn: 'change',
				requiredScopes: originalEstimateIssueViewScopes,
				...(fg('refactor-field-processor')
					? {
							fieldProcessor: adaptIssueViewPerFieldHook({
								hook: useOriginalEstimateFieldWithTimeTracking,
								saveFnName: 'saveValue',
							}),
						}
					: null),
			},
		} as const;
	}

	getSupportedFieldTypes() {
		const supportedFieldTypes: FieldType[] = [
			SUMMARY_TYPE,
			DESCRIPTION_TYPE,
			ASSIGNEE_TYPE,
			PRIORITY_TYPE,
			URL_CF_TYPE,
			TEXT_CF_TYPE,
			MULTI_CHECKBOXES_CF_TYPE,
			TEXT_AREA_CF_TYPE,
			SELECT_CF_TYPE,
			MULTI_SELECT_CF_TYPE,
			RADIO_BUTTONS_CF_TYPE,
			FIX_VERSIONS_TYPE,
			NUMBER_CF_TYPE,
			DATE_CF_TYPE,
			DATETIME_CF_TYPE,
			LABELS_TYPE,
			USER_CF_TYPE,
			REPORTER_TYPE,
			COMPONENTS_TYPE,
			MULTI_USER_CF_TYPE,
			PARENT_TYPE,
			AFFECTS_VERSIONS_TYPE,
			PEOPLE_CF_TYPE,
			STATUS_TYPE,
			TIME_ESTIMATE_TYPE,
			CASCADING_SELECT_CF_TYPE,
		];

		return supportedFieldTypes;
	}

	isExecutionContextSupported({ context }: { context: IssueViewExecutionContext }): boolean {
		if (!context.project) {
			return false;
		}

		return this.supportedProjectTypes.includes(context.project.type);
	}

	getConsentMessage() {
		return fg('jira-issue-terminology-refresh-m3')
			? messages.uimIssueViewConsentMessageIssueTermRefresh
			: messages.uimIssueViewConsentMessage;
	}

	getAnalytics() {
		return this.analytics;
	}

	getSupportedTriggerPointsConfiguration() {
		return new Set([
			FULL_ISSUE,
			SOFTWARE_ISSUE,
			SOFTWARE_BACKLOG_ISSUE,
			SIMPLE_SOFTWARE_BOARD,
			AGILITY_BACKLOG,
			GLOBAL_ISSUE_NAVIGATOR_ISSUE,
			ISSUE_NAVIGATOR,
			BUSINESS_LIST,
		] as const);
	}
}
