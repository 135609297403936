import React from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { BlockedAppsIssueInfoBanner as BlockedAppsBanner } from '@atlassian/jira-blocked-apps-info-banner/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { IssueViewRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { IssueViewDataClassification } from '@atlassian/jira-issue-field-data-classification/src/ui/index.tsx';
import { REACT_KEY_SUMMARY } from '@atlassian/jira-issue-layout-common-constants/src/index.tsx';
import ColorField from '@atlassian/jira-issue-view-foundation/src/color/index.tsx';
import SummaryField from '@atlassian/jira-issue-view-foundation/src/summary/index.tsx';
import { MajorIncidentLozengeWithTooltip } from '@atlassian/jira-major-incident/src/ui/major-incident-lozenge/index.tsx';
import { ReadOnlyIssueInfo } from '@atlassian/jira-read-only-issue-info/src/main.tsx';

function FoundationContent({
	issueViewRelayFragment,
}: {
	// TODO Decomp JIV-12514 - add useFragment to this component and replace this prop with more specific fragment key
	issueViewRelayFragment?: IssueViewRelayFragment | null;
}) {
	return (
		<>
			<BlockedAppsBanner />
			<ReadOnlyIssueInfo />
			<IssueViewDataClassification issueViewRelayFragment={issueViewRelayFragment ?? null} />
			{fg('blu-6276-fix-major-incident-lozenge-placement') ? (
				<Box xcss={foundationContentColumnWrapperStyles}>
					<MajorIncidentLozengeWithTooltip />
					<Box xcss={foundationContentRowWrapperStyles}>
						<ColorField />
						<SummaryFieldWrapper key={REACT_KEY_SUMMARY}>
							<SummaryField />
						</SummaryFieldWrapper>
					</Box>
				</Box>
			) : (
				<FoundationContentWrapper>
					<MajorIncidentLozengeWithTooltip />
					<ColorField />
					<SummaryFieldWrapper key={REACT_KEY_SUMMARY}>
						<SummaryField />
					</SummaryFieldWrapper>
				</FoundationContentWrapper>
			)}
		</>
	);
}

export default FoundationContent;

const foundationContentColumnWrapperStyles = xcss({
	display: 'flex',
	flex: '1 1 auto',
	minWidth: '0',
	width: '100%',
	flexDirection: 'column',
});

const foundationContentRowWrapperStyles = xcss({
	display: 'flex',
	flex: '1 1 auto',
	minWidth: '0',
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FoundationContentWrapper = styled.div({
	display: 'flex',
	flex: '1 1 auto',
	minWidth: 0,
	width: '100%',
});
FoundationContentWrapper.displayName = 'FoundationContentWrapper';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SummaryFieldWrapper = styled.div({
	display: 'flex',
	flex: '1 1 auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div': {
		display: 'flex',
		flex: '1 1 auto',
	},
});
SummaryFieldWrapper.displayName = 'SummaryFieldWrapper';
