import React, { type MouseEvent, useEffect, useCallback, useState, useMemo } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Portal from '@atlaskit/portal';
import { triggerPostMoveFlash } from '@atlaskit/pragmatic-drag-and-drop-flourish/trigger-post-move-flash';
import {
	type Edge,
	extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { getReorderDestinationIndex } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { reorder } from '@atlaskit/pragmatic-drag-and-drop/reorder';
import { token } from '@atlaskit/tokens';
import { AnnouncerV2 } from '@atlassian/jira-accessibility/src/ui/announcer-v2/index.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { useIntl } from '@atlassian/jira-intl';
import { useItemRegistry } from '@atlassian/jira-issue-sortable/src/controllers/use-item-registry/index.tsx';
import { isSortableItemData } from '@atlassian/jira-issue-sortable/src/controllers/use-sortable-item/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { ItemLineCard, type ItemRenderer } from './item-line-card/index.tsx';
import type { BaseItem } from './item-line-card/types.tsx';
import messages from './messages.tsx';

export type ItemLineCardGroupProps<Item extends BaseItem> = {
	groupId: string;
	items: ReadonlyArray<Item>;
	children: ItemRenderer<Item>;
	isReorderEnabled?: boolean;
	isInteractive?: boolean;
	isVariableHeightCards?: boolean;
	onOrderChange?: (
		items: ReadonlyArray<Item>,
		target: Item,
		sourceIdx: number,
		destinationIdx: number,
		analyticsEvent: UIAnalyticsEvent,
	) => void;
	onClick?: (item: Item, event: MouseEvent<HTMLElement>) => void;

	testId?: string;
};

type Action = {
	type: 'reorder';
	draggableId: string;
	itemName: string;
	startPosition: number;
	endPosition: number;
	numberOfItems: number;
};

export function ItemLineCardGroup<Item extends BaseItem>({
	groupId: instanceId,
	items,
	isReorderEnabled = false,
	isInteractive = true,
	isVariableHeightCards = false,
	onOrderChange = noop,
	onClick = noop,
	testId,
	children,
}: ItemLineCardGroupProps<Item>) {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { itemRegistry, registerItem } = useItemRegistry();

	const [lastAction, setLastAction] = useState<Action | null>(null);

	useEffect(() => {
		if (lastAction === null) {
			return;
		}

		if (lastAction.type === 'reorder') {
			const element = itemRegistry.get(lastAction.draggableId);
			if (!element) {
				return;
			}
			triggerPostMoveFlash(element);
		}
	}, [itemRegistry, lastAction]);

	const onReorderItem = useCallback(
		({ startIndex, finishIndex }: { startIndex: number; finishIndex: number }) => {
			const itemsCopy = reorder({
				/**
				 * Casting from `readonly Item[]` to mutable `Item[]` because
				 * the `reorder` util does not mark the input as `readonly`,
				 * but it does not mutate the list (so this is still safe).
				 */
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				list: items as Item[],
				startIndex,
				finishIndex,
			});

			const analyticsEvent = createAnalyticsEvent({
				action: 'updated',
				actionSubject: 'issueRank',
			});

			setLastAction({
				type: 'reorder',
				draggableId: items[startIndex].id,
				itemName: items[startIndex].issueSummary ?? '',
				startPosition: startIndex + 1,
				endPosition: finishIndex + 1,
				numberOfItems: items.length,
			});
			onOrderChange(itemsCopy, items[startIndex], startIndex, finishIndex, analyticsEvent);
		},
		[createAnalyticsEvent, items, onOrderChange],
	);

	useEffect(
		() =>
			monitorForElements({
				canMonitor({ source }) {
					return isSortableItemData(source.data) && source.data.instanceId === instanceId;
				},
				onDrop({ source, location }) {
					const destination = location.current.dropTargets[0];

					if (!destination) {
						return;
					}

					if (!isSortableItemData(destination.data) || !isSortableItemData(source.data)) {
						return;
					}

					const closestEdgeOfTarget: Edge | null = extractClosestEdge(destination.data);

					const startIndex = source.data.index;
					const finishIndex = getReorderDestinationIndex({
						startIndex,
						indexOfTarget: destination.data.index,
						closestEdgeOfTarget,
						axis: 'vertical',
					});

					onReorderItem({ startIndex, finishIndex });
				},
			}),
		[createAnalyticsEvent, instanceId, items, onOrderChange, onReorderItem],
	);

	const intl = useIntl();

	const liveRegion = useMemo(() => {
		if (lastAction?.type === 'reorder') {
			return {
				shouldAnnounce: true,
				message: intl.formatMessage(messages.postMoveAnnouncement, lastAction),
			};
		}

		return { shouldAnnounce: false, message: '' };
	}, [intl, lastAction]);

	return (
		<>
			<Container data-testid={testId}>
				{items.map((item: Item, index: number) => (
					<ErrorBoundary key={item.id}>
						<ItemLineCard
							item={item}
							index={index}
							numItems={items.length}
							isReorderEnabled={isReorderEnabled}
							isInteractive={isInteractive}
							isVariableHeight={isVariableHeightCards}
							onClick={onClick}
							onReorderItem={onReorderItem}
							setItemInRegistry={registerItem}
							testId={testId ? `${testId}.item--${item.id}` : undefined}
							instanceId={instanceId}
						>
							{children}
						</ItemLineCard>
					</ErrorBoundary>
				))}
			</Container>
			{isReorderEnabled && (
				/**
				 * Only rendering this if reordering is enabled to avoid DOM bloat.
				 *
				 * Portalling to avoid a layout issue. It was causing the body scroll
				 * container for the issue view to grow and have dead space.
				 */
				<Portal>
					<AnnouncerV2
						shouldAnnounce={liveRegion.shouldAnnounce}
						message={liveRegion.message}
						liveMode="assertive"
					/>
				</Portal>
			)}
		</>
	);
}

const Container = componentWithCondition(
	isVisualRefreshEnabled,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
	styled.ul({
		backgroundColor: token('elevation.surface'),
		borderWidth: '1px',
		borderStyle: 'solid',
		borderColor: `${token('color.border')}`,
		borderRadius: '3px',
		paddingTop: token('space.0'),
		paddingRight: token('space.0'),
		paddingBottom: token('space.0'),
		paddingLeft: token('space.0'),
	}),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
	styled.ul({
		boxShadow: token('elevation.shadow.raised'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
		backgroundColor: token('elevation.surface.raised'),
		borderRadius: '3px',
		paddingTop: token('space.0'),
		paddingRight: token('space.0'),
		paddingBottom: token('space.0'),
		paddingLeft: token('space.0'),
	}),
);
