/** @jsx jsx */
import React, { useEffect } from 'react';

import { css, jsx } from '@compiled/react';
import { IconTile } from '@atlaskit/icon';
import PageIcon from '@atlaskit/icon/core/page';
import Lozenge from '@atlaskit/lozenge';
import { Box, xcss } from '@atlaskit/primitives';
import LoadingSpinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import Pressable from '@atlaskit/primitives/pressable';

import { useIntl, type MessageDescriptor } from '@atlassian/jira-intl';
import { Container } from '@atlassian/jira-issue-view-common-views/src/smart-link-content/styled.tsx';

type Props = {
	testId?: string;
	templateMessage: MessageDescriptor;
	lozengeMessage: MessageDescriptor;
	loadingMessage?: MessageDescriptor;
	onClick?: (e: React.MouseEvent) => void;
	onClickCapture?: (e: React.MouseEvent) => void;
	onFeatureExposed?: () => void;
	showHeading?: boolean;
};

export const PlaceholderTemplate = ({
	templateMessage,
	lozengeMessage,
	loadingMessage,
	onClick,
	onClickCapture,
	onFeatureExposed,
	testId = '',
}: Props) => {
	const { formatMessage } = useIntl();

	useEffect(() => {
		onFeatureExposed && onFeatureExposed();
	}, [onFeatureExposed]);

	return (
		<Box xcss={containerStyles}>
			<Container>
				<li css={listStyle}>
					<Pressable
						xcss={buttonStyle}
						onClick={onClick}
						onClickCapture={onClickCapture}
						testId={testId}
					>
						<div css={cardStyles}>
							<div css={containerLeftStyles}>
								<IconTile icon={PageIcon} label="" appearance="blueBold" shape="square" size="16" />
								<div css={templateNameCss}>{formatMessage(templateMessage)}</div>
							</div>
							<div css={containerRightStyles}>
								{loadingMessage ? (
									<>
										{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
										<p css={loadingStyle}>{formatMessage(loadingMessage)}</p>
										<LoadingSpinner size="small" />
									</>
								) : (
									<Lozenge appearance="new">{formatMessage(lozengeMessage)}</Lozenge>
								)}
							</div>
						</div>
					</Pressable>
				</li>
			</Container>
		</Box>
	);
};

const buttonStyle = xcss({
	backgroundColor: 'elevation.surface',
	width: '100%',
	padding: '0',
	font: 'font.body',
});

const containerRightStyles = css({
	display: 'flex',
	alignItems: 'center',
	gap: `0 ${token('space.100')}`,
	justifyContent: 'space-between',

	color: token('color.text'),
});

const containerLeftStyles = css({
	display: 'flex',
	alignItems: 'center',
	gap: `0 ${token('space.100')}`,
	justifyContent: 'space-between',

	color: token('color.text'),
	minWidth: 0,
});

const containerStyles = xcss({
	paddingRight: 'space.025',
});

const loadingStyle = css({
	color: token('color.text.subtlest'),
	font: token('font.body.UNSAFE_small'),
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

const cardStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:last-child': {
		borderBottomColor: 'transparent',
	},
	'&:hover': {
		backgroundColor: token('color.background.neutral.subtle.hovered'),
		textDecoration: 'none',
		cursor: 'pointer',
	},

	borderBottom: `1px solid ${token('color.border')}`,
	boxShadow: 'none',

	backgroundColor: token('color.background.neutral.subtle'),
	paddingTop: 0,
	paddingRight: token('space.075'),
	paddingBottom: 0,
	paddingLeft: token('space.075'),
	marginTop: 0,
	marginRight: token('space.025'),
	marginBottom: 0,
	marginLeft: token('space.025'),
	borderRadius: '3px',
	display: 'flex',
	minHeight: '40px',
	justifyContent: 'space-between',
	alignItems: 'center',
	alignSelf: 'stretch',
});

const templateNameCss = css({
	marginTop: token('space.0'),
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

const listStyle = css({
	listStyle: 'none',
});
