import React, { memo, useCallback } from 'react';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type {
	IssueViewRelayFragment,
	MainIssueAggQueryRelayFragment,
} from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { useIssueFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import {
	useFieldsValues,
	useFieldValue,
} from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { useIsIssueOfPostIncidentReviewPractice } from '@atlassian/jira-issue-field-servicedesk-practices/src/services/use-practices-field-value/index.tsx';
import {
	CONTEXT_AREA,
	layoutContainerItemTypes,
} from '@atlassian/jira-issue-layout-common-constants/src/index.tsx';
import { PROJECT_ARCHIVED } from '@atlassian/jira-issue-shared-types/src/common/types/project-type.tsx';
import { SERVICEDESK_SLA_PANEL } from '@atlassian/jira-issue-view-common-constants/src/onboarding-constants.tsx';
import {
	ALWAYS_PRIMARY_AND_VISIBLE,
	JSD_TEMPLATE,
} from '@atlassian/jira-issue-view-layout-templates-constants/src/index.tsx';
import { ItemList } from '@atlassian/jira-issue-view-layout-templates-item-list/src/index.tsx';
import { useVisibleHiddenItems } from '@atlassian/jira-issue-view-layout-templates-services/src/services/context/visible-hidden/visible-hidden-items/index.tsx';
import { ContextGroup } from '@atlassian/jira-issue-view-layout-templates-views-context-group/src/index.tsx';
import VisibleHiddenContextSectionView from '@atlassian/jira-issue-view-layout-templates-views/src/ui/context/visible-hidden/ui/index.tsx';
import { getLayoutItemId } from '@atlassian/jira-issue-view-layout/src/services/utils.tsx';
import {
	CUSTOMER_CONTEXT_TYPE,
	SENTIMENT_TYPE,
	SLA_PANEL_TYPE,
	STATUS_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	useProjectKey,
	useProjectContext,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { useCanUseServiceDeskAgentFeatures } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { useGlobalRefStoreActions } from '@atlassian/jira-refs-store/src/index.tsx';
import { AIContextPanel } from '@atlassian/jira-servicedesk-ai-context-panel/src/ui/index.tsx';
import { CustomerServiceIssueViewPanel } from '@atlassian/jira-servicedesk-customer-service-issue-view-panel/src/ui/index.tsx';
import { INCIDENT_SUMMARY_SECTION } from '@atlassian/jira-servicedesk-post-incident-review/src/common/constants.tsx';
import { AsyncIncidentSummary } from '@atlassian/jira-servicedesk-post-incident-review/src/ui/incident-summary/async.tsx';
import { useSlaDetails } from '@atlassian/jira-servicedesk-sla-panel/src/services/fetch-sla-details/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import messages from './messages.tsx';
import { addGlobalRefs, transformCSAT, isSentimentCustomFieldShown } from './utils.tsx';

type Props = {
	// TODO Decomp BENTO-12514 - add useFragment to this component and replace this prop with more specific fragment key
	issueViewRelayFragment?: IssueViewRelayFragment | null;
	rootRelayFragment: MainIssueAggQueryRelayFragment | null;
};

const ContextItems = ({ issueViewRelayFragment, rootRelayFragment }: Props) => {
	const [items, updateHiddenSecondaryFields] = useVisibleHiddenItems(
		ALWAYS_PRIMARY_AND_VISIBLE[JSD_TEMPLATE],
	);
	const { formatMessage } = useIntl();
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const [status] = useFieldValue({ issueKey, fieldKey: STATUS_TYPE });
	const [{ value: issueFieldsConfig }] = useIssueFieldConfig(issueKey);
	const [issueFieldsValues] = useFieldsValues(issueKey);
	const projectContext = useProjectContext(projectKey);
	const [sla, error] = useSlaDetails();
	const canUseServiceDeskAgentFeatures = useCanUseServiceDeskAgentFeatures(projectKey);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [, { register }] = useGlobalRefStoreActions();

	const onRef = useCallback(
		(ref: HTMLDivElement | null) => {
			register(SERVICEDESK_SLA_PANEL, ref);
		},
		[register],
	);

	const isNextGen = projectContext === undefined ? false : projectContext.isSimplified;
	const itemsWithCsatField = transformCSAT(
		isNextGen,
		items,
		status,
		issueFieldsConfig,
		issueFieldsValues,
	);
	const itemsWithRefs = addGlobalRefs(itemsWithCsatField, issueFieldsConfig);

	const slaIndex = itemsWithRefs.visibleItems.findIndex(
		(item) => getLayoutItemId(item) === SLA_PANEL_TYPE,
	);

	const slaItem = slaIndex !== -1 && itemsWithRefs.visibleItems.splice(slaIndex, 1);
	// removing loading state from shouldShowSla as it shows a janky flicker behaviour for projects where SLA is not configured.
	// @ts-expect-error - TS2571 - Object is of type 'unknown'.
	let shouldShowSla = error || (sla && sla.goalViews.length !== 0);

	if (!(canUseServiceDeskAgentFeatures || projectContext?.projectStatus === PROJECT_ARCHIVED)) {
		shouldShowSla = false;
	}

	const SlaPanel = !!slaItem && (
		<div ref={onRef}>
			<ContextGroup title={formatMessage(messages.slaText)} groupId="sla-group" initialOpened>
				<ItemList
					items={slaItem}
					area={CONTEXT_AREA}
					layoutItemsDataFragment={issueViewRelayFragment ?? null}
					rootRelayFragment={rootRelayFragment}
				/>
			</ContextGroup>
		</div>
	);

	const shouldShowCustomerContextPanel =
		itemsWithRefs.visibleItems.findIndex(
			(item) => getLayoutItemId(item) === CUSTOMER_CONTEXT_TYPE,
		) !== -1;

	// Temporary workaround to show a new field for Sentiment without BE changes.
	// TODO NBL-3495 - implement sentiment as a proper custom domain field
	if (!isSentimentCustomFieldShown(items, issueFieldsConfig) && fg('jsm-sentiment-analysis')) {
		itemsWithRefs.visibleItems.push({
			type: layoutContainerItemTypes.field,
			fieldItemId: SENTIMENT_TYPE,
		});
		// analytics is added to visualise on the solution for deprecating this panel
		fireTrackAnalytics(createAnalyticsEvent({}), 'sentimentPanel visible');
	}

	const CustomerContextPanel = shouldShowCustomerContextPanel && (
		<ContextGroup
			title={formatMessage(messages.customerContextText)}
			groupId="customer-context-group"
			initialOpened
		>
			<CustomerServiceIssueViewPanel />
		</ContextGroup>
	);

	const AsyncIncidentSummaryComponent = useIsIssueOfPostIncidentReviewPractice() ? (
		<SpotlightTarget name={INCIDENT_SUMMARY_SECTION}>
			<ContextGroup
				groupId="incident-summary"
				title={formatMessage(messages.incidentSummaryText)}
				initialOpened
			>
				<AsyncIncidentSummary />
			</ContextGroup>
		</SpotlightTarget>
	) : null;

	return (
		<>
			{Boolean(shouldShowSla) && SlaPanel}
			{fg('jsm_issue_view_ai_context') && (
				<JSErrorBoundary fallback="unmount" id="ai-context-panel" teamName="JSM Agent AI">
					<AIContextPanel />
				</JSErrorBoundary>
			)}
			{CustomerContextPanel}
			{AsyncIncidentSummaryComponent}
			<VisibleHiddenContextSectionView
				items={itemsWithRefs}
				// Below error is only in typecheck with reference that why added ts-ignore
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore - error TS2719: Type mismatch
				issueViewRelayFragment={issueViewRelayFragment}
				onToggleExpanded={updateHiddenSecondaryFields}
				rootRelayFragment={rootRelayFragment}
			/>
		</>
	);
};

export default componentWithFG(
	'issue_view_improve_context_layout',
	memo(ContextItems),
	ContextItems,
);
