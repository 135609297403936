import React, { useCallback, useState, useMemo } from 'react';
import { useFragment, graphql, useMutation } from 'react-relay';
import type { ValidationFieldProps } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/field-inline-edit-lite/types.tsx';
import SingleSelectEditViewEntryPoint from '@atlassian/jira-issue-field-single-select-editview-full/src/entrypoint.tsx';
import { FieldInlineEditLiteWithEntryPoint } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/index.tsx';
import { useFieldInlineEditActions } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/index.tsx';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import type {
	SingleSelectEditViewProps,
	NullableOption,
} from '@atlassian/jira-issue-field-single-select-editview-full/src/ui/single-select/types.tsx';
import { SingleSelectReadView } from '@atlassian/jira-issue-field-single-select-readview-full/src/ui/single-select/index.tsx';
import type { lite_singleSelect_issueFieldSingleSelect_SingleSelectField_Mutation as SingleSelectInlineEditViewMutation } from '@atlassian/jira-relay/src/__generated__/lite_singleSelect_issueFieldSingleSelect_SingleSelectField_Mutation.graphql';

import type { OnSubmitCallbacks } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/types.tsx';
import type { lite_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditViewIsEditable_fragmentRef$key as SingleSelectInlineEditViewIsEditableFragment } from '@atlassian/jira-relay/src/__generated__/lite_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditViewIsEditable_fragmentRef.graphql';
import type { lite_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditView_fragmentRef$key as SingleSelectInlineEditViewFragment } from '@atlassian/jira-relay/src/__generated__/lite_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditView_fragmentRef.graphql';
import type {
	SingleSelectInlineEditViewNewProps,
	SingleSelectInlineEditViewIsEditableProps,
} from '../types.tsx';

/** Check if the new selected value is equal to the old value */
export const isEqualSelection = (a: NullableOption, b: NullableOption) => a?.value === b?.value;

export const SingleSelectInlineEditView = ({
	fragmentRef,
	...props
}: SingleSelectInlineEditViewNewProps) => {
	const data = useFragment<SingleSelectInlineEditViewFragment>(
		graphql`
			fragment lite_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditView_fragmentRef on JiraSingleSelectField {
				...lite_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditViewIsEditable_fragmentRef
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
	);

	return (
		<SingleSelectInlineEditViewIsEditable
			{...props}
			fragmentRef={data}
			isEditable={data.fieldConfig?.isEditable ?? false}
		/>
	);
};

export const SingleSelectInlineEditViewIsEditable = ({
	attributes,
	editViewPopup,
	editViewPopupAlignBlock,
	fragmentRef,
	onSubmit,
	onSubmitSucceeded,
	onSubmitFailed,
	spacing = 'compact',
	menuPosition,
	menuPortalTarget,
	readViewFitContainerHeight,
	isEditable,
}: SingleSelectInlineEditViewIsEditableProps) => {
	// #region Relay
	const data = useFragment<SingleSelectInlineEditViewIsEditableFragment>(
		graphql`
			fragment lite_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditViewIsEditable_fragmentRef on JiraSingleSelectField {
				id
				name
				fieldId
				type
				...singleSelect_issueFieldSingleSelectReadviewFull_SingleSelectReadView
				fieldOption {
					id
					optionId
					value
				}
			}
		`,
		fragmentRef,
	);

	const [commit] = useMutation<SingleSelectInlineEditViewMutation>(graphql`
		mutation lite_singleSelect_issueFieldSingleSelect_SingleSelectField_Mutation(
			$input: JiraUpdateSingleSelectFieldInput!
		) @raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateSingleSelectField(input: $input) {
					success
					errors {
						message
					}
					field {
						...lite_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditViewIsEditable_fragmentRef
					}
				}
			}
		}
	`);

	const { id: uniqueFieldId, name, fieldOption, fieldId, type: fieldType } = data;
	// #endregion Relay

	// #region State
	const initialValue = useMemo(
		() =>
			fieldOption
				? {
						label: fieldOption.value ?? '',
						value: fieldOption.id ?? '',
						optionId: fieldOption.optionId ?? '',
					}
				: null,
		[fieldOption],
	);

	const [updatedValue, setUpdatedValue] = useState<NullableOption | null>(initialValue);

	const { overriding } = useInlineEditFieldInjections();
	const { overrideLabel, overrideIsEditable, overrideFieldOptionsFilter } = overriding;

	const fieldName = useMemo(() => overrideLabel(name), [overrideLabel, name]);

	const isFieldEditable = useMemo(
		() => overrideIsEditable(isEditable),
		[overrideIsEditable, isEditable],
	);

	const filterOptionsById = useMemo(
		() => overrideFieldOptionsFilter(null),
		[overrideFieldOptionsFilter],
	);
	// #endregion State

	// #region Action
	const handleSubmit = useCallback(
		(newOption: NullableOption, { onSuccess, onFail }: OnSubmitCallbacks) => {
			onSubmit?.(newOption);

			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operation: {
							operation: 'SET',
							id: newOption?.value ?? null,
						},
					},
				},
				onCompleted: (response) => {
					if (response.jira?.updateSingleSelectField?.success) {
						onSuccess();
					} else {
						onFail();
					}
				},
				onError: (error) => onFail(error, error.message),
				optimisticResponse: {
					jira: {
						updateSingleSelectField: {
							success: true,
							errors: null,
							field: {
								id: uniqueFieldId,
								name,
								fieldId,
								type: fieldType,
								fieldOptions: null,
								fieldOption: newOption
									? {
											id: newOption.value,
											optionId: newOption.optionId,
											value: newOption.label,
											color: null,
										}
									: null,
							},
						},
					},
				},
			});
		},
		[commit, fieldId, fieldType, name, onSubmit, uniqueFieldId],
	);

	const actions = useFieldInlineEditActions({
		attributes,
		fieldId,
		fieldName,
		fieldType,
		initialValue,
		onSubmit: handleSubmit,
		onSubmitFailed,
		onSubmitSucceeded,
		onUpdateValue: setUpdatedValue,
		updatedValue,
		isValueEqual: isEqualSelection,
		clipboardComponentType: 'singleSelect',
	});

	const {
		hasServerValidationError,
		handleCancel,
		handleEdit,
		handleConfirm,
		handleChangeAndConfirm,
		handleCopy,
		handleCut,
		handlePaste,
		invalidMessage,
		isEditing,
	} = actions;
	// #endregion Action

	// #region View
	const renderReadView = useCallback(
		() => <SingleSelectReadView fieldType={fieldType} fragmentRef={data} />,
		[data, fieldType],
	);

	const getEditViewProps = (fieldProps: ValidationFieldProps): SingleSelectEditViewProps => ({
		...fieldProps,
		fieldId: uniqueFieldId,
		fieldType,
		fieldName,
		autoFocus: true,
		value: updatedValue,
		menuPosition,
		menuPortalTarget,
		onChange: handleChangeAndConfirm,
		filterOptionsById,
		spacing,
		openMenuOnFocus: true,
	});

	return (
		<FieldInlineEditLiteWithEntryPoint
			editViewPopup={editViewPopup}
			editViewPopupAlignBlock={editViewPopupAlignBlock}
			editViewPopupMinWidth="small"
			editViewEntryPoint={SingleSelectEditViewEntryPoint}
			editViewEntryPointParams={{ id: uniqueFieldId, filterById: filterOptionsById }}
			getEditViewProps={getEditViewProps}
			fieldName={fieldName}
			hasUnsubmittedChanges={hasServerValidationError}
			invalidMessage={invalidMessage}
			isEditing={isEditing}
			isEditable={isFieldEditable}
			onCancel={handleCancel}
			onConfirm={handleConfirm}
			onEdit={handleEdit}
			onCopy={handleCopy}
			onCut={handleCut}
			onPaste={handlePaste}
			readViewFitContainerHeight={readViewFitContainerHeight}
			readView={renderReadView}
			hideActionButtons
		/>
	);
	// #endregion View
};
