import type { ViewUUID } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State } from '../../types.tsx';

export const updateViewLastSeenStateInternal =
	(viewUUID: ViewUUID, data: Record<AccountId, Date>) => async (api: StoreActionApi<State>) => {
		const { setState, getState } = api;
		setState({
			lastSeen: {
				...getState().lastSeen,
				[viewUUID]: { loading: true, data, error: undefined },
			},
		});
	};
