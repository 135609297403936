import React, { useContext } from 'react';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { createUseEntryPointContext } from '@atlassian/jira-entry-point/src/controllers/utils/create-use-entry-point-context/index.tsx';
import { AIOpsLinkIncidentSuggestionsEntrypoint } from '../../entrypoint.tsx';

export const AIOpsLinkIncidentSuggestionsEntrypointContext =
	createUseEntryPointContext<typeof AIOpsLinkIncidentSuggestionsEntrypoint>();

const EMPTY_ENTRY_POINT_PARAMS = {};

export const useAIOpsLinkIncidentSuggestionsEntryPointContext = () =>
	useContext(AIOpsLinkIncidentSuggestionsEntrypointContext);

export const AIOpsLinkIncidentSuggestionsEntrypointContextProvider = ({
	children,
}: React.PropsWithChildren<{}>) => {
	const entryPoint = useEntryPoint(
		AIOpsLinkIncidentSuggestionsEntrypoint,
		EMPTY_ENTRY_POINT_PARAMS,
	);

	return (
		<AIOpsLinkIncidentSuggestionsEntrypointContext.Provider value={entryPoint}>
			{children}
		</AIOpsLinkIncidentSuggestionsEntrypointContext.Provider>
	);
};
