import { useCallback, useEffect, useState, type ReactNode } from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import type { QuickActionComponent } from '../types.tsx';

const useFocusTrapSearch = ({
	actionsMenuRef,
	searchRef,
	actions,
	crossflow,
	searchQuery,
}: {
	actionsMenuRef: React.RefObject<HTMLDivElement>;
	searchRef: React.RefObject<HTMLInputElement>;
	actions: ReactNode[];
	crossflow?: {
		actions: QuickActionComponent[];
	};
	searchQuery?: string;
}) => {
	const [menuItems, setMenuItems] = useState<NodeListOf<HTMLButtonElement> | undefined>(
		actionsMenuRef.current?.querySelectorAll<HTMLButtonElement>(
			'div [role="group"] > [role="menuitem"]',
		),
	);

	const firstElementFocusCallback = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === 'ArrowUp') {
				setTimeout(() => {
					searchRef.current?.focus();
				}, 0);
			}
		},
		[searchRef],
	);

	const lastElementFocusCallback = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === 'ArrowDown') {
				setTimeout(() => {
					searchRef.current?.focus();
				}, 0);
			}
		},
		[searchRef],
	);

	useEffect(() => {
		if (!expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false)) {
			return;
		}

		setTimeout(() => {
			setMenuItems((prevMenuItems) => {
				if (prevMenuItems?.length) {
					prevMenuItems[0].removeEventListener('keydown', firstElementFocusCallback);
					prevMenuItems[prevMenuItems.length - 1].removeEventListener(
						'keydown',
						lastElementFocusCallback,
					);
				}
				const newMenuItems = actionsMenuRef.current?.querySelectorAll<HTMLButtonElement>(
					'div [role="group"] > [role="menuitem"]',
				);

				return newMenuItems;
			});
		}, 0);
	}, [
		actions,
		crossflow?.actions,
		actionsMenuRef,
		searchQuery,
		firstElementFocusCallback,
		lastElementFocusCallback,
	]);

	useEffect(() => {
		if (!expVal('quick_actions_m2_experiment', 'isM2ExperimentEnabled', false)) {
			return;
		}

		if (menuItems?.length) {
			menuItems[0].addEventListener('keydown', firstElementFocusCallback);
			menuItems[menuItems.length - 1].addEventListener('keydown', lastElementFocusCallback);
		}
	}, [menuItems, firstElementFocusCallback, lastElementFocusCallback]);
};

export default useFocusTrapSearch;
