// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { State } from '../../types.tsx';
import { setFieldCache } from '../set-field-cache/index.tsx';
import type { SetRecentlySelectedItemProps } from './types.tsx';
import { getRecentlySelectedWithoutNewSelected } from './utils.tsx';

export const setRecentlySelectedItem =
	({ fieldKey, projectKey, selected, maxLength }: SetRecentlySelectedItemProps): Action<State> =>
	({ getState, dispatch }) => {
		const recentlySelected = getState()[fieldKey][projectKey]?.recentlySelected || [];

		const recentlySelectedWithoutNewSelected = getRecentlySelectedWithoutNewSelected({
			selections: recentlySelected,
			newlySelected: selected,
		});

		const newRecentlySelected = [selected, ...recentlySelectedWithoutNewSelected];
		const newRecentlySelectedOfMaxLength =
			newRecentlySelected.length <= maxLength
				? newRecentlySelected
				: newRecentlySelected.slice(0, maxLength);

		dispatch(
			setFieldCache({
				fieldKey,
				projectKey,
				recentlySelected: newRecentlySelectedOfMaxLength,
			}),
		);
	};
