import React, { Children, type ReactNode } from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import times from 'lodash/times';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { Skeleton, SkeletonCompiled } from './styled.tsx';

export const ButtonGroupSkeleton = ({ children }: { children: ReactNode }) => {
	const buttonCount = Children.count(children);
	return (
		<ButtonGroupCompiled>
			<MarginCompiled buttonCount={buttonCount} />
			{times(buttonCount - 1).map((n) => (
				<LineCompiled key={n} position={n} />
			))}
		</ButtonGroupCompiled>
	);
};

const buttonWidth = 13 * gridSize;
const buttonHeight = token('space.400');

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Button = styled2(SkeletonCompiled)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${buttonWidth}px`,
	height: buttonHeight,
	marginTop: token('space.100'),
	marginRight: 0,
	marginBottom: token('space.100'),
	marginLeft: 0,
});

export default Button;

/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ButtonGroupOld = styled(Skeleton)({
	position: 'relative',
	overflow: 'hidden',

	height: buttonHeight,
	flexGrow: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ButtonGroupCompiled = styled2(SkeletonCompiled)({
	position: 'relative',
	overflow: 'hidden',

	height: buttonHeight,
	flexGrow: 1,
});

export const ButtonGroup = componentWithCondition(
	() => fg('enghealth-4756-migrate-complex-styled-component'),
	ButtonGroupCompiled,
	ButtonGroupOld,
);

/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line  @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LineOld = styled.div<{ position: number }>`
	width: 4px;
	height: 100%;
	position: absolute;
	top: 0;
	right: ${
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(props: any) => (props.position + 1) * buttonWidth
	}px;
`;
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LineCompiled = styled2.div<{ position: number }>({
	width: '4px',
	height: '100%',
	position: 'absolute',
	top: 0,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	right: `${(props: any) => (props.position + 1) * buttonWidth}px`,
});

export const Line = componentWithCondition(
	() => fg('enghealth-4756-migrate-complex-styled-component'),
	LineCompiled,
	LineOld,
);

/**
 * @deprecated styled version is deprecated, please use the compiled version
 */
// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line  @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const MarginOld = styled.div<{ buttonCount: number }>`
	position: absolute;
	width: calc(
		100% -
			${
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(props: any) => props.buttonCount * buttonWidth
			}px
	);
	height: 100%;
	top: 0;
	right: ${
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(props: any) => props.buttonCount * buttonWidth
	}px;
`;
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const MarginCompiled = styled2.div<{ buttonCount: number }>({
	position: 'absolute',
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: `calc(100% - ${(props: any) => props.buttonCount * buttonWidth}px)`,
	height: '100%',
	top: 0,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	right: `${(props: any) => props.buttonCount * buttonWidth}px`,
});

export const Margin = componentWithCondition(
	() => fg('enghealth-4756-migrate-complex-styled-component'),
	MarginCompiled,
	MarginOld,
);
