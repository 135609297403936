import React, { type SyntheticEvent, useMemo, useState, useCallback } from 'react';
import Button from '@atlaskit/button/new';
import { JiraSiteAri } from '@atlassian/ari/jira/site';
import type { InvocationResponse, ManualRule } from '@atlassian/automation-manual-triggers';
import { invokeManuallyTriggeredRule } from '@atlassian/automation-manual-triggers/services/manual-rules';
import { useFlagsService } from '@atlassian/jira-flags';
import { defineMessages, useIntl } from '@atlassian/jira-intl';
import { useIssueAri } from '@atlassian/jira-issue-hooks/src/services/use-issue-ari/index.tsx';
import { ModalEntryPointButtonTrigger } from '@atlassian/jira-modal-entry-point-button-trigger/src/ModalEntryPointButtonTrigger.tsx';
import type {
	AutomationSuggestedAction,
	SuggestedRule,
} from '@atlassian/jira-servicedesk-ai-context-panel-resources/src/services/ops/common/types.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useEnvironment } from '@atlassian/jira-tenant-context-controller/src/components/environment/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { ActionButtonProps } from '../../types.tsx';
import { automationManualTriggerEntryPoint } from './user-input/entrypoint.tsx';
import type { UserInputModalProps } from './user-input/types.tsx';

export const messages = defineMessages({
	runAutomation: {
		id: 'jira-servicedesk-ai-context-ops-panel.run-automation.button',
		defaultMessage: 'Run automation rule',
		description: 'Create Problem button',
	},
	successFlag: {
		id: 'jira-servicedesk-ai-context-ops-panel.run-automation.success-flag',
		defaultMessage: 'Your automation is in progress',
		description: 'Text for a success flag when an automation rule has been triggered',
	},
	failureFlagTitle: {
		id: 'jira-servicedesk-ai-context-ops-panel.run-automation.failure-flag-title',
		defaultMessage: 'Oops we ran into a problem',
		description: 'Title for an error flag when an automation rule failed to be triggered',
	},
	failureFlagDescription: {
		id: 'jira-servicedesk-ai-context-ops-panel.run-automation.failure-flag-description',
		defaultMessage: "There was a problem and we couldn't run the automation",
		description: 'Description for an error flag when an automation rule failed to be triggered',
	},
});

const INVOCATIONS = 'invocations';
const SUCCESS = 'SUCCESS';

const getFailedObjects = (
	response: InvocationResponse,
	isInvocationsToBeExcluded: boolean,
): string[] =>
	isInvocationsToBeExcluded
		? Object.keys(response).filter(
				(object) => object !== INVOCATIONS && response[object] !== SUCCESS,
			)
		: Object.keys(response).filter((object) => response[object] !== SUCCESS);

type Props = ActionButtonProps<AutomationSuggestedAction['context']>;

export const transformToManualRule = (rule: SuggestedRule): ManualRule | null => {
	const { id, name, ruleScope, userInputPrompts } = rule;
	if (!Number.isNaN(Number.parseInt(id, 10))) {
		return {
			id: Number.parseInt(id, 10),
			name,
			userInputPrompts,
			ruleScope: ruleScope[0],
		};
	}
	return null;
};

export const RunAutomation = ({ context, onSuccess, onFailure, onClick }: Props) => {
	const [isLoading, setIsLoading] = useState(false);
	const { formatMessage } = useIntl();
	const env = useEnvironment();
	const cloudId = useCloudId();
	const issueAri = useIssueAri();
	const { showFlag } = useFlagsService();

	const siteAri = JiraSiteAri.create({ siteId: cloudId }).toString();
	const objects = [issueAri];

	const rule = context.suggestion?.[0] ? transformToManualRule(context.suggestion[0]) : null;

	const onRuleInvocationSuccess = useCallback(() => {
		showFlag({
			type: 'info',
			title: formatMessage(messages.successFlag),
			isAutoDismiss: true,
			messageId: 'servicedesk-ai-context-ops-panel.common.ui.rca-automation.show-flag.info',
			messageType: 'transactional',
		});
		onSuccess?.();
	}, [formatMessage, onSuccess, showFlag]);

	const onRuleInvocationFailure = useCallback(
		(failedObjects: string[]) => {
			showFlag({
				type: 'error',
				title: formatMessage(messages.failureFlagTitle),
				description: formatMessage(messages.failureFlagDescription),
				isAutoDismiss: true,
				messageId: 'servicedesk-ai-context-ops-panel.common.ui.rca-automation.show-flag.error',
				messageType: 'transactional',
			});
			onFailure?.(new Error(`Rule Failed ${failedObjects.join()}`));
		},
		[formatMessage, onFailure, showFlag],
	);

	const onResponseInvocation = useCallback(
		(response: InvocationResponse) => {
			const failedObjects: string[] = getFailedObjects(
				response,
				fg('phantom-2265_include_invocations_in_manual_rule'),
			);

			if (failedObjects.length === 0) {
				onRuleInvocationSuccess();
			} else {
				onRuleInvocationFailure(failedObjects);
			}
		},
		[onRuleInvocationFailure, onRuleInvocationSuccess],
	);

	if (!rule) {
		return null;
	}

	const onRunAutomationClick = async (_: SyntheticEvent<HTMLElement>) => {
		onClick?.();
		setIsLoading(true);
		try {
			const response = await invokeManuallyTriggeredRule(env, siteAri, rule.id, objects);
			onResponseInvocation(response);
			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
			onRuleInvocationFailure([]);
		}
	};

	const ruleHasInputs: boolean = rule.userInputPrompts?.length > 0;

	if (!ruleHasInputs) {
		return (
			<Button onClick={onRunAutomationClick} isLoading={isLoading}>
				{formatMessage(messages.runAutomation)}
			</Button>
		);
	}
	return (
		<UserInput
			selectedRule={{ rule, objects }}
			onResponseInvocation={onResponseInvocation}
			onRuleInvocationFailure={onRuleInvocationFailure}
			env={env}
			siteAri={siteAri}
			onClick={onClick}
		/>
	);
};

const UserInput = ({
	selectedRule,
	onResponseInvocation,
	onRuleInvocationFailure,
	env,
	siteAri,
	onClick: onClickProp,
}: UserInputModalProps) => {
	const { formatMessage } = useIntl();
	const entryPointParams = useMemo(
		() => ({
			selectedRule,
			onResponseInvocation,
			onRuleInvocationFailure,
			env,
			siteAri,
		}),
		[env, onResponseInvocation, onRuleInvocationFailure, selectedRule, siteAri],
	);

	const onClick = () => {
		onClickProp?.();
	};

	return (
		<ModalEntryPointButtonTrigger
			entryPoint={automationManualTriggerEntryPoint}
			entryPointParams={entryPointParams}
			interactionName="open-automation-manual-trigger"
			useInternalModal={false}
			onClick={onClick}
		>
			{formatMessage(messages.runAutomation)}
		</ModalEntryPointButtonTrigger>
	);
};
