import { useEffect, useState, useMemo } from 'react';
import { fetchQuery, graphql } from 'react-relay';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type {
	dataForRecommendationsInEditorQuery,
	dataForRecommendationsInEditorQuery$data,
} from '@atlassian/jira-relay/src/__generated__/dataForRecommendationsInEditorQuery.graphql';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';

export type MentionUser = {
	name: string;
	userId: string;
	avatarUrl?: string;
};

export const LOG_LOCATION =
	'editor-wrapper.mention-recommendations.data-for-recommendations-in-editor';

const mentionsDataQuery = graphql`
	query dataForRecommendationsInEditorQuery(
		$cloudId: ID!
		$query: String!
		$issueKey: String!
		$sessionId: String!
		$maxResults: Int!
		$organizationId: String!
	) {
		jira {
			searchUserTeamMention(
				cloudId: $cloudId
				query: $query
				issueKey: $issueKey
				sessionId: $sessionId
				maxResults: $maxResults
				organizationId: $organizationId
			) {
				edges @required(action: THROW) {
					node @required(action: THROW) {
						user {
							accountId
							name
							picture
							__typename
						}
					}
				}
			}
		}
	}
`;

const transformMentionableResponse = (
	response: dataForRecommendationsInEditorQuery$data,
	loggedInUserAccountId: string | null,
): MentionUser[] => {
	const mentions = response.jira?.searchUserTeamMention?.edges.reduce<MentionUser[]>(
		(acc, edge) => {
			const { user } = edge?.node || {};
			if (
				user &&
				user.__typename === 'AtlassianAccountUser' &&
				user.accountId !== loggedInUserAccountId
			) {
				acc.push({
					name: user.name,
					userId: user.accountId,
					avatarUrl: user.picture || '',
				});
			}

			return acc;
		},
		[],
	);

	return mentions || [];
};

export const useDataForRecommendationsInEditor = (): MentionUser[] => {
	const [mentionsUserData, setMentionsUserData] = useState<MentionUser[]>([]);
	const issueKey = useIssueKey();
	const cloudId = useCloudId();
	const loggedInUserAccountId = useAccountId();

	const variables = useMemo(
		() => ({
			query: '',
			issueKey,
			organizationId: '',
			maxResults: 8,
			sessionId: '',
			cloudId,
			siteId: cloudId,
		}),
		[issueKey, cloudId],
	);

	const environment = useMemo(() => getRelayEnvironment(), []);

	useEffect(() => {
		const fetchMentionsUserData = async () => {
			try {
				const response = await fetchQuery<dataForRecommendationsInEditorQuery>(
					environment,
					mentionsDataQuery,
					variables,
				).toPromise();
				const transformedMentionableResponse = response
					? transformMentionableResponse(response, loggedInUserAccountId)
					: [];
				setMentionsUserData(transformedMentionableResponse);
			} catch (error) {
				setMentionsUserData([]);
				log.safeErrorWithoutCustomerData(
					LOG_LOCATION,
					`Error occurred while fetching mentions user data: ${error}`,
				);
			}
		};
		fetchMentionsUserData();
	}, [environment, variables, loggedInUserAccountId]);

	return mentionsUserData;
};
