export const template = {
	type: 'doc',
	content: [
		{
			type: 'heading',
			attrs: { level: 2 },
			content: [
				{ type: 'emoji', attrs: { id: '1f525', text: '🔥', shortName: ':fire:' } },
				{ text: ' Overview', type: 'text' },
			],
		},
		{
			type: 'table',
			attrs: { layout: 'default' },
			content: [
				{
					type: 'tableRow',
					content: [
						{
							type: 'tableHeader',
							attrs: { colspan: 1, background: '#fffae6', rowspan: 1, colwidth: [256.0] },
							content: [
								{
									type: 'paragraph',
									content: [{ text: 'Name', type: 'text', marks: [{ type: 'strong' }] }],
								},
							],
						},
						{
							type: 'tableHeader',
							attrs: { colspan: 1, background: '#ffffff', rowspan: 1, colwidth: [503.0] },
							content: [
								{
									type: 'paragraph',
									content: [
										{
											type: 'placeholder',
											attrs: { text: "Type /link to add the known error's ticket" },
										},
									],
								},
							],
						},
					],
				},
				{
					type: 'tableRow',
					content: [
						{
							type: 'tableHeader',
							attrs: { colspan: 1, background: '#fffae6', rowspan: 1, colwidth: [256.0] },
							content: [
								{
									type: 'paragraph',
									content: [{ text: 'Status', type: 'text', marks: [{ type: 'strong' }] }],
								},
							],
						},
						{
							type: 'tableHeader',
							attrs: { colspan: 1, background: '#ffffff', rowspan: 1, colwidth: [503.0] },
							content: [
								{
									type: 'paragraph',
									content: [
										{
											type: 'status',
											attrs: {
												color: 'green',
												style: 'bold',
												text: 'ACTIVE',
												localId: '2a629ca6-2d78-466f-97b8-26e18b652877',
											},
										},
										{
											text: ' / ',
											type: 'text',
											marks: [{ type: 'textColor', attrs: { color: '#97a0af' } }],
										},
										{
											type: 'status',
											attrs: {
												color: 'yellow',
												style: 'bold',
												text: 'DRAFT',
												localId: '1c924559-a9f5-49a7-9cf0-079ad5abea69',
											},
										},
										{
											text: ' / ',
											type: 'text',
											marks: [{ type: 'textColor', attrs: { color: '#97a0af' } }],
										},
										{
											type: 'status',
											attrs: {
												color: 'neutral',
												style: 'bold',
												text: 'ARCHIVED',
												localId: '98582d2c-53f4-42af-8104-ecd806fb1ff1',
											},
										},
									],
								},
							],
						},
					],
				},
				{
					type: 'tableRow',
					content: [
						{
							type: 'tableHeader',
							attrs: { colspan: 1, background: '#fffae6', rowspan: 1, colwidth: [256.0] },
							content: [
								{
									type: 'paragraph',
									content: [{ text: 'Version', type: 'text', marks: [{ type: 'strong' }] }],
								},
							],
						},
						{
							type: 'tableCell',
							attrs: { colspan: 1, rowspan: 1, colwidth: [503.0] },
							content: [
								{
									type: 'paragraph',
									content: [{ type: 'placeholder', attrs: { text: 'e.g., 2.3' } }],
								},
							],
						},
					],
				},
				{
					type: 'tableRow',
					content: [
						{
							type: 'tableHeader',
							attrs: { colspan: 1, background: '#fffae6', rowspan: 1, colwidth: [256.0] },
							content: [
								{
									type: 'paragraph',
									content: [{ text: 'Owner', type: 'text', marks: [{ type: 'strong' }] }],
								},
							],
						},
						{
							type: 'tableCell',
							attrs: { colspan: 1, rowspan: 1, colwidth: [503.0] },
							content: [
								{
									type: 'paragraph',
									content: [{ type: 'placeholder', attrs: { text: '@ mention the owner' } }],
								},
							],
						},
					],
				},
				{
					type: 'tableRow',
					content: [
						{
							type: 'tableHeader',
							attrs: { colspan: 1, background: '#fffae6', rowspan: 1, colwidth: [256.0] },
							content: [
								{
									type: 'paragraph',
									content: [{ text: 'Related errors', type: 'text', marks: [{ type: 'strong' }] }],
								},
							],
						},
						{
							type: 'tableCell',
							attrs: { colspan: 1, rowspan: 1, colwidth: [503.0] },
							content: [
								{
									type: 'paragraph',
									content: [
										{
											type: 'placeholder',
											attrs: { text: 'Type /link to add tickets related to the known error' },
										},
									],
								},
							],
						},
					],
				},
				{
					type: 'tableRow',
					content: [
						{
							type: 'tableHeader',
							attrs: { colspan: 1, background: '#fffae6', rowspan: 1, colwidth: [256.0] },
							content: [
								{
									type: 'paragraph',
									content: [{ text: 'Summary', type: 'text', marks: [{ type: 'strong' }] }],
								},
							],
						},
						{
							type: 'tableCell',
							attrs: { colspan: 1, rowspan: 1, colwidth: [503.0] },
							content: [
								{
									type: 'paragraph',
									content: [{ type: 'placeholder', attrs: { text: 'Summary' } }],
								},
							],
						},
					],
				},
			],
		},
		{ type: 'paragraph' },
		{
			type: 'heading',
			attrs: { level: 2 },
			content: [
				{ type: 'emoji', attrs: { id: '1f9ef', text: '🧯', shortName: ':fire_extinguisher:' } },
				{ text: ' Error details', type: 'text' },
			],
		},
		{
			type: 'table',
			attrs: { layout: 'default' },
			content: [
				{
					type: 'tableRow',
					content: [
						{
							type: 'tableHeader',
							attrs: { colspan: 1, background: '#ffebe6', rowspan: 1, colwidth: [259.0] },
							content: [
								{
									type: 'paragraph',
									content: [{ text: 'Description', type: 'text', marks: [{ type: 'strong' }] }],
								},
							],
						},
						{
							type: 'tableHeader',
							attrs: { colspan: 1, background: '#ffffff', rowspan: 1, colwidth: [500.0] },
							content: [
								{
									type: 'paragraph',
									content: [
										{
											type: 'placeholder',
											attrs: {
												text: "Provide a detailed explanation of the known error's causes and how it was discovered",
											},
										},
									],
								},
							],
						},
					],
				},
				{
					type: 'tableRow',
					content: [
						{
							type: 'tableHeader',
							attrs: { colspan: 1, background: '#ffebe6', rowspan: 1, colwidth: [259.0] },
							content: [
								{
									type: 'paragraph',
									content: [
										{
											text: 'Impacted services services',
											type: 'text',
											marks: [{ type: 'strong' }],
										},
									],
								},
							],
						},
						{
							type: 'tableCell',
							attrs: { colspan: 1, rowspan: 1, colwidth: [500.0] },
							content: [
								{
									type: 'paragraph',
									content: [
										{
											type: 'placeholder',
											attrs: { text: 'List the services impacted by the known error' },
										},
									],
								},
							],
						},
					],
				},
				{
					type: 'tableRow',
					content: [
						{
							type: 'tableHeader',
							attrs: { colspan: 1, background: '#ffebe6', rowspan: 1, colwidth: [259.0] },
							content: [
								{
									type: 'paragraph',
									content: [
										{
											text: 'Primary configuration item',
											type: 'text',
											marks: [{ type: 'strong' }],
										},
									],
								},
							],
						},
						{
							type: 'tableCell',
							attrs: { colspan: 1, rowspan: 1, colwidth: [500.0] },
							content: [
								{
									type: 'paragraph',
									content: [
										{
											type: 'placeholder',
											attrs: {
												text: 'Describe the main configuration item involved in the known error',
											},
										},
									],
								},
							],
						},
					],
				},
				{
					type: 'tableRow',
					content: [
						{
							type: 'tableHeader',
							attrs: { colspan: 1, background: '#ffebe6', rowspan: 1, colwidth: [259.0] },
							content: [
								{
									type: 'paragraph',
									content: [
										{ text: 'Steps to reproduce', type: 'text', marks: [{ type: 'strong' }] },
									],
								},
							],
						},
						{
							type: 'tableCell',
							attrs: { colspan: 1, rowspan: 1, colwidth: [500.0] },
							content: [
								{
									type: 'paragraph',
									content: [
										{
											type: 'placeholder',
											attrs: {
												text: 'Summarize the steps you need to take to recreate the known error',
											},
										},
									],
								},
							],
						},
					],
				},
				{
					type: 'tableRow',
					content: [
						{
							type: 'tableHeader',
							attrs: { colspan: 1, background: '#ffebe6', rowspan: 1, colwidth: [259.0] },
							content: [
								{
									type: 'paragraph',
									content: [{ text: 'Workaround', type: 'text', marks: [{ type: 'strong' }] }],
								},
							],
						},
						{
							type: 'tableCell',
							attrs: { colspan: 1, rowspan: 1, colwidth: [500.0] },
							content: [
								{
									type: 'paragraph',
									content: [
										{
											type: 'placeholder',
											attrs: {
												text: 'Describe how your team was able to continue working while the known error was still unresolved',
											},
										},
									],
								},
							],
						},
					],
				},
				{
					type: 'tableRow',
					content: [
						{
							type: 'tableHeader',
							attrs: { colspan: 1, background: '#ffebe6', rowspan: 1, colwidth: [259.0] },
							content: [
								{
									type: 'paragraph',
									content: [{ text: 'Solution', type: 'text', marks: [{ type: 'strong' }] }],
								},
							],
						},
						{
							type: 'tableCell',
							attrs: { colspan: 1, rowspan: 1, colwidth: [500.0] },
							content: [
								{
									type: 'paragraph',
									content: [
										{
											type: 'placeholder',
											attrs: { text: 'Describe how the known error can be resolved' },
										},
									],
								},
							],
						},
					],
				},
			],
		},
		{ type: 'paragraph', content: [{ text: ' ', type: 'text' }] },
	],
	version: 1,
};
