import React, { useCallback, type ComponentPropsWithoutRef, type ReactNode } from 'react';
import { graphql, useFragment } from 'react-relay';
import EditIcon from '@atlaskit/icon/core/edit';
import Link from '@atlaskit/link';
import { Box, xcss } from '@atlaskit/primitives';
import Pressable from '@atlaskit/primitives/pressable';
import Tooltip from '@atlaskit/tooltip';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { FieldIconPopup } from '@atlassian/jira-issue-field-icon-popup/src/FieldIconPopup.tsx';
import type { FieldEditIcon$key } from '@atlassian/jira-relay/src/__generated__/FieldEditIcon.graphql';
import { useEditFieldConfigContext } from '@atlassian/jira-issue-field-edit-field-config-context/src/context.tsx';
import { useCustomFieldsInlineEditExperiment } from './controllers/useCustomFieldsInlineEditExperiment.tsx';
import { fieldEditButtonSelectorName } from './constants.tsx';
import messages from './messages.tsx';

export const FieldEditIcon = ({
	fragmentKey,
	fieldId,
}: {
	fragmentKey: FieldEditIcon$key;
	fieldId: string;
}) => {
	const { formatMessage } = useIntl();
	const isCustomFieldConfigurationEnabled = useCustomFieldsInlineEditExperiment();
	const { onEditFieldConfigClick, hasPermissions } = useEditFieldConfigContext();

	// eslint-disable-next-line @atlassian/relay/query-restriction
	const data = useFragment<FieldEditIcon$key>(
		graphql`
			fragment FieldEditIcon on JiraIssueField
			@argumentDefinitions(
				isIssueViewFieldConfigEditEnabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-custom-field-config.relayprovider"
				}
			) {
				fieldOperations @include(if: $isIssueViewFieldConfigEditEnabled) {
					canEdit
				}
			}
		`,
		fragmentKey,
	);

	const isEditableField = data?.fieldOperations?.canEdit ?? false;

	const onClick = useCallback(() => {
		if (!hasPermissions) {
			return;
		}

		onEditFieldConfigClick(fieldId);
	}, [onEditFieldConfigClick, hasPermissions, fieldId]);

	if (!isCustomFieldConfigurationEnabled || !isEditableField) {
		return null;
	}

	const popupContent = (
		<FormattedMessage
			{...messages.contactAdminToManageFields}
			values={{
				link: (chunks) => (
					<Link href="/secure/ContactAdministrators!default.jspa" target="_blank">
						{chunks}
					</Link>
				),
			}}
		/>
	);

	if (!hasPermissions) {
		return (
			<FieldIconPopup customContent={popupContent} label={formatMessage(messages.label)}>
				<FieldEditButton>
					<Box xcss={pressableStyles} aria-label={formatMessage(messages.tooltip)}>
						<EditIcon
							color="currentColor"
							label={formatMessage(messages.label)}
							LEGACY_size="small"
						/>
					</Box>
				</FieldEditButton>
			</FieldIconPopup>
		);
	}

	return (
		<FieldEditButton>
			<Tooltip content={formatMessage(messages.tooltip)}>
				<Pressable
					onClick={onClick}
					xcss={pressableStyles}
					aria-label={formatMessage(messages.tooltip)}
				>
					<EditIcon
						color="currentColor"
						label={formatMessage(messages.label)}
						LEGACY_size="small"
					/>
				</Pressable>
			</Tooltip>
		</FieldEditButton>
	);
};

const FieldEditButton = (props: ComponentPropsWithoutRef<typeof FieldEditButtonComponent>) => (
	<FieldEditButtonComponent data-component-selector={fieldEditButtonSelectorName} {...props} />
);

const FieldEditButtonComponent = ({ children, ...props }: { children: ReactNode }) => (
	// eslint-disable-next-line react/jsx-props-no-spreading
	<Box xcss={fieldEditButtonComponentStyles} {...props}>
		{children}
	</Box>
);

const pressableStyles = xcss({
	width: '16px',
	height: '16px',
	borderRadius: '4px',
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: 'color.background.neutral.subtle',
	color: 'color.text.subtle',
});

const fieldEditButtonComponentStyles = xcss({
	paddingTop: 'space.025',
	paddingRight: 'space.025',
});
