import React from 'react';
import { styled as styled2 } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import {
	useIsIssueOfIncidentsPractice,
	useIsIssueOfPostIncidentReviewPractice,
} from '@atlassian/jira-issue-field-servicedesk-practices/src/services/use-practices-field-value/index.tsx';
import { ALL_ACTIVITY } from '@atlassian/jira-issue-view-common-constants/src/activity-items.tsx';
import { jsmActivityFeedFilterExperience } from '../common/ui/experience/index.tsx';
import { ActivityFeedFilterButton } from './activity-feed-filter-button/index.tsx';

export type ActivityFeedFilterProps = {
	hasApprovals: boolean;
	selectedActivityFeed: string;
	isIncidentTimelineModalOpen?: boolean;
};

// This wrapper separate to the button incase it is needed elsewhere and needs to be shown with different styles.
const ActivityFeedFilter = ({
	hasApprovals,
	selectedActivityFeed,
	isIncidentTimelineModalOpen = false,
}: ActivityFeedFilterProps) => {
	jsmActivityFeedFilterExperience.success();

	const isIssueOfIncidentsPractice = useIsIssueOfIncidentsPractice();
	const isIssueOfPostIncidentReviewPractice = useIsIssueOfPostIncidentReviewPractice();
	const showPirActivityFilter = isIssueOfPostIncidentReviewPractice && isIncidentTimelineModalOpen;

	if (showPirActivityFilter) {
		return (
			<ActivityFeedFilterWrapper>
				<ActivityFeedFilterButton
					hasApprovals={hasApprovals}
					isIssueOfIncidentsPractice={isIssueOfIncidentsPractice}
					isIncidentTimelineModalOpen={isIncidentTimelineModalOpen}
				/>
			</ActivityFeedFilterWrapper>
		);
	}

	if (selectedActivityFeed !== ALL_ACTIVITY || !isIssueOfIncidentsPractice) {
		return null;
	}

	return (
		<ActivityFeedFilterWrapper testId="jsm-issue-activity-filter.ui.activity-feed-filter-wrapper">
			<ActivityFeedFilterButton
				hasApprovals={hasApprovals}
				isIssueOfIncidentsPractice={isIssueOfIncidentsPractice}
			/>
		</ActivityFeedFilterWrapper>
	);
};

export { ActivityFeedFilter };

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ActivityFeedFilterWrapper = styled2.div<{ testId?: string }>({
	marginTop: token('space.200'),
});
