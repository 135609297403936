/**
 * @jsxRuntime classic
 * @jsx jsx
 * @jsxFrag jsx
 */
import { Fragment, useMemo, useState } from 'react';
import AkAvatarGroup, { type AvatarProps } from '@atlaskit/avatar-group';
import { cssMap, jsx } from '@atlaskit/css';
import { Box, Inline } from '@atlaskit/primitives/compiled';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { FormattedMessage } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';
import type { LazyLoadAvatarsProps } from './types.tsx';
import { getPlaceholderAvatars } from './utils.tsx';

const MINIMUM_NUMBER_OF_AVATAR = 3;
const AVATAR_MAX_COUNT = 2;

const styles = cssMap({
	heading: {
		paddingInline: token('space.200'),
		// not using @atlaskit/heading as it does not support subtle text color
		color: token('color.text.subtle'),
		font: token('font.heading.xxsmall'),
	},
	spinner: {
		marginBlock: token('space.100'),
	},
});

export const AvatarGroup = ({ getData, numberOfCollaborators }: LazyLoadAvatarsProps) => {
	const [users, setUsers] = useState<AvatarProps[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isComplete, setIsComplete] = useState(false);

	const fetchAndSetUsers = async () => {
		if (isLoading || isComplete) {
			return;
		}

		setIsLoading(true);

		try {
			const userData = await getData();
			setUsers(
				userData.map(({ id, name, avatar }) => ({
					key: id,
					name,
					src: avatar,
				})),
			);
			setIsLoading(false);
			setIsComplete(true);
		} catch {
			setIsLoading(false);
		}
	};

	const numberOfPlaceholders = Math.max(MINIMUM_NUMBER_OF_AVATAR - numberOfCollaborators, 1);

	const data = useMemo(() => {
		const placeholderAvatar = getPlaceholderAvatars(numberOfPlaceholders);
		if (!isComplete) {
			return [...placeholderAvatar, ...getPlaceholderAvatars(numberOfCollaborators)];
		}
		return [...placeholderAvatar, ...users];
	}, [isComplete, numberOfCollaborators, numberOfPlaceholders, users]);

	return (
		<AkAvatarGroup
			data={data}
			size="small"
			maxCount={AVATAR_MAX_COUNT}
			showMoreButtonProps={{ onMouseEnter: fetchAndSetUsers }}
			overrides={{
				AvatarGroupItem: {
					render: (Component, props, index) => {
						if (isLoading) {
							if (index === AVATAR_MAX_COUNT - 1) {
								return (
									<Inline xcss={styles.spinner} grow="fill" alignInline="center">
										<Spinner key={index} />
									</Inline>
								);
							}
							return null;
						}

						if (numberOfPlaceholders > 0 && index < numberOfPlaceholders) {
							return null;
						}

						// If Avatar is first in drop down, show title
						return index === numberOfPlaceholders ? (
							<Fragment key={`${index}-overridden`}>
								<Box as="span" xcss={styles.heading}>
									<FormattedMessage {...messages.avatarTooltipDescription} />
								</Box>
								<Component {...props} key={index} />
							</Fragment>
						) : (
							<Component {...props} key={index} />
						);
					},
				},
				MoreIndicator: {
					render: (Component, props) => <Component {...props} count={numberOfCollaborators} />,
				},
			}}
		/>
	);
};
