import React, { memo, useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import type { MainIssueAggQueryRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import type { ui_ViewAutomationDiscoveryPanel_features$key } from '@atlassian/jira-relay/src/__generated__/ui_ViewAutomationDiscoveryPanel_features.graphql';
import type { ui_ViewAutomationDiscoveryPanel_userPreferences$key } from '@atlassian/jira-relay/src/__generated__/ui_ViewAutomationDiscoveryPanel_userPreferences.graphql';
import { AutomationDiscoveryContainer } from './automation-discover-container/index.tsx';

export const ViewAutomationDiscoveryPanel = ({
	rootRelayFragment,
}: {
	rootRelayFragment: MainIssueAggQueryRelayFragment;
}) => {
	const featuresData = useFragment<ui_ViewAutomationDiscoveryPanel_features$key>(
		graphql`
			fragment ui_ViewAutomationDiscoveryPanel_features on JiraProject {
				isAutomationDiscoverabilityEnabled
			}
		`,
		rootRelayFragment?.jira?.issueByKey?.projectField?.project || null,
	);

	const userPreferenceData = useFragment<ui_ViewAutomationDiscoveryPanel_userPreferences$key>(
		graphql`
			fragment ui_ViewAutomationDiscoveryPanel_userPreferences on JiraUserPreferences
			@argumentDefinitions(first: { type: "Int", defaultValue: 20 }) {
				dismissedAutomationDiscoverabilityTemplates(first: $first)
					@connection(
						key: "ui_ViewAutomationDiscoveryPanel_userPreferences_dismissedAutomationDiscoverabilityTemplates"
					) {
					__id
					edges {
						node {
							templateId
							dismissUntilDateTime
						}
					}
				}
			}
		`,
		rootRelayFragment?.jira?.userPreferences || null,
	);

	const connectionId = userPreferenceData?.dismissedAutomationDiscoverabilityTemplates?.__id;
	const dismissedTemplates = useMemo(
		() =>
			(userPreferenceData?.dismissedAutomationDiscoverabilityTemplates?.edges ?? [])
				.map((edge) => {
					const node = edge?.node;
					if (node && node.templateId != null && node.dismissUntilDateTime != null) {
						return {
							templateId: node.templateId,
							dismissUntilDateTime: node.dismissUntilDateTime,
						};
					}
					return null;
				})
				.filter(Boolean),
		[userPreferenceData?.dismissedAutomationDiscoverabilityTemplates?.edges],
	);

	if (featuresData?.isAutomationDiscoverabilityEnabled !== true || connectionId == null) {
		return null;
	}
	return (
		<AutomationDiscoveryContainer dismissData={dismissedTemplates} connectionId={connectionId} />
	);
};
export default memo(ViewAutomationDiscoveryPanel);
