import React, {
	createContext,
	type PropsWithChildren,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
	type FocusEventHandler,
} from 'react';
import noop from 'lodash/noop';

const CopyPasteContext = createContext(noop);

export type ClipboardEventHandlers = {
	/**
	 * Callback function invoked when a copy operation is performed. This should return `true` if the `ClipboardEvent`
	 * is explicitly handled, otherwise `false`.
	 */
	onCopy?: (e: ClipboardEvent) => boolean;
	/**
	 * Callback function invoked when a cut operation is performed. This should return `true` if the `ClipboardEvent`
	 * is explicitly handled, otherwise `false`.
	 */
	onCut?: (e: ClipboardEvent) => boolean;
	/**
	 * Callback function invoked when a copy operation is performed. This should return `true` if the `ClipboardEvent`
	 * is explicitly handled, otherwise `false`.
	 */
	onPaste?: (e: ClipboardEvent) => boolean;
};

export type CopyPasteTargetHandlers = Required<ClipboardEventHandlers> & {
	/**
	 * Set whether the target was the most recently copied entity. This is set to `false` when a new entity is copied or
	 * a successful paste operation is performed.
	 */
	onLastCopiedTarget: (isCopied: boolean) => void;
};

/**
 * Add global document listeners to copy and paste events which will invoke a delegated callback function as registered
 * by `useCopyPasteTarget`.
 */
export const CopyPasteContextProvider = ({ children }: PropsWithChildren<{}>) => {
	const copyPasteTarget = useRef<CopyPasteTargetHandlers | null>(null);
	const lastCopiedTarget = useRef<CopyPasteTargetHandlers | null>(null);

	const updateCopyPasteTarget = useCallback((handlers: CopyPasteTargetHandlers) => {
		copyPasteTarget.current = handlers;
	}, []);

	/** Update the last copied target if the copy event is explicitly handled. */
	const updateLastCopiedTargetWhenHandled = useCallback(
		(handled: boolean, nextTarget: CopyPasteTargetHandlers | null) => {
			if (!handled) return;

			// Clear previously copied target
			lastCopiedTarget.current?.onLastCopiedTarget(false);
			// Set next copied target
			nextTarget?.onLastCopiedTarget(true);
			lastCopiedTarget.current = nextTarget;
		},
		[],
	);
	const onCopy = useCallback(
		(event: ClipboardEvent) => {
			updateLastCopiedTargetWhenHandled(
				copyPasteTarget.current?.onCopy(event) ?? false,
				copyPasteTarget.current,
			);
		},
		[updateLastCopiedTargetWhenHandled],
	);
	const onCut = useCallback(
		(event: ClipboardEvent) => {
			updateLastCopiedTargetWhenHandled(
				copyPasteTarget.current?.onCut(event) ?? false,
				copyPasteTarget.current,
			);
		},
		[updateLastCopiedTargetWhenHandled],
	);
	const onPaste = useCallback(
		(event: ClipboardEvent) => {
			updateLastCopiedTargetWhenHandled(copyPasteTarget.current?.onPaste(event) ?? false, null);
		},
		[updateLastCopiedTargetWhenHandled],
	);

	useEffect(() => {
		globalThis.document.addEventListener('copy', onCopy);
		globalThis.document.addEventListener('cut', onCut);
		globalThis.document.addEventListener('paste', onPaste, true);
		return () => {
			globalThis.document.removeEventListener('copy', onCopy);
			globalThis.document.removeEventListener('cut', onCut);
			globalThis.document.removeEventListener('paste', onPaste, true);
		};
	}, [onCopy, onCut, onPaste]);

	return (
		<CopyPasteContext.Provider value={updateCopyPasteTarget}>{children}</CopyPasteContext.Provider>
	);
};

type ClipboardTargetEventHandlers = {
	/** `true` if the current target was the most recently copied entity. */
	lastCopiedTarget: boolean;
	/** Callback function invoked when the target element is focused. */
	onFocus: FocusEventHandler;
	/** Callback function invoked when the target element is blurred. */
	onBlur: FocusEventHandler;
};

/** Returns event handlers to enable binding/unbinding copy/paste callback functions when an element is focused/blurred. */
export const useCopyPasteTarget = ({
	onCopy,
	onCut,
	onPaste,
}: ClipboardEventHandlers): ClipboardTargetEventHandlers => {
	const updateCopyPasteTarget = useContext(CopyPasteContext);
	const [lastCopiedTarget, setLastCopiedTarget] = useState(false);

	// Track callbacks as refs so they can be invoked using a stable function reference
	const onCopyRef = useRef(onCopy);
	onCopyRef.current = onCopy;
	const onCutRef = useRef(onCut);
	onCutRef.current = onCut;
	const onPasteRef = useRef(onPaste);
	onPasteRef.current = onPaste;

	const onFocus = useCallback(() => {
		// Ensure stable function reference is provided as the callback functions are only bound to the document once
		const stableCopy = (event: ClipboardEvent) => onCopyRef.current?.(event) ?? false;
		const stableCut = (event: ClipboardEvent) => onCutRef.current?.(event) ?? false;
		const stablePaste = (event: ClipboardEvent) => onPasteRef.current?.(event) ?? false;

		/* Queue as a microtask to ensure the blur callback is emitted before calling updateCopyPasteTarget when
		 * navigating between targets. */
		queueMicrotask(() => {
			updateCopyPasteTarget({
				onCopy: stableCopy,
				onCut: stableCut,
				onPaste: stablePaste,
				onLastCopiedTarget: setLastCopiedTarget,
			});
		});
	}, [updateCopyPasteTarget]);

	const onBlur = useCallback(() => {
		updateCopyPasteTarget(null);
	}, [updateCopyPasteTarget]);

	return { lastCopiedTarget, onFocus, onBlur };
};
