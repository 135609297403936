import keys from 'lodash/keys';
import { withAnalyticsEvents, type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { RelatedIssue } from '@atlassian/jira-ai-related-issues/src/controllers/context.tsx';
import { AnalyticsSubject } from '@atlassian/jira-analytics-web-react/src/components/decorators.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-analytics-web-react/src/utils/fire-track-event.tsx';
import { safeLinkTypeName } from '@atlassian/jira-common-constants/src/issue-link-types.tsx';
import withLinkActions from '@atlassian/jira-common-navigation/src/with-link-actions/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type {
	IssueLinkType,
	IssueLinks,
} from '@atlassian/jira-issue-shared-types/src/common/types/linked-issue-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { applinkAuthenticationRequest } from '@atlassian/jira-issue-view-store/src/actions/applink-authentication-actions.tsx';
import { updateAssociatedIssueRequest } from '@atlassian/jira-issue-view-store/src/actions/associated-issue-actions.tsx';
import {
	addIssueLinkClick,
	closeAddIssueLink,
	deleteLinkedIssueRequest,
	saveLinkedIssueCancel,
	saveLinkedIssueRetry,
	fetchCreateLinkedIssuePermissionRequest,
	createLinkedIssueSuccess,
	saveLinkedIssueSuccess,
	onAILinkedIssueSuggestion,
	saveLinkedIssueRequest,
} from '@atlassian/jira-issue-view-store/src/actions/issue-links-actions.tsx';
import {
	deleteRemoteLinkedIssueRequest,
	saveRemoteLinkedIssueRetry,
	saveRemoteLinkedIssueCancel,
} from '@atlassian/jira-issue-view-store/src/actions/remote-issue-links-actions.tsx';
import {
	baseUrlSelector,
	issueKeySelector,
	issueIdSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import {
	isCompletedLoadingSelector,
	projectTypeSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import {
	shouldDisplayAddLinksSelector,
	combinedLinksSelector,
	hasCombinedIssueLinksSelector,
	shouldDisplayRelatedIssuePanelSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/issue-links-selector.tsx';
import {
	canAddIssueLinksSelector,
	issueLinkTypesSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/issue-links-type-selector.tsx';
import type { Application } from '@atlassian/jira-shared-types/src/application.tsx';
import type { ApplicationEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import { toIssueId, type IssueKey, type Href } from '@atlassian/jira-shared-types/src/general.tsx';
import { transformIssueLink } from './add/transformer.tsx';
import IssueLinksView from './view.tsx';
import type { HandleError, HandleErrorOld } from './types.tsx';

const linkedIssueCreatedAnalyticsAction = 'created';

const IssueLinksViewWithStore = connect(
	(state: State) => ({
		canAddIssueLinks: canAddIssueLinksSelector(state),
		hasLinks: hasCombinedIssueLinksSelector(state),
		issueLinks: combinedLinksSelector(state),
		// FIXME: missing transformIssueLinkTypes? this is serious!
		// FIXME: JIV-17455 should be fully typed
		// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
		issueLinkTypes: issueLinkTypesSelector(state) as any,
		baseUrl: baseUrlSelector(state),
		issueKey: issueKeySelector(state),
		externalId: 'issue.issue-links',
		displayAddIssueLinks: shouldDisplayAddLinksSelector(state),
		displayRelatedIssueSuggestion: shouldDisplayRelatedIssuePanelSelector(state),
		// FIXME: JIV-17455 should be fully typed
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		projectType: projectTypeSelector(state)!,
		isIssueViewComplete: isCompletedLoadingSelector(state),
	}),
	(dispatch) => ({
		onAuthenticateApplink: (href: Href, analyticsEvent?: UIAnalyticsEvent) =>
			dispatch(applinkAuthenticationRequest(href, analyticsEvent)),
		deleteLinkedIssue: (
			{
				linkId,
				globalId,
			}: {
				linkId: string;
				globalId?: string;
			},
			application: Application | undefined = undefined,
			edition: ApplicationEdition | undefined = undefined,
			analyticsEvent?: UIAnalyticsEvent,
		) => {
			if (globalId !== undefined) {
				dispatch(deleteRemoteLinkedIssueRequest(globalId, application, edition, analyticsEvent));
			} else {
				dispatch(deleteLinkedIssueRequest(linkId, application, edition, analyticsEvent));
			}
		},
		cancelLinkedIssue: (optimisticId: string, isRemote: boolean) => {
			if (isRemote) {
				dispatch(saveRemoteLinkedIssueCancel(optimisticId));
			} else {
				dispatch(saveLinkedIssueCancel(optimisticId));
			}
		},
		retryLinkedIssue: (
			optimisticId: string,
			isRemote: boolean,
			requestAnalyticsEvent: UIAnalyticsEvent,
			maybeAnalyticsEventOrPostAnalyticsEvent: UIAnalyticsEvent,
			onSuccess: () => void,
			onError: HandleError | HandleErrorOld,
			analyticsEvent?: UIAnalyticsEvent,
		) => {
			if (fg('operandi_issue_view_additional_logging')) {
				if (isRemote) {
					dispatch(
						saveRemoteLinkedIssueRetry(
							optimisticId,
							requestAnalyticsEvent,
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							analyticsEvent!,
							onSuccess,
							onError,
						),
					);
				} else {
					dispatch(
						saveLinkedIssueRetry(
							optimisticId,
							requestAnalyticsEvent,
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							analyticsEvent!,
							onSuccess,
							onError,
							maybeAnalyticsEventOrPostAnalyticsEvent,
						),
					);
				}
			} else {
				// eslint-disable-next-line no-lonely-if
				if (isRemote) {
					dispatch(
						saveRemoteLinkedIssueRetry(
							optimisticId,
							requestAnalyticsEvent,
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							maybeAnalyticsEventOrPostAnalyticsEvent!,
							onSuccess,
							onError,
						),
					);
				} else {
					dispatch(
						saveLinkedIssueRetry(
							optimisticId,
							requestAnalyticsEvent,
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							maybeAnalyticsEventOrPostAnalyticsEvent!,
							onSuccess,
							onError,
						),
					);
				}
			}
		},
		onIssueLinkAddClick: () => dispatch(addIssueLinkClick()),
		onIssueLinkAddClosed: () => dispatch(closeAddIssueLink()),
		onMountCreatePermissionFetching: () => dispatch(fetchCreateLinkedIssuePermissionRequest()),
		onRelatedIssuesTriggered: (isOpen: boolean) => dispatch(onAILinkedIssueSuggestion(isOpen)),
		onRelatedIssueLinkSave:
			(
				onAddSuccess: (() => void) | undefined,
				onAddError: HandleError | HandleErrorOld | undefined,
			) =>
			(
				optimisticId: string,
				linkSuggestionsRequest: RelatedIssue,
				issueLinkType: IssueLinkType,
				requestAnalyticsEvent: UIAnalyticsEvent | null,
				analyticsEvent?: UIAnalyticsEvent,
			) =>
				dispatch(
					saveLinkedIssueRequest(
						{
							optimisticId,
							linkedIssueKey: linkSuggestionsRequest.issueKey,
							issueLinkType,
							issueLinks: transformIssueLink(
								optimisticId,
								issueLinkType,
								{
									id: linkSuggestionsRequest.id,
									key: linkSuggestionsRequest.issueKey,
									summaryText: linkSuggestionsRequest.issueSummary,
									img: linkSuggestionsRequest.issueTypeIconUrl,
								},
								// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
								window.location.origin,
							),
							isOpenedFromJsmSimilarIssues: false,
						},
						requestAnalyticsEvent,
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						analyticsEvent!,
						onAddSuccess,
						onAddError,
					),
				),
	}),
)(IssueLinksView);

const IssueLinksViewWithLinkActions = withLinkActions(IssueLinksViewWithStore);

const fireLinkedIssueCreatedTrackAnalytics = (
	{ issueLinks, linkedIssues }: IssueLinks,
	analyticsEvent: UIAnalyticsEvent,
) => {
	const issueLinkId = keys(issueLinks)[0];
	// issueLinkId would be undefined when the newly created linked issue is not actually linked to the current
	// issue.
	if (issueLinkId !== undefined) {
		const issueLink = issueLinks[issueLinkId];
		const linkedIssue = linkedIssues[issueLink.linkedIssueKey];
		const analyticsAttributes = {
			action: linkedIssueCreatedAnalyticsAction,
			actionSubjectId: linkedIssue.id,
			attributes: {
				linkTypeName: safeLinkTypeName(issueLink.typeName),
				linkTypeId: issueLink.typeId,
				issueLinkId,
			},
		};
		fireTrackAnalytics(analyticsEvent, analyticsAttributes);
	}
};

// FIXME:JIV-17455 - this should be strongly typed
export default flowWithSafeComponent(
	withAnalyticsEvents({
		deleteLinkedIssue: { action: 'deleted' },
		onAuthenticateApplink: { action: 'authenticate' },
	}),
	AnalyticsSubject('linkedIssue'),
	// @ts-expect-error - Argument of type 'InferableComponentEnhancerWithProps<{ issueId: string; } & { onLinkedIssueCreated: (newIssueLinks: IssueLinks, maybeAnalyticsEventOrPostAnalyticsEvent: UIAnalyticsEvent, analyticsEvent?: UIAnalyticsEvent | undefined) => void; onEditIssue: (issueKey: string) => void; }, {}>' is not assignable to parameter of type 'FlowStep<ContextResult<ForwardRefExoticComponent<PropsWithoutRef<LibraryManagedAttributes<Component, Omit<Props, keyof WithAnalyticsEventsProps>>> & RefAttributes<...>>, PropsWithoutRef<...> & RefAttributes<...>>, ConnectedComponent<...>>'.
	connect(
		(state: State) => ({ issueId: toIssueId(String(issueIdSelector(state))) }),
		(dispatch) => ({
			onLinkedIssueCreated: (
				newIssueLinks: IssueLinks,
				maybeAnalyticsEventOrPostAnalyticsEvent: UIAnalyticsEvent,
				analyticsEvent?: UIAnalyticsEvent,
			) => {
				dispatch(createLinkedIssueSuccess(newIssueLinks));
				if (fg('operandi_issue_view_additional_logging')) {
					Object.entries(newIssueLinks.issueLinks).forEach(([optimisticId, issueLink]) => {
						dispatch(
							saveLinkedIssueSuccess(
								optimisticId,
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								analyticsEvent!,
								issueLink.linkedIssueKey,
								maybeAnalyticsEventOrPostAnalyticsEvent,
							),
						);
					});
					dispatch(closeAddIssueLink());
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					fireLinkedIssueCreatedTrackAnalytics(newIssueLinks, analyticsEvent!);
				} else {
					Object.keys(newIssueLinks.issueLinks).forEach((optimisticId) => {
						dispatch(saveLinkedIssueSuccess(optimisticId, maybeAnalyticsEventOrPostAnalyticsEvent));
					});
					dispatch(closeAddIssueLink());
					fireLinkedIssueCreatedTrackAnalytics(
						newIssueLinks,
						maybeAnalyticsEventOrPostAnalyticsEvent,
					);
				}
			},
			onEditIssue: (issueKey: IssueKey) => {
				dispatch(updateAssociatedIssueRequest(issueKey));
			},
			// get the onLinkSave here so we can pass it into SuggestRelatedIssuesModalContainer
		}),
	),
)(IssueLinksViewWithLinkActions);
