/**
 * @generated SignedSource<<829511a32650946ae766b4c02ab1fe66>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraProjectStyle = "COMPANY_MANAGED_PROJECT" | "TEAM_MANAGED_PROJECT" | "%future added value";
export type JiraTimeFormat = "DAYS" | "HOURS" | "PRETTY" | "%future added value";
export type JiraTimeUnit = "DAY" | "HOUR" | "MINUTE" | "WEEK" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutTimeTrackingUtils_IssueViewTimeTrackingController$data = {
  readonly issue: {
    readonly childIssues: {
      readonly issues?: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly timeTrackingField: {
              readonly originalEstimate: {
                readonly timeInSeconds: AGG$Long | null | undefined;
              } | null | undefined;
              readonly remainingEstimate: {
                readonly timeInSeconds: AGG$Long | null | undefined;
              } | null | undefined;
              readonly timeSpent: {
                readonly timeInSeconds: AGG$Long | null | undefined;
              } | null | undefined;
            } | null | undefined;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly search?: string | null | undefined;
    } | null | undefined;
    readonly projectField: {
      readonly project: {
        readonly projectStyle: JiraProjectStyle | null | undefined;
      } | null | undefined;
    } | null | undefined;
    readonly statusField: {
      readonly status: {
        readonly statusCategory: {
          readonly statusCategoryId: string;
        } | null | undefined;
      };
    } | null | undefined;
  };
  readonly originalEstimate: {
    readonly timeInSeconds: AGG$Long | null | undefined;
  } | null | undefined;
  readonly remainingEstimate: {
    readonly timeInSeconds: AGG$Long | null | undefined;
  } | null | undefined;
  readonly timeSpent: {
    readonly timeInSeconds: AGG$Long | null | undefined;
  } | null | undefined;
  readonly timeTrackingSettings: {
    readonly defaultFormat: JiraTimeFormat | null | undefined;
    readonly defaultUnit: JiraTimeUnit | null | undefined;
    readonly isJiraConfiguredTimeTrackingEnabled: boolean | null | undefined;
    readonly workingDaysPerWeek: number | null | undefined;
    readonly workingHoursPerDay: number | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ui_issueViewLayoutTimeTrackingUtils_IssueViewTimeTrackingController";
} | null | undefined;
export type ui_issueViewLayoutTimeTrackingUtils_IssueViewTimeTrackingController$key = {
  readonly " $data"?: ui_issueViewLayoutTimeTrackingUtils_IssueViewTimeTrackingController$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutTimeTrackingUtils_IssueViewTimeTrackingController">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "name": "timeInSeconds"
  }
],
v1 = {
  "concreteType": "JiraEstimate",
  "kind": "LinkedField",
  "name": "originalEstimate",
  "plural": false,
  "selections": (v0/*: any*/)
},
v2 = {
  "concreteType": "JiraEstimate",
  "kind": "LinkedField",
  "name": "timeSpent",
  "plural": false,
  "selections": (v0/*: any*/)
},
v3 = {
  "concreteType": "JiraEstimate",
  "kind": "LinkedField",
  "name": "remainingEstimate",
  "plural": false,
  "selections": (v0/*: any*/)
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutTimeTrackingUtils_IssueViewTimeTrackingController",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraIssue",
        "kind": "LinkedField",
        "name": "issue",
        "plural": false,
        "selections": [
          {
            "concreteType": "JiraStatusField",
            "kind": "LinkedField",
            "name": "statusField",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraStatus",
                "kind": "LinkedField",
                "name": "status",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraStatusCategory",
                    "kind": "LinkedField",
                    "name": "statusCategory",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "statusCategoryId"
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "concreteType": "JiraProjectField",
            "kind": "LinkedField",
            "name": "projectField",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraProject",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "projectStyle"
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "name": "childIssues",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "search"
                  }
                ],
                "type": "JiraChildIssuesExceedingLimit"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "concreteType": "JiraIssueConnection",
                    "kind": "LinkedField",
                    "name": "issues",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraIssueEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JiraIssue",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraTimeTrackingField",
                                "kind": "LinkedField",
                                "name": "timeTrackingField",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  (v2/*: any*/),
                                  (v3/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ],
                "type": "JiraChildIssuesWithinLimit"
              }
            ]
          }
        ]
      },
      "action": "NONE",
      "path": "issue"
    },
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "concreteType": "JiraTimeTrackingSettings",
      "kind": "LinkedField",
      "name": "timeTrackingSettings",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isJiraConfiguredTimeTrackingEnabled"
        },
        {
          "kind": "ScalarField",
          "name": "workingDaysPerWeek"
        },
        {
          "kind": "ScalarField",
          "name": "workingHoursPerDay"
        },
        {
          "kind": "ScalarField",
          "name": "defaultUnit"
        },
        {
          "kind": "ScalarField",
          "name": "defaultFormat"
        }
      ]
    }
  ],
  "type": "JiraTimeTrackingField"
};
})();

(node as any).hash = "8d1751c0ce9dbded94b180058e3962d1";

export default node;
