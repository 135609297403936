import React from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import { token } from '@atlaskit/tokens';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { Subtle } from '../../../../../common/ui/change/index.tsx';
import type { User } from '../../../../../types.tsx';
import messages from './messages.tsx';

export type Props = {
	user: User | null;
};

const Assignee = ({ user }: Props) => {
	const intl = useIntl();

	return user ? (
		<Container>
			<AvatarContainer>
				<Avatar
					size={
						expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false)
							? 'small'
							: 'medium'
					}
					src={user.avatarUrl}
				/>
			</AvatarContainer>
			<div>{user.displayName}</div>
		</Container>
	) : (
		<Subtle>{intl.formatMessage(messages.unassigned)}</Subtle>
	);
};

export default Assignee;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarContainer = styled.div({
	height: '36px', // Required because AkAvatar is styled as inline-block
	marginRight: token('space.100'),
});
