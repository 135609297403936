import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noIssuesFound: {
		id: 'polaris-lib-issue-select.no-issues-found',
		defaultMessage: 'No issues found',
		description:
			'Placeholder text in issue picker dropdown, when no linkable issues could be found.',
	},
	noIssuesFoundIssueTermRefresh: {
		id: 'polaris-lib-issue-select.no-issues-found-issue-term-refresh',
		defaultMessage: 'No work items found',
		description:
			'Placeholder text in issue picker dropdown, when no linkable issues could be found.',
	},
	noIdeasFound: {
		id: 'polaris-lib-issue-select.no-ideas-found',
		defaultMessage: 'No ideas found',
		description:
			'Placeholder text in issue picker dropdown, when no linkable ideas could be found.',
	},
	typeToSearch: {
		id: 'polaris-lib-issue-select.type-to-search',
		defaultMessage: 'Type to search for an issue',
		description:
			'Initial placeholder text in issue picker dropdown before a search request was made.',
	},
	typeToSearchIssueTermRefresh: {
		id: 'polaris-lib-issue-select.type-to-search-issue-term-refresh',
		defaultMessage: 'Type to search for a work item',
		description:
			'Initial placeholder text in issue picker dropdown before a search request was made.',
	},
	issuePickerPlaceholder: {
		id: 'polaris-lib-issue-select.issue-picker-placeholder',
		defaultMessage: 'Search for an issue',
		description: 'Placeholder text in issue picker select component.',
	},
	issuePickerPlaceholderIssueTermRefresh: {
		id: 'polaris-lib-issue-select.issue-picker-placeholder-issue-term-refresh',
		defaultMessage: 'Search for a work item',
		description: 'Placeholder text in issue picker select component.',
	},
	optionGroupSuggestions: {
		id: 'polaris-lib-issue-select.option-group-suggestions',
		defaultMessage: 'Issues in {cloudName}',
		description: 'Option group caption in issue picker dropdown.',
	},
	optionGroupSuggestionsIssueTermRefresh: {
		id: 'polaris-lib-issue-select.option-group-suggestions-issue-term-refresh',
		defaultMessage: 'Work items in {cloudName}',
		description: 'Option group caption in issue picker dropdown.',
	},
	optionIdeasGroupSuggestions: {
		id: 'polaris-lib-issue-select.option-ideas-group-suggestions',
		defaultMessage: 'Ideas in {cloudName}',
		description: 'Option group caption for ideas in issue picker dropdown.',
	},
	isLoading: {
		id: 'polaris-lib-issue-select.is-loading',
		defaultMessage: 'Loading ...',
		description: 'Placeholder text in issue picker dropdown, when loading is in progress',
	},
	issuePickerDefaultGroupLabel: {
		id: 'polaris-lib-issue-select.issue-picker-default-group-label',
		defaultMessage: 'In this project',
		description:
			'Option group label for merge ideas issue select dropdown content. This option group contains all project ideas',
	},
});
