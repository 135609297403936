/**
 * @generated SignedSource<<2be101ac744cbc438af2a8cd9fada35f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type AccountStatus = "active" | "closed" | "inactive" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type commandPaletteAssignee_issueViewLayoutAssigneeField$data = {
  readonly fieldId: string;
  readonly id: string;
  readonly issue: {
    readonly projectField: {
      readonly project: {
        readonly key: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly name: string;
  readonly user: {
    readonly accountId: string;
    readonly accountStatus: AccountStatus;
    readonly id: string;
    readonly name: string;
    readonly picture: AGG$URL;
  } | null | undefined;
  readonly " $fragmentType": "commandPaletteAssignee_issueViewLayoutAssigneeField";
};
export type commandPaletteAssignee_issueViewLayoutAssigneeField$key = {
  readonly " $data"?: commandPaletteAssignee_issueViewLayoutAssigneeField$data;
  readonly " $fragmentSpreads": FragmentRefs<"commandPaletteAssignee_issueViewLayoutAssigneeField">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
},
v1 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "commandPaletteAssignee_issueViewLayoutAssigneeField",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "accountId"
        },
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "kind": "ScalarField",
          "name": "picture"
        },
        {
          "kind": "ScalarField",
          "name": "accountStatus"
        }
      ]
    },
    {
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "issue",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraProjectField",
          "kind": "LinkedField",
          "name": "projectField",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraProject",
              "kind": "LinkedField",
              "name": "project",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "key"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraSingleSelectUserPickerField"
};
})();

(node as any).hash = "36d7a840e28f500d022667f1fdd855c2";

export default node;
