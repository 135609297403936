import React, { useState, type ReactNode, useCallback, useEffect } from 'react';
import { jsx } from '@compiled/react';
import AkSpinner from '@atlaskit/spinner';
import UFOSegment from '@atlaskit/react-ufo/segment';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import CustomizeIcon from '@atlaskit/icon/core/customize';
import { IconButton } from '@atlaskit/button/new';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	errorSelector,
	isLoadingSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { setSelectedActivityFeedLayout } from '@atlassian/jira-issue-view-store/src/actions/activity-feed-actions.tsx';
import { SIDE_PANEL_EXPERIMENT_ISSUE_ACTIVITY_LAYOUT } from '@atlassian/jira-issue-shared-types/src/common/types/user-preferences-type.tsx';
import { useUserPreferencesValue } from '@atlassian/jira-issue-user-preference-services/src/main.tsx';
import type { ActivityLayoutType } from '@atlassian/jira-issue-shared-types/src/common/types/activity-layout-type.tsx';
import { LAYOUT_VERTICAL, LAYOUT_HORIZONTAL } from './constants.tsx';
import messages from './messages.tsx';
import type { Props, StateProps, DispatchProps } from './types.tsx';
import { layoutPreferencesDialogEntryPoint } from './entrypoint.tsx';

export const LayoutSelector = ({
	isIssueLoading = false,
	hasError = false,
	setSelectedActivityLayout,
}: Props) => {
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const { entryPointReferenceSubject: layoutPreferencesEntryPointRef, entryPointActions } =
		useEntryPoint(layoutPreferencesDialogEntryPoint, {});
	const popContentTriggerRef = useEntryPointButtonTrigger(entryPointActions);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [selectedValue] = useUserPreferencesValue(SIDE_PANEL_EXPERIMENT_ISSUE_ACTIVITY_LAYOUT);

	useEffect(() => {
		const activityLayoutView: ActivityLayoutType | null =
			selectedValue === LAYOUT_VERTICAL || selectedValue === LAYOUT_HORIZONTAL
				? selectedValue
				: null;
		if (activityLayoutView) {
			setSelectedActivityLayout(activityLayoutView);
		}
	}, [selectedValue, setSelectedActivityLayout]);

	const onTriggerClick = useCallback(
		(trigger: 'LayoutPreferenceModal') => {
			const analyticsEvent = createAnalyticsEvent({
				action: 'layout preferences btn clicked',
				actionSubject: 'icon-button',
				attributes: {
					popupState: isOpen ? 'closed' : 'opened',
				},
			});
			setIsOpen(!isOpen);
			fireUIAnalytics(analyticsEvent, trigger);
		},
		[isOpen, createAnalyticsEvent],
	);

	if (hasError) {
		return null;
	}

	return (
		<Popup
			messageId="issue-view-foundation.header.activity-layout-preferences.popup"
			messageType="transactional"
			onClose={() => {
				setIsOpen(false);
			}}
			zIndex={layers.modal}
			content={() => (
				<Box testId="issue-view-foundation.header.activity-layout-preferences.content">
					{isOpen && (
						<JiraEntryPointContainer
							entryPointReferenceSubject={layoutPreferencesEntryPointRef}
							id="issue-view-foundation.header.activity-layout-preferences.entrypoint.container"
							packageName="popup-activity-layout-preferences"
							fallback={fallback}
							runtimeProps={{}}
							errorFallback="unmount"
						/>
					)}
				</Box>
			)}
			isOpen={isOpen}
			placement="bottom-end"
			trigger={(triggerProps) => {
				const { 'aria-expanded': ariaExpanded, ...restProps } = triggerProps;
				return (
					<div {...restProps}>
						<UFOSegment name="issue-view-layout-preference-button">
							<IconButton
								testId="issue-view-foundation.header.activity-layout-preferences.button"
								onClick={() => onTriggerClick('LayoutPreferenceModal')}
								isTooltipDisabled={false}
								tooltip={{ position: 'top' }}
								label={formatMessage(messages.layoutPreferencesBtn)}
								isSelected={isOpen}
								icon={CustomizeIcon}
								isDisabled={isIssueLoading}
								interactionName="issue-view-layout-selector-modal"
								ref={popContentTriggerRef}
							/>
						</UFOSegment>
					</div>
				);
			}}
		/>
	);
};

const spinnerContainer = xcss({
	height: '50px',
	width: '50px',
});

const fallback: ReactNode = (
	<Flex xcss={spinnerContainer} alignItems="center" justifyContent="center">
		<AkSpinner size="medium" />
	</Flex>
);

export const ActivityLayoutSelector = connect(
	(state: State): StateProps => ({
		hasError: !!errorSelector(state),
		isIssueLoading: isLoadingSelector(state),
	}),
	(dispatch): DispatchProps => ({
		setSelectedActivityLayout: (selectedLayout: ActivityLayoutType) => {
			dispatch(setSelectedActivityFeedLayout(selectedLayout));
		},
	}),
)(LayoutSelector);
