import React, { useCallback, useEffect, useState, useRef, forwardRef, type Ref } from 'react';
import { useFragment, graphql, type EntryPointProps } from 'react-relay';
import { useIntl } from '@atlassian/jira-intl';
import {
	parseTimeString,
	isValidTimeString,
} from '@atlassian/jira-issue-format-time/src/index.tsx';
import type { TimeTrackingConfig } from '@atlassian/jira-issue-shared-types/src/common/types/jira-settings-type.tsx';
import useDebouncedCallback from '@atlassian/jira-platform-use-debounce/src/utils/use-debounce-callback/index.tsx';
import type { originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditView$key as TimeTrackingSettingsFragment } from '@atlassian/jira-relay/src/__generated__/originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditView.graphql';
import { timeTrackingFormatter } from '@atlassian/jira-time-tracking-formatter/src/main.tsx';
import type { TimeFormat, TimeUnit } from '@atlassian/jira-time-tracking-formatter/src/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditViewOld_timeTrackingSettingsfragmentRef$key as TimeTrackingSettingsFragmentOld } from '@atlassian/jira-relay/src/__generated__/originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditViewOld_timeTrackingSettingsfragmentRef.graphql';
import type { originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditViewNew_timeTrackingSettingsfragmentRef$key as TimeTrackingSettingsFragmentNew } from '@atlassian/jira-relay/src/__generated__/originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditViewNew_timeTrackingSettingsfragmentRef.graphql';
import { useFormatInputValue } from '@atlassian/jira-issue-field-original-estimate-readview-full/src/ui/original-estimate/utils/formatting.tsx';
import {
	useValidateTimeString,
	useParseTimeString,
} from '@atlassian/jira-issue-field-original-estimate-readview-full/src/ui/original-estimate/utils/parsing.tsx';
import messages from './messages.tsx';
import type { OriginalEstimateEditViewProps } from './types.tsx';
import { OriginalEstimateEditViewWithFormattedValue } from './original-estimate-with-formatted-value/index.tsx';

const DEBOUCE_DELAY_MS = 500;

const OriginalEstimateEditViewOld = forwardRef(
	(
		{
			autoFocus,
			onChange,
			initialTimeInSeconds,
			isDisabled,
			isInvalid,
			placeholder = '',
			spacing,
			onChangeInvalidMessage,
			invalidMessage,
			timeTrackingSettingsfragmentRef,
			onFocus,
			onBlur,
		}: Omit<OriginalEstimateEditViewProps, 'timeTrackingSettingsfragmentRef'> & {
			timeTrackingSettingsfragmentRef: TimeTrackingSettingsFragmentOld | null;
		},
		ref: Ref<HTMLInputElement>,
	) => {
		const allowValueOverrideRef = useRef(true);
		const isValueInitalisedRef = useRef(false);
		const [inputValue, setInputValue] = useState('');
		const timeTrackingSettingsData = useFragment<TimeTrackingSettingsFragmentOld>(
			graphql`
				fragment originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditViewOld_timeTrackingSettingsfragmentRef on JiraTimeTrackingSettings {
					workingHoursPerDay
					workingDaysPerWeek
					defaultFormat
					defaultUnit
				}
			`,
			timeTrackingSettingsfragmentRef,
		);

		const intl = useIntl();
		const { formatMessage } = useIntl();

		/*
		 * this hook have `initialTimeInSeconds` in its deps array so we could now track the changes
		 * and be able to verride the value by a third-party, e.g. ui modifications, in addition,
		 * there is a lock mechanism `allowValueOverrideRef` that blocks the value override when user does manual input
		 * so this useEffect won't do anything during that period of time, we unlock for value override on blur.
		 */
		useEffect(
			() => {
				if (!allowValueOverrideRef.current) {
					return;
				}

				if (isValueInitalisedRef.current && initialTimeInSeconds === null) {
					setInputValue('');
					onChange(null);

					return;
				}

				if (initialTimeInSeconds && !Number.isNaN(initialTimeInSeconds)) {
					const timeFormat =
						(fg('fix-time-time-format-input')
							? timeTrackingSettingsData?.defaultFormat?.toLowerCase()
							: timeTrackingSettingsData?.defaultFormat) || 'pretty';

					const formattedInputValue = timeTrackingFormatter(
						initialTimeInSeconds,
						{
							workingHoursPerDay: timeTrackingSettingsData?.workingHoursPerDay || 8,
							workingDaysPerWeek: timeTrackingSettingsData?.workingDaysPerWeek || 5,
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							timeFormat: timeFormat as TimeFormat,
							defaultUnit:
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								(timeTrackingSettingsData?.defaultUnit?.toLowerCase() as TimeUnit) || 'minute',
						},
						intl,
					);

					setInputValue(formattedInputValue);

					if (isValueInitalisedRef.current) {
						onChange(initialTimeInSeconds);
					}
				}

				if (!isValueInitalisedRef.current) {
					isValueInitalisedRef.current = true;
				}
			},
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[initialTimeInSeconds],
		);

		const [debouncedSetInputError, cancel] = useDebouncedCallback(
			onChangeInvalidMessage,
			DEBOUCE_DELAY_MS,
		);

		const validateString = useCallback(
			(timeString: string, skipDebounce = false) => {
				const timeTrackingSettings = {
					hoursPerDay: timeTrackingSettingsData?.workingHoursPerDay || 8,
					daysPerWeek: timeTrackingSettingsData?.workingDaysPerWeek || 5,
					format: timeTrackingSettingsData?.defaultFormat || 'pretty',
					defaultUnit: timeTrackingSettingsData?.defaultUnit?.toLowerCase() || 'minute',
					isTimeTrackingEnabled: true,
				};
				cancel();

				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				if (isValidTimeString(timeTrackingSettings as TimeTrackingConfig)(timeString)) {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					const newTimeValue = parseTimeString(timeTrackingSettings as TimeTrackingConfig)(
						timeString,
					);
					onChange(newTimeValue);
				} else {
					const errorMessage = formatMessage(messages.invalidInput);
					skipDebounce
						? onChangeInvalidMessage(errorMessage)
						: debouncedSetInputError(errorMessage);
				}
			},
			[
				cancel,
				debouncedSetInputError,
				formatMessage,
				onChange,
				onChangeInvalidMessage,
				timeTrackingSettingsData?.defaultFormat,
				timeTrackingSettingsData?.defaultUnit,
				timeTrackingSettingsData?.workingDaysPerWeek,
				timeTrackingSettingsData?.workingHoursPerDay,
			],
		);

		const handleChange = useCallback(
			(event: React.ChangeEvent<HTMLInputElement>) => {
				/**
				 * `allowValueOverrideRef` is set to `false` when there is user input going on
				 * so the useEffect won't trigger value change with each `initialTimeInSeconds` update
				 * the value change logic is intended to work with value override when the field is unlocked
				 * for overriding.
				 */
				if (allowValueOverrideRef.current) {
					allowValueOverrideRef.current = false;
				}

				onChangeInvalidMessage(null);
				setInputValue(event.target.value);
				validateString(event.target.value);
			},
			[onChangeInvalidMessage, validateString],
		);

		const handleBlur = useCallback(
			(event: React.FocusEvent<HTMLInputElement>) => {
				/**
				 * `allowValueOverrideRef` is set to `true` when the user blurs from the field
				 * so features like UI modification could override the value when there is no user interaction.
				 */
				if (!allowValueOverrideRef.current) {
					allowValueOverrideRef.current = true;
				}

				validateString(inputValue, true);

				onBlur?.(event);
			},
			[inputValue, validateString, onBlur],
		);

		return (
			<OriginalEstimateEditViewWithFormattedValue
				ref={ref}
				isDisabled={isDisabled}
				isInvalid={isInvalid}
				onBlur={handleBlur}
				autoFocus={autoFocus}
				onFocus={onFocus}
				spacing={spacing}
				value={inputValue}
				placeholder={placeholder}
				onChange={handleChange}
				invalidMessage={invalidMessage || undefined}
			/>
		);
	},
);

const OriginalEstimateEditViewNew = forwardRef(
	(
		{
			'aria-describedby': ariaDescribedby,
			autoFocus,
			onChange,
			initialTimeInSeconds,
			isDisabled,
			isInvalid,
			placeholder = '',
			spacing,
			onChangeInvalidMessage,
			invalidMessage,
			timeTrackingSettingsfragmentRef,
			onFocus,
			onBlur,
		}: Omit<OriginalEstimateEditViewProps, 'timeTrackingSettingsfragmentRef'> & {
			timeTrackingSettingsfragmentRef: TimeTrackingSettingsFragmentNew | null;
		},
		ref: Ref<HTMLInputElement>,
	) => {
		const allowValueOverrideRef = useRef(true);
		const isValueInitalisedRef = useRef(false);
		const [inputValue, setInputValue] = useState('');
		const timeTrackingSettingsData = useFragment<TimeTrackingSettingsFragmentNew>(
			graphql`
				fragment originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditViewNew_timeTrackingSettingsfragmentRef on JiraTimeTrackingSettings {
					workingHoursPerDay
					workingDaysPerWeek
					defaultFormat
					defaultUnit
				}
			`,
			timeTrackingSettingsfragmentRef,
		);

		const { formatMessage } = useIntl();
		const settingsInput = {
			workingHoursPerDay: timeTrackingSettingsData?.workingHoursPerDay,
			workingDaysPerWeek: timeTrackingSettingsData?.workingDaysPerWeek,
			defaultFormat: timeTrackingSettingsData?.defaultFormat,
			defaultUnit: timeTrackingSettingsData?.defaultUnit,
		};

		const formatInputValue = useFormatInputValue(settingsInput);
		const validateTime = useValidateTimeString(settingsInput);
		const parseTime = useParseTimeString(settingsInput);
		/*
		 * this hook have `initialTimeInSeconds` in its deps array so we could now track the changes
		 * and be able to verride the value by a third-party, e.g. ui modifications, in addition,
		 * there is a lock mechanism `allowValueOverrideRef` that blocks the value override when user does manual input
		 * so this useEffect won't do anything during that period of time, we unlock for value override on blur.
		 */
		useEffect(
			() => {
				if (!allowValueOverrideRef.current) {
					return;
				}

				if (isValueInitalisedRef.current && initialTimeInSeconds === null) {
					setInputValue('');
					onChange(null);

					return;
				}

				if (initialTimeInSeconds && !Number.isNaN(initialTimeInSeconds)) {
					setInputValue(formatInputValue(initialTimeInSeconds));

					if (isValueInitalisedRef.current) {
						onChange(initialTimeInSeconds ?? null);
					}
				}

				if (!isValueInitalisedRef.current) {
					isValueInitalisedRef.current = true;
				}
			},
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[initialTimeInSeconds],
		);

		const [debouncedSetInputError, cancel] = useDebouncedCallback(
			onChangeInvalidMessage,
			DEBOUCE_DELAY_MS,
		);

		const validateString = useCallback(
			(timeString: string, skipDebounce = false) => {
				cancel();

				if (validateTime(timeString)) {
					const newTimeValue = parseTime(timeString);
					onChange(newTimeValue);
				} else {
					const errorMessage = formatMessage(messages.invalidInput);
					skipDebounce
						? onChangeInvalidMessage(errorMessage)
						: debouncedSetInputError(errorMessage);
				}
			},
			[
				cancel,
				debouncedSetInputError,
				formatMessage,
				onChange,
				onChangeInvalidMessage,
				validateTime,
				parseTime,
			],
		);

		const handleChange = useCallback(
			(event: React.ChangeEvent<HTMLInputElement>) => {
				/**
				 * `allowValueOverrideRef` is set to `false` when there is user input going on
				 * so the useEffect won't trigger value change with each `initialTimeInSeconds` update
				 * the value change logic is intended to work with value override when the field is unlocked
				 * for overriding.
				 */
				if (allowValueOverrideRef.current) {
					allowValueOverrideRef.current = false;
				}

				onChangeInvalidMessage(null);
				setInputValue(event.target.value);
				validateString(event.target.value);
			},
			[onChangeInvalidMessage, validateString],
		);

		const handleBlur = useCallback(
			(event: React.FocusEvent<HTMLInputElement>) => {
				/**
				 * `allowValueOverrideRef` is set to `true` when the user blurs from the field
				 * so features like UI modification could override the value when there is no user interaction.
				 */
				if (!allowValueOverrideRef.current) {
					allowValueOverrideRef.current = true;
				}

				validateString(inputValue, true);

				onBlur?.(event);
			},
			[inputValue, validateString, onBlur],
		);

		return (
			<OriginalEstimateEditViewWithFormattedValue
				aria-describedby={ariaDescribedby}
				ref={ref}
				isDisabled={isDisabled}
				isInvalid={isInvalid}
				onBlur={handleBlur}
				autoFocus={autoFocus}
				onFocus={onFocus}
				spacing={spacing}
				value={inputValue}
				placeholder={placeholder}
				onChange={handleChange}
				invalidMessage={invalidMessage || undefined}
			/>
		);
	},
);

// Refactor to use extracted utility functions for parsing and formatting time strings
export const OriginalEstimateEditView = forwardRef(
	(
		{ timeTrackingSettingsfragmentRef, ...props }: OriginalEstimateEditViewProps,
		ref: Ref<HTMLInputElement>,
	) => {
		const data = useFragment<TimeTrackingSettingsFragment>(
			/* eslint-disable @atlassian/relay/graphql-naming */
			graphql`
				fragment originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditView on JiraTimeTrackingSettings {
					...originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditViewOld_timeTrackingSettingsfragmentRef
					...originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditViewNew_timeTrackingSettingsfragmentRef
				}
			`,
			timeTrackingSettingsfragmentRef,
		);

		if (fg('jsc_inline_editing_field_refactor')) {
			return (
				<OriginalEstimateEditViewNew {...props} ref={ref} timeTrackingSettingsfragmentRef={data} />
			);
		}
		return (
			<OriginalEstimateEditViewOld {...props} ref={ref} timeTrackingSettingsfragmentRef={data} />
		);
	},
);

const OriginalEstimateEditViewEntryPoint = ({
	props,
}: EntryPointProps<{}, {}, OriginalEstimateEditViewProps, {}>) => (
	<OriginalEstimateEditView {...props} />
);

export default OriginalEstimateEditViewEntryPoint;
