// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook } from '@atlassian/react-sweet-state';
import { store } from '../index.tsx';
import type { State } from '../types.tsx';

const getCloneStatus = (state: State) => state.cloneFetchStatus;

export const useCloneStatus = createHook(store, {
	selector: getCloneStatus,
});
