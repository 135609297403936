// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createContainer,
	createActionsHook,
	createStateHook,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';

type IssueLoadingIndicator = 'UNKNOWN' | 'LOADING' | 'LOADED';

type IssueRefreshLoadingState = {
	backgroundRefreshStatus: IssueLoadingIndicator;
	realTimeRefreshStatus: IssueLoadingIndicator;
};

const initialState: IssueRefreshLoadingState = {
	backgroundRefreshStatus: 'UNKNOWN',
	realTimeRefreshStatus: 'UNKNOWN',
};

const actions = {
	setBackgroundRefreshLoadingStatus:
		(status: IssueLoadingIndicator) =>
		({ setState }: StoreActionApi<IssueRefreshLoadingState>) => {
			setState({ backgroundRefreshStatus: status });
		},
	setRealTimeRefreshLoadingStatus:
		(status: IssueLoadingIndicator) =>
		({ setState }: StoreActionApi<IssueRefreshLoadingState>) => {
			setState({ realTimeRefreshStatus: status });
		},
} as const;

type Actions = typeof actions;

const store = createStore<IssueRefreshLoadingState, Actions>({
	name: 'issue-view.issue-actions.issue-refresh-loading',
	initialState,
	actions,
});

export const useIssueRefreshLoadingActions = createActionsHook(store);

export type IssueRefreshLoadingActions = ReturnType<typeof useIssueRefreshLoadingActions>;

export const IssueRefreshLoadingContainer = createContainer(store);

const selectBackgroundRefreshLoadingStatus = (state: IssueRefreshLoadingState) =>
	state.backgroundRefreshStatus;

const selectRealTimeRefreshLoadingStatus = (state: IssueRefreshLoadingState) =>
	state.realTimeRefreshStatus;

export const useBackgroundRefreshLoading = createStateHook(store, {
	selector: selectBackgroundRefreshLoadingStatus,
});

export const useRealTimeRefreshLoading = createStateHook(store, {
	selector: selectRealTimeRefreshLoadingStatus,
});
