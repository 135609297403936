import React, { Component, type ElementRef, type ComponentType, type ReactNode } from 'react';
import ReactDOM from 'react-dom';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import Icon from '@atlaskit/icon/core/migration/warning';
import { token } from '@atlaskit/tokens';
import UserPicker, { type Value } from '@atlaskit/user-picker';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { JiraInlineDialog as InlineDialog } from '@atlassian/jira-inline-dialog/src/ui/jira-inline-dialog.tsx';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { User } from '../model/types.tsx';
import searchUser from '../services/search-user/index.tsx';
import messages from './messages.tsx';

export type Props = {
	isMulti: boolean;
	isDisabled: boolean;
	isInvalid: boolean;
	isInputDisabled: boolean;
	inputId?: string;
	invalidMessage: string;
	autoFocus: boolean;
	usePortalForDropdown: boolean;
	minQueryLength: number;
	appearance: 'normal' | 'compact';
	placeholder: string;
	value: ReadonlyArray<User> | undefined;
	onChange: (arg1: ReadonlyArray<User>) => void;
	projectKey: string | undefined;
	issueKey: string | undefined;
};

type DefaultProps = {
	isInvalid: boolean;
	isInputDisabled: boolean;
	appearance: 'normal' | 'compact';
	placeholder: string;
	value: undefined;
	minQueryLength: number;
	autoFocus: boolean;
	invalidMessage: string;
	usePortalForDropdown: boolean;
};

type PropsWithIntl = Props & {
	intl: IntlShape;
};

type State = {
	query: string | undefined;
	options: User[];
	isLoading: boolean;
	isDropdownPortalReady: boolean;
	isOpen: boolean;
};

const DropdownPortal = ({
	innerRef,
	isOpen,
}: {
	innerRef: (ref: ElementRef<'div'> | null) => void;
	isOpen: boolean;
}) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (document.body !== null) {
		return ReactDOM.createPortal(
			<DropdownWrapper isOpen={isOpen} innerRef={innerRef} />,

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.body,
		);
	}
	return null;
};

// eslint-disable-next-line jira/react/no-class-components
export class PeopleField extends Component<PropsWithIntl, State> {
	static defaultProps: DefaultProps = {
		isInvalid: false,
		isInputDisabled: false,
		appearance: 'normal',
		placeholder: '',
		value: undefined,
		minQueryLength: 1,
		autoFocus: false,
		invalidMessage: '',
		usePortalForDropdown: true,
	};

	static getDerivedStateFromProps(props: Props, state: State): null | Partial<State> {
		if (props.isInputDisabled && state.isOpen) {
			return { isOpen: false };
		}

		return null;
	}

	constructor(props: PropsWithIntl) {
		super(props);

		this.state = {
			query: '',
			options: [],
			isLoading: false,
			isDropdownPortalReady: false,
			// right now this is the only way to simulate 'autoFocus' behaviour for UserPicker
			isOpen: props.autoFocus,
		};
	}

	onChange: (arg1: Value) => void = (value: Value) => {
		this.setState({ isOpen: false });
		if (value && !Array.isArray(value)) {
			this.props.onChange([value]);
		} else {
			this.props.onChange(value || []);
		}
	};

	onDropdownPortalRefChange: (arg1: ElementRef<'div'> | null) => void = (
		ref: ElementRef<'div'> | null,
	) => {
		if (!this.dropdownPortalRef && ref) {
			this.setState({ isDropdownPortalReady: true });
		}
		this.dropdownPortalRef = ref;
	};

	onQueryChange: (arg1: string | undefined) => void = (query?: string) => {
		const { minQueryLength } = this.props;
		if (query === undefined || query === '' || query.length < minQueryLength) {
			this.setState({ isLoading: false, query, options: [] });
			return;
		}

		this.setState({ query, isLoading: true }, () => this.updateOptions(query));
	};

	onBlur: () => void = () => this.setState({ isOpen: false });

	// eslint-disable-next-line jira/react-arrow-function-property-naming
	getNoOptionsMessage: () => string = () => {
		const { query } = this.state;
		const {
			minQueryLength,
			intl: { formatMessage },
		} = this.props;
		const queryLength = query == null ? 0 : query.length;
		return formatMessage(queryLength < minQueryLength ? messages.startTyping : messages.empty);
	};

	getValue(): User | ReadonlyArray<User> | null | undefined {
		const { isMulti, value } = this.props;

		if (isMulti || !value) {
			return value;
		}

		return value.length ? value[0] : null;
	}

	updateOptions: (arg1: string) => void = debounce((query: string) => {
		this.fetchOptions(query)
			.then((options) => {
				if (query !== this.state.query) {
					return;
				}
				this.setState({ options, isOpen: true, isLoading: false });
			})
			.catch(() => {
				if (query === this.state.query) {
					this.setState({ isLoading: false });
				}
			});
	}, 300);

	cache: {
		[key: string]: User[];
	} = {};

	dropdownPortalRef: ElementRef<'div'> | null = null;

	fetchOptions(query = ''): Promise<User[]> {
		if (this.cache[query]) {
			return Promise.resolve(this.cache[query]);
		}

		const fetch = async () => {
			const response: User[] = await searchUser(this.props.projectKey, this.props.issueKey, query);
			this.cache[query] = response;
			return response;
		};

		return fetch();
	}

	renderUserPicker() {
		const { isLoading, options, isOpen, isDropdownPortalReady } = this.state;
		const {
			isMulti,
			usePortalForDropdown,
			appearance,
			placeholder,
			isDisabled,
			isInvalid,
			invalidMessage,
			isInputDisabled,
			inputId,
		} = this.props;
		const { dropdownPortalRef } = this;

		if (usePortalForDropdown && !isDropdownPortalReady) {
			// we should wait for dropdown wrapper
			// otherwise the first mount can look weird
			return null;
		}

		const userPicker = (
			<UserPicker
				width="100%"
				isLoading={isLoading}
				options={options}
				addMoreMessage=""
				onBlur={this.onBlur}
				isClearable
				onInputChange={this.onQueryChange}
				open={!isInputDisabled && isOpen}
				// This needs to be passed as a function due to a bug in the component
				// https://product-fabric.atlassian.net/browse/FS-4023
				noOptionsMessage={this.getNoOptionsMessage}
				menuPortalTarget={dropdownPortalRef || undefined}
				isMulti={isMulti}
				disableInput={isInputDisabled}
				appearance={appearance}
				placeholder={placeholder}
				// @ts-expect-error - TS2322 - Type 'User | readonly User[] | null | undefined' is not assignable to type 'Value'.
				value={this.getValue()}
				onChange={this.onChange}
				isDisabled={isDisabled}
				fieldId="people-field"
				inputId={inputId}
				UNSAFE_hasDraggableParentComponent
				ariaLabel={placeholder}
			/>
		);

		if (!isInvalid) {
			return userPicker;
		}

		return (
			<InlineDialog
				isOpen
				content={invalidMessage}
				placement="right"
				messageId="project-settings-apps-people-field.inline-dialog"
				messageType="transactional"
			>
				{userPicker}
				<WarningIcon>
					<Icon spacing="spacious" label="warning" />
				</WarningIcon>
			</InlineDialog>
		);
	}

	render() {
		const { isOpen } = this.state;
		const { usePortalForDropdown } = this.props;
		return (
			<>
				{this.renderUserPicker()}

				{usePortalForDropdown ? (
					<DropdownPortal isOpen={isOpen} innerRef={this.onDropdownPortalRefChange} />
				) : null}
			</>
		);
	}
}

type PeopleFieldPropsWithDefault = Props & DefaultProps;

// @ts-expect-error - TS2322 - Type 'ComponentType<Diff<Record<any, any> | (Record<any, any> & { children?: ReactNode; }), InjectIntlVoidProps>>' is not assignable to type 'ComponentType<PeopleFieldPropsWithDefault>'. | TS2345 - Argument of type 'typeof PeopleField' is not assignable to parameter of type 'ComponentType<Record<any, any>>'.
const WithIntl: ComponentType<PeopleFieldPropsWithDefault> = injectIntl(PeopleField);
export default WithIntl;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DropdownWrapper = styled.div<{
	children?: ReactNode;
	isOpen: boolean;
	innerRef: (ref: ElementRef<'div'> | null) => void;
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
}>((props) => ({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: layers.modal,
	overflow: 'visible',
	position: 'absolute',
	left: 0,
	top: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	display: props.isOpen ? undefined : 'none',
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WarningIcon = styled.div<{
	children?: ReactNode;
}>({
	zIndex: 1,
	position: 'absolute',
	right: token('space.050'),
	bottom: token('space.050'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.warning'),
});
