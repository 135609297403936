import React, { Component, type ReactElement, type ComponentType } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { fg } from '@atlassian/jira-feature-gating';
import type { IntlShape } from '@atlassian/jira-intl';
import { IssueEditorBoundary as IssueCommentEditorBoundary } from '@atlassian/jira-issue-view-common/src/component/editor/async.tsx';
import type { RenderCustomSecondaryToolbar } from '@atlassian/jira-issue-view-common/src/component/editor/index.tsx';
import ServiceDeskCommentEditorContainer from '@atlassian/jira-issue-view-servicedesk-editor-container/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { MediaContext } from '@atlassian/jira-issue-view-common-types/src/media-context-type.tsx';
import type { UserMentionProvider } from '@atlassian/jira-mention-provider/src/services/user-mention-provider/index.tsx';
import type { ContextIdentifier } from '@atlassian/jira-rich-content/src/index.tsx';
import messages from '../messages.tsx';
import type { ConnectedEditorProps } from './comment-editor/index.tsx';
import { ConnectedRendererNew, ConnectedRendererOld } from './comment-renderer/index.tsx';

export type OwnProps = {
	canEdit: boolean;
	isEditing: boolean;
	bodyAdf?: ADF;
	draftValue?: ADF;
	commentId: string;
	secondaryToolbarComponents?: ReactElement[];
	onSave: (arg1: ADF) => void;
	onCancel: () => void;
	onChange: (arg1: ADF) => void;
	onPaste: (arg1: string) => void;
	onBlur: () => void;
	onHideSave: () => void;
	// remove undefined on change_comment_editorstate_props_to_propdrill cleanup
	contextIdentifier?: ContextIdentifier | null;
	mentionProvider?: UserMentionProvider | null;
	mediaContext?: MediaContext | null;
};
type IntlProps = {
	intl: IntlShape;
};
export type StateProps = {
	isServiceDesk: boolean;
	isCustomerService: boolean;
};
export type Props = OwnProps & StateProps & IntlProps;
// TODO reevaluate phase - forPaint is simply for initial parity
export const IssueCommentEditor = lazyForPaint<ComponentType<ConnectedEditorProps>>(
	() => import(/* webpackChunkName: "async-editor-view" */ './comment-editor'),
);
// eslint-disable-next-line jira/react/no-class-components
export class CommentContent extends Component<Props> {
	static displayName = 'CommentContent';

	static defaultProps = {
		isServiceDesk: false,
		isCustomerService: false,
		onBlur: noop,
		onSave: noop,
		onCancel: noop,
		onChange: noop,
		onPaste: noop,
		onHideSave: noop,
	};

	getEditValue = () => this.props.draftValue || this.props.bodyAdf;

	fgConnectedRenderer = () => {
		if (
			// this checks can also go on change_comment_editorstate_props_to_propdrill clean up. They are here to avoid type errors. The actual check is done earlier
			this.props.mentionProvider &&
			this.props.contextIdentifier &&
			this.props.mediaContext &&
			fg('change_comment_editorstate_props_to_propdrill')
		) {
			return (
				<ConnectedRendererNew
					key={`comment-${this.props.commentId}`}
					adf={this.props.bodyAdf}
					canEdit={this.props.canEdit}
					commentId={this.props.commentId}
					mentionProvider={this.props.mentionProvider}
					contextIdentifier={this.props.contextIdentifier}
					mediaContext={this.props.mediaContext}
				/>
			);
		}
		return (
			<ConnectedRendererOld
				key={`comment-${this.props.commentId}`}
				adf={this.props.bodyAdf}
				canEdit={this.props.canEdit}
				commentId={this.props.commentId}
			/>
		);
	};

	getIssueCommentEditor = ({
		assistiveLabel = '',
		renderCustomSecondaryToolbar,
		isLoomJSMBannerEnabled,
	}: {
		assistiveLabel: string;
		renderCustomSecondaryToolbar?: RenderCustomSecondaryToolbar;
		isLoomJSMBannerEnabled?: boolean;
	}) => {
		const editorProps: ConnectedEditorProps = {
			isExpanded: true,
			isExpandedByDefault: true,
			shouldFocus: true,
			defaultValue: this.getEditValue(),
			secondaryToolbarComponents: this.props.secondaryToolbarComponents,
			onBlur: this.props.onBlur,
			onSave: this.props.onSave,
			onCancel: this.props.onCancel,
			onChange: this.props.onChange,
			onPaste: this.props.onPaste,
			onHideSave: this.props.onHideSave,
		};
		return (
			<IssueCommentEditorBoundary packageName="issue" fallback={this.fgConnectedRenderer()}>
				<IssueCommentEditor
					{...editorProps}
					{...(assistiveLabel && { assistiveLabel })}
					{...(renderCustomSecondaryToolbar &&
						fg('jcs_master_flag') && { renderCustomSecondaryToolbar })}
					{...{ isLoomJSMBannerEnabled }}
				/>
			</IssueCommentEditorBoundary>
		);
	};

	renderEditor = () =>
		this.props.isServiceDesk || (this.props.isCustomerService && fg('jcs_project_type_m3')) ? (
			<ServiceDeskCommentEditorContainer isExpanded commentId={this.props.commentId}>
				{this.getIssueCommentEditor}
			</ServiceDeskCommentEditorContainer>
		) : (
			this.getIssueCommentEditor({
				assistiveLabel: this.props.intl.formatMessage(messages.comment),
			})
		);

	render() {
		return (
			<EditorContainer>
				{this.props.isEditing ? this.renderEditor() : this.fgConnectedRenderer()}
			</EditorContainer>
		);
	}
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EditorContainer = styled.div({
	/* 100% is needed to make the text wrap in IE11 - without it,
     the editor stays on a single line and grows horizontally. */
	flex: '1 1 100%',
	/* See: https://css-tricks.com/flexbox-truncated-text/ */
	minWidth: 0,
	/* override the font size of add comment placeholder */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	input: {
		font: 'inherit',
	},
});
