/**
 * @generated SignedSource<<3e57196921e0f2a7ae787734c41b33fb>>
 * @relayHash 00e1dcf4a731f67f81eb71e14b1de420
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 5d67171bcec2c91c815850238ad4e30f27fc9df5b9fb93e1f9022765ae09ac18

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraSingleValueFieldOperations = "SET" | "%future added value";
export type JiraUpdateRichTextFieldInput = {
  id: string;
  operation: JiraRichTextFieldOperationInput;
};
export type JiraRichTextFieldOperationInput = {
  document: JiraADFInput;
  operation: JiraSingleValueFieldOperations;
};
export type JiraADFInput = {
  jsonValue?: AGG$JSON | null | undefined;
  version?: number | null | undefined;
};
export type ui_issueViewLayoutRichTextField_IssueViewRichTextField_Mutation$variables = {
  input: JiraUpdateRichTextFieldInput;
};
export type ui_issueViewLayoutRichTextField_IssueViewRichTextField_Mutation$data = {
  readonly jira: {
    readonly updateRichTextField: {
      readonly errors: ReadonlyArray<{
        readonly extensions: {
          readonly statusCode: number | null | undefined;
        } | null | undefined;
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly id: string;
        readonly richText: {
          readonly $updatableFragmentSpreads: FragmentRefs<"ui_issueViewLayoutRichTextField_IssueViewRichTextField_richText">;
          readonly adfValue: {
            readonly convertedPlainText: {
              readonly plainText: string | null | undefined;
            } | null | undefined;
            readonly json: AGG$JSON | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type ui_issueViewLayoutRichTextField_IssueViewRichTextField_Mutation$rawResponse = {
  readonly jira: {
    readonly updateRichTextField: {
      readonly errors: ReadonlyArray<{
        readonly extensions: {
          readonly __typename: "BulkMutationErrorExtension";
          readonly id: string;
          readonly statusCode: number | null | undefined;
        } | {
          readonly __typename: string;
          readonly statusCode: number | null | undefined;
        } | null | undefined;
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly id: string;
        readonly richText: {
          readonly __typename: "JiraRichText";
          readonly adfValue: {
            readonly convertedPlainText: {
              readonly plainText: string | null | undefined;
            } | null | undefined;
            readonly json: AGG$JSON | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type ui_issueViewLayoutRichTextField_IssueViewRichTextField_Mutation = {
  rawResponse: ui_issueViewLayoutRichTextField_IssueViewRichTextField_Mutation$rawResponse;
  response: ui_issueViewLayoutRichTextField_IssueViewRichTextField_Mutation$data;
  variables: ui_issueViewLayoutRichTextField_IssueViewRichTextField_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "kind": "ScalarField",
  "name": "message"
},
v4 = {
  "kind": "ScalarField",
  "name": "statusCode"
},
v5 = {
  "kind": "ScalarField",
  "name": "id"
},
v6 = {
  "concreteType": "JiraADF",
  "kind": "LinkedField",
  "name": "adfValue",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "json"
    },
    {
      "concreteType": "JiraAdfToConvertedPlainText",
      "kind": "LinkedField",
      "name": "convertedPlainText",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "plainText"
        }
      ]
    }
  ]
},
v7 = {
  "kind": "ScalarField",
  "name": "__typename"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "ui_issueViewLayoutRichTextField_IssueViewRichTextField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraRichTextFieldPayload",
            "kind": "LinkedField",
            "name": "updateRichTextField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/)
                    ]
                  }
                ]
              },
              {
                "concreteType": "JiraRichTextField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "concreteType": "JiraRichText",
                    "kind": "LinkedField",
                    "name": "richText",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "FragmentSpread",
                        "name": "ui_issueViewLayoutRichTextField_IssueViewRichTextField_richText"
                      },
                      (v6/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ui_issueViewLayoutRichTextField_IssueViewRichTextField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraRichTextFieldPayload",
            "kind": "LinkedField",
            "name": "updateRichTextField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "concreteType": "MutationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v4/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v5/*: any*/)
                        ],
                        "type": "BulkMutationErrorExtension"
                      }
                    ]
                  }
                ]
              },
              {
                "concreteType": "JiraRichTextField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "concreteType": "JiraRichText",
                    "kind": "LinkedField",
                    "name": "richText",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v6/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "5d67171bcec2c91c815850238ad4e30f27fc9df5b9fb93e1f9022765ae09ac18",
    "metadata": {},
    "name": "ui_issueViewLayoutRichTextField_IssueViewRichTextField_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "16fa348f71311a0554106e215b373bc8";

export default node;
