import type { ViewSet } from '@atlassian/jira-polaris-domain-view/src/view-set/types.tsx';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';

export const upsertView = (views: View[], newView: View): View[] => {
	let isExisting = false;
	const updatedViews = views.map((view) => {
		if (view.viewId === newView.viewId) {
			isExisting = true;
			return {
				...newView,
				id: view.id,
				fetchCommentsTimestamp: view.fetchCommentsTimestamp,
				selectedIssues: view.selectedIssues,
				collapsedSwimlanes: view.collapsedSwimlanes,
				issueRanking: view.issueRanking,
				comments: view.comments,
				isEditingTitle: view.isEditingTitle,
				commentsLoaded: view.commentsLoaded,
				// real-time events don't contain the last viewed timestamp
				newView: newView.lastCommentsViewedTimestamp || view.lastCommentsViewedTimestamp,
			};
		}
		return view;
	});

	return isExisting ? updatedViews : [...updatedViews, newView];
};

export const isViewAriInViewSets = (viewAri: string, viewSets: ViewSet[]): boolean =>
	viewSets.some((viewSet) => {
		if (viewSet.views.some((view) => view.viewId === viewAri)) {
			return true;
		}

		return viewSet.viewSets ? isViewAriInViewSets(viewAri, viewSet.viewSets) : false;
	});
