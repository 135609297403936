import React, { useCallback, useMemo, type Ref, type MouseEvent } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { asyncInlineCreateStatusModal } from '@atlassian/jira-inline-create-status-modal/entrypoint.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { defaultModalProps } from '@atlassian/jira-status-config-common/src/constants.tsx';
import {
	ISSUE_VIEW_SOURCE,
	type ConfigureSource,
} from '@atlassian/jira-status-config-common/src/types.tsx';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { createStatusExperience } from '../../../experiences.tsx';
import { messages } from './messages.tsx';

export type CreateStatusOptionProps = {
	projectId: string;
	projectKey: string;
	projectType: ProjectType;
	issueTypeId: string;
	statusNames?: string[];
	onClick: () => void;
	onClose: () => void;
	onStatusCreated?: () => Promise<void>;
	buttonRef: Ref<HTMLButtonElement>;
	source?: ConfigureSource;
};

export const CreateStatusOption = ({
	projectId,
	projectKey,
	projectType,
	issueTypeId,
	statusNames = [],
	onClick,
	onClose,
	onStatusCreated,
	buttonRef,
	source,
}: CreateStatusOptionProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const cloudId = useCloudId();

	const handleClick = useCallback(
		(_e: MouseEvent<HTMLElement>) => {
			onClick();
			fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'createStatusButton');
			// Because we are triggering the click manually for keyboard users
			// this handler becomes the source-of-truth handler for both keyboard users and mouse users
			// However, we need to stopPropagation from here to prevent double modal for mouse users
			// When mouse user clicks the button, if we don't stop propagation here, handleChange
			// will be called which will trigger click manually again and cause double modal rendering
			_e.stopPropagation();
		},
		[onClick, createAnalyticsEvent],
	);

	const onModalClose = useCallback(() => {
		onClose();
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'closed',
				actionSubject: 'inlineCreateStatusModal',
			}),
		);
	}, [createAnalyticsEvent, onClose]);

	const onMouseDown = () => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			}),
			'createStatusButton',
		);
	};

	const entryPointParams = useMemo(
		() => ({
			cloudId,
			projectKey,
		}),
		[cloudId, projectKey],
	);

	const entryPointProps = useMemo(
		() => ({
			statusNames,
			projectId,
			projectKey,
			projectType,
			onClose: onModalClose,
			isNewControllerEnabled: true,
			workflowAssociatedIssueTypeId: issueTypeId,
			createStatusExperience,
			source: source || ISSUE_VIEW_SOURCE,
			onStatusCreated,
		}),
		[
			statusNames,
			projectId,
			projectKey,
			projectType,
			onModalClose,
			issueTypeId,
			source,
			onStatusCreated,
		],
	);

	return (
		<ModalEntryPointPressableTrigger
			entryPoint={asyncInlineCreateStatusModal}
			entryPointProps={entryPointProps}
			entryPointParams={entryPointParams}
			interactionName="create-status-modal"
			modalProps={defaultModalProps}
			useInternalModal
			forwardedRef={buttonRef}
		>
			{({ ref }) => (
				<Box
					role="button"
					xcss={optionStyles}
					onClick={handleClick}
					data-show-on-hover
					ref={ref}
					onMouseDown={(event: MouseEvent<HTMLElement>) => {
						if (event.button === 0) {
							// Left mouse button was clicked
							onMouseDown();
						}
					}}
				>
					{formatMessage(messages.createStatus)}
				</Box>
			)}
		</ModalEntryPointPressableTrigger>
	);
};

const optionStyles = xcss({
	height: '100%',
	width: '100%',
	backgroundColor: 'color.background.neutral.subtle',
	alignItems: 'center',
	textAlign: 'left',
	fontWeight: 'font.weight.medium',
});
