import { getConfluenceApiErrorMessage } from './errors.tsx';
import type {
	ConfluenceFetchResponse,
	ConfluenceUser,
	CreateConfluenceDraftV2Response,
	FetchWithRetryParams,
	GetCurrentConfluenceUserParams,
	getUserPersonalSpaceResponse,
} from './types.tsx';

export const CONF_REQUEST_ACCESS_CAPABILITIES_URL =
	'/gateway/api/invitations/v1/access-requests/capabilities?';
export const CONF_REQUEST_ACCESS_CAPABILITIES_ARI = 'ari:cloud:confluence::site/';
const CONF_DIRECT_JOIN_API = '/gateway/api/invitations/v1/access-requests/request';

/**
 * Exponential backoff with max delay and jitter
 * https://aws.amazon.com/blogs/architecture/exponential-backoff-and-jitter/
 */
const maxExpBackoffWithJitter = (retries: number, baseDelay: number, maxDelay: number) => {
	const exponential = Math.pow(2, retries) * baseDelay;
	const delay = Math.min(exponential, maxDelay);
	const jitter = Math.random();
	const delayWithJitter = Math.floor(jitter * delay);
	return delayWithJitter;
};

export const fetchWithRetry: <T>(
	params: FetchWithRetryParams,
) => Promise<ConfluenceFetchResponse<T>> = async ({
	url,
	options,
	shouldRetryOnApiError,
	retries = 3,
	baseDelay = 300,
	maxDelay = 10000,
}) => {
	try {
		const response = await fetch(url, options);
		if (response.ok) {
			const body = await response.json();
			return { success: true, result: body };
		}
		if (shouldRetryOnApiError) {
			throw new Error('Failed request with a confluence error');
		}
		const errorBody = await response.json();
		const errorMessage = getConfluenceApiErrorMessage(errorBody);
		return { success: false, error: new Error(errorMessage) };
	} catch (error: unknown) {
		if (retries > 0) {
			const retryDelay = maxExpBackoffWithJitter(retries, baseDelay, maxDelay);
			await new Promise((resolve) => setTimeout(resolve, retryDelay));
			return fetchWithRetry({
				url,
				options,
				shouldRetryOnApiError,
				retries: retries - 1,
				baseDelay,
				maxDelay,
			});
		}
		const newError = error instanceof Error ? error : new Error('An unknown error occurred');
		return { success: false, error: newError };
	}
};

export const deleteDraftPage = async (cloudId: string, pageId: string) => {
	const request = new Request(
		`/gateway/api/ex/confluence/${cloudId}/wiki/rest/api/content/${pageId}?status=draft`,
		{
			method: 'DELETE',
		},
	);
	try {
		const response = await fetch(request);
		if (!response.ok) {
			throw new Error(`HTTP ${response.status} ${request.url}`);
		}
		return response;
	} catch (e: unknown) {
		throw new Error(
			`Unable to fetch ${request.url}: ${e instanceof Error ? e.message : String(e)}`,
			{ cause: e },
		);
	}
};

/*
    TODO: We implemented an api call below for now.
    This will be updated to be a link to the Confluence Request Access Page
*/
export const requestConfluenceAccess = (cloudId: string) => {
	const params = new URLSearchParams({
		resource: `${CONF_REQUEST_ACCESS_CAPABILITIES_ARI}${cloudId}`,
	});

	return fetch(`${CONF_REQUEST_ACCESS_CAPABILITIES_URL}${params.toString()}`, {
		method: 'GET',
		credentials: 'include',
		headers: { Accept: 'application/json' },
	});
};

export const directJoinConfluence = (cloudId: string) =>
	fetch(`${CONF_DIRECT_JOIN_API}`, {
		method: 'POST',
		credentials: 'include',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({
			resource: `${CONF_REQUEST_ACCESS_CAPABILITIES_ARI}${cloudId}`,
		}),
	});

export const getCurrentConfluenceUser = async ({
	cloudId,
	shouldRetryOnApiError = true,
	retries = 3,
	baseDelay,
	maxDelay,
}: GetCurrentConfluenceUserParams): Promise<ConfluenceFetchResponse<ConfluenceUser>> =>
	fetchWithRetry({
		url: `/gateway/api/ex/confluence/${cloudId}/wiki/rest/api/user/current?expand=operations`,
		options: {
			credentials: 'include',
			headers: { Accept: 'application/json' },
		},
		shouldRetryOnApiError,
		retries,
		baseDelay,
		maxDelay,
	});

export const getUsersPersonalSpaceInfo = async (
	cloudId: string,
): Promise<ConfluenceFetchResponse<getUserPersonalSpaceResponse>> =>
	fetchWithRetry<getUserPersonalSpaceResponse>({
		url: `/gateway/api/ex/confluence/${cloudId}/wiki/rest/api/user/current?expand=personalSpace`,
		options: {
			credentials: 'include',
			headers: { Accept: 'application/json' },
		},
		shouldRetryOnApiError: false,
	});

const getCreateConfluencePageURL = (cloudId: string): string =>
	`/gateway/api/ex/confluence/${cloudId}/wiki/api/v2/pages?${new URLSearchParams({
		expand: 'space',
	}).toString()}`;

export const createConfluenceDraft = async (
	cloudId: string,
	spaceId: string,
	subtype?: string,
): Promise<ConfluenceFetchResponse<CreateConfluenceDraftV2Response>> => {
	try {
		/** API docs at
		 * https://developer.atlassian.com/cloud/confluence/rest/v2/api-group-page/#api-pages-post
		 */
		const response = await fetch(getCreateConfluencePageURL(cloudId), {
			method: 'post',
			credentials: 'include',
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				status: 'draft',
				spaceId,
				body: {
					representation: 'storage',
					value: '',
				},
				...(subtype && { subtype }),
			}),
		});

		if (!response.ok) {
			// We could have multiple errors from the v2 api.
			const responseBody = await response.json();
			return { success: false, error: new Error(getConfluenceApiErrorMessage(responseBody)) };
		}

		const responseJson = await response.json();
		return { success: true, result: responseJson };
	} catch (error: unknown) {
		// This is some type of Network error
		const newError = error instanceof Error ? error : new Error('An unknown error occurred.');
		return { success: false, error: newError };
	}
};
