import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import LinkIcon from '@atlaskit/icon/core/migration/link';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import AsyncIcon from '@atlassian/jira-common-components-async-icon/src/view.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { SimilarIssueLink } from '@atlassian/jira-issue-shared-types/src/common/types/linked-issue-type.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { useIssueLinkLocality } from '@atlassian/jira-issue-view-services/src/issue-link-locality-service/context.tsx';
import { addIssueLinkClick } from '@atlassian/jira-issue-view-store/src/actions/issue-links-actions.tsx';
import { useAIAnalyticsContext } from '@atlassian/jira-instrumentation-common/src/ai-analytics-context/context.tsx';
import { AI_EVENTS } from '@atlassian/jira-instrumentation-common/src/ai-analytics-context/common.tsx';
import type { RelatedIssue } from '../../../common/types.tsx';
import { useRelatedIssuesForIssue } from '../../../services/context.tsx';
import { messages } from './messages.tsx';

export type IssueLineCardProps = RelatedIssue & {
	onAddIssueLinkClick: (linkedIssue: SimilarIssueLink) => void;
};

const IssueLineCard = ({
	id,
	issueTypeIconUrl,
	issueTypeName,
	issueKey,
	issueSummary,
	onAddIssueLinkClick,
}: IssueLineCardProps) => {
	const { formatMessage } = useIntl();
	const { fireTrackAI } = useAIAnalyticsContext();
	const [{ status }] = useRelatedIssuesForIssue();

	const title = formatMessage(messages.issueTypeName, { issueTypeName });

	// We only want the JSM Similar Issues panel's "Link" button to show up if the standard
	// Jira linking panel dropdown has a local Jira instance selected. This is because the JSM
	// Similar Issues panel just shows related issues from the same instance, so we only want to
	// offer linking if the same instance is selected.
	const [{ isLinkingToLocalJira }] = useIssueLinkLocality();

	const issueLink = `/browse/${issueKey}`;

	const handleLinkClick = (event: React.MouseEvent) => {
		event.stopPropagation(); // to not 'click' the panel beneath the button
		fireTrackAI(AI_EVENTS.ACTIONED, {
			aiResultAction: 'recommendationAccepted',
			relatedIssueStatus: status,
			relatedIssueId: id,
		});
		onAddIssueLinkClick({
			id,
			iconUrl: issueTypeIconUrl,
			value: issueKey,
			fullObject: {
				id: Number(id),
				key: issueKey,
				keyHtml: issueKey,
				img: issueTypeIconUrl,
				summary: issueSummary,
				summaryText: issueSummary, // if not specified- other links re-render on add
			},
		});
	};

	return (
		<>
			<StyledIssueButton
				appearance="link"
				href={issueLink}
				iconBefore={<AsyncIcon alt={title} url={issueTypeIconUrl} />}
			>
				{issueKey} {issueSummary}
			</StyledIssueButton>
			{isLinkingToLocalJira && (
				<Tooltip
					content={formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.issueLinkingButtonIssueTermRefresh
							: messages.issueLinkingButton,
					)}
					position="left"
				>
					{({ ref, ...tooltipProps }) => (
						<StyledLinkingButton
							ref={ref}
							appearance="subtle"
							spacing="compact"
							{...tooltipProps}
							iconBefore={<LinkIcon spacing="spacious" label="" />}
							aria-label={formatMessage(
								fg('jira-issue-terminology-refresh-m3')
									? messages.issueLinkingButtonAriaLabelIssueTermRefresh
									: messages.issueLinkingButtonAriaLabel,
							)}
							onClick={handleLinkClick}
						/>
					)}
				</Tooltip>
			)}
		</>
	);
};

export default connect(null, {
	onAddIssueLinkClick: addIssueLinkClick,
})(IssueLineCard);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledIssueButton = styled(Button)({
	marginTop: token('space.050'),
	overflow: 'hidden',
});

StyledIssueButton.displayName = 'StyledIssueButton';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledLinkingButton = styled(Button)({
	marginLeft: 'auto',
	alignSelf: 'center',
});

StyledLinkingButton.displayName = 'StyledLinkingButton';
