import memoize from 'lodash/memoize';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next/types';
import performance from '@atlassian/jira-common-performance/src/performance.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { getTraceId } from '@atlassian/jira-fetch/src/utils/trace-id.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { IssueId, ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { TServiceRegistryResponse, TSimilarIncidentResponse } from '../common/types.tsx';
import type { Context, Filter, SimilarityResponse } from './types.tsx';
import { transformResponseForAnalytics } from './utils.tsx';

export const REQUEST_ISSUE_LIMIT = 15;

type SimilarityReponseWithTraceId = {
	data: SimilarityResponse;
	traceId: string;
};

type SimilarIncidentsReponseWithTraceId = {
	data: TSimilarIncidentResponse;
	traceId: string;
};
/**
 * Call Firebolt to get related issues.
 * Cache is based on issue id + query + JQL. If user clicks between different issue status types,
 * will retrieve from cache if already fetched.
 */
export const fetchSimilarIssues = memoize(
	(
		context: Context,
		baseUrl: string,
		filter: Filter,
		createAnalyticsEvent: CreateUIAnalyticsEvent,
	): Promise<SimilarityReponseWithTraceId> => {
		const startTime = performance.now();

		const FIREBOLT_URL = '/gateway/api/firebolt/1/similarissues/issue/project';

		// hardcoding estimatedTotalIssues for now, to remove when field is made optional in FRS.
		return fetch(FIREBOLT_URL, {
			body: JSON.stringify({
				context: {
					subProduct: 'serviceDesk',
					product: 'jira',
					sessionId: context.sessionId,
					containerId: context.projectId,
					objectId: context.issueId,
					queryText: context.queryText,
					principalId: 'context',
					tenantId: context.cloudId,
				},
				filter,
				limit: REQUEST_ISSUE_LIMIT,
				estimatedTotalIssues: 1000,
			}),
			headers: { 'Content-Type': 'application/json' },
			method: 'POST',
		})
			.then(async (res): Promise<SimilarityReponseWithTraceId> => {
				const traceId = getTraceId(res) || '';
				if (!res.ok) {
					throw new FetchError(res.status, 'Similar issues api failed', traceId, res);
				}
				const data = await res.json();
				return { data, traceId };
			})
			.then(({ data, traceId }) => {
				fireOperationalAnalytics(createAnalyticsEvent({}), 'fetchRelatedIssues succeeded', {
					elapsedTimeMilli: Math.floor(performance.now() - startTime),
					results: transformResponseForAnalytics(data),
					resultsSize: data.issues.length,
				});
				return { data, traceId };
			})
			.catch((error: FetchError) => {
				fireOperationalAnalytics(createAnalyticsEvent({}), 'fetchRelatedIssues failed', {
					elapsedTimeMilli: Math.floor(performance.now() - startTime),
					traceId: error.traceId,
					statusCode: error.statusCode,
				});
				throw error;
			});
	},
	(context: Context, baseUrl: string, filter: Filter) =>
		`${context.issueId} - ${context.queryText ?? ''} - ${filter.jql}`,
);

/*
 * Call Firebolt to get similar incidents.
 */
export const fetchSimilarIncidents = (
	issueId: IssueId,
	projectId: ProjectId,
	createAnalyticsEvent: CreateUIAnalyticsEvent,
): Promise<SimilarIncidentsReponseWithTraceId> => {
	const SIMIMLAR_INCIDENTS_URL = `/gateway/api/firebolt/1/similarissues/incidents/${issueId}?projectId=${projectId}`;
	const startTime = performance.now();

	return fetch(SIMIMLAR_INCIDENTS_URL)
		.then(async (response) => {
			const traceId = getTraceId(response) || '';
			if (!response.ok) {
				throw new FetchError(response.status, 'Similar incidents api failed', traceId, response);
			}
			fireOperationalAnalytics(createAnalyticsEvent({}), 'fetchSimilarIncidents succeeded', {
				elapsedTimeMilli: Math.floor(performance.now() - startTime),
				statusCode: 200,
			});
			const data = await response.json();
			return { data, traceId };
		})
		.catch((error) => {
			fireOperationalAnalytics(createAnalyticsEvent({}), 'fetchSimilarIncidents failed', {
				elapsedTimeMilli: Math.floor(performance.now() - startTime),
				traceId: error.traceId,
				statusCode: error.statusCode,
			});
			throw error;
		});
};

/*
 * Call service registry to get service names.
 */
export const fetchServiceNames = (
	ids: string[],
	createAnalyticsEvent: CreateUIAnalyticsEvent,
): Promise<TServiceRegistryResponse> => {
	const SERVICE_URL = '/rest/service-registry-api/service';
	const startTime = performance.now();

	return fetchJson(SERVICE_URL, {
		method: 'POST',
		body: JSON.stringify({
			ids,
		}),
	})
		.then((response) => response)
		.catch((error) => {
			fireOperationalAnalytics(createAnalyticsEvent({}), 'fetchServiceNames failed', {
				elapsedTimeMilli: Math.floor(performance.now() - startTime),
				traceId: error.traceId,
				statusCode: error.statusCode,
			});
			throw error;
		});
};
