// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook } from '@atlassian/react-sweet-state';
import { store } from '../store/index.tsx';
import type { State } from '../types.tsx';

export const isRollingUp = (state: State) => state.isRollingUp;

export const useWorklogRollingUpData = createHook(store, {
	selector: isRollingUp,
});
