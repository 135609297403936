/**
 * @generated SignedSource<<d8f09ce19aff3f197c637b62f45369ec>>
 * @relayHash a36415c069bf48afbe1a27cf7ba911be
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 654a598e81ba739aa91122a3529d10235ad2934a74debc9bb73befe6f037ee6c

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { siteSwitcherQuery } from './siteSwitcherQuery.graphql';

const node: PreloadableConcreteRequest<siteSwitcherQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "654a598e81ba739aa91122a3529d10235ad2934a74debc9bb73befe6f037ee6c",
    "metadata": {},
    "name": "siteSwitcherQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
