import React, {
	type ComponentType,
	useEffect,
	useCallback,
	useRef,
	useMemo,
	useState,
	type ChangeEvent,
} from 'react';
import { styled } from '@compiled/react';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import trim from 'lodash/trim';
import type { AnalyticsEventPayload } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import { Box, xcss } from '@atlaskit/primitives';
import useUFOTypingPerformanceTracing from '@atlaskit/react-ufo/typing-performance-tracing';
import Textfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { JiraInlineDialog as InlineDialog } from '@atlassian/jira-inline-dialog/src/ui/jira-inline-dialog.tsx';
import { AnalyticsSubject } from '@atlassian/jira-analytics-web-react/src/components/decorators.tsx';
// eslint-disable-next-line jira/wrm/no-load-bridge
import { loadBridge } from '@atlassian/jira-common-bridge/src';
import EnterEscapeHandler from '@atlassian/jira-common-components-enter-escape-handler/src/index.tsx';
import { QUICK_EDIT_PLUGIN_WRM_KEY } from '@atlassian/jira-common-constants/src/load-bridge.tsx';
import componentWithCondition from '@atlassian/jira-common-util-component-with-condition/src/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { GIC_LIFECYCLE_EVENTS } from '@atlassian/jira-issue-create-extensibility/src/common/utils/lifecycle-events/constants.tsx';
import { useGICLifeCycleEvents } from '@atlassian/jira-issue-create-extensibility/src/common/utils/lifecycle-events/main.tsx';
import type { ChildIssue } from '@atlassian/jira-issue-shared-types/src/common/types/children-issues-type.tsx';
import { GIC_CALLBACK_PAYLOAD_INLINE_CREATE_ID } from '@atlassian/jira-issue-view-common-constants/src/child-issues.tsx';
import type { ChildIssueType } from '@atlassian/jira-issue-view-common-types/src/child-issue-type.tsx';
import { smoothScrollIntoCenterIfNeeded } from '@atlassian/jira-issue-view-common-utils/src/scroll/index.tsx';
import { transformGICPayload } from '@atlassian/jira-new-issue-create-trigger/src/utils.tsx';
import { useTriggerIssueViewModal } from '@atlassian/jira-open-issue-view-modal-controller/src/index.tsx';
import {
	JSW_ISSUE_VIEW_CREATE_ISSUE_IN_EPIC,
	JSW_ISSUE_VIEW_CREATE_SUBTASK,
} from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/constants.tsx';
import { withNewGICProvider } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/main.tsx';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { useGlobalRef, useGlobalRefStoreActions } from '@atlassian/jira-refs-store/src/index.tsx';
import type {
	BaseUrl,
	IssueId,
	IssueKey,
	ProjectId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import { useStorageEnforcement } from '@atlassian/jira-software-enforce-storage-limits/src/controllers/storage-enforcement/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { QUICK_ADD_ITEMS_ADD_BUTTON_REF_KEY } from '@atlassian/jira-issue-view-common-constants/src/index.tsx';
import { useIssueBreakdown } from '@atlassian/jira-ai-work-breakdown/src/controllers/context.tsx';
import transfromServerChild from '../../common/transform-server-child/index.tsx';
import {
	type OptimisticUiChildIssue,
	type ChildIssuesPanelType,
	type OpenIssueCreateModalProps,
	CLASSIC_SUBTASKS,
	CLASSIC_PORTFOLIO_CHILDREN,
	CHILDREN_ISSUES_PANEL,
	CLASSIC_PROJECT_EPIC_CHILDREN,
} from '../../model/types.tsx';
import { AddExistingIssueButton } from './add-existing-issue-button/view.tsx';
import messages from './messages.tsx';

const MAX_SUMMARY_LENGTH = 255;
type DefaultProps = {
	addClickCount: number;
	requiresFullCreate: boolean;
	parentIssueKey?: IssueKey;
	epicLinkFieldKey: string | null;
};
type Props = {
	issueTypes: ChildIssueType[];
	issueHierarchyLevel?: number;
	parentIssueKey?: IssueKey;
	epicLinkFieldKey?: string | null;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ChildTypesSelect: ComponentType<Record<any, any>>;
	childIssuesPanelType: ChildIssuesPanelType;
	baseUrl: BaseUrl;
	projectId: ProjectId;
	parentIssueId: IssueId;
	requiresFullCreate?: boolean;
	addClickCount?: number;
	selectedIssueType: ChildIssueType | null;
	summary: string;
	inputId?: string;
	// eslint-disable-next-line jira/react/handler-naming
	openIssueCreateModal?: (props: OpenIssueCreateModalProps) => void;
	onSubmit: (
		optimisticUiChildIssue: OptimisticUiChildIssue,
		additionalAttributes: AnalyticsEventPayload['attributes'],
	) => void;
	onCancel: () => void;
	onFullDialogCreate: (
		optimsticId: string,
		childIssue: ChildIssue,
		additionalAttributes: AnalyticsEventPayload['attributes'],
	) => void;
	onSelectedChildTypeChanged: (arg1: ChildIssueType) => void;
	onChangeSummary: (summary: string) => void;
	onAddExistingIssue: () => void;
	onSubmitClickAnalytics: () => void;
	onCancelClickAnalytics: () => void;
	onEnterKeyPressedAnalytics: () => void;
	onEscapeKeyPressedAnalytics: () => void;
	onAddExistingClickAnalytics: () => void;
	triggeredViaHotKey?: boolean;
};

const isSummaryTooLong = (summary: string) => {
	const trimmedSummary = trim(summary);
	return trimmedSummary.length > MAX_SUMMARY_LENGTH;
};

const isCreatingChildIssueAllowed = (selectedIssueType: ChildIssueType | null | undefined) => {
	// Currently, in an Epic only project selectedIssueType is coming as undefined
	if (!selectedIssueType) {
		return false;
	}

	return true;
};

const isPrimaryButtonDisabled = (
	summary: string,
	selectedIssueType: ChildIssueType | null | undefined,
) => {
	if (!isCreatingChildIssueAllowed(selectedIssueType)) {
		return true;
	}
	const trimmedSummary = trim(summary);
	return isEmpty(trimmedSummary) || isSummaryTooLong(summary);
};

const isTextFieldInvalid = (
	summary: string,
	selectedIssueType: ChildIssueType | null | undefined,
) => {
	if (!isCreatingChildIssueAllowed(selectedIssueType)) {
		return true;
	}
	return isSummaryTooLong(summary);
};

const testId = 'issue.views.common.child-issues-panel.inline-create.add-child-trigger-button';

export const InlineCreate = ({
	addClickCount = 0,
	requiresFullCreate = false,
	parentIssueKey = undefined,
	epicLinkFieldKey = null,
	issueTypes = [],
	ChildTypesSelect,
	childIssuesPanelType,
	baseUrl,
	projectId,
	parentIssueId,
	selectedIssueType,
	summary,
	inputId,
	openIssueCreateModal,
	onSubmit,
	onCancel,
	onFullDialogCreate,
	onChangeSummary,
	onAddExistingIssue,
	onSubmitClickAnalytics,
	onCancelClickAnalytics,
	onEnterKeyPressedAnalytics,
	onEscapeKeyPressedAnalytics,
	onAddExistingClickAnalytics,
	issueHierarchyLevel,
	triggeredViaHotKey,
}: Props) => {
	const childContainer = useRef<HTMLDivElement>(null);
	const [, { register }] = useGlobalRefStoreActions();

	const createSubtaskButtonRef = useGlobalRef('quick-add-items.create-subtask')[0];

	const createSubtaskAddButton = useGlobalRef(QUICK_ADD_ITEMS_ADD_BUTTON_REF_KEY)[0];

	const addChildSubtaskRef = useGlobalRef('quick-add-items.add-child-subtask')[0];

	const fieldContainer = useUFOTypingPerformanceTracing('child-issue-inline-create-type-tracing');

	const createIssueFormFactory = useRef<unknown>(null);

	const { showEnforcementIfStorageIsFull } = useStorageEnforcement();

	const [{ issueCreateSessionId }] = useTriggerIssueViewModal();

	const { formatMessage } = useIntl();
	const [{ isProactivelySuggestingIssues }, { resetIssueBreakdownState }] = useIssueBreakdown();

	const reset = useCallback(() => {
		onChangeSummary('');
	}, [onChangeSummary]);

	const closeAndReset = useCallback(() => {
		reset();
		onCancel();
	}, [onCancel, reset]);

	const resetAndFocus = useCallback(() => {
		reset();
		fieldContainer.current?.focus();
	}, [reset, fieldContainer]);

	const isNextgenSubtask = useCallback(
		() =>
			childIssuesPanelType === CHILDREN_ISSUES_PANEL
				? !isNil(issueHierarchyLevel) && issueHierarchyLevel === 0
				: false,
		[childIssuesPanelType, issueHierarchyLevel],
	);

	const subtaskCreateEnabled = useCallback(
		() =>
			isNextgenSubtask() ||
			childIssuesPanelType === CLASSIC_SUBTASKS ||
			childIssuesPanelType === CLASSIC_PORTFOLIO_CHILDREN,
		[isNextgenSubtask, childIssuesPanelType],
	);

	const enableVisualRefreshChanges = isVisualRefreshEnabled();

	const [childIssuesPanelTypeInState] = useState(() => childIssuesPanelType);

	useEffect(() => {
		const legacyResource =
			childIssuesPanelTypeInState === CLASSIC_SUBTASKS
				? 'quick-edit/form/factory/create-subtask'
				: 'quick-edit/form/factory/create-issue';
		// eslint-disable-next-line jira/wrm/no-load-bridge
		loadBridge({
			name: legacyResource,
			wrmKeys: QUICK_EDIT_PLUGIN_WRM_KEY,
		}).then((bridgeValue) => {
			createIssueFormFactory.current = bridgeValue;
		});
	}, [childIssuesPanelTypeInState]);

	const createChild = useCallback(() => {
		const optimisticId = `OPTIMISTIC_CHILD_${Date.now()}`;

		// If full create is required, we check if modern GIC is enabled and window.IssueCreate is defined
		// If full create is not required, we don't need to check for GIC or windows.issueCreate and the child issue will simply be submitted
		const isWRMRemovalConditionMet =
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			(window.IssueCreate &&
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.IssueCreate.Visibility.isEnabled &&
				fg('render_modern_global_issue_create_modal_experience')) ||
			(!selectedIssueType?.hasRequiredField && !requiresFullCreate);

		const isWRMRemoved = isWRMRemovalConditionMet
			? true
			: typeof createIssueFormFactory.current === 'function';

		const canCreateChild = !isPrimaryButtonDisabled(summary, selectedIssueType) && isWRMRemoved;

		if (canCreateChild && selectedIssueType) {
			const optimisticChild = {
				id: optimisticId,
				issueSummary: summary,
				issueTypeId: `${selectedIssueType.id}`,
				issueTypeIconUrl: `${selectedIssueType.iconUrl}`,
				issueTypeName: `${selectedIssueType.name}`,
				assigneeUrl: null,
				assigneeDisplayName: null,
			};

			if (selectedIssueType.hasRequiredField || requiresFullCreate) {
				const creatingIssueInClassicProjectEpic =
					childIssuesPanelType === CLASSIC_PROJECT_EPIC_CHILDREN;

				const payload = {
					pid: projectId,
					issueType: selectedIssueType && selectedIssueType.id,
					fields: {
						issuetype: selectedIssueType.id,
						summary: summary || '',
						...(creatingIssueInClassicProjectEpic && epicLinkFieldKey
							? { [epicLinkFieldKey]: parentIssueKey }
							: {}),
					},
					customFieldsToPreserveOnFormRecreation: [
						...(creatingIssueInClassicProjectEpic && epicLinkFieldKey ? [epicLinkFieldKey] : []),
					],
					...(childIssuesPanelType === CLASSIC_SUBTASKS || isNextgenSubtask()
						? { parentIssueId }
						: {}),
				};

				if (
					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					window.IssueCreate &&
					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					window.IssueCreate.Visibility.isEnabled &&
					fg('render_modern_global_issue_create_modal_experience')
				) {
					const { pid, issueType, fields: payloadFields } = payload;

					const epicField =
						creatingIssueInClassicProjectEpic && epicLinkFieldKey && parentIssueKey
							? {
									epic: {
										fieldId: epicLinkFieldKey,
										fieldValue: parentIssueKey,
									},
								}
							: null;
					const newPayload = transformGICPayload({
						prefilledFields: {
							pid,
							parentId: parentIssueId,
							issuetype: issueType,
							summary: payloadFields.summary,
							...epicField,
						},
					});
					const triggerSource = creatingIssueInClassicProjectEpic
						? JSW_ISSUE_VIEW_CREATE_ISSUE_IN_EPIC
						: JSW_ISSUE_VIEW_CREATE_SUBTASK;

					openIssueCreateModal?.({
						payload: newPayload,
						fieldsToAddInPublishedData: [epicLinkFieldKey],
						isSubTaskCreationOpen: subtaskCreateEnabled(),
						triggerPointKey: triggerSource,
						sessionId: issueCreateSessionId,
						triggeredViaHotKey,
						callbackPayload: {
							id: GIC_CALLBACK_PAYLOAD_INLINE_CREATE_ID,
							data: {
								optimisticId,
								parentIssueKey,
								childIssuesPanelType,
							},
						},
					});
				} else {
					// @ts-expect-error - TS2571 - Object is of type 'unknown'.
					const createIssueForm = createIssueFormFactory.current?.(payload);

					// This overrides the monolith frontend's JQuery event watcher that sends GASv3 events.
					// So we manually send the Create Issue SLA event in the create-child-success epic.
					createIssueForm?.bind(
						'issueCreated',
						(
							e: Error,
							data: {
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								createdIssueDetails: any;
							},
						) => {
							if (
								!creatingIssueInClassicProjectEpic ||
								// @ts-expect-error - TS2538 - Type 'null' cannot be used as an index type.
								data.createdIssueDetails.fields[epicLinkFieldKey] === parentIssueKey
							) {
								onFullDialogCreate(
									optimisticId,
									transfromServerChild(data.createdIssueDetails, baseUrl),
									{ sessionId: issueCreateSessionId },
								);
							}

							resetAndFocus();
						},
					);

					createIssueForm
						?.asDialog({
							id:
								childIssuesPanelType === CLASSIC_SUBTASKS
									? 'create-subtask-dialog'
									: 'create-issue-dialog',
						})
						.show();
				}
			} else {
				onSubmit(optimisticChild, {
					sessionId: issueCreateSessionId,
					...(expVal('quick_actions_m3_experiment', 'hotKeysEnabled', false)
						? { triggeredViaHotKey }
						: {}),
				});
				resetAndFocus();
				showEnforcementIfStorageIsFull({ touchpoint: 'child-issues-panel' });
			}
		}
	}, [
		selectedIssueType,
		requiresFullCreate,
		summary,
		childIssuesPanelType,
		projectId,
		epicLinkFieldKey,
		parentIssueKey,
		isNextgenSubtask,
		parentIssueId,
		openIssueCreateModal,
		subtaskCreateEnabled,
		issueCreateSessionId,
		triggeredViaHotKey,
		resetAndFocus,
		onFullDialogCreate,
		baseUrl,
		onSubmit,
		showEnforcementIfStorageIsFull,
	]);

	const onSubmitClicked = useCallback(() => {
		createChild();
		onSubmitClickAnalytics();
	}, [createChild, onSubmitClickAnalytics]);

	const onCancelClicked = useCallback(() => {
		closeAndReset();
		// eslint-disable-next-line jira/ff/no-preconditioning
		fg('proactive_ai_suggestions_for_child_items_targeting') &&
			isProactivelySuggestingIssues &&
			resetIssueBreakdownState();
		onCancelClickAnalytics();
		if (fg('jfp-a11y-team-issue-view-child-issue-panel-focus')) {
			if (addChildSubtaskRef) {
				addChildSubtaskRef?.focus();
			} else {
				createSubtaskAddButton?.focus();
			}
			register('quick-add-items.add-child-subtask', null);
		}
	}, [
		closeAndReset,
		onCancelClickAnalytics,
		createSubtaskAddButton,
		addChildSubtaskRef,
		register,
		isProactivelySuggestingIssues,
		resetIssueBreakdownState,
	]);

	const onCancelKeyPress = useCallback(
		(event: React.KeyboardEvent<HTMLButtonElement>) => {
			if (event.key === 'Enter' || event.key === ' ') {
				createSubtaskButtonRef?.focus();
			}
		},
		[createSubtaskButtonRef],
	);

	const onEnterPressed = useCallback(() => {
		createChild();
		onEnterKeyPressedAnalytics();
	}, [createChild, onEnterKeyPressedAnalytics]);

	const onEscPressed = useCallback(() => {
		closeAndReset();
		onEscapeKeyPressedAnalytics();
	}, [closeAndReset, onEscapeKeyPressedAnalytics]);

	const onUpdateChildSummary = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			onChangeSummary(e.target.value);
		},
		[onChangeSummary],
	);

	const onAddExisting = useCallback(() => {
		onAddExistingIssue();
		onAddExistingClickAnalytics();
	}, [onAddExistingClickAnalytics, onAddExistingIssue]);

	const previousAddClickCount = usePrevious(addClickCount);

	const scrollPanelIntoView = useCallback(() => {
		if (fg('quick_actions_menu_ga')) {
			setTimeout(() => {
				smoothScrollIntoCenterIfNeeded(childContainer.current);
				fieldContainer.current?.focus({ preventScroll: true });
			}, 0);
		} else {
			smoothScrollIntoCenterIfNeeded(childContainer.current);
			fieldContainer.current?.focus({ preventScroll: true });
		}
	}, [childContainer, fieldContainer]);

	useEffect(() => {
		scrollPanelIntoView();
	}, [childIssuesPanelTypeInState, scrollPanelIntoView, fieldContainer]);

	useEffect(() => {
		if (previousAddClickCount !== undefined && addClickCount !== previousAddClickCount) {
			scrollPanelIntoView();
		}
	}, [addClickCount, previousAddClickCount, fieldContainer, scrollPanelIntoView]);

	const summaryTooLongMessage = isSummaryTooLong(summary)
		? formatMessage(messages.summaryTooLong)
		: '';

	const cannotCreateChildIssueMessage = formatMessage(
		fg('jira-issue-terminology-refresh-m3')
			? messages.cannotCreateChildIssueIssueTermRefresh
			: messages.cannotCreateChildIssue,
	);

	const getInlineDialogContent = () => {
		if (!isCreatingChildIssueAllowed(selectedIssueType)) {
			return cannotCreateChildIssueMessage;
		}
		return summaryTooLongMessage;
	};

	const addExistingIssueButton = useMemo(() => {
		switch (childIssuesPanelType) {
			case CHILDREN_ISSUES_PANEL:
				return (
					<Tooltip
						content={formatMessage(
							expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
								? messages.addExistingIssuePlaceholderIssueTermRefresh
								: messages.addExistingIssuePlaceholder,
						)}
					>
						<AddExistingIssueButton onClick={onAddExisting}>
							{formatMessage(
								expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
									? messages.addExistingIssuePlaceholderIssueTermRefresh
									: messages.addExistingIssuePlaceholder,
							)}
						</AddExistingIssueButton>
					</Tooltip>
				);
			case CLASSIC_PORTFOLIO_CHILDREN:
			case CLASSIC_PROJECT_EPIC_CHILDREN:
			case CLASSIC_SUBTASKS:
			default:
				return <div />;
		}
	}, [childIssuesPanelType, formatMessage, onAddExisting]);

	const { subscribe } = useGICLifeCycleEvents();

	useEffect(
		() =>
			subscribe?.(GIC_LIFECYCLE_EVENTS.ISSUE_CREATE_SUCCESS, async (payload) => {
				if (payload.callbackPayload?.id !== GIC_CALLBACK_PAYLOAD_INLINE_CREATE_ID) return;

				const callbackData = payload.callbackPayload?.data;
				const isEpicEqualToParent =
					(epicLinkFieldKey &&
						payload.createdIssueData.createdIssueDetails.fields[epicLinkFieldKey]) ===
					parentIssueKey;

				if (
					callbackData.parentIssueKey !== parentIssueKey ||
					callbackData.childIssuesPanelType !== childIssuesPanelType
				)
					return;

				if (subtaskCreateEnabled() || isEpicEqualToParent) {
					resetAndFocus();
				}
			}),
		[
			subscribe,
			resetAndFocus,
			subtaskCreateEnabled,
			epicLinkFieldKey,
			parentIssueKey,
			childIssuesPanelType,
		],
	);
	useEffect(() => {
		if (issueTypes.length < 1) {
			fireErrorAnalytics({
				error: new Error('issue types array is empty'),
				meta: {
					id: 'ChildIssuePanelNoIssueType',
					packageName: 'jiraIssueViewCommonViews',
					teamName: 'bento',
				},
				skipSentry: true,
			});
		}
	}, [issueTypes.length]);

	const childTypeSelect = useMemo(
		() => <ChildTypesSelect isDisabled={issueTypes.length <= 1} />,
		[ChildTypesSelect, issueTypes.length],
	);

	return (
		<div ref={childContainer}>
			<WrapperDiv isVisualRefreshEnabled={enableVisualRefreshChanges}>
				{enableVisualRefreshChanges ? (
					<Box xcss={childTypesSelectStyles}>{childTypeSelect}</Box>
				) : (
					childTypeSelect
				)}
				<FieldTextWrapper>
					<EnterEscapeHandler onEnter={onEnterPressed} onEscape={onEscPressed}>
						<InlineDialog
							messageId="issue-view-common-views.child-issues-panel.inline-create.inline-dialog"
							messageType="transactional"
							content={getInlineDialogContent()}
							isOpen={isTextFieldInvalid(summary, selectedIssueType)}
							placement="right"
						>
							<Textfield
								id={inputId}
								value={summary}
								placeholder={formatMessage(messages.summaryPlaceholder)}
								onChange={onUpdateChildSummary}
								isInvalid={isTextFieldInvalid(summary, selectedIssueType)}
								// @ts-expect-error - TS2322 - Type '{ id: string | undefined; value: string; placeholder: string; onChange: (e: ChangeEvent<HTMLInputElement>) => void; isInvalid: boolean; invalidMessage: string; shouldFitContainer: true; ref: MutableRefObject<...>; elemAfterInput: Element | null; }' is not assignable to type 'IntrinsicAttributes & TextfieldProps & RefAttributes<unknown>'.
								shouldFitContainer
								aria-label={formatMessage(messages.summaryTextFieldLabel)}
								ref={fieldContainer}
								elemAfterInput={
									isTextFieldInvalid(summary, selectedIssueType) ? (
										<Box xcss={warningWrapperStyles}>
											<WarningIcon
												color={token('color.icon.warning')}
												spacing="spacious"
												label=""
												testId="issue-view-common-views.child-issues-panel.inline-create.invalid-textfield-warning-icon"
											/>
										</Box>
									) : null
								}
								isDisabled={!isCreatingChildIssueAllowed(selectedIssueType)}
							/>
						</InlineDialog>
					</EnterEscapeHandler>
				</FieldTextWrapper>
			</WrapperDiv>
			<ButtonsContainer>
				{addExistingIssueButton}
				<ButtonsRightSide>
					<StyledButton
						appearance="primary"
						onClick={onSubmitClicked}
						isDisabled={isPrimaryButtonDisabled(summary, selectedIssueType)}
						data-testId={testId}
					>
						{formatMessage(messages.childCreateButton)}
					</StyledButton>
					<StyledButton
						appearance="subtle"
						onClick={onCancelClicked}
						onKeyPress={onCancelKeyPress}
						/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
						testId="issue.views.common.child-issues-panel.inline-create.styled-button"
					>
						{formatMessage(messages.childCancelButton)}
					</StyledButton>
				</ButtonsRightSide>
			</ButtonsContainer>
		</div>
	);
};

export default componentWithCondition<Props>(
	() => fg('render_modern_global_issue_create_modal_experience'),
	// @ts-expect-error - TS2345 - Argument of type 'ComponentType<Record<any, any>>' is not assignable to parameter of type 'AbstractComponent<Props, any>'. | TS2345 - Argument of type '(props: Props & DefaultProps) => ReactElement<any, string | JSXElementConstructor<any>>' is not assignable to parameter of type 'ComponentType<Record<any, any>>'.
	AnalyticsSubject('issue')(withNewGICProvider<Props & DefaultProps>(InlineCreate)),
	AnalyticsSubject('issue')(InlineCreate),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WrapperDiv = styled.div<{ isVisualRefreshEnabled: boolean }>({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: (props) =>
		props.isVisualRefreshEnabled ? undefined : token('color.background.neutral'),
	width: '100%',
	gap: token('space.025'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldTextWrapper = styled.div({
	flexGrow: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledButton = styled(Button)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	marginLeft: `${token('space.100')} !important`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonsContainer = styled.div({
	marginTop: token('space.100'),
	display: 'flex',
	justifyContent: 'space-between',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonsRightSide = styled.div({
	justifyContent: 'flex-end',
	display: 'flex',
});

const warningWrapperStyles = xcss({
	marginLeft: 'space.negative.050',
	marginRight: 'space.050',
});

const childTypesSelectStyles = xcss({
	backgroundColor: 'color.background.neutral',
	marginRight: 'space.050',
});
