// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';

export const setNewlyAddedGlobalFields =
	(fieldKeys: string[]): Action<State, Props> =>
	async ({ setState }) => {
		setState({
			newlyAddedGlobalFields: fieldKeys,
		});
	};

export const resetNewlyAddedGlobalFields =
	(): Action<State, Props> =>
	async ({ setState }) => {
		setState({
			newlyAddedGlobalFields: [],
		});
	};
