import React, { type ReactNode } from 'react';
import type { Dispatch } from 'redux';
import { graphql, useFragment } from 'react-relay';
import { JiraProjectAri } from '@atlassian/ari/jira/project';
import { fg } from '@atlassian/jira-feature-gating';
import type { Action } from '@atlassian/jira-issue-view-actions/src/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { useEditFieldConfigContext } from '@atlassian/jira-issue-field-edit-field-config-context/src/context.tsx';
import { refreshIssueRequest } from '@atlassian/jira-issue-view-store/src/common/actions/issue-fetch-actions.tsx';
import type { ConditionalInlineFieldConfigContent$key } from '@atlassian/jira-relay/src/__generated__/ConditionalInlineFieldConfigContent.graphql';
import { WrappedInlineFieldConfigContentEntryPoint } from './WrappedInlineFieldConfigContentEntryPoint.tsx';

type ConditionalInlineFieldConfigContentProps = {
	children: ReactNode;
	issueViewRelayFragment?: ConditionalInlineFieldConfigContent$key;
	onRefreshIssue: () => void;
};

const ConditionalInlineFieldConfigContentUnconnected = ({
	children,
	issueViewRelayFragment,
	onRefreshIssue,
}: ConditionalInlineFieldConfigContentProps) => {
	if (!issueViewRelayFragment || !fg('issue_view_field_config_edit')) {
		return <>{children}</>;
	}

	const data =
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useFragment<ConditionalInlineFieldConfigContent$key>(
			graphql`
				fragment ConditionalInlineFieldConfigContent on JiraIssue {
					projectField {
						project {
							id
							key
						}
					}
				}
			`,
			issueViewRelayFragment,
		);

	const projectKey = data.projectField?.project?.key;
	const projectId = JiraProjectAri.parse(data.projectField?.project?.id ?? '')?.resourceId;

	if (!projectKey || !projectId) {
		return <>{children}</>;
	}
	const {
		editFieldConfigState,
		setEditFieldConfigState,
		inlineFieldConfigEntryPointReferenceSubject,
		// eslint-disable-next-line react-hooks/rules-of-hooks
	} = useEditFieldConfigContext();

	if (editFieldConfigState.mode !== 'closed' && inlineFieldConfigEntryPointReferenceSubject) {
		return (
			<WrappedInlineFieldConfigContentEntryPoint
				editFieldConfigState={editFieldConfigState}
				setEditFieldConfigState={setEditFieldConfigState}
				projectId={projectId}
				projectKey={projectKey}
				inlineFieldConfigEntryPointReferenceSubject={inlineFieldConfigEntryPointReferenceSubject}
				onRefreshIssue={onRefreshIssue}
				isSidebarView
			/>
		);
	}

	return <>{children}</>;
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
	onRefreshIssue: () => {
		dispatch(refreshIssueRequest());
	},
});

export const ConditionalInlineFieldConfigContent = connect(
	() => ({}),
	mapDispatchToProps,
)((props: ConditionalInlineFieldConfigContentProps) => (
	<ConditionalInlineFieldConfigContentUnconnected {...props} />
));
