import React, { useEffect } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { unarchiveModalEntryPoint } from './entrypoint.tsx';
import { useUnarchiveIssueModalStates } from './controllers/selectors.tsx';

const runtimeProps = {};
const entryPointParams = {};
const AsyncUnarchiveModal = () => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		unarchiveModalEntryPoint,
		entryPointParams,
	);

	const { isUnarchiveIssueModalOpen } = useUnarchiveIssueModalStates();

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		if (isUnarchiveIssueModalOpen) {
			entryPointActions.load();
			return () => {
				entryPointActions.unload();
			};
		}
	}, [entryPointActions, isUnarchiveIssueModalOpen]);

	return (
		<JiraEntryPointContainer
			id="jira-unarchive-issue-modal"
			packageName="jiraUnarchiveModal"
			entryPointReferenceSubject={entryPointReferenceSubject}
			runtimeProps={runtimeProps}
		/>
	);
};

export default AsyncUnarchiveModal;
