import React, { forwardRef, type Ref } from 'react';
import { graphql, useLazyLoadQuery, type EntryPointProps } from 'react-relay';
import type { JwmUpdateFieldVariables } from '@atlassian/jira-business-inline-field-config-sidebar/src/services/update-field/__generated_apollo__/JwmUpdateField';
import type { ActionMeta } from '@atlassian/jira-common-components-picker/src/model.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { SelectableFieldEditViewWithFieldOptionsFragment } from '@atlassian/jira-issue-selectable-field-edit-view/src/ui/index.tsx';
import client from '@atlassian/jira-apollo-gira/src/index.tsx';
import { ApolloClientProvider } from '@atlassian/jira-apollo-multiple-clients/src/main.tsx';
import type {
	SelectableValueOption,
	NullableOptions,
	OptionsInput,
	OperationType,
} from '@atlassian/jira-issue-selectable-field-edit-view/src/ui/types.tsx';
import type { singleSelect_issueSelectableFieldQuery } from '@atlassian/jira-relay/src/__generated__/singleSelect_issueSelectableFieldQuery.graphql';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagsService, toFlagId } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useUpdateField } from '@atlassian/jira-business-inline-field-config-sidebar/src/services/update-field/index.tsx';

import messages from './messages.tsx';
import type { SingleSelectEditViewProps } from './types.tsx';
import { transformToSelectableValueOption, transformToOnChangeShape } from './utils.tsx';

export const UPDATE_FIELD_ERROR_FLAG_ID = toFlagId('update-field-error-flag');

const SingleSelectEditViewBase = forwardRef(
	(props: SingleSelectEditViewProps, ref: Ref<HTMLDivElement>) => {
		const { formatMessage } = useIntl();
		const { update } = useUpdateField();
		const { showFlag, dismissFlag } = useFlagsService();
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const { value, fieldId, fieldName, fieldType, onChange, menuPosition } = props;
		const { data } = useProjectContext();
		const projectId = data?.projectId;
		const fieldIdWithoutARI = fieldId.split(':').pop()?.split('/').pop() || '';

		const suggestionsData = useLazyLoadQuery<singleSelect_issueSelectableFieldQuery>(
			graphql`
				query singleSelect_issueSelectableFieldQuery(
					$id: ID!
					$filterById: JiraFieldOptionIdsFilterInput
				) {
					...ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment
						@arguments(id: $id, filterById: $filterById)
				}
			`,
			{ id: fieldId, filterById: props.filterOptionsById },
			{ fetchPolicy: 'store-only' },
		);

		const handleOnChange = (
			newValue: NullableOptions,
			_actionMeta: ActionMeta<SelectableValueOption>,
		): void => {
			onChange?.(transformToOnChangeShape(newValue));
		};

		const handleOnOptionChange = (
			updatedOptionsInput: OptionsInput[],
			operationType: OperationType,
		) => {
			const input = {
				projectId,
				fieldId: fieldIdWithoutARI,
				name: fieldName,
				description: null,
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				options: updatedOptionsInput as JwmUpdateFieldVariables['input']['options'],
				configuration: null,
			};

			return update({ input }).then((result) => {
				if (!result?.data?.jira?.jwmUpdateField?.success) {
					showFlag({
						messageId:
							'issue-field-single-select-editview-full.ui.single-select.show-flag.error.update-field-error',
						messageType: 'transactional',
						id: UPDATE_FIELD_ERROR_FLAG_ID,
						type: 'error',
						title: formatMessage(messages.updateFieldErrorTitle, {
							fieldName,
						}),
						description: expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.updateFieldErrorMessageIssueTermRefresh
							: messages.updateFieldErrorMessage,
						isAutoDismiss: true,
					});

					setTimeout(() => {
						dismissFlag(UPDATE_FIELD_ERROR_FLAG_ID);
					}, 5000);

					fireTrackAnalytics(
						createAnalyticsEvent({}),
						'edit failed',
						'jiraBusinessInlineFieldConfigSidebar',
						{
							projectId,
							numberOfOptions: updatedOptionsInput?.length,
							fieldType,
							operationType,
						},
					);
				}
				fireTrackAnalytics(
					createAnalyticsEvent({}),
					'field edited',
					'jiraBusinessInlineFieldConfigSidebar',
					{
						projectId: input?.projectId,
						numberOfOptions: input?.options?.length,
						fieldType,
						operationType,
					},
				);
				return result;
			});
		};
		const content = (
			<SelectableFieldEditViewWithFieldOptionsFragment
				{...props}
				isClearable
				placeholder={
					fieldName
						? formatMessage(messages.placeholder, {
								fieldName,
							})
						: undefined
				}
				value={value ? [transformToSelectableValueOption(value)] : undefined}
				onChange={handleOnChange}
				fieldOptionsFragmentRef={suggestionsData}
				menuPosition={menuPosition}
				onOptionChange={handleOnOptionChange}
			/>
		);

		return fg('jsc_inline_editing_field_refactor') ? <div ref={ref}>{content}</div> : content;
	},
);

export const SingleSelectEditView = forwardRef(
	(props: SingleSelectEditViewProps, ref: Ref<HTMLDivElement>) => (
		<ApolloClientProvider client={client}>
			<SingleSelectEditViewBase {...props} ref={ref} />
		</ApolloClientProvider>
	),
);

const SingleSelectViewEntryPoint = ({
	props,
}: EntryPointProps<{}, {}, SingleSelectEditViewProps, {}>) => <SingleSelectEditView {...props} />;

export default SingleSelectViewEntryPoint;
