/**
 * @generated SignedSource<<e226b473fc0105dfbef90c3010b8d27c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type headerActions_issueViewFoundation_HeaderActionsView_headerActions$data = {
  readonly votesField?: {
    readonly vote?: {
      readonly count: AGG$Long | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"main_issueViewFoundation_VoteButtonRelay" | "voters_issueViewFoundation_VotersItemNew">;
  } | null | undefined;
  readonly watchesField?: {
    readonly " $fragmentSpreads": FragmentRefs<"header_issueViewWatchers_WatchersItem">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"issueActions_issueViewFoundation_IssueActionsMenuWithRelay_issueViewRelayFragment" | "issueRestriction_issueViewFoundation_IssueRestrictionWrapper" | "ui_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelField">;
  readonly " $fragmentType": "headerActions_issueViewFoundation_HeaderActionsView_headerActions";
};
export type headerActions_issueViewFoundation_HeaderActionsView_headerActions$key = {
  readonly " $data"?: headerActions_issueViewFoundation_HeaderActionsView_headerActions$data;
  readonly " $fragmentSpreads": FragmentRefs<"headerActions_issueViewFoundation_HeaderActionsView_headerActions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcconcealitemsintomeatballmenuexperimentrelayprovidertsx"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationconfigurationurlrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsissuerestrictionsrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssecuritylevelrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsvotesrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldswatchersrelayprovider"
    }
  ],
  "kind": "Fragment",
  "name": "headerActions_issueViewFoundation_HeaderActionsView_headerActions",
  "selections": [
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssecuritylevelrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelField"
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldswatchersrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "concreteType": "JiraWatchesField",
          "kind": "LinkedField",
          "name": "watchesField",
          "plural": false,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "header_issueViewWatchers_WatchersItem"
            }
          ]
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsvotesrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "concreteType": "JiraVotesField",
          "kind": "LinkedField",
          "name": "votesField",
          "plural": false,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "voters_issueViewFoundation_VotersItemNew"
            },
            {
              "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcconcealitemsintomeatballmenuexperimentrelayprovidertsx",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "concreteType": "JiraVote",
                  "kind": "LinkedField",
                  "name": "vote",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "count"
                    }
                  ]
                },
                {
                  "kind": "FragmentSpread",
                  "name": "main_issueViewFoundation_VoteButtonRelay"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationconfigurationurlrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "issueActions_issueViewFoundation_IssueActionsMenuWithRelay_issueViewRelayFragment"
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsissuerestrictionsrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "issueRestriction_issueViewFoundation_IssueRestrictionWrapper"
        }
      ]
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "cd57a9a7e3c718c54a2d425025f698d2";

export default node;
