import React from 'react';
import Heading from '@atlaskit/heading';
import Image from '@atlaskit/image';
import { Box, xcss, Flex, Text } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import darkEmptyComment from './assets/dark-comments-empty.svg';
import lightEmptyComment from './assets/light-comments-empty.svg';
import messages from './messages.tsx';

export const CommentEmptyState = () => {
	const { formatMessage } = useIntl();

	return (
		<Flex
			xcss={placeholderWrapperStyles}
			alignItems="center"
			justifyContent="center"
			direction="column"
			gap="space.300"
		>
			<Box xcss={imageWrapperStyles}>
				<Image
					src={lightEmptyComment}
					srcDark={darkEmptyComment}
					alt={formatMessage(messages.header)}
				/>
			</Box>
			<Flex justifyContent="center" direction="column" alignItems="center" gap="space.100">
				<Heading size="xsmall">{formatMessage(messages.header)}</Heading>
				<Text size="small" color="color.text.subtle">
					{formatMessage(messages.description)}
				</Text>
			</Flex>
		</Flex>
	);
};

const placeholderWrapperStyles = xcss({
	textAlign: 'center',
	maxWidth: '300px',
	margin: '0 auto',
	paddingTop: 'space.1000',
	paddingBottom: 'space.1000',
	minHeight: '300px',
});

const imageWrapperStyles = xcss({
	width: '120px',
	height: '120px',
});
