import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { Box, xcss } from '@atlaskit/primitives';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import type { CustomerServiceCustomDetailsEntityType } from '@atlassian/jira-relay/src/__generated__/tableCustomDetailsQuery.graphql';
import { ExperienceFailure } from '@atlassian/jira-servicedesk-organizations-contacts-common/src/common/ui/experience-failure/index.tsx';
import { type UFOExperience, useUFOComponentExperience } from '@atlassian/ufo';
import { useIsJCSProjectType } from '@atlassian/jira-servicedesk-customer-service-common/src/utils/use-is-jcs/index.tsx';
import { useCommonPageContext } from '@atlassian/jira-servicedesk-common-page-context/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { ErrorState } from '../details/error-state/index.tsx';

const LoadingComponent = () => (
	<Container>
		<LoadingContainer>
			<Spinner testId="servicedesk-customer-service-issue-view-panel.common.ui.details-container.spinner" />
		</LoadingContainer>
	</Container>
);

export const DetailsContainer = ({
	context,
	children,
	entityExperience,
	traceId,
}: {
	context: CustomerServiceCustomDetailsEntityType;
	children: ReactNode;
	entityExperience?: UFOExperience;
	traceId?: string;
}) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	entityExperience && useUFOComponentExperience(entityExperience);
	const { projectType } = useCommonPageContext();
	const isJCS = useIsJCSProjectType(projectType);

	return (
		<JSErrorBoundary
			id="DetailsContainer"
			packageName="jiraServicedeskCustomerServiceIssueViewPanel"
			teamName="boysenberry"
			sendToPrivacyUnsafeSplunk
			fallback={({ error }) => (
				<>
					<ErrorState context={context} />
					{entityExperience && (
						<ExperienceFailure
							experience={entityExperience}
							config={
								fg('berry-4911_issue_view_org_expand_ufo_data')
									? {
											metadata: {
												traceId,
												error: {
													message: error?.message,
													stack: error?.stack,
												},
											},
										}
									: undefined
							}
						/>
					)}
				</>
			)}
		>
			<Placeholder name="customer-service-issue-view-panel" fallback={<LoadingComponent />}>
				<Box xcss={isJCS && jcsStyles}>
					<Container>{children}</Container>
				</Box>
			</Placeholder>
		</JSErrorBoundary>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	borderRadius: '4px',
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.025'),
	paddingBottom: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LoadingContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	height: '160px',
	width: '100%',
});

const jcsStyles = xcss({
	marginBottom: 'space.100',
});
