import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performGetRequest, performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';

export type UserPropertiesApiResponse<T> = {
	key: string;
	value: T;
};

const getUserUrl = (accountId: string, propertyKey: string) =>
	`/rest/api/3/user/properties/${propertyKey}?accountId=${accountId}`;

const handleErrorLogging = (
	operation: string,
	error: Error,
	attributes: Record<string, string>,
) => {
	fireErrorAnalytics({
		meta: {
			id: operation,
			packageName: 'jiraDevelopmentDetails',
			teamName: 'nova',
		},
		error,
		sendToPrivacyUnsafeSplunk: true,
		attributes,
	});
};

export const setUserProperty = async <T,>({
	propertyValue,
	propertyKey,
	accountId,
}: {
	propertyValue: T;
	propertyKey: string;
	accountId: string;
}): Promise<void> => {
	const url = getUserUrl(accountId, propertyKey);

	try {
		await performPutRequest(url, {
			body: JSON.stringify(propertyValue),
		});
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		handleErrorLogging('setUserProperty', error, {
			propertyValue: JSON.stringify(propertyValue),
		});
		throw error;
	}
};

export const getUserProperty = async <T,>({
	propertyKey,
	defaultValue,
	accountId,
}: {
	propertyKey: string;
	accountId: string;
	defaultValue: T;
}): Promise<UserPropertiesApiResponse<T>> => {
	const url = getUserUrl(accountId, propertyKey);
	try {
		const response: UserPropertiesApiResponse<T> = await performGetRequest(url);

		return response;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		return { key: propertyKey, value: defaultValue };
	}
};
