import isEqual from 'lodash/isEqual';
import type { ActionSubjectAndAction } from '@atlassian/jira-product-analytics-bridge';

import type { AnalyticsAttributesUntyped } from '@atlassian/jira-product-analytics-bridge/src/types';

export const AI_EVENTS: Record<string, ActionSubjectAndAction> = {
	INIT: 'aiInteraction initiated',
	VIEWED: 'aiResult viewed',
	ACTIONED: 'aiResult actioned',
	DISMISSED: 'aiInteraction dismissed',
	FEEDBACK: 'aiFeedback submitted',
	ERROR: 'aiResult error',
} as const;

export type AI_EVENTS_TYPE = (typeof AI_EVENTS)[keyof typeof AI_EVENTS];

type BaseConfig = {
	product: string;
	subproduct: string;
	aiFeatureName?: string;
	featureName?: string;
	proactiveGeneratedAI: 0 | 1;
	userGeneratedAI: 0 | 1;
	isAIFeature?: 0 | 1;
	aiExperienceName?: string;
};

export type AttributesType = Partial<BaseConfig> & {
	aiFeedbackResult?: 'up' | 'down';
	aiResultAction?: string;
	aiErrorMessage?: string;
	aiErrorCode?: number;
} & AnalyticsAttributesUntyped;

export type ProviderProps = {
	config: BaseConfig & AnalyticsAttributesUntyped;
	children: React.ReactNode;
};

export type AIAnalyticsContextType = {
	addTraceId: (traceId: string) => void;
	fireTrackAI: (
		eventName: AI_EVENTS_TYPE,
		attr?: Omit<AttributesType, 'singleInstrumentationID'>,
	) => void;
};

export const isConfigEqual = (prevProps: ProviderProps, nextProps: ProviderProps) => {
	const prevDefaults = prevProps.config || {};
	const nextDefaults = nextProps.config || {};

	return isEqual(prevDefaults, nextDefaults);
};
