import React, { Component } from 'react';
import range from 'lodash/range';
import StarStarredIcon from '@atlaskit/icon/core/migration/star-starred--star-filled';
import StarUnstarredIcon from '@atlaskit/icon/core/migration/star-unstarred--star';
import { token } from '@atlaskit/tokens';
import { JiraInlineDialog as InlineDialog } from '@atlassian/jira-inline-dialog/src/ui/jira-inline-dialog.tsx';
import { FieldDescription } from '@atlassian/jira-issue-field-description/src/ui/index.tsx';
import { FieldHeading } from '@atlassian/jira-issue-field-heading/src/index.tsx';
import {
	FieldHeadingTitle,
	FieldWrapper,
	SideBySideField,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin/src/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import FeedbackDetails from './feedback-details/index.tsx';
import { FeedbackRating } from './styled.tsx';

export type Props = {
	isVisible: boolean;
	issueKey: IssueKey;
	rating: number;
	fieldName: string;
	fieldId: string;
	showPinButton?: boolean;
};

type State = {
	shouldShowDetails: boolean;
};

const SCALE = 5;

// eslint-disable-next-line jira/react/no-class-components
export default class RequestFeedback extends Component<Props, State> {
	static defaultProps = {
		isVisible: true,
	};

	state = {
		shouldShowDetails: false,
	};

	onToggle = () =>
		this.setState({
			shouldShowDetails: !this.state.shouldShowDetails,
		});

	renderStar = (rating: number) => {
		const stars = range(SCALE).map((starIndex) =>
			starIndex < rating ? (
				<StarStarredIcon
					label=""
					key={starIndex}
					color={token('color.icon.accent.yellow')}
					testId="issue-view-base.context.request-feedback.star-filled-icon"
				/>
			) : (
				<StarUnstarredIcon
					label=""
					key={starIndex}
					color={token('color.text.subtlest')}
					testId="issue-view-base.context.request-feedback.star-icon"
				/>
			),
		);

		return <>{stars}</>;
	};

	render() {
		const { fieldName, rating, isVisible, fieldId, showPinButton, issueKey } = this.props;

		return (
			isVisible && (
				<FieldWrapper>
					<FieldHeading>
						<FieldHeadingTitle>{fieldName}</FieldHeadingTitle>
						{issueKey !== undefined && fieldId !== undefined && (
							<FieldDescription issueKey={issueKey} fieldKey={fieldId} label={fieldName} />
						)}
						{showPinButton === true && <FieldPin fieldId={fieldId} label={fieldName} />}
					</FieldHeading>
					<SideBySideField>
						<InlineDialog
							messageId="issue-view-base.context.request-feedback.inline-dialog"
							messageType="transactional"
							onClose={this.onToggle}
							content={<FeedbackDetails />}
							isOpen={this.state.shouldShowDetails}
						>
							<FeedbackRating onClick={this.onToggle}>{this.renderStar(rating)}</FeedbackRating>
						</InlineDialog>
					</SideBySideField>
				</FieldWrapper>
			)
		);
	}
}
