/**
 * @generated SignedSource<<cfc61df233acc6b30ec9146794c8e11d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type checkboxInlineEditView_issueInternalFields$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FieldEditIcon">;
  readonly " $fragmentType": "checkboxInlineEditView_issueInternalFields";
};
export type checkboxInlineEditView_issueInternalFields$key = {
  readonly " $data"?: checkboxInlineEditView_issueInternalFields$data;
  readonly " $fragmentSpreads": FragmentRefs<"checkboxInlineEditView_issueInternalFields">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "checkboxInlineEditView_issueInternalFields",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "FieldEditIcon"
    }
  ],
  "type": "JiraIssueField",
  "abstractKey": "__isJiraIssueField"
};

(node as any).hash = "5da830cc95ffe177e53e3c2eea7c5fa9";

export default node;
