import React from 'react';
import { token } from '@atlaskit/tokens';

const failedColor = token('color.icon.danger');
const backgroundColor = token('color.icon.inverse');

const FailedIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="100%"
		height="100%"
		fill="none"
		viewBox="0 0 16 16"
	>
		<circle r="6" cx="8" cy="8" fill={backgroundColor} />

		<path
			fill={failedColor}
			fillRule="evenodd"
			d="M.768 9.768a2.5 2.5 0 010-3.536L6.232.768a2.5 2.5 0 013.536 0l5.464 5.464a2.5 2.5 0 010 3.536l-5.464 5.464a2.5 2.5 0 01-3.536 0L.768 9.768zM7 4a1 1 0 112 0v4a1 1 0 01-2 0V4zm1 8a1 1 0 100-2 1 1 0 000 2z"
			clipRule="evenodd"
		/>
	</svg>
);

FailedIcon.displayName = 'FailedIcon';
export default FailedIcon;
