import { AnyAri } from '@atlassian/ari/any-ari';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createActionsHook, createStateHook } from '@atlassian/react-sweet-state';
import { PolarisEnvironmentContainerTypes, type PolarisEnvironmentContainer } from '../types.tsx';

type State = {
	loading: boolean;
	error?: string;
	data: PolarisEnvironmentContainer | undefined;
};

const initialState: State = {
	loading: true,
	data: undefined,
	error: undefined,
};

const Store = createStore({
	initialState,
	actions: {
		setLoading:
			(loading: boolean) =>
			({ setState }) => {
				setState({ loading });
			},
		setError:
			(error: string | undefined) =>
			({ setState }) => {
				setState({ error });
			},
		setData:
			(data: PolarisEnvironmentContainer | undefined) =>
			({ setState }) => {
				setState({ data });
			},
		reset:
			() =>
			({ setState }) => {
				setState(initialState);
			},
	},
	name: 'jpd-environment-datacontainer',
});

export const useActions = createActionsHook(Store);

export const useEnvironmentContainer = createStateHook(Store, { selector: (state) => state.data });
export const useEnvironmentContainerId = createStateHook(Store, {
	selector: (state) => state.data?.id,
});

export const useContainerAri = (): string => {
	const { cloudId } = useTenantContext();
	const container = useEnvironmentContainer();

	const createARI = (resourceType: string, resourceId: string) =>
		AnyAri.create({
			resourceOwner: 'jira',
			cloudId,
			resourceType,
			resourceId,
		}).toString();

	switch (container?.type) {
		case PolarisEnvironmentContainerTypes.PROJECT:
			return container.projectAri.toString();
		case PolarisEnvironmentContainerTypes.COLLECTION:
			return createARI('collection', container.collectionId);
		case PolarisEnvironmentContainerTypes.VIEW:
			return createARI('view', container.viewId);
		default:
			return '';
	}
};

export const useHasEnvironmentContainerError = createStateHook(Store, {
	selector: (state) => !state.loading && (state.error || !state.data),
});

export const useIsEnvironmentContainerAvailable = createStateHook(Store, {
	selector: (state, containerType?: PolarisEnvironmentContainer['type']) => {
		if (containerType) {
			return !state.loading && state.data !== undefined && state.data.type === containerType;
		}

		return !state.loading && state.data !== undefined;
	},
});
