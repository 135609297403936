import React, { useCallback } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useIssueId, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useExportExcelModalActions } from '@atlassian/jira-export-excel-dialog/src/controllers/main.tsx';
import messages from './messages.tsx';

const ExportIssueCSV = () => {
	const { openExportExcelModal } = useExportExcelModalActions();
	const issueKey = useIssueKey();
	const issueId = useIssueId();
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onExportExcelSuccess = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'exported',
			actionSubject: 'issue',
		});
		fireUIAnalytics(analyticsEvent, 'exportExcel');
	}, [createAnalyticsEvent]);

	const onClick = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'dropdownItem',
		});
		fireUIAnalytics(analyticsEvent, 'exportCSV');
		openExportExcelModal({
			issueKey,
			issueId,
			onExportExcelSuccess,
		});
	}, [createAnalyticsEvent, issueId, issueKey, onExportExcelSuccess, openExportExcelModal]);

	return (
		<DropdownItem key="ExportCSVIssueActionView" onClick={onClick} target="_blank">
			{formatMessage(messages.exportCSV)}
		</DropdownItem>
	);
};
export default ExportIssueCSV;
