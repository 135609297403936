/**
 * @generated SignedSource<<af8cd62231d7a8708d56fec28f529290>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueActions_issueViewFoundation_IssueActionsMenuWithRelay_root$data = {
  readonly " $fragmentSpreads": FragmentRefs<"main_redactions_issueActionMenu_user">;
  readonly " $fragmentType": "issueActions_issueViewFoundation_IssueActionsMenuWithRelay_root";
};
export type issueActions_issueViewFoundation_IssueActionsMenuWithRelay_root$key = {
  readonly " $data"?: issueActions_issueViewFoundation_IssueActionsMenuWithRelay_root$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueActions_issueViewFoundation_IssueActionsMenuWithRelay_root">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjiraissueviewredactionexperiencerelayprovider"
    }
  ],
  "kind": "Fragment",
  "name": "issueActions_issueViewFoundation_IssueActionsMenuWithRelay_root",
  "selections": [
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjiraissueviewredactionexperiencerelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "main_redactions_issueActionMenu_user"
        }
      ]
    }
  ],
  "type": "JiraQuery"
};

(node as any).hash = "e783aea87e7250deea1824d5a90d92f5";

export default node;
