import { di } from 'react-magnetic-di';

import { useAdjustmentsEnabled } from '../../adjustments-context';
import { useHasFinishedLoading } from '../../adjustments-context/hooks/use-enabled-status';

type Props = {
	forgeFinishedLoading: boolean;
	forgeModulesCount: number;
};

export const useUimAnalytics = ({ forgeFinishedLoading, forgeModulesCount }: Props) => {
	di(useAdjustmentsEnabled, useHasFinishedLoading);
	const hasIssueAdjustmentFinishedLoading = useHasFinishedLoading();
	const adjustmentsAreEnabled = useAdjustmentsEnabled();

	const isIssueAdjustmentDisabled =
		forgeFinishedLoading &&
		(forgeModulesCount === 0 || (hasIssueAdjustmentFinishedLoading && !adjustmentsAreEnabled));

	const isIssueAdjustmentEnabled =
		forgeFinishedLoading &&
		forgeModulesCount > 0 &&
		hasIssueAdjustmentFinishedLoading &&
		adjustmentsAreEnabled;

	const isUimFinishedLoading = isIssueAdjustmentDisabled || isIssueAdjustmentEnabled;

	return { isUimFinishedLoading };
};
