import React, { useCallback, useMemo } from 'react';
import { Inline, Stack, Text } from '@atlaskit/primitives';
import Lozenge from '@atlaskit/lozenge';
import usePressTracing from '@atlaskit/react-ufo/use-press-tracing';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { RoundedSection } from '../../common/ui/rounded-section/index.tsx';
import { PlaybookPanelMessages } from './messages.tsx';
import { playbooksPanelBodyEntryPoint } from './playbook-panel-body/entrypoint.tsx';
import { PlaybooksPanelLoadingView } from './PlaybooksPanelLoadingView.tsx';
import { PlaybooksPanelErrorView } from './PlaybooksPanelErrorView.tsx';

type PlaybooksPanelProps = {
	issueId: string;
	cloudId: string;
	projectKey: string;
};

export const PlaybooksPanel = ({ issueId, cloudId, projectKey }: PlaybooksPanelProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const pressTracing = usePressTracing('playbooks-for-issue-panel');

	const { entryPointReferenceSubject, entryPointActions } = useEntryPoint(
		playbooksPanelBodyEntryPoint,
		{
			issueId,
			cloudId,
			projectKey,
		},
	);
	const triggerRef = useEntryPointButtonTrigger(entryPointActions, true, {
		click: entryPointActions.load,
	} satisfies Partial<Record<keyof GlobalEventHandlersEventMap, EventListener>>);
	const runtimeProps = useMemo(() => ({}), []);

	const onPanelExpand = useCallback(() => {
		pressTracing();
		const analyticsEvent = createAnalyticsEvent({
			action: 'expanded',
			actionSubject: 'panel',
		});
		fireUIAnalytics(analyticsEvent, 'playbooks');
	}, [createAnalyticsEvent, pressTracing]);

	return (
		<ContextualAnalyticsData
			sourceType={SCREEN}
			sourceName="playbooksForIssuePanel"
			attributes={{
				isRuleOrchestrationRelated: true,
			}}
		>
			<FireScreenAnalytics />
			<RoundedSection
				triggerRef={triggerRef}
				onExpand={onPanelExpand}
				header={
					<Stack space="space.050">
						<Inline space="space.050" alignBlock="center">
							<Text weight="bold">{formatMessage(PlaybookPanelMessages.playbooks)}</Text>
							<Lozenge appearance="new">{formatMessage(PlaybookPanelMessages.new)}</Lozenge>
						</Inline>
						<Text color="color.text.subtlest">
							{formatMessage(
								fg('itsm-issue-renaming')
									? PlaybookPanelMessages.descriptionIssueRefresh
									: PlaybookPanelMessages.description,
							)}
						</Text>
					</Stack>
				}
			>
				<JiraEntryPointContainer
					entryPointReferenceSubject={entryPointReferenceSubject}
					id="agent-view-issue-playbooks-panel-body"
					packageName="jiraPlaybooksAgentView"
					teamName="ITOps Phantom"
					placeholderName="playbooks-for-issue-panel"
					runtimeProps={runtimeProps}
					fallback={<PlaybooksPanelLoadingView />}
					errorFallback={() => <PlaybooksPanelErrorView />}
				/>
			</RoundedSection>
		</ContextualAnalyticsData>
	);
};
