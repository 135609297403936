import { useEffect } from 'react';
import { useOverridesStoreActions } from '@atlassian/jira-issue-field-base/src/services/field-config-service/context.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

/*
 * The purpose of this hook is to make sure that overrides sent to issue-field-overrides store are not
 * applied for longer than they should be (e.g. for issue types without UIM registered).
 *
 * resetOverridesStore should be called in the following scenarios:
 * 1. after issue type change - only once (not on opening issue type select picker)
 * 2. after issueKey change (e.g. SPA transition to a different issue)
 * 3. when the UiModificationsEntryPoint component unmounts
 *
 * We need to check if issueType is defined to not call cleanup during the initialization of UIM
 * to make the code future-proof for any potential race-conditions.
 */
export const useOverridesStoreCleanup = ({
	issueType,
	issueKey,
}: {
	issueType?: {
		id: string;
		name: string;
	};
	issueKey: IssueKey;
}) => {
	const { resetOverridesStore } = useOverridesStoreActions();

	useEffect(
		() => () => {
			if (issueType?.id) {
				resetOverridesStore(issueKey);
			}
		},
		[resetOverridesStore, issueType?.id, issueKey],
	);

	return null;
};
