import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import cloneDeep from 'lodash/cloneDeep';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action, StoreActionApi } from '@atlassian/react-sweet-state';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { createIssueAri } from '../../../../common/utils/ari/index.tsx';
import {
	getLocalIssueIdForJiraIssueId,
	getLocalIssueIdToJiraId,
} from '../../selectors/issue-ids.tsx';
import { createGetPlatformGoalsValueSelector } from '../../selectors/properties/index.tsx';
import type { Props, State } from '../../types.tsx';
import type { GoalsFieldValue } from '../../utils/field-mapping/goals/index.tsx';

// Function to batch requests
// Atlas API supports 50 linking/unlinking relationships per request so we batch them
const BATCH_SIZE = 50;
const batchRequests = async (
	relationships: { from: string; to: string }[],
	requestFn: (batch: { relationships: { from: string; to: string }[] }) => Promise<void>,
) => {
	const batches = [];
	for (let i = 0; i < Math.ceil(relationships.length / BATCH_SIZE); i++) {
		const batch = requestFn({
			relationships: relationships.slice(i * BATCH_SIZE, (i + 1) * BATCH_SIZE),
		});
		batches.push(batch);
	}
	return Promise.all(batches);
};

export type UpdateIssueGoalsRequest = {
	fieldKey: FieldKey;
	issueId: string;
	goalsToLink?: GoalsFieldValue;
	goalsToUnlink?: GoalsFieldValue;
	skipNetworkRequest?: boolean;
	onError?: (error: Error) => void;
};

export const updateIssueGoals =
	({
		fieldKey,
		issueId,
		goalsToLink = [],
		goalsToUnlink = [],
		skipNetworkRequest,
		onError,
	}: UpdateIssueGoalsRequest): Action<State, Props> =>
	async ({ getState, setState }: StoreActionApi<State>, props: Props) => {
		if (!goalsToLink.length && !goalsToUnlink.length) {
			return;
		}

		const state = getState();
		const { issuesRemote, onIssueUpdateFailed, cloudId } = props;

		const localIssueId = getLocalIssueIdForJiraIssueId(issueId)(state, props);
		const goals = createGetPlatformGoalsValueSelector(fieldKey, localIssueId)(state, props);

		const currentGoals = goals || [];

		// Add new goals (merge unique values)
		const newGoals = Array.from(new Set([...currentGoals, ...goalsToLink]));
		// Remove goals to unlink
		const updatedGoals = newGoals.filter((goal) => !goalsToUnlink.includes(goal));

		const issueAri = createIssueAri(cloudId, issueId);

		// Optimistic update only for goals property
		const previousProperties = { ...getState().properties };

		setState({
			...state,
			properties: {
				...state.properties,
				goals: {
					...state.properties.goals,
					[fieldKey]: {
						...state.properties.goals?.[fieldKey],
						[localIssueId]: updatedGoals,
					},
				},
			},
			lastUpdatedIssueIds: [localIssueId],
		});

		if (skipNetworkRequest) {
			return;
		}

		try {
			const promises = [];
			if (goalsToLink.length) {
				const linkRelationships = goalsToLink.map((goal) => ({ from: issueAri, to: goal }));
				promises.push(batchRequests(linkRelationships, issuesRemote.linkGoals));
			}
			if (goalsToUnlink.length) {
				const unlinkRelationships = goalsToUnlink.map((goal) => ({ from: issueAri, to: goal }));
				promises.push(batchRequests(unlinkRelationships, issuesRemote.unlinkGoals));
			}

			await Promise.all(promises);
		} catch (error) {
			// Revert optimistic update only for goals property
			setState({ properties: previousProperties });

			if (error instanceof Error) {
				onIssueUpdateFailed(error);
				onError?.(error);
			}
		}
	};

type UpdateIssueGoalsBulkMap = Map<
	LocalIssueId,
	{
		goalsToLink: string[];
		goalsToUnlink: string[];
	}
>;

export const updateIssueGoalsBulk =
	(fieldKey: FieldKey, issueGoalsMap: UpdateIssueGoalsBulkMap): Action<State, Props> =>
	async ({ setState, getState }, props) => {
		if (issueGoalsMap.size === 0) {
			return;
		}

		const state = getState();
		const { issuesRemote, onIssueUpdateFailed, cloudId } = props;
		const jiraIdMap = getLocalIssueIdToJiraId(getState(), props);

		// Create relationships for all issues
		const linkRelationships = Array.from(issueGoalsMap.entries()).flatMap(
			([localIssueId, { goalsToLink }]) => {
				if (!goalsToLink.length) return [];
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const issueId = jiraIdMap[localIssueId] as IssueId;
				const issueAri = createIssueAri(cloudId, issueId);
				return goalsToLink.map((goal) => ({ from: issueAri, to: goal }));
			},
		);

		const unlinkRelationships = Array.from(issueGoalsMap.entries()).flatMap(
			([localIssueId, { goalsToUnlink }]) => {
				if (!goalsToUnlink.length) return [];
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const issueId = jiraIdMap[localIssueId] as IssueId;
				const issueAri = createIssueAri(cloudId, issueId);
				return goalsToUnlink.map((goal) => ({ from: issueAri, to: goal }));
			},
		);

		const previousProperties = cloneDeep(state.properties);
		const updatedGoalsState = { ...state.properties.goals?.[fieldKey] };

		for (const [localIssueId, { goalsToLink, goalsToUnlink }] of issueGoalsMap) {
			const currentGoals = state.properties.goals?.[fieldKey]?.[localIssueId] || [];
			const newGoals = Array.from(new Set([...currentGoals, ...goalsToLink]));
			updatedGoalsState[localIssueId] = newGoals.filter((goal) => !goalsToUnlink.includes(goal));
		}

		setState({
			...state,
			properties: {
				...state.properties,
				goals: {
					...state.properties.goals,
					[fieldKey]: updatedGoalsState,
				},
			},
			lastUpdatedIssueIds: Array.from(issueGoalsMap.keys()),
		});

		try {
			const promises = [];
			if (linkRelationships.length) {
				promises.push(batchRequests(linkRelationships, issuesRemote.linkGoals));
			}
			if (unlinkRelationships.length) {
				promises.push(batchRequests(unlinkRelationships, issuesRemote.unlinkGoals));
			}
			await Promise.all(promises);
		} catch (error) {
			// Revert optimistic update only for goals property
			setState({ properties: previousProperties });

			if (error instanceof Error) {
				onIssueUpdateFailed(error);
			}
		}
	};

type UpdateGoalsFieldValueRequest = {
	fieldKey: FieldKey;
	localIssueIds: LocalIssueId[];
	newValue: unknown | undefined;
	removeValue?: unknown | undefined;
	appendMultiValues?: boolean;
	skipNetworkRequest?: boolean;
	onError?: (error: Error) => void;
};

export const updateGoalsFieldValue =
	({
		fieldKey,
		localIssueIds,
		newValue,
		removeValue,
		appendMultiValues,
		skipNetworkRequest = false,
		onError,
	}: UpdateGoalsFieldValueRequest): Action<State, Props> =>
	({ getState, dispatch }, props) => {
		if (localIssueIds.length === 0) {
			return;
		}

		const state = getState();
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const newGoalsValue = (newValue || []) as string[];
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const removeGoalsValue = (removeValue || []) as string[];

		const updateIssueGoalsBulkRequest = localIssueIds.reduce<UpdateIssueGoalsBulkMap>(
			(acc, localIssueId) => {
				const currentGoals =
					createGetPlatformGoalsValueSelector(fieldKey, localIssueId)(state, props) || [];

				const goalsToLink = difference(newGoalsValue, currentGoals);
				const goalsToUnlink = appendMultiValues
					? intersection(removeGoalsValue, currentGoals)
					: difference(currentGoals, newGoalsValue);

				acc.set(localIssueId, { goalsToLink, goalsToUnlink });

				return acc;
			},
			new Map(),
		);

		if (localIssueIds.length === 1) {
			const localIssueId = localIssueIds[0];
			const jiraIdMap = getLocalIssueIdToJiraId(state, props);
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const issueId = jiraIdMap[localIssueId] as IssueId;
			dispatch(
				updateIssueGoals({
					fieldKey,
					issueId,
					goalsToLink: updateIssueGoalsBulkRequest.get(localIssueId)?.goalsToLink,
					goalsToUnlink: updateIssueGoalsBulkRequest.get(localIssueId)?.goalsToUnlink,
					skipNetworkRequest,
					onError,
				}),
			);
		} else {
			dispatch(updateIssueGoalsBulk(fieldKey, updateIssueGoalsBulkRequest));
		}
	};
