/**
 * @generated SignedSource<<4a459a685d953c058b5f9be77551a667>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment$data = {
  readonly node: {
    readonly fieldOperations?: {
      readonly canEdit: boolean | null | undefined;
    } | null | undefined;
    readonly fieldOptions?: {
      readonly totalCount: number | null | undefined;
    } | null | undefined;
    readonly selectableValueOptions?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly color?: {
            readonly colorKey: string | null | undefined;
          } | null | undefined;
          readonly id: string;
          readonly isDisabled?: boolean | null | undefined;
          readonly optionId?: string;
          readonly selectableGroupKey: string | null | undefined;
          readonly selectableIconUrl: AGG$URL | null | undefined;
          readonly selectableLabel: string | null | undefined;
          readonly value?: string | null | undefined;
          readonly versionId?: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"ufoDataFragment_issueSelectableFieldEditView_UFOCustomDataFragment">;
  } | null | undefined;
  readonly " $fragmentType": "ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment";
};
export type ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment$key = {
  readonly " $data"?: ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment">;
};

import selectableFieldSearchRefetchQuery_graphql from './selectableFieldSearchRefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "node",
  "selectableValueOptions"
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterById"
    },
    {
      "defaultValue": 20,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "id"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "searchBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": selectableFieldSearchRefetchQuery_graphql
    }
  },
  "name": "ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "id"
        }
      ],
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ufoDataFragment_issueSelectableFieldEditView_UFOCustomDataFragment"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "concreteType": "JiraFieldOperation",
              "kind": "LinkedField",
              "name": "fieldOperations",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "canEdit"
                }
              ]
            },
            {
              "concreteType": "JiraOptionConnection",
              "kind": "LinkedField",
              "name": "fieldOptions",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "totalCount"
                }
              ]
            }
          ],
          "type": "JiraSingleSelectField"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "selectableValueOptions",
              "args": [
                {
                  "kind": "Variable",
                  "name": "filterById",
                  "variableName": "filterById"
                },
                {
                  "kind": "Variable",
                  "name": "searchBy",
                  "variableName": "searchBy"
                }
              ],
              "concreteType": "JiraSelectableValueConnection",
              "kind": "LinkedField",
              "name": "__selectableValue_issueFieldEditviewFull_fieldOptions__selectableValueOptions_connection",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraSelectableValueEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "name": "id"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "selectableLabel"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "selectableGroupKey"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "selectableIconUrl"
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "name": "isDisabled"
                            },
                            {
                              "kind": "ScalarField",
                              "name": "optionId"
                            },
                            {
                              "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider",
                              "kind": "Condition",
                              "passingValue": true,
                              "selections": [
                                {
                                  "concreteType": "JiraColor",
                                  "kind": "LinkedField",
                                  "name": "color",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "kind": "ScalarField",
                                      "name": "colorKey"
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              "kind": "ScalarField",
                              "name": "value"
                            }
                          ],
                          "type": "JiraOption"
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "name": "versionId"
                            }
                          ],
                          "type": "JiraVersion"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "__typename"
                        }
                      ]
                    },
                    {
                      "kind": "ScalarField",
                      "name": "cursor"
                    }
                  ]
                },
                {
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "endCursor"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "hasNextPage"
                    }
                  ]
                }
              ]
            }
          ],
          "type": "JiraHasSelectableValueOptions",
          "abstractKey": "__isJiraHasSelectableValueOptions"
        }
      ]
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "ea4f7acb23c995d8f293a1dae069443f";

export default node;
