import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

const OriginalEstimateEditViewWithFormattedValueEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "async-original-estimate-edit-view-with-formatted-value" */ './index.tsx'
			),
	),
	getPreloadProps: () => ({}),
});

export default OriginalEstimateEditViewWithFormattedValueEntryPoint;
