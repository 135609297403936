import React from 'react';
import Button from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import RefreshIcon from '@atlaskit/icon/core/migration/refresh';
import { Box, Flex } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';

import { getBaseAutomationUrl } from '@atlassian/jira-automation-platform/src/common/utils.tsx';
import { defineMessages, useIntl } from '@atlassian/jira-intl';

import { useIssueKey, useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	useProjectKey,
	useIsSimplifiedProject,
	useProjectType,
	useProjectId,
} from '@atlassian/jira-project-context-service/src/main.tsx';

import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useEnvironment } from '@atlassian/jira-tenant-context-controller/src/components/environment/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import useAuditItems from '../services/use-audit-items/index.tsx';
import RecentRuleRuns from './recent-rule-runs/index.tsx';

const messages = defineMessages({
	recentRuleRunsTitle: {
		id: 'automation-issue-audit-log-panel.automation-issue-audit-log-body.recent-rule-runs-title',
		defaultMessage: 'Recent rule runs',
		description:
			"Title text that appears at the top of the panel. It's what the panel is about: showing recent rule runs.",
	},
	refreshButtonText: {
		id: 'automation-issue-audit-log-panel.automation-issue-audit-log-body.refresh-button-text',
		defaultMessage: 'Refresh',
		description:
			'Text for the refresh button. This button is used to refresh the list of recent rule runs. It essentially fetches the latest data.',
	},
});

const AutomationIssueAuditLogBody: React.FC = () => {
	const { formatMessage } = useIntl();

	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);

	const env = useEnvironment();
	const cloudId = useCloudId();
	const projectId = useProjectId(projectKey);
	const issueId = useIssueId();

	const [permissions] = useProjectPermissions(projectKey);
	const projectType = useProjectType(projectKey);
	const isSimplified = useIsSimplifiedProject(projectKey);

	const [auditItemsResult, fetchAuditItems] = useAuditItems(env, cloudId, projectId, issueId);

	const manageRuleUrl = `${getBaseAutomationUrl({
		projectKey,
		projectType,
		isSimplified,
	})}#/rule`;

	const projectAdminDetails = {
		isProjectAdmin: permissions.canAdministerProject || permissions.canAdministerJira,
		manageRuleUrl,
	};

	return (
		<Box
			paddingBlockStart="space.150"
			testId="automation-issue-audit-log.ui.automation-issue-audit-log-body"
		>
			<Flex justifyContent="space-between" alignItems="center">
				<Heading size="xsmall" as={fg('ux-2931-a11y-automation-fix') ? 'h2' : undefined}>
					{formatMessage(messages.recentRuleRunsTitle)}
				</Heading>
				<Button
					appearance="subtle"
					iconBefore={(iconProps) =>
						auditItemsResult.status === 'loading' ? (
							<Spinner
								size="small"
								interactionName="automation-issue-audit-log-panel.refreshing-audit-items"
							/>
						) : (
							<RefreshIcon LEGACY_size="small" {...iconProps} />
						)
					}
					onClick={fetchAuditItems}
					isDisabled={auditItemsResult.status === 'loading'}
				>
					{formatMessage(messages.refreshButtonText)}
				</Button>
			</Flex>
			<RecentRuleRuns auditItems={auditItemsResult} projectAdminDetails={projectAdminDetails} />
		</Box>
	);
};

export default AutomationIssueAuditLogBody;
