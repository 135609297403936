import type { ReactNode } from 'react';

import type { GlyphProps, NewCoreIconProps } from '@atlaskit/icon';
import type { MessageDescriptor } from '@atlassian/jira-intl';

type IconFunction = (props: GlyphProps | NewCoreIconProps) => ReactNode;

export const RuleParts = {
	trigger: 'trigger',
	action: 'action',
	condition: 'condition',
	branch: 'branch',
} as const;

export type RulePartsKeys = (typeof RuleParts)[keyof typeof RuleParts];

export type ComponentResponse = {
	type: RulePartsKeys;
	text: MessageDescriptor;
	icon: IconFunction;
	iconLabel?: MessageDescriptor;
};

export type MappedTypeResponse = {
	info: {
		footer: MessageDescriptor;
		footerNonAdmin: MessageDescriptor;
		learnMoreLink: string;
		learnMoreText: MessageDescriptor;
		savedMinutes: number;
	};
	components: ComponentResponse[];
	id?: string;
};

export type MappedTypeResponseWithHeader = {
	info: {
		header: MessageDescriptor;
	};
	id?: string;
};

export type MappedType<Key extends PropertyKey, ValueType> = Record<Key, ValueType>;

export type Container = 'alert' | 'issue';

export type FetchError =
	| {
			status: number;
			statusText: string;
	  }
	| undefined;

export type DismissStateData = {
	[key: string]: number;
};

export const ALERT_MAP_KEYS = {
	itsm_template_19: 'itsm_template_19',
	itsm_template_19_newrelic: 'itsm_template_19_newrelic',
	itsm_template_23: 'itsm_template_23',
	itsm_template_27: 'itsm_template_27',
	itsm_template_29: 'itsm_template_29',
	default: 'default',
} as const;

export const ISSUE_MAP_KEYS = {
	itsm_template_18: 'itsm_template_18',
	itsm_template_20: 'itsm_template_20',
	itsm_template_21: 'itsm_template_21',
	itsm_template_24: 'itsm_template_24',
	itsm_template_25: 'itsm_template_25',
	itsm_template_26: 'itsm_template_26',
	default: 'default',
} as const;

export type TemplateMapProps =
	| {
			/**
			 * Id of template for which details need to be shown
			 */
			templateId: keyof typeof ISSUE_MAP_KEYS;
			/**
			 * Type on which this component is mounted
			 */
			type: 'issue';
	  }
	| {
			templateId: keyof typeof ALERT_MAP_KEYS;
			type: 'alert';
	  };

export type DismissTemplate = { templateId: string; dismissUntilDateTime: string };
export type DismissData = DismissTemplate[] | null;
