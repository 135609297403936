import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { getAnalyticsAttributesFromExtension } from '@atlassian/jira-forge-ui-analytics/src/common/utils/get-analytics-attributes-from-extension/index.tsx';
import { ViewAnalyticsWrapper } from '@atlassian/jira-forge-issue-activity/src/analytics/ui/index.tsx';
import { AsyncIssueActivity } from '@atlassian/jira-forge-issue-activity/src/ui/async/AsyncIssueActivity.tsx';
import type { ForgeUiIssueData } from '@atlassian/jira-forge-ui-types/src/common/types/extension-data.tsx';
import type { IssueActivity } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import { useOnDemandIssueRefreshTime } from '@atlassian/jira-issue-refresh-service/src/services/main.tsx';

type Props = {
	source: string | undefined;
	extensionData: ForgeUiIssueData;
	extension: IssueActivity;
};

export const ForgeIssueActivity = ({ source, extension, extensionData }: Props) => (
	<Padding>
		<AsyncIssueActivity
			extension={extension}
			extensionData={extensionData}
			analyticsAttributes={{ source }}
		/>
	</Padding>
);

export const ForgeIssueActivityWithAnalytics = (props: Props) => {
	const refreshedOn = useOnDemandIssueRefreshTime();

	return (
		<ViewAnalyticsWrapper
			key={refreshedOn}
			attributes={{
				source: props.source,
				...getAnalyticsAttributesFromExtension(props.extension),
			}}
		>
			<ForgeIssueActivity {...props} />
		</ViewAnalyticsWrapper>
	);
};

export default ForgeIssueActivityWithAnalytics;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Padding = styled.div({
	marginTop: token('space.300'),
});
Padding.displayName = 'Padding';
