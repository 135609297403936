import React, { useEffect, useState, useCallback, memo, useMemo, type ChangeEvent } from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled, { css } from 'styled-components';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { PRETTY } from '@atlassian/jira-common-constants/src/jira-settings.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { ErrorFlag } from '@atlassian/jira-issue-error-flag/src/index.tsx';
import {
	// eslint-disable-next-line jira/styled/no-styled-import-alias
	InlineEditContainer as InlineEditContainerStyled,
	// eslint-disable-next-line jira/styled/no-styled-import-alias
	ReadViewContainer as BaseReadViewContainer,
} from '@atlassian/jira-issue-field-inline-edit/src/styled.tsx';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import {
	parseTimeString,
	isValidTimeString,
} from '@atlassian/jira-issue-format-time/src/index.tsx';
import { TIME_ESTIMATE_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { toIssueKey, toIssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { timeTrackingFormatter } from '@atlassian/jira-time-tracking-formatter/src/main.tsx';
import messages from '../messages.tsx';
import { useOriginalEstimateField } from '../services/index.tsx';
import { OriginalEstimateEdit } from './edit/index.tsx';
import type { Props, OriginalEstimateInlineEditContainerProps } from './types.tsx';
import { OriginalEstimateView } from './view/index.tsx';

export const actionSubject = 'originalEstimateInlineEdit';

export default memo<Props>((props: Props) => {
	const {
		isMobile,
		config,
		editView,
		fieldId,
		issueId,
		issueKey,
		onUpdate,
		onFailure,
		onSubmit,
		placeholder,
		readView,
		timeTrackingValue,
		invalidTimeFormatMessage,
		onCancel,
		onConfirm,
		onEdit,
		onEnter,
		onEscape,
		isBacklogComponent = false,
		...rest
	} = props;

	const [{ value: initialValue, error, fieldConfig }, { saveValue, resetError }] =
		useOriginalEstimateField({
			fieldId,
			issueId: issueId && toIssueId(issueId),
			issueKey: toIssueKey(issueKey),
			onSuccess: onUpdate,
			onFailure,
			onSubmit,
			timeTrackingValue,
		});

	const [isEditing, setIsEditing] = useState<boolean>(false);
	// Set Initial Value to prevent the value from displaying 0m
	const [timeValue, setTimeValue] = useState<unknown>(initialValue);
	const [inputTime, setInputTime] = useState<string>('');
	const [isInvalidInput, setIsInvalidInput] = useState(false);

	const intl = useIntl();

	const timeString = useMemo(() => {
		// At this time we need to force the value into an english formatted
		// time string eg: `1w 1d 1h 1m` as that is the only format supported
		// on entry, future changes will support localised values.
		const intlForTimeTrackingInput = {
			...intl,
			// @ts-expect-error - TS7031 - Binding element 'defaultMessage' implicitly has an 'any' type.
			formatMessage: ({ defaultMessage }) => defaultMessage || '',
		};
		return timeTrackingFormatter(
			// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'number'.
			timeValue || 0,
			{
				workingHoursPerDay: config.hoursPerDay,
				workingDaysPerWeek: config.daysPerWeek,
				timeFormat: config.format || PRETTY,
				defaultUnit: config.defaultUnit,
			},
			intlForTimeTrackingInput,
		);
	}, [config, intl, timeValue]);

	const readTimeString = useMemo(
		() =>
			timeTrackingFormatter(
				// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'number'.
				timeValue || 0,
				{
					workingHoursPerDay: config.hoursPerDay,
					workingDaysPerWeek: config.daysPerWeek,
					timeFormat: config.format || PRETTY,
					defaultUnit: config.defaultUnit,
				},
				intl,
			),
		[config, intl, timeValue],
	);

	useEffect(() => {
		setInputTime(timeString);
	}, [timeString]);

	useEffect(() => {
		setTimeValue(initialValue);
	}, [initialValue]);

	useEffect(() => {
		if (error) {
			setIsEditing(true);
		}
	}, [error]);

	const isFieldEditable = fieldConfig?.isEditable ?? false;

	const save = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			if (inputTime === timeString) {
				setIsEditing(false);
				return;
			}

			if (isValidTimeString(config)(inputTime)) {
				const newTimeValue = parseTimeString(config)(inputTime);
				saveValue(newTimeValue, null, analyticsEvent);
				fireUIAnalytics(analyticsEvent);
				setIsInvalidInput(false);
				setIsEditing(false);
			} else {
				setIsInvalidInput(true);
				setIsEditing(true);
			}
		},
		[inputTime, timeString, config, saveValue],
	);

	const cancel = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			resetError();
			setIsInvalidInput(false);
			setIsEditing(false);
			setInputTime(timeString);
			setTimeValue(initialValue);
			fireUIAnalytics(analyticsEvent);
		},
		[initialValue, resetError, timeString],
	);

	const onCancelRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			cancel(analyticsEvent);
			onCancel?.(analyticsEvent);
		},
		[onCancel, cancel],
	);

	const onConfirmRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			save(analyticsEvent);
			onConfirm?.(analyticsEvent);
		},
		[save, onConfirm],
	);

	const onEditRequested = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			setIsEditing(true);
			fireUIAnalytics(analyticsEvent);
			onEdit?.(analyticsEvent);
		},
		[onEdit],
	);

	const onEscapeRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			cancel(analyticsEvent);
			onEscape?.(analyticsEvent);
		},
		[onEscape, cancel],
	);

	const onChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const { value: newStringValue } = event.target;

			setIsInvalidInput(false);
			resetError();
			setInputTime(newStringValue);
		},
		[resetError, setInputTime],
	);

	const renderReadView = useCallback(() => {
		const view = <OriginalEstimateView value={readTimeString} isEditable={isFieldEditable} />;

		if (!isBacklogComponent || !isFieldEditable) {
			return (
				<ReadViewContainer isEditable={isFieldEditable} compactMarginTop={isBacklogComponent}>
					{view}
				</ReadViewContainer>
			);
		}

		return view;
	}, [isFieldEditable, readTimeString, isBacklogComponent]);

	const renderEditView = useCallback(
		() => (
			<Box xcss={originalEstimateEditContainerStyles}>
				<OriginalEstimateEdit
					isInvalidInput={isInvalidInput}
					invalidTimeFormatMessage={invalidTimeFormatMessage}
					placeholder={placeholder}
					value={inputTime}
					onChange={onChange}
					isCompact={isBacklogComponent}
				/>
			</Box>
		),
		[
			inputTime,
			invalidTimeFormatMessage,
			isInvalidInput,
			onChange,
			placeholder,
			isBacklogComponent,
		],
	);
	const renderErrorFlag = () => {
		if (!error) return null;

		return (
			<ErrorFlag error={error} title={messages.errorTitle} description={messages.errorMessage} />
		);
	};

	const InlineEditContainer = isBacklogComponent
		? BacklogStylesWrapper
		: OriginalEstimateInlineEditContainer;

	return (
		<>
			{error && renderErrorFlag()}
			{/* @ts-expect-error - TS2322 - Type '{ children: Element; isEditing: boolean; isEditable: boolean; }' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<Component<ThemedOuterStyledProps<ClassAttributes<HTMLDivElement> & HTMLAttributes<HTMLDivElement> & { ...; }, any>, any, any> | Component<...>> & Readonly<...> & Readonly<...> & Readonly<...> & Readonly<...>'. */}
			<InlineEditContainer isEditing={isEditing} isEditable={isFieldEditable}>
				<FieldInlineEditStateLessContainer>
					<FieldInlineEditStateLess
						actionSubject={actionSubject}
						fieldId={fg('one_event_rules_them_all_fg') ? fieldId : undefined}
						isEditing={isEditing}
						readView={renderReadView()}
						editView={isFieldEditable ? renderEditView() : null}
						onCancel={onCancelRequest}
						onConfirm={onConfirmRequest}
						onEdit={onEditRequested}
						onEscape={onEscapeRequest}
						{...rest}
						isEditable={isFieldEditable}
						{...(fg('one_event_rules_them_all_fg') && {
							componentAnalyticsData: { fieldType: TIME_ESTIMATE_TYPE },
						})}
					/>
				</FieldInlineEditStateLessContainer>
			</InlineEditContainer>
		</>
	);
});

const backlogStylesReverseMarginTop = '-1px';

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ReadViewContainer = styled(BaseReadViewContainer)`
	${({ isEditable, compactTopMargin }) =>
		!isEditable
			? css({
					paddingRight: token('space.025'),
					marginRight: token('space.050'),
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
					marginTop: compactTopMargin ? token('space.negative.100') : undefined,
				})
			: null}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FieldInlineEditStateLessContainer = styled2.div({
	paddingRight: token('space.050'),
});

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line  @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const BacklogStylesWrapper = styled.div<{ isEditable: boolean }>`
	& form > div {
		margin-top: 0;
	}

	${'' /* Prevent width increase before edit container renders */}
	${ReadViewContainer} {
		left: 0;
		padding-right: 0;
		margin-right: 0;
	}

	${'' /* Input focus style fix */}
	& div[data-read-view-fit-container-width] {
		background: transparent !important;
		border: none !important;
		vertical-align: top;
	}

	& button[aria-label='Edit']:focus + div > div:first-child {
		border: 2px solid ${token('color.border.focused')};
		border-radius: token('border.radius.300', '12px');
		padding: ${token('space.025')};
		margin-top: -10px;
		margin-bottom: -10px;
		${
			'' /* PX values as these are to negate the focus outline offset (implemented as a 2px border) */
		}
		margin-left: -2px;
		margin-right: -6px;
	}

	${({ isEditable }) =>
		!isEditable
			? css({
					// targets the non-editable wrapper for backlog :S
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
					'& > div': {
						marginTop: 0,
					},
					// NonEditableMargin overrides
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
					'& > div > div': {
						margin: `${backlogStylesReverseMarginTop} 0 0`,
					},
				})
			: null}
`;

// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression
const nonEditableStyles = css`
	margin-left: ${token('space.negative.025')};

	${'' /* NonEditableMargin overrides */}
	& > div {
		margin-left: 0;
	}

	${ReadViewContainer} {
		line-height: 1;
		left: 0;
		padding: 3px 0px 3px 0px;
	}
`;

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const OriginalEstimateInlineEditContainer = styled<OriginalEstimateInlineEditContainerProps>(
	// @ts-expect-error - TS2345 - Argument of type 'StyledComponentClass'
	InlineEditContainerStyled,
)`
	${({ isEditable }) => !isEditable && nonEditableStyles}
`;

const originalEstimateEditContainerStyles = xcss({
	paddingLeft: 'space.075',
});
