import { useState } from 'react';

export const useHoverState = (): [
	boolean,
	{
		onMouseOver: () => void;
		onFocus: () => void;
		onMouseOut: () => void;
		onBlur: () => void;
	},
] => {
	const [isHovered, setIsHovered] = useState(false);

	const onMouseOver = () => {
		setIsHovered(true);
	};

	const onMouseOut = () => {
		setIsHovered(false);
	};

	const onFocus = () => {
		setIsHovered(true);
	};

	const onBlur = () => {
		setIsHovered(false);
	};

	return [
		isHovered,
		{
			onMouseOver,
			onMouseOut,
			onFocus,
			onBlur,
		},
	];
};
