import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import CopyIcon from '@atlaskit/icon/core/copy';
import Lozenge from '@atlaskit/lozenge';
import { LinkItem } from '@atlaskit/menu';
import { Box, Inline } from '@atlaskit/primitives';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import Toggle from '@atlaskit/toggle';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { MenuIcon } from '@atlassian/automation-manual-triggers';
import {
	SOFTWARE_PROJECT,
	projectTypeToCanonicalId,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { useFlagsService, toFlagId } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import {
	fireTrackAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import {
	useIsSimplifiedProject,
	useProjectId,
	useProjectType,
	useProjectKey,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { DISABLED, ENABLED, MISSING_PERMS, PACKAGE_NAME, TEAM_NAME } from '../common/constants.tsx';
import { useFetchRule, type RuleState } from './hooks/use-fetch-rule.tsx';
import { useSetNonRecurringIssue } from './hooks/use-set-non-recurring-issue.tsx';
import { useSetRecurringIssue } from './hooks/use-set-recurring-issue.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const flagId = toFlagId('jira-recurring-work-toggle-success-flag');

const RecurringIssueLinkItem = ({ issueKey }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const ruleIdRef = useRef<number | string | null>(null);
	const [isChecked, setIsChecked] = useState(false);
	const { showFlag, dismissFlag } = useFlagsService();
	const hasMountedRef = useRef(false);

	const projectKey = useProjectKey(issueKey);
	const projectId = useProjectId(projectKey);
	const projectType = useProjectType(projectKey);
	const isSimplified = useIsSimplifiedProject(projectKey);
	const productKey = projectType && projectTypeToCanonicalId(projectType)?.replace('jira-', '');

	if (!projectId) {
		throw new Error('Could not retrieve projectId');
	}

	// We need to memoize `analyticsEvent` otherwise it triggers the `useEffect` hooks below on mount
	const { analyticsEvent, analyticsAttributes } = useMemo(() => {
		return {
			analyticsEvent: createAnalyticsEvent({}),
			analyticsAttributes: {
				issueKey,
			},
		};
	}, [createAnalyticsEvent, issueKey]);

	const { data: existingRuleData, loading: isFetchingRuleConfig } = useFetchRule(
		issueKey,
		projectId,
	);
	const { loading: isCreateInProgress, fetch: sendSetAsRecurringRequest } = useSetRecurringIssue(
		issueKey,
		projectId,
	);
	const { loading: isDeleteInProgress, fetch: sendSetAsNonRecurringRequest } =
		useSetNonRecurringIssue(issueKey, projectId);

	const onToggleChange = () => {
		setIsChecked(!isChecked);
		fireUIAnalytics(analyticsEvent, 'recurringIssue toggled', {
			...analyticsAttributes,
			actionTaken: isChecked ? 'disabled' : 'enabled',
			action: isChecked ? 'disabled' : 'enabled',
		});
	};

	const onClickSuccessFlagAction = useCallback(() => {
		dismissFlag(flagId);
		let automationRuleURL;
		if (projectType === SOFTWARE_PROJECT) {
			automationRuleURL = `/jira/${productKey}/${isSimplified ? '' : 'c/'}projects/${projectKey}/settings/automate#/rule/${ruleIdRef.current}`;
		} else {
			automationRuleURL = `/jira/${productKey}/projects/${projectKey}/settings/automation#/rule/${ruleIdRef.current}`;
		}
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.open(automationRuleURL, '_blank');
	}, [dismissFlag, isSimplified, productKey, projectKey, projectType]);

	useEffect(() => {
		if (!isFetchingRuleConfig && existingRuleData !== undefined && !hasMountedRef.current) {
			fireTrackAnalytics(analyticsEvent, 'recurringIssueMenuItem viewed', analyticsAttributes);
			hasMountedRef.current = true;
		}
	}, [analyticsAttributes, analyticsEvent, existingRuleData, isFetchingRuleConfig]);

	// Create recurring rule when toggle is enabled
	useEffect(() => {
		if (isCreateInProgress) {
			return;
		}

		const createTemplateFn = async () => {
			const response = await sendSetAsRecurringRequest();
			ruleIdRef.current = response?.ruleId ?? null;

			if (response === undefined) {
				setIsChecked(false);
				return;
			}

			showFlag({
				messageId: 'recurring-issue-action.ui.show-flag.success',
				messageType: 'transactional',
				id: flagId,
				type: 'success',
				title: formatMessage(messages.successFlagTitle, { issueKey }),
				description: messages.successFlagContent,
				actions: [
					{
						content: messages.successFlagAction,
						onClick: onClickSuccessFlagAction,
					},
				],
			});

			fireTrackAnalytics(analyticsEvent, 'recurringRule created', {
				...analyticsAttributes,
				ruleId: ruleIdRef.current,
			});
		};

		if (!ruleIdRef.current && isChecked) {
			createTemplateFn();
		}
	}, [
		analyticsAttributes,
		analyticsEvent,
		formatMessage,
		isChecked,
		isCreateInProgress,
		issueKey,
		onClickSuccessFlagAction,
		sendSetAsRecurringRequest,
		showFlag,
	]);

	// Delete recurring rule when toggle is disabled
	useEffect(() => {
		if (isDeleteInProgress) {
			return;
		}

		const deleteTemplateFn = async () => {
			if (typeof ruleIdRef.current !== 'number' && typeof ruleIdRef.current !== 'string') {
				return;
			}
			const response = await sendSetAsNonRecurringRequest({
				ruleId: ruleIdRef.current,
			});

			if (response === undefined) {
				setIsChecked(true);
				return;
			}
			fireTrackAnalytics(analyticsEvent, 'recurringRule deleted', {
				...analyticsAttributes,
				ruleId: ruleIdRef.current,
			});
			ruleIdRef.current = null;
		};

		// We need this condition to avoid unnecessary calls when the hook runs
		if (ruleIdRef.current && !isChecked) {
			deleteTemplateFn();
		}
	}, [
		analyticsAttributes,
		analyticsEvent,
		isChecked,
		isDeleteInProgress,
		sendSetAsNonRecurringRequest,
	]);

	// Setting initial toggle state based on first fetch
	useEffect(() => {
		ruleIdRef.current = existingRuleData?.ruleId || null;
		const ruleState = existingRuleData?.ruleState || null;

		const hasExistingRule = !isFetchingRuleConfig && !!ruleIdRef.current;
		const isToggleChecked = ruleState !== DISABLED && hasExistingRule;
		setIsChecked(isToggleChecked);
	}, [existingRuleData?.ruleId, existingRuleData?.ruleState, isFetchingRuleConfig]);

	const getTooltipContent = (ruleState: RuleState | null) => {
		switch (ruleState) {
			case DISABLED:
				return formatMessage(messages.ruleDisabledTooltip);
			case MISSING_PERMS:
				return formatMessage(messages.missingDeletePermissionTooltip);
			case ENABLED:
			default:
				return '';
		}
	};

	const ruleState = existingRuleData?.ruleState || null;

	return (
		<LinkItem
			testId="recurring-issue-action.ui.set-recurring"
			iconBefore={
				<MenuIcon>
					<CopyIcon
						label={formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.menuItemTextIssueTermRefresh
								: messages.menuItemText,
						)}
					/>
				</MenuIcon>
			}
		>
			<Inline as="span" alignBlock="center" spread="space-between">
				<Inline alignBlock="center" space="space.075">
					<label htmlFor="recurring-issue-menu-item-label">
						{formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.menuItemTextIssueTermRefresh
								: messages.menuItemText,
						)}
					</label>
					<Lozenge appearance="new">{formatMessage(messages.newLozenge)}</Lozenge>
					<UFOLoadHold
						name="fetch-recurring-issue-rule-config-loading"
						hold={isFetchingRuleConfig}
					/>
				</Inline>
				<Tooltip content={getTooltipContent(ruleState)}>
					<Box>
						<Toggle
							id="recurring-issue-menu-item-label"
							isChecked={isChecked}
							isDisabled={
								ruleState === DISABLED ||
								ruleState === MISSING_PERMS ||
								isFetchingRuleConfig ||
								isCreateInProgress ||
								isDeleteInProgress
							}
							onChange={onToggleChange}
						/>
					</Box>
				</Tooltip>
			</Inline>
		</LinkItem>
	);
};

const RecurringIssueLinkItemWithErrorBoundary = ({ issueKey }: Props) => {
	return (
		<UFOSegment name="recurring-issue-link-item">
			<JSErrorBoundary
				id="recurring-issue-link-item-wrapper"
				packageName={PACKAGE_NAME}
				teamName={TEAM_NAME}
				fallback="flag"
			>
				<RecurringIssueLinkItem issueKey={issueKey} />
			</JSErrorBoundary>
		</UFOSegment>
	);
};

export { RecurringIssueLinkItemWithErrorBoundary as RecurringIssueLinkItem };
