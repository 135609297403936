import React from 'react';
import debounce from 'lodash/debounce';
import memoizeOne from 'memoize-one';
import PeopleGroupIcon from '@atlaskit/icon/core/migration/people-group';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { IntlShapeV2 } from '@atlassian/jira-intl/src/v2/types.tsx';
import {
	OptionContainer,
	OptionLabel,
} from '@atlassian/jira-issue-internal-field-select/src/common/styled.tsx';
import MultiSelectInlineEditView, {
	type Props,
} from '@atlassian/jira-issue-internal-field-select/src/multi-select-inline-edit/index.tsx';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages.tsx';
import type { SaveFieldArguments } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field.tsx';
import { updateOrganization } from '@atlassian/jira-issue-view-services/src/issue/organization-server.tsx';
import { fieldAutoCompleteUrlSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import messages from './messages.tsx';
import {
	transformFromStateValue,
	transformToStateValue,
	transformSuggestions,
} from './transformer.tsx';

const createSuccessEvent = ({ isSearchStringEmpty }: { isSearchStringEmpty: boolean }) => ({
	source: 'issueView',
	action: 'succeeded',
	actionSubject: 'jsmOrganizationsFieldSearch',
	attributes: {
		isSearchStringEmpty,
	},
});

// @ts-expect-error - TS7006 - Parameter 'analyticsEvent' implicitly has an 'any' type.
const fireSearchSuccessEvent = async (analyticsEvent) => {
	const client = await getAnalyticsWebClientPromise();
	client.getInstance().sendTrackEvent(analyticsEvent);
};

// we debounce the success event firing, to more accurately represent a complete search
// as opposed to a search which is triggered mid-way through a user's search term
const searchSuccessDebounceTime = 2000;
const debouncedFireSearchSuccessEvent = debounce(
	fireSearchSuccessEvent,
	searchSuccessDebounceTime,
	{ leading: true, trailing: false }, // fire event on the leading edge of the debounce
);

export const fetchSuggestionsFactory = memoizeOne((autoCompleteUrl) => (query: string) => {
	if (!autoCompleteUrl) {
		return Promise.resolve([]);
	}

	const url = `${autoCompleteUrl}${encodeURIComponent(query)}`;
	return fetchJson(url)
		.then((res) => {
			debouncedFireSearchSuccessEvent(createSuccessEvent({ isSearchStringEmpty: query === '' }));
			return transformSuggestions(res);
		})
		.catch((error) => {
			fireErrorAnalytics({
				meta: {
					id: 'jsmOrganizationsFieldSearch',

					packageName: 'jiraIssueView',
				},
				error,
				attributes: {
					isSearchStringEmpty: query === '',
				},
				sendToPrivacyUnsafeSplunk: true,
			});
			throw error;
		});
});

export const saveField = ({
	baseUrl,
	issueKey,
	fieldMetaKey,
	value, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: SaveFieldArguments<any>): Promise<any> =>
	updateOrganization(baseUrl, issueKey, fieldMetaKey, value);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const analyticsAttributeIdRetriever = (value: any): string =>
	value && Array.isArray(value) ? value.map((org) => org.id).join(',') : value;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const formatOptionLabelMemoized = memoizeOne((intl: IntlShapeV2) => (option: any) => (
	<OptionContainer>
		<PeopleGroupIcon
			color="currentColor"
			label={intl.formatMessage(messages.organizationIconLabel)}
		/>
		<OptionLabel>{option.content}</OptionLabel>
	</OptionContainer>
));

const additionalPropsMemoized = memoizeOne(
	(ownPropsOnMount) => (state: State, intl: IntlShapeV2) => {
		const fieldAutoCompleteUrlSelectorGetter = fieldAutoCompleteUrlSelector(
			ownPropsOnMount.fieldId,
		);

		return {
			tagAppearance: 'rounded',
			formatOptionLabel: formatOptionLabelMemoized(intl),
			classNamePrefix: 'organizations-field',
			fetchSuggestions: fetchSuggestionsFactory(fieldAutoCompleteUrlSelectorGetter(state)),
			placeholder: intl.formatMessage(messages.placeholder),
			noValueText: intl.formatMessage(genericMessages.noValue),
			analyticsAttributeIdRetriever,
			showPinButton: getShowPinButton(ownPropsOnMount.area),
		};
	},
);

const connectFieldNext = connectField((stateOnMount, ownPropsOnMount) => ({
	fieldId: ownPropsOnMount.fieldId,
	transformFromStateValue,
	transformToStateValue,
	saveField,
	additionalProps: additionalPropsMemoized(ownPropsOnMount),
}));

const wrappedMutliSelectInlineEditView = (props: Props) => (
	<UFOSegment name="issue-field-organizations">
		<div data-testid="issue-view-base.context.organizations.field">
			<MultiSelectInlineEditView {...props} />
		</div>
	</UFOSegment>
);

export default connectFieldNext(wrappedMutliSelectInlineEditView);
