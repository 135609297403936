import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	addCommentPlaceholderText: {
		id: 'issue-activity-feed.ui.add-comment-placeholder.text',
		defaultMessage: 'Add a comment',
		description: 'Text in placeholder used to add a new comment.',
	},
	closeAddCommentPlaceholder: {
		id: 'issue-activity-feed.ui.add-comment-placeholder.closeBtnText',
		defaultMessage: 'Close',
		description: 'Title of Close button inside add a comment placeholder',
	},
});
