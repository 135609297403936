/**
 * @generated SignedSource<<d562f1c657a122a87b7df4a14d511876>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type async_issueViewCommonViews_AsyncConfigurableChildIssuesPanelUsingEntryPoint$data = {
  readonly rankField: {
    readonly jqlTerm: string;
  } | null | undefined;
  readonly userPreferences: {
    readonly isIssueViewHideDoneChildIssuesFilterEnabled: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "async_issueViewCommonViews_AsyncConfigurableChildIssuesPanelUsingEntryPoint";
};
export type async_issueViewCommonViews_AsyncConfigurableChildIssuesPanelUsingEntryPoint$key = {
  readonly " $data"?: async_issueViewCommonViews_AsyncConfigurableChildIssuesPanelUsingEntryPoint$data;
  readonly " $fragmentSpreads": FragmentRefs<"async_issueViewCommonViews_AsyncConfigurableChildIssuesPanelUsingEntryPoint">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    }
  ],
  "kind": "Fragment",
  "name": "async_issueViewCommonViews_AsyncConfigurableChildIssuesPanelUsingEntryPoint",
  "selections": [
    {
      "args": (v0/*: any*/),
      "concreteType": "JiraUserPreferences",
      "kind": "LinkedField",
      "name": "userPreferences",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isIssueViewHideDoneChildIssuesFilterEnabled"
        }
      ]
    },
    {
      "args": (v0/*: any*/),
      "concreteType": "JiraJqlFieldWithAliases",
      "kind": "LinkedField",
      "name": "rankField",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "jqlTerm"
        }
      ]
    }
  ],
  "type": "JiraQuery"
};
})();

(node as any).hash = "4fa20f3c6f4db2528a3ff03b38d3501a";

export default node;
