import React from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button/standard-button';
import { token } from '@atlaskit/tokens';
import { singleLineClamp } from '@atlassian/jira-common-styles/src/mixins.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { FormatMessage } from '@atlassian/jira-shared-types/src/general.tsx';
import { OPEN, RESOLVED } from '../../common/constants.tsx';
import type { IssueStatus, SelectIssueStatusItem } from '../../common/types.tsx';
import { useRelatedIssuesForIssue } from '../../services/context.tsx';
import { useQueryText } from '../../services/use-query-text/index.tsx';
import { useDiscoverabilityExperiment } from '../../controllers/use-discoverability-experiment/index.tsx';
import messages from './messages.tsx';
import LoadingSkeleton from './skeleton/index.tsx';

const IssueStatusButton = ({
	buttonKey,
	name,
	isDisabled,
	isSelected,
	onSelectIssueStatusItem,
	formatMessage,
}: {
	buttonKey: IssueStatus;
	name: string;
	isDisabled: boolean;
	isSelected: boolean;
	onSelectIssueStatusItem: SelectIssueStatusItem;
	formatMessage: FormatMessage;
}) => {
	const getAriaLabel = (key: string) => {
		if (key === OPEN) {
			return formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.openAriaLabelIssueTermRefresh
					: messages.openAriaLabel,
			);
		}
		if (key === RESOLVED) {
			return formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.resolvedAriaLabelIssueTermRefresh
					: messages.resolvedAriaLabel,
			);
		}
		return undefined;
	};
	return (
		<StyledStatusButton
			spacing="compact"
			key={buttonKey}
			isDisabled={isDisabled}
			isSelected={isSelected && !isDisabled}
			onClick={(_, analyticsEvent) =>
				onSelectIssueStatusItem(buttonKey, analyticsEvent, 'IssueStatusButton')
			}
			aria-label={getAriaLabel(buttonKey)}
		>
			{name}
		</StyledStatusButton>
	);
};

const StatusTabs = ({ sessionId }: { sessionId: string }) => {
	const { formatMessage } = useIntl();
	const [{ data, status, isLoading, error }, { fetchRelatedIssuesForIssue, setIssueStatus }] =
		useRelatedIssuesForIssue();
	const { isIncludedInExperiment } = useDiscoverabilityExperiment();

	const hasOpenIssues = (data.OPEN?.length ?? 0) > 0;
	const hasResolvedIssues = (data.RESOLVED?.length ?? 0) > 0;
	const isInitialState =
		!isLoading && data[OPEN] === undefined && data[RESOLVED] === undefined && error === undefined;

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const queryText = useQueryText();

	const handleSelectIssueStatusItem = (
		selectedStatus: IssueStatus,
		analyticsEvent: UIAnalyticsEvent,
		subject: string,
	) => {
		if (status !== selectedStatus) {
			fireUIAnalytics(analyticsEvent, subject, {
				selectedStatusFilter: selectedStatus,
			});

			setIssueStatus(selectedStatus);
			fetchRelatedIssuesForIssue(sessionId, createAnalyticsEvent, queryText);
		}
	};

	return (
		<TabContainer>
			<ShowTextContainer>{formatMessage(messages.filterBy)}</ShowTextContainer>
			{isLoading || isInitialState ? (
				<LoadingSkeleton />
			) : (
				<>
					<IssueStatusButton
						buttonKey={OPEN}
						name={
							isIncludedInExperiment
								? formatMessage(messages.openWithCount, { count: data.OPEN?.length ?? 0 })
								: formatMessage(messages.open)
						}
						isSelected={OPEN === status}
						onSelectIssueStatusItem={handleSelectIssueStatusItem}
						isDisabled={!hasOpenIssues}
						formatMessage={formatMessage}
					/>
					<IssueStatusButton
						buttonKey={RESOLVED}
						name={
							isIncludedInExperiment
								? formatMessage(messages.resolvedWithCount, { count: data.RESOLVED?.length ?? 0 })
								: formatMessage(messages.resolved)
						}
						isSelected={RESOLVED === status}
						onSelectIssueStatusItem={handleSelectIssueStatusItem}
						isDisabled={!hasResolvedIssues}
						formatMessage={formatMessage}
					/>
				</>
			)}
		</TabContainer>
	);
};

export default StatusTabs;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledStatusButton = styled(Button)({
	paddingTop: 0,
	paddingRight: token('space.050'),
	paddingBottom: 0,
	paddingLeft: token('space.050'),
	marginTop: 0,
	marginRight: token('space.050'),
	marginBottom: 0,
	marginLeft: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TabContainer = styled.div({
	display: 'flex',
	marginTop: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ShowTextContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	...singleLineClamp,
	minWidth: 0,
});
