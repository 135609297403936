// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createStateHook, createActionsHook } from '@atlassian/react-sweet-state';
import type { State, ViewIdentifiers } from './types.tsx';

const initialState: State = {
	views: [],
	viewsByPolarisApiId: {},
	viewsByUUID: {},
	viewsByAri: {},
};

const Store = createStore({
	initialState,
	actions: {
		setViews:
			(views: ViewIdentifiers[]) =>
			({ setState }) => {
				const viewsByPolarisApiId: Record<string, ViewIdentifiers> = {};
				const viewsByUUID: Record<string, ViewIdentifiers> = {};
				const viewsByAri: Record<string, ViewIdentifiers> = {};

				views.forEach((view) => {
					viewsByPolarisApiId[view.viewPolarisApiId] = view;
					viewsByUUID[view.viewUUID] = view;
					viewsByAri[view.viewAri] = view;
				});

				setState({
					views,
					viewsByPolarisApiId,
					viewsByUUID,
					viewsByAri,
				});
			},
	},
	name: 'PolarisViewIdMappingStore',
});

const useActions = createActionsHook(Store);
export const useSetViews = () => {
	const { setViews } = useActions();
	return setViews;
};

export const useViewIdsByPolarisApiViewId = createStateHook(Store, {
	selector: (state, { polarisApiId }: { polarisApiId: string }): ViewIdentifiers | undefined =>
		state.viewsByPolarisApiId[polarisApiId],
});
export const useViewIdsByViewAri = createStateHook(Store, {
	selector: (state, { viewAri }: { viewAri: string }): ViewIdentifiers | undefined =>
		state.viewsByAri[viewAri],
});
export const useViewIdsByViewUUID = createStateHook(Store, {
	selector: (state, { viewUUID }: { viewUUID: string | undefined }): ViewIdentifiers | undefined =>
		viewUUID !== undefined ? state.viewsByUUID[viewUUID] : undefined,
});
