import React, { forwardRef, type Ref } from 'react';
import type { EntryPointProps } from 'react-relay';
import { ErrorMessage } from '@atlaskit/form';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import { Box, xcss } from '@atlaskit/primitives';
import useUFOTypingPerformanceTracing from '@atlaskit/react-ufo/typing-performance-tracing';
import Textfield from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import useMergeRefs from '@atlassian/jira-merge-refs/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { SingleLineTextEditViewProps } from './types.tsx';

const SingleLineTextField = (props: SingleLineTextEditViewProps, ref: Ref<HTMLInputElement>) => {
	const {
		spacing,
		type = 'text',
		value = '',
		placeholder = '',
		invalidMessage = null,
		onChange,
		onBlur,
		onFocus,
		autoFocus = false,
		isDisabled = false,
		isInvalid = false,
		inputId,
		'aria-describedby': ariaDescribedby,
		ariaLabel,
		ariaLabelledBy,
	} = props;

	const typeTracingRef = useUFOTypingPerformanceTracing('single-line-text-field-type-tracing');
	const fieldRef = useMergeRefs(ref, typeTracingRef);
	const displayValue = value === null ? '' : value;
	return (
		<>
			<Textfield
				aria-describedby={fg('jsc_inline_editing_field_refactor') ? ariaDescribedby : undefined}
				type={type}
				value={displayValue}
				placeholder={placeholder}
				onChange={onChange}
				onBlur={onBlur}
				onFocus={onFocus}
				isInvalid={!!invalidMessage || isInvalid}
				autoFocus={autoFocus}
				ref={fieldRef}
				isCompact={spacing === 'compact'}
				isDisabled={isDisabled}
				id={inputId}
				elemAfterInput={
					!!invalidMessage === true ? (
						<Box xcss={warningWrapperStyles}>
							<WarningIcon
								LEGACY_size="medium"
								spacing="spacious"
								color={token('color.icon.warning')}
								label=""
							/>
						</Box>
					) : null
				}
				aria-label={ariaLabel}
				aria-labelledby={ariaLabelledBy}
			/>
			{invalidMessage !== null ? <ErrorMessage>{invalidMessage}</ErrorMessage> : null}
		</>
	);
};

SingleLineTextField.displayName = 'SingleLineTextField';

export const SingleLineTextEditView = forwardRef<HTMLInputElement, SingleLineTextEditViewProps>(
	SingleLineTextField,
);

const SingleLineTextEditViewEntryPoint = ({
	props,
}: EntryPointProps<{}, {}, SingleLineTextEditViewProps, {}>) => (
	<SingleLineTextEditView {...props} />
);

export default SingleLineTextEditViewEntryPoint;

const warningWrapperStyles = xcss({
	marginLeft: 'space.negative.050',
	marginRight: 'space.050',
});
