import React, {
	// eslint-disable-next-line jira/restricted/react
	PureComponent,
	Children,
	type ReactNode,
	type ReactElement,
	// eslint-disable-next-line jira/restricted/react-component-props
	type ComponentProps,
} from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { Intl } from '@atlassian/jira-shared-types/src/general.tsx';
import ProgressElement from './progress-element/index.tsx';
import messages from './messages.tsx';

export type ProgressBarProps = Partial<Pick<HTMLDivElement, 'tabIndex'>> & {
	// children: progress-element - it accepts three props: value, tooltipContent, color.
	children:
		| Array<ReactElement<ComponentProps<typeof ProgressElement>>>
		| ReactElement<ComponentProps<typeof ProgressElement>>
		| null;
	// amount of all elements values + remaining space (not required, default no empty space)
	sum: number;
	// if the sum is greater than a amount of all elements values a tooltip might be shown with provided description (not required, default none)
	remainingTooltipContent: string | ReactNode;
	// a background color of a remaining space (not required, default akColorN30)
	backgroundColor: string;
	// a height of the ProgressBar (not required, default 10 px)
	height: number;
	// Adjust the border radius
	borderRadius?: number;
	// tooltipHoverPadding - padding above & below element to trigger tooltip
	tooltipHoverPadding?: number;
	// ariaLabel - arial label for the progress bar
	'aria-label'?: string | ReactNode;
};

type ValidateElementsAndCalculateElementsWidth = {
	// validatedElements = check if elements values are valid = value needs to exist & to be > 0
	validatedElements: ReactElement<ComponentProps<typeof ProgressElement>>[];
	// total children elements sum
	elementsSum: number;
};

// eslint-disable-next-line jira/react/no-class-components
class ProgressBar extends PureComponent<ProgressBarProps & Intl> {
	static defaultProps: ProgressBarProps = {
		children: null,
		sum: 0,
		remainingTooltipContent: '',
		// TODO: Use tokens for colors
		backgroundColor: colors.N30,
		height: 10,
	};

	validateElementsAndCalculateElementsWidth(): ValidateElementsAndCalculateElementsWidth {
		const { children } = this.props;
		let sumOfElementsValues = 0;
		const validatedElements: ReactElement<ComponentProps<typeof ProgressElement>>[] = [];

		Children.map(children, (child) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const childEl = child as ReactElement;

			if (childEl.props.value && childEl.props.value > 0) {
				sumOfElementsValues += childEl.props.value;
				validatedElements.push(childEl);
			}
		});

		return {
			validatedElements,
			elementsSum: sumOfElementsValues,
		};
	}

	render() {
		const {
			children,
			remainingTooltipContent,
			sum,
			backgroundColor,
			height,
			borderRadius,
			tooltipHoverPadding,
			intl: { formatMessage },
		} = this.props;

		if (!children) {
			return (
				<ProgressWrapper
					// eslint-disable-next-line jira/integration/test-id-by-folder-structure
					data-testid="common.components.progress-bar.progress-wrapper"
					backgroundColor={backgroundColor}
					elementsGrow={0}
					elementsHeight={height}
					// @ts-expect-error - TS2322 - Type 'number | undefined' is not assignable to type 'string | number'.
					borderRadius={borderRadius}
				/>
			);
		}

		const { validatedElements, elementsSum } = this.validateElementsAndCalculateElementsWidth();

		const remaining = sum - elementsSum;
		return (
			<ProgressWrapper
				{...this.props}
				// eslint-disable-next-line jira/integration/test-id-by-folder-structure
				data-testid="common.components.progress-bar.progress-wrapper"
				backgroundColor={remaining > 0 ? backgroundColor : 'transparent'}
				elementsHeight={height}
				// @ts-expect-error - TS2322 - Type 'number | undefined' is not assignable to type 'string | number'.
				borderRadius={borderRadius}
				tooltipHoverPadding={tooltipHoverPadding}
				ariaLive="polite"
				role="progressbar"
				aria-valuemax={sum}
				aria-valuenow={elementsSum}
				aria-label={this.props['aria-label'] || formatMessage(messages.progressBarAriaLabel)}
			>
				{validatedElements}
				{remaining > 0 && (
					<ProgressElement
						value={remaining}
						tooltipContent={remainingTooltipContent}
						color={backgroundColor}
						tooltipHoverPadding={tooltipHoverPadding}
					/>
				)}
			</ProgressWrapper>
		);
	}
}

export default injectIntl(ProgressBar);

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProgressWrapper = styled.div<{
	elementsHeight: number;
	borderRadius: string | number;
	tooltipHoverPadding?: number;
}>`
	width: 100%;
	height: ${
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(props: any) => props.elementsHeight
	}px;
	display: flex;
	${({ borderRadius, elementsHeight, tooltipHoverPadding }) =>
		!tooltipHoverPadding &&
		`border-radius: ${borderRadius || elementsHeight / 2}px;
        overflow: hidden;
    `}
	& > div {
		${({ borderRadius, elementsHeight }) => {
			const radius = `${borderRadius || elementsHeight / 2}px`;

			return `
                &:first-child {
                    border-top-left-radius: ${radius};
                    border-bottom-left-radius: ${radius};
                }
                &:last-child {
                    border-top-right-radius: ${radius};
                    border-bottom-right-radius: ${radius};
                }
                &:not(:last-child) {
                    margin-right: 2px;
                }
                &:last-child {
                    margin-right: 0;
                }
            `;
		}}
	}
`;
ProgressWrapper.displayName = 'ProgressWrapper';
