import { useMemo } from 'react';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	useProjectKey,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import {
	type ApplicationEdition,
	FREE_EDITION,
	UNLICENSED,
} from '@atlassian/jira-shared-types/src/edition.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';

type Cohort = 'control' | 'dynamic' | 'static' | 'not-enrolled';

export const COHORTS: { [key: string]: Cohort } = {
	CONTROL: 'control',
	DYNAMIC: 'dynamic',
	STATIC: 'static',
	NOT_ENROLLED: 'not-enrolled',
};

const freeAppEditions: ApplicationEdition[] = [FREE_EDITION, UNLICENSED];

type ExperimentConfiguration = {
	cohort: Cohort;
	isIncludedInExperiment: boolean;
	fireExposure: () => void;
};

export const useDiscoverabilityExperiment = (): ExperimentConfiguration => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);
	const appEditions = useAppEditions();

	return useMemo(() => {
		const isServiceDeskProject = projectType === SERVICE_DESK_PROJECT;
		const isPaidTenant = !freeAppEditions.includes(appEditions.serviceDesk);
		// eslint-disable-next-line jira/ff/unsafe-no-exposure
		const [config, fireExposure] = UNSAFE_noExposureExp('jsm_smart_related_issues_icon');
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const cohort = config.get('cohort', COHORTS.NOT_ENROLLED) as Cohort;
		const isTargeted = cohort !== COHORTS.NOT_ENROLLED;
		const isEligible = isServiceDeskProject && isPaidTenant;
		return { cohort, isIncludedInExperiment: isTargeted && isEligible, fireExposure };
	}, [appEditions.serviceDesk, projectType]);
};
