import React, {
	useCallback,
	useRef,
	useState,
	useContext,
	type ComponentType,
	type SyntheticEvent,
} from 'react';
import { styled } from '@compiled/react';
import {
	graphql,
	useFragment,
	usePreloadedQuery,
	useQueryLoader,
	type PreloadedQuery,
} from 'react-relay';
import Popup, { type TriggerProps } from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import Placeholder from '@atlaskit/react-ufo/placeholder';
import AkSpinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { HeaderPopupsStateContext } from '@atlassian/jira-issue-view-sticky-header-container/src/popup-context/index.tsx';
import type { view_issueViewWatchers_Dropdown$key } from '@atlassian/jira-relay/src/__generated__/view_issueViewWatchers_Dropdown.graphql';
import view_issueViewWatchers_DropdownContentQuery, {
	type view_issueViewWatchers_DropdownContentQuery as DropdownContentQueryType,
} from '@atlassian/jira-relay/src/__generated__/view_issueViewWatchers_DropdownContentQuery.graphql';
import ThirdPartyIssueWatchNudge from '@atlassian/jira-third-party-nudge/src/ui/third-party-issue-watch-nudge/async.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { ActionButtonNew } from '../action-button/index.tsx';
import { AddWatchersNew } from './add-watchers/index.tsx';
import ChangeWatchStateView from './change-watch-state/index.tsx';
import { WatchersListNew } from './watchers-list/index.tsx';
import { SpinnerContainer } from './watchers-list/view.tsx';

type WatchersButtonProps = {
	isDropdownOpen: boolean;
	onClick?: (event: SyntheticEvent) => void;
};

type WatchersChangeWatchStateProps = {
	onToggle: (isWatching: boolean) => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EmptyProps = Record<any, any>;

type Props = {
	Button: ComponentType<WatchersButtonProps>;
	ChangeWatchState: ComponentType<WatchersChangeWatchStateProps>;
	WatchersList: ComponentType<EmptyProps>;
	AddWatchers: ComponentType<EmptyProps>;
};

const customModifiers = [
	{
		name: 'preventOverflow',
		enabled: true,
		options: {
			padding: { top: 65 },
		},
	},
];

const loadingContentTestId = 'issue.watchers.dropdown.dropdown';
const loadingTestId = 'issue-view-watchers.watchers.dropdown.watchers-list.loading';
const LoadingDropdownContent = () => {
	return (
		<ContentFf data-testid={loadingContentTestId}>
			<Container data-testid={loadingTestId}>
				<SpinnerContainer>
					<AkSpinner size="medium" />
				</SpinnerContainer>
			</Container>
		</ContentFf>
	);
};

// Todo remove optional on watches on relay-migration-issue-header-and-parent cleanup
export const Dropdown = ({ watches }: { watches?: view_issueViewWatchers_Dropdown$key }) => {
	// eslint-disable-next-line @atlassian/relay/query-restriction
	const viewData = useFragment<view_issueViewWatchers_Dropdown$key>(
		graphql`
			fragment view_issueViewWatchers_Dropdown on JiraWatchesField {
				id
				...actionButton_issueViewWatchers_ActionButtonWithRelay
			}
		`,
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		watches!,
	);

	const [open, setOpen] = useState(false);

	const [shouldShowThirdPartyNudge, setShouldShowThirdPartyNudge] = useState<boolean>(false);

	const buttonContainer = useRef<HTMLDivElement | null>(null);

	const { updateIsOpened } = useContext(HeaderPopupsStateContext);

	const [popupContentQueryReference, loadPopupContentQuery] =
		useQueryLoader<DropdownContentQueryType>(view_issueViewWatchers_DropdownContentQuery);

	const onOpenClose = useCallback(() => {
		setOpen((oldOpen) => {
			const newOpen = !oldOpen;
			if (newOpen) {
				loadPopupContentQuery({ id: viewData.id });
			}
			updateIsOpened(newOpen);
			return newOpen;
		});
	}, [loadPopupContentQuery, updateIsOpened, viewData.id]);

	const onClick = useCallback(
		(event: SyntheticEvent) => {
			const { target } = event;
			if (
				target instanceof HTMLElement &&
				buttonContainer.current !== null &&
				buttonContainer.current.contains(target)
			) {
				onOpenClose();
			}
		},
		[onOpenClose],
	);

	const changeWatchStateToggle = useCallback(
		(isWatching: boolean) => {
			onOpenClose();
			if (isWatching) {
				setShouldShowThirdPartyNudge(true);
			}
		},
		[onOpenClose],
	);

	const content = useCallback(
		() => (
			<Placeholder
				name="jira.issue-view-watchers.watchers.view.dropdown.content"
				fallback={<LoadingDropdownContent />}
			>
				{popupContentQueryReference ? (
					<PopupContent
						onChangeWatchStateToggle={changeWatchStateToggle}
						queryReference={popupContentQueryReference}
					/>
				) : (
					<LoadingDropdownContent />
				)}
			</Placeholder>
		),
		[changeWatchStateToggle, popupContentQueryReference],
	);

	return (
		<Container>
			{shouldShowThirdPartyNudge && <ThirdPartyIssueWatchNudge reference={buttonContainer} />}
			<Popup
				boundary="clippingParents"
				isOpen={open}
				placement="auto-end"
				trigger={({ ref }: TriggerProps) => (
					<div
						ref={(buttonContainerLocal) => {
							buttonContainer.current = buttonContainerLocal;
							if (ref && typeof ref === 'function') {
								ref(buttonContainerLocal);
							}
						}}
					>
						<ActionButtonNew isDropdownOpen={open} onClick={onClick} watches={viewData} />
					</div>
				)}
				onClose={onOpenClose}
				content={content}
				testId="issue-view-watchers.watchers.dropdown.popup"
				zIndex={layers.modal}
				{...(fg('jfp_a11y_team_revert_issueview_popup_reading_order')
					? {}
					: {
							modifiers: customModifiers,
							shouldRenderToParent: true,
						})}
			/>
		</Container>
	);
};

type PopupContentProps = {
	onChangeWatchStateToggle: (isWatching: boolean) => void;
	queryReference: PreloadedQuery<DropdownContentQueryType>;
};

const PopupContent = ({ onChangeWatchStateToggle, queryReference }: PopupContentProps) => {
	// eslint-disable-next-line @atlassian/relay/query-restriction
	const data = usePreloadedQuery<DropdownContentQueryType>(
		graphql`
			query view_issueViewWatchers_DropdownContentQuery($id: ID!) {
				node(id: $id) {
					... on JiraWatchesField {
						...changeWatchState_issueViewWatchers_ChangeWatchWithRelay
						...watchersList_issueViewWatchers_WatchersListRelay
						...addWatchers_issueViewWatchers_AddWatchersRelay
					}
				}
			}
		`,
		queryReference,
	);
	if (!data?.node) {
		return <LoadingDropdownContent />;
	}
	return (
		// eslint-disable-next-line jira/integration/test-id-by-folder-structure
		<ContentFf data-testid="issue.watchers.dropdown.dropdown">
			<ChangeWatchStateView onToggle={onChangeWatchStateToggle} watches={data?.node} />
			<WatchersListNew watches={data?.node} />
			<AddWatchersNew watches={data?.node} />
		</ContentFf>
	);
};

export const DropdownOld = (props: Props) => {
	const [open, setOpen] = useState(false);
	const { Button, ChangeWatchState, WatchersList, AddWatchers } = props;

	const [shouldShowThirdPartyNudge, setShouldShowThirdPartyNudge] = useState<boolean>(false);
	const { updateIsOpened } = useContext(HeaderPopupsStateContext);

	const buttonContainer = useRef<HTMLDivElement | null>(null);

	const onOpenClose = useCallback(() => {
		const state = !open;
		setOpen(state);
		updateIsOpened(state);
	}, [open, updateIsOpened]);

	const onClick = useCallback(
		(event: SyntheticEvent) => {
			const { target } = event;
			if (
				target instanceof HTMLElement &&
				buttonContainer.current !== null &&
				buttonContainer.current.contains(target)
			) {
				onOpenClose();
			}
		},
		[onOpenClose],
	);

	const changeWatchStateToggle = useCallback(
		(isWatching: boolean) => {
			onOpenClose();
			if (isWatching) {
				setShouldShowThirdPartyNudge(true);
			}
		},
		[onOpenClose],
	);

	return (
		<Container>
			{shouldShowThirdPartyNudge && <ThirdPartyIssueWatchNudge reference={buttonContainer} />}
			<Popup
				boundary="clippingParents"
				isOpen={open}
				placement="auto-end"
				trigger={({ ref }: TriggerProps) => (
					<div
						ref={(buttonContainerLocal) => {
							buttonContainer.current = buttonContainerLocal;
							if (ref && typeof ref === 'function') {
								ref(buttonContainerLocal);
							}
						}}
					>
						<Button isDropdownOpen={open} onClick={onClick} />
					</div>
				)}
				onClose={onOpenClose}
				content={() => (
					// eslint-disable-next-line jira/integration/test-id-by-folder-structure
					<ContentFf data-testid="issue.watchers.dropdown.dropdown">
						<ChangeWatchState onToggle={changeWatchStateToggle} />
						<WatchersList />
						<AddWatchers />
					</ContentFf>
				)}
				testId="issue-view-watchers.watchers.dropdown.popup"
				zIndex={layers.modal}
				{...(fg('jfp_a11y_team_revert_issueview_popup_reading_order')
					? {}
					: {
							modifiers: customModifiers,
							shouldRenderToParent: true,
						})}
			/>
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"[data-role='droplistContent']": {
		overflow: 'visible',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentFf = styled.div({
	width: '240px',
	paddingTop: token('space.050'),
	paddingRight: 0,
	paddingBottom: token('space.050'),
	paddingLeft: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		"[role='menuitem']": {
			paddingTop: token('space.100'),
			paddingRight: token('space.150'),
			// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
			paddingBottom: '7px',
			paddingLeft: token('space.150'),
		},
		/**
		 * We cannot target the inner element by contains / starts with
		 * because that automatically includes the parent as well, as they
		 * both start with, contain in their class name "GroupTitle"
		 */
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		"[class*='GroupTitle']": {
			paddingTop: token('space.100'),
			paddingRight: token('space.150'),
			paddingBottom: token('space.100'),
			paddingLeft: token('space.150'),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		"[class*='GroupTitleText']": {
			padding: 0,
		},
	},
});
