import { ProfileClient } from '@atlaskit/profilecard';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	type Action,
	createStore,
	createContainer,
	createStateHook,
} from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { CustomUserProfileClient, type OnLoadError } from './custom-user-profile-client.tsx';

export type ProfileCardContainerProps = {
	fabricPfDirUrl: string | null;
	profileClient?: ProfileClient | null;
	cloudId: string;
	onLoadError?: OnLoadError;
	orgId?: string;
};

export type State = {
	profileClient: ProfileClient | null;
};

const GATEWAY_GRAPHQL_URL = '/gateway/api/graphql';

const createUserProfileCardClientWithoutOrgId = (
	fabricPfDirUrl: string,
	onLoadError: OnLoadError | undefined,
	gatewayGraphqlUrl?: string,
	cloudId?: string,
	_orgId?: string,
) => {
	const userClient = new CustomUserProfileClient(fabricPfDirUrl, onLoadError);

	// From upward version 14 of `@atlaskit/profilecard`, `ProfileClient` class accepts `userClient` and `teamClient` in the 2nd argument.
	return new ProfileClient(
		{
			cacheMaxAge: 1000 * 60 * 3,
			cacheSize: 20,
			url: fabricPfDirUrl,
			productIdentifier: 'jira',
			gatewayGraphqlUrl,
			cloudId,
		},
		{
			userClient,
		},
	);
};
const createUserProfileCardClientWithOrgId = (
	fabricPfDirUrl: string,
	onLoadError: OnLoadError | undefined,
	gatewayGraphqlUrl?: string,
	cloudId?: string,
	orgId?: string,
) => {
	const userClient = new CustomUserProfileClient(fabricPfDirUrl, onLoadError);

	// From upward version 14 of `@atlaskit/profilecard`, `ProfileClient` class accepts `userClient` and `teamClient` in the 2nd argument.
	return new ProfileClient(
		{
			cacheMaxAge: 1000 * 60 * 3,
			cacheSize: 20,
			url: fabricPfDirUrl,
			productIdentifier: 'jira',
			gatewayGraphqlUrl,
			cloudId,
			orgId,
		},
		{
			userClient,
		},
	);
};

export const createUserProfileCardClient = functionWithCondition(
	() => fg('issue_view_prevent_unnecessary_orgid_query'),
	createUserProfileCardClientWithOrgId,
	createUserProfileCardClientWithoutOrgId,
);

export const actions = {
	bootstrap:
		(): Action<State, ProfileCardContainerProps> =>
		({ setState }, { fabricPfDirUrl, profileClient, onLoadError, cloudId, orgId }) => {
			if (fg('issue_view_prevent_unnecessary_orgid_query')) {
				const finalProfileClient =
					profileClient ||
					createUserProfileCardClientWithOrgId(
						fabricPfDirUrl ?? '',
						onLoadError,
						GATEWAY_GRAPHQL_URL,
						cloudId,
						orgId,
					);

				setState({
					profileClient: finalProfileClient,
				});
			} else {
				const finalProfileClient =
					profileClient ||
					createUserProfileCardClientWithoutOrgId(
						fabricPfDirUrl ?? '',
						onLoadError,
						GATEWAY_GRAPHQL_URL,
						cloudId,
					);

				setState({
					profileClient: finalProfileClient,
				});
			}
		},
} as const;

type Actions = typeof actions;

export const ProfileClientContainer = createContainer<ProfileCardContainerProps>({
	displayName: 'ProfileClientContainer',
});

export const initialState: State = {
	profileClient: null,
};

export const store = createStore<State, Actions, ProfileCardContainerProps>({
	name: 'ProfileClientStore',
	initialState,
	actions,
	containedBy: ProfileClientContainer,
	handlers: {
		onInit:
			() =>
			({ dispatch }) =>
				dispatch(actions.bootstrap()),
	},
});

export const useProfileClient = createStateHook(store);
