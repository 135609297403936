import { createContainer } from 'react-sweet-state';

import { type Actions, store } from '../main';
import type { IssueAdjustmentsState, StoreContainerProps } from '../types';

export const LoadedUiModificationsContainer = createContainer<
	IssueAdjustmentsState,
	Actions,
	StoreContainerProps
>(store, {
	displayName: 'LoadedUiModificationsContainer',
	onInit:
		() =>
		({ setState }, { viewType }) => {
			setState({ viewType });
		},
});
