/** @jsx jsx */
import React, { useCallback, useState, useEffect, useMemo, type PropsWithChildren } from 'react';
import { css, styled, jsx } from '@compiled/react';
import { useFragment, graphql, useMutation } from 'react-relay';
import { ErrorMessage } from '@atlaskit/form';
import { token } from '@atlaskit/tokens';
import { Box, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	CascadingSelectEditView,
	getCascadingSelectEditViewSuggestionsVariables,
} from '@atlassian/jira-issue-field-cascading-select-editview-full/src/ui/cascading-select/index.tsx';
import CascadingEditViewEntryPoint from '@atlassian/jira-issue-field-cascading-select-editview-full/src/entrypoint.tsx';
import { FieldInlineEditLiteWithEntryPoint } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/index.tsx';
import type {
	CascadingSelectEditViewProps,
	CascadingSelectValuePair,
	NullableCascadingSelectValuePair,
} from '@atlassian/jira-issue-field-cascading-select-editview-full/src/ui/cascading-select/types.tsx';
import { CascadingSelectReadView } from '@atlassian/jira-issue-field-cascading-select-readview-full/src/ui/cascading-select/index.tsx';
import { FRAGMENT_SELECTABLE_FIELD_OPTIONS_FIRST } from '@atlassian/jira-issue-field-constants/src/index.tsx';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import { useOptionallyControlledEditingState } from '@atlassian/jira-issue-field-optional-editing-state-manager/src/index.tsx';
import { useQueryLoaderOnIntent } from '@atlassian/jira-issue-hooks/src/services/use-query-loader-on-intent/index.tsx';
import type {
	cascadingSelect_issueFieldCascadingSelect_CascadingSelectField_Mutation as CascadingSelectMutation,
	cascadingSelect_issueFieldCascadingSelect_CascadingSelectField_Mutation$data as CascadingSelectMutationData,
	cascadingSelect_issueFieldCascadingSelect_CascadingSelectField_Mutation$rawResponse as CascadingSelectMutationResponse,
} from '@atlassian/jira-relay/src/__generated__/cascadingSelect_issueFieldCascadingSelect_CascadingSelectField_Mutation.graphql';
import type {
	cascadingSelect_issueFieldCascadingSelect_CascadingSelectFieldIsEditable_Mutation as CascadingSelectIsEditableMutation,
	cascadingSelect_issueFieldCascadingSelect_CascadingSelectFieldIsEditable_Mutation$rawResponse as CascadingSelectIsEditableMutationResponse,
} from '@atlassian/jira-relay/src/__generated__/cascadingSelect_issueFieldCascadingSelect_CascadingSelectFieldIsEditable_Mutation.graphql';
import { useFieldInlineEditActions } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/index.tsx';

import type { cascadingSelect_issueFieldCascadingSelect_PrefetchQuery as CascadingSelectInlineEditViewPrefetchQuery } from '@atlassian/jira-relay/src/__generated__/cascadingSelect_issueFieldCascadingSelect_PrefetchQuery.graphql';

import type { cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditViewNew_fragmentRef$key as CascadingSelectNewFragment } from '@atlassian/jira-relay/src/__generated__/cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditViewNew_fragmentRef.graphql';
import type { cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditViewOld_fragmentRef$key as CascadingSelectOldFragment } from '@atlassian/jira-relay/src/__generated__/cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditViewOld_fragmentRef.graphql';
import type { cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditView_fragmentRef$key as CascadingSelectFragment } from '@atlassian/jira-relay/src/__generated__/cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditView_fragmentRef.graphql';
import type { cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditViewIsEditable_fragmentRef$key as CascadingSelectIsEditableFragment } from '@atlassian/jira-relay/src/__generated__/cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditViewIsEditable_fragmentRef.graphql';
import type { OnSubmitCallbacks } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/types.tsx';
import type { ValidationFieldProps } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/field-inline-edit-lite/types.tsx';
import messages from './messages.tsx';

import type {
	ErrorsType,
	CascadingSelectInlineEditViewProps,
	CascadingSelectInlineEditViewIsEditableProps,
	CascadingSelectInlineEditViewOldProps,
	CascadingSelectInlineEditViewNewProps,
} from './types.tsx';

const isCascadingOptionExist = (value: unknown): value is CascadingSelectValuePair | null =>
	value !== undefined;

/** Check if the new selected value is equal to the old value */
const isEqualSelection = (
	a: NullableCascadingSelectValuePair,
	b: NullableCascadingSelectValuePair,
) =>
	a?.parentOptionValue?.value === b?.parentOptionValue?.value &&
	a?.childOptionValue?.value === b?.childOptionValue?.value;

const EditViewWrapper = ({ children }: PropsWithChildren<{}>) => {
	// This needs to be fixed by DST: go/DSP-22083
	// Dropdown z-index is 1, action bar is 200
	// Bento fixes this by wrapping dropdown into a new 300 stack context
	return <Box xcss={wrapperStyles}>{children}</Box>;
};

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components.
 *
 * @param props [CascadingSelectInlineEditViewProps](./types.tsx)
 */
export const CascadingSelectInlineEditViewIsEditable = ({
	attributes,
	editViewPopup,
	editViewPopupAlignBlock,
	editViewPopupTarget,
	readViewFitContainerHeight,
	hideActionButtons = false,
	menuPosition,
	fragmentRef,
	onSubmit,
	onSubmitFailed,
	onSubmitSucceeded,
	spacing = 'default',
	isTruncated = false,
	isEditable = false,
}: CascadingSelectInlineEditViewIsEditableProps) => {
	// #region Relay
	const data = useFragment<CascadingSelectIsEditableFragment>(
		graphql`
			fragment cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditViewIsEditable_fragmentRef on JiraCascadingSelectField {
				id
				name
				fieldId
				type
				...cascadingSelect_issueFieldCascadingSelectReadviewFull_CascadingSelectReadView
				cascadingOption {
					parentOptionValue {
						id
						value
						isDisabled
					}
					childOptionValue {
						id
						value
						isDisabled
					}
				}
			}
		`,
		fragmentRef,
	);

	const [commit] = useMutation<CascadingSelectIsEditableMutation>(graphql`
		mutation cascadingSelect_issueFieldCascadingSelect_CascadingSelectFieldIsEditable_Mutation(
			$input: JiraUpdateCascadingSelectFieldInput!
		) @raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateCascadingSelectField(input: $input) {
					success
					errors {
						message
					}
					field {
						...cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditViewIsEditable_fragmentRef
					}
				}
			}
		}
	`);

	const { id: uniqueFieldId, cascadingOption, name, fieldId, type: fieldType } = data;
	// #endregion

	// #region State
	const initialValue: CascadingSelectValuePair = useMemo(
		() => ({
			parentOptionValue: cascadingOption?.parentOptionValue
				? {
						id: cascadingOption?.parentOptionValue?.id ?? '',
						value: cascadingOption?.parentOptionValue?.value ?? '',
						isDisabled: cascadingOption?.parentOptionValue?.isDisabled,
					}
				: null,
			childOptionValue: cascadingOption?.childOptionValue
				? {
						id: cascadingOption?.childOptionValue?.id ?? '',
						value: cascadingOption?.childOptionValue?.value ?? '',
						isDisabled: cascadingOption?.childOptionValue?.isDisabled,
					}
				: null,
		}),
		[cascadingOption?.childOptionValue, cascadingOption?.parentOptionValue],
	);
	const [updatedValue, setUpdatedValue] = useState<NullableCascadingSelectValuePair>(initialValue);

	const { overriding } = useInlineEditFieldInjections();
	const { overrideLabel, overrideIsEditable } = overriding;

	const fieldName = useMemo(() => overrideLabel(name), [overrideLabel, name]);

	const isFieldEditable = useMemo(
		() => overrideIsEditable(isEditable),
		[overrideIsEditable, isEditable],
	);
	// #endregion

	// #region Action
	const handleSubmit = useCallback(
		(newOption: CascadingSelectValuePair | null, { onSuccess, onFail }: OnSubmitCallbacks) => {
			onSubmit?.(newOption);

			// note: If these objects happen to include more than what is expected, Relay will complain.
			const { parentOptionValue, childOptionValue } = newOption || {};

			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operation: {
							operation: 'SET',
							parentOption: parentOptionValue?.id || null,
							childOption: childOptionValue?.id || null,
						},
					},
				},
				onCompleted: (response) => {
					if (response.jira?.updateCascadingSelectField?.success) {
						onSuccess();
					} else {
						onFail();
					}
				},
				onError: (error) => onFail(error, error.message),
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				optimisticResponse: {
					jira: {
						updateCascadingSelectField: {
							success: true,
							errors: null,
							field: {
								id: uniqueFieldId,
								name: fieldName,
								fieldId,
								type: fieldType,
								cascadingOption: {
									parentOptionValue,
									childOptionValue,
								},
							},
						},
					},
				} as CascadingSelectIsEditableMutationResponse,
			});
		},
		[commit, fieldId, fieldName, fieldType, onSubmit, uniqueFieldId],
	);

	const actions = useFieldInlineEditActions({
		attributes,
		fieldId,
		fieldName,
		fieldType,
		initialValue,
		onSubmit: handleSubmit,
		onSubmitFailed,
		onSubmitSucceeded,
		onUpdateValue: setUpdatedValue,
		updatedValue,
		isValueEqual: isEqualSelection,
	});

	const {
		hasServerValidationError,
		handleCancel,
		handleEdit,
		handleConfirm,
		handleChange,
		invalidMessage,
		isEditing,
	} = actions;
	// #endregion Action

	// #region View
	const renderReadView = useCallback(
		() => <CascadingSelectReadView fragmentRef={data} isTruncated={isTruncated} />,
		[data, isTruncated],
	);

	const getEditViewProps = (fieldProps: ValidationFieldProps): CascadingSelectEditViewProps => ({
		...fieldProps,
		wrapper: EditViewWrapper,
		fieldId: uniqueFieldId,
		autoFocus: true,
		parentOptionValue: updatedValue?.parentOptionValue,
		childOptionValue: updatedValue?.childOptionValue,
		menuPosition,
		onChange: handleChange,
		spacing,
	});

	return (
		<FieldInlineEditLiteWithEntryPoint
			editViewPopup={editViewPopup}
			editViewPopupAlignBlock={editViewPopupAlignBlock}
			editViewPopupTarget={editViewPopupTarget}
			editViewPopupMinWidth="small"
			editViewEntryPoint={CascadingEditViewEntryPoint}
			editViewEntryPointParams={{}}
			getEditViewProps={getEditViewProps}
			fieldName={fieldName}
			hasUnsubmittedChanges={hasServerValidationError}
			invalidMessage={invalidMessage}
			isEditing={isEditing}
			isEditable={isFieldEditable}
			onCancel={handleCancel}
			onConfirm={handleConfirm}
			onEdit={handleEdit}
			readViewFitContainerHeight={readViewFitContainerHeight}
			readView={renderReadView}
			hideActionButtons={hideActionButtons}
		/>
	);
	// #endregion Action
};

export const CascadingSelectInlineEditViewNew = ({
	fragmentRef,
	...props
}: CascadingSelectInlineEditViewNewProps) => {
	const data = useFragment<CascadingSelectNewFragment>(
		graphql`
			fragment cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditViewNew_fragmentRef on JiraCascadingSelectField {
				...cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditViewIsEditable_fragmentRef
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
	);
	return (
		<CascadingSelectInlineEditViewIsEditable
			{...props}
			fragmentRef={data}
			isEditable={data.fieldConfig?.isEditable ?? false}
		/>
	);
};

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components.
 *
 * @param props [CascadingSelectInlineEditViewProps](./types.tsx)
 */
export const CascadingSelectInlineEditViewOld = ({
	fragmentRef,
	isEditing: startWithEditViewOpen = false,
	readViewFitContainerWidth = true,
	onSubmit,
	onSubmitFailed,
	onSubmitSucceeded,
	spacing = 'default',
}: CascadingSelectInlineEditViewOldProps) => {
	// #region Relay
	const data = useFragment<CascadingSelectOldFragment>(
		graphql`
			fragment cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditViewOld_fragmentRef on JiraCascadingSelectField {
				id
				name
				...cascadingSelect_issueFieldCascadingSelectReadviewFull_CascadingSelectReadView
				cascadingOption {
					parentOptionValue {
						id
						value
						isDisabled
					}
					childOptionValue {
						id
						value
						isDisabled
					}
				}
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
	);

	const { id: uniqueFieldId, name: fieldName, fieldConfig } = data;
	const cascadingOption: CascadingSelectValuePair = useMemo(
		() => ({
			parentOptionValue: data.cascadingOption?.parentOptionValue
				? {
						id: data.cascadingOption?.parentOptionValue?.id ?? '',
						value: data.cascadingOption?.parentOptionValue?.value ?? '',
						isDisabled: data.cascadingOption?.parentOptionValue?.isDisabled,
					}
				: null,
			childOptionValue: data.cascadingOption?.childOptionValue
				? {
						id: data.cascadingOption?.childOptionValue?.id ?? '',
						value: data.cascadingOption?.childOptionValue?.value ?? '',
						isDisabled: data.cascadingOption?.childOptionValue?.isDisabled,
					}
				: null,
		}),
		[data.cascadingOption?.childOptionValue, data.cascadingOption?.parentOptionValue],
	);

	const [commit] = useMutation<CascadingSelectMutation>(graphql`
		mutation cascadingSelect_issueFieldCascadingSelect_CascadingSelectField_Mutation(
			$input: JiraUpdateCascadingSelectFieldInput!
		) @raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateCascadingSelectField(input: $input) {
					success
					errors {
						message
					}
					field {
						...cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditViewOld_fragmentRef
					}
				}
			}
		}
	`);
	// #endregion

	// #region Common state
	const { overriding } = useInlineEditFieldInjections();
	const [isEditing, setIsEditing] = useOptionallyControlledEditingState(
		startWithEditViewOpen,
		uniqueFieldId,
	);
	const [newValue, setNewValue] = useState<CascadingSelectValuePair | null>(cascadingOption);
	const [errors, setErrors] = useState<ErrorsType>(null);
	const { formatMessage } = useIntl();
	const isEditable = fieldConfig?.isEditable || false;

	const isFieldEditable = overriding.overrideIsEditable(isEditable);

	// #endregion

	// #region Handle new value
	useEffect(() => {
		if (isCascadingOptionExist(cascadingOption)) {
			setNewValue(cascadingOption);
		}
	}, [cascadingOption]);

	const onCancelRequest = useCallback(() => {
		setErrors(null);
		setIsEditing(false);
		// clear the user's state so it reflects the last server state
		setNewValue({
			parentOptionValue: cascadingOption?.parentOptionValue ?? null,
			childOptionValue: cascadingOption?.childOptionValue ?? null,
		});
	}, [cascadingOption?.childOptionValue, cascadingOption?.parentOptionValue, setIsEditing]);

	const onEditRequest = useCallback(() => {
		setErrors(null);
		setIsEditing(true);
	}, [setIsEditing]);

	const onCommitError = useCallback(
		(responseErrors: ErrorsType) => {
			setErrors(responseErrors);
			setIsEditing(true);
			onSubmitFailed?.();
		},
		[onSubmitFailed, setIsEditing],
	);

	const onCommitCompleted = useCallback(
		(response: CascadingSelectMutationData, newOption?: CascadingSelectValuePair | null) => {
			if (!response.jira?.updateCascadingSelectField) {
				onCommitError([]);
				return;
			}

			const { success, errors: responseErrors } = response.jira.updateCascadingSelectField;
			if (!success) {
				onCommitError(responseErrors);
				return;
			}
			setNewValue(newOption ?? null);
			onSubmitSucceeded?.(newOption);
		},
		[onSubmitSucceeded, onCommitError],
	);

	const handleNewValue = useCallback(
		(newOption?: CascadingSelectValuePair | null) => {
			// Clear errors and exit editing mode
			setErrors(null);
			setIsEditing(false);

			// note: If these objects happen to include more than what is expected, Relay will complain.
			const { parentOptionValue, childOptionValue } = newOption || {};

			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operation: {
							operation: 'SET',
							parentOption: parentOptionValue?.id || null,
							childOption: childOptionValue?.id || null,
						},
					},
				},
				onCompleted(res) {
					onCommitCompleted(res, newOption);
				},
				onError(error) {
					onCommitError([error]);
				},
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				optimisticResponse: {
					jira: {
						updateCascadingSelectField: {
							success: true,
							errors: null,
							field: {
								id: uniqueFieldId,
								name: fieldName,
								cascadingOption: {
									parentOptionValue,
									childOptionValue,
								},
								fieldConfig: { isEditable: true },
							},
						},
					},
				} as CascadingSelectMutationResponse,
			});
		},
		[commit, fieldName, onCommitCompleted, onCommitError, setIsEditing, uniqueFieldId],
	);
	// #endregion

	// #region Common callbacks
	const onConfirmRequest = useCallback(() => {
		handleNewValue(newValue);
		onSubmit?.(newValue);
	}, [newValue, handleNewValue, onSubmit]);
	// #endregion

	// #region Read view
	const renderReadView = useCallback(
		() => (
			<ReadViewContainer data-testid="issue-field-cascading-select-inline-edit-full.ui.cascading-select.read-view">
				<CascadingSelectReadView fragmentRef={data} />
			</ReadViewContainer>
		),
		[data],
	);
	// #endregion

	// #region Edit view
	const renderEditView = useCallback(
		() => (
			<>
				{errors && errors.length > 0 && <ErrorMessage>{errors[0].message}</ErrorMessage>}

				<EditViewContainer data-testid="issue-field-cascading-select-inline-edit-full.ui.cascading-select.edit-view">
					<CascadingSelectEditView
						autoFocus
						fieldId={uniqueFieldId}
						ariaLabel={fieldName}
						parentOptionValue={newValue?.parentOptionValue}
						childOptionValue={newValue?.childOptionValue}
						onChange={setNewValue}
						spacing={spacing}
					/>
				</EditViewContainer>
			</>
		),
		[
			errors,
			fieldName,
			newValue?.childOptionValue,
			newValue?.parentOptionValue,
			spacing,
			uniqueFieldId,
		],
	);
	// #endregion

	const [, prefetchOptions, abortPrefetchOptions] =
		useQueryLoaderOnIntent<CascadingSelectInlineEditViewPrefetchQuery>(graphql`
			query cascadingSelect_issueFieldCascadingSelect_PrefetchQuery(
				$id: ID!
				$selectedParentIdFilter: JiraFieldOptionIdsFilterInput!
				$isSelectedParentIdSet: Boolean!
				$first: Int
				$searchBy: String
				$filterById: JiraFieldOptionIdsFilterInput
				$childOptionsFilterById: JiraFieldOptionIdsFilterInput
			) {
				# eslint-disable-next-line @atlassian/relay/must-colocate-fragment-spreads
				...cascadingSelect_issueFieldCascadingSelectEditviewFull_CascadingSelectEditViewWithFieldOptionsFragment
					@arguments(
						id: $id
						selectedParentIdFilter: $selectedParentIdFilter
						isSelectedParentIdSet: $isSelectedParentIdSet
						first: $first
						searchBy: $searchBy
						filterById: $filterById
						childOptionsFilterById: $childOptionsFilterById
					)
			}
		`);

	return (
		<InlineEditContainer
			isEditable={isFieldEditable}
			onPointerEnter={() =>
				prefetchOptions(
					getCascadingSelectEditViewSuggestionsVariables({
						id: uniqueFieldId,
						selectedParentId: newValue?.parentOptionValue?.id,
						queryString: '',
						filterParentOptionsById: null,
						filterChildOptionsById: null,
						first: FRAGMENT_SELECTABLE_FIELD_OPTIONS_FIRST,
					}),
				)
			}
			onPointerLeave={(e) => e.pointerType === 'mouse' && abortPrefetchOptions()}
		>
			<FieldInlineEditStateLess
				editView={renderEditView}
				isEditable={isFieldEditable}
				isEditing={isEditing}
				isLabelHidden
				label={fieldName}
				readView={renderReadView}
				readViewFitContainerWidth={readViewFitContainerWidth}
				testId="issue-field-cascading-select-inline-edit-full.ui.cascading-select.field-inline-edit-state-less"
				fieldId={fg('one_event_rules_them_all_fg') ? uniqueFieldId : undefined}
				hideActionButtons={false}
				cancelButtonLabel={formatMessage(messages.cancelButtonLabel, {
					fieldName,
				})}
				confirmButtonLabel={formatMessage(messages.confirmButtonLabel, {
					fieldName,
				})}
				editButtonLabel={formatMessage(messages.editButtonLabel, {
					fieldName,
				})}
				onCancel={onCancelRequest}
				onConfirm={onConfirmRequest}
				onEdit={onEditRequest}
			/>
		</InlineEditContainer>
	);
};

export const CascadingSelectInlineEditView = ({
	fragmentRef,
	...props
}: CascadingSelectInlineEditViewProps) => {
	const data = useFragment<CascadingSelectFragment>(
		graphql`
			fragment cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditView_fragmentRef on JiraCascadingSelectField {
				...cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditViewNew_fragmentRef
				...cascadingSelect_issueFieldCascadingSelectInlineEditFull_CascadingSelectInlineEditViewOld_fragmentRef
			}
		`,
		fragmentRef,
	);

	if (fg('jsc_inline_editing_field_refactor')) {
		return <CascadingSelectInlineEditViewNew {...props} fragmentRef={data} />;
	}

	return <CascadingSelectInlineEditViewOld {...props} fragmentRef={data} />;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditViewContainer = styled.div({
	zIndex: 300,
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewContainer = styled.div({
	display: 'flex',
	flex: '1 1 auto',
	wordBreak: 'break-word',
	position: 'relative',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '20px',
});

const nonEditableStyles = css({
	display: 'flex',
	flex: '1 1 auto',
	wordBreak: 'break-word',
	position: 'relative',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '20px',
	paddingLeft: token('space.075'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineEditContainer = styled.div<{ isEditable: boolean }>(
	{
		width: '100%',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& div[data-read-view-fit-container-width]': {
			display: 'flex',
			alignItems: 'center',
			width: '100%',
			minHeight: '32px',
			paddingTop: 0,
			paddingBottom: 0,
			paddingLeft: token('space.075'),
			paddingRight: token('space.075'),
		},
		marginTop: `${token('space.negative.100')}`,
		marginLeft: token('space.negative.100'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isEditable }) => (!isEditable ? nonEditableStyles : undefined),
);

const wrapperStyles = xcss({
	zIndex: 'modal',
	position: 'relative',
});
