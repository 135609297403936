import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireScreenAnalytics, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import { sendPendoTrackEvent } from '../../pendo/index.tsx';
import { getViewViewedPendoEventAttributes } from '../../../common/utils/get-view-viewed-pendo-event-attributes.tsx';

export const ListView = {
	opened: (event: UIAnalyticsEvent, accessLevel?: string) => {
		if (fg('jpd_issues_relationships')) {
			const attributes = getViewViewedPendoEventAttributes(event);
			if (attributes.viewHasConnectionFields) {
				sendPendoTrackEvent({ actionSubjectAndAction: 'viewWithConnectionFields opened' });
			}

			// https://app.pendo.io/s/4758009750487040/trackevents/M2-9XepSbr5FNP9yFynxrzIQUZQ
			sendPendoTrackEvent({
				actionSubjectAndAction: 'listView opened',
				attributes: { accessLevel: accessLevel || '', ...attributes },
			});
		} else {
			// https://app.pendo.io/s/4758009750487040/trackevents/M2-9XepSbr5FNP9yFynxrzIQUZQ
			sendPendoTrackEvent({
				actionSubjectAndAction: 'listView opened',
				attributes: { accessLevel: accessLevel || '' },
			});
		}

		// https://data-portal.internal.atlassian.com/analytics/registry/51087
		fireScreenAnalytics(event, { accessLevel });
	},
	fieldValueUpdatedByDragBetweenSwimlanes: (fieldType: string | undefined) => {
		sendPendoTrackEvent(`fieldValue updated ${fieldType} listDragBetweenSwimlanes`);
	},
	fieldValueUpdatedByInlineEdit: (fieldType: string | undefined) => {
		sendPendoTrackEvent(`fieldValue updated ${fieldType} listInlineEdit`);
	},
	columnActionItemClicked: (
		event: UIAnalyticsEvent,
		attributes: { selectedActionItem: string },
	) => {
		sendPendoTrackEvent(`listViewColumnActionItem ${attributes.selectedActionItem}`);

		fireUIAnalytics(event, 'dropdownItem clicked', 'viewFieldActions', attributes);
	},
	learnMoreAboutArchiveButtonClicked: (event: UIAnalyticsEvent) => {
		// https://data-portal.internal.atlassian.com/analytics/registry/56971
		fireUIAnalytics(event, 'button clicked', 'learnMoreAboutArchivingIdeas', {});
	},
	addIdeaFixedBottomRowClicked: (event: UIAnalyticsEvent, grouped: string) => {
		// https://data-portal.internal.atlassian.com/analytics/registry/75128
		fireUIAnalytics(event, 'button clicked', 'addIdeaFixedRow', {
			grouped,
		});
	},
};
