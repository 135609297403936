import {
	isIssueTypeIdFilter,
	isIssueTypeNameFilter,
	type ConnectionFieldIssueTypeFilter,
} from '@atlassian/jira-polaris-domain-field/src/field/connection/types.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';

type IsMatchingConnectionFieldFilter = {
	filters: ConnectionFieldIssueTypeFilter[];
	issueTypeId: IssueTypeId | undefined;
	issueTypeName?: string;
};

export const isMatchingConnectionFieldFilter = ({
	filters,
	issueTypeId,
	issueTypeName,
}: IsMatchingConnectionFieldFilter) => {
	if (!issueTypeId) {
		return false;
	}

	return filters.some((filter) => {
		if (isIssueTypeNameFilter(filter) && issueTypeName) {
			return filter.names.includes(issueTypeName);
		}

		if (isIssueTypeIdFilter(filter)) {
			return filter.ids.includes(issueTypeId);
		}

		// In case of unsupported filter type
		return false;
	});
};
