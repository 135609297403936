import React from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { defaultSelectStyles } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/styled.tsx';
import { fireUIAnalytics, MountEvent } from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import { AppLinkOption } from '../app-link-option/index.tsx';
import type { SelectItemAppLinkEntry } from '../app-link-option/types.tsx';
import { messages } from './messages.tsx';
import { SelectWithAnalytics } from './select-with-analytics/index.tsx';
import type { Props, SelectItemAppLink } from './types.tsx';

const customSelectStyle = {
	...defaultSelectStyles,
	// @ts-expect-error - TS7006 - Parameter 'base' implicitly has an 'any' type. | TS7006 - Parameter 'state' implicitly has an 'any' type.
	menu: (base, state) => {
		const defaultMenu = defaultSelectStyles.menu ? defaultSelectStyles.menu(base, state) : {};
		return {
			...defaultMenu,
			minWidth: state.selectProps.containerWidth || 'auto',
		};
	},
	// @ts-expect-error - TS7019 - Rest parameter 'args' implicitly has an 'any[]' type.
	option: (...args) => {
		// @ts-expect-error - TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter.
		const defaultOption = defaultSelectStyles.option ? defaultSelectStyles.option(...args) : {};
		return { ...defaultOption, overflow: 'initial' };
	},
} as const;

export const AppLinksSelect = (props: Props) => {
	const { selectType } = props;
	const { formatMessage } = useIntl();
	const formatOptionLabel = (option: SelectItemAppLinkEntry) => (
		<AppLinkOption
			value={option}
			onAuthenticateAppLink={props.onAuthenticateAppLink}
			selectType={selectType}
		/>
	);

	const getNumAppLinksRequiringAuth = () =>
		props.selectItems.filter((item) => item.fullObject.requireCredentials).length;

	const sendMenuAnalytics = (analyticsEvent: UIAnalyticsEvent, action: string) => {
		analyticsEvent &&
			fireUIAnalytics(analyticsEvent, `issueView.${selectType} ${action}`, selectType, {
				numAppLinks: props.selectItems.length - 1,
				numAppLinksRequiringAuth: getNumAppLinksRequiringAuth(),
			});
	};

	const onChange = (option: SelectItemAppLink, analyticsEvent: UIAnalyticsEvent) => {
		if (option.fullObject.requireCredentials && fg('linked_issue_a11y_bug_fix')) {
			props.onAuthenticateAppLink(option.fullObject.authUrl, analyticsEvent);
		} else {
			props.onChange(option);
		}
		analyticsEvent &&
			fireUIAnalytics(analyticsEvent, `issueView.${selectType} changed`, selectType, {
				appLinkId: option.fullObject.id,
			});
	};

	const { selectItems, value, containerWidth } = props;

	const selectAriaLabel =
		selectType === 'JiraAppLinksSelect'
			? formatMessage(messages.jiraInstanceSelectorAriaLabel)
			: formatMessage(messages.confluenceInstanceSelectorAriaLabel);

	return (
		<ErrorBoundary>
			<ReportErrors packageName="jiraIssueViewCommonViews" id={selectType}>
				<SelectWithAnalytics
					aria-label={selectAriaLabel}
					placeholder={formatMessage(messages.placeholderText)}
					options={selectItems}
					value={value}
					// @ts-expect-error - TS7006 - Parameter 'option' implicitly has an 'any' type. | TS7006 - Parameter 'actionMeta' implicitly has an 'any' type. | TS7006 - Parameter 'analyticsEvent' implicitly has an 'any' type.
					onChange={(option, actionMeta, analyticsEvent) => onChange(option, analyticsEvent)}
					formatOptionLabel={formatOptionLabel}
					// @ts-expect-error - TS7006 - Parameter 'option' implicitly has an 'any' type.
					isOptionDisabled={(option) => {
						if (fg('linked_issue_a11y_bug_fix')) {
							return false;
						}
						return option.fullObject.requireCredentials;
					}}
					styles={customSelectStyle}
					// @ts-expect-error - TS7006 - Parameter 'analyticsEvent' implicitly has an 'any' type.
					onMenuOpen={(analyticsEvent) => sendMenuAnalytics(analyticsEvent, 'opened')}
					// @ts-expect-error - TS7006 - Parameter 'analyticsEvent' implicitly has an 'any' type.
					onMenuClose={(analyticsEvent) => sendMenuAnalytics(analyticsEvent, 'closed')}
					containerWidth={containerWidth}
				/>
				<MountEvent
					onMount={(analyticsEvent: UIAnalyticsEvent) => {
						analyticsEvent &&
							fireUIAnalytics(analyticsEvent, `issueView.${selectType} mounted`, selectType, {
								numAppLinks: selectItems.length - 1,
								numAppLinksRequiringAuth: getNumAppLinksRequiringAuth(),
							});
					}}
				/>
			</ReportErrors>
		</ErrorBoundary>
	);
};

export default AppLinksSelect;
