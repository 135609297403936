/**
 * @generated SignedSource<<6d4510132be2664eea08b6e403b08d61>>
 * @relayHash 5b333b762bc16d374fa0211843032fd3
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a3765b07c05c0bef8c3a5ad567d6e189c38c233a0c3b8f76fc59c63a177d0e5c

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { PlaybooksPanelBodyQuery } from './PlaybooksPanelBodyQuery.graphql';

const node: PreloadableConcreteRequest<PlaybooksPanelBodyQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "a3765b07c05c0bef8c3a5ad567d6e189c38c233a0c3b8f76fc59c63a177d0e5c",
    "metadata": {},
    "name": "PlaybooksPanelBodyQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
