import head from 'lodash/head';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { createExperienceWithMetaType } from '@atlassian/jira-polaris-lib-analytics/src/common/utils/experience/main.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';

const fieldOptionUpdateExperience = createExperienceWithMetaType(
	experience.fieldsSidebar.fieldOptionUpdate,
	'rankOptions',
	(err) => `FieldOptionUpdate: ${err?.message ?? 'generic error'}`,
);

export const rankOptions =
	(fieldKey: FieldKey, rankedOptionIds: string[]) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ onFieldUpdateFailed, projectId, createAnalyticsEvent, fieldRemote, issueTypeIds }: Props,
	) => {
		const state = getState();
		const previousOptions = state.fields[fieldKey].options;
		if (previousOptions === undefined) {
			return;
		}

		const newOptions = rankedOptionIds
			.map((id) => previousOptions.find((option) => option.jiraOptionId === id))
			.filter(Boolean);

		const fields = {
			...state.fields,
			[fieldKey]: {
				...state.fields[fieldKey],
				options: newOptions,
			},
		};

		const issueTypeId = head(issueTypeIds);

		setState({ fields });

		if (projectId !== undefined && issueTypeId !== undefined) {
			fieldOptionUpdateExperience.start();

			fieldRemote
				.updateFieldOptionRank({
					fieldKey,
					rankedOptionIds: newOptions.map((option) => option.jiraOptionId),
					issueTypeId,
				})
				.then(() => {
					fieldOptionUpdateExperience.success();

					fireCompoundAnalyticsEvent.FieldConfig.optionRankChanged(createAnalyticsEvent({}), {
						issueFieldKey: fieldKey,
						issueFieldType: fields[fieldKey].type,
					});
				})
				.catch((error: Error) => {
					fieldOptionUpdateExperience.failure(error);

					onFieldUpdateFailed(error);
				});
		}
	};
