import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { createGetFieldMapping, createGetFieldsOfType } from '../../selectors/fields.tsx';
import type { Props, State } from '../../types.tsx';

export type FieldValueSyncTask = {
	identity: (item: unknown) => boolean;
	newValue: unknown | undefined;
};

/**
 * use this to update local issue state when a referenced object has changed
 * in a different source-of truth store
 * e.g. issue type names, properties etc.
 * cannot be used to create new reference values!
 * pure state operation without (backend) side effects
 * @param fieldType the type of the changed reference value
 * @param identity a function to determine the changed reference value (as ids can be different)
 * @param newValue the new value of the reference value, deletion if undefined
 */
export const syncFieldValues =
	(fieldType: FieldType, syncTasks: FieldValueSyncTask[]) =>
	({ getState, setState }: StoreActionApi<State>, props: Props) => {
		const affectedFields = createGetFieldsOfType(fieldType)(getState(), props);

		affectedFields.forEach((field) => {
			const mapping = createGetFieldMapping(field.key)(getState(), props);
			if (mapping) {
				const allValues = mapping.getAllValues(getState(), props);

				let newMaps = getState().properties;

				Object.keys(allValues).forEach((issueId: LocalIssueId) => {
					const value = allValues[issueId];

					syncTasks.forEach(({ identity, newValue }) => {
						if (identity(value)) {
							if (newValue !== undefined) {
								// update
								newMaps = mapping.setImmutable(newMaps, issueId, newValue);
							} else {
								// delete
								newMaps = mapping.remove(newMaps, issueId);
							}
						}
					});
				});
				setState({
					properties: newMaps,
				});
			}
		});
	};
