/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type Props = {
	placeholder?: string;
	onClick?: () => void;
	onFocus?: () => void;
};

export const CommentTextAreaPlaceholder = ({ placeholder, onClick }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<button
			data-testid="issue-view-activity-comment.comment-add-editor.textarea"
			css={textAreaStyles}
			onClick={onClick}
		>
			{placeholder || formatMessage(messages.defaultPlaceholderMessages)}
		</button>
	);
};

// NOTE: Styling gets copied from platform/packages/editor/editor-core/src/ui/ChromeCollapsed/styles.ts and adjusted to the design
// Except the focus visible state which is new
const textAreaStyles = css({
	resize: 'none',
	appearance: 'none',
	// NOTE: before changing this.
	// see src/packages/issue/issue-view-activity-comment/src/comment-add-editor/comments-placeholder.tsx:22
	backgroundColor: `var(--jira-issue-view-add-comment-placeholder-background-color, ${token('color.background.input')})`,
	borderWidth: '1px',
	borderStyle: 'solid',
	borderColor: `${token('color.border.input')}`,
	// eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "4px" and fallback "3px" do not match and can't be replaced automatically.
	borderRadius: token('border.radius', '3px'),
	boxSizing: 'border-box',
	height: '40px',
	paddingLeft: token('space.250'),
	paddingTop: token('space.150'),
	paddingBottom: token('space.150'),
	font: token('font.body'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: 1,
	width: '100%',
	color: token('color.text.subtlest'),
	display: 'inline-flex',
	alignItems: 'flex-start',
	'&:hover': {
		// NOTE: before changing this.
		// see src/packages/issue/issue-view-activity-comment/src/comment-add-editor/comments-placeholder.tsx:22
		backgroundColor: `var(--jira-issue-view-add-comment-placeholder-background-color, ${token('color.background.input.hovered')})`,
		borderColor: token('color.border.input'),
		cursor: 'text',
	},
	'&:focus': {
		outline: 'none',
	},
	'&:focus-visible': {
		outline: `2px solid ${token('color.border.focused')}`,
		outlineOffset: token('space.025'),
	},
});
