import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';

export const LOCAL_STORAGE = createLocalStorageProvider('jira-issue-view-comment-placeholder');

export const HIDE_ADD_COMMENT_PLACEHOLDER_KEY = 'hideAddCommentPlaceholder';

export const setHideAddCommentPlaceholder = (value: boolean) => {
	LOCAL_STORAGE.set(HIDE_ADD_COMMENT_PLACEHOLDER_KEY, value);
};

export const getHideCommentPlaceholder = () => {
	return !!LOCAL_STORAGE.get(HIDE_ADD_COMMENT_PLACEHOLDER_KEY);
};
