import memoizeOne from 'memoize-one';
import {
	getUserProperty,
	setUserProperties,
} from '@atlassian/jira-common-rest/src/api/latest/user-properties/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { SHOULD_SHOW_WELCOME_MESSAGE_USER_PROPERTIES_KEY } from '@atlassian/jira-issue-view-common-constants/src/onboarding-constants.tsx';
import type { AccountId, BaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';

const getShouldShowWelcomeTourFromUserPreferencesNew = memoizeOne(
	(baseUrl: BaseUrl, accountId: AccountId | null): Promise<boolean | undefined> =>
		accountId
			? getUserProperty<boolean>(accountId, SHOULD_SHOW_WELCOME_MESSAGE_USER_PROPERTIES_KEY).catch(
					(error) => {
						/**
						 * This API will return 404 error if property key is not found
						 */
						if (error.statusCode === 404) {
							return undefined;
						}

						log.safeErrorWithoutCustomerData(
							'getShouldShowWelcomeTour',
							'Error reading data from getShouldShowWelcomeTour - UserProperty',
							error,
						);
						return false;
					},
				)
			: new Promise((resolve: (result: boolean) => void) => {
					log.safeErrorWithoutCustomerData(
						'getShouldShowWelcomeTour',
						'Failed to get shouldShowWelcomeTour - no accountId',
					);
					resolve(false);
				}),
);

const getShouldShowWelcomeTourFromUserPreferencesOld = (
	baseUrl: BaseUrl,
	accountId: AccountId | null,
): Promise<boolean | undefined> =>
	// @ts-expect-error - TS2322 - Type 'Promise<unknown>' is not assignable to type 'Promise<boolean | undefined>'.
	accountId
		? getUserProperty(accountId, SHOULD_SHOW_WELCOME_MESSAGE_USER_PROPERTIES_KEY).catch((error) => {
				/**
				 * This API will return 404 error if property key is not found
				 */
				if (error.statusCode === 404) {
					return undefined;
				}

				log.safeErrorWithoutCustomerData(
					'getShouldShowWelcomeTour',
					'Error reading data from getShouldShowWelcomeTour - UserProperty',
					error,
				);
				return false;
			})
		: new Promise((resolve: (result: Promise<boolean> | boolean) => void) => {
				log.safeErrorWithoutCustomerData(
					'getShouldShowWelcomeTour',
					'Failed to get shouldShowWelcomeTour - no accountId',
				);
				resolve(false);
			});

export const getShouldShowWelcomeTourFromUserPreferences = functionWithCondition(
	() => fg('user-properties-iv-llc-unnecessary-api'),
	getShouldShowWelcomeTourFromUserPreferencesNew,
	getShouldShowWelcomeTourFromUserPreferencesOld,
);

export const setShouldShowWelcomeTourInUserPreferences = <T,>(
	shouldShowWelcomeMessage: boolean,
	baseUrl: BaseUrl,
	accountId: AccountId | null,
): Promise<T | undefined> =>
	accountId
		? setUserProperties(
				accountId,
				SHOULD_SHOW_WELCOME_MESSAGE_USER_PROPERTIES_KEY,
				String(shouldShowWelcomeMessage),
			).catch((error) => {
				log.safeErrorWithoutCustomerData(
					'setShouldShowWelcomeTourInUserPreferences',
					'Failed to update boolean value in setShouldShowWelcomeTourInUserPreferences - UserProperty',
					error,
				);
			})
		: new Promise(() => {
				log.safeErrorWithoutCustomerData(
					'setShouldShowWelcomeTourInUserPreferences',
					'Failed to update boolean value in setShouldShowWelcomeTourInUserPreferences - no accountId',
				);
			});
