import React from 'react';
import { useIssueId, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { StateContainer } from '../controllers/state/index.tsx';

export const SummaryStateContainer = ({ children }: React.PropsWithChildren<{}>) => {
	const issueKey = useIssueKey();
	const issueId = useIssueId();

	if (issueId == null || issueKey == null) {
		// Outside the context of an issue, we can't show anything meaningful
		return null;
	}

	return <StateContainer scope={issueKey}>{children}</StateContainer>;
};
