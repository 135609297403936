// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createActionsHook,
	createSelector,
	createStateHook,
} from '@atlassian/react-sweet-state';
import * as actions from './actions/index.tsx';
import type { State } from './types.tsx';

const initialState: State = {
	users: {},
	isLoading: false,
	error: undefined,
};

const Store = createStore<State, typeof actions>({
	initialState,
	actions,
	name: 'PolarisCollectionsUsers',
});

export const useUsersActions = createActionsHook(Store);

const getUsers = (state: State) => state.users;
const getUsersList = createSelector(getUsers, (users) =>
	Object.values(users)
		.map((u) => u)
		.filter(Boolean),
);
const getUsersLoading = (state: State) => state.isLoading;
const getUsersError = (state: State) => state.error;
const getUsersHasError = createSelector(getUsersError, (error) => !!error);
const getUserById = createSelector(
	getUsers,
	(_, accountId: string) => accountId,
	(users, accountId) => users[accountId],
);

export const useUsersLoading = createStateHook(Store, { selector: getUsersLoading });
export const useUsersHasError = createStateHook(Store, { selector: getUsersHasError });
export const useUsersList = createStateHook(Store, { selector: getUsersList });
export const useUsers = createStateHook(Store, { selector: getUsers });
export const useUser = createStateHook(Store, { selector: getUserById });
