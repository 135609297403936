import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorLabel: {
		id: 'issue-field-validation-popup.validation-popper.popup-content.error-label',
		defaultMessage: 'Error',
		description: 'Label shown for the validation message error icon.',
	},
	warningLabel: {
		id: 'issue-field-validation-popup.validation-popper.popup-content.warning-label',
		defaultMessage: 'Warning',
		description: 'Label shown for the validation message warning icon.',
	},
});
