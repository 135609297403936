import { AnyAri } from '@atlassian/ari/any-ari';
import { fg } from '@atlassian/jira-feature-gating';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';

export const getUniversalViewId = (view: View): string | undefined => {
	if (fg('jpd-sidebar-v4-for-roadmaps')) {
		if (view.viewId) {
			// `viewId` contains the correct identifier for both:
			// - Projects & Collections.
			//
			// Project's viewId will look like:
			// - `ari:cloud:jira:d8c41c27-ad95-40f9-9e69-2497900efdbe:view/1052268`
			//
			// while collection's view will look like:
			// `ari:cloud:jira:d8c41c27-ad95-40f9-9e69-2497900efdbe:view/3a6710d6-ffb4-4e19-8a92-a7012d8a777f`
			//
			// By parsing `ari` and taking `resourceId` we will always get correct `viewId`
			return AnyAri.parse(view.viewId).resourceId;
		}
	} else {
		return String(view.viewLegacyId);
	}
};
