import React, { useEffect, useState } from 'react';
import { useQueryLoader } from 'react-relay';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import PanelViewCustomerQuery, {
	type panelViewOrganizationQuery,
} from '@atlassian/jira-relay/src/__generated__/panelViewOrganizationQuery.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { setTraceId as setTraceIdAtFetch } from '@atlassian/jira-servicedesk-customer-service-common/src/utils/trace-id/index.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import type { OrganizationOption, PanelSource } from '../../types.tsx';
import { ExpandPanel } from '../expand-panel/index.tsx';
import { OrganizationField } from './organization-field/index.tsx';
import { PanelView } from './panel-view/index.tsx';

export const OrganizationOld = ({
	panelSource,
	organization,
	isLast,
}: {
	panelSource: PanelSource;
	organization: OrganizationOption;
	isLast?: boolean;
}) => {
	const cloudId = useCloudId();
	const issueId = useIssueId() || '';
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [queryReference, loadQuery, disposeQuery] =
		useQueryLoader<panelViewOrganizationQuery>(PanelViewCustomerQuery);
	const hasQueryReference = !!queryReference;

	useEffect(() => {
		if (!isOpen && hasQueryReference) {
			disposeQuery();
		}
	}, [disposeQuery, hasQueryReference, isOpen]);

	const onToggle = () => {
		setIsOpen((prev) => {
			if (!prev && organization?.id) {
				loadQuery({
					cloudId,
					organizationId: organization.id,
					filter: { context: { type: 'ISSUE', issueId } },
				});
			}

			return !prev;
		});
	};

	return (
		<ExpandPanel
			onToggle={onToggle}
			field={<OrganizationField value={organization} />}
			isOpen={isOpen}
			id={`organization-${organization.id}`}
			source={panelSource}
		>
			{isOpen && queryReference && (
				<div data-testid="servicedesk-customer-service-issue-view-panel.common.ui.organization.div">
					<PanelView queryReference={queryReference} isLast={isLast} />
				</div>
			)}
		</ExpandPanel>
	);
};

export const OrganizationNew = ({
	panelSource,
	organization,
	isLast,
}: {
	panelSource: PanelSource;
	organization: OrganizationOption;
	isLast?: boolean;
}) => {
	const cloudId = useCloudId();
	const issueId = useIssueId() || '';
	const [traceId, setTraceId] = useState<string>('');
	const [queryReference, loadQuery, disposeQuery] =
		useQueryLoader<panelViewOrganizationQuery>(PanelViewCustomerQuery);
	const hasQueryReference = !!queryReference;
	const isOpen = !!traceId;

	useEffect(() => {
		if (!isOpen && hasQueryReference) {
			disposeQuery();
		}
	}, [disposeQuery, hasQueryReference, isOpen]);

	const onToggle = () => {
		setTraceId((prev) => {
			if (prev === '' && organization?.id) {
				const newTraceId = setTraceIdAtFetch();

				loadQuery({
					cloudId,
					organizationId: organization.id,
					filter: { context: { type: 'ISSUE', issueId } },
				});

				return newTraceId;
			}

			return '';
		});
	};

	return (
		<ExpandPanel
			onToggle={onToggle}
			field={<OrganizationField value={organization} />}
			isOpen={isOpen}
			id={`organization-${organization.id}`}
			source={panelSource}
		>
			{isOpen && queryReference && (
				<div data-testid="servicedesk-customer-service-issue-view-panel.common.ui.organization.div">
					<PanelView queryReference={queryReference} isLast={isLast} traceId={traceId} />
				</div>
			)}
		</ExpandPanel>
	);
};

export const Organization = componentWithCondition(
	() => fg('berry-4911_issue_view_org_expand_ufo_data'),
	OrganizationNew,
	OrganizationOld,
);
