import { useEffect, useState } from 'react';
import { useViewIdsByViewUUID } from '@atlassian/jira-polaris-component-view-id-mapping/src/index.tsx';
import type { ViewUUID } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createStateHook, createActionsHook } from '@atlassian/react-sweet-state';
import type { UserData, ViewLastSeen } from '../../domain/types.tsx';
import { actions } from './actions/index.tsx';
import { getSortedLastVisitors } from './selectors/index.tsx';
import type { State } from './types.tsx';

const initialState: State = {
	lastSeen: {},
	users: {},
};

const Store = createStore({
	initialState,
	actions,
	name: 'PolarisViewMetadataStore',
});

const useLastSeenRecords = createStateHook(Store, { selector: (state) => state.lastSeen });
export const useLastSeenRecord = (viewUUID: ViewUUID | undefined) => {
	const lastSeenRecords = useLastSeenRecords();
	if (viewUUID === undefined) {
		return undefined;
	}
	return lastSeenRecords[viewUUID];
};
export const useLastSeenRecordAvailable = (viewUUID: ViewUUID | undefined) => {
	const lastSeen = useLastSeenRecord(viewUUID);
	if (lastSeen === undefined || lastSeen.loading || lastSeen.error) {
		return undefined;
	}
	return true;
};
export const useUserData = createStateHook(Store, {
	selector: (state, { viewUUID }: { viewUUID: string }): Record<AccountId, UserData> =>
		state.users[viewUUID] || {},
});
export const useSortedVisitors = createStateHook(Store, {
	selector: (state, { viewUUID }: { viewUUID: string }) => getSortedLastVisitors(state, viewUUID),
});

export const useActions = createActionsHook(Store);

export const useViewLastSeen = (viewUUID: ViewUUID): ViewLastSeen => {
	const [data, setData] = useState<ViewLastSeen>({ loading: true, data: {} });
	const record = useLastSeenRecord(viewUUID);
	const { loadViewsLastSeen } = useActions();
	const viewIds = useViewIdsByViewUUID({ viewUUID });

	useEffect(() => {
		if (record !== undefined) {
			setData(record);
		} else {
			loadViewsLastSeen(viewIds);
		}
	}, [loadViewsLastSeen, viewIds, record]);

	return data;
};
