import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { ViewKind } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { isClientFetchError } from '@atlassian/jira-fetch/src/index.tsx';
import { isPermissionError } from '@atlassian/jira-polaris-lib-errors/src/controllers/utils.tsx';
import {
	getCurrentViewSharingLinkEnabled,
	getIsCurrentViewSharingStakeholdersLoading,
	getIsCurrentViewSharingStakeholdersInitialLoading,
} from '../../selectors/index.tsx';
import type { State, Props } from '../../types.tsx';
import { setSharingStakeholdersForView } from '../utils.tsx';

type ViewAnalyticsData = {
	viewAri?: string;
	viewKind?: ViewKind;
};

export const loadSharingStakeholders =
	(viewUUID: string, { viewAri, viewKind }: ViewAnalyticsData): Action<State, Props> =>
	async ({ getState, dispatch }, { viewRemote, createAnalyticsEvent }) => {
		const isSharingStakeholdersLoading = getIsCurrentViewSharingStakeholdersLoading(getState());
		const isSharingStakeholdersInitialLoading =
			getIsCurrentViewSharingStakeholdersInitialLoading(getState());

		if (!isSharingStakeholdersInitialLoading && isSharingStakeholdersLoading) {
			return;
		}

		dispatch(setSharingStakeholdersForView(viewUUID, { isLoading: true }));

		try {
			experience.sharing.loadStakeholders.start();

			const stakeholders = await viewRemote.fetchSharingStakeholders(viewUUID);
			dispatch(setSharingStakeholdersForView(viewUUID, { stakeholders }));

			const sharingLinkEnabled = getCurrentViewSharingLinkEnabled(getState());
			const analyticsAttributes = {
				stakeholdersCount: stakeholders.users.length,
				groupsCount: stakeholders.groups.length,
				isPublished: sharingLinkEnabled === true,
			};
			fireTrackAnalytics(
				createAnalyticsEvent({
					containers: {
						view: {
							id: viewAri || '',
							type: viewKind?.toLowerCase() || '',
						},
					},
				}),
				'stakeholdersList loaded',
				analyticsAttributes,
			);
			sendPendoTrackEvent({
				actionSubjectAndAction: 'stakeholdersList loaded',
				attributes: analyticsAttributes,
			});

			experience.sharing.loadStakeholders.success();
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireErrorAnalytics(
				createErrorAnalytics('polaris.view-sharing.load-stakeholders-action', error),
			);

			if (isClientFetchError(error) || isPermissionError(error)) {
				experience.sharing.loadStakeholders.abort(error);
			} else {
				experience.sharing.loadStakeholders.failure(error);
			}

			dispatch(
				setSharingStakeholdersForView(viewUUID, {
					stakeholders: undefined,
					error,
				}),
			);
		}
	};

export type StakeholdersToAdd = {
	accountIds: string[];
	emails: string[];
	groupIds: string[];
};

// Returns list of ids and emails for accounts that failed to add
export const addSharingStakeholders =
	(
		viewUUID: string,
		{ accountIds, emails, groupIds }: StakeholdersToAdd,
		viewAnalyticsData: ViewAnalyticsData,
	): Action<State, Props, Promise<string[]>> =>
	async ({ dispatch }, { viewRemote, onActionFailed }) => {
		try {
			experience.sharing.addStakeholders.start();

			const { failures } = await viewRemote.addSharingStakeholders(viewUUID, {
				accountIds,
				emails,
				groupIds,
			});

			await dispatch(loadSharingStakeholders(viewUUID, viewAnalyticsData));

			experience.sharing.addStakeholders.success();
			return [...(failures?.accountIds || []), ...(failures?.emails || [])];
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireErrorAnalytics(
				createErrorAnalytics('polaris.view-sharing.add-stakeholders-action', error),
			);

			if (isClientFetchError(error) || isPermissionError(error)) {
				experience.sharing.addStakeholders.abort(error);
			} else {
				experience.sharing.addStakeholders.failure(error);
			}

			onActionFailed(error);

			return [...accountIds, ...emails, ...groupIds];
		}
	};

type StakeholdersToRemove = {
	accountIds: string[];
	groupIds: string[];
};

export const removeSharingStakeholders =
	(
		viewUUID: string,
		{ accountIds, groupIds }: StakeholdersToRemove,
	): Action<State, Props, Promise<void>> =>
	async ({ getState, dispatch }, { viewRemote, onActionFailed }) => {
		const state = getState();
		const stakeholders = state.sharingStakeholders[viewUUID]?.stakeholders;

		if (!stakeholders) {
			return;
		}

		try {
			experience.sharing.removeStakeholders.start();

			await viewRemote.removeSharingStakeholders(viewUUID, { accountIds, groupIds });

			dispatch(
				setSharingStakeholdersForView(viewUUID, {
					stakeholders: {
						users: stakeholders.users.filter(({ id }) => !accountIds.includes(id)),
						groups: stakeholders.groups.filter(({ id }) => !groupIds.includes(id)),
					},
				}),
			);

			experience.sharing.removeStakeholders.success();
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireErrorAnalytics(
				createErrorAnalytics('polaris.view-sharing.remove-stakeholders-action', error),
			);

			if (isClientFetchError(error) || isPermissionError(error)) {
				experience.sharing.removeStakeholders.abort(error);
			} else {
				experience.sharing.removeStakeholders.failure(error);
			}

			onActionFailed(error);
		}
	};
