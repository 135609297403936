import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { useIntl } from '@atlassian/jira-intl';
import { JiraIssueAri } from '@atlassian/ari/jira/issue';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { cardCoverManagerEntryPoint } from '@atlassian/jira-card-cover-manager/entrypoint.tsx';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import type { RuntimePropsOfEntryPoint } from '@atlassian/jira-entry-point/src/common/types.tsx';
import type { CoverMedia } from '@atlassian/jira-card-cover-manager/src/common/cover-media.types.tsx';
import { useIssueViewCoverStore } from '@atlassian/jira-issue-view-cover/src/store.tsx';
import {
	ISSUE_VIEW_COVER_MEDIA_READ_TOKEN_DURATION,
	ISSUE_VIEW_COVER_MEDIA_READ_TOKEN_MAX_LENGTH,
} from '@atlassian/jira-issue-view-cover/src/constants.tsx';
import { CardCoverSkeleton } from '@atlassian/jira-card-cover-manager/src/ui/CardCoverSkeleton.tsx';
import type { Props } from './types.tsx';
import messages from './messages.tsx';

const EditCoverIssue = ({ onClick, label, itemKey }: Props) => {
	const { formatMessage } = useIntl();
	const [, { updateCoverMedia }] = useIssueViewCoverStore();

	const cloudId = useCloudId();
	const issueId = useIssueId();
	const [isOpen, setIsOpen] = useState(false);

	const {
		entryPointActions: cardCoverManagerEntryPointActions,
		entryPointReferenceSubject: cardCoverManagerEntryPointReferenceSubject,
	} = useEntryPoint(
		cardCoverManagerEntryPoint,
		useMemo(
			() => ({
				cloudId,
				issueId: JiraIssueAri.create({
					issueId: String(issueId),
					siteId: cloudId,
				}).toString(),
				durationInSeconds: ISSUE_VIEW_COVER_MEDIA_READ_TOKEN_DURATION,
				maxTokenLength: ISSUE_VIEW_COVER_MEDIA_READ_TOKEN_MAX_LENGTH,
			}),
			[cloudId, issueId],
		),
	);

	const onCardCoverUpdated = useCallback<
		RuntimePropsOfEntryPoint<typeof cardCoverManagerEntryPoint>['onUpdated']
	>(
		(coverMedia: CoverMedia) => {
			updateCoverMedia(coverMedia);
		},
		[updateCoverMedia],
	);

	useEffect(() => {
		cardCoverManagerEntryPointActions.load();
	}, [cardCoverManagerEntryPointActions]);

	const handleClick = useCallback(
		(e: React.MouseEvent<Element> | React.KeyboardEvent<Element>) => {
			e.stopPropagation();
			setIsOpen(!isOpen);
			onClick?.(itemKey);
		},
		[onClick, itemKey, isOpen],
	);

	return (
		<DropdownMenu
			isOpen={isOpen}
			placement="left-start"
			trigger={({ triggerRef, ...triggerProps }) => (
				<DropdownItem
					{...triggerProps}
					ref={triggerRef}
					onClick={handleClick}
					key="editCover"
					data-testid="issue-view-foundation.issue-actions.issue-manipulation-dropdown-group.edit-cover.trigger"
					elemAfter={<ChevronRightIcon label={formatMessage(messages.editCoverIconLabel)} />}
				>
					{label}
				</DropdownItem>
			)}
		>
			<DropdownItemGroup>
				<JiraEntryPointContainer
					id="jira-card-cover-manager-issue-view"
					packageName="jiraCardCoverManager"
					entryPointReferenceSubject={cardCoverManagerEntryPointReferenceSubject}
					fallback={<CardCoverSkeleton />}
					runtimeProps={{
						onClose: () => setIsOpen(false),
						onUpdated: onCardCoverUpdated,
						durationInSeconds: ISSUE_VIEW_COVER_MEDIA_READ_TOKEN_DURATION,
						maxTokenLength: ISSUE_VIEW_COVER_MEDIA_READ_TOKEN_MAX_LENGTH,
						showCardCoverColors: true,
					}}
				/>
			</DropdownItemGroup>
		</DropdownMenu>
	);
};

export default EditCoverIssue;
