import React from 'react';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type {
	IssueViewRelayFragment,
	MainIssueAggQueryRelayFragment,
} from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { ContentSectionView } from '@atlassian/jira-issue-view-layout-templates-views/src/ui/content/index.tsx';
import { useLayoutContainerByType } from '@atlassian/jira-issue-view-layout/src/services/main.tsx';

const ContentTemplate = ({
	issueViewRelayFragment,
	rootRelayFragment,
}: {
	// TODO Decomp BENTO-12514 - add useFragment to this component and replace this prop with more specific fragment key
	issueViewRelayFragment?: IssueViewRelayFragment | null;
	rootRelayFragment: MainIssueAggQueryRelayFragment | null;
}) => {
	const issueKey = useIssueKey();
	const [items] = useLayoutContainerByType(issueKey, 'CONTENT');

	return (
		<ContentSectionView
			items={items}
			issueViewRelayFragment={issueViewRelayFragment}
			rootRelayFragment={rootRelayFragment}
		/>
	);
};

export default ContentTemplate;
