// This eslint error is suppressed because the fields are passed to transformer which uses them all
/* eslint-disable @atlassian/relay/unused-fields */
import 'rxjs/add/operator/map';
import { graphql, fetchQuery } from 'react-relay';
import { Observable } from 'rxjs/Observable';
import {
	NUM_INITIAL_ITEMS_TO_LOAD,
	NUM_PAGED_ITEMS_TO_LOAD,
} from '@atlassian/jira-issue-view-common-constants/src/activity-feed.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import type { CommentsPageInfo } from '@atlassian/jira-issue-shared-types/src/common/types/comment-transformer-types.tsx';
import type {
	commentFetchAggServerQuery,
	commentFetchAggServerQuery$variables,
} from '@atlassian/jira-relay/src/__generated__/commentFetchAggServerQuery.graphql';
import type {
	commentFetchAggServerChildCommentsQuery,
	commentFetchAggServerChildCommentsQuery$variables,
} from '@atlassian/jira-relay/src/__generated__/commentFetchAggServerChildCommentsQuery.graphql';
import { fg } from '@atlassian/jira-feature-gating';
import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { transformAggData, transformChildCommentsAggData } from './gira/comments/index.tsx';

const getComments = (
	variables: Omit<
		commentFetchAggServerQuery$variables,
		'jiraThreadedCommentsEnabled' | 'hasCommentTargetId'
	>,
) => {
	return fetchQuery<commentFetchAggServerQuery>(
		getRelayEnvironment(),
		graphql`
			query commentFetchAggServerQuery(
				$issueAri: ID!
				$first: Int
				$after: String
				$last: Int
				$before: String
				$targetId: String
				$beforeTarget: Int
				$afterTarget: Int
				$jiraThreadedCommentsEnabled: Boolean!
				$hasCommentTargetId: Boolean!
			) {
				node(id: $issueAri) {
					... on JiraIssue {
						comments(
							first: $first
							after: $after
							last: $last
							before: $before
							targetId: $targetId
							beforeTarget: $beforeTarget
							afterTarget: $afterTarget
							sortBy: { field: CREATED, order: DESC }
						) {
							edges {
								cursor
								node {
									__typename
									commentId
									created
									updated
									author {
										name
										accountId
										picture
									}
									updateAuthor {
										name
										accountId
										picture
									}
									richText {
										adfValue {
											json
										}
									}
									permissionLevel {
										group {
											id
											name
											groupId
										}
										role {
											id
											name
										}
									}
									... on JiraServiceManagementComment {
										authorCanSeeRequest
										eventOccurredAt
										visibility
										jsdIncidentActivityViewHidden
									}
								}
							}
							pageInfo {
								hasNextPage
								hasPreviousPage
								startCursor
								endCursor
							}
						}
						threadedComments: comments(
							first: $first
							after: $after
							last: $last
							before: $before
							targetId: $targetId
							beforeTarget: $beforeTarget
							afterTarget: $afterTarget
							rootCommentsOnly: true
							sortBy: { field: CREATED, order: DESC }
						) @include(if: $jiraThreadedCommentsEnabled) {
							edges {
								cursor
								node {
									__typename
									commentId
									created
									updated
									isDeleted
									author {
										name
										accountId
										picture
									}
									updateAuthor {
										name
										accountId
										picture
									}
									richText {
										adfValue {
											json
										}
									}
									permissionLevel {
										group {
											id
											name
											groupId
										}
										role {
											id
											name
										}
									}
									... on JiraPlatformComment {
										childComments(first: 1) {
											edges {
												cursor
												node {
													__typename
													commentId
													created
													updated
													isDeleted
													author {
														name
														accountId
														picture
													}
													updateAuthor {
														name
														accountId
														picture
													}
													richText {
														adfValue {
															json
														}
													}
													permissionLevel {
														group {
															id
															name
															groupId
														}
														role {
															id
															name
														}
													}
												}
											}
											pageInfo {
												hasNextPage
												hasPreviousPage
												startCursor
												endCursor
											}
										}
										targetChildComments: childComments(
											targetId: $targetId
											beforeTarget: 1
											afterTarget: 1
										) @include(if: $hasCommentTargetId) {
											edges {
												cursor
												node {
													__typename
													commentId
													created
													updated
													isDeleted
													author {
														name
														accountId
														picture
													}
													updateAuthor {
														name
														accountId
														picture
													}
													richText {
														adfValue {
															json
														}
													}
													permissionLevel {
														group {
															id
															name
															groupId
														}
														role {
															id
															name
														}
													}
												}
											}
											pageInfo {
												hasNextPage
												hasPreviousPage
												startCursor
												endCursor
											}
										}
									}
									... on JiraServiceManagementComment {
										authorCanSeeRequest
										eventOccurredAt
										visibility
										jsdIncidentActivityViewHidden
									}
								}
							}
							pageInfo {
								hasNextPage
								hasPreviousPage
								startCursor
								endCursor
							}
						}
					}
				}
			}
		`,
		{
			...variables,
			hasCommentTargetId: !!variables.targetId,
			jiraThreadedCommentsEnabled: fg('threaded_comments_fetch_only'),
		},
	).toPromise();
};

export const fetchMoreCommentsFromAgg = (
	issueAri: string,
	isOlderButton: boolean,
	projectType: ProjectType | undefined,
	pageInfo?: CommentsPageInfo,
) => {
	const buildFetchParams = () => {
		if (pageInfo) {
			if (isOlderButton) {
				return {
					issueAri,
					first: NUM_PAGED_ITEMS_TO_LOAD,
					after: pageInfo.endCursor,
				};
			}

			return {
				issueAri,
				last: NUM_PAGED_ITEMS_TO_LOAD,
				before: pageInfo.startCursor,
			};
		}

		return { issueAri };
	};

	return Observable.fromPromise(
		getComments(buildFetchParams()).then((data) =>
			transformAggData(data, projectType, pageInfo, isOlderButton),
		),
	);
};

export const fetchFocusedCommentFromAgg = (
	issueAri: string,
	targetId: string,
	projectType: ProjectType | undefined,
) => {
	const buildFetchParams = () => {
		return {
			issueAri,
			targetId,
			afterTarget: NUM_INITIAL_ITEMS_TO_LOAD,
			beforeTarget: NUM_INITIAL_ITEMS_TO_LOAD,
		};
	};

	return Observable.fromPromise(
		getComments(buildFetchParams()).then((data) => transformAggData(data, projectType)),
	);
};

export const fetchSortedCommentsFromAgg = (
	issueAri: string,
	projectType: ProjectType | undefined,
) => {
	const buildFetchParams = () => {
		return {
			issueAri,
			first: NUM_INITIAL_ITEMS_TO_LOAD,
		};
	};

	return Observable.fromPromise(
		getComments(buildFetchParams()).then((data) => transformAggData(data, projectType)),
	);
};

const getChildComments = (variables: commentFetchAggServerChildCommentsQuery$variables) => {
	return fetchQuery<commentFetchAggServerChildCommentsQuery>(
		getRelayEnvironment(),
		graphql`
			query commentFetchAggServerChildCommentsQuery(
				$commentAri: ID!
				$first: Int
				$after: String
				$last: Int
				$before: String
			) {
				node(id: $commentAri) {
					... on JiraPlatformComment {
						childComments(first: $first, after: $after, last: $last, before: $before) {
							edges {
								cursor
								node {
									__typename
									commentId
									created
									updated
									isDeleted
									author {
										name
										accountId
										picture
									}
									updateAuthor {
										name
										accountId
										picture
									}
									richText {
										adfValue {
											json
										}
									}
									permissionLevel {
										group {
											id
											name
											groupId
										}
										role {
											id
											name
										}
									}
								}
							}
							pageInfo {
								hasNextPage
								hasPreviousPage
								startCursor
								endCursor
							}
						}
					}
				}
			}
		`,
		{
			...variables,
		},
	).toPromise();
};

export const fetchMoreChildComments = (
	parentId: string,
	commentAri: string,
	isOlderButton: boolean,
	pageInfo?: CommentsPageInfo,
) => {
	const buildFetchParams = () => {
		if (pageInfo) {
			if (isOlderButton) {
				return {
					commentAri,
					first: NUM_PAGED_ITEMS_TO_LOAD,
					after: pageInfo.endCursor,
				};
			}

			return {
				commentAri,
				last: NUM_PAGED_ITEMS_TO_LOAD,
				before: pageInfo.startCursor,
			};
		}

		return { commentAri };
	};

	return Observable.fromPromise(
		getChildComments(buildFetchParams()).then((data) =>
			transformChildCommentsAggData(parentId, data, pageInfo, isOlderButton),
		),
	);
};
