import { useMemo } from 'react';
import partition from 'lodash/partition';
import { fg } from '@atlassian/jira-feature-gating';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIssueFieldConfigStore } from '@atlassian/jira-issue-field-base/src/services/field-config-service/context.tsx';
import type { IssueConfiguration } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';
import {
	type IssueFields,
	useFieldsValues,
} from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import {
	type LayoutContainerNode,
	layoutContainerItemTypes,
	type LayoutContainerTemplateItem,
} from '@atlassian/jira-issue-layout-common-constants/src/index.tsx';
import {
	getIsUTCDateDueTodayOrWithin7DaysOverdue,
	getIsUTCDatePastOrDueSoon,
} from '@atlassian/jira-issue-view-common/src/utils/date-utils/index.tsx';
import {
	DUE_DATE,
	SELECT_CUSTOM_FIELD_TYPE,
} from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import { isEmpty } from '@atlassian/jira-issue-view-layout-templates-utils/src/index.tsx';
import { useLayoutContainerByType } from '@atlassian/jira-issue-view-layout/src/services/main.tsx';
import { getLayoutItemId } from '@atlassian/jira-issue-view-layout/src/services/utils.tsx';
import { useEdition, useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import type { ApplicationEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import { usePrimarySecondaryItems } from '../context/visible-hidden/primary-secondary-items/index.tsx';

type LayoutFieldsMeta = {
	numberOfPinnedFields?: Number;
	numberOfEmptyPinnedFields?: Number;
	numberOfVisibleContextFields?: Number;
	numberOfEmptyVisibleContextFields?: Number;
	numberOfHiddenFields?: Number;
	numberOfConfiguredHiddenFields?: Number;
	numberOfContentFields?: Number;
	numberOfEmptyContentFields?: Number;
	/**
	 * Number of hidden read-only fields that are empty. These fields are hidden from the layout because
	 * they are empty and read-only.
	 */
	numberOfHiddenReadOnlyFields?: Number;
	isDueDatePast?: boolean;
	isDueSoon?: boolean;
	isWithin7DaysOverdue?: boolean;
	isDueToday?: boolean;
	hasDueDateField?: boolean;
	hasSingleSelectCF?: boolean;
	edition?: ApplicationEdition;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const filterItemsWithValue = (items: any[], issueFieldsValues: IssueFields) =>
	items.filter((item) => {
		const fieldValue = issueFieldsValues[getLayoutItemId(item)];

		// If the field is not present in the issueFieldsValues, it means the field
		// value is loaded asynchronously. An example of this is the devSummary field.
		if (fieldValue === undefined) {
			return false;
		}

		if (fieldValue && typeof fieldValue === 'object') {
			// If the field is an ADF field, we need to check if it has content
			if (fieldValue.type === 'doc') {
				return isEmpty(fieldValue.content);
			}

			// If the field is an parent link field, we need to check if it has data
			if (
				fieldValue.hasEpicLinkFieldDependency !== undefined &&
				fieldValue.showField !== undefined
			) {
				return isEmpty(fieldValue.data);
			}
		}

		return isEmpty(fieldValue);
	});

const flatteningContentItems = (contentItems: LayoutContainerNode[]): LayoutContainerNode[] =>
	contentItems.flatMap((contentItem) => {
		if (contentItem.type === layoutContainerItemTypes.tab) {
			const contentTabFields = flatteningContentItems(contentItem.items.nodes);

			return contentTabFields;
		}

		return contentItem;
	});

const hasDueDateInFieldList = (fieldList: LayoutContainerTemplateItem[]) =>
	!!fieldList.find((item: LayoutContainerTemplateItem) => getLayoutItemId(item) === DUE_DATE);

const hasDueDateFieldInFieldLists = (fieldLists: LayoutContainerTemplateItem[][]) => {
	return fieldLists.some((fieldList) => hasDueDateInFieldList(fieldList));
};

const hasCustomSingleSelectInFieldLists = (
	fieldLists: LayoutContainerTemplateItem[],
	fieldConfig: IssueConfiguration = {},
) => {
	return fieldLists.some(
		(item: LayoutContainerTemplateItem) =>
			fieldConfig[getLayoutItemId(item)]?.schema?.custom === SELECT_CUSTOM_FIELD_TYPE,
	);
};

export const useLayoutFieldsMeta = (alwaysPrimaryFieldIds: string[] = []): LayoutFieldsMeta => {
	const items = usePrimarySecondaryItems(alwaysPrimaryFieldIds);

	const { pinnedFields, primaryItems, secondaryItems, hiddenReadOnlyFields = [] } = items;
	const issueKey = useIssueKey();
	const [issueFieldsValues] = useFieldsValues(issueKey);
	const [issueFieldConfig] = useIssueFieldConfigStore({ issueKey });
	const [contentItems] = useLayoutContainerByType(issueKey, 'CONTENT');
	const projectKey = useProjectKey(issueKey);
	const edition = useEdition(projectKey, true);

	return useMemo(() => {
		if (!issueFieldsValues) {
			return {};
		}

		const [hiddenSecondaryFields = [], visibleSecondaryFields = []] = partition(
			secondaryItems,
			(item) => isEmpty(issueFieldsValues[getLayoutItemId(item)]),
		);

		const contentFields = flatteningContentItems(contentItems);

		const emptyContentFields = filterItemsWithValue(contentFields, issueFieldsValues);
		const emptyVisibleFields = filterItemsWithValue(primaryItems, issueFieldsValues);
		const emptyPinnedFields = filterItemsWithValue(pinnedFields, issueFieldsValues);

		const numberOfVisibleFields = primaryItems.length + visibleSecondaryFields.length;
		const { isDueDatePast } = getIsUTCDatePastOrDueSoon(issueFieldsValues.duedate);

		return {
			numberOfPinnedFields: pinnedFields.length,
			numberOfEmptyPinnedFields: emptyPinnedFields.length,
			numberOfVisibleContextFields: numberOfVisibleFields,
			numberOfEmptyVisibleContextFields: emptyVisibleFields.length,
			numberOfHiddenFields: hiddenSecondaryFields.length,
			numberOfConfiguredHiddenFields: secondaryItems.length,
			numberOfContentFields: contentFields.length,
			numberOfEmptyContentFields: emptyContentFields.length,
			...(fg('issue_view_read_only_empty_fields_event') && {
				numberOfHiddenReadOnlyFields: hiddenReadOnlyFields.length,
			}),
			// eslint-disable-next-line jira/ff/no-preconditioning
			...(fg('issue-viewed-event-add-due-date-attributes_lhefn') && issueFieldsValues.duedate
				? { isDueDatePast }
				: {}),
			...(fg('jsw_due_date_metrics')
				? {
						hasDueDateField: hasDueDateFieldInFieldLists([
							pinnedFields,
							primaryItems,
							secondaryItems,
						]),
					}
				: {}),
			...(fg('thor_track_single_select_field_updated')
				? {
						hasSingleSelectCF: hasCustomSingleSelectInFieldLists(
							[...pinnedFields, ...primaryItems, ...secondaryItems],
							issueFieldConfig,
						),
					}
				: {}),
			// eslint-disable-next-line jira/ff/no-preconditioning
			...(fg('due_date_notification_metrics_attributes') && issueFieldsValues.duedate
				? getIsUTCDateDueTodayOrWithin7DaysOverdue(issueFieldsValues.duedate)
				: {}),
			edition,
		};
	}, [
		hiddenReadOnlyFields,
		pinnedFields,
		primaryItems,
		secondaryItems,
		contentItems,
		issueFieldsValues,
		edition,
		issueFieldConfig,
	]);
};
