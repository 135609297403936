import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { CardAction } from '@atlaskit/smart-card';
import { HoverCard } from '@atlaskit/smart-card/hover-card';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { xcss, Anchor } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { shouldDisplayStrikethrough } from '../common/utils.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

export const IssueKey = memo<Props>(
	({
		issueKey,
		issueLink,
		statusCategory,
		hideTooltipOnMouseDown = false,
		showHoverPreviewEnabled,
		isResolved,
		tooltipContent,
		label,
		testId,
		isVisuallyRefreshedStyle,
		onClick,
	}: Props) => {
		const { formatMessage } = useIntl();

		const getDefaultLabel = () =>
			isResolved ? formatMessage(messages.label, { issueKey }) : undefined;

		const renderKeyContent = () =>
			isVisuallyRefreshedStyle ? (
				<Anchor
					testId={testId}
					href={issueLink || ''}
					onClick={onClick}
					aria-label={label ?? getDefaultLabel()}
					role="link"
					xcss={
						shouldDisplayStrikethrough(isResolved, statusCategory)
							? [visualRefreshKeyStyles, strikeThroughKeyStyle]
							: visualRefreshKeyStyles
					}
					/**
					 * Disabling dragging so drags happen on the parent instead of this link.
					 */
					draggable={false}
				>
					{issueKey}
				</Anchor>
			) : (
				<Key
					data-testid={testId}
					href={issueLink}
					shouldDisplayStrikethrough={shouldDisplayStrikethrough(isResolved, statusCategory)}
					onClick={onClick}
					aria-label={label ?? getDefaultLabel()}
					role="link"
					/**
					 * Disabling dragging so drags happen on the parent instead of this link.
					 */
					draggable={false}
				>
					{issueKey}
				</Key>
			);

		if (showHoverPreviewEnabled) {
			const normalizedIssueLink = issueLink?.startsWith('/')
				? // eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					`${window.location.protocol}//${window.location.hostname}${issueLink}`
				: issueLink;

			return (
				<HoverCard
					url={normalizedIssueLink || ''}
					closeOnChildClick
					actionOptions={{
						hide: false,
						exclude: [CardAction.ChangeStatusAction],
					}}
				>
					{renderKeyContent()}
				</HoverCard>
			);
		}

		return (
			<Tooltip
				content={tooltipContent}
				hideTooltipOnMouseDown={hideTooltipOnMouseDown}
				position="bottom"
			>
				{renderKeyContent()}
			</Tooltip>
		);
	},
);

export default IssueKey;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Key = styled.a<{ shouldDisplayStrikethrough?: boolean }>({
	outline: 'none',
	marginLeft: token('space.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.link'),
	font: token('font.body.UNSAFE_small'),
	fontWeight: token('font.weight.medium'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	textDecoration: (props) => (props.shouldDisplayStrikethrough ? 'line-through' : 'none'),
	whiteSpace: 'nowrap',
});

const visualRefreshKeyStyles = xcss({
	outline: 'none',
	marginLeft: 'space.100',
	color: 'color.text.subtle',
	font: 'font.body',
	fontWeight: 'font.weight.regular',
	whiteSpace: 'nowrap',
	textDecorationLine: 'none',
	':hover': {
		color: 'color.text.subtle',
	},
	':focus': {
		color: 'color.text.subtle',
	},
	':active': {
		color: 'color.text.subtle',
	},
	':visited': {
		color: 'color.text.subtle',
	},
});

const strikeThroughKeyStyle = xcss({
	textDecorationLine: 'line-through',
});
