import React, { useCallback } from 'react';
import { ButtonItem } from '@atlaskit/menu';
import { Box } from '@atlaskit/primitives';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import { issueRedactionTableEntrypoint } from '@atlassian/jira-issue-redaction-table/entrypoint.tsx';
import type { Props } from './types.tsx';

export const RedactionsButtonItem = ({
	onClick,
	label,
	setDropdownItemRef,
	itemKey,
	isSelected = false,
}: Props) => {
	const handleClick = useCallback(() => {
		onClick?.(itemKey);
	}, [onClick, itemKey]);

	return (
		<ModalEntryPointPressableTrigger
			entryPoint={issueRedactionTableEntrypoint}
			modalProps={modalProps}
			useInternalModal
			interactionName="issue-redaction-table"
		>
			{({ ref }) => (
				<Box ref={setDropdownItemRef}>
					<ButtonItem
						ref={ref}
						key="classifyIssue"
						isSelected={isSelected}
						role="menuitem"
						onClick={handleClick}
					>
						{label}
					</ButtonItem>
				</Box>
			)}
		</ModalEntryPointPressableTrigger>
	);
};

const modalProps = {
	width: 'large',
};
