import React, { type ReactNode } from 'react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Link from '@atlaskit/link';
import { Box, xcss } from '@atlaskit/primitives';

import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { CustomerServiceCustomDetailsEntityType } from '@atlassian/jira-relay/src/__generated__/tableCustomDetailsQuery.graphql';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { ExperienceSuccess, type UFOExperience } from '@atlassian/ufo';
import messages from './messages.tsx';

export const EMPTY_STATE_DESCRIPTION_TESTID =
	'servicedesk-customer-service-issue-view-panel.common.ui.details.empty-state.description';

const getDescription = (isJiraAdmin: boolean, context: CustomerServiceCustomDetailsEntityType) => {
	if (isJiraAdmin) {
		switch (context) {
			case 'CUSTOMER':
				return fg('itsm-issue-renaming')
					? messages.descriptionCustomerIssueTermRefresh
					: messages.descriptionCustomer;
			case 'ORGANIZATION':
				return fg('itsm-issue-renaming')
					? messages.descriptionOrganizationIssueTermRefresh
					: messages.descriptionOrganization;
			case 'ENTITLEMENT':
				return fg('itsm-issue-renaming')
					? messages.descriptionEntitlementIssueTermRefresh
					: messages.descriptionEntitlement;
			default:
				// This should never return but formatMessage requires a non null value
				return fg('itsm-issue-renaming')
					? messages.descriptionCustomerIssueTermRefresh
					: messages.descriptionCustomer;
		}
	}

	switch (context) {
		case 'CUSTOMER':
			return messages.notAdminDescriptionCustomer;
		case 'ORGANIZATION':
			return messages.notAdminDescriptionOrganization;
		case 'ENTITLEMENT':
			return messages.notAdminDescriptionEntitlement;
		default:
			return messages.notAdminDescriptionCustomer;
	}
};

export const EmptyState = ({
	context,
	detailsUrl,
	entityExperience,
}: {
	context: CustomerServiceCustomDetailsEntityType;
	detailsUrl?: string;
	entityExperience?: UFOExperience;
}) => {
	const isJiraAdmin = useIsAdmin();
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onLinkClicked = () =>
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'createCustomerContextDetails', {
			context,
		});

	return (
		<Box xcss={containerStyles}>
			<Box xcss={descriptionStyles} testId={EMPTY_STATE_DESCRIPTION_TESTID}>
				{formatMessage(getDescription(isJiraAdmin, context), {
					a: (children: ReactNode) =>
						isJiraAdmin && detailsUrl ? (
							<Link
								onClick={onLinkClicked}
								href={detailsUrl}
								testId="servicedesk-customer-service-issue-view-panel.common.ui.details.empty-state.button"
							>
								{children}
							</Link>
						) : (
							children
						),
				})}
			</Box>
			{entityExperience && <ExperienceSuccess experience={entityExperience} />}
		</Box>
	);
};

const containerStyles = xcss({
	marginBlock: 'space.100',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	minHeight: '140px',
});

const descriptionStyles = xcss({
	textAlign: 'center',
	maxWidth: '296px',
	color: 'color.text.subtlest',
	marginBlock: 'space.100',
});
