import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import {
	type UIAnalyticsEvent,
	fireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';

import type { AnalyticsAttributesUntyped } from '@atlassian/jira-product-analytics-bridge/src/types';

const getErrorAttributes = (error: unknown) => {
	if (error instanceof Error) {
		return {
			errorMessage: error.message,
			...(error instanceof FetchError && { statusCode: error.statusCode }),
		};
	}

	return {};
};

export const callServiceWithAnalytics = async <T,>(
	analyticsEvent: UIAnalyticsEvent,
	requestPromise: Promise<T>,
	requestName: string,
	attributes: AnalyticsAttributesUntyped = {},
): Promise<T> => {
	try {
		const response = await requestPromise;
		fireOperationalAnalytics(analyticsEvent, `${requestName} succeeded`, { ...attributes });
		return response;
	} catch (err) {
		fireOperationalAnalytics(analyticsEvent, `${requestName} failed`, {
			...attributes,
			...getErrorAttributes(err),
		});
		throw err;
	}
};
