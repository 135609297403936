import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { PlaceholderTemplateError } from '../placeholder-template-error.tsx';

export type ConfluenceBlueprint = {
	name: string;
	description: string;
	contentBlueprintId: string;
	blueprintModuleCompleteKey?: string;
};

export const BlueprintCompleteKey = {
	BLANK_SPACE:
		'com.atlassian.confluence.plugins.confluence-create-content-plugin:create-blank-space-blueprint',
} as const;

export type BlueprintCompleteKey = (typeof BlueprintCompleteKey)[keyof typeof BlueprintCompleteKey];
export interface BlueprintData {
	blueprintCompleteKey: BlueprintCompleteKey;
	blueprintModuleCompleteKey: string;
	contentBlueprintId: string;
}

export const getBlueprint = async (
	cloudId: string,
	spaceKey: string,
	blueprintModuleCompleteKey: string,
): Promise<ConfluenceBlueprint> => {
	const url = `/gateway/api/ex/confluence/${cloudId}/wiki/rest/create-dialog/1.0/blueprints/web-items?spaceKey=${spaceKey}`;
	try {
		const blueprints = await fetchJson<ConfluenceBlueprint[]>(url);
		const found = blueprints.find(
			(blueprint) => blueprint.blueprintModuleCompleteKey === blueprintModuleCompleteKey,
		);
		if (!found) {
			throw new Error(
				`Fetching blueprints succeeded, but blueprint: ${blueprintModuleCompleteKey} was not found for space: ${spaceKey}`,
			);
		}
		return found;
	} catch (error: unknown) {
		throw new PlaceholderTemplateError(
			`Unknown error calling getBlueprint for space: ${spaceKey}`,
			'getBlueprint',
			error instanceof FetchError ? error.statusCode : undefined,
			error instanceof FetchError ? error.traceId : undefined,
		);
	}
};

export async function getBlankSpaceBlueprintId(): Promise<string | undefined> {
	try {
		const api = '/wiki/rest/create-dialog/1.0/space-blueprint/dialog/web-items';
		const spaceBlueprints = await performGetRequest(api);

		const blankSpaceBlueprintId: string =
			spaceBlueprints.find(
				(spaceBlueprint: BlueprintData) =>
					spaceBlueprint.blueprintModuleCompleteKey === BlueprintCompleteKey.BLANK_SPACE,
			)?.contentBlueprintId || '';

		return blankSpaceBlueprintId;
	} catch (error: unknown) {
		throw new PlaceholderTemplateError(
			'Failed to get blank space blueprintId',
			'getBlankSpaceBlueprintId',
			error instanceof FetchError ? error.statusCode : undefined,
			error instanceof FetchError ? error.traceId : undefined,
		);
	}
}
