import React, { useCallback, useMemo } from 'react';
import { useFragment, graphql, useMutation } from 'react-relay';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import type {
	ResolutionOption,
	ResolutionValue,
} from '@atlassian/jira-issue-field-resolution/src/common/types.tsx';
import {
	mapResolutionOptionToOption,
	mapOptionToResolutionOption,
	mapAndFilterResolutionOptions,
} from '@atlassian/jira-issue-field-resolution/src/common/utils.tsx';
import messages from '@atlassian/jira-issue-field-resolution/src/messages.tsx';
import { ResolutionEditView } from '@atlassian/jira-issue-field-resolution/src/ui/edit/popover/index.tsx';
import { ResolutionReadView } from '@atlassian/jira-issue-field-resolution/src/ui/read/index.tsx';
import { useSoftRefreshCallbacks } from '@atlassian/jira-issue-view-common-views/src/connect-field/relay-field/use-connect-relay-field.tsx';
import {
	useAnalyticsEvents,
	fireUIAnalytics,
	fireTrackAnalytics,
	fireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import type { ui_issueFieldResolution_IssueViewResolutionFieldMutation } from '@atlassian/jira-relay/src/__generated__/ui_issueFieldResolution_IssueViewResolutionFieldMutation.graphql';
import type {
	ui_issueViewLayoutResolutionField_IssueViewResolutionField$key,
	ui_issueViewLayoutResolutionField_IssueViewResolutionField$data,
} from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutResolutionField_IssueViewResolutionField.graphql';

export type AggValueShape = {
	readonly id: string;
	readonly name: string;
	readonly resolutionId: string;
};

/**
 * We don't get all the AGG fields required back from the legacy field, so we need to pick from the available options.
 */
function transformComponentValueToAggValue(
	value: ResolutionValue,
	resolutions: ui_issueViewLayoutResolutionField_IssueViewResolutionField$data['resolutions'],
): AggValueShape | undefined {
	return mapNodes(resolutions).find((options) => options.id === value?.id);
}

export type IssueViewResolutionFieldProps = {
	fragmentKey: ui_issueViewLayoutResolutionField_IssueViewResolutionField$key;
};

export const IssueViewResolutionField = ({ fragmentKey }: IssueViewResolutionFieldProps) => {
	const { showFlag } = useFlagsService();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const data = useFragment<ui_issueViewLayoutResolutionField_IssueViewResolutionField$key>(
		graphql`
			fragment ui_issueViewLayoutResolutionField_IssueViewResolutionField on JiraResolutionField {
				__typename
				id
				fieldId
				type
				isEditableInIssueView

				issue {
					id
					key
				}

				resolution {
					id
					# eslint-disable-next-line @atlassian/relay/unused-fields: This field is used in 'value'
					name @required(action: NONE)
				}
				resolutions {
					edges {
						node {
							id
							# eslint-disable-next-line @atlassian/relay/unused-fields: This field is used in 'allowedValues' and 'saveValue'
							name @required(action: NONE)
							# eslint-disable-next-line @atlassian/relay/unused-fields: This field is used in 'saveValue'
							resolutionId
						}
					}
				}
			}
		`,
		fragmentKey,
	);

	const [commit] = useMutation<ui_issueFieldResolution_IssueViewResolutionFieldMutation>(graphql`
		mutation ui_issueFieldResolution_IssueViewResolutionFieldMutation(
			$input: JiraUpdateResolutionFieldInput!
		) @raw_response_type {
			jira {
				updateResolutionField(input: $input) @optIn(to: "JiraIssueFieldMutations") {
					success
					field {
						resolution {
							id
							name @required(action: NONE)
						}
					}
				}
			}
		}
	`);

	const { overriding } = useInlineEditFieldInjections();
	const softRefreshCallbacks = useSoftRefreshCallbacks<AggValueShape>({
		issueId: data.issue?.id,
		fieldData: {
			__typename: data.__typename,
			fieldId: data.fieldId,
			type: data.type,
		},
	});

	const issueKey = data.issue?.key;
	const allowedValues = mapNodes(data.resolutions);
	const value = data.resolution ?? null;
	const isEditable = overriding.overrideIsEditable(data.isEditableInIssueView ?? false);

	const onFailure = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			fireOperationalAnalytics(analyticsEvent, 'field updateFailed', {
				fieldKey: data.fieldId,
				fieldType: data.type,
			});
			log.safeErrorWithoutCustomerData(
				'issue-field.save-value',
				`Failed to update field ${data.fieldId} of type ${data.type}`,
			);
			showFlag({
				type: 'error',
				title: [messages.editResolutionFailedTitle, { issueKey }],
				description: messages.editResolutionFailedDescription,
				messageId: 'issue-view-layout-resolution-field.ui.show-flag.error',
				messageType: 'transactional',
			});
		},
		[data.fieldId, data.type, issueKey, showFlag],
	);

	const saveValue = useCallback(
		(savedValue: ResolutionValue, analyticsEvent: UIAnalyticsEvent) => {
			const aggValue = transformComponentValueToAggValue(savedValue, data.resolutions);
			const analyticsData = {
				editOperationType: 'set',
				issueId: data.issue?.id,
				fieldKey: data.fieldId,
				fieldType: data.type,
			};

			if (!aggValue) {
				log.safeInfoWithoutCustomerData(
					'issue.fields.issue-view-layout.resolution-field',
					'failed to transform component value to agg value',
				);
				onFailure(analyticsEvent);
				return;
			}

			softRefreshCallbacks.onSubmit(aggValue);
			fireTrackAnalytics(analyticsEvent, 'field updated', analyticsData);

			commit({
				variables: {
					input: {
						id: data.id,
						operation: {
							operation: 'SET',
							id: savedValue?.id,
						},
					},
				},
				onError: () => {
					onFailure(analyticsEvent);
				},
				onCompleted: (response) => {
					if (response.jira?.updateResolutionField?.success) {
						softRefreshCallbacks.onSubmitSucceeded(aggValue);
						fireOperationalAnalytics(analyticsEvent, 'field updateSucceeded', analyticsData);
					} else {
						onFailure(analyticsEvent);
						softRefreshCallbacks.onSubmitFailed();
					}
				},
				optimisticResponse: {
					jira: {
						updateResolutionField: {
							success: true,
							field: {
								id: data.id,
								resolution: savedValue,
							},
						},
					},
				},
			});
		},
		[
			commit,
			data.fieldId,
			data.id,
			data.issue?.id,
			data.resolutions,
			data.type,
			onFailure,
			softRefreshCallbacks,
		],
	);

	const selected = useMemo(() => (value ? mapResolutionOptionToOption(value) : null), [value]);
	const options = useMemo(
		() => mapAndFilterResolutionOptions(value, allowedValues),
		[allowedValues, value],
	);

	const isEditViewEnabled = isEditable && options.length >= 1;

	const onChange = useCallback(
		(option: ResolutionOption) => {
			const optionExternal = mapOptionToResolutionOption(option);
			const analyticsEvent = createAnalyticsEvent({
				action: 'changed',
				actionSubject: 'select',
			});
			fireUIAnalytics(analyticsEvent, 'resolutionField');
			saveValue(optionExternal, analyticsEvent);
		},
		[createAnalyticsEvent, saveValue],
	);

	return isEditViewEnabled ? (
		<ResolutionEditView onChange={onChange} allowedOptions={options} value={selected} />
	) : (
		<ResolutionReadView value={value} isLabelClickEnabled={false} />
	);
};

type Nullable<T> = T | null | undefined;

type Connection<TNode> = {
	readonly edges?: Nullable<
		ReadonlyArray<
			Nullable<{
				readonly node?: Nullable<TNode>;
			}>
		>
	>;
} | null;

function mapNodes<TNode>(conn: Nullable<Connection<TNode>>): TNode[] {
	const nodes: TNode[] = [];

	return (
		conn?.edges?.reduce((acc, edge) => {
			if (edge?.node) {
				acc.push(edge.node);
			}

			return acc;
		}, nodes) ?? []
	);
}
