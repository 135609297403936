import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import parameters from '@atlassian/jira-relay/src/__generated__/ui_main_ConfigurableChildIssuesPanelWithEntryPointQuery.graphql.ts';
import type { createConfigurableChildIssuesPanelQueryVariables } from './src/utils/index.tsx';

interface EntryPointParams {
	variables: ReturnType<typeof createConfigurableChildIssuesPanelQueryVariables>;
}

export const configurableChildIssuesPanelEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-configurable-child-issues-panel" */ './src/ui').then(
			(module) => module.ConfigurableChildIssuesPanelWithEntryPoint,
		),
	),
	getPreloadProps: ({ variables }: EntryPointParams) => ({
		queries: {
			childIssuesData: {
				options: {
					fetchKey: 'issue',
					fetchPolicy: 'store-or-network' as const,
					name: 'issueViewCommon',
				},
				parameters,
				variables,
			},
		},
	}),
});
