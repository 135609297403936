import urlModule from 'url';
import { matchPath } from 'react-router';

const pattern = '/wiki/spaces/:spaceKey/whiteboard/:contentId(\\d+)';

export const getSpaceKey = (url?: string | undefined) => {
	if (url) {
		const parsed = urlModule.parse(url, true);
		const pathname: string = parsed.pathname ?? '';
		const match = matchPath<{ [key: string]: string }>(pathname, {
			path: pattern,
			exact: true,
		});
		return match?.params.spaceKey;
	}
	return '';
};
