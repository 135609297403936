import { fg } from '@atlassian/jira-feature-gating';
import type { FlagConfiguration } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import {
	LINK_PARENT_FAILURE,
	REORDER_CHILDREN_FAILED,
	type LinkParentFailureAction,
	type ReorderChildrenFailedAction,
} from '../state/entities/actions.tsx';
import messages from './messages.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	action: LinkParentFailureAction | ReorderChildrenFailedAction,
): FlagConfiguration | undefined => {
	switch (action.type) {
		case LINK_PARENT_FAILURE: {
			return {
				messageId: 'issue-view-common-views.child-issues-panel.flags.flag.error.link-parent-error',
				messageType: 'transactional',
				type: 'error',
				title: fg('jira-issue-terminology-refresh-m3')
					? messages.linkParentErrorTitleIssueTermRefresh
					: messages.linkParentErrorTitle,
				description: fg('jira-issue-terminology-refresh-m3')
					? messages.linkParentErrorDescriptionIssueTermRefresh
					: messages.linkParentErrorDescription,
			};
		}

		case REORDER_CHILDREN_FAILED: {
			return {
				messageId: 'issue-view-common-views.child-issues-panel.flags.flag.error.reorder-error',
				messageType: 'transactional',
				type: 'error',
				title: fg('jira-issue-terminology-refresh-m3')
					? messages.reorderErrorTitleIssueTermRefresh
					: messages.reorderErrorTitle,
				description: messages.reorderErrorDescription,
			};
		}

		default:
			return undefined;
	}
};
