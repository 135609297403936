// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createStore,
	createHook,
	type Action,
	createSubscriber,
} from '@atlassian/react-sweet-state';

type State = { isLinkingToLocalJira: boolean };

const initialState: State = { isLinkingToLocalJira: true };

const actions = {
	setIsLinkingToLocalJira:
		(isLinkingToLocalJira: boolean): Action<State> =>
		({ setState, getState }) => {
			setState({ ...getState(), isLinkingToLocalJira });
		},
} as const;

type Actions = typeof actions;

const Store = createStore<State, Actions>({
	initialState,
	actions,
	name: 'issue-link-locality',
});

export const useIssueLinkLocality = createHook(Store);

export const IssueLinkLocalitySubscriber = createSubscriber(Store);
