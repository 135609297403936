import type { State } from './types.tsx';

export const INITIAL_STATE: State = {
	hasFetched: false,
	isFetching: false,
	isCreatingRule: false,
	isDeletingRule: false,
	isUpdatingRule: false,
	errors: null,
	hasBootstrapError: false,
	isRuleOwner: null,
	issueProperties: null,
};

export const NO_RULE_PARTIAL_STATE: Pick<State, 'isRuleOwner' | 'issueProperties'> = {
	isRuleOwner: null,
	issueProperties: null,
};
