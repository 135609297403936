import React, { type ComponentType, useMemo, useRef, useEffect, useCallback } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import { graphql, useFragment } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import type { AnalyticsEventPayload } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import {
	steps,
	useIssueBreakdown,
} from '@atlassian/jira-ai-work-breakdown/src/controllers/context.tsx';

import { ISSUE_VIEW_CONSUMER } from '@atlassian/jira-ai-work-breakdown/src/constants.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import {
	useChildIssuesLimit,
	useIsAiEnabledForIssue,
	useIssueId,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { CreatedIssueWithIssueData } from '@atlassian/jira-issue-create-common-types/src/common/types/index.tsx';
import { GIC_LIFECYCLE_EVENTS } from '@atlassian/jira-issue-create-extensibility/src/common/utils/lifecycle-events/constants.tsx';
import { useGICLifeCycleEvents } from '@atlassian/jira-issue-create-extensibility/src/common/utils/lifecycle-events/main.tsx';
import type { ServerAssociatedIssue } from '@atlassian/jira-issue-shared-types/src/common/types/associated-issue-type.tsx';
import {
	EXCEEDED_LIMIT_AFTER_LOAD,
	EXCEEDED_LIMIT_INITIAL_LOAD,
	WITHIN_LIMIT,
	type ChildIssuesLimitStatus,
	GIC_CALLBACK_PAYLOAD_INLINE_CREATE_ID,
} from '@atlassian/jira-issue-view-common-constants/src/child-issues.tsx';

import type { ChildIssueType } from '@atlassian/jira-issue-view-common-types/src/child-issue-type.tsx';
import {
	type ChildIssue,
	getChildIssuesLimitExceededAnalyticsCount,
} from '@atlassian/jira-issue-view-common-types/src/children-issues-type.tsx';
import {
	FireScreenAnalytics,
	ContextualAnalyticsData,
} from '@atlassian/jira-product-analytics-bridge';
import { useTriggerIssueViewModal } from '@atlassian/jira-open-issue-view-modal-controller/src/index.tsx';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import type {
	IssueKey,
	BaseUrl,
	ProjectId,
	IssueTypeId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import type { MainIssueAggQueryRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import type { view_issueViewCommonViews_ChildIssuesPanel_RankFieldRootRelayFragment$key } from '@atlassian/jira-relay/src/__generated__/view_issueViewCommonViews_ChildIssuesPanel_RankFieldRootRelayFragment.graphql';

import { CLASSIC_SUBTASKS } from '@atlassian/jira-issue-view-common-views/src/child-issues-panel/model/types.tsx';
import {
	useAiWorkBreakDownAnalytics,
	ISSUE_BREAKDOWN_PROACTIVE_CHILD_PANEL_ADD_ID,
} from '@atlassian/jira-ai-work-breakdown/src/common/analytics/index.tsx';
import transfromServerChild from '../common/transform-server-child/index.tsx';
import {
	hasExceededIssuesLimitInitialLoad,
	hasExceededIssuesLimitAfterLoad as hasExceededIssuesLimitAfterLoadUtil,
} from '../common/util.tsx';
import { useChildPanelRenderTrigger } from '../hooks/child-panel-render-trigger/index.tsx';
import { useShouldTriggerAiSuggestedIssues } from '../hooks/main.tsx';
import type { ChildIssuesPanelType } from '../model/types.tsx';
import { CHILD_PANEL_VIEWS, type ChildPanelView } from '../state/ui/actions.tsx';
import { AiIssueBreakdownEntryPointContainer } from './ai-issue-breakdown/index.tsx';
import { ChildIssuesLimitFlag } from './child-issues-limit-flag/index.tsx';
import { ChildIssuesLimitPanel } from './child-issues-limit-panel/index.tsx';
import { AsyncConfigurableChildIssuesPanel } from './configurable-child-issues-panel/src/ui/async.tsx';
import type { AddChildEventHandler } from './heading/add-child/types.tsx';
import { HideDoneIconWithToolTip } from './hide-done-icon-with-tooltip/index.tsx';
import { getIsSubtaskCreateEnabled, useHideDoneChildIssues } from './utils.tsx';
import { useShouldProactivelySuggestIssues } from './hooks.tsx';
import { ProgressSummaryData } from './progress-summary/src/index.tsx';

// Only show progress summary when there are at least two child issues (or subtasks).
const PROGRESS_SUMMARY_MIN_ISSUE_COUNT = 2;

export type OwnProps = {
	hasExceededIssuesLimitAfterLoad: boolean;
	sourceName?: string;
	childIssuesLimitUrl: string;
	totalChildIssueCount: number;
	onAddChildClick: AddChildEventHandler | undefined;
	onIssueBreakdownClick: (() => void) | undefined;
	shouldProactivelySuggestIssues?: boolean;
};

type ConnectProps = {
	Heading: ComponentType<{
		onAddChildClick?: AddChildEventHandler;
		sourceName?: string;
		childIssuesLimitStatus: ChildIssuesLimitStatus;
		issueHierarchyLevel: number | undefined;
		rankFieldJql?: string;
	}>;
	Issues: ComponentType<{
		hasExceededChildIssuesLimitOnLoadOrAfter: boolean;
	}>;
	InlineCreate: ComponentType<{
		inputId?: string;
	}>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ProgressSummary: ComponentType<Record<any, any>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	AddExistingIssue: ComponentType<Record<any, any>>;
	allIssues: ChildIssue[];
	addClickCount?: number;
	childPanelView: ChildPanelView;
	incompleteIssues: ChildIssue[];
	childIssuesPanelType?: ChildIssuesPanelType;
	issueHierarchyLevel?: number;
	epicLinkFieldKey?: string | null;
	parentIssueKey?: IssueKey;
	projectId: ProjectId | null;
	parentIssueTypeId: IssueTypeId | null;
	baseUrl: BaseUrl;
	childIssueTypes: ChildIssueType[];
	issueTypeWithWorkflowValidators?: { [issueTypeId: string]: boolean };
	// TODO: Remove on jira-issue-view-child-issues-panel-merge-subtasks cleanup
	filterSubtasks: boolean | null;
	onFullDialogCreate: (
		optimisticId: string,
		childIssue: ChildIssue,
		additionalAttributes: AnalyticsEventPayload['attributes'],
	) => void;
	onSuggestedChildIssueCreateSuccess: (
		createdChild: CreatedIssueWithIssueData | ChildIssue,
	) => void;
	rootRelayFragment: MainIssueAggQueryRelayFragment | null;
};

export type Props = OwnProps & ConnectProps;

const ChildIssuesPanelBase = ({
	hasExceededIssuesLimitAfterLoad,
	totalChildIssueCount,
	addClickCount = 0,
	Heading,
	Issues,
	InlineCreate,
	ProgressSummary,
	AddExistingIssue,
	allIssues,
	childPanelView,
	sourceName,
	childIssuesLimitUrl,
	onAddChildClick,
	onIssueBreakdownClick,
	childIssuesPanelType,
	issueHierarchyLevel,
	epicLinkFieldKey,
	parentIssueKey,
	parentIssueTypeId,
	projectId,
	baseUrl,
	childIssueTypes,
	issueTypeWithWorkflowValidators,
	onFullDialogCreate,
	onSuggestedChildIssueCreateSuccess,
	incompleteIssues,
	filterSubtasks,
	rootRelayFragment,
	shouldProactivelySuggestIssues = false,
}: Props) => {
	const [{ issueCreateSessionId }] = useTriggerIssueViewModal();
	const initialTotalChildIssueCount = useRef<number>(totalChildIssueCount);

	const childIssuesLimitStatus = useMemo(() => {
		if (childIssuesLimitUrl) {
			return EXCEEDED_LIMIT_INITIAL_LOAD;
		}

		if (hasExceededIssuesLimitAfterLoad) {
			return EXCEEDED_LIMIT_AFTER_LOAD;
		}

		return WITHIN_LIMIT;
	}, [childIssuesLimitUrl, hasExceededIssuesLimitAfterLoad]);

	const shouldTriggerAiSuggestedIssues = useShouldTriggerAiSuggestedIssues();

	const issueId = expVal('aiwb_convo_ai_migration', 'use_convo_ai_migration', false)
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useIssueId()
		: '';

	const projectKey = expVal('aiwb_convo_ai_migration', 'use_convo_ai_migration', false)
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useProjectKey(parentIssueKey || '')
		: '';

	// fg('jira-nike-chin-config-button-in-header') is the main flag behind this change and it ties in with expVal('jira-issue-view-chin-ga', 'isChildIssueEnabled', false)
	const isConfigHeaderEnabled =
		// eslint-disable-next-line jira/ff/no-preconditioning
		fg('jira-nike-chin-config-button-in-header') &&
		expVal('jira-issue-view-chin-ga', 'isChildIssueEnabled', false);

	const [{ whichChildPanelRenderTriggered }, { resetWhichChildPanelRenderTriggered }] =
		useChildPanelRenderTrigger();

	const hasIssues = useMemo(() => allIssues.length > 0, [allIssues.length]);
	const hasExceededChildIssuesLimitInitialLoad = useMemo(
		() => hasExceededIssuesLimitInitialLoad(childIssuesLimitStatus),
		[childIssuesLimitStatus],
	);

	const hasExceededChildIssuesLimitAfterLoad = useMemo(
		() => hasExceededIssuesLimitAfterLoadUtil(childIssuesLimitStatus),
		[childIssuesLimitStatus],
	);

	const hasContent =
		hasExceededChildIssuesLimitInitialLoad ||
		hasIssues ||
		addClickCount > 0 ||
		shouldTriggerAiSuggestedIssues ||
		whichChildPanelRenderTriggered;

	const childIssuesLimit = useChildIssuesLimit();
	const approachingLimitCutOff = childIssuesLimit - 1;

	const startedBelowTheCutoff =
		!hasExceededChildIssuesLimitInitialLoad &&
		initialTotalChildIssueCount.current < approachingLimitCutOff;

	const isNowAtTheCutoff = totalChildIssueCount === approachingLimitCutOff;

	const isApproachingLimit = startedBelowTheCutoff && isNowAtTheCutoff;

	const { shouldShowHideDoneInfoIcon, completedIssuesCount } = useHideDoneChildIssues(
		allIssues,
		incompleteIssues,
	);

	const getProgressSummaryComponent = () =>
		fg('issue-jiv-20335-relay-progress-summary') ? (
			<ProgressSummaryData
				rootRelayFragment={rootRelayFragment}
				isClassicSubtaskPanel={childIssuesPanelType === CLASSIC_SUBTASKS}
			/>
		) : (
			<ProgressSummary />
		);

	const renderIssues = useMemo(() => {
		const progressSummary =
			!hasExceededChildIssuesLimitInitialLoad &&
			(expVal('jira-issue-view-chin-ga', 'isChildIssueEnabled', false)
				? true
				: allIssues.length >= PROGRESS_SUMMARY_MIN_ISSUE_COUNT)
				? getProgressSummaryComponent()
				: null;

		const childIssues = () => {
			if (expVal('jira-issue-view-chin-ga', 'isChildIssueEnabled', false)) {
				const isChildIssuesPresent: boolean = allIssues.length > 0;

				return (
					<UFOSegment name="issue-child-issues-table">
						<AsyncConfigurableChildIssuesPanel
							parentIssueTypeId={parentIssueTypeId}
							projectId={projectId}
							filterSubtasks={filterSubtasks}
							rootRelayFragment={rootRelayFragment}
							totalChildIssueCount={totalChildIssueCount}
							// TODO: Passing to satisfy test conditions; this will be cleaned up in the future under jira-issue-view-chin-ga
							Heading={Heading}
							progressSummary={progressSummary}
							headerComponents={{
								onAddChildClick,
								sourceName,
								childIssuesLimitStatus,
								shouldShowHideDoneInfoIcon,
								completedIssuesCount,
								issueHierarchyLevel,
								isChildIssuesPresent,
								isApproachingLimit,
							}}
						/>
					</UFOSegment>
				);
			}

			return (
				<UFOSegment name="issue-child-issues-list">
					<Issues
						hasExceededChildIssuesLimitOnLoadOrAfter={
							hasExceededChildIssuesLimitAfterLoad || hasExceededChildIssuesLimitInitialLoad
						}
					/>
				</UFOSegment>
			);
		};

		const ProgressSummaryWithHideDoneIconContainer = expVal(
			'jira-issue-view-chin-ga',
			'isChildIssueEnabled',
			false,
		)
			? ProgressSummaryWithHideDoneIconContainerCHIN
			: ProgressSummaryWithHideDoneIconContainerOld;

		return allIssues.length > 0 ? (
			<>
				{!isConfigHeaderEnabled && (
					<ProgressSummaryWithHideDoneIconContainer>
						{progressSummary}
						{shouldShowHideDoneInfoIcon && (
							<HideDoneIconWithToolTip completedChildIssuesCount={completedIssuesCount} />
						)}
					</ProgressSummaryWithHideDoneIconContainer>
				)}
				{childIssues()}
			</>
		) : null;
		// Excluded ProgressSummary, projectKey, Issues, issueId as the component and analytic variable are not needed in the dependency
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		shouldShowHideDoneInfoIcon,
		completedIssuesCount,
		allIssues.length,
		projectId,
		parentIssueTypeId,
		incompleteIssues.length,
		hasExceededChildIssuesLimitInitialLoad,
		hasExceededChildIssuesLimitAfterLoad,
		filterSubtasks,
	]);

	const { subscribe } = useGICLifeCycleEvents();
	const isSubtaskCreateEnabled = getIsSubtaskCreateEnabled(
		childIssuesPanelType,
		issueHierarchyLevel,
	);

	useEffect(
		() =>
			subscribe?.(GIC_LIFECYCLE_EVENTS.ISSUE_CREATE_SUCCESS, async (payload) => {
				if (payload.callbackPayload?.id !== GIC_CALLBACK_PAYLOAD_INLINE_CREATE_ID) return;
				const { createdIssueDetails } = payload.createdIssueData;

				const callbackData = payload.callbackPayload?.data;

				if (
					callbackData.parentIssueKey !== parentIssueKey ||
					callbackData.childIssuesPanelType !== childIssuesPanelType
				)
					return;

				if (
					isSubtaskCreateEnabled ||
					(epicLinkFieldKey && createdIssueDetails.fields[epicLinkFieldKey]) === parentIssueKey ||
					callbackData?.parentIssueKey === parentIssueKey
				) {
					onFullDialogCreate(
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						callbackData.optimisticId as string,
						transfromServerChild(
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							createdIssueDetails as unknown as ServerAssociatedIssue,
							baseUrl,
						),
						{ sessionId: issueCreateSessionId },
					);
				}
			}),
		[
			subscribe,
			baseUrl,
			epicLinkFieldKey,
			onFullDialogCreate,
			parentIssueKey,
			childIssuesPanelType,
			isSubtaskCreateEnabled,
			issueCreateSessionId,
		],
	);

	const [
		{ currentStep, isProactivelySuggestingIssues, channelId },
		{
			resetIssueBreakdownState,
			isIssueBreakdownOpen,
			setIssueBreakdownStep,
			updateStreamingStatus,
			setIsProactivelySuggestingIssues,
		},
	] = useIssueBreakdown();

	const { fireTrack } = fg('aiwb_proactive_interaction_events')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useAiWorkBreakDownAnalytics({ channelId, consumer: ISSUE_VIEW_CONSUMER })
		: { fireTrack: noop };

	const isAiOptInEnabled = useIsAiEnabledForIssue();

	const renderCreate = useMemo(() => {
		const isAiEnabledAndOpen = isAiOptInEnabled && isIssueBreakdownOpen();

		// Create panel should not render if AI issue create is open if proactive suggestions experiment is disabled
		// when enabled should render both inline create & AI issue breakdown if the pre-conditioning is met
		if (isAiEnabledAndOpen && !fg('proactive_ai_suggestions_for_child_items_targeting')) {
			return;
		}
		if (
			// eslint-disable-next-line jira/ff/no-preconditioning
			isAiEnabledAndOpen &&
			fg('proactive_ai_suggestions_for_child_items_targeting') &&
			!isProactivelySuggestingIssues
		) {
			return;
		}

		if (addClickCount > 0) {
			switch (childPanelView) {
				case CHILD_PANEL_VIEWS.InlineCreate:
					return <InlineCreate inputId={sourceName} />;
				case CHILD_PANEL_VIEWS.AddExistingIssue:
					return <AddExistingIssue />;
				default:
					return null;
			}
		}
		return null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		addClickCount,
		childPanelView,
		sourceName,
		currentStep,
		isIssueBreakdownOpen,
		shouldProactivelySuggestIssues,
	]);

	const rankFieldJql =
		// eslint-disable-next-line @atlassian/relay/query-restriction
		useFragment<view_issueViewCommonViews_ChildIssuesPanel_RankFieldRootRelayFragment$key>(
			graphql`
				fragment view_issueViewCommonViews_ChildIssuesPanel_RankFieldRootRelayFragment on JiraQuery {
					rankField(cloudId: $cloudId) {
						jqlTerm
					}
				}
			`,
			rootRelayFragment?.jira || null,
		)?.rankField?.jqlTerm || 'created';

	const onAddChildClickWithAiBreakdownEnabled = useCallback<AddChildEventHandler>(
		(e, analyticsEvent) => {
			if (isAiOptInEnabled) {
				if (fg('proactive_ai_suggestions_for_child_items_targeting')) {
					if (shouldProactivelySuggestIssues) {
						setIsProactivelySuggestingIssues(true);
						if (!isIssueBreakdownOpen()) {
							setIssueBreakdownStep(steps.draftListStep);
							updateStreamingStatus(true);
							fg('aiwb_proactive_interaction_events') &&
								fireTrack('aiInteraction initiated', 'proactiveFromQuickAddClick', {
									invokedFrom: ISSUE_BREAKDOWN_PROACTIVE_CHILD_PANEL_ADD_ID,
								});
						}
					} else if (!isProactivelySuggestingIssues) {
						resetIssueBreakdownState();
					}
				} else {
					resetIssueBreakdownState();
				}
			}
			onAddChildClick && onAddChildClick(e, analyticsEvent);
		},
		[
			fireTrack,
			isAiOptInEnabled,
			isIssueBreakdownOpen,
			isProactivelySuggestingIssues,
			onAddChildClick,
			resetIssueBreakdownState,
			setIsProactivelySuggestingIssues,
			setIssueBreakdownStep,
			shouldProactivelySuggestIssues,
			updateStreamingStatus,
		],
	);

	const childIssuesLimitExceededAnalyticsCount =
		getChildIssuesLimitExceededAnalyticsCount(childIssuesLimit);

	const renderAIWorkBreakdown = useMemo(() => {
		if (childIssueTypes.length === 0) return null;

		const childIssueTypeWithWFValidator = childIssueTypes.map((issueType) => ({
			...issueType,
			hasWorkflowValidators:
				(issueTypeWithWorkflowValidators && issueTypeWithWorkflowValidators[issueType.id]) || false,
		}));
		return (
			<AiIssueBreakdownEntryPointContainer
				onInit={onIssueBreakdownClick}
				onCreate={onSuggestedChildIssueCreateSuccess}
				childIssueTypes={childIssueTypeWithWFValidator}
				isSubTaskCreationOpen={isSubtaskCreateEnabled}
				issueHierarchyLevel={issueHierarchyLevel}
				onDiscard={resetWhichChildPanelRenderTriggered}
				{...(expVal('aiwb_convo_ai_migration', 'use_convo_ai_migration', false)
					? {
							projectId,
							issueKey: parentIssueKey || '',
							issueId,
							projectKey,
							consumer: ISSUE_VIEW_CONSUMER,
						}
					: {})}
			/>
		);
	}, [
		childIssueTypes,
		onIssueBreakdownClick,
		onSuggestedChildIssueCreateSuccess,
		isSubtaskCreateEnabled,
		issueHierarchyLevel,
		resetWhichChildPanelRenderTriggered,
		projectId,
		parentIssueKey,
		issueId,
		projectKey,
		issueTypeWithWorkflowValidators,
	]);

	if (!hasContent) {
		return null;
	}

	return (
		<ContextualAnalyticsData
			attributes={{
				childCount: hasExceededChildIssuesLimitInitialLoad
					? childIssuesLimitExceededAnalyticsCount
					: allIssues.length,
				isChildIssuesLimitPanelDisplayed: hasExceededChildIssuesLimitInitialLoad,
			}}
		>
			<FireScreenAnalytics actionSubjectId={sourceName} />
			{!isConfigHeaderEnabled && (
				<Heading
					onAddChildClick={onAddChildClickWithAiBreakdownEnabled}
					sourceName={sourceName}
					childIssuesLimitStatus={childIssuesLimitStatus}
					issueHierarchyLevel={issueHierarchyLevel}
					rankFieldJql={fg('hide-rank-option-when-unavailable') ? rankFieldJql : undefined}
				/>
			)}
			{hasExceededChildIssuesLimitInitialLoad && (
				<ChildIssuesLimitPanel
					hasExceededIssuesLimitInitialLoad={hasExceededChildIssuesLimitInitialLoad}
				/>
			)}
			{!isConfigHeaderEnabled && (
				<ChildIssuesLimitFlag
					isApproachingLimit={isApproachingLimit}
					childIssuesLimitStatus={childIssuesLimitStatus}
				/>
			)}
			{renderIssues}
			{renderCreate && expVal('jira-issue-view-chin-ga', 'isChildIssueEnabled', false) ? (
				<Box xcss={createChildIssueContainerStyles}>{renderCreate}</Box>
			) : (
				renderCreate
			)}
			{renderAIWorkBreakdown}
		</ContextualAnalyticsData>
	);
};

const ChildIssuePanelWithProactiveParam = (props: Props) => {
	const shouldProactivelySuggestIssues = useShouldProactivelySuggestIssues(
		props.totalChildIssueCount,
	);
	return (
		<ChildIssuesPanelBase
			{...props}
			shouldProactivelySuggestIssues={shouldProactivelySuggestIssues}
		/>
	);
};

export const ChildIssuesPanel = componentWithFG(
	'proactive_ai_suggestions_for_child_items_targeting',
	ChildIssuePanelWithProactiveParam,
	ChildIssuesPanelBase,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProgressSummaryWithHideDoneIconContainerOld = styled.div({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProgressSummaryWithHideDoneIconContainerCHIN = styled.div({
	display: 'flex',
	marginBottom: token('space.100'),
});

const createChildIssueContainerStyles = xcss({
	marginTop: 'space.100',
});
