import React, { useEffect, useRef, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { CardLoading } from '@atlaskit/media-card';
import { xcss, Box } from '@atlaskit/primitives';
import { WidthObserver } from '@atlaskit/width-detector';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';

import GridView from './components/grid-view.tsx';
import { MAX_ROW_SIZE, DEFAULT_COLUMN_SIZE } from './constants.tsx';
import { getColumnSize } from './utils.tsx';
import { GridProvider, useGridContext } from './context/index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const AsyncAttachmentGridView = lazy(
	() => import(/* webpackChunkName: "async-attachment-grid-view" */ './main'),
);

export const GridSkeleton = () => {
	const { colSize } = useGridContext();
	return (
		<GridView isLoading>
			{Array.from({ length: (colSize || DEFAULT_COLUMN_SIZE) * MAX_ROW_SIZE }, (_, index) => (
				<CardLoading
					key={index}
					dimensions={{
						height: '125px',
					}}
				/>
			))}
		</GridView>
	);
};

const GridViewContent = () => {
	const [isWidthCalculated, setIsWidthCalculated] = useState(false);
	const { updateColSize } = useGridContext();
	const gridViewWidthObserverRef = useRef<HTMLDivElement>(null);

	const debouncedResize = useMemo(
		() =>
			debounce((width: number) => {
				updateColSize(getColumnSize(width));
			}, 100),
		[updateColSize],
	);

	useEffect(() => {
		if (gridViewWidthObserverRef.current) {
			debouncedResize(gridViewWidthObserverRef.current.offsetWidth);
			setIsWidthCalculated(true);
		}
	}, [debouncedResize]);

	return (
		<>
			<Box xcss={relativeWrapper} ref={gridViewWidthObserverRef}>
				<WidthObserver setWidth={debouncedResize} />
			</Box>
			{isWidthCalculated && (
				<Placeholder name="jira-attachment-grid-view" fallback={<GridSkeleton />}>
					<AsyncAttachmentGridView />
				</Placeholder>
			)}
		</>
	);
};

const AttachmentGridView = () => {
	return (
		<JSErrorBoundary id="issue.details.attachment.grid-view" packageName="jira-issue-view-base">
			<GridProvider>
				<GridViewContent />
			</GridProvider>
		</JSErrorBoundary>
	);
};

export default AttachmentGridView;

const relativeWrapper = xcss({
	position: 'relative',
});
