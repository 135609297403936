import type { Store as ReduxStore } from 'redux';
import type { State as IssueState } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, type Action } from '@atlassian/react-sweet-state';

/*
 * This sweet state store holds the Issue View redux store.
 *
 * The reason for doing this is because the current version of react-redux jira-frontend
 * is on does not expose the useStore hook and we need to access the redux store further down
 * in the tree so we can wrap issue actions in a Provider. The issue actions are used in the
 * ShortcutsDialog component which is rendered outside of the issue view scope
 * (hence the need to be wrapped in the store provider).
 *
 * Here is the DACI that led to this implementation decision
 * https://hello.atlassian.net/wiki/spaces/JIE/pages/1773458701/Decision+Shortcuts+dialog+rendered+outside+of+issue-view+-+actions+need+access+to+issue-view+redux+store
 *
 * This store should only be used as a short term solution (until the issue view moves to relay).
 * No new usages of this store should be added.
 *
 * DO NOT USE the useStoreForIssueViewShortcutActions_DO_NOT_USE hook
 * OR the withStoreForIssueViewShortcutActions_DO_NOT_USE HOC.
 */

type IssueStore = ReduxStore<IssueState>;
type State = {
	store: null | IssueStore;
};

const actions = {
	setStore:
		(store: null | IssueStore): Action<State> =>
		({ setState }) => {
			setState({ store });
		},
} as const;
export type ActionsType = typeof actions;

export const issueViewStore = createStore<State, ActionsType>({
	initialState: {
		store: null,
	},
	actions,
	name: 'issue-view-store',
});
