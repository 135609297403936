/**
 * @generated SignedSource<<82fe0e3ffda7907f5c795008092299b0>>
 * @relayHash d927164e1eea08cda69ef17eaefb40d6
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 65107db5e70b4f85f75ce98067325e1d7b83135c90375d668c7c57609c3457a0

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { CardCoverManagerQuery } from './CardCoverManagerQuery.graphql';

const node: PreloadableConcreteRequest<CardCoverManagerQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "65107db5e70b4f85f75ce98067325e1d7b83135c90375d668c7c57609c3457a0",
    "metadata": {},
    "name": "CardCoverManagerQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
