import React, { useCallback } from 'react';
import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type {
	GroupApproverFieldValue,
	GroupApproverPrincipal,
	ApproverFieldValue,
} from '@atlassian/jira-issue-shared-types/src/common/types/approval-type.tsx';
import { getIssueModalAkDropdownPortal } from '@atlassian/jira-issue-view-common-utils/src/get-element/index.tsx';
import { useIssueLayoutActions } from '@atlassian/jira-issue-view-layout/src/services/main.tsx';
import {
	SERVICEDESK_APPROVAL_TYPE,
	STATUS_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { AsyncApprovalGlancePanel } from '@atlassian/jira-servicedesk-approval-panel/src/async.tsx';
import messages from '../messages.tsx';
import type { GlanceProps } from '../types.tsx';
import useApprovalField from '../use-approval-fields.tsx';

/**
 * Convert Group Approver Principals to Multi-Group-Picker values.
 *
 * This converts the Approver principals into a format suitable for sending to the back-end. The
 * reason it uses `.flatMap()` instead of `.map()` is because Flow can’t tell that the output will
 * _only_ be group approvers. So we write the conversion in a format Flow can understand.
 *
 * @param {ApproverFieldValue | ReadOnlyArray<ApproverFieldValue> | ReadOnlyArray<GroupApproverPrincipal>} list A list of approvers.
 * @returns An array of multi-group-picker values.
 */
const convertGroupFieldValues = (
	list:
		| null
		| ApproverFieldValue
		| ReadonlyArray<ApproverFieldValue>
		| ReadonlyArray<GroupApproverPrincipal>,
): ReadonlyArray<GroupApproverFieldValue> => {
	if (list == null) return [];
	const approvers = Array.isArray(list) ? list : [list];
	return approvers.flatMap((approver) =>
		approver.type === 'group' && typeof approver.name === 'string' ? [{ name: approver.name }] : [],
	);
};

/**
 * Filter out approvers that aren't single user values.
 *
 * This is a cludge to get Flow to recognise that the return value is purely a list of single
 * user approvers and doesn't contain any group approvers. The reason it uses `.flatMap()` instead
 * of `.map()` is because Flow can’t tell that the output will _only_ be user approvers. So we
 * write the conversion in a format Flow can understand.
 *
 * @param {ApproverFieldValue | ReadOnlyArray<ApproverFieldValue> | ReadOnlyArray<GroupApproverPrincipal>} list A list of approvers.
 * @returns Only the approvers that are single user picker values.
 */
const onlyUserApprovers = (
	list:
		| null
		| ApproverFieldValue
		| ReadonlyArray<ApproverFieldValue>
		| ReadonlyArray<GroupApproverPrincipal>,
): null | ApproverFieldValue | ReadonlyArray<ApproverFieldValue> => {
	if (list == null) return null;
	// @ts-expect-error - TS2322 - Type 'readonly GroupApproverPrincipal[] | ApproverFieldValue | readonly ApproverFieldValue[]' is not assignable to type 'ApproverFieldValue | readonly ApproverFieldValue[] | null'.
	if (!Array.isArray(list)) return list;
	return list.flatMap((approver) => (approver.type === 'group' ? [] : [approver]));
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: GlanceProps) => {
	const { showFlag } = useFlagsService();
	const issueKey = useIssueKey();

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const portalElement = getIssueModalAkDropdownPortal() || document.body;

	const [, { setIssueViewLayoutContextPanel }] = useIssueLayoutActions();

	const approvalFieldId =
		(props.payload && props.payload.approvalFieldId) || SERVICEDESK_APPROVAL_TYPE;

	const onHidePanel = useCallback(() => {
		setIssueViewLayoutContextPanel(issueKey, undefined);
	}, [issueKey, setIssueViewLayoutContextPanel]);

	const onAddApproverError = useCallback(() => {
		showFlag({
			messageId: 'issue-view-context-approval.show-flag.error.view.show-flag.add-approver-error',
			messageType: 'transactional',
			type: 'error',
			title: messages.addApproverError,
		});
	}, [showFlag]);

	const onRemoveApproverError = useCallback(() => {
		showFlag({
			messageId: 'issue-view-context-approval.show-flag.error.view.show-flag.remove-approver-error',
			messageType: 'transactional',
			type: 'error',
			title: messages.removeApproverError,
		});
	}, [showFlag]);

	const { approval, linkedFieldId, saveLinkedFieldValue, autoCompleteUrl, isEditable } =
		useApprovalField({
			issueKey,
			approvalFieldId,
		});

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [statusFieldValue] = useFieldValue({ issueKey, fieldKey: STATUS_TYPE });

	const onApproverListUpdated = (
		newApproverList:
			| null
			| ApproverFieldValue
			| ReadonlyArray<ApproverFieldValue>
			| ReadonlyArray<GroupApproverPrincipal>,
	) => {
		const fieldValues =
			approval?.configuration.approvers.type === 'multi_group_picker'
				? convertGroupFieldValues(newApproverList)
				: onlyUserApprovers(newApproverList);
		Promise.all([
			saveLinkedFieldValue(
				fieldValues,
				null,
				createAnalyticsEvent({
					approverSourceFieldType: approval?.configuration.approvers.type,
				}),
			),
			Promise.resolve(
				// TODO - Keep redux + ss in sync. Remove when approvals multi-user-picker is extracted
				props.updateApprovalLinkedUserPickerField(linkedFieldId, newApproverList),
			),
		])
			// Once saveLinkedFieldValue() finishes, we need to trigger a background refresh so that
			// we can work out whether the current user is able to approve the request or not.
			// Ideally, we'd only request data for the servicedesk-approvals calculated field, but
			// at this particular location, we don't have a way to do that.
			.then(props.renderApproverList);
	};

	const approvalGlanceProps = {
		fieldId: linkedFieldId,
		fieldMetaKey: linkedFieldId,
		approval,
		issueKey,
		fieldAutoCompleteUrl: autoCompleteUrl ?? '',
		hasLinkedFieldEditPermissions: isEditable,
		issueStatusId: statusFieldValue.id,
		portalElement,
		onRemoveApproverError,
		onAddApproverError,
		onHidePanel,
		renderApproverList: props.renderApproverList, // TODO - remove when issue refresh hook exists
		onApproverListUpdated,
	};

	// @ts-expect-error - TS2322 - Type '{ fieldId: string; fieldMetaKey: string; approval: Approval | null; issueKey: string; fieldAutoCompleteUrl: string; hasLinkedFieldEditPermissions: boolean | undefined; issueStatusId: any; ... 5 more ...; onApproverListUpdated: (newApproverList: readonly GroupApproverPrincipal[] | ... 2 more ... | null) => void; }' is not assignable to type 'ApprovalGlancePanelProps'.
	return <AsyncApprovalGlancePanel {...approvalGlanceProps} />;
};
