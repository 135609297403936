import React from 'react';
import EmptyState from '@atlaskit/empty-state';
import { Box, xcss } from '@atlaskit/primitives';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import UnknownError from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/error/components/empty-search-error-colour/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { ErrorDisplay } from '../../common/ui/error-display/index.tsx';
import { messages } from './messages.tsx';

const ErrorImage = () => {
	return fg('replace_error_display_with_empty_state') ? (
		<Box xcss={imageWrapperStyles}>
			<UnknownError width={90} height={130} alt="" />
		</Box>
	) : (
		<UnknownError width={90} height={130} alt="" />
	);
};

export const UnknownErrorView = () => {
	const intl = useIntl();

	return fg('replace_error_display_with_empty_state') ? (
		<EmptyState
			testId="issue-view-errors.ui.unknown-error-view.unknown-error-view"
			header={intl.formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.unknownErrorHeaderIssueTermRefresh
					: messages.unknownErrorHeader,
			)}
			description={intl.formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.unknownErrorDescriptionIssueTermRefresh
					: messages.unknownErrorDescription,
			)}
			renderImage={() => <ErrorImage />}
		/>
	) : (
		<ErrorDisplay
			header={intl.formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.unknownErrorHeaderIssueTermRefresh
					: messages.unknownErrorHeader,
			)}
			description={intl.formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.unknownErrorDescriptionIssueTermRefresh
					: messages.unknownErrorDescription,
			)}
			image={<ErrorImage />}
		/>
	);
};

const imageWrapperStyles = xcss({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});
