import { useCallback, useEffect, useMemo } from 'react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { fg } from '@atlassian/jira-feature-gating';
import {
	useIsAiEnabledForIssue,
	useIssueKey,
	useIssueId,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { REPORTER, ASSIGNEE } from '@atlassian/jira-issue-view-configurations/src/index.tsx';

import { projectTypeToCanonicalId } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useIsPremium } from '@atlassian/jira-platform-react-hooks-use-ai-opt-in/src/index.tsx';
import { useUserProperty } from '@atlassian/jira-platform-use-user-properties/src/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	useProjectKey,
	useEdition,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { useActivationId } from '@atlassian/jira-tenant-context-controller/src/components/activation-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';

import {
	useAutohighlightSupplier,
	AutoHighlightsProduct,
	KeyPhraseCategory,
	useTextHighlighterClickNative,
} from '@atlassian/search-ai';
import { useAcronymsQuery } from '../../services/index.tsx';
import {
	useHighlightActions,
	useIsHighlightEnabled,
	useResetTargetHighlight,
} from '../use-highlight-actions/index.tsx';
import {
	isUnderlineInLoadingState,
	markUpContentGracefully,
	parseContent,
	removeUnderlineStyle,
	resetMarkedUpContentGracefully,
} from './utils.tsx';

// Replace with lodash/noop
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noopWithClickArgs = (_e: MouseEvent) => {};

const AUTO_HIGHLIGHTING_ENABLED_PROPERTY_KEY = 'autoHighlightingEnabled';

const HIGHLIGHT_RESET_TIMEOUT = 12000;

export const useAutoHighlightingEnabled: () => {
	autoHighlightingEnabled: boolean;
	setAutoHighlightingEnabled: (newValue: boolean) => void;
} = () => {
	const [autoHighlightingEnabled, setAutoHighlightingEnabledInternal] = useUserProperty(
		AUTO_HIGHLIGHTING_ENABLED_PROPERTY_KEY,
	);

	const autoHighlightingEnabledBoolean = useMemo(
		() => autoHighlightingEnabled === 'true' || autoHighlightingEnabled === undefined,
		[autoHighlightingEnabled],
	);

	const setAutoHighlightingEnabled = useCallback(
		(newValue: boolean) => {
			setAutoHighlightingEnabledInternal(newValue ? 'true' : 'false');
		},
		[setAutoHighlightingEnabledInternal],
	);

	return { autoHighlightingEnabled: autoHighlightingEnabledBoolean, setAutoHighlightingEnabled };
};

export const useAcronymHighlighter = <T extends HTMLElement>(
	descriptionRef: React.RefObject<T>,
	fieldType?: string,
) => {
	const { highlightEnabled } = useIsHighlightEnabled();
	const { data, loadAcronyms, resetData } = useAcronymsQuery();
	const {
		toggleAcronymDialog,
		setTargetRef,
		setTriggerPosition,
		setKeyPhraseCategory,
		setFieldType,
	} = useHighlightActions();

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isAiOptInEnabled = useIsAiEnabledForIssue();
	const isPremium = useIsPremium();
	const isFeatureEnabled = isPremium && isAiOptInEnabled;
	const issueKey = useIssueKey();
	const issueId = useIssueId() || '';
	const projectKey = useProjectKey(issueKey);
	const edition = useEdition(projectKey, true);
	const projectType = useProjectType(projectKey);
	const productKey = projectType && projectTypeToCanonicalId(projectType);
	const cloudId = useCloudId();
	const activationId = useActivationId();
	const [jiraReporter] = useFieldValue({ issueKey, fieldKey: REPORTER });
	const [jiraAssignee] = useFieldValue({ issueKey, fieldKey: ASSIGNEE });
	const { resetTargetHighlight } = useResetTargetHighlight();
	let onHighlightClickWrapper = noopWithClickArgs;

	const { autoHighlightingEnabled } = useAutoHighlightingEnabled();
	const FIELD_TYPE_COMMENT = 'comment';

	const { keyPhrases, keyPhraseCategory, loadAutohighlights, resetAutohighlights } = fg(
		'kd_definitions_autohighlight_supplier',
	)
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useAutohighlightSupplier({
				product: AutoHighlightsProduct.JIRA,
				cloudId,
				activationId,
				contentId: issueId,
				contentType: 'issue',
				jiraReporterAccountId:
					fieldType === FIELD_TYPE_COMMENT && fg('thor_ai_definitions_issue_comment')
						? undefined
						: jiraReporter?.accountId,
				jiraAssigneeAccountId: jiraAssignee?.accountId,
				subProduct: productKey,
				fieldType,
			})
		: {
				keyPhrases: [],
				keyPhraseCategory: KeyPhraseCategory.AUTO,
				loadAutohighlights: noop,
				resetAutohighlights: noop,
			};

	// open definitions popup on single click
	const onClick = useCallback(
		(e: MouseEvent | React.MouseEvent<HTMLElement, MouseEvent>) => {
			e.stopPropagation();
			toggleAcronymDialog(
				e.clientX,
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				e.target as HTMLElement,
			);

			resetTargetHighlight();
			// hide highlight when a definition is loading
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			setTargetRef(e.target as HTMLElement);
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			removeUnderlineStyle(e.target as HTMLElement);

			setKeyPhraseCategory(keyPhraseCategory);
			setFieldType(fieldType);

			if (fg('kd_definitions_highlight_reset')) {
				// Temporary workaround to replace the highlight if the user exits(click outside popup) while loading
				// to be replaced with click outside logic in the PopupWrapper component
				setTimeout(() => {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					if (isUnderlineInLoadingState(e.target as HTMLElement)) {
						resetTargetHighlight();
					}
				}, HIGHLIGHT_RESET_TIMEOUT);
			}

			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'jiraReadingAidsAcronymHighlight',
			});
			fireUIAnalytics(analyticsEvent, {
				isReadingAidsEnabled: isFeatureEnabled,
				edition,
			});
		},
		[
			toggleAcronymDialog,
			resetTargetHighlight,
			setTargetRef,
			setKeyPhraseCategory,
			keyPhraseCategory,
			setFieldType,
			fieldType,
			createAnalyticsEvent,
			isFeatureEnabled,
			edition,
		],
	);

	// open the define popup on double clicks
	const onDoubleClick = useCallback(
		(e: MouseEvent | React.MouseEvent<HTMLElement, MouseEvent>) => {
			e.stopPropagation();
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			setTargetRef(e.target as HTMLElement);
			setTriggerPosition(
				e.clientX,
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				e.target as HTMLElement,
				'action-menu',
			);
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			removeUnderlineStyle(e.target as HTMLElement);
		},
		[setTriggerPosition, setTargetRef],
	);

	if (fg('kd_definitions_handle_double_click')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const onHighlightClick = useTextHighlighterClickNative({
			singleClickCallback: onClick,
			doubleClickCallback: onDoubleClick,
		});

		// eslint-disable-next-line react-hooks/rules-of-hooks
		onHighlightClickWrapper = useCallback(
			(e: MouseEvent) => {
				e.stopPropagation();
				onHighlightClick(e);
			},
			[onHighlightClick],
		);
	} else {
		onHighlightClickWrapper = onClick;
	}

	const loadData = useCallback(
		(adfValue: ADF | undefined) => {
			const content = parseContent(adfValue);
			if (content) {
				fg('kd_definitions_autohighlight_supplier')
					? loadAutohighlights({ inputText: content, contentId: issueKey })
					: loadAcronyms(content);
			}
		},
		[loadAutohighlights, loadAcronyms, issueKey],
	);
	const highlightAcronyms = useCallback(
		(adfValue: ADF | undefined) => {
			if (adfValue) {
				loadData(adfValue);
			}
		},
		[loadData],
	);

	useEffect(() => {
		if (descriptionRef.current) {
			if (autoHighlightingEnabled) {
				if (keyPhrases.length && fg('kd_definitions_autohighlight_supplier')) {
					const phrases = keyPhrases.map((node) => node.keyPhrase);
					markUpContentGracefully(descriptionRef.current, phrases, onHighlightClickWrapper);
				} else if (data) {
					const { acronyms } = data || { acronyms: [] };
					markUpContentGracefully(descriptionRef.current, acronyms, onHighlightClickWrapper);
				}
			} else if (!autoHighlightingEnabled) {
				resetMarkedUpContentGracefully();
			}
		}
	}, [keyPhrases, descriptionRef, autoHighlightingEnabled, onHighlightClickWrapper, data]);

	const resetAcronymsData = useCallback(() => {
		fg('kd_definitions_autohighlight_supplier') ? resetAutohighlights() : resetData();
	}, [resetAutohighlights, resetData]);

	return !highlightEnabled
		? {
				highlightAcronyms: noop,
				resetAcronymsData: noop,
			}
		: {
				highlightAcronyms,
				resetAcronymsData,
			};
};
