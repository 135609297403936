import React, { type MouseEvent } from 'react';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import { LabelIcon } from '@atlassian/jira-servicedesk-customer-service-common/src/ui/label-icon/index.tsx';
import { useRouterPush } from '@atlassian/jira-servicedesk-customer-service-common/src/utils/use-router-push/index.tsx';
import { useEntitlementFieldValue } from '../../../common/controllers/use-entitlement-field-value/index.tsx';
import { getProfileUrl } from '../../../common/ui/details/utils.tsx';
import { useIsAgentOrProjectAdmin } from '../../../common/utils.tsx';

const getEntityType = (typename?: string): 'CUSTOMER' | 'ORGANIZATION' | undefined => {
	switch (typename) {
		case 'JiraServiceManagementEntitlementCustomer':
			return 'CUSTOMER';
		case 'JiraServiceManagementEntitlementOrganization':
			return 'ORGANIZATION';
		case 'CustomerServiceIndividual':
			return 'CUSTOMER';
		case 'CustomerServiceOrganization':
			return 'ORGANIZATION';
		default:
			return undefined;
	}
};

export const EntitlementField = () => {
	const { push } = useRouterPush();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const fieldValue = useEntitlementFieldValue();
	const isAgentOrProjectAdmin = useIsAgentOrProjectAdmin();

	const entityType = getEntityType(fieldValue?.entity?.__typename);
	if (!fieldValue || !fieldValue.entity || !entityType) {
		return <></>;
	}

	const { id, product } = fieldValue;

	const profileURL = `${getProfileUrl(projectKey, entityType, fieldValue.entity.id)}?selectedEntitlement=${id}`;

	const onClick = (e: MouseEvent<HTMLElement>) => {
		fireUIAnalytics(createAnalyticsEvent({}), 'link clicked', 'viewCustomerContextProfile', {
			context: 'ENTITLEMENT',
		});

		push(e, profileURL);
	};

	return (
		<Inline
			space="space.100"
			alignBlock="center"
			xcss={containerStyles}
			testId="servicedesk-customer-service-issue-view-panel.ui.entitlement.entitlement-field.inline"
		>
			<LabelIcon size="small" type="PRODUCT" />
			<Box as="span" xcss={nameStyles}>
				{isAgentOrProjectAdmin ? (
					<a onClick={onClick} href={profileURL}>
						{product.name}
					</a>
				) : (
					product.name
				)}
			</Box>
		</Inline>
	);
};

const containerStyles = xcss({
	overflowWrap: 'anywhere',
});

const nameStyles = xcss({
	display: 'flex',
	flexWrap: 'wrap',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: 1,
	wordBreak: 'break-word',
});
