import React, { useEffect, type ComponentType, useMemo } from 'react';
import isNil from 'lodash/isNil';
import { ExtensionTitle } from '@atlassian/jira-forge-ui-extension-title/src/ui/index.tsx';
import type { ForgeCustomFieldValue } from '@atlassian/jira-forge-ui-types/src/common/types/contexts/custom-field.tsx';
import type { ForgeUiIssueData } from '@atlassian/jira-forge-ui-types/src/common/types/extension-data.tsx';
import type {
	CustomFieldLatest,
	CustomFieldTypeLatest,
	CustomFieldLegacy,
	CustomFieldTypeLegacy,
} from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import { useIssueId, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { isBuiltInJiraFieldRender } from '@atlassian/jira-forge-field-base/src/utils/index.tsx';
import { useRenderCustomReadView } from './custom-read-view/index.tsx';
import { ForgeCustomFieldEdit } from './edit/index.tsx';
import { useFormatterValue } from './formatter-value/index.tsx';
import type { FieldProps, CustomFieldProps } from './types.tsx';
import { useValueFunction } from './value-function/index.tsx';

type Props = FieldProps<ForgeCustomFieldValue> & {
	shouldUseFormatter: boolean;
	extension: CustomFieldLatest | CustomFieldTypeLatest;
	extensionData: ForgeUiIssueData | null;
	fieldName: string;
	ForgeField: ComponentType<CustomFieldProps>;
};

type PropsOld = FieldProps<ForgeCustomFieldValue> & {
	shouldUseFormatter: boolean;
	extension: CustomFieldLegacy | CustomFieldTypeLegacy;
	extensionData: ForgeUiIssueData | null;
	fieldName: string;
	ForgeField: ComponentType<CustomFieldProps>;
};

const ForgeCustomFieldViewNew = ({
	value,
	fieldId,
	fieldName,
	onSave,
	shouldUseFormatter,
	extension,
	extensionData,
	ForgeField,
	...restProps
}: Props) => {
	const issueId = useIssueId();
	const issueKey = useIssueKey();

	const shouldUseValueFunction = useMemo(
		() => !isNil(extension?.properties?.view?.value),
		[extension],
	);

	const {
		error: valueFunctionError,
		isLoading: isLoadingValueFunction,
		requestExecuteValueFunction,
	} = useValueFunction(
		issueKey,
		issueId,
		fieldId,
		extension?.properties?.type,
		shouldUseValueFunction,
		onSave,
	);

	const { formatterValue, isLoading: isLoadingFormatter } = useFormatterValue({
		value,
		formatterProperty: extension?.properties?.view?.formatter,
		fieldId,
		issueKey,
		issueId,
		shouldUseFormatter,
		isEditing: restProps.isEditing,
	});

	useEffect(() => {
		if (shouldUseValueFunction) {
			requestExecuteValueFunction();
		}
	}, [requestExecuteValueFunction, shouldUseValueFunction]);

	const { type, collection } = extension.properties;

	const nativeRendering = isBuiltInJiraFieldRender(extension, 'view', 'issue-view');

	const shouldRenderCustomReadView =
		!nativeRendering ||
		(shouldUseFormatter && (formatterValue !== null || isLoadingFormatter)) ||
		(shouldUseValueFunction && (valueFunctionError !== null || isLoadingValueFunction));

	const renderCustomReadView = useRenderCustomReadView({
		extension,
		extensionData,
		type,
		fieldId,
		fieldName,
		collection,
		shouldUseFormatter,
		formatterValue,
		error: valueFunctionError,
		isLoadingValueFunction,
		onRequestExecuteValueFunction: requestExecuteValueFunction,
		isLoadingFormatter,
	});

	const viewProps: CustomFieldProps = {
		shouldFireScreenEvent: !shouldRenderCustomReadView,
		value,
		fieldId,
		fieldName,
		extension,
		titleView: (
			<ExtensionTitle
				extensionName={restProps.label}
				extensionEnvType={extension.environmentType}
				extensionEnvKey={extension.environmentKey}
			/>
		),
		...(shouldRenderCustomReadView
			? {
					customReadView: renderCustomReadView,
				}
			: null),
		...restProps,
	};

	if (!isBuiltInJiraFieldRender(extension, 'edit', 'issue-view')) {
		const { onCancel } = restProps;

		return (
			<ForgeCustomFieldEdit
				issueKey={issueKey}
				fieldId={fieldId}
				fieldName={fieldName}
				value={value}
				extension={extension}
				extensionData={extensionData}
				renderCustomReadView={renderCustomReadView}
				shouldRenderCustomReadView={shouldRenderCustomReadView}
				viewProps={viewProps}
				ForgeField={ForgeField}
				onCancel={onCancel}
				onSave={onSave}
			/>
		);
	}

	return <ForgeField {...viewProps} />;
};

const ForgeCustomFieldViewOld = ({
	value,
	fieldId,
	fieldName,
	onSave,
	shouldUseFormatter,
	extension,
	extensionData,
	ForgeField,
	...restProps
}: PropsOld) => {
	const issueId = useIssueId();
	const issueKey = useIssueKey();

	const shouldUseValueFunction = useMemo(() => !isNil(extension?.properties.value), [extension]);

	const {
		error: valueFunctionError,
		isLoading: isLoadingValueFunction,
		requestExecuteValueFunction,
	} = useValueFunction(
		issueKey,
		issueId,
		fieldId,
		extension?.properties?.type,
		shouldUseValueFunction,
		onSave,
	);

	const { formatterValue, isLoading: isLoadingFormatter } = useFormatterValue({
		value,
		formatterProperty: extension?.properties.formatter,
		fieldId,
		issueKey,
		issueId,
		shouldUseFormatter,
		isEditing: restProps.isEditing,
	});

	useEffect(() => {
		if (shouldUseValueFunction) {
			requestExecuteValueFunction();
		}
	}, [requestExecuteValueFunction, shouldUseValueFunction]);

	const {
		type,
		collection,
		function: extensionFunction,
		resource: extensionResource,
		render,
	} = extension.properties;

	const shouldRenderCustomReadView =
		!isNil(extensionFunction) ||
		(!isNil(extensionResource) && render === 'native') ||
		(shouldUseFormatter && (formatterValue !== null || isLoadingFormatter)) ||
		(shouldUseValueFunction && (valueFunctionError !== null || isLoadingValueFunction));

	const renderCustomReadView = useRenderCustomReadView({
		extension,
		extensionData,
		type,
		fieldId,
		fieldName,
		collection,
		shouldUseFormatter,
		formatterValue,
		error: valueFunctionError,
		isLoadingValueFunction,
		onRequestExecuteValueFunction: requestExecuteValueFunction,
		isLoadingFormatter,
	});

	const viewProps: CustomFieldProps = {
		shouldFireScreenEvent: !shouldRenderCustomReadView,
		value,
		fieldId,
		fieldName,
		extension,
		titleView: (
			<ExtensionTitle
				extensionName={restProps.label}
				extensionEnvType={extension.environmentType}
				extensionEnvKey={extension.environmentKey}
			/>
		),
		...(shouldRenderCustomReadView
			? {
					customReadView: renderCustomReadView,
				}
			: null),
		...restProps,
	};

	if (extension.properties.edit) {
		const { onCancel } = restProps;

		return (
			<ForgeCustomFieldEdit
				issueKey={issueKey}
				fieldId={fieldId}
				fieldName={fieldName}
				value={value}
				extension={extension}
				extensionData={extensionData}
				renderCustomReadView={renderCustomReadView}
				shouldRenderCustomReadView={shouldRenderCustomReadView}
				viewProps={viewProps}
				ForgeField={ForgeField}
				onCancel={onCancel}
				onSave={onSave}
			/>
		);
	}

	return <ForgeField {...viewProps} />;
};

export const ForgeCustomFieldView = componentWithFG(
	'ditto-fcf-support-new-manifest-on-frontend',
	ForgeCustomFieldViewNew,
	ForgeCustomFieldViewOld,
);
