import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';

import { getBlueprint } from './confluence-blueprint.tsx';

export type ConfluencePage = {
	content: {
		id: string;
		title: string;
		status: string;
		space: {
			id: number;
			key: string;
		};
		version: {
			number: number;
		};
		_links: {
			self: string;
			tinyui: string;
			editui: string;
			webui: string;
		};
	};
	_links: {
		base: string;
		context: string;
	};
};

export type DraftConfluencePage = {
	contentId: string;
	draftShareId: string | undefined;
};

export const createConfluenceDraft = async (
	cloudId: string,
	spaceKey: string,
	blueprintModuleCompleteKey: string,
): Promise<DraftConfluencePage | null> => {
	try {
		const blueprint = await getBlueprint(cloudId, spaceKey, blueprintModuleCompleteKey);

		const response = await fetchJson<ConfluencePage>(
			`/gateway/api/ex/confluence/${cloudId}/wiki/rest/create-dialog/1.0/content/blueprint/${blueprintModuleCompleteKey}/instance`,
			{
				method: 'POST',
				body: JSON.stringify({
					content: {
						type: 'page',
						status: 'draft',
						space: {
							key: spaceKey,
						},
						metadata: {
							properties: {
								editor: {
									value: 'v2',
								},
							},
							frontend: {
								embedded: true,
							},
						},
					},
					contentBlueprintSpec: {
						context: {
							spaceKey,
						},
						blueprintId: blueprint.contentBlueprintId,
					},
				}),
			},
		);
		return {
			contentId: response.content.id,
			draftShareId: response.content._links.editui?.match(/draftShareId=(.*?)(#|&|$)/)?.[1],
		};
	} catch (error: unknown) {
		fireErrorAnalytics({
			meta: {
				id: 'jira-issue-content-placeholder-template/confluence-create-draft',
				packageName: '@atlassian/jira-issue-content-placeholder-template',
				teamName: 'bento',
			},
			attributes: { errorCode: error instanceof FetchError ? error.statusCode : null },
			error: {
				name: 'error',
				message: `Fetching blueprints succeeded, but blueprint: ${blueprintModuleCompleteKey} was not found for space: ${spaceKey}`,
			},
		});
		return null;
	}
};
