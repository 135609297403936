// eslint-disable-next-line jira/wrm/no-load-bridge
import { loadBridge } from '@atlassian/jira-common-bridge/src';
import { REVIEW_PANEL_DATA_TYPE } from '@atlassian/jira-development-details/src/common/model/constants.tsx';
import type { PanelDataTypes } from '@atlassian/jira-development-details/src/main/model/types.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createContainer,
	createHook,
	createStore,
	createSubscriber,
	defaultRegistry,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';
import { DEV_DETAILS_WRM_RESOURCE, INITIAL_STATE } from '../common/constants/index.tsx';
import type { LegacyData, State } from '../common/types/index.tsx';
import type { DialogBridge } from './types.tsx';

export const loadDialogBridge = async (): Promise<DialogBridge> =>
	// eslint-disable-next-line jira/wrm/no-load-bridge
	loadBridge({
		name: DEV_DETAILS_WRM_RESOURCE,
		wrmKeys: [
			'wrc!com.atlassian.jira.plugins.jira-development-integration-plugin:devstatus-wrm',
			'wrc!com.atlassian.jira.plugins.jira-development-integration-plugin:devsummarycf-resources',
		],
	});

export const actions = {
	resetState:
		(stateOverrides?: Partial<State>) =>
		({ setState }: Partial<StoreActionApi<State>>): void => {
			// @ts-expect-error - TS2722 - Cannot invoke an object which is possibly 'undefined'.
			setState({ ...INITIAL_STATE, ...stateOverrides });
		},
	updateState:
		(stateUpdates: Partial<State>) =>
		({ getState, setState }: Partial<StoreActionApi<State>>): void => {
			// @ts-expect-error - TS2722 - Cannot invoke an object which is possibly 'undefined'. | TS2722 - Cannot invoke an object which is possibly 'undefined'.
			setState({ ...getState(), ...stateUpdates });
		},
	showDevDetailDialog:
		(
			itemType: PanelDataTypes,
			legacyData?: LegacyData,
			targetIssueKey?: string,
			targetIssueId?: string,
		) =>
		({ setState, getState }: Partial<StoreActionApi<State>>): void => {
			if (itemType === REVIEW_PANEL_DATA_TYPE) {
				// @ts-expect-error - TS2722 - Cannot invoke an object which is possibly 'undefined'.
				setState({
					// @ts-expect-error - TS2722 - Cannot invoke an object which is possibly 'undefined'.
					...getState(),
					legacyDevPanelDetailsDialogShownCounter:
						// @ts-expect-error - TS2722 - Cannot invoke an object which is possibly 'undefined'.
						getState().legacyDevPanelDetailsDialogShownCounter + 1,
					isDevPanelDetailsDialogShown: false,
					dataTypeSelected: itemType,
					legacyData,
					issueKey: targetIssueKey,
					issueId: targetIssueId,
				});
			} else {
				// @ts-expect-error - TS2722 - Cannot invoke an object which is possibly 'undefined'.
				setState({
					// @ts-expect-error - TS2722 - Cannot invoke an object which is possibly 'undefined'.
					...getState(),
					isDevPanelDetailsDialogShown: true,
					dataTypeSelected: itemType,
					issueKey: targetIssueKey,
					issueId: targetIssueId,
				});
			}
		},
	closeDetailDialog:
		() =>
		({ setState, getState }: Partial<StoreActionApi<State>>): void => {
			// @ts-expect-error - TS2722 - Cannot invoke an object which is possibly 'undefined'.
			setState({
				// @ts-expect-error - TS2722 - Cannot invoke an object which is possibly 'undefined'.
				...getState(),
				// @ts-expect-error - TS2322 - Type '""' is not assignable to type 'PanelDataTypes | undefined'.
				dataTypeSelected: '',
				isDevPanelDetailsDialogShown: false,
				issueKey: undefined,
				issueId: undefined,
			});
		},
} as const;

export type Actions = typeof actions;

export const Store = createStore<State, Actions>({
	name: 'DetailsDialogLoaderRemoteState', // Unique store ID, visible in redux-dev-tools chrome plugin
	initialState: INITIAL_STATE,
	actions,
});

export const DevOpsDetailsDialogLoaderContainer = createContainer<State, Actions, {}>(Store);

export const DevOpsDetailsDialogLoaderSubscriber = createSubscriber<State, Actions>(Store);

export const useDevOpsDetailsDialogLoaderStore = createHook(Store);

export function useDevOpsDetailsDialogLoaderActions(scopeId: string) {
	return defaultRegistry.getStore(Store, scopeId)?.actions;
}
