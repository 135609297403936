import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import defer from 'lodash/defer';
import Button from '@atlaskit/button/new';
import { ErrorMessage } from '@atlaskit/form';
import Heading from '@atlaskit/heading';
import ChevronRightIcon from '@atlaskit/icon/utility/migration/chevron-right';
import { Box, Flex, Inline, xcss } from '@atlaskit/primitives';
import TextField from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import {
	fireTrackAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { ColorPickerMenu } from './color-picker/index.tsx';
import messages from './messages.tsx';
import { OptionTag } from './option-tag/index.tsx';
import type {
	ColorOptionData,
	ProjectType,
	ColorPaletteVariant,
	ColorCardVariant,
	ColorPickerStateType,
} from './types.tsx';
import { COLOR_PICKER_STATE } from './constants.tsx';
import { GREY_META } from './color-picker/constants.tsx';

export type Props = {
	onCancel: () => void;
	onSubmit: (data: ColorOptionData) => void;
	initialData: ColorOptionData;
	projectType: ProjectType;
	type?: ColorPickerStateType;
	colorPaletteVariant?: ColorPaletteVariant;
	colorCardVariant?: ColorCardVariant;
	editLabel?: boolean;
	showCancelButton?: boolean;
	onInputBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
	availableOptions?: string[];
};

export const ColorPickerForm = memo<Props>(
	({
		onCancel,
		onSubmit,
		initialData,
		type,
		projectType = 'software',
		colorPaletteVariant = 'old',
		colorCardVariant = 'fill',
		editLabel = true,
		showCancelButton = true,
		availableOptions,
		onInputBlur,
	}: Props) => {
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const { color, name } = initialData;
		const { formatMessage } = useIntl();
		const [currentColor, setCurrentColor] = useState<string>(!color ? GREY_META.name : color);
		const [currentName, setCurrentName] = useState<string>(name);
		const [isInputDirty, setIsInputDirty] = useState<boolean>(false);
		const inputElement = useRef<HTMLInputElement>(null);
		const analyticsEventName =
			projectType === 'business' ? 'CategoryForm' : 'OptionColorPickerForm';
		const analyticsEventPrefix = projectType === 'business' ? 'category' : 'option';

		useEffect(() => {
			fireTrackAnalytics(createAnalyticsEvent({}), `${analyticsEventName} viewed`, {
				purpose: type,
			});
		}, [createAnalyticsEvent, type, analyticsEventName]);

		useEffect(() => {
			if (inputElement.current) {
				// This ensures that the input element is focused after the component is rendered
				defer(() => inputElement?.current?.focus());
			}
		}, []);

		const isUpdatedOptionNameDuplicate = useCallback(() => {
			// If the current name is the same as the original name, it's not a duplicate.
			if (currentName === name) {
				return false;
			}
			// Check if the current name exists in the available options.
			return availableOptions?.includes(currentName) ?? false;
		}, [currentName, name, availableOptions]);

		const isUpdatedOptionNameEmpty = useCallback(() => {
			return !currentName || currentName.trim() === '';
		}, [currentName]);

		const isOptionNameInvalid = useCallback(
			() => isInputDirty && (isUpdatedOptionNameEmpty() || isUpdatedOptionNameDuplicate()),
			[isUpdatedOptionNameEmpty, isInputDirty, isUpdatedOptionNameDuplicate],
		);

		const handleSubmit = useCallback(() => {
			if (isOptionNameInvalid()) return;
			fireUIAnalytics(
				createAnalyticsEvent({}),
				'button clicked',
				type === COLOR_PICKER_STATE.EDIT
					? `${analyticsEventPrefix}FormSave`
					: `${analyticsEventPrefix}FormAdd`,
			);
			onSubmit && onSubmit({ name: currentName, color: currentColor });
		}, [
			createAnalyticsEvent,
			analyticsEventPrefix,
			onSubmit,
			currentName,
			currentColor,
			type,
			isOptionNameInvalid,
		]);

		const handleCancel = useCallback(() => {
			onCancel();
			fireUIAnalytics(
				createAnalyticsEvent({}),
				'button clicked',
				`${analyticsEventPrefix}FormCancel`,
			);
		}, [onCancel, createAnalyticsEvent, analyticsEventPrefix]);

		const handleKeyDownEvent = useCallback(
			(event: KeyboardEvent) => {
				if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
					handleCancel();
				}
				if (event.key === 'Enter' || event.keyCode === 13) {
					handleSubmit();
					event.preventDefault();
				}
			},
			[handleCancel, handleSubmit],
		);

		useEffect(() => {
			if (typeof window !== 'undefined') {
				window.addEventListener('keydown', handleKeyDownEvent, true);

				return () => {
					window.removeEventListener('keydown', handleKeyDownEvent, true);
				};
			}
		}, [handleKeyDownEvent]);

		const getInvalidOptionNameErrorMessage = () => {
			if (isUpdatedOptionNameEmpty()) {
				return formatMessage(messages.errorMessageBlankOptionName);
			}
			if (isUpdatedOptionNameDuplicate()) {
				return formatMessage(messages.errorMessageDuplicateOptionName);
			}
			return null;
		};

		return (
			<Box
				onClick={() => inputElement?.current?.focus()}
				xcss={[formStyles, colorPaletteVariant === 'new' && maxWidthStyles]}
				backgroundColor="elevation.surface.overlay"
			>
				<Inline
					alignBlock="center"
					xcss={[previewStyles, wrapperStyles]}
					testId="option-color-picker.preview"
				>
					<Heading as="h4" size="small">
						{type === COLOR_PICKER_STATE.EDIT
							? formatMessage(messages.editOptionTitle)
							: formatMessage(messages.newOptionTitle)}
					</Heading>
					<ChevronRightIcon label="" color={token('color.icon.subtle')} spacing="spacious" />
					{currentName !== '' && (
						<>
							<OptionTag name={currentName} color={currentColor} />
						</>
					)}
				</Inline>
				<Box xcss={formContentStyles}>
					{editLabel && (
						<Box xcss={[fieldWrapperStyles, wrapperStyles]}>
							<TextField
								aria-label={formatMessage(messages.optionNameLabel)}
								autoComplete="off"
								autoFocus
								ref={inputElement}
								value={currentName}
								onBlur={(e) => {
									if (!isInputDirty) setIsInputDirty(true);
									onInputBlur?.(e);
								}}
								placeholder={formatMessage(messages.optionNamePlaceholder)}
								onChange={(e) => {
									if (!isInputDirty) setIsInputDirty(true);
									setCurrentName(e.currentTarget.value);
								}}
							/>
							{isOptionNameInvalid() && (
								<ErrorMessage>{getInvalidOptionNameErrorMessage()}</ErrorMessage>
							)}
						</Box>
					)}
					<Box xcss={fieldWrapperStyles}>
						<ColorPickerMenu
							onChangeColor={(newColor: string) => {
								setCurrentColor(newColor);
								fireUIAnalytics(
									createAnalyticsEvent({}),
									'color clicked',
									`${analyticsEventPrefix}FormColorPicker`,
									{ color: newColor },
								);
							}}
							color={currentColor}
							colorPaletteVariant={colorPaletteVariant}
							colorCardVariant={colorCardVariant}
						/>
					</Box>
					<Flex xcss={buttonContainerStyles} gap="space.100" justifyContent="end">
						{showCancelButton && (
							<Button
								appearance="subtle"
								spacing="compact"
								testId="option-color-picker.cancel-button"
								onClick={handleCancel}
							>
								{formatMessage(messages.cancelButton)}
							</Button>
						)}
						<Button
							appearance="primary"
							spacing="compact"
							testId="option-color-picker.submit-button"
							onClick={handleSubmit}
						>
							{type === COLOR_PICKER_STATE.EDIT
								? `${formatMessage(messages.saveButton)} ⏎`
								: formatMessage(messages.addButton)}
						</Button>
					</Flex>
				</Box>
			</Box>
		);
	},
);

const formStyles = xcss({
	borderRadius: 'border.radius',
	boxShadow: 'elevation.shadow.overlay',
	display: 'block',
	padding: 'space.200',
	width: 'fit-content',
});
const maxWidthStyles = xcss({
	maxWidth: '356px',
});

const previewStyles = xcss({
	whiteSpace: 'nowrap',
	maxWidth: '350px',
});

const wrapperStyles = xcss({
	marginLeft: 'space.050',
	marginRight: 'space.050',
});
const buttonContainerStyles = xcss({
	marginRight: 'space.050',
});
const fieldWrapperStyles = xcss({
	marginBottom: 'space.150',
});

const formContentStyles = xcss({
	marginTop: 'space.100',
});
