import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { ApplicationKey } from '@atlassian/jira-shared-types/src/application-key.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import { PACKAGE_NAME, STORAGE_KEY, USAGE_LIMITS_EXPIRY_MS } from '../../../common/constants.tsx';
import type { UsageLimitsData } from '../../../common/types.tsx';
import {
	usageLimitsStorage,
	setUsageLimitsForProductInLocalStorage,
	isUsageLimitsResponse,
} from '../../../common/utils.tsx';
import type { State } from '../../types.tsx';

function setCachedDataUsage(response: UsageLimitsData) {
	usageLimitsStorage.set(STORAGE_KEY, response, Date.now() + USAGE_LIMITS_EXPIRY_MS);
}

type FetchStorageLimitDataForProductParams = {
	caller: string;
	analyticsMetaId: string;
	analyticsTeamName: string;
	productType: ApplicationKey;
	cloudId: string;
};

export const fetchStorageLimitDataForProduct =
	({
		caller,
		analyticsMetaId,
		productType,
		cloudId,
		analyticsTeamName,
	}: FetchStorageLimitDataForProductParams): Action<State> =>
	async ({ getState, setState }) => {
		const currentState = getState();

		const dataForProductAlreadyInStore = currentState[productType]?.data;
		// If data from API is already there, use it
		if (dataForProductAlreadyInStore != null) {
			return;
		}

		// If fetch in progress, bail out.
		if (currentState[productType]?.isFetching === true) {
			return;
		}

		setState({ [productType]: { ...getState()[productType], isFetching: true, error: null } });

		fetchJson(`/gateway/api/media/admin/usage/limits?cloudId=${cloudId}&products=${productType}`)
			.then((response: UsageLimitsData) => {
				if (response?.limits === undefined) {
					throw Error(`Invalid storage limit response: ${JSON.stringify(response)}`);
				}
				setState({ [productType]: { ...getState()[productType], data: response } });

				setCachedDataUsage(response);
			})
			.catch((e) => {
				setState({ [productType]: { ...getState()[productType], error: e } });
				fireErrorAnalytics({
					error: e,
					meta: {
						id: analyticsMetaId,
						packageName: PACKAGE_NAME,
						teamName: analyticsTeamName,
					},
					attributes: {
						caller,
						fetchTraceId: e.traceId,
						productType,
					},
					sendToPrivacyUnsafeSplunk: true,
				});
			})
			.finally(() => {
				setState({ [productType]: { ...getState()[productType], isFetching: false } });
			});
	};

type FetchStorageLimitDataForProductParamsWithTimestamp = {
	caller: string;
	analyticsMetaId: string;
	analyticsTeamName: string;
	productType: ApplicationKey;
	cloudId: string;
};

export const fetchStorageLimitDataForProductWithTimestamp =
	({
		caller,
		analyticsMetaId,
		productType,
		cloudId,
		analyticsTeamName,
	}: FetchStorageLimitDataForProductParamsWithTimestamp): Action<State> =>
	async ({ getState, setState }) => {
		// if fetch is in progress from another call, exit early and rely on the other call to update it in memory
		if (getState()[productType]?.isFetching === true) {
			return;
		}

		// initiate fetch
		setState({
			[productType]: {
				...getState()[productType],
				isFetching: true,
				error: null,
			},
		});

		return fetchJson(
			`/gateway/api/media/admin/usage/limits?cloudId=${cloudId}&products=${productType}`,
		)
			.then((response: unknown) => {
				if (!isUsageLimitsResponse(response)) {
					throw Error('Invalid storage limit response', {
						cause: JSON.stringify(response),
					});
				}

				const fetchTimestamp = Date.now();
				const usageLimitsWithTimestamp: UsageLimitsData = {
					...response,
					fetchTimestamp,
				};

				// set the data in memory
				setState({
					[productType]: {
						...getState()[productType],
						data: usageLimitsWithTimestamp,
					},
				});

				// set the data in local storage
				setUsageLimitsForProductInLocalStorage(
					productType,
					usageLimitsWithTimestamp,
					analyticsTeamName,
				);
			})
			.catch((e) => {
				setState({ [productType]: { ...getState()[productType], error: e } });
				fireErrorAnalytics({
					error: e,
					meta: {
						id: analyticsMetaId,
						packageName: PACKAGE_NAME,
						teamName: analyticsTeamName,
					},
					attributes: {
						caller,
						fetchTraceId: e.traceId,
						productType,
					},
					sendToPrivacyUnsafeSplunk: true,
				});
			})
			.finally(() => {
				setState({ [productType]: { ...getState()[productType], isFetching: false } });
			});
	};
