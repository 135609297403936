import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import {
	customFieldInteractionMetric,
	markAdditionalTimingsFromWindowPerformance,
} from '../../common/utils/performance-analytics/main.tsx';
import { PERFORMANCE_KEYS } from '../../constants.tsx';
import type { GlobalState, GlobalStoreApi, ContainerState, ContainerStoreApi } from './types.tsx';
import { defaultShouldMeasureInteraction, getExtensionMeasuredKey } from './utils.tsx';

export const updateExtensionMeasuredStartTime =
	(issueKey: IssueKey, extensionId: string, measuredStartTime: number): Action<GlobalState> =>
	({ getState, setState }: GlobalStoreApi) => {
		const { extensionsMeasuredStartTime } = getState();

		setState({
			extensionsMeasuredStartTime: {
				...extensionsMeasuredStartTime,
				[getExtensionMeasuredKey(issueKey, extensionId)]: measuredStartTime,
			},
		});
	};

export const markPageSegmentFMP =
	(): Action<ContainerState> =>
	({ getState, setState }: ContainerStoreApi) => {
		const { pageSegmentLoadPreviouslyMeasured, pageSegmentLoadMetric } = getState();

		if (
			!pageSegmentLoadPreviouslyMeasured &&
			pageSegmentLoadMetric &&
			pageSegmentLoadMetric.getData().state === 'started'
		) {
			pageSegmentLoadMetric.markFMP();
			pageSegmentLoadMetric.mark(PERFORMANCE_KEYS.MARKS.TTI_START);

			setState({
				pageSegmentLoadMetric,
			});
		}
	};

export const markPageSegmentAsInterative =
	(): Action<ContainerState> =>
	({ getState, setState }: ContainerStoreApi) => {
		const {
			performanceAttributes,
			pageSegmentLoadPreviouslyMeasured,
			pageSegmentLoadMetric,
			pageSegmentLoadAdditionalTimings,
		} = getState();

		if (
			performanceAttributes &&
			!pageSegmentLoadPreviouslyMeasured &&
			pageSegmentLoadMetric &&
			pageSegmentLoadMetric.getData().state === 'started'
		) {
			const { customData } = performanceAttributes;

			pageSegmentLoadMetric.mark(PERFORMANCE_KEYS.MARKS.TTI_END);

			if (Array.isArray(pageSegmentLoadAdditionalTimings)) {
				markAdditionalTimingsFromWindowPerformance(
					pageSegmentLoadMetric,
					pageSegmentLoadAdditionalTimings,
				);
			}

			pageSegmentLoadMetric.stop({
				// will show as BM3 props "custom:key = value"
				customData,
			});

			setState({
				pageSegmentLoadMetric,
			});
		}
	};

export const startUserInteraction =
	(
		shouldMeasureInteraction: (
			source: string | undefined,
			renderContext: string,
		) => boolean = defaultShouldMeasureInteraction,
	): Action<ContainerState> =>
	({ getState, setState }: ContainerStoreApi) => {
		const { performanceAttributes, interactionAdditionalTimings } = getState();

		if (performanceAttributes) {
			const { source, renderContext, module } = performanceAttributes;

			switch (module) {
				case 'jira:customField':
				case 'jira:customFieldType': {
					if (shouldMeasureInteraction(source, renderContext)) {
						const interactionMetric = customFieldInteractionMetric(interactionAdditionalTimings);
						interactionMetric.start();
						setState({ interactionMetric });
					}
					break;
				}
				default: {
					// performance analytics turned off as default behavior
				}
			}
		}
	};

export const markUserInteractionFeedback =
	(): Action<ContainerState> =>
	({ getState, setState }: ContainerStoreApi) => {
		const { interactionMetric } = getState();

		if (interactionMetric && interactionMetric.getData().state === 'started') {
			interactionMetric.mark('feedback');
			setState({ interactionMetric });
		}
	};

export const finishUserInteraction =
	(): Action<ContainerState> =>
	({ getState, setState }: ContainerStoreApi) => {
		const { performanceAttributes, interactionMetric, interactionAdditionalTimings } = getState();

		if (
			performanceAttributes &&
			interactionMetric &&
			interactionMetric.getData().state === 'started'
		) {
			const { customData } = performanceAttributes;

			if (Array.isArray(interactionAdditionalTimings)) {
				markAdditionalTimingsFromWindowPerformance(interactionMetric, interactionAdditionalTimings);
			}

			interactionMetric.stop({
				// will show as BM3 props "custom:key = value"
				customData,
			});
			setState({ interactionMetric: undefined });
		}
	};

export const globalActions = {
	updateExtensionMeasuredStartTime,
} as const;

export type GlobalActions = typeof globalActions;

export const containerActions = {
	markPageSegmentFMP,
	markPageSegmentAsInterative,
	startUserInteraction,
	markUserInteractionFeedback,
	finishUserInteraction,
} as const;

export type ContainerActions = typeof containerActions;
