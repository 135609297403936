import React from 'react';
import { AsyncIssueViewBackgroundScript } from '@atlassian/jira-forge-issue-background-script/src/ui/async/AsyncIssueViewBackgroundScript.tsx';
import type { IssueViewBackgroundScriptExtensionData } from '@atlassian/jira-forge-ui-types/src/common/types/extension-data.tsx';
import type { IssueViewBackgroundScript } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import { useOnDemandIssueRefreshTime } from '@atlassian/jira-issue-refresh-service/src/services/main.tsx';

export type Props = {
	item: IssueViewBackgroundScript;
	extensionData: Omit<IssueViewBackgroundScriptExtensionData, 'type'>;
};

type PropsWithRefreshedOn = Props & { refreshedOn: number };

export const ForgeBackgroundScriptBase = ({ item, extensionData }: PropsWithRefreshedOn) => {
	const {
		properties: { resourceUploadId, shouldReloadOnRefresh },
	} = item;

	const refreshedOn = useOnDemandIssueRefreshTime();

	if (!shouldReloadOnRefresh) {
		const key = `forge-iFrame-${resourceUploadId}`;

		return (
			<AsyncIssueViewBackgroundScript
				key={key}
				testId={key}
				extension={item}
				extensionData={extensionData}
			/>
		);
	}

	const key = refreshedOn
		? `forge-iFrame-${resourceUploadId}-${refreshedOn}`
		: `forge-iFrame-${resourceUploadId}`;

	return (
		<AsyncIssueViewBackgroundScript
			key={key}
			testId={key}
			extension={item}
			extensionData={extensionData}
		/>
	);
};

ForgeBackgroundScriptBase.defaultProps = { refreshedOn: 0 };

export { ForgeBackgroundScriptBase as ForgeBackgroundScript };
