import isEqual from 'lodash/isEqual';
import startOfWeek from 'date-fns/startOfWeek';
import addDays from 'date-fns/addDays';
import getTimezoneOffset from 'date-fns-tz/getTimezoneOffset';
import { addMilliseconds, subMilliseconds } from 'date-fns';
import type { ByDay, RecurrenceRule } from '@atlassian/jira-ical/src/types.tsx';
import { Days, Frequencies } from '@atlassian/jira-ical/src/constants.tsx';
import type { MessageDescriptor, IntlShape } from '@atlassian/jira-intl';
import type { RuleConfig } from '../common/types.tsx';
import type { RecurFrequency, RecurFrequencyFieldValues } from './fields/RecurFrequencyField.tsx';
import type { RecurWeeklyConfigValues } from './fields/RecurWeeklyConfigField.tsx';
import type { RecurConditionFieldValues } from './fields/RecurConditionField.tsx';
import type { FormData } from './types.tsx';
import messages from './messages.tsx';

export const mapFrequencyToRRule = (
	timeframe: RecurFrequency,
	recurWeeklyConfig: ByDay[],
):
	| Pick<RecurrenceRule, 'frequency' | 'byDay' | 'interval'>
	| Pick<RecurrenceRule, 'frequency' | 'interval'> => {
	switch (timeframe) {
		case 'DAILY':
			return { frequency: Frequencies.DAILY, interval: 1 };
		case 'WEEKDAY':
			return {
				frequency: Frequencies.DAILY,
				byDay: [Days.MO, Days.TU, Days.WE, Days.TH, Days.FR],
				interval: 1,
			};
		case 'WEEKLY':
			return { frequency: Frequencies.WEEKLY, byDay: recurWeeklyConfig, interval: 1 };
		case 'FORTNIGHTLY':
			// There is no native FORTNIGHTLY frequency for ical, so we need to combine WEEKLY with interval 2
			return { frequency: Frequencies.WEEKLY, byDay: recurWeeklyConfig, interval: 2 };
		default:
			throw new Error(`Unknown timeframe: ${timeframe}`);
	}
};

export const mapFrequencyToSmartOffsetValue = (timeframe: RecurFrequency) => {
	switch (timeframe) {
		case 'DAILY':
			return '{{now.plusDays(1)}}';
		case 'WEEKDAY':
			return '{{now.plusBusinessDays(1)}}';
		case 'WEEKLY':
			return '{{now.plusWeeks(1)}}';
		case 'FORTNIGHTLY':
			return '{{now.plusWeeks(2)}}';
		default:
			throw new Error(`Could not calculate due date smart offset value: ${timeframe}`);
	}
};

export const getFrequencyFromRRule = (rrule: RecurrenceRule): RecurFrequency | undefined => {
	if (rrule.frequency === Frequencies.DAILY) {
		if (isEqual(rrule.byDay, [Days.MO, Days.TU, Days.WE, Days.TH, Days.FR])) {
			return 'WEEKDAY';
		}
		return 'DAILY';
	}
	if (rrule.frequency === Frequencies.WEEKLY) {
		if (rrule.interval === 2) {
			return 'FORTNIGHTLY';
		}
		return 'WEEKLY';
	}
};

export const getRecurMenuItemLabel = (
	rrule: RecurrenceRule,
	isRecurOnComplete = false,
): MessageDescriptor => {
	if (isRecurOnComplete) {
		return messages.recursOnComplete;
	}

	const frequency = getFrequencyFromRRule(rrule);

	// Every business day (Monday to Friday)
	if (frequency === 'WEEKDAY') {
		return messages.recursEveryWeekday;
	}
	// Daily
	if (frequency === 'DAILY') {
		return messages.recursDaily;
	}
	// Fortnightly
	if (frequency === 'FORTNIGHTLY') {
		return messages.recursEveryTwoWeeks;
	}
	// Weekly
	if (frequency === 'WEEKLY') {
		return messages.recursWeekly;
	}

	return messages.setToRecur;
};

const byDayOptions = Object.values(Days);

const getWeekDayNameFromByDay = (byDayValue: ByDay, intl: IntlShape) => {
	const dayIndex = byDayOptions.indexOf(byDayValue);
	if (dayIndex < 0) {
		throw new Error(`Invalid ByDay value provided: ${byDayValue}. Valid options: ${byDayOptions}`);
	}
	const firstDayOfWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
	const day = addDays(firstDayOfWeek, dayIndex);
	return intl.formatDate(day, { weekday: 'long' });
};

type GetWeekDayNamesFromByDaysParams = {
	byDayValues: ByDay[];
	intl: IntlShape;
	standardizedLocale: string;
};

export const getWeekDayNamesFromByDays = ({
	byDayValues,
	intl,
	standardizedLocale,
}: GetWeekDayNamesFromByDaysParams) =>
	new Intl.ListFormat(standardizedLocale, { style: 'long', type: 'conjunction' }).format(
		Array.from(byDayValues, (byDayValue) => getWeekDayNameFromByDay(byDayValue, intl)),
	);

type GetSuccessFlagContentParams = RecurFrequencyFieldValues &
	RecurConditionFieldValues &
	Partial<RecurWeeklyConfigValues> & {
		intl: IntlShape;
		standardizedLocale: string;
		issueKey: string;
		issueType: string;
	};

export type GetSuccessFlagContentResult = {
	title: string;
	description?: string;
};

export const getSuccessFlagContent = ({
	frequency,
	recurCondition,
	recurWeeklyConfig,
	intl,
	standardizedLocale,
	issueKey,
	issueType,
}: GetSuccessFlagContentParams): GetSuccessFlagContentResult => {
	const issueTypeLowercase = issueType.toLocaleLowerCase(standardizedLocale);
	switch (recurCondition) {
		case 'SCHEDULE_IF_COMPLETE':
			switch (frequency) {
				case 'DAILY':
					return {
						title: intl.formatMessage(messages.successFlagTitleRecurOnScheduleIfCompleteDaily, {
							issueKey,
						}),
						description: intl.formatMessage(
							messages.successFlagDescriptionRecurOnScheduleIfCompleteDaily,
							{ issueType: issueTypeLowercase },
						),
					};
				case 'WEEKDAY':
					return {
						title: intl.formatMessage(messages.successFlagTitleRecurOnScheduleIfCompleteWeekday, {
							issueKey,
						}),
						description: intl.formatMessage(
							messages.successFlagDescriptionRecurOnScheduleIfCompleteWeekday,
							{ issueType: issueTypeLowercase },
						),
					};
				case 'WEEKLY':
					return {
						title: intl.formatMessage(messages.successFlagTitleRecurOnScheduleIfCompleteWeekly, {
							issueKey,
						}),
						description: intl.formatMessage(
							messages.successFlagDescriptionRecurOnScheduleIfCompleteWeekly,
							{
								issueType: issueTypeLowercase,
								weekDayNames: getWeekDayNamesFromByDays({
									byDayValues: recurWeeklyConfig ?? [],
									intl,
									standardizedLocale,
								}),
							},
						),
					};
				case 'FORTNIGHTLY':
					return {
						title: intl.formatMessage(
							messages.successFlagTitleRecurOnScheduleIfCompleteFortnightly,
							{ issueKey },
						),
						description: intl.formatMessage(
							messages.successFlagDescriptionRecurOnScheduleIfCompleteFortnightly,
							{
								issueType: issueTypeLowercase,
								weekDayNames: getWeekDayNamesFromByDays({
									byDayValues: recurWeeklyConfig ?? [],
									intl,
									standardizedLocale,
								}),
							},
						),
					};
				default:
					throw new Error(`Unknown frequency: ${frequency}`);
			}
		case 'SCHEDULE':
			switch (frequency) {
				case 'DAILY':
					return {
						title: intl.formatMessage(messages.successFlagTitleRecurOnScheduleDaily, { issueKey }),
					};
				case 'WEEKDAY':
					return {
						title: intl.formatMessage(messages.successFlagTitleRecurOnScheduleWeekday, {
							issueKey,
						}),
					};
				case 'WEEKLY':
					return {
						title: intl.formatMessage(messages.successFlagTitleRecurOnScheduleWeekly, {
							issueKey,
							weekDayNames: getWeekDayNamesFromByDays({
								byDayValues: recurWeeklyConfig ?? [],
								intl,
								standardizedLocale,
							}),
						}),
					};
				case 'FORTNIGHTLY':
					return {
						title: intl.formatMessage(messages.successFlagTitleRecurOnScheduleFortnightly, {
							issueKey,
							weekDayNames: getWeekDayNamesFromByDays({
								byDayValues: recurWeeklyConfig ?? [],
								intl,
								standardizedLocale,
							}),
						}),
					};
				default:
					throw new Error(`Unknown frequency: ${frequency}`);
			}
		default:
			throw new Error(`Unknown recur condition: ${recurCondition}`);
	}
};

export const isRecurOnCompleteToggleRule = (issueProperties: RuleConfig | null): boolean => {
	return (
		!!issueProperties &&
		!!issueProperties.token &&
		(issueProperties.recurOnScheduleConfig === null ||
			issueProperties.recurOnScheduleConfig === undefined)
	);
};

const areByDaysEqual = ({
	byDays,
	anotherByDays,
}: {
	byDays?: ByDay[];
	anotherByDays?: ByDay[];
}): boolean => {
	// assumes lists have a consistent order
	if (byDays === undefined) {
		return anotherByDays === undefined;
	}
	if (anotherByDays === undefined) {
		return false;
	}
	if (byDays.length !== anotherByDays.length) {
		return false;
	}
	return byDays.every((byDay, index) => byDay === anotherByDays[index]);
};

const areDatesEqual = (date1: Date | undefined, date2: Date | undefined): boolean => {
	if (date1 === undefined || date2 === undefined) {
		return date1 === date2;
	}

	// We only care about checking the date equality, not the time
	return (
		date1.getFullYear() === date2.getFullYear() &&
		date1.getMonth() === date2.getMonth() &&
		date1.getDate() === date2.getDate()
	);
};

export type HasRuleChangedParams = {
	formInput: FormData;
	formOutput: FormData;
};

export const hasRuleChanged = ({ formInput, formOutput }: HasRuleChangedParams): boolean => {
	// comparing 2 recur-on-schedule rules
	if (
		formInput.frequency !== formOutput.frequency ||
		formInput.condition !== formOutput.condition ||
		!areDatesEqual(formInput.endsOn, formOutput.endsOn)
	) {
		return true;
	}
	if (formInput.frequency === 'WEEKLY' || formInput.frequency === 'FORTNIGHTLY') {
		return !areByDaysEqual({ byDays: formInput.byDay, anotherByDays: formOutput.byDay });
	}
	return false;
};

/**
 * Adjusts the JS Date object to the user-configured timezone to be used for date formatting.
 * The unix timestamp will be incorrect, but the human-readable date will be usable.
 *
 * @param date Date object to be adjusted, assumed to have browser timezone applied
 * @param browserTimezone Current browser timezone string (e.g. Australia/Sydney)
 * @param userTimezone Current user-configured timezone strong (e.g. America/Los_Angeles)
 * @returns Date object adjusted to user-configured timezone to be used for date formatting
 */
export const applyUserTimezone = (
	date: Date,
	browserTimezone: string,
	userTimezone: string,
): Date => {
	const browserTimezoneOffsetMs = getTimezoneOffset(browserTimezone);
	const userTimezoneOffsetMs = getTimezoneOffset(userTimezone);

	return addMilliseconds(subMilliseconds(date, browserTimezoneOffsetMs), userTimezoneOffsetMs);
};
