import { useState, useEffect, useCallback, type RefObject } from 'react';
import noop from 'lodash/noop';
import { fg } from '@atlassian/jira-feature-gating';
import { useResizeObserver } from '@atlassian/jira-react-use-resize-observer/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';

export const useElementWidth = (ref: RefObject<HTMLElement>) => {
	const isLinkedIssuesRefreshEnabled =
		isVisualRefreshEnabled() && fg('redesign-linked-issues-issue-view');
	const [width, setWidth] = useState<number>(0);

	useEffect(() => {
		if (ref.current && isLinkedIssuesRefreshEnabled) {
			setWidth(ref.current.clientWidth || 0);
		}
	}, [ref, isLinkedIssuesRefreshEnabled]);

	const updateWidth = useCallback(() => {
		if (ref.current) {
			setWidth(ref.current.clientWidth || 0);
		}
	}, [ref]);

	useResizeObserver({
		ref,
		onResize: isLinkedIssuesRefreshEnabled ? updateWidth : noop,
	});

	return width;
};
