import { performGetRequestWithCache } from '@atlassian/jira-caching-layer/src/utils/cache-proxies/perform-get-request.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { ProjectId, BaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';

const onFetchErrorCacheHandler = (error: FetchError) => {
	if (error.statusCode === 404) {
		return { value: false };
	}
	throw error;
};

export const isDemoProject = async ({
	baseUrl,
	projectId,
}: {
	baseUrl: BaseUrl;
	projectId: ProjectId;
}) => {
	try {
		if (expValEquals('jira_caching_layer_milestone_1_exp', 'cohort', 'variation')) {
			const response = await performGetRequestWithCache<FetchError>(
				`${baseUrl}/rest/api/3/project/${projectId}/properties/isDemo`,
				onFetchErrorCacheHandler,
			);
			return !!response && !!response.value;
		}
		const response = await performGetRequest(
			`${baseUrl}/rest/api/3/project/${projectId}/properties/isDemo`,
		);
		return !!response && !!response.value;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		if (error.statusCode === 404) {
			/**
			 * Expected when project property is not found.
			 * Demo project has project property "isDemo = true",
			 * otherwise property does not exist
			 * and response status will be 404
			 */
			return false;
		}

		throw error;
	}
};

export const getItsmSampleProject = async ({
	baseUrl,
}: {
	baseUrl: BaseUrl;
}): Promise<
	| {
			projectId: number;
			projectKey: string;
	  }
	| undefined
> => {
	try {
		const response = await performGetRequest(
			`${baseUrl}/rest/servicedesk/onboarding/1/itsm/sample-project`,
		);
		return response;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		if (error.statusCode === 404) {
			return undefined;
		}

		throw error;
	}
};
