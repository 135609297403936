/**
 * @generated SignedSource<<e4f8992b0ea95910affe1139722e55b2>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type AssignToMeInlineEditInner$data = {
  readonly fieldId: string;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"assignToMe_issueFieldAssigneeAssignToMe_fragmentRef">;
  readonly " $fragmentType": "AssignToMeInlineEditInner";
};
export type AssignToMeInlineEditInner$key = {
  readonly " $data"?: AssignToMeInlineEditInner$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssignToMeInlineEditInner">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "AssignToMeInlineEditInner",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "FragmentSpread",
      "name": "assignToMe_issueFieldAssigneeAssignToMe_fragmentRef"
    }
  ],
  "type": "JiraSingleSelectUserPickerField"
};

(node as any).hash = "843c16b6a5deae96bfe486645a222c19";

export default node;
