import fireErrorAnalytics, {
	isNetworkError,
} from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { PACKAGE_NAME, TEAM_NAME } from '../constants.tsx';

export const SKIP_SENTRY_ERROR_MESSAGES = [
	'Internal error.',
	'Internal error opening backing store for indexedDB.open.',
	"Can't find variable: indexedDB",
	'Error looking up record in object store by key range',
	"IDBDatabase.transaction: 'keyval' is not a known object store name",
	'A mutation operation was attempted on a database that did not allow mutations.',
	'Encountered full disk while opening backing store for indexedDB.open.',
	"Failed to execute 'transaction' on 'IDBDatabase': One of the specified object stores was not found.",
	'Internal error retrieving bucket data directory.',
	'Connection to Indexed Database server lost. Refresh the page to try again',
	'The current transaction exceeded its quota limitations.',
	'The operation failed for reasons unrelated to the database itself and not covered by any other error code.',
	'Attempt to get a record from database without an in-progress transaction',
];

const shouldSkipSentry = (error: Error) =>
	isNetworkError(error) || SKIP_SENTRY_ERROR_MESSAGES.includes(error?.message);

export const errorHandler = (error: Error, source: string) => {
	const cause = error instanceof Error ? error.message : 'Not known';
	fireErrorAnalytics({
		meta: {
			id: source,
			packageName: PACKAGE_NAME,
			teamName: TEAM_NAME,
		},
		error: new Error(`JCL ${source} - ${cause}`, { cause: error }),
		attributes: {
			cause,
		},
		sendToPrivacyUnsafeSplunk: true,
		skipSentry: shouldSkipSentry(error),
	});
};
