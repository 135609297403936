// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createActionsHook } from '@atlassian/react-sweet-state';
import { ExportExcelModalActions, type Actions } from './actions.tsx';
import { initialState } from './constants.tsx';
import type { State } from './types.tsx';

export const ExportExcelModalStore = createStore<State, Actions>({
	initialState,
	actions: ExportExcelModalActions,
	name: 'exportExcelModalStore',
});

export const useExportExcelModalActions = createActionsHook<State, Actions>(ExportExcelModalStore);
