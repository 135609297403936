/** @jsx jsx */
import React, { type ComponentPropsWithoutRef } from 'react';
import { css, keyframes, styled, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type { Position, ResizerProps } from '../../common/types.tsx';

interface ResizeIconProps {
	triggerAnimation?: boolean;
	persistResizeIcon?: boolean;
	children: React.ReactNode;
	onResizeHandleClick?: ResizerProps['onResizeHandleClick'];
	isRefreshVariant?: boolean;
}

const resizeHandleSelectorName = 'jira-issue-view-common-component-resize-handle';
export const RESIZE_HANDLE_COMPONENT_SELECTOR = `[data-component-selector="${resizeHandleSelectorName}"]`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ResizeHandleComponent = styled.div<{
	resizeHandleAsOverlay?: boolean;
	resizeHandleZIndex?: number;
	position?: Position;
	collapsed?: boolean;
}>(
	{
		flexGrow: 0,
		flexShrink: 0,
		width: `${token('space.300')}`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
		cursor: ({ collapsed }) => (collapsed ? 'pointer' : 'ew-resize'),
		alignSelf: 'stretch',
		display: 'flex',
		justifyContent: 'center',
		'&:focus': {
			outline: 'none',
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ resizeHandleZIndex }) => resizeHandleZIndex && `z-index: ${resizeHandleZIndex}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ position }) => (position === 'left' ? 'left: 0px' : 'right: 0px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ resizeHandleAsOverlay, position }) =>
		resizeHandleAsOverlay &&
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		css({
			top: '0px',
			position: 'absolute',
			height: '100%',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			transform: position === 'left' ? 'translate3d(-50%, 0, 0)' : 'translate3d(50%, 0, 0)',
		}),
);

export const ResizeHandle = (props: ComponentPropsWithoutRef<typeof ResizeHandleComponent>) => (
	<ResizeHandleComponent data-component-selector={resizeHandleSelectorName} {...props} />
);

const appearAnimationBorder = keyframes({
	'0%': {
		opacity: 0,
	},
	'25%': {
		opacity: 1,
	},
	'75%': {
		opacity: 1,
	},
	'100%': {
		opacity: 0,
	},
});

const appearAnimationIcon = keyframes({
	'0%': {
		opacity: 0,
	},
	'1%': {
		opacity: 1,
	},
	'99%': {
		opacity: 1,
	},
	'100%': {
		opacity: 0,
	},
});

const ResizeIconBaseStyles = css({
	position: 'absolute',
	top: token('space.400'),
	height: '24px',
	width: '24px',
	left: token('space.negative.150'),
	boxShadow: `${token('elevation.shadow.overlay')}`,
	borderRadius: '50%',
	animationName: appearAnimationIcon,

	animationTimingFunction: 'ease-in-out',

	backgroundColor: token('elevation.surface'),
});

const ResizeIconRefreshStyles = css({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

const PointerStyle = css({ cursor: 'pointer' });
const OpacityStyle1 = css({ opacity: 1 });
const OpacityStyle0 = css({ opacity: 0 });
const AnimationDuration1S = css({ animationDuration: '1s' });
const AnimationDuration0S = css({ animationDuration: '0s' });
const ResizeIconCompiled = ({
	isRefreshVariant = false,
	triggerAnimation,
	persistResizeIcon,
	children,
	onResizeHandleClick,
}: ResizeIconProps) => {
	return (
		<div
			css={[
				/**
				 * This is done to support preprocessing of styles conditionally, since we need to rely on boolean decisions. Ref: https://atlassian.slack.com/archives/CFJ9DU39U/p1730768655944379?thread_ts=1730751318.919529&cid=CFJ9DU39U
				 */
				ResizeIconBaseStyles,
				isRefreshVariant && ResizeIconRefreshStyles,
				triggerAnimation && AnimationDuration1S,
				!triggerAnimation && AnimationDuration0S,
				persistResizeIcon && OpacityStyle1,
				!persistResizeIcon && OpacityStyle0,
				!!onResizeHandleClick && PointerStyle,
			]}
		>
			{children}
		</div>
	);
};

export const ResizeIcon: React.FC<ResizeIconProps> = componentWithCondition(
	() => isVisualRefreshEnabled(),
	(props: ResizeIconProps) => <ResizeIconCompiled isRefreshVariant {...props} />,
	ResizeIconCompiled,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ResizeBorder = styled.div<{
	triggerAnimation?: boolean;
	collapsed?: boolean;
}>({
	width: '2px',
	alignSelf: 'stretch',
	animationName: appearAnimationBorder,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	animationDuration: ({ triggerAnimation }) => (triggerAnimation ? '1s' : '0s'),
	animationTimingFunction: 'ease-in-out',
	transition: 'background 0.5s',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ collapsed }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
		collapsed ? token('color.border') : token('color.border.focused'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: ({ collapsed }) => (collapsed ? 1 : 0),
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	[`
                    ${RESIZE_HANDLE_COMPONENT_SELECTOR}:hover &,
                    ${RESIZE_HANDLE_COMPONENT_SELECTOR}:active &,
                    ${RESIZE_HANDLE_COMPONENT_SELECTOR}:focus &
                  `]: {
		opacity: 1,
		// This style will ensure that the border is highlighted when the resize handle is hovered on
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
		backgroundColor: token('color.border.focused'),
	},
});
